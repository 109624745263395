<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="this.callPlanMode == 'team'" [(searchText)]="callPlanSearchText"
    (onControlClick)="onCloseModal()" (onSearchInput)="onInput($event)" (onFilterClick)="openFilterMenu($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()"
    [hideFilterButton]="hideFilterButton" (ionBlur)="onSearchBlur($event)"></ind-header-left>

  <ion-toolbar *ngIf="fromPlanTab" class="container-c">
    <ind-tabs [data]="agendaPlanTabs" [(selectedTab)]="uiService.activitiesPageTab"
      (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
  </ion-toolbar>
  <ion-toolbar *ngIf="!fromPlanTab" no-padding>
    <ind-tabs [data]="tabsData" [(selectedTab)]="callPlanMode" (selectedTabChange)="segmentChange($event)"></ind-tabs>
  </ion-toolbar>

  <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length ">
    <span *ngFor="let filter of filterNameBubble; let i=index">
      <ion-text class="category-name"> {{filter.categoryName}} : </ion-text>
      <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
        {{facet.selectedFacet}}
        <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(facet, filter)" />
      </span>
    </span>
  </div>
</ion-header>


<ion-content #Content
  [ngClass]="{'vectorimageleft': (!searching && this.callPlanMode == 'mine' && this.myFormattedCallPlans && hasMyCallPlans)
                                  || (!searching && this.callPlanMode == 'team' && this.teamFormattedCallPlans && hasTeamCallPlans)}">
  <div [ngSwitch]="callPlanMode" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
    <div *ngSwitchCase="'mine'">
      <ng-container *ngIf="!searching && !filtering || isFilteredByCustomerTypeOnly">
        <ion-item-group *ngIf="callPlanOfflineService.loadedCallPlans" class="list-hight">
          <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedCallPlans && hasMyCallPlans">
            <div class="statistics-grid">
              <div *ngFor="let s of statistics" class="statistics-item {{s.id}}">
                <div class="statistics-title">
                  <label>{{s.label}}</label>
                  <label>({{s.actual}}/{{s.defined}})</label>
                </div>
                <div class="statistics-percentage">{{s.percentage}}%</div>
              </div>
            </div>
            <div *ngIf="hasCallPlanProgress && statisticsGoalProgress.length>0" class="statistics-goal-progress-grid">
              <div *ngFor="let sgp of statisticsGoalProgress" class="statistics-item {{sgp.id}}" [style]="getGridColumnSpan()">
                <div class="statistics-title">
                  <label>{{sgp.label}}: <span class="goal-progress-number">{{sgp.actual}}/{{sgp.defined}}</span></label>
                </div>
              </div>
            </div>
            <ind-section-header [viewData]="mycustomersHeaderModel"  (onControlClick)="onSortControlClick($event)"></ind-section-header>
            <ion-item *ngIf="filteredCustomers?.length === 0">
              <p class="no-call-plan">{{'NO_CALL_PLANS' | translate}}</p>
            </ion-item>
            <div *ngIf="filteredCustomers?.length != 0">
            <ion-item-group id="{{plan.customerId}}" *ngFor="let plan of filteredCustomers" (click)="handleCallPlanSelection(plan)"
              [ngClass]="{'itemSelected' : plan?.customerId === callPlanOfflineService.selectedCallPlan?.customerId}">
              <div>
                <ion-item>
                  <p *ngIf="plan?.customerType == 'contact'" class="customer-initials" [attr.data-letters]="plan.initials"></p>
                  <p *ngIf="plan?.customerType == 'account'" class="customer-initials" [attr.account-data-letters]="plan.initials"></p>
                  <ion-label class="customer-info">
                    <ion-text class="primary-text">{{plan.customerName}}</ion-text>
                    <p class="secondary-text">
                    </p>
                  </ion-label>
                  <div *ngIf="!displaygoalsbeyond100" class="percent-div-width">
                    <span slot="end" class="compPercent" [ngClass]="{'done': plan.percentage == 100}">
                      {{ plan.percentage }}%
                    </span>
                  </div>
                </ion-item>
                <div *ngIf="hasCallPlanProgress" [ngClass]="plan?.contactId === callPlanOfflineService.selectedCallPlan?.contactId ? 'customer-goal-progress-grid-selected': 'customer-goal-progress-grid'">
                  <div class="progress-item">
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalF2FMeetings || displayAllActProgress"> {{'F2F'|translate}} : <span class="goal-progress-number">{{plan.actualF2FMeetings}}/{{plan.totalGoalF2FMeetings ? plan.totalGoalF2FMeetings : 'NA'}}</span></span>
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalRemoteMeetings || displayAllActProgress">{{'REMOTE_SHORT_FORM'|translate}} : <span class="goal-progress-number">{{plan.actualRemoteMeetings}}/{{plan.totalGoalRemoteMeetings ? plan.totalGoalRemoteMeetings : 'NA'}}</span></span>
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalEmails || displayAllActProgress">{{'MESSAGE_SHORT_FORM'|translate}} : <span class="goal-progress-number">{{plan.actualEmails}}/{{plan.totalGoalEmails ? plan.totalGoalEmails : 'NA'}}</span></span>
                    <!-- <span [style]="getSecondaryGridColumnSpan(plan)"class="progress-title" *ngIf="plan.totalGoalWeCom">{{'WECOM'|translate}} : <span class="goal-progress-number">{{plan.actualWeCom?plan.actualWeCom:0}}/{{plan.totalGoalWeCom?plan.totalGoalWeCom:0}}</span></span> -->
                  </div>
                </div>
              </div>
              <div class="separationLine"></div>
            </ion-item-group>
            </div>
          </ion-list>
        </ion-item-group>
      </ng-container>
      <!-- hide statistics-grid in search or filter condition, show it in customer type filter only -->
      <ng-container *ngIf="(searching || filtering) && !isFilteredByCustomerTypeOnly">
        <ng-container *ngIf="!(filteredCustomers?.length === 0) else noSearchResult;">
          <ion-item-group *ngIf="callPlanOfflineService.loadedCallPlans">
            <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedCallPlans && hasMyCallPlans">
              <ind-section-header [viewData]="mycustomersHeaderModel" (onControlClick)="onSortControlClick($event)"></ind-section-header>
              <ion-item-group id="{{plan.customerId}}" *ngFor="let plan of filteredCustomers" (click)="handleCallPlanSelection(plan)"
              [ngClass]="{'itemSelected' : plan?.customerId === callPlanOfflineService.selectedCallPlan?.customerId}">
                <ion-item>
                  <p *ngIf="plan?.customerType == 'contact'" class="customer-initials" [attr.data-letters]="plan.initials"></p>
                  <p *ngIf="plan?.customerType == 'account'" class="customer-initials" [attr.account-data-letters]="plan.initials"></p>
                  <ion-label class="customer-info">
                    <ion-text class="primary-text">{{plan.customerName}}</ion-text>
                    <p class="secondary-text">
                    </p>
                  </ion-label>
                  <div *ngIf="!displaygoalsbeyond100" class="percent-div-width">
                    <span slot="end" class="compPercent team" [ngClass]="{'done': plan.percentage == 100}">
                      {{ plan.percentage }}%
                    </span>
                  </div>
                </ion-item>
                <div *ngIf="hasCallPlanProgress" [ngClass]="plan?.contactId === callPlanOfflineService.selectedCallPlan?.contactId ? 'customer-goal-progress-grid-selected': 'customer-goal-progress-grid'">
                  <div class="progress-item">
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalF2FMeetings || displayAllActProgress"> {{'F2F'|translate}} : <span class="goal-progress-number">{{plan.actualF2FMeetings}}/{{plan.totalGoalF2FMeetings ? plan.totalGoalF2FMeetings : 'NA'}}</span></span>
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalRemoteMeetings || displayAllActProgress">{{'REMOTE_SHORT_FORM'|translate}} : <span class="goal-progress-number">{{plan.actualRemoteMeetings}}/{{plan.totalGoalRemoteMeetings ? plan.totalGoalRemoteMeetings : 'NA'}}</span></span>
                    <span [style]="getSecondaryGridColumnSpan(plan)" class="progress-title" *ngIf="plan.totalGoalEmails || displayAllActProgress">{{'MESSAGE_SHORT_FORM'|translate}} : <span class="goal-progress-number">{{plan.actualEmails}}/{{plan.totalGoalEmails ? plan.totalGoalEmails : 'NA'}}</span></span>
                    <!-- <span [style]="getSecondaryGridColumnSpan(plan)"class="progress-title" *ngIf="plan.totalGoalWeCom">{{'WECOM'|translate}} : <span class="goal-progress-number">{{plan.actualWeCom?plan.actualWeCom:0}}/{{plan.totalGoalWeCom?plan.totalGoalWeCom:0}}</span></span> -->
                  </div>
                </div>
                <div class="separationLine"></div>
              </ion-item-group>
            </ion-list>
          </ion-item-group>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'team'" class="list-hight">
      <ng-container *ngIf="!searching">
        <ion-item-group *ngIf="waitingForTeamPlans">
          <ion-item>
            <p class="no-call-plan">{{(device.isOffline?'TEAM_CALLPLANS_ONLINE_ONLY':'NO_TEAM_CALL_PLANS') | translate}}
            </p>
          </ion-item>
        </ion-item-group>
        <ion-item-group *ngIf="!waitingForTeamPlans && !callPlanOfflineService.teamSegmentCallPlans.length">
          <ion-item>
            <p class="no-call-plan">{{(device.isOffline?'TEAM_CALLPLANS_ONLINE_ONLY':'NO_TEAM_CALL_PLANS') | translate}}
            </p>
          </ion-item>
        </ion-item-group>
        <ion-item-group *ngIf="!waitingForTeamPlans && callPlanOfflineService.teamSegmentCallPlans.length">
          <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedTeamCallPlans
                                                && hasTeamCallPlans">
            <div class="statistics-grid">
              <div *ngFor="let s of teamStatistics" class="statistics-item {{s.id}}">
                <div class="statistics-title">
                  <label>{{s.label}}</label>
                  <label>({{s.actual}}/{{s.defined}})</label>
                </div>
                <div class="statistics-percentage">{{s.percentage}}%</div>
              </div>
            </div>
            <ind-section-header [viewData]="myTeamscustomersHeaderModel"
              (onControlClick)="onSortControlClick($event)">
            </ind-section-header>
            <ion-item *ngIf="filteredTeamsCustomersByGroup?.length === 0">
              <p class="no-call-plan">{{'NO_TEAM_CALL_PLANS' | translate}}</p>
            </ion-item>
            
            <!-- /* // * // * //* Update Manager View for Team Call Plan // * // * //* */ -->
            <div *ngIf="filteredTeamsCustomersByGroup?.length !== 0">
              <div class="dv-section-header" *ngFor="let group of filteredTeamsCustomersByGroup; index as i">
                  <!-- Parent Rows   -->
                  <div class="dv-section-header-divider">
                    <ion-label class="title-text sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
                      <div class="text-wrapper">{{ group['fullname'] }} ({{ group.callplans?.length }})   </div>
                    </ion-label>
                    <ion-buttons slot="end">
                        <ion-button (click)="group.isExpand = !group.isExpand" >
                          <div class="button-content-wrapper">
                              <ion-icon class="control-icon-sort sortIcon" [name]="group.isExpand ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
                          </div>
                        </ion-button>
                    </ion-buttons>
                  </div>
                  <!-- Parent Rows   -->

                  <!-- Child Rows   -->
                  <div *ngIf="group.isExpand">
                    <ion-item-group  *ngFor="let plan of group.callplans" (click)="handleCallPlanSelection(plan)"
                    [ngClass]="{'itemSelected' : plan?.customerId === callPlanOfflineService.selectedCallPlan?.customerId && plan?.positionId === callPlanOfflineService.selectedCallPlan?.positionId}">
                      <ion-item>
                        <p *ngIf="plan?.customerType == 'contact'" class="customer-initials" [attr.data-letters]="plan.initials"></p>
                        <p *ngIf="plan?.customerType == 'account'" class="customer-initials" [attr.account-data-letters]="plan.initials"></p>
                        <ion-label class="customer-info">
                          <ion-text class="primary-text">{{plan.customerName}}</ion-text>
                          <p class="secondary-text">
                            <ion-text></ion-text>
                          </p>
                        </ion-label>
                        <div *ngIf="!displaygoalsbeyond100" class="percent-div-width">
                          <span slot="end" class="compPercent" [ngClass]="{'done': plan.percentage == 100}">
                            {{ plan.percentage }}%
                          </span>
                        </div>
                      </ion-item>
                      <div class="separationLine"></div>
                    </ion-item-group>
                  </div>
                  <!-- Child Rows   -->

                  <!-- Divider between Parent Rows -->
                  <div class="separationLine"></div>
                  <!-- Divider between Parent Rows -->
              </div>
            </div>
            <!-- /* // * // * //* Update Manager View for Team Call Plan // * // * //* */ -->

          </ion-list>
        </ion-item-group>
      </ng-container>
      <!-- Team Call Plans Advanced Search -->
      <ng-container *ngIf="searching">
        <ion-item-group *ngIf="!callPlanOfflineService.teamSegmentCallPlans">
          <ion-item>
            <p class="no-data-message">{{'NO_TEAM_CALL_PLANS' | translate}}</p>
          </ion-item>
        </ion-item-group>
        <ng-container *ngIf="!(filteredTeamsCustomersByGroup?.length === 0) else noSearchResult;">
          <ion-item-group *ngIf="!waitingForTeamPlans && callPlanOfflineService.teamSegmentCallPlans.length">
            <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedTeamCallPlans
                                                                  && hasTeamCallPlans">
              <ind-section-header [viewData]="myTeamscustomersHeaderModel" (onControlClick)="onSortControlClick($event)"></ind-section-header>
              <ion-item *ngIf="filteredTeamsCustomersByGroup?.length === 0">
                <p class="no-call-plan">{{'NO_TEAM_CALL_PLANS' | translate}}</p>
              </ion-item>

              <!-- /* // * // * //* Update Manager View for Team Call Plan // * // * //* */ -->
            <div *ngIf="filteredTeamsCustomersByGroup?.length !== 0">
              <div class="dv-section-header" *ngFor="let group of filteredTeamsCustomersByGroup; index as i">
                  <!-- Parent Rows   -->
                  <div class="dv-section-header-divider">
                    <ion-label class="title-text sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
                      <div class="text-wrapper">{{ group['fullname'] }} ({{ group.callplans?.length }})   </div>
                    </ion-label>
                    <ion-buttons slot="end">
                        <ion-button (click)="group.isExpand = !group.isExpand" >
                          <div class="button-content-wrapper">
                              <ion-icon class="control-icon-sort sortIcon" [name]="group.isExpand ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
                          </div>
                        </ion-button>
                    </ion-buttons>
                  </div>
                  <!-- Parent Rows   -->

                  <!-- Child Rows   -->
                  <div *ngIf="group.isExpand">
                    <ion-item-group  *ngFor="let plan of group.callplans" (click)="handleCallPlanSelection(plan)"
                    [ngClass]="{'itemSelected' : plan?.customerId === callPlanOfflineService.selectedCallPlan?.customerId && plan?.positionId === callPlanOfflineService.selectedCallPlan?.positionId}">
                      <ion-item>
                        <p *ngIf="plan?.customerType == 'contact'" class="customer-initials" [attr.data-letters]="plan.initials"></p>
                        <p *ngIf="plan?.customerType == 'account'" class="customer-initials" [attr.account-data-letters]="plan.initials"></p>
                        <ion-label class="customer-info">
                          <ion-text class="primary-text">{{plan.customerName}}</ion-text>
                          <p class="secondary-text">
                            <ion-text></ion-text>
                          </p>
                        </ion-label>
                        <div *ngIf="!displaygoalsbeyond100" class="percent-div-width">
                          <span slot="end" class="compPercent" [ngClass]="{'done': plan.percentage == 100}">
                            {{ plan.percentage }}%
                          </span>
                        </div>
                      </ion-item>
                      <div class="separationLine"></div>
                    </ion-item-group>
                  </div>
                  <!-- Child Rows   -->

                  <!-- Divider between Parent Rows -->
                  <div class="separationLine"></div>
                  <!-- Divider between Parent Rows -->
              </div>
            </div>
            <!-- /* // * // * //* Update Manager View for Team Call Plan // * // * //* */ -->
             
            </ion-list>
          </ion-item-group>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ion-content>
<footer-toolbar *ngIf="!fromPlanTab || isFilterActive" [footerView]="'masterView'" [selectedView]="'customercallplans'"
  (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>


<ng-template #noSearchResult>
  <ind-section-header [viewData]='noSearchResultHeaderModel'></ind-section-header>
  <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
</ng-template>