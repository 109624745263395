<!-- Generated template for the TimeOffListComponent component -->
<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="coachingSearchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="onInput($event.detail)" (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)" (onEnterClick)="onClickEnter($event)" ></ind-header-left>


    <!-- KeyControls Area -->
    <!--<ion-item-divider class="keyControlsArea" color="light" id="allCoaching">
        <ion-button item-left icon-only fill="clear" color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch">
            {{ 'SAVE_SEARCH' | translate}}
        </ion-button>
    </ion-item-divider>-->
    <ind-key-controls-area id="allCoaching" [viewData]="coachingListKeyControlAreaModel"> </ind-key-controls-area>

    <!-- selected serach suggestions area -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
          {{suggestion.selectedFacet}}
          <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)"/>
        </span>
    </div>

    <!-- Segment Tabs -->
    <ion-toolbar no-padding>
        <ind-tabs [data]="tabsData" [(selectedTab)]="coachingModel" (selectedTabChange)="checkActivityModel($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': !searching && filteredCoachingList?.length === 0}">
    <div [ngSwitch]="coachingModel">
        <!-- My Coaching Tab -->
        <div *ngSwitchCase="'myCoaching'">
            <ng-container *ngIf="!searching">
                <ind-section-header [viewData]="sectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ion-list *ngIf="filteredCoachingList?.length == 0" class="no-data-message">
                    <div *ngIf="!coachingSearchText">
                        {{'COACHING_NO_COACHING' | translate}}
                    </div>
                    <!-- <div *ngIf="searchTextModel">
                        {{'NO_RESULTS_FOR_THIS_SEARCH' | translate}}
                    </div> -->
                </ion-list>
                <ion-list class="coaching-list" *ngIf="filteredCoachingList.length > 0">
                  <!-- Extra item for Coaching from Genee: Start -->
                  <ion-item-group class="addedCoachingItem" *ngIf="!searching && coachingFromGenee" tappable [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && coachingFromGenee.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" (click)="openCoachingReportDetails(coachingFromGenee)">
                    <ion-item>
                      <coaching-item [report]="coachingFromGenee" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="sortBy"></coaching-item>
                    </ion-item>
                    <div class="separationLine"></div>
                  </ion-item-group>
                  <!-- Extra item for Coaching from Genee: End -->
                    <!-- <ion-item tappable *ngFor="let report of reportByTabSubject | texfilter  : coachingSearchText : 'created_by'; trackBy: trackByFn" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" -->
                    <ion-item-group tappable *ngFor="let report of filteredCoachingList" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" (click)="openCoachingReportDetails(report)"
                        #searchResult>
                        <ion-item [id]="report.indskr_coachingreportid">
                            <coaching-item [report]="report" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="sortBy"></coaching-item>
                            <!-- <ion-icon class="rightArrow" name="chevron-forward-outline" slot="end"></ion-icon> -->
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </ion-list>
                <ion-infinite-scroll *ngIf="filteredCoachingList.length > 0" (ionInfinite)="doInfinite($event)" threshold="30%">
                    <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ng-container>
            <ng-container *ngIf="searching">
                <ind-section-header [viewData]="searchSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ind-no-search-result *ngIf="filteredList?.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                <ion-list class="coaching-list" *ngIf="filteredList.length > 0">
                    <!-- <ion-item tappable *ngFor="let report of reportByTabSubject | texfilter  : coachingSearchText : 'created_by'; trackBy: trackByFn" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" -->
                    <ion-item-group tappable *ngFor="let report of filteredList" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" (click)="openCoachingReportDetails(report)"
                        #searchResult>
                        <ion-item>
                            <coaching-item [report]="report" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="sortBy"></coaching-item>
                            <!-- <ion-icon class="rightArrow" name="chevron-forward-outline" slot="end"></ion-icon> -->
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </ion-list>
            </ng-container>
        </div>

        <!-- Team Coaching Tab -->
        <div *ngSwitchCase="'teamCoaching'">
            <ng-container *ngIf="!searching">
                <ind-section-header [viewData]="sectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <!--        <div class="no-data-message" *ngIf="searchTextModel && filteredItems?.length == 0">{{'NO_RESULTS_FOR_THIS_SEARCH' | translate}}</div>-->
                <ion-list *ngIf="filteredCoachingList?.length == 0" class="no-data-message">
                    <div *ngIf="!coachingSearchText">
                        {{'COACHING_NO_TEAM_COACHING' | translate}}
                    </div>
                    <!-- <div *ngIf="searchTextModel">
                        {{'NO_RESULTS_FOR_THIS_SEARCH' | translate}}
                    </div> -->
                </ion-list>
                <ion-list class="coaching-list" *ngIf="filteredCoachingList.length > 0">
                  <!-- Team Coaching: Extra item for Coaching from Genee: Start -->
                  <ion-item-group class="addedCoachingItem" *ngIf="!searching && coachingFromGenee" tappable [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && coachingFromGenee.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid}" (click)="openCoachingReportDetails(coachingFromGenee)">
                    <ion-item>
                      <coaching-item [report]="coachingFromGenee" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="teamSortBy"></coaching-item>
                    </ion-item>
                    <div class="separationLine"></div>
                  </ion-item-group>
                  <!-- Team Coaching: Extra item for Coaching from Genee: End -->
                    <!-- <ion-item tappable *ngFor="let report of reportByTabSubject | texfilter  : coachingSearchText : 'created_for'; trackBy: trackByFn" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && (report.offlineCoachingReportId ? (report.offlineCoachingReportId === coachingReportService.selectedReport.value.offlineCoachingReportId) : (report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid))}" -->
                    <ion-item-group tappable *ngFor="let report of filteredCoachingList" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && (report.offlineCoachingReportId ? (report.offlineCoachingReportId === coachingReportService.selectedReport.value.offlineCoachingReportId) : (report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid))}"
                        (click)="openCoachingReportDetails(report)" #searchResult>
                        <ion-item [id]="report.indskr_coachingreportid">
                            <coaching-item [report]="report" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="teamSortBy"></coaching-item>
                            <!-- <ion-icon class="rightArrow" name="chevron-forward-outline" slot="end"></ion-icon> -->
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </ion-list>
                <ion-infinite-scroll *ngIf="filteredCoachingList.length > 0" (ionInfinite)="doInfinite($event)" threshold="30%">
                    <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ng-container>
            <ng-container *ngIf="searching">
                <ind-section-header [viewData]="searchSectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                <ind-no-search-result *ngIf="filteredList?.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                <ion-list class="coaching-list" *ngIf="filteredList.length > 0">
                    <ion-item-group tappable *ngFor="let report of filteredList" [ngClass]="{'itemSelected': coachingReportService.selectedReport.value && (report.offlineCoachingReportId ? (report.offlineCoachingReportId === coachingReportService.selectedReport.value.offlineCoachingReportId) : (report.indskr_coachingreportid === coachingReportService.selectedReport.value.indskr_coachingreportid))}"
                        (click)="openCoachingReportDetails(report)">
                        <ion-item>
                            <coaching-item [report]="report" [type]="coachingModel" [showTertiary]="showTertiaryFlag" [sortBy]="teamSortBy"></coaching-item>
                            <!-- <ion-icon class="rightArrow" name="chevron-forward-outline" slot="end"></ion-icon> -->
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </ion-list>
                <ion-infinite-scroll *ngIf="filteredCoachingList.length > 0" (ionInfinite)="doInfinite($event)" threshold="30%">
                    <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ng-container>
        </div>
    </div>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'CoachingList'"></footer-toolbar>
<!-- </ion-footer> -->

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)" [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive' (selectSavedSearch)="handleSavedSearchSelection($event)">
</search-suggestion-popover>
