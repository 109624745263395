import { ComponentViewMode } from './../../../services/ui/ui.service';
import { AssetSelectionViewDataModel } from './../../../models/meeting-assets-selection-model';
import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from '@omni/services/events/events.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, Input } from "@angular/core";
import { PopoverController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AssetTransfer } from "@omni/classes/field-materials/asset-transfer.class";
import { AssetCategory, CustomerAsset } from '@omni/classes/field-materials/customer-asset.class';
import { AssetDetailsComponent } from '@omni/components/field-material-management/asset-details/asset-details';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { SearchConfigService } from "@omni/services/search/search-config.service";
import { UIService } from '@omni/services/ui/ui.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AssetTransferDetailsComponent } from '../asset-transfer-details/asset-transfer-details';
import { AssetTrasferStatus } from './../../../classes/field-materials/asset-transfer.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { FieldMaterialManagementDataService } from '@omni/data-services/field-material-management/field-material-management.data.service';
import { format } from 'date-fns';

/**
 * Generated class for the FieldMaterialManagementListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'field-material-management-list',
  templateUrl: 'field-material-management-list.html',
  styleUrls: ['field-material-management-list.scss'],
})
export class FieldMaterialManagementListComponent {

  @Input() viewData: AssetSelectionViewDataModel;

  public myAssets: CustomerAsset[];
  public filteredAssets: CustomerAsset[];
  public filteredAssetsModel: MainCardViewDataModel[] = [];
  public filteredTransfers: AssetTransfer[] = [];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  tabsData: IndTabsDataModel[];
  assetSectionHeaderData: IndSectionHeaderViewDataModel;
  transferSectionHeaderData: IndSectionHeaderViewDataModel;
  public fieldMaterialModel = 'myAssets';
  searchText: string;
  public currentTab: string;
  private assetCategoryFilter: number = null;
  private transferStatusFilter: number = null;
  public searching = false;
  assetsFilterPopoverData: {
    text: string;
    value: string;
    items: any;
    handler: (selectedItem: any, item: any, parent: any) => void;
  }[];
  transfersFilterPopoverData: {
    text: string;
    value: string;
    items: any;
    handler: (selectedItem: any, item: any, parent: any) => void;
  }[];
  @Output() closeModal = new EventEmitter<string>();
  private ngDestroy$ = new Subject<boolean>();
  public compViewMode = ComponentViewMode;
  public isDirty: boolean = false;
  public selectedAssets: CustomerAsset[] = [];
  public selectedAssetsModel: MainCardViewDataModel[] = [];
  public selectedAssetsHeaderModel: IndSectionHeaderViewDataModel;
  private accessedFromProcedureLog: boolean = false;
  private accessedFromProcedureLogAssetBooking: boolean = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    private readonly popover: PopoverController,
    public _cd: ChangeDetectorRef,
    public searchConfigService: SearchConfigService,
    public fieldMaterialElement: ElementRef,
    private readonly uiService: UIService,
    private readonly device: DeviceService,
    private readonly events: EventsService,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthenticationService,
    private readonly navParams: NavParams,
    private readonly alertService: AlertService
  ) { }

  ngOnInit() {
    console.log(this.viewData);
    if (this.navParams.get('from')) {
      if(this.navParams.get('from') === 'procedure_log') {
        this.accessedFromProcedureLog = true;
      } else if(this.navParams.get('from') === 'procedure_log_asset_booking') {
        this.accessedFromProcedureLogAssetBooking = true;
      }
    }
    this.myAssets = this.fieldMaterialManagementService.customerAssets;
    if(this.accessedFromProcedureLogAssetBooking) {
      let fromDate = format(new Date(), 'YYYY-MM-DD');
      let endDate = format(new Date(), 'YYYY-MM-DD');
      if(this.viewData && this.viewData.procedureLogDate) {
        fromDate = format(this.viewData.procedureLogDate['fromDate'], 'YYYY-MM-DD');
        endDate = format(this.viewData.procedureLogDate['endDate'], 'YYYY-MM-DD');
      }
      this.uiService.displayLoader();
      Promise.all([
        this.fieldMaterialManagementService.getAssetsForAssetBooking(fromDate, endDate).then((assets) => {
          if(assets && assets.length>0) {
            this.myAssets =  this.myAssets.filter(myA => assets.some(a => a.msdyn_customerassetid == myA.msdyn_customerassetid));
          } else {
            this.myAssets = [];
          }      
        })
      ]).then(() => {
        this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers;
        this.filteredAssets = _.cloneDeep(this.myAssets);
        this.getCustomerAssetsHeirarchyModel();
        this.currentTab = this.fieldMaterialModel;
        this.initFieldMaterialHeaderLeft();
        this.setTabsData();
        this.initSectionHeaders();
        this._initAssetsFilterPopoverData();
        if (this.viewData.viewMode != this.compViewMode.LISTVIEW) {
          this.selectedAssets = _.isEmpty(this.viewData.selectedAssets) ? [] : _.cloneDeep(this.viewData.selectedAssets);
          this.initSelectedAssetsHeaderModel();
        }
        this.uiService.dismissLoader();
      })
    } else {
      if (this.accessedFromProcedureLog) {
        this.myAssets = this.myAssets .filter(e => e.indskr_assetstatus_value != 548910001 && e.indskr_assetstatus_value != 548910000);
      }
      if (!this.accessedFromProcedureLog) this.myAssets = this.myAssets .filter(ma => _.isEqual(ma.ownerid, this.authService.user.systemUserID));
      this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers;
      this.filteredAssets = _.cloneDeep(this.myAssets);
      this.getCustomerAssetsHeirarchyModel();
      this.currentTab = this.fieldMaterialModel;
      this.initFieldMaterialHeaderLeft();
      this.setTabsData();
      this.initSectionHeaders();
      this._initAssetsFilterPopoverData();
      if (this.viewData.viewMode != this.compViewMode.LISTVIEW) {
        this.selectedAssets = _.isEmpty(this.viewData.selectedAssets) ? [] : _.cloneDeep(this.viewData.selectedAssets);
        this.initSelectedAssetsHeaderModel();
      }
    }
    this.events.observe('assetTransferCancelled').pipe(takeUntil(this.ngDestroy$)).subscribe((data: AssetTransfer) => {
      if (data && data.indskr_assettransferid) {
        let asset = this.fieldMaterialManagementService.customerAssets.find(t => t.msdyn_customerassetid == data.asset_id);
        if (asset) asset.indskr_approvalrequired = asset.indskr_acknowledgerequired = false;
        this.fieldMaterialManagementService.assetTransfers = this.fieldMaterialManagementService.assetTransfers.filter(tr => tr.indskr_assettransferid != data.indskr_assettransferid);
        this.filteredTransfers = this.filteredTransfers.filter(tr => tr.indskr_assettransferid != data.indskr_assettransferid);
      }
      this.fieldMaterialManagementService.selectedAssetTransfer = null;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.FieldMaterialManagementPageComponent);
      this.navService.setChildNavRightPaneView(false);
    });
    this.events.observe('assetDeploymentCancelled').pipe(takeUntil(this.ngDestroy$)).subscribe((data: CustomerAsset) => {
      if (data && data.msdyn_customerassetid) {
        let asset = this.myAssets.find(t => t.msdyn_customerassetid == data.msdyn_customerassetid);
        if (asset) {
          let filtered = this.filteredAssets.find(t => t.msdyn_customerassetid == data.msdyn_customerassetid);
          asset.msdyn_account = asset.accountName = null;
          if (filtered) filtered.msdyn_account = filtered.accountName = null;
          this.getCustomerAssetsHeirarchyModel();
        }
      }
    });
  }

  private initSelectedAssetsHeaderModel() {
    this.getSelectedAssetsHeirarchyModel();
    let controls: any[] = [];
    let isHidden = this.viewData.viewMode == this.compViewMode.LISTVIEW || _.isEmpty(this.selectedAssetsModel);
    if (!isHidden) {
      controls.push({
        id: 'assets-clear',
        text: `${this.translate.instant('CLEAR')}`,
        isDisabled: false,
      });
    }
    this.selectedAssetsHeaderModel = {
      id: 'selectededitems',
      title: `${this.translate.instant('SELECTED')} (${this.selectedAssets.length})`,
      controls: controls
    }
    if (this.viewData.viewMode == this.compViewMode.SELECTION) {
      this.indHeaderLeftModel.controls.find(c => c.id == 'done').isDisabled = _.isEmpty(_.xorBy(this.viewData.selectedAssets, this.selectedAssets, 'msdyn_customerassetid'));
    }
  }

  private initFieldMaterialHeaderLeft(): void {
    let controls = [];
    if(this.viewData.viewMode == this.compViewMode.LISTVIEW) {
      controls.push({
        id: "home",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left"
      });
    } else {
      if(this.viewData.viewMode ==this.compViewMode.SELECTION || this.viewData.viewMode == this.compViewMode.READONLY){
        controls.push({
          id: "close",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: (this.viewData.viewMode===this.compViewMode.SELECTION || this.viewData.viewMode === this.compViewMode.READONLY) ?'seventyPercentWidth' : '',
          isDisabled: false,
          align: "left",
        });
      }
    }
    if (this.viewData.viewMode == this.compViewMode.SELECTION) {
      controls.push({
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: true,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'field-material-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('FIELD_MATERIAL_MANAGEMENT'),
      mode: true,
      controls: controls,
    };
  }

  private setTabsData() {
    this.tabsData = [
      {
        displayText: this.translate.instant('MY_ASSETS'),
        value: 'myAssets',
      },
      {
        displayText: this.translate.instant('TRANSFER_REQUESTS'),
        value: 'transferRequests',
      },
    ];
  }

  public checkFieldMaterialModel(value: string) {
    if (this.currentTab === value) return;
    this.searching = false;
    this.currentTab = value;
    this.clearFilters();
    if (value === 'myAssets') {
      this.searchText = this.translate.instant('SEARCH_MY_ASSETS');
      this._initAssetsFilterPopoverData();
    } else {
      this.searchText = this.translate.instant('SEARCH_TRANSFER_REQUESTS');
      this._initTransfersFilterPopoverData();
    }
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.FieldMaterialManagementPageComponent);
    this.fieldMaterialManagementService.selectedAsset = null;
    this.fieldMaterialManagementService.selectedAssetTransfer = null;
    this.initFieldMaterialHeaderLeft();
    this.updateEmptyMessage();
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'home':
      case 'close':
        this.onCloseModal(id);
        break;
      case 'done':
        if(this.accessedFromProcedureLogAssetBooking) {
          await this.checkAssetAvailabilityForAssetBooking().then(async (data) => {
            if(data) {
              let confirm = await this.confirmAssetBooking();
              if(confirm) {
                this.viewData.callbackFromSelectionComponent('DONEBUTTONCLICK', _.xorBy(this.viewData.selectedAssets, this.selectedAssets, 'msdyn_customerassetid'))
                this.onCloseModal(id);
              } else {
                return;
              }
            } else {
              return;
            }
          });
        } else {
          this.viewData.callbackFromSelectionComponent('DONEBUTTONCLICK', _.xorBy(this.viewData.selectedAssets, this.selectedAssets, 'msdyn_customerassetid'))
          this.onCloseModal(id);
        }
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private initSectionHeaders() {
    this.assetSectionHeaderData = {
      id: 'my-assets-header',
      title: `${this.searching ? this.translate.instant('RESULTS_CAP') :
        (this.accessedFromProcedureLog ? this.translate.instant('ALL_ASSETS') : this.translate.instant('MY_ASSETS'))} 
        (${this.filteredAssets.length})`,
      controls: [
        {
          id: 'assets-sort',
          text: this.translate.instant('NAME'),
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
        },
      ],
    };
    this.transferSectionHeaderData = {
      id: 'my-transfers-header',
      title: `${this.searching
          ? this.translate.instant('RESULTS_CAP')
          : this.translate.instant('TRANSFER_REQUESTS')
        } (${this.filteredTransfers.length})`,
      controls: [
        {
          id: 'transfers-sort',
          text: this.translate.instant('NAME'),
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
        },
      ],
    };
  }

  onCloseModal(event) {
    this.closeModal.emit(event);
  }

  onSectionHeaderControlClick(id: string) {
    // TODO: implement
    if (id === 'assets-sort') {
      console.log('sort');
      // this.sortCoachingList();
    }
    if (id === 'transfers-sort') {
      console.log('sort');
      // this.sortCoachingList();
    }
    switch (id) {
      case 'assets-clear':
        if (!_.isEmpty(this.selectedAssetsModel)) {
          this.selectedAssets.forEach(a => {
            let card = this.findInDepth(a.msdyn_customerassetid, this.filteredAssetsModel);
            card.endIconType = 'indegene-selectors-add-icon';
            card.endIconCssClass = 'add-icon';
          });
          this.selectedAssets = [];
          this.initSelectedAssetsHeaderModel();
        }
        break;
    
      default:
        break;
    }
  }

  private getCustomerAssetsHeirarchyModel() {
    const hashTable = Object.create(null)
    this.filteredAssets = _.orderBy(this.filteredAssets, obj => obj.msdyn_name);
    this.filteredAssets.forEach(aData => hashTable[aData.msdyn_customerassetid] = this.getMainCardDataModel(aData));
    const dataTree = []
    this.filteredAssets.forEach(aData => {
      if (aData.msdyn_parentasset && hashTable[aData.msdyn_parentasset]) {
        hashTable[aData.msdyn_parentasset].showIcon = true;
        hashTable[aData.msdyn_parentasset].noLeftMarginLabel = true;
        hashTable[aData.msdyn_parentasset].childItems.push((hashTable[aData.msdyn_customerassetid]));
        hashTable[aData.msdyn_parentasset].childItems = _.orderBy(hashTable[aData.msdyn_parentasset].childItems, obj => obj.primaryTextRight);
      } else {
        dataTree.push(hashTable[aData.msdyn_customerassetid]);
      }
    })
    this.filteredAssetsModel = dataTree;
  }

  private getSelectedAssetsHeirarchyModel() {
    const hashTable = Object.create(null)
    this.selectedAssets = _.orderBy(this.selectedAssets, obj => obj.msdyn_name);
    this.selectedAssets.forEach(aData => hashTable[aData.msdyn_customerassetid] = this.getSelectedMainCardDataModel(aData));
    const dataTree = []
    this.selectedAssets.forEach(aData => {
      if (aData.msdyn_parentasset && hashTable[aData.msdyn_parentasset]) {
        hashTable[aData.msdyn_parentasset].showIcon = true;
        hashTable[aData.msdyn_parentasset].noLeftMarginLabel = true;
        hashTable[aData.msdyn_parentasset].childItems.push((hashTable[aData.msdyn_customerassetid]));
        hashTable[aData.msdyn_parentasset].childItems = _.orderBy(hashTable[aData.msdyn_parentasset].childItems, obj => obj.primaryTextRight);
      } else {
        dataTree.push(hashTable[aData.msdyn_customerassetid]);
      }
    })
    this.selectedAssetsModel = dataTree;
  }

  private getCustomerAssetsModel() {
    this.filteredAssets = _.orderBy(this.filteredAssets, obj => obj.msdyn_name);
    this.filteredAssetsModel = this.filteredAssets.map(asset => {
      return this.getMainCardDataModel(asset);
    })
  }

  public getsecondaryInfo(asset) {
    const accountName = _.isEmpty(asset.accountName) ? this.translate.instant('NO_ACCOUNT') : asset.accountName;
    return [asset.categoryName, accountName].filter(Boolean).join(", ");
  }

  private getSelectedMainCardDataModel(asset: CustomerAsset) {
    let viewData: MainCardViewDataModel;
    const accountName = _.isEmpty(asset.accountName) ? this.translate.instant('NO_ACCOUNT') : asset.accountName
    viewData = {
      id: asset.msdyn_customerassetid,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: asset.msdyn_name,
      secondaryTextRight: [asset.categoryName, accountName].filter(Boolean).join(", "),
      secondaryTextRightTwo: '',
      isMainToolTemplate: true,
      isSelected: this.fieldMaterialManagementService.selectedAsset?.msdyn_customerassetid == asset.msdyn_customerassetid,
      childItems: [],
      iconName: 'chevron-forward-outline',
      isExpanded: false,
      isNotAutoExpanded: true,
      parentAsset: asset.msdyn_parentasset,
      clickHandler: (id: string, event, specificTarget, refData) => {
        event?.stopPropagation();
        if (specificTarget == 'startIcon') {
          let card = this.findInDepth(id, this.selectedAssetsModel);
          card.isExpanded = !card.isExpanded;
          card.iconName = card.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
        } else if (specificTarget == 'endIcon') {
          this.handleAssetRemoval(id);
        } else {
          this.viewAssetDetails(id);
        }
      },
    };
    if (this.viewData.viewMode == ComponentViewMode.SELECTION) {
      viewData.showEndIcon = true;
      viewData.endIconType = 'indegene-selectors-remove-icon';
      viewData.endIconCssClass = 'remove-icon';
      viewData.mainItemCssClass = 'selector-item';
    }
    return viewData;
  }

  private getMainCardDataModel(asset: CustomerAsset) {
    let viewData: MainCardViewDataModel;
    const accountName = _.isEmpty(asset.accountName) ? this.translate.instant('NO_ACCOUNT') : asset.accountName
    viewData = {
      id: asset.msdyn_customerassetid,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: asset.msdyn_name,
      secondaryTextRight: [asset.categoryName, accountName].filter(Boolean).join(", "),
      secondaryTextRightTwo: '',
      isMainToolTemplate: true,
      isSelected: this.fieldMaterialManagementService.selectedAsset?.msdyn_customerassetid == asset.msdyn_customerassetid,
      childItems: [],
      iconName: 'chevron-forward-outline',
      isExpanded: false,
      isNotAutoExpanded: true,
      parentAsset: asset.msdyn_parentasset,
      clickHandler: (id: string, event, specificTarget, refData) => {
        event?.stopPropagation();
        if (specificTarget == 'startIcon') {
          let card = this.findInDepth(id, this.filteredAssetsModel);
          card.isExpanded = !card.isExpanded;
          card.iconName = card.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline';
        } else if (specificTarget == 'endIcon') {
          this.handleAssetAddition(id);
        } else {
          this.viewAssetDetails(id);
        }
      },
    };
    if (this.viewData.viewMode == ComponentViewMode.SELECTION) {
      const isSelected = !_.isEmpty(this.viewData.selectedAssets) && this.viewData.selectedAssets.findIndex(a => a?.msdyn_customerassetid == asset.msdyn_customerassetid) > -1;
      viewData.showEndIcon = true;
      viewData.endIconType = isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
      viewData.endIconCssClass = isSelected ? 'checkmark-icon' : 'add-icon';
      viewData.mainItemCssClass = 'selector-item';
    }
    return viewData;
  }

  private handleAssetAddition(id: string) {
    let card = this.filteredAssetsModel.find(a => a.id == id);
    if (!card) card = this.findInDepth(id, this.filteredAssetsModel);
    this.changeIcon(card, 'checkmark-icon');
    this.addAssettoSelectedList(card)
    if (card.childItems) {
      const childCards = this.flattenTree(card.childItems);
      childCards.forEach(c => this.addAssettoSelectedList(c));
    }
    this.initSelectedAssetsHeaderModel();
  }

  private addAssettoSelectedList(card) {
    const asset = this.filteredAssets.find(as => as.msdyn_customerassetid == card.id);
    if (!this.selectedAssets.some(as => as.msdyn_customerassetid == card.id)) {
      this.selectedAssets.push(asset);
    }
  }

  public handleAssetRemoval(id) {
    let card = this.findInDepth(id, this.filteredAssetsModel);
    if (card) {
      this.changeIcon(card, 'add-icon');
      this.removeAssetFromSelected(card);
      if (card.childItems) {
        const childCards = this.flattenTree(card.childItems);
        childCards.forEach(c => this.removeAssetFromSelected(c));
        this.initSelectedAssetsHeaderModel();
      }
    }
  }

  private removeAssetFromSelected(card) { 
    const idx = this.selectedAssets.findIndex(a => a.msdyn_customerassetid == card.id);
    if (idx > -1) {
      this.selectedAssets.splice(idx, 1);
    }
  }

  private changeIcon(card, icon: string, collapse?: boolean) {
    const changeIconForCard = (card, icon, collapse) => {
      card.endIconType = 'indegene-selectors-' + icon;
      card.endIconCssClass = icon;
      if (collapse) {
        card.isExpanded = false;
        card.iconName = 'chevron-forward-outline'
      }
    }
    changeIconForCard(card, icon, collapse);
    if (!_.isEmpty(card.childItems)) {
      let allChild = this.flattenTree(card.childItems);
      allChild.forEach(ca => changeIconForCard(ca, icon, collapse));
    }
  }

  private findInDepth(id: string, array: any[]) {
    let result;
    array.some(o => o.id === id && (result = o) || (result = this.findInDepth(id, o.childItems || [])));
    return result;
  }

  private flattenTree(childItems) {
    return childItems.flatMap( child => _.isEmpty(child.childItems) ? child : [child, ...this.flattenTree(child.childItems)]);
  }

  public getMainCardDataModelForTransfer(transfer: AssetTransfer) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: transfer.indskr_assettransferid,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: transfer.indskr_assetname,
      secondaryTextRight: transfer.indskr_name,
      secondaryTextRightTwo: '',
      isSelected: this.fieldMaterialManagementService.selectedAssetTransfer?.indskr_assettransferid == transfer.indskr_assettransferid,
      arrowType: 'chevron-forward-outline',
      clickHandler: async (id: string, event, specificTarget) => {
        let transferRequest: AssetTransfer = this.fieldMaterialManagementService.assetTransfers.find(at => at.indskr_assettransferid == id);
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          transferRequest = await this.fieldMaterialManagementService.getAssetTransferById(id);
          this.uiService.dismissLoader();
        }
        console.log('Selected Transer', transferRequest);
        this.fieldMaterialManagementService.selectedAssetTransfer = transferRequest;
        if (!transferRequest) {
          this.notificationService.notify(this.translate.instant('ASSET_TRANSFER_ALREADY_DELETED'), 'Account Info', 'top', ToastStyle.DANGER, 3000);
          this.filteredTransfers = this.filteredTransfers.filter(tr => tr.indskr_assettransferid != id);
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.FieldMaterialManagementPageComponent);
          this.navService.setChildNavRightPaneView(false);
          return;
        }
        await this.navService.setChildNavRoot(AssetTransferDetailsComponent, PageName.AssetTransferDetailsComponent, PageName.FieldMaterialManagementPageComponent, { transfer: transferRequest });
        this.navService.setChildNavRightPaneView(true);
      },
    };
    return viewData;
  }

  async viewAssetDetails(id: string) {
    let asset = this.fieldMaterialManagementService.customerAssets.find(a => a.msdyn_customerassetid == id);
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
      asset = await this.fieldMaterialManagementService.getAssetDeltaById(id);
      this.uiService.dismissLoader();
    }
    this.fieldMaterialManagementService.selectedAsset = asset;
    await this.navService.setChildNavRoot(AssetDetailsComponent, PageName.AssetDetailsComponent, PageName.FieldMaterialManagementPageComponent, { selectedAsset: asset });
    this.navService.setChildNavRightPaneView(true);
    this.filteredAssets.forEach(fa => {
      const card = this.findInDepth(fa.msdyn_customerassetid, this.filteredAssetsModel);
      if (card) {
        if (this.fieldMaterialManagementService.selectedAsset.msdyn_customerassetid == fa.msdyn_customerassetid) {
          card.isSelected = true;
          const accountName = _.isEmpty(asset.accountName) ? this.translate.instant('NO_ACCOUNT') : asset.accountName;
          card.secondaryTextRight = [asset.categoryName, accountName].filter(Boolean).join(", ");
        } else {
          card.isSelected = false;
        }
      }
    })
    await this.uiService.dismissLoader();
  }

  async openFilters(event) {
    if (this.currentTab === 'myAssets') {
      const assetsFilter = await this.popover.create({
        component: MultiSelectPopover,
        componentProps: { root: this.assetsFilterPopoverData },
        event,
      });
      assetsFilter.present();
      assetsFilter.onDidDismiss().then(() => { });
    } else {
      const transfersFilter = await this.popover.create({
        component: MultiSelectPopover,
        componentProps: { root: this.transfersFilterPopoverData },
        event,
      });
      transfersFilter.present();
      transfersFilter.onDidDismiss().then(() => { });
    }
  }

  private _initAssetsFilterPopoverData() {
    this.assetsFilterPopoverData = [
      {
        text: '',
        value: '',
        items: [{ text: this.translate.instant('ALL_MY_ASSETS'), value: '0' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.clearFilters();
          this.updateEmptyMessage();
        },
      },
      {
        text: this.translate.instant('CATEGORY'),
        value: '',
        items: [
          {
            text: this.translate.instant('DEMO_ASSET'),
            value: AssetCategory.DEMO,
          },
          {
            text: this.translate.instant('STAND_BY_ASSET'),
            value: AssetCategory.STAND_BY,
          },
          {
            text: this.translate.instant('LOANER_ASSET'),
            value: AssetCategory.LOANER,
          },
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value =
            item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.assetCategoryFilter = item.value;
            this.filterByCategory(item.value);
          }
          this.updateEmptyMessage();
        },
      },
    ];
    this.assetsFilterPopoverData[0].value = '0';
  }

  private _initTransfersFilterPopoverData() {
    this.transfersFilterPopoverData = [
      {
        text: '',
        value: '',
        items: [{ text: this.translate.instant('ALL_TRANSFER_REQUESTS'), value: '0' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.clearFilters();
          this.updateEmptyMessage();
        },
      },
      //TODO: update filter
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          {
            text: this.translate.instant('PENDING_TRANSFER_REQS'),
            value: AssetTrasferStatus.Approved,
          },
          {
            text: this.translate.instant('SUCCESSFUL_TRANSFER_REQS'),
            value: AssetTrasferStatus.Completed,
          },
          {
            text: this.translate.instant('REJECTED_TRANSFER_REQS'),
            value: AssetTrasferStatus.Rejected,
          }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value =
            item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.transferStatusFilter = item.value;
            this.filterByStatus(item.value);
          }
          this.updateEmptyMessage();
        },
      },
    ];
    this.transfersFilterPopoverData[0].value = '0';
  }

  private filterByCategory(category: number) {
    if (!_.isEmpty(this.myAssets)) {
      if (this.assetCategoryFilter != null) {
        this.filteredAssets = this.myAssets.filter(
          (asset) => asset.indskr_assetscategory == category
        );
      } else {
        this.filteredAssets = this.myAssets;
      }
    } else {
      this.filteredAssets = [];
    }
    this.getCustomerAssetsHeirarchyModel();
    this.initSectionHeaders();
  }

  private filterByStatus(status: number) {
    const filterCondition = status === AssetTrasferStatus.Approved ? ((tr) => tr.statuscode == status || tr.statuscode == AssetTrasferStatus.Initiated) : (tr) => tr.statuscode == status;
    if (!_.isEmpty(this.fieldMaterialManagementService.assetTransfers)) {
      if (this.transferStatusFilter != null) {
        this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers.filter(filterCondition);
      } else {
        this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers;
      }
    } else {
      this.filteredTransfers = [];
    }
    this.initSectionHeaders();
  }

  private clearFilters() {
    this.filteredAssets = this.myAssets;
    this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers;
    this.assetCategoryFilter = null;
    this.transferStatusFilter = null;
    this.getCustomerAssetsHeirarchyModel();
    this.initSectionHeaders();
  }

  showVectorImage(): boolean {
    if (this.searching) {
      if (this.fieldMaterialModel === 'myAssets') {
        return this.filteredAssets.length === 0;
      } else {
        return this.filteredTransfers.length === 0;
      }
    } else {
      if (this.fieldMaterialModel === 'myAssets') {
        return this.myAssets.length === 0;
      } else {
        return this.fieldMaterialManagementService.assetTransfers.length === 0;
      }
    }
    return false;
  }

  onInput(eventDetail) {
    const searchTest = eventDetail.target ? eventDetail.target.value : '';
    this.searching = searchTest.length;
    if (this.currentTab == 'myAssets') {
      this.handleMyAssetsSearch(searchTest);
    } else {
      this.handleTransferRequestsSearch(searchTest);
    }
  }

  private async handleMyAssetsSearch(searchText: string) {
    if (searchText && searchText.trim().length > 0) {
      const formattedSearchText = this.searchConfigService
        .convertFormattedString(searchText)
        .toLowerCase();
      this.filteredAssets = this.myAssets.filter((c) => {
        if (c.msdyn_name) {
          const assetName = this.searchConfigService
            .convertFormattedString(c.msdyn_name)
            .toLowerCase();
          return (
            assetName && assetName.includes(formattedSearchText)
          );
        }
      });
      this.getCustomerAssetsModel();
    } else {
      this.filteredAssets = this.myAssets;
      this.getCustomerAssetsHeirarchyModel();
    }
    this.initSectionHeaders();
    this.updateEmptyMessage();
  }

  private async handleTransferRequestsSearch(searchText: string) {
    if (searchText && searchText.trim().length > 0) {
      const formattedSearchText = this.searchConfigService
        .convertFormattedString(searchText)
        .toLowerCase();
      this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers.filter((c) => {
        if (c.indskr_name) {
          const transferName = this.searchConfigService
            .convertFormattedString(c.indskr_name)
            .toLowerCase();
          return (
            transferName && transferName.includes(formattedSearchText)
          );
        }
      });
    } else {
      this.filteredTransfers = this.fieldMaterialManagementService.assetTransfers;
    }
    this.initSectionHeaders();
    this.updateEmptyMessage();
  }

  private updateEmptyMessage() {
    let dataSize = 0;
    if (this.currentTab == "myAssets") {
      dataSize = this.filteredAssets.length;
    } else {
      dataSize = this.filteredTransfers.length;
    }
    this.uiService.updateNothingSelectedScreenMessageFor(dataSize)
  }

  ionSearchFocus(ev) {
    try {
      let el = null;
      if (this.currentTab == 'myAssets') {
        el = this.fieldMaterialElement.nativeElement.ownerDocument.getElementById('my-assets-header');
      } else {
        el = this.fieldMaterialElement.nativeElement.ownerDocument.getElementById('my-transfers-header');
      }
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  ngOnDestroy() {
    this.fieldMaterialManagementService.selectedAsset = null;
    this.fieldMaterialManagementService.selectedAssetTransfer = null;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private async isAssetAlreadyInUseForAssetBooking(): Promise<any> {
    if(this.device.isOffline) return;
    let fromDate = this.viewData.procedureLogDate['fromDate'].toLocaleDateString();
    let endDate = this.viewData.procedureLogDate['endDate'].toLocaleDateString();
    const assetInUseIds = await this.fieldMaterialManagementService.checkAssetAvailabilityForAssetBooking(fromDate, endDate);
    return assetInUseIds;
  }

  private async checkAssetAvailabilityForAssetBooking(): Promise<boolean> {
    let selectedAssets = _.xorBy(this.viewData.selectedAssets, this.selectedAssets, 'msdyn_customerassetid');
    const assetInUseIds = await this.isAssetAlreadyInUseForAssetBooking();
    if(assetInUseIds){
      let unavailableAssets = selectedAssets.filter((selectedAsset) => assetInUseIds.some(id => id.msdyn_customerassetid == selectedAsset.msdyn_customerassetid));
      if (assetInUseIds.length > 0 && !_.isEmpty(unavailableAssets)) {
          let assetNames = unavailableAssets.map(ass => ass.msdyn_name);
          await this.alertService.showAlert({
              header: this.translate.instant('ASSET_REMOVE_TITLE'),
              message: this.translate.instant('ASSET_IN_USE_FOR_ASSET_BOOKING', {asset: assetNames.join(', ')}),
              buttons:[{text:this.translate.instant('OK'), role:"ok"}]
            },
            ).then(async res => {
              if (res.role === 'ok') {
                unavailableAssets.forEach(ua => {
                  this.handleAssetRemoval(ua.msdyn_customerassetid);
                })
              }
              return false;
            }).catch((err) => console.log('catch event ! ' + err));
      } else {
        return true;
      }
    }else {
      return true;
    }

  }

  private async confirmAssetBooking(): Promise<boolean> {
    let isValid = false;
    await this.alertService.showAlert({
      header: this.translate.instant('POP_CONFIRM_SUBMIT_POLL'),
      message: this.translate.instant('POP_CONFIRM_ASSET_BOOKING')
    }, this.translate.instant('CONFIRM'),  this.translate.instant('CANCEL')
    ).then(async res => {
      if (res.role === 'ok') {
        isValid = true;
      }
    }).catch((err) => {
      console.log('catch event ! ' + err);
    });
    return isValid;
  }
}
