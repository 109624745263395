import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { NothingSelectedView } from '../../components/shared/nothing-selected-view/nothing-selected-view';


export enum ChildNavNames {
  AccountManagementNavigation = 'accountmanagementrightpane',
  ActivitiesPageNavigation = 'activitiespagerightpane',
  ContactPageNavigation = 'contactpagerightpane',
  AccountPageNavigation = 'accountpagerightpane',
  ScientificActivityNavigation = 'scientificactivityrightpane',
  PresentationToolsNavigation = 'presentationtoolsrightpane',
  OrderManagementNavigation = 'ordermanagementrightpane',
  AppsettingNavigation = 'appsettingspagerightpane',
  OpportunityManagementNavigation = 'opportunitymanagementrightpane',
  MeetingPresentationNavigation = 'meetingpresentationpane',
  MainToolTemplateNavigation = 'maintooltemplaterightpane',
  MarketScanNavigation = 'marketscanrightpane',
  EventsToolNavigation = 'eventtoolsrightpane',
  ResourceToolNavigation = 'resourcerightpane',
  TemplatePreviewNavigation = 'templatepersonalizepagerightpane',
  EmailTemplateNavigation = "emailtemplaterightpane",
  CallPlanPageNavigation = 'callPlanPageRightPane',
  CalendarInviteTemplateNavigation = 'calendarinvitetemplatenavigation',
  CoachingActivitiesNavigation = 'coachingactivitiesrightpane',
  CoachingDetailsNavigation = 'coachingdetailsrightpane',
  CustomerAllocationsToolNavigation = 'customerallocationtoolrightpane',
  OpportunitySelectionNavigation = 'opportunityselectionrightpane',
  EventSelectionNavigation = 'eventselectionrightpane',
  FieldMaterialManagementNavigation = 'fieldmaterialrightpane',
  AccountPlanActivitiesNavigation = "accountplanactivitiesrightpane",
  MarketingPlanNavigation="marketingplanrightpane",
  QuotesNavigation="quotesrightpane",
  PharmacovigilanceNavigation = 'pharmacovigilancerightpane',
  TerritoryNavigation="territoryrightpane",
  KOLStatusPageNavigation = 'kolstatuspagerightpane',
  SurveyNavigation = 'surveyRightpane',
  MedicalInsightPageNavigation = 'medicalinsightpagenavigation',
  SurgeryContractNavigation = 'procedurecontractpane'
}








export enum PageName {
  ActivitiesPageComponent = 1,
  ActivitiesDetailsPaneComponent = 2,
  ScientificActivityPage = 3,
  ScientificActivityList = 4,
  ScientificActivityDetail = 5,
  ScientificPlanFollowUpTask = 6,
  AppSettingsDetailsComponent = 7,
  NewActivityComponent = 8,
  AppSettingsPageComponent = 9,
  AccountPageComponent = 10,
  AccountDetailsComponent = 11,
  AccountListRoComponent = 12,
  CallPlanComponent = 14,
  CallPlanDetails = 15,
  CallPlanCustDetailsComponent = 16,
  CoachingComponent = 17,
  CoachingDetailsComponent = 18,
  CoachingForSelectComponent = 19,
  CoachingActivitiesComponent = 20,
  ContactPageComponent = 21,
  ContactDetailsComponent = 22,
  ContactListROComponent = 23,
  ContactNewAddressComponent = 24,
  ContactAcountAffiliationComponent = 25,
  PresentationPageComponent = 26,
  PresentationPreviewComponent = 27,
  PresentationMeetingComponent = 28,
  PresentationFullscreenComponent = 29,
  MyAssistantComponent = 30,
  RemoteURLEmailTemplate = 31,
  LocationComponent = 32,
  SettingsComponent = 33,
  TimeOffComponent = 34,
  TimeOffDetailsComponent = 35,
  ResourceDetailsComponent = 36,
  ResourcePageComponent = 37,
  NewTimeOffComponent = 38,
  ToolsDrawer = 39,
  SchedulerDetailComponent = 40,
  NewSampleActivityComponent = 41,
  AllocationComponent = 42,
  AllocationOrdersList = 43,
  ContactAllocationEligibilitiesComponent = 44,
  IOMultiSelect = 45,
  CaptureAllocationOrderComponent = 46,
  SchedulerPopoverComponent = 47,
  CallNotesAssistantComponent = 49,
  ChildUsersPageComponent = 50,
  ConsentDetailsComponent = 51,
  EmailTemplatePageComponent = 52,
  EmailDetailsPageComponent = 53,
  AccountManagementPageComponent = 54,
  AccountPlanListComponent = 55,
  AccountPlanDetailsComponent = 56,
  AccountObjectiveDetailsComponent = 57,
  PlanProgressReportComponent = 58,
  FollowUpActionDetailComponent = 59,
  NothingSelectedView = 60,
  TemplatePersonalizePageComponent = 61,
  ResourceInteractionDetailComponent = 62,
  CaseManagementHomePage = 63,
  CaseManagementDetailComponent = 64,
  InMeetingCasesComponent = 65,
  OrderManagementPageComponent = 66,
  OrdersListComponent = 67,
  OrderDetailsComponent = 68,
  OrderAddQuantityComponent = 6,
  SelectListComponent = 70,
  CustomerXperiencesComponent = 71,
  CustomerInsightComponent = 72,
  CustomerJourneyComponent = 73,
  CustomerRelationshipComponent = 74,
  CustomerInterestComponent = 75,
  RelationshipListComponent = 76,
  LanguageSettingsComponent = 77,
  GeneralSettingsComponent = 78,
  AboutSettingsComponent = 79,
  DateFormatsPage = 80,
  LanguageListComponent = 81,
  OpportunityManagementPage = 82,
  OpportunitiesListComponent = 83,
  OpportunityDetailsComponent = 84,
  OpportunityInfoComponent = 85,
  OpportunityQuotesComponent = 86,
  OpportunityAgreementsComponent = 87,
  OpportunityOrdersComponent = 88,
  OpportunityQuoteDetailsComponent = 89,
  NewOpportunityComponent = 90,
  IndRightpaneReadonlyComponent = 91,
  MarketingEmailDetailsComponent = 92,
  AccountPlanOpportunityListComponent = 93,
  AboutDetailsComponent = 94,
  MainToolTemplateComponent = 95,
  AccountFormComponent = 96,
  InisghtsPlusComponent = 97,
  GeneralInsightsPlusComponent = 98,
  DynamicFormComponent = 99,
  CaptureCustomerInquireComponent = 100,
  GeneeInfoComponent = 101,
  PhoneCallDetailsComponent = 102,
  ContactConsentCaptureComponent = 103,
  PlansPredictionsComponent = 104,
  JourneyInsightsComponent = 105,
  InterestInsightsComponent = 106,
  RelationshipInsightsComponent = 107,
  MorePlansInsightsComponent = 108,
  GeneeNotificationComponent = 109,
  MarketScanPageComponent = 110,
  MarketScanDetailsComponent = 111,
  EventsToolPageComponent = 112,
  EventsListComponent = 113,
  EventDetailsPageComponent = 114,
  CaptureEventsComponent = 115,
  PdfPreviewComponent = 116,
  ParticipantListComponent = 117,
  TemplatePreviewComponent = 118,
  ReplacementTokenListComponent = 119,
  EmailTemplateDetailsComponent = 120,
  NewChangeRequestComponent = 121,
  CrDetailsComponent = 122,
  AccountCrDetailsComponent = 123,
  EdgeAnalyticsListComponent = 124,
  EdgeAnalyticsDetailComponent = 125,
  PlansListComponent = 126,
  ImpactDetailsComponent = 127,
  TrendingCustomersComponent = 128,
  SurgeryOrderDetailsComponent = 129,
  CalendarInviteComponent = 130,
  CalendarInviteTemplatePageComponent = 131,
  CalendarInviteTemplateListComponent = 132,
  ContactTimelineComponent = 133,
  AccountTimelineComponent = 134,
  ScientificActivityTimeline = 135,
  OpportunityCollaboratorsComponent = 136,
  ActivitySkeletonPaneComponent = 137,
  AllocationShipmentDetailsComponent = 138,
  AffiliationExplorerComponent = 139,
  NotificationListComponent = 140,
  SurveyComponent = 141,
  NotificationDetailsComponent = 143,
  CustomerAssessComponent = 144,
  SectionSpecificComponent = 145,
  AppSettingsRecommendationsComponent = 146,
  OpportunitySelectionPage = 147,
  NewAccountPlanComponent = 148,
  ProcedureTrackerDetailsComponent = 149,
  ProcedureTrackerComponent = 150,
  ExpensesDetails = 151,
  FieldMaterialManagementPageComponent = 152,
  EventNotesComponent = 153,
  AssetDetailsComponent = 154,
  AssetTransferDetailsComponent = 155,
  AccountPlanActivities = 156,
  MarketScanEditPageComponent = 157,
  PresentationDetailComponent = 158,
  EventParticipantComponent = 159,
  MarketingPlanManagementPageComponent = 160,
  MarketingPlanDetails = 161,
  CustomerAvailabilityComponent = 162,
  UserInventoryListComponent = 163,
  UserInventoryDetailComponent = 164,
  QuotePageComponent = 165,
  QuoteDetailsComponent = 166,
  CustomerCallPlanPage = 167,
  CustomerCallPlanDetails = 168,
  NewQuoteComponent = 169,
  QuoteProductsComponent = 170,
  CustomerCallPlanSuggest = 171,
  IndFilterMenuComponent = 172,
  PharmacovigilancePage = 173,
  PharmacovigilanceList = 174,
  PharmacovigilanceDetail = 175,
  CustomNotificationDetails = 176,
  CustomerAssessTeamviewComponent = 177,
  KOLStatusPageComponent = 178,
  KOLDetailsComponent = 179,
  KOLStatusListComponent = 180,
  ContactMedicalInsightComponent = 181,
  TerritoryManagementPageComponent = 182,
  TerritoryManagementDetailsComponent = 183,
  SurveyPageComponent = 184,
  SurveyListComponent = 185,
  SurveyDetailsComponent = 186,
  SurveyPreviewComponent = 187,
  SurveyListCardviewComponent = 188,
  MedicalInsightPageComponent = 189,
  EventAttendiesListComponent = 190,
  Contact360DetailsComponent = 191,
  PersonalizedQuickGlanceViewComponent = 192,
  ContactTimelinePageComponent = 133,
  AccountTimelinePageComponent = 134,
  ContactOmnichannelEventsComponent = 135,
  StoreCheckDetailsComponent = 193,
  IndPhotoCaptureViewerComponent = 194,
  SurgeryOrderContractDetailsComponent = 138,
  IndPhotoViewerComponent = 195,
  ContactSentimentHistoryListComponent = 196,
  MeetingSentimentHistoryDetailComponent = 197,
  AppealDetailsComponent = 198,
  SurgeryOrderContractComponent = 199,
  SurgeryOrderContractListComponent = 200,
  SurveyHistoryComponent = 201,
  SetBookingActivityDetailsComponent = 202,
  ShortCallHomeComponent = 205,
  DisplayFormComponent = 206,
  MainToolTemplateDetailsComponent = 207,
  ContactOmnichannelOpportunitiesComponent =208
}
export interface PageStack {
    pageName: PageName,
    masterPageName: PageName
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
    private _pageStack: Array<PageStack> = [];
    private _activeChildNavName:ChildNavNames;
    private _defaultNav: HTMLIonNavElement;
    private _rightPaneNavCtrlStack: Array<{
        navCtrl:IonNav,
        navName:ChildNavNames,
        isRightPaneViewActive: boolean,
        navPageStack:Array<PageStack>,
    }> =  [];
    public isModalViewOpen: boolean = false;
    private _currentPageStack$: BehaviorSubject<PageStack[]> = new BehaviorSubject(this._pageStack);
    readonly currentPageStack$: Observable<PageStack[]> = this._currentPageStack$.asObservable().pipe(debounceTime(0));

    public childNavPopObserver = new Subject<PageName>();

    constructor() {
    }

    initStack(homeNavCtrl: HTMLIonNavElement) {
        this._pageStack = [{ pageName: PageName.ActivitiesPageComponent, masterPageName: PageName.ActivitiesPageComponent }];
        this._defaultNav = homeNavCtrl;//this.appCtrl.getActiveNavs()[0];
        this._currentPageStack$.next(this._pageStack);
    }

    /**
     * Push a page with the name of page which will be tracked by '_pageStack' property
     * so that we can keep track the pushed page stack.
     * We assume that we are using this service within the scope of the home.ts and since
     * we use ion-nav that takes 'ActivitiesPageComponent' as root, we will use that navCtrl
     * for navigation rather than the root navCtrl which has 'HomePage' as root.
     * getActiveNavs() returns array of active navs other than the root nav and it is a recommended method to
     * get an active nav according to the ionic console warning, however there is not enough documentation regarding this as of yet.
     * Since our current acrhitecture utilizes only one ion-nav, we assume that the getAcitveNavs() will return an array with only one nav.
     * Leaving 'navCtrl' as optional argument just in case we need to use other navCtrl.
     * @param pageComponentToPush
     * @param newPageName
     * @param params
     * @param options
     * @param navCtrl
     */
    pushWithPageTracking(pageComponentToPush: any, newPageName: PageName, params?: any, masterPageName?: PageName, options: any = {animate: false}, navCtrl?: IonNav): Promise<any> {
        const activeNav = navCtrl ? navCtrl : this._defaultNav;
        if (activeNav) {
            this._pushToStack({ pageName: newPageName, masterPageName: masterPageName ? masterPageName : this.getCurrentMasterPageName() });
            //console.log('%%% curPageStack after push: ', this._pageStack);
            return navCtrl ? navCtrl.push(pageComponentToPush, params, options) : this._defaultNav.push(pageComponentToPush, params, options);
        } else {
            return Promise.reject('NavigationService: pushWithPageTracking: Nav controller undefined');
        }
    }

    popWithPageTracking(options: any = {animate: false}, navCtrl?: IonNav): Promise<any> {
        const activeNav = navCtrl ? navCtrl : this._defaultNav;
        if (activeNav) {
          if(activeNav.canGoBack() && this._pageStack.length > 1) {
            this._popFromStack();
            //console.log('%%% curPageStack after pop: ', this._pageStack);
            return activeNav.pop(options);
          }
        } else {
            return Promise.reject('NavigationService: popWithPageTracking: Nav controller undefined');
        }
    }

    popToRootWithPageTracking(options: any = {animate: false}, navCtrl?: IonNav): Promise<any> {
        const activeNav = navCtrl ? navCtrl : this._defaultNav;
        if (activeNav) {
            this.initStack(this._defaultNav);
            //console.log('%%% curPageStack after popToRoot: ', this.getCurrentPageStack());
            return navCtrl ? navCtrl.popToRoot(options) : this._defaultNav.popToRoot(options);
        } else {
            return Promise.reject('NavigationService: popToRootWithPageTracking: Nav controller undefined');
        }
    }

    private getActiveChildNav(){
        return this._rightPaneNavCtrlStack[this._rightPaneNavCtrlStack.length - 1];
    }


    public popChildNavCtrlFromStack(childNavName:ChildNavNames):Promise<any>{
        return new Promise(async (resolve,reject)=> {
            if(this._activeChildNavName){
                if(childNavName == this._activeChildNavName){
                    this._rightPaneNavCtrlStack.pop();
                    this._activeChildNavName = this._rightPaneNavCtrlStack[this._rightPaneNavCtrlStack.length -1].navName;
                    resolve('');
                }else{
                    reject('Please pop the current active child nav first');
                }
            }else{
                reject('No active child nav to pop');
            }
        });
    }

    public initRightPaneChildNav (nav:IonNav,childNavName:ChildNavNames,masterPageName:PageName,rightPaneNavFlag?:boolean, data?: any):void {
        this._rightPaneNavCtrlStack.push({
            navCtrl: nav,
            navName: childNavName,
            isRightPaneViewActive: rightPaneNavFlag,
            navPageStack: [],
        })
        this._activeChildNavName = childNavName;
        if (data) {
        this.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,masterPageName,data);
        } else {
          this.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,masterPageName);
        }
    }

    public setChildNavRightPaneView(flag:boolean){
        this.getActiveChildNav().isRightPaneViewActive = flag;
    }

    public get isActiveChildNavRightPaneViewDisplayed():boolean {
        if(this.getActiveChildNav){
            return this.getActiveChildNav().isRightPaneViewActive;
        }else{
            return false;
        }
    }

    public setChildNavRoot (pageToSet:any, newPageName:PageName, masterPageName?:PageName, data?:any, options: any = {animate: false}) : Promise<any> {
        if(this.getActiveChildNav()){
            if(!masterPageName){
                masterPageName = this.getActiveChildNav().navPageStack[0].masterPageName;
            }
            this.getActiveChildNav().navPageStack = [];
            this.getActiveChildNav().navPageStack.push({
                pageName: newPageName,
                masterPageName: masterPageName,
            });
            return this.getActiveChildNav().navCtrl.setRoot(pageToSet, data,options);
        }else{
            return Promise.reject('Invalid child nav controller');
        }

    }

    public pushChildNavPageWithPageTracking (pageComponentToPush: any, newPageName: PageName, masterPageName?: PageName, params?: any, options: any = {animate: false}):Promise<any>{
        if(this.getActiveChildNav()){
            if(!masterPageName){
                masterPageName = this.getActiveChildNav().navPageStack[0].masterPageName;
            }
            this.getActiveChildNav().navPageStack.push({
                pageName: newPageName,
                masterPageName: masterPageName,
            });
            return this.getActiveChildNav().navCtrl.push(pageComponentToPush,params,options)
            //return this.navService.pushWithPageTracking(pageComponentToPush,newPageName,params,masterPageName,options,this._ChildNav);
        }else{
            return Promise.reject('Invalid child nav controller');
        }
    }

    public popChildNavPageWithPageTracking(options: any = {animate: false}): Promise<any>{
        if(this.getActiveChildNav()){
             const pageName = this.getActiveChildNav().navPageStack.pop();
            return this.getActiveChildNav().navCtrl.pop(options).then(succ=> {
              this.childNavPopObserver.next(pageName ? pageName.pageName : null);
            });
            //return this.navService.popWithPageTracking(options,this._accountManagementNav);
        }else{
            return Promise.reject('Invalid child nav controller');
        }
    }

    popWithPageTrackingMulitplePages(steps:number, options: any = {animate: false}, navCtrl?: IonNav): Promise<any> {
      const activeNav = navCtrl ? navCtrl : this._defaultNav;
      if (activeNav) {
        if(activeNav.canGoBack() && this._pageStack.length > steps) {
          // this._popPreviousPageFromStack(steps);
          let removeIdx = this.getTotalPageStackLength()-steps-1;
          this._pageStack.splice(removeIdx, 1);
          this.popChildNavCtrlFromStack(this.getChildNavNameWithMultipleSteps(steps))
          this._currentPageStack$.next(this._pageStack);
          return activeNav.removeIndex(removeIdx, 1);
        }
      } else {
          return Promise.reject('NavigationService: popWithPageTracking: Nav controller undefined');
      }
  } 

    private _popPreviousPageFromStack(steps:number) {
      let pageStackLastIdx = this.getTotalPageStackLength()-1;
      this._pageStack.splice(pageStackLastIdx-steps, 1);
      this._currentPageStack$.next(this._pageStack);
    }

    public popChildNavPageWithPageTrackingMultipleSteps(steps:number, options: any = {animate: false}): Promise<any>{
      if(this.getActiveChildNav()){
          let sliceEndIndex = this.getActiveChildNav().navPageStack.length - steps;
          if(sliceEndIndex<1) sliceEndIndex = 1
          this.getActiveChildNav().navPageStack = this.getActiveChildNav().navPageStack.slice(0,sliceEndIndex);
          return this.getActiveChildNav().navCtrl.popTo(sliceEndIndex-1, options);
      }else{
          return Promise.reject('Invalid child nav controller');
      }
    }

    public popToRootChildNavPageWithPageTracking(options: any = {animate: false}): Promise<any>{
        if(this.getActiveChildNav()){
            this.getActiveChildNav().navPageStack = [this.getActiveChildNav().navPageStack[0]];
            return this.getActiveChildNav().navCtrl.popToRoot(options);
        }else{
            return Promise.reject('Invalid child nav controller');
        }
    }

    getActiveChildNavName():ChildNavNames {
        return this._activeChildNavName;
    }

    getChildNavNameWithMultipleSteps(steps:number):ChildNavNames {
      return this._rightPaneNavCtrlStack[this._rightPaneNavCtrlStack.length-steps].navName;
  }

    getActiveChildNavViewPageName():PageName {
        return (this.getActiveChildNav() && this.getActiveChildNav().navPageStack.length > 0) ? this.getActiveChildNav().navPageStack[this.getActiveChildNav().navPageStack.length - 1].pageName : undefined;
    }

    getActiveChildNavViewMasterPageName():PageName {
        return (this.getActiveChildNav() && this.getActiveChildNav().navPageStack.length > 0) ? this.getActiveChildNav().navPageStack[this.getActiveChildNav().navPageStack.length - 1].masterPageName : undefined;
    }

    getPreviousActiveChildNavViewPageName():PageName {
      return (this.getActiveChildNav() && this.getActiveChildNav().navPageStack.length > 1) ? this.getActiveChildNav().navPageStack[this.getActiveChildNav().navPageStack.length - 2].pageName : undefined;
    }

    getPageNameByIndex(index: number): PageName {
        return this._pageStack[index].pageName;
    }

    getMasterPageNameByIndex(index: number): PageName {
        return this._pageStack[index].masterPageName;
    }

    getCurrentPageName(): PageName {
        return (this._pageStack && this._pageStack.length > 0) ? this._pageStack[this._pageStack.length - 1].pageName : undefined;
    }

    getAllPageStack(): PageStack[] {
      return this._pageStack ? this._pageStack : undefined;
    }

    getPreviousPageName(): PageName {
        return (this._pageStack && this._pageStack.length > 1) ? this._pageStack[this._pageStack.length - 2].pageName : undefined;
    }

    getCurrentMasterPageName(): PageName {
        return (this._pageStack && this._pageStack.length > 0) ? this._pageStack[this._pageStack.length - 1].masterPageName : undefined;
    }

    getCurrentPageStack(): PageStack {
        return (this._pageStack && this._pageStack.length > 0) ? this._pageStack[this._pageStack.length - 1] : undefined;
    }

    getTotalPageStackLength(): number {
      return this._pageStack ? this._pageStack.length : undefined;
    }

    isInMainAppView(): boolean {
        return this._pageStack && this._pageStack.length > 0 && this._pageStack[0].pageName === PageName.ActivitiesPageComponent;
    }

    public popMasterPageFromStack(){
        this._popFromStack();
    }

    private _pushToStack(pageStack: PageStack) {
        this._pageStack.push(pageStack);
        this._currentPageStack$.next(this._pageStack);
    }

    private _popFromStack() {
        this._pageStack.pop();
        this._currentPageStack$.next(this._pageStack);
    }
}
