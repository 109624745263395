import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { Language, LocalizationService } from '../../../../services/localization/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { FooterService } from '@omni/services/footer/footer.service';
import { EventsService } from '@omni/services/events/events.service';
import { DeviceService, DEVICE_EVENT } from '@omni/services/device/device.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
/**
 * Generated class for the AppLanguageListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-language-list',
  templateUrl: 'app-language-list.html',
  styleUrls:['app-language-list.scss']
})
export class AppLanguageListComponent  implements OnInit {
  public languages: Language[];
  public selectedLanguageCode: string;
  noOfLanguages: number;
  selectedlang: Language;
  disableDoneBtn: boolean;
  public filteredLanguages : Language[] = [];
  public isSearching : boolean;
  public serachString: string;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  AllLanguageHeader: IndSectionHeaderViewDataModel;
  resultHeader: IndSectionHeaderViewDataModel;
  constructor(
    public navService: NavigationService,
    public languageService: LocalizationService,
    private translateService: TranslateService,
    public footerService: FooterService,
    private events: EventsService,
    public device: DeviceService,
    private notificationService: NotificationService,
  ) {
  }

  async ngOnInit() {
        this.disableDoneBtn = true;
        this.initHeaderLeft();
        this.languages = [];
        await this.languageService.getDynamicsLanguage().then((dynamicsLang) => {
          if(dynamicsLang && dynamicsLang['LocaleIds'] && dynamicsLang['LocaleIds'].length>0){
            dynamicsLang['LocaleIds'].forEach(lang => {
              let foundLang = this.languageService.languages.find(l => l.localeID == lang);
              if(foundLang) this.languages.push(foundLang);
            })
          }
        }).catch(err => {
          console.log("Fetching Dynamics Languages Failed: " + err);
          this.languages.push(this.languageService.languages.find(l => l.localeID == '1033'));
        });
        this.filteredLanguages = this.languages;
        this.noOfLanguages = this.languages.length;
        this.selectedLanguageCode = this.languageService.selectedLanguage.code;
        this.serachString = "";
        this.initSectionHeader();
        this.initResultSectionHeader();
  }

  initSectionHeader(){
   this.AllLanguageHeader = {
      id: 'lang-detail-header',
      title: `${this.translateService.instant('SETTINGS_ALL_LANGUAGES')} (${this.getNumberofLanguage})`,
      controls: []
    };
  }

  initResultSectionHeader(){
   this.resultHeader = {
      id:'language-result-header',
      title: `${this.translateService.instant('AL_RESULTS')} (${this.getNumberofLanguage})`,
      controls: [],
    }
  }

  private initHeaderLeft() {
    let buttons = [];

    buttons.push({
      id: "done",
      imgSrc: 'assets/imgs/header_complete.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: this.disableDoneBtn,
      align: "right",
    });
    buttons.push({
      id: "close",
      cssClass: 'seventyPercentWidth',
      imgSrc: 'assets/imgs/header_cancel.svg',
      isDisabled: false,
      align: "left",
    });

    this.indHeaderLeftModel = {
      id: 'appsettings-list-header-left',
      title: this.translateService.instant('LANGUAGES'),
      mode: true,
      controls: buttons
    }
  }

  onHeaderControlClick(id) {
    if (id === 'close') {
      this.onClosePage();
    }
    if (id === 'done') {
      this.applyChangedLanguage();
    }
  }

  changeLanguage(lang) {
    this.disableDoneBtn = this.languageService.selectedLanguage.code === lang.code || this.device.isOffline;
    this.selectedlang = lang
    this.selectedLanguageCode = lang.code;
    this.initHeaderLeft();
  }

  onClosePage() {
    this.navService.popWithPageTracking();
  }

  async applyChangedLanguage() {
    if(this.device.isOffline) {
      this.notificationService.notify(this.translateService.instant('YOUR_DEVICE_IS_OFFLINE'), 'appLanguageList', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    if (this.selectedLanguageCode != this.languageService.selectedLanguage.code) {
      await this.languageService.updateDyanmicsLanguage(this.selectedlang).then((data) => {
        console.log('Dynamics UI Language has been changed: ' + data);
        localStorage.setItem("selectedLanguage", this.selectedLanguageCode);
        this.languageService.language.next(this.selectedlang);
        this.events.publish('home:runUpdateSync', { syncType: DEVICE_EVENT.languageChange });
      })
      .catch(err => {
        console.log("Change language in Dynamics Failed: " + err);
      });
    }
    this.navService.popWithPageTracking()
  }

  onInput(event){
      let searchParam = this.serachString ? this.serachString : '';
      if(searchParam.length > 0){
        this.isSearching = true;
        this.searchLanguages(searchParam.trim().toLocaleLowerCase());
      }
      else{
        this.isSearching = false
        this.filteredLanguages = this.languages;
        this.noOfLanguages = this.filteredLanguages.length
      }
  }

  searchLanguages(searchParam){
    this.filteredLanguages = this.languages.filter((language) =>{
        return (language.name.toLocaleLowerCase().includes(searchParam) || language.subName.toLocaleLowerCase().includes(searchParam) ||
        language.code.toLocaleLowerCase().includes(searchParam) || language.localeID.toLocaleLowerCase().includes(searchParam))
    });
    this.noOfLanguages = this.filteredLanguages.length
    this.initResultSectionHeader();
  }

  get getNumberofLanguage(){
     return this.noOfLanguages;
  }


}
