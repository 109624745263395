import { EventActivity } from '@omni/classes/events-tool/event.class';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EventStatus } from '../../enums/event/event.enum';
import { EventHandler, Events as IonicEvents } from './events';

export enum EventName {
  NEWOPPORTUNITYCONFIRMED = 'confirm_new_opportunity',
  OPPORTUNITYSCRAPBUTTON = 'scrap_opportunity',
  DROPDOWNLISTDISMISSDATA = 'dropdown_list_dismiss_data',
  SAMPLEELLIGIBILITESFOOTERBUTTON = 'sample_order_elligibilities_button',
  ACCOUNTCREATEDUPDATEDFROMAPP = 'account_create_update_from_app',
  ACCOUNTFORMISDIRTYEVENT = 'account_form_is_dirty_event',
  PRESENTATION_DETAILS = 'presentation_detail_ev',
  ALLOCATION_SIGNATURE_FOOTER_BUTTON = 'allocation_signature_footer_button',
  ACCOUNTEDITPAGEACTIVE = 'account-page-button-active',
  FINISHEDSERVICEWORKERMAPPING = 'finished-service-worker-mapping',
  ACCOUNTUPDATEINTERACTION = 'account_update_interaction',
  ACTIVITY_DETAILS_INTERACTION = 'userInteractionInActivityDetails',
  OPEN_SELECTED_ACCOUNT_DETAILS = 'open_selected_account_details',
  OPPORTUNITYCLOSEASWON = 'closeaswon',
  OPPORTUNITYCLOSEASLOST = 'closeaslost',
  OPPORTUNITYCLOSEASUSERCANCELED = 'closeasusercanceled',
  OPPORTUNITYMARKCONFIRMED = 'mark_confirm_opportunity',
  OPPORTUNITYERROR_CLOSE_TO_OPEN = 'opportunity_error_close_to_open',
  INMEETINGFOLLOWUPACTIONSCRAP = 'in_meeting_followup_action_scap',
  NEW_KOL_STATUS_SUBMITTED = 'new_kol_status_submitted',
  DCR_APPROVAL_SUBMITTED = 'dcr_approval_submitted',
  ASSIGN_OPPORTUNITY_OWNER = 'assign_opportunity_owner',
  BACK_TO_FOLLOW_UP_DETAIL_FROM_PUSHED_PAGE = 'back_to_follow_up_detail_from_pushed_page',
  CONTACTDETAILSSEGMENTUPDATE = 'contact_details_segment_update',
  OPPORTUNITY_CREATED_FROM_MARKETING_PLAN = 'created_opportunity_from_marketing_plan',
  QUICKGLANCECCONTACTREATEPHONECALL = 'quick_glance_contact_create_phone_call',
  QUICKGLANCECCONTACTREATEMESSAGE = 'quick_glance_contact_create_message',
  QUICKGLANCECCONTACTREATEMEETING= 'quick_glance_contact_create_meeting',
  QUICKGLANCECACCOUNTREATEPHONECALL = 'quick_glance_account_create_phone_call',
  QUICKGLANCECACCOUNTREATEMEETING= 'quick_glance_account_create_meeting',
  QUICKGLANCECCONTACTREATEMESSAGEFIRSTTIME= 'quick_glance_contact_create_message_first_time',
}

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private readonly events: IonicEvents, 
              private translate: TranslateService) {}

  observe<T>(event: string): Observable<T> {
    return Observable.create(observer => {
      let handler = (args) => {
        console.log(event);
        observer.next(args);
      };
      this.events.subscribe(event, handler);
      return ()=> this.events.unsubscribe(event, handler);
    });
  }

  publish(topic: string, ...args: any[]) {
    return this.events.publish(topic, ...args);
  }

  unsubscribe(topic: string, handler?: EventHandler): boolean {
    if(handler) {
      let proxyHandler = ProxyHandlerFactory.fromHandler(handler);
      if(proxyHandler)
        return proxyHandler.unsubscribe();
    }
    return this.events.unsubscribe(topic, handler);
  }

  subscribe(topic: string, ...handlers: EventHandler[]) {
    let proxyHandlers = handlers.map(handler => new ProxyHandlerFactory(topic, this.events, handler));
    this.events.subscribe(topic, ...proxyHandlers.map(p => p.handler));
    return proxyHandlers.map(handler => handler as { unsubscribe: Function });
  }

  subscribeOnce(topic: string, ...handlers: Function[]) {
    let proxyHandlers = handlers.map(handler => new ProxyHandlerFactory(topic, this.events, handler, true));
    this.events.subscribe(topic, ...proxyHandlers.map(p => p.handler));
    return proxyHandlers.map(handler => handler as { unsubscribe: Function });
  }

   getFormattedStatus(event: EventActivity, forcedState?: EventStatus) {
    const status = forcedState ? forcedState : event.statuscode;
    switch (status) {
      case EventStatus.DRAFT: return this.translate.instant('OM_DRAFT');
      case EventStatus.APPROVED: return this.translate.instant('APPROVED'); //Approved
      case EventStatus.REJECTED: return this.translate.instant('REJECTED');// Rejected
      case EventStatus.COMPLETED: return this.translate.instant('COMPLETED');//Completed
      case EventStatus.FOR_REVIEW: return this.translate.instant('FOR_REVIEW');//For Review
    }
  }

  getTranslatedSubject(event) {
    return event.name && event.name.toLowerCase() === 'Event'.toLowerCase() ? this.translate.instant('EVENT') : event.name;
  }
}
class ProxyHandlerFactory {
  public get handler() {
    return this.proxyHandler
  }

  constructor(private topic: string,
    private events: IonicEvents,
    private _handler: Function,
    private _once: boolean = false) {
    this.proxyHandler = (...args) => {
      if (this._once) {
        this.unsubscribe();
      }
      //console.log("Event published", this.topic, this._once, this._handler);
      return this._handler(...args);
    }
    ProxyHandlerFactory._localHandlerCache.push(this._handler)
    ProxyHandlerFactory._localProxyHandlerCache.push(this)
  }
  private static _localHandlerCache:Function[] = []
  private static _localProxyHandlerCache:ProxyHandlerFactory[] = []

  private proxyHandler:EventHandler

  static fromHandler(handler:Function) {
    let handlerIndex = ProxyHandlerFactory._localHandlerCache.indexOf(handler);
    if(handlerIndex >= 0) {
      return ProxyHandlerFactory._localProxyHandlerCache[handlerIndex]
    }
  }

  public unsubscribe() {
    //console.log("Event unsubscribed", this.topic, this._once, this._handler);
    if(this.events.unsubscribe(this.topic, this.proxyHandler)) {
      let handlerIndex = ProxyHandlerFactory._localProxyHandlerCache.indexOf(this);
      if(handlerIndex >= 0) {
        ProxyHandlerFactory._localProxyHandlerCache.splice(handlerIndex, 1)
        ProxyHandlerFactory._localHandlerCache.splice(handlerIndex, 1)
        return true;
      }
    }
    return false;
  }
}
