<ion-content>
    <ion-grid [ngClass]="{ 'toolbar-android': isAndroid, 'toolbar-non-ios-android': isNonAndroidOriOS}">
      <ion-row class="user-info-header" 
        [ngClass]="{
          'header-with-location': faService.isShortCallLauncherEnabledInMobileDevice,
          'offline-state': device.isOffline || (repService.isOfflineState && device.isApplicationLoaded)
        }">
        <h3
          class="user-name"
          [ngClass]="{'offline-margin': (device.isOffline || (repService.isOfflineState && device.isApplicationLoaded)) && (isAndroid || device.isIOS())}">
          {{ userName ? userName : authenticationService.user.displayName }} {{ dbEncryptionEnabled }}
        </h3>
        <div class="user-position">{{ userPosition ? userPosition : authenticationService.user.positionName }}</div>
        <div class="user-location" *ngIf="faService.isShortCallLauncherEnabledInMobileDevice">
          <img text-center menuClose src="assets/imgs/location.svg"/><span
          *ngIf="!device.isOffline">{{ authenticationService.user.currentLocation?.name }}</span><span
          *ngIf="device.isOffline">{{ "LOCATION_SERVICE_UNAVAILABLE" | translate }}</span>
        </div>
        <h5 class="user-status">
                <span class="checkmark-image">
          <img text-center menuClose src="assets/imgs/green_checkmark.svg"/>
        </span>
          <span>
          <ion-select #statusSelect (ionChange)="changeStatus(userStatus)" [(ngModel)]="userStatus"
                      [selectedText]="getDisplayText()"
                      interface="popover" class="user-status-padding" [disabled]="disableStatus">
            <ion-select-option *ngFor="let o of userStatusList" [disabled]="o.disabled"
                               [value]="o.userState">{{ translate.instant(o.userState) }}</ion-select-option>
          </ion-select>
        </span>
        </h5>
      </ion-row>
        <ion-row class="listWrapper" [ngClass]="{'listWrapper-with-location': faService.isShortCallLauncherEnabledInMobileDevice}">
            <!-- <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="1">
        <ion-list no-lines class="home-sync">
          <ion-item class="item-content inner-padding">
              <img text-center menuClose src="assets/imgs/back_to_home_btn.svg" width="25px" (click)="menuCtrl.toggle()"/>
          </ion-item>
          <ion-item class="item-content inner-padding">
            <img text-center  menuClose (click)="refreshData()" src="assets/imgs/syncIcon.svg" width="25px" [ngClass]="{'disabled': isOffline()||device.isOffline}"/>
          </ion-item>
          <ion-item class="inner-padding-sync item-content">
            <span text-center class="sync-text">
              {{ syncDateString }} {{ syncTimeString }}
            </span>
          </ion-item>
          <ion-item class="item-content exit-settings-item inner-padding" *ngIf="this.hasSettingsEnabled">
            <img text-center menuClose src="assets/imgs/app-settings-icon.PNG" width="25px" (click)="openSettings()"/>
        </ion-item>
          <ion-item class="item-content exit-item inner-padding">
              <img text-center menuClose src="assets/imgs/logoutIcon.svg" width="25px" (click)="logout()"/>
          </ion-item>
        </ion-list>
      </ion-col> -->
            <!-- <ion-col size-xs="10" size-sm="10" size-md="10" size-lg="10" size-xl="11"> -->
            <ion-col [ngClass]="{ 'allow-scroll-bar': isNonAndroidOriOS }">
                <ion-list #menuList no-lines class="menu-options">
                    <ion-item class="menuOptions" *ngFor="let option of options | orderBy:'order':true:'displayName':true" [ngClass]="{'invisible': !option.visible,'itemSelected':option.selected, 'itemUnSelected':!option.selected, 'disabled':!option.enable}">
                        <div class="label-wrapper" [ngClass]="option.id" menuClose *ngIf="option.displayName != 'LOGOUT'" (click)="onClickMenuOption(option)">
                            <ion-icon *ngIf="option.iconName && option.iconName != ''" name="indegene-{{option.iconName}}" class="{{option.iconName}} icon-alignment"></ion-icon>
                            <span class="optionDisplayName">{{option.displayName}}</span>
                        </div>
                        <div slot="end">
                            <span *ngIf="(deltaService.deltaRecordsDTO.accounts.length && option.id == 'accounts') ||
                                  (deltaService.deltaRecordsDTO.accounts.length && option.id == 'accountManagement') ||
                                  (deltaService.deltaRecordsDTO.contacts.length && option.id == 'contacts') ||
                                  (deltaService.deltaRecordsDTO.resources.length && option.id == 'resources') ||
                                  (deltaService.deltaRecordsDTO.presentations.length && option.id == 'presentations')" class="notify-bubble">{{getNewItemsCount(option.id)}}
                            </span>
                        </div>
                    </ion-item>
                    <ion-item class="menuOptions" *ngFor="let option of hyperlinkOptions | orderBy:'order':true:'displayName':true" [ngClass]="{'invisible': !option.visible,'itemSelected':option.selected, 'itemUnSelected':!option.selected, 'disabled':!option.enable}">
                      <div class="label-wrapper" [ngClass]="option.id" menuClose *ngIf="option.displayName != 'LOGOUT'" (click)="onClickMenuOption(option)">
                          <ion-icon *ngIf="option.iconName && option.iconName != ''" name="indegene-{{option.iconName}}" class="{{option.iconName}} icon-alignment"></ion-icon>
                          <span class="optionDisplayName">{{ option.displayName | translate: { globalCustomerText: utilityService.globalCustomerText } | capitalizeFirstLetter }}</span>
                      </div>
                  </ion-item>
                </ion-list>
                <!-- <div class="menuGeneeContainer">
                  <ion-button fill="clear" (click)="openMyAccount()" class="microphone-btn">
                    <img src="assets/imgs/ogeenee.png" width="35">
                    <ion-badge color="primary" class="ogeeneeBtnBadge" *ngIf="getCountUnreadNotifications()>0">{{getCountUnreadNotifications()}}</ion-badge>
                  </ion-button>
                </div> -->
            </ion-col>
        </ion-row>
        <ion-row class="footer-controls">
            <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="4" size-xl="4" class='logout-col'>
                <div class='logout-div'>
                    <img text-center menuClose src="assets/imgs/logoutIcon.svg" width="25px" (click)="logout()" />
                    <span text-center class="logout-text" (click)="logout()">
            {{ "LOGOUT" | translate}}
          </span>
                </div>
            </ion-col>
            <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="4" size-xl="4" class='sync-col'
              [ngClass]="{'disabled': isOffline()||device.isOffline || backgroundUploadInProgress || activityService.todoRefreshInProgress}">
              <div class='sync-div'>
                <img text-center menuClose class="logout-img" (click)="refreshData()" src="assets/imgs/syncIcon.svg" width="25px"
                  [ngClass]="{'disabled': isOffline() || device.isOffline || backgroundUploadInProgress || activityService.todoRefreshInProgress}" />
                <span text-center class="sync-text" (click)="refreshData()">
                  {{ "SYNC" | translate}} - {{ syncDateString }} {{ syncTimeString }}
                </span>
              </div>
            </ion-col>
            <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="4" size-xl="4" class='settings-col' *ngIf="isSettingsEnabled">
                <div class='settings-div'>
                    <img text-center menuClose src="assets/imgs/app-settings-icon.svg" width="25px" (click)="openSettings()" />
                    <span text-center class="settings-text" (click)="openSettings()">
            {{ "SETTINGS" | translate}}
          </span>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<!-- <ion-footer [hidden]="!hasBaselineEnabled">
  <footer-toolbar [footerView]="'masterView'" [selectedView]="'menu-toggle'"></footer-toolbar>
</ion-footer> -->
