import { Injectable } from "@angular/core";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TranslateService } from "@ngx-translate/core";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ScientificActivityPage } from "@omni/components/activity/scientific-activity/scientific-activity-page/scientific-activity-page";
import { AppSettingsPageComponent } from "@omni/components/app-settings/appsettings-page/appsettings-page";
import { CoachingComponent } from "@omni/components/coaching/coaching-home/coaching-home";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
import { CustomerCallPlanPage } from "@omni/components/customer-call-plans/customer-call-plan-page/customer-call-plan-page";
import { EventsToolPageComponent } from "@omni/components/events-tool/event-tool-page/events-tools-page";
import { FieldMaterialManagementPageComponent } from "@omni/components/field-material-management/field-material-management-page/field-material-management-page";
import { MarketScanPageComponent } from "@omni/components/market-scan/market-scan-page/market-scan-page";
import { OpportunityManagementPageComponent } from "@omni/components/opportunity-management/opportunity-management-page/opportunity-management-page";
import { OrderManagementPageComponent } from "@omni/components/order-management/order-management-page/order-management-page";
import { PharmacovigilanceComponent } from "@omni/components/pharmacovigilance-reporting/page/pharmacovigilance-page";
import { ResourcePageComponent } from "@omni/components/resource/resource-page/resource-page";
import { AllocationComponent } from "@omni/components/sample/allocation-page/allocation-page";
import { SettingsComponent } from "@omni/components/settings/settings/settings";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { SurveyPageComponent } from "@omni/components/survey/survey-page/survey-page";
import { TimeOffComponent } from "@omni/components/time-off/time-off-home/time-off-home";
import { AuthenticationDataService } from "@omni/data-services/authentication/authentication.service";
import { AccountManagementPageComponent } from "@omni/pages/account-management-page/account-management-page";
import { CaseManagementHomePage } from "@omni/pages/case-management-home/case-management-home";
import { MarketingPlanManagementPageComponent } from "@omni/pages/marketing-plan-page/marketing-plan-page";
import { PresentationPageComponent } from "@omni/pages/presentation/presentation";
import { QuotePageComponent } from "@omni/pages/quote-page/quote-page";
import { startRecursiveEventScheduleConflictFlaggingForEventsArray } from "@omni/utility/activity.utility";
import _ from "lodash";
import { APP_VERSION } from "src/config/endpoints.config";
import { AccountManagementOfflineService } from "./account-management/account-management.service";
import { AccountOfflineService } from "./account/account.offline.service";
import { ActivityService } from "./activity/activity.service";
import { AuthenticationService } from "./authentication.service";
import { CaseManagementService } from "./case-management/case-management.service";
import { ContactOfflineService } from "./contact/contact.service";
import { DeviceService } from "./device/device.service";
import { EventsToolService } from "./events-tool/events-tool.service";
import { GlobalUtilityService } from "./global-utility.service";
import { TrackingEventNames, TrackService } from "./logging/tracking.service";
import { NavigationService, PageName } from "./navigation/navigation.service";
import { PharmacovigilanceService } from "./pharmacovigilance-reporting/pharmacovigilance.service";
import { PresentationService } from "./presentation/presentation.service";
import { ResourceService, ResourceView } from "./resource/resource.service";
import { AllocationFeatureService } from "./sample/allocation.feature.service";
import { TimeOffService } from "./time-off/time-off.service";
import { AppView, ComponentViewMode, PresentationView, UIService } from "./ui/ui.service";
import { electronApi } from "@omni/services/electron-api";

@Injectable({
  providedIn: 'root'
})
export class GoAppNavigationService {

  private hasBaselineEnabled: boolean = false;

  constructor(
    private translate: TranslateService,
    private utilityService: GlobalUtilityService,
    private authenticationService: AuthenticationService,
    private device: DeviceService,
    private trackingService: TrackService,
    private contactService: ContactOfflineService,
    private uiService: UIService,
    private accountService: AccountOfflineService,
    private caseManagementService: CaseManagementService,
    private navService: NavigationService,
    public accountManagementOfflineService: AccountManagementOfflineService,
    private presentationService: PresentationService,
    private pharmacovigilanceService: PharmacovigilanceService,
    private eventsToolService: EventsToolService,
    private inAppBrowser: InAppBrowser,
    private authenticationDataService: AuthenticationDataService,
    private resourceService: ResourceService,
    private allocFeatureService: AllocationFeatureService,
    private timeoffService: TimeOffService,
    public activityService: ActivityService,
  ) {


  }

  private getDisplayNamesAsPerLang() {
    let textdisplayName;
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        textdisplayName = this.translate.instant('STAKEHOLDER');
        break;
      case 'Contact':
        textdisplayName = this.translate.instant('CONTACT');
        break;
      case 'Customer':
        textdisplayName = this.translate.instant('CUSTOMER');
        break;
      default:
        textdisplayName = this.utilityService.globalCustomerText;
        break;
    }
    let allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_WITH_TEXT', { text: textdisplayName });
    switch (this.translate.currentLang) {
      case 'it':
      case 'pt':
      case 'es':
        allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_TEXT_IN_MENU');
        break;
      default:
        allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_WITH_TEXT', { text: textdisplayName });
        break;
    }
    return { textdisplayName, allocationOrderDisplayName };
  }

  public initMenuOptions() {
    this.hasBaselineEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.BASELINE);
    if (this.hasBaselineEnabled) {
      let { textdisplayName, allocationOrderDisplayName } = this.getDisplayNamesAsPerLang();

      const options = [
        {
          id: 'accounts',
          visible: this.hasBaselineEnabled,
          iconPath: 'assets/imgs/account-icon.svg',
          selectedIconPath: 'assets/imgs/selected-account-icon.svg',
          displayName: this.translate.instant('ACCOUNTS'),
          selected: false,
          component: 'AccountsPage',
          enable: true
        },
        {
          id: 'accountManagement',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT),
          iconPath: 'assets/imgs/account-management-icon.svg',
          selectedIconPath: 'assets/imgs/selected-account-management-icon.svg',
          displayName: this.translate.instant('ACCOUNT_PLANS'),
          selected: false,
          component: 'AccountManagementPage',
          enable: true
        },
        {
          id: 'marketingBusiness',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN),
          iconPath: 'assets/imgs/marketing-plan-icon.svg',
          selectedIconPath: 'assets/imgs/selected-marketing-plan-icon.svg',
          displayName: this.translate.instant('MARKETING_BUSINESS_PLANS'),
          selected: false,
          component: 'MarketingBusinessManagementPage',
          enable: true
        },
        {
          id: 'quotes',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.QUOTES),
          iconPath: 'assets/imgs/quote-tool-icon.svg',
          selectedIconPath: 'assets/imgs/selected-quote-tool-icon.svg',
          displayName: this.translate.instant('QUOTES'),
          selected: false,
          component: 'QuotePage',
          enable: true
        },
        {
          id: 'orderManagement',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT),
          iconPath: 'assets/imgs/ordermgmt-icon.svg',
          selectedIconPath: 'assets/imgs/selected-ordermgmt-icon.svg',
          displayName: this.translate.instant('SALES_ORDERS'),
          selected: false,
          component: 'OrderManagementPage',
          enable: true
        },
        {
          id: 'call-plan',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS),
          iconPath: 'assets/imgs/call-plan-icon.svg',
          selectedIconPath: 'assets/imgs/selected-call-plan-icon.svg',
          displayName: this.translate.instant('CALL_PLANS'),
          selected: false,
          component: 'CustomerCallPlanPage',
          enable: true
        },
        {
          id: 'case-tool',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE),
          iconPath: 'assets/imgs/case-icon.svg',
          selectedIconPath: 'assets/imgs/selected-case-icon.svg',
          displayName: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT', { globalCustomerText: textdisplayName }),
          selected: false,
          component: 'CaseManagementHome',
          enable: true
        },
        {
          id: 'coaching',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL),
          iconName: 'coaching-icon',
          iconPath: 'assets/imgs/coaching-icon.svg',
          selectedIconPath: 'assets/imgs/selected-coaching-icon.svg',
          displayName: this.translate.instant('COACHING'),
          selected: false,
          component: 'CoachingPage',
          enable: true
        },
        {
          id: 'contacts',
          visible: this.hasBaselineEnabled,
          iconPath: 'assets/imgs/contacts-icon.svg',
          selectedIconPath: 'assets/imgs/selected-contacts-icon.svg',
          displayName: this.translate.instant('CONTACTS'),
          selected: false,
          component: 'ContactPage',
          enable: true
        },
        {
          id: 'fieldMaterialManagement',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.FIELD_MATERIAL_MANAGEMENT),
          iconPath: 'assets/imgs/field_asset_management_icon_grey.svg',
          selectedIconPath: 'assets/imgs/tools_field_material.svg',
          displayName: this.translate.instant('FIELD_MATERIAL_MANAGEMENT'),
          selected: false,
          component: 'FieldMaterialManagementPage',
          enable: true
        },
        {
          id: 'presentations',
          visible: this.hasBaselineEnabled,
          iconPath: 'assets/imgs/tools_presentations.png',
          selectedIconPath: 'assets/imgs/selected-presentations-icon.svg',
          displayName: this.translate.instant('PRESENTATIONS'),
          selected: false,
          component: 'PresentationPage',
          enable: true
        },
        {
          id: 'pharmacovigilance',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.PHARMACOVIGILANCE_TOOL),
          iconPath: 'assets/imgs/pharmacovigilance_grey.svg',
          selectedIconPath: 'assets/imgs/tools_pharmacovigilance.svg',
          displayName: this.translate.instant('PHARMACOVIGILANCE_REPORTING'),
          selected: false,
          component: 'PharmacovigilanceComponent',
          enable: true
        },
        {
          id: 'analytics',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.OMNIPRESENCE_DNA),
          iconPath: 'assets/imgs/analytics-icon.svg',
          selectedIconPath: 'assets/imgs/selected-analytics-icon.svg',
          displayName: this.translate.instant('ANALYTICS'),
          selected: false,
          component: 'OmnipresenceExtURL',
          enable: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.OMNIPRESENCE_DNA) && !this.device.isOffline
        },
        {
          id: 'survey',
          visible: this.hasBaselineEnabled && (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY) || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)),
          iconPath: 'assets/imgs/survey.svg',
          selectedIconPath: 'assets/imgs/survey-icon.svg',
          displayName: this.translate.instant('SURVEY'),
          selected: false,
          component: 'SurveyPage',
          enable: true
        },
        {
          id: 'time-off',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL),
          iconName: 'time-off-icon',
          iconPath: 'assets/imgs/timeoff.svg',
          selectedIconPath: 'assets/imgs/selected-timeoff-icon.svg',
          displayName: this.translate.instant('TIME_OFF'),
          selected: false,
          component: 'TimeoffPage',
          enable: true
        },
        {
          id: 'resources',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL),
          iconPath: 'assets/imgs/resource.svg',
          selectedIconPath: 'assets/imgs/selected-resource-icon.svg',
          displayName: this.translate.instant('RESOURCES'),
          selected: false,
          component: 'ResourcesPage',
          enable: true
        },
        {
          id: 'allocation',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL),
          iconPath: 'assets/imgs/allocation-tool-icon.svg',
          selectedIconPath: 'assets/imgs/selected-allocation-tool-icon.svg',
          displayName: allocationOrderDisplayName,
          selected: false,
          component: 'AllocationPage',
          enable: true,
        },
        {
          id: 'consent',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL),
          iconPath: 'assets/imgs/consent-icon.svg',
          selectedIconPath: 'assets/imgs/selected-consent-icon.svg',
          displayName: this.translate.instant('CONSENT'),
          selected: false,
          component: 'ConsentPage',
          enable: true
        },
        {
          id: 'scientificactivity',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN),
          iconPath: 'assets/imgs/scientific_plans.svg',
          selectedIconPath: 'assets/imgs/selected-scientific-tool-icon.svg',
          displayName: this.translate.instant('SCIENTIFIC_PLANS'),
          selected: false,
          component: 'ScientificActivityPage',
          enable: true
        },
        {
          id: 'opportunitymgmt',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
          iconPath: 'assets/imgs/opportunity-icon.svg',
          selectedIconPath: 'assets/imgs/selected-opportunity-icon.svg',
          displayName: this.translate.instant('OPPORTUNITIES'),
          selected: false,
          component: 'OpportunityManagementPage',
          enable: true
        },
        {
          id: 'marketscan',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL),
          iconPath: 'assets/imgs/customer-scan-icon.svg',
          selectedIconPath: 'assets/imgs/selected-customer-scan-icon.svg',
          displayName: this.translate.instant('CUSTOMER_SCAN'),
          selected: false,
          component: 'MarketScanPage',
          enable: true
        },
        {
          id: 'scheduler',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.SCHEDULER),
          iconPath: 'assets/imgs/scheduler-icon.svg',
          selectedIconPath: 'assets/imgs/selected-scheduler-icon.svg',
          displayName: this.translate.instant('SCHEDULER'),
          selected: false,
          component: 'SchedulerPage',
          enable: true
        },
        {
          id: 'eventstool',
          visible: this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL),
          iconPath: 'assets/imgs/events-icon.svg',
          selectedIconPath: 'assets/imgs/selected-events-icon.svg',
          displayName: this.translate.instant('EVENTS'),
          selected: false,
          component: 'EventsToolPageComponent',
          enable: true
        },
      ];
      return _.orderBy(options.filter(op => op.visible), 'displayName');
    }
  }

  public async navigateToMenuOption(option) {
    switch (option.component) {
      case "ContactPage":
        this.trackingService.tracking('ToolsContacts', TrackingEventNames.ACTIVITY);
        if (!this.contactService.loadedContacts && !this.contactService.approvableDCRRequests.length) return;
        this.uiService.showNewActivity = false;
        this.uiService.prevView = this.uiService.activeView
        this.uiService.activeView = "Contacts";
        this.uiService.isConsentFromToolDrawer = false;
        this.uiService.consentFlipButton = false;
        this.contactService.contactInformation = null;
        this.contactService.prevSelected = null;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.uiService.isContactsFromToolDrawer = true;
        if (this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
          this.caseManagementService.assignSelectedCase(undefined);
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        }
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { from: 'ContactTool' }, PageName.ContactPageComponent);
        break;
      case "ConsentPage":
        this.trackingService.tracking('ToolsConsent', TrackingEventNames.ACTIVITY);
        if (!this.contactService.loadedContacts) return;
        this.uiService.showNewActivity = false;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Consent";
        this.uiService.isConsentFromToolDrawer = true;
        this.uiService.consentFlipButton = false;
        this.contactService.contactInformation = null;
        this.contactService.prevSelected = null;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.contactService.consentFrom = 'ConsentTool';
        if (this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
          this.caseManagementService.assignSelectedCase(undefined);
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        }
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { from: 'ConsentTool' }, PageName.ContactPageComponent);
        break;
      case "AccountsPage":
        this.trackingService.tracking('ToolsAccounts', TrackingEventNames.ACTIVITY)
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Accounts";
        this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.uiService.isAccountsFromToolDrawer = true;
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { from: 'AccountTool', 'listMode': ComponentViewMode.LISTVIEW }, PageName.AccountPageComponent);
        break;
      case "AccountManagementPage":
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "AccountManagement";
        this.uiService.activitiesPagePlanTabSelectedPlan = undefined;
        this.navService.pushWithPageTracking(AccountManagementPageComponent, PageName.AccountManagementPageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.AccountManagementPageComponent);
        break;
      case "MarketingBusinessManagementPage":
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "marketingBusiness";
        this.uiService.showNewActivity = false;
        this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.MarketingPlanManagementPageComponent);
        break;
      case "QuotePage":
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "quotes";
        this.uiService.showNewActivity = false;
        this.navService.pushWithPageTracking(QuotePageComponent, PageName.QuotePageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.QuotePageComponent);
        break;
      case "CustomerCallPlanPage":
        this.trackingService.tracking('ToolsCallPlans', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "CallPlans";
        this.navService.pushWithPageTracking(CustomerCallPlanPage, PageName.CustomerCallPlanPage, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.CustomerCallPlanPage);
        break;
      case "FieldMaterialManagementPage":
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(FieldMaterialManagementPageComponent, PageName.FieldMaterialManagementPageComponent, null, PageName.FieldMaterialManagementPageComponent);
        break;
      case "PresentationPage":
        this.trackingService.tracking('ToolsPresentations', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Presentation";
        this.presentationService.viewMode = PresentationView.MENU;
        this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent, null, PageName.PresentationPageComponent);
        //this.navCtrl.push(PresentationPageComponent);
        // this.viewCtrl.dismiss();
        break;
      case 'PharmacovigilanceComponent':
        this.pharmacovigilanceService.loadRecordsFromDB();
        this.pharmacovigilanceService.loadInfoButtonsDataFromDB(this.authenticationService.user.businessUnitName);
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(
          PharmacovigilanceComponent,
          PageName.PharmacovigilancePage,
          null,
          PageName.PharmacovigilancePage,
        );
        break;
      case "OmnipresenceExtURL":
        this.uiService.toolsActivityActive = false;
        this.trackingService.tracking('ToolsAnalytics', TrackingEventNames.ACTIVITY)
        if (!this.device.isOffline) {
          const appInsightsInstrumentationKey = this.authenticationService.userConfig.activeInstance.appInsightsInstrumentationKey;
          let encJson = CryptoJS.AES.encrypt(JSON.stringify(appInsightsInstrumentationKey), 'analytics').toString()
          let encryptedAppInsightsKey = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))

          let url = this.authenticationService.userConfig.activeInstance.dnaDashboardUrl +
            '?etoken=' + (await this.authenticationDataService.getEncryptedTokenForSelectedInstance()) +
            '&instanceid=' + this.authenticationService.userConfig.activeInstance.id +
            '&friendlyname=' + this.authenticationService.userConfig.activeInstance.friendlyName +
            '&appinsightskey=' + encryptedAppInsightsKey +
            '&appversion=' + APP_VERSION;

          if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
            if (this.device.deviceFlags.ios) {
              const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
            }
            else {
              const browserRef = this.inAppBrowser.create(url, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
            }
          } else {
            if (this.device.deviceFlags.electron) {
              electronApi.openExternal(url);
            } else {
              window.open(url, "_system");
            }
          }
        }
        break;
      case "ResourcesPage":
        this.trackingService.tracking('ToolsResources', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Resources";
        this.resourceService.viewMode = ResourceView.MENU;
        this.navService.pushWithPageTracking(ResourcePageComponent, PageName.ResourcePageComponent, null, PageName.ResourcePageComponent);
        break;
      case "TimeoffPage":
        this.trackingService.tracking('ToolsTimeOff', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.timeoffService.setMobileTracker("timeOff");
        this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffComponent, null, PageName.TimeOffComponent);
        //this.navCtrl.push(TimeOffComponent);
        break;
      case "AllocationPage":
        this.allocFeatureService.loadShipmentsForAllocationTool();
        this.allocFeatureService.loadTeamAdjustments();
        this.trackingService.tracking('ToolsCustomerAllocations', TrackingEventNames.ACTIVITY)
        this.activityService.indexWholeSampleActivityMetasBySKU();
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "CustomerAllocation";
        this.navService.pushWithPageTracking(AllocationComponent, PageName.AllocationComponent, null, PageName.AllocationComponent);
        break;
      case "CoachingPage":
        this.trackingService.tracking('ToolsCoaching', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Coaching";
        this.navService.pushWithPageTracking(CoachingComponent, PageName.CoachingComponent, null, PageName.CoachingComponent);
        break;
      case "ScientificActivityPage":
        this.trackingService.tracking('ScientificPlansToolsDrawer', TrackingEventNames.SCIENTIFICACTIVITYPLAN, true);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "ScientificActivity";
        this.navService.pushWithPageTracking(ScientificActivityPage, PageName.ScientificActivityPage, null, PageName.ScientificActivityPage);
        break;
      case "CaseManagementHome":
        this.trackingService.tracking('ToolsCustomerInquiries', TrackingEventNames.ACTIVITY);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Case-Mangement";
        this.caseManagementService.assignSelectedCase(undefined);
        this.caseManagementService.showNewCase = false;
        this.navService.pushWithPageTracking(CaseManagementHomePage, PageName.CaseManagementHomePage, null);
        break;
      case "OrderManagementPage":
        this.trackingService.tracking('OrderManagementToolsDrawer', TrackingEventNames.ORDERMANAGEMENT);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "OrderManagement";
        this.navService.pushWithPageTracking(OrderManagementPageComponent, PageName.OrderManagementPageComponent, {}, PageName.OrderManagementPageComponent);
        break;
      case "OpportunityManagementPage":
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(OpportunityManagementPageComponent, PageName.OpportunityManagementPage, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.OpportunityManagementPage);
        break;
      case "MarketScanPage":
        this.uiService.prevView = this.uiService.activeView;
        this.trackingService.tracking('CustomerScanToolClick', TrackingEventNames.CUSTOMER_SCAN, true);
        this.navService.pushWithPageTracking(MarketScanPageComponent, PageName.MarketScanPageComponent, {}, PageName.MarketScanPageComponent);
        break;
      case "EventsToolPageComponent":
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
        await this.uiService.displayLoader();
        this.eventsToolService.clearConflictEventIds();
        const lastIdx = startRecursiveEventScheduleConflictFlaggingForEventsArray(this.eventsToolService.eventsToolData, 0, this.eventsToolService.conflictCheckLastIdx, this.authenticationService.user.xSystemUserID);
        this.eventsToolService.setConflictCheckLastIdx(lastIdx);
        this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, {}, PageName.EventsToolPageComponent);
        await this.uiService.dismissLoader();
        break;
      case "SurveyPage":
        this.uiService.prevView = this.uiService.activeView;
        this.trackingService.tracking('SurveyPageToolsDrawer', TrackingEventNames.SURVEY, true);
        this.navService.pushWithPageTracking(SurveyPageComponent, PageName.SurveyPageComponent, {}, PageName.SurveyPageComponent);
        break;
    }
  }
}