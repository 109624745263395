<ind-section-header *ngIf="timelineSectionHeader" [viewData]='timelineSectionHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

<!--Timeline/Journey Section-->  
<ion-list class="time-line-list">
  <timeline-item *ngFor="let item of timelineItems" [inputData]="item" (clickedOnHeader)="openDetails($event)"></timeline-item>
</ion-list>
<ion-list *ngIf="timelineItems && timelineItems.length == 0">
  <div class="no-data-message" >
    {{'NO_ACTIVITIES_ON_TIMELINE' | translate}}
  </div>
</ion-list>