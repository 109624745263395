
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, Input } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { Subject } from "rxjs";
import { NavigationService, PageName } from "../../services/navigation/navigation.service";
import { ChildUsersPageComponent } from "../child-users-page/child-users-page";

/** @hidden */
interface ISelectPopoverItem {
  text?: string;
  value?: string;
  displayIcon?: string;
  disabled?: boolean;
  checked?: boolean;
  expanded?: boolean;
  items?: ISelectPopoverItem[];
  handler?: Function;
  multiselect?: boolean;
  customized?: boolean;
  selectedValues? : any[];
  disableLabel?: boolean;
  keyword?: string;
}

class SelectPopoverItem {
  parent: SelectPopoverItemGroup;
  item: ISelectPopoverItem;
  text: string;
  disabled: boolean;
  checked: boolean;
  handler?: Function;
  protected _value: string;
  displayIcon?: string;
  protected _selectedValues: any[];
  disableLabel: boolean;

  get value(): string {
    return this._value;
  }
  get selectedValues(): any[]{
    return this._selectedValues
  }
  set selectedValues(values){
    this._selectedValues = values
  }
  constructor(obj: ISelectPopoverItem, parent: SelectPopoverItemGroup, protected uiComponent: MultiSelectPopover) {
    this.item = obj;
    this.parent = parent;
    this.text = obj.text;
    this.disabled = !!obj.disabled;
    this.checked = !!obj.checked;
    this._value = obj.value === undefined && !obj.items ? obj.text : obj.value;
    this.handler = obj.handler;
    this._selectedValues = obj.selectedValues? obj.selectedValues : [];
    this.displayIcon = obj.displayIcon;
    this.disableLabel = (obj.disableLabel === undefined ? false : obj.disableLabel)
  }
}

/** @hidden */
class SelectPopoverItemGroup extends SelectPopoverItem {
  private _expanded: boolean;
  get expanded(): boolean {
    return this._expanded;
  }
  set expanded(expanded: boolean) {
    // this.parent.items.forEach(item => {
    //   if (item instanceof SelectPopoverItemGroup) {
    //     item._expanded = false;
    //   }
    // });
    this._expanded = expanded;
  }
  items: SelectPopoverItem[];
  multiselect: boolean;
  customized?: boolean = false;
  keyword?: string = '';

  constructor(obj: ISelectPopoverItem, parent: SelectPopoverItemGroup, uiComponent:MultiSelectPopover) {
    super(obj, parent, uiComponent);
    this._expanded = obj.expanded || true;
    this.multiselect = obj.multiselect;
    this.customized = obj.customized || false;
    this.keyword = obj.keyword || parent?.keyword;
    this.items = obj.items.map(
      i =>  i.items 
        ? new SelectPopoverItemGroup(i, this, uiComponent)
        : new SelectPopoverItem(i, this, uiComponent) 
        
        // {
        // i.keyword = obj.keyword;
        // if(i.items) {
        //   i.items.forEach(item => item.keyword = this.keyword);
        //   return new SelectPopoverItemGroup(i, this, uiComponent);
        // } else {
        //   return new SelectPopoverItem(i, this, uiComponent);
        // }
      
    );
  }

  get value() {
    return super.value;
  }

  set value(value) {
      let checkedOption = this.items && this.items.find(option => option.value === value);
      // if (!checkedOption)
      //   return;
      if (this.handler && checkedOption) {
        this.handler(checkedOption.item, this.item, this, this.keyword);
        this._value = this.item.value;
        checkedOption.disabled = checkedOption.item.disabled;
      } else {
        this._value = checkedOption ? value : undefined;
        this.item.value = this._value;
      }
      this.uiComponent.detectChanges();
  }
  //selection/de-selection of options for a multi-select type filter
  setSelectedValue(option){
    let isAlreadySelected = this.selectedValues && this.selectedValues.some(value => option.value === value);
    let selectedOption = this.items && this.items.find(xyz => xyz.value === option.value);
    selectedOption.checked = !isAlreadySelected
    if(isAlreadySelected){
      let index = this.selectedValues.findIndex(k => option.value === k)
      if (index >= 0) {
        this.selectedValues.splice(index, 1);
      }
    }
    else{
      this.selectedValues.push(option.value);
    }
    if (!option.doNotTriggerHandler) {
      this.handler(selectedOption.item, this.item, this, selectedOption.checked);
    }
  }
  //de-selection of all options in an item group for a multi-select type filter
  deSelectAll(){
    this.selectedValues = [];
    this.item.selectedValues = [];
    this.items.map(o =>{
      o.checked = false;
    })
  }
}

/** @hidden */
@Component({
  templateUrl: "multi-select-popover.html",
  styleUrls:['multi-select-popover.scss']
})
export class MultiSelectPopover implements OnInit {
  root: SelectPopoverItemGroup;
  @Input('root') _root: any;
  @Input('multiselect') _multiselect: any;
  @Input('showClear')showClear: boolean;
  @Input('keyword') keyword: string;
  ngDestroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private navParams: NavParams,
    private screenOrientation: ScreenOrientation,
    private navService: NavigationService,
    public _cd: ChangeDetectorRef,
    public popoverCtrl:PopoverController
  ) {
    this.screenOrientation
      .onChange().pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.popoverCtrl.dismiss();
      });
  }

  public ngOnInit() {
    const input:ISelectPopoverItem[] = this._root ? this._root : this.navParams.get("root");
    this.root = new SelectPopoverItemGroup({ items: input, multiselect: this._multiselect, keyword: this.keyword }, null, this);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.unsubscribe();
  }
  handleCustomizedSectionClick(item:SelectPopoverItemGroup){
    if(item.text == 'User'){
      this.popoverCtrl.dismiss();
      this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'CasePageFilter' })
    }
  }

  //handle clicking of an option from a multi-select filter
  handleMultiSelectOptionClick(child:SelectPopoverItem, parent:SelectPopoverItemGroup){
    parent.setSelectedValue(child);
  }

  //handle clicking of 'Clear All' button from a multi-select filter
  clearAll(data){
    if(!data || !Array.isArray(data)){
      data = this.root.items
    }
      data.map(o=>{
        if(o.item){
          if(o.item.items){
            this.clearAll(o.items)
          }
          else{
            if(o.parent.selectedValues.includes(o.item.value)){
              o.parent.setSelectedValue(o);
            }
          }
        }
      })
  }

  detectChanges(){
    this._cd.detectChanges();
  }
}
