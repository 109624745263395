import { Endpoints } from "src/config/endpoints.config";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { decrypt, encrypt } from "../crypto";
import { CRMInstance } from "@omni/classes/authentication/instance.class";
import { DiskService } from "../disk/disk.service";
import { AuthenticationDataService } from "@omni/data-services/authentication/authentication.service";
// import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { BrowserCredential } from "./browser.credential";
import { RepServices } from "@omni/data-services/rep/rep.services";
import { DeviceService } from "../device/device.service";
import { LaunchDarklyProvider } from "@omni/providers/launch-darkly/launch-darkly";
import { GlobalUtilityService } from "../global-utility.service";
import { LOGGED_OUT, LOGGED_OUT_AND_BACK_TO_LOGIN, REP_STATUS } from "@omni/models/rep-status-model";
import { TokenCredential, TokenCredentialOptions } from "./token.credential";
import { ElectronCredential } from "./electron.credential";
import { CordovaCredential, IntuneManager } from "./cordova.credential";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { CordovaPlugin } from "../cordova";
import { FeatureActionsService } from "../feature-actions/feature-actions.service";
import { Utility } from "@omni/utility/util";

interface AuthConfig {
  tenant: string;
  region: string;
  clientId: string;
  dynamicsAdminCentreUrl: string;
  instanceUrl?: string;
  userId: string;
  domain: string;
  redirectUri: string;
}

function defaultScopeForUrl(url: string) {
  return `${url}${url.endsWith('/') ? '' : '/'}.default`
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private config: AuthConfig;
  private _ready: Promise<void>;
  private storage = sessionStorage;
  private didJustLogoutWithOfflineStateAndRemeberMe = false;
  private _cordovaHelpers = new CordovaPlugin('CordovaHelpers');

  public rememberMe = false;
  get username() {
    return this.config?.userId;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthenticationDataService,
    private readonly repService: RepServices,
    // private readonly appInsightsService: AppInsightsService,
    private readonly device: DeviceService,
    private readonly launchDarkly: LaunchDarklyProvider,
    private readonly guv: GlobalUtilityService,
    private readonly disk: DiskService,
    private faService: FeatureActionsService,
  ) {
  }

  async ready() {
    if (!this._ready) {
      this._ready = this.readCache();
    }
    return this._ready;
  }

  get isAuthenticated() {
    return !!(this.authenticationService.user && this.authenticationService.userConfig)
  }

  private async readCache() {
    try {
      this.rememberMe = false;
      let domain = this.storage.getItem('omni.adal.domain');
      let configJson = domain && this.storage.getItem(domain);
      if (!domain && !configJson && this.storage != localStorage) {
        this.storage = localStorage;
        return this.readCache();
      }
      let config = configJson && (JSON.parse(configJson) as AuthConfig);
      if (config && config.clientId && config.domain && config.tenant && config.dynamicsAdminCentreUrl && config.userId) {
        let rememberMe = this.storage === localStorage;
        const credential = await this.createCredential({
          clientId: config.clientId,
          username: config.userId,
          domain: config.domain,
          redirectUri: config.redirectUri,
          authority: this.getAuthorityUrl(config.tenant, config.region || (config.domain.endsWith('.cn') ? 'china' : '')),
          persist: rememberMe,
        });
        this.authService.credential = credential;
        this.rememberMe = rememberMe;
        this.config = config;
        // this.appInsightsService.config = {
        //   accountId: this.config.tenant
        // };
        if (this.didJustLogoutAndBackToLogin()) {
          this.clearLogoutAndBackToLoginFlag()
          this.didJustLogoutWithOfflineStateAndRemeberMe = true;
        } else if (!this.didLogout() || (this.didLogout() && this.repService.wasLastUserStateOffline())) {
          await this.restoreSession(true);
        }
      }
    } catch (ex) {
      console.error(ex);
      this.config = undefined;
    }
  }

  private async createCredential(options: TokenCredentialOptions){
    let credential: TokenCredential = !this.device.isNativeApp
      ? new BrowserCredential(options)
      : this.device.deviceFlags.electron
        ? new CordovaCredential(options)
        : new CordovaCredential(options);
    await credential.ready();
    if (this.device.deviceFlags.nativeIOS || this.device.deviceFlags.nativeAndroid) {
      await IntuneManager.initIntune(options.clientId);
    }
    return credential;
  }

  private async saveCache() {
    let config = this.config;
    if (!config.clientId || !config.domain || !config.tenant || !config.dynamicsAdminCentreUrl) {
      return;
    }
    this.storage = this.rememberMe ? localStorage : sessionStorage;
    this.storage.setItem('omni.adal.domain', config.domain);
    this.storage.setItem(config.domain, JSON.stringify(config));
    if (!this.rememberMe) {
      let domain = localStorage.getItem('omni.adal.domain');
      if (domain) {
        localStorage.removeItem('omni.adal.domain');
        localStorage.removeItem(domain);
      }
    }
  }

  async restoreSession(force = false) {
    if (!this.config) return false;
    this.authService.isOfflineLoginFlow = false;
    const deviceOffline = !(await this.device.checkNetworkConnectivity()) || this.device.isOffline;
    const repStateOffline = this.repService.wasLastUserStateOffline();

    if (force || repStateOffline || deviceOffline) {
      if (repStateOffline) {
        this.device.isOffline = this.device.isUserStateOffline = this.repService.isOfflineState = true;
      } else if (this.device.isDeviceRealOffline) {
        this.device.isOffline = this.repService.isOfflineState = true;
      }
      if (!repStateOffline && !deviceOffline) {
        this.authenticationService.shouldTrySyncingDuringAppLaunch = true;
      }
      this.device._isDeviceOfflineFromLogin = true;
      this.authService.isOfflineLoginFlow = repStateOffline || deviceOffline || this.didJustLogoutWithOfflineStateAndRemeberMe;
    } else {
      return false;
    }
    if (!this.device.isOffline && this.authService.credential) {
      await this.authService.getTokenForSelectedInstance();
    }

    const iv = await this.disk.getFromSecureStorage(this.config.userId);
    const encBuffer = await this.disk.getFromSecureStorage(this.config.userId + '-encBuffer');

    if (iv && encBuffer) {
      //Decrypt time
      const results = await decrypt(encBuffer, iv, this.config.userId);
      const loginConfiguration = results && JSON.parse(results);
      if (loginConfiguration && loginConfiguration.user) {
        this.authenticationService.user = loginConfiguration.user;
        this.authenticationService.userConfig = loginConfiguration.userConfig;

        // Restore FA values
        this.faService.updateFaValues();
      }
    }

    if (this.authenticationService.user && this.authenticationService.userConfig) {
      await this.afterLoggedIn(true)
      return true;
    } else {
      if (!this.config?.instanceUrl) return false;
      let scope = defaultScopeForUrl(this.config.dynamicsAdminCentreUrl);
      let authResult = await this.authService.credential.getToken(scope);
      await this.fetchInstances(authResult.token);
      let selectedInstance = this.authenticationService.userConfig?.CRMInstances?.find(instance => instance.url.toLowerCase() == this.config.instanceUrl.toLowerCase())
      if (selectedInstance) {
        await this.selectInstance(selectedInstance);
        return this.isAuthenticated;
      }
    }
    return false;
  }

  async login(username: string, rememberMe: boolean) {
    const domain = username.split('@')[1];
    let domainDetails = await this.getDomainDetails(domain)
    if (!domainDetails) {
      throw new Error("INVALID_EMAIL_MESSAGE")
    }
    let config: AuthConfig = {
      tenant: domainDetails.tenantId,
      clientId: domainDetails.clientId,
      dynamicsAdminCentreUrl: domainDetails.dynamicsAdminCentreUrl,
      redirectUri: window.location.origin + '/.auth/login/aad/callback',
      userId: username,
      region: domainDetails.region,
      domain
    };
    localStorage.setItem('region', domainDetails.region);
    let credential = await this.createCredential({
      clientId: config.clientId,
      redirectUri: config.redirectUri,
      username,
      domain,
      authority: this.getAuthorityUrl(config.tenant, config.region),
      persist: rememberMe,
    });
    let scope = defaultScopeForUrl(config.dynamicsAdminCentreUrl);
    // Give a delay to have the credential instance ready  OMNI-44913
    await Utility.delay(1000);
    let authResult = await credential.getToken(scope);
    if (authResult) {
      if (this.device.deviceFlags.nativeIOS || this.device.deviceFlags.nativeAndroid) {
        await IntuneManager.registerAccount(config.clientId, config.userId).catch(() => {});
      }
      await this.fetchInstances(authResult.token)
      this.authService.credential = credential;
      this.config = config;
      this.rememberMe = rememberMe;
      // this.appInsightsService.config = {
      //   accountId: this.config.tenant
      // };
      this.saveCache();
      this.clearLogoutFlag();
      this.authenticationService.shouldTrySyncingDuringAppLaunch = false;
      return true;
    }
    return false;
  }

  private async fetchInstances(token: string) {
    try {
      // const url: string = Endpoints.authentication.GET_INSTANCES;
      const url: string = (localStorage.getItem("region") && localStorage.getItem("region") === "China") ? Endpoints.authentication.GET_CHINA_INSTANCES : Endpoints.authentication.GET_INSTANCES;
      let res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.ok) {
        const data = await res.json();
        this.authenticationService.mapLoginResponse(data);
      }
    } catch {
      throw new Error("LOGIN_NO_NET_CONNECTIVITY");
    } finally {
      if (!this.authenticationService.userConfig?.CRMInstances?.length) {
        throw new Error("LOGIN_NO_OMNI_INSTANCE_ASSIGNED")
      }
    }
  }

  private getAuthorityUrl(tenant: string, region: string) {
    let loginPartner = Endpoints.authentication.MICROSOFT_BASE_URL_GLOBAL;
    region = region?.toLowerCase();
    if (region == 'china') {
      loginPartner = Endpoints.authentication.MICROSOFT_BASE_URL_CHINA;
    }
    return `${loginPartner}/${tenant}`;
  }


  private async getDomainDetails(domain) {
    // return {
    //   tenantId: 'common',
    //   clientId: 'd78440c4-622c-496f-ba35-6250243d9a9a',
    //   dynamicsAdminCentreUrl: 'https://globaldisco.crm.dynamics.com/'
    // }
    let url = Endpoints.domain.GET_DOMAIN_DETAILS + domain;
    let res = await fetch(url).catch(() => { throw new Error("LOGIN_NO_NET_CONNECTIVITY") });
    if (res.ok) {
      let json = await res.json();;
      return {
        tenantId: json['realm-id'] as string,
        clientId: json['client-id'] as string,
        region: json.region as string || (domain.endsWith('.cn') ? 'china' : ''),
        dynamicsAdminCentreUrl: json.dynamicsAdminCentreUrl as string
      };
    } else {
      let data = await res.json();
      throw new class extends Error {
        readonly error = data;
        readonly status = res.status;
        constructor() {
          super(data.errorMessage || data.toString())
        }
      };
    }
  }

  async selectInstance(instance: CRMInstance) {
    let instanceUrl = instance.url;
    let authResult = await this.authService.getTokenForResource(instanceUrl);
    if (authResult) {
      this.authenticationService.userConfig.activeInstance = instance;
      await this.authService.getUser(true);
      await this.fetchTeamsConfig();
      await this.saveSession();
      await this.afterLoggedIn();
      return true;
    }
    return false;
  }

  private async fetchTeamsConfig() {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING)) {
      let url = Endpoints.domain.GET_TEAMS_CONFIG;
      url = url.replace('{tenantid}', this.config.tenant);
      url = url.replace('{clientid}', this.config.clientId);
      let token = await this.authService.getTokenForSelectedInstance();
      let res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      });
      if (res.ok) {
        this.authenticationService.userConfig.teamsConfig = await res.json();
      }
    }
  }

  private async afterLoggedIn(offlineMode = false) {
    this.clearLogoutFlag();
    // this.appInsightsService.config = {
    //   accountId: this.config.tenant
    // };
    await this.disk.setUserDB(this.authenticationService.userConfig);
    if (this.device.deviceFlags.native && (this.device.deviceFlags.ios || this.device.deviceFlags.android)) {
      this._cordovaHelpers.exec('setAppCenterUserId', this.authenticationService.user?.userPrincipalName ?? this.authenticationService.user?.displayName);
    }
    const hasNetwork = await this.device.checkNetworkConnectivity()
    await Promise.all([
      (async () => {
        if (hasNetwork) {
          this.launchDarkly.initialize().catch(() => { });
          this.guv.setupSessionStack();
        }
      })(),
      this.authService.fetchNotesAssistantConfiguration(offlineMode),
      this.authService.fetchOKIntegrationConfig(offlineMode)
    ]);
  }


  private async saveSession() {
    try {
      this.config.instanceUrl = this.authenticationService.userConfig.activeInstance.url
      await this.saveCache();
      if (!this.rememberMe) return;
      const username = this.config.userId;
      // If hybrid app, encrypt and save to secure storage
      const offlineConfiguration = {
        user: this.authenticationService.user,
        userConfig: this.authenticationService.userConfig,
      };
      const { ivString, encryptedString } = await encrypt(JSON.stringify(offlineConfiguration), username);
      //We save the IV for unlocking the loginConfig
      await this.disk.saveToSecureStorage(username + '-encBuffer', encryptedString);
      await this.disk.saveToSecureStorage(username, ivString);
      return true;
    }
    catch (ex) {
      console.error(ex);
    }
    return false;
  }

  public async logout() {
    (navigator as any)?.splashscreen?.show();
    this.markLogout();
    this.markLogoutAndBackToLogin();
    let isOfflineState = this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState;
    if (!isOfflineState || !this.rememberMe) {
      await this.disk.clearSecureStorage();
      //Lets kill what we got on disk
      sessionStorage.clear();
      if (!this.rememberMe) {
        localStorage.clear();
      } else if (this.config.domain && localStorage.getItem(this.config.domain)) {
        this.config.instanceUrl = undefined;
        localStorage.setItem(this.config.domain, JSON.stringify(this.config));
      }
    }
    if (window.location.protocol.startsWith('file') && !window.location.href.endsWith('.html')) {
      window.location.href += `${!window.location.href.endsWith('/') ? '/' : ''}index.html`;
    } else {
      window.location.reload();
    }
  }

  private didLogout(): boolean {
    return window.localStorage.getItem(LOGGED_OUT) === 'true';
  }
  private markLogout() {
    if (this.device.isNativeApp && this.rememberMe) {
      window.localStorage.setItem(LOGGED_OUT, 'true');
    }
  }
  private clearLogoutFlag() {
    window.localStorage.removeItem(LOGGED_OUT);
  }

  private didJustLogoutAndBackToLogin(): boolean {
    return window.localStorage.getItem(LOGGED_OUT_AND_BACK_TO_LOGIN) === 'true';
  }
  private markLogoutAndBackToLogin() {
    if (this.rememberMe) {
      window.localStorage.setItem(LOGGED_OUT_AND_BACK_TO_LOGIN, 'true');
    }
  }
  private clearLogoutAndBackToLoginFlag() {
    window.localStorage.removeItem(LOGGED_OUT_AND_BACK_TO_LOGIN);
  }
}
