<ion-grid [ngClass]="(isRightPaneActive)?'preview-active':'preview-inactive'">
    <ion-row>
        <ion-col class="order-quantity-left-pane">
            <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="productsSearchStr" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="searchInput($event)"></ind-header-left>
            <ion-content [ngClass]="{'vectorimageleft' : productsSearchStr.length == 0 && filteredProducts.length==0 }">
                

                <ion-list class="products-list">
                    <!-- <ion-item-divider *ngIf="selectedProducts.length > 0" class="list-title">{{'SELECTED_CAP' | translate}} ({{selectedProducts.length}})</ion-item-divider> -->
                    <ind-section-header *ngIf="selectedProducts.length > 0" [viewData]='{id:"selectedProd",title: translate.instant("SELECTED_CAP")+" ("+selectedProducts.length+")"}'></ind-section-header>
                    <ion-item-group class='selector-item' *ngFor="let selProduct of selectedProducts" [ngClass]="{'itemSelected': selectedProduct && selectedProduct.productId === selProduct.productId}" (click)="openProductDetail(selProduct)">
                        <ion-item>
                            <ion-label>
                                {{selProduct.productName}}
                            </ion-label>
                            <ion-icon class='remove-icon endIcon' [ngClass]="{'disabledIcon': backgroundUploadInProgress}" name="indegene-selectors-remove-icon" (click)="removeSelectedProduct(selProduct)" slot="end"></ion-icon>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                    <!-- <ion-item-divider *ngIf="!productsSearchStr" class="list-title">{{'OM_PRICELIST_PRODUCTS' | translate}} ({{filteredProducts.length}})</ion-item-divider>
                    <ion-item-divider *ngIf="productsSearchStr" class="list-title">{{'RESULTS_CAP' | translate}} ({{filteredProducts.length}})</ion-item-divider> -->
                    <ind-section-header [viewData]='{id:"productsHeader",title: (!productsSearchStr? translate.instant("OM_PRICELIST_PRODUCTS")
                          :translate.instant("RESULTS_CAP"))+" ("+filteredProducts.length+")"}'></ind-section-header>
                    <ion-item-group *ngFor="let product of filteredProducts" class='selector-item' [ngClass]="{'itemSelected': selectedProduct && selectedProduct.productId === product.productId}" (click)="openProductDetail(product)">
                        <ion-item>
                            <ion-label>
                                {{product.productName}}
                            </ion-label>
                            <ion-icon *ngIf="selectedProducts.includes(product)" class='checkmark-icon endIcon' name="indegene-selectors-checkmark-icon" slot="end"></ion-icon>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                    <p *ngIf="productsSearchStr.length==0 && filteredProducts.length==0" justify-content-center class="no-data-message"> {{'SCHEDULER_NO_PRODUCTS' |translate}}</p>
                </ion-list>
                <ind-no-search-result *ngIf="productsSearchStr.length > 0 && filteredProducts.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
            </ion-content>
            <!-- <ion-footer> -->
            <footer-toolbar [footerView]="'masterView'" [selectedView]="'contactAllocationEligibilities'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>

        <ion-col class="order-quantity-right-pane">
            <ind-page-title *ngIf="selectedProduct" [viewData]="indHeaderRightModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
            <ion-content>
                <!-- <ion-toolbar *ngIf="selectedProduct" class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
                    <ion-buttons slot="start">
                        <ion-button *ngIf="!isSplitView()" (click)="closeRightPane()" class="back-btn">
                            <ion-icon class="back-btn" name="chevron-back-outline" large></ion-icon>
                        </ion-button>
                    </ion-buttons>
                    <ion-title text-center>{{selectedProduct.productName}}</ion-title>
                </ion-toolbar> -->
                <ion-list *ngIf="selectedProduct" class="product-details-list">
                    <!-- <ion-item-divider class="list-title">
                        {{'ORDER_LINE_CAP' | translate}}
                        <span slot="end" class='divider-text ion-margin-start ion-float-right' [ngClass]="(!isAddItemEnabled)?'closed-status':'open-status'" (click)="addQuantityToProduct()">
                            {{ (selectedProducts.includes(this.selectedProduct) ? 'UPDATE_LINE':'ADD_LINE') | translate }}
                        </span>
                    </ion-item-divider> -->
                    <ind-section-header [viewData]='orderLineHeader'></ind-section-header>
                    <!-- <ion-item>
                        <ion-label class='product-field-header-label'> {{'OM_UNIT_PRICE' | translate}}
                        </ion-label>
                        <div item-content class="product-field-text">
                            {{selectedProduct.formattedUnitPrice}}
                        </div>
                    </ion-item> -->
                    <!-- <ion-item>
                        <ion-label class='product-field-header-label'> {{'QUANTITY' | translate}} </ion-label>
                        <ion-input class="product-field-text"
                            [(ngModel)]="selectedProductCopy.quantity" (ionChange)='handleQuantityEntered($event.detail,$event)' type="text" placeholder="Enter value"
                            type="number" pattern="[0-9]*" placeholder="Enter value" min="0" text-right style="margin-right:-0.5%;">
                        </ion-input>
                        <ion-icon class="rightArrow" name="arrow-back" slot="end" ></ion-icon>
                    </ion-item> -->
                    <ind-form-field [viewData]='getUnitPriceFormField()'></ind-form-field>
                    <ind-form-field [viewData]='getQuantityFormField()'></ind-form-field>
                    <ind-form-field [viewData]='getTotalFormField()'></ind-form-field>
                    <!-- <ion-item>
                        <ion-label class='product-field-header-label'> {{'TOTAL' | translate}}
                        </ion-label>
                        <div item-content class="product-field-text">
                            {{selectedProductCopy.formattedTotal}}
                        </div>
                    </ion-item> -->
                </ion-list>
                <nothing-selected-view *ngIf="!selectedProduct" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
            </ion-content>
            <!-- <ion-footer *ngIf="selectedProduct"> -->
            <!-- <footer-toolbar *ngIf="selectedProduct && footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar> -->
            <!-- </ion-footer> -->
        </ion-col>
    </ion-row>
</ion-grid>
