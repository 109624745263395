<ion-item (click)="handleCheckboxClick(item, $event)">
  <ion-icon
    class="insights arrow-icon"
    [name]="item.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'"
    (click)="handleRightIconClick($event, item)"
    slot="start">
  </ion-icon>

  <ion-spinner *ngIf="showSpinner" slot="start"></ion-spinner>

  <ion-checkbox *ngIf="!item.hideCheckbox"
                class="selectable-item"
                [(ngModel)]="item.isChecked"
                (ionChange)="toggleItem(item, $event)"
                labelPlacement="end"
                justify="start">
    {{ item.label }}
  </ion-checkbox>
</ion-item>

<ion-item-group class="innerChild" *ngFor="let item of childItems; trackBy: trackByFn">
  <omni-accordion-item
    [item]="item"
    [viewData]="viewData"
    [depth]="depth + 1"
    (onToggleChildItem)="emitToggleItem(item, $event)"
    (onToggleChildItemClick)="emitToggleItemClick(item, $event)">
  </omni-accordion-item>
</ion-item-group>

<div class="separationLine"></div>
