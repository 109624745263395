import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '@omni/classes/contact/contact.class';

@Pipe({
    name: "contactNameSequence"
})

export class ContactNameSequencePipe implements PipeTransform {
  constructor(
    private translate : TranslateService,
  ) {}

  transform(contact: Contact, checkStatus: boolean = false): string {
    const contactDisplaySequence = Number.parseInt(localStorage.getItem("contactdisplaysequence") || '0');
    let sequenceName: string = `${contact.firstName} ${contact.lastName}`;
    let statusStr: string = '';

    if (!contact || !contact.firstName && !contact.lastName) return sequenceName;
    if (checkStatus) {
      statusStr = contact.isActive ? '' : ` (${this.translate.instant("INACTIVE")})`;
    }

    if (contact.firstName && contact.lastName) {
      if (contactDisplaySequence === 548910000 || contactDisplaySequence === 0 || isNaN(contactDisplaySequence)) {
        sequenceName = `${contact.firstName} ${contact.lastName}`;
      } else if (contactDisplaySequence === 548910001) {
        sequenceName = `${contact.lastName} ${contact.firstName}`;
      }
    } else if (contact.firstName) {
      sequenceName = contact.firstName;
    } else if (contact.lastName) {
      sequenceName = contact.lastName;
    }

    if (statusStr) {
      sequenceName += statusStr;
    }

    return sequenceName;
  }

}
