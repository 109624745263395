import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import {takeUntil} from 'rxjs/operators';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, Input, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, HostListener, Output, EventEmitter } from "@angular/core";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { BrandOfflineService } from "../../../services/brand/brand.service";
// import { NavController } from "@ionic/angular/navigation/nav-controller";
// import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { CallPlanDataService } from '../../../data-services/call-plan/call-plan.data.service';
import { DeviceService } from "../../../services/device/device.service";
import { CallPlanDetails } from "../call-plan-details/call-plan-details";
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
// import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { PopoverController } from '@ionic/angular';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { EventsService } from '../../../services/events/events.service';
import { Subject, Subscription } from 'rxjs';
// import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { TranslateService } from '@ngx-translate/core';
// import { AccountPageComponent } from '../../../components/account/account-page/account-page';
import { SearchConfigService } from '../../../services/search/search-config.service';
import { IndKeyControlsAreaModel } from './../../../models/indKeyControlsAreaModel';
import { AlertService } from '../../../services/alert/alert.service';
import * as _ from 'lodash';
import { SearchConfigDataService } from './../../../data-services/search-config/search-config-data-service';
import { searchIndexDataModel, SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from '../../../models/search-config-data-model';
import {IndSectionHeaderViewDataModel} from './../../../models/indSectionHeaderDataModel';
import {MainCardViewDataModel} from './../../../models/MainCardViewDataModel';
import { DiskService } from '../../../services/disk/disk.service';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';

@Component({
  selector: "call-plan-list",
  templateUrl: "call-plan-list.html",
  styleUrls:['call-plan-list.scss']
})
export class CallPlanList implements OnInit, OnDestroy {
  @Output() onCallPlanSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCallPlanSelectOnPlanTab: EventEmitter<any> = new EventEmitter<any>();
  public compViewMode = ComponentViewMode;
  public callPlanMode: string = "mine";
  public hasTeamPlansEnabled:boolean = false;
  @ViewChild('Content', {static:true}) content:HTMLIonContentElement;
  destroy$: Subject<boolean> = new Subject<boolean>();
  myFormattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future:[] };
  teamFormattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future:[] };
  /* removed to use advanced search
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any) => void; }[];
  listFiltered: boolean = false;
  multiSelectFilterPopoverData: { text: string; selectedValues: string[]; items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  */
  public searchText = "";

  //added properties for advanced search
  callPlanSearchText: string;
  suggestionsActive: boolean = false;
  searchKey : string = '';
  shouldFireSearchChangeEvent: boolean = true;
  public searching: boolean = false;
  disableSaveSearch: boolean = false;
  selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
  filteredList: { past: any[], present: any[], future: any[] } = { past: [], present: [], future:[] };
  suggestionsData: {
    header:IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  sortBy:{text: string, value: string, asc: boolean};
  //sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  tabsData: IndTabsDataModel[];
  agendaPlanTabs: IndTabsDataModel[] = [];
  private subs: Subscription[] = [];
  public mycallplanPastHeaderModel : IndSectionHeaderViewDataModel
  public mycallplanCurrentHeaderModel : IndSectionHeaderViewDataModel
  public mycallplanFutureHeaderModel : IndSectionHeaderViewDataModel
  public filteredListPastHeaderModel : IndSectionHeaderViewDataModel
  public filteredListCurrentHeaderModel : IndSectionHeaderViewDataModel
  public filteredListFutureHeaderModel : IndSectionHeaderViewDataModel
  public teamCallplanPastHeaderModel : IndSectionHeaderViewDataModel
  public teamCallplanCurrentHeaderModel : IndSectionHeaderViewDataModel
  public teamCallplanFutureHeaderModel : IndSectionHeaderViewDataModel
  public noSearchResultHeaderModel: IndSectionHeaderViewDataModel;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public filterValueSelected: boolean = false;
  public fromPlanTab: boolean = false;
  public waitingForTeamPlans: boolean = true;

  constructor(
    public repService: RepServices,
    public callPlanOfflineService: CallPlanOfflineService,
    private callDataService: CallPlanDataService,
    // private accountService:AccountOfflineService,
    private activityService:ActivityService,
    private callPlanService: CallPlanDataService,
    private brandService: BrandOfflineService,
    // private navCtrl: NavController,
    public uiService: UIService,
    // private footerService: FooterService,
    public device:DeviceService,
    private authService:AuthenticationService,
    private trackingService: TrackService,
    private navService: NavigationService,
    private events: EventsService,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    public alertService: AlertService,
    public searchConfigService: SearchConfigService,
    public searchConfigDataService: SearchConfigDataService,
    public _cd: ChangeDetectorRef,
    public contactListElement: ElementRef,
    public disk: DiskService,
    private contactService:ContactOfflineService,
    public footerService: FooterService,
    private readonly agendaFooterService: AgendaFooterService
  ) {
    this.callPlanOfflineService.setCallPlanMode('mine');
  }

  ngOnInit(){
    // this.activityService.selectedActivity = undefined;
    if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan =='callPlans') this.fromPlanTab = true;
    this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(res=>{
      if(res && res.raw && res.raw.length){
        const a = this.aggregateRepCallPlans(res.raw);
        this.callPlanOfflineService.segmentCallPlans = a
      }
      else this.callPlanOfflineService.segmentCallPlans = [];
      this.getFormattedCallPlans();
      this.updateEmptyMessage();
      this.initializeSectionHeader();
      if(!this.fromPlanTab)this.callPlanOfflineService.mapMyCallPlansToSearchIndex(this.callPlanOfflineService.segmentCallPlans);
    });
    if(this.authService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)){
      this.hasTeamPlansEnabled = true;
    }
    if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan =='callPlans') this.fromPlanTab = true;
    this.initCallPlanHeaderLeft();

    this.setTabsData();
    // advanced search -configue
    if(this.searchConfigService.configUpdateRequired){
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }
    this.searchText = this.translate.instant('SEARCH_MY_CALLPLANS');

    //events subscriptions
    this.events.observe(this.translate.instant('SYNC_COMPLETED')).pipe(
      takeUntil(this.destroy$))
      .subscribe(error => {
        // Refresh call plans after a sync
        this.getFormattedCallPlans();
      });

    // For the plan tab on Agenda
    this.agendaPlanTabs = this.uiService.getAgendaTabs();
    this.subs.push(this.device.isOfflineObservable.subscribe(isOffline => {
      this.agendaPlanTabs = this.uiService.getAgendaTabs();
      if(this.callPlanMode != 'mine'){
        this.uiService.dismissLoader();
      }
      this.setTabsData();
      this._cd.detectChanges();
    }))
    this.events.observe("sync:completed")
    .subscribe(() => {
      this.agendaPlanTabs = this.uiService.getAgendaTabs();
    });
    this.callPlanOfflineService.segmentCallPlansList$.pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      if(data && data.hasUpdatedData){
        this.getFormattedCallPlans();
      }
    })
    this.uiService.toolsActivityActive = true;
  }

  aggregateRepCallPlans(repPlansArray){
    var a = [];

    if (Array.isArray(repPlansArray)) {
      // Filter out possible duplicates due to HCP having more than one specialty
      const uniqRepPlansArray = _.uniqBy(repPlansArray, 'indskr_customercallplanid');
      uniqRepPlansArray.map((c)=>{
        var id = a.findIndex(o=>o.cycleplanid==c.cycleplanid);
        c.customerName = c.contactFirstName + " " + c.contactLastName
        if(id>-1) {
          a[id].repPlans.push(c)
          a[id].actualCalls += c.indskr_actualcalls?c.indskr_actualcalls:0
          a[id].totalGoalCalls += c.indskr_hocalls?c.indskr_hocalls:0
          a[id].actualEmails += c.indskr_actualemails?c.indskr_actualemails:0
          a[id].totalGoalEmails += c.indskr_hoemails?c.indskr_hoemails:0
          a[id].allRepCallPlansApproved = a[id].allRepCallPlansApproved==true && c.statuscode == 2?true:false
        }
        else{
            a.push({'cycleplanid': c.cycleplanid,
                      'cycleplanname':c.cycleplanname,
                      'repPlans':[c],
                      'indskr_enddate':c.indskr_enddate,
                      'indskr_startdate':c.indskr_startdate,
                      'actualCalls':c.indskr_actualcalls || 0,
                      'totalGoalCalls':c.indskr_hocalls || 0,
                      'actualEmails':c.indskr_actualemails || 0,
                      'totalGoalEmails':c.indskr_hoemails || 0,
                      'allRepCallPlansApproved': c.statuscode == 2?true:false,
                      'productName':c.productid_Formatted || '',
                      'suggestionApprovalneeded': c.cycleplansuggestionapprovalneeded 
                    })
        }
      });
    }
    return a;
  }

  private initCallPlanHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      imgSrc: 'assets/imgs/back_to_home_btn.svg',
      isDisabled: false,
      align: "left",
    })
    this.indHeaderLeftModel = {
      id: 'coaching-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.fromPlanTab ? this.translate.instant('HOME') : this.translate.instant('CALL_PLANS'),
      mode: true,
      isOnActPage: this.fromPlanTab ? true : false,
      controls: buttons,
    };
  }
  initializeSectionHeader() {
    this.mycallplanPastHeaderModel = {
      id: 'myPastCallPlan',
      title : `${this.translate.instant('PAST')} (${this.myFormattedCallPlans?.past?.length})`,
      controls: []
    }
    this.mycallplanCurrentHeaderModel = {
      id: 'myPastCallPlanCurrent',
      title : `${this.translate.instant('CURRENT')} (${this.myFormattedCallPlans?.present?.length})`,
      isHighlighted: true,
      controls: []
    }
    this.mycallplanFutureHeaderModel = {
      id: 'myPastCallFuture',
      title : `${this.translate.instant('FUTURE')} (${this.myFormattedCallPlans?.future?.length})`,
      controls: []
    }
    this.filteredListPastHeaderModel = {
      id: 'filteredPastCallPlan',
      title : `${this.translate.instant('PAST')} (${this.filteredList?.past?.length})`,
      controls: []
    }
    this.filteredListCurrentHeaderModel = {
      id: 'filteredCurrentCallPlan',
      title : `${this.translate.instant('CURRENT')} (${this.filteredList?.present?.length})`,
      isHighlighted: true,
      controls: []
    }
    this.filteredListFutureHeaderModel = {
      id: 'filteredFutureCallPlan',
      title : `${this.translate.instant('FUTURE')} (${this.filteredList?.future?.length})`,
      controls: []
    }
    this.teamCallplanPastHeaderModel = {
      id: 'myPastCallPlan',
      title : `${this.translate.instant('PAST')} (${this.teamFormattedCallPlans?.past?.length})`,
      controls: []
    }
    this.teamCallplanCurrentHeaderModel = {
      id: 'myPastCallPlanCurrent',
      title : `${this.translate.instant('CURRENT')} (${this.teamFormattedCallPlans?.present?.length})`,
      isHighlighted: true,
      controls: []
    }
    this.teamCallplanFutureHeaderModel = {
      id: 'myPastCallFuture',
      title : `${this.translate.instant('FUTURE')} (${this.teamFormattedCallPlans?.future?.length})`,
      controls: []
    }
    this.noSearchResultHeaderModel = {
      id: 'no-search-result-header',
      title: `${this.translate.instant('AL_RESULTS')} (0)`,
      controls: []
    };
  }

  updateEmptyMessage(){
    let datasize = 0
    if(this.callPlanMode === 'team' && this.teamFormattedCallPlans){
      if(!this.searching && (this.teamFormattedCallPlans.past.length > 0 || this.teamFormattedCallPlans.present.length >0 ||
        this.teamFormattedCallPlans.future.length > 0 )){
          datasize = 1
        }
    }
    else if(this.myFormattedCallPlans){
      if(!this.searching && (this.myFormattedCallPlans.past.length > 0 ||
        this.myFormattedCallPlans.present.length >0 || this.myFormattedCallPlans.future.length > 0 )){
          datasize = 1
        }
    }
    if(this.searching && (this.filteredList.past.length > 0 || this.filteredList.present.length > 0 ||
      this.filteredList.future.length > 0)){
        datasize = 1
    }
    this.uiService.updateNothingSelectedScreenMessageFor(datasize)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.callPlanOfflineService.callPlanSearchText = '';
    this.contactService.contactInformation = undefined;
    this.fromPlanTab = false;
    if (!(this.uiService.activitiesPageTab === "plans" && this.agendaFooterService.planTabSegment === "goalsPlansTab")) {
      this.callPlanOfflineService.segmentCallPlans = [];
      this.callPlanOfflineService.selectedCallPlanAtHome = undefined;
      this.searchConfigService.myCallPlansSearchIndexesConfig.forEach(o => o.values = [])
    }
    this.callPlanOfflineService.teamSegmentCallPlans = []
    this.callPlanOfflineService.teamPositionsOtherRepPlans = []
    // this.searchConfigService.myCallPlansSearchIndexesConfig.forEach(o => o.values = [])
    this.searchConfigService.teamCallPlansSearchIndexesConfig.forEach(o => o.values = [])

    this.callPlanOfflineService.repNamesWithPositions = []
  }
  ionViewDidEnter(){
    if (this.callPlanOfflineService.callPlanAccessedFromScheduler){
      this.callPlanOfflineService.selectedCallPlan = undefined;
      this.callPlanOfflineService.callPlanAccessedFromScheduler = false;
    }
    else{
      setTimeout(() => {
        this.scrollTo('currentHeader');
      }, 0);
    }
    this.getFormattedCallPlans();
  }

  private getFormattedCallPlans() {
    this.myFormattedCallPlans = this.callPlanOfflineService.formattedCallPlans;
    this.teamFormattedCallPlans = this.callPlanOfflineService.formattedTeamCallPlans;
  }

  private scrollTo(data){
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it or use the content scrollInto routine
    let ele:any = document.getElementById(data);
    if(ele){
      ele.scrollIntoView(true);
      let yOffset = ele.offsetTop;
      this.content.scrollTo(0 , 0);
    }
  }

  public async handleCallPlanSelection(callPlan) {
    if(this.fromPlanTab) {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.callPlanOfflineService.selectedCallPlanAtHome = callPlan;
    }
    else this.uiService.activeView = 'CallPlanDetails';
    if(this.callPlanMode == 'mine'){
      if(!this.device.isOffline){
        const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
        this.callDataService.getCallPlans(dataRangeWithFutureBoundBySixMonths,false,false,callPlan.cycleplanid);
      }
    }
    this.callPlanOfflineService.selectedCallPlan = callPlan;
    if(this.fromPlanTab) this.onCallPlanSelectOnPlanTab.emit(callPlan);
    else this.onCallPlanSelect.emit(callPlan);
    let insightsEventName = this.callPlanMode == 'mine'?'MyCallPlanSegmentCallPlanClicked':'TeamCallPlanSegmentCallPlanClicked'
    this.trackingService.tracking(insightsEventName, TrackingEventNames.CALLPLAN)
  }


  trackInInsights(evt:string){
    if(evt == "mine"){
      this.trackingService.tracking('MyCallPlansTab', TrackingEventNames.CALLPLAN);
    }
    if(evt == "team"){
      this.trackingService.tracking('TeamCallPlanTab', TrackingEventNames.CALLPLAN);
    }
    this.callPlanOfflineService.setCallPlanMode(evt);
      setTimeout(()=>{
        this.scrollTo(evt=="mine"?'currentHeader':'currentTeamHeader')
      },0)
  }

  public onCloseModal() {
    this.trackingService.tracking('CallPlanBack', TrackingEventNames.CALLPLAN)
    this.navService.popWithPageTracking().then( () => {
      this.uiService.activeView = this.uiService.prevView;
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        if(_.isEmpty(this.activityService.selectedActivityAtHome) && _.isEmpty(this.activityService.selectedActivity)) {
          this.uiService.showRightPane = false;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
      }
    });
    this.callPlanOfflineService.selectedCallPlan = undefined;
    if (this.callPlanOfflineService.selectedCallPlanAtHome) {
      this.callPlanOfflineService.selectedCallPlan = this.callPlanOfflineService.selectedCallPlanAtHome;
    }
    this.callPlanOfflineService.callPlanPageView = '';
    this.uiService.toolsActivityActive = false;
    this.uiService.activitiesPagePlanTabSelectedPlan = this.uiService.activitiesPagePlanTabSelectedPlanAtHome;
    this.fromPlanTab = false;
    if (this.activityService.selectedActivity){
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome ? this.activityService.getActivityByID(this.activityService.selectedActivityAtHome.ID) : this.activityService.selectedActivity;
      this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
    }
    // this.accountService.selectedAccounts = [];
    // .then(() => {
    //   this.uiService.activeView = this.uiService.prevView;
    //   if (this.uiService.activeView === "Appointment")
    //     this.footerService.initButtons(FooterViews.Activities);
    // });
  }


  async segmentChange(segment) {
    if(segment == "mine") {
      this.searchText = this.translate.instant('SEARCH_MY_CALLPLANS');
    } else if (segment == "team") {
      this.searchText = this.translate.instant('SEARCH_TEAM_CALLPLANS');
      if(!this.callPlanOfflineService.teamSegmentCallPlans.length && this.waitingForTeamPlans && !this.device.isOffline){
        try {
          const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
          this.uiService.displayLoader({}, true);
          let a = this.aggregateRepCallPlans(await this.callDataService.getTeamCallPlans(dataRangeWithFutureBoundBySixMonths));
          this.callPlanOfflineService.teamSegmentCallPlans = a;
          this.getFormattedCallPlans();
          this.initializeSectionHeader();
          if(this.callPlanOfflineService.teamSegmentCallPlans.length){
            this.waitingForTeamPlans = false;
          }
          this.uiService.dismissLoader();
        } catch (error) {
          this.uiService.dismissLoader();
        }
      }
    }
    this.trackInInsights(segment);
    this.removeAllSelectedSuggestions()
    this.updateEmptyMessage()
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if(this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  clickSearchArea(ev){
    ev.stopPropagation();
    if(!this.suggestionsActive) {
      this.suggestionsActive = true;
      let searchToolName: any ="";
      if(this.callPlanMode =='mine'){
        if(!this.callPlanOfflineService.myRecentSearches || !this.callPlanOfflineService.myRecentSearches.length){
          searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Call Plans');
        }
      } else{
        if(!this.callPlanOfflineService.teamRecentSearches || !this.callPlanOfflineService.teamRecentSearches.length){
          searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Call Plans');
        }
      }
      if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
        this.suggestionsActive = false;
      }
    }
  }

  onInput(event) {
    this.searchKey = '';
    if(!this.shouldFireSearchChangeEvent){
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.target) ? event.target.value : '';
    // for input search text length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true; // to figure out results of search
      this.searchKey = params;
      if(this.callPlanMode =='mine'){
        this.searchCallPlansSuggestions(this.myFormattedCallPlans, params, event);
      }else {
        this.searchCallPlansSuggestions(this.teamFormattedCallPlans, params, event);
      }
    }
    // for input search text length is 0 to 2
    else {
        this.suggestionsData = [];
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.filteredList = { past: [], present: [], future:[] };
          this.searching = false;
          this.getFormattedCallPlans();
        }
        else{
          this.searchCallPlansList();
        }
        let searchToolName;
        if(this.callPlanMode =='mine'){
          if(!this.callPlanOfflineService.myRecentSearches || !this.callPlanOfflineService.myRecentSearches.length){
            searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Call Plans');
            }
        } else{
          if(!this.callPlanOfflineService.teamRecentSearches || !this.callPlanOfflineService.teamRecentSearches.length){
            searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Call Plans');
            }
          }
        if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
           this.suggestionsActive = false;
        }
    }
    this.updateEmptyMessage()
    this.initializeSectionHeader();
    this._cd.detectChanges();
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let toolName;
    if(this.callPlanMode =='mine'){
      toolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Call Plans');
    } else {
      toolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Call Plans');
    }
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_SEARCH'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.disableSaveSearch = true;
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                toolName?toolName.searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.selectedSuggestionsData.slice())
        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }
  //To display suggestions
  searchCallPlansSuggestions(callPlans: { past: any[], present: any[], future: any[] }, searchText: string, event?){
    if (searchText && callPlans) {
      searchText = searchText.toUpperCase();
      let callPlansSearchIndexConfig =  this.callPlanMode == 'mine'?this.searchConfigService.myCallPlansSearchIndexesConfig:this.searchConfigService.teamCallPlansSearchIndexesConfig;
      let toolNameForEntityCharacterSearch = this.callPlanMode == 'mine'?this.translate.instant('MY_CALL_PLANS'):this.translate.instant('TEAM_CALL_PLANS');
      this.suggestionsData = this.searchConfigService.fetchSuggestions(callPlansSearchIndexConfig,this.selectedSuggestionsData,
                              toolNameForEntityCharacterSearch ,this.callPlanSearchText)
        if(this.suggestionsData.length) this.suggestionsActive = true;
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.filteredList.past = callPlans.past;
          this.filteredList.present = callPlans.present;
          this.filteredList.future = callPlans.future;
          const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
          this.filteredList.past = this.filteredList.past.filter(callPlan=>{
            let callPlanString = this.getOjectValues(callPlan);
            return callPlanString.includes(formattedSearchText);
          });
          this.filteredList.present = this.filteredList.present.filter(callPlan=>{
            let callPlanString = this.getOjectValues(callPlan);
            return callPlanString.includes(formattedSearchText);
          });
          this.filteredList.future = this.filteredList.future.filter(callPlan=>{
            let callPlanString = this.getOjectValues(callPlan);
            return callPlanString.includes(formattedSearchText);
          });
        }
    } else {
        return [];
      }
  }

  getOjectValues(callPlanObject): string {
    let objString: string[] = [];
    let callPlanInclude = ['cycleplanname'];
    for (let callPlan in callPlanObject) {
      if (callPlanInclude.indexOf(callPlan) > -1 && callPlanObject[callPlan]) {
        if (Array.isArray(callPlanObject[callPlan])) {
          for (let i = 0; i < callPlanObject[callPlan].length; i++) {
            objString.push(this.getOjectValues(callPlanObject[callPlan][i]));
            }
          } else {
            objString.push(callPlanObject[callPlan]);
          }
        }
      }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  searchCallPlansList(){
    // second level search for call plans
    this.searching = true;
    let filteredCallPlans:{ past: any[], present: any[], future: any[] } = { past: [], present: [], future:[] };
    filteredCallPlans.past= (this.callPlanMode=='mine')?this.callPlanOfflineService.formattedCallPlans.past:this.callPlanOfflineService.formattedTeamCallPlans.past;
    filteredCallPlans.present= (this.callPlanMode=='mine')?this.callPlanOfflineService.formattedCallPlans.present:this.callPlanOfflineService.formattedTeamCallPlans.present;
    filteredCallPlans.future= (this.callPlanMode=='mine')?this.callPlanOfflineService.formattedCallPlans.future:this.callPlanOfflineService.formattedTeamCallPlans.future;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    // entity level character search
    if(entityLevelCharSearch) {
      const formattedSearchText = entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
      const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
      if (formattedSearchTextSplit) {
        formattedSearchTextSplit.forEach(searchText => {
          filteredCallPlans.past = filteredCallPlans.past.filter(callPlan => {
           let callPlanPastEntityLevel = this.getOjectValues(callPlan);
            return callPlanPastEntityLevel.includes(searchText);
          });
          filteredCallPlans.present = filteredCallPlans.present.filter(callPlan => {
           let callPlanPresentEntityLevel = this.getOjectValues(callPlan);
            return callPlanPresentEntityLevel.includes(searchText);
          });
          filteredCallPlans.future = filteredCallPlans.future.filter(callPlan => {
           let callPlanFutureEntityLevel = this.getOjectValues(callPlan);
           return callPlanFutureEntityLevel.includes(searchText);
          });
        });
     }
    }
    filteredCallPlans.past = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy,filteredCallPlans.past)
    this.filteredList.past = filteredCallPlans.past;
    filteredCallPlans.present = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy,filteredCallPlans.present)
    this.filteredList.present = filteredCallPlans.present;
    filteredCallPlans.future = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy,filteredCallPlans.future)
    this.filteredList.future = filteredCallPlans.future;
    this.initializeSectionHeader();
  }
  // end advance search functions

  onInputForGeneralSearch(event) {
    let filteredCallPlans:{ past: any[], present: any[], future: any[] } = { past: [], present: [], future:[] };
        filteredCallPlans.past = this.callPlanOfflineService.formattedCallPlans.past;
        filteredCallPlans.present = this.callPlanOfflineService.formattedCallPlans.present;
        filteredCallPlans.future = this.callPlanOfflineService.formattedCallPlans.future;
    if (event && event.value) {
      const val = event.value;
      if (val && val.trim() != '') {
        this.searching = true;
        const formattedSearchText = this.searchConfigService.convertFormattedString(val).toUpperCase();
        filteredCallPlans.past = filteredCallPlans.past.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
        filteredCallPlans.present = filteredCallPlans.present.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
        filteredCallPlans.future = filteredCallPlans.future.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
         });
      } else {
        this.searching = false;
      }
    } else {
      this.searching = false;
    }
    this.filteredList.past = filteredCallPlans.past;
    this.filteredList.present = filteredCallPlans.present;
    this.filteredList.future = filteredCallPlans.future;
    this.initializeSectionHeader();
  }

  ionSearchFocus(ev) {
    try {
      let el = this.contactListElement.nativeElement.ownerDocument.getElementById('allCallPlans');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }
  // Recent search list
  handleFacetSelection(data:SelectedSuggestionPillDataModel){
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a,b)=>{
      if(a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.callPlanSearchText = '';
    this.suggestionsData= [];
    this.shouldFireSearchChangeEvent = false;
    this.searchCallPlansList();
    if(!data.isComingFromRecentSearch){
      let recentSearches;
      recentSearches = (this.callPlanMode =='mine')?this.callPlanOfflineService.myRecentSearches:this.callPlanOfflineService.teamRecentSearches;
      if(!recentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index)=>index>2);
        if(this.callPlanMode == 'mine'){
          this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_CALL_PLANS_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = recentSearches;
          return doc;
          })
        }else {
          this.disk.updateOrInsert(DB_KEY_PREFIXES.TEAM_CALL_PLANS_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = recentSearches;
          return doc;
          })
        }
      }
    }
  }

  handleSavedSearchSelection(data){
    this.selectedSuggestionsData = [];
    if(data && data.categoryValuePairs){
      data.categoryValuePairs.forEach(catValPair=>{
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a,b)=>{
        if(a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.callPlanSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchCallPlansList();
    }
  }

  removeAllSelectedSuggestions(){
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = { past: [], present: [], future:[] };
    this.callPlanSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
  }

  removeSelectedSuggestion(suggestion){
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
    if(this.selectedSuggestionsData.length == 0){
      //this.content.resize();
      this.filteredList = { past: [], present: [], future:[] };
      this.callPlanSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
    }
    else{
      this.searchCallPlansList();
    }
  }

  setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('MY_CALL_PLANS'),
         value: "mine"
      },
      {
       displayText: this.hasTeamPlansEnabled ? this.translate.instant('TEAM_CALL_PLANS') : '',
       value: "team",
       disable: !this.hasTeamPlansEnabled || (this.device.isOffline && this.callPlanOfflineService.teamSegmentCallPlans.length == 0)
      },
    ]
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }

}
