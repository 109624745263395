<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="false"
    [searchDisabled]="filteredEventsList.length === 0 && !searchInput" [(searchText)]="searchInput"
    (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="handleSearch()"
    (onFilterClick)="openFilter($event)"></ind-header-left>

  <!-- <ind-key-controls-area *ngIf="eventsListKeyControlAreaModel" [viewData]="eventsListKeyControlAreaModel"></ind-key-controls-area> -->
</ion-header>
<ion-content [ngClass]="{'vectorimageleft' : !searchInput && filteredEventsList.length <= 0}">
  <ion-list *ngIf="eventsData && filteredEventsList">
    <ng-container *ngIf="selectedItems.length > 0">
      <ind-section-header [viewData]="selectedEventsHeader"></ind-section-header>
      <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
    </ng-container>

    <ion-item-divider class="normal-divider" sticky='true'>
      <span class="eventlistHeaderText" [ngClass]="{'filterStyleHeading' :searchInput}">{{(searchInput || typeFilter) ? ('AL_RESULTS'
        | translate) : ('ALL_EVENTS' | translate)}} ({{filteredEventsList.length}})</span>
      <!-- <button item-end icon-only clear ion-button (click)='sortEvents($event)'>
                <img class="sortIcon" src="assets/imgs/sort_with_double_arrows.svg" width="16px">
                {{sortBy?.text}}
                <ion-icon class="filterIcon filterStyleHeading" name="md-arrow-dropdown"></ion-icon>
            </button> -->
    </ion-item-divider>
    <!-- Extra Item for Event from Genee: Start -->
    <main-card class="addedEventItem" *ngIf="eventFromGenee" [viewData]="eventFromGenee"></main-card>
    <!-- Extra Item for Event from Genee: End -->
    <main-card *ngFor="let item of filteredEventsList" [id]="item.id" [viewData]="item"></main-card>
  </ion-list>
  <ion-infinite-scroll *ngIf="filteredEventsList.length > 0" (ionInfinite)="doInfinite($event)" threshold="30%">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <div class="no-data-message" *ngIf="!searchInput && !filteredEventsList.length">{{'NO_EVENTS_TOOL' | translate}}</div>
  <ind-no-search-result *ngIf="searchInput && filteredEventsList.length === 0"
    [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar [footerView]="'masterView'" [selectedView]="'orders'"></footer-toolbar>
<!-- </ion-footer> -->
