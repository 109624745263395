import {Component, Input} from "@angular/core";

@Component({
  selector: 'ind-no-search-result',
  templateUrl: 'ind-no-search-result.html',
  styleUrls: ['ind-no-search-result.scss']
})
export class IndNoSearchResultComponent {
  @Input() text: string = '';
  @Input() src: string = 'assets/imgs/search-illustration.svg'

  constructor(
  ) {}
}
