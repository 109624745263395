import { EmailTemplateServices } from './../../data-services/email-templates/email-template.service';
import { AppointmentActivity } from './../../classes/activity/appointment.activity.class';
import { Activity } from './../../classes/activity/activity.class';
import { EmailTemplateType } from './../../classes/email-templates/email-template.class';
import { ConsentService } from './../consent/consent.service';
import { Injectable } from "@angular/core";
import { format, isToday, isTomorrow, isValid, isYesterday } from "date-fns";

import { Events } from '@omni/events';
import { LoadingController } from "@ionic/angular";
import _, { cloneDeep } from "lodash";
import { BehaviorSubject } from "rxjs";
import {
  AttachmentInteractionDetails,
  ContactProperties,
  EmailActionType,
  EmailActivity,
  EmailActivityParty, EmailAddress,
  EmailAttachment,
  EmailViewType,
  MeetingProperties,
  ProductProperties,
  UserProperties,
  EmailStatusCodes
} from "../../classes/activity/email.activity.class";
import { FeatureActionsMap, User } from "../../classes/authentication/user.class";
import { Brand } from "../../classes/brand/brand.class";
import {
  ContentToken,
  ReplacementToken,
  ResourceEmailTemplate
} from "../../classes/email-templates/email-template.class";
import { Resource } from "../../classes/resource/resource.class";
import { Endpoints } from "../../../config/endpoints.config";
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from "../../config/pouch-db.config";
import { DeltaService, EntityNames, EntitySyncInfo } from "../../data-services/delta/delta.service";
import { EmailDataService } from "../../data-services/email/email.data.service";
import { ActivityService } from "../activity/activity.service";
import { AuthenticationService } from "../authentication.service";
import { BrandOfflineService } from "../brand/brand.service";
import { ContactOfflineService } from "../contact/contact.service";
import { DeviceService } from "../device/device.service";
import { DiskService } from "../disk/disk.service";
import { EventsService } from "../events/events.service";
import { ResourceService } from "../resource/resource.service";
import { TrackingEventNames, TrackService } from '../logging/tracking.service';
import { DateTimeFormatsService } from "../date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import { TranslateService } from "@ngx-translate/core";
import {Channel, ChannelActivityType, ChannelType} from "../../classes/consent/channel.class";
import { Contact } from '../../classes/contact/contact.class';
import { UIService } from '../ui/ui.service';
import { SearchConfigService } from '../search/search-config.service';
import { SelectedSuggestionPillDataModel } from '../../models/search-config-data-model';
import { GlobalUtilityService } from '../global-utility.service';
import { AccompainedUser } from '@omni/classes/activity/accompained-user.class';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { DatePipe } from '@angular/common';
import * as moment from "moment";
import  ja  from 'date-fns/locale/ja';
import  es  from 'date-fns/locale/es';
import  fr  from 'date-fns/locale/fr';
import  en  from 'date-fns/locale/en';
import tr from '@angular/common/locales/tr';
import pt from '@angular/common/locales/pt';
import it from '@angular/common/locales/it';
import { LocalizationService } from '../localization/localization.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { CallPlanDataService } from '@omni/data-services/call-plan/call-plan.data.service';
import { AlertService } from '../alert/alert.service';

declare var cordova:any;

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  public emailTemplates: ResourceEmailTemplate[] = [];
  public matchedMessageTemplates: ResourceEmailTemplate[] = [];
  public contentTokens: ContentToken[] = [];
  private selectedTemplate$ = new BehaviorSubject<ResourceEmailTemplate>(null);
  selectedTemplate = this.selectedTemplate$.asObservable();
  private selectedContentToken$ = new BehaviorSubject<ContentToken>(null);
  selectedContentToken = this.selectedContentToken$.asObservable();
  isResourceDisabled: boolean = true;
  private currentEmailSource = new BehaviorSubject<EmailActivity>(undefined);
  currentEmailObserver = this.currentEmailSource.asObservable();
  public selectedActivity: EmailActivity;
  public viewType: EmailViewType;
  public isRemoteEmail: boolean = false;
  public resourceInteractionDetails;
  //TC-594 track if user initiate actions from the action bar
  public isActionTriggeredFromFooter: boolean = false;
  public disableSendButton: boolean = true;
  public disableMarkComplete: boolean = false;
  public isMessageExternal: boolean = false;
  public emailFromGeneeMessage: any = {
    message : '',
    messageFlag : false
  }
  errorHandler: any;
  emailProductSKU: Brand[] = [];
  private openedApp: boolean = false;
  public isTemplateNewlyAdded: boolean = false;
  public template: ResourceEmailTemplate;
  public isNotifiedTokenMessageInOffline: boolean = false;

  private locale;
  public selectedCalendarInviteTemplate: ResourceEmailTemplate;
  constructor(
    private uiService:UIService,
    private emailDataService: EmailDataService,
    private activityService: ActivityService,
    private authService: AuthenticationService,
    private events: Events,
    private eventService: EventsService,
    private disk: DiskService,
    private deltaService: DeltaService,
    private deviceService: DeviceService,
    private trackingService: TrackService,
    private contactService: ContactOfflineService,
    private brandService: BrandOfflineService,
    private loadingCtrl: LoadingController,
    private consentService: ConsentService,
    private resourceService: ResourceService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private searchConfigService: SearchConfigService,
    private emailTemplateServices: EmailTemplateServices,
    private globalUtility: GlobalUtilityService,
    private socialSharing: SocialSharing,
    private datePipe: DatePipe,
    public authenticationService: AuthenticationService,
    private localizationService: LocalizationService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private callPlanDataService: CallPlanDataService,
    private alertService: AlertService,
  ) {
    this.eventService.observe('sync:completed').subscribe(() => {
      if (
        this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) ||
        this.authService.hasFeatureAction(FeatureActionsMap.EMAIL_ACTIVITY) ||
        this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_NON_NATIVE)
      ) {
        this.disk
          .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES)
          .then((data: ResourceEmailTemplate[]) => {
            this.emailTemplates = data;
            data.forEach((template) =>
              this.mapEmailTeamplateFieldsToSearchIndex(template)
            );
            console.log(
              `Email Templates from disk : ${data ? data.length : 0}`
            );
          });
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
      }
    });
    this.initWeCom();
  }

  getProduct(): any {
    const product = this.emailProductSKU.find(e => e.ID === this.selectedActivity.product_id);
    return product ? product : undefined;
  }

  public async removeDeactivatedEntities() {
    if (this.selectedActivity && ((this.selectedActivity.channelType === ChannelType.EMAIL && this.selectedActivity.emailStatus === EmailStatusCodes.Draft
      && !this.activityService.teamViewActive) || this.selectedActivity.isMessageExternal === true)) {
      let payload = {};
      this.getInActiveEmailActivityParties(payload);
      this.getInActiveTemplateAndResource(payload);
      if (Object.keys(payload).length) {
        this.notificationService.notify(this.translate.instant("REMOVED_DEACTIVATED").replace("{{activityType}}", new String(this.translate.instant("MESSAGE")).toLowerCase()), 'Email Activity Detail', 'top', ToastStyle.INFO);
        if (this.deviceService.isOffline || this.selectedActivity.ID.includes('offline')
          || this.activityService.hasOfflineEmailData(this.selectedActivity.offlineActivityId)) {
          this.updateSelectedActivity(payload);
          await this.updateOfflineDocument();
        } else {
          await this.emailDataService.updateEmailActivity(payload, this.selectedActivity.ID).then(async () => {
            // updating selected activity to handle network disconnection before online call
            this.updateSelectedActivity(payload);
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.selectedActivity, true);
            await this.activityService.addActivity(this.selectedActivity, true);
            // this.activityService.isActivityUpdate = true;
          }).catch((err) => {
            console.log("Error occurred while removing deactivated entities in email: ", err);
          });
        }
      }
    }
  }
  private async updateOfflineDocument() {
    const idToBeUpdated: string = this.selectedActivity.offlineActivityId;
    if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
      this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
      await this.disk.createOfflineEmailActivity(this.selectedActivity);
    }
    else {
      this.disk.updateofflineEmailActivtyDocument(this.selectedActivity);
    }
    //update local db for this activity
    this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.selectedActivity, true);
    await this.activityService.addActivity(this.selectedActivity, true);
    // this.activityService.isActivityUpdate = true;
    this.activityService.publishActivityEvent({action: "Update", activity: this.selectedActivity});

  }

  updateSelectedActivity(payload: {}) {
    if (payload["emailAddresses"]) {
      this.updateEmailActivtyDetailsOnContactSelection(this.selectedActivity, this.selectedActivity.emailActivityParties);
    }
    if (payload["template_id"] === null) {
      this.selectedActivity.template_id = null;
      this.selectedActivity.templateRefProductId = null;
      this.selectedActivity.description = "";
      this.selectedActivity['emailSubject'] = payload["subject"];
      this.selectedActivity.info = "";
      if (this.selectedActivity.emailAttachments && payload["deleteAttachments"]) {
        this.selectedActivity.emailAttachments = [];
      }
    }
    if (this.selectedActivity.template_id && this.selectedActivity.emailAttachments && payload["deleteAttachments"]) {
      const deleteAttachmentIds = payload["deleteAttachments"];
      this.selectedActivity.emailAttachments = this.selectedActivity.emailAttachments.filter(attachment => !deleteAttachmentIds.includes(attachment.indskr_emailattachmentid));
    }
    this.setCurrentEmail(this.selectedActivity);
    if (!this.uiService.toolsActivityActive){
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  private getInActiveTemplateAndResource(payload: {}) {
    if (this.selectedActivity.template_id && this.selectedActivity.template_id.length) {
      const template = this.getEmailTemplateById(this.selectedActivity.template_id);
      if (!template || !(template.statuscode == 1 || template.statuscode == 548910001)) {
        payload["template_id"] = null;
        payload["product_id"] = null;
        payload["indskr_therapeuticareaid"] = null;
        payload["description"] = "";
        payload["subject"] = "New Message";
        if (this.selectedActivity.emailAttachments) {
          let deleteAttachmentsReq = [];
          this.selectedActivity.emailAttachments.filter(attachment => attachment.indskr_emailattachmentid !== null).forEach(attachment => {
            deleteAttachmentsReq.push({ indskr_emailattachmentid: attachment.indskr_emailattachmentid });
          });
          payload["deleteAttachments"] = deleteAttachmentsReq;
        }
      }
      else {
        this.getInActiveEmailAttachments(payload);
      }
    }
  }

  private getInActiveEmailAttachments(payload: {}) {
    if (this.selectedActivity.emailAttachments && this.selectedActivity.emailAttachments.length) {
      let deleteAttachmentsReq = [];
      let offlineAttachmentsToRemove: EmailAttachment[] = [];
      this.selectedActivity.emailAttachments.forEach(attachment => {
        const resource = this.resourceService.getResourceById(attachment.indskr_resourceid, attachment.indskr_resourceid);
        if (!resource || resource.stateCode === 1) {
          if(attachment.indskr_emailattachmentid && attachment.indskr_emailattachmentid.length) {
            deleteAttachmentsReq.push({ indskr_emailattachmentid: attachment.indskr_emailattachmentid });
          } else {
            //Offline added attachment, yet to sync to dynamics. On delta resource is removed, remove it from attachments
            offlineAttachmentsToRemove.push(attachment);
          }
        }
      });
      if(offlineAttachmentsToRemove.length) {
        this.selectedActivity.emailAttachments = _.difference(this.selectedActivity.emailAttachments, offlineAttachmentsToRemove);
      }
      if (deleteAttachmentsReq.length) {
        payload["deleteAttachments"] = deleteAttachmentsReq;
      }
    }
  }

  private getInActiveEmailActivityParties(payload: {}) {
    if (this.selectedActivity.emailActivityParties && this.selectedActivity.emailActivityParties.length) {
      let emailActivityPartyToRemove: EmailActivityParty[] = [];
      this.selectedActivity.emailActivityParties.forEach(party => {
        const contact: Contact = this.contactService.getContactByID(party.indskr_contactid);
        if (!contact || !contact.isActive) {
          emailActivityPartyToRemove.push(party);
        }
      });
      if (emailActivityPartyToRemove && emailActivityPartyToRemove.length > 0) {
        let emailAddressId = [];
        const activeEmailActivityParties: EmailActivityParty[] = _.difference(this.selectedActivity.emailActivityParties, emailActivityPartyToRemove);
        this.selectedActivity.emailActivityParties = activeEmailActivityParties;
        this.prepareEmailActivityPartReqPayload(activeEmailActivityParties, emailAddressId);
        payload["emailAddresses"] = emailAddressId;
      }
    }
  }

  public getEmailTemplateById(id: String) {
    return this.emailTemplates.find(template => template.indskr_emailtemplateid === id);
  }

  public async loadEmailTemplatesDataFromLocalDb() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES).then((data: ResourceEmailTemplate[]) => {
      this.emailTemplates = data;
      data.forEach(template => this.mapEmailTeamplateFieldsToSearchIndex(template));
      console.log(`Email Templates from disk : ${data ? data.length : 0}`);
    });
  }
  public async loadEmailTemplateContentTokensFromLocalDb() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATE_CONTENT_TOKENS).then((data: ContentToken[]) => {
      this.contentTokens = data;
      console.log(`Email template content tokens from disk : ${data ? data.length : 0}`);
    });
  }


  public async syncEmailActivitiesData(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) || this.authService.hasFeatureAction(FeatureActionsMap.EMAIL_ACTIVITY) || this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) {
      if (loadFromDbOnly) {
        // Email activity should have been already loaded from "syncActivity" function in case of loadFromDbOnly = true
        // Thus we only need to load templates here.
        await this.loadEmailTemplatesDataFromLocalDb();
        await this.loadEmailTemplateContentTokensFromLocalDb();
        await this.getEmailProducts(loadFromDbOnly);
      } else {
        //if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) await this.getEmailActivities(dataRange);
        await this.getEmailTemplates();
        await this.getEmailProducts(loadFromDbOnly);

      const contentTokenSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.contentToken,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      //flow for content tokens - always replace
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATE_CONTENT_TOKENS);
      await this.emailDataService.getContentTokens().then(data => {
        if (Array.isArray(data)) {
          contentTokenSyncInfo.totalSynced = data.length;
          data.forEach((tk: ContentToken) => {
            tk._id = DB_KEY_PREFIXES.EMAIL_TEMPLATE_CONTENT_TOKEN + tk.indskr_contenttokenid;
          });
          this.disk.bulk(data);
        }
        this.contentTokens = data;
        this.deltaService.addEntitySyncInfo(contentTokenSyncInfo);
      }).catch(err => {
        console.error('syncEmailActivities: getContentTokens: ', err);
        this.deltaService.addSyncErrorToEntitySyncInfo(contentTokenSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.GET_CONTENT_TOKENS, err);
        this.deltaService.addEntitySyncInfo(contentTokenSyncInfo);
      });
    }
    }
  }

  private async getEmailTemplates() {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_EMAIL_TEMPLATES);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime || this.authService.shouldContentFullSync;
    const newLastUpdatedTime = new Date().getTime();
    const emailTemplateSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.emailTemplate,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (isInitialSync) {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES);
    }
    await this.emailDataService.getTemplates(isInitialSync, syncState.lastUpdatedTime).then(
      async (data: ResourceEmailTemplate[]) => {
        console.log("Saving templates to disk...");
        await this.saveToDisk(isInitialSync, data);
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          emailTemplateSyncInfo.totalSynced = data.length;
        }
        data.forEach(template => this.mapEmailTeamplateFieldsToSearchIndex(template));
        this.deltaService.addEntitySyncInfo(emailTemplateSyncInfo);
        console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
        this.disk.retrieve(DB_KEY_PREFIXES.EMAIL_TEMPLATE_RECENT_SEARCHES, true).then((doc)=>{
          if(doc && doc.raw){
            this.emailTemplateServices.recentSearches = doc.raw
          }
          else {
            this.emailTemplateServices.recentSearches = [];
          }
        })
      }).catch(err => {
        console.error("Error occurred while fetching templates...")
        this.deltaService.addSyncErrorToEntitySyncInfo(emailTemplateSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.GET_ALL_EMAIL_ACTIVITIES, err);
        this.deltaService.addEntitySyncInfo(emailTemplateSyncInfo);
      });
  }

  public async getEmailProducts(loadFromDbOnly = false) {
    await this.emailDataService.fetchEmailProducts(loadFromDbOnly).then (
      async (data: Brand[]) => {
        console.log("Saving templates to disk...");
        if (Array.isArray(data)) {
          this.emailProductSKU = data.map(e => new Brand(e));
        }
      }).catch(err => {
        console.error("Error occurred while fetching templates...: ", err);
      });
  }

  getProductDetails(): string {
    const product = this.emailProductSKU.find(e => e.ID === this.selectedActivity.product_id);
    return product && product.name ? product.name  : '';
  }

  // private async getEmailActivities(dataRange: { from: string, to: string }) {
  //   const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_EMAIL_ACTIVITIES);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const emailActivitySyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.email,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };
  //   let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.GET_ALL_EMAIL_ACTIVITIES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to);
  //   url = isInitialSync ? url : url + '&lastUpdatedTime=' + syncState.lastUpdatedTime;
  //   await this.emailDataService.getEmailActivities(url).then(
  //     async (data) => {
  //       const newLastUpdatedTime = new Date().getTime();
  //       console.log("Saving email activities to disk...");
  //       if (isInitialSync)
  //         await this.activityService.mapFullSyncedEmailActivities(data, newLastUpdatedTime);
  //       else
  //         await this.activityService.mapDeltaSyncedEmailActivities(data, newLastUpdatedTime);
  //       syncState.lastUpdatedTime = newLastUpdatedTime;
  //       await this.disk.updateSyncState(syncState);
  //       if (Array.isArray(data)) {
  //         emailActivitySyncInfo.totalSynced = data.length;
  //       }
  //       this.deltaService.addEntitySyncInfo(emailActivitySyncInfo);
  //       console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
  //       // Update UI after sync is mapped
  //       if (!this.uiService.toolsActivityActive){
  //         this.events.publish('refreshAgenda');
  //       } else this.uiService.agendaRefreshRequired = true;
  //     }).catch(err => {
  //       console.error("Error occurred while fetching email activities...")
  //       this.deltaService.addSyncErrorToEntitySyncInfo(emailActivitySyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.email.GET_ALL_EMAIL_ACTIVITIES, err);
  //       this.deltaService.addEntitySyncInfo(emailActivitySyncInfo);
  //     });
  // }

  private saveToDisk(isInitialSync, data: ResourceEmailTemplate[]) {
    if (data && data.length > 0 && !isInitialSync) {
      data.forEach(async (template: ResourceEmailTemplate) => {
        template._id = DB_KEY_PREFIXES.EMAIL_TEMPLATE + template.indskr_emailtemplateid;
        if (!isInitialSync) {
          // if (template.track_action == 548910001 || template.statuscode !== 1) {
          if (template.statuscode == 2) {
              this.disk.retrieve(template._id.toString()).then(async (exist) => {
                if (exist) await this.disk.remove(template._id.toString());
              });
          } else {

            let exist = await this.disk.retrieve(template._id.toString());
              if (!exist) {
                await this.disk.updateOrInsert(template._id.toString(), doc => template)
                  .catch(error => console.error('EmailService: saveToDisk: ', error));
              } else {
                exist = {
                  ...exist,
                  indskr_description: template.indskr_description,
                  indskr_name: template.indskr_name,
                  indskr_editablebyuser: template.indskr_editablebyuser,
                  indskr_available_from: template.indskr_available_from,
                  indskr_available_until: template.indskr_available_until,
                  indskr_body: template.indskr_body,
                  product_id: template.product_id,
                  content_tokens: template.content_tokens,
                  createdon: template.createdon,
                  modifiedon: template.modifiedon,
                  indskr_thumbnailurl: template.indskr_thumbnailurl,
                  channelTypes: template.channelTypes,
                  productName: template.productName,
                  therapeuticAreaName: template.therapeuticAreaName,
                  indskr_type: template.indskr_type,
                  statuscode: template.statuscode,
                  indskr_fb_body: template.indskr_fb_body,
                  indskr_sms_body: template.indskr_sms_body,
                  indskr_whatsapp_body: template.indskr_whatsapp_body,
                  indskr_email_subject: template.indskr_email_subject
                }
                await this.disk.updateOrInsert(template._id.toString(), doc => exist)
                  .catch(error => console.error('EmailService: saveToDisk: ', error));
            }
          }
        }
      });
    }
    if (isInitialSync) {
      data = data.filter(tm => tm.statuscode === 1); //filter all published templates
      data.forEach((template: ResourceEmailTemplate) => {
        template._id = DB_KEY_PREFIXES.EMAIL_TEMPLATE + template.indskr_emailtemplateid;
      });
      this.disk.bulk(data);
    }
    console.log(`${data.length} template(s) has been written to disk`)
  }

  public async createNewEmailActivity(request: any): Promise<EmailActivity | any> {
    const ownerId: string = this.authService.user.systemUserID;
    let emailType = (request.emailType === EmailTemplateType.RemoteURL ? 'remoteurl_' : '').toLowerCase()
    delete request.emailType;
    request['offlineActivityId'] = `offline_email_${emailType}${new Date().getTime()}`;
    let channelType = request.channelType;
    let channelId;
    let channelActivityType;
    if(request.channelId && request.channelActivityType){
      channelId = request.channelId;
      channelActivityType = request.channelActivityType;
    }else{
      let channels = _.chain(this.consentService.allConsentChannelSubject.value).map(c => ([c.indskr_consentType.valueOf(), c.indskr_consenttypeid.valueOf()])).fromPairs().value();
      let channelsActivityType = _.chain(this.consentService.allConsentChannelSubject.value).map(c => ([c.indskr_consentType.valueOf(), c.activityType.valueOf()])).fromPairs().value();
      channelId = channels[channelType];
      channelActivityType = channelsActivityType[channelType];
    }
    const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
    request.subject = channel.indskr_consentType;
    if (request.channelType) delete request.channelType;
    if (request.channelId) delete request.channelId;
    if (request.channelActivityType) delete request.channelActivityType;
    request['indskr_datetimecreatedonapp'] = new Date().getTime().toString();
    if (this.deviceService.isOffline) {
      let emailActivity = await this.createNewOfflineEmail(request, ownerId, channelType, channelId, channelActivityType);
      emailActivity.channelName = channel.indskr_consentType + '';
      return emailActivity;
    }
    const loader = await this.loadingCtrl.create({});
    loader.present();
    if (request.emailActivityParties) delete request.emailActivityParties;
    try {
      let data = await this.emailDataService.createEmail(request, ownerId)
      request['activityid'] = data['activityid'];
      await this.emailDataService.updateEmailActivityInDynamics({ 'indskr_channel_Email@odata.bind': `indskr_consenttypes(${channelId})` }, data['activityid'])
      data['statecode'] = 0;
      data['activitytypecode'] = 'email';
      data['statuscode'] = 1;
      data['indskr_ownerid'] = ownerId;
      data["scheduledend"] = request["scheduledend"];
      data["scheduledstart"] = request["scheduledstart"];
      data["offlineActivityId"] = request["offlineActivityId"];
      data['channelType'] = channelType;
      data['indskr_channelid'] = channelId;
      data['channelActivityType'] = channelActivityType;
      let emailActivity: EmailActivity = new EmailActivity(data);
      emailActivity.channelName = channel.indskr_consentType + '';
      const _id = DB_KEY_PREFIXES.EMAIL_ACTIVITY + emailActivity.ID;
      emailActivity.lastUpdatedTime = new Date().getTime();
      await this.disk.createDocument(_id, data);
      loader.dismiss();
      return emailActivity;
    } catch (err) {
      console.log("Error ", err);
      const email = await this.createNewOfflineEmail(request, ownerId, channelType, channelId, channelActivityType);
      loader.dismiss();
      return email;
      // throw err;
    };
  }

  private async createNewOfflineEmail(request: any, ownerId: string, channelType: any, channelID: string, channelActivityType: string) {
    request['statecode'] = 0;
    request['statuscode'] = 1;
    request['indskr_ownerid'] = ownerId;
    request['activitytypecode'] = 'email';
    request['channelType'] = channelType;
    request['indskr_channelid'] = channelID;
    request['channelActivityType'] = channelActivityType;
    let emailActivity = new EmailActivity(request);
    emailActivity.ID = request['offlineActivityId'];
    await this.disk.createOfflineEmailActivity(emailActivity);
    this.activityService.addToOfflineEmailActivityIds(emailActivity.ID);
    return emailActivity;
  }

  public reset() {
    this.selectedTemplate$.next(null);
  }

  public getTitleOfEmailActivity(): string {
    if (!_.isEmpty(this.selectedActivity) && this.selectedActivity.emailActivityParties) {
      if (this.selectedActivity.emailActivityParties.length == 1) {
        return ((this.selectedActivity.emailActivityParties[0].contact_firstname || '') + " " + (this.selectedActivity.emailActivityParties[0].contact_lastname || ''));
      }
      else if (this.selectedActivity.emailActivityParties.length > 1) {
        this.sortedEmailActivtyParties(this.selectedActivity.emailActivityParties);
        return ((this.selectedActivity.emailActivityParties[0].contact_firstname || '') + " " + (this.selectedActivity.emailActivityParties[0].contact_lastname || '') + " +" +
          (this.selectedActivity.emailActivityParties.length - 1));
      }
    }
    return 'Message';
  }

  public async updateEmailActivityContactParties(emailActivityParties: EmailActivityParty[],channel:ChannelType, activityId: string, channelActivityType: ChannelActivityType): Promise<any> {
    const ownerId: string = this.authService.user.systemUserID;
    if(activityId.includes('offline'))return;
    if(channelActivityType == ChannelActivityType.SMS){
      const payload = emailActivityParties.map(con => {
        return {
          "indskr_contactid": con.indskr_contactid,
          "phoneNumbers": [
              con.contact_mobilephone,
          ]
        };
      })
      await this.emailDataService.updateEmailActivitySMSPartiesInDynamics(payload,activityId);
    }else{
      this.emailDataService.updateEmailActivityInDynamics({
        email_activity_parties: [
          {
            'partyid_systemuser@odata.bind': `systemusers(${ownerId})`,
            "participationtypemask": 1
          },
          ...emailActivityParties.map(party => ({
            'partyid_contact@odata.bind': `contacts(${party.indskr_contactid})`,
            'donotemail': true,
            'donotfax': true,
            'donotphone': true,
            'donotpostalmail': true,
            'participationtypemask': 2,
          }))
        ]
      }, activityId);
    }

  }
  public async updateEmailActivityParties(emailActivityParties: EmailActivityParty[], doNotRefreshAgenda?: boolean): Promise<any> {
    //Prepare request payload
    let emailAddressIds: string[] = [];
    this.prepareEmailActivityPartReqPayload(emailActivityParties, emailAddressIds);
    const contactFullName = !_.isEmpty(emailActivityParties) ? (emailActivityParties[0].contact_firstname && emailActivityParties[0].contact_firstname.length > 0) ? emailActivityParties[0].contact_firstname + " " + emailActivityParties[0].contact_lastname : emailActivityParties[0].contact_lastname : '';
    const subject = _.isEmpty(contactFullName) ? this.setSecondaryTextForMessages(this.selectedActivity)
      : (contactFullName + '' +
        (emailActivityParties.length > 1 ? (' +' + (emailActivityParties.length - 1)) : '')
        + ' - ' + this.setSecondaryTextForMessages(this.selectedActivity)); 
    this.selectedActivity.emailSubject = subject;
     
    if (this.deviceService.isOffline || this.selectedActivity.ID.includes('offline')
      || this.activityService.hasOfflineEmailData(this.selectedActivity.offlineActivityId)) {
      this.updateEmailActivtyDetailsOnContactSelection(this.selectedActivity, emailActivityParties);

      //add to offline update document hashmap
      // let idToBeUpdated: string = this.selectedActivity.offlineActivityId;
      // if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
      //   this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
      //   await this.disk.createOfflineEmailActivity(this.selectedActivity);
      // }
      // //update offline upload document for this activity
      // else (
      //   await this.disk.updateofflineEmailActivtyDocument(this.selectedActivity)
      // )
      // //update local db for this activity
      // await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.selectedActivity, true);
      // await this.activityService.addActivity(this.selectedActivity, true);
      // this.activityService.isActivityUpdate = true;
    } else {
      const ownerId: string = this.authService.user.systemUserID;
      
      let request: any = { emailAddresses: emailAddressIds, subject: subject };
      let isSocialChannel = this.selectedActivity.isMessageExternal ? true : this.selectedActivity.isSocialChannel;
      try {
        if (isSocialChannel) {
          await this.updateEmailActivityContactParties(emailActivityParties, this.selectedActivity.channelType, this.selectedActivity.ID, this.selectedActivity.channelActivityType);
          await this.emailDataService.updateEmailAddress({ subject: subject }, ownerId, this.selectedActivity.ID);
        } else {
          await this.emailDataService.updateEmailAddress(request, ownerId, this.selectedActivity.ID);
        }
        this.updateEmailActivtyDetailsOnContactSelection(this.selectedActivity, emailActivityParties);
      } catch (err) {
        console.error("failed to updated emailaddress")
      }
      //Add logic to update on indskrphonenumber entity
      /*await (isSocialChannel ? this.updateEmailActivityContactParties(emailActivityParties,this.selectedActivity.channelType, this.selectedActivity.ID, this.selectedActivity.channelActivityType) :
        this.emailDataService.updateEmailAddress(request, ownerId, this.selectedActivity.ID)).then(() => {
          console.info("updated emailaddress");
          //update selected activities
          this.updateEmailActivtyDetailsOnContactSelection(this.selectedActivity, emailActivityParties);
          // this.activityService.isActivityUpdate = true;
        }).catch(err => {
          console.error("failed to updated emailaddress")
        })*/
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.selectedActivity, true);
    }
    //Publish event to update the agenda list
    this.setCurrentEmail(this.selectedActivity);
    if (!this.uiService.toolsActivityActive) {
      if (!doNotRefreshAgenda) this.events.publish("refreshAgenda");
    }
    else {
      this.uiService.agendaRefreshRequired = true;
    }
    this.activityService.publishActivityEvent({ action: "Update", activity: this.selectedActivity });
   }

  private prepareEmailActivityPartReqPayload(emailActivityParties: EmailActivityParty[], emailAddressIds: string[]) {
    emailActivityParties.forEach(emailActivityParty => _.uniqBy(emailActivityParty.emailAddresses, 'email_addressid').forEach(emailAddress => emailAddressIds.push(emailAddress.email_addressid)));
  }

  public async updateEmailActivity(request: any, action: EmailActionType) {


    this.selectedActivity.modified = new Date();
    if (this.deviceService.isOffline || this.selectedActivity.ID.includes('offline')
      || this.activityService.hasOfflineEmailData(this.selectedActivity.offlineActivityId)) {
      this.setEmailPropertiesByAction(request, action);
      await this.updateOfflineDocument();
    }
    else {
      this.uiService.displayLoader();
      await (action === EmailActionType.STATUS
        ? this.emailDataService.updateEmailActivityInDynamics(request, this.selectedActivity.ID)
        : this.emailDataService.updateEmailActivity(request, this.selectedActivity.ID)).then(async () => {
          console.info("updated email activity");
          this.setEmailPropertiesByAction(request, action);
          this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.selectedActivity, true);
          await this.activityService.addActivity(this.selectedActivity, true);
          // this.activityService.isActivityUpdate = true;
          this.activityService.publishActivityEvent({action: "Update", activity: this.selectedActivity});
        }).catch(err => {
          console.error("failed to updated activity")
          if (action === EmailActionType.STATUS) {
            throw err;
          }
        });
    }
    this.setCurrentEmail(this.selectedActivity);
    //Publish event to update the agenda list
    // if (!this.uiService.toolsActivityActive){
    //   this.events.publish('refreshAgenda');
    // } else this.uiService.agendaRefreshRequired = true;
    this.uiService.dismissLoader();
  }


  private setEmailPropertiesByAction(request: any, action: EmailActionType) {
    switch (action) {
      case EmailActionType.SCHEDULE:
        const selectedDate: Date = new Date(request.scheduledend);
        this.selectedActivity.scheduledStart = selectedDate;
        this.selectedActivity.scheduledEnd = selectedDate;
        this.selectedActivity.resetScheduledStartLocale();
        break;
      case EmailActionType.TEMPLATE_ADD:
        this.selectedActivity['template_id'] = request.template_id;
        this.selectedActivity.description = request.description;
        this.selectedActivity.templateRefProductId = request.product_id;
        this.selectedActivity['emailSubject'] = request.subject;
        this.selectedActivity.indskr_therapeuticareaid = request.indskr_therapeuticareaid;
        if (request.template_id == null) {
          this.selectedActivity.info = "";
        } else {
          this.selectedActivity.info = request.subject;
        }
        break;
      case EmailActionType.TEMPLATE_UPDATE:
        this.selectedActivity.description = request.description;
        break;
      case EmailActionType.SUBJECT:
        this.selectedActivity["emailSubject"] = request.subject;
        this.selectedActivity["info"] = request.subject;
        break;
      case EmailActionType.NOTES:
       this.selectedActivity["indskr_emailnotes"] = request.indskr_emailnotes;
      this.selectedActivity["info"] = request.indskr_emailnotes;
      break;
      case EmailActionType.PRODUCT:
        this.selectedActivity["product_id"] = request.product_id;
        this.selectedActivity['level1Product'] = request.level1Product;
        this.selectedActivity['level2Product'] = request.level2Product;
       break;
      case EmailActionType.STATUS:
        this.selectedActivity.status = request.statuscode;
        this.selectedActivity.state = request.statecode;
        this.selectedActivity.emailStatus = this.selectedActivity.status;
        this.selectedActivity.emailActivityParties.forEach(emailActivityParty => { emailActivityParty.email_statuscode = this.selectedActivity.status });
        break;

      default:
        console.log("unhandled update case: ", EmailActionType);
        break;
    }
    if (this.selectedActivity.appointmentid && ((this.selectedActivity.appointmentid && this.selectedActivity.appointmentid.length > 0 ) ||
    ((this.selectedActivity.phoneCallActivityId && this.selectedActivity.phoneCallActivityId.length > 0 ) ||
    (this.selectedActivity.phoneCallOfflineId && this.selectedActivity.phoneCallOfflineId.length > 0))) && this.selectedActivity.emailType != EmailTemplateType.RemoteURL)
      this.events.publish('updateEmbeddedActivity', this.selectedActivity);
  }

  public updateEmailActivtyDetailsOnContactSelection(emailActivity: EmailActivity, emailActivityParties: EmailActivityParty[]) {
    this.activityService.publishActivityEvent({action: "Update", activity: emailActivity});
    emailActivityParties.forEach(emailActivityParty => { emailActivityParty.email_statuscode = 1 })
    emailActivity.emailActivityParties = this.getSortedEmailActivityParties(emailActivityParties);
    if (emailActivity.emailActivityParties.length > 0) {
      let contactName = emailActivity.emailActivityParties[0].contact_firstname || '' + " " + emailActivity.emailActivityParties[0].contact_lastname || '';
      emailActivity.subject = emailActivity.emailActivityParties.length > 1 ? (contactName + " + " + (emailActivity.emailActivityParties.length - 1)) : contactName;
    }
    else {
      emailActivity.subject = this.translate.instant("MESSAGE");
    }
    if (this.selectedActivity && ((this.selectedActivity.appointmentid && this.selectedActivity.appointmentid.length > 0 ) ||
    ((this.selectedActivity.phoneCallActivityId && this.selectedActivity.phoneCallActivityId.length > 0 ) ||
    (this.selectedActivity.phoneCallOfflineId && this.selectedActivity.phoneCallOfflineId.length > 0))) &&
    this.selectedActivity.emailType != EmailTemplateType.RemoteURL)
      this.events.publish('updateEmbeddedActivity', this.selectedActivity);
  }

  private getSortedEmailActivityParties(emailActivtyParties: EmailActivityParty[]): EmailActivityParty[] {
    if (emailActivtyParties.length > 1) {
      let tempEmailActivityParties = emailActivtyParties;
      this.sortedEmailActivtyParties(tempEmailActivityParties);
      return tempEmailActivityParties;
    }
    return emailActivtyParties;
  }

  private sortedEmailActivtyParties(emailActivtyParties: EmailActivityParty[]) {
    emailActivtyParties.sort((contactA, contactB): number => {
      let contactAFullName = (contactA.contact_firstname && contactA.contact_firstname.length > 0) ? contactA.contact_firstname + " " + contactA.contact_lastname : contactA.contact_lastname;
      let contactBFullName = (contactB.contact_firstname && contactB.contact_firstname.length > 0) ? contactB.contact_firstname + " " + contactB.contact_lastname : contactB.contact_lastname;
      if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase())
        return 1;
      if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase())
        return -1;
      return 0;
    });
  }

  public async scrapEmailActivity(emailActivity: EmailActivity, fromParentActivity: boolean = false) {
    this.trackingService.tracking('EmailScrapped', TrackingEventNames.EMAIL, true);
    if (this.deviceService.isOffline && emailActivity.ID.includes('offline')) {
      emailActivity.deleted = true;
      await this.disk.removeOfflineCreatedEmailFromDocument(emailActivity);
      await this.disk.remove(DB_KEY_PREFIXES.EMAIL_ACTIVITY + emailActivity.ID);
      this.activityService.removeActivity(emailActivity);
    }
    else if (this.deviceService.isOffline && !emailActivity.ID.includes('offline')) {
      emailActivity.deleted = true;
      //add to offline update document hashmap
      let idToBeUpdated: string = emailActivity.offlineActivityId;
      if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
        this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
        await this.disk.createOfflineEmailActivity(emailActivity);
      }
      //update offline upload document for this activity
      else {
        this.disk.updateofflineEmailActivtyDocument(emailActivity);
      }
      await this.disk.remove(DB_KEY_PREFIXES.EMAIL_ACTIVITY + emailActivity.ID);
      this.activityService.removeActivity(emailActivity);
    } else {
      const loader = await this.loadingCtrl.create({});
      loader.present();
      await this.emailDataService.scrapEmailAddress(emailActivity.ID).then(() => {
        loader.dismiss();
        this.disk.remove(DB_KEY_PREFIXES.EMAIL_ACTIVITY + emailActivity.ID);
        this.activityService.removeActivity(emailActivity);
      }).catch(err => {
        loader.dismiss();
        console.log("err -->", err);
      });
    }
    this.activityService.publishActivityEvent({ action: 'Delete', activity: emailActivity });
    if (!this.uiService.toolsActivityActive)
    {
      this.events.publish("refreshAgenda");
    }
    else {
      this.uiService.agendaRefreshRequired = true;
    }
    this.events.publish('initTodo');
    if (emailActivity.appointmentid && emailActivity.appointmentid.length > 0)
        this.events.publish('deletedEmbeddedActivity', emailActivity);

    this.eventService.publish('messageActivityDeleted', emailActivity, fromParentActivity);
  }

  public async updateDummyEmailAddressInToField() {
    this.emailDataService.updateEmailAddress({ emailAddresses: this.authService.user.systemUserID }, this.authService.user.systemUserID, this.selectedActivity.ID).then(() => {
      console.info("updated dummy emailaddress");
    }).catch(err => {
      console.error("failed to update dummy emailaddress")
    })
  }

  public async sendEmailActivity(emailActivityId: string, isMessageExternal?) {
    this.deleteDisabledTokens(emailActivityId);
    if (this.deviceService.isOffline || this.selectedActivity.ID.includes('offline')
      || this.activityService.hasOfflineEmailData(this.selectedActivity.offlineActivityId)) {
        if(isMessageExternal) {
            let offlineTimelineToBeUpdated: boolean = false;
            this.selectedActivity.isMessageExternal = true;
            this.selectedActivity.status = 3;
            if((this.selectedActivity?.isPreviewEnabled && (this.selectedActivity.channelActivityType == ChannelActivityType.SMS || this.selectedActivity.channelActivityType == ChannelActivityType.WHATSAPP))) {
              this.selectedActivity.isPreviewEnabled = true;
              await this.shareMessageChannel().then(()=>{
                if(this.openedApp) {
                  this.selectedActivity.status = 548910000;
                  if(this.deviceService.isOffline) offlineTimelineToBeUpdated = true;
                } else {
                  this.selectedActivity.status = 1;
                }
              });
            }else {
              if(this.deviceService.isOffline) offlineTimelineToBeUpdated = true;
            }
            //Publish event to update the completed message in contact timeline offline mode
            if(offlineTimelineToBeUpdated) {
              await this.activityService.publishOfflineActivityForTimeline(this.selectedActivity);
            }
        } else {
          this.selectedActivity.status = 6;
        }
      const idToBeUpdated: string = this.selectedActivity.offlineActivityId;
      if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
        this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
        await this.disk.createOfflineEmailActivity(this.selectedActivity);
      } else {
        await this.disk.updateofflineEmailActivtyDocument(this.selectedActivity);
      }
      this.handleSendEmailResponse();
    } else {
      const loader = await this.loadingCtrl.create({});
      loader.present();
      if(isMessageExternal) {
        this.selectedActivity.isMessageExternal = true;
        if((this.selectedActivity?.isPreviewEnabled && (this.selectedActivity.channelActivityType == ChannelActivityType.SMS || this.selectedActivity.channelActivityType == ChannelActivityType.WHATSAPP))) {
          this.selectedActivity.isPreviewEnabled = true;
          await this.shareMessageChannel().then(()=>{
            if(this.openedApp) {
              this.emailDataService.sendEmailExternalActivity(this.selectedActivity, emailActivityId).then(() => {
                this.selectedActivity.status = 548910000;
                this.handleSendEmailResponse();
                const sentDate = new Date();
                this.updateEmailActivity({ scheduledend: sentDate.getTime(), scheduledstart: sentDate.getTime() }, EmailActionType.SCHEDULE).then(()=> {
                  this.updateEmailActivity({ statuscode: EmailStatusCodes.Shared, statecode: 1 }, EmailActionType.STATUS);
                });
                loader.dismiss();
              }).catch(() => {
                this.selectedActivity.status = 1;
                this.handleSendEmailResponse();
                loader.dismiss();
              });
            } else {
              this.selectedActivity.status = 1;
              this.handleSendEmailResponse();
              loader.dismiss();
            }
          })
        } else {
          await this.emailDataService.sendEmailExternalActivity(this.selectedActivity, emailActivityId).then(() => {
            if (this.selectedActivity.scheduledEnd.getTime() > new Date().getTime()) {
                this.selectedActivity.status = 1;
            }
            this.handleSendEmailResponse();
            loader.dismiss();
          }).catch(() => {
            this.selectedActivity.status = 1;
            this.handleSendEmailResponse();
            loader.dismiss();
          });
        }
      } else {
        await this.emailDataService.sendEmailActivity(emailActivityId).then(() => {
          if (this.selectedActivity.scheduledEnd.getTime() > new Date().getTime()) {
            this.selectedActivity.status = 9;
          }
          this.handleSendEmailResponse();
          loader.dismiss();
        }).catch(() => {
          this.selectedActivity.status = 1;
          this.handleSendEmailResponse();
          loader.dismiss();
        });
      }
    }
  }

  private handleSendEmailResponse() {
    if (!this.deviceService.isOffline && this.selectedActivity.scheduledEnd > new Date()) {
      this.selectedActivity.status = 9;
    } else if (this.selectedActivity.isMessageExternal && !this.selectedActivity?.isPreviewEnabled) {
      this.selectedActivity.status = 3;
    } else if (this.selectedActivity.isMessageExternal && this.selectedActivity?.isPreviewEnabled) {
      this.selectedActivity.status = this.openedApp ?  548910000 : 1;
    } else {
      this.selectedActivity.status = 6;
    }
    this.selectedActivity.statuscode = this.selectedActivity.status;
    this.selectedActivity.emailActivityParties.forEach(emailActivityParty => { emailActivityParty.email_statuscode = this.selectedActivity.status });
     //interaction for contacts for sent email
    if(this.selectedActivity.status === 6 || this.selectedActivity.status === 548910000){
      let contacts: Contact[] = [];
      this.selectedActivity.emailActivityParties.forEach(emailActivityParty => {
        contacts.push(this.contactService.getContactByID(emailActivityParty.indskr_contactid));
      });
      this.globalUtility.updateInteraction(contacts, undefined, 'Messages');
    }
    this.selectedActivity.emailStatus = this.selectedActivity.status;
    this.activityService.addActivity(this.selectedActivity, true);
    // this.activityService.isActivityUpdate = true;
    if (((this.selectedActivity.appointmentid && this.selectedActivity.appointmentid.length > 0 ) ||
    ((this.selectedActivity.phoneCallActivityId && this.selectedActivity.phoneCallActivityId.length > 0 ) ||
    (this.selectedActivity.phoneCallOfflineId && this.selectedActivity.phoneCallOfflineId.length > 0)))
      && this.selectedActivity.emailType != EmailTemplateType.RemoteURL)
      this.events.publish('updateEmbeddedActivity', this.selectedActivity);
      if (!this.uiService.toolsActivityActive){
        this.events.publish('refreshAgenda');
      } else this.uiService.agendaRefreshRequired = true;
    this.setCurrentEmail(this.selectedActivity);
    
    this.events.publish('messageActivitySent', this.selectedActivity);
    setTimeout(()=>{
      this.callPlanDataService.syncCallPlanActivities();
    }, 500);
  }

  public personalizeTemplate(email: EmailActivity, template: ResourceEmailTemplate = null) {
    let preview = this.personalizeTemplateBody(
      email.description,
      {
        user: this.authService.user,
        tokens: template?template.content_tokens:null,
        productId: template?template.product_id:(email.product_id?email.product_id:null),
        contactId: email.emailActivityParties && email.emailActivityParties.length > 0 && email.emailActivityParties[0].indskr_contactid,
        activity: this.activityService.selectedActivity
      },
      email.emailActivityParties
    );
    email.editablePreview = preview.editablePreview;
    email.templatePreview = preview.templatePreview;
  }

  public personalizeTemplateBody(body: string, { user, tokens, productId, contactId, activity }: { user?: User, tokens?: string[], productId?: string, contactId?: string, activity?: Activity }, emailActivityParties?: Array<EmailActivityParty>) {
    let templatePreview = body;
    if (user) {
      templatePreview = this.personalizeUsingObject(templatePreview, UserProperties, 
        {
          ...user,
          photoUrl: user.photoUrl ? `<img src=${user.photoUrl}>` : '',
        });
    }
    if (productId) {
      const brand: Brand = (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) ? this.getProductFromProductHierachy(productId) : this.brandService.getBrandByID(productId);
      templatePreview = this.personalizeUsingObject(templatePreview, ProductProperties, brand)
    }
    if (activity instanceof AppointmentActivity) {
      templatePreview = this.personalizeUsingObject(templatePreview, MeetingProperties, activity);
    }
    if (contactId) {
      let contact = this.contactService.getContactByID(contactId);
      let countryCode = '';
      let preferredLangCode = '';
      if(!_.isEmpty(contact.addressesList)) {
        let contactAddress = contact.addressesList.find(add => add.isPrimary) ? contact.addressesList.find(add => add.isPrimary) : contact.addressesList[0];
        let contactCountry = contactAddress.country ?? '';
        countryCode = this.localizationService.getCountryCodeByName(contactCountry);
      }
      preferredLangCode = this.localizationService.getLangCodeById(contact.primaryLanguage.id);
      templatePreview = this.personalizeUsingObject(templatePreview,
        ContactProperties,
        {
          ...contact,
          primaryEmail: contact.getPrimaryEmail || '',
          addressesList: contact.getPrimaryAddress || '',
          onekeyTitle: this.localizationService.getOneKeyCodesLabelFormattedValueByPreferredLang(contact.raw?._omnione_lu_titlecode_value || contact._omnione_lu_titlecode_value, countryCode, preferredLangCode) || '',
          onekeyPrefix: this.localizationService.getOneKeyCodesLabelFormattedValueByPreferredLang(contact.raw?._omnione_lu_title_value || contact._omnione_lu_title_value, countryCode, preferredLangCode) || '',
          onekeyMailingName: contact.omnione_individualsnameasitappearsinmailing || contact.raw?.omnione_individualsnameasitappearsinmailing || ''
        });
    }
    let editablePreview = templatePreview;
    if (tokens) {
      let personalizedBody = this.personalizeCustomToken(templatePreview, tokens, { user, tokens, productId, contactId, activity }, emailActivityParties);
      templatePreview = personalizedBody.templatePreview;
      editablePreview = personalizedBody.editablePreview;
    }
    this.isTemplateNewlyAdded = false;
    return { templatePreview, editablePreview }
  }

  private personalizeCustomToken(body: string, addedTokens: string[], { user, tokens, productId, contactId, activity }: { user?: User, tokens?: string[], productId?: string, contactId?: string, activity?: Activity }, emailActivityParties?: Array<EmailActivityParty>) {
    const contentTokens: ContentToken[] = this.contentTokens;
    let templatePreview = body;
    let editablePreview = body;
    const hideContentTokenId: string = this.getHideContentTokenId();

    for (let addedToken of addedTokens) {
      for (let allToken of contentTokens) {
        const cId = allToken.indskr_contenttokenid;
        const rtokenIndex: number = body.indexOf(cId) + cId.length + 1;
        if (addedToken != cId || !body || body.indexOf(cId) < 0) continue;
        allToken['isDeleted'] = false;
        if(body.substring(rtokenIndex, rtokenIndex + cId.length+9).includes('_disabled')) allToken['isDeleted'] = true;
        let rTokenId: string = body.substring(rtokenIndex, rtokenIndex + cId.length);
        let deletedTokens: string = `<img slot="end" id="token_edit_${cId}" class="token_edit_disabled" style="width: 24px; height: 24px; margin-left: 2px" src="assets/imgs/email_template_edit_grey.svg"><img slot="end" id="token_select_${cId}" style="width: 24px; height: 24px; margin-left: 5px" src="assets/imgs/token_enable.svg"><img slot="end" id="token_delete_${cId}" style="width: 22px; height: 22px; margin-left: 5px" src="assets/imgs/token_disable_grey.svg">`
        let selectedTokens: string = `<img slot="end" id="token_edit_${cId}" style="width: 24px; height: 24px; margin-left: 2px" src="assets/imgs/email_template_edit.svg"><img slot="end" id="token_select_${cId}" style="width: 24px; height: 24px; margin-left: 5px" src="assets/imgs/token_enable_grey.svg"><img slot="end" id="token_delete_${cId}" style="width: 22px; height: 22px; margin-left: 5px" src="assets/imgs/token_disable.svg">`
        let controls = allToken.isDeleted ? deletedTokens : selectedTokens
        if(allToken.indskr_iseditable) {
          let rDefaultValue = allToken.replacementTokens.find(rDV => rDV.is_default).replacement_id;
          let rText: string = body.substring(body.indexOf(cId + '.') + cId.length + 1);
          if(rText.indexOf('%custom.')>0) rText = rText.substring(0, rText.indexOf('%custom.'));
          let isReplacementTextDirty = this.isTemplateNewlyAdded || rDefaultValue == rTokenId || rText.indexOf('%&thinsp;')<0 ? false : true;     
          let isDefaultText = allToken.replacementTokens[0].replacement_value == 'Free Text'; 
          let defaultText = allToken.replacementTokens[0].replacement_value;
          // let placeholder = allToken.replacementTokens[0].replacement_value == 'Free Text' ? this.translate.instant('ENTER_YOUR_MESSAGE_HERE') : allToken.replacementTokens[0].replacement_value;
          let placeholder = this.translate.instant('ENTER_YOUR_MESSAGE_HERE');
          if(defaultText.indexOf('%')>-1) {
            if (user) {
              defaultText = this.personalizeUsingObject(defaultText, UserProperties, 
                {
                  ...user,
                  photoUrl: user.photoUrl ? `<img src=${user.photoUrl}>` : '',
                });
            }
            if (productId) {
              const brand: Brand = (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) ? this.getProductFromProductHierachy(productId) : this.brandService.getBrandByID(productId);
              defaultText = this.personalizeUsingObject(defaultText, ProductProperties, brand)
            }
            if (activity instanceof AppointmentActivity) {
              defaultText = this.personalizeUsingObject(defaultText, MeetingProperties, activity);
            }
            if (emailActivityParties && emailActivityParties.length>1) {
              defaultText = this.personalizeUsingObject(defaultText,
                ContactProperties,
                {
                  firstName: '%contact.firstname%',
                  lastName: '%contact.lastname%',
                  fullname: '%contact.fullname%',
                  fax: '%contact.fax%',
                  title: '%contact.title%',
                  mobilePhone: '%contact.mainphone%',
                  primaryEmail: '%contact.email%',
                  addressesList: '%contact.businessaddress%',
                  onekeyTitle: '%contact.onekeytitle%',
                  onekeyPrefix: '%contact.onekeytitlename%',
                  onekeyMailingName: '%contact.individualsname%'
                });
            } else if (contactId) {
              let contact = this.contactService.getContactByID(contactId);
              let countryCode, preferredLangCode;
              if(!_.isEmpty(contact.addressesList)) {
                let contactAddress = contact.addressesList.find(add => add.isPrimary) ? contact.addressesList.find(add => add.isPrimary) : contact.addressesList[0];
                let contactCountry = contactAddress.country ?? '';
                countryCode = this.localizationService.getCountryCodeByName(contactCountry);
              }
              preferredLangCode = this.localizationService.getLangCodeById(contact.primaryLanguage.id);
              defaultText = this.personalizeUsingObject(defaultText,
                ContactProperties,
                {
                  ...contact,
                  primaryEmail: contact.getPrimaryEmail || '',
                  addressesList: contact.getPrimaryAddress || '',
                  onekeyTitle: this.localizationService.getOneKeyCodesLabelFormattedValueByPreferredLang(contact.raw?._omnione_lu_titlecode_value || contact._omnione_lu_titlecode_value, countryCode, preferredLangCode) || '',
                  onekeyPrefix: this.localizationService.getOneKeyCodesLabelFormattedValueByPreferredLang(contact.raw?._omnione_lu_title_value || contact._omnione_lu_title_value, countryCode, preferredLangCode) || '',
                  onekeyMailingName: contact.omnione_individualsnameasitappearsinmailing || contact.raw?.omnione_individualsnameasitappearsinmailing || ''
                });
            }
          }
          rText = isReplacementTextDirty ? rText.substring(0, rText.indexOf('%&thinsp;')) : !isDefaultText ? defaultText : rText.substring(0, rText.indexOf('%'));
          let rTextValue = isReplacementTextDirty || !isDefaultText ? rText : '';
          rTextValue = this.escapeSpecialCharacters(rTextValue);
          let rTextValueForEditablePreview = rTextValue.replace(/<br>/g, '\n');
          let textToBeReplaced = isReplacementTextDirty ? '%custom.' + cId + '.' + rText + '%&thinsp;' : !isDefaultText ? '%custom.' + cId + '.' + rTokenId + '%' : '%custom.' + cId + '.' + rText + '%';
          templatePreview = templatePreview.replace(textToBeReplaced, `<span style="white-space: pre-wrap;">${rTextValue}</span>`);
          editablePreview = editablePreview.replace(textToBeReplaced,
            // '<span class="highlightCustomToken" id="' + cId + ':' + rTextValueForEditablePreview + '"><textarea maxlength="1000" rows="3" style="width: 100%;border-radius: 5px;padding: 8px;border-color: #DFDFDF; font-size: 16px;" placeholder="' + placeholder + '" class="custom_' + cId + '">' + rTextValueForEditablePreview + '</textarea><button slot="end" class="editable_button" style="padding-left: 0px; background-color: rgb(255,255,255);"><span>' + this.translate.instant('USE_PLACEHOLDER') + '</span></button></span>');
            '<span class="highlightCustomToken" id="' + cId + ':' + rTextValueForEditablePreview + '"><textarea maxlength="1000" rows="3" style="width: 100%;border-radius: 5px;padding: 8px;border-color: #DFDFDF; font-size: 16px;" placeholder="' + placeholder + '" class="custom_' + cId + '">' + rTextValueForEditablePreview + '</textarea></span>');
        } else {
          const rToken: ReplacementToken = allToken.replacementTokens.find(rt => rt.replacement_id == rTokenId);
          if (rToken) {
            if (cId != hideContentTokenId) {
              let replacementValue = _.cloneDeep(rToken.replacement_value);
              if(allToken.indskr_isallowdeletion && allToken.isDeleted) {
                rTokenId = rTokenId.includes('_disabled') ? rTokenId : rTokenId + '_disabled';
                replacementValue = '';
              }
              if(allToken.indskr_isrtetemplate) {
                let escaped_replacement =  this.escapeSpecialCharacters(replacementValue);        
                // Do not break the line. It will break html parsing on template-preview.
                templatePreview = templatePreview.replace('%custom.' + cId + '.' + rTokenId + '%',
                `<div class="container" style="position: relative; width: 95%; height: max-content;"><div class="box" style="position: relative; width: 100%; height: 100%; box-sizing: border-box; overflow: scroll;"><div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 1;display: contents;">${escaped_replacement} </div></div></div>`); 
                if(allToken.indskr_isallowdeletion) {
                  editablePreview = editablePreview.replace('%custom.' + cId + '.' + rTokenId + '%',
                  `<div class="highlightCustomToken" id="${cId}:${rTokenId}" style="display:flex;"><div class="container" style="position: relative; width: 95%; height: 200px; border-radius: 10px; outline: 0.55px solid lightgrey;"><div class="box" style="position: relative; width: 100%; height: 200px; box-sizing: border-box; overflow: scroll; border-radius: 10px;"><div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 1;">${escaped_replacement} </div></div></div>${controls}</div>`);    
                } else {
                  editablePreview = editablePreview.replace('%custom.' + cId + '.' + rTokenId + '%',
                  `<div class="highlightCustomToken" id="${cId}:${rTokenId}" style="display:flex;"><div class="container" style="position: relative; width: 95%; height: 200px; border-radius: 10px; outline: 0.55px solid lightgrey;"><div class="box" style="position: relative; width: 100%; height: 200px; box-sizing: border-box; overflow: scroll; border-radius: 10px;"><div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 1;">${escaped_replacement} </div></div></div><img slot="end" style="width: 24px; height: 24px; margin-left: 2px" src="assets/imgs/email_template_edit.svg"></div>`);  
                }
                templatePreview = templatePreview.split('$$').join('$');
                editablePreview = editablePreview.split('$$').join('$');
              } else {
                templatePreview = templatePreview.replace('%custom.' + cId + '.' + rTokenId + '%', replacementValue);
                if(allToken.indskr_isallowdeletion) {
                  editablePreview = editablePreview.replace('%custom.' + cId + '.' + rTokenId + '%',
                  `<span class="highlightCustomToken" id="${cId}:${rTokenId}"><label class="custom">${replacementValue}</label>${controls}</span>`);  
                } else {
                editablePreview = editablePreview.replace('%custom.' + cId + '.' + rTokenId + '%',
                  '<span class="highlightCustomToken" id="' + cId + ':' + rTokenId + '"><label class="custom">' + replacementValue + '</label><img style="width: 24px; height: 24px; margin-left: 2px" src="assets/imgs/email_template_edit.svg"></span>');  
                }
              }
            } else {
              // To hide the content token
              const numOfOccurrences = templatePreview.split(hideContentTokenId).length - 1;
              for (let numToHide = 1; numToHide <= numOfOccurrences; numToHide++) {
                templatePreview = templatePreview.replace('%custom.' + cId + '.' + rTokenId + '%', '');
                editablePreview = editablePreview.replace('%custom.' + cId + '.' + rTokenId + '%', '');
              }
            }
          }
        }
      }
    }
    // Check if the template has been changed or added token has been deactivated. Either case, remove the tokens.
    let unReplacedTokenIndexForTemplatePreview = (templatePreview.match(new RegExp('%custom.', 'g')) || []).length;
    if(unReplacedTokenIndexForTemplatePreview > 0 ) {
      for (let i=0; i<unReplacedTokenIndexForTemplatePreview; i++) {
        let token = templatePreview.substring(templatePreview.search(new RegExp('%custom.')));
        token = token.substring(0, token.indexOf('</p>'));
        templatePreview = templatePreview.replace(token, '');
        editablePreview = editablePreview.replace(token, '');
      }
    }
    return { templatePreview, editablePreview }
  }

  private deleteDisabledTokens(emailActivityId: string) {
    if(this.selectedActivity.activityid != emailActivityId) return;
    let body = _.cloneDeep(this.selectedActivity.description);
    let template: ResourceEmailTemplate = _.cloneDeep(this.emailTemplates.find(templ => { return templ.indskr_emailtemplateid == this.selectedActivity.template_id }));
    let addedTokens = template?template.content_tokens:null;
    let contentTokens: ContentToken[] = _.cloneDeep(this.contentTokens);
    if(addedTokens) {
      for (let addedToken of addedTokens) {
        for (let allToken of contentTokens) {
          const cId = allToken.indskr_contenttokenid;
          const ctokenIndex: number = body.indexOf(cId);
          const rtokenIndex: number = ctokenIndex + cId.length + 1;

          if (addedToken != cId || !body || body.indexOf(cId) < 0) continue;

          if(allToken.indskr_iseditable) {

          } else {
            if(allToken.indskr_isrtetemplate) {
              if(body.substring(rtokenIndex, rtokenIndex + cId.length + 9).includes('_disabled')) {
                body = body.substring(0,ctokenIndex-8) + body.substring(rtokenIndex+ cId.length + 10);
              }    
            }
          }
        }
      }
    }
    this.updateEmailActivity({ description: body }, EmailActionType.TEMPLATE_UPDATE);
  }

  private escapeSpecialCharacters(text: string) {
    text = text.split('$').join('$$');
    // text = text.split('<').join('&lt;');  Disable escaping since user is no longer able to enter '%', '<', '>'
    return text;
  }

  private personalizeUsingObject(body: string, mapper: any, object: any) {
    if (object) {
      try {
        return Object.keys(mapper).reduce((body, key) => body.replace(new RegExp(mapper[key], 'g'), object[key] || ''), body)
      } catch {
      }
    }
    return body;
  }

  personalizeCalendarInviteTemplate(activity: Activity, template: ResourceEmailTemplate) {
    this.selectedCalendarInviteTemplate = template;
    const activityCopy: Activity = cloneDeep(activity);
    if (this.authService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING) && activityCopy instanceof AppointmentActivity) {
      activityCopy.meetingURL = activityCopy.indskr_teamslink;
    }
    let tokenReplacedPreview = '';
    if (template?.indskr_body) {
      const preview = this.personalizeTemplateBody(
        template.indskr_body,
        {
          user: this.authService.user,
          tokens: template.content_tokens,
          productId: template.product_id,
          contactId: activity['contacts'] && activity['contacts'].length ? activity['contacts'][0].ID : null,
          activity: activityCopy
        }
      );
      tokenReplacedPreview = preview?.templatePreview ?? template.indskr_body;
    }
    return tokenReplacedPreview;
  }

  getDisplayTime = (): [string, string] => {
    const email: EmailActivity = this.selectedActivity;
    if (!email) return;
    let date: Date = email.scheduledStart;
    if ((email.emailStatus == EmailStatusCodes.Sent || email.emailStatus == EmailStatusCodes.Failed || email.emailStatus == EmailStatusCodes.Shared)
      && email.emailActivityParties && email.emailActivityParties.length) {
      let sentDates: number[] = [];
      if(email.channelType == ChannelType.SMS || email.channelType == ChannelType.WHATSAPP || email.channelActivityType == ChannelActivityType.SMS || email.channelActivityType == ChannelActivityType.WHATSAPP
        || email.channelType.toLowerCase().includes('sms') || email.channelType.toLowerCase().includes('whatsapp')) {
        email.emailActivityParties.forEach(epa => {
          if(epa.email_senton){
            sentDates.push(new Date(parseFloat(epa.email_senton.toString())).getTime());
          }
        });
      }else{
        email.emailActivityParties.forEach(epa => {
          if(epa.emailAddresses){
            sentDates.push(...epa.emailAddresses.map(ea => ea.email_senton && new Date(parseFloat(ea.email_senton.toString())).getTime()));
          }
        });
      }
      sentDates = sentDates.filter(Boolean);
      if (sentDates.length > 0) {
        const min = Math.min(...sentDates);
        if (min) date = new Date(min);
      }
    }
    let scheduleDay = this.datePipe.transform(this.selectedActivity.scheduledStart, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let startDate: Date = new Date(date);
    // let scheduleTime = startDate.toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.dateFormat, hour: '2-digit', minute: '2-digit' });

    let scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });

    return [scheduleDay, scheduleTime];
  }


  getDisplayTimeInActivityPane = (activity: EmailActivity): string => {
    switch (this.translate.currentLang) {
      case 'es' :
        this.locale =  {locale : es}
        break;
      case 'fr' :
        this.locale =  {locale : fr}
        break;
      case 'ja' :
        this.locale =  {locale : ja}
        break;
      case 'pt':
        this.locale = { locale: pt }
        break;
      case 'it':
        this.locale = { locale: it }
        break;
      case 'tr':
        this.locale = { locale: tr }
        break;  
      case 'en' :
        this.locale =  {locale : en}
        break;
  }
    const email: EmailActivity = activity;
    let date: Date = email.scheduledStart;
    if ((email.emailStatus == EmailStatusCodes.Sent || email.emailStatus == EmailStatusCodes.Failed || email.emailStatus == EmailStatusCodes.Shared)
      && email.emailActivityParties && email.emailActivityParties.length) {
      let sentDates: number[] = [];
      if(email.channelType == ChannelType.SMS || email.channelType == ChannelType.WHATSAPP || email.channelActivityType == ChannelActivityType.SMS || email.channelActivityType == ChannelActivityType.WHATSAPP
        || email.channelType.toLowerCase().includes('sms') || email.channelType.toLowerCase().includes('whatsapp')) {
        email.emailActivityParties.forEach(epa => {
          if(epa.email_senton){
            sentDates.push(new Date(parseFloat(epa.email_senton.toString())).getTime());
          }
        });
      }else{
        email.emailActivityParties.forEach(epa => {
          if(epa.emailAddresses){
            sentDates.push(...epa.emailAddresses.map(ea => ea.email_senton && new Date(parseFloat(ea.email_senton.toString())).getTime()));
          }
        });
      }
      sentDates = sentDates.filter(Boolean);
      if (sentDates.length > 0) {
        const min = Math.min(...sentDates);
        if (min) date = new Date(min);
      }
    }
    let startDate: Date = new Date(date);
    let scheduleTime = format(startDate,this.dateTimeFormatsService.timeFormatSelected.segment,this.locale);
    return scheduleTime;
  }

  getSentTime = (): [string, string] => {
    const email: EmailActivity = this.selectedActivity;
    if (!email) return;
    let date: Date = email.scheduledStart;
    if ((email.emailStatus == EmailStatusCodes.Sent || email.emailStatus == EmailStatusCodes.Failed || email.emailStatus == EmailStatusCodes.Shared)
      && email.emailActivityParties && email.emailActivityParties.length) {
      let sentDates: number[] = [];
      if(email.channelType == ChannelType.SMS || email.channelType == ChannelType.WHATSAPP || email.channelActivityType == ChannelActivityType.SMS || email.channelActivityType == ChannelActivityType.WHATSAPP
        || email.channelType.toLowerCase().includes('sms') || email.channelType.toLowerCase().includes('whatsapp')) {
        email.emailActivityParties.forEach(epa => {
          if(epa.email_senton){
            sentDates.push(new Date(parseFloat(epa.email_senton.toString())).getTime());
          }
        });
      }else{
        email.emailActivityParties.forEach(epa => {
          if(epa.emailAddresses){
            sentDates.push(...epa.emailAddresses.map(ea => ea.email_senton && new Date(parseFloat(ea.email_senton.toString())).getTime()));
          }
        });
      }
      sentDates = sentDates.filter(Boolean);
      if (sentDates.length > 0) {
        const min = Math.min(...sentDates);
        if (min) date = new Date(min);
      }
    }
    let scheduleDay = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let scheduleTime = date.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });

    return [scheduleDay, scheduleTime];
  }

  getFormattedTime = (longTime: string): string => {
    if (!longTime) return '';
    let timeToFormat: Date;
    if (isValid(new Date(longTime))) {
      timeToFormat = new Date(longTime);
    } else {
      timeToFormat = new Date(parseFloat(longTime));
    }

    let scheduleDay = isToday(timeToFormat) ? this.translate.instant('TODAY') : isTomorrow(timeToFormat) ? this.translate.instant('TOMORROW')
      : isYesterday(timeToFormat) ? this.translate.instant('YESTERDAY') : moment(timeToFormat).locale(this.translate.currentLang).format(this.dateTimeFormatsService.dateToUpper);
    let startDate: Date = new Date(timeToFormat);
    let scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return scheduleDay + " " + scheduleTime;
  }

  public selectTemplate(template: ResourceEmailTemplate) {
    this.selectedTemplate$.next(template);
  }

  public resetContentToken() {
    this.selectedContentToken$.next(null);
  }

  public selectContentToken(token: ContentToken) {
    this.selectedContentToken$.next(token);
  }

  public setCurrentEmail(input) {
    this.currentEmailSource.next(input);
  }

  //distinguishes if resource has been added or removed
  public async detectResourceAddedOrRemoved(resources: Resource[]) {
    let addedResource: any[] = [];
    let deletedResource: any[] = [];

    resources.forEach(element => {
      let idx = this.selectedActivity.emailAttachments.findIndex(e => {
        return (e.indskr_resourceid === element.ioResourceId) || (e.indskr_resourceid === element.ioDocumentId)
      });
      if (idx === -1) {
        addedResource.push(element.emailAttachmentDTO);
      }
    });

    this.selectedActivity.emailAttachments.forEach(element => {
      let idx = resources.findIndex(e => {
        return (e.ioResourceId === element.indskr_resourceid) || (e.ioDocumentId === element.indskr_resourceid)
      });
      if (idx === -1) {
        deletedResource.push(element);
      }
    });

    //Offline Created Email not synced yet to dynamics as the device is offline
    if (this.deviceService.isOffline || this.selectedActivity.ID.includes('offline')
      || this.activityService.hasOfflineEmailData(this.selectedActivity.offlineActivityId)) {
      if (addedResource && addedResource.length) {
        this.addResourceOffline(addedResource, this.selectedActivity.ID.includes('offline'));
      }
      if (deletedResource && deletedResource.length) {
        this.removeResourceOffline(deletedResource, this.selectedActivity.ID.includes('offline'));
      }
    }
    // Device is not offline, phew that was close
    else {
      if (addedResource && addedResource.length) {
        await this.emailDataService.addAttachment(addedResource, this.selectedActivity.ID).then(
          async res => {
            if (res && Array.isArray(res)) {
              res.forEach((inp: any) => {
                let attachment: EmailAttachment = new EmailAttachment(inp);
                attachment.indskr_resourceid = inp.indskr_ioresourceid ? inp.indskr_ioresourceid : inp.indskr_iodocumentid,
                  this.selectedActivity.emailAttachments.push(attachment);
              });

              if (this.selectedActivity.description.includes('%content.url%')) {
                let req: Resource[] = [];
                try {
                  this.selectedActivity.emailAttachments.map(el => {
                    let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
                    if (resource) {
                      req.push(resource);
                    }
                  });
                  let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
                  this.updatePreviewTemplate(_customAttachment, true);
                } catch (error) { }
              }

              this.setCurrentEmail(this.selectedActivity);
            }
          }).catch(err => {
            console.log("error adding resource");
            //Some Logic for adding in offline mode
          });
      }
      if (deletedResource && deletedResource.length) {
        //Offline Conflict Check, resource added offline and upload has not been performed yet
        if (deletedResource.every((i) => { return i['indskr_emailattachmentid'] })) {
          await this.emailDataService.removeAttachment(deletedResource).then(
            async res => {
              deletedResource.forEach(element => {
                let idx = this.selectedActivity.emailAttachments.findIndex(el => { return el.indskr_emailattachmentid === element.indskr_emailattachmentid });
                this.selectedActivity.emailAttachments.splice(idx, 1);
              });
              if (this.selectedActivity.description.includes('%content.url%')) {
                let req: Resource[] = [];
                try {
                  this.selectedActivity.emailAttachments.map(el => {
                    let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
                    if (resource) {
                      req.push(resource);
                    }
                  });
                  let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
                  this.updatePreviewTemplate(_customAttachment, true);
                } catch (error) { }
              }
              this.setCurrentEmail(this.selectedActivity);
            }).catch(err => {
              console.log("error adding resource");
              //Some Logic for adding in offline mode
            });
        }
        else {
          //Some resource have undefined indskr_emailattachmentid as they were added in offline mode
          // and were pending to be uploaded, need to remove them from offline doc and update the rest in online mode
          let offlineRemoval = deletedResource.filter(e => { return !e.indskr_emailattachmentid; });
          let onlineRemoval = deletedResource.filter(e => { return e.indskr_emailattachmentid; });


          if (onlineRemoval && onlineRemoval.length) {
            //First perform the online part, offline we can update anytime later
            await this.emailDataService.removeAttachment(onlineRemoval).then(async res => {
              onlineRemoval.forEach(element => {
                let idx = this.selectedActivity.emailAttachments.findIndex(el => { return el.indskr_emailattachmentid === element.indskr_emailattachmentid });
                this.selectedActivity.emailAttachments.splice(idx, 1);
              });
              this.setCurrentEmail(this.selectedActivity);
              if (this.selectedActivity.description.includes('%content.url%')) {
                let req: Resource[] = [];
                try {
                  this.selectedActivity.emailAttachments.map(el => {
                    let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
                    if (resource) {
                      req.push(resource);
                    }
                  });
                  let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
                  this.updatePreviewTemplate(_customAttachment, true);
                } catch (error) { }
              }
            });
          }

          if (offlineRemoval && offlineRemoval.length) {
            offlineRemoval.forEach(element => {
              let idx = this.selectedActivity.emailAttachments.findIndex(el => { return el.indskr_resourceid === element.indskr_resourceid });
              let ix = this.selectedActivity.offlineAddedResource.findIndex(el => {
                if (el.indskr_ioresourceid) {
                  return el.indskr_ioresourceid === element.indskr_resourceid
                }
                if (el.indskr_iodocumentid) {
                  return el.indskr_iodocumentid === element.indskr_resourceid
                }
              });
              //removing from entity manually
              this.selectedActivity.emailAttachments.splice(idx, 1);
              this.selectedActivity.offlineAddedResource.splice(ix, 1);
            });
            await this.disk.updateofflineEmailActivtyDocument(this.selectedActivity);
            this.setCurrentEmail(this.selectedActivity);
            if (this.selectedActivity.description.includes('%content.url%')) {
              let req: Resource[] = [];
              try {
                this.selectedActivity.emailAttachments.map(el => {
                  let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
                  if (resource) {
                    req.push(resource);
                  }
                });
                let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
                this.updatePreviewTemplate(_customAttachment, true);
              } catch (error) { }
            }
          }
        }
      }
    }
  }

  async addResourceOffline(value: any[], isCreatedOffline: boolean) {
    //storing value for fethcing DTO
    this.selectedActivity.offlineAddedResource = value;
    //Adding data for preview purpose
    value.forEach((inp: any) => {
      let attachment: EmailAttachment = new EmailAttachment(inp);
      attachment.indskr_resourceid = inp.indskr_ioresourceid ? inp.indskr_ioresourceid : inp.indskr_iodocumentid;
      this.selectedActivity.emailAttachments.push(attachment);
    });
    const idToBeUpdated: string = this.selectedActivity.offlineActivityId;
    if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
      this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
      await this.disk.createOfflineEmailActivity(this.selectedActivity);
    } else {
      await this.disk.updateofflineEmailActivtyDocument(this.selectedActivity)
    }
    if (this.selectedActivity.description.includes('%content.url%')) {
      let req: Resource[] = [];
      try {
        this.selectedActivity.emailAttachments.map(el => {
          let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
          if (resource) {
            req.push(resource);
          }
        });
        let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
        this.updatePreviewTemplate(_customAttachment, false);
      } catch (error) { }
    }
    this.setCurrentEmail(this.selectedActivity);

  }

  async removeResourceOffline(value: EmailAttachment[], isCreatedOffline: boolean) {
    //storing value for fethcing DTO
    this.selectedActivity.offlineRemovedResource = value;
    value.forEach(element => {
      let idx = this.selectedActivity.emailAttachments.findIndex(el => {
        if (isCreatedOffline) {
          return el.indskr_resourceid === element.indskr_resourceid;
        }
        else
          return el.indskr_emailattachmentid === element.indskr_emailattachmentid
      });
      if (this.selectedActivity.offlineAddedResource) {
        let removeIndex = this.selectedActivity.offlineAddedResource.findIndex(e => (e.indskr_resourceid === element.indskr_resourceid) && (!e.indskr_emailattachmentid))
        this.selectedActivity.offlineAddedResource.splice(removeIndex, 1);
      }
      this.selectedActivity.emailAttachments.splice(idx, 1);
    });
    const idToBeUpdated: string = this.selectedActivity.offlineActivityId;
    if (!this.activityService.hasOfflineEmailData(idToBeUpdated)) {
      this.activityService.addToOfflineEmailActivityIds(idToBeUpdated);
      await this.disk.createOfflineEmailActivity(this.selectedActivity);
    } else {
      await this.disk.updateofflineEmailActivtyDocument(this.selectedActivity)
    }
    if (this.selectedActivity.description.includes('%content.url%')) {
      let req: Resource[] = [];
      try {
        this.selectedActivity.emailAttachments.map(el => {
          let resource = this.resourceService.allResources.find(r => { return (r.ioResourceId === el.indskr_resourceid) || (r.ioDocumentId === el.indskr_resourceid) });
          if (resource) {
            req.push(resource);
          }
        });
        let _customAttachment = this.convertEmailAttachmentToHtmlString(req);
        this.updatePreviewTemplate(_customAttachment, false);
      } catch (error) { }
    }
    this.setCurrentEmail(this.selectedActivity);
  }

  convertEmailAttachmentToHtmlString(req: Resource[]): string {
    let res: string = "";
    if (req && Array.isArray(req) && req.length) {
      res = "<ul style='list-style\: none;\'>"
      req.forEach(a => {
        if (a) {
          let element = "<li><img src=\'" + a.thumbnailURL + "\'height=\'42\' width=\'42\'><a href=\'" + a.assetURL + "\' target=\'_blank\'>" + a.title + "<\/a><\/li>"
          res += element;
        }
      });
      return res + "</ul>"
    }
    else {
      return res;
    }
  }

  updatePreviewTemplate(template: string, isOnlinie: boolean): string {
    console.log(template);
    if (template.length > 0) {
      this.selectedActivity.templatePreview = this.selectedActivity.templatePreview.replace("%content.url%", template);
    }
    return this.selectedActivity.templatePreview;
  }

  getResourceInteractionDetails(selectedAttachment: EmailAttachment) {
    const resourceInteractions: EmailActivityParty[] = cloneDeep(this.selectedActivity.emailActivityParties);
    const emailAttachmentDetails = this.selectedActivity.emailAttachments.find(attachment =>  selectedAttachment.indskr_resourceid && attachment.indskr_resourceid == selectedAttachment.indskr_resourceid
      || attachment.indskr_emailattachmentid == selectedAttachment.indskr_emailattachmentid);
    resourceInteractions.forEach(emailParty => {
      emailParty.emailAddresses.forEach(emailAddress => {
        let attachmentDetails: AttachmentInteractionDetails[] = [];
        if (emailAddress.attachmentDetails) {
          emailAddress.attachmentDetails.filter(attachment =>
            attachment.indskr_resourceid ? attachment.indskr_resourceid == selectedAttachment.indskr_resourceid : attachment.indskr_documentid == selectedAttachment.indskr_resourceid)
            .forEach(attachment => attachmentDetails.push(attachment));
        }
        emailAddress['attachmentDetails'] = attachmentDetails;
      })
    })
    this.resourceInteractionDetails = { emailAttachment: emailAttachmentDetails, interactionDetails: resourceInteractions };
  }

  getProductIdFromTemplate(templateId: string): string | null {
    const template = this.emailTemplates.find(e => e.indskr_emailtemplateid === templateId);
    return template && template.product_id ? template.product_id : null;
  }

  public checkConsentsForMessageActivity() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_CONSENT_REQUIRED)
    && !this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) {
      this.contactService.isConsentInvoked = false;
      if (this.selectedActivity && this.selectedActivity.template_id) {
        let emailTemplateIndex = this.emailTemplates.findIndex(e => {
          return e.indskr_emailtemplateid === this.selectedActivity.template_id
        });
        if (emailTemplateIndex >= 0) {
          this.selectedActivity.templateRefProductId = this.emailTemplates[emailTemplateIndex].product_id;
        }
      }

      // To check whether Product Consent flag is true for this Business Unit
      // then continue with the existing flow of check Consent submitted for Products & Channels
      // else go for new flow to check Consent submitted for Products only
      if (this.authenticationService.user.isProductConsent) {
        if (this.selectedActivity && this.selectedActivity.template_id && this.selectedActivity.templateRefProductId) {
          if (this.selectedActivity?.emailActivityParties.length) {
            this.selectedActivity.emailActivityParties.forEach(e => {
              let contact = {
                ID: e.indskr_contactid,
                emailAddressList: e.emailAddresses ? e.emailAddresses : null,
                mobilePhone: e.contact_mobilephone ? e.contact_mobilephone : null,
                indskr_facebookpsid: e.contact_indskr_facebookpsid ? e.contact_indskr_facebookpsid : null
              };
              let removeEmails: boolean = false;
              if (this.selectedActivity.channelType == ChannelType.EMAIL) {
                if (this.selectedActivity.statuscode == 1) {
                  let consentContact = this.consentService.doesContactHaveConsentForChannel(contact, this.selectedActivity.channelType, this.selectedActivity.scheduledStart, this.selectedActivity.templateRefProductId).contact;
                  let hasConsent = consentContact.emailAddressList.some(e => e.hasConsented);
                  if (hasConsent) {
                    if (consentContact.emailAddressList.length > 1) {
                      consentContact.emailAddressList.forEach(email => {
                        if (!email.hasConsented) {
                          let index = e.emailAddresses.findIndex(ea => ea.email_addressid == email.email_addressid);
                          let emailAddress: EmailAddress = e.emailAddresses.splice(index, 1)[0];
                          this.contactService.deselectContactEmailAddress(e.indskr_contactid, emailAddress.email_addressid);
                          removeEmails = true;
                        }
                      })
                    }
                    e.isNotConsented = false;
                  } else {
                    e.isNotConsented = true;
                  }
                  if (removeEmails) {
                    this.removeEmailAddress(e);
                  }
                } else {
                  e.isNotConsented = false;
                }
              } else {
                if (this.selectedActivity.statuscode == 1) {
                  e.isNotConsented = !this.consentService.doesContactHaveConsentForChannel(contact, this.selectedActivity.channelType, this.selectedActivity.scheduledStart, this.selectedActivity.templateRefProductId).hasConsented;
                } else {
                  e.isNotConsented = false;
                }
              }
            });
            this.disableSendButton = this.selectedActivity.emailActivityParties.some(e => e.isNotConsented);
          }
        }
      }
      else {
          if (this.selectedActivity && this.selectedActivity?.emailActivityParties.length) {
            this.selectedActivity.emailActivityParties.forEach(e => {
              let contact = {
                ID: e.indskr_contactid,
                emailAddressList: e.emailAddresses ? e.emailAddresses : null,
                mobilePhone: e.contact_mobilephone ? e.contact_mobilephone : null,
                indskr_facebookpsid: e.contact_indskr_facebookpsid ? e.contact_indskr_facebookpsid : null
              };
              let removeEmails: boolean = false;
              if (this.selectedActivity.channelType == ChannelType.EMAIL) {
                if (this.selectedActivity.statuscode == 1) {
                  let consentContact = this.consentService.doesContactHaveConsentForChannel(contact, this.selectedActivity.channelType, this.selectedActivity.scheduledStart, this.selectedActivity.templateRefProductId).contact;
                  let hasConsent = consentContact.emailAddressList.some(e => e.hasConsented);
                  if (hasConsent) {
                    if (consentContact.emailAddressList.length > 1) {
                      consentContact.emailAddressList.forEach(email => {
                        if (!email.hasConsented) {
                          let index = e.emailAddresses.findIndex(ea => ea.email_addressid == email.email_addressid);
                          let emailAddress: EmailAddress = e.emailAddresses.splice(index, 1)[0];
                          this.contactService.deselectContactEmailAddress(e.indskr_contactid, emailAddress.email_addressid);
                          removeEmails = true;
                        }
                      })
                    }
                    e.isNotConsented = false;
                  } else {
                    e.isNotConsented = true;
                  }
                  if (removeEmails) {
                    this.removeEmailAddress(e);
                  }
                } else {
                  e.isNotConsented = false;
                }
              } else {
                if (this.selectedActivity.statuscode == 1) {
                  e.isNotConsented = !this.consentService.doesContactHaveConsentForChannel(contact, this.selectedActivity.channelType, this.selectedActivity.scheduledStart, this.selectedActivity.templateRefProductId).hasConsented;
                } else {
                  e.isNotConsented = false;
                }
              }
            });
            this.disableSendButton = this.selectedActivity.emailActivityParties.some(e => e.isNotConsented);
          }
      }
      this.disableSendButton = this.selectedActivity.emailActivityParties.some(e => e.isNotConsented);
    } else {
      this.disableSendButton = false;
    }
    // Final check - valid email address in email activity parties
    if (!this.disableSendButton && this.selectedActivity && this.selectedActivity.emailStatus === EmailStatusCodes.Draft && this.selectedActivity?.emailActivityParties.length > 0) {
      let hasValidEmailAddressToSend: boolean = false;
      for (const emailActivityParty of this.selectedActivity.emailActivityParties) {
        if (emailActivityParty.indskr_contactid) {
          const contact: Contact = this.contactService.getContactByID(emailActivityParty.indskr_contactid);
          if (contact) {
            if (contact?.emailAddressList?.length) {
              const contactEmailAddressSet = new Set(contact.emailAddressList.map(e => e.emailAddress));
              const filteredData = emailActivityParty['emailAddresses']?.filter(ep => contactEmailAddressSet.has(ep.emailAddress)) || [];
              if (filteredData.length > 0) {
                hasValidEmailAddressToSend = true;
                break;
              }
            }
          }
        }
      }
      this.disableSendButton = !hasValidEmailAddressToSend;
    }
  }

  showNotification() {
    let isDisplayedConsentNotification: boolean = false;
    //1. check required consent
    if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_CONSENT_REQUIRED)
    && !this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) {
      // To check whether Product Consent flag is true for this Business Unit
      // then continue with the existing flow of notify Consent Required for Contacts & Templates
      // else go for new flow of notify Consent Required for Contacts only
      if (this.authenticationService.user.isProductConsent) {
        if (this.selectedActivity.template_id && this.selectedActivity.templateRefProductId) {
          if (!_.isEmpty(this.selectedActivity.emailActivityParties) && this.disableSendButton && this.selectedActivity.isMessageExternal === false) {
            this.notificationService.notify(this.translate.instant('NOTIFY_CONSENT_REQUIRED'), 'Email Details Page');
            isDisplayedConsentNotification = true;
          }
        }
      }
      else {
        if (!_.isEmpty(this.selectedActivity.emailActivityParties) && this.disableSendButton && this.selectedActivity.isMessageExternal === false) {
          this.notificationService.notify(this.translate.instant('NOTIFY_CONSENT_REQUIRED'), 'Email Details Page');
          isDisplayedConsentNotification = true;
        }
      }
    }
    
    //2. check sent email recipients based on the selected template
    if(this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_CONSENT_REQUIRED)) {
      const selectedTemplateId: string = this.selectedActivity && this.selectedActivity.template_id ? this.selectedActivity.template_id : '';
      let selectedRecipientsList: any[] = [];
      if(this.selectedActivity && !_.isEmpty(this.selectedActivity.emailActivityParties)) {
        this.selectedActivity.emailActivityParties.forEach(ep => {
          if(ep.indskr_contactid) {
            selectedRecipientsList.push({id: ep.indskr_contactid, name: `${ep.contact_firstname} ${ep.contact_lastname}`});
          }
        });
      }
      if(!_.isEmpty(selectedTemplateId) && !_.isEmpty(selectedRecipientsList)) {
        let sentEmailRecipientsList = [];
        selectedRecipientsList.forEach(contact => {
          const isSentEmail = this.checkSentEmailRecipientByTemplate(contact.id, selectedTemplateId);
          if(isSentEmail) {
            sentEmailRecipientsList.push(contact.name);
          }
        });
        
        if(!_.isEmpty(sentEmailRecipientsList)) {
          const nameListStr: string = sentEmailRecipientsList.join(', ');
          const nameListStrWithQuotes: string = `"${nameListStr}"`;
          const timeOutValue: number = isDisplayedConsentNotification || this.isNotifiedTokenMessageInOffline ? 3000 : 0;
          setTimeout(()=>{
            this.notificationService.notify(this.translate.instant('NOTIFY_SENT_EMAIL_RECIPIENTS', {recipientsName: nameListStrWithQuotes}), 'Email Details Page', "top", ToastStyle.INFO, 7000);
          }, timeOutValue);
        }
      }
    }
  }

  removeEmailAddress(emailActivityParty) {
    let loader
    this.loadingCtrl.create()
    .then((load)=>{
      loader = load;
      loader.present();
      let emailActivityParties: EmailActivityParty[] = this.selectedActivity.emailActivityParties;
      emailActivityParties[emailActivityParties.indexOf(emailActivityParty)].emailAddresses = emailActivityParty.emailAddresses;
      this.updateEmailActivityParties(emailActivityParties).then(() => {
        if (!emailActivityParty.emailAddresses.length) {
          emailActivityParties.splice(emailActivityParties.indexOf(emailActivityParty), 1);
        }
        loader.dismiss();
      })
    }).catch(() => loader.dismiss());
  }

  public mapEmailTeamplateFieldsToSearchIndex(newEmail:ResourceEmailTemplate){
    if(!this.searchConfigService.isConfigInitiated){
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    if(newEmail.productName && !this.searchConfigService.emailTemplatesSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o == newEmail.productName)){
      this.searchConfigService.emailTemplatesSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.push(newEmail.productName);
    }
    if(newEmail.therapeuticAreaName && !this.searchConfigService.emailTemplatesSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.some(o=> o == newEmail.therapeuticAreaName)){
      this.searchConfigService.emailTemplatesSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.push(newEmail.therapeuticAreaName);
    }
  }

  /**
   *  Need to switch all products/key message update
   */

  public async updateEmailActivityProductKeyMessages(activity: EmailActivity) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_PRODUCTS.replace('{activity_id}', activity.ID);

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authService.user.xPositionID
    );

    //console.log("PRODUCTS");
    //console.log(activity.productsDTO);
    try{
      // let response = await this.http
      // .put(url, activity.productsDTO , headers) // send only the required payload
      // .toPromise();
      // this.logService.logDebug(response);
    }catch(e){
      this.errorHandler.handleError(e);
    }

    try{ // I/O operations needs to be wrap in a try and catch block
      //this.activityService.upsertMeetingsOfflineData(activity); // offline saving
    }catch(e){
      console.error('Caught error trying to save products and key messages offline', e);
    }
  }

  public async addRemoveAccompaniedUsers(raw:AccompainedUser[]) {
    this.selectedActivity.accompaniedUserList = raw;
    this.setCurrentEmail(this.selectedActivity);
    this.emailDataService.addAccompaniedUser(raw, this.selectedActivity.ID, this.selectedActivity.appointmentid).subscribe(
      res => {

      },
      async err => {
        //someerror occured
        //proceed for offline saving data
        console.error("some error occured while posting data");

      }
    );
  }

  // Share message through non-native channel
  private mobilePhoneList(): string {
    let phoneNumberList: string = '';
    phoneNumberList = this.selectedActivity.emailActivityParties[0].contact_mobilephone;
    return phoneNumberList;
  }

  private shareMessageText(): string {
    let messageForSharing: string = '';
    messageForSharing = this.selectedActivity.editablePreview || '';
    //remove undefined token, tags and space
    if ((messageForSharing != null) || (messageForSharing != '')) {
      messageForSharing = messageForSharing.toString().replace(/\(%.*?%\)/ig, '').replace(/(<([^>]+)>)/ig, ' ').replace("&nbsp;", ' ');
      messageForSharing = messageForSharing.trim().replace(/  +/g,' ');
    }
    return messageForSharing;
  }

  async shareWhatsapp() {
    let message = this.shareMessageText();
    // let receiver = this.mobilePhoneList();
    // let receiverArr = receiver.split(",");
    // if (receiverArr && receiverArr.length > 1) {
    //   for (let i=0; i < receiverArr.length; i++){
    //     receiverArr[i] = '+' + receiverArr[i];
    //   }
    //   receiver = receiverArr.join(", ");
    // } else if (receiverArr.length == 1) {
    //   receiver = '+' + receiver;
    // }
    // if (receiver) {
    //   this.socialSharing.shareViaWhatsAppToReceiver(message, receiver).then((success) => { }).catch(() => {
    //     this.socialSharing.shareViaWhatsApp(message).then((success) => { }).catch(() => { })
    //    });
    // } else {
    //   this.socialSharing.shareViaWhatsApp(message, null, null).then((success) => { }).catch(() => { });
    // }
    await this.socialSharing.shareViaWhatsApp(message, null, null).then((success) => {
      console.log("Opened WhatsApp application");
      this.openedApp = true;
     }).catch(() => {
      console.log("Not opened WhatsApp application");
      this.openedApp = false;
      this.notificationService.notify(this.translate.instant("WHATSAPP_IS_NOT_AVAILABLE"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
      });
  }

  async shareSMS() {
    let message = this.shareMessageText();
    let phoneNumber = this.mobilePhoneList();
    await this.socialSharing.shareViaSMS(message, phoneNumber).then((success) => {
      console.log("Opened SMS application");
      this.openedApp = true;
     }).catch(() => {
      console.log("Not opened SMS application");
      this.openedApp = false;
      this.notificationService.notify(this.translate.instant("SMS_IS_NOT_AVAILABLE"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
     })
  }

  private async initWeCom() {
    const schema = 'wwauth8a1d766615820ba3000003';
    if (this.deviceService.deviceFlags.native && this.deviceService.deviceFlags.android && cordova?.plugins?.cordovaHelpers?.initWeCom) {
      return (new Promise((res, rej) => {
        cordova.plugins.cordovaHelpers.initWeCom(res, rej, schema);
      })).catch(() => undefined);
    }
  }

  private async shareWeCom() {
    const corpId = 'ww8a1d766615820ba3';
    const agentId = '1000003';
    const message = this.shareMessageText();
    if (this.deviceService.deviceFlags.native && this.deviceService.deviceFlags.android && cordova?.plugins?.cordovaHelpers?.sendWeComMessage) {
      try {
        new Promise((res, rej) => {
          cordova.plugins.cordovaHelpers.sendWeComMessage(res, rej, corpId, agentId, message);
        });
        console.log("Opened WeCom application");
        this.openedApp = true;
      } catch (ex) {
        console.log("Not opened WeCom application");
        this.openedApp = false;
        this.notificationService.notify(this.translate.instant("WHATSAPP_IS_NOT_AVAILABLE"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
      } finally {
        this.uiService.dismissLoader();
      }
    }
  }

  async shareMessageChannel() {
    if (this.selectedActivity.channelActivityType == ChannelActivityType.SMS) {
      await this.shareSMS();
    } else if (this.selectedActivity.channelActivityType == ChannelActivityType.WHATSAPP) {
      await this.shareWhatsapp();
    }
    // } else if (this.selectedActivity.channelActivityType == ChannelActivityType.WHATSAPP/* && this.selectedActivity.channelName == "WeCom"*/) {
    //   await this.shareWeCom();
    // }/* else if (this.selectedActivity.channelActivityType == ChannelActivityType.WHATSAPP) {
    //   await this.shareWhatsapp();
    // }*/
  }

  async fetchVersionUpdateTemplates() {
    if (this.matchedMessageTemplates.length) {
      const groupIds = this.matchedMessageTemplates.map((o) => {
        return o.indskr_groupguid
      });
      if (Array.isArray(groupIds) && groupIds.length) {
        let templates = await this.emailDataService.fetchVersionUpdateTemplates(groupIds);
        this.matchedMessageTemplates.forEach((temp: ResourceEmailTemplate) => {
          temp._id = DB_KEY_PREFIXES.EMAIL_TEMPLATE + temp.indskr_emailtemplateid;
          this.disk.retrieve(temp._id.toString()).then(async (exist) => {
            if (exist) {
              let updatedTemplate = templates.find(t => t.indskr_groupguid === exist.indskr_groupguid);
              if (updatedTemplate) {
                updatedTemplate._id = DB_KEY_PREFIXES.EMAIL_TEMPLATE + updatedTemplate.indskr_emailtemplateid;
                exist = {
                  ...exist,
                  indskr_description: updatedTemplate.indskr_description,
                  indskr_name: updatedTemplate.indskr_name,
                  indskr_editablebyuser: updatedTemplate.indskr_editablebyuser,
                  indskr_available_from: updatedTemplate.indskr_available_from,
                  indskr_available_until: updatedTemplate.indskr_available_until,
                  indskr_body: updatedTemplate.indskr_body,
                  product_id: updatedTemplate.product_id,
                  content_tokens: updatedTemplate.content_tokens,
                  createdon: updatedTemplate.createdon,
                  modifiedon: updatedTemplate.modifiedon,
                  indskr_thumbnailurl: updatedTemplate.indskr_thumbnailurl,
                  channelTypes: updatedTemplate.channelTypes,
                  productName: updatedTemplate.productName,
                  therapeuticAreaName: updatedTemplate.therapeuticAreaName,
                  indskr_type: updatedTemplate.indskr_type,
                  statuscode: updatedTemplate.statuscode,
                  indskr_fb_body: updatedTemplate.indskr_fb_body,
                  indskr_sms_body: updatedTemplate.indskr_sms_body,
                  indskr_whatsapp_body: updatedTemplate.indskr_whatsapp_body,
                  indskr_email_subject: updatedTemplate.indskr_email_subject
                };
                await this.disk.updateOrInsert(updatedTemplate._id.toString(), doc => exist)
                  .catch(error => console.error('EmailService: saveToDisk: ', error));
              } else {
                await this.disk.remove(temp._id.toString());
              }
            }
          });
        });
      }
    }
  }

  get filteredTemplates(): ResourceEmailTemplate[] {
    const email: EmailActivity = this.selectedActivity;
    let templates: ResourceEmailTemplate[] = [];
    const channelId = email.indskr_channelid;
    const filterType =
      this.viewType === EmailViewType.CREATE_FROM_MEETING &&
      this.isRemoteEmail
        ? EmailTemplateType.RemoteURL
        : EmailTemplateType.Resource;
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      if(cha){
        const requiredTemplateId: string = String(cha?.indskr_consenttypeid);
        templates = cloneDeep(this.emailTemplates.filter((template) => template.channelTypes.some((ct) => ct.channelId === requiredTemplateId) && template.indskr_type === filterType));
      }

    }
    return templates;
  }

  // selected channel info for checking external/preview flag
  public getChannelInfo(channelId) {
    const selectedChannelId = channelId;
    const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === selectedChannelId);
      if (!_.isEmpty(cha)) {
        this.selectedActivity.channelActivityType = cha.activityType;
        if (cha.indskr_externalchannel === true) {
          this.selectedActivity.isMessageExternal = true;
          this.selectedActivity.isPreviewEnabled = cha.indskr_enablepreview === true ? true : false;
        } else {
          this.selectedActivity.isMessageExternal = false;
          this.selectedActivity.isPreviewEnabled = false;
        }
      }
      else {
        // for the deactivate channel - need to be updated because current savedChannels has only activate channel data
      }
  }
  // To find the content token id for hiding content token
  public getHideContentTokenId(): string {
    const currentUser: User = this.authService.user;
    const contentTokens: ContentToken[] = this.contentTokens;
    let hideContentTokenId: string = '';
    if (currentUser.hasOwnProperty('ioConfigurations') && currentUser['ioConfigurations'].length > 0) {
      let foundConfigValue: string = '';
      foundConfigValue = currentUser.ioConfigurations.find(c => c.configName === 'Email.BOT.URL.Token').configValue;
      if (foundConfigValue) {
        const foundHideContentToken: ContentToken = contentTokens.find(ct => ct.indskr_name === foundConfigValue);
        hideContentTokenId = foundHideContentToken ? foundHideContentToken.indskr_contenttokenid : '';
      }
    }
    return hideContentTokenId;
  }
  public isHideContentTokenOnly(template: string): boolean {
    const hideContentTokenId: string = this.getHideContentTokenId();
    const numOfCustomToken: number = template.toLowerCase().split('%custom.').length - 1;
    const numOfHideContentToken: number = template.split(hideContentTokenId).length - 1;
    const isHideContentTokenOnly: boolean = numOfCustomToken === numOfHideContentToken ? true : false;
    return isHideContentTokenOnly;
  }

  public setSecondaryTextForMessages(activity: EmailActivity): string {
    const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === activity.indskr_channelid);
    let channelTypeText;
    if (channel != undefined) {
      channelTypeText = String(channel?.indskr_consentType);
    } else if(activity.channelName){
      channelTypeText = activity.channelName;
    }
     else {
      channelTypeText = activity.channelType;
    }
    return channelTypeText ? ((channelTypeText === 'Email') ? this.translate.instant('EMAIL') : channelTypeText) : channelTypeText;
  }

  public setSecondaryTextTwoForMessages(activity: EmailActivity): string {
    const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === activity.indskr_channelid);
    let subjectText;
    if (activity.emailStatus !=1) {
      // In case of autoSubject, To avoid the same text from autoSubject
      subjectText = activity.emailSubject === this.setPrimaryTextRightForMessages(activity) ? '' : activity.emailSubject || '';
    } else {
      if (channel != undefined) {
        // External channel message with turned off preview
        if (channel.indskr_externalchannel && !channel?.indskr_enablepreview) {
          subjectText = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) ? '' : activity.emailSubject === 'Message' ? '' : activity.emailSubject;
        // message with template
        } else {
          subjectText = activity.emailSubject === 'Message' ? '' : activity.emailSubject;
        }
      } else subjectText = activity.template_id ? this.getTemplateSubject(activity.template_id) || '' : '';
    }
    return subjectText;
  }

  public getTemplateSubject(template_id): string {
    this.template = this.emailTemplates.find(temp => temp.indskr_emailtemplateid === template_id);
    if(this.template){
    return this.template.indskr_email_subject;
    }else{
      return '';
    }
  }

  public setTimeForMessages(activity: EmailActivity): string {
    return this.getDisplayTimeInActivityPane(activity);
  }

  public setPrimaryTextRightForMessages(activity: EmailActivity): string {
    // const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === activity.indskr_channelid);
    if (_.isEmpty(activity?.emailActivityParties)) {
      return activity.channelName + '';
    } else {
      let fullName;
      if (activity.emailActivityParties && activity.emailActivityParties.length == 1) {
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);
          return `${fullName} - ${activity.channelName}`;
        } else return activity.channelName + '';
      } else if (activity.emailActivityParties && activity.emailActivityParties.length >= 2) {
        let cntName = 0;
        let cntNameStr = '';
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);;
        }
        for (let emailActivityParty in activity.emailActivityParties) {
          if (activity.emailActivityParties[emailActivityParty]?.contact_firstname) {
            cntName = cntName + 1;
          }
        }
        cntNameStr = cntName == 0 ? '' : String(cntName = cntName - 1);
        return `${fullName} + ${cntNameStr} - ${activity.channelName}`;
      } else return activity.channelName + '';
    }
  }

  private getContactName(emailParty: EmailActivityParty) {
    return `${emailParty.contact_firstname} ${_.isEmpty(emailParty.contact_lastname) ? '' : emailParty.contact_lastname}`;
  }

  public getProductFromProductHierachy(productId? : string): Brand {
    let productHierarchies = this.surgeryOrderDataService.productHierarchies;
    let product;
    let selctedProductId = (productId) ? productId : this.selectedActivity.product_id;

    if (productHierarchies) {
      productHierarchies.forEach(surProd => {
        if ((surProd.surgeryId == selctedProductId) && !product) {
          var foundProduct = {
            productid: surProd.surgeryId,
            name: surProd.surgeryName,
            description: surProd.surgeryName
          }
          product = new Brand(foundProduct);
        }
        if (!product) {
          surProd.productCategories.forEach(prodCat => {
            if (prodCat.productCategoryId == selctedProductId) {
              foundProduct = {
                productid: prodCat.productCategoryId,
                name: prodCat.productCategoryName,
                description: prodCat.productCategoryName
              }
              product = new Brand(foundProduct);
            }
            if (!product) {
              prodCat.products.forEach(prod => {
                if (prod.productId == selctedProductId) {
                  foundProduct = {
                    productid: prod.productId,
                    name: prod.productName,
                    description: prod.productName
                  }
                  product = new Brand(foundProduct);
                }
              });
            }
          });
        }
      });
    }
    return product;
  }

  public translateChannelType(type:string): string {
    let translatedStr: string = type;
    switch(type) {
      case 'Email':
        translatedStr = this.translate.instant('EMAIL');
        break;
      case 'Phone':
        translatedStr = this.translate.instant('PHONE');
        break;
      case 'Visit':
        translatedStr = this.translate.instant('VISIT');
        break;
      case 'Fax':
        translatedStr = this.translate.instant('FAX');
        break;
      case 'Address':
        translatedStr = this.translate.instant('ADDRESS');
        break;
      default:
        break;
    }
    return translatedStr;
  }

  public checkSentEmailRecipientByTemplate(contactId: string, templateId: string): boolean {
    let isSentRecipient: boolean = false;
    if(!_.isEmpty(this.activityService.activities)) {
      const filteredActivities = this.activityService.activities.filter(activity => 
        activity instanceof EmailActivity && 
        activity.type == 'Email' && 
        !_.isEmpty(activity.template_id)
      );
      
      if(!_.isEmpty(filteredActivities)) {
        const emailActivities = filteredActivities as EmailActivity[]; 
        const groupEmailActivitiesByTemplate = _.groupBy(emailActivities, 'template_id');
        const targetEmailActivities = groupEmailActivitiesByTemplate[templateId];
        
        if(!_.isEmpty(targetEmailActivities)) {
          targetEmailActivities.some((ea: EmailActivity) => {
            if(!_.isEmpty(ea.emailActivityParties)) {
              ea.emailActivityParties.some((ep: EmailActivityParty) => {
                if((ep.email_statuscode == EmailStatusCodes.Sent || ep.email_statuscode == EmailStatusCodes.Read)
                  && ep.indskr_contactid == contactId) {
                  isSentRecipient = true;
                  return true;
                }
              });
            }
            if(isSentRecipient) return true;
          });
        }
      }
    }
    return isSentRecipient;
  }

  public checkEmailAddressForFirstTimeEmail(activityId: string): boolean {
    let hasFirstTimeEmailAddress: boolean = true;
    if (activityId && this.selectedActivity && activityId == this.selectedActivity.activityid) {
      hasFirstTimeEmailAddress = this.selectedActivity?.emailActivityParties[0]?.emailAddresses.length > 0 || false;
    }
    return hasFirstTimeEmailAddress;
  }

  public async discardChagesForEmail(): Promise<Boolean> {
    let cancelText = this.translate.instant("CANCEL");
    return new Promise<Boolean>(resolve => {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant("FIRST_TIME_EMAIL_DISCARD")
      }, this.translate.instant('DISCARD'), cancelText
      ).then(res => {
        if (res.role == "ok") {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}
