<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel"
                     [hideFilterButton]="true"
                     [searchDebounce]="750"
                     [(searchText)]="query" 
                     (onControlClick)="onPageTitleControlClick($event)"
                     (onSearchInput)="searchAddressGlobally($event)"
                     (onSearchClear)="clearSearch($event)">
    </ind-header-left>
</ion-header>
<ion-content>
    <ng-template [ngIf]="!noResult" [ngIfElse]="nodata">
        <ion-item-group>
            <ion-item-divider>
                {{ 'SEARCH_RESULTS' | translate}}
            </ion-item-divider>
            <ion-list>
                <ion-radio-group (ionChange)="initHeader()" [(ngModel)]="selectedaddress">
                    <ion-item *ngFor="let address of contactAddress">
                        <ion-label>{{address.compositeAdd}}</ion-label>
                        <ion-radio slot="start" [value]="address"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>
        </ion-item-group>
    </ng-template>
    <ng-template #nodata>
            <ion-item-group>
                <ion-item-divider>
                    {{ 'SEARCH_RESULTS' | translate}}
                </ion-item-divider>
                <ion-list>
                    <ion-item>
                        <ion-label>{{ 'NO_DATA' | translate}}...</ion-label>
                    </ion-item>
                </ion-list>
            </ion-item-group>
        </ng-template>
</ion-content>
<io-spinner [displayText]="'please wait...'" *ngIf="loading"></io-spinner>
