import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IndTabsDataModel} from '../../../models/ind-tabs-data-model';
import {IonSegment} from '@ionic/angular';

/**
 * The component utilizing the IndTabsDataModel interface is a tabbed user interface (UI) component. This component is designed to display multiple tabs, each representing a distinct section or category of content within the application.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-tabs',
  templateUrl: 'ind-tabs.html',
  styleUrls: ['ind-tabs.scss']
})
export class IndTabsComponent implements AfterViewChecked {
  @Input() data: IndTabsDataModel[];
  @Input() selectedTab: string;
  @Output() selectedTabChange = new EventEmitter<string>();
  @ViewChild(IonSegment, { read: ElementRef }) ionSegment: ElementRef;
  @Input() type: string = 'ios';
  @Input() showSkeleton: boolean = false;

  constructor() { }

  ngAfterViewChecked() {
    if (this.ionSegment) {
      (this.ionSegment.nativeElement as HTMLElement).scrollLeft += this.getWidthToScroll();
    }
  }

  public segmentChanged() {
    if (this.ionSegment) {
      (this.ionSegment.nativeElement as HTMLElement).scrollLeft += this.getWidthToScroll();
    }
    this.selectedTabChange.emit(this.selectedTab);
  }

  getWidthToScroll(): number {
    const scrollElement = (this.ionSegment.nativeElement as HTMLElement);
    let ele = document.getElementsByClassName('segment-button-checked')[0];
    if (ele && ele.getBoundingClientRect().right > scrollElement.getBoundingClientRect().right) {
      return ele.getBoundingClientRect().width;
    }
    if (ele && ele.getBoundingClientRect().left < scrollElement.getBoundingClientRect().left) {
      return -1 * ele.getBoundingClientRect().width;
    }
    return 0;
  }

  // get shouldApplyTextWrapToSegmentLabel() {
  //   return (this.data && this.data.length > 2);
  // }

}
