import { Observable, Subject } from 'rxjs';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { DynamicFormType, DynamicFormComponentModel } from '@omni/models/dynamic-form-component.model';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { DynamicForm, FormType, BusinessProcessType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { DefaultAccountDisplayForm } from '@omni/config/dynamic-forms/default-account-display';
import { PageName, NavigationService } from '@omni/services/navigation/navigation.service';
import { defaultAccountCreateForm } from '@omni/config/dynamic-forms/default-account-create';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackService, TrackingEventNames } from '@omni/services/logging/tracking.service';
import { DynamicFormComponent } from '@omni/components/shared/ind-dynamic-form/ind-dynamic-form';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { UIService, ComponentViewMode } from '@omni/services/ui/ui.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import {ChangeRequestType, MDMRequestType, MDMType} from '@omni/classes/mdm/source-type-optionset.class';
import { DeviceService } from '@omni/services/device/device.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { MdmService } from '@omni/services/mdm/mdm.service';
import _ from 'lodash';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NewActivityDataModel } from '@omni/models/indNewActivityDataModel';
import { ActivitySource } from '@omni/services/activity/activity.service';
import { NewChangeRequestComponent } from '@omni/components/mdm/new-change-request/new-change-request';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Utility, MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX } from '@omni/utility/util';
import { toBase64 } from '@omni/utility/util';
import { IONote } from '@omni/classes/io/io-note.class';
import { DiskService, OFFLINE_DB_LINKED_ENTITY_NAME } from '@omni/services/disk/disk.service';
import { isBefore } from 'date-fns';
import { unsubscribeSubscriptionArray } from '../../../utility/common.utility';
import { takeUntil } from 'rxjs/operators';
import { Account } from '@omni/classes/account/account.class';
import { VeevaWidgetService } from '@omni/services/veeva-widget/veeva-widget.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';



@Component({
    selector: 'configured-account-info',
    templateUrl: 'configured-account-info.html',
    styleUrls:['configured-account-info.scss']
})

export class ConfiguredAccountInfoComponent {

    public isAccountEditEnabled:boolean = false;
    public isEditDisabled:boolean = false;
    public oneKeyEditFeatureEnabled:boolean = false;
    public businessEditFeatureEnabled:boolean = false;
    private isPreViewMode: boolean = false;
    private readonly accountListMode;

    public displayFormDetails;
    public rawFormData;
    public dynamicFormType:DynamicFormType;
    public accountLinkedEntityDBPrefix:string = DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY;
    @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;
    @Input() scrollObservable: Observable<any>;
    @Input() refreshAccountNotesObservable: Observable<boolean>;
    @ViewChild('attachInput') attachInput: ElementRef;

    private linkEntitiesReqPayload: any = [];
    public notesSectionHeaderViewData:IndSectionHeaderViewDataModel;
    public tempNoteText: string = '';
    public notesPlaceholder: string = '';
    public isAccountNotesEnabled: boolean = false;
    public isNotesAttachmentEnabled: boolean = false;
    public attachmentTitle: string = '';
    public isAttachmentAdded: boolean = false;
    private attachmentFile: any;
    private base64str;
    public accountNotes: Array<IONote> = [];
    private ngDestroy$ = new Subject<boolean>();


    constructor(
        public accountService: AccountOfflineService,
        private accountDataService: AccountDataService,
        private dynamicFormsService: DynamicFormsService,
        private navService: NavigationService,
        private translate: TranslateService,
        private trackingService: TrackService,
        private footerService: FooterService,
        public uiService: UIService,
        private eventService: EventsService,
        private notificationService: NotificationService,
        private alertService: AlertService,
        private authService: AuthenticationService,
        private readonly navParams: NavParams,
        private device: DeviceService,
        private contactService: ContactOfflineService,
        private modalCtrl: ModalController,
        private mdmService: MdmService,
        private iab: InAppBrowser,
        private disk: DiskService,
        private veevaWidgetService: VeevaWidgetService,
        private dynamics: DynamicsClientService,
        private loadingCtrl: LoadingController
    ){
        this.accountListMode = (this.navParams.data && this.navParams.data.listMode);
    }

    ngOnInit() {
      if (this.accountListMode === ComponentViewMode.ADDNEW || this.accountListMode === ComponentViewMode.SELECTION || this.accountListMode === ComponentViewMode.READONLY) {
        this.isAccountEditEnabled = false;
      }

      this.businessEditFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_EDIT);
      this.oneKeyEditFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST) && this.accountService.selected ? this.accountService.selected.indskr_mdm == MDMType.ONEKEY : false;
      this.isAccountEditEnabled = this.businessEditFeatureEnabled || this.oneKeyEditFeatureEnabled;
      this.isAccountNotesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_NOTES);
      this.isNotesAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_ATTACHMENT_UPLOAD);

      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_EDIT) && this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent && this.accountListMode == ComponentViewMode.ADDNEW) {
        this.isAccountEditEnabled = true;
      }
      if (this.isAccountEditEnabled && (this.accountService.selected ? this.accountService.selected.indskr_mdm == MDMType.VEEVA : false)) {
        // this.veevaWidgetService.loadVeevaScripts();
      }
      if (this.accountService.accountPageMode === ComponentViewMode.PREVIEW) {
        this.isPreViewMode = true;
        this.isAccountEditEnabled = false;
        this.isEditDisabled  = true;
      } else {
        this.isPreViewMode = false;
        this.isAccountEditEnabled = this.businessEditFeatureEnabled || this.oneKeyEditFeatureEnabled;
        this.isEditDisabled  = false;
      }
      if (this.device.isOffline) this.isEditDisabled = true;
      this.refreshAccountNotesObservable.subscribe(refreshNotes => {
        if (refreshNotes) {
          this.initAccountNotesData();
          this._initAccountNotesViewData();
        }
      });
      this.eventService.observe("highlightAccount").pipe(
        takeUntil(this.ngDestroy$))
        .subscribe((account: Account) => {
          if (this.accountService.accountPageMode === ComponentViewMode.PREVIEW) {
            this.isPreViewMode = true;
            this.isAccountEditEnabled = false;
            this.isEditDisabled  = true;
          } else {
            this.isPreViewMode = false;
            this.isAccountEditEnabled = this.businessEditFeatureEnabled || this.oneKeyEditFeatureEnabled;
            this.isEditDisabled  = false;
          }
          if (this.device.isOffline) this.isEditDisabled = true;
          if (this.accountService.isOneKeySearching) {
            this.initOKAccountInfo();
          } else {
            this.initAccountInfo();
            setTimeout(() => {
              this._initAccountNotesViewData();
            }, 100);
          }
        });
    }
  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
    ngAfterViewInit(){
        if (this.accountService.isOneKeySearching) {
          this.initOKAccountInfo();
        } else {
          this.initAccountInfo();
          setTimeout(() => {
            this._initAccountNotesViewData();
          }, 100);
        }
        if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }

    private async initAccountInfo() {
        if (this.accountService.selected) {
          let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM);
          let formType = DynamicFormType.CONFIGUREDFORM;
          if (!accountForm) {
            accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
            formType = DynamicFormType.DEFAULTFORM;
          }
          this.dynamicFormType = formType;
          this.displayFormDetails = accountForm;
          this.rawFormData = this.accountService.selected.raw;
          this.eventService.publish('refreshAccountDetailPageTitle');
        }
    }

    private async initOKAccountInfo() {
      if (this.accountService.selectedOKAccount) {
        let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM, BusinessProcessType.OneKey);
        let formType = DynamicFormType.CONFIGUREDFORM;
        if (!accountForm) {
          accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
          formType = DynamicFormType.DEFAULTFORM;
        }
        this.dynamicFormType = formType;
        this.displayFormDetails = accountForm;
        this.isAccountEditEnabled = false;
        this.rawFormData = this.rawOKAccountObj;
        this.eventService.publish('refreshAccountDetailPageTitle');
      }
    }

    get rawOKAccountObj(): any {
      let okAccount = this.accountService.selectedOKAccount;
      let rawAccount =  {
        "accountid":"",
        "indskr_accountsourcetype":ChangeRequestType.MDM,
        "indskr_accountsourcetype@OData.Community.Display.V1.FormattedValue":"MDM",
        "indskr_accountsourcetype_Formatted":"MDM",
        "indskr_mdm":MDMType.ONEKEY,
        "indskr_mdm@OData.Community.Display.V1.FormattedValue":"OneKey",
        "indskr_mdm_Formatted":"OneKey",
        "indskr_mdmid":okAccount['workplaceEid'],
        "name":this.accountService.getAccountName(okAccount),
        "statuscode":Number(okAccount['statusCode']),
        "statuscode@OData.Community.Display.V1.FormattedValue":okAccount['statusCorporateLabel'],
        "statuscode_Formatted":okAccount['statusLabel'],
        "omnione_officialworkplacenameina2ndalphabetlangua": okAccount['officialName2'] ? okAccount['officialName2'] : "",
        "omnione_officialworkplacenameina2ndalphabetlangua@OData.Community.Display.V1.FormattedValue": okAccount['officialName2'] ? okAccount['officialName2'] : "",
        "omnione_providestraining": okAccount['isOfferingTraining'] ? 1 : 0,
        "omnione_providestraining@OData.Community.Display.V1.FormattedValue": okAccount['isOfferingTraining'] ? 'Yes' : 'No',
        "omnione_accountmanagermdmid": okAccount['managerWorkplaceEid'] ? okAccount['managerWorkplaceEid'] : "",
        "omnione_accountmanagermdmid@OData.Community.Display.V1.FormattedValue": okAccount['managerWorkplaceEid'] ? okAccount['managerWorkplaceEid'] : "",
        "omnione_accountmanagerofficialname": okAccount['managerWorkplaceOfficialName'] ? okAccount['managerWorkplaceOfficialName'] : "",
        "omnione_accountmanagerofficialname@OData.Community.Display.V1.FormattedValue": okAccount['managerWorkplaceOfficialName'] ? okAccount['managerWorkplaceOfficialName'] : "",
        "omnione_accountmanagerofficialname2": okAccount['managerWorkplaceOfficialName2'] ? okAccount['managerWorkplaceOfficialName2'] : "",
        "omnione_accountmanagerofficialname2@OData.Community.Display.V1.FormattedValue": okAccount['managerWorkplaceOfficialName2'] ? okAccount['managerWorkplaceOfficialName2'] : "",
        "omnione_accountmanagername": okAccount['managerWorkplaceUsualName'] ? okAccount['managerWorkplaceUsualName'] : "",
        "omnione_accountmanagername@OData.Community.Display.V1.FormattedValue": okAccount['managerWorkplaceUsualName'] ? okAccount['managerWorkplaceUsualName'] : "",
        "omnione_accountmanagename2": okAccount['managerWorkplaceUsualName2'] ? okAccount['managerWorkplaceUsualName2'] : "",
        "omnione_accountmanagename2@OData.Community.Display.V1.FormattedValue": okAccount['managerWorkplaceUsualName2'] ? okAccount['managerWorkplaceUsualName2'] : "",
        "indskr_noofbeds": okAccount['profileDatas'] ? okAccount['profileDatas']['NB_BEDS,1']['valueForNumber'] : "",
        "indskr_noofbeds@OData.Community.Display.V1.FormattedValue": okAccount['profileDatas'] ? okAccount['profileDatas']['NB_BEDS,1']['valueForNumber'] : ""
      }
      if (okAccount['workplaceAddresses'] && okAccount['workplaceAddresses']['P,1'] && okAccount['workplaceAddresses']['P,1']['address']) {
        let addressObj = okAccount['workplaceAddresses']['P,1']['address'];
        let rawAddressObj = {
          'indskr_indskr_customeraddress_v2id': "",
          '_indskr_address_value@OData.Community.Display.V1.FormattedValue': addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] :"",
          '_indskr_address_value': addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] :"",
          // 'state': "",
          // 'city': "",
          'country': addressObj['postalTownReference'] ? addressObj['postalTownReference']['country'] : "",
          'indskr_primary@OData.Community.Display.V1.FormattedValue': okAccount['workplaceAddresses']['P,1']['typeCode'] && okAccount['workplaceAddresses']['P,1']['typeCode'] == "P" ? 'Yes' : 'No',
          'indskr_primary': okAccount['workplaceAddresses']['P,1']['typeCode'] && okAccount['workplaceAddresses']['P,1']['typeCode'] == "P"
        }
        rawAccount['accountAddress'] = [rawAddressObj];
      }
      return rawAccount;
    }

    @HostListener('window:veeva-network-dcr-submitted', ['$event'])
    async dcrSubmitted(event) {
      console.log(event);
      if (this.accountService.selected.id && event.detail.taskId) {
        await this.dynamics.dwa.create({
          omniveev_externalid: event.detail.taskId,
          omniveev_type: 200000001,
          'omniveev_customer_account@odata.bind': `/accounts(${this.accountService.selected.id})`
        }, "omniveev_datachangerequests");
      }
    }

    public async handleAccountEdit(ev){
      if (this.device.isOffline) return;
      this.dynamicFormsService.okLookUpMapper = [];
        if (this.accountService.selected.indskr_mdm == MDMType.VEEVA) {
          await this.veevaWidgetService.openProfileWidget(
            this.accountService.selected.extendedDetails.externalId,
            this.accountService.selected.accountName
          );
        }
        else if (this.accountService.selected.indskr_mdm === MDMType.ONEKEY) {
          if (this.businessEditFeatureEnabled && this.oneKeyEditFeatureEnabled) {
            const newActivityModel: NewActivityDataModel = {
              source: ActivitySource.ONE_KEY_ACCOUNT_EDIT,
              displayOptions: [
                {
                  id: "business_information",
                  formattedLabel: this.translate.instant('ONE_KEY_BUSINESS_INFORMATION'),
                  isChecked: true
                },
                {
                  id: "one_key_information",
                  formattedLabel: this.translate.instant('ONE_KEY_MDM_INFORMATION'),
                  isChecked: false
                }
              ],
              title: this.translate.instant('EDIT') + ' ' + this.accountService.selected.accountName,
              eventHandler: (data) => this.handleSelectEvent(data)
            }
            this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.ContactPageComponent, {newActivityDataModel : newActivityModel});
          } else if (this.businessEditFeatureEnabled){
            this.accountService.isOneKeyChangeRequest = false;
            this.contactService.isOneKeyChangeRequest = false;
            this.oneKeyAccountBusinessInforamtionEditFlow();
          } else {
            await this.oneKeyAccountEditFlow();
          }

        }
        else if(this.accountService.selected && this.navService.getActiveChildNavViewPageName() != PageName.DynamicFormComponent && this.navService.getActiveChildNavViewMasterPageName() == PageName.AccountPageComponent){
          let accountForm: DynamicForm = await this.getFormDefinition();
          let formType = DynamicFormType.CONFIGUREDFORM;
          if(!accountForm){
            accountForm = new DynamicForm(defaultAccountCreateForm['value'][0]);
            formType = DynamicFormType.DEFAULTFORM;
          }
          let accountRaw = await this.accountDataService.getRawAccountDetailsById(this.accountService.selected.id);
          let accountIdData = {
            accountid: this.accountService.selected.id,
          };
          let formDetail: DynamicFormComponentModel = {
            title: this.translate.instant('EDIT') + ' ' + this.accountService.selected.accountName,
            LookupEntitySetName: 'accounts',
            showLeftHeaderButton: true,
            mainEntityObjectId: accountIdData,
            leftHeaderBtnIcon: 'chevron-back-outline',
            showRightHeaderButton: true,
            rightHeaderImgSrc: 'assets/imgs/header_save.svg',
            rightHeaderBtnText: this.translate.instant('SAVE'),
            formMetadata: accountForm,
            formEntityObject: accountRaw,
            dynamicFormType: formType,
            navOptions: { animate: false },
            eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel)=> this._handleAccountFormComponentEvent(data,eventTarget),
          };

          this.trackingService.tracking('EditSelected', TrackingEventNames.ACCOUNT_EDIT,true);
          this.accountService.isOneKeyChangeRequest = false;
          this.contactService.isOneKeyChangeRequest = false;
          this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent,PageName.DynamicFormComponent,PageName.AccountPageComponent,{
            MODE:'EDIT_MODE',
            FORM_DETAIL: formDetail,
            LINKED_ENTITY_DB_PREFIX: this.accountLinkedEntityDBPrefix,
            RAW_DATA: this.rawFormData,
            REF_ENTITY_NAME: 'accountid'
          });
          this.footerService.initButtons(FooterViews.Accounts);
          this.navService.setChildNavRightPaneView(true);
          this.uiService.showNewActivity = false;
          this.eventService.publish(EventName.ACCOUNTEDITPAGEACTIVE);
        }

      }

      async handleSelectEvent(data) {
        switch (data.id) {
          case "one_key_information":
            await this.oneKeyAccountEditFlow();
            break;
          case "business_information":
            this.oneKeyAccountBusinessInforamtionEditFlow();
            break;
          default:
            break;
        }
      }

    private async oneKeyAccountBusinessInforamtionEditFlow() {
      let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity('account', FormType.EDITFORM, BusinessProcessType.OneKey);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if(!accountForm){
        this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
        return;
      }
      let accountRaw = await this.accountDataService.getRawAccountDetailsById(this.accountService.selected.id);
      let accountIdData = {
        accountid: this.accountService.selected.id,
      };
      let formDetail: DynamicFormComponentModel = {
        title: this.translate.instant('EDIT') + ' ' + this.accountService.selected.accountName,
        LookupEntitySetName: 'accounts',
        showLeftHeaderButton: true,
        mainEntityObjectId: accountIdData,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrc: 'assets/imgs/header_save.svg',
        rightHeaderBtnText: this.translate.instant('SAVE'),
        formMetadata: accountForm,
        formEntityObject: accountRaw,
        dynamicFormType: formType,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel)=> this._handleAccountFormComponentEvent(data,eventTarget),
      };

      this.trackingService.tracking('EditSelected', TrackingEventNames.ACCOUNT_EDIT,true);
      this.accountService.isOneKeyChangeRequest = false;
      this.contactService.isOneKeyChangeRequest = false;
      this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent,PageName.DynamicFormComponent,PageName.AccountPageComponent,{
        MODE:'EDIT_MODE',
        FORM_DETAIL: formDetail,
        LINKED_ENTITY_DB_PREFIX: this.accountLinkedEntityDBPrefix,
        RAW_DATA: this.rawFormData,
        REF_ENTITY_NAME: 'accountid'
      });
      this.footerService.initButtons(FooterViews.Accounts);
      this.navService.setChildNavRightPaneView(true);
      this.uiService.showNewActivity = false;
      this.eventService.publish(EventName.ACCOUNTEDITPAGEACTIVE);
    }

    private async oneKeyAccountEditFlow() {
      let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_accountcr', FormType.EDITFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if (!accountForm) {
        this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
        return;
      }
      let rawObjectData = this.rawFormData;
      rawObjectData = {
        indskr_name: this.accountService.selected.accountName,
        indskr_mdm: this.accountService.selected.indskr_mdm,
        indskr_mdmrequesttype: MDMRequestType.UPDATE,
        ...rawObjectData
      }
      // const accountRaw = {
      //   indskr_mdmid: this.accountService.selected.indskr_mdmid,
      //   indskr_name: this.accountService.selected.accountName,
      //   indskr_accountsourcetype: this.accountService.selected.indskr_accountsourcetype,
      //   indskr_mdm: this.accountService.selected.indskr_mdm,
      //   indskr_mdmrequesttype: MDMRequestType.UPDATE
      // }
      const accountRaw = rawObjectData;

      this.mdmService.addLinkEntities([]);
      let formDetail: DynamicFormComponentModel = {
        title: this.translate.instant('EDIT') + ' ' + this.accountService.selected.accountName,
        LookupEntitySetName: 'indskr_accountcr',
        showLeftHeaderButton: true,
        mainEntityObjectId: null,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrc: 'assets/imgs/header_save.svg',
        rightHeaderBtnText: this.translate.instant('SAVE'),
        formMetadata: accountForm,
        formEntityObject: accountRaw,
        dynamicFormType: formType,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleOnekeyAccountFormComponentEvent(data, eventTarget),
      };
      this.trackingService.tracking('EditSelected', TrackingEventNames.ACCOUNT_EDIT, true);
      this.accountService.isOneKeyChangeRequest = true;
      this.contactService.isOneKeyChangeRequest = true;
      this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.AccountPageComponent, {
        MODE: 'EDIT_MODE',
        FORM_DETAIL: formDetail,
        TYPE: "ONE_KEY"
      });
      this.footerService.initButtons(FooterViews.Accounts);
      this.navService.setChildNavRightPaneView(true);
      this.uiService.showNewActivity = false;
      this.eventService.publish(EventName.ACCOUNTEDITPAGEACTIVE);
    }

  private loadIOSWidget() {
    let activeInstance = this.authService.userConfig.activeInstance;
    const veevaConfig = activeInstance.veevaConfig;
    this.iab.create(`${activeInstance.OmnipresenceWfeUrl}?veevaWidget=VEEVA_PROFILE&preset=account&id=${this.accountService.selected.extendedDetails.externalId}&title=${this.accountService.selected.accountName}&widgetName=${veevaConfig.profileWidgetName}&widgetID=${veevaConfig.profileWidgetId}&authDomain=${veevaConfig.authDomain}&manifestURL=${veevaConfig.manifestScriptUrl}&widgetURL=${veevaConfig.profileWidgetScriptUrl}`, '_system', { location: 'yes', hideurlbar: 'no', hidenavigationbuttons: 'no' });
    return;
  }

      private async getFormDefinition(){
        let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity('account',FormType.EDITFORM);
        if(accountForm){
          return accountForm;
        }
      }

      private async _handleOnekeyAccountFormComponentEvent(data, eventTarget) {
        if (eventTarget) {
          if (eventTarget == 'DONEBUTTONCLICK') {
            if (data && data.formValue) {
              this._handleOneKeyAccountUpdate(data);
            }
          } else if (eventTarget == 'CANCELBUTTONCLICK') {
            if (data && data.displayConfirmation) {
              this._displayDiscardConfirmation();
            } else {
              this._closeAccountFormPage();
            }
          } else if (eventTarget == 'ADDEDLINKEDENTITY') {
            this.trackingService.tracking('DoneSelected', TrackingEventNames.ACCOUNT_EDIT, true);

            if (data) {
              this.mdmService.addLinkEntities(data);
              if (data && data.eventsHandler) {
                data.eventsHandler(data, 'ENABLEFORM');
              }
            }
            this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, data);
          } else if (eventTarget == 'ISFORMDIRTY') {
            this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, data);
          }
        }
      }

      private async _handleAccountFormComponentEvent(data,eventTarget){
        if(eventTarget){
          if(eventTarget == 'DONEBUTTONCLICK'){
            if(data && data.formValue){
              this._handleAccountCreateAndUpdateOnline(data);
            }
          } else if (eventTarget == 'CANCELBUTTONCLICK') {
            if (data && data.displayConfirmation) {
              this._displayDiscardConfirmation();
            } else {
              this._closeAccountFormPage();
            }
          }else if(eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY'){
            this.trackingService.tracking('DoneSelected', TrackingEventNames.ACCOUNT_EDIT,true);
            if(data && data.entity && data.values) {
              this.addLinkEntitiesForAccount(data);
            }
            this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, data);
            // if(data && data.formValue && data.formValue.accountid){
            //   let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(data.formValue['accountid']);
            //   if (rawDetails) {
            //     await this.accountService.addUpdateRawAccountDetails(rawDetails);
            //     let selectedAccount = this.accountService.getAccountById(data.formValue['accountid']);
            //     if(selectedAccount && this.accountService.selected && this.accountService.selected.id == data.formValue['accountid']){
            //       this.accountService.selected = selectedAccount;
            //       this.initAccountInfo();
            //     }
            //   }
            // }
          }else if(eventTarget == 'ISFORMDIRTY'){
            this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT,data);
          }
        }
      }

      private async _handleOneKeyAccountUpdate(data) {
        let payload = _.cloneDeep(data.formValue);
        if (!payload) return;
        this.uiService.displayLoader();
        payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();
        payload['indskr_mdmrequesttype']= MDMRequestType.UPDATE;
        payload['indskr_mdmid'] = this.accountService.selected.indskr_mdmid;
        if (payload) {
          await this.mdmService.createOneKeyAccountCR(payload).then(async res => {
            try {
              if (res && res['indskr_accountcrid']) {
                if (data && data.eventsHandler) {
                  data.eventsHandler(res, 'DISABLEFORM');
                  this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                  this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, false);
                  this.notificationService.notify(this.translate.instant('CR_UPDATE_SUBMIT_MESSAGE', { crType: 'account', lastName: data.formValue['indskr_name'] }), 'Account-Form-Component');
                }
              }
            } catch (error) {
              console.error(error);
            }
            this.uiService.dismissLoader();
          }).catch(err => {
            this.uiService.dismissLoader();
            console.error(err);
          });
        }
        this.uiService.dismissLoader();
      }

      private async _handleAccountCreateAndUpdateOnline(data) {
        let payload = data.formValue;
        if (!payload) return;
        this.uiService.displayLoader();
        if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
          const accountContactAffReqPayload = [];
          for (let reqPayload of this.linkEntitiesReqPayload) {
            await this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity);
            if (reqPayload.entity === 'indskr_accountcontactaffiliations' && (payload.indskr_accounttype == '100000001' || payload.indskr_accounttype == '100000004')) {
              accountContactAffReqPayload.push({
                accountId: payload.accountid,
                accountName: payload.name,
                contactId: reqPayload?.values['lookupfields']?.find(req => req['entity'] === 'contacts').id,
                indskr_accounttype: 100000001
              });
            }
            // if (!res) {
            //   this.notificationService.notify( this.translate.instant('FAILED_CREATING_LINKED_ENTITY_GENERIC_ERROR_MESSAGE'), 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
            // }
          }
          this.eventService.publish("marketScan:newAccountContactAff", accountContactAffReqPayload);
          this.linkEntitiesReqPayload = [];
        }
        await this.accountDataService.createUpdateAccountOnline(payload).then(async res => {
          try {
            if (res && res['accountid']) {
              let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(res['accountid']);
              if (rawDetails) {
                await this.accountService.addUpdateRawAccountDetails(rawDetails);
                let newAccount = this.accountService.getAccountById(res['accountid']);
                this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, false);
                //this.eventService.publish(EventName.ACCOUNTCREATEDUPDATEDFROMAPP,newAccount);
                if (data && data.eventsHandler) {
                  if (data.isFormSaved) {
                    await this.accountService.getAccountRelationshipById(res['accountid']);
                    data.eventsHandler(res, 'NEWDATAUPDATED');
                    this.uiService.dismissLoader();
                    this.notificationService.notify(this.translate.instant('CONTACT_UPDATE_CREATED'), 'Account-Form-Component');
                  }
                }
              }
            }
          } catch (error) {
            console.error(error);
            // this.uiService.dismissLoader();
          }
        }).catch(err => {
          console.error(err);
          data.eventsHandler("ERROR", 'NEWDATAUPDATED');
          this.uiService.dismissLoader();
        });
        this.uiService.dismissLoader();
      }

      private _displayDiscardConfirmation(){
        let cancelText = this.translate.instant("CANCEL");
        if(this.translate.currentLang == 'it') {
          cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
        }
        this.alertService.showAlert({
            header: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
        ).then (res => {
            if(res.role == 'ok') {
              this._closeAccountFormPage();
            }
        });
      }

      private _closeAccountFormPage(){
        this.navService.popChildNavPageWithPageTracking().then(()=>{
          this.eventService.publish(EventName.ACCOUNTFORMISDIRTYEVENT, false);
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
          if(this.accountService.selected){
            let newAccount = this.accountService.getAccountById(this.accountService.selected.id);
            this.eventService.publish(EventName.ACCOUNTCREATEDUPDATEDFROMAPP,newAccount);
          }
          if(this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView){
              this.navService.setChildNavRightPaneView(false);
          }
          this.eventService.publish(EventName.ACCOUNTEDITPAGEACTIVE);
        });
      }

      private addLinkEntitiesForAccount(linkEntity) {
        if (_.isEmpty(linkEntity))
          this.linkEntitiesReqPayload = [];
        else {
          const linkEntityPayload = {
            "values": linkEntity["values"],
            "entity": linkEntity["entity"],
            "linkedEntityId": linkEntity["linkedEntityExistingDataId"],
            "addedNewDataId": linkEntity["linkedEntityAddedNewDataId"]
          }
          if (!_.isEmpty(this.linkEntitiesReqPayload)) {
            let index;
            if (linkEntity["linkedEntityExistingDataId"]) {
              index = this.linkEntitiesReqPayload.findIndex(x => x.linkedEntityId == linkEntity["linkedEntityExistingDataId"]);
            } else {
              index = this.linkEntitiesReqPayload.findIndex(x => x.addedNewDataId == linkEntity["linkedEntityAddedNewDataId"]);
            }
            if (index != -1) {
              this.linkEntitiesReqPayload[index] = linkEntityPayload;
            } else this.linkEntitiesReqPayload.push(linkEntityPayload);
          } else {
            this.linkEntitiesReqPayload.push(linkEntityPayload);
          }
        }
      }

  private _initAccountNotesViewData(){
    if (this.isAccountNotesEnabled || this.isNotesAttachmentEnabled) {
      let controls: any[] = [];
      if (this.isNotesAttachmentEnabled) {
        controls.push({
          id:'attach_note',
          text: this.translate.instant('ATTACH'),
          isDisabled: this.isPreViewMode
        });
      }
      controls.push({
        id: 'save_note',
        text: this.translate.instant('SAVE'),
        isDisabled: _.isEmpty(this.tempNoteText) && !this.isAttachmentAdded || this.isPreViewMode,
      });
      this.notesSectionHeaderViewData = {
        id: 'account_notes_section_header',
        title: this.translate.instant('ACCOUNT_NOTES'),
        controls: controls
      }
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    }
  }

  public notesChanged(ev): void {
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
    } else {
      this.tempNoteText = '';
    }
    this._initAccountNotesViewData();
  }

  public onSectionHeaderControlClick(id:string){
    switch(id){
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async handleSaveNote(){
    if (!_.isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      this.uiService.displayLoader();
      const now = new Date().getTime().toString();
      const offlinePayload = {
        annotationid: 'offlineaccountnote'+now,
        accountid: this.accountService.selected.id,
        createdon: now,
        notetext: this.tempNoteText,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
        deleted: false,
        pendingPushOnDynamics: true,
        isdocument : false
      };
      if (this.base64str) {
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }
      let currentNote = new IONote(offlinePayload);
      await this.accountService.updateAccountNoteInOfflineDB(offlinePayload);
      if(!this.device.isOffline){
        await this.accountDataService.uploadAccountNotesOnline().then(res=>{
          if (res && Array.isArray(res) && res.length>0 && res[0].noteid) {
            currentNote.noteId = res[0].noteid;
          }
        });
      }
      this.accountNotes.push(currentNote);
      this._sortCustomerNotes();
      this.tempNoteText = '';
      this.removeAttachment(null);
      this._initAccountNotesViewData();
      this.uiService.dismissLoader();
    }
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      const idx = this.accountNotes.findIndex(note => note.noteId == ev.noteId);
      if (idx < 0) {
        console.error("Error : coundn't find note id --> ", ev.noteId);
        return
      }
      let existingNote = this.accountNotes[idx];
      let offlinePayload: any =  {
        annotationid: existingNote.noteId,
        accountid: existingNote.accountId,
        createdon: existingNote.createdTime.getTime().toString(),
        notetext: existingNote.noteText,
        ownerName: existingNote.ownerName,
        ownerid: existingNote.ownerId,
        isdocument: existingNote.hasDocument,
        filename: existingNote.documentName,
        mimetype: existingNote.documentMimeType,
        filesize: existingNote.documentSize,
        pendingPushOnDynamics: true
      };
      if (existingNote.noteId.includes("offlineaccountnote") && existingNote.hasDocument && !_.isEmpty(existingNote.documentDataURL)) {
        offlinePayload.documentbody = existingNote.documentDataURL;
      }
      if (ev.action == 'DELETE' && idx >= 0) {
        offlinePayload.deleted = true;
        this.accountNotes.splice(idx, 1);
      } else if (ev.action == 'SAVE' && idx >= 0) {
        this.accountNotes[idx].noteText = offlinePayload.notetext= ev.updatedText
        if (ev.attachmentFileUpdated) {
          this.accountNotes[idx].hasDocument = offlinePayload['isdocument'] = true;
          offlinePayload['documentbody'] = ev.attachmentFileDataUrl;
          this.accountNotes[idx].documentName = offlinePayload['filename'] = ev.documentName;
          this.accountNotes[idx].documentSize = offlinePayload['filesize'] = ev.documentSize;
          this.accountNotes[idx].documentMimeType = offlinePayload['mimetype'] = ev.documentMimeType;
          offlinePayload['fileupdated'] = true;
        }
        if (ev.isAttachmentRemoved) {
          this.accountNotes[idx].hasDocument = offlinePayload['isdocument'] = false;
          this.accountNotes[idx].documentMimeType = this.accountNotes[idx].documentName = '';
          offlinePayload['documentbody'] = offlinePayload['filename'] =  offlinePayload['filesize'] =  offlinePayload['mimetype'] = '';
          offlinePayload['fileremoved'] = true;
        }
      }
      const loader = await this.loadingCtrl.create();
      loader.present();
      // Update Service
      this._sortCustomerNotes();
      await this.accountService.updateAccountNoteInOfflineDB(offlinePayload);
      if (!this.device.isOffline) {
        await this.accountDataService.uploadAccountNotesOnline().then(res => {
          if (ev.action != 'DELETE' && res && Array.isArray(res) && res.length>0 && res[0].noteid) {
            this.accountNotes[idx].noteId = res[0].noteid;
          }
        });
      }
      loader.dismiss();
    }
  }

  private handleNoteAttachment(){
    if(this.isNotesAttachmentEnabled){
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async loadImageFromDevice(event){
    if(event && event.target && event.target.files){
      try {
        const file = event.target.files[0];
        if ((file.size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initAccountNotesViewData();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Account Info','top','info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Account Info','top','info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public removeAttachment(event){
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    this._initAccountNotesViewData();
  }

  public isNoteDeletable(note: IONote): boolean {
    return ((this.isAccountNotesEnabled && this.isNotesAttachmentEnabled) || (this.isAccountNotesEnabled && !note.hasDocument) || (this.isNotesAttachmentEnabled && _.isEmpty(note.noteText))) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  public isNoteEditable(note: IONote): boolean {
    return (this.isAccountNotesEnabled || this.isNotesAttachmentEnabled) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  private async initAccountNotesData(){
    this.accountNotes = [];
    let dbNotes = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_NOTES);
    if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0){
      dbNotes.raw.forEach(item=>{
        if(this.accountService.selected && item['annotation.objectid'] == this.accountService.selected.id && item['annotation.annotationid'] && item['annotation.ownerid'] && item['annotation.createdon']){
          const payload = {
            annotationid: item['annotation.annotationid'],
            accountid: item['annotation.objectid'],
            createdon: item['annotation.createdon']?new Date(item['annotation.createdon']).getTime().toString():null,
            notetext: item['annotation.notetext'],
            ownerName: item['annotation.ownerid@OData.Community.Display.V1.FormattedValue'],
            ownerid: item['annotation.ownerid'],
            filename:   item['annotation.filename'],
            isdocument: !_.isEmpty(item['annotation.filename']),
            mimetype: item['annotation.mimetype']
          };
          let currentNote = new IONote(payload);
          let idx = this.accountNotes.findIndex(note => note.noteId == currentNote.noteId);
          if(idx >= 0){
            this.accountNotes[idx] = currentNote;
          }else{
            this.accountNotes.push(currentNote);
          }
        }
      });
    }
    let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_ACCOUNT_NOTES);
    if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0){
      offlineNotes.raw.forEach(item => {
        if(item && item.deleted){
          let idx = this.accountNotes.findIndex(note => note.noteId == item.annotationid);
          if(idx >= 0){
            this.accountNotes.splice(idx,1);
          }
        }else if(this.accountService.selected && item.accountid == this.accountService.selected.id){
          let currentNote = new IONote(item);
          let idx = this.accountNotes.findIndex(note => note.noteId == currentNote.noteId);
          if (idx >= 0) {
            this.accountNotes[idx] = currentNote;
          } else {
            this.accountNotes.push(currentNote);
          }
        }
      });
    }
    this._sortCustomerNotes();
  }

  private _sortCustomerNotes(){
    if(!_.isEmpty(this.accountNotes)){
      this.accountNotes = this.accountNotes.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }
}
