<ion-row>
    <ion-col class="left-pane">
        <ion-header>
            <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [searchDisabled]="isReadOnlyMode" [(searchText)]="searchValue" (onControlClick)="onHeaderControlClick($event)" (onSearchInput)="searchText($event)"></ind-header-left>

        </ion-header>

        <ion-content [ngClass]="{fixHeight: !deviceService.isMobileDevice, 'vectorimageleft': selectionData.length === 0 && !isSearching}">
            <ion-list *ngIf="!isReadOnlyMode" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                <ind-section-header *ngIf="userSelection.length > 0" [viewData]='{id:"selecteded_items",title: translate.instant("SELECTED_CAP")+" ("+userSelection.length+")"}'></ind-section-header>
                <!-- <ion-item-divider *ngIf="userSelection.length > 0">{{'SELECTED' | translate}} ({{userSelection.length}})</ion-item-divider> -->
                <ion-item-group class='selector-item' *ngFor="let el of userSelection">
                    <ion-item>
                        <ion-label>
                          <ion-text class="primary-text">{{el.name}}</ion-text>
                          <p class="secondary-text"><ion-text>{{el.emailaddress}}, {{el.businessunitname}}</ion-text></p>
                        </ion-label>
                        <img *ngIf="el.remoteMeetingJoinStatus == remoteMeetingState.JOINED" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                        <img *ngIf="el.remoteMeetingJoinStatus == remoteMeetingState.LEFT" src="assets/imgs/contact_disc.png" width="14" class="contact-state-icon" />
                        <!-- <ion-icon class="addButton" slot="end" float-right *ngIf="!i.checked" name="add" (click)="doSelection(i, true)"></ion-icon> -->
                        <ion-icon slot="end" *ngIf="el.checked && el.remoteMeetingJoinStatus != remoteMeetingState.JOINED" class='remove-icon' name="indegene-selectors-remove-icon" (click)="doSelection(el, false)"></ion-icon>
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
                <ind-section-header *ngIf="selectionData.length >= 0 && !isSearching" [viewData]='{id:"all_items",title: this.sectionHeaderTitle +" ("+selectionData.length+")"}'></ind-section-header>

                <div *ngIf="!isSearching">
                    <ion-item-group class='selector-item' *ngFor="let i of itemsToDisplay">
                        <ion-item>
                            <ion-label>
                              <ion-text class="primary-text">{{i.name}}</ion-text>
                              <p class="secondary-text">
                                <ion-text>{{i.emailaddress}}, {{i.businessunitname}}</ion-text>
                              </p>
                            </ion-label>
                            <ion-icon class="add-icon" slot="end" *ngIf="!i.checked" name="indegene-selectors-add-icon" (click)="doSelection(i, true)"></ion-icon>
                            <ion-icon class="checkmark-icon" slot="end" *ngIf="i.checked" name="indegene-selectors-checkmark-icon" (click)="doSelection(i, false)"></ion-icon>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </div>
                <div *ngIf="isSearching">
                    <ind-section-header *ngIf="searchResult.length >= 0" [viewData]='{id:"resulyt_items",title: translate.instant("RESULTS_CAP")+" ("+searchResult.length+")"}'></ind-section-header>
                    <ion-item-group class='selector-item' *ngFor="let i of searchResult">
                        <ion-item>
                            <ion-label>
                              <ion-text class="primary-text">{{i.name}}</ion-text>
                              <p class="secondary-text">
                                <ion-text>{{i.emailaddress}}, {{i.businessunitname}}</ion-text>
                              </p>
                            </ion-label>
                            <ion-icon class="add-icon" slot="end" *ngIf="!i.checked" name="indegene-selectors-add-icon" (click)="doSelection(i, true)"></ion-icon>
                            <ion-icon class="checkmark-icon" slot="end" *ngIf="i.checked" name="indegene-selectors-checkmark-icon" (click)="doSelection(i, false)"></ion-icon>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                    <ind-no-search-result *ngIf="searchValue && searchResult.length === 0 && isSearching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                </div>
            </ion-list>

            <ion-list *ngIf="isReadOnlyMode" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                <ind-section-header *ngIf="userSelection.length >= 0" [viewData]='{id:"selecteded_items",title: translate.instant("SELECTED_CAP")+" ("+userSelection.length+")"}'></ind-section-header>
                <ion-item-group *ngFor="let el of userSelection">
                    <ion-item>
                        <ion-label>
                          <ion-text class="primary-text">{{el.name}}</ion-text>
                          <p *ngIf="el.emailaddress" class="secondary-text">
                            <ion-text>{{el.emailaddress}}, {{el.businessunitname}}</ion-text>
                          </p>
                        </ion-label>
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
            </ion-list>
            <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ion-content>

        <!-- <ion-footer> -->
            <footer-toolbar [footerView]="'masterView'" [selectedView]="'CoVisitors'"></footer-toolbar>
        <!-- </ion-footer> -->
    </ion-col>

    <ion-col class="right-pane">
        <nothing-selected-view [textToDisplay]="''" [notShowText]="true" style="background-color: aqua;" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
    </ion-col>
</ion-row>
