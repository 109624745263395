<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="serachString" (onControlClick)="onHeaderControlClick($event)" (onSearchInput)="onInput($event)">
  </ind-header-left>
</ion-header>
<ion-content [ngClass]="{'vectorimageleft' : !isSearching && filteredLanguages?.length === 0}">
  <ind-section-header *ngIf="!isSearching" [viewData]="AllLanguageHeader"></ind-section-header>
  <ind-section-header *ngIf="isSearching" [viewData]='resultHeader'></ind-section-header>
  <ind-no-search-result *ngIf="filteredLanguages.length === 0 && isSearching" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <ion-item *ngFor="let language of filteredLanguages" (click)="changeLanguage(language)" class="alignTextwithDivider">
          <ion-label>
              <h3>{{language.name}}</h3>
              <h4>{{language.subName}}</h4>
          </ion-label>
          <ion-icon class="checkmark-icon" name="checkmark" color="primary" *ngIf="selectedLanguageCode == language.code" slot="end"></ion-icon>
      </ion-item>
    </ion-list>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="''"></footer-toolbar>
