<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500" [hideFilterButton]="hideFilterButton"
    [searchDisabled]="isReadOnlyMode" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onSearch()"
    [(searchText)]="searchInput" (onSearchClear)="onCancel()"></ind-header-left>
  <ind-key-controls-area *ngIf="viewData == null" [viewData]="marketPlanKeyControlAreaModel"></ind-key-controls-area>
</ion-header>
<ion-content>
  <ng-container *ngIf="selectedItems.length > 0">
    <ind-section-header [viewData]="selectedHeader"></ind-section-header>
    <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
  </ng-container>
  <ng-container
    *ngIf="!viewData?.isReadOnlyMode && (!isSearched || (isSearched && (plansData.length > 0))) else noResult;">
    <ind-section-header [viewData]="allMarketingPlansHeader"></ind-section-header>
    <ion-list class="plans-list">
      <ion-item-sliding #slidingItem *ngFor="let plan of plansData" class="sliding-item">
        <ng-container [ngTemplateOutlet]="mobSlideOptions"
          [ngTemplateOutletContext]="{plan:plan, slidingItem:slidingItem }"></ng-container>
      </ion-item-sliding>
    </ion-list>
  </ng-container>
  <ng-template #mobSlideOptions let-plan='plan' let-slidingItem='slidingItem'>
    <main-card [viewData]="getMarketingPlanmainCardModel(plan)"></main-card>
    <ion-item-options side="end" *ngIf="!device.isOffline && (device.isAndroid() || device.isIOS())">
      <div class="sliding-button" (click)="cloneMarketingBusinessPlan(plan);slidingItem.close();">
        <img class="sliding-icon" src="assets/imgs/copyicon.svg" width="40" />
        <p class="sliding-btn-text">{{'CLONE'|translate}}</p>
      </div>
    </ion-item-options>
  </ng-template>

  <ng-template #noResult>
    <ind-no-search-result *ngIf="!viewData?.isReadOnlyMode" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  </ng-template>

</ion-content>