<ion-header class="safe-padding-top" [ngClass]="{ 'toolbar-android': isAndroid }">
    <ion-toolbar class="page-title right-header-background-HCP" no-padding>
        <ion-grid no-padding>
            <ion-row>
                <ion-col no-padding>
                    <div class="meeting-interaction-wrapper">
                      <div class="meeting-interaction">
                        <ion-buttons class="short-call-back" *ngIf="isShortCall">
                          <ion-button (click)="goBack()">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                          </ion-button>
                        </ion-buttons>
                        <ion-chip [ngClass]="{'io-chip-active': activity?.id == selectedMeetingActivity?.id}"
                          (click)="openInteraction(activity, true)" *ngFor="let activity of embeddedInteractions">
                          <img *ngIf="activity.type === interactionType.Activity || activity.type === interactionType.Survey"
                            src="assets/imgs/{{activity.id == selectedMeetingActivity?.id ? activity.iconName + '_white' : activity.iconName}}.svg">
                          <img io-thumbnail *ngIf="activity.type === interactionType.Content" [src]="thumbURL(activity) | safe: 'url'">
                          <ion-label>{{activity.name}}</ion-label>
                          <img *ngIf="activity.type === interactionType.Content && activity.interaction.downloaded" class="downloaded-icon"
                            src="assets/imgs/contact_conn.png">
                        </ion-chip>
                      </div>
                    </div>
                </ion-col>
                <ion-col no-padding class="new-activity-btn" [ngClass]="{'survey': isShortCall}" *ngIf="isNewActivityBtnEnabled">
                    <ion-buttons>
                        <ion-button tooltip="New Activity" color="dark" icon-only [disabled]="presentationService.showNewActivity || backgroundUploadInProgress || (deviceService.isOffline && isShortCall)" (click)="openNewActivity()">
                          <div class="action-item">
                            <ion-icon color="dark" class="pluse-icon"></ion-icon>
                          </div>  
                        </ion-button>
                    </ion-buttons>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
    <ion-progress-bar mode="ios" class="left-progress-bar" *ngIf="deviceService.isBackgroundUploadInProgress || deviceService.syncInProgress" type="indeterminate"></ion-progress-bar>
</ion-header>
<ion-content class="content-page" [ngClass]="{'child-nav-active': !showContent, 'resource-view': showContent && presentationService.contentMode == 'Resource'}">
    <ng-container ion-fixed>
        <div [hidden]="!showContent || presentationService.showNewActivity" class="iframe-container" [ngClass]="{'iframe-container-carousel-hidden': this.footerService.shouldApplyExtraCssForIframe, 'ecard-no-participant-carousel': isEcard && (shouldShowPresentationCarousel || !showContent)}">
            <div class="sentiments" *ngIf="canCaptureSentiments" [ngClass]="{'sentiments-mobile-view': deviceService.isMobileDevice}">
              <div *ngIf="!turnOffSentimentCaptureEnabled">
                <img tappable (click)="setSentiment(0)" src="assets/imgs/sentiment-up.png" width="20" /><br><br>
                <img tappable (click)="setSentiment(1)" src="assets/imgs/sentiment-nutral.png" width="20" /><br><br>
                <img tappable (click)="setSentiment(2)" src="assets/imgs/sentiment-down.png" width="20" /><br><br>
              </div>
                <img tappable (click)="handleFullScreenClick()" src="assets/imgs/action_button_fullscreen{{!presentationService.isFullScreenMode ? '': '_back'}}.svg" width="20" title="{{!presentationService.isFullScreenMode ? ('Fullscreen' | translate) : ('BACK' | translate)}}" />
            </div>  
            <io-iframe [selectedPageUrl]="selectedPageUrl" (pdfPageChange)="onPdfPageChange($event)" [viewMode]="viewMode"> </io-iframe>
            <div class="overlay-play-button" *ngIf="!this.websocket.meetingInProgress">
                <ion-button [disabled]="isScreenSharingAllowed && !websocket.remoteUsersAreConnected" (click)="onContentPlayButtonClicked()" fill="clear">
                    <img src="assets/imgs/play-presentation.svg" width="60" height="auto" />
                </ion-button>
                <div [hidden]="!isScreenSharingAllowed || websocket.remoteUsersAreConnected">
                    {{'WAITING_FOR_PARTICIPANTS' | translate}}
                </div>
            </div>
        </div>
        <io-carousel [hidden]="this.presentationService.hideCarousel || !shouldShowPresentationCarousel || !showContent || presentationService.showNewActivity" #carousel [pages]="presentationService.presPages" [briefcase]="presList" [showGalleryBtn]="true" (onButtonClick)="toggleCarousel()" class="carousel-container"
            [viewMode]="viewMode" (changeCarousel)="sharePresentation($event)">
        </io-carousel>
        <ot-publisher type="screen" [audio]="false" [video]="true" *ngIf="opentokService.screenshareActive && isScreenSharingAllowed"></ot-publisher>
        <io-participant-carousel *ngIf="!shouldShowPresentationCarousel && showContent && !presentationService.showNewActivity"
        class="carousel-container" [viewMode]="viewMode" [hideContentToggle]="websocket.shortCallRemoteMeeting" (onButtonClick)="toggleCarousel()">        </io-participant-carousel>
        <io-reference-carousel
          *ngIf="presentationService.isReferenceVisible"
          class="reference-carousel-container"
          [hidden]="!showContent || presentationService.showNewActivity"
          [isDataLoading]="presentationService.isReferenceFetching$ | async"
          [pageReferences]="presentationService.references$ | async"
          (onLinkClick)="onReferenceCardClick($event)"
        ></io-reference-carousel>
        <ion-nav #meetingpresentationpane class="meeting-activities-nav" [hidden]="showContent && !presentationService.showNewActivity"></ion-nav>
    </ng-container>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('Appointment')" [footerView]="'fullScreenView'" (buttonClicked)="onFooterButtonClicked($event)" [selectedView]="'presentations'" [attachedComponent]="pageName.PresentationMeetingComponent"></footer-toolbar>
