<!-- Generated template for the CaseManagementListComponent component -->
<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [(searchText)]="caseManagementService.searchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="handleSearch()" (onFilterClick)="caseManagementService.listMode === 'myCases' ? openCaseFilterPopup('my',$event) : openCaseFilterPopup('team', $event)"
        [filterButtonBadgeCount]="getFilterButtonBadgeCount()"></ind-header-left>
    <ion-toolbar no-padding>
        <ind-tabs [data]="tabsData" [(selectedTab)]="caseManagementService.listMode" (selectedTabChange)="listSegment($event)"></ind-tabs>
    </ion-toolbar>
    <ion-item-divider *ngIf="caseManagementService.listMode === 'myCases'" class="normal-divider">
        <ion-label class="title-text">
            {{ (caseManagementService.searchText != '' ? 'AL_RESULTS': (caseManagementService.caseFilterText === 'All' || caseManagementService.caseFilterText === 'All Inquiries') ? 'ALL_INQUIRIES_S' : caseManagementService.caseFilterText ) | translate}} ({{caseManagementService.casesLength}})
        </ion-label>
    </ion-item-divider>
    <ion-item-divider *ngIf="caseManagementService.listMode === 'teamCases'" class="normal-divider">
        <ion-label class="title-text">
            {{getCaseFilterText()}} ({{caseManagementService.casesLength}})
        </ion-label>
    </ion-item-divider>
</ion-header>

<ion-content #Content no-padding [ngClass]="{'vectorimageleft' : !caseManagementService.searchText && caseManagementService.casesLength == 0}">
  <ion-item-group [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
    <ion-list *ngFor="let item of visibleCases">
      <case-list-item (click)="caseSelection(item)" [case]="item"></case-list-item>
  </ion-list>
  <ion-list *ngIf="!caseManagementService.casesLength && caseManagementService.searchText">
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  </ion-list>
  <ion-list *ngIf="!caseManagementService.casesLength && !caseManagementService.searchText">
      <div class="no-data-message" [hidden]="filterValueSelected">{{NoInquiryMessage | translate}}</div>
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
  </ion-item-group>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'CaseList'"></footer-toolbar>
<!-- </ion-footer> -->
