<ind-display-form #scrollTop [formMetadata]='displayFormDetails' [rawData]='rawFormData'
  [dynamicFormType]='dynamicFormType' [linkedEntityDBPrefix]='accountLinkedEntityDBPrefix'
  [referencingEntityName]='"accountid"'
    [isEditEnabled]="isAccountEditEnabled"
    [isEditDisabled]="isEditDisabled"
    (onEditClick)="handleAccountEdit($event)"
    [displayFormAccessedFrom]="'configuredAccountInfo'"
    [scrollObservable]="scrollObservable">
</ind-display-form>
<ng-container *ngIf="notesSectionHeaderViewData">
  <ind-section-header [viewData]="notesSectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ind-text-area id="notesInput" [ngClass]="{'hide-notes': !isAccountNotesEnabled}" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)"
  [value]="tempNoteText" (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"> </ind-text-area>
  <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
  <io-note *ngFor="let note of accountNotes" [isNoteAttachmentEnabled]="isNotesAttachmentEnabled || isAccountNotesEnabled" [inputNote]="note" [editable]="isNoteEditable(note)" 
  [readOnlyNote]="!isAccountNotesEnabled" [readOnlyAttachment]="!isNotesAttachmentEnabled" [deletable]="isNoteDeletable(note)" (saveNoteOut)="updateNote($event)" 
  [disableDownloadInOffline]="true" [from]="uiService.page.AccountDetailsComponent" [viewMode]="accountService.accountPageMode"></io-note>
</ng-container>
