<ion-grid no-padding [ngClass]="isPreviewActive?'preview-active':'preview-inactive'">
  <ion-row>
      <ion-col class="market-scan-left-pane">
          <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="itemsSearchStr" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="searchInput($event)"></ind-header-left>
          <ion-content [ngClass]="{'vectorimageleft' : itemsSearchStr.length == 0 && filteredItems.length==0 }">
            <ind-section-header [viewData]='sectionHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
            <main-card *ngFor="let item of filteredItems" [viewData]="item"></main-card>
            <ind-no-search-result *ngIf="itemsSearchStr.length > 0 && filteredItems.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
          </ion-content>
          <!-- <ion-footer> -->
          <footer-toolbar [footerView]="'masterView'" [selectedView]="'contactAllocationEligibilities'"></footer-toolbar>
          <!-- </ion-footer> -->
      </ion-col>

      <ion-col class="market-scan-right-pane">
          <ind-page-title *ngIf="selectedItem" [viewData]="indHeaderRightModel" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
          <ion-content id="marketScanTable">
          
            <ng-container *ngIf="scanData">
              <ind-section-header [viewData]='rightpanesectionHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
              <ion-grid>
                <ion-row class="table-row" id="marketScanEditTableRow">
                  <ion-col *ngFor="let itemCol of scanData.matrixTable;let i = index"
                    [ngClass]="i == 0 ? 'first-column' : 'products-column' ">
                    <div *ngFor="let item of itemCol['columnItems'];let j = index"
                      [ngClass]=" item.isHeader ? 'header-item' : (item.isTotal ? 'total-item' : 'value-item') ">
                      <div class="item-text" *ngIf="!item.isInputField"
                        [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' ">
                        <div class="primary-label-text">
                          {{item.columnItemLabel}}
                        </div>
                        <div class="secondary-label-text" *ngIf="item.columnItemSecondaryLabel">
                          {{item.columnItemSecondaryLabel}}
                        </div>
                        <div *ngFor="let subItem of item['childItems']; let k = index">
                          <div class="child-label-text" *ngIf="!subItem.isInputField">
                            {{subItem.childItemLabel}}
                          </div>
                          <div class="sub-item-text" *ngIf="subItem.isInputField">
                            <ion-input type="number" #inputRef placeHolder="---" (ionBlur)="handleMetricItemValue(subItem,$event)"
                              [value]="subItem.childItemLabel"> </ion-input>
                          </div>
                        </div>
                     </div>
                      <div class="item-text" *ngIf="item.isInputField"
                        [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' ">
                        <ion-input type="number" #inputRef placeHolder="---" (ionBlur)="handleMetricValue(item,$event)"
                          [value]="item.columnItemLabel"> </ion-input>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ng-container>
            <nothing-selected-view *ngIf="!selectedItem" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'">
            </nothing-selected-view>
            <p-paginator slot="fixed" class="stick-left p-paginator-footer"
              [ngStyle]="{'display': (showPaginator && visibleRowsLength > 10 ? 'block' : 'none')}" #paginator [rows]="10"
              [showFirstLastIcon]="false" showCurrentPageReport="true" [totalRecords]="visibleRowsLength" pageLinkSize="5"
              (onPageChange)="paginate($event)"></p-paginator>
          </ion-content>
          
      </ion-col>
  </ion-row>
</ion-grid>
