import { Pipe, PipeTransform } from '@angular/core';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { Account } from "@omni/classes/account/account.class";
import _ from 'lodash';

@Pipe({
    name: "secondaryInfoFormat"
})

export class SecondaryInfoFormatPipe implements PipeTransform {
  constructor(
    private secondaryInfoService: SecInfoConfigDataService,
    private localizationService: LocalizationService,
  ) {}

  transform(entity: Contact | Account, entityName: string): string {
    const secondaryInfoList = this.secondaryInfoService.secondaryInfoConfigList || [];
    const secInfo = secondaryInfoList.find(info => info.entity === entityName);

    if (!secInfo) {return '';}

    // Process attributes and return formatted text
    const formattedAttributes = secInfo.attributes
      .map((attr) => {
        if (attr.customText) {
          return attr.customText;
        }

      // Onekey code label multilingual check
      if (attr.name.includes('omnione_lu') && !_.isEmpty(this.localizationService.multiLingualFieldsData)) {
        const onekeyCodesLabelsId = entity.raw[`${attr.alias}`] || entity.raw[`_${attr.name}_value`] || '';
        
        if (onekeyCodesLabelsId) {
          const foundRecord = this.localizationService.multiLingualFieldsData.find(
            (a) => a['omnione_onekeycodeslabelsid'] == onekeyCodesLabelsId
          );
          if (foundRecord) {
            let translatedLang = '';
            let key = this.localizationService.getOneKeyTableAttributes();
            translatedLang = foundRecord[key]
              ? foundRecord[key] || ''
              : foundRecord['omnione_en_long_label'] || '';
            if (translatedLang) {
              return translatedLang;
            }
          }
        }
      }

      if (attr.alias && entity && entity.raw) {
        return (
          entity.raw[`${attr.alias}_Formatted`] ||
          entity.raw[attr.alias] ||
          entity.raw[`${attr.alias}@OData.Community.Display.V1.FormattedValue`]
        );
      }

      return undefined;
    });

    return formattedAttributes.filter(Boolean).join(secInfo.seperator) || '';
  }

}
