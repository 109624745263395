<ion-header>
  <go-app-title></go-app-title>
</ion-header>
<ion-content>
  <div *ngIf="hasBaselineEnabled" class="flex-container">
    <go-app-navigation-menu type="overlay" [isExpanded]="isExpanded" (sidebarToggle)="onSidebarToggle()">
    </go-app-navigation-menu>
    <div class="content">
      <!-- <div class="cards">
        Cards
      </div> -->
      <!-- <div class="main-content"> -->
        <!-- <go-app-activities></go-app-activities> -->
        <!-- <div class="todo"></div> -->
      <!-- </div> -->
    </div>
  </div>
  <div class="no-data-message" *ngIf="!hasBaselineEnabled">
    {{'NO_ACCESS_TO_OMNIPRESENCE' | translate}}
  </div>
</ion-content>
<ion-footer *ngIf="hasBaselineEnabled">
  <go-app-footer-navigation-menu [isExpanded]="isExpanded" (sidebarToggle)="onSidebarToggle()">
  </go-app-footer-navigation-menu>
</ion-footer>