<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="onInput($event)" (onSearchClear)="onCancel($event)" (onFilterClick)="openSAPFilter($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()"></ind-header-left>
    <!-- plan tab in activities page -->
    <ion-toolbar *ngIf="fromPlanTab" class="container-c">
        <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
    </ion-toolbar>

    <!-- <ion-item-divider class="keyControlsArea" color="light">
        <ion-button fill="clear" slot="end" icon-only (click)="filterSAP('products')">
          {{'PRODUCTS' | translate}} ({{this.productFilterList?.length}})
        </ion-button>
        <ion-button slot="end" fill="clear" (click)="filterSAP('therapeutic')">
          {{'THERAPEUTIC_AREAS' | translate}} ({{this.therapeuticAreaFilterList?.length}})
        </ion-button>
    </ion-item-divider> -->

    <!-- Segment for my scientific plans or team plans -->
    <!-- <ion-toolbar no-padding>
    <ion-segment mode='md' [(ngModel)]="scientificPlanMode" tappable>
      <ion-segment-button style="text-transform: none;" value="mine" (ionSelect)="trackInInsights('mine')">
        My Scientific Plans
      </ion-segment-button>
      <ion-segment-button style="text-transform: none;" value="team" (ionSelect)="trackInInsights('team')" *ngIf="hasScientificTeamPlansEnabled">
        {{hasScientificTeamPlansEnabled?'Team Scientific Plans':''}}
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar> -->
</ion-header>

<ion-content #Content [ngClass]="{'vectorimageleft': !searching && (pastScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length <= 0 && (currentScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length <= 0 && (futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length <= 0}">
    <ion-item-group [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
        <ng-container *ngIf="!searching || (searching && (pastScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length > 0 || (currentScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length > 0 || (futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length > 0) else noResult;">
            <ind-section-header [viewData]='pastHeaderModel'></ind-section-header>
            <!-- <ion-item-divider sticky='true'>{{'PAST' | translate}} ({{(pastScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList ).length}})
        </ion-item-divider> -->
            <ion-list class="scientific-Activities-List">
                <ion-item *ngIf="(pastScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length === 0">
                    <div class="no_scientific_activity">{{'SCIENTIFIC_ACTIVITY_NO_PAST_SCIENTIFIC_PLANS' | translate}}</div>
                </ion-item>
                <ion-item-group [ngClass]="{itemSelected : saPlan.ID === selectedPlan}" *ngFor="let saPlan of pastScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList | orderBy: sortBy?.value:sortBy?.asc" (click)="itemSelected(saPlan)">
                    <ion-item>
                        <div class="overflowHiddenAndEllipsis scientific-Activities-item">{{saPlan.scientificActivityPlanName}}</div>
                        <!-- <p>{{saPlan.status}}</p> -->
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
            </ion-list>
            <ind-section-header [viewData]='currentHeaderModel'>
            </ind-section-header>
            <!-- <ion-item-divider sticky='true' class="blueBackground" id="currentHeader">
          {{'CURRENT' | translate}} ({{(currentScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList ).length}})
        </ion-item-divider> -->
            <ion-list class="scientific-Activities-List">
                <ion-item *ngIf="(currentScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList ).length === 0" wra>
                    <p class="no_scientific_activity">{{'SCIENTIFIC_ACTIVITY_NO_CURRENT_SCIENTIFIC_PLANS' | translate}}</p>
                </ion-item>
                <ion-item-group [ngClass]="{itemSelected : saPlan.ID === selectedPlan}" *ngFor="let saPlan of currentScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList  | orderBy: sortBy?.value:sortBy?.asc" (click)="itemSelected(saPlan)">
                    <ion-item>
                        <div class="overflowHiddenAndEllipsis scientific-Activities-item">{{saPlan.scientificActivityPlanName}}</div>
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
            </ion-list>
            <ind-section-header [viewData]='futureHeaderModel'></ind-section-header>
            <!-- <ion-item-divider sticky='true'>{{'FUTURE' | translate}} ({{(futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length}})
        </ion-item-divider> -->
            <ion-list class="scientific-Activities-List">
                <!-- <button *ngIf="(futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length === 0" ion-item>
        No Future Scientific Plans
      </button> -->
                <ion-item *ngIf="(futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList).length === 0">
                    <p class="no_scientific_activity">{{'SCIENTIFIC_ACTIVITY_NO_FUTURE_SCIENTIFIC_PLANS' | translate}}</p>
                </ion-item>
                <ion-item-group [ngClass]="{itemSelected : saPlan.ID === selectedPlan}" *ngFor="let saPlan of futureScientificActivities | sapFilter: therapeuticAreaFilterList: productFilterList | orderBy: sortBy?.value:sortBy?.asc" (click)="itemSelected(saPlan)">
                    <ion-item>
                        <div class="overflowHiddenAndEllipsis scientific-Activities-item">{{saPlan.scientificActivityPlanName}}</div>
                        <!-- <p>{{saPlan.status}}</p> -->
                    </ion-item>
                    <div class="separationLine"></div>
                </ion-item-group>
            </ion-list>
        </ng-container>

        <ng-template #noResult>
            <ind-section-header [viewData]='noSearchResultHeaderModel'></ind-section-header>
            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </ng-template>
    </ion-item-group>

    <ng-template #empty>
        <div class="no-data-message">
            {{'SCIENTIFIC_ACTIVITY_NO_SCIENTIFIC_PLANS' | translate}}
        </div>
    </ng-template>
</ion-content>
<!-- <ion-footer *ngIf="!fromPlanTab"> -->
    <footer-toolbar *ngIf="!fromPlanTab" [footerView]="'masterView'" [selectedView]="'scientific-plans'"></footer-toolbar>
<!-- </ion-footer> -->
