import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { DeviceService } from '@omni/services/device/device.service';



@Component({
  selector: 'glance-card',
  templateUrl: 'glance-card.html',
  styleUrls:['glance-card.scss']
})
export class GlanceCardComponent {

  @Input() viewData: GlanceCardViewDataModel;
  @Output() onControlClick = new EventEmitter<any>();

  public userInitials:string;

  constructor(
    public device: DeviceService,
  ) {}

  public ngOnInit() {
    this.userInitials = this.viewData && this.viewData.name ? this.getInitials(this.viewData.name) : '';
  }

  private getInitials(name) {
    if (!name) return '';
    const parts = name.trim().split(' '); 
    if (parts.length === 1) {
      return parts[0].substring(0, 2).toUpperCase();
    }
    return (parts[0].charAt(0) + parts[1].charAt(0)).toUpperCase();
  }

  public onButtonClick(button,event){
    if(button && !button.isDisabled) {
      let obj = {
        id: button.id,
        item: button,
        event: event,
      };
      this.onControlClick.emit(obj);
    }
  }

  onExpandClick (){
    this.viewData.isExpanded = !this.viewData.isExpanded;
  }
}
