<ng-container *ngIf="!isSkeleton; else skeleton;">
  <ion-toolbar class="right-header-toolbar" [ngClass]="{
                                          'toolbar-android': isAndroid,
                                          'right-header-background-HCP': isHCPFacing,
                                          'impersontated-view': authService.impersonatedUser }">
    <div class="right-header-toolbar-inner-wrapper">

      <ion-buttons class="start-slot" *ngIf="leftControls?.length > 0; else noButton" slot="start">
        <ion-button *ngFor="let button of leftControls" (click)="onButtonClick(button)">
          <ion-icon [name]="button.icon" [src]="button.imgSrc"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ng-template #noButton>
        <ion-buttons class="start-slot" slot="start">
          <ion-button *ngIf="enableBackButton" (click)="onButtonClick(backButton)">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ng-template>

      <div class="right-header-title" *ngIf="!viewData?.enableTooltipForTitle">{{viewData?.title}}</div>
      <div class="right-header-title" *ngIf="viewData?.enableTooltipForTitle === true" [tooltip]="viewData?.title">{{viewData?.title}}</div>

      <ion-buttons class="end-slot" slot="end" *ngIf="rightControls?.length > 0">
        <ion-button *ngFor="let button of rightControls" (click)="onButtonClick(button)"
          [ngClass]="{ 'has-label': button.name ? true : false }" [disabled]="button.isDisabled" id="{{button.id}}"
          [tooltip]="button.tooltip" [display]="button.tooltip ? true : false">
          <div class="right-header-toolbar-btn-inner-wrapper">
            <ion-icon [ngClass]="{ 'has-label': button.name ? true : false }" [name]="button.icon"
              [src]="button.imgSrc"></ion-icon>
            <ion-label *ngIf="button.name" [ngClass]="{'insights-text': viewData.id === 'xperiences-customer'}">
              {{button.name}}</ion-label>
          </div>
        </ion-button>
      </ion-buttons>

    </div>
  </ion-toolbar>
  <ion-progress-bar
    mode="ios"
    class="right-progress-bar"
    *ngIf="(backgroundUploadInProgress$ | async) || (syncInProgress$ | async)"
    type="indeterminate">
  </ion-progress-bar>
</ng-container>

<ng-template #skeleton>
  <ion-toolbar class="right-header-toolbar" [ngClass]="{
                      'toolbar-android': isAndroid,
                      'right-header-background-HCP': isHCPFacing,
                      'impersontated-view': authService.impersonatedUser }">
    <div class="right-header-toolbar-inner-wrapper">
      <div class="right-header-title skeleton-header-title">
        <ion-skeleton-text class="skeleton-text-animated"
          [ngStyle]="{ width: skeletonConfig?.titleWidth ? skeletonConfig.titleWidth : defaultTitleWidth }">
        </ion-skeleton-text>
      </div>
    </div>
  </ion-toolbar>
</ng-template>
