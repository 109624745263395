<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="opportunity-selection-left-pane">
      <ion-header>
        <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="isReadOnlyMode" [hideFilterButton]="true" [(searchText)]="opportunitiesSearchText" 
        (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="searchText($event)"></ind-header-left>
      </ion-header>
      <ion-content [ngClass]="{fixHeight: !device.isMobileDevice, 'vectorimageleft': selectedItems.length === 0 && !isSearching}">
        <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
          <!-- Selected Opportunities List View -->
          <ng-container *ngIf="selectedItems.length > 0">
            <ind-section-header [viewData]="selectedOpportunitiesHeader"></ind-section-header>
            <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
          </ng-container>

          <ng-container *ngIf="!isReadOnlyMode && relatedOpportunitiesHeader && relatedItems.length && !isSearching">
            <ind-section-header  [viewData]="relatedOpportunitiesHeader"></ind-section-header>
            <main-card *ngFor="let item of relatedItems" [viewData]="item"></main-card>
          </ng-container>

          <ng-container *ngIf="!isReadOnlyMode">
            <ind-section-header  [viewData]="filteredOpportunitiesHeader"></ind-section-header>
            <main-card *ngFor="let item of filteredItems" [viewData]="item"></main-card>
          </ng-container>
        </ion-list>
        <ind-no-search-result *ngIf="isSearching && filteredItems.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
      </ion-content>
      <footer-toolbar [footerView]="'masterView'" [selectedView]="'select-list'"></footer-toolbar>
    </ion-col>
    <ion-col class="opportunity-selection-right-pane">
      <ion-nav #opportunityselectionrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>
