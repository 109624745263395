<ion-list class="menu-options sidebar" [ngClass]="{ 'sidebar-expanded': isExpanded }">
  <ion-item class="menu-item" [ngClass]="option.id" *ngFor="let option of sideBarOptions"
    [ngClass]="{'itemSelected':option.selected}" (click)="onClickMenuOption(option)">
    <div class="container">
      <img [tooltip]="isExpanded ? '' : option.displayName" class="menu-icon" *ngIf="option.selected"
        [src]="option.selectedIconPath" />
      <img [tooltip]="isExpanded ? '' : option.displayName" class="menu-icon" *ngIf="!option.selected"
        [src]="option.iconPath" />
      <span *ngIf="isExpanded" class="menu-label">{{option.displayName}}</span>
    </div>
  </ion-item>
</ion-list>