import { AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { GlobalUtilityService } from './../../../services/global-utility.service';
import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, Input, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, HostListener, Output, EventEmitter } from "@angular/core";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { CallPlanDataService } from '../../../data-services/call-plan/call-plan.data.service';
import { DeviceService } from "../../../services/device/device.service";
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { LoadingController, ModalController, PopoverController, IonContent } from '@ionic/angular';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { EventsService } from '../../../services/events/events.service';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SearchConfigService } from '../../../services/search/search-config.service';
import { AlertService } from '../../../services/alert/alert.service';
import * as _ from 'lodash';
import { SearchConfigDataService } from './../../../data-services/search-config/search-config-data-service';
import { searchIndexDataModel, SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from '../../../models/search-config-data-model';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { DiskService } from '../../../services/disk/disk.service';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { CallPlanCustomerType } from '@omni/classes/call-plan/rep-call-plan.class';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
  selector: "customer-call-plan-list",
  templateUrl: "customer-call-plan-list.html",
  styleUrls: ['customer-call-plan-list.scss']
})
export class CustomerCallPlanList implements OnInit, OnDestroy {
  @Output() onCallPlanSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCallPlanSelectOnPlanTab: EventEmitter<any> = new EventEmitter<any>();
  public compViewMode = ComponentViewMode;
  public callPlanMode: string = "mine";
  public hasTeamPlansEnabled: boolean = false;
  @ViewChild('Content', { static: true }) content: HTMLIonContentElement;
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  myFormattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
  teamFormattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
  /* removed to use advanced search
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any) => void; }[];
  listFiltered: boolean = false;
  multiSelectFilterPopoverData: { text: string; selectedValues: string[]; items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  */
  public searchText = "";
  sortPopoverData: any;
  //added properties for advanced search
  callPlanSearchText: string;
  suggestionsActive: boolean = false;
  searchKey: string = '';
  shouldFireSearchChangeEvent: boolean = true;
  public searching: boolean = false;
  disableSaveSearch: boolean = false;
  selectedSuggestionsData: SelectedSuggestionPillDataModel[] = [];
  filteredList: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
  suggestionsData: {
    header: IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  sortBy: { text: string, value: string, asc: boolean };
  //sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  tabsData: IndTabsDataModel[];
  agendaPlanTabs: IndTabsDataModel[] = [];
  private subs: Subscription[] = [];
  public mycustomersHeaderModel: IndSectionHeaderViewDataModel
  public mycallplanPastHeaderModel: IndSectionHeaderViewDataModel
  public mycallplanCurrentHeaderModel: IndSectionHeaderViewDataModel
  public mycallplanFutureHeaderModel: IndSectionHeaderViewDataModel
  public filteredListPastHeaderModel: IndSectionHeaderViewDataModel
  public filteredListCurrentHeaderModel: IndSectionHeaderViewDataModel
  public filteredListFutureHeaderModel: IndSectionHeaderViewDataModel
  public teamCallplanPastHeaderModel: IndSectionHeaderViewDataModel
  public teamCallplanCurrentHeaderModel: IndSectionHeaderViewDataModel
  public teamCallplanFutureHeaderModel: IndSectionHeaderViewDataModel
  public noSearchResultHeaderModel: IndSectionHeaderViewDataModel
  public myTeamscustomersHeaderModel:IndSectionHeaderViewDataModel
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public filteredCustomers = [];
  public filteredTeamsCustomers=[];
  public filteredTeamsCustomersCopy=[];
  public filteredTeamsCustomersByGroup=[];
  public filterValueSelected: boolean = false;
  public fromPlanTab: boolean = false;
  public waitingForTeamPlans: boolean = true;
  public hasMyCallPlans: boolean = false;
  public hasTeamCallPlans: boolean = false;
  public hasCallPlanProgress: boolean = false;
  public displaygoalsbeyond100: boolean = false;
  public displayAllActProgress: boolean = false;
  public activeTab: string = 'call-plan-current';
  public statistics: { id: string, label: string, actual: number, defined: number, percentage: number, customers?:[] }[] = [];
  public statisticsGoalProgress: { id: string, label: string, actual: number, defined: number, percentage?: number}[] = [];
  public teamStatistics: { id: string, label: string, actual: number, defined: number, percentage: number, customers?:[] }[] = [];
  public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public filtering: boolean = false;
  public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  public isFilterActive:boolean =false;
  callPlanContact: any = [];
  public isFilteredByCustomerTypeOnly: boolean = false;
  public hideFilterButton :boolean =false;

  constructor(
    public repService: RepServices,
    public callPlanOfflineService: CallPlanOfflineService,
    private callDataService: CallPlanDataService,
    private activityService: ActivityService,
    public uiService: UIService,
    public device: DeviceService,
    private authService: AuthenticationService,
    private trackingService: TrackService,
    private navService: NavigationService,
    private events: EventsService,
    private translate: TranslateService,
    public alertService: AlertService,
    public searchConfigService: SearchConfigService,
    public searchConfigDataService: SearchConfigDataService,
    public _cd: ChangeDetectorRef,
    public contactListElement: ElementRef,
    public disk: DiskService,
    private contactService: ContactOfflineService,
    public footerService: FooterService,
    private readonly agendaFooterService: AgendaFooterService,
    private utilityService: GlobalUtilityService,
    public popover: PopoverController,
    private modalCtrl:ModalController,
    public accountService: AccountOfflineService,
    private faService: FeatureActionsService,
    private loadingController: LoadingController,
  ) {
    this.callPlanOfflineService.setCallPlanMode('mine');
  }

  async ngOnInit() {
    this._checkBUConfig();
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans') this.fromPlanTab = true;
    this.searchText = this.translate.instant('SEARCH_MY_CALLPLANS');
    this.initCallPlanHeaderLeft();
    if (this.authService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)) {
      this.hasTeamPlansEnabled = true;
    }
    this.setTabsData();
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "fullName",
        items: [
          { text: this.translate.instant('NAME'), value: "fullName", asc: true },
          { text: this.translate.instant('PERCENTAGE_LOW_To_HIGH'), value: "LowToHigh", asc: true },
          { text: this.translate.instant('PERCENTAGE_HIGH_To_LOW'), value: "HighToLow", asc: false }
        ],
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.onFilterChange(selectedItem, item, itemRef, selected)
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          if (this.callPlanMode == "mine") {
            this.sortCustomerCallPlanList();
          } else {
            this.sortTeamCustomerCallPlanList();
          }
          this.initializeSectionHeader();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    try {
      await this.uiService.displayLoader();
      await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(async(res) => {
        if (res && res.raw && res.raw.length) {
          this.callPlanOfflineService.segmentCallPlans = res.raw;
        } else {
          this.callPlanOfflineService.segmentCallPlans = [];
        }
        await this.uiService.dismissLoader();
        try {
          await this.getFormattedCallPlans();
        } catch(error) {
          console.log(error);
        }
        this.updateEmptyMessage();
        this.initializeSectionHeader();
      });
    } catch(error) {
      console.log(error);
      await this.uiService.dismissLoader();
    }
    
    this.agendaFooterService.initButtons(AgendaFooterView.CallPlanCurrent);
    
    this.events.observe(this.translate.instant('SYNC_COMPLETED')).pipe(
      takeUntil(this.destroy$))
      .subscribe(async(error) => {
        this._checkBUConfig();
        await this.getFormattedCallPlans();
      });

    this.agendaPlanTabs = this.uiService.getAgendaTabs();
    this.subs.push(this.device.isOfflineObservable.pipe(takeUntil(this.destroy$)).subscribe(isOffline => {
      if(!isOffline) this.callDataService.syncCallPlanActivities();
      this.agendaPlanTabs = this.uiService.getAgendaTabs();
      if (this.callPlanMode != 'mine') {
        this.uiService.dismissLoader();
      }
      this.setTabsData();
      this._cd.detectChanges();
    }))
    this.events.observe("sync:completed")
      .subscribe(() => {
        this._checkBUConfig();
        this.agendaPlanTabs = this.uiService.getAgendaTabs();
      });
    this.callPlanOfflineService.segmentCallPlansList$.pipe(takeUntil(this.destroy$)).subscribe(async (data) => {
      if (data && data.hasUpdatedData) {
        await this.getFormattedCallPlans();
      }
    })
    this.events.observe('callPlanActivitiesUpdated').pipe(
      takeUntil(this.destroy$))
      .subscribe(async(error) => {
        if (this.callPlanMode != "team") {
          try {
            await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(async(res) => {
              if (res && res.raw && res.raw.length) {
                this.callPlanOfflineService.segmentCallPlans = res.raw;
              } else {
                this.callPlanOfflineService.segmentCallPlans = [];
              }
              try {
                await this.getFormattedCallPlans(true);
              } catch(error) {
                console.log(error);
              }
              this.updateEmptyMessage();
              this.initializeSectionHeader();
              this.events.publish('callPlanListUpdated');
            });
          } catch(error) {
            console.log(error);
          }
          if (this.filtering) {
            this.searchCallPlansList(true);
          } else if (this.searching) {
            this.searchCallPlansList();
          }
        } else {
          //Real-time fetch is used for Team call plans when switching to Team call plans by segmentChange
          //Missing data issue with Team call plans when a user clicks the call plan tool and immediately switches to Team call plans.
          //This resulted in an attempt to fetch team call plans data twice, which prevented the data from being updated.
        }
      });
    this.uiService.toolsActivityActive = true;
    this.initializeSectionHeader();

    this.filterPopoverData = [
      {
        text: "",
        // expanded: true,
        value: "fullName",
        items: [
          { text: this.translate.instant('NAME'), value: "fullName", asc: true },
          { text: this.translate.instant('PERCENTAGE_LOW_To_HIGH'), value: "LowToHigh", asc: true },
          { text: this.translate.instant('PERCENTAGE_HIGH_To_LOW'), value: "HighToLow", asc: false }
        ],
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.onFilterChange(selectedItem, item, itemRef, selected)
          item.value = selectedItem.value;
          // this.sortBy = selectedItem;
          if (this.callPlanMode == "mine") {
            this.sortCustomerCallPlanList();
          } else {
            this.sortTeamCustomerCallPlanList();
          }
          this.initializeSectionHeader();
        }
      }
    ];
    this.filterPopoverData[0].value;
  }

  aggregateTeamsCallPlans(repPlansArray) {
    let a = [];
    if(!_.isEmpty(repPlansArray)) {
      repPlansArray.map((c) => {
        let plan = c.contactId ? a.find(o => o.positionId == c.positionId && o.contactId == c.contactId) : a.find(o => o.positionId == c.positionId && o.accountId == c.accountId);
        if(c.contactId) {
          c.customerId = c.contactId;
          c.customerName = c.contactFirstName;
          c.initials = c.contactFirstName?.charAt(0).toUpperCase();
          if(!_.isEmpty(c.contactLastName)) {
            c.initials += c.contactLastName?.charAt(0).toUpperCase();
            c.customerName += " " + c.contactLastName;
          }
        }else {
          c.customerId = c.accountId || c.customerId;
          c.customerName = c.accountName;
          c.initials = c.accountName?.charAt(0).toUpperCase();
        }
        c.productName = c.productid_Formatted || '';

        if (plan) {
          plan.repPlans.push(c);
          plan.actualCalls += this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls);
          plan.totalGoalCalls += c.indskr_hocalls ? c.indskr_hocalls : 0;
          plan.actualEmails += this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails);
          plan.totalGoalEmails += c.indskr_hoemails ? c.indskr_hoemails : 0;
          plan.actualF2FMeetings += this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted);
          plan.totalGoalF2FMeetings += c.indskr_f2fgoals ? c.indskr_f2fgoals : 0;
          plan.actualRemoteMeetings += this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted);
          plan.totalGoalRemoteMeetings += c.indskr_remotemeetinggoals ? c.indskr_remotemeetinggoals : 0;
          plan.actualPhoneCalls += this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted);
          plan.totalGoalPhoneCalls += c.indskr_phonecallgoals ? c.indskr_phonecallgoals : 0;
          plan.actualEvents += this.goalOrCompletedValue(c.indskr_eventgoals, c.indskr_completedevents);
          plan.totalGoalEvents += c.indskr_eventgoals ? c.indskr_eventgoals : 0;
          plan.allRepCallPlansApproved = plan.allRepCallPlansApproved == true && c.statuscode == 2 ? true : false;
          plan.completedGoals = plan.actualCalls + plan.actualEmails + plan.actualF2FMeetings + plan.actualRemoteMeetings + plan.actualPhoneCalls + plan.actualEvents;
          plan.definedGoals = plan.totalGoalCalls + plan.totalGoalEmails + plan.totalGoalF2FMeetings + plan.totalGoalRemoteMeetings + plan.totalGoalPhoneCalls + plan.totalGoalEvents;
          plan.percentage = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
        } else {
          plan = {
            'positionId': c.positionId,
            'cycleplanid': c.cycleplanid,
            'contactId': c.contactId || '',
            'accountId': c.accountId || '',
            'customerId': c.contactId || c.accountId || '',
            'customerType' : c.contactId ? CallPlanCustomerType.CONTACT : CallPlanCustomerType.ACCOUNT,
            'customerName': c.customerName || c.accountName,
            'initials': c.initials,
            'cycleplanname': c.cycleplanname,
            'repPlans': [c],
            'indskr_enddate': c.indskr_enddate,
            'indskr_startdate': c.indskr_startdate,
            'actualCalls': this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls),
            'totalGoalCalls': c.indskr_hocalls || 0,
            'actualEmails': this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails),
            'totalGoalEmails': c.indskr_hoemails || 0,
            'actualF2FMeetings': this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted),
            'totalGoalF2FMeetings': c.indskr_f2fgoals || 0,
            'actualRemoteMeetings': this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted),
            'totalGoalRemoteMeetings': c.indskr_remotemeetinggoals || 0,
            'actualPhoneCalls': this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted),
            'totalGoalPhoneCalls': c.indskr_phonecallgoals || 0,
            'actualEvents': this.goalOrCompletedValue(c.indskr_eventgoals, c.indskr_completedevents),
            'totalGoalEvents': c.indskr_eventgoals || 0,
            'allRepCallPlansApproved': c.statuscode == 2 ? true : false,
            'productName': c.productid_Formatted || '',
            'suggestionApprovalneeded': c.cycleplansuggestionapprovalneeded
          };
          plan['completedGoals'] = plan['actualCalls'] + plan['actualEmails'] + plan['actualF2FMeetings'] + plan['actualRemoteMeetings'] + plan['actualPhoneCalls'] + plan['actualEvents'];
          plan['definedGoals'] = plan['totalGoalCalls'] + plan['totalGoalEmails'] + plan['totalGoalF2FMeetings'] + plan['totalGoalRemoteMeetings'] + plan['totalGoalPhoneCalls'] + plan['totalGoalEvents'];
          plan['percentage'] = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
          a.push(plan);
        }
      });
    }
    return a;
  }

  private setCustomerStatistics() {
    this.statistics = [];
    let F2FCompletion: { id: string, label: string, actual: number, defined: number };
    let RmtCompletion: { id: string, label: string, actual: number, defined: number };
    let MsgCompletion: { id: string, label: string, actual: number, defined: number };
    let WeComCompletion: { id: string, label: string, actual: number, defined: number };
    let EventsCompletion: { id: string, label: string, actual: number, defined: number };
    let customerGoalComp = 0;
    let customerReachCount = 0;
    let completedGoals = 0;
    let definedGoals = 0;
    let actualF2FMeetings = 0;
    let actualRemoteMeetings = 0;
    let actualEmails = 0;
    let actualWeCom = 0;
    let actualEvents = 0;
    let totalGoalEmails = 0;
    let totalGoalF2FMeetings = 0;
    let totalGoalRemoteMeetings = 0;
    let totalGoalWeCom = 0;
    let totalGoalEvents = 0;
    if (!_.isEmpty(this.filteredCustomers)) {
      let tempFilteredCustomers = _.cloneDeep(this.filteredCustomers);
      if(this.isFilteredByCustomerTypeOnly && !_.isEmpty(this.selectedFilterData)) {
        if(this.selectedFilterData[0].value == 'account') {
          tempFilteredCustomers = tempFilteredCustomers.filter(t=>t.customerType == CallPlanCustomerType.ACCOUNT);
        }else if(this.selectedFilterData[0].value == 'contact') {
          tempFilteredCustomers = tempFilteredCustomers.filter(t=>t.customerType == CallPlanCustomerType.CONTACT);
        }
      }
      const customersCount = tempFilteredCustomers.length;
      tempFilteredCustomers.forEach(c => {
        if (c.percentage) {
          customerReachCount += 1;
          if (c.completedGoals/c.definedGoals >= 1) {
            customerGoalComp += 1
          }
        }
        completedGoals += c.completedGoals;
        definedGoals += c.definedGoals
        actualF2FMeetings += c.actualF2FMeetings;
        actualRemoteMeetings += c.actualRemoteMeetings;
        actualEmails += c.actualEmails;
        actualWeCom += c.actualWeCom;
        actualEvents += c.actualEvents;
        totalGoalEmails += c.totalGoalEmails;
        totalGoalF2FMeetings += c.totalGoalF2FMeetings;
        totalGoalRemoteMeetings += c.totalGoalRemoteMeetings;
        totalGoalWeCom += c.totalGoalWeCom;
        totalGoalEvents += c.totalGoalEvents;
      })
      const customerGoal: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: this.displaygoalsbeyond100 ? 'customer-goal-call-plan-progress' : 'customer-goal',
        label: this.translate.instant('CUSTOMERS_WITH_GOAL_COMPLETION'),
        actual: customerGoalComp,
        defined: customersCount,
        percentage: customerGoalComp != 0 ? _.toNumber(((customerGoalComp / customersCount) * 100).toFixed(0)) : 0
      }
      const customerReach: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: 'customer-reach',
        label: this.translate.instant('CUSTOMER_REACH'),
        actual: customerReachCount,
        defined: customersCount,
        percentage: customerReachCount != 0 ? _.toNumber(((customerReachCount / customersCount) * 100).toFixed(0)) : 0
      }
      const goalCompletion: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: 'goal-completion',
        label: this.translate.instant('CALL_PLAN_GOAL_COMPLETION'),
        actual: completedGoals,
        defined: definedGoals,
        percentage: completedGoals != 0 ? _.toNumber(((completedGoals / definedGoals) * 100).toFixed(0)) : 0
      }
      if(totalGoalF2FMeetings>0 || this.displayAllActProgress) F2FCompletion = {
        id: 'F2F-completion',
        label: this.translate.instant('F2F'),
        actual: actualF2FMeetings,
        defined: totalGoalF2FMeetings,
      }
      if(totalGoalRemoteMeetings>0 || this.displayAllActProgress) RmtCompletion = {
        id: 'rmt-completion',
        label: this.translate.instant('REMOTE_SHORT_FORM'),
        actual: actualRemoteMeetings,
        defined: totalGoalRemoteMeetings,
      }
      if(totalGoalEmails>0 || this.displayAllActProgress) MsgCompletion = {
        id: 'msg-completion',
        label: this.translate.instant('MESSAGE_SHORT_FORM'),
        actual: actualEmails,
        defined: totalGoalEmails,
      }
      if(totalGoalWeCom>0) WeComCompletion = {
        id: 'WeCom-completion',
        label: this.translate.instant('WECOM'),
        actual: actualWeCom,
        defined: totalGoalWeCom,
      }
      if(totalGoalEvents>0 || this.displayAllActProgress) EventsCompletion = {
        id: 'events-completion',
        label: this.translate.instant('MESSAGE_SHORT_FORM'),
        actual: actualEvents,
        defined: totalGoalEvents,
      }
      if(this.displaygoalsbeyond100) {
        this.statistics = [customerGoal, customerReach];
      } else {
        this.statistics = [customerGoal, goalCompletion, customerReach];
      }
      this.statisticsGoalProgress = [F2FCompletion, RmtCompletion, MsgCompletion, WeComCompletion, EventsCompletion].filter(g => g);
    }
  }

  public getGridColumnSpan() {
    return `grid-column: span ${12/this.statisticsGoalProgress.length}`
  }

  public getSecondaryGridColumnSpan(plan) {
    let numOfItem = 0;
    if(this.displayAllActProgress) numOfItem = 3;
    else {
      if(plan.totalGoalF2FMeetings) numOfItem += 1;
      if(plan.totalGoalRemoteMeetings) numOfItem += 1;
      if(plan.totalGoalEmails) numOfItem += 1;
    }
    return `grid-column: span ${12/numOfItem}`
  }

  private setTeamCustomerStatistics() {
    this.teamStatistics = [];
    let customerGoalComp = 0;
    let customerReachCount = 0;
    let completedGoals = 0;
    let definedGoals = 0;
    if (!_.isEmpty(this.filteredTeamsCustomers)) {
      const customersCount = this.filteredTeamsCustomers.length;
      this.filteredTeamsCustomers.forEach(c => {
        if (c.percentage) {
          customerReachCount += 1;
          if (c.completedGoals/c.definedGoals>=1) {
            customerGoalComp += 1
          }
        }
        completedGoals += c.completedGoals;
        definedGoals += c.definedGoals
      })
      const customerGoal: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: this.displaygoalsbeyond100 ? 'customer-goal-call-plan-progress' : 'customer-goal',
        label: this.translate.instant('CUSTOMERS_WITH_GOAL_COMPLETION'),
        actual: customerGoalComp,
        defined: customersCount,
        percentage: customerGoalComp != 0 ? _.toNumber(((customerGoalComp / customersCount) * 100).toFixed(0)) : 0
      }
      const customerReach: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: 'customer-reach',
        label: this.translate.instant('CUSTOMER_REACH'),
        actual: customerReachCount,
        defined: customersCount,
        percentage: customerReachCount != 0 ? _.toNumber(((customerReachCount / customersCount) * 100).toFixed(0)) : 0
      }
      const goalCompletion: { id: string, label: string, actual: number, defined: number, percentage: number } = {
        id: 'goal-completion',
        label: this.translate.instant('CALL_PLAN_GOAL_COMPLETION'),
        actual: completedGoals,
        defined: definedGoals,
        percentage: completedGoals != 0 ? _.toNumber(((completedGoals / definedGoals) * 100).toFixed(0)) : 0
      }
      if(this.displaygoalsbeyond100) {
        this.teamStatistics = [customerGoal, customerReach];
      } else {
        this.teamStatistics = [customerGoal, goalCompletion, customerReach];
      }
    }
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      if(!this.displaygoalsbeyond100) value = completed > goal ? goal : completed;
      else value = completed;
    }
    return value;
  }

  private initCallPlanHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      imgSrc: 'assets/imgs/back_to_home_btn.svg',
      isDisabled: false,
      align: "left",
    })
    this.indHeaderLeftModel = {
      id: 'coaching-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.fromPlanTab ? this.translate.instant('HOME') : this.translate.instant('CALL_PLANS'),
      mode: true,
      isOnActPage: this.fromPlanTab ? true : false,
      controls: buttons,
    };
  }
  initializeSectionHeader() {
    const sortButton = []
    sortButton.push({
      id: 'my-customers-sort',
      text: this.sortBy?.text,
      isDisabled: false,
      img: "assets/imgs/sort_with_double_arrows.svg",
    });
    this.mycustomersHeaderModel = {
      id: 'my-customers',
      title: `${this.translate.instant(this.utilityService.globalCustomersText ?? 'CUSTOMERS')} (${this.filteredCustomers?.length})`,
      controls: sortButton
    }
    this.filteredListPastHeaderModel = {
      id: 'filteredPastCallPlan',
      title: `${this.translate.instant('PAST')} (${this.filteredList?.past?.length})`,
      controls: []
    }
    this.filteredListCurrentHeaderModel = {
      id: 'filteredCurrentCallPlan',
      title: `${this.translate.instant('CURRENT')} (${this.filteredList?.present?.length})`,
      isHighlighted: true,
      controls: []
    }
    this.filteredListFutureHeaderModel = {
      id: 'filteredFutureCallPlan',
      title: `${this.translate.instant('FUTURE')} (${this.filteredList?.future?.length})`,
      controls: []
    }
    this.myTeamscustomersHeaderModel={
      id: 'my-teams-customers',
      title: `${this.translate.instant('COACHING_TEAM_MEMBERS')} (${this.filteredTeamsCustomersByGroup?.length})`,
      controls: sortButton
    }
    this.noSearchResultHeaderModel = {
      id: 'no-search-result-header',
      title: `${this.translate.instant('AL_RESULTS')} (0)`,
      controls: []
    };

  }

  onSortControlClick(id: string) {
    if (id === 'my-customers-sort') {
      this.popover
        .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
        .then((data) => {
          data.present();
        })
    }
  }

  async sortCustomerCallPlanList() {
    if (!this.sortBy || this.sortBy?.value == "fullName") {
      this.filteredCustomers = _.orderBy(this.filteredCustomers, [(customer) => customer['customerName'].toLowerCase()], 'asc');
    } else if (this.sortBy?.value == "LowToHigh") {
      this.filteredCustomers = _.orderBy(this.filteredCustomers, 'percentage', 'asc');
    } else if (this.sortBy?.value == "HighToLow") {
      this.filteredCustomers = _.orderBy(this.filteredCustomers, 'percentage', 'desc');
    }
  }

  async sortTeamCustomerCallPlanList() {
    if (!this.sortBy || this.sortBy?.value == "fullName") {
      this.filteredTeamsCustomersByGroup = this.filteredTeamsCustomersByGroup.map((rep) => {
        rep.callplans = _.orderBy(rep.callplans, [(customer) => customer['customerName']?.toLowerCase()], 'asc')
        return rep;
      });
    } else if (this.sortBy?.value == "LowToHigh") {
      this.filteredTeamsCustomersByGroup = this.filteredTeamsCustomersByGroup.map((rep) => {
        rep.callplans = _.orderBy(rep.callplans, 'percentage', 'asc')
        return rep;
      });
    } else if (this.sortBy?.value == "HighToLow") {
      this.filteredTeamsCustomersByGroup = this.filteredTeamsCustomersByGroup.map((rep) => {
        rep.callplans = _.orderBy(rep.callplans, 'percentage', 'desc')
        return rep;
      });
    }
  }

  private onFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if (selectedItem && selectedItem.value) {
      const val = selectedItem.value;
      let filters = [];
      if (_.isEqual(val, "fullName")) {
        filters = ["fullName"];
      } else {
        filters = itemRef.selectedValues.filter(v => !_.isEqual(v, "fullName"));
        if (!selected) filters = itemRef.selectedValues.filter(v => !_.isEqual(v, val));
        const availableFilters = this.sortPopoverData[0].items.filter(i => !_.isEqual(i.value, "fullName")).length;
        if (_.isEmpty(filters) || filters.length == availableFilters) filters = ["fullName"];;
      }
      itemRef.selectedValues = this.sortPopoverData[0].selectedValues = filters;
      this.sortPopoverData[0].value = val;
    }

  }

  updateEmptyMessage() {
    let datasize = 0
    if (this.callPlanMode === 'team' && this.teamFormattedCallPlans) {
      if (!this.searching && (this.teamFormattedCallPlans.past.length > 0 || this.teamFormattedCallPlans.present.length > 0 ||
        this.teamFormattedCallPlans.future.length > 0)) {
        datasize = 1
      }
    }
    else if (this.myFormattedCallPlans) {
      if (!this.searching && (this.myFormattedCallPlans.past.length > 0 ||
        this.myFormattedCallPlans.present.length > 0 || this.myFormattedCallPlans.future.length > 0)) {
        datasize = 1
      }
    }
    if (this.searching && (this.filteredList.past.length > 0 || this.filteredList.present.length > 0 ||
      this.filteredList.future.length > 0)) {
      datasize = 1
    }
    this.uiService.updateNothingSelectedScreenMessageFor(datasize)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.callPlanOfflineService.callPlanSearchText = '';
    this.contactService.contactInformation = undefined;
    this.fromPlanTab = false;
    if (!(this.uiService.activitiesPageTab === "plans" && this.agendaFooterService.planTabSegment === "goalsPlansTab")) {
      this.callPlanOfflineService.segmentCallPlans = [];
      this.callPlanOfflineService.selectedCallPlanAtHome = undefined;
      this.searchConfigService.myCallPlansSearchIndexesConfig.forEach(o => o.values = [])
    }
    this.callPlanOfflineService.teamSegmentCallPlans = []
    this.callPlanOfflineService.teamPositionsOtherRepPlans = []
    this.searchConfigService.teamCallPlansSearchIndexesConfig.forEach(o => o.values = [])

    this.callPlanOfflineService.repNamesWithPositions = []
  }
  async ionViewDidEnter() {
    if (this.callPlanOfflineService.callPlanAccessedFromScheduler) {
      this.callPlanOfflineService.selectedCallPlan = undefined;
      this.callPlanOfflineService.callPlanAccessedFromScheduler = false;
    }
    else {
      setTimeout(() => {
        this.scrollTo('currentHeader');
      }, 0);
    }
    await this.getFormattedCallPlans();
  }

  private async getFormattedCallPlans(isUpdated: boolean = false) {
    this.teamFormattedCallPlans = this.callPlanOfflineService.formattedTeamCallPlans;
    this.myFormattedCallPlans = this.callPlanOfflineService.formattedCallPlans;
    this.hasMyCallPlans = !_.isEmpty(this.myFormattedCallPlans?.past) || !_.isEmpty(this.myFormattedCallPlans?.present) || !_.isEmpty(this.myFormattedCallPlans?.future);
    this.hasTeamCallPlans = !_.isEmpty(this.teamFormattedCallPlans?.past) || !_.isEmpty(this.teamFormattedCallPlans?.present) || !_.isEmpty(this.teamFormattedCallPlans?.future);
    if (this.callPlanMode == "mine") {
      this.initFilteredCustomers();
    } else {
      await this.filterTeamsCustomers();
    }
    if (isUpdated) {
      this.scrollToCurrentPoint();
    }
  }

  private scrollTo(data) {
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it or use the content scrollInto routine
    let ele: any = document.getElementById(data);
    if (ele) {
      ele.scrollIntoView(true);
      let yOffset = ele.offsetTop;
      this.content.scrollTo(0, 0);
    }
  }

  public async handleCallPlanSelection(callPlan) {
    this.uiService.activeView = 'CallPlanDetails';
    if(callPlan.customerType == CallPlanCustomerType.CONTACT) {
      let foundContact = this.contactService.getContactByID(callPlan.contactId);
      this.contactService.contactInformation =foundContact;
      this.accountService.selected = undefined;
    }else {
      let foundAccount = this.accountService.getAccountById(callPlan.accountId);
      this.accountService.selected = foundAccount;
      this.contactService.contactInformation = undefined;
    }
    this.callPlanOfflineService.selectedCallPlan = callPlan;
    this.callPlanOfflineService.selectedCallPlan.isTeamCallPlan = this.callPlanMode == "team" && !this.fromPlanTab;
    if (this.fromPlanTab) this.onCallPlanSelectOnPlanTab.emit(callPlan);
    else this.onCallPlanSelect.emit(callPlan);
    let insightsEventName = this.callPlanMode == 'mine' ? 'MyCallPlanSegmentCallPlanClicked' : 'TeamCallPlanSegmentCallPlanClicked'
    this.trackingService.tracking(insightsEventName, TrackingEventNames.CALLPLAN)
  }


  trackInInsights(evt: string) {
    if (evt == "mine") {
      this.trackingService.tracking('MyCallPlansTab', TrackingEventNames.CALLPLAN);
    }
    if (evt == "team") {
      this.trackingService.tracking('TeamCallPlanTab', TrackingEventNames.CALLPLAN);
    }
    this.callPlanOfflineService.setCallPlanMode(evt);
    setTimeout(() => {
      this.scrollTo(evt == "mine" ? 'currentHeader' : 'currentTeamHeader')
    }, 0)
  }

  public onCloseModal() {
    this.trackingService.tracking('CallPlanBack', TrackingEventNames.CALLPLAN)
    this.navService.popWithPageTracking().then(() => {
      this.uiService.activeView = this.uiService.prevView;
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        if (_.isEmpty(this.activityService.selectedActivityAtHome) && _.isEmpty(this.activityService.selectedActivity)) {
          this.uiService.showRightPane = false;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
      } else {
        // Short call feature requires to bring the tab back to short call home
        if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
          this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
          this.uiService.setAgendaTab('shortCallHome');
        }
      }
    });
    this.callPlanOfflineService.selectedCallPlan = undefined;
    if (this.callPlanOfflineService.selectedCallPlanAtHome) {
      this.callPlanOfflineService.selectedCallPlan = this.callPlanOfflineService.selectedCallPlanAtHome;
    }
    this.callPlanOfflineService.callPlanPageView = '';
    this.uiService.toolsActivityActive = false;
    this.uiService.activitiesPagePlanTabSelectedPlan = this.uiService.activitiesPagePlanTabSelectedPlanAtHome;
    this.fromPlanTab = false;
    if (this.activityService.selectedActivity) {
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome ? this.activityService.getActivityByID(this.activityService.selectedActivityAtHome.ID) : this.activityService.selectedActivity;
      this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
    }
  }


  async segmentChange(segment) {
    this.callPlanOfflineService.selectedCallPlan = undefined;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    if (segment == "mine") {
      this.searchText = this.translate.instant('SEARCH_MY_CALLPLANS');
      setTimeout(() => {
        this.initFilteredCustomers();
      }, 0);
    } else if (segment == "team") {
      this.searchText = this.translate.instant('SEARCH_TEAM_CALLPLANS');
      if (!this.callPlanOfflineService.teamSegmentCallPlans.length && this.waitingForTeamPlans && !this.device.isOffline) {
        const loader = await this.loadingController.create();
        loader.present();
        try {
          const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
          let a = await this.callDataService.getTeamCallPlans(dataRangeWithFutureBoundBySixMonths);
          if(!_.isEmpty(a)) {
            this.callPlanOfflineService.teamSegmentCallPlans = a;
          }else {
            this.callPlanOfflineService.teamSegmentCallPlans = [];
          }
          await this.getFormattedCallPlans();
          this.initializeSectionHeader();
          if (this.callPlanOfflineService.teamSegmentCallPlans?.length) {
            this.waitingForTeamPlans = false;
          }
          loader.dismiss();
        } catch (error) {
          loader.dismiss();
        }
      }
    }
    this.trackInInsights(segment);
    this.removeAllSelectedSuggestions()
    this.updateEmptyMessage()
    this.ionContent.scrollToPoint(0, 0);
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if (this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  clickSearchArea(ev) {
    ev.stopPropagation();
    if (!this.suggestionsActive) {
      this.suggestionsActive = true;
      let searchToolName: any = "";
      if (this.callPlanMode == 'mine') {
        if (!this.callPlanOfflineService.myRecentSearches || !this.callPlanOfflineService.myRecentSearches.length) {
          searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'My Call Plans');
        }
      } else {
        if (!this.callPlanOfflineService.teamRecentSearches || !this.callPlanOfflineService.teamRecentSearches.length) {
          searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Team Call Plans');
        }
      }
      if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
        this.suggestionsActive = false;
      }
    }
  }

  async onInput(event) {
    this.searchKey = '';
    if (!this.shouldFireSearchChangeEvent) {
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.target.value) ? event.target.value : '';
    // for input search text length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true; // to figure out results of search
      this.searchKey = params;
      if (this.callPlanMode == 'mine') {
        this.searchCallPlansSuggestions(this.filteredCustomers, params, event);
      } else {
        this.searchCallPlansTeamSuggestions(this.filteredTeamsCustomersCopy, params, event);

         /* // * // * //* Update Manager View for Team Call Plan // * // * //* */
        this.filteredTeamsCustomersByGroup = _.chain(this.filteredTeamsCustomers)
        .groupBy('positionId')
        .map((value, key) => ({
          positionId: key,
          customers: _.sortBy(_.uniqBy(value, 'customerId'), ['customerName']),
          isExpand: false,
        }))
        .value();

        if(!_.isEmpty(this.filteredTeamsCustomersCopy) && !_.isEmpty(this.callPlanOfflineService.repNamesWithPositions)) {
            this.callPlanOfflineService.repNamesWithPositions.filter((item1) => {
            const found = this.filteredTeamsCustomersByGroup.find(elm2 => elm2.positionId === item1['_positionid_value']);
              if(found) {
                item1 = _.merge(item1,found)
                return item1;
              }
            });
          this.filteredTeamsCustomersByGroup = this.callPlanOfflineService.repNamesWithPositions;
        }

        this.filteredTeamsCustomers = await this.aggregateTeamsCallPlans(this.filteredTeamsCustomers);

        this.filteredTeamsCustomersByGroup.map(rep => {
          if(rep.customers) {
            rep.callplans = [];

            rep.customers.filter((elm1) => {
              const found = this.filteredTeamsCustomers.find(elm2 => elm2.customerId === elm1.customerId && elm2.positionId === elm1.positionId);

              if(found) {
                return rep.callplans.push(found);
              }
            });
            rep.callplans = _.uniqBy(rep.callplans, 'customerId');
          }
          return rep;
        })

        this.filteredTeamsCustomersByGroup = this.filteredTeamsCustomersByGroup.filter(item => (item.callplans.length > 0));

        //Sort by fullName in alphabetical order
        this.filteredTeamsCustomersByGroup = _.sortBy(this.filteredTeamsCustomersByGroup,
          [(member) => member.fullname.toLowerCase()]
        );
        /* // * // * //* Update Manager View for Team Call Plan // * // * //* */
      }
    }
    // for input search text length is 0 to 2
    else {
      this.suggestionsData = [];
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = { past: [], present: [], future: [] };
        this.searching = false;
        if (!_.isEmpty(this.selectedFilterData)) {
          this.searchCallPlansList(true);  
        } else {
          await this.getFormattedCallPlans();
        }
      }
      else {
        this.searchCallPlansList();
      }
      let searchToolName;
      if (this.callPlanMode == 'mine') {
        if (!this.callPlanOfflineService.myRecentSearches || !this.callPlanOfflineService.myRecentSearches.length) {
          searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'My Call Plans');
        }
      } else {
        if (!this.callPlanOfflineService.teamRecentSearches || !this.callPlanOfflineService.teamRecentSearches.length) {
          searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Team Call Plans');
        }
      }
      if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
        this.suggestionsActive = false;
      }
    }
    this.updateEmptyMessage()
    this.initializeSectionHeader();
    this.onSearchBlur(null);
    this._cd.detectChanges();
  }

  async saveAdvancedSearch(failedText?: string) {
    let saveSearchName: string
    let toolName;
    if (this.callPlanMode == 'mine') {
      toolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'My Call Plans');
    } else {
      toolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Team Call Plans');
    }
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o => o.searchToolName == toolName.searchToolNameID);
    this.alertService.showAlert({
      title: this.translate.instant('SAVE_SEARCH'),
      subTitle: failedText ? failedText : '',
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
      inputs: [{ type: 'text', name: "name", placeholder: this.translate.instant('ENTER_NAME') }]
    }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if (res.data && res.data.values.name) {
            saveSearchName = res.data.values.name.trim();
            let sameNameSearches = currentSavedSearches.filter(o => o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0);
            let incrementNumber: number = 1;
            if (sameNameSearches.length) {
              saveSearchName += ' (';
              _.each(sameNameSearches, (searchData => {
                if (searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0) {
                  let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                  if (!isNaN(currentIncrement) && currentIncrement >= incrementNumber) incrementNumber = currentIncrement + 1;
                }
              }))
              saveSearchName += incrementNumber + ')';
            }
            this.disableSaveSearch = true;
            await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
              toolName ? toolName.searchToolNameID : '',
              UserSavedSearchTypes.OWNED,
              this.selectedSuggestionsData.slice())
          }
          else if (res.data && res.data.values.name == "") {
            this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
          }
        }
      })
  }
  //To display suggestions
  searchCallPlansSuggestions(callPlans, searchText: string, event?) {
    if (searchText && callPlans) {
      searchText = searchText.toUpperCase();
      let callPlansSearchIndexConfig = this.callPlanMode == 'mine' ? this.searchConfigService.myCallPlansSearchIndexesConfig : this.searchConfigService.teamCallPlansSearchIndexesConfig;
      let toolNameForEntityCharacterSearch = this.callPlanMode == 'mine' ? this.translate.instant('MY_CALL_PLANS') : this.translate.instant('TEAM_CALL_PLANS');
      this.suggestionsData = this.searchConfigService.fetchSuggestions(callPlansSearchIndexConfig, this.selectedSuggestionsData,
        toolNameForEntityCharacterSearch, this.callPlanSearchText)
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
        this.filteredCustomers = callPlans
        this.filteredCustomers = this.filteredCustomers.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
      }
    } else {
      return [];
    }
  }


  searchCallPlansTeamSuggestions(callPlans, searchText: string, event?) {
    if (searchText && callPlans) {
      searchText = searchText.toUpperCase();
      let callPlansSearchIndexConfig = this.callPlanMode == 'mine' ? this.searchConfigService.myCallPlansSearchIndexesConfig : this.searchConfigService.teamCallPlansSearchIndexesConfig;
      let toolNameForEntityCharacterSearch = this.callPlanMode == 'mine' ? this.translate.instant('MY_CALL_PLANS') : this.translate.instant('TEAM_CALL_PLANS');
      this.suggestionsData = this.searchConfigService.fetchSuggestions(callPlansSearchIndexConfig, this.selectedSuggestionsData,
        toolNameForEntityCharacterSearch, this.callPlanSearchText)
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredTeamsCustomers = callPlans
        const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
        this.filteredTeamsCustomers =this.filteredTeamsCustomers.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
      }
    } else {
      return [];
    }
  }
  getOjectValues(callPlanObject): string {
    let objString: string[] = [];
    let callPlanInclude =  ['customerName'];
    for (let callPlan in callPlanObject) {
      if (callPlanInclude.indexOf(callPlan) > -1 && callPlanObject[callPlan]) {
        if (Array.isArray(callPlanObject[callPlan])) {
          for (let i = 0; i < callPlanObject[callPlan].length; i++) {
            objString.push(this.getOjectValues(callPlanObject[callPlan][i]));
          }
        } else {
          objString.push(callPlanObject[callPlan]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  async searchCallPlansList(isFilter?: boolean) {
    // second level search for call plans
    if (isFilter) {
      this.filtering = true;
      this.isFilteredByCustomerTypeOnly = this.selectedFilterData.length == 1 && this.selectedFilterData.some(o=> o.categoryPath == 'customerType');
    }else {
      this.searching = true;
    }
    let selectedData: SelectedSuggestionPillDataModel[] = [];
    if (this.filtering && this.searching) {
      selectedData = _.uniq(_.concat(this.selectedFilterData, this.selectedSuggestionsData));
    } else {
      selectedData = isFilter ? this.selectedFilterData : this.selectedSuggestionsData;
    }

    this.initFilteredCustomers();

    let filteredCustomersCopy: any[] = [];
    filteredCustomersCopy = (this.callPlanMode == 'mine') ? this.filteredCustomers : this.callPlanOfflineService.formattedTeamCallPlans.past;

    let selectedSuggestionsDataCopy = selectedData.slice().filter(data => data.categoryName != 'tag')
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o => o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    // entity level character search
    if (entityLevelCharSearch) {
      const formattedSearchText = entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
      const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
      if (formattedSearchTextSplit) {
        formattedSearchTextSplit.forEach(searchText => {
          this.filteredCustomers = filteredCustomersCopy?.filter(callPlan => {
            let callPlanPastEntityLevel = this.getOjectValues(callPlan);
            return callPlanPastEntityLevel.includes(searchText);
          });
        });
      }
    }

    //Case: Custoemr Type category
    const idxCustomerTypeCategory = selectedSuggestionsDataCopy.findIndex(s=>s.categoryPath == "customerType");
    if(!_.isEmpty(filteredCustomersCopy) && idxCustomerTypeCategory > -1) {
      const selectedValue = selectedSuggestionsDataCopy[idxCustomerTypeCategory].value;
      if(selectedValue == 'contact') {
        filteredCustomersCopy = filteredCustomersCopy.filter(d=>d.contactId);
      }else if(selectedValue == 'account') {
        filteredCustomersCopy = filteredCustomersCopy.filter(d=>d.accountId);
      }
    }

     //Case: Status category
     const idxStatus = selectedSuggestionsDataCopy.findIndex(s=>s.categoryPath =="status");
     if(!_.isEmpty(filteredCustomersCopy) && idxStatus > -1) {
       const selectedValue = selectedSuggestionsDataCopy[idxStatus].value;
       if(selectedValue == 'completed') {
        filteredCustomersCopy = filteredCustomersCopy?.filter(d => d.percentage ==100);
       }else if(selectedValue == 'inprogress') {
        filteredCustomersCopy = filteredCustomersCopy?.filter(d => d.percentage <=99);
       }
     }

    //Case: Affilaited Accounts category
    const filteredAffiliatedAccountsCategory = selectedSuggestionsDataCopy.filter(s=>s.categoryName == "Affiliated Accounts");
    if(!_.isEmpty(filteredAffiliatedAccountsCategory)) {
      const dbKey = 'indskr_accountcontactaffiliation'
      const rawLEData = await this.disk.retrieve(dbKey);
      if(rawLEData && rawLEData.raw) {
        let foundContactIds = [];
        const linkedEntityData = rawLEData.raw;
        filteredAffiliatedAccountsCategory.forEach(item => {
          const filteredLinkedEntityData = linkedEntityData.filter(a => a['indskr_accountcontactaffiliation.indskr_accountid_Formatted'] == item.selectedFacet);
          if(!_.isEmpty(filteredLinkedEntityData)) {
            filteredLinkedEntityData.forEach(d=>{
              if(d.contactid) foundContactIds.push(d.contactid);
            });
          }
        });
        filteredCustomersCopy = filteredCustomersCopy?.filter(d => foundContactIds.includes(d.contactId));
      }
    }

    //remove search categories which are the external config
    selectedSuggestionsDataCopy = selectedSuggestionsDataCopy.slice().filter(data => data.categoryPath != 'customerType');
    selectedSuggestionsDataCopy = selectedSuggestionsDataCopy.slice().filter(data => data.categoryPath != 'status');
    selectedSuggestionsDataCopy = selectedSuggestionsDataCopy.slice().filter(data => data.categoryName != 'Affiliated Accounts');

    let filteredCallPlans: any[] = [];
    if(!_.isEmpty(selectedSuggestionsDataCopy)) {
      filteredCallPlans = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredCustomersCopy);
    }else {
      filteredCallPlans = filteredCustomersCopy;
    }

    this.filteredCustomers = filteredCallPlans;
    //check sort
    if (this.callPlanMode == "mine") {
      this.sortCustomerCallPlanList();
    } else {
      this.sortTeamCustomerCallPlanList();
    }
    this.initializeSectionHeader();
    this.onSearchBlur(null);
  }
  // end advance search functions

  onInputForGeneralSearch(event) {
    let filteredCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
    filteredCallPlans.past = this.callPlanOfflineService.formattedCallPlans.past;
    filteredCallPlans.present = this.callPlanOfflineService.formattedCallPlans.present;
    filteredCallPlans.future = this.callPlanOfflineService.formattedCallPlans.future;
    if (event && event.value) {
      const val = event.value;
      if (val && val.trim() != '') {
        this.searching = true;
        const formattedSearchText = this.searchConfigService.convertFormattedString(val).toUpperCase();
        filteredCallPlans.past = filteredCallPlans.past.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
        filteredCallPlans.present = filteredCallPlans.present.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
        filteredCallPlans.future = filteredCallPlans.future.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
      } else {
        this.searching = false;
      }
    } else {
      this.searching = false;
    }
    this.filteredList.past = filteredCallPlans.past;
    this.filteredList.present = filteredCallPlans.present;
    this.filteredList.future = filteredCallPlans.future;
    this.initializeSectionHeader();
  }

  ionSearchFocus(ev) {
    try {
      let el = this.contactListElement.nativeElement.ownerDocument.getElementById('allCallPlans');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickedInSuggestionsArea(ev) {
    ev.stopPropagation();
  }
  // Recent search list
  handleFacetSelection(data: SelectedSuggestionPillDataModel) {
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a, b) => {
      if (a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.callPlanSearchText = '';
    this.suggestionsData = [];
    this.shouldFireSearchChangeEvent = false;
    this.searchCallPlansList();
    if (!data.isComingFromRecentSearch) {
      let recentSearches;
      recentSearches = (this.callPlanMode == 'mine') ? this.callPlanOfflineService.myRecentSearches : this.callPlanOfflineService.teamRecentSearches;
      if (!recentSearches.some(recSearch => recSearch.categoryName == data.categoryName && recSearch.selectedFacet == data.selectedFacet)) {
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index) => index > 2);
        if (this.callPlanMode == 'mine') {
          this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_CALL_PLANS_RECENT_SEARCHES, (doc) => {
            if (!doc || !doc.raw) {
              doc = {
                raw: []
              }
            }
            doc.raw = recentSearches;
            return doc;
          })
        } else {
          this.disk.updateOrInsert(DB_KEY_PREFIXES.TEAM_CALL_PLANS_RECENT_SEARCHES, (doc) => {
            if (!doc || !doc.raw) {
              doc = {
                raw: []
              }
            }
            doc.raw = recentSearches;
            return doc;
          })
        }
      }
    }
  }

  handleSavedSearchSelection(data) {
    this.selectedSuggestionsData = [];
    if (data && data.categoryValuePairs) {
      data.categoryValuePairs.forEach(catValPair => {
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1
        else return -1
      })
      this.suggestionsActive = false;
      this.callPlanSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchCallPlansList();
    }
  }

  removeAllSelectedSuggestions() {
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    this.filteredList = { past: [], present: [], future: [] };
    this.filteredCustomers=[];
    this.callPlanSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.filtering = false;
  }

  removeSelectedSuggestion(suggestion,filter) {
    this.disableSaveSearch = false;
    let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
    _.remove(this.selectedFilterData,(o)=>_.isEqual(o,suggestion));
    _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion,(o)=>_.isEqual(o,suggestion));
    if(_.isEmpty(selectedSuggestions)) {
      let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
      if(index>-1) this.filterNameBubble.splice(index,1);
    }
    if (this.selectedSuggestionsData.length == 0) {
      this.callPlanSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
      if(this.selectedFilterData.length == 0) {
        this.filtering = false;
        this.initFilteredCustomers();
      }else {
        this.searchCallPlansList(true);
      }
      this.initializeSectionHeader();
    }
    else {
      this.searchCallPlansList(true);
    }
  }

  setTabsData() {
    this.tabsData = [
      {
        displayText: this.translate.instant('MY_CALL_PLANS'),
        value: "mine"
      },
      {
        displayText: this.hasTeamPlansEnabled ? this.translate.instant('TEAM_CALL_PLANS') : '',
        value: "team",
        disable: !this.hasTeamPlansEnabled || (this.device.isOffline && this.callPlanOfflineService.teamSegmentCallPlans.length == 0)
      },
    ]
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  async footerButtonClicked(buttonId) {
    if(this.activeTab != buttonId) {
      this.clearFilters();
      this.ionContent.scrollToPoint(0, 0);
    }
    this.activeTab = this.callPlanOfflineService.currentFooterFilter = buttonId;
    this.callPlanOfflineService.selectedCallPlan = undefined;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    if (this.callPlanMode == "mine") {
      this.initFilteredCustomers();
    } else {
      await this.filterTeamsCustomers();
    }
  }

  initFilteredCustomers() {
    this.filteredCustomers = [];
    this.callPlanSearchText = '';
    switch (this.activeTab) {
      case 'call-plan-current':
        this.filteredCustomers = this.myFormattedCallPlans.present;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanCurrent);
        break;
      case 'call-plan-past':
        this.filteredCustomers = this.myFormattedCallPlans.past;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanPast);
        break;
      case 'call-plan-future':
        this.filteredCustomers = this.myFormattedCallPlans.future;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanFuture);
        break;

      default:
        console.log('Unhandled case', this.activeTab);
        break;
    }
    this.filteredCustomers = this.callPlanOfflineService.aggregateRepCallPlans(this.filteredCustomers);
    this.callPlanOfflineService.mapMyCallPlansToSearchIndex(this.filteredCustomers);
    this.setCustomerStatistics();
    this.sortCustomerCallPlanList();
    this.initializeSectionHeader();
  }


  async filterTeamsCustomers() {
    this.filteredTeamsCustomers = [];
    this.callPlanSearchText = '';
    switch (this.activeTab) {
      case 'call-plan-current':
        this.filteredTeamsCustomers = this.teamFormattedCallPlans.present;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanCurrent);
        break;
      case 'call-plan-past':
        this.filteredTeamsCustomers = this.teamFormattedCallPlans.past;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanPast);
        break;
      case 'call-plan-future':
        this.filteredTeamsCustomers = this.teamFormattedCallPlans.future;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanFuture);
        break;

      default:
        console.log('Unhandled case', this.activeTab);
        break;
    }
    /* // * // * //* Update Manager View for Team Call Plan // * // * //* */
    this.filteredTeamsCustomersCopy = Object.assign([], this.filteredTeamsCustomers);

    this.filteredTeamsCustomersByGroup = _.chain(this.filteredTeamsCustomers)
    .groupBy('positionId')
    .map((value, key) => ({
      positionId: key,
      customers: _.sortBy(_.uniqBy(value, 'customerId'), ['customerName']),
      isExpand: false,
    }))
    .value();

    let teamRepNamesWithPositions = [];
    if(_.isEmpty(this.callPlanOfflineService.repNamesWithPositions)) {
      const repNamesWithPositionArr = await this.callDataService.getRepNamesByParentPositionId();
      const tempList = repNamesWithPositionArr.map(element => _.pick(element,['_positionid_value','fullname']));
      this.callPlanOfflineService.repNamesWithPositions = this.filterUniqueName(tempList);
      teamRepNamesWithPositions = _.cloneDeep(this.callPlanOfflineService.repNamesWithPositions);
    } else {
      teamRepNamesWithPositions = _.cloneDeep(this.callPlanOfflineService.repNamesWithPositions);
    }

    if(!_.isEmpty(this.callPlanOfflineService.repNamesWithPositions) && !_.isEmpty(this.filteredTeamsCustomersCopy)) {
      this.callPlanOfflineService.repNamesWithPositions.filter((item1) => {
        const found = this.filteredTeamsCustomersByGroup.find(elm2 => elm2.positionId === item1['_positionid_value']);
        if(found) {
          item1 = _.merge(item1,found)
          return item1;
        }
      });

      this.filteredTeamsCustomersByGroup = this.callPlanOfflineService.repNamesWithPositions;

      this.filteredTeamsCustomers = await this.aggregateTeamsCallPlans(this.filteredTeamsCustomers);

      this.filteredTeamsCustomersByGroup.map(rep => {
        if(rep.customers) {
          rep.callplans = [];

          rep.customers.filter((elm1) => {
            const found = this.filteredTeamsCustomers.find(elm2 => elm2.customerId === elm1.customerId && elm2.positionId === elm1.positionId);
            if(found) {
              return rep.callplans.push(found);
            }
          });

          rep.callplans = _.uniqBy(rep.callplans, 'customerId');
        }
        return rep;
      })

      //Sort by fullName in alphabetical order
      this.filteredTeamsCustomersByGroup = _.sortBy(this.filteredTeamsCustomersByGroup,
        [(member) => member.fullname.toLowerCase()]
      );
    }

    teamRepNamesWithPositions = teamRepNamesWithPositions.map((repnames) => {
      repnames.callplans = [];
      repnames.customers = [];
      return repnames;
    })

    this.filteredTeamsCustomersByGroup = _.unionBy(
      this.filteredTeamsCustomersByGroup,
      teamRepNamesWithPositions,
      'fullname'
    );
    /* // * // * //* Update Manager View for Team Call Plan // * // * //* */

    this.setTeamCustomerStatistics();
    this.sortTeamCustomerCallPlanList();
    this.initializeSectionHeader();

  }

  private _checkBUConfig() {
    if(this.authService.user.buConfigs['indskr_callplanprogress']) this.hasCallPlanProgress = true;
    if(this.authService.user.buConfigs['indskr_displaygoalsbeyond100']) this.displaygoalsbeyond100 = true;
    if(this.authService.user.buConfigs['indskr_displayallactivitiesprogress']) this.displayAllActProgress = true;
  }

  async openFilterMenu(event) {
      this._initiateMyContactsFilter();
      this.isFilterActive =false;
  }

  private async _initiateMyContactsFilter() {
    let options
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data:  options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.CustomerCallPlanPage,
        isFilterActive:false
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;

          data.selectedItems.forEach((selected) => {
            if(!this.selectedFilterData.some(o=> o.categoryName==selected.categoryName && o.selectedFacet == selected.selectedFacet)) {
              this.selectedFilterData.unshift(selected);
            }
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));

          this.searchCallPlansList(true);

          this.selectedFilterData.sort((a,b)=>{
            if(a.createdOn < b.createdOn) return 1
            else return -1
          })
        }
      }
      this.isFilterActive =false;
    });
  }

  private _updateFilterNameBubble(item) {
    if(item.categoryName == 'completed' || item.categoryName == 'In Progress') {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == this.translate.instant('STATUS'));
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: this.translate.instant('STATUS'), suggestion: [item]};
        this.filterNameBubble.push(selected);
      }
    } else {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: item.categoryName, suggestion: [item]};
        this.filterNameBubble.push(selected);
      }
    }
  }

  onSectionHeaderControlClick(id: string) {
    if (id === 'clear-filter') {
      this.clearFilters();
    }
  }

  private async clearFilters() {
    this.filtering = false;
    this.isFilteredByCustomerTypeOnly = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    if (this.selectedSuggestionsData.length == 0) {
      this.removeAllSelectedSuggestions();
    } else {
      this.searchCallPlansList();
    }
    await this.getFormattedCallPlans();
    this.initializeSectionHeader();
  }

  private filterUniqueName(nameList: any[]) {
    const groupedById = _.groupBy(nameList, '_positionid_value');
    const filteredObjects = _.flatMap(groupedById, (group) => {
      const uniqueNames = _.uniqBy(group, 'fullname');
      return uniqueNames;
    });

    return filteredObjects;
  }

  getFilterButtonBadgeCount() {
    return this.selectedFilterData?.length;
  }

  onSearchBlur(event){
    if(this.callPlanSearchText && this.callPlanSearchText.length>0){
      this.hideFilterButton = true;
    }
    else{
      this.hideFilterButton = false;
    }
  }

  private async scrollToCurrentPoint() {
    const scrollElement = await this.ionContent.getScrollElement();
    const scrollPosition = scrollElement.scrollTop || 0;
    if (scrollPosition && scrollPosition > 0) {
      setTimeout(()=>{
        this.ionContent.scrollToPoint(0, scrollPosition);
      }, 0);
    }
  }

}
