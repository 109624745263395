<!-- <ion-header *ngIf="viewData && (viewData.showCancelandDoneBtn || viewData.pageTitle || viewData.isSearchEnabled)" class="dropdown-list-header"> -->
    <ion-toolbar class="page-title" *ngIf="viewData && viewData.pageTitle">
        <ion-title class="ion-text-center overflowHiddenAndEllipsis dropdown-title"> {{(viewData)?viewData.pageTitle:''}} </ion-title>
    </ion-toolbar>
    <ion-toolbar class="page-title" *ngIf="viewData && viewData.showCancelandDoneBtn">
      <ion-buttons slot="start" *ngIf="viewData.showCancelandDoneBtn">
          <ion-button large fill="clear" (click)='dismissList(false)'>
              {{ (viewData.cancelButtonText?viewData.cancelButtonText:'CANCEL') | translate}}
          </ion-button>
      </ion-buttons>
      <ion-buttons slot="end" *ngIf="viewData.showCancelandDoneBtn">
          <ion-button [disabled]="noChanges" icon-end large (click)="dismissList(true)">
              {{ (viewData.doneButtonText?viewData.doneButtonText:'DONE') | translate}}
          </ion-button>
      </ion-buttons>
  </ion-toolbar>
<!--    <ion-toolbar *ngIf="viewData.isSearchEnabled">-->
        <ion-searchbar *ngIf="viewData.isSearchEnabled" [(ngModel)]="searchInput" (ionInput)="searchText($event)" [showCancelButton]="false"
            placeholder="{{viewData.searchInputPlaceholder ? viewData.searchInputPlaceholder : 'XPLORE_SEARCH_PLACEHOLDER' | translate}}">
        </ion-searchbar>
<!--    </ion-toolbar>-->
<!-- </ion-header> -->
<ion-content class='dropdown-list'>
    <ind-key-controls-area *ngIf="viewData.showClearAllBtn" [viewData]="dropdownListKeyControlAreaModel"> </ind-key-controls-area>
    <ng-container *ngIf="viewData && listData">
        <ion-item-divider *ngIf="viewData.listItemCountTitle">{{(searchActive) ? 'AL_RESULTS' : viewData.listItemCountTitle | translate}} ({{listData.length}})
        </ion-item-divider>
        <!-- <ion-scroll class="dropdown-options-list-scroll"> -->
        <div class="dropdown-options-list-scroll">
          <ion-list class="dropdown-options-list">
            <ion-item *ngFor="let item of listData" (click)="selectItem(item)">
              <!-- <ion-checkbox *ngIf="viewData.isMultipleSelectionEnabled" [(ngModel)]="item.isSelected" [checked]="item.isSelected"></ion-checkbox> -->
              <ion-checkbox *ngIf="viewData.isMultipleSelectionEnabled" 
                [checked]="item.isSelected" 
                [disabled]="viewData.isReadOnly"
                labelPlacement="end"
                justify="start"
                >{{item.title}}
              </ion-checkbox>
              <ion-label *ngIf="!viewData.isMultipleSelectionEnabled" > {{item.title}} </ion-label>
              <ion-icon slot="end" color="primary" name="checkmark" *ngIf="item.isSelected && !viewData.isMultipleSelectionEnabled"></ion-icon>
            </ion-item>
          </ion-list>
        </div>
    </ng-container>
</ion-content>
