import { DiskService } from './../../../services/disk/disk.service';
import { PageName } from '@omni/services/navigation/navigation.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation, ViewChild, ElementRef, SimpleChanges } from "@angular/core";
import _ from "lodash";
import * as Survey from "survey-angular";
import { Events } from '@omni/events';
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { ComponentViewMode } from "@omni/services/ui/ui.service";
import { CaseManagementService } from "@omni/services/case-management/case-management.service";
import { Observable, Subscription } from "rxjs";
import { CaseActivity } from "@omni/classes/case-intake/case-activity.class";
import { DeviceService } from '@omni/services/device/device.service';
import { SearchLookupPopoverService } from '@omni/services/popover/search-lookup-popover.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { LookupSearchResult } from '@omni/classes/popover/search-lookup-popover.class';
import { SearchLookupListDetailModel } from '@omni/models/search-lookup-list-detail-model';
import { TranslateService } from '@ngx-translate/core';
import { SearchLookupPopoverComponent } from '../../popover/search-lookup-popover/search-lookup-popover';
import { PopoverController } from '@ionic/angular';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '../ind-dropdown-list/ind-dropdown-list';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';

@Component({
    selector: "ind-survey",
    templateUrl: "ind-survey.html",
    styleUrls: ['ind-survey.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SurveyComponent implements OnInit {

    @Output() completeSurvey = new EventEmitter<any>();
    @Output() surveyData = new EventEmitter<any>();
    @Input() tempLookupResult: any;
    @Input() surveyTemplate: any;
    @Input() opportunity: Opportunity;
    @Input() surgeryOrder: SurgeryOrderActivity;
    @Input() salesOrder: OrderActivity;
    @ViewChild('surveyContent') surveyContent: ElementRef;

    public survey;
    public surveyResponse;
    public IcurrentCaseSubscription: Subscription;
    public IcurrentLookupResultSubscription: Subscription;
    public selectedCase: CaseActivity;
    private _lookupPopover: HTMLIonPopoverElement;
    public _dropdownPopover:any;

    constructor(
      public events: Events,
      public contactService: ContactOfflineService,
      public caseManagementService: CaseManagementService,
      public surveyService: SurveyService,
      private readonly device: DeviceService,
      public searchLookupPopoverService: SearchLookupPopoverService,
      public disk: DiskService,
      public translate: TranslateService,
      public popoverCtrl: PopoverController,
      ) {}

    ngOnInit() {

      this.IcurrentCaseSubscription = this.caseManagementService.currentSelectedCase.subscribe(
        (value: CaseActivity) => {
          this.selectedCase = value;
        }
      );

      this.IcurrentLookupResultSubscription = this.surveyService.currentLLookupResult.subscribe((result) => {
        this.initCustomWidget();
        this.loadSurveyDetails()
      });

      this.events.subscribe('completeSurvey_sendCaseForReview', () => {
        this.survey.doComplete()
      });

      this.events.subscribe('loadSurveyTemplate', (template) => {
        this.surveyTemplate = template;
        this.loadSurveyDetails();
      });

      this.initCustomWidget();
      this.loadSurveyDetails();
    }

    ngOnDestroy() {
      this.tempLookupResult = [];
      this.events.unsubscribe('completeSurvey_sendCaseForReview');
      this.events.unsubscribe('loadSurveyTemplate');
    }

    ngOnChanges(changes: SimpleChanges) {
      if(changes) {
        this.initCustomWidget();
        this.loadSurveyDetails();
      }
    }

    private loadSurveyDetails() {
      this.survey = new Survey.Model(this.surveyTemplate);
      this.survey.focusFirstQuestionAutomatic = false;
      this.survey.focusOnFirstError = false;
      this.survey.data = this.surveyService.surveyStaticResults;
      this.survey.onValueChanged.add(this.onValueChanged.bind(this));
      this.survey.onComplete.add(this.onComplete.bind(this));
      this.survey.showNavigationButtons = false;
      this.survey.showQuestionNumbers = 'off';
      this.customizeAppearance();
      Survey.SurveyNG.render('surveyElement', { model: this.survey });
    }

    private onValueChanged(sender,question) {
      console.log('Answer changed');
      this.surveyData.emit(sender.data);
    }

    private onComplete(sender) {
      console.log('survey completed');
      this.surveyResponse = this.getSurveyResponse();
      this.completeSurvey.emit(this.surveyResponse);
      this.surveyData.emit(sender.data);
    }

    private getSurveyResponse(){
      let surveyResponse: Array<any> = [];
      // getAllQuestions returns a list of all questions in a survey. (visibleOnly?: boolean, includingDesignTime?: boolean) => Question[]
      this.survey.getAllQuestions(true).forEach(question => {
        let response = {
          indskr_question : question.title,
          indskr_answer : this._getAnswer(question),
          questionName : question.name
        }
        surveyResponse.push(response);
      });
      return surveyResponse;
    }

    private _getAnswer(question) {
      let answer = question.displayValue;
      // if(question.getType() == 'lookup') {
      //   answer = question.displayValue;
      // } else {
      //   answer = question.value;
      // }
      return _.isArray(answer) ? this._aggregateAnswer(answer) : answer
    }

    private _aggregateAnswer(answers) {
      if(answers.length == 1) {
        return answers[0];
      } else {
        let stringValue = answers[0];
        for(let i=1;i<answers.length;i++) {
          stringValue += ', '+answers[i];
        }
        return stringValue;
      }
    }

    private initCustomWidget() {
      const widgetName = `lookup`
      Survey.CustomWidgetCollection.Instance.add({
        name: widgetName,
        widgetIsLoaded() {
          return true
        },
        isFit(question: any) {
          return question.getType() === this.name
        },
        init() {
          //Register a new type using the empty question as the base.
          Survey.Serializer.addClass(this.name, [], undefined, 'empty')
          Survey.Serializer.addProperty(widgetName, 'metadata').visible = false
          Survey.Serializer.addProperty(widgetName, 'valueCaption').visible = false
        },
        afterRender: (question: any, el: HTMLElement) => {
          let input = el.getElementsByTagName('input')[0]
          let caretIcon = el.getElementsByTagName('ion-icon')[0];
          if(question.readOnly){
            caretIcon.style['display'] = "none";
          }
          question.displayValueCallback = () => question.valueCaption;
          const metadata = question.metadata
          const targetEntity = metadata.target;
          if((this.tempLookupResult && this.tempLookupResult.length>0) || (this.surveyService.surveyLookupResults &&  this.surveyService.surveyLookupResults.length>0)){
            this.mapLookupResultFromOfflineDB(targetEntity, question);
          }
          this.onValueChangedCallback(targetEntity, question);
          // question.valueChangedCallback = () => this.onValueChangedCallback(targetEntity, question);
          // input.onchange = () => input.value = question.value;
          input.onclick = async() => {
            if(!question.readOnly){
              this.onLookupControlClick(metadata, question, event);
            }
          }
          caretIcon.onclick = async() => {
            if(!question.readOnly){
              this.onLookupControlClick(metadata, question, event);
            }
          }
          if(question.valueCaption) input.value = question.valueCaption;
          input.placeholder = `Select ${targetEntity.displayName || ''}`;
        },
        htmlTemplate: `<ion-item class='lookup_container'>
                        <input class='lookup_input' readonly></input>
                        <ion-icon class="ion-float-right arrow-icon chevron-forward-outline" name="chevron-forward-outline"></ion-icon>
                       </ion-item>`,
      })

      Survey.CustomWidgetCollection.Instance.add(
        {
          name: 'textarea',
          // iconName: 'icon-text',
          widgetIsLoaded() {
            return true;
          },
          isFit(question: any) {
            return question.getType() === this.name;
          },
          init() {
            //Register a new type using the empty question as the base.
            Survey.Serializer.addClass(this.name, [], undefined, 'text');
          },
          afterRender: (question: any, el: HTMLElement) => {
            let textarea = el.getElementsByTagName('textarea')[0];
            textarea.value = question.value || '';
            let changingValue = false;
            var updateQuestionValue = () => {
              if (changingValue) return;
              changingValue = true;
              question.value = textarea.value;
              changingValue = false;
            }
            textarea.addEventListener('change', () => updateQuestionValue())
            question.valueChangedCallback = () => {
              if (changingValue) return;
              changingValue = true;
              textarea.value = question.value || '';
              changingValue = false;
            }
            let updateReadOnly = () => {
              textarea.readOnly = question.isReadOnly;
            }
            updateReadOnly();
            question.readOnlyChangedCallback = function () {
              updateReadOnly();
            }
          },
          htmlTemplate: `<ion-label class='lookup_container'>
                          <textarea rows='10' class='multiline_textarea'}></textarea>
                         </ion-label>`,
        },
      )

      Survey.CustomWidgetCollection.Instance.add({
        name: 'dropdown',
        widgetIsLoaded() {
          return true
        },
        isFit(question: any) {
          return question.getType() === this.name
        },
        init() {
          //Register a new type using the empty question as the base.
          Survey.Serializer.addClass(this.name, [], undefined, 'empty')
          Survey.Serializer.addProperty('dropdown', 'valueCaption').visible = true
          Survey.Serializer.addProperty('dropdown', 'choices').visible = true
        },
        afterRender: (question: any, el: HTMLElement) => {
          let input = el.getElementsByTagName('input')[0]
          let caretIcon = el.getElementsByTagName('ion-icon')[0];
          if(question.readOnly){
            caretIcon.style['display'] = "none";
          }
          try {
            if(question && question.getType() == "dropdown" && question.choices){
              let choice = question.choices.find(c=> ( (c == question.value) || (c.hasOwnProperty('value') && c.value == question.value) ) );
              if(question.jsonObj && question.jsonObj.metadata && question.jsonObj.metadata.AttributeType && question.jsonObj.metadata.AttributeType == 'MultiSelectPicklistType'){
                choice = '';
                question.choices.forEach(cho => {
                  if(question.value && question.value.includes(cho.value)){
                    choice += (choice != '' ? ', ' : '') + cho.text;
                  }else if(question.value && question.value.includes(cho)){
                    choice += (choice != '' ? ', ' : '') + cho;
                  }
                })
              }
              if(choice && choice.text){
                question.valueCaption = choice.text;
              }else if (choice){
                question.valueCaption = choice;
              }
            }
          } catch (error) {
            console.log('Error mapping option set value'+error);
          }
          question.displayValueCallback = () => question.valueCaption;
          //const metadata = question.metadata
          //const targetEntity = metadata.target;
          // if((this.tempLookupResult && this.tempLookupResult.length>0) || (this.surveyService.surveyLookupResults &&  this.surveyService.surveyLookupResults.length>0)){
          //   this.mapLookupResultFromOfflineDB(targetEntity, question);
          // }
          //this.onValueChangedCallback(targetEntity, question);
          // question.valueChangedCallback = () => this.onValueChangedCallback(targetEntity, question);
          // input.onchange = () => input.value = question.value;
          input.onclick = async() => {
            if(!question.readOnly){
              this.onDropdownControlClick(question, event);
            }
          }
          caretIcon.onclick = async() => {
            if(!question.readOnly){
              this.onDropdownControlClick (question, event);
            }
          }
          if(question.valueCaption) input.value = question.valueCaption;
          input.placeholder = `Select ${question.title || ''}`;
        },
        htmlTemplate: `<ion-item class='lookup_container'>
                        <input class='lookup_input' readonly></input>
                        <ion-icon class="ion-float-right arrow-icon chevron-forward-outline" name="chevron-forward-outline"></ion-icon>
                       </ion-item>`,
      })
    }

    public onValueChangedCallback(entity, question) {
      if(this.surveyService.surveyLookupResults) {
        let index = this.surveyService.surveyLookupResults.findIndex(result => result.targetEntity == entity.name)
        if(index>-1){
          question.value = this.surveyService.surveyLookupResults[index].id;
          question.valueCaption = this.surveyService.surveyLookupResults[index].name;
        }
      }
    }

    public onLookupControlClick(metadata, question, event) {
      switch (metadata.target.name) {
        case 'account':
            this.events.publish('surveyLookupEvent', 'account-field');
          break;
        case 'product':
            this.events.publish('surveyLookupEvent', 'product-field');
          break;
        case 'contact':
        case '':
            console.log('no entity is set for this lookup');
            break;
        default:
          this._displayLookupSearchPopover(metadata, question, event);
          break;
      }

    }

  public async onDropdownControlClick(question, event) {
    if (question.isReadOnly) return;
    let allOptions = [];
    let isMultipleSelectionEnabled:boolean = false;
    try {
      if(question && question.getType() == "dropdown" && question.choices){
        allOptions = question.choices;
      }
      if(question && question.jsonObj && question.jsonObj.metadata && question.jsonObj.metadata.AttributeType && question.jsonObj.metadata.AttributeType == 'MultiSelectPicklistType'){
        isMultipleSelectionEnabled = true;
      }
    } catch (error) {
      console.log('Error mapping option set value'+error);
    }
    if (Array.isArray(allOptions) && allOptions.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Survey-Dropdown-List-Select',
        data: allOptions.map(option => {
          let obj = {
            title: option.text ? option.text : option,
            id: option.value ? option.value : option,
            isSelected: false,
          };
          if(isMultipleSelectionEnabled && question.value && question.value.includes(obj.id)){
            obj['isSelected'] = true;
          }else if (question.value && question.value == obj.id) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      try {
        let bindingTarget = event.target;
        if (event && event.target) {
          bindingTarget = event.target.parentElement;
        }
        let bindingEvent = {
          target: bindingTarget,
        }
        event = bindingEvent;
        let target = {
          top: event.target.getBoundingClientRect().top,
          bottom: event.target.getBoundingClientRect().bottom,
          height: event.target.getBoundingClientRect().height,
          left: event.target.getBoundingClientRect().left,
          right: event.target.getBoundingClientRect().right,
          width: event.target.getBoundingClientRect().width,
          x: event.target.getBoundingClientRect().x,
          y: event.target.getBoundingClientRect().y,
        };
        console.log('CLick event on'+event.target.name+' Position '+JSON.stringify(target));
      } catch (error) {
        console.log(error);
      }
      if(isMultipleSelectionEnabled){
        dropdownListDetail.showCancelandDoneBtn = true;
        dropdownListDetail.pageTitle = question.title;
        dropdownListDetail.isMultipleSelectionEnabled = true;
      }
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: event });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (isMultipleSelectionEnabled && data && data.selectedItems && data.selectedItems.length > 0) {
          
          if(data.selectedItems.length > 1){
            data.selectedItems = data.selectedItems.sort((a,b) => {
              return (a.title > b.title) ? 1: -1;
            })
          }
          let concatString = '';
          let concatID = '';
          for(let i = 0; i<data.selectedItems.length; i++){
            concatString += data.selectedItems[i].title;
            concatID += data.selectedItems[i].id;
            if(i<data.selectedItems.length-1) {
              concatString += ', ';
              concatID += ', ';
            }
          }
          question.value = concatID;
          question.valueCaption = concatString;
        }else if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != question.value){
          question.value = data.selectedItems[0].id;
          question.valueCaption = data.selectedItems[0].title;
        }else if(data && data.selectedItems && data.selectedItems.length == 0){
          question.value = null;
          question.valueCaption = ''
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

    public mapLookupResultFromOfflineDB(targetEntity, question) {
      let data;
      if(this.tempLookupResult && this.tempLookupResult.length > 0){
        data = this.tempLookupResult.find((result) => {
          if(result.hasOwnProperty('questionName')){
            return result.questionName == question.name;
          }else{
            return result.targetEntity == targetEntity.name;
          }
        })
      }else if(this.surveyService.surveyLookupResults && this.surveyService.surveyLookupResults.length > 0){
        data = this.surveyService.surveyLookupResults.find((result) => {
          if(result.hasOwnProperty('questionName')){
            return result.questionName == question.name;
          }else{
            return result.targetEntity == targetEntity.name;
          }
        })
      }

      if(data) {
        question.value = data.id;
        question.valueCaption = data.name;
      }
    }

    private async _displayLookupSearchPopover(metadata, question, event) {
      const targetEntity = metadata.target;
      let searchParam = ''
      if(targetEntity.name == 'indskr_expertcategorytype' || targetEntity.name == 'indskr_expertinquiryresponse') {
        this.searchLookupPopoverService.getLookupExistingDataForCaseSurvey(searchParam, targetEntity)
      } else {
        this.searchLookupPopoverService.getLookupResultsForCaseSurvey(searchParam, targetEntity);
      }

      let popoverListDetail: SearchLookupListDetailModel = {
        id: 'lookup_search_popover',
        title: targetEntity.displayName,
        isSearchEnabled: true,
        selectedValue: question.value,
        searchInputPlaceholder: this.translate.instant('SEARCH'),
        showUnselectOption: true,
        unselectOptionText: this.translate.instant('SELECT'),
        callbackEvent: (data: any) => this._handleLookupComponentCallback(data, metadata, question),
      };
      let cssClassName = 'lookup-popover-list-view';
      if (this.device.isNativeApp) {
        cssClassName = 'native-lookup-popover-list-view';
      }
      this._lookupPopover = await this.popoverCtrl.create({
        component: SearchLookupPopoverComponent,
        componentProps: { viewData: popoverListDetail, accessedFrom: PageName.SurveyComponent, targetEntity: targetEntity },
        cssClass: cssClassName,
        backdropDismiss: true,
        event: this.device.isNativeApp ? event : undefined
      });
      this._lookupPopover.onDidDismiss().then(async (data) => {
        this._lookupPopover = undefined;
        this.searchLookupPopoverService.lookupSearchData = [];
      });
      this._lookupPopover.present();
    }

    private async _handleLookupComponentCallback(data, metadata, question) {
      const targetEntity = metadata.target;
      // let targetObject:any = (this.opportunity?this.opportunity:(this.surgeryOrder?this.surgeryOrder:(this.selectedCase?this.selectedCase:(this.salesOrder?this.salesOrder:null))))
      let targetObject:any = (this.opportunity?this.opportunity:(this.surgeryOrder?this.surgeryOrder:(this.salesOrder?this.salesOrder:null)));
      if (data && data.selectedItem) {
        if(targetEntity.name == 'sla') {
          if(targetObject){
            targetObject.surveyResponseAdditionalData['slaid_sla@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }else{
            this.selectedCase._case_inquiry_additional_attributes['slaid_sla@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }
        } else if(metadata.schemaName.includes('indskr')) {
          if(targetObject){
            targetObject.surveyResponseAdditionalData[metadata.schemaName + '@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }else{
            this.selectedCase._case_inquiry_additional_attributes[metadata.schemaName + '@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }
        } else {
          if(targetObject){
            targetObject.surveyResponseAdditionalData[metadata.logicalName + '@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }else{
            this.selectedCase._case_inquiry_additional_attributes[metadata.logicalName + '@odata.bind'] = '/'+ targetEntity.setName + '(' +  data.selectedItem.id + ')';
          }
        }
        let selectedValue = {
          targetEntity: targetEntity.name,
          id: data.selectedItem.id,
          name: data.selectedItem.name,
          questionName: question.name,
        }
        this.surveyService.assignLookupResult(selectedValue);
        if(targetObject){
          if(targetObject.surveyResponseLookupData) {
            let index = targetObject.surveyResponseLookupData.findIndex(result => result.targetEntity == selectedValue['targetEntity'])
            if(index>-1) {
              targetObject.surveyResponseLookupData[index] = selectedValue;
            } else {
              targetObject.surveyResponseLookupData.push(selectedValue);
            }
          } else {
            targetObject.surveyResponseLookupData.push(selectedValue);
          }
          // Update opportunity in service
        }else{
          if(this.selectedCase._case_inquiry_response_surveyLookupData) {
            let index = this.selectedCase._case_inquiry_response_surveyLookupData.findIndex(result => result.targetEntity == selectedValue['targetEntity'])
            if(index>-1) {
              this.selectedCase._case_inquiry_response_surveyLookupData[index] = selectedValue;
            } else {
              this.selectedCase._case_inquiry_response_surveyLookupData.push(selectedValue);
            }
          } else {
            this.selectedCase._case_inquiry_response_surveyLookupData.push(selectedValue);
          }
          this.updateDataOnPouchForCase();
        }
      }
    }

    async updateDataOnPouchForCase() {
      try {
        await this.disk.addCaseToCollection(this.selectedCase);
      } catch (error) {
        console.log(error);
      }
      try {
        await this.caseManagementService.upsertMyCases(this.selectedCase);
      } catch (error) {
        console.log(error);
      }
    }

    private customizeAppearance() {
      let defaultThemeColors = Survey.StylesManager.ThemeColors['default'];
      defaultThemeColors["$main-color"] = "#99C6E9";
      defaultThemeColors["$main-hover-color"] = "#1B75BB";
      defaultThemeColors["$body-container-background-color"] = "#ffffff";
      Survey.defaultBootstrapCss.panel.container = 'panel_container';
      Survey.defaultBootstrapCss.panel.description = 'panel_description';
      Survey.StylesManager.applyTheme();
    }

}
