<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="marketScanSearchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="handleSearch()" (onSearchFocus)="onSearchBarFocus()"></ind-header-left>

    <ind-key-controls-area id="allAccounts" [viewData]="listKeyControlAreaModel"></ind-key-controls-area>
</ion-header>
<ion-content [ngClass]="{'vectorimageleft': marketScanSearchText.length === 0 && filteredMScansList?.length === 0}">
    <div slot='fixed' style="position:relative">
        <ind-section-header [viewData]="sectionHeaderData" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
    </div>
    <div *ngIf="marketScanSearchText.length === 0 && filteredMScansList.length === 0" class="no-data-message no-data-message-container">
        {{ ('NO' | translate) + ' ' + ('CUSTOMER_SCANS' | translate | lowercase) }}
    </div>
    <ind-no-search-result *ngIf="marketScanSearchText.length > 0 && filteredMScansList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    <ion-list class='marketscan-list' [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
        <div *ngFor="let market of filteredMScansList">
            <main-card [viewData]='getMainCardDataModel(market)'></main-card>
        </div>
    </ion-list>
    <ion-infinite-scroll *ngIf="filteredMScansList.length > 0" (ionInfinite)="doInfinite($event)" threshold="30%">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'marketScanList'"></footer-toolbar>
<!-- </ion-footer> -->
