<ion-header>
    <ind-page-title [viewData]="aboutPageTitle" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content no-padding toolbar-md-height>
    <ng-container>
        <ion-list>
            <ind-section-header [viewData]='versionHeader'></ind-section-header>

            <ion-item class="alignTextwithDivider">
                <ion-label class="detailsLabel">{{'VERSION' | translate }}</ion-label>
                <ion-label class="light-color ellipsis text-end" tappable>{{appVersion}}</ion-label>
            </ion-item>

            <ind-section-header [viewData]='refreshDataHeader'></ind-section-header>
            <ion-item (click)="refreshData()" class="alignTextwithDivider"
              [ngClass]="{'disabled': device.isOffline || device.isBackgroundUploadInProgress}">
              <ion-label class="detailsLabel">{{'REFRESH_DATA' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="forceUploadEnabled" (click)="forceUpload()" class="alignTextwithDivider"
              [ngClass]="{'disabled': device.isOffline || device.isBackgroundUploadInProgress}">
              <ion-label class="detailsLabel">{{ 'FORCE_OFFLINE_DATA_UPLOAD' | translate }}</ion-label>
            </ion-item>
            <ind-section-header [viewData]='intuneHeader'></ind-section-header>
            <ion-item (click)="showIntuneDiagnostics()" class="alignTextwithDivider"
              [ngClass]="{'disabled': device.isOffline || device.isBackgroundUploadInProgress}">
              <ion-label class="detailsLabel">Diagnostics</ion-label>
            </ion-item>

            <ind-section-header [viewData]='importantDeatilHeader'></ind-section-header>
            <ion-item (click)="openAboutDetails('COPYRIGHT_INFO')" class="alignTextwithDivider">
                <ion-label class="detailsLabel">{{'COPYRIGHT_INFO' | translate }}</ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
            <ion-item (click)="openAboutDetails('PRIVACY_POLICY')" class="alignTextwithDivider">
                <ion-label class="detailsLabel">{{'PRIVACY_POLICY' | translate }}</ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
            <ion-item (click)="openAboutDetails('TERMS_AND_CONDITIONS')" class="alignTextwithDivider">
                <ion-label class="detailsLabel">{{'TERMS_AND_CONDITIONS' | translate }}</ion-label>
                <ion-icon class="arrow-icon" tappable name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>
    </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'generalsettings'"></footer-toolbar>
<!-- </ion-footer> -->