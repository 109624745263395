<!-- Generated template for the ContactInfoComponent component -->
<ind-display-form #scrollTop [formMetadata]='displayFormDetails' [rawData]='rawFormData' [referencingEntityName]='"contactid"' [dynamicFormType]='dynamicFormType' [isEditEnabled]="isEditable" [isEditDisabled]="isEditDisabled" (onEditClick)="editContact($event)"
    [displayFormAccessedFrom]="'configuredContactInfo'" [scrollObservable]="scrollObservable">
</ind-display-form>
<!-- <ng-container *ngIf="medicalInsightsSectionHeaderViewData">
  <ind-section-header [viewData]="medicalInsightsSectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ng-container *ngFor="let insight of currentMedicalInsights; let i=index" class="insight-item">
    <ion-row class="insight-item-row">
      <ion-col size="6"><ind-form-field [viewData]="getViewModel('owner',insight)"></ind-form-field></ion-col>
          <ion-col size="6"><ind-form-field [viewData]="getViewModel('date',insight)"></ind-form-field></ion-col>
    </ion-row>
    <ion-row class="insight-item-row">
    <ion-col size="6"><ind-form-field  [viewData]="getViewModel('insight-category',insight)"></ind-form-field ></ion-col>
      <ion-col size="6"><ind-form-field [viewData]="getViewModel('specialty',insight)"></ind-form-field></ion-col>
    </ion-row>
    <ion-row class="insight-item-row">
      <ion-col size="6"><ind-form-field  [viewData]="getViewModel('priority',insight)"></ind-form-field ></ion-col>
      <ion-col size="6"><ind-form-field [viewData]="getViewModel('summary',insight)"></ind-form-field></ion-col>
    </ion-row>
    <div class="separationLine"></div>
  </ng-container>
  <ion-item class='empty-item' *ngIf="!(currentMedicalInsights.length > 0)"></ion-item>
</ng-container> -->
<ng-container *ngIf="notesSectionHeaderViewData">
    <ind-section-header [viewData]="notesSectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-text-area id="notesInput" [ngClass]="{'hide-notes': !isCustomerNotesEnabled}" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)" [value]="tempNoteText"
        (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"> </ind-text-area>
    <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
    <io-note *ngFor="let note of currentCustomerNotes" [isNoteAttachmentEnabled]="isCustomerNotesAttachmentEnabled || isCustomerNotesEnabled" [inputNote]="note" [editable]="isNoteEditable(note)" [readOnlyNote]="!isCustomerNotesEnabled" [readOnlyAttachment]="!isCustomerNotesAttachmentEnabled"
        [deletable]="isNoteDeletable(note)" (saveNoteOut)="updateNote($event)" [disableDownloadInOffline]="true" [from]="uiService.page.ContactDetailsComponent" [viewMode]="contactService.contactPageMode"></io-note>
</ng-container>
<ng-container *ngIf="kolStatusSectionHeaderViewData">
  <ind-section-header [viewData]="kolStatusSectionHeaderViewData"></ind-section-header>
  <ng-container *ngFor="let kol of contactKOLStatuses; let i=index" class="kol-item">
    <ion-row class="kol-item-row">
      <ion-col size="6"><ind-form-field  [viewData]="getKOLViewModel('status',kol)"></ind-form-field ></ion-col>
      <ion-col size="6"><ind-form-field [viewData]="getKOLViewModel('date',kol)"></ind-form-field></ion-col>
    </ion-row>
    <ion-row class="kol-item-row">
      <ion-col size="6"><ind-form-field [viewData]="getKOLViewModel('owner',kol)"></ind-form-field></ion-col>
      <ion-col size="6"><ind-form-field [viewData]="getKOLViewModel('disease_area',kol)"></ind-form-field></ion-col>
    </ion-row>
    <ion-row class="kol-item-row">
      <ion-col size="6"><ind-form-field [viewData]="getKOLViewModel('kol_type',kol)"></ind-form-field></ion-col>
    </ion-row>
    <div class="separationLine"></div>
  </ng-container>
  <ion-item class='empty-item' *ngIf="!(contactKOLStatuses.length > 0)"></ion-item>
</ng-container>
<!-- <ion-item-group>
  <ion-item-divider>
    {{ 'OVERVIEW_CAP' | translate}}
    <a href="#" slot="end" class="overViewButton" *ngIf="isEditFeatureEnabled && !contactService.isInGlobalSearch && contactService.contactPageMode===compViewMode.LISTVIEW && masterPageNameCheck && !this.uiService.isConsentFromToolDrawer" (click)="editContact($event)">{{'EDIT' | translate}}</a>
  </ion-item-divider>
  <ion-item *ngIf="contactService.contactInformation?.title?.length>0">
    <ion-label fixed>{{ 'TITLE' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.title"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label fixed>{{ 'CONTACT_FIRST_NAME' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.firstName"></ion-input>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation.middleName && contactService.contactInformation.middleName.length>0">
    <ion-label fixed>{{ 'CONTACT_MIDDLE_NAME' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.middleName"></ion-input>
  </ion-item>
  <ion-item >
    <ion-label fixed >{{ 'CONTACT_LAST_NAME' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.lastName" ></ion-input>
  </ion-item> -->
<!-- <ion-item *ngIf="contactService.contactInformation.getPrimaryAddress?.length>0">
    <ion-label fixed>Primary Address</ion-label>
    <ion-input readonly [value]="contactService.contactInformation.getPrimaryAddress"></ion-input>
  </ion-item> -->
<!-- <ion-item *ngIf="contactService.contactInformation?.getPrimaryEmail?.length>0">
    <ion-label fixed>{{ 'CONTACT_PRIMARY_EMAIL' | translate}}</ion-label> -->
<!-- <ion-input readonly [value]="contactService.contactInformation?.getPrimaryEmail"></ion-input> -->
<!-- <ion-label class = "contact-email-form-input" readonly (click) = "showToolTip($event, [contactService.contactInformation?.getPrimaryEmail])"> {{contactService.contactInformation?.getPrimaryEmail}} </ion-label>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.otherEmails?.length>0">
    <ion-label fixed>{{ 'CONTACT_OTHER_EMAIL' | translate}}</ion-label> -->
<!-- <ion-input readonly [value]="contactService.contactInformation?.otherEmails"></ion-input> -->
<!-- <ion-label class = "contact-email-form-input" (click) = "showToolTip($event, [contactService.contactInformation?.otherEmails])">
      {{contactService.contactInformation?.otherEmails}}</ion-label>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.telephone?.length>0">
    <ion-label fixed>{{ 'CONTACT_BUSINESS_PHONE' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.telephone"></ion-input>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.mobilePhone?.length>0">
    <ion-label fixed>{{ 'CONTACT_MOBILE_PHONE' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.mobilePhone"></ion-input>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.alternatePhoneNumbers?.length>0">
    <ion-label fixed>{{ 'CONTACT_ALTERNATE_NUMBERS' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.alternatePhoneNumbers"></ion-input>
  </ion-item> -->
<!-- <ion-item *ngIf="contactService.contactInformation.fax?.length>0">
    <ion-label fixed>Fax</ion-label>
    <ion-input readonly [value]="contactService.contactInformation.fax"></ion-input>
  </ion-item> -->
<!-- <ion-item *ngIf="contactService.contactInformation?.language?.length>0">
    <ion-label fixed>{{ 'CONTACT_PREFERRED_LANGUAGE' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.language"></ion-input>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.speciality?.length>0">
    <ion-label fixed class="primaryspeciality">{{ 'CONTACT_PRIMARY_SPECIALTY' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.speciality"></ion-input>
  </ion-item>
  <ion-item *ngIf="contactService.contactInformation?.professionalDesignation?.designation?.length > 0">
    <ion-label fixed>{{ 'CONTACT_PROFESSIONAL_DESIGNATION' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.professionalDesignation.designation"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label fixed>{{ 'SPEAKER' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.isSpeaker"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label fixed>{{ 'CONTACT_KOL_CAP' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.isKOL"></ion-input>
  </ion-item>
  <ion-item *ngIf="isMedProfileEnabled">
    <ion-label class="primaryspeciality" fixed>{{ 'CONTACT_GRANT_RECIPIENT' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.ind_grantrecipient ? 'Yes' : 'No'"></ion-input>
  </ion-item>
  <ion-item *ngIf="isMedProfileEnabled">
    <ion-label fixed>{{ 'CONTACT_INVESTIGATOR' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.ind_investigator ? 'Yes' : 'No'"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label fixed>{{ 'VERIFICATION' | translate}}</ion-label>
    <ion-input readonly [value]="contactService.contactInformation?.verificationStatus"></ion-input>
  </ion-item> -->


<!--Addresses-->
<!-- <ion-item-divider color="light">{{ 'ADDRESSES_CAP' | translate}}</ion-item-divider>
  <ion-item class='empty-item' *ngIf="!(contactService.contactInformation?.addressesList?.length > 0)"></ion-item> -->
<!-- <ion-item *ngFor="let address of contactService.contactInformation?.addressesList; let i=index">
    <div col-10 >
      <span *ngIf="address.isPrimary" class="indGrey-color">{{'ACCOUNT_PRIMARY_CONTACT_NAME' | translate}}</span>
      <span *ngIf="!address.isPrimary" class="indGrey-color"> {{ 'ALTERNATE_ADDRESS' | translate}}</span>
      <p text-wrap class="indGrey-color">{{address.compositeAdd}}</p>
      <p text-wrap *ngIf="address.postOfficeBox.length>0" class="indGrey-color"><span ion-text>{{ 'CONTACT_POST_BOX' | translate}} </span>{{address.postOfficeBox}}</p>
      <p text-wrap *ngIf="address.primaryContact.length>0" class="indGrey-color"><span  ion-text>{{ 'CONTACT_PRIMARY_CONTACT' | translate}} </span>{{address.primaryContact}}</p>
      <p text-wrap *ngIf="address.telephone1.length>0 || address.telephone2.length>0" class="indGrey-color"><span>P: </span><span>{{address.telephone1}}  {{address.telephone2}}</span></p>
      <p text-wrap *ngIf="address.fax.length>0 || address.fax2.length>0" class="indGrey-color"><span>F: </span><span>{{address.fax}}  {{address.fax2}}</span></p>
    </div>
    <div col-2 slot="end">
      <img class="map_image" src="assets/imgs/maps.png" (click)="openMap(address.compositeAdd)" />
    </div>
  </ion-item> -->
<!-- <div *ngFor="let address of addressesViewData">
    <div class="accordion-header">
      <main-card [viewData]="address.view">
        <div class='main-card-secondary-right'>
          <ng-container *ngIf="address.view.customSecondaryTextRight">
            <span class="ion-text-center blue-text" (click)="openLocation(address)">
              {{address.view.customSecondaryTextRight}}
            </span>
          </ng-container>
        </div>
      </main-card>
    </div>
    <div class="contact-accordian-expanded" *ngIf="address.isExpanded">
      <ind-form-field *ngFor="let field of address.childItems" [viewData]="field"></ind-form-field>
    </div>
  </div> -->

<!--Accounts-->
<!-- <ion-item-divider color="light">{{ 'ACCOUNTS_CAP' | translate}}</ion-item-divider>
  <ion-item class='empty-item' *ngIf="!(contactService.contactInformation?.accountRelationships?.length > 0)"></ion-item> -->
<!-- <ion-item *ngFor="let account of contactService.contactInformation.accountRelationships" [ngClass]="(account.showDetails)?'expandable-item':''">
    <div ion-row>
      <span col no-padding-left text-wrap>{{account.accountName}}</span>
      <ion-icon text-right col [name]="account['showDetails']? 'arrow-up': 'arrow-down'" (click)="expandList(account);"></ion-icon>
    </div>
    <expandable #expWrapper [contactaccountrelation]="account" [viewType]="'contactAccountInfo'" [expanded]="account.showDetails"></expandable>
  </ion-item> -->
<!-- <div *ngFor="let account of accountsViewData">
    <div class="accordion-header">
      <main-card [viewData]="account.view"></main-card>
    </div>
    <div class="contact-accordian-expanded" *ngIf="account.isExpanded">
      <ind-form-field *ngFor="let field of account.childItems" [viewData]="field"></ind-form-field>
    </div>
  </div> -->

<!--Relationship-->
<!-- <ion-item-divider color="light">{{ 'RELATIONSHIPS_CAP' | translate}}</ion-item-divider>
  <ion-item class='empty-item' *ngIf="!(contactService.contactInformation?.contactRelationships?.length > 0)"></ion-item>
  <div *ngFor="let relation of relationshipViewData">
    <div class="accordion-header">
      <main-card [viewData]="relation.view"></main-card>
    </div>
    <div class="contact-accordian-expanded" *ngIf="relation.isExpanded">
      <ind-form-field *ngFor="let field of relation.childItems" [viewData]="field"></ind-form-field>
    </div>
  </div> -->
<!-- <ion-item *ngFor="let relation of contactService.contactInformation.contactRelationships" [ngClass]="(relation.showDetails)?'expandable-item':''">
    <div ion-row>
      <span col no-padding-left text-wrap>{{relation.contactName}}</span>
      <ion-icon text-right col-1 name="chevron-down" (click)="expandList(relation);"></ion-icon>
    </div>
    <expandable #expWrapper [contactContactRelation] = "relation" [viewType]="'contactRelationInfo'" [expanded]="relation.showDetails"></expandable>
  </ion-item> -->

<!--Coverage-->
<!-- <ion-item-divider color="light">{{ 'COVERAGE_TEAM_CAP' | translate}}</ion-item-divider>
  <ion-item class='empty-item' *ngIf="!(contactService.contactInformation?.customerPositions.length > 0)"></ion-item>
  <ion-item *ngFor="let position of contactService.contactInformation.customerPositions" (click)="expandList(position)">
    <ion-label>{{position.position}}</ion-label> -->
<!-- <ion-label fixed>Position</ion-label> -->
<!-- <ion-input readonly [value]="position.position"></ion-input> -->
<!-- </ion-item> -->

<!-- Additional Info (Configure fields)-->
<!-- <ion-item-divider *ngIf="contactService.contactInformation.configuredFields.length > 0" color="light">{{ 'ADDITIONAL_INFO_CAP' | translate}}</ion-item-divider>
  <ion-item  *ngFor="let field of contactService.contactInformation.configuredFields">
    <ion-label>{{field.fieldName}}</ion-label>
    <div item-content class="light-color">{{field.fieldValue}}</div>
  </ion-item>
</ion-item-group> -->
