<ion-toolbar class="safe-padding-top" [ngClass]="{
  'toolbar-android': isAndroid}">
  <img class="logo" src="assets/imgs/exeevo.svg">
  <ion-buttons slot="end">
    <ion-button *ngIf="hasBaselineEnabled">
      <img src="assets/imgs/ios_add_3x.svg" (click)="onButtonClick('addIcon')">
    </ion-button>
    <ion-button>
      <img src="assets/imgs/notification.svg" (click)="onButtonClick('notification')">
    </ion-button>
    <ion-button class="user">
      <p class="user" (click)="onButtonClick('user')">{{userNameInitial}}</p>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ng-container *ngIf="backgroundUploadInProgress || syncInProgress || localDbDataLoadInProgress">
  <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
  <div class="in-progress">
    <ion-spinner></ion-spinner>
    <span class="secondary-text">{{ syncMessage }}</span>
  </div>
</ng-container>