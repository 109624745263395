<ion-header>
  <ion-toolbar class="left-header-toolbar" [ngClass]="{ 'toolbar-android': isAndroid, 'impersontated-view': authenticationOfflineService.impersonatedUser }">
    <ion-buttons class="start-slot" slot="start">
      <ion-menu-toggle *ngIf="!authenticationOfflineService.impersonatedUser">
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" [ngClass]="{'whiteFont':activityService.teamViewActive}" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
      </ion-menu-toggle>
    </ion-buttons>

    <div class="searchbar-wrapper">
        <ion-searchbar [(ngModel)]="searchText" (ionInput)="onInput($event)" [debounce]="300" placeholder="{{'HOME' | translate}}"></ion-searchbar>
    </div>
  </ion-toolbar>

  <ng-container *ngIf="backgroundUploadInProgress || syncInProgress || localDbDataLoadInProgress">
    <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
    <div class="in-progress">
      <ion-spinner></ion-spinner>
      <span class="secondary-text">{{ syncMessage }}</span>
    </div>
  </ng-container>

  <ion-toolbar class="container-c">
    <ion-button fill="clear" size="small" (click)="onSelectedTabChange('notifications')" class="notification-btn">
      <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
      <ion-icon slot="icon-only" src="assets/imgs/notifications-inactive.svg"  class="notification-bell-icon"></ion-icon>
    </ion-button>
    <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
  </ion-toolbar>
  <ind-section-header *ngIf="!searchText else searchHeader;" [viewData]="sectionHeaderModel"></ind-section-header>
  <ng-template #searchHeader>
    <ind-section-header [viewData]="searchSectionHeaderModel"></ind-section-header>
  </ng-template>
</ion-header>

<ion-content class="main-container" [ngClass]="{ 'vectorimageleft': searchText == '' && (!measureCards || measureCards?.length === 0) }">
  <ng-container *ngIf="!searchText else searchResult;">
    <ion-list *ngIf="measureCards?.length > 0 else noData;" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <ng-container *ngFor="let measureCard of measureCards" >
        <main-card [viewData]="measureCard"></main-card>
      </ng-container>
    </ion-list>

    <ng-template #noData>
      <div class="no-data-message">
        {{'NO_MEASURES' | translate}}
      </div>
    </ng-template>
  </ng-container>

  <ng-template #searchResult>
    <ion-list *ngIf="searchedMeasureCards?.length > 0 else noSearchResult;" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <ng-container *ngFor="let measureCard of searchedMeasureCards" >
        <main-card [viewData]="measureCard"></main-card>
      </ng-container>
    </ion-list>

    <ng-template #noSearchResult>
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </ng-template>
  </ng-template>

</ion-content>
