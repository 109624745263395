<!-- <div class="carousel-label">
    <ion-select interface="popover" [(ngModel)]="selectedView" (ionChange)="changeView($event.detail,$event)">
        <ion-select-option *ngFor="let selectedView of briefcase" [value]="selectedView"> {{selectedView.name}} </ion-select-option>
    </ion-select>
</div>
<div class="swiper-container-presentation">
    <div class="swiper-wrapper">
        <div class="swiper-slide" (click)="setPageUrl(presPage)" *ngFor="let presPage of presPages">
            <img [src]="presPage.thumbnail"> -->
<!-- <div>{{presPage.name}}</div> -->
<!-- </div>
    </div> -->
<!-- Add Pagination -->
<!-- <div class="swiper-pagination"></div>    -->

<!-- Navigation Buttons -->
<!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
</div>  -->

<div class="carousel-label">
    <ion-grid>
        <ion-row no-padding>
          <ion-col size="6" class="ion-text-left">
            <div *ngIf="presentationService.contentMode === contentMode.PRESENTATION" no-padding class="filter-row d-flex align-items-center">
                <ion-select interface="popover" [interfaceOptions]="{ cssClass: 'presentation-popover'}" class="select-text-width" [(ngModel)]="key" (ionChange)="filterPages(key)">
                    <ion-select-option *ngFor="let key of keyMessages" [value]="key"> {{key.keyMessageName | translate}} </ion-select-option>
                </ion-select>
            </div>
            </ion-col>
          <ion-col  size="6" class="ion-text-right">
            <div *ngIf="showGallery">
              <ion-button class="gallery-btn" fill="clear" [disabled]="deviceService.isOffline" (click)="onButtonClick.emit()">
                <span class="section-header-button-text">
                    {{'GALLERY' | translate}}
                </span>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
    </ion-grid>
</div>
<div #carousel class="slides-container">
    <!-- <ion-slides #Slides [options]="{slidesPerView: slidesPerView}" class="swiper-wrapper" (ionSlideDrag)=removeSwiperLock()>
        <ion-slide class="carousel-slide" (click)="setPageUrl(presPage)" *ngFor="let presPage of presPages">
            <img id="thumbnail{{presPage.id}}" io-thumbnail [ngClass]="{'active': presPage === activePage || totalPages == 1, 'thumb-without-background': presPage.isLoaded, 'thumb': !presPage.isLoaded}" [src]="thumbURL(presPage)" (load)="imgLoadCheck($event, presPage)">
            <div class="text-bottom">{{presPage?.pageSequence}}){{presPage?.name}}</div>
        </ion-slide>
    </ion-slides> -->

  <swiper-container #Slides [slidesPerView]="slidesPerView" [loop]="false" class="swiper-wrapper">
    <swiper-slide *ngFor="let presPage of presPages; let i = index" (click)="setPageUrl(presPage, i)" class="carousel-slide">
      <img id="thumbnail{{presPage.id}}" io-thumbnail alt="slide thumbnail"
        [ngClass]="{'active': presPage === activePage || totalPages == 1, 'thumb-without-background': presPage.isLoaded, 'thumb': !presPage.isLoaded}"
        [src]="thumbURL(presPage)" (load)="imgLoadCheck($event, presPage)">
      <div class="text-bottom">{{presPage?.pageSequence}}){{presPage?.name}}</div>
    </swiper-slide>
  </swiper-container>
</div>
