import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { DeviceService } from "../../../services/device/device.service";
import { NotificationService } from "../../../services/notification/notification.service";
import { IONote } from "../../../classes/io/io-note.class";
import {DateTimeFormatsService} from "../../../services/date-time-formats/date-time-formats.service";
import { toBase64, MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX } from '@omni/utility/util';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { TranslateService } from '@ngx-translate/core';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { DatePipe } from '@angular/common';
import { PageName } from '@omni/services/navigation/navigation.service';
import _ from 'lodash';

@Component({
    selector:'io-note',
    templateUrl:'io-note.html',
  styleUrls:['io-note.scss']
  })
  export class EditableNote {

    public readMode:boolean = true;
    public inputText:string = '';
    public attachmentFile:any;
    public attachmentTitle:string = '';
    public isAttachmentAdded:boolean = false;
    public isAttachmentRemoved:boolean = false;
    public base64str;
    public disableDownload;
    public isSaveDisabled:boolean = true;
    @ViewChild('attachInputNote') attachInputNote: ElementRef;

    @Output () saveNoteOut  = new EventEmitter();
    @Input () inputNote:IONote;
    @Input () editable:boolean;
    @Input () deletable:boolean;
    @Input () isNoteAttachmentEnabled:boolean;
    @Input () readOnlyNote:boolean;
    @Input () readOnlyAttachment:boolean;
    @Input () disableDownloadInOffline:boolean
    @Input () from: PageName;
    @Input () viewMode: ComponentViewMode;



    constructor(
        public deviceService: DeviceService,
        private notificationService: NotificationService,
        public dateTimeFormatsService: DateTimeFormatsService,
        private fileService: IoFileService,
        private translate: TranslateService,
        private uiService: UIService,
        private datePipe: DatePipe,
        private cdref: ChangeDetectorRef
    ) {

    }

    ngOnInit(){
      this.disableDownload = this.deviceService.isOffline && this.disableDownloadInOffline;
      if (this.viewMode && this.viewMode == ComponentViewMode.PREVIEW || this.viewMode == ComponentViewMode.READONLY) {
        this.disableDownload = true;
      }
    }

    ngDoCheck() {
      if (this.deviceService.isOffline && this.disableDownloadInOffline && !this.disableDownload) {
        this.disableDownload = true;
      }
    }

  public getFormattedDateTime(value: Date): string {
    if (value) {
      // return format(value, this.dateTimeFormatsService.dateTimeToUpper);
      let noteDay = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)

      let noteTime = (new Date(value)).toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      return noteDay + " " + noteTime;
    } else {
      return '';
    }
  }

    public toggleEditMode(value:boolean):void{
        if(value){
            this.inputText = this.inputNote.noteText?this.inputNote.noteText.slice():'';
            this._updateIsSaveButton();
        }else{
            this.inputText = '';
            if(this.isNoteAttachmentEnabled){
              this.isAttachmentRemoved = false;
              this.removeAttachment(false);
            }
        }
        this.readMode = !value;
        this._detectChanges();
    }

    public deleteNote():void {
        this.saveNoteOut.emit({
            action: 'DELETE',
            noteId: this.inputNote.noteId,
        });
        this._detectChanges();
    }

    public onInput(ev){
        if(ev && ev.target){
            this.inputText = ev.target.value;
        }else{
            this.inputText = '';
        }
        this._updateIsSaveButton();
        this._detectChanges();
    }

    private _updateIsSaveButton(){
      if(this.inputText != this.inputNote?.noteText?.slice()){
        if(this.inputText){
          this.isSaveDisabled = false;
        }else if(this.isAttachmentAdded || this.inputNote.hasDocument){
          if(this.isAttachmentRemoved){
            this.isSaveDisabled = true;
          }else{
            this.isSaveDisabled = false;
          }
        }else {
          this.isSaveDisabled = true;
        }
      }else {
        if(this.inputText){
          if(this.isAttachmentAdded || this.isAttachmentRemoved){
            this.isSaveDisabled = false;
          }else {
            this.isSaveDisabled = true;
          }
        }else {
          if(this.isAttachmentAdded){
            this.isSaveDisabled = false;
          }else{
            this.isSaveDisabled = true;
          }
        }
      }
    }

    public confirmChanges():void {
      if (this.isSaveDisabled) return;
      let res = {
        action: 'SAVE',
        noteId: this.inputNote.noteId,
        updatedText: this.inputText,
      }
      if(this.isNoteAttachmentEnabled){
        if(this.attachmentFile){
          res['attachmentFileUpdated'] = true;
          res['attachmentFileDataUrl'] = this.base64str;
          res['documentMimeType'] = this.attachmentFile.type;
          res['documentName'] = this.attachmentFile.name;
          res['documentSize'] = this.attachmentFile.size;
        }else if(this.isAttachmentRemoved){
          res['isAttachmentRemoved'] = true;
        }
      }
      this.saveNoteOut.emit(res);
      this.inputText = '';
      if(this.isNoteAttachmentEnabled){
        this.removeAttachment(false);
        this.isAttachmentRemoved = false;
      }
      this.readMode = true;
    }

    public async  loadImageFromDevice(event){
      if(event && event.target && event.target.files){
        try {
          if((event.target.files[0].size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE){
            if(NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)){
              this.attachmentFile = event.target.files[0];
              this.attachmentTitle = event.target.files[0].name;
              this.isAttachmentAdded = true;
              this.base64str = await toBase64(this.attachmentFile);
              const dataURLPattern = /^data:[^;]+;base64,/;
              this.base64str = this.base64str.replace(dataURLPattern, '');
            }else{
              this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Note Attachment','top','info');
              this.removeAttachment(false);
            }
          }else{
            this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Note Attachment','top','info');
            this.removeAttachment(false);
          }
          this._updateIsSaveButton();
          this._detectChanges();
        } catch (error) {
          console.log(error);
        }
      }
    }

    public handleNoteAttachment(){
      if(this.isNoteAttachmentEnabled){
        try {
          this.attachInputNote.nativeElement.click();
        } catch (error) {
          console.log(error);
        }
      }
    }

    public removeAttachment(flag){
      try {
        this.attachInputNote.nativeElement.value = null;
      } catch (error) {
        console.log(error);
      }
      if(flag){
        this.isAttachmentRemoved = flag;
      }
      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this._updateIsSaveButton();
      this._detectChanges();
    }

  public downloadAttachment() {
    if (this.deviceService.isOffline) {
      this.notificationService.notify(this.translate.instant('ATTACHMENT_CAN_BE_DOWNLOADED_ONLY_WHEN_ONLINE_MSG'), 'Notes Attachment Down', 'top', 'info');
      return;
    }

    switch (this.from) {
      case PageName.EventNotesComponent:
        this.downloadDocumentBody();
        break;

      default:
        this.defaultDownloadAttachment();
        break;
    }
  }

  private async defaultDownloadAttachment() {
    if (this.isNoteAttachmentEnabled && this.inputNote.documentName) {
      if (this.inputNote.documentDataURL) { // where we have the document body we don't really need to get teh base64 encoded data from Server for example opportunity.
        try {
          if(this.inputNote.documentDataURL.indexOf('data:')!==0 && this.inputNote.documentMimeType){
            const prefix = 'data:'+this.inputNote.documentMimeType + ';base64,';
            this.inputNote.documentDataURL = prefix + this.inputNote.documentDataURL;
          }
          if (this.deviceService.isNativeApp && !this.deviceService.deviceFlags.electron) {
            this.fileService.downloadeBase64DataFileAndOpenInNativeApp(this.inputNote.documentName, this.inputNote.documentDataURL, this.inputNote.documentMimeType);
          } else {
            const linkSource = this.inputNote.documentDataURL;
            const downloadLink = document.createElement("a");
            const fileName = this.inputNote.documentName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            downloadLink.remove();
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // In case if we do not have the body we nee dto fetch the body from Dynamics and map it to the note or teh input note for example follow up notes, account notes etc.
        this.downloadDocumentBody();
      }
    }
  }
  private async downloadDocumentBody() {
    try {
      await this.uiService.displayLoader();
      let response = await this.fileService.getDocumentBody(this.inputNote.noteId);
      if (response['documentbody']) {
        if(response['documentbody'].indexOf('data:')!==0 && this.inputNote.documentMimeType){
          const prefix = 'data:'+this.inputNote.documentMimeType + ';base64,';
          response['documentbody'] = prefix + response['documentbody'];
        }
        if (this.deviceService.isNativeApp && !this.deviceService.deviceFlags.electron) {
          this.fileService.downloadeBase64DataFileAndOpenInNativeApp(this.inputNote.documentName, response['documentbody'], this.inputNote.documentMimeType);
        } else {
          const linkSource = response['documentbody'];
          const downloadLink = document.createElement("a");
          const fileName = this.inputNote.documentName;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          downloadLink.remove();
        }
      }
    } catch (error) {
      console.error('downloadDocumentBody: ', error);
    } finally {
      await this.uiService.dismissLoader();
    }
  }

    // public get isSaveDisabled() {
    //   return !( this.inputNote.noteText.slice() != this.inputText || this.isAttachmentAdded || (this.isAttachmentRemoved && this.inputText)) && (!this.inputText && !this.isAttachmentAdded && !this.inputNote.hasDocument);
    // }

    private _detectChanges() {
      this.cdref.detectChanges();
      this.cdref.markForCheck();
    }

  }
