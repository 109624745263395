import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {IndFormFieldViewDataModel} from '@omni/models/indFormFieldDataModel';
import _ from 'lodash';


@Component({
  selector: 'alert-with-input',
  templateUrl: 'alert-with-input.html',
  styleUrls: ['alert-with-input.scss'],
})
export class AlertWithInput implements OnInit {
  @Input() inputs: any;
  @Input() header: string;
  @Input() message: string
  @Input() Placeholder: string
  @Input() cancelText;
  @Input() confirmText;
  @Input() isMandatory: boolean;
  selectedItem: any;
  @Input() selectedOtherItem: string;
  @Input() enableMultiSelect:boolean = false;
  @Input() customFormField: IndFormFieldViewDataModel;
  public enableOtherItemTextBox:boolean = false;

  constructor(
    private popoverCtrl: PopoverController,
  ) {


  }

  onSelectOption(ev) {
    this.selectedItem = ev;
    if (this.selectedItem != 'Others') {
      this.selectedOtherItem = null;
    }
  }

  onMultiSelectOption(ev){
    if(ev['isSelected']){
      ev['isSelected'] = !ev['isSelected']
    }else{
      ev['isSelected'] = true;
    }
    let foundev = this.inputs.find(a=> a['isSelected'] == true);
    if(foundev){
      this.onSelectOption(ev);
    }else{
      this.selectedItem = null;
    }
    if(this.inputs.find(a=> a.name == "Others" && a.isSelected == true)){
      this.enableOtherItemTextBox = true;
    }else{
      this.enableOtherItemTextBox = false;
    }
  }

  onDismiss(flag) {
    //To check if configurable list (dropdown) is not present i.e. text is present
    if(this.inputs.length === 0)
      if (flag && this.isMandatory && _.isEmpty(this.selectedOtherItem)) return;

    if (!flag) {
      this.popoverCtrl.dismiss();
      return;
    }

    let payload = {
      inputs: this.selectedItem,
      selectedOtherItem: this.selectedOtherItem ? this.selectedOtherItem : null,
      role: 'ok'
    }
    if(this.enableMultiSelect){
      payload['selectedInputs'] = this.inputs.filter(a=> a['isSelected']);
    }

    this.popoverCtrl.dismiss(payload);

  }


  ngOnInit() { }

}
