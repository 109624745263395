import { Activity } from '@omni/classes/activity/activity.class';
import { EventsToolService } from './../../services/events-tool/events-tool.service';
import { takeUntil, takeWhile, debounceTime } from 'rxjs/operators';
import { EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { EntityNames } from '@omni/data-services/delta/delta.service';
import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener
} from "@angular/core";
import {
  MenuController,
  PopoverController,
  AlertController,
  IonNav,
} from "@ionic/angular";
import { AuthenticationDataService } from "../../data-services/authentication/authentication.service";
import { LoadingController } from "@ionic/angular";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivityService } from "../../services/activity/activity.service";
import { RepServices } from "../../data-services/rep/rep.services";
import { DiskService } from "../../services/disk/disk.service";
import { MenuOptionModel } from "../../models/menu-option-model";
import { VirtualFileService } from "../../data-services/virtual-file-server/virtual-file-service";
import { ContactPageComponent } from "../../components/contact/contact-page/contact-page";
import { PresentationPageComponent } from "../presentation/presentation";
import { UIService, PresentationView } from "../../services/ui/ui.service";
import { LogService } from "../../services/logging/log-service";
import { MasterDataService } from "../../data-services/master/master.data.service";
import { ActivityType } from "../../classes/activity/activity.class";
import {
  FooterViews,
  FooterService
} from "../../services/footer/footer.service";
//idle and keep alive implementation
import { timer, Observable, Subject, interval as RxInterval, throwError } from "rxjs";
import { WebsocketDataService } from "../../data-services/websocket/websocket.data.service";
import {
  DeviceService,
  DEVICE_EVENT
} from "../../services/device/device.service";
import { PresentationService } from "../../services/presentation/presentation.service";
import { ActivitiesPageComponent } from "../activities-page/activities-page";
import {
  NavigationService,
  PageName
} from "../../services/navigation/navigation.service";
import { MyAssistantService, NOTIFICATION } from "../../services/my-assistant/my-assistant.service";
import { format, isPast, isBefore, subDays } from "date-fns";
import { EventsService } from "../../services/events/events.service";

import { REP_STATUS } from "../../models/rep-status-model";
import { TrackService, TrackingEventNames } from "../../services/logging/tracking.service";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
// import { MapsAPILoader } from "@agm/core";
import { Utility } from "../../utility/util";
import { DB_SYNC_STATE_KEYS } from "../../config/pouch-db.config";
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { LaunchDarklyProvider } from '../../providers/launch-darkly/launch-darkly';
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DeltaService } from "../../data-services/delta/delta.service";
import { GlobalUtilityService } from "../../services/global-utility.service";
import { SampleService, SamplingDetailsViewMode } from "../../services/sample/sample.service";
import { GeneeNotificationPopoverComponent } from "../../components/genee-notification-popover/genee-notification-popover";
import { TranslateService } from "@ngx-translate/core";
import { CaseManagementService } from "../../services/case-management/case-management.service";
import { AccountOfflineService } from "../../services/account/account.offline.service";
import { ContactOfflineService } from "../../services/contact/contact.service";
import { DateTimeFormatsService } from "../../services/date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from "../../services/notification/notification.service";
import { AlertService } from "../../services/alert/alert.service";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { OfflineSyncUtility } from '../../utility/offline-sync.utility';
import { sha256 } from 'js-sha256'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import _ from "lodash";
import { IndNotificationDataModel } from "@omni/models/indNotificationDataModel";
import { AgendaPlanListService } from '@omni/services/agenda-plan-list/agenda-plan-list.service';
import { checkTimeConflictWithOtherActivities, startRecursiveActivityScheduleConflictFlagging, startRecursiveEventScheduleConflictFlagging, checkProximityWarningAgainstActivities } from '../../utility/activity.utility';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { VoiceCallService } from '@omni/services/voice-call/voice-call.sertvice';
import { LoginService } from '@omni/services/msal/login.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { LocationOfflineService } from '@omni/services/location/location.service';
import { DEFAULT_AUTO_DELTA_SYNC_TIME_IN_HOURS } from '@omni/config/shared.config';

export const BACKGROUND_UPLOAD_DELAY = 60000;
export const BACKGROUND_UPLOAD_WAIT_TIME = 3600000;
@Component({
  selector: "page-home",
  templateUrl: "home.html",
  styleUrls: ['home.scss']
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  public updateCalled: boolean = false;
  /**
   * Keep track the screen width change
   */
  @HostListener("window:resize", ["$event"])
  onresize(event) {
    this.device.updateScreenWidth(window.innerWidth);
    this.device.updateScreenHeight(window.innerHeight);
  }

  rootPage: any = ActivitiesPageComponent;
  private loadFromCache: boolean = false;
  idleState = "Not started.";
  timedOut: boolean = false;
  lastPing: Date = null;
  private _offlineSessionTimeoutStatusSubscription: any;
  private _tokenRefreshSubscription: any;
  private _sessionTimeoutToast: any;
  private syncNotificationModel: IndNotificationDataModel;
  private offlineGivenDay :any;
  private offlineMessage :any;
  private offlineHourInMilliseconds :any;
  private interval :any;
  private timeintervalExecuteOnce :any;

  private isAutoDeltaSyncDisabled = false;

  private alive: boolean = false;
  private pingInterval: number = 60000 * 60;
  private _isSyncFailed: boolean = false;
  private backgroundUploadTimerId: any = undefined;
  private _deviceConnectionChangeHandler: ({ isFluctuation }) => void = ({ isFluctuation }) => {

    // if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent && !isFluctuation) {
    //   this.showSyncPopup();
    // }
  }

  @ViewChild("activities", { static: true }) private homeNavCtrl: HTMLIonNavElement;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private offlineSync: OfflineSyncUtility,
    public device: DeviceService,
    private activityOfflineService: ActivityService,
    private disk: DiskService,
    public menu: MenuController,
    public popoverCtrl: PopoverController,
    public loadingCtrl: LoadingController,
    public virtualFileServerService: VirtualFileService,
    public navCtrl: IonNav,
    private presentationService: PresentationService,
    public uiService: UIService,
    private logService: LogService,
    private masterData: MasterDataService,
    private footerService: FooterService,
    private events: EventsService,
    private authService: AuthenticationService,
    private authDataService: AuthenticationDataService,
    private loginService: LoginService,
    private websocket: WebsocketDataService,
    private _cd: ChangeDetectorRef,
    private alertCtrl: AlertController,
    public navService: NavigationService,
    public myAssistantService: MyAssistantService,
    private repService: RepServices,
    private trackingService: TrackService,
    public geoLocation: Geolocation,
    // private mapsApiLoader: MapsAPILoader,
    private launchDarkly: LaunchDarklyProvider,
    public sampleDataService: SampleDataService,
    private deltaService: DeltaService,
    private utilityService: GlobalUtilityService,
    private sampleService: SampleService,
    private translate: TranslateService,
    private caseManagementService: CaseManagementService,
    private accountOfflineService: AccountOfflineService,
    private contactService: ContactOfflineService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private statusBar: StatusBar,
    private todoService: AgendaPlanListService,
    private eventsToolService: EventsToolService,
    private languageService: LocalizationService,
    private voiceService: VoiceCallService,
    private faService: FeatureActionsService,
    private locationService: LocationOfflineService,
  ) {
    this.uiService.activeView = "Appointment";
    this.menu.enable(true);
    //this.websocket.reconnect(); //testing reconnect_delay revert if a regression bug is logged
    if (
      this.authService.hasFeatureAction(FeatureActionsMap.BASELINE)
    ) {
      console.log("baseline enabled");
    }
    this.translate.onLangChange.subscribe(data => {
      // this.translate.use(data.lang);
    });
    // this.platform.registerBackButtonAction(() => {
    //   console.log("Attempted to hit back button, rejected");
    // });

    this.events.observe("detectLanguageChange")
    .subscribe((localeID) => {
      this.languageService.updateLanguage(localeID);
    });

    this.trackingService.initialized = false;
    //if(!this.device.isOnAndroidDevice) {
    if (
      this.authService.userConfig &&
      this.authService.userConfig.activeInstance &&
      this.authService.userConfig.activeInstance
        .appInsightsInstrumentationKey
        //&& this.appInsightsService.config
    ) {
      // this.appInsightsService.config.instrumentationKey = this.authService.userConfig.activeInstance.appInsightsInstrumentationKey;
      // this.appInsightsService.config.disableAjaxTracking = true;
      // this.appInsightsService.config.overrideTrackPageMetrics = true;
      // this.appInsightsService.init();
      // this.appInsightsService.queue.push(function () {
      //   this.appInsightsService.context.addTelemetryInitializer(function () {
      //     this.appInsightsService.context.application.ver = "1.5.0";
      //   });
      // });

      // this.appInsightsService.setAuthenticatedUserContext(
      //   sha256(this.authService.user.systemUserID)
      // );
      // this.trackingService.initialized = true;

      this.trackingService.appInsightsInstance = new ApplicationInsights({ config: {
        instrumentationKey : this.authService.userConfig.activeInstance.appInsightsInstrumentationKey,
        disableAjaxTracking: true,
      } });
      this.trackingService.appInsightsInstance.loadAppInsights();
      this.trackingService.appInsightsInstance.setAuthenticatedUserContext(sha256(this.authService.user.systemUserID));
      this.trackingService.initialized = true;
    } else {
      console.error("home: appInsightsInstrumentationKey is missing");
    }
    //}
  }

  ionViewDidEnter() {
    console.log('Page is about to be entered or reopened. did enter fun');
    this.offlineNotificationAfterClear();
    if (this.device.deviceFlags.ios && this.device.runningDevice.version === "13.1") {
      this.events.publish("scrollToDate");
    }
    this.voiceService.initClinkToolBar();
  }


  onGeoLocationSuccess(pos) {
    //console.log("navigation coordinates", pos, this.mapsApiLoader);
    try {
      // this.mapsApiLoader
      //   .load()
      //   .then(() => {
          let latlng = { lat: pos.coords.latitude, lng: pos.coords.longitude };
          let map = new google.maps.Geocoder();
          console.log("found map", map);
          map.geocode(
            { location: latlng },
            (
              results: google.maps.GeocoderResult[]) => {
              console.log(results);
              if (results) {
                results.map(o => {
                  if (o.types.indexOf("country") > -1) {
                    this.trackingService.countryName = o.formatted_address;
                  }
                  if (o.types.indexOf("administrative_area_level_1") > -1) {
                    this.trackingService.stateName =
                      o.address_components[0].long_name;
                  }
                  if (o.types.indexOf("locality") > -1) {
                    this.trackingService.cityName =
                      o.address_components[0].long_name;
                  }
                });
              }
              this.trackingService.tracking("LoginSucessful", TrackingEventNames.LOGIN);
            }
          );
        // })
        // .catch(error => {
        //   console.log(error);
        // });
    } catch (ex) {
      console.log(ex);
    }
  }
  onGeoLocationError(error) {
    console.log("geo location error", error);
    if (
      error.message == "User denied Geolocation" ||
      error.message == "Illegal Access"
    ) {
      this.trackingService.countryName = "User denied permission";
      this.trackingService.stateName = "User denied permission";
      this.trackingService.cityName = "User denied permission";
    }
    this.trackingService.tracking('LoginSuccessful', TrackingEventNames.LOGIN);
  }

  ionViewCanEnter() {
    try {
    //Setup launch darkly
    if (!this.device._isDeviceOfflineFromLogin) {
    this.launchDarkly.refreshUser({
    "key": this.authService.user.userID
        // "sysuser": this.authService.user.systemUserID,
        // "xsysuser": this.authService.user.xSystemUserID,
      });
    }

    if (this.activityOfflineService.selectedActivity) {
      let activity = this.activityOfflineService.selectedActivity;
      switch (activity.type) {
        case ActivityType.Appointment:
          this.uiService.showNewActivity = false;
          this.uiService.activeView = "Appointment";
          if (this.uiService.activeView === "Appointment") {
            if (this.sampleService.samplingDetailsViewMode != SamplingDetailsViewMode.CREATE_FROM_MEETING) {
              this.footerService.initButtons(FooterViews.Activities);
            }
          }
          break;
        case ActivityType.Email:
          this.uiService.activeView = "Email";
          if (this.uiService.activeView === "Email")
            this.footerService.initButtons(FooterViews.EmailDetails);
          break;
        case ActivityType.FollowUp:
          if (this.uiService.activeView == 'ActivitiesPageRightPaneNav') {
            this.footerService.initButtons(FooterViews.FollowUpDetails);
          }
          break;
        case ActivityType.Sample:
          break;
        case ActivityType.PhoneCall:
          this.uiService.showNewActivity = false;
          if (this.uiService.activeView === "PhoneCall") {
            this.footerService.initButtons(FooterViews.PHONE_CALL);
          }
          break;
        default:
          this.footerService.initButtons("");
      }

    
      }
    } catch (error) {
      console.log('HomePage:ionViewCanEnter', error);
      
    }
  }
  private async fetchUserCurrentLocation() {
    if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
      this.authService.user.currentLocation = await this.locationService.getCurrentLocationName('home');
    } else this.authService.user.currentLocation = null;
  }
  async ngOnInit() {

    if (!this.device.deviceFlags.electron && this.device.isNativeApp && this.device.deviceFlags.ios) {
      this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#00000000');
      this.events.subscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
    }
    if (!this.device.deviceFlags.electron && this.device.isNativeApp && this.device.deviceFlags.android) {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#204B8F');
      this.events.subscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
    }
    if (
      this.authService.hasFeatureAction(FeatureActionsMap.ERROR_REPORTING_REPLAY)
    ) {
      // LogRocket.init("28jvr0/indegene-omnipresence", {
      //   network: { isEnabled: false }
      // });

      //Identify for LogRocket
      //  LogRocket.identify(this.authService.UserID, {
      //    dom: true,
      //    name: this.authService.userConfig.fullName,
      //    position: this.authService.user.xPositionID,
      //    sysUserId: this.authService.user.xSystemUserID
      //  });

      if ((<any>window).SessionStack) {
        (<any>window).SessionStack.identify({
          userId: this.authService.UserID, // Replace the USER-ID with the user id from your app
          displayName: this.authService.userConfig.fullName, // Not required
        });
      }
    } else {
      if ((<any>window).SessionStack) {
        (<any>window).SessionStack.stop();
      }
    }

    this.device.isApplicationLoaded = true;
    this.navService.initStack(this.homeNavCtrl);
    // Update init screen width
    this.device.updateScreenWidth(window.innerWidth);

    if (!this.device.isDeviceRealOffline) {
      this.alive = true;
      //Update Rep State Every One Hour CWD-2339
      RxInterval(this.pingInterval).pipe(
        takeWhile(() => this.alive)) // only fires when component is alive
        .subscribe(() => {
          if (!this.device.isOffline) {
            const newCurState = this.repService.getCurrentUserState();
            const newStateData = { userState: newCurState };
            this.repService.setCurrentState(newStateData);
          }
        });
      // this.sessionTimeOutOnIdle(parseInt(this.authService.expires , 10 ) , 5);
    } else {
      // Offline login. Start offlineSessionTimeout
      //this.device.startOfflineSession();
    }

    this.device.onResumeObservable.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.locationService.leftToLocationSettingFrom === 'home') {
        this.fetchUserCurrentLocation();
      }

      if (this.isDeltaSyncDue()) {
        this.activityOfflineService.deltaSyncData();
      } else {
        this.setSyncBanner();
      }
    })

    this.setSyncBanner();

    // Checker for initial sync completed so that we can trigger initial sync if there was any interruption while syncing (eg.app kill during refresh data)
    const prevSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MASTER_STATUS);
    let prevSyncNotFinished = prevSyncState && prevSyncState.status != 'success' && 
        (prevSyncState.syncType == 'refreshData' || prevSyncState.syncType == DEVICE_EVENT.languageChange);
        
    let cacheValue: any = this.uiService.getUIServiceData;
    if (this.loadFromCache && cacheValue.page === "Me-Page") {
      console.log("Do nothing");
    } else if (prevSyncNotFinished) {
      this.runUpdateSync(true, null, true, false, true);
    } else {
      if (this.device._isDeviceOfflineFromLogin) {
        // Do initial DB encryption check early before accessing database.
        await this.authService.initialDBEncryptionCheck()
          let loadingText: string = "I am initializing, please wait...";
          let data = {
            heading: this.translate.instant('GENEE_SYNC_IN_PROGRESS_CAP'),
            notificationText: loadingText,
            showLoader: true
          }
          if (!this.uiService.geneeSyncPopover) {
            this.uiService.geneeSyncPopover = await this.popoverCtrl.create({
              component: GeneeNotificationPopoverComponent,
              componentProps: data,
              backdropDismiss: false,
              showBackdrop: true,
              cssClass: 'geneeSyncInProgress',
            })

              await this.uiService.geneeSyncPopover.present();
              // this.device.syncInProgress = true;
          }

           // this.authService.getOfflineUser().then(() => {
            this.fetchUserCurrentLocation();
           // });
           this.authService.setGlobalCustomerText();
           this.authService.setGlobalCustomersText();

          this.activityOfflineService.restoreSnapshot()
            .then(async () => {
              this._cd.markForCheck();
              await Utility.delay(10);
              await this._offlineDataLoad();
            })
            .catch(error => {
              console.error('home: snapshot load: ', error);
              this._offlineDataLoad();
            });
      } else {
        this.runUpdateSync(true, null, false, false, true);
      }
    }
    this.myAssistantService.getAndSaveAssistantData().then(() => {
      this.events.subscribe("home:runUpdateSync", (data) => {
        console.log("Event Home: Run Update Sync");
        if (this.updateCalled && this.authService.shouldTrySyncingDuringAppLaunch) {
          this.updateCalled = false;
          this.authService.shouldTrySyncingDuringAppLaunch = false;
        }
        if (this.updateCalled) {
          return;
        }
        else {
          if (this.uiService.activitiesPageTab === 'scheduler') {
            //TC-361
            /*
             If user is on scheduler view and sync the data, if the feature is unmapped the
             user was left with a blank page, so now if the user click on refresh button while
             he is on the scheduler tab, he will be moved to the agenda view for safety purpose.
            */
            this.uiService.activitiesPageTab = 'agenda';
            this.uiService.setTabSwitch(this.uiService.activitiesPageTab);
          }
          // Upload app insight offline data
          this.trackingService.pushAppAnalyticsDataFromOffline();
          let doc = {
            syncType: data?.syncType || 'delta',
            status: 'start',
            lastStartTime: new Date().getTime(), 
            lastUpdatedTime: null
          };
          this.deltaService.updateMasterSyncStatus(doc);

          if (data && data.syncType && data.syncType == 'networkStateFluctuateAutoSync') {
            this.runUpdateSync(false, data.syncType);
          }
          else if (data && data.syncType && data.syncType == 'userToGeneeConfirmed') {
            this.runUpdateSync(true, data.syncType);
          } else if (data && data.syncType && (data.syncType === DEVICE_EVENT.networkReconnection || data.syncType === DEVICE_EVENT.userStatusBackToOnline)) {
            this.runUpdateSync(false, null, false, true);
          } else if (data && data.syncType && data.syncType === DEVICE_EVENT.languageChange) {
            this.runUpdateSync(false, data.syncType, true, false);
          }
          else {
            this.runUpdateSync();
          }
        }

      });
    });

    this.events
      .observe("sync:completedNotification").pipe(
        takeUntil(this.destroy$))
      .subscribe(error => {
        if (_.isEmpty(this.translate.currentLang)) this.translate.use(localStorage.getItem('selectedLanguage'));
        this.myAssistantService.loadAndMapNotificationsFromDB().catch((error) => console.log('HomePage:ngOnInit:sync:completedNotification', error));
        this.todoService.convertPinnedItemsIds();
        this.events.publish('aciviiyCreatedRefreshTODO');
        if (!error) {          
          if(this.device.isBackgroundUploadInProgress && !this.device.syncInProgress){
            this.notificationService.notify(this.translate.instant('OFFLINE_UPLOAD_SUCCESS_MESSAGE'), 'Home Page');
          } else {
            this.notificationService.notify(this.translate.instant('SYNC_SUCCESSFUL'), 'Home Page');
          }
          /*this.myAssistantService.addNewNotification(
            this.translate.instant('SYNC_COMPLETED_FORMAT', { Format: format(new Date(), this.dateTimeFormatsService.dateToUpper + " HH:mm:ss") })
          );*/
        } else {
          if(this.device.isBackgroundUploadInProgress && !this.device.syncInProgress){
          this.notificationService.notify(this.translate.instant('OFFLINE_UPLOAD_FAILED_MESSAGE'), 'Home Page', 'top', ToastStyle.DANGER);
          } else {
            this.notificationService.notify(this.translate.instant('SYNC_FAILED'), 'Home Page', 'top', ToastStyle.DANGER);
          }
          let errorMsg: string = this.translate.instant('SYNC_FAILED_FORMAT', { Format: format(new Date(), "MMMM DD, YYYY HH:mm:ss") });
          if (this.utilityService.sync_error.length > 0) {
            errorMsg = errorMsg + ` ${this.utilityService.sync_error.join(", ")}`;
          }
          this.syncNotificationModel = {
            type: NOTIFICATION.SYNC_FAILED,
            name: this.translate.instant("SYNC_FAILED"),
            DateTime: Date.now(),
            id: NOTIFICATION.SYNC_FAILED + Date.now(),
            data: { data: this.translate.instant("SYNC_FAILED_DETAILS") },
            icon: 'assets/imgs/sync.svg',
            isRed: false,
            params: {}
          };
          this.myAssistantService.saveNotificationToDisk(this.syncNotificationModel);
          // this.myAssistantService.addNewNotification(errorMsg);
          console.error("Caught error resyncing data with network", error);
        }
      });

    this.events
      .observe("refreshData").pipe(
        takeUntil(this.destroy$))
      .subscribe(async () => {
        try {
          this.uiService.showRightPane = false;
          let doc = {
            syncType: 'refreshData',
            status: 'start',
            lastStartTime: new Date().getTime(), 
            lastUpdatedTime: null
          };
          this.deltaService.updateMasterSyncStatus(doc);
          let data = {
            heading: this.translate.instant('GENEE_SYNC_IN_PROGRESS_CAP'),
            notificationText: this.translate.instant('GENEE_INITIALIZING_SYNCING_DATA_PLEASE_WAIT'),
            showLoader: true
          }
          if (!this.uiService.geneeSyncPopover) {
            this.uiService.geneeSyncPopover = await this.popoverCtrl.create(
              {
                component: GeneeNotificationPopoverComponent, componentProps: data,
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'geneeSyncInProgress',
              });
            this.uiService.geneeSyncPopover.present();
            this.device.syncInProgress = true;
            this._cd.detach();
          }
          this._cd.reattach();
          this._cd.markForCheck();
          if (this.device.isNativeApp && this.disk.isThereOfflineDataToBeUploaded()) {
            const syncDebugInfo: EntitySyncInfo = {
              entityName: EntityNames.syncDebug,
              totalFailed: 0,
              totalSynced: 0,
              errors: [],
              syncStatus: true
            };
            let uploadSuccess = false;

            this.deltaService.startSyncInfoRecording(true, false);
            try {
              uploadSuccess = await this.masterData.uploadOfflineData(syncDebugInfo);
            } catch (error) {
              this.deltaService.addSyncErrorToEntitySyncInfo(
                syncDebugInfo,
                '',
                {
                  debug: {
                    error,
                    description: 'DEBUG: refreshData',
                  }
                }
              );
            } finally {
              this._isSyncFailed = uploadSuccess && this.deltaService.wasPushFailed();
              this.deltaService.endSyncInfoRecording(true);
              await this.deltaService.uploadSyncStatus(true);
              this.deltaService.cleanUpSyncInfoRecording();
            }
          }
          if (this.device.isOffline) {
            //incase device is offline before clearing db, dismiss genee and continue
            if (this.uiService.geneeSyncPopover) {
              this.uiService.geneeSyncPopover.dismiss();
              this.uiService.geneeSyncPopover = undefined;
              this.device.syncInProgress = false;
              this._cd.detach();
            }
            this._cd.reattach();
            this._cd.markForCheck();
            return;
          }
          await this.disk.resetDb().then(() => {
            let doc = {
              syncType: 'refreshData',
              status: 'start',
              lastStartTime: new Date().getTime(), 
              lastUpdatedTime: null
            };
            this.deltaService.updateMasterSyncStatus(doc);
          });
          if (this.device.isOffline) {
            //incase device is offline after clearing db, user will not have any data/any FA for App. So logout
            this.loginService.logout();
          }
          else {
            await this.authDataService.getUser(false).then(() => {
              this.runUpdateSync(true, "refreshData", true, false, true);
            }).catch(err => {
              if (this.uiService.geneeSyncPopover) {
                this.uiService.geneeSyncPopover.dismiss();
                this.uiService.geneeSyncPopover = undefined;
                this.device.syncInProgress = false;
                this._cd.detach();
              }
              console.log("get user failed while refreshData: ", err);
              this.loginService.logout();
            })
          }
          if (this.uiService.geneeSyncPopover) {
            this.uiService.geneeSyncPopover.dismiss();
            this.uiService.geneeSyncPopover = undefined;
            this.device.syncInProgress = false;
            this._cd.detach();
          }
          this._cd.reattach();
          this._cd.markForCheck();

        } catch (err) {
          console.log("failed to full sync while refreshing data : ", err);
          if (this.uiService.geneeSyncPopover) {
            this.uiService.geneeSyncPopover.dismiss();
            this.uiService.geneeSyncPopover = undefined;
            this.device.syncInProgress = false;
            this._cd.detach();
          }
          this._cd.reattach();
          this._cd.markForCheck();
        }
      });

        this.device.isAppInBackgroundObservable.pipe(
      takeUntil(this.destroy$))
      .subscribe((isAppInBackground: Boolean) => {
        if (this.device.isNativeApp && this.deltaService.isInitialSyncFinished) {
          if (isAppInBackground) {
            // Unsubscribe token refresh scheduler when the app goes to background
            if (this._tokenRefreshSubscription) {
              this._tokenRefreshSubscription.unsubscribe();
              this._tokenRefreshSubscription = null;
            }
            this.alive = false;
            
            // Upload only if:
            //   - Has been more than an hour since last sync
            //   - Has network
            let lastDataUploadTime = localStorage.getItem('lastDataUploadTime');
            if (lastDataUploadTime) {
              let dateObjectForTimestamp = new Date(parseInt(lastDataUploadTime));
              let dateObjectForPresent = new Date();
              if (dateObjectForTimestamp && dateObjectForPresent) {
                if (dateObjectForPresent.getTime() - dateObjectForTimestamp.getTime() >= BACKGROUND_UPLOAD_WAIT_TIME && this.backgroundUploadTimerId === undefined && !this.updateCalled) {
                  this.backgroundUploadTimerId = setTimeout(async () => {
                    // Upload as long as the device has network (regardless of user status)
                    if (!this.device.isDeviceRealOffline) {
                      this.updateCalled = true;
                      const syncDebugInfo: EntitySyncInfo = {
                        entityName: EntityNames.syncDebug,
                        totalFailed: 0,
                        totalSynced: 0,
                        errors: [],
                        syncStatus: true
                      };
                      this.deltaService.startSyncInfoRecording(true, false);
                      try {
                        await this.masterData.uploadOfflineData(syncDebugInfo, true);
                      } catch (error) {
                        this.deltaService.addSyncErrorToEntitySyncInfo(
                          syncDebugInfo,
                          '',
                          {
                            debug: {
                              error,
                              description: 'DEBUG: isAppInBackgroundObservable',
                            }
                          }
                        );
                      } finally {
                        this.deltaService.addEntitySyncInfo(syncDebugInfo);
                        this.deltaService.endSyncInfoRecording(true);
                      }

                      if (this.disk.wasThereOfflineDataUpload) {
                        // Since there was upload, report sync info to dynamics
                        await this.deltaService.uploadSyncStatus(true, true);
                        this.disk.wasThereOfflineDataUpload = false;
                      }
                      localStorage.setItem('lastDataUploadTime', new Date().getTime().toString());
                      this.updateCalled = false;
                    }
                    this.backgroundUploadTimerId = undefined;
                  }, BACKGROUND_UPLOAD_DELAY);
                }
              }
            }
          } else {
            if (this.backgroundUploadTimerId !== undefined) {
              clearTimeout(this.backgroundUploadTimerId);
              this.backgroundUploadTimerId = undefined;
            }
          }
        }
      });

    this.activityOfflineService.signalActivityConflictCheck$.asObservable()
    .pipe(
      takeUntil(this.destroy$),
      debounceTime(0),
    )
    .subscribe((activity: Activity) => {
      try{
      // Update conflict check idx just in case..
      // Ideally this needs to run only when a new activity
      // is added or removed without calling refreshAgenda
      // however, there's not enough time to find all such scenarios
      // thus adding it here even though it's inefficient..
      this.activityOfflineService.findAndSetConflictCheckStartIdx();

      // When an activity (which require time conflict check) CRUD happens
      // on a view that doesn't trigger refreshAgenda,
      // manually run conflict check here..
      checkTimeConflictWithOtherActivities(
        activity,
        this.activityOfflineService.activities,
        this.activityOfflineService.conflictCheckStartIdx,
        this.eventsToolService.eventsToolData,
        this.eventsToolService.conflictCheckLastIdx,
        this.authService.user.xSystemUserID,
      );
      checkProximityWarningAgainstActivities(
        activity,
        this.activityOfflineService.activities,
        this.authService.user.xSystemUserID,
        this.authService.user.meetingProximityWarningPeriod
      );
      // Signal conflict alert msg refresh
      this.activityOfflineService.signalRefreshConflictAlertMsg$.next(null);
    }catch (error) {
      console.log('HomePage:ngOnInit:signalActivityConflictCheck', error);
      
      }
    });


  }

  ngAfterViewInit() {
    // Set initial window width & height
    this.device.updateScreenWidth(window.innerWidth);
    this.device.updateScreenHeight(window.innerHeight);
  }

  @HostListener('window:keyboardDidShow', ['$event'])
  window_keyboardDidShow(event) {
    setTimeout(() => {
      let el = document.activeElement;
      el.scrollIntoView({ behavior: 'smooth', block: "center" });
    }, 100);
  }


  // showSyncPopup() {
  //   if (!this.device.isDeviceRealOffline && this.device.isUserStateOffline && !this.device._isDeviceOfflineFromLogin) {
  //     if (this.offlineSync.isSyncRequire()) {
  //       this.events.publish('syncPopup');
  //     }
  //   }
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.alive = false;
    this.destroy$.unsubscribe();
    if (this._offlineSessionTimeoutStatusSubscription) {
      this._offlineSessionTimeoutStatusSubscription.unsubscribe();
      this._offlineSessionTimeoutStatusSubscription = null;
    }
    if (this._tokenRefreshSubscription) {
      this._tokenRefreshSubscription.unsubscribe();
      this._tokenRefreshSubscription = null;
    }
  }

  async runUpdateSync(bypassLocalStorageCheck: boolean = false, syncType?: string, forceFullSync = false, pushOnly = false, isForAppLaunch = false) {
    // Prevent triggering another sync while a sync in progress
    //OMNI-48797: Last page end time missing, possible fix prevent background sync while user is in Presentation Screen
    if (this.updateCalled || 
    (this.navService.getCurrentMasterPageName() === PageName.PresentationMeetingComponent)) {
      return;
    }
    // Close side menu if open
    this.menu.close();

    this.updateCalled = true;
    this._isSyncFailed = false;

    //If we are offline ignore
    if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState || this.websocket.meetingInProgress) {
      this.updateCalled = false;
      this.events.publish("FA Refreshed");
            return;
    }

    // Checker for initial sync completed so that we can trigger initial sync if there was any interruption while syncing (eg.app kill during refresh data)
    const prevSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MASTER_STATUS);
    if(prevSyncState && prevSyncState.status != 'success' && 
        (prevSyncState.syncType == 'refreshData' || prevSyncState.syncType == DEVICE_EVENT.languageChange) ) {
      await this.disk.resetDb();
      forceFullSync = true;
    }
    // Checker for initial sync so that we can display appropriate loading msg
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_STATUS);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime || forceFullSync;

    let isThereOfflineDataToUpload = false;
    if (this.device.isNativeApp) {
      // Check if there is offline data to upload
      if (!isInitialSync) {
        // If this function was called during the app launch, let's just trigger upload.
        // This is to prevent any leftover offline data (probably due to logical bug).
        isThereOfflineDataToUpload = isForAppLaunch ? true : this.disk.isThereOfflineDataToBeUploaded();
        this.device.backgroundUploadInProgress = !this.device.isOffline && isThereOfflineDataToUpload && pushOnly;
      }

      if (!isThereOfflineDataToUpload && pushOnly) {
        this.updateCalled = false;
        return;
      }

      //Check if we have tried to run an update in the last 5 minutes.
      let lastDataUploadTime = localStorage.getItem('lastDataUploadTime');
      if (isThereOfflineDataToUpload && lastDataUploadTime && !bypassLocalStorageCheck && !pushOnly) {
        try {
          let dateObjectForTimestamp = new Date(parseInt(lastDataUploadTime));
          let dateObjectForPresent = new Date();
          if (dateObjectForTimestamp && dateObjectForPresent) {
            const syncDelay = 180000; // 3 min.
            if (dateObjectForPresent.getTime() - dateObjectForTimestamp.getTime() <= syncDelay) {
              console.log('Ignoring our offline to online sync due to last sync within 5 minutes.');
              if (syncType && syncType == 'networkStateFluctuateAutoSync') { }
              else {
                this.notificationService.notify(this.translate.instant('PREPARING_UR_DATA_NEXT_SYNC_AVAILABLE_IN_MINUTES',
                  { text: Math.ceil((syncDelay - (dateObjectForPresent.getTime() - dateObjectForTimestamp.getTime())) / 1000 / 60) }), 'Home Page');
              }
              this.updateCalled = false;
              if (this.uiService.geneeSyncPopover) {
                this.uiService.geneeSyncPopover.dismiss();
                this.uiService.geneeSyncPopover = undefined;
                this.device.syncInProgress = false;
              }
              return;
            }
          }
        } catch (dateError) {
          console.error('Caught an error trying to compare dates for offline to online sync', dateError);
        }
      }

      // Do initial DB encryption check early before accessing database.
      await this.authService.initialDBEncryptionCheck();
    }
    
    let loadingText: string = isInitialSync ? this.translate.instant('GENEE_INITIALIZING_SYNCING_DATA_PLEASE_WAIT') : this.translate.instant('GENEE_INITIALIZING_SYNCING_DATA_PLEASE_WAIT');
    let data = {
      heading: this.translate.instant('GENEE_SYNC_IN_PROGRESS_CAP'),
      notificationText: loadingText,
      showLoader: true
    }
    if (!this.uiService.geneeSyncPopover && !pushOnly) {
      this.uiService.geneeSyncPopover = await this.popoverCtrl.create(
        {
          component: GeneeNotificationPopoverComponent, componentProps: data,
          backdropDismiss: false,
          showBackdrop: true,
          cssClass: 'geneeSyncInProgress',
        });
      this.uiService.geneeSyncPopover.present();
    }
    this.device.syncInProgress = true;

    this._cd.detach();

    if (bypassLocalStorageCheck) {
      if (this.device.isOffline) {
        this.authService.getOfflineUser().then(() => {
        });
        this.deltaService.masterSyncState.setState('Sync');
        await this.masterData.syncData(false, true).then(() => {
          this.uiService.geneeSyncPopover.dismiss();
          this.uiService.geneeSyncPopover = undefined;
          this.device.syncInProgress = false;
          this.updateCalled = false;
          this.deltaService.masterSyncState.setState('Success');
          this.deltaService.updateMasterSyncStatus({status: 'success'});
        }).catch(error => {
          console.error('runUpdateSync: bypassLocalStorageCheck: ', error);
          this.uiService.geneeSyncPopover.dismiss();
          this.uiService.geneeSyncPopover = undefined;
          this.device.syncInProgress = false;
          this.updateCalled = false;
          this.deltaService.masterSyncState.setState('Fail');
          this.deltaService.updateMasterSyncStatus({status: 'fail'});
        });
      } else {
        if (
          this.authService.hasFeatureAction(FeatureActionsMap.BASELINE)
        ) {
          console.log("baseline enabled");
        }
      }
    }

    if (!this.device.isOffline) {
      this.deltaService.masterSyncState.setState('Sync');

      if (isThereOfflineDataToUpload && (!isInitialSync || forceFullSync)) {
        this.deltaService.startSyncInfoRecording(true, false);
        if (pushOnly) {
          this.device.syncInProgress = false;
          this.uploadDataInBackground();
        } else {
          // Start upload sync state recording
          const syncDebugInfo: EntitySyncInfo = {
            entityName: EntityNames.syncDebug,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
          };
          this.deltaService.startSyncInfoRecording(true, false);

          try {
            let uploadSuccess = await this.masterData.uploadOfflineData(syncDebugInfo);
            this._isSyncFailed = uploadSuccess && this.deltaService.wasPushFailed();
            this.deltaService.endSyncInfoRecording(true);
            localStorage.setItem('lastDataUploadTime', new Date().getTime().toString());
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              syncDebugInfo,
              '',
              {
                debug: {
                  error,
                  description: 'DEBUG: runUpdateSync: !pushOnly',
                }
              }
            );
          } finally {
            this.deltaService.addEntitySyncInfo(syncDebugInfo);
            this.deltaService.endSyncInfoRecording(true);
          }
        }
      } else if (!isThereOfflineDataToUpload) {
        localStorage.setItem('lastDataUploadTime', new Date().getTime().toString());
      }

      if (this.disk.wasThereOfflineDataUpload && !pushOnly) {
        // Since there was upload, report sync info to dynamics
        await this.deltaService.uploadSyncStatus(true);

        if (!pushOnly) {
          await Utility.delay(4000);
        }
        this.disk.wasThereOfflineDataUpload = false;
      }

      this.masterData.connectWebsocket();

      // Check for any changes that requires a full sync
      const didUserDataChangeToTriggerFullSync = (!pushOnly && syncType != 'refreshData') ? await this.masterData.checkUserDataChangeAndCleanDbIfFullSyncRequired(this._isSyncFailed) : false;
      this.events.publish('FA Refreshed');

      if (didUserDataChangeToTriggerFullSync) {
        // Requires a full sync. Cleaned db, break out of this function and re-sync..
        this.uiService.geneeSyncPopover.dismiss({ syncedNow: true });
        this.uiService.geneeSyncPopover = undefined;
        this.updateCalled = false;
        this.device.syncInProgress = false;
        this._cd.reattach();

        await this.runUpdateSync(true, '', true);
      } else {
        if (!pushOnly) {
          // Do data purge
          try {
            if (this._shouldPurgeBeTriggered()) {
              const duration = (!isNaN(this.authService.user.offlineDataDuration) && this.authService.user.offlineDataDuration >= 0) ? this.authService.user.offlineDataDuration : this.authService.DEFAULT_OFFLINE_DURATION;
              if (duration > 0) {
                let today = new Date();
                const maxEndDateUnixTimestamp = Date.UTC(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - duration
                );
                await this.masterData.purgeData(maxEndDateUnixTimestamp);

                localStorage.setItem('lastDataPurgeTime', today.getTime().toString());
              }
            }
          } catch (error) {
            console.error('runUpdateSync: purge: ', error);
          }

          await this.deltaService.updateDeltaSyncStatus();

          // Start download sync state recording
          this.deltaService.startSyncInfoRecording(false, isInitialSync);
          this.uiService.isDeltaSyncTriggred = true;
          try {
            await this.masterData.syncData(forceFullSync).then(()=>{
              this.deltaService.updateMasterSyncStatus({status: 'success'}); 
            });
          } catch (error) {
            console.error('runUpdateSync: ', error);
            this.deltaService.updateMasterSyncStatus({status: 'fail'});
            // this._isSyncFailed = true;
          }

          this._isSyncFailed = this.utilityService.sync_error.length == 0 ? this.deltaService.wasPullFailed() : true;
          this.deltaService.endSyncInfoRecording();
          await this.deltaService.uploadSyncStatus();
          this.deltaService.cleanUpSyncInfoRecording();
          localStorage.setItem('lastFullSyncTime', new Date().getTime().toString());
          
          if (this.isAutoDeltaSyncDisabled) {
            this.authService.setSyncBanner();
          }

          if (!this.launchDarkly.initialized) {
          await this.launchDarkly.initialize();
          }
          this.launchDarkly.refreshUser({
          "key": this.authService.user.userID
          });
        }
        if (!this.deltaService.isInitialSyncFinished) {
          this.doInitialActivityTimeConflictCheck();
        }

        this.setConfigForShortCallLauncher(pushOnly);

        if (this.uiService.geneeSyncPopover) {
          this.uiService.geneeSyncPopover.dismiss({ syncedNow: true });
          this.uiService.geneeSyncPopover = undefined;
          this.device.syncInProgress = false;
          this.deltaService.isInitialSyncFinished = true;
        }
        if (!pushOnly) {
          if (!this._isSyncFailed) {
            this.events.publish("sync:completed");
            this.events.publish("sync:completedNotification");
            this.trackingService.tracking('syncCompleted', TrackingEventNames.SYNC);
            this.deltaService.masterSyncState.setState('Success');
          } else {
            this.trackingService.tracking('syncFailed', TrackingEventNames.SYNC);
            this.events.publish("sync:completed", true);
            this.events.publish("sync:completedNotification", true);
            this.deltaService.masterSyncState.setState('Fail');
          }
        } else {
          this.deltaService.masterSyncState.setState('Success');
        }

        this.updateCalled = false;
      }
    }

    this._cd.reattach();
    this._cd.markForCheck();

    this.activityOfflineService.noActivityConfirmation = true;
  }

  private setConfigForShortCallLauncher(pushOnly = false) {
    if (pushOnly) {
      return;
    }
    if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
      // Reset selected account
      this.accountOfflineService.shortCallSelectedAccountId = '';
      // Lock device orientation if Short call feature is on
      this.device.lockDeviceOrientationPotrait();
    }
  }

  private uploadDataInBackground() {
    //OMNI-48797: Last page end time missing, possible fix prevent background sync while user is in Presentation Screen
    if(this.navService.getCurrentMasterPageName() === PageName.PresentationMeetingComponent) return; 
    this.device.backgroundUploadInProgress = true;
    // Start upload sync state recording
    const syncDebugInfo: EntitySyncInfo = {
      entityName: EntityNames.syncDebug,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    // Make sure another sync doesn't get triggered during background upload
    this.updateCalled = true;
    this.deltaService.startSyncInfoRecording(true, false);
    this.masterData.uploadOfflineData(syncDebugInfo).then(async (success: boolean) => {
      this._isSyncFailed = success && this.deltaService.wasPushFailed();
      this.deltaService.endSyncInfoRecording(true);
      await this.deltaService.uploadSyncStatus(true);
      localStorage.setItem('lastDataUploadTime', new Date().getTime().toString());
      if (!this._isSyncFailed && this.device.isBackgroundUploadInProgress) {
        this.disk.wasThereOfflineDataUpload = false;
        this.events.publish("sync:completed");
        this.events.publish("sync:completedNotification");
        this.trackingService.tracking('syncCompleted', TrackingEventNames.SYNC);
      } else {
        this.trackingService.tracking('syncFailed', TrackingEventNames.SYNC);
        this.events.publish("sync:completed", true);
        this.events.publish("sync:completedNotification", true);
      }
      this.updateCalled = false;
      this.device.backgroundUploadInProgress = false;
    }).catch(error => {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        syncDebugInfo,
        '',
        {
          debug: {
            error,
            description: 'DEBUG: runUpdateSync: uploadDataInBackground',
          }
        }
      );
    }).finally(async () => {
      this.deltaService.addEntitySyncInfo(syncDebugInfo);
      this.deltaService.endSyncInfoRecording(true);
      await this.deltaService.uploadSyncStatus();
      this.deltaService.cleanUpSyncInfoRecording();
    });
  }

  // Default - Trigger once a day
  private _shouldPurgeBeTriggered(daysBefore: number = 1): boolean {
    let shouldBeTriggered = true;
    //offline notification data

    this.offlineGivenDay = localStorage.getItem("notificationDay");
    this.offlineMessage = localStorage.getItem("notificationMessage");
    // this.offlineNotification();
    try {
      const lastPurgeTime = Number(localStorage.getItem('lastDataPurgeTime'));
      if (!isNaN(lastPurgeTime) && lastPurgeTime > 0) {
        if (daysBefore < 1) {
          daysBefore = 1;
        }
        const lastPurgeTimeDateObj = new Date(lastPurgeTime);
        const dayToCompare = subDays(new Date().setHours(0, 0, 0, 0), daysBefore - 1);
        shouldBeTriggered = isBefore(lastPurgeTimeDateObj, dayToCompare);
      }
    } catch (error) {
      console.error('shouldWeeklyPurgeBeTriggered: ', error);
    }
    return shouldBeTriggered;
  }

  public selectOption(option: MenuOptionModel | any): void {
    if (option.displayName) {
      switch (option.displayName) {
        case "Logout":
          // this.logout();
          this.loginService.logout();
          break;
        case "Contacts":
          this.navService.pushWithPageTracking(
            ContactPageComponent,
            PageName.ContactPageComponent
          );
          break;
        case "Presentations":
          this.presentationService.viewMode = PresentationView.MENU;
          this.navService.pushWithPageTracking(
            PresentationPageComponent,
            PageName.PresentationPageComponent
          );
          break;
        default:
          console.error(option.displayName + " is not supported!");
      }
    }

    if (typeof option === "object") {
    } else {
    }
  }

  onMenuToggle(isOpen: boolean) {
    isOpen
      ? this.events.publish("sideMenu:toggle", { isOpen: true })
      : this.events.publish("sideMenu:toggle", { isClose: true });
  }

  private async _offlineDataLoad() {
    try {
      // Due to iOS 13+ killing our app frequently, app launch flow for a remembered account has been changed.
      // When the app is killed online, regardless of who killed it, whether that be OS or user,
      // next app launch will try to do the same thing as offline login. (bringing user straight to the agenda page without login process / sync)
      // However, that causes an infrequent full delta sync (both push and pull), hence we're triggering a conditional sync
      // to keep the data synced as much as possible.
      // It will check last full sync time and

      // let shouldTriggerDeltaSync: boolean = !this.device.isDeviceRealOffline && !this.repService.wasLastUserStateOffline() && this.device.shouldDailySyncBeTriggered();
      // const autoDeltaSyncConfig = this.authService.user?.ioConfigurations?.find((config) => config.configName == 'AutoSyncTimerInHours');
      // const timeSinceLastSyncInHours = this.device.getTimeSinceLastSyncInHours();
      // const autoDeltaSyncTimerInHours = !isNaN(parseInt(autoDeltaSyncConfig?.configValue)) ? parseInt(autoDeltaSyncConfig.configValue) : DEFAULT_AUTO_DELTA_SYNC_TIME_IN_HOURS;
     
      // if (this.isAutoDeltaSyncDisabled) {
      //   if (timeSinceLastSyncInHours < autoDeltaSyncTimerInHours) {
      //     shouldTriggerDeltaSync = false;
      //   } else if (timeSinceLastSyncInHours >= autoDeltaSyncTimerInHours) {
      //     shouldTriggerDeltaSync = !this.device.isDeviceRealOffline && !this.repService.wasLastUserStateOffline();
      //   }
      // }

      const shouldTriggerDeltaSync = this.isDeltaSyncDue();

      if (this.authService.shouldTrySyncingDuringAppLaunch && shouldTriggerDeltaSync) {
        this.authService.shouldTrySyncingDuringAppLaunch = false;
        this.device.syncInProgress = true;
        this.runUpdateSync(true, null, false, false, true);
      } else {
        this.updateCalled = true;
        this.events.publish("FA Refreshed");
        this._cd.detach();
        this.device.localDbDataLoadInProgress = true;
        this.deltaService.masterSyncState.setState('Sync');

        await this.masterData.syncData(false, true);
        if (this.uiService.geneeSyncPopover) {
          await this.uiService.geneeSyncPopover.dismiss({ syncedNow: true });
          this.uiService.geneeSyncPopover = undefined;
          this.device.syncInProgress = false;
        }
        this._cd.reattach();
        this._cd.markForCheck();

        if (!this.device.isDeviceRealOffline) {
          // add the refresh for Launch darkly flags.
        if (!this.launchDarkly.initialized) {
        await this.launchDarkly.initialize();
        }
          this.launchDarkly.refreshUser({
        "key": this.authService.user.userID
        });
        }

        this.caseManagementService.reMapDataWithValues(this.accountOfflineService.accounts, this.contactService.contacts);
        this.activityOfflineService.filterDisplayActivity();
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else {
          this.uiService.agendaRefreshRequired = true;
        }
        if (!this.deltaService.isInitialSyncFinished) {
          this.activityOfflineService.sortAllActivitiesByStartDateTime();
          this.doInitialActivityTimeConflictCheck();
        }

        this.deltaService.masterSyncState.setState('Success');
        this.events.publish('offlineDataLoad:Completed')
        this.deltaService.isInitialSyncFinished = true;
        this.activityOfflineService.snapshot = undefined;
        this.updateCalled = false;
        this.device.localDbDataLoadInProgress = false;
        this.setConfigForShortCallLauncher();
      }
    } catch (error) {
      console.error('_offlineDataLoad: ', error);
      if (!this.deltaService.isInitialSyncFinished) {
        this.activityOfflineService.sortAllActivitiesByStartDateTime();
        this.doInitialActivityTimeConflictCheck();
      }
      this._cd.reattach();
      this._cd.markForCheck();
      if (this.uiService.geneeSyncPopover) {
        this.uiService.geneeSyncPopover.dismiss();
        this.uiService.geneeSyncPopover = undefined;
        this.device.syncInProgress = false;
      }
      this.deltaService.isInitialSyncFinished = true;
      this.activityOfflineService.snapshot = undefined;
      this.deltaService.masterSyncState.setState('Fail');
    }
  }

  private doInitialActivityTimeConflictCheck() {
    this.activityOfflineService.setConflictCheckState('Running');
    const hasTimeOffEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL) ? true : false;
    const hasPhoneCallEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ? true : false;
    const hasAccountVisitEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT) ? true : false;

    // Sort
    this.activityOfflineService.sortAllActivitiesByStartDateTime();
    // Find and set starting index
    const startIdx = this.activityOfflineService.findAndSetConflictCheckStartIdx();

    try {
      const userId: string = this.authService.user.xSystemUserID;
      for (let i = startIdx; i < this.activityOfflineService.activities.length; i++) {
        const activity = this.activityOfflineService.activities[i];
        if (activity.type === ActivityType.Appointment) {
          startRecursiveActivityScheduleConflictFlagging(this.activityOfflineService.activities, i, userId, hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        } else if (activity.type === ActivityType.PhoneCall) {
          if (!hasPhoneCallEnabled) {
            continue;
          }
          startRecursiveActivityScheduleConflictFlagging(this.activityOfflineService.activities, i, userId, hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        } else if (activity.type === 'TimeOff' || activity.type === 'TimeOffRequest') {
          if (!hasTimeOffEnabled) {
            continue;
          }
          startRecursiveActivityScheduleConflictFlagging(this.activityOfflineService.activities, i, userId, hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        }
      }
    } catch (error) {
      console.error('activityTimeConflictCheck: ', error);
    }

    this.activityOfflineService.setConflictCheckState('Done');
  }

  //offline nlotification
  clearInterval(){
    clearInterval(this.interval);
  }
  offlineNotification(){
    let offlineRecurringTime = Number.parseInt(localStorage.getItem("recurringNotificationTime"));
    this.offlineHourInMilliseconds = offlineRecurringTime * 60 * 60  * 1000;//
   this.interval= setInterval(() => {
        this.offlineMessageConfigure(localStorage.getItem("notificationDay"),localStorage.getItem("notificationMessage"));
      },this.offlineHourInMilliseconds  );
  }
  offlineMessageConfigure(givenDay,message){
    if(this.device.isOffline){
      let wentOfflineTime =  new Date(localStorage.getItem("offline.sync.flow.wentOfflineAt"));
      // Get the current date
      let currentDate = new Date();

     // Calculate the time difference in milliseconds

     let timeDifference = this.dateTimeFormatsService.getFormattedTimeInterval(wentOfflineTime,currentDate)
     let diffDay = Number.parseInt(timeDifference.substr(0,timeDifference.search("Day")-1));
     if(!Number.isNaN(diffDay)){
      if(Number.parseInt(givenDay)-diffDay<=0){
        this.notificationService.notify(message, 'Home Page', 'top', ToastStyle.DANGER ,null);
      }
     }
    }
  }
  offlineNotificationAfterClear(){
      if(this.device.isOffline){
        let givenDay= localStorage.getItem("notificationDay");
        let message = localStorage.getItem("notificationMessage");
        let offlineRecurringTime = Number.parseInt(localStorage.getItem("recurringNotificationTime"));
        let wentOfflineTime =  new Date(localStorage.getItem("offline.sync.flow.wentOfflineAt"));
        // Get the current date
        let currentDate = new Date();

       // Calculate the time difference in milliseconds

       let timeDifference = this.dateTimeFormatsService.getFormattedTimeInterval(wentOfflineTime,currentDate)
       let diffDay = Number.parseInt(timeDifference.substr(0,timeDifference.search("Day")-1));
       var hour=0,mint=0;
       if(!Number.isNaN(diffDay)){
        if(Number.parseInt(givenDay)-diffDay<=0){
          this.notificationService.notify(message, 'Home Page', 'top', ToastStyle.DANGER ,null);
          if(timeDifference.search("Hour")>0){
            let timeStr= timeDifference.split(',')[1]
            hour = Number.parseInt(timeStr.substr(0,timeStr.search("Hour")-1));
          }
          if(timeDifference.search("Minute")>0){
            let splitdiffTime=timeDifference.split(',');
            let minStr= splitdiffTime[splitdiffTime.length-1];
            mint = Number.parseInt(minStr.substr(0,minStr.search("Minute")-1));
          }
          hour = hour + (diffDay-Number.parseInt(givenDay))*24;
          let requrringTimeAfterClear = (offlineRecurringTime*60)-((hour % offlineRecurringTime)*60 + mint);
          setTimeout(() => {
            this.notificationService.notify(message, 'Home Page', 'top', ToastStyle.DANGER ,null);
            this.offlineNotification();
          },requrringTimeAfterClear*60 *1000  );

        }
       }


      }

  };

  setSyncBanner(){
    this.isAutoDeltaSyncDisabled = this.authService._user?.ioConfigurations?.some((config) => config.configName == 'DisableAutoDeltaSync' && config.configValue === "true");
    this.authService.setSyncBanner();
    this._cd.detectChanges();
  }

  isDeltaSyncDue() {
    let isDeltaSyncRequired: boolean = !this.device.isDeviceRealOffline && !this.repService.wasLastUserStateOffline() && this.device.shouldDailySyncBeTriggered();
    const deltaSyncConfig = this.authService.user?.ioConfigurations?.find((config) => config.configName == 'AutoSyncTimerInHours');
    const hoursSinceLastSync = this.device.getTimeSinceLastSyncInHours();
    const deltaSyncIntervalHours = !isNaN(parseInt(deltaSyncConfig?.configValue)) ? parseInt(deltaSyncConfig.configValue) : DEFAULT_AUTO_DELTA_SYNC_TIME_IN_HOURS;

    if (this.isAutoDeltaSyncDisabled) {
      if (hoursSinceLastSync < deltaSyncIntervalHours) {
        isDeltaSyncRequired = false;
      } else {
        isDeltaSyncRequired = !this.device.isDeviceRealOffline && !this.repService.wasLastUserStateOffline();
      }
    }

    return isDeltaSyncRequired;
  }

}
