import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { DeltaService } from "@omni/data-services/delta/delta.service";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { DeviceService } from "@omni/services/device/device.service";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import _ from 'lodash';
import { PresentationService } from "@omni/services/presentation/presentation.service";
import { Page, Presentation, SwipeStatus } from "@omni/classes/presentation/presentation.class";
import { Account } from "@omni/classes/account/account.class";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";
import { TerritoryManagementService } from "@omni/services/territory-management/territory-management.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, PresentationView, UIService } from "@omni/services/ui/ui.service";
import { Contact } from "@omni/classes/contact/contact.class";
import { MeetingDataService, UpdateMeetingPayload } from "@omni/data-services/meeting/meeting.data.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { AppointmentActivity, CHECKOUT_OPTIONS, OFFLINE_ID_PREFIX } from "@omni/classes/activity/appointment.activity.class";
import { ActivityService } from "@omni/services/activity/activity.service";
import { MeetingStructureService } from "@omni/services/meeting-structure/meeting-structure.service";
import { Activity, ActivityType, EmbeddedInteraction, FormatType, MeetingActivityTypeCode } from "@omni/classes/activity/activity.class";
import { PresentationMeetingComponent } from "@omni/pages/presentation/presentation-meeting";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { ContentCard, ParentTag, ShortCallContent, ShortCallField, ShortCallFilterField, TodoItem, TodoMasterData, UserTagData } from "@omni/classes/short-call/short-call.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { ContactTimelineAggregatorService } from "@omni/services/contact/contact-timeline.service";
import { sortObjArrayByStringFields } from "@omni/utility/common.utility";
import { Counter } from "@omni/classes/shared/counter.class";
import { IoContentFilter } from "@omni/components/io-component/io-content-filter/io-content-filter";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { LocationService } from "@omni/data-services/location/location.data.service";
import { GPS_STATUS, LocationOfflineService } from "@omni/services/location/location.service";
import { GPSActivity, GPSActivityPhoto, Photo, PhotoOrigin } from "@omni/classes/store-check/photo";
import { IndPhotoCaptureViewerComponent } from "@omni/components/shared/ind-photo-capture-viewer/ind-photo-capture-viewer";
import { IndPhotoCaptureViewerDataModel } from "@omni/models/indPhotoCaptureViewerDataModel";
import { DiskService } from "@omni/services/disk/disk.service";
import { IoContactList } from "@omni/components/io-component/io-contact-list/io-contact-list";
import { AlertService } from "@omni/services/alert/alert.service";
import { MeetingStatus } from "@omni/classes/meeting/meeting.class";
import { addMinutes, format, isToday } from "date-fns";
import { EmailService } from "@omni/services/email-templates/email.service";
import { EmailActionType, EmailActivity, EmailViewType } from "@omni/classes/activity/email.activity.class";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
import { LocationCoordinates } from "@omni/interfaces/shared/shared.interface";
import moment from "moment";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { FOLLOW_UP_TYPE, FollowUpActivity } from "@omni/classes/activity/follow-up-action.activity.class";
import { FollowUpActivityDataService } from "@omni/data-services/follow-up-activity/follow-up-activity.data.service";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "@omni/components/shared/ind-datetime-form/ind-datetime-form";
import { EventsService } from "@omni/services/events/events.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";
import { ActivityDataService } from "@omni/data-services/activity/activity.service";
import { ActivitiesDetailsPaneComponent } from "../activities-details-pane/activities-details-pane";
import { EmailActivityDetailComponent } from "../email-activity-detail/email-activity-detail";
import { FollowUpActionDetailComponent } from "../follow-up-action-detail/follow-up-action-detail";
import { PresentationPreviewComponent } from "@omni/components/presentation/presentation-preview/presentation-preview";

@Component({
  selector: 'short-call-home',
  templateUrl: 'short-call-home.html',
  styleUrls: ['short-call-home.scss'],
})
export class ShortCallHomeComponent implements OnInit, OnDestroy {

  private ngDestroy$ = new Subject<boolean>();

  isAndroid: boolean = false;
  searchText: string = '';
  backgroundUploadInProgress: boolean = false;
  syncInProgress: boolean = false;
  toDoRefresh: boolean = false;
  public eCardData: ShortCallContent = {
    title: "ECARD",
    showSkeleton: false,
    cards: []
  };
  public edaData: ShortCallContent = {
    title: "EDA",
    showSkeleton: false,
    cards: []
  };

  public productTabData = [];
  public selectedProductTab: string = null;
  private nonExpiredContents: Presentation[] = [];
  public selectedAccount: Account;
  public targetContactsCount: number = 0;
  public selectedActivityOption: string = null;
  public shortCallActivityOptions: string[] = ["MEETING"];
  public selectedContact: Contact;
  public affiliatedContacts: Contact[];
  public shouldDisplayCustomerSegment = false;
  private hasTerritoryFAEnabled = false;
  private hasConsentCheckFAEnabled = false;
  private isCurrentContactConsented = false;
  private isCheckingConsent = false;
  private contactListToBeDisplayed$: BehaviorSubject<Contact[]> = new BehaviorSubject([]);
  public contactListToBeDisplayedObservable: Observable<Contact[]> = this.contactListToBeDisplayed$.asObservable();
  public mostRecentActivityTitle = '';
  public mostRecentActivityLoadingCounter: Counter = new Counter('mostRecentActivityLoadingCounter');
  public suggestionsActive: boolean = false;
  public suggestionsData = [];
  private filterTags: ShortCallFilterField[] = [];
  public selectedTags: ShortCallField[] = [];
  private isManualGPSCheckinEnabled: boolean = false;
  private isManualCheckinProcess: boolean;
  private checkinProcess: boolean;
  public accountVisit: AppointmentActivity;
  public todos: TodoItem[] = [];
  private filteredContents: string[] = [];
  public isAccountInitDone = false;

  public localDbDataLoadInProgress = false;
  public syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');

  constructor(
    private agendaFooterService: AgendaFooterService,
    private device: DeviceService,
    private cd: ChangeDetectorRef,
    public deltaService: DeltaService,
    private brandService: BrandOfflineService,
    private presentationService: PresentationService,
    private accountService: AccountOfflineService,
    public territoryService: TerritoryManagementService,
    private contactService: ContactOfflineService,
    private navService: NavigationService,
    private meetingService: MeetingDataService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activityService: ActivityService,
    private meetingStructureService: MeetingStructureService,
    private footerService: FooterService,
    public uiService: UIService,
    private authService: AuthenticationService,
    private contactTimelineAggregatorService: ContactTimelineAggregatorService,
    private modalCtrl: ModalController,
    private alertCtrl : AlertController,
    private locationService: LocationOfflineService,
    private meetingDataService: MeetingDataService,
    private disk : DiskService,
    private alertService: AlertService,
    private emailService: EmailService,
    public featureActionsService: FeatureActionsService,
    private readonly followUpActivityDataService: FollowUpActivityDataService,
    private readonly popoverCtrl: PopoverController,
    private readonly events: EventsService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    public activityDataService: ActivityDataService,
  ) {
    this.isAndroid = this.device.isAndroid();
  }

  ngOnInit() {
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this.syncMessage = this.translate.instant('OFFLINE_UPLOAD_IN_PROGRESS');
      this.cd.detectChanges();
    });
    this.device.syncInProgress$.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this.syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
      this.searchText = '';
      this.initilizeHomeData(!inProgress);
      this.suggestionsActive = false;
      this.suggestionsData = [];
      this.isManualGPSCheckinEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN);
      this.shouldDisplayCustomerSegment = this.authService.hasFeatureAction(FeatureActionsMap.SHORT_CALL_LAUNCHER_DISPLAY_CUSTOMER_SEGMENTATION);
      if(this.accountVisit) {
        this.clearSelection();
      }
      this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
      this.cd.detectChanges();
    });
    this.initilizeHomeData();
    this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);

    // Loading counter to track the number of mostRecentActivity data fetch in progress
    this.mostRecentActivityLoadingCounter.resetCounter();
    this.isManualGPSCheckinEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN);
    this.shouldDisplayCustomerSegment = this.authService.hasFeatureAction(FeatureActionsMap.SHORT_CALL_LAUNCHER_DISPLAY_CUSTOMER_SEGMENTATION);
    this.events.observe('initTodo').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if (!this.device.isOffline && this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent)
        this.initTodoList()
    });
    
    this.device.isDataLoadFromLocalDB.pipe(takeUntil(this.ngDestroy$)).subscribe((inProgress) => {
      this.localDbDataLoadInProgress = inProgress;
      this.syncMessage = this.translate.instant('INIT_DATA_IN_PROGRESS');
      this.cd.detectChanges();
    });
 
  }
  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  async onSearchInput(ev) {
    if (ev && ev.target) {
      let params = ev.target.value;
      //OMNI-42997: user inputs at least one Chinese character, or three English alphabets
      if (this.translate.currentLang == 'zh_CN') {
        if (params.length >= 1) {
          await this.getSuggestedData(params);
        } else {
          this.suggestionsData = [];
        }
      } else {
        if (params.length >= 3) {
          await this.getSuggestedData(params);
        } else {
          this.suggestionsData = [];
        }
      }
      this.suggestionsActive = !_.isEmpty(this.suggestionsData);
    }
  }

  menuClicked(ev) {
    if (this.accountVisit) {
      console.log(ev);
      this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
    }
  }

  private async getSuggestedData(params: any) {
    this.suggestionsData = [];
    try {
      const filteredContacts = this.contactService.contacts.filter(contact => contact.fullName.toLowerCase().includes(params.toLowerCase()));
      if (!_.isEmpty(filteredContacts)) {
        const accContactAffs = await this.contactService.getAccountContactAffiliationsFromDB();
        filteredContacts.forEach(contact => {
          const affAccounts = accContactAffs.filter(accContactAff => accContactAff['indskr_accountcontactaffiliation.indskr_contactid'] === contact.ID
            && this.accountService.accounts.some(acc => acc.id === accContactAff['indskr_accountcontactaffiliation.indskr_accountid']));
          if (!_.isEmpty(affAccounts)) {
            const searchText = contact.fullName.substring(contact.fullName.toLowerCase().indexOf(params.toLowerCase()), contact.fullName.toLowerCase().indexOf(params.toLowerCase()) + params.length);
            affAccounts.forEach(account => {
              this.suggestionsData.push({
                contactId: contact.ID,
                accountId: account.accountid,
                text: `<span class='contactName'>`
                  + contact.fullName.split(new RegExp(params, 'i')).join(`<span class='highlight'>` + searchText + `</span>`) + `<span class='secondary-text'> | `
                  + [contact.indskr_externalid, account['indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.FormattedValue']].filter(Boolean).join(' | ') + `</span>`,
                contactName: contact.fullName,
              });
            });
          }
        });
      }else{
        this.suggestionsData.push({text: this.translate.instant('NO_RESULTS_FOR_THIS_SEARCH')});
      }
    } catch (err) {
      console.error('Failed to get contact search suggested data', err);
    }
  }

  private initilizeHomeData(syncDone?: boolean) {
    this.initOptions();
    this.resetSelectedContact();
    this.checkFAs();
    this.initProducts();
    this.initContent();
    this.initAccountWidget();
    this.initFilterTagData();
    this.initTodoList(syncDone);
  }

  initOptions() {
    this.shortCallActivityOptions = [];
    if (this.accountVisit) {
      if (this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus != null) {
        this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
        this.shortCallActivityOptions.push('CHECK_OUT_FOOTBAR');
      } else {
        this.selectedActivityOption = null;
        this.shortCallActivityOptions.push('CHECK_IN_ACCOUNT_VISIT')
      }
    } else {
      this.selectedActivityOption = null;
      this.shortCallActivityOptions = ["MEETING"]
      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)) {
        this.shortCallActivityOptions = ["REMOTE_HOME", "CHECK_IN_ACCOUNT_VISIT"]
      }
    }
  }

  private async initAccountWidget() {
    if (!this.syncInProgress && this.accountService.accounts?.length > 0) {
      // Sync done and account records available.
      if (this.accountService.shortCallSelectedAccountId) {
        this.selectedAccount = this.accountService.getAccountById(this.accountService.shortCallSelectedAccountId);
      } else {
        let nearByAccounts;
        try {
          nearByAccounts = await this.accountService.getAccountNearByMeter(5000);
        } catch (error) {
          console.error('initAccountWidget: getAccountNearByMeter: ', error);
        }
        if (_.isEmpty(nearByAccounts)) {
          try {
            const sorted = _.sortBy(this.accountService.accounts, 'accountName');
            this.selectedAccount = Array.isArray(sorted) ? sorted[0] : undefined;
          } catch (error) {
            console.error('initAccountWidget: sortBy: ', error);
          }
        } else {
          this.selectedAccount = nearByAccounts[0];
        }
        // Mark current selected account
        this.accountService.shortCallSelectedAccountId = this.selectedAccount?.id ?? undefined;
      }
      this.isAccountInitDone = true;

      this.fetchAffContacts();
    } else if (!this.syncInProgress && this.accountService.accounts?.length === 0) {
      // Sync done but no account records.
      this.isAccountInitDone = true;
    } else {
      // Sync running
      this.isAccountInitDone = false;
      this.selectedAccount = null;
    }
  }

  public selectOption(option: string) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    if (option == this.selectedActivityOption) {
      this.clearSelection();
    } else {
      if (option === 'CHECK_IN_ACCOUNT_VISIT' && (this.selectedActivityOption === 'MEETING' || this.selectedActivityOption === 'REMOTE_HOME')) {
        this.notificationService.notify(this.translate.instant('DESELECT_MEETING_TO_START_AC'), 'Short Call Home', 'top', ToastStyle.DANGER);
        return;
      }
      this.selectedActivityOption = option;
      if (option == 'CHECK_IN_ACCOUNT_VISIT') {
        this.checkInAccount();
        // this.openContactSelection();
      } else if (option === 'CHECK_OUT_FOOTBAR') {
        this.checkOutAccountVisit();
      }
    }
  }

  private clearSelection() {
    this.accountVisit = null;
    this.selectedActivityOption = null;
    this.initOptions();
    this.resetSelectedContact();
    this.mostRecentActivityLoadingCounter.resetCounter();
    this.edaData.cards.forEach(c => c.selected = false);
  }

  scrapAccountVisit() {
    this.alertService.showAlert({
      header: this.translate.instant('SCRAP_ACCOUNT_VISIT'),
      message: this.translate.instant('ACCOUNT_VISIT_NESTED_MEETING_WILL_BE_SCRAPPED_CONFIRM')
    }, this.translate.instant('SCRAP')
    ).then(async (res) => {
      if (res.role == "ok") {
        await this.uiService.displayLoader();
        await this.meetingService.scrapAccountVisit(this.accountVisit);
        this.contactListToBeDisplayed$.next([]);
        this.fetchAffContacts()
        this.activityService.selectedActivity = null;
        this.activityService.selectedActivityOnHomeScreen = null;
        this.clearSelection();
        await this.uiService.dismissLoader();
      }
    });
  }

  private initContent() {
    this.eCardData.showSkeleton = this.edaData.showSkeleton = true;
    if (!this.syncInProgress && this.presentationService.presentation?.length > 0) {
      this.presentationService.presentation = [...this.presentationService.initialPres];
      this.nonExpiredContents = this.presentationService.filterExpiredPresentations(this.presentationService.presentation);
      this.filterContent();
    }
    this.eCardData.showSkeleton = this.edaData.showSkeleton = this.syncInProgress;
  }

  private initProducts() {
    this.productTabData = [];
    if (this.syncInProgress) {
      this.productTabData = Array.from({ length: 3 }, (_, index) => ({
        value: index + 1,
        displayText: null,
        showSkeleton: true
      }))
    } else {
      if (this.brandService.brands?.length > 0) {
        this.productTabData = sortObjArrayByStringFields(
          this.brandService.brands.map(prod => { return { value: prod.ID, displayText: prod.name } }),
          'displayText',
          undefined,
          false,
          this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
        );
        // this.productTabData = _.orderBy(this.brandService.brands.map(prod => { return { value: prod.ID, displayText: prod.name } }), 'displayText');
        this.selectedProductTab = this.productTabData[0].value;
      }
    }
  }

  private initFilterTagData() {
    this.filterTags = this.selectedTags = [];
    const tagData: UserTagData = _.cloneDeep(this.presentationService.userTagData);
    if (tagData?.parentTags?.length > 0) {
      const filteredChildTags = _.flatMap(tagData.presentationTags.filter(presentationTag => this.filteredContents.includes(presentationTag.presentation_id)), 'tags');
      const filteredParentTags = _.filter(tagData.parentTags, parentTag => {
        const matchingChildTags = _.intersectionBy(parentTag.childTags, filteredChildTags, 'indskr_usertagid');
        return matchingChildTags.length > 0;
      });

      // Remove non-matching child tags from filtered parent tags
      filteredParentTags.forEach(parentTag => {
        parentTag.childTags = _.intersectionBy(parentTag.childTags, filteredChildTags, 'indskr_usertagid');
      });
      this.filterTags = filteredParentTags.map(pt => ({
        value: pt.parent_tag_id,
        label: pt.parent_tag_name,
        selected: false,
        tags: pt.childTags.map(child => ({
          value: child.indskr_usertagid,
          label: child.indskr_name,
          selected: false
        }))
      }));
    }
  }

  private async initTodoList(syncDone: boolean = false) {
    this.activityService.todoRefreshInProgress = this.toDoRefresh  = true;
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    this.todos = await this.getTodosForToday(syncDone);
    this.activityService.todoRefreshInProgress = this.toDoRefresh  = false;
    console.log("initTodoList ~ todos:", this.todos);
  }

  async getTodosForToday(clear: boolean): Promise<TodoItem[]> {
    if (_.isEmpty(this.activityService.activities)) return [];
    let filteredActivities: TodoItem[] = [];
    try {
      const hasSuggestedMeetingsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
      const hasSuggestedWeComMessagesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_WECOM_MESSAGES);
      const hasSuggestedAlertsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_TASKS);
      const today = moment(new Date()).format('DD/MM/YYYY');
      let first_sync_for_today = true;
      const isOpenAppointment = (activity: Activity): boolean => (hasSuggestedMeetingsEnabled && activity.type === ActivityType.Appointment && activity.state == 0 && activity['indskr_suggest'] == true
        && activity['ownerId'] === this.authService.user.systemUserID);
      const isOpenEmail = (activity: Activity): boolean => (hasSuggestedWeComMessagesEnabled && activity.type === ActivityType.Email && activity.status == 1 && activity['indskr_recommendedmessage'] == true);
      const isOpenAlert = (activity: Activity): boolean => (hasSuggestedAlertsEnabled && activity.type === ActivityType.FollowUp && activity.state == 0 && activity['planType'] == FOLLOW_UP_TYPE.SUGGESTED
        && activity['ownerId'] === this.authService.user.systemUserID);
      const existingTodo: TodoMasterData = await this.disk.retrieve(DB_KEY_PREFIXES.USER_TODO_DATA, true);
      const contacts = [];
      const activities = this.activityService.activities.filter(activity => {
        const isValid = ((isOpenEmail(activity) || isOpenAppointment(activity) || isOpenAlert(activity)) && isToday(activity.scheduledStart));
        if (isValid) {
          if (activity instanceof AppointmentActivity) {
            contacts.push(...activity.contacts);
          } else if (activity instanceof EmailActivity) {
            contacts.push(...activity.emailActivityParties.map(party => ({ ...party, ID: party.indskr_contactid })));
          } else if (activity instanceof FollowUpActivity) {
            contacts.push(...[{ ID: activity.contactId }]);
          }
        }
        return isValid;
      })
      await this.contactService.mapProductSegmentationsToContactsForShortCallHome(contacts);
      filteredActivities = activities.map(a => {
        const item: TodoItem = {
          id: a.ID,
          type: a.type,
          start_date: a.scheduledStart?.getTime(),
          modifiedon: a.modified instanceof Date ? a.modified.getTime() : parseInt(a.modified + ''),
          indskr_suggestionrescheduled: a['indskr_suggestionrescheduled'] ?? false,
          rows: []
        }
        const rowItems = [];
        if (a instanceof AppointmentActivity) {
          const rowItem = {
            text: !_.isEmpty(a.contacts) ? a.contactString : '',
            cssClass: 'primary-text',
            subText: null,
            scrapIcon: true,
            icon: 'assets/imgs/appointment.svg'
          }
          item.field_to_sort = a.contactString;
          if (!_.isEmpty(a.contacts)) {
            const subTexts = []
            const contact = this.contactService.contacts.find(con => con.ID === a.contacts[0].ID);
            if (contact.indskr_externalid)
              subTexts.push(contact.indskr_externalid);
            const con = contacts.find(c => c.ID === a.contacts[0].ID);
            if (con && !_.isEmpty(con.productSegmentations))
              subTexts.push(con.productSegmentations[0].segmentation);
            rowItem.subText = _.isEmpty(subTexts) ? '' : " | " + subTexts.join(' | ');
            rowItems.push(rowItem);
          }
          if (!_.isEmpty(a.accounts)) {
            const rowItem = {
              text: a.accountString,
              cssClass: 'secondary-text'
            }
            rowItems.push(rowItem);
          }
          if (!_.isEmpty(a.indskr_reason)) {
            const rowItem = {
              text: a.indskr_reason,
              cssClass: 'reason'
            }
            rowItems.push(rowItem);
          }
        } else if (a instanceof EmailActivity) {
          const rowItem = {
            text: !_.isEmpty(a.emailActivityParties) ? a.emailActivityParties[0].contact_firstname + ' ' + a.emailActivityParties[0].contact_lastname : '',
            cssClass: 'primary-text',
            icon: 'assets/imgs/email.svg',
            scrapIcon: true,
            subText: null
          }
          item.field_to_sort = rowItem.text;
          if (!_.isEmpty(a.emailActivityParties)) {
            const subTexts = []
            const contact = this.contactService.contacts.find(con => con.ID === a.emailActivityParties[0].indskr_contactid);
            if (contact) {
              if (contact.indskr_externalid)
                subTexts.push(contact.indskr_externalid);
              const con = contacts.find(c => c.ID === a.emailActivityParties[0].indskr_contactid);
              if (!_.isEmpty(con.productSegmentations)) {
                subTexts.push(con.productSegmentations[0].segmentation);
              }
            }
            rowItem.subText = _.isEmpty(subTexts) ? null : " | " + subTexts.join(' | ');
            rowItems.push(rowItem);
          }
          if (!_.isEmpty(a.accounts)) {
            const rowItem = {
              text: a.accountString,
              cssClass: 'secondary-text'
            }
            rowItems.push(rowItem);
          }
          if (!_.isEmpty(a.indskr_reason)) {
            const rowItem = {
              text: a.indskr_reason,
              cssClass: 'reason'
            }
            rowItems.push(rowItem);
          }
        } else if (a instanceof FollowUpActivity) {
          item.start_date = new Date(a.scheduledEnd).getTime();
          const rowItem = {
            text: a.followupActionTypeName,
            cssClass: 'primary-text',
            subText: '',
            scrapIcon: true,
            icon: 'assets/imgs/alert.svg'
          }
          rowItems.push(rowItem);
          if (a.contactId) {
            const texts = [a.contactNameString];
            const c = this.contactService.contacts.find(c => c.ID === a.contactId);
            if (c && c.indskr_externalid)
              texts.push(c.indskr_externalid);
            const rowItem = {
              text: texts.join(" | "),
              cssClass: 'secondary-text'
            }
            item.field_to_sort = a.contactNameString;
            rowItems.push(rowItem);
          }
          if (a.accountId) {
            const rowItem = {
              text: a.accountNameString,
              cssClass: 'secondary-text'
            }
            rowItems.push(rowItem);
          }
          if (a.indskr_reason) {
            const rowItem = {
              text: a.indskr_reason,
              cssClass: 'reason'
            }
            rowItems.push(rowItem);
          }
        }
        item.rows = rowItems;
        return item;
      });
      filteredActivities = _.orderBy(filteredActivities, ['indskr_suggestionrescheduled', 'modifiedon', 'field_to_sort'], ['desc', 'asc', 'asc']);
      if (!existingTodo || today != existingTodo.date) {
        first_sync_for_today = true;
        const newTodos = this.getSlicedData(filteredActivities, 20);
        filteredActivities = newTodos.map(newTodo => {
          newTodo['rows'][0]['new_todo'] = true;
          return {
            ...newTodo
          }
        });
      } else {
        // Update existing todos with new todo reason and date
        existingTodo.todos = _.map(existingTodo.todos, e => {
          const newTodo = _.find(filteredActivities, { id: e.id });
          if (newTodo) {
            return { ...newTodo };
          }
          e['rows'][0]['new_todo'] = false;
          return e;
        });
        // Remove existing todos not present in newTodoList
        let updatedExistingTodoList = _.intersectionBy(existingTodo.todos, filteredActivities, 'id');
        if (clear) {
          first_sync_for_today = false;
          updatedExistingTodoList = updatedExistingTodoList.map(newTodo => ({ ...newTodo }));
        }
        // Add missing new todo items to existing with new_flag as true
        const missingNewTodoItems = _.differenceBy(filteredActivities, existingTodo.todos, 'id')
          .map(newTodo => {
            newTodo['rows'][0]['new_todo'] = true;
            return {
              ...newTodo
            }
          });
        const finalTodoList: TodoItem[] = [...updatedExistingTodoList, ...missingNewTodoItems];
        filteredActivities = _.orderBy(finalTodoList, ['indskr_suggestionrescheduled', 'modifiedon', 'field_to_sort'], ['desc', 'asc', 'asc']);
        filteredActivities = this.getSlicedData(finalTodoList, 20);
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.USER_TODO_DATA, (doc) => {
        return { date: today, first_sync_for_today: first_sync_for_today, todos: filteredActivities }
      });
    } catch (e) {
      console.log("Error while fetching Todos :", e)
    }
    // return _.orderBy(filteredActivities, ['indskr_suggestionrescheduled', 'modifiedon', 'field_to_sort'], ['desc', 'desc', 'asc']);
    return filteredActivities;
  }
  
  private getSlicedData(data: TodoItem[], limit: number) {
    return data?.length <= limit ? data : data.slice(0, limit);
  }

  public async syncTodo() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    this.activityService.todoRefreshInProgress = this.toDoRefresh = true;
    
    const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
    await Promise.all([
      this.activityDataService.syncAppointments(dataRangeWithFutureBoundBySixMonths, false, false),
      this.activityDataService.syncFollowupTasks(dataRangeWithFutureBoundBySixMonths, false, false),
      this.activityDataService.syncMessages(dataRangeWithFutureBoundBySixMonths, false, false),
    ]);
    await this.initTodoList(this.toDoRefresh);
    this.activityService.todoRefreshInProgress = this.toDoRefresh  = false;
  }

  private checkFAs() {
    this.hasTerritoryFAEnabled = (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT))
      && this.territoryService.currentListPeriod?.tagLabel
      ? true : false;
    this.hasConsentCheckFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONSENT_CHECK);
    this.shouldDisplayCustomerSegment = this.authService.hasFeatureAction(FeatureActionsMap.SHORT_CALL_LAUNCHER_DISPLAY_CUSTOMER_SEGMENTATION);
  }
  private resetSelectedContact() {
    this.selectedContact = null;
  }

  public selectedTabChange(product: string) {
    this.edaData.showSkeleton = true;
    this.selectedProductTab = product;
    this.filterTags = [];
    this.filterContent();
    this.initFilterTagData();
  }

  private filterContent() {
    this.filteredContents = [];
    const contentsByProduct: Presentation[] = this.nonExpiredContents.filter(c => c.brands?.length > 0 && c.brands.some(c => c.ID == this.selectedProductTab));
    let eDaCards: ContentCard[] = [];
    let eCards: ContentCard[] = [];
    const tags = _.cloneDeep(this.presentationService.userTagData?.presentationTags ?? []);
    this.selectedTags = this.filterTags?.reduce((acc, obj) => acc.concat(obj.tags.filter(tag => tag.selected)), []) ?? [];
    contentsByProduct.forEach(c => {
      const contentCard: ContentCard = {
        contentId: c.contentId,
        imageUrl: c.thumbnailUrl,
        name: c.name,
        tags: tags.find(t => t.presentation_id == c.contentId)?.tags ?? []
      };
      const addContent = (ecard: boolean, contentCard: ContentCard): void => {
        this.filteredContents.push(contentCard.contentId);
        if (ecard) {
          if(!eCards.some(c => c.contentId == contentCard.contentId)) eCards.push(contentCard);
        } else {
          if (!eDaCards.some(c => c.contentId == contentCard.contentId)) eDaCards.push(contentCard);
        }
      }
      if (this.selectedTags.length > 0) {
        if (contentCard.tags.length > 0) {
          this.selectedTags.forEach(st => {
            if (contentCard.tags.some(cc => cc.indskr_usertagid == st.value)) {
              addContent(c.indskr_ecard, contentCard);
            }
          })
        }
      } else {
        addContent(c.indskr_ecard, contentCard);
      } 
    });

    this.edaData.cards = sortObjArrayByStringFields(
      eDaCards,
      'name',
      undefined,
      false,
      this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
    );

    this.eCardData.cards = sortObjArrayByStringFields(
      eCards,
      'name',
      undefined,
      false,
      this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
    );
    this.eCardData.showSkeleton = this.edaData.showSkeleton = false;
  }

  private async checkInAccount() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('NETOWRK_UNAVAILABLE_CHECKIN'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      this.clearSelection();
      return;
    }
    if (this.accountVisit) {
      await this.checkInForMeeting();
    } else {
      await this.uiService.displayLoader({duration: 50000});
      const offlineMeetingId = OFFLINE_ID_PREFIX + new Date().getTime();
      const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.selectedAccount.id);
      
      const newAccountVisit = new AppointmentActivity({
        subject: this.translate.instant('ACCOUNT_VISIT'),
        scheduledstart: new Date().getTime(),
        scheduledend: addMinutes(new Date().getTime(), this.authService.user?.meeting_duration || 30),
        offlineMeetingId,
        indskr_positionid: this.authService.user.xPositionID,
        statecode: 0,
        statuscode: 1,
        activityid: offlineMeetingId,
        indskr_ownerid: this.authService.user.systemUserID,
        indskr_ownerfullname: this.authService.user.displayName,
        indskr_isparentcall: true,
        indskr_meetingtype: 600000000
      });
      if (selectedAddress) {
        newAccountVisit.location = selectedAddress.compositeAdd;
        newAccountVisit.indskr_meetinglocationlongitude = selectedAddress.longitude;
        newAccountVisit.indskr_meetinglocationlatitude = selectedAddress.latitude;
      }
      newAccountVisit.accounts = [this.selectedAccount]
      const activityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.ACCOUNT_VISIT);
      if (activityTypes.length !== 0) {
        const defaultActivityType = activityTypes.length == 1 ? activityTypes[0] : activityTypes.find(at => at.indskr_default);
        if (defaultActivityType) {
          newAccountVisit.indskr_activitytype = defaultActivityType.indskr_activitytypeid;
          newAccountVisit.activityTypeName = defaultActivityType.indskr_name;
          newAccountVisit.subject = (_.isEmpty(newAccountVisit.accountString) ? '' : ((newAccountVisit.accountString + ' - '))) + this.translate.instant('ACCOUNT_VISIT') + ' - ' + defaultActivityType.indskr_name;
        }
      }
      const success = await this.meetingService.crudMeetingsOnlineWithOfflineUploadEndpoint([newAccountVisit]);
      await this.uiService.dismissLoader();
      if (success) {
        this.activityService.selectedActivityOnHomeScreen = this.accountVisit = newAccountVisit;
        this.contactListToBeDisplayed$.next([]);
        await this.checkInForMeeting();
        // this.openContactSelection();
      }
      else {
        this.initOptions();
        this.notificationService.notify(
          this.translate.instant('ACCOUNT_VISIT_ERROR_CREATING_NEW_ACCOUNT_VISIT'),
          'Short Call Home',
          'top',
          ToastStyle.INFO
        );
      }
    }
  }

  private async checkOutAccountVisit() {
    this.activityService.selectedActivity = this.accountVisit;
    if (await this.activityService.isMeetingLimitReached()) return;

    const checkoutDateTime = new Date();
    // GPS check out min duration reminder
    if (this.accountVisit.gpsCheckInDetails?.indskr_checkindatetime) {
      const { durationLimitInMin, actualDurationInMin, shouldAlert } = this.activityService.validateGpsCheckOutMinDurationViolation(
        new Date(this.accountVisit.gpsCheckInDetails?.indskr_checkindatetime),
        checkoutDateTime,
      );

      if (shouldAlert) {
        if (!await this.activityService.showGPSCheckOutMinDurationReminder(durationLimitInMin, actualDurationInMin)) {
          this.initOptions();
          return;
        }
      }
    }

    if ((this.accountVisit.gpsCheckInDetails?.indskr_checkoutstatus == GPS_STATUS.VALID) 
    || (this.accountVisit.gpsCheckInDetails?.indskr_checkoutstatus == GPS_STATUS.INVALID && this.isManualGPSCheckinEnabled && !_.isEmpty(this.accountVisit.gpsActivityPhotos))) {
      await this.uiService.displayLoader();
      const markedComplete = await this.markCompleteAccountVisit();
      if (!markedComplete) {
        this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
      }
      else {
        await this.activityService.upsertMeetingsOfflineData(this.accountVisit);
        this.activityService.selectedActivity = null;
        this.clearSelection();
      }
      await this.uiService.dismissLoader();
      return;
    }
    const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(this.accountVisit.ID);
    if (nestedMeetings.length > 0) {
      // Need to check if nested meetings are all complete-able
      const notCompletedNestedMeetings: AppointmentActivity[] = nestedMeetings.filter(m => m.state !== 1);
      if (!this.activityService.appointmentsCanComplete(notCompletedNestedMeetings)) {
        this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
        return;
      }
    }
    let gpsCheckOutStatus;
    let distance;
    await this.uiService.displayLoader({ duration: 50000 });
    const currentCoordinates: any = this.authService.user.currentLocation ? this.authService.user.currentLocation : { latitude: 0, longitude: 0 };

    let confirmedCheckOut;
    this.isManualCheckinProcess = false;

    if (currentCoordinates.latitude <= 0) {
      this.notificationService.notify(this.translate.instant('UNABLE_TO_GET_LOCATION'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
      await this.uiService.dismissLoader();
      this.checkinProcess = false;

      // if isManualGPSCheckinEnabled is ON then force user to do manual checkout incase of UNABLE_TO_GET_LOCATION 
      if (this.isManualGPSCheckinEnabled && this.locationService.isLocationEnabledOnDevice) {
        let confirmedManualCheckOut = await this.showManualGPSConfirmAlert('AUTO_CHECK_OUT_FAILED', this.translate.instant('MANUAL_CHECKOUT_DISTANCE_WARNING_AC'), 'MANUAL_CHECKOUT');
        if (!confirmedManualCheckOut) {
          this.checkinProcess = false;
          this.initOptions();
          return;
        };
        confirmedCheckOut = CHECKOUT_OPTIONS.CHECKOUT;
        this.isManualCheckinProcess = true;
        gpsCheckOutStatus = GPS_STATUS.INVALID;
      }
      else {
        this.initOptions();
        return;
      }
    }
    distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, this.accountVisit.indskr_meetinglocationlatitude, this.accountVisit.indskr_meetinglocationlongitude, 'M');
    await this.uiService.dismissLoader();

    if (!this.isManualCheckinProcess) {
      if (distance > this.authService.user.validCallDistance) {

        if (this.isManualGPSCheckinEnabled) {
          const fixedDistance = (distance / 1000).toFixed(2);
          const alertMsg = this.translate.instant('MANUAL_CHECKOUT_WITH_DISTANCE_WARNING_AC', { distance: fixedDistance });
          let confirmedManualCheckOut = await this.showManualGPSConfirmAlert('AUTO_CHECK_OUT_FAILED', alertMsg, 'MANUAL_CHECKOUT');
          if (!confirmedManualCheckOut) {
            this.checkinProcess = false;
            this.initOptions();
            return;
          };
          confirmedCheckOut = CHECKOUT_OPTIONS.CHECKOUT;
          this.isManualCheckinProcess = true;
          gpsCheckOutStatus = GPS_STATUS.INVALID;
        } else {
          confirmedCheckOut = await this.showCheckOutConfirmAlert('CHECKOUT_DISTANCE_WARNING_AC', (distance / 1000).toFixed(2));
          if (!confirmedCheckOut) {
            this.checkinProcess = false;
            this.initOptions();
            return;
          };
          gpsCheckOutStatus = GPS_STATUS.INVALID;
        }

      } else {
        confirmedCheckOut = await this.showCheckOutConfirmAlert('CHECKOUT_POSITIVE_AC');
        if (!confirmedCheckOut) {
          this.checkinProcess = false;
          this.initOptions();
          return;
        };
        gpsCheckOutStatus = GPS_STATUS.VALID;
      }
    }

    let payload: any = {
      indskr_checkoutlongitude: currentCoordinates.longitude,
      indskr_checkoutlatitude: currentCoordinates.latitude,
      indskr_checkoutdatetime: checkoutDateTime,
      indskr_checkoutdistance: distance,
      indskr_checkoutstatus: gpsCheckOutStatus,
      indskr_gpscheckindetailsid: this.accountVisit.gpsCheckInDetails["indskr_gpscheckindetailsid"]
    }

    if (confirmedCheckOut === CHECKOUT_OPTIONS.CHECKOUT_COMPLETE) {
      await this.uiService.displayLoader({ duration: 50000 });
      const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(this.accountVisit, payload);
      if (!response) {
        await this.uiService.dismissLoader();
        this.checkinProcess = false;
        this.initOptions();
        return;
      };

      this.setCheckinCheckoutStatus(payload);
      this.notificationService.notify(this.translate.instant('GPS_CHECKOUT_SUCESS'), 'Check Out', 'top', ToastStyle.INFO, 3000);

      const markedComplete = await this.markCompleteAccountVisit();//await this.markComplete(true);
      if (!markedComplete) {
        this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
        await this.uiService.dismissLoader();
        this.initOptions();
        return;
      }

      await this.activityService.upsertMeetingsOfflineData(this.accountVisit);
      this.contactListToBeDisplayed$.next([]);
      await this.fetchAffContacts()
      this.activityService.selectedActivity = null;
      this.clearSelection();
      await this.uiService.dismissLoader();
    } else if (confirmedCheckOut === CHECKOUT_OPTIONS.CHECKOUT) {
      if (this.isManualGPSCheckinEnabled && this.isManualCheckinProcess) {
        this.openPhotoCaptureViewerForGPSActivity(GPSActivity.CHECKOUT, payload, this.accountVisit)
      }
      else {
        await this.uiService.displayLoader({ duration: 50000 });
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(this.accountVisit, payload);
        if (!response) {
          this.checkinProcess = false;
          this.initOptions();
          return;
        };
        this.setCheckinCheckoutStatus(payload);
        this.notificationService.notify(this.translate.instant('GPS_CHECKOUT_SUCESS'), 'Check Out', 'top', ToastStyle.INFO, 3000);
        const markedComplete = await this.markCompleteAccountVisit();
        if (!markedComplete) {
          this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
          await this.uiService.dismissLoader();
          this.initOptions();
          return;
        }

        await this.activityService.upsertMeetingsOfflineData(this.accountVisit);
        this.activityService.selectedActivity = null;
        await this.uiService.dismissLoader();
        this.contactListToBeDisplayed$.next([]);
        await this.fetchAffContacts()
        this.clearSelection();
      }
    }
  }

  async showCheckOutConfirmAlert(message, distance?) : Promise<any> {

    if (distance) {
      message = this.translate.instant(message, { distance });
    } else {
      message = this.translate.instant(message, {})
    }
    
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKOUT'),
      message,
      buttons: [{
        text: this.translate.instant(distance ? 'CHECKOUT_ANYWAY_AND_COMPLETE' : 'CHECKOUT_COMPLETE'),
        role: 'confirm',
        handler: () => {
          return ({ values: CHECKOUT_OPTIONS.CHECKOUT_COMPLETE })
        },
      }, {
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return ({ values: CHECKOUT_OPTIONS.CANCEL })
        },
      }],
    });
    await alert.present();
   
    return await alert.onDidDismiss().then(({data}) => {
      if (this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
        this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
        return false;
      }
      return data ? data.values : false; 
    });

  }

  private async setCheckinCheckoutStatus(payload) {
    this.accountVisit.gpsCheckInDetails['indskr_checkoutstatus'] = payload.indskr_checkoutstatus;
    this.accountVisit.gpsCheckInDetails['indskr_checkoutdatetime'] = payload.indskr_checkoutdatetime;
    await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.accountVisit, true);
    this.checkinProcess = false;
  }

  async checkInForMeeting() {
    let distance;
    let gpsCheckInStatus;

    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('NETOWRK_UNAVAILABLE_CHECKIN'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      return;
    }

    await this.uiService.displayLoader({ duration: 50000 });
    const currentCoordinates: any = this.authService.user.currentLocation ? this.authService.user.currentLocation : { latitude: 0, longitude: 0 };

    this.isManualCheckinProcess = false;

    if (currentCoordinates.latitude <= 0) {
      this.notificationService.notify(this.translate.instant('UNABLE_TO_GET_LOCATION'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      await this.uiService.dismissLoader();
      this.checkinProcess = false;

      // if isManualGPSCheckinEnabled is ON then force user to do manual checkin incase of UNABLE_TO_GET_LOCATION 
      if (this.isManualGPSCheckinEnabled && this.locationService.isLocationEnabledOnDevice) {
        let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', this.translate.instant('MANUAL_CHECKIN_DISTANCE_WARNING'), 'MANUAL_CHECKIN');
        if (!confirmedManualCheckIn) {
          this.checkinProcess = false;
          this.initOptions();
          if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
            this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
          }
          return;
        };
        this.isManualCheckinProcess = true;
        gpsCheckInStatus = GPS_STATUS.INVALID;
      }
      else {
        this.initOptions();
        return;
      }
    };

    // if meeting doesn't have latitude and logitude
    if (!this.accountVisit.indskr_meetinglocationlatitude && !this.accountVisit.indskr_meetinglocationlongitude) {
      const coordinatesFromAddress = await this.locationService.convertAddressToCoords(this.accountVisit.location);
      if (coordinatesFromAddress) {
        this.accountVisit.indskr_meetinglocationlongitude = coordinatesFromAddress.longitude;
        this.accountVisit.indskr_meetinglocationlatitude = coordinatesFromAddress.latitude;
        await this.meetingDataService.updateMeetingLocation(this.accountVisit);
      } else if (this.isManualGPSCheckinEnabled) {
        await this.uiService.dismissLoader();
        // if isManualGPSCheckinEnabled is ON then force user to do manual checkin incase of INVALID_ADDRESS 
        let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', this.translate.instant('MANUAL_CHECKIN_DISTANCE_WARNING'), 'MANUAL_CHECKIN');
        if (!confirmedManualCheckIn) {
          this.checkinProcess = false;
          this.initOptions();
          if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
            this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
          }
          return;
        };
        this.isManualCheckinProcess = true;
        gpsCheckInStatus = GPS_STATUS.INVALID;
      }
      else {
        this.notificationService.notify(this.translate.instant('INVALID_ADDRESS'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
        await this.uiService.dismissLoader();
        this.checkinProcess = false;
        this.initOptions();
        return;
      }
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, this.accountVisit.indskr_meetinglocationlatitude, this.accountVisit.indskr_meetinglocationlongitude, 'M');
    } else {
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, this.accountVisit.indskr_meetinglocationlatitude, this.accountVisit.indskr_meetinglocationlongitude, 'M');
    }

    await this.uiService.dismissLoader();

    if (!this.isManualCheckinProcess) {
      if (distance > this.authService.user.validCallDistance) {

        if (this.isManualGPSCheckinEnabled) {
          const fixedDistance = (distance / 1000).toFixed(2);
          const alertMsg = this.translate.instant('MANUAL_CHECKIN_WITH_DISTANCE_WARNING_AC', { distance: fixedDistance });
          let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', alertMsg, 'MANUAL_CHECKIN');
          if (!confirmedManualCheckIn) {
            this.checkinProcess = false;
            this.initOptions();
            if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
              this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
            }
            return;
          };
          this.isManualCheckinProcess = true;
          gpsCheckInStatus = GPS_STATUS.INVALID;
        } else {
          let confirmedCheckIn = await this.showCheckInDistanceAlert((distance / 1000).toFixed(2));
          if (!confirmedCheckIn) {
            this.checkinProcess = false;
            this.initOptions();
            if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
              this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
            }
            return;
          };
          gpsCheckInStatus = GPS_STATUS.INVALID;
        }

      } else {
        let confirmedCheckIn = await this.showCheckInConfirmAlert();
        if (!confirmedCheckIn) {
          this.checkinProcess = false;
          this.initOptions();
          if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
            this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
          }
          return;
        };
        gpsCheckInStatus = GPS_STATUS.VALID;
      }
    }

    let payload: any = {
      indskr_checkinlongitude: currentCoordinates.longitude,
      indskr_checkinlatitude: currentCoordinates.latitude,
      indskr_checkindatetime: new Date(),
      indskr_checkindistance: distance,
      indskr_checkinstatus: gpsCheckInStatus
    }

    if (this.isManualGPSCheckinEnabled && this.isManualCheckinProcess) {
      this.openPhotoCaptureViewerForGPSActivity(GPSActivity.CHECKIN, payload, this.accountVisit);
    } else {
      await this.uiService.displayLoader({duration: 50000});

      const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(this.accountVisit, payload);
      if (!response) {
        await this.uiService.dismissLoader();
        this.checkinProcess = false;
        this.initOptions();
        return;
      }

      this.accountVisit.gpsCheckInDetails['indskr_checkinstatus'] = response.indskr_checkinstatus ?? gpsCheckInStatus;
      this.accountVisit.gpsCheckInDetails['indskr_checkindatetime'] = response.indskr_checkindatetime ?? payload.indskr_checkindatetime;
      this.accountVisit.gpsCheckInDetails['indskr_gpscheckindetailsid'] = response.indskr_gpscheckindetailsid;
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.accountVisit, true);
      await this.uiService.dismissLoader();
      this.checkinProcess = false;
      this.notificationService.notify(this.translate.instant('GPS_CHECKIN_SUCESS'), 'Check In', 'top', ToastStyle.INFO, 3000);
      this.openContactSelection();
    }
    this.initOptions();
  }

  async openContactSelection() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }

    if (!this.accountVisit?.gpsCheckInDetails?.indskr_checkinstatus) {
      this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
      return;
    }
    
    this.initOptions();
    const selectedContactIDs = _.isEmpty(this.accountVisit.contacts) ? [] : this.accountVisit.contacts.map(con => con.ID);
    // this.accountVisit = <AppointmentActivity>this.activityService.activities.filter(ac => ac.type === ActivityType.Appointment && ac['indskr_isparentcall'])[0];
    let { target, nonTarget }: { target: Contact[]; nonTarget: Contact[]; } = this.getContactsToDisplay();
    [...target, ...nonTarget].map(contact => {
      contact['selected'] = selectedContactIDs.includes(contact.ID);
      contact.hasConsent = contact.hasConsent ? contact.hasConsent : this.contactService.activeConsents.find(c => c.contactid == contact.ID);
      const segmentation = this.shouldDisplayCustomerSegment ? _.isEmpty(contact.productSegmentations) ? null : contact.productSegmentations[0].segmentation : null;
      contact['secondaryText'] = [contact.indskr_externalid, segmentation, !contact.hasConsent ? this.translate.instant('NO_CONSENT_SHORT_CALL') : null].filter(Boolean).join(' | ')
    });
    const pageModal = await this.modalCtrl.create({
      component: IoContactList, componentProps: {
        view: 'io-contact-list',
        contacts: _.cloneDeep([...target, ...nonTarget]),
        selectedContactIds: selectedContactIDs,
        maxHeightPercent: 60,
      }, backdropDismiss: true, cssClass: ['io-contact-list', 'dynamic-height-modal']
    })
    await pageModal.present();
    await pageModal.onDidDismiss().then(async (obj) => {
      if (obj?.data?.isDone) {
        if (this.device.isOffline) {
          this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
          return;
        }
        await this.uiService.displayLoader();
        const selectedContacts = obj.data.data.filter(con => con.selected);
        const oldContacts = this.accountVisit.contacts;
        this.accountVisit.contacts = selectedContacts;
        await this.meetingDataService.updateContactToAccountVisit(this.accountVisit, oldContacts).then((success) => {
          this.contactListToBeDisplayed$.next([...this.accountVisit.contacts]);
        });
        await this.uiService.dismissLoader();
      }
    });
  }

  private openPhotoCaptureViewerForGPSActivity(activityType: number, gpsPayload, activity) {
    if (!this.device.isNativeApp) {
      this.notificationService.notify(this.translate.instant('GPS_PHOTOS_WEB_WARNING'), 'Meeting Details', 'top', ToastStyle.DANGER);
      this.checkinProcess = false;
      this.initOptions();
      return;
    }
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
      return;
    }
    let header;// = activityType === GPSActivity.CHECKIN ? 'MANUAL_CHECKIN': 'MANUAL_CHECKOUT';
    let activityPhotos: GPSActivityPhoto;
    if (activityType === GPSActivity.CHECKIN) {
      header = 'MANUAL_CHECKIN';
      if (!_.isEmpty(activity.gpsActivityPhotos)) {
        activityPhotos = activity.gpsActivityPhotos.find(gpsActivityPhoto => gpsActivityPhoto.indskr_type === 548910000);
      }
    } else {
      header = 'MANUAL_CHECKOUT';
      if (!_.isEmpty(activity.gpsActivityPhotos)) {
        activityPhotos = activity.gpsActivityPhotos.find(gpsActivityPhoto => gpsActivityPhoto.indskr_type === 548910001);
      }
    }
    let accountName = '';
    let etmsCode = '';
    if (!_.isEmpty(activity.accounts)) {
      accountName = activity.accountString;
      const account = this.accountService.getAccountById(activity.accounts[0].id);
      if (account?.extendedDetails['externalId']) {
        etmsCode = account.extendedDetails['externalId']
      }
    }
    const photoModel: IndPhotoCaptureViewerDataModel = {
      pageTitle: this.translate.instant(header),
      photosLimit: activityPhotos ? (10 - activityPhotos.photoAttachments.length) : 10,
      photoNameFormat: `${this.authService.user.displayName} ${accountName} ${etmsCode} {currentTimestamp} ${this.translate.instant(header)}.PNG`,
      photoOrigin: PhotoOrigin.GPS_CHECKIN,
      accountId: _.isEmpty(activity.accounts) ? '' : activity.accounts[0].id,
      errorMessage: this.translate.instant('MAX_PHOTO_NOTIFICATION', { limit: 10 }),
      callbackEvent: (data: []) => this._handlePhotoCaptureComponentCallbackForGPSActivity(data, activityType, gpsPayload, activity)
    };
    this.navService.pushWithPageTracking(IndPhotoCaptureViewerComponent, PageName.IndPhotoCaptureViewerComponent, { photoModel: photoModel }, PageName.IndPhotoCaptureViewerComponent);
  }

  private async _handlePhotoCaptureComponentCallbackForGPSActivity(data: Photo[], activityType: number, gpsPayload, activity: AppointmentActivity) {
    if (!_.isEmpty(data)) {
      let gpsActivitiyPayload = [{
        'indskr_type': activityType,
        'indskr_gpscheckindetailsid': activity.gpsCheckInDetails['indskr_gpscheckindetailsid'],
        'photoAttachmentIds': []
      }];

      await this.uiService.displayLoader({ duration: 50000 });
      if (activityType === GPSActivity.CHECKIN) {
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(activity, gpsPayload, data, gpsActivitiyPayload);
        if (!response) {
          this.notificationService.notify(activityType === GPSActivity.CHECKIN ? this.translate.instant('GPS_CHECKIN_PHOTOS_FAILED') : this.translate.instant('GPS_CHECKOUT_PHOTOS_FAILED'), 'Check In', 'top', ToastStyle.DANGER, 3000);
          await this.uiService.dismissLoader();
          this.checkinProcess = false;
          this.initOptions();
          return;
        }

        activity.gpsCheckInDetails['indskr_checkinstatus'] = response.indskr_checkinstatus ?? GPS_STATUS.INVALID;
        activity.gpsCheckInDetails['indskr_checkindatetime'] = response.indskr_checkindatetime ?? gpsPayload.indskr_checkindatetime;
        activity.gpsCheckInDetails['indskr_gpscheckindetailsid'] = response.indskr_gpscheckindetailsid;
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
        this.checkinProcess = false;
      } else {
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(activity, gpsPayload, data, gpsActivitiyPayload);
        if (!response) {
          this.notificationService.notify(activityType === GPSActivity.CHECKIN ? this.translate.instant('GPS_CHECKIN_PHOTOS_FAILED') : this.translate.instant('GPS_CHECKOUT_PHOTOS_FAILED'), 'Check In', 'top', ToastStyle.DANGER, 3000);
          this.checkinProcess = false;
          await this.uiService.dismissLoader();
          this.initOptions();
          return;
        };
        activity.gpsCheckInDetails['indskr_checkoutstatus'] = gpsPayload.indskr_checkoutstatus;
        activity.gpsCheckInDetails['indskr_checkoutdatetime'] = gpsPayload.indskr_checkoutdatetime;
      }

      this.notificationService.notify(activityType === GPSActivity.CHECKIN ? this.translate.instant('GPS_CHECKIN_PHOTOS_SUBMITTED') : this.translate.instant('GPS_CHECKOUT_PHOTOS_SUBMITTED'), 'Check In', 'top', ToastStyle.INFO, 3000);
      if (activityType === GPSActivity.CHECKOUT) {
        const markedComplete = await this.markCompleteAccountVisit();
        if (!markedComplete) {
          this.selectedActivityOption = 'CHECK_IN_ACCOUNT_VISIT';
          await this.uiService.dismissLoader();
          this.initOptions();
          return;
        }
        await this.uiService.dismissLoader();
        await this.activityService.upsertMeetingsOfflineData(this.accountVisit);
        this.contactListToBeDisplayed$.next([]);
        await this.fetchAffContacts()
        this.activityService.selectedActivity = null;
        this.clearSelection();
      } else{
        await this.uiService.dismissLoader();
        this.openContactSelection();
      }
    }
    else {
      if (!this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
      this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
      return;
    }
      this.initOptions();
      this.checkinProcess = false;
    }
  }

  async showCheckInConfirmAlert(): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKIN'),
      message: this.translate.instant('CHECKIN_CONFIRM_MESSAGE_AC'),
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return false;
        },
      }, {
        text: this.translate.instant('CHECK_IN_FOOTBAR'),
        role: 'confirm',
        handler: () => {
          return true;
        },
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm";
    });

  }

  async showCheckInDistanceAlert(distance): Promise<any> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKIN'),
      message : this.translate.instant('CHECKIN_DISTANCE_WARNING_AC', { distance }),
      buttons: [{
        text: this.translate.instant('CHECKIN_ANYWAY'),
        role: 'confirm',
        handler: () => {
          return true
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return  false;
        },
      }],
    });
    await alert.present();
   
    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm" ; 
    });
  }

  async showManualGPSConfirmAlert(header,message, button) : Promise<any> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(header),
      message: message,
      buttons: [{
        text: this.translate.instant(button),
        role: 'confirm',
        handler: () => {
          return  true;
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return  false;
        }
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm"; 
    });
  }

  public async openContentFilter() {
    // console.log('Content filter');
    const pageModal = await this.modalCtrl.create({
      component: IoContentFilter, componentProps: {
        view: 'short-call-home',
        tags: this.filterTags,
      }, backdropDismiss: true, cssClass: ['short-call-home', 'dynamic-height-modal'],
    })
    await pageModal.present();
    await pageModal.onDidDismiss().then(async (data) => {
      const filter: { type: string, tags: ShortCallFilterField[], modified: boolean } = data?.data;
      console.log(filter);
      if (filter?.modified) this.initContent();
    });
  }

  public onContentSelect(card: ContentCard, ecard: boolean) {
    console.log("Selected Content: ", card);
    console.log("Selected Activity Option: ", this.selectedActivityOption);
    if (this.accountVisit && !this.accountVisit.gpsCheckInDetails?.indskr_checkinstatus) {
      this.notificationService.notify(this.translate.instant('PLEASE_CHECK_IN_CONTINUE'), 'Short Call Home', 'top', ToastStyle.INFO);
      return;
    }
    switch (this.selectedActivityOption) {
      case 'MEETING':
      case 'REMOTE_HOME':
        this.createMeeting(card, ecard);
        break;
      case 'CHECK_IN_ACCOUNT_VISIT':
        this.playPresentation(card, ecard);
        break;
      default:
        this.openContentPreview(card);
        break;
    }
  }

  private openContentPreview(card: ContentCard) {
    const pres = this.nonExpiredContents.find(p => p.contentId == card.contentId);
    if (!pres.downloaded && this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "presentation-list", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    this.navService.pushWithPageTracking(PresentationPreviewComponent, PageName.PresentationPreviewComponent, { from: PageName.ShortCallHomeComponent, viewMode: PresentationView.SHORTCALLHOME });
    this.presentationService.presShowRightPane = true;
    this.presentationService.setCarouselBriefcase([pres]);
    this.presentationService.setCurrentSelectedPres(pres);
    let page: Page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null;
    if (pres.customePageLocation) {
      if (isNaN(parseInt(pres.customePageLocation))) {
        page = this.presentationService.presPages.find(o => o.name == pres.customePageLocation);
      }
      else {
        page = this.presentationService.presPages.find(o => o.name == 'Slide' + pres.customePageLocation);
      }
    }
    this.presentationService.setCurrentSelectedPresPage(page);
    this.presentationService.viewMode = PresentationView.SHORTCALLHOME;
    setTimeout(() => {
      if (pres.indskr_ecard) {
        this.events.publish('footer-toolbar:carousel-toggle');
        this.presentationService.swipeStatus = SwipeStatus.DISABLED_BY_USER;
      } else {
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_DEFAULT;
      }
    }, 500);
    if (pres.indskr_ecard)
      this.presentationService.hideCarousel = true;
    else
      this.presentationService.hideCarousel = false;
  }

  private async playPresentation(card: ContentCard, ecard: boolean) {
    if (!this.accountVisit) return;
    if (!this.selectedContact) {
      this.notificationService.notify(this.translate.instant('PLEASE_SELECT_HCP'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    } else if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    const nestedMeeting = this.activityService.getExistingNestedMeetingWithContact(this.accountVisit.ID, this.selectedContact.ID);
    if (nestedMeeting) {
      if (nestedMeeting.isCompleted) {
        this.notificationService.notify(this.translate.instant('NESTED_MEETING_COMPLETED'), 'Short Call Home', 'top', ToastStyle.DANGER);
        return;
      }
      this.activityService.selectedActivity = nestedMeeting;
      const presentation = this.nonExpiredContents.find(p => p.contentId == card.contentId);
      try {
        const shortcall = this.activityService.selectedActivity as AppointmentActivity;
        //Cann't convert short call meeting to long call
        if (!_.isEmpty(shortcall.presentations)) {
          if (shortcall.indskr_shortcall && !presentation.indskr_ecard) {
            this.notificationService.notify(this.translate.instant('EDA_TO_SHORT_MEETING'), 'Short Call Home', 'top', ToastStyle.DANGER);
            return;
          } else if (!shortcall.indskr_shortcall && presentation.indskr_ecard) {
            this.notificationService.notify(this.translate.instant('ECARD_TO_LONG_MEETING'), 'Short Call Home', 'top', ToastStyle.DANGER);
            return;
          }
        }

        await this.uiService.displayLoader();

        const startDate = new Date();
        const meetingDuration = ecard ? 5 : this.authService.user?.meeting_duration || 30;
        const endDate = addMinutes(startDate.getTime(), meetingDuration);

        if (_.isEmpty(this.activityService.selectedActivity['presentations'])) {
          this.activityService.selectedActivity['presentations'] = [];
        }

        this.activityService.selectedActivity['presentations'] = _.uniqBy([...this.activityService.selectedActivity['presentations'], presentation], 'ioPresentationId');

        const payload = new UpdateMeetingPayload(
          shortcall.subject,
          this.activityService.selectedActivity.location,
          startDate,
          endDate
        );

        shortcall.indskr_shortcall = payload.indskr_shortcall = presentation.indskr_ecard;

        if (shortcall.indskr_shortcall) {
          payload.subject = `${this.selectedContact.fullName} - ${this.translate.instant('SHORT_MEETING')} - ${shortcall.activityTypeName} - ${this.authService.user.displayName}`
        }

        await Promise.all([
          this.meetingDataService.addContentToMeeting(shortcall.presentations, shortcall),
          this.meetingDataService.updateMeeting(shortcall, payload)
        ]);

        this.activityService.selectedActivity.subject = payload.subject;
        this.activityService.selectedActivity.scheduledStart = startDate;
        this.activityService.selectedActivity.scheduledEnd = endDate;

        // Assign long & lat of account
        const selectedAccountAddress = this.authService.user.buSettings
                                    && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
                                    ? await this.accountService.getPrimaryAddressOfAccount(this.selectedAccount.id) : null;
        if (selectedAccountAddress) {
          shortcall.indskr_meetinglocationlongitude = selectedAccountAddress.longitude;
          shortcall.indskr_meetinglocationlatitude = selectedAccountAddress.latitude;
        }

        await this.navigateToPresentationMeetion(shortcall, presentation);
      } catch (err) {
        this.uiService.dismissLoader();
      }
    }
  }

  private prepContactToDisplayList() {
    if (!Array.isArray(this.affiliatedContacts)) {
      this.contactListToBeDisplayed$.next([]);
      return;
    }

    let { target, nonTarget }: { target: Contact[]; nonTarget: Contact[]; } = this.getContactsToDisplay();
    this.targetContactsCount = target.length;
    // Merge them
    this.contactListToBeDisplayed$.next([...target, ...nonTarget]);
  }

  private getContactsToDisplay() {
    let target: Contact[] = [];
    let nonTarget: Contact[] = [];

    if (this.hasTerritoryFAEnabled) {
      // Separate out target & non-target contacts
      const reducedContacts = this.affiliatedContacts.reduce((result, contact) => {
        const isTargetContact = contact.tagLabels?.includes(this.territoryService.currentListPeriod.tagLabel);
        contact.isTargetContact = isTargetContact;

        return isTargetContact
          ? { ...result, target: [...result.target, contact] }
          : { ...result, nonTarget: [...result.nonTarget, contact] };
      },
        {
          target: [],
          nonTarget: [],
        });

      target = reducedContacts.target;
      nonTarget = reducedContacts.nonTarget;
    } else {
      nonTarget = [...this.affiliatedContacts];
    }
    // Sort both list
    target = sortObjArrayByStringFields(
      target,
      'fullName',
      undefined,
      false,
      this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
    );
    nonTarget = sortObjArrayByStringFields(
      nonTarget,
      'fullName',
      undefined,
      false,
      this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
    );
    return { target, nonTarget };
  }

  private async fetchAffContacts() {
    this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.selectedAccount]);
    if (this.shouldDisplayCustomerSegment) {
      this.contactService.mapProductSegmentationsToContactsForShortCallHome(this.affiliatedContacts);
    }
    this.prepContactToDisplayList();
  }

  async openAccountSelection() {
    this.accountService.accessedAccountListFrom = PageName.ShortCallHomeComponent;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': ComponentViewMode.ADDNEW,
      selectedAccountId: this.selectedAccount.id,
      callbackEvent: (data) => this.onAccountSelection(data)
    }, PageName.AccountPageComponent);
  }

  async onAccountSelection(data) {
    if (data && data.isDone) {
      // Reset selected contact if a new account is selected
      if (
        this.selectedAccount?.id
        && data.selectedItem?.id
        && this.selectedAccount.id !== data.selectedItem.id
      ) {
        this.resetSelectedContact();
      }
      this.selectedAccount = data.selectedItem;
      // Mark current selected account
      this.accountService.shortCallSelectedAccountId = this.selectedAccount.id
      await this.fetchAffContacts();
    }
  }

  private getMostRecentActivityTitle(contact) {
    const cache = this.contactService.getMostRecentActivityDataById(contact.ID);

    // Display cache upfront
    if (cache?.mostRecentActivityTitle) {
      this.mostRecentActivityTitle = cache.mostRecentActivityTitle;
    }
    // If offline, fetch is already triggered, or it has been less than 5 min since last fetch, return
    // UPDATE: decided to trigger the fetch everytime without 5 min wait time.
    // Putting 10s as the min delay
    if (
      this.device.isOffline
      || cache?.isFetching
      || (cache?.recentFetchTime && new Date().getTime() - 10000 < cache.recentFetchTime)
    ) {
      return;
    }

    // Start fetch
    // Update fetch flag to cache
    this.contactService.setMostRecentActivityData(
      contact.ID,
      {
        isFetching: true,
        mostRecentActivityTitle: cache?.mostRecentActivityTitle ?? null,
        recentFetchTime: cache?.recentFetchTime ?? null,
      }
    );
    this.mostRecentActivityLoadingCounter.incCounter();

    this.contactTimelineAggregatorService.fetchContactTimeline(contact)
      .then(() => {
        const mostRecentActivitySubject = this.contactTimelineAggregatorService.getMostRecentActivitySubject(contact);
        // Only update label if it's current contact
        if (this.selectedContact.ID === contact.ID) {
          this.mostRecentActivityTitle = mostRecentActivitySubject;
        }
        this.contactService.setMostRecentActivityData(
          contact.ID,
          {
            isFetching: false,
            mostRecentActivityTitle: mostRecentActivitySubject,
            recentFetchTime: new Date().getTime(),
          }
        )
      })
      .catch((err) => {
        console.error('short-call-home: getMostRecentActivityTitle: ', err);
      })
      .finally(() => this.mostRecentActivityLoadingCounter.decCounter());
  }

  private async hasConsent(contact: Contact): Promise<boolean> {
    let hasConsent = false;
    try {
      hasConsent = await this.meetingService.getActiveGeneralConsent(contact?.ID);
    } catch (error) {
      console.error(' short-call-home: hasConsent: ', contact, error);
    }
    return hasConsent;
  }

  async contactChipClick(contact: Contact, fromSearch=false) {
    if (this.selectedContact) {
      let curSelectedContact = this.selectedContact;
      if (!fromSearch) {
        // Reset selection & most recent activity label
        this.mostRecentActivityTitle = '';
        this.selectedContact = undefined;
      }
      // It was just de-selecting a contact
      if (curSelectedContact.ID === contact.ID) {
        return;
      }
    }

    // Selecting a contact
    this.selectedContact = contact;
    this.getMostRecentActivityTitle(contact);
    setTimeout(() => {
      if(document.getElementById(contact.ID))
        document.getElementById(contact.ID).scrollIntoView();
    }, 100);
    if (this.hasConsentCheckFAEnabled) {
      // Run consent check and notify if FA enabled
      this.isCheckingConsent = true;
      this.hasConsent(contact)
      .then(hasConsent => {
        this.isCurrentContactConsented = !!(hasConsent);
        this.isCheckingConsent = false;
        if (!hasConsent) {
          this.notificationService.notify(this.translate.instant('SHORT_MEETING_NO_CONSENT'), 'Short Call Home', 'top', ToastStyle.INFO);
        }
      });
    }
  }
  openContactDetail(contact: Contact) {
    this.uiService.contactDetailsSegment = 'info';
    this.contactService.contactInformation = contact;
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    this.navService.pushWithPageTracking(
      ContactPageComponent,
      PageName.ContactPageComponent,
      {
        openContactDetail: true,
        contact,
      },
      PageName.ShortCallHomeComponent,
    );
  }

  private async createMeeting(content: ContentCard, ecard: boolean) {
    if (this.isCheckingConsent) {
      return;
    }
    if (!this.selectedContact) { 
      this.notificationService.notify(this.translate.instant('SHORT_CALL_HCP_MANDATORY'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    } else if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    await this.uiService.displayLoader();

    // Consent check can block UI and make user feel the app not responsive
    // hence placed this check after the loader
    if (
      this.hasConsentCheckFAEnabled
      && this.selectedContact
      && !this.isCurrentContactConsented
    ) {
      // Selected contact does not have consent. Notify and return.
      await this.uiService.dismissLoader();
      this.notificationService.notify(this.translate.instant('SHORT_MEETING_NO_CONSENT_CONTACT_TO_START_MEETING'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }

    const presentation = this.nonExpiredContents.find(p => p.contentId == content.contentId);
    try {
      let accountCoordinates: LocationCoordinates;
      // Assign long & lat of account
      const selectedAccountAddress = await this.accountService.getPrimaryAddressOfAccount(this.selectedAccount.id);
      if (
        selectedAccountAddress
        && this.authService.user.buSettings
        && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
      ) {
        accountCoordinates = {
          longitude: selectedAccountAddress.longitude,
          latitude: selectedAccountAddress.latitude,
        };
      }
      await this.meetingService.createMeetingFromShortCallHome(this.selectedAccount, this.selectedContact, presentation, ecard, selectedAccountAddress?.compositeAdd, accountCoordinates);
      const shortcall = this.activityService.selectedActivity as AppointmentActivity;
      await this.navigateToPresentationMeetion(shortcall, presentation);
    } catch (err) {
      this.uiService.dismissLoader();
      this.notificationService.notify(this.translate.instant('SHORT_MEETING_CREATE_FAILED'), 'Short Call Home', 'top', ToastStyle.DANGER); 
    }
  }

  private async navigateToPresentationMeetion(shortcall: AppointmentActivity, presentation: Presentation) {
    await this.meetingStructureService.createEmbeddedInteractions(shortcall);
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      this.activityService.selectedActivity.isMeetingPresentationDetailed = this.activityService.selectedActivity['activityPresentations'].length > 0;
      this.presentationService.clearCurrentPresentation();
      this.presentationService.setCurrentSelectedPres(null);
      this.presentationService.setCarouselBriefcase(this.meetingStructureService.contents);
      const firstActivity: EmbeddedInteraction = this.meetingStructureService.embeddedIntreactions?.length > 0 ? this.meetingStructureService.embeddedIntreactions[0] : null;
      this.meetingStructureService.setCurrentMeetingActivity(firstActivity);
      this.presentationService.setCurrentSelectedPres(presentation);
      const page: Page = _.isEmpty(this.presentationService.presPages) ? null : this.presentationService.presPages[0];
      this.presentationService.setCurrentSelectedPresPage(page);
      this.presentationService.selectedActivityId = shortcall.ID;
      this.presentationService.viewMode = PresentationView.MEETING;
      this.activityService.activityDetailsLoaded = true;
      this.footerService.initButtons(FooterViews.PreviewMeeting);
      // This ensures that the footer doesn't change when HCP joins
      this.uiService.activeView = 'Meeting';
      const onShortCallCompletion = (data) => { if (data != undefined && !this.accountVisit) this.selectOption(this.selectedActivityOption); };
      await this.navService.pushWithPageTracking(PresentationMeetingComponent, PageName.PresentationMeetingComponent,
        { from: PageName.ShortCallHomeComponent, onShortCallCompletion: onShortCallCompletion}, PageName.PresentationMeetingComponent);
      this.uiService.dismissLoader();
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  async handleFacetSelection(data) {
    console.log(data);
    if(null != data.contactId){
      const contact = this.contactService.contacts.find(con => con.ID === data.contactId);
      this.selectedAccount = this.accountService.accounts.find(acc => acc.id === data.accountId);
      // Mark current selected account
      this.accountService.shortCallSelectedAccountId = this.selectedAccount.id
      await this.fetchAffContacts();
      this.selectedActivityOption = this.shortCallActivityOptions[0]; //Default select first option
      this.contactChipClick(contact, true);
    }
    this.suggestionsActive = false;
    this.suggestionsData = [];
    this.searchText = '';
  }

  onSearchBlur($event: FocusEvent) {
    setTimeout(async() => {
      console.log('wait for blur time out.');
      this.suggestionsActive = false;
      this.suggestionsData = [];
      this.searchText = '';
    }, 200);
  }

  public async scrapTodo(todo: TodoItem, event) {
    event.stopPropagation();
    console.log("scrapTodo ~ todo:", todo);
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
   
      const activity: Activity = this.activityService.getActivityByID(todo.id);
      if (activity) {
        switch (activity.type) {
          case ActivityType.Appointment:
            // await this.uiService.displayLoader();
            // await this.meetingDataService.updateMeetingStatus(activity as AppointmentActivity, MeetingStatus.CANCELED);
            // this.activityService.removeActivity(activity);
            this.events.publish('deleteMeeting', activity);
            break;
          case ActivityType.Email:
            this.alertService.showAlert({
              header: this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE'),
              message: this.translate.instant('R_U_SURE_SCRAP_MESG')
            }, this.translate.instant('SCRAP')
            ).then(async res => {
              if (res.role == "ok") {
                await this.uiService.displayLoader();
                await this.emailService.scrapEmailActivity(activity as EmailActivity);
                await this.uiService.dismissLoader();
              }
          });
            break;
          case ActivityType.FollowUp:
            await this.cancelFollowUp(activity as FollowUpActivity);
            break;
          default:
            break;
        }
        this.initTodoList(false);
      }
  }

  public async cancelFollowUp(currentFollowUpActivity: FollowUpActivity): Promise<void> {
    const res = await this.alertService.showAlert({
      header: this.translate.instant('FOLLOW_UP_ALERT_TITLE_CANCEL_FOLLOW_UP'),
      message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_CANCEL_FOLLOW_UP'),
      inputs: [{ type: 'text', name: "reasonforcancellation", placeholder: this.translate.instant('ENTER_REASON_FOR_CANCELLATION') }],
    }, this.translate.instant('OK')
    );
    if (res.role === 'ok') {
      if (res.data && res.data.values && res.data.values.reasonforcancellation == '') {
        this.notificationService.notify(this.translate.instant("ENTER_REASON_FOR_CANCELLATION"), 'Follow up cancel', 'top', ToastStyle.INFO);
        return;
      }
      await this.uiService.displayLoader();
      currentFollowUpActivity.state = 2;
      currentFollowUpActivity.status = 6;
      currentFollowUpActivity.pendingPushToDynamics = true;
      currentFollowUpActivity.reasonForCancellation = res.data.values.reasonforcancellation;

      await this.followUpActivityDataService.updateFollowUpActivity({
        onDynamics: !this.device.isOffline && !this.device.isDeviceRealOffline,
        onLocalCopy: true,
        onLocalDatabase: true,
        operationDetail: {
          code: 'CANCEL_FOLLOWUP',
          message: 'cancelled followup'
        }
      }, [currentFollowUpActivity], new Date().getTime()).then(succ => {

      }).catch(async (error) => {

      });
      await this.uiService.dismissLoader();
    }
  }

  public updateTodo(todo: TodoItem, event) {
    event.stopPropagation();
    console.log("updateToDo ~ todo:", todo);
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    const activity: Activity = this.activityService.getActivityByID(todo.id);
    switch(todo.type) {
      case ActivityType.Appointment:
        this.openStartDatePicker(activity, CurViewPageType.Appointment);
        break;
      case ActivityType.Email:
        this.openStartDatePicker(activity, CurViewPageType.Email);
        break;
      default:
        break;
    }
  }

  async openStartDatePicker(activity: Activity, type: CurViewPageType) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    const popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: type,
          startDateTimeValue: activity.scheduledStart,
          endDateTimeValue: activity.scheduledEnd,
          from: PageName.ShortCallHomeComponent
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then(async (obj: any) => {
      if (this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
        return;
      }
      if (obj !== null && !_.isEmpty(obj.data) && obj.data.startTime !='') {
        const startDate = moment(obj.data.startTime).format();
        if (activity.type === ActivityType.Email) {
          this.emailService.selectedActivity = activity as EmailActivity;
          await this.emailService.updateEmailActivity({ scheduledend: new Date(startDate).getTime(), scheduledstart: new Date(startDate).getTime(), indskr_suggestionrescheduled: true }, EmailActionType.SCHEDULE);
          this.emailService.selectedActivity = null;
        } else if (activity.type === ActivityType.Appointment) {
          const appointment = activity as AppointmentActivity;
          await this.uiService.displayLoader();
          activity.scheduledStart = new Date(startDate);
          activity.scheduledEnd = new Date(obj.data.endTime);
          const addedMinutes: number = appointment.indskr_shortcall ? 5 : this.authService.user?.meeting_duration || 30 ;
          activity.scheduledEnd = addMinutes(activity.scheduledStart, addedMinutes);
          const payload = new UpdateMeetingPayload(
            activity.subject,
            activity.location,
            activity.scheduledStart,
            activity.scheduledEnd,
            activity.notes,
            false
          );
          payload['indskr_suggestionrescheduled'] = true;
          await this.meetingDataService.updateMeeting(appointment, payload, false, true, true, true);
          await this.activityService.upsertMeetingsOfflineData(appointment);
          await this.uiService.dismissLoader();
        }
        this.initTodoList(false);
      }
    });
    popover.present();

  }

  public async openTodoDetails(todo: TodoItem) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    await this.uiService.displayLoader();
    const activity: Activity = this.activityService.getActivityByID(todo.id);
    this.activityService.selected = activity;
    await this.activityDataService.updateActivityDetails(this.activityService.selectedActivity);
    switch(activity.type) {
      case ActivityType.Appointment:
        this.openAppointmentDetails(activity as AppointmentActivity);
        break;
      case ActivityType.Email:
        this.opemEmailActivityDetails(activity as EmailActivity);
        break;
      case ActivityType.FollowUp:
        this.openFollowupDetails(activity as FollowUpActivity);
        break;
      default:
        break;
    }
    this.uiService.showRightPane = true;
    await this.uiService.dismissLoader();
    this.initTodoList(false);
  }

  public openAppointmentDetails(appointment: AppointmentActivity ) {
    this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ShortCallHomeComponent, {from: PageName.ShortCallHomeComponent});
    this.events.publish('activities:appConfigFields');
    this.events.publish('detectChangesOnActivityDetails');
    this.footerService.initButtons(FooterViews.Activities);
  }

  public opemEmailActivityDetails(email: EmailActivity ) {
    this.emailService.viewType = EmailViewType.FROM_AGENDA;
    this.emailService.selectedActivity = email;
    this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ShortCallHomeComponent, { from: PageName.ShortCallHomeComponent });
  }

  public openFollowupDetails(task: FollowUpActivity ) {
    this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ShortCallHomeComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
  }
  

  private async markCompleteAccountVisit() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    let shouldThrowError = false;
    let errorToThrow;

    const payload: AppointmentActivity[] = [];
    if (!this.accountVisit) {
      throw(new Error('markCompleteAccountVisit has no selected activity'));
    }
    const activity = this.accountVisit;
    const origState = this.accountVisit.state;
    const dateCompleted = new Date().getTime().toString();
    activity.state = 1;
    activity.omnip_datecompleted = dateCompleted;
    if (!!((this.activityService.selectedActivity as AppointmentActivity).indskr_parentcallid)) {
      // This activity is a nested meeting
      const activityPresentationGpsData = await this.presentationService.getActivityPresentationGpsData(activity.offlineMeetingId);
      // Update GPS data for the nested meeting
      if (
        this.authService.user.buSettings
        && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
        && activityPresentationGpsData
      ) {
        const meetingDistancePayload = this.presentationService.getActivityPresentationGpsDataPayload(activity, activityPresentationGpsData);
        if (meetingDistancePayload) {
          if (meetingDistancePayload.indskr_meetingdistance) {
            activity.indskr_meetingdistance = meetingDistancePayload.indskr_meetingdistance;
          } else if (meetingDistancePayload.indskr_reasonforlocationcapturefailure) {
            activity.indskr_reasonforlocationcapturefailure = meetingDistancePayload.indskr_reasonforlocationcapturefailure;
          }
        }
      }
    }
    payload.push(activity);

    const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(activity.ID);

    if (nestedMeetings.length > 0) {
      // // Need to check if nested meetings are all complete-able
      const notCompletedNestedMeetings: AppointmentActivity[] = nestedMeetings.filter(m => m.state !== 1);

      const activityPresentationGpsDataMap = await this.presentationService.getMultiActivityPresentationGpsData(notCompletedNestedMeetings.map(m => m.offlineMeetingId));
      for (let i = 0; i < notCompletedNestedMeetings.length; i++) {
        const nActivity = notCompletedNestedMeetings[i];
        nActivity.state = 1;
        nActivity.omnip_datecompleted = dateCompleted;
        // this.activityService.updateSlideEndTime(nActivity, true);

        // Update GPS data for each nested meetings
        if (
          this.authService.user.buSettings
          && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
          && activityPresentationGpsDataMap
        ) {
          const activityPresentationGpsData = activityPresentationGpsDataMap[nActivity.offlineMeetingId] ?? null;
          const meetingDistancePayload = this.presentationService.getActivityPresentationGpsDataPayload(nActivity, activityPresentationGpsData);
          if (meetingDistancePayload) {
            if (meetingDistancePayload.indskr_meetingdistance) {
              nActivity.indskr_meetingdistance = meetingDistancePayload.indskr_meetingdistance;
            } else if (meetingDistancePayload.indskr_reasonforlocationcapturefailure) {
              nActivity.indskr_reasonforlocationcapturefailure = meetingDistancePayload.indskr_reasonforlocationcapturefailure;
            }
          }
        }

        payload.push(nActivity);
      }
    }

    try {
      if (!shouldThrowError) {
        const success = await this.meetingDataService.crudMeetingsOnlineWithOfflineUploadEndpoint(payload);
        await this.uiService.dismissLoader();
        if (success) {
          this.notificationService.notify(this.translate.instant('ACCOUNT_VISIT_COMPLETED').replace('{{count}}', nestedMeetings.length), 'Short Call Home', 'top', ToastStyle.INFO);
        } else {
          this.notificationService.notify(
            this.translate.instant('XPERIENCES_ERROR'),
            'Activity Detail',
            'top',
            ToastStyle.DANGER,
          );
        }
        this.activityService.selectedActivityOnHomeScreen = null;
        shouldThrowError = !success;
      }
    } catch (error) {
      console.error('markCompleteAccountVisit: ', error);
      this.notificationService.notify(
        this.translate.instant('XPERIENCES_ERROR'),
        'Activity Detail',
        'top',
        ToastStyle.DANGER,
      );
      shouldThrowError = true;
      errorToThrow = error;
    }

    if (shouldThrowError) {
      // Revert
      activity.state = origState;
      activity.omnip_datecompleted = undefined;
    }
    return !shouldThrowError;
  }
}
