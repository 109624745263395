<!-- Background Overlay -->
<div #overlay class="background-overlay" [ngClass]="{'show': isFooterExpanded}" (click)="closeMoreOptions()"></div>

<div *ngIf="isFooterExpanded" #menuContainer class="menu-container" [ngClass]="{'show': isFooterExpanded}">
  <div class="scrollable-container">
    <ion-grid>
      <ion-row>
        <ion-col size="3" size-sm="2" size-md="2.4" *ngFor="let option of moreOptions" class="menu-item"
          (click)="onClickMenuOption(option, $event)">
          <div class="menu-item-content">
            <img class="menu-icon" *ngIf="option.selected" [src]="option.selectedIconPath" />
            <img class="menu-icon" *ngIf="!option.selected" [src]="option.iconPath" />
            <span class="menu-label">{{option.displayName}}</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
<div #footer class="navigation-footer">
  <ion-fab>
    <ion-row>
      <ng-container *ngFor="let option of footerOptions">
        <ion-col class="menu-item" [ngClass]="{'itemSelected':option.selected}" [id]="option.id" size="4"
          (click)="onClickMenuOption(option, $event)">
          <div class="menu-item-content">
            <img class="menu-icon" *ngIf="option.selected" [src]="option.selectedIconPath" />
            <img class="menu-icon" *ngIf="!option.selected" [src]="option.iconPath" />
            <span class="menu-label">{{option.displayName}}</span>
          </div>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-fab>
</div>