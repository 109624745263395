<ion-header>

    <ion-toolbar class="left-header-toolbar" [ngClass]="{ 'offline-indicator': repService.isOfflineState, 'toolbar-android': isAndroid, 'impersontated-view': authenticationOfflineService.impersonatedUser }">
        <ion-buttons class="start-slot" slot="start">
            <ion-menu-toggle *ngIf="!authenticationOfflineService.impersonatedUser">
                <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" [ngClass]="{'whiteFont':activityService.teamViewActive}" src="assets/imgs/menu-toggle.svg"></ion-icon>
                <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
                      deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
          </span>
            </ion-menu-toggle>
        </ion-buttons>

        <div class="searchbar-wrapper" *ngIf="selectedFilter !== 'location'">
            <ion-searchbar [(ngModel)]="searchText" (ionInput)="onInput($event)" (ionFocus)="onFocus($event)" [debounce]="300" placeholder="{{'HOME' | translate}}">
            </ion-searchbar>
        </div>

        <ion-buttons class="end-slot" slot="end" *ngIf="xperiencesService.enableInsight && !xperiencesService.selectedXpContact">
            <ion-button [disabled]="device.isOffline" (click)="createInsight()">
                <ion-icon slot="icon-only" src="assets/imgs/ios_add_3x.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ng-container *ngIf="backgroundUploadInProgress || syncInProgress || localDbDataLoadInProgress">
      <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
      <div class="in-progress">
        <ion-spinner></ion-spinner>
        <span class="secondary-text">{{ syncMessage }}</span>
      </div>
    </ng-container>

    <ion-toolbar class="xperiences-filter">
      <ion-button fill="clear" size="small" (click)="tabChangeEvent('notifications')" class="notification-btn">
        <ion-icon slot="icon-only" src="assets/imgs/notification_bluedot.svg" class="tab-notification-dot" *ngIf="assistantService.hasAnyUnRedNotifications"></ion-icon>
        <ion-icon slot="icon-only" src="assets/imgs/notifications-inactive.svg"></ion-icon>
      </ion-button>
        <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab" (selectedTabChange)="tabChangeEvent($event)"></ind-tabs>
    </ion-toolbar>

</ion-header>

<ion-content class="main-container" #content no-padding toolbar-md-height [ngClass]="{
  'location-active' : selectedFilter === 'location',
  'vectorimageleft' : filteredCustomers.length == 0 && !searchText && selectedFilter == 'saved'
                      || filteredCustomers.length == 0 && !searchText && selectedFilter == 'trending'
                      || filteredTrendingAccounts.length == 0
                      && !searchText && selectedFilter === 'accounts'|| trendingFeeds.length === 0
                      && selectedFilter === 'all' || selectedFilter == 'location'
                      && allLocationCustomers.length === 0,
    'native-content': device.isNativeApp && selectedFilter != 'location',
    'non-native-content' : !device.isNativeApp || selectedFilter == 'location'}">
    <ng-container *ngIf="selectedFilter!='location' && selectedFilter!='accounts' && selectedFilter!='all'">
        <ion-list #CustomerList class="align">
            <ion-item-group>
                <!-- <ion-item-divider class="list-header">
                    <ion-label>
                        {{ (searchText && searchText.trim().length > 0) ? ('ACCOUNT_RESULTS' | translate) : ('XPERIENCES_TRENDING_CUSTOMERS' | translate: { globalCustomerText: utilityService.globalCustomerText }) }}
                        ({{filteredCustomers.length}})</ion-label>
                    <ion-button *ngIf="filteredCustomers.length > 0" slot="end" icon-only fill="clear" (click)='sortTrendingAccounts()'>
                        <span class="sort-text"> {{sortByValue}} </span>
                        <img src="assets/imgs/sort_with_double_arrows.svg" width="16px">
                    </ion-button>
                </ion-item-divider> -->
                <ind-section-header [viewData]="trendingCustomerHeaderModel" (onControlClick)='onAccountHeaderControlClick($event)'></ind-section-header>
                <div class="ion-item-maindiv">
                    <div class="no-data-message" *ngIf="filteredCustomers.length == 0 && !searchText && selectedFilter == 'trending'"> {{'NO' | translate}} {{ utilityService.globalCustomersText?.toUpperCase() | translate | lowercase}}</div>
                    <div class="no-data-message" *ngIf="filteredCustomers.length == 0 && !searchText && selectedFilter == 'saved'"> {{'NO' | translate}} {{'SAVED'| translate | lowercase}} {{ utilityService.globalCustomersText.toUpperCase() | translate | lowercase}}</div>
                    <ind-no-search-result *ngIf="filteredCustomers.length === 0 && searchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                    <ion-item-sliding #item *ngFor="let customer of filteredCustomers">
                        <ion-item [ngClass]="{'itemSelected': xperiencesService.selectedXpContact && (customer.contactid === xperiencesService.selectedXpContact.contactid)}" tappable (click)="openXperiences(customer)">
                            <div text-left>
                                <h3>{{customer.fullname}}</h3>
                                <h4 class="wrap-nextline">
                                    {{customer.description}}
                                </h4>
                                <div float-left class="marg-10">
                                    <div float-left>
                                        <!-- <ion-icon class="h20" name="indegene-xp-predictions-left"></ion-icon> -->
                                        <span class="alignFont marg-predictions">{{customer.predictions.length}} {{'PREDICTIONS' | translate}}</span>
                                    </div>
                                    <div class="pad-10" float-left>
                                        <!-- <ion-icon class="h20" name="indegene-xp-trending-score-left"></ion-icon> -->
                                        <span class="alignFont marg-category">{{ localisedAccountPotential(customer.trending_category)}}</span>
                                    </div>
                                </div>
                            </div>
                        </ion-item>
                        <ion-item-options>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" *ngIf="!customer.savedFlag" (click)="saveCustomer(customer, true)">
                                <ion-icon slot="top" class="indegene-save"></ion-icon>
                                {{'SAVE' | translate}}
                            </ion-item-option>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" *ngIf="customer.savedFlag" (click)="saveCustomer(customer, false)">
                                <ion-icon slot="top" class="indegene-save-active"></ion-icon>
                                {{'SAVE' | translate}}
                            </ion-item-option>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" (click)="showGeneePopover(customer)">
                                <ion-icon slot="top" class="indegene-genee-white"></ion-icon>
                                {{'GENEE' | translate}}
                            </ion-item-option>
                        </ion-item-options>
                        <!-- <ion-item-options side="end">
                                <ion-button [ngClass]="{'device-offline' : device.isOffline}" *ngIf="!customer.savedFlag" (click)="saveCustomer(customer, true)">
                  <ion-icon name="indegene-xp-save"></ion-icon>
                  <ion-label *ngIf="!customer.savedFlag">Save</ion-label>
                </ion-button>
                                <ion-button [ngClass]="{'device-offline' : device.isOffline}" *ngIf="customer.savedFlag" (click)="saveCustomer(customer, false)">
                  <img *ngIf="customer.savedFlag" src="assets/imgs/xp_save_active.svg" />
                  <ion-label>Saved</ion-label>
                </ion-button>
                                <ion-button [ngClass]="{'device-offline' : device.isOffline}" (click)="showGeneePopover(customer)">
                  <ion-icon name="indegene-xp-genee"></ion-icon>
                  <ion-label>Genee</ion-label>
                </ion-button>
                            </ion-item-options> -->
                    </ion-item-sliding>
                </div>
            </ion-item-group>
        </ion-list>
    </ng-container>
    <ng-container *ngIf="selectedFilter === 'accounts'">
        <ion-list #List class="accounts-list align">
            <ion-item-group>
                <!-- <ion-item-divider class="list-header">
                    <ion-label>
                        {{ (searchText && searchText.trim().length > 0) ? ('ACCOUNT_RESULTS' | translate) : ('ACCOUNTS' | translate) }} ({{filteredTrendingAccounts.length}})
                    </ion-label>
                    <ion-button *ngIf="filteredTrendingAccounts.length > 0" slot="end" icon-only fill="clear" (click)='sortTrendingAccounts()'>
                        <span class="sort-text"> {{sortByValue}} </span>
                        <img src="assets/imgs/sort_with_double_arrows.svg" width="16px">
                    </ion-button>
                </ion-item-divider> -->
                <ind-section-header [viewData]="trendingAccountHeaderModel" (onControlClick)='onAccountHeaderControlClick($event)'></ind-section-header>
                <div class="ion-item-maindiv">
                    <div class="no-data-message" *ngIf="filteredTrendingAccounts.length == 0 && !searchText"> {{'NO' |translate}} {{ 'ACCOUNTS'| translate | lowercase}}</div>
                    <ind-no-search-result *ngIf="filteredTrendingAccounts.length === 0 && searchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                    <ion-item #item *ngFor="let account of filteredTrendingAccounts" [ngClass]="{'itemSelected': xperiencesService.selectedTrendingAccount &&
                            xperiencesService.selectedTrendingAccount.accountName === account.accountName}" tappable (click)="openTrendingAccountDetails(account)">
                        <div>
                            <div class="account-info">
                                <div class="account-name-description">
                                    <span class="account-name">{{account.accountName}}</span>
                                    <span class="account-description">
                                            {{account.trending_description}}
                                        </span>
                                </div>
                                <!--<div class="account-score">
                                        {{(account.score * 100 )| number: '1.0-0'}}%
                                    </div>-->
                            </div>
                            <div class="account-tertiary-info">
                                <span class="account-predictions">
                                      <!-- <ion-icon name="indegene-account-predictions"></ion-icon> -->
                                      <span class="text marg-predictions-feed">{{account.predictions.length}} {{'PREDICTIONS' | translate}}</span>
                                </span>
                                <span class="account-health">
                                      <!-- <ion-icon name="indegene-account-health-{{account.health}}"></ion-icon> -->
                                      <span class="text marg-category">{{localisedAccounthealth(accountHealth[account.health])}}</span>
                                </span>
                                <span class="account-potential">
                                      <!-- <ion-icon name="indegene-xp-account-potential"></ion-icon> -->
                                      <span class="text oflow marg-category">{{ localisedAccountPotential(accountPotential[account.potential_category])}}</span>
                                </span>
                            </div>
                        </div>
                    </ion-item>
                </div>
            </ion-item-group>
        </ion-list>
    </ng-container>

    <ng-container *ngIf="selectedFilter === 'all'">
        <ion-list class="accounts-list align">
            <ion-item-group>
                <!-- <ion-item-divider class="list-header">
                    <ion-label>
                        {{ (searchText && searchText.trim().length > 0) ? ('ACCOUNT_RESULTS' | translate) : 'Feed' }} ({{filteredTrendingFeeds.length}})
                    </ion-label>
                </ion-item-divider> -->
                <ind-section-header [viewData]="trendingFeedHeaderModel"></ind-section-header>
                <div class="ion-item-maindiv">
                    <div class="no-data-message" *ngIf="filteredTrendingFeeds.length == 0 && !searchText"> {{'IMPACT_NO_FEEDS' |translate}}</div>
                    <ind-no-search-result *ngIf="filteredTrendingFeeds.length === 0 && searchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                    <ion-item-sliding #item *ngFor="let feed of filteredTrendingFeeds">
                        <ion-item [ngClass]="{'itemSelected': xperiencesService.selectedTrendingFeed &&
                            xperiencesService.selectedTrendingFeed.feed_id === feed.feed_id}" tappable (click)="openTrendingFeedDetails(feed)">
                            <div class="main-text-right">
                                <div class="account-name-description">
                                    <span class="account-name">{{feed.description}}</span>
                                    <span class="account-description">
                                            {{feed.second_line_description}}
                                    </span>
                                </div>
                                <div class="account-tertiary-info">
                                    <span class="account-predictions">
                                        <!-- <ion-icon name="indegene-feed-like"></ion-icon> -->
                                        <span class="text marg-predictions">{{feed.likeCount}} {{'LIKES' | translate}}</span>
                                    </span>
                                    <span class="account-predictions">
                                      <!-- <ion-icon name="indegene-account-predictions"></ion-icon> -->
                                      <span class="text marg-category" style="width: 110px;">{{feed.predictions.length}} {{'PREDICTIONS' | translate}}</span>
                                    </span>
                                </div>
                            </div>
                        </ion-item>
                        <ion-item-options>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" *ngIf="!feed.likedFlag" (click)="likeFeed(feed, true)">
                                <ion-icon slot="top" src="assets/imgs/feedback_thumbs_up.svg"></ion-icon>
                            </ion-item-option>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" *ngIf="feed.likedFlag">
                                <!-- <ion-icon slot="top" class="indegene-save"></ion-icon> -->
                                <img *ngIf="feed.likedFlag" src="assets/imgs/feedback_thumbs_up_active.svg" />
                            </ion-item-option>
                            <ion-item-option [ngClass]="{'device-offline' : device.isOffline}" (click)="likeFeed(feed, false)">
                                <ion-icon slot="top" src="assets/imgs/feedback_thumbs_down.svg"></ion-icon>
                            </ion-item-option>
                        </ion-item-options>
                        <!-- <ion-item-options side="right">
                            <button ion-button [ngClass]="{'device-offline' : device.isOffline}" *ngIf="!feed.likedFlag" (click)="likeFeed(feed, true)">
                                <ion-icon name="indegene-thumbs-up"></ion-icon>
                            </button>
                            <button ion-button [ngClass]="{'device-offline' : device.isOffline}" *ngIf="feed.likedFlag">
                                <img *ngIf="feed.likedFlag" src="assets/imgs/feedback_thumbs_up_active.svg" />
                            </button>
                            <button ion-button [ngClass]="{'device-offline' : device.isOffline}" (click)="likeFeed(feed, false)">
                                <ion-icon name="indegene-thumbs-down"></ion-icon>
                            </button>
                        </ion-item-options> -->
                    </ion-item-sliding>
                </div>
            </ion-item-group>
        </ion-list>
    </ng-container>

    <div id="map_canvas" #googleMapContainer [style.display]='selectedFilter === "location" ? "block": "none"'>
        <div class="no-data-message" *ngIf="allLocationCustomers.length == 0 "> {{'NO_ADDRESS_FOUND' |translate }}</div>
    </div>
    <ion-content class="location-cards" style="bottom: 80px;height: 85px;" *ngIf="selectedFilter == 'location' && allLocationCustomers.length > 0">
        <ion-slides [options]="{ slidesPerView: 'auto', zoom: false, grabCursor: true, spaceBetween:15 }" [scrollbar]=false>
            <ion-slide *ngFor="let customer of allLocationCustomers" style="width: 225px;height: 85px;margin-bottom: 10px;align-items: baseline;">
                <ion-card [id]="customer.contactid" [ngClass]="{'focused-customer' : focusedCustomer && focusedCustomer.contactid == customer.contactid}" (click)="focusCustomer(customer)">
                    <ion-card-header>
                        <ion-row>
                            <ion-label>{{customer.fullname}}</ion-label>
                            <!-- <ion-icon name="indegene-bookmark{{customer.savedFlag ? '-saved' : ''}}" (click)="saveCustomer(customer, customer.savedFlag ? false : true)" [ngClass]="{'device-offline' : device.isOffline}"></ion-icon> -->
                            <ion-icon *ngIf="customer.savedFlag" src="assets/imgs/indegene_bookmark_saved.svg" (click)="saveCustomer(customer, customer.savedFlag ? false : true)" [ngClass]="{'device-offline' : device.isOffline}"></ion-icon>
                            <ion-icon *ngIf="!customer.savedFlag" src="assets/imgs/indegene_bookmark.svg" (click)="saveCustomer(customer, customer.savedFlag ? false : true)" [ngClass]="{'device-offline' : device.isOffline}"></ion-icon>
                        </ion-row>
                    </ion-card-header>
                    <ion-card-content text-wrap>{{customer.description}}</ion-card-content>
                </ion-card>
            </ion-slide>
        </ion-slides>
    </ion-content>
    <!-- <ion-scroll scrollX="true" #scroll [ngClass]="{'ios-scroll' : device.deviceFlags.ios}">
            <ion-row nowrap>
                <ion-card *ngFor="let customer of allLocationCustomers" [id]="customer.contactid" [ngClass]="{'focused-customer' : focusedCustomer && focusedCustomer.contactid == customer.contactid}" (click)="focusCustomer(customer)">
                    <ion-card-header>
                        <ion-row>
                            <ion-label>{{customer.fullname}}</ion-label>
                            <ion-icon name="indegene-bookmark{{customer.savedFlag ? '-saved' : ''}}" (click)="saveCustomer(customer, customer.savedFlag ? false : true)" [ngClass]="{'device-offline' : device.isOffline}"></ion-icon>
                        </ion-row>
                    </ion-card-header>
                    <ion-card-content text-wrap>{{customer.description}}</ion-card-content>
                </ion-card>
            </ion-row>
        </ion-scroll>
    </div> -->
</ion-content>
