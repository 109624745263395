<ion-header>

    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="emailTemplateSearchText" (onControlClick)="onHeaderControlClick($event)" (onSearchInput)="onInput($event)" (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)" (onEnterClick)="onClickEnter($event)"></ind-header-left>

    <ind-key-controls-area id="emailTemplateList" *ngIf="emailTemplateListKeyControlAreaModel" [viewData]="emailTemplateListKeyControlAreaModel"></ind-key-controls-area>
    <!-- <ion-item-divider *ngIf="templates.length > 0" class="keyControlsArea" color="light">
    <button slot="end" icon-only fill="clear" [color]='primary' ion-button (click)='sort()'>
      <img class="sortIcon" src="assets/imgs/sort_with_double_arrows.svg" width="16px">
      {{sortBy?.text}}
      <ion-icon class="filterIcon filterStyleHeading" slot="end" name="md-arrow-dropdown"></ion-icon>
    </button>
  </ion-item-divider> -->
    <!-- <ind-key-controls-area *ngIf="templatesListKeyControlAreaModel" [viewData]="templatesListKeyControlAreaModel"> </ind-key-controls-area> -->

    <!-- selected serach suggestions area -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
      {{suggestion.selectedFacet}}
      <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)" />
    </span>
    </div>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft' : !searching && templates.length == 0 ,'mobileTop': selectedTemplate && (selectedTemplate.indskr_emailtemplateid == currentTemplateId || (currentTemplateId && selectedTemplate.indskr_emailtemplateid != currentTemplateId))}"
    no-padding>

    <!-- Selected E-mail Templates Section -->
    <ind-section-header *ngIf="selectedTemplate" [viewData]='{id:"email-template-list", title: translate.instant("SELECTED_CAP") + " (1)"}'>
    </ind-section-header>
    <ion-item class='selector-item' *ngIf="selectedTemplate" [ngClass]="{itemSelected: selectedTemplate.indskr_emailtemplateid == currentTemplateId, inactive: selectedTemplate.shouldBeGreyedOut}">
        <email-template-item #templateItem tappable (click)="previewTemplate(selectedTemplate)" [template]="selectedTemplate"></email-template-item>
        <ion-icon class="remove-icon" slot="end" name="indegene-selectors-remove-icon" (click)="removeTemplate(selectedTemplate)"></ion-icon>
    </ion-item>

    <!-- Matched Message Templates Section -->
    <ng-container *ngIf="matchedTemplates.length > 0 && !isMessageProductBundleFeature">
        <ind-section-header [viewData]="matchedTemplateListHeader">
        </ind-section-header>
        <ion-item-group>
            <ion-list>
                <ion-item class='selector-item' *ngFor="let template of matchedTemplates | orderBy: sortBy?.value:sortBy?.asc" [ngClass]="{itemSelected: template.indskr_emailtemplateid == currentTemplateId, inactive: template.shouldBeGreyedOut}">
                    <email-template-item #templateItem tappable (click)="previewTemplate(template)" [template]="template" [sortedBy]="sortBy.value"></email-template-item>
                    <ion-icon class="checkmark-icon" *ngIf="selectedTemplate && selectedTemplate.indskr_emailtemplateid == template.indskr_emailtemplateid" slot="end" name="indegene-selectors-checkmark-icon"></ion-icon>
                    <ion-icon class="add-icon" slot="end" (click)="selectTemplate(template)" name="indegene-selectors-add-icon" *ngIf="!selectedTemplate || selectedTemplate.indskr_emailtemplateid != template.indskr_emailtemplateid">
                    </ion-icon>
                </ion-item>
            </ion-list>
        </ion-item-group>
    </ng-container>


    <!-- All E-mail Templates -->
    <ng-container *ngIf="!searching">
        <ind-section-header [viewData]="allTemplateListHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
        <ion-item-group *ngIf="templates.length > 0" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <ion-list>
                <ion-item class='selector-item' *ngFor="let template of templates | orderBy: sortBy?.value:sortBy?.asc" [ngClass]="{itemSelected: template.indskr_emailtemplateid == currentTemplateId, inactive: template.shouldBeGreyedOut}">
                    <email-template-item #templateItem tappable (click)="previewTemplate(template)" [template]="template" [sortedBy]="sortBy.value"></email-template-item>
                    <ion-icon class="checkmark-icon" *ngIf="selectedTemplate && selectedTemplate.indskr_emailtemplateid == template.indskr_emailtemplateid" slot="end" name="indegene-selectors-checkmark-icon"></ion-icon>
                    <ion-icon class="add-icon" slot="end" (click)="selectTemplate(template)" name="indegene-selectors-add-icon" *ngIf="!selectedTemplate || selectedTemplate.indskr_emailtemplateid != template.indskr_emailtemplateid">
                    </ion-icon>
                </ion-item>
            </ion-list>
            <ion-list *ngIf="templates.length == 0">
                <div class="no-data-message">
                    {{ 'NO_TEMPLATES' | translate}}
                </div>
            </ion-list>
        </ion-item-group>
    </ng-container>

    <!-- E-mail Templates after searching -->
    <ng-container *ngIf="searching">
        <ind-section-header *ngIf="templates.length" [viewData]="resultTemplateListHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
        <ion-list *ngIf="filteredList.length > 0" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <ion-item class='selector-item' *ngFor="let template of filteredList | orderBy: sortBy?.value:sortBy?.asc" [ngClass]="{itemSelected: template.indskr_emailtemplateid == currentTemplateId, inactive: template.shouldBeGreyedOut}">
                <email-template-item #templateItem tappable (click)="previewTemplate(template)" [template]="template" [sortedBy]="sortBy.value"></email-template-item>
                <ion-icon class="checkmark-icon" *ngIf="selectedTemplate && selectedTemplate.indskr_emailtemplateid == template.indskr_emailtemplateid" slot="end" name="indegene-selectors-checkmark-icon"></ion-icon>
                <ion-icon class="add-icon" slot="end" (click)="selectTemplate(template)" name="indegene-selectors-add-icon" *ngIf="!selectedTemplate || selectedTemplate.indskr_emailtemplateid != template.indskr_emailtemplateid">
                </ion-icon>
            </ion-item>
        </ion-list>
        <ion-list *ngIf="filteredList.length == 0">
            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </ion-list>
    </ng-container>
    <p *ngIf="!searching && templates.length == 0" class="no-data-message"> {{ 'NO_TEMPLATES' | translate}}</p>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'email-templates'"></footer-toolbar>
<!-- </ion-footer> -->

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)"
  [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive'
  (selectSavedSearch)="handleSavedSearchSelection($event)" [from]="uiService.page.EmailTemplatePageComponent">
</search-suggestion-popover>
