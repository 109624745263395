<div class="no-search-result-wrapper">
  <div class="image-wrapper">
    <img ngSrc="{{src}}"
         alt="No search result"
         priority fill/>
  </div>
  <div class="label">
    {{ text }}
  </div>
</div>
