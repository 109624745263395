import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceService} from "@omni/services/device/device.service";
import {AuthenticationService} from '@omni/services/authentication.service';
import {ActivityService} from '@omni/services/activity/activity.service';
import {DeltaService} from '@omni/data-services/delta/delta.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject, takeUntil} from 'rxjs';

/**
 * The component serves to define the structure and behavior of the left side header of a page within the application.
 * It encapsulates various properties and configurations, including the title, CSS class, mode, and custom header options, allowing for customization and adaptability to different page contexts.
 * Controls: The controls property represents an array of control objects, each defining a unique identifier, name, icon, alignment, disabled state, CSS class, and optional image source URL. These controls enable users to perform specific actions or navigate within the application.
 * Overall, the IndHeaderLeftDataModel interface facilitates the dynamic and flexible rendering of the left side header component, contributing to a cohesive and intuitive user experience within the application.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-header-left',
  templateUrl: 'ind-header-left.html',
  styleUrls:['ind-header-left.scss']
})
export class IndHeaderLeftComponent {
  buttonsFilter = {
    left: { align: 'left' },
    right: { align: 'right' }
  };
  @Input() viewData: any;
  @Input() searchDebounce: number = 300;
  private _searchText: string = '';
  @Input() set searchText(value: string) {
    this._searchText = value;
    this.searchTextChange.emit(this._searchText);
  }
  get searchText(): string {
    return this._searchText;
  }

  private _searchDisabled: boolean = false;
  @Input() set searchDisabled(value: boolean) {
    this._searchDisabled = value;
    this.searchDisabledChange.emit(this._searchDisabled);
  }
  get searchDisabled(): boolean {
    return this._searchDisabled;
  }

  private _searchHidden: boolean = false;
  @Input() set searchHidden(value: boolean) {
    this._searchHidden = value;
  }
  get searchHidden(): boolean {
    return this._searchHidden;
  }

  private _hideFilterButton: boolean = false;
  @Input() set hideFilterButton(value: boolean) {
    this._hideFilterButton = value;
    this.hideFilterButtonChange.emit(this._hideFilterButton);
  }
  get hideFilterButton(): boolean {
    return this._hideFilterButton;
  }

  private _filterButtonBadgeCount: number = 0;
  @Input() set filterButtonBadgeCount(value: number) {
    this._filterButtonBadgeCount = value;
    this.filterButtonBadgeCountChange.emit(this._filterButtonBadgeCount);
  }
  get filterButtonBadgeCount(): number {
    return this._filterButtonBadgeCount;
  }

  @Output() searchTextChange = new EventEmitter<string>();
  @Output() searchDisabledChange = new EventEmitter<boolean>();
  @Output() hideFilterButtonChange = new EventEmitter<boolean>();
  @Output() filterButtonBadgeCountChange = new EventEmitter<number>();
  @Output() onControlClick = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<any>();
  @Output() onEnterClick = new EventEmitter<any>();
  @Output() onSearchChange = new EventEmitter<any>();
  @Output() onSearchFocus = new EventEmitter<any>();
  @Output() onSearchBlur = new EventEmitter<any>();
  @Output() onSearchCancel = new EventEmitter<any>();
  @Output() onSearchClear = new EventEmitter<any>();
  @Output() onSearchInput = new EventEmitter<any>();
  @Output() onSearchBarClick = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();
  enableBackButton : boolean = false;
  shouldShowSearchBarFilterButton: boolean = true;
  isAndroid: boolean = false;
  private backButton = {
    id: "close",
    icon: "indegene-back-to-home-btn",
    isDisabled: false,
    align: "left"
  };
  private plusButton = {
    id: "plusNew",
    icon: "indegene-iAddL",
    isDisabled: false,
    align: "right"
  };
  readonly: boolean;
  public backgroundUploadInProgress: boolean = false;
  public syncInProgress: boolean = false;

  public localDbDataLoadInProgress = false;
  public syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
  private ngDestroy$ = new Subject<boolean>();

  constructor(
    public device: DeviceService,
    private _cd: ChangeDetectorRef,
    public authenticationOfflineService: AuthenticationService,
    public activityService: ActivityService,
    public translate : TranslateService,
    public deltaService: DeltaService, ) { }

  ngOnInit() {
    this.isAndroid = this.device.isAndroid();
    this.enableBackButton = (this.viewData && this.viewData.id != "new-activity-page-title" && this.viewData.controls.filter(control => control.align === this.backButton.align).length == 0)
    && (window.innerWidth < 620);
    this.readonly = this.viewData ? this.viewData.isReadOnly : false;

    this.device.isBackgroundUploadInProgressObservable.subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this.syncMessage = this.translate.instant('OFFLINE_UPLOAD_IN_PROGRESS');
      this._cd.detectChanges();
    });

    this.device.syncInProgress$.subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this.syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
      this._cd.detectChanges();
    });

    this.device.isDataLoadFromLocalDB.pipe(takeUntil(this.ngDestroy$)).subscribe((inProgress) => {
      this.localDbDataLoadInProgress = inProgress;
      this.syncMessage = this.translate.instant('INIT_DATA_IN_PROGRESS');
      this._cd.detectChanges();
    });

  }

  public onButtonClick(button) {
    if (button.id && !button.isDisabled) {
      this.onControlClick.emit(button.id);
    }
  }

  _onFilterClick(ev: any) {
    this.onFilterClick.emit(ev);
  }

  _onSearchChange(ev: any) {
    this.onSearchChange.emit(ev);
  }
  _onSearchFocus(ev: any) {
    this.shouldShowSearchBarFilterButton = false;
    this.onSearchFocus.emit(ev);
  }
  _onSearchBlur(ev: any) {
    this.shouldShowSearchBarFilterButton = true;
    this.onSearchBlur.emit(ev);
  }
  _onSearchCancel(ev: any) {
    this.onSearchCancel.emit(ev);
  }
  _onSearchClear(ev: any) {
    this.onSearchClear.emit(ev);
  }
  _onSearchInput(ev: any) {
    this.onSearchInput.emit(ev);
  }
  _onSearchBarClick(ev: any) {
    this.onSearchBarClick.emit(ev);
  }
  _onSearch(ev: any) {
    this.onSearch.emit(ev);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public get displayBackButton(){
    let backButtonOption = (this.viewData.id != "new-activity-page-title" && this.viewData.controls.filter(control => control.align === this.backButton.align).length == 0)
    && (window.innerWidth < 620);
    if(backButtonOption != this.enableBackButton){
      this.enableBackButton = backButtonOption;
      this._cd.detectChanges();
      this._cd.markForCheck();
    }
    return backButtonOption;
  }

  public onKeyPressEventHandler (event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onEnterClick.emit(true);
    }
  }

}
