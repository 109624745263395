<div class="wrapper">
  <ion-item lines="none"
    [ngClass]="inputData && inputData.customCssClass ? inputData.customCssClass : 'time-line-item' ">
    <ion-icon [src]="inputData.mainIcon" slot="start" class="item-icon"></ion-icon>
    <div class="header-text">
      <ion-text class="time-text" *ngIf="inputData.subheaderText"> {{ inputData.subheaderText }}
        <span class="breaker">|</span>
        <ion-text class="date-text" [color]="inputData.subheaderText2Color" *ngIf="inputData.subheaderText2">{{
          inputData.subheaderText2 }}</ion-text>
      </ion-text>
      <ion-label class="main-label">{{ inputData.headerText }}</ion-label>
    </div>
    <ion-button *ngIf="!inputData.hideEndArrow" [disabled]="inputData.disableEndArrow" fill="clear" slot="end"
      (click)="headerClick($event)" class ='margin-right-0'>
      <ion-icon slot="icon-only" name="chevron-forward-outline" class="end-icon"></ion-icon>
    </ion-button>
    <ion-button  *ngIf="inputData.popupImage"  fill="clear"  (click)="popupImage($event)" slot="end" class ='margin-left-0'>
      <img class="popupImage" src="assets/imgs/popup-link-icon.png" alt="Link Icon">
    </ion-button>
  </ion-item>

  <ion-row>
    <ion-col [attr.size]="getColSize('default',item)" [attr.size-md]="getColSize('md',item)" *ngFor="let item of inputData.dataToDisplay;let i=index"
      [ngClass]="{'ion-hide-sm-down' : i > 3 || item.hideOnMobile} ">
      <!-- show only 4 columns in mobile view -->
      <display-value *ngIf="!item.isProgressbarWithText" (clicked)="popupClick($event)" [inputData]="item"></display-value>
      <progress-with-text *ngIf="item.isProgressbarWithText" [inputData]="item.values"></progress-with-text>
    </ion-col>
  </ion-row>
</div>