import { Injectable } from "@angular/core";
import { Activity, ActivityType } from "@omni/classes/activity/activity.class";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { EmailActivity } from "@omni/classes/activity/email.activity.class";
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { SampleActivity } from "@omni/classes/activity/sample.activity.class";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { CaseActivity } from "@omni/classes/case-intake/case-activity.class";
import { EventActivity, EventParticipant } from "@omni/classes/events-tool/event.class";
import { TimeLineItem } from "@omni/components/timeline-item/timeline-item.component";
import { ActivityDataService } from "@omni/data-services/activity/activity.service";
import { DateTimeFormatsService } from "../date-time-formats/date-time-formats.service";
import { distanceInWordsToNow, format, isPast, isValid } from "date-fns";
import { TranslateService } from "@ngx-translate/core";
import { ActivityService } from "../activity/activity.service";
import { ContactOfflineService } from "../contact/contact.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { EmailService } from "../email-templates/email.service";
import { OrderActivity } from "@omni/classes/activity/order.activity.class";
import { ProcedureTrackerActivity } from "@omni/classes/activity/procedure-tracker.activity.class";
import { Presentation } from "@omni/classes/presentation/presentation.class";
import { Resource } from "@omni/classes/resource/resource.class";
import { DatePipe } from "@angular/common";
import { EventRegistrationStatus, EventStatus } from "@omni/enums/event/event.enum";
import { SurveyCategory, SurveyFrequency } from "@omni/classes/customer-assessment/assessment-template.class";
import { GlobalUtilityService } from "../global-utility.service";
import { Channel } from "@omni/classes/consent/channel.class";
import { ConsentService } from "../consent/consent.service";
import { EventRegistration } from "@omni/classes/customer-event/customer-event.class";
import { PresentationService } from "../presentation/presentation.service";
import { ResourceService } from "../resource/resource.service";
import { EventsToolService } from "../events-tool/events-tool.service";
import _ from 'lodash';
import { Quotes } from "@omni/classes/quotes/quotes.class";
import { QuotesService } from "../quote/quote-tool.service";
import { OpportunityManagementDataService } from "@omni/data-services/opportunity-management/opportunity-management.data.service";
import { ProcedureContract } from "@omni/classes/procedure-contract/procedure-contract.class";
import { ProcedureContractService } from "@omni/data-services/procedure-contract/procedure-contract.service";

@Injectable({
  providedIn: 'root'
})
export class Customer360UIService {

  constructor(
    // private readonly activityDataService: ActivityDataService,
    private readonly dateTimeFormatService: DateTimeFormatsService,
    private readonly translate: TranslateService,
    private readonly activityService: ActivityService,
    private readonly contactService: ContactOfflineService,
    // private readonly authService: AuthenticationService,
    private readonly emailService: EmailService,
    private datePipe: DatePipe,
    // private readonly utilityService: GlobalUtilityService,
    private readonly consentService: ConsentService,
    private readonly presentationService: PresentationService,
    private readonly resourceService: ResourceService,
    private readonly eventsToolService: EventsToolService,
    private quotesOfflineService : QuotesService,
    private readonly opportunityDataService: OpportunityManagementDataService,
    private procedureContractService: ProcedureContractService,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) {}

  public getViewForJourneyItem(isFeed: boolean, item, id?): TimeLineItem {
    let view: TimeLineItem;
    if (item) {
      if (item.type == ActivityType.CustomerJourneyEmail) {
        view = this._getTimelineItemViewForCustomerJourneyEmail(item);
      } else if (item.type == ActivityType.CustomerJourneyPhoneCall) {
        view = this._getTimelineItemViewForCustomerJourneyPhoneCall(item);
      } else if (item.type == ActivityType.CustomerJourneyAppointment) {
        view = this._getTimelineItemViewForCustomerJourneyAppointment(item);
      } else if (item instanceof AppointmentActivity) {
        view = this._getTimelineItemViewForAppointmentActivity(item);
      } else if (item instanceof SampleActivity) {
        view = this._getTimelineItemViewForSampleActivity(item);
      } else if (item instanceof EmailActivity) {
        view = this._getTimelineItemViewForEmailActivity(isFeed, item);
      } else if (item instanceof PhoneActivity) {
        view = this._getTimelineItemViewForPhoneActivity(item);
      } else if (item instanceof SurgeryOrderActivity) {
        view = this._getTimelineItemViewForSurgeryOrderActivity(item);
      } else if (item instanceof CaseActivity) {
        view = this._getTimelineItemViewForCaseIntakeActivity(item);
      } else if (item instanceof EventActivity) {
        view = this._getTimelineItemViewForEventActivity(item, id);
      } else if (item instanceof EventRegistration) {
        view = this._getTimelineItemViewForEventRegistrationActivity(item);
      } else if (item.type == ActivityType.ConsentActivity) {
        view = this._getTimelineItemViewForConsentItem(item);
      } else if (item instanceof OrderActivity) {
        view = this._getTimelineItemViewForOrderActivity(item);
      } else if (item instanceof ProcedureTrackerActivity) {
        view = this._getTimelineItemViewForProcedureTrackerActivity(item);
      } else if (item.type && item.type == ActivityType.CustomerSurvey) {
        view = this._getTimelineItemViewForSurveyActivity(item);
      } else if (item.type && item.type == ActivityType.WebsiteAccess) {
        view = this._getTimelineItemViewForWebsiteAccessActivity(item);
      }
    }

    return view;
  }

  private _getTimelineItemViewForAppointmentActivity(item: AppointmentActivity): TimeLineItem {
    let view: TimeLineItem;
    //item = await this.activityDataService.getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(item.ID) as AppointmentActivity;
    let firstdetailIconType, firstdetailObjectId;
    if (item.presentations && item.presentations.length > 0 && item.presentations[0] instanceof Presentation) {
      let foundPres = this.presentationService.getPresentationById(item.presentations[0].ioPresentationId);
      if (foundPres) {
        firstdetailIconType = 'ActivityPresentation';
        firstdetailObjectId = item.presentations[0].ioPresentationId;
      }
    } else if (item.presentations && item.presentations.length > 0 && item.presentations[0] instanceof Resource) {
      let foundRes = this.resourceService.getResourceById(item.presentations[0].ioResourceId, item.presentations[0].ioDocumentId);
      if (foundRes) {
        firstdetailIconType = 'ActivityResource';
        firstdetailObjectId = item.presentations[0].ioResourceId || item.presentations[0].ioDocumentId;
      }
    } else if (item.presentations && item.presentations.length > 0) {
      let foundPres = this.presentationService.getPresentationById(item.presentations[0].contentId);
      if (foundPres) {
        firstdetailIconType = 'ActivityPresentation';
        firstdetailObjectId = item.presentations[0].contentId;
      } else {
        let foundRes = this.resourceService.getResourceById(item.presentations[0].contentId, item.presentations[0].contentId);
        if (foundRes) {
          firstdetailIconType = 'ActivityResource';
          firstdetailObjectId = item.presentations[0].contentId;
        }
      }
    }
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('ACCOUNTS'),
          value: (item.accounts && item.accounts.length > 0) ? item.accounts[0].accountName : '',
          placeholderText: this.translate.instant('NO_ACCOUNTS'),
          valuePopupText: (item.accounts && item.accounts.length > 1) ? '+ ' + (item.accounts.length - 1) : '',
          popupListData: (item.accounts && item.accounts.length > 1) ? item.accounts.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.accountName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          value: item.location || '',
          placeholderText: this.translate.instant('NO_LOCATION')
        },
        {
          label: this.translate.instant('CONTENT'),
          value: (item.presentations && item.presentations.length > 0) ? item.presentations[0].name : '',
          placeholderText: this.translate.instant('NO_CONTENT'),
          openDetailIcon: (firstdetailIconType && firstdetailObjectId) ? 'assets/imgs/omni_path.svg' : null,
          detailIconType: firstdetailIconType,
          detailObjectId: firstdetailObjectId,
          valuePopupText: (item.presentations && item.presentations.length > 1) ? '+ ' + (item.presentations.length - 1) : '',
          popupListData: (item.presentations && item.presentations.length > 1) ? item.presentations.slice(1).map((a: any) => {
            let detailIconType, detailObjectId;
            let thumbnailURL = a['thumbnailURL'];
            if (a instanceof Presentation) {
              let foundPres = this.presentationService.getPresentationById(a.ioPresentationId);
              if (foundPres) {
                detailIconType = 'ActivityPresentation';
                detailObjectId = a.ioPresentationId;
                thumbnailURL = a.thumbnailUrl;
              }
            } else if (a instanceof Resource) {
              let foundRes = this.resourceService.getResourceById(a.ioResourceId, a.ioDocumentId);
              if (foundRes) {
                detailIconType = 'ActivityResource';
                detailObjectId = a.ioResourceId || a.ioDocumentId;
                thumbnailURL = a['thumbnailURL'];
              }
            } else {
              let foundPres = this.presentationService.getPresentationById(a.contentId);
              if (foundPres) {
                detailIconType = 'ActivityPresentation';
                detailObjectId = a.contentId;
                thumbnailURL = a.thumbnailURL;
              } else {
                let foundRes = this.resourceService.getResourceById(a.contentId, a.contentId);
                if (foundRes) {
                  detailIconType = 'ActivityResource';
                  detailObjectId = a.contentId;
                  thumbnailURL = a['thumbnailURL'];
                }
              }
            }
            let obj = {
              id: a.contentId,
              title: a.name,
              detailObjectType: detailIconType,
              detailObjectId: detailObjectId,
              thumbnailURL: thumbnailURL, //Pres Thumbnail
            };
            return obj;
          }) : [],
        },
      ]
    };
    const activityProds = item.products ? item.products.filter(a => a.isSelected) : [];
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (activityProds && activityProds.length > 0) ? activityProds[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (activityProds && activityProds.length > 1) ? '+ ' + (activityProds.length - 1) : '',
      popupListData: (activityProds && activityProds.length > 1) ? activityProds.slice(1).map(a => {
        let obj = {
          id: a.ID,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    // if (!this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE)) {
    //   view.dataToDisplay.push({
    //     label: this.translate.instant('FORMAT_DETAILS'),
    //     value: item.activitySubTypeName || this.translate.instant('NO_FORMAT_DETAILS'),
    //     valuePopupText: '',
    //   })
    // }
    // view.dataToDisplay.push({
    //   label: this.translate.instant('FORMAT'),
    //   value: item.activityTypeName || this.translate.instant('NO_FORMAT'),
    //   valuePopupText: '',
    // })
    view.dataToDisplay.push({
      label: this.translate.instant('ACCOUNT_TIMELINE_APPOINTMENT_MET_BY_OWNER', { meetingOwner: '' }),
      value: item.meetingOwnerName,
      valuePopupText: '',
    })
    view.dataToDisplay.push({
      label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
      value: (item.accompaniedUserList && item.accompaniedUserList.length > 0) ? item.accompaniedUserList[0].name : '',
      placeholderText: this.translate.instant('ACTIVITY_DETAILS_NO_COVISITORS'),
      valuePopupText: (item.accompaniedUserList && item.accompaniedUserList.length > 1) ? '+ ' + (item.accompaniedUserList.length - 1) : '',
      popupListData: (item.accompaniedUserList && item.accompaniedUserList.length > 1) ? item.accompaniedUserList.slice(1).map(a => {
        let obj = {
          id: a.id,
          title: a.name,
        };
        return obj;
      }) : [],
    })
    return view;
  }

  private _getTimelineItemViewForSampleActivity(item: SampleActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('TIME'),
          value: format(item.scheduledStart, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('STATUS'),
          value: item.statusString,
        },
      ]
    };
    let selAdr: string;
    if (item.contactID && item.addressID) {
      let obj = this.contactService.getContactAddressObjById(item.contactID, item.contactID);
      if (obj && obj.compositeAdd) {
        selAdr = obj.compositeAdd;
      }
    }
    view.dataToDisplay.push({
      label: this.translate.instant('ELIGIBLE_ADDRESS'),
      value: selAdr || item.location || '',
      placeholderText: this.translate.instant('ACTIVITY_NO_ELIGIBLE_ADDRESS'),
      valuePopupText: '',
    })
    view.dataToDisplay.push({
      label: this.translate.instant('ELIGIBLE_PRODUCTS_CAMEL_CASE'),
      value: (item.samples && item.samples.length > 0) ? item.samples[0].indskr_skuname : '',
      placeholderText: this.translate.instant('ACTIVITY_NO_ELIGIBLE_PRODUCTS'),
      valuePopupText: (item.samples && item.samples.length > 1) ? '+ ' + (item.samples.length - 1) : '',
      popupListData: (item.samples && item.samples.length > 1) ? item.samples.slice(1).map(a => {
        let obj = {
          id: a.indskr_skuid,
          title: a.indskr_skuname,
        };
        return obj;
      }) : [],
    })
    return view;
  }

  private _getTimelineItemViewForEmailActivity(isFeed: boolean, item: EmailActivity): TimeLineItem {
    let view: TimeLineItem;
    let statusString: string = '';
    switch (item.emailStatusString) {
      case 'Canceled':
        statusString = this.translate.instant('CANCELLED')
        break;
      case 'Sent':
        statusString = this.translate.instant('SENT');
        break;
      case 'Completed':
        statusString = this.translate.instant('COMPLETED');
        break;
      case 'Open':
        statusString = this.translate.instant('OPEN');
        break;
      case 'Received':
        statusString = this.translate.instant('RECEIVED');
        break;
      case 'Pending Sync':
        statusString = this.translate.instant('PENDING_SYNC_SMALL');
        break;
      case 'Sending':
        statusString = this.translate.instant('SENDING');
        break;
      case 'Failed':
        statusString = this.translate.instant('FAILED');
        break;
      case 'Scheduled':
        statusString = this.translate.instant('SCHEDULED');
        break;
      case 'Shared':
        statusString = this.translate.instant('SHARED');
        break;
      default:
        statusString = item.emailStatusString;
        break;
    }
    let channelName = item.channelName || '';
    if (!channelName && (item.indskr_channelid != undefined || item.indskr_channelid != "")) {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === item.indskr_channelid);
      if (cha != undefined) {
        channelName = String(cha.indskr_consentType);
      }
    }

    const product = this.emailService.emailProductSKU.find(e => e.ID === item.product_id);
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('NEW_ACTIVITY_CHANNEL'),
          value: channelName,
        },
      ]
    };
    view.dataToDisplay.push({
      label: this.translate.instant('STATUS'),
      value: statusString,
    });

    //Attachment info - dataToDisplay
    let valuePopupTextStr: string = '';
    if (item.emailAttachments && item.emailAttachments.length > 0) {
      item.emailAttachments = item.emailAttachments.filter(a => a.indskr_ckmtitle);
      if (item.emailAttachments.length > 1) {
        item.emailAttachments = _.sortBy(item.emailAttachments, ['indskr_ckmtitle']);
        valuePopupTextStr = '+ ' + (item.emailAttachments.length - 1);
      }
    }
    let selectedContactId = this.contactService.contactInformation ? this.contactService.contactInformation.ID : '';
    let emailActivityIdList = [];
    if (!isFeed && selectedContactId && item.emailActivityParties) {
      item.emailActivityParties.forEach(p => {
        if (p.indskr_contactid == selectedContactId && p.emailAddresses) {
          p.emailAddresses.forEach(e => {
            if (e.emailActivityId) {
              emailActivityIdList.push(e.emailActivityId);
            } else if (e.activityid) {
              emailActivityIdList.push(e.activityid);
            }
          });
        }
      });
    }

    let isAccessed: boolean = false;
    if (item['email_linksclickedcount'] && item['email_linksclickedcount'] > 0 || item['email_attachmentopencount'] && item['email_attachmentopencount'] > 0) {
      isAccessed = true;
    }

    let showEyeIcon: boolean = false;
    if (!isFeed && item.emailAttachments && item.emailAttachments.length > 0) {
      showEyeIcon = true;
    }

    view.dataToDisplay.push({
      activityType: ActivityType.Email,
      referenceActivityIds: emailActivityIdList,
      label: this.translate.instant('CONTENTS'),
      labelIcon: showEyeIcon ? isAccessed ? 'assets/imgs/omni_links_accessed.svg' : 'assets/imgs/omni_links_not_accessed.svg' : '',
      value: (item.emailAttachments && item.emailAttachments.length > 0) ? item.emailAttachments[0]?.indskr_ckmtitle ? item.emailAttachments[0].indskr_ckmtitle : '' : '',
      placeholderText: this.translate.instant('EMAIL_NO_CONTENTS'),
      valuePopupText: valuePopupTextStr,
      showPopover: item.emailAttachments && item.emailAttachments.length > 0,
      popupListData: (item.emailAttachments && item.emailAttachments.length > 0) ? item.emailAttachments.map(a => {
        let detailIconType, detailObjectId;
        if (a.indskr_resourceid) {
          const foundRes = this.resourceService.getResourceById(a.indskr_resourceid, a.indskr_resourceid);
          if (foundRes) {
            detailIconType = 'ActivityResource';
            detailObjectId = a.indskr_resourceid;
          }
        }
        const thumbnailRULstr: string = a.indskr_ckmthumbnailurl ? a.indskr_ckmthumbnailurl : '../../../../assets/imgs/thumbnail_default.png';
        let obj = {
          id: a.indskr_resourceid,
          title: a.indskr_ckmtitle,
          thumbnailURL: thumbnailRULstr,
          detailObjectType: detailIconType,
          detailObjectId: detailObjectId,
          activityType: ActivityType.Email,
          offlineMessage: showEyeIcon ? this.translate.instant("EMAIL_CONTENTS_PREVIEW_OFFLINE") : '',
        };
        return obj;
      }) : [],
    });

    // Recipients
    let recipientsText = '';
    if (item.emailActivityParties && item.emailActivityParties.length > 0) {
      const party = item.emailActivityParties[0];
      const firstName = party.contact_firstname;
      const lastName = party.contact_lastname;

      if (firstName && lastName) {
        recipientsText = `${firstName} ${lastName}`;
      } else if (firstName) {
        recipientsText = firstName;
      } else if (lastName) {
        recipientsText = lastName;
      }
    }
    view.dataToDisplay.push({
      label: this.translate.instant('RECIPIENTS'),
      value: recipientsText,
      placeholderText: this.translate.instant('NO_RECIPIENTS'),
      valuePopupText: (item.emailActivityParties && item.emailActivityParties.length > 1) ? '+ ' + (item.emailActivityParties.length - 1) : '',
      popupListData: (item.emailActivityParties && item.emailActivityParties.length > 1) ? item.emailActivityParties.slice(1).map(a => {
        let obj = {
          id: a.indskr_contactid,
          title: a.contact_firstname + ' ' + a.contact_lastname,
        };
        return obj;
      }) : [],
    });

    view.dataToDisplay.push({
      label: this.translate.instant('PRODUCT'),
      value: product && product.name ? product.name : item.emailProductName ? item.emailProductName : '',
      placeholderText: this.translate.instant('NO_PRODUCT'),
    });
    // let tempName = '';
    // if (item.template_id) {
    //   let obj = this.emailService.emailTemplates.find(a => a.indskr_emailtemplateid == item.template_id);
    //   if (obj && obj.indskr_name) {
    //     tempName = obj.indskr_name;
    //   }
    // }

    view.dataToDisplay.push({
      label: this.translate.instant('SCHEDULE_AT'),
      value: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
    });

    return view;

  }

  private _getTimelineItemViewForPhoneActivity(item: PhoneActivity): TimeLineItem {
    let view: TimeLineItem;
    // let selectedProducts = item.activityProducts.filter((prod) => prod.isSelected);
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('CUSTOMER'),
          value: (item.contacts && item.contacts.length > 0) ? item.contacts[0].fullName : '',
          placeholderText: this.translate.instant('NO_CUSTOMER'),
          valuePopupText: (item.contacts && item.contacts.length > 1) ? '+ ' + (item.contacts.length - 1) : '',
          popupListData: (item.contacts && item.contacts.length > 1) ? item.contacts.slice(1).map(a => {
            let obj = {
              id: a.ID,
              title: a.fullName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('PHONE_NUMBER_PHONECALL'),
          // value: item.phonecallphonenumber,
          value: item.contacts && item.contacts.length > 0 ? item.selectedMobileNumber : '',
          placeholderText: this.translate.instant('CONSENT_NO_PHONE_NUMBS'),
        },
        {
          label: this.translate.instant('END_DATE'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('END_TIME'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper),
        },
      ]
    };
    const activityProds = item.products ? item.products.filter(a => a.isSelected) : [];
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (activityProds && activityProds.length > 0) ? activityProds[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (activityProds && activityProds.length > 1) ? '+ ' + (activityProds.length - 1) : '',
      popupListData: (activityProds && activityProds.length > 1) ? activityProds.slice(1).map(a => {
        let obj = {
          id: a.ID,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    view.dataToDisplay.push({
      label: this.translate.instant('STATUS'),
      value: item.getStatusString,
    });
    return view;
  }

  private _getTimelineItemViewForSurgeryOrderActivity(item: SurgeryOrderActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('END_DATE'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('START_TIME'),
          value: format(item.scheduledStart, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('END_TIME'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('PROCEDURE'),
          value: item.surgeryNameString || '',
          placeholderText: this.translate.instant('NO_PROCEDURE'),
        },
        {
          label: this.translate.instant('OM_OWNER'),
          value: item.ownerNameString,
        },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForCaseIntakeActivity(item: CaseActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.caseName,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value: item._case_status_value,
        },
        {
          label: this.translate.instant('STAGE'),
          value: item._case_stage_value,
        },
        {
          label: this.translate.instant('INQUIRY_TYPE'),
          value: item._case_trans_category_type && item._case_trans_category_type != 'Select' ? item._case_trans_category_type : (item._case_category ? item._case_category.name : ''),
          placeholderText: this.translate.instant('NO_INQUIRY_TYPE'),
        },
        {
          label: this.translate.instant('PRODUCT'),
          value: (item._case_trans_product_name && item._case_trans_product_name != 'Product') ? item._case_trans_product_name : '',
          placeholderText: this.translate.instant('NO_PRODUCT'),
        },
        {
          label: this.translate.instant('ASSIGNED_TO'),
          value: item._case_assignee,
        }
      ]
    };
    return view;
  }

  private _getTimelineItemViewForEventActivity(item: EventActivity, id): TimeLineItem {
    let view: TimeLineItem;
    let eventparticipant = item.participants && item.participants.length > 0 ? item.participants.filter(a => a.indskr_reasons && a.indskr_reasons != EventRegistrationStatus.Cancelled) : [];
    let selectedParticipant = eventparticipant.find(ep => ep.id == id);
    view = {
      id: item.ID || item.offlineId,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.name,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
        },
        // {
        //   label: this.translate.instant('START_TIME'),
        //   value: format(item.scheduledStart, this.dateTimeFormatService.timeToUpper),
        // },
        {
          label: this.translate.instant('END_DATE'),
          value: isValid(item.scheduledEnd) ? format(item.scheduledEnd, this.dateTimeFormatService.dateTimeToUpper) : '',
        },
        // {
        //   label: this.translate.instant('END_TIME'),
        //   value: isValid(item.scheduledEnd) ? format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper) : '',
        // },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS'),
          value: (eventparticipant && eventparticipant.length > 0) ? eventparticipant[0].name : '',
          placeholderText: this.translate.instant('NO_PARTICIPANTS'),
          valuePopupText: (eventparticipant && eventparticipant.length > 1) ? '+ ' + (eventparticipant.length - 1) : '',
          popupListData: (eventparticipant && eventparticipant.length > 1) ? eventparticipant.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('COACHING_TEAM_MEMBERS'),
          value: (item.covisitors && item.covisitors.length > 0) ? item.covisitors[0].name : '',
          placeholderText: this.translate.instant('NO_TEAM_MEMBERS'),
          valuePopupText: (item.covisitors && item.covisitors.length > 1) ? '+ ' + (item.covisitors.length - 1) : '',
          popupListData: (item.covisitors && item.covisitors.length > 1) ? item.covisitors.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
            };
            return obj;
          }) : [],
        },
        // {
        //   label: this.translate.instant('PRODUCTS'),
        //   value: (item.products && item.products.length > 0) ? item.products[0].name : this.translate.instant('NO_PRODUCTS'),
        //   valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
        //   popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
        //     let obj = {
        //       id: a.id,
        //       title: a.name,
        //     };
        //     return obj;
        //   }) : [],
        // },
        {
          label: this.translate.instant('REGISTRATION_STATUS'),
          value: selectedParticipant ? selectedParticipant.indskr_reasons_formatted : '',
        }
      ]
    };
    return view;
  }

  private _getTimelineItemViewForEventRegistrationActivity(item: EventRegistration): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.eventId,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.eventName,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE'),
          value: format(item.eventStartDate, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('START_TIME'),
          value: format(item.eventStartDate, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('REGISTERED_DATE'),
          value: isValid(item.registeredOn) ? format(item.registeredOn, this.dateTimeFormatService.dateToUpper) : '',
        },
        {
          label: this.translate.instant('REGISTERED_TIME'),
          value: isValid(item.registeredOn) ? format(item.registeredOn, this.dateTimeFormatService.timeToUpper) : '',
        },
        // {
        //   label: this.translate.instant('PARTICIPANTS'),
        //   value: (item.participants && item.participants.length > 0) ? item.participants[0].name : '',
        //   placeholderText: this.translate.instant('NO_PARTICIPANTS'),
        //   valuePopupText: (item.participants && item.participants.length > 1) ? '+ ' + (item.participants.length - 1) : '',
        //   popupListData: (item.participants && item.participants.length > 1) ? item.participants.slice(1).map(a => {
        //     let obj = {
        //       id: a.id,
        //       title: a.name,
        //     };
        //     return obj;
        //   }) : [],
        // },
        // {
        //   label: this.translate.instant('COACHING_TEAM_MEMBERS'),
        //   value: (item.covisitors && item.covisitors.length > 0) ? item.covisitors[0].name : '',
        //   placeholderText: this.translate.instant('NO_TEAM_MEMBERS'),
        //   valuePopupText: (item.covisitors && item.covisitors.length > 1) ? '+ ' + (item.covisitors.length - 1) : '',
        //   popupListData: (item.covisitors && item.covisitors.length > 1) ? item.covisitors.slice(1).map(a => {
        //     let obj = {
        //       id: a.id,
        //       title: a.name,
        //     };
        //     return obj;
        //   }) : [],
        // },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForConsentItem(item): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.indskr_consenttermsid + '',
      refObject: item,
      subheaderText: format(new Date(item.scheduledStart), this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: 'assets/imgs/omni_consent-signature.svg',
      disableEndArrow: true,
      dataToDisplay: [
        {
          label: this.translate.instant('TYPE'),
          value: item.indskr_type == 100000000 ? this.translate.instant('OPT_IN') : this.translate.instant('OPT_OUT'),
        },
        {
          label: this.translate.instant('SOURCE'),
          value: item.source,
        },
        {
          label: this.translate.instant('CONSENT_INFO'),
          value: item.emailAddress || item.indskr_phone || item.indskr_addressname || '',
        },
        {
          label: this.translate.instant('CAPTURE_DATE'),
          value: format(new Date(item.scheduledStart), this.dateTimeFormatService.dateToUpper),
        },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForOrderActivity(item: OrderActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('END_DATE'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('START_TIME'),
          value: format(item.scheduledStart, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('END_TIME'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('PRODUCTS'),
          value: (item.products && item.products.length > 0) ? item.products[0].productName : '',
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
          popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
            let obj = {
              id: a.productId,
              title: a.productName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('OM_OWNER'),
          value: item.ownerNameString,
        },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForProcedureTrackerActivity(item: ProcedureTrackerActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('END_DATE'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('START_TIME'),
          value: format(item.scheduledStart, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('END_TIME'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper),
        },
        {
          label: this.translate.instant('PROCEDURE'),
          value: (item.procedureTrackers && item.procedureTrackers.length > 0) ? item.procedureTrackers[0].procedureName : '',
          placeholderText: this.translate.instant('NO_PROCEDURE'),
          valuePopupText: (item.procedureTrackers && item.procedureTrackers.length > 1) ? '+ ' + (item.procedureTrackers.length - 1) : '',
          popupListData: (item.procedureTrackers && item.procedureTrackers.length > 1) ? item.procedureTrackers.slice(1).map(a => {
            let obj = {
              id: a.procedureId,
              title: a.procedureName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('OM_OWNER'),
          value: item.ownerNameString,
        },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForSurveyActivity(item): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: "assets/imgs/survey_blue.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('SURVEY_TYPE'),
          value: item['category'] == SurveyCategory.ACCOUNT ? this.translate.instant('SURVEY_ACCOUNT_SURVEY') : this.translate.instant('SURVEY_CUSTOMER_SURVEY'),
        },
        {
          label: this.translate.instant('TYPE'),
          value: item.surveyFrequency,
        },
        {
          label: this.translate.instant('SURVEYED_BY'),
          value: item.surveyedBy,
        },
      ]
    };
    return view;
  }

  private _getTimelineItemViewForWebsiteAccessActivity(item): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.channel + ' - ' + item.department,
      mainIcon: "",
      dataToDisplay: [
        {
          label: this.translate.instant('TITLE'),
          value: item.pageTitle
        },
        {
          label: this.translate.instant('URL'),
          value: item.pageURL,
        },
        {
          label: this.translate.instant('ADDITIONAL_INFORMATION'),
          value: item.additionalInfo,
        },
      ]
    };
    return view;
  }


  // Get data for field material / asset management
  assetManagementTimeline() {
    return (asset: { msdyn_customerassetid: any; msdyn_name: any; indskr_serialnumber: any; productName: any; categoryName: any; indskr_assetstatus: any; }) => {
      return {
        id: asset.msdyn_customerassetid,
        headerText: asset.msdyn_name,
        subheaderText: '',
        subheaderText2: '',
        subheaderText2Color: 'primary', // Assuming a default value
        mainIcon: 'assets/imgs/tools_field_material_gray.svg',
        customCssClass: 'normal-list', // Assuming a default value
        dataToDisplay: [
          {label: 'Status', value: asset.indskr_assetstatus},
          {label: 'Product', value: asset.productName},
          {label: 'Category', value: asset.categoryName},
          {label: 'Serial Number', value: asset.indskr_serialnumber}
        ],
        refObject: asset
      };
    };
  }

  private _getTimelineItemViewForCustomerJourneyEmail(item: Activity): TimeLineItem {
    let marketingEmailTypeStr: string = '';
    let emailStatusStr = '';
    let view: TimeLineItem;
    view = {
      id: item.journeyId,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.journeyName,
      mainIcon: "assets/imgs/omni_email.svg",
      disableEndArrow: true,
      dataToDisplay: []
    };

    //outbound marketing email
    if (!item.isRealtimeMarketingEmail) {
      marketingEmailTypeStr = this.translate.instant("OUTBOUND");

      if (item.email_opened !== 0) {
        emailStatusStr = `${this.translate.instant('EMAIL_OPENED')}`;
      } else if (item.email_delivered !== 0) {
        emailStatusStr = `${this.translate.instant('EMAIL_DELIVERED')}`;
      } else {
        emailStatusStr = `${this.translate.instant('EMAIL_SENT')}`;
      }

      view.dataToDisplay.push(
        {
          label: this.translate.instant('MARKETING_EMAIL'),
          value: marketingEmailTypeStr,
        },
        {
          label: this.translate.instant('DELIVERY_INFO'),
          value: emailStatusStr,
        },
        {
          label: this.translate.instant('OM_OWNER'),
          value: item.meetingOwnerName,
        });
    } else {
      //realtime marketing email
      marketingEmailTypeStr = this.translate.instant("REAL_TIME");
      const templateName: string = item.templateName || '';
      const templatePreviewHtml: string = item.templatePreviewHtml || '';

      if(item.interactionEvent == "msdynmkt_emailopened") {
        emailStatusStr = `${this.translate.instant('EMAIL_OPENED')}`;
      } else if (item.interactionEvent == "msdynmkt_emaildelivered") {
        emailStatusStr = `${this.translate.instant('EMAIL_DELIVERED')}`;
      }

      view.dataToDisplay.push(
        {
          label: this.translate.instant('MARKETING_EMAIL'),
          value: marketingEmailTypeStr,
        },
        {
          label: this.translate.instant('SUBJECT'),
          value: item.subject,
        },
        {
          label: this.translate.instant('TEMPLATE'),
          value: templateName,
          showPopoverPreviewHtml: templatePreviewHtml ? true: false,
          popupListData: [
            {
              id: "templatePreviewHtml",
              title: templateName,
              previewHtml: templatePreviewHtml
            }]
        },
        {
          label: this.translate.instant('DELIVERY_INFO'),
          value: emailStatusStr,
        },
        // {
        //   label: this.translate.instant('OM_OWNER'),
        //   value: item.meetingOwnerName,
        // }
      );
    }
    return view;
  }

  private _getTimelineItemViewForCustomerJourneyPhoneCall(item: Activity): TimeLineItem {
    let view: TimeLineItem;
    // let selectedProducts = item.activityProducts.filter((prod) => prod.isSelected);
    view = {
      id: item.journeyId,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.journeyName,
      disableEndArrow: true,
      mainIcon: "assets/imgs/omni_phone-call-activity.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('CUSTOMER'),
          value: (item.journeyActivityContacts && item.journeyActivityContacts.length > 0) ? item.journeyActivityContacts[0].contactName : '',
          placeholderText: this.translate.instant('NO_CUSTOMER'),
          valuePopupText: (item.journeyActivityContacts && item.journeyActivityContacts.length > 1) ? '+ ' + (item.journeyActivityContacts.length - 1) : '',
          popupListData: (item.journeyActivityContacts && item.journeyActivityContacts.length > 1) ? item.journeyActivityContacts.slice(1).map(a => {
            let obj = {
              id: a.contactId,
              title: a.contactName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('PHONE_NUMBER'),
          value: item.phonecallphonenumber,
        },
        {
          label: this.translate.instant('END_DATE'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.dateToUpper),
        },
        {
          label: this.translate.instant('END_TIME'),
          value: format(item.scheduledEnd, this.dateTimeFormatService.timeToUpper),
        },
      ]
    };
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (item.journeyActivityProducts && item.journeyActivityProducts.length > 0) ? item.journeyActivityProducts[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (item.journeyActivityProducts && item.journeyActivityProducts.length > 1) ? '+ ' + (item.journeyActivityProducts.length - 1) : '',
      popupListData: (item.journeyActivityProducts && item.journeyActivityProducts.length > 1) ? item.journeyActivityProducts.slice(1).map(a => {
        let obj = {
          id: a.id,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    return view;
  }

  private _getTimelineItemViewForCustomerJourneyAppointment(item: Activity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart) + (isPast(item.scheduledStart) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.journeyName,
      disableEndArrow: true,
      mainIcon: "assets/imgs/omni_appointment.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('ACCOUNTS'),
          value: (item.journeyActivityAccounts && item.journeyActivityAccounts.length > 0) ? item.journeyActivityAccounts[0].accountName : '',
          placeholderText: this.translate.instant('NO_ACCOUNTS'),
          valuePopupText: (item.journeyActivityAccounts && item.journeyActivityAccounts.length > 1) ? '+ ' + (item.journeyActivityAccounts.length - 1) : '',
          popupListData: (item.journeyActivityAccounts && item.journeyActivityAccounts.length > 1) ? item.journeyActivityAccounts.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          value: item.location || '',
          placeholderText: this.translate.instant('NO_LOCATION')
        },
        {
          label: this.translate.instant('CONTENT'),
          value: (item.journeyActivityPresentations && item.journeyActivityPresentations.length > 0) ? item.journeyActivityPresentations[0].name : '',
          placeholderText: this.translate.instant('NO_CONTENT'),
          valuePopupText: (item.journeyActivityPresentations && item.journeyActivityPresentations.length > 1) ? '+ ' + (item.journeyActivityPresentations.length - 1) : '',
          popupListData: (item.journeyActivityPresentations && item.journeyActivityPresentations.length > 1) ? item.journeyActivityPresentations.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
              thumbnailURL: a.thumbnailURL
            };
            return obj;
          }) : [],
        },
      ]
    };
    const activityProds = item.journeyActivityProducts ? item.journeyActivityProducts : [];
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (activityProds && activityProds.length > 0) ? activityProds[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (activityProds && activityProds.length > 1) ? '+ ' + (activityProds.length - 1) : '',
      popupListData: (activityProds && activityProds.length > 1) ? activityProds.slice(1).map(a => {
        let obj = {
          id: a.id,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    // if (!this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE)) {
    //   view.dataToDisplay.push({
    //     label: this.translate.instant('FORMAT_DETAILS'),
    //     value: item.activitySubTypeName || this.translate.instant('NO_FORMAT_DETAILS'),
    //     valuePopupText: '',
    //   })
    // }
    // view.dataToDisplay.push({
    //   label: this.translate.instant('FORMAT'),
    //   value: item.activityTypeName || this.translate.instant('NO_FORMAT'),
    //   valuePopupText: '',
    // })
    view.dataToDisplay.push({
      label: this.translate.instant('ACCOUNT_TIMELINE_APPOINTMENT_MET_BY_OWNER', { meetingOwner: '' }),
      value: item.meetingOwnerName,
      valuePopupText: '',
    })
    view.dataToDisplay.push({
      label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
      value: (item.journeyActivityCoVisitors && item.journeyActivityCoVisitors.length > 0) ? item.journeyActivityCoVisitors[0].name : '',
      placeholderText: this.translate.instant('ACTIVITY_DETAILS_NO_COVISITORS'),
      valuePopupText: (item.journeyActivityCoVisitors && item.journeyActivityCoVisitors.length > 1) ? '+ ' + (item.journeyActivityCoVisitors.length - 1) : '',
      popupListData: (item.journeyActivityCoVisitors && item.journeyActivityCoVisitors.length > 1) ? item.journeyActivityCoVisitors.slice(1).map(a => {
        let obj = {
          id: a.id,
          title: a.name,
        };
        return obj;
      }) : [],
    })
    return view;
  }

  public getOmnichannelViewForEventActivity(item: EventActivity): TimeLineItem {
    let view: TimeLineItem;
    let participant: EventParticipant;
    const startDateTimeValue: Date = new Date(item.startDate);
    const endDateTimeValue: Date = new Date(item.endDate);
    const eventStartDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatService.date, undefined, this.translate.currentLang);
    const eventEndDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatService.date, undefined, this.translate.currentLang);
    const eventStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    const eventEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    if (item.participants && this.contactService.contactInformation) {
      participant = item.participants ? item.participants.find(a => a.id == this.contactService.contactInformation.ID) : null;
    }
    view = {
      id: item.ID || item.offlineId,
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: '',
      headerText: item.name,
      customCssClass: 'events-list-item',
      mainIcon: "assets/imgs/omni_event_open.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('START_DATE_AND_TIME'),
          value: eventStartDay + ' | ' + eventStartTime,
        },
        {
          label: this.translate.instant('TYPE'),
          value: item.eventTypeFormatted || '',
          placeholderText: this.translate.instant('NO_TYPE'),
        },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          value: item.locationFormatted || '',
          placeholderText: '',
        },
      ]
    };
    view.dataToDisplay.push({
      label: this.translate.instant('STATUS'),
      value: (participant && participant.indskr_reasons != EventRegistrationStatus.Cancelled && participant.indskr_reasons) ? (participant.indskr_reasons_formatted ? participant.indskr_reasons_formatted : this.eventsToolService.getFormattedRegistrationStatus(participant.indskr_reasons)) : item.statuscode == EventStatus.DRAFT ? this.translate.instant('ELIGIBLE') : '',
    });
    view.dataToDisplay.push({
      label: this.translate.instant('FORMAT'),
      value: item.formattedStatus,
    });
    view.dataToDisplay.push({
      label: this.translate.instant('END_DATE_AND_TIME'),
      value: eventEndDay + ' | ' + eventEndTime,
    },)

    return view;
  }

  public getSentimentHistoryViewForMeetingActivity(item: AppointmentActivity): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: '',
      customCssClass: 'events-list-item',
      headerText: item.subject + ' - ' + item.meetingOwnerName,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('DATE'),
          value: format(item.scheduledStart, this.dateTimeFormatService.dateToUpper),
        },
      ]
    };
    const activityProds = item.products ? item.products.filter(a => a.isSelected) : (item.activityProducts ? item.activityProducts.map(a => { return { ID: a['indskr_productid'] || a.ID, name: a['productName'] || a.name } }) : []);
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (activityProds && activityProds.length > 0) ? activityProds[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (activityProds && activityProds.length > 1) ? '+ ' + (activityProds.length - 1) : '',
      popupListData: (activityProds && activityProds.length > 1) ? activityProds.slice(1).map(a => {
        let obj = {
          id: a.ID,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    view.dataToDisplay.push({
      label: this.translate.instant('ACCOUNT_TIMELINE_APPOINTMENT_MET_BY_OWNER', { meetingOwner: '' }),
      value: item.meetingOwnerName,
      valuePopupText: '',
    })
    return view;
  }

  public getTimelineItemViewForQuotes(item: Quotes): TimeLineItem {
    let view: TimeLineItem;
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.createdon, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.createdon) + (isPast(item.createdon) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.name,
      mainIcon: "assets/imgs/tool-icon-Account-Profile.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value: this.quotesOfflineService.getFormattedStatus(item.statuscode),
        },
        {
          label: this.translate.instant('AMOUNT'),
          value: item.totalAmount ? this.quotesOfflineService.getFormattedValue(parseInt(item.totalAmount), item) : '0.00',
          placeholderText: this.translate.instant('NO_AMOUNT'),
        },
        {
          label: this.translate.instant('PRODUCTS'),
          value: (item.products && item.products.length > 0) ? item.products[0].name : '',
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
          popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('EFFECTIVE_TO'),
          value: item.effectiveto ? format(item.effectiveto, this.dateTimeFormatService.dateToUpper) : '',
        },
      ]
    };
    return view;
  }

  public getTimelineItemViewForContract(procedureContract: ProcedureContract): TimeLineItem {
    let view: TimeLineItem;
    let contractStartDayValue = this.datePipe.transform(procedureContract.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let contractEndDayValue = this.datePipe.transform(procedureContract.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const statusString = this.procedureContractService.getStatusString(procedureContract.statuscode) ?? procedureContract.statusString;

    view = {
      id: procedureContract.indskr_procedurecontractid,
      refObject: procedureContract,
      subheaderText: format(procedureContract.createdon, this.dateTimeFormatService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(procedureContract.createdon) + (isPast(procedureContract.createdon) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: procedureContract.indskr_name,
      mainIcon: "assets/imgs/tool-icon-Account-Profile.svg",
      dataToDisplay: [{
        label: this.translate.instant('STATUS'),
        value: statusString
      },
      {
        label: this.translate.instant('CONTRACT_TYPE'),
        value: procedureContract.contractTypeString,
        placeholderText: this.translate.instant('NO_CONTRACT_TYPE')
      },
      {
        label: this.translate.instant('SUB_TYPE'),
        value: procedureContract.proceudreSubTypeString
      },
      {
        label: this.translate.instant('START_DATE'),
        value: contractStartDayValue
      },
      {
        label: this.translate.instant('END_DATE'),
        value: contractEndDayValue
      },
      {
        label: this.translate.instant('PRODUCT'),
        value: procedureContract.productName,
        placeholderText: this.translate.instant('NO_PRODUCTS')
      }]
    };
    return view;
  }

  public  async _getProfileItemViewForOpportunities(item): Promise<TimeLineItem> {
    let view: TimeLineItem;
    let progressValue = ((item.closedValue) / (item.estimatedValue)) * 100 ;
    let progress = progressValue.toFixed(this.dateTimeFormatService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let statusIcon = item.status == "Won" ? "assets/imgs/green_indicator.svg" : item.status =="Lost" ? "assets/imgs/red-indicator.svg" : "assets/imgs/notification_bluedot.svg";

    view = {
      id: item.ID,
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: 'primary',
      headerText: item.opportunityName,
      customCssClass: 'normal-list',
      mainIcon: "assets/imgs/Opportunities_3x.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          valueIcon : statusIcon,
          value: item.status,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('ESTIMATED_CLOSE_DATE'),
          placeholderText: this.translate.instant('NO_ESTIMATED_CLOSE_DATE'),
          value: (item.estimatedDate) ? format(new Date(parseInt(item.estimatedDate)), this.dateTimeFormatService.dateToUpper) : '',
          valuePopupText: '',
          hideOnMobile: true,
        },
        {
          label: this.translate.instant('STAGE'),
          value: _.startCase(item.opportunityStageValue),
          valuePopupText: '',
        },
        {
          isProgressbarWithText: true,
          size: 12,
          'size-md': 8,
          values: {
            start: {
              label: this.translate.instant('ACTUAL_REVENUE'),
              value: item.closedValueFormatted ? this.dateTimeFormatService.formattedCurrency(item.closedValueFormatted) : '',
              valuePopupText: '',
            },
            end: {
              label: this.translate.instant('ESTIMATED_REVENUE'),
              value: item.estimatedValueFormatted ? this.dateTimeFormatService.formattedCurrency(item.estimatedValueFormatted) : '',
              placeholderText: this.translate.instant('NO_PRODUCTS'),
              valuePopupText: '',
              cssClass: 'ion-text-end'
            },
            progressValue: progress === 'NaN' ? 0 : progress

          }
        }
      ]
    };
    return view;
  }
  public _getProfileItemViewForAccountPlan(item): any {
    let view: TimeLineItem;
    let startDate = item.startDate || item.indskr_startdate;
    let endDate = item.endDate || item.indskr_enddate;
    let StartDayValue = this.datePipe.transform(startDate, this.dateTimeFormatService.date, undefined, this.translate.currentLang);
    let EndDayValue = this.datePipe.transform(endDate, this.dateTimeFormatService.date, undefined, this.translate.currentLang);
    let status = item['statuscode'] && item['statuscode'] == 548910001 ? 'Completed' : item['statuscode'] && item['statuscode'] == 2 ? 'Inactive' : item['statuscode'] && item['statuscode'] == 548910002 ? 'Cancelled' : 'Open';

    view = {
      id: item.ID,
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: 'primary',
      headerText: item.accountPlanName || item.indskr_name
      ,
      customCssClass: 'normal-list',
      mainIcon: "assets/imgs/tool-icon-Account-Profile.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value: status,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('PRODUCTS'),
          value: (item.products && item.products.length > 0) ? item.products[0].productName : '',
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
          popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
            let obj = {
              id: a.productId,
              title: a.productName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('START_DATE'),
          value: StartDayValue,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('END_DATE'),
          value: EndDayValue,
          valuePopupText: '',
        }
      ]
    };
    return view;
  }



}




