import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@omni/services/device/device.service';
import { GoAppNavigationService } from '@omni/services/go-app-navigation.service';

@Component({
  selector: 'go-app-footer-navigation-menu',
  templateUrl: './go-app-footer-navigation-menu.component.html',
  styleUrl: './go-app-footer-navigation-menu.component.scss'
})
export class GoAppFooterNavigationMenuComponent {

  public isMobileView: boolean = false;
  public isFooterExpanded: boolean = false;
  public footerOptions = [];
  public moreOptions = [];
  private prevSelectedOption = null;

  @ViewChild('menuContainer') menuContainerRef: ElementRef;
  @ViewChild('overlay') overlayRef: ElementRef;
  @ViewChild('footer') footerRef: ElementRef;

  constructor(private translate: TranslateService,
    public device: DeviceService,
    private goAppNavigationService: GoAppNavigationService,
  ) {

  }

  ngOnInit() {
    this.isMobileView = window.innerWidth <= 768;
    this.initMobileFooterOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobileView = window.innerWidth <= 768;
    this.initMobileFooterOptions();
  }

  private initMobileFooterOptions() {
    this.footerOptions = [{
      id: 'dashboard',
      visible: true,
      iconPath: 'assets/imgs/home-grey.svg',
      selectedIconPath: 'assets/imgs/home-blue.svg',
      displayName: this.translate.instant('HOME'),
      selected: true,
      component: 'HomePage',
      enable: true
    },
    {
      id: 'calendar',
      visible: true,
      iconPath: 'assets/imgs/calendar-grey.svg',
      selectedIconPath: 'assets/imgs/calendar-blue.svg',
      displayName: this.translate.instant('CALENDAR'),
      selected: false,
      component: 'CalendarPage',
      enable: true
    },
    {
      id: 'more',
      visible: true,
      iconPath: 'assets/imgs/more-grey-icon.svg',
      selectedIconPath: 'assets/imgs/more-blue-icon.svg',
      displayName: this.translate.instant('MORE'),
      selected: false,
      component: '',
      enable: true
    }];
    this.moreOptions = this.goAppNavigationService.initMenuOptions();
  }

  onClickMenuOption(option, ev) {
    if (this.isFooterExpanded && option.id == "more" && this.prevSelectedOption) {
      this.footerOptions.forEach(op => op.selected = op.id == this.prevSelectedOption.id);
    }
    if (option.id != "more" && this.isFooterExpanded) {
      this.isFooterExpanded = false;
    }
    switch (option.id) {
      case "hamburger":
        break;
      case "dashboard":
      case "calendar":
        this.footerOptions.forEach(op => op.selected = option.id == op.id);
        break;
      case "more":
        if (!this.isFooterExpanded)
          this.prevSelectedOption = this.footerOptions.find(op => op.selected);
        this.isFooterExpanded = !this.isFooterExpanded;
        this.footerOptions.forEach(op => op.selected = option.id == op.id);
        setTimeout(() => {
          this.calculateOverlayHeight();
        }, 100);
        break;
      default:
        this.goAppNavigationService.navigateToMenuOption(option);
        //Keep home as selected on clicking back
        this.footerOptions.forEach(op => op.selected = op.id == "dashboard");
        break;
    }
  }

  closeMoreOptions() {
    this.isFooterExpanded = false;
    if (this.prevSelectedOption?.id !== "more") {
      this.footerOptions.forEach(op => op.selected = op.id == this.prevSelectedOption.id);
    }
  }

  calculateOverlayHeight() {
    if(this.menuContainerRef && this.overlayRef) {
      const envSafeAreaBottom = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom');
      this.overlayRef.nativeElement.style.height = window.innerHeight - this.menuContainerRef.nativeElement.offsetHeight - this.footerRef.nativeElement.offsetHeight - (envSafeAreaBottom ? parseInt(envSafeAreaBottom, 10) : 0) + 'px';
    }
  }
}
