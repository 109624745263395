<!-- Generated template for the CasePopoverComponent component -->

<!-- Opens as a popup -->
<div *ngIf="type === 'list-filter' || type === 'response-preference' || type === 'case-category' || type === 'contact-details' || type === 'therapeutic-area'">
    <ion-header>
    </ion-header>
    <ion-content>
        <!-- List filter -->
        <div *ngIf="type === 'list-filter'">
            <ion-list *ngIf="caseManagementService.listMode === 'myCases'">
                <ion-item class='selector-item' (click)="listFilterUpdate('All')">
                    {{'ALL_INQUIRIES' | translate}}
                    <ion-icon slot="end" *ngIf="caseManagementService.caseFilterText === 'All'" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item class='selector-item' *ngFor="let item of caseManagementService.caseTypeList" (click)="listFilterUpdate(item.name)">
                    {{item?.name}}
                    <ion-icon slot="end" *ngIf="caseManagementService.caseFilterText === item.name" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="caseManagementService.caseTypeList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
            <ion-list *ngIf="caseManagementService.listMode === 'teamCases'">
                <ion-item class='selector-item' (click)="listFilterUpdate('All')">
                    {{'ALL_INQUIRIES' | translate}}
                    <ion-icon slot="end" *ngIf="caseManagementService.teamInquiriesFilter.type === 'All'" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item-divider class="custom-header">
                    {{'CASE_TYPE' | translate}}
                </ion-item-divider>

                <ion-item class='selector-item' *ngFor="let item of caseManagementService.caseTypeList" (click)="listFilterUpdate(item.name)">
                    {{item?.name}}
                    <ion-icon slot="end" *ngIf="caseManagementService.teamInquiriesFilter.type === item.name" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="caseManagementService.caseTypeList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
                <ion-item-divider class="custom-header">
                    {{'PRODUCTS' | translate}}
                </ion-item-divider>
                <ion-item class='selector-item' *ngFor="let product of caseManagementService.caseProductSKU" (click)="teamListFilter(product, 'Product')">
                    {{product?.name}}
                    <ion-icon slot="end" *ngIf="(caseManagementService.filterObject && caseManagementService.filterObject.ID == product.ID)" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-list-header class="custom-header" (click)="goToUserSelection()" *ngIf="teamViewEnabled">
                    {{'USERS' | translate}}
                    <ion-icon class="light-color" tappable name="ios-arrow-forward" slot="end"></ion-icon>
                </ion-list-header>
            </ion-list>
        </div>

        <!-- Response Preference -->
        <div *ngIf="type === 'response-preference'">
            <ion-list class="ion-no-margin ion-no-padding">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                    <ion-label>{{pref.name | titlecase}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase._case_response_preference_id === pref.id" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
        </div>

        <!-- Case Category -->
        <div *ngIf="type === 'case-category'">
            <ion-list class="ion-no-margin ion-no-padding">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                    <ion-label>{{pref.name}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase?._case_category_id === pref.id" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
        </div>

        <!-- Contact details select -->
        <div *ngIf="type === 'contact-details'">
            <ion-list *ngIf="subType === 'email'">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                  <ion-label>{{pref.emailAddress}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase?._selected_email_id == pref.emailAddress" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
            <ion-list *ngIf="subType === 'phone'">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                  <ion-label>{{pref}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase?._selected_phone == pref" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
            <ion-list *ngIf="subType === 'visit'">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                    <ion-label>{{pref.compositeAdd}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase?._selected_address == pref.compositeAdd" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
        </div>

        <!-- Therapeutic Area -->
        <div *ngIf="type === 'therapeutic-area'">
            <ion-list class="ion-no-margin ion-no-padding">
                <ion-item class='selector-item' *ngFor="let pref of optionList" (click)="selectOptionAndClose(pref)">
                    <ion-label>{{pref.therapeuticarea_name | titlecase}}</ion-label>
                    <ion-icon slot="end" *ngIf="selectedCase?._case_therapeutic_id === pref.therapeuticareaid" class='checkmark-icon'>
                    </ion-icon>
                </ion-item>
                <ion-item *ngIf="optionList.length === 0">
                  <ion-label>{{'NO_OPTIONS_AVAILABLE' | translate}}</ion-label>
                </ion-item>
            </ion-list>
        </div>

    </ion-content>
</div>

<!-- Opens as Modal -->
<ion-grid class="fullstrech" *ngIf="type === 'product-select'">
    <ion-row class="fullstrech">
        <ion-col class="left-pane fullstrech">
            <ion-header>
                <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="searchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="onSearch()">
                </ind-header-left>
            </ion-header>
            <ion-content>
                <!-- Product selection on details -->
                <div *ngIf="type === 'product-select'" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                    <ion-list *ngIf="optionList.length != 0">
                        <ind-section-header *ngIf="listSelection" [viewData]="caseSelectHeaderModel"></ind-section-header>
                        <!-- <ion-item-divider *ngIf="listSelection">
                            <span class="ion-float-left">{{'SELECTED' | translate}} (1)</span>
                        </ion-item-divider> -->
                        <ion-item class='selector-item-product' *ngIf="listSelection">
                            <ion-label>{{listSelection.name}}</ion-label>
                            <ion-icon (click)="unselect()" name="indegene-selectors-remove-icon" class='remove-icon' slot="end"></ion-icon>
                        </ion-item>
                        <!-- <ion-item-divider *ngIf="searchText.length === 0">
                            <span class="ion-float-left">{{'ALL_PRODUCTS_CAP' | translate}} ({{productSize}})</span>
                        </ion-item-divider> -->
                        <ind-section-header *ngIf="searchText.length === 0" [viewData]="caseAllProductHeaderModel"></ind-section-header>

                        <!-- <ion-item-divider class="results-text" *ngIf="searchText.length > 0">
                            <span class="ion-float-left">{{'RESULTS_CAP' | translate}} ({{productSize}})</span>
                        </ion-item-divider> -->
                        <ind-section-header *ngIf="searchText.length > 0" [viewData]="caseResultHeaderModel"></ind-section-header>

                        <ion-item-group *ngFor="let item of optionList | orderBy: 'name' :true">
                            <ion-item class='selector-item-product' (click)="onOptionSelection(item)">
                                <ion-label>
                                    {{item.name}}
                                </ion-label>
                                <ion-icon *ngIf="listSelection && item.ID == listSelection?.ID" class='checkmark-icon' slot="end"></ion-icon>
                                <ion-icon *ngIf="!listSelection || item.ID != listSelection?.ID" class='add-icon' slot="end"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                            <ion-item *ngIf="optionList.length === 0">
                                <ion-label>{{'NO_PRODUCTS_AVAILABLE' | translate}}</ion-label>
                            </ion-item>
                            <div *ngIf="item.skus.length != 0">
                                <ion-item-group class="pad-10 selector-item-product" *ngFor="let sku of item.skus | orderBy: 'name' :true" (click)="onOptionSelection(sku)">
                                    <ion-item>
                                        <ion-label>
                                            {{sku.name}}
                                        </ion-label>
                                        <ion-icon *ngIf="listSelection && sku.ID == listSelection?.ID" class='checkmark-icon' slot="end"></ion-icon>
                                        <ion-icon *ngIf="!listSelection || sku.ID != listSelection?.ID" class='add-icon' slot="end"></ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </div>
                        </ion-item-group>
                    </ion-list>
                    <ion-item-divider *ngIf="searchText.length === 0 && optionList.length === 0">
                        <span class="ion-float-left">{{'PRODUCTS_CAP' | translate}} ({{productSize}})</span>
                    </ion-item-divider>
                    <ion-item-divider class="results-text" *ngIf="searchText.length > 0 && optionList.length === 0">
                        <span class="ion-float-left">{{'RESULTS_CAP' | translate}} ({{productSize}})</span>
                    </ion-item-divider>
                    <div *ngIf="optionList.length === 0 && !searchText" class="no-data-message">{{'SCHEDULER_NO_PRODUCTS'| translate}}</div>
                    <ind-no-search-result *ngIf="optionList.length === 0 && searchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                </div>
            </ion-content>
            <!-- <ion-footer>/ -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'callplans'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>

        <ion-col class="right-pane fullstrech">
            <nothing-selected-view [textToDisplay]="''" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
        </ion-col>
    </ion-row>
</ion-grid>
