import { FieldMaterialManagementService } from './../../services/field-materials/field-material-management.service';
import { ProcedureTracker, ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerActivityDataService } from './../procedure-tracker-activity/procedure-tracker-activity.data.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from './../dynamics-client/dynamics-client.service';
import { AccountManagementOfflineService } from './../../services/account-management/account-management.service';
import { Resource, SharedResourceStatus } from './../../classes/resource/resource.class';
import { Observable } from 'rxjs';
import {
  Activity,
  ActivityCancellationReson,
  ActivityType,
  ActivityTypeCode,
  ConfigFieldOptionResponse,
  ConfigFieldOptionValue,
  MeetingActivityType,
  MeetingSubActivityType
} from '../../classes/activity/activity.class';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import { AuthenticationService } from '../../services/authentication.service';
import { EmailActivity } from '../../classes/activity/email.activity.class';
import { timeout } from 'rxjs/operators';
import { ActivityService } from '../../services/activity/activity.service';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { ActivityCompetitorProduct, AppointmentActivity, MeetingTypeDTO } from '../../classes/activity/appointment.activity.class';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../../services/disk/disk.service';
import { LogService } from '../../services/logging/log-service';
import { Contact } from '../../classes/contact/contact.class';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { Account } from '../../classes/account/account.class';
import { TimeOffActivity } from '../../classes/activity/timeoff.class';
import { TimeOffDataService } from '../../data-services/time-off/time-off.data.service';
import { TimeOffService } from '../../services/time-off/time-off.service';
import { Presentation } from '../../classes/presentation/presentation.class';
import { PresentationService } from '../../services/presentation/presentation.service';
import { DeviceService } from '../../services/device/device.service';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { Brand } from '../../classes/brand/brand.class';
import { LoadingController } from '@ionic/angular';
import { Events } from '@omni/events';
import { BrandDataService } from '../brand/brand.data.service';
import { ActivityPresentation } from '../../classes/presentation/activity-presentation.class';
import { ResourceService } from '../../services/resource/resource.service';
import { AuthenticationDataService } from '../authentication/authentication.service';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '../../config/pouch-db.config';
import { AccompainedUser } from '../../classes/activity/accompained-user.class';
import { SampleActivity } from '../../classes/activity/sample.activity.class';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { TrackAction } from '../../utility/common-enums';
import { NotificationService } from '../../services/notification/notification.service';
import { MyAssistantService, NOTIFICATION } from '../../services/my-assistant/my-assistant.service';
import { FollowUpActivityDataService, OperationDetail } from '../follow-up-activity/follow-up-activity.data.service';
import { AssignmentHistory, FollowUpActivity, TaskUser } from '../../classes/activity/follow-up-action.activity.class';
import { EmailService } from '../../services/email-templates/email.service';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { IONote } from '../../classes/io/io-note.class';
import { ConsentService } from '../../services/consent/consent.service';
import { differenceInHours, format, isAfter, isBefore } from 'date-fns';
import { OrderActivity, OrderProductClass } from '../../classes/activity/order.activity.class';
import { OrderActivityDataService } from '../order-activity/order-activity.data.service';
import { CaseManagementService } from '../../services/case-management/case-management.service';
import { fetchXmlEntity } from '../../config/dynamics-fetchQueries';
import { Channel, ChannelActivityType, ChannelType } from '../../classes/consent/channel.class';
import { PollService } from './../../services/poll/poll.service';
import { TherapeuticAreaDataService } from '../therapeutic-area/therapeutic-area.data.service';
import { TherapeuticArea } from '../../classes/therapeutic-area/therapeutic-area.class';
import { TranslateService } from '@ngx-translate/core';
import { SampleService } from '../../services/sample/sample.service';
import { CaseManagementDataService } from '../case-management/case-management.data.service';
import { PhoneCallDataService } from '../phone-call/phonecall.data.service';
import { CoachingReportDataService } from '../coaching/coaching.report.data.service';
import { NextCallObjectiveDataService } from '../next-call-objective/next.call.objective.data.service';
import { UIService } from '@omni/services/ui/ui.service';
import { UpdateTypeAndSubTypeActivityPayLoad } from '../meeting/meeting.data.service';
import { ConfiguredFields, hcpInteractionDefaultField } from '@omni/classes/authentication/configured.field.class';
import { ActivityResource } from '@omni/classes/resource/activity-resource.class';
import {
  OrderContact,
  OrderProductCategory,
  SurgeryOrderActivity
} from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderActivityDataService } from '../surgery-order-activity/surgery-order-activity.data.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { AgendaFooterService } from '../../services/footer/agenda-footer.service';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { ReportDataManagementService } from '@omni/services/reports/report-data-management.service';
import { MeasureType } from '@omni/enums/edge-analytics/edge-analytics.enum';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { getConfigFieldPickListRequestURL, parseConfigFieldPicklistResponse } from '../../utility/common.utility';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import DynamicsWebApi from 'dynamics-web-api';
import { ACCOUNT_VISIT_ALLOWED_FORMAT_IDS_IO_CONFIGURATION_NAME } from '@omni/config/shared.config';
import { IoConfiguration } from '@omni/interfaces/shared/shared.interface';
import { ACTIVITY_ATTENDEE_JOINING_DETAIL_ENTITY_NAME, IO_CONFIGURATION_ENTITY_NAME } from '@omni/config/fetch-xml/shared-fetchXML-entity-names';
import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { StoreCheckService } from '@omni/services/store-check/store-check.service';
import { ProcedureContractService } from '../procedure-contract/procedure-contract.service';
// import { SetBookingDataService } from '../set-booking/set-booking.data.service';
import { RequiredSetBookingAttributes, SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import * as XML2JS from 'xml2js';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataService {
  private currentStartDate: Date;
  private currentEndDate: Date;
  public activityDetailsLoaded: boolean = false;

  //private _isInitialMappingDone: boolean = false;
  private _isInitialMeetingMappingDone: boolean = false;
  private _isInitialTimeOffMappingDone: boolean = false;
  private _isInitialSampleDropsMappingDone: boolean = false;
  private _isInitialPhoneCallsMappingDone: boolean = false;
  private _isInitialTasksMappingDone: boolean = false;
  private _isInitialCasesMappingDone: boolean = false;
  private _isInitialMessagesMappingDone: boolean = false;
  private _isInitialOrdersMappingDone: boolean = false;
  private _isInitialSurgeryOrdersMappingDone: boolean = false;
  private _isInitialProcedureTrackersMappingDone: boolean = false;
  private activityNotificationDataModel: IndNotificationDataModel;

  public isUserDatetimeSettingsEnabled: boolean = false;
  public isCustomerAdditionalInformationEnabled: boolean = false;

  constructor(
    private uiService: UIService,
    public events: Events,
    private disk: DiskService,
    private http: HttpClient,
    private brandDataService: BrandDataService,
    private authenticationService: AuthenticationService,
    private authenticationDataService: AuthenticationDataService,
    private activityService: ActivityService,
    private logService: LogService,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private timeOffDataService: TimeOffDataService,
    private presentationService: PresentationService,
    private deviceService: DeviceService,
    private brandService: BrandOfflineService,
    private resourceService: ResourceService,
    private timeOffService: TimeOffService,
    private deltaService: DeltaService,
    private notificationService: NotificationService,
    private myAssistantService: MyAssistantService,
    private followUpActivityDataService: FollowUpActivityDataService,
    private emailService: EmailService,
    private samplingService: SampleService,
    public accountManagementOfflineService: AccountManagementOfflineService,
    private loadingCtrl: LoadingController,
    private consetnService: ConsentService,
    private dynamics: DynamicsClientService,
    private orderActivityDataService: OrderActivityDataService,
    private phoneCallDataService: PhoneCallDataService,
    private injector: Injector,
    private caseManagementDataService: CaseManagementDataService,
    private caseManagementService: CaseManagementService,
    private therapeuticService: TherapeuticAreaDataService,
    private translate: TranslateService,
    private consentService: ConsentService,
    private coachingDataService: CoachingReportDataService,
    private nextCallObjectiveDataService: NextCallObjectiveDataService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    private utilityService: GlobalUtilityService,
    private reportDataMgmService: ReportDataManagementService,
    public dateFormatsService: DateTimeFormatsService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    private fieldMaterialManagementService: FieldMaterialManagementService,
    private storeCheckService: StoreCheckService,
    private contractService : ProcedureContractService,
    private dynamicFormsService : DynamicFormsService,
    // private setBookingDataService : SetBookingDataService,
  ) { }


  // async syncActivitiesOld(dataRange: { from: string, to: string }, loadFromDbOnly = false, noOfflineFallback = false, forceFullSync = false) {
  //   this.deltaService.pushSyncEntityName(SyncFeatureCategory.activities);

  //   if (!(this.deviceService.isOffline || loadFromDbOnly)) {
  //     let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACTIVITY);
  //     const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //     const doFullSync = forceFullSync || isInitialSync;
  //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ALL_ACTIVITY_DETAILS
  //       .replace('{startDate}', dataRange.from)
  //       .replace('{endDate}', dataRange.to);
  //     const positions = this.authenticationService.user.positions.map((o) => {
  //       return o.ID
  //     })
  //     url = url.replace('{{positionIDs}}', positions.toString())
  //     let appointmentConfigFields: string[] = []
  //     if (this.authenticationService.user.appointmentConfiguredFields != undefined && this.authenticationService.user.appointmentConfiguredFields.length > 0) {
  //       appointmentConfigFields = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
  //         return obj.fieldName
  //       })
  //     }

  //     url = url.replace('{{appointmentConfigFields}}', appointmentConfigFields.toString());

  //     let phonecallConfiguredFields: string[] = this.getPhoneCallConfigFields();
  //     url = url.replace('{{phonecallConfigFields}}', phonecallConfiguredFields.toString());


  //     url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //     const meetingActivitySyncInfo: EntitySyncInfo = {
  //       entityName: EntityNames.appointment,
  //       totalFailed: 0,
  //       totalSynced: 0,
  //       errors: [],
  //       syncStatus: true
  //     };

  //     const allocationOrderSyncInfo: EntitySyncInfo = {
  //       entityName: EntityNames.allocationOrder,
  //       totalFailed: 0,
  //       totalSynced: 0,
  //       errors: [],
  //       syncStatus: true
  //     };

  //     const timeOffSyncInfo: EntitySyncInfo = {
  //       entityName: EntityNames.timeOff,
  //       totalFailed: 0,
  //       totalSynced: 0,
  //       errors: [],
  //       syncStatus: true
  //     };

  //     let response: any;
  //     let headers = new HttpHeaders();
  //     headers = headers.set('Sync-Service', 'true');
  //     headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //     try {
  //       response = await this.http.get(url, { headers }).toPromise();
  //     } catch (error) {
  //       console.error('syncActivities: ', error);
  //       this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, error);
  //       this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
  //       return;
  //     }

  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (doFullSync) {
  //         // full sync flow
  //         await this.activityService.mapFullSyncedActivities(response['meetings'], newLastUpdatedTime, forceFullSync);
  //         if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
  //           await this.activityService.mapFullSyncedSampleOrderActivities(response['sampleDrops'], newLastUpdatedTime);
  //         }
  //         if (this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
  //           await this.activityService.mapFullSyncedPhoneCallActivities(response['phoneCalls'], newLastUpdatedTime);
  //         }
  //         if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
  //           await this.timeOffService.mapFullSyncedTots(response['myTimeOffs'], newLastUpdatedTime);
  //         }
  //         if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS)) {
  //           await this.timeOffService.mapFullSyncedTeamTots(response['teamTimeOffs'], newLastUpdatedTime);
  //         }

  //         if (response['meetings'] && Array.isArray(response['meetings'])) {
  //           meetingActivitySyncInfo.totalSynced += response['meetings'].length;
  //         }
  //         if (response['sampleDrops'] && Array.isArray(response['sampleDrops'])) {
  //           allocationOrderSyncInfo.totalSynced += response['sampleDrops'].length;
  //         }
  //         if (response['myTimeOffs'] && Array.isArray(response['myTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['myTimeOffs'].length;
  //         }
  //         if (response['teamTimeOffs'] && Array.isArray(response['teamTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['teamTimeOffs'].length;
  //         }
  //         if (response['followupTasks'] && Array.isArray(response['followupTasks']) && response['followupTasks'].length > 0) {
  //           let followUps: Array<FollowUpActivity> = [];
  //           response['followupTasks'].map(rawfollowup => {
  //             let followUpObj = new FollowUpActivity(rawfollowup);
  //             followUpObj.type = ActivityType.FollowUp;
  //             if (followUpObj.offlineDBId && followUpObj.ownerId && !(followUpObj.state == 2 && followUpObj.status == 6)) {
  //               followUps.push(followUpObj);
  //             }
  //           });
  //           let action: OperationDetail = {
  //             onDynamics: false,
  //             onLocalDatabase: true,
  //             onLocalCopy: true,
  //           };
  //           await this.followUpActivityDataService.createFollowUpActivity(action, followUps, newLastUpdatedTime, true);
  //         }
  //         this._isInitialMappingDone = true;
  //       }
  //       else {
  //         // delta sync flow

  //         // In case of app load, load data from local db and map
  //         // Won't do anything if already loaded
  //         if (!this._isInitialMappingDone) {
  //           await this.activityService.loadActivitiesFromDBAndMap(dataRange);
  //           await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
  //           await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
  //           await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
  //           await this.timeOffService.loadTeamTotsFromDBAndMap(dataRange);
  //           await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
  //           if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
  //             await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
  //           }

  //           this._isInitialMappingDone = true;
  //         }

  //         // Sync the deltas
  //         await this.activityService.mapDeltaSyncedActivities(response['meetings'], newLastUpdatedTime);
  //         if (Array.isArray(response['meetings'])) {
  //           meetingActivitySyncInfo.totalSynced = response['meetings'].length;
  //         }
  //         await this.activityService.mapDeltaSyncedPhoneActivities(response['phoneCalls'], newLastUpdatedTime);
  //         if (Array.isArray(response['phoneCalls'])) {
  //           meetingActivitySyncInfo.totalSynced = response['phoneCalls'].length;
  //         }
  //         await this.activityService.mapDeltaSyncedSampleOrderActivities(response['sampleDrops'], newLastUpdatedTime);
  //         if (Array.isArray(response['sampleDrops'])) {
  //           allocationOrderSyncInfo.totalSynced = response['sampleDrops'].length;
  //         }
  //         await this.timeOffService.mapDeltaSyncedTots(response['myTimeOffs'], response['deletedTimeOffs'], newLastUpdatedTime);
  //         if (Array.isArray(response['myTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['myTimeOffs'].length;
  //         }
  //         if (Array.isArray(response['deletedTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['deletedTimeOffs'].length;
  //         }
  //         await this.timeOffService.mapDeltaSyncedTeamTots(response['teamTimeOffs'], newLastUpdatedTime);
  //         if (Array.isArray(response['teamTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['teamTimeOffs'].length;
  //         }
  //         if (response['followupTasks'] && Array.isArray(response['followupTasks']) && response['followupTasks'].length > 0) {
  //           let followUps: Array<FollowUpActivity> = [];
  //           response['followupTasks'].map(rawfollowup => {
  //             let followUpObj = new FollowUpActivity(rawfollowup);
  //             if (rawfollowup['track_action'] && rawfollowup['track_action'] == TrackAction.Deleted) {
  //               this.followUpActivityDataService.handleDeletedTrackActionForFollowUpTask(rawfollowup);
  //             } else {
  //               followUpObj.type = ActivityType.FollowUp;
  //               if (followUpObj.offlineDBId && followUpObj.ownerId) {
  //                 followUps.push(followUpObj);
  //               }
  //             }
  //           });
  //           let action: OperationDetail = {
  //             onDynamics: false,
  //             onLocalDatabase: true,
  //             onLocalCopy: true,
  //             operationDetail: {
  //               code: 'FUPDEL101',
  //               message: this.translate.instant('DELTA_SYNC_UPDATE')
  //             }
  //           };
  //           if (followUps.length > 0) {
  //             await this.followUpActivityDataService.updateFollowUpActivity(action, followUps, newLastUpdatedTime);
  //           }
  //         }
  //       }
  //       this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
  //       this.deltaService.addEntitySyncInfo(allocationOrderSyncInfo);
  //       this.deltaService.addEntitySyncInfo(timeOffSyncInfo);

  //       // Done sync. Update sync state.
  //       if (meetingActivitySyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }

  //       // Update UI after sync is mapped
  //       // this.events.publish('refreshAgenda');
  //       if (!this.uiService.toolsActivityActive) {
  //         this.events.publish("refreshAgenda");
  //       }
  //       else {
  //         this.uiService.agendaRefreshRequired = true;
  //       }
  //     }
  //     // End of the flow for online fetch
  //     return;
  //   }

  //   // Falls through here if online fetch didn't work or if it's an offline flow
  //   if (!noOfflineFallback && !this._isInitialMappingDone && (this.deviceService.isOffline || loadFromDbOnly)) {
  //     // Load data from local db.
  //     await this.activityService.loadActivitiesFromDBAndMap(dataRange);
  //     await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
  //     await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
  //     await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
  //     await this.timeOffService.loadTeamTotsFromDBAndMap(dataRange);
  //     await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
  //     // await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
  //       await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
  //     }

  //     this._isInitialMappingDone = true;
  //     // Update UI after sync is mapped
  //     if (!this.uiService.toolsActivityActive) {
  //       this.events.publish('refreshAgenda');
  //     }
  //     else {
  //       this.uiService.agendaRefreshRequired = true;
  //     }
  //   }
  // }

  private getUrlForActivitesFetchByType(activityType,fromDate,toDate,lastUpdatedTime,positions,configFields,procedurelogStartDateStr:string = null){
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_SELF_ACTIVITIES_BY_ACTIVITY_TYPES;
    url = url.replace('{startDate}', fromDate);
    url = url.replace('{endDate}', toDate);
    url = url.replace('{activityTypes}', activityType);
    url = url.replace('{positionIDs}', positions.toString());
    if(configFields){
      url = url.concat(configFields);
    }
    if(procedurelogStartDateStr){
      url = url + procedurelogStartDateStr;
    }
    if(lastUpdatedTime){
      url = url + '&lastUpdatedTime=' + lastUpdatedTime;
    }

    if (activityType.includes('appointment')) {
      url = url.concat('&featureFlags=activityEvents');
      if(this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)){
        url = url.concat(',marketingbusinessplan');
      }
    }

    if (activityType.includes('surgery')) {
      // const fetchCancelledSurgeryOrders = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar'];
      // const covisitorEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_VISITOR);
      const marketingBusinessPlanEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);
      // const fetchShoreiMasterSurgeryOrders = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displayshorei'];
      let flags = [];

      // if(fetchCancelledSurgeryOrders) flags.push('fetchCancelOrders');
      // if(covisitorEnabled) flags.push('coVisitorProcedureLogs');
      if(marketingBusinessPlanEnabled) flags.push('marketingbusinessplan');
      // if(fetchShoreiMasterSurgeryOrders)flags.push('displayShorei');

      if(flags && flags.length > 0){
        url = url.concat(`&featureFlags=${flags.join(',')}`);
      }
    }
    return url;
  }

  private addFollowUpFeatureFlags(url: string) {
    const isMarketingPlanEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);
    const isEventFollowUpEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_FOLLOW_UP_ACTION);
    let hasBeenAdded = false;
    if (isMarketingPlanEnabled) {
      url = url + '&featureFlags=marketingbusinessplan';
      hasBeenAdded = true;
    }
    if (isEventFollowUpEnabled) {
      url = url + (hasBeenAdded ? ',eventfollowup' : '&featureFlags=eventfollowup');
    }
    return url;
  }

  private addProcedureLogFeatureFlags(url: string) {
    const isAssetBookingEnabled = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_assetavailabilitylogic'] == 548910001;
    const fetchCancelledSurgeryOrders = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar'];
    const covisitorEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_VISITOR);
    const fetchShoreiMasterSurgeryOrders = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displayshorei'];

    if (fetchShoreiMasterSurgeryOrders) {
      url = url.concat(url.includes('featureFlags') ? ',displayShorei' : '&featureFlags=displayShorei');
    }
    if (covisitorEnabled) {
      url = url.concat(url.includes('featureFlags') ? ',coVisitorProcedureLogs' : '&featureFlags=coVisitorProcedureLogs')
    }
    if (fetchCancelledSurgeryOrders) {
      url = url.concat(url.includes('featureFlags') ? ',fetchCancelOrders' : '&featureFlags=fetchCancelOrders')
    }

    if (isAssetBookingEnabled) {
      url = url.concat(url.includes('featureFlags') ? ',procedureLogAssetBooking' : '&featureFlags=procedureLogAssetBooking')
    }
    return url;
  }

  private fetchActivitiesForUserOnline(activityType,fromDate,toDate,lastUpdatedTime,positions,configFields,procedurelogStartDateStr:string = null, fetchCancelledMeetings = false): Promise<any> {
    let url = this.getUrlForActivitesFetchByType(activityType,fromDate,toDate,lastUpdatedTime,positions,configFields,procedurelogStartDateStr);
    let headers = new HttpHeaders();
    headers = headers.set('Sync-Service', 'true');
    headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);

    if(fetchCancelledMeetings && activityType === 'appointment'){
      // url = url + '&featureFlags=fetchCancelMeetings';
      url = url.concat(url.includes('featureFlags') ? ',fetchCancelMeetings' : '&featureFlags=fetchCancelMeetings');
    }

    if (activityType === 'followup') {
      url = this.addFollowUpFeatureFlags(url);
    } else if (activityType === 'surgery') {
      url = this.addProcedureLogFeatureFlags(url);
    }
    return this.http.get(url, { headers }).toPromise();
  }

  async syncActivities(dataRange: { from: string, to: string }, loadFromDbOnly = false, forceFullSync = false):Promise<any> {
    if (forceFullSync) {
      this.activityService.clear();
      try {
        // Delete all of the activity docs (not clearing offline created meetings that's not synced yet)
        // This is needed for force fullsync.
        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVITIES);
      } catch (error) {
        console.error('forceFullSync: failed to clear activities from DB: ', error);
      }
    }
    return Promise.all([
      this.syncAppointments(dataRange,forceFullSync,loadFromDbOnly),
      this.syncTimeoffs(dataRange,forceFullSync,loadFromDbOnly),
      this.syncAllocationOrders(dataRange,forceFullSync,loadFromDbOnly),
      this.syncFollowupTasks(dataRange,forceFullSync,loadFromDbOnly),
      this.syncPhoneCalls(dataRange,forceFullSync,loadFromDbOnly),
      this.syncMessages(dataRange,forceFullSync,loadFromDbOnly),
      this.syncOrders(dataRange,forceFullSync,loadFromDbOnly),
      this.syncSurgeryOrders(dataRange,forceFullSync,loadFromDbOnly),
      this.syncProcedureTrackers(dataRange,forceFullSync,loadFromDbOnly),
      this.syncCases(dataRange,forceFullSync,loadFromDbOnly),
    ]);
  }

  async syncAppointments(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEETINGS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;

      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      });
      let appointmentConfigFields:any;
      if (!_.isEmpty(this.authenticationService.user.appointmentConfiguredFields)) {
        appointmentConfigFields = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
          return obj.fieldName
        });
        appointmentConfigFields = "&appointmentConfigFields=".concat(appointmentConfigFields.toString());
      }

      const meetingActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.appointment,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      if (lastUpdatedTime || forceFullSync) {
        if (lastUpdatedTime) {
          if (!this._isInitialMeetingMappingDone) {
            await this.activityService.loadActivitiesFromDBAndMap(dataRange);
            this._isInitialMeetingMappingDone = true;
          }
        }
        //Save Meeting Presentation on delta sync/ ForceFullSync
        await this.saveOfflineMeetingPresentations();
      }

      try {
        const fetchCancelledMeetings = this.authenticationService.user.buSettings &&
        this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'] ? true : false;
        const response = await this.fetchActivitiesForUserOnline('appointment',dataRange.from,dataRange.to,lastUpdatedTime,positions,null,appointmentConfigFields,fetchCancelledMeetings);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (response['meetings'] && Array.isArray(response['meetings'])) {
            meetingActivitySyncInfo.totalSynced += response['meetings'].length;
            if (lastUpdatedTime) {

              await this.activityService.mapDeltaSyncedActivities(response['meetings'], newLastUpdatedTime);
            } else {
              await this.activityService.mapFullSyncedActivities(response['meetings'], newLastUpdatedTime, forceFullSync);
              this._isInitialMeetingMappingDone = true;
            }
          }
          this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
          // Done sync. Update sync state.
          if (meetingActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }

        }
      } catch (error) {
        console.error('syncMeetings: ', error);
        let url = this.getUrlForActivitesFetchByType('appointment',dataRange.from,dataRange.to,lastUpdatedTime,positions,appointmentConfigFields);
        this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.activityService.loadActivitiesFromDBAndMap(dataRange);
      this._isInitialMeetingMappingDone = true;
    }
  }

  async saveOfflineMeetingPresentations() {
    if (this.disk.getOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_PRESENTATIONS) > 0) {
      try {

        const dbDoc = await this.disk.retrieve('offlineMeetingPresentations');
        if (dbDoc && !_.isEmpty(dbDoc.meetings)) {
          const payload = [];
          // dbDoc.meetings.forEach(meeting => {
          for (let meeting of dbDoc.meetings) {
            const activity = this.activityService.activities.find(activity => activity.ID === meeting.activityid && activity.type === ActivityType.Appointment);
            //Save only for online meetings
            if (activity && !this.activityService.hasOfflineMeetingData(activity.ID)) {
              const appointmentActivity = activity as AppointmentActivity;
              // await this.activityService.updateSlideEndTime(appointmentActivity, true);
              const offlineDTO = appointmentActivity.DTO;
              offlineDTO['activityPresentations'] = meeting['activityPresentations'];
              offlineDTO['activityResources'] = meeting['activityResources'];
              payload.push(offlineDTO);
            }

          }
          if (!_.isEmpty(payload)) {
            const headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
            headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
            const body = {
              offlineMeetings: payload
            };
            const response = await this.http.post(this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_MEETING, body, headers).toPromise();
            if (response && response['offlineMeetings']) {
              // Loop through offlineMeetings response
              for (const key in response['offlineMeetings']) {
                if (response['offlineMeetings'].hasOwnProperty(key)) {
                  const meetingResponse = response['offlineMeetings'][key];

                  if (!meetingResponse.hasOwnProperty('errorId')) {
                    if (meetingResponse.hasOwnProperty('activityId')) {
                      const idx = dbDoc.meetings.findIndex(a => a.activityid === meetingResponse.activityId);
                      if (idx >= 0) {
                        // Remove from offline db record
                        dbDoc.meetings.splice(idx, 1);
                        // Since upload was successful, delete from offline meeting data id hashmap if exists
                        if (this.activityService.hasOfflineMeetingPresentationData(meetingResponse.activityId)) {
                          this.activityService.deleteFromOfflineMeetingPesentationIds(meetingResponse.activityId);
                        }
                      } else {
                        console.warn(`uploadOfflineMeetingPresentations: ID ${meetingResponse.activityId} is not found from local db.`);
                      }
                    } else {
                      console.error(`uploadOfflineMeetings: 'activityId' is missing from the response: `, meetingResponse);
                    }
                  }
                }
              }
              this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_PRESENTATIONS, dbDoc.meetings.length);
              await this.disk.updateOrInsert('offlineMeetingPresentations', doc => ({ meetings: dbDoc.meetings }));
            }
          }

        }
      }
      catch (error) {
        console.error("failed saveOfflineMeetingPresentations: ", error);
      }
    }
  }

  async fetchAccountVisitFormatFieldConfig(loadFromDbOnly: boolean) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)) {
      return;
    }
    if (this.deviceService.isOffline || loadFromDbOnly) {
      // Offline
      await this.activityService.loadAccountVisitAllowedFormatIDsConfiguration();
      return;
    }
    // Online
    try {
      const response: IoConfiguration[] = await this.fetchIoConfigValueOnline(ACCOUNT_VISIT_ALLOWED_FORMAT_IDS_IO_CONFIGURATION_NAME);
      await this.activityService.saveAccountVisitAllowedFormatIDsConfiguration(response);
    } catch (error) {
      console.error('fetchAccountVisitFormatFieldConfig: ', error);
    }
  }

  async fetchIoConfigValueOnline(configName: string): Promise<IoConfiguration[]> {
    if (this.deviceService.isOffline) {
      return null;
    }

    let fetchXML = fetchQueries.fetchIoConfigurations;
    const filter = `<filter type="and">
      <condition attribute="indskr_configname" operator="eq" value="${configName}" />
    </filter>`;

    fetchXML = fetchXML.replace(
      '{filter}',
      filter,
    );

    try {
      return await this.dynamics.executeFetchQuery(
        IO_CONFIGURATION_ENTITY_NAME,
        fetchXML,
      );
          } catch (error) {
      console.error('fetchIoConfigValueOnline: ', error);
      return null;
    }
  }

  async fetchAttendeeJoiningDetailsOnline(activityId: string): Promise<any[]> {
    let fetchXML = fetchQueries.fetchActivityAttendeeJoiningDetailsByAppointmentId;
    fetchXML = fetchXML.replace('{appointmentId}', activityId);
    try {
      return await this.dynamics.executeFetchQuery(ACTIVITY_ATTENDEE_JOINING_DETAIL_ENTITY_NAME, fetchXML);
    } catch (error) {
      console.error('fetchAttendeeJoiningDetailsOnline: ', error);
      return null;
    }
  }

  async syncTimeoffs(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) return;
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_TIMEOFFS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      });

      const timeOffSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.timeOff,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      try {
        const response = await this.fetchActivitiesForUserOnline('timeoff',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (!doFullSync) {
            if (Array.isArray(response['deletedTimeOffs'])) {
              timeOffSyncInfo.totalSynced += response['deletedTimeOffs'].length;
            }
          }
          if (response['myTimeOffs'] && Array.isArray(response['myTimeOffs'])) {
            timeOffSyncInfo.totalSynced += response['myTimeOffs'].length;
          }

          if(lastUpdatedTime){
            if(!this._isInitialTimeOffMappingDone) {
              await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
              this._isInitialTimeOffMappingDone = true;
            }
            await this.timeOffService.mapDeltaSyncedTots(response['myTimeOffs'], response['deletedTimeOffs'], newLastUpdatedTime);
          }else{
            await this.timeOffService.mapFullSyncedTots(response['myTimeOffs'], newLastUpdatedTime);
            this._isInitialTimeOffMappingDone = true;
          }

          this.deltaService.addEntitySyncInfo(timeOffSyncInfo);
          // Done sync. Update sync state.
          if (timeOffSyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncTimeoffs: ', error);
        let url = this.getUrlForActivitesFetchByType('timeoff',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
        this.deltaService.addSyncErrorToEntitySyncInfo(timeOffSyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(timeOffSyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
      this._isInitialTimeOffMappingDone = true;
    }
  }

  async syncAllocationOrders(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) return;
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOCATION_ORDERS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      const allocationOrderSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.allocationOrder,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this.fetchActivitiesForUserOnline('sampledrop',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (Array.isArray(response['sampleDrops'])) {
            allocationOrderSyncInfo.totalSynced = response['sampleDrops'].length;
          }

          if(lastUpdatedTime){
            if(!this._isInitialSampleDropsMappingDone) {
              await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
              this._isInitialSampleDropsMappingDone = true;
            }
            await this.activityService.mapDeltaSyncedSampleOrderActivities(response['sampleDrops'], newLastUpdatedTime);
          }else{
            await this.activityService.mapFullSyncedSampleOrderActivities(response['sampleDrops'], newLastUpdatedTime);
            this._isInitialSampleDropsMappingDone = true;
          }

          this.deltaService.addEntitySyncInfo(allocationOrderSyncInfo);
          // Done sync. Update sync state.
          if (allocationOrderSyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncAllocationOrders: ', error);
        let url = this.getUrlForActivitesFetchByType('sampledrop',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
        this.deltaService.addSyncErrorToEntitySyncInfo(allocationOrderSyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(allocationOrderSyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
      this._isInitialSampleDropsMappingDone = true;
    }
  }

  async syncFollowupTasks(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)
    && !this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
    && !this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK)) return;
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_FOLLOWUP_TASKS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      const followupSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.followup,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this.fetchActivitiesForUserOnline('followup',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (Array.isArray(response['followupTasks'])) {
            followupSyncInfo.totalSynced = response['followupTasks'].length;
          }

          if(lastUpdatedTime){
            if(!this._isInitialTasksMappingDone) {
              await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
              this._isInitialTasksMappingDone = true;
            }
            if (response['followupTasks'] && Array.isArray(response['followupTasks']) && response['followupTasks'].length > 0) {
              let followUps: Array<FollowUpActivity> = [];
              response['followupTasks'].map(rawfollowup => {
                let followUpObj = new FollowUpActivity(rawfollowup);
                if (rawfollowup['track_action'] && rawfollowup['track_action'] == TrackAction.Deleted) {
                  this.followUpActivityDataService.handleDeletedTrackActionForFollowUpTask(rawfollowup);
                } else {
                  followUpObj.type = ActivityType.FollowUp;
                  if (followUpObj.offlineDBId && followUpObj.ownerId) {
                    followUps.push(followUpObj);
                  }
                }
              });
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'FUPDEL101',
                  message: this.translate.instant('DELTA_SYNC_UPDATE')
                }
              };
              if (followUps.length > 0) {
                await this.followUpActivityDataService.updateFollowUpActivity(action, followUps, newLastUpdatedTime);
              }
            }
          }else{
            if (response['followupTasks'] && Array.isArray(response['followupTasks']) && response['followupTasks'].length > 0) {
              let followUps: Array<FollowUpActivity> = [];
              response['followupTasks'].map(rawfollowup => {
                let followUpObj = new FollowUpActivity(rawfollowup);
                followUpObj.type = ActivityType.FollowUp;
                if (followUpObj.offlineDBId && followUpObj.ownerId) {
                  followUps.push(followUpObj);
                }
              });
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'INITIAL_SYNC_DATA_MAPPING',
                  message: 'Initial sync data mapping'
                }
              };
              await this.followUpActivityDataService.createFollowUpActivity(action, followUps, newLastUpdatedTime, true);
              this._isInitialTasksMappingDone = true;
            }
          }

          this.deltaService.addEntitySyncInfo(followupSyncInfo);
          // Done sync. Update sync state.
          if (followupSyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncFollowupTasks: ', error);
        let url = this.getUrlForActivitesFetchByType('followup',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
        this.deltaService.addSyncErrorToEntitySyncInfo(followupSyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(followupSyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
      this._isInitialTasksMappingDone = true;
    }
  }

  async syncPhoneCalls(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) return;
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PHONECALLS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;

      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })
      let phonecallConfigFields:any = this.getPhoneCallConfigFields();
      if (!_.isEmpty(phonecallConfigFields)) {
        phonecallConfigFields = "&phonecallConfigFields=".concat(phonecallConfigFields.toString());
      }
      const phoneCallActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.phonecall,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this.fetchActivitiesForUserOnline('phonecall',dataRange.from,dataRange.to,lastUpdatedTime,positions,phonecallConfigFields);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (response['phoneCalls'] && Array.isArray(response['phoneCalls'])) {
            phoneCallActivitySyncInfo.totalSynced += response['phoneCalls'].length;
            if(lastUpdatedTime){
              if (!this._isInitialPhoneCallsMappingDone) {
                await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
              }
              await this.activityService.mapDeltaSyncedPhoneActivities(response['phoneCalls'], newLastUpdatedTime);
            }else{
              await this.activityService.mapFullSyncedPhoneCallActivities(response['phoneCalls'], newLastUpdatedTime, forceFullSync);
              this._isInitialPhoneCallsMappingDone = true;
            }
          }
          this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo);
          // Done sync. Update sync state.
          if (phoneCallActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncMeetings: ', error);
        let url = this.getUrlForActivitesFetchByType('phonecall',dataRange.from,dataRange.to,lastUpdatedTime,positions,phonecallConfigFields);
        this.deltaService.addSyncErrorToEntitySyncInfo(phoneCallActivitySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
      this._isInitialPhoneCallsMappingDone = true;
    }
  }

  async syncCases(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
      return;
    }
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_INQUIRY);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;

      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      const caseInqiurySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.timeOff,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this.fetchActivitiesForUserOnline('case',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (response['myCases'] && Array.isArray(response['myCases'])) {
            caseInqiurySyncInfo.totalSynced += response['myCases'].length;
          }
          if (Array.isArray(response['deletedCases'])) {
            caseInqiurySyncInfo.totalSynced += response['deletedCases'].length;
          }
          if (lastUpdatedTime) {
            if (!this._isInitialCasesMappingDone) {
              /* Loading cases that were created but never sent */
              await this.caseManagementService.loadMyCaseFromDb(dataRange);
              this._isInitialCasesMappingDone = true;
            }
            await this.caseManagementService.mapDeltaSyncedMyCases(response['myCases'], response['deletedCases'], newLastUpdatedTime);
          } else {
            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) && response['myCases']) {
              await this.caseManagementService.mapFullSyncedMyCases(response['myCases'], newLastUpdatedTime);
            }
            this._isInitialCasesMappingDone = true;
          }
          this.deltaService.addEntitySyncInfo(caseInqiurySyncInfo);
          // Done sync. Update sync state.
          if (caseInqiurySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncActivities: ', error);
        let url = this.getUrlForActivitesFetchByType('case',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
        this.deltaService.addSyncErrorToEntitySyncInfo(caseInqiurySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(caseInqiurySyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.caseManagementService.loadMyCaseFromDb(dataRange);
      this._isInitialCasesMappingDone = true;
    }
  }

  async syncMessages(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)){
      if (!(this.deviceService.isOffline || loadFromDbOnly)) {
        const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_EMAIL_ACTIVITIES);
        const isInitialSync = !syncState || !syncState.lastUpdatedTime;
        const doFullSync = forceFullSync || isInitialSync;
        const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;

        const positions = this.authenticationService.user.positions.map((o) => {
          return o.ID
        })

        const emailActivitySyncInfo: EntitySyncInfo = {
          entityName: EntityNames.email,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };

        try {
          const response = await this.fetchActivitiesForUserOnline('email', dataRange.from, dataRange.to, lastUpdatedTime, positions, null);
          if (response) {
            const newLastUpdatedTime = new Date().getTime();
            if (response['myEmails'] && Array.isArray(response['myEmails'])) {
              emailActivitySyncInfo.totalSynced += response['myEmails'].length;
            }
            if (lastUpdatedTime) {
              if (!this._isInitialMessagesMappingDone) {
                await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
                this._isInitialMessagesMappingDone = true;
              }
              await this.activityService.mapDeltaSyncedEmailActivities(response['myEmails'], newLastUpdatedTime);
            } else {
              await this.activityService.mapFullSyncedEmailActivities(response['myEmails'], newLastUpdatedTime);
              this._isInitialMessagesMappingDone = true;
            }
            this.deltaService.addEntitySyncInfo(emailActivitySyncInfo);
            // Done sync. Update sync state.
            if (emailActivitySyncInfo.syncStatus) {
              syncState.lastUpdatedTime = newLastUpdatedTime;
              await this.disk.updateSyncState(syncState);
            }
          }
        } catch (error) {
          console.error('syncEmails: ', error);
          let url = this.getUrlForActivitesFetchByType('email',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
          this.deltaService.addSyncErrorToEntitySyncInfo(emailActivitySyncInfo, url, error);
          this.deltaService.addEntitySyncInfo(emailActivitySyncInfo);
        }
      }else if(loadFromDbOnly){
        await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
        this._isInitialMessagesMappingDone = true;
      }
    }
  }

  async syncOrders(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)
      && !this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_ORDERS)) return;
    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ORDER_ACTIVITIES);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      const orderActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.order,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        const response = await this.fetchActivitiesForUserOnline('order',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (Array.isArray(response['myOrders'])) {
            orderActivitySyncInfo.totalSynced = response['myOrders'].length;
          }

          if (lastUpdatedTime) {
            if (!this._isInitialOrdersMappingDone) {
              await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
              this._isInitialOrdersMappingDone = true;
            }
            if (response['deletedOrders'] && Array.isArray(response['deletedOrders']) && response['deletedOrders'].length > 0) {
              response['deletedOrders'].map(rawOrder => {
                if (rawOrder['salesorderid'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.orderActivityDataService.handleDeletedTrackActionForOrderActivity(rawOrder);
                }
              });
            }
            if (response['myOrders'] && Array.isArray(response['myOrders']) && response['myOrders'].length > 0) {
              let orders: Array<OrderActivity> = [];
              response['myOrders'].map(rawOrder => {
                let orderObj = new OrderActivity(rawOrder);
                orderObj.type = ActivityType.Order;
                if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.orderActivityDataService.handleDeletedTrackActionForOrderActivity(rawOrder);
                } else {
                  //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                  if (orderObj.offlineDBId && orderObj.ownerId) {
                    orders.push(orderObj);
                  }
                  //}
                }
              });
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'OIADEL101',
                  message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
                }
              };
              if (orders.length > 0) {
                await this.orderActivityDataService.updateOrderActivity(action, orders, newLastUpdatedTime);
              }
            }
          } else {
            let orders: Array<OrderActivity> = [];
            if (response['myOrders'] && Array.isArray(response['myOrders']) && response['myOrders'].length > 0) {
              orderActivitySyncInfo.totalSynced += response['myOrders'].length;
              response['myOrders'].map(rawOrder => {
                let orderObj = new OrderActivity(rawOrder);
                orderObj.type = ActivityType.Order;
                //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                if (orderObj.offlineDBId && orderObj.ownerId) {
                  orders.push(orderObj);
                }
                //}
              });
            }
            if (orders.length >= 1) {
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code:'OIAINI101',
                  message:'Order Activities Initial Sync Creation',
                }
              };
              await this.orderActivityDataService.createOrderActivity(action, orders, newLastUpdatedTime, true);
            }
          }

          this.deltaService.addEntitySyncInfo(orderActivitySyncInfo);
          // Done sync. Update sync state.
          if (orderActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncOrderActivity: ', error);
        let url = this.getUrlForActivitesFetchByType('order',dataRange.from,dataRange.to,lastUpdatedTime,positions,null);
        this.deltaService.addSyncErrorToEntitySyncInfo(orderActivitySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(orderActivitySyncInfo);
      }
    }else if(loadFromDbOnly){
      await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
      this._isInitialOrdersMappingDone = true;
    }
  }

  async syncSurgeryOrders(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.user.hasProcedureLog) return;
    // Flag to check whether procedure log (surgery order) data start date is different from user's
    // offline data duration config.
    let hasSurgeryOrderDataPastOfflineDataDuration = false;
    const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));

    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SURGERY_ORDER_ACTIVITIES);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      let procedureLogStartDateUrlStr;
      const procedureLogStartDate = this.surgeryOrderActivityDataService.getProcedureLogDataStartDate(dataRange.from);
      if (procedureLogStartDate && dataRange.from !== procedureLogStartDate) {
        procedureLogStartDateUrlStr = '&procedureLogStartDate=' + procedureLogStartDate;
        hasSurgeryOrderDataPastOfflineDataDuration = true;
      }

      const surgeryOrderActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.surgeryorder,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.procedure);
        this.surgeryOrderActivityDataService.setSurgeryOrderSyncCompletedSubjectValue(false);
        this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(false);

        const response = await this.fetchActivitiesForUserOnline('surgery',dataRange.from,dataRange.to,lastUpdatedTime,positions,null,procedureLogStartDateUrlStr);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (Array.isArray(response['mySurgeryOrders'])) {
            surgeryOrderActivitySyncInfo.totalSynced = response['mySurgeryOrders'].length;
          }

          if (lastUpdatedTime) {
            let orders: Array<SurgeryOrderActivity> = [];
            let pastOrdersForEdgeAnalytics: SurgeryOrderActivity[] = [];
            if (!this._isInitialSurgeryOrdersMappingDone) {
              await this.surgeryOrderActivityDataService.loadSurgeryOrderActivitiesFromDb(dataRange);
              this._isInitialSurgeryOrdersMappingDone = true;
            }
            if (response['deletedSurgeryOrder'] && Array.isArray(response['deletedSurgeryOrder']) && response['deletedSurgeryOrder'].length > 0) {
              response['deletedSurgeryOrder'].map(rawOrder => {
                if (rawOrder['salesorderid'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.surgeryOrderActivityDataService.handleDeletedTrackActionForSurgeryOrderActivity(rawOrder);
                }
              });
            }
            if (response['mySurgeryOrders'] && Array.isArray(response['mySurgeryOrders']) && response['mySurgeryOrders'].length > 0) {
              response['mySurgeryOrders'].map(rawOrder => {
                let orderObj = new SurgeryOrderActivity(rawOrder);
                orderObj.type = ActivityType.SurgeryOrder;
                if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.surgeryOrderActivityDataService.handleDeletedTrackActionForSurgeryOrderActivity(rawOrder);
                } else {
                  //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                  if (orderObj.offlineDBId && orderObj.ownerId) {
                    if (!hasSurgeryOrderDataPastOfflineDataDuration) {
                      orders.push(orderObj);
                    } else {
                      if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
                        if((this.authenticationService.user.buSettings && !this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar'] && orderObj.status !== 548910005) ||
                        (this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar'])){
                              orders.push(orderObj);
                        }
                      } else {
                        pastOrdersForEdgeAnalytics.push(orderObj);
                      }
                    }
                  }
                  //}
                }
              });
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'SODSDM101',
                  message: 'Surgery Orders Delta Sync Data Mapping'
                }
              };
              if (orders.length > 0) {
                await this.surgeryOrderActivityDataService.updateOrderActivity(action, orders, newLastUpdatedTime);
              }
              if (pastOrdersForEdgeAnalytics.length > 0) {
                await this.surgeryOrderActivityDataService.updateOrderActivity({
                  onDynamics: false,
                  onLocalDatabase: true,
                  onLocalCopy: false,
                }, pastOrdersForEdgeAnalytics, newLastUpdatedTime);
              }
            }
          } else {
            let orders: Array<SurgeryOrderActivity> = [];
            let pastOrdersForEdgeAnalytics: SurgeryOrderActivity[] = [];
            if(response['mySurgeryOrders'] && Array.isArray(response['mySurgeryOrders']) && response['mySurgeryOrders'].length > 0){
              surgeryOrderActivitySyncInfo.totalSynced += response['mySurgeryOrders'].length;
              response['mySurgeryOrders'].map(rawOrder => {
                let orderObj = new SurgeryOrderActivity(rawOrder);
                orderObj.type = ActivityType.SurgeryOrder;
                //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                if (orderObj.offlineDBId && orderObj.ownerId) {
                  if (!hasSurgeryOrderDataPastOfflineDataDuration) {
                    orders.push(orderObj);
                  } else {
                    if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
                      orders.push(orderObj);
                    } else {
                      pastOrdersForEdgeAnalytics.push(orderObj);
                    }
                  }
                }
                //}
              });
            }
            if (orders.length >= 1) {
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'SOAISDM101',
                  message: 'Surgery Order Activity Inital Sync Data Mapping',
                }
              };
              await this.surgeryOrderActivityDataService.createOrderActivity(action, orders, newLastUpdatedTime, true);
            }
            if (pastOrdersForEdgeAnalytics.length > 0) {
              await this.surgeryOrderActivityDataService.createOrderActivity({
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: false
              }, pastOrdersForEdgeAnalytics, newLastUpdatedTime, orders.length === 0);
            }
            this._isInitialSurgeryOrdersMappingDone = true;
          }

          this.deltaService.addEntitySyncInfo(surgeryOrderActivitySyncInfo);
          // Done sync. Update sync state.
          if (surgeryOrderActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncSurgeryOrderActivity: ', error);
        let url = this.getUrlForActivitesFetchByType('surgery',dataRange.from,dataRange.to,lastUpdatedTime,positions,null,procedureLogStartDateUrlStr);
        this.deltaService.addSyncErrorToEntitySyncInfo(surgeryOrderActivitySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(surgeryOrderActivitySyncInfo);
      }
    }else if(loadFromDbOnly){
      this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.procedure);
      await this.surgeryOrderActivityDataService.loadSurgeryOrderActivitiesFromDb(dataRange);
      this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(true);
      this._isInitialSurgeryOrdersMappingDone = true;
    }
  }

  async syncProcedureTrackers(dataRange: { from: string, to: string }, forceFullSync: boolean,loadFromDbOnly):Promise<any> {
    if (!this.authenticationService.user.hasBulkProcedureLog) return;
    // Flag to check whether procedure log (surgery order) data start date is different from user's
    // offline data duration config.
    let hasTrackerDataPastOfflineDataDuration = false;
    const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));

    if (!(this.deviceService.isOffline || loadFromDbOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PROCEDURE_TRACKER_ACTIVITIES);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const doFullSync = forceFullSync || isInitialSync;
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !doFullSync? syncState['lastUpdatedTime'] : null;
      const positions = this.authenticationService.user.positions.map((o) => {
        return o.ID
      })

      let procedureLogStartDateUrlStr;
      const procedureLogStartDate = this.surgeryOrderActivityDataService.getProcedureLogDataStartDate(dataRange.from);
      if (procedureLogStartDate && dataRange.from !== procedureLogStartDate) {
        procedureLogStartDateUrlStr = '&procedureLogStartDate=' + procedureLogStartDate;
        hasTrackerDataPastOfflineDataDuration = true;
      }

      const trackerActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.proceduretracker,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.proceduretracker);
        this.procedureTrackerDataService.setProcedureTrackerSyncCompletedSubjectValue(false);
        this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(false);

        const response = await this.fetchActivitiesForUserOnline('proceduretracker',dataRange.from,dataRange.to,lastUpdatedTime,positions,null,procedureLogStartDateUrlStr);

        if (response) {
          const newLastUpdatedTime = new Date().getTime();
          if (Array.isArray(response['procedureTrackers'])) {
            trackerActivitySyncInfo.totalSynced = response['procedureTrackers'].length;
          }

          if (lastUpdatedTime) {
            let orders: Array<ProcedureTrackerActivity> = [];
            let pastOrdersForEdgeAnalytics: ProcedureTrackerActivity[] = [];
            if (!this._isInitialProcedureTrackersMappingDone) {
              await this.procedureTrackerDataService.loadProcedureTrackerActivitiesFromDb(dataRange);
              this._isInitialProcedureTrackersMappingDone = true;
            }
            if (response['deletedSurgeryOrder'] && Array.isArray(response['deletedSurgeryOrder']) && response['deletedSurgeryOrder'].length > 0) {
              response['deletedSurgeryOrder'].map(rawOrder => {
                if (rawOrder['salesorderid'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.procedureTrackerDataService.handleDeletedTrackActionForProcedureTrackerActivity(rawOrder);
                }
              });
            }
            if (response['procedureTrackers'] && Array.isArray(response['procedureTrackers']) && response['procedureTrackers'].length > 0) {
              response['procedureTrackers'].map(rawOrder => {
                let orderObj = new ProcedureTrackerActivity(rawOrder);
                orderObj.type = ActivityType.ProcedureTracker;
                if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
                  this.procedureTrackerDataService.handleDeletedTrackActionForProcedureTrackerActivity(rawOrder);
                } else {
                  //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                  if (orderObj.offlineDBId && orderObj.ownerId) {
                    if (!hasTrackerDataPastOfflineDataDuration) {
                      orders.push(orderObj);
                    } else {
                      if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
                        orders.push(orderObj);
                      } else {
                        pastOrdersForEdgeAnalytics.push(orderObj);
                      }
                    }
                  }
                  //}
                }
              });
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'SODSDM101',
                  message: 'Bulk Procedure Log Delta Sync Data Mapping'
                }
              };
              if (orders.length > 0) {
                await this.procedureTrackerDataService.updateOrderActivity(action, orders, newLastUpdatedTime);
              }
              if (pastOrdersForEdgeAnalytics.length > 0) {
                await this.procedureTrackerDataService.updateOrderActivity({
                  onDynamics: false,
                  onLocalDatabase: true,
                  onLocalCopy: false,
                }, pastOrdersForEdgeAnalytics, newLastUpdatedTime);
              }
            }
          } else {
            let orders: Array<ProcedureTrackerActivity> = [];
            let pastOrdersForEdgeAnalytics: ProcedureTrackerActivity[] = [];
            if(response['procedureTrackers'] && Array.isArray(response['procedureTrackers']) && response['procedureTrackers'].length > 0){
              trackerActivitySyncInfo.totalSynced += response['procedureTrackers'].length;
              response['procedureTrackers'].map(rawOrder => {
                let orderObj = new ProcedureTrackerActivity(rawOrder);
                orderObj.type = ActivityType.ProcedureTracker;
                //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
                if (orderObj.offlineDBId && orderObj.ownerId) {
                  if (!hasTrackerDataPastOfflineDataDuration) {
                    orders.push(orderObj);
                  } else {
                    if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
                      orders.push(orderObj);
                    } else {
                      pastOrdersForEdgeAnalytics.push(orderObj);
                    }
                  }
                }
                //}
              });
            }
            if (orders.length >= 1) {
              let action: OperationDetail = {
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'SOAISDM101',
                  message: 'Bulk Procedure Log Activity Inital Sync Data Mapping',
                }
              };
              await this.procedureTrackerDataService.createOrderActivity(action, orders, newLastUpdatedTime, true);
            }
            if (pastOrdersForEdgeAnalytics.length > 0) {
              await this.procedureTrackerDataService.createOrderActivity({
                onDynamics: false,
                onLocalDatabase: true,
                onLocalCopy: false
              }, pastOrdersForEdgeAnalytics, newLastUpdatedTime, orders.length === 0);
            }
            this._isInitialProcedureTrackersMappingDone = true;
          }

          this.deltaService.addEntitySyncInfo(trackerActivitySyncInfo);
          // Done sync. Update sync state.
          if (trackerActivitySyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
          }
        }
      } catch (error) {
        console.error('syncProcedureTrackerActivity: ', error);
        let url = this.getUrlForActivitesFetchByType('proceduretracker',dataRange.from,dataRange.to,lastUpdatedTime,positions,null,procedureLogStartDateUrlStr);
        this.deltaService.addSyncErrorToEntitySyncInfo(trackerActivitySyncInfo, url, error);
        this.deltaService.addEntitySyncInfo(trackerActivitySyncInfo);
      }
    }else if(loadFromDbOnly){
      this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.proceduretracker);
      await this.procedureTrackerDataService.loadProcedureTrackerActivitiesFromDb(dataRange);
      this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(true);
      this._isInitialProcedureTrackersMappingDone = true;
    }
  }

  private fetchTeamViewActivitiesForAgendaOnline(userIds,activityType,dataRange: { from: string, to: string },configFields:any = null,procedurelogStartDateStr:string = null): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Sync-Service', 'true');
    headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
    let positions=[];
    positions = this.authenticationService.user.positions.map((o) => {
      return o.ID
    })
    // if(this.authenticationService.user.buSettings["indskr_homepageteamview"]===548910001){
    //   let siblingPositions = this.authenticationService.user.siblingPositions.map((o) => {
    //     return o.siblingPositionId
    //   });
    //   positions = [...positions,...siblingPositions];
    // }
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_TEAM_USER_ACTIVITIES_BY_ACTIVITY_TYPES;
    url = url.replace('{startDate}', dataRange.from);
    url = url.replace('{endDate}', dataRange.to);
    url = url.replace('{positionIDs}', positions.toString());
    url = url.replace('{activityTypes}', activityType);
    url = url.replace('{childUserIds}',userIds)
    if(configFields){
      url = url.concat(configFields);
    }

    if((activityType === 'appointment' || activityType === 'surgeryorder') && this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)){
      url = url.concat('&featureFlags=marketingbusinessplan');
    } else if (activityType === 'followup') {
      url = this.addFollowUpFeatureFlags(url);
    }

    if(procedurelogStartDateStr){
      url = url + procedurelogStartDateStr;
    }
    return this.http.get(url, { headers }).toPromise();
  }

  private _initActivityObject(rawObject){
    let activity = new Activity(rawObject);
    switch (activity.type) {
      case ActivityType.Appointment:
        activity = new AppointmentActivity(rawObject);
        break;
      case ActivityType.StoreCheck:
        activity = new StoreCheckActivity(rawObject);
        break;
      case ActivityType.PhoneCall:
        activity = new PhoneActivity(rawObject);
        break;

      case ActivityType.Email:
        activity = new EmailActivity(rawObject);
        break;

      case ActivityType.TimeOff:
        activity = new TimeOffActivity(rawObject, ActivityType.TimeOff);
        break;

      case ActivityType.Sample:
        activity = new SampleActivity(rawObject);
        break;

      default:
        activity = null;
    }
    return activity;
  }

  async fetchAgendaTeamViewActivities(userId:string,isMultipleUsers:boolean = false):Promise<any> {
    if(!userId) return;
    this.activityService.agendaTeamViewActivities = [];
    const dataRange = (isMultipleUsers) ? this.authenticationService.getFromToDateRangeForMultiCalendarInUTCMiliSec() : this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);

    return Promise.all([
      this.fetchAgendaTeamViewAppointments(userId,dataRange),
      this.fetchAgendaTeamViewTimeoffs(userId,dataRange),
      this.fetchAgendaTeamViewAllocationOrders(userId,dataRange),
      this.fetchAgendaTeamViewFollowupTasks(userId,dataRange),
      this.fetchAgendaTeamViewPhoneCalls(userId,dataRange),
      this.fetchAgendaTeamViewMessages(userId,dataRange),
      this.fetchAgendaTeamViewOrders(userId,dataRange),
      this.fetchAgendaTeamViewSurgeryOrders(userId,dataRange),
      this.fetchAgendaTeamViewCases(userId,dataRange),
      this.fetchAgendaTeamViewKitBookings(userId,dataRange),
    ]);
  }

  async fetchAgendaTeamViewAppointments(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (userId) {
      let appointmentConfigFields:any;
      if (!_.isEmpty(this.authenticationService.user.appointmentConfiguredFields)) {
        appointmentConfigFields = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
          return obj.fieldName
        });
        appointmentConfigFields = "&appointmentConfigFields=".concat(appointmentConfigFields.toString());
      }

      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'appointment',dataRange,appointmentConfigFields);

        if (response) {
          if (response['meetings'] && Array.isArray(response['meetings'])) {
            await this.updateTeamActivitiesForConfigField(ActivityType.Appointment,userId,response['meetings'],dataRange);
            response['meetings'].forEach(rawMeeting=> {
              const activity = this._initActivityObject(rawMeeting);
              if (activity && activity.state !== 4 && activity.state !== 2) {
                // Don't add canceled activity to the list.
                if (activity.indskr_type != 100000001) {
                  if (activity.indskr_type === ActivityTypeCode.STORE_CHECK) {
                    this._appendStoreCheckDetailsToActivity(activity, rawMeeting);
                  } else {
                    this._appendMeetingDetailsToActivity(activity, rawMeeting);
                  }
                  this.activityService.agendaTeamViewActivities.push(activity);
                }
              }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Meetings: ', error)
      }
    }
  }

  async fetchAgendaTeamViewTimeoffs(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'timeoff',dataRange);

        if (response) {
          if (response['teamTimeOffs'] && Array.isArray(response['teamTimeOffs'])) {
            response['teamTimeOffs'].forEach(rawTimeoff=> {
              const rawTot = rawTimeoff;
              const timeOffType = rawTot.statuscode === 100000004 ? ActivityType.TimeOff : ActivityType.TimeOffRequest;
              const timeOff = new TimeOffActivity(rawTot, timeOffType);
              this.activityService.agendaTeamViewActivities.push(timeOff);
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Timeoffs: ', error);
      }
    }
  }

  async fetchAgendaTeamViewAllocationOrders(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'sampledrop',dataRange);

        if (response) {
          if (response['sampleDrops'] && Array.isArray(response['sampleDrops'])) {
            response['sampleDrops'].forEach(rawAllocationOrder=> {
              const activity = this._initActivityObject(rawAllocationOrder);
              if (activity && activity.state !== 4 && activity.state !== 2) {// Don't add canceled activity to the list.
                if (activity instanceof SampleActivity && activity.appointmentID && activity.statusString == 'Open') {
                  this.activityService.agendaTeamViewActivities.push(activity);
                }else{
                  this.activityService.agendaTeamViewActivities.push(activity);
                }
              }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Allocation Orders: ', error);
      }
    }
  }

  async fetchAgendaTeamViewFollowupTasks(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)
    && !this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
    && !this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'followup',dataRange);

        if (response) {
          if (response['followupTasks'] && Array.isArray(response['followupTasks'])) {
            response['followupTasks'].forEach(rawFollowupTask=> {
              let followUpObj = new FollowUpActivity(rawFollowupTask);
                followUpObj.type = ActivityType.FollowUp;
                if (followUpObj.offlineDBId && followUpObj.ownerId && !(followUpObj.state == 2 && followUpObj.status == 6)) {
                  this.activityService.agendaTeamViewActivities.push(followUpObj);
                }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Follow Up Actions: ', error);
      }
    }
  }

  async fetchAgendaTeamViewPhoneCalls(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) return;
    if (userId) {
      let phonecallConfigFields:any = this.getPhoneCallConfigFields();
      if (!_.isEmpty(phonecallConfigFields)) {
        phonecallConfigFields = "&phonecallConfigFields=".concat(phonecallConfigFields.toString());
      }
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'phonecall',dataRange,phonecallConfigFields);
        await this.updateTeamActivitiesForConfigField(ActivityType.PhoneCall,userId,response['phoneCalls'],dataRange);
        if (response) {
          if (response['phoneCalls'] && Array.isArray(response['phoneCalls'])) {
            response['phoneCalls'].forEach(rawPhoneCall=> {
              const activity = this._initActivityObject(rawPhoneCall);
              if (activity && activity.state !== 2) {
                this._appendPhoneCallDetailsToActivity(activity,rawPhoneCall,false);
                this.activityService.agendaTeamViewActivities.push(activity);
              }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Phone Call Activities: ', error);
      }
    }
  }

  async fetchAgendaTeamViewMessages(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'email',dataRange);

        if (response) {
          if (response['myEmails'] && Array.isArray(response['myEmails'])) {
            response['myEmails'].forEach(rawEmail=> {
              const activity = this._initActivityObject(rawEmail);
              if (activity && activity.state !== 2 && activity.state !== 4) {
                this.activityService.agendaTeamViewActivities.push(activity);
              }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Email Activities: ', error);
      }
    }
  }

  async fetchAgendaTeamViewOrders(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'order',dataRange);

        if (response) {
          if (response['myOrders'] && Array.isArray(response['myOrders'])) {
            response['myOrders'].forEach(rawOrder=> {
              let orderObj = new OrderActivity(rawOrder);
                orderObj.type = ActivityType.Order;
                if (orderObj.offlineDBId && orderObj.ownerId) {
                  this.activityService.agendaTeamViewActivities.push(orderObj);
                }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Sales Order Activities: ', error);
      }
    }
  }

  async fetchAgendaTeamViewSurgeryOrders(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.user.hasProcedureLog) return;
    if (userId) {
      let hasSurgeryOrderDataPastOfflineDataDuration = false;
      const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));
      let procedureLogStartDateUrlStr;
      const procedureLogStartDate = this.surgeryOrderActivityDataService.getProcedureLogDataStartDate(dataRange.from);
      if (procedureLogStartDate && dataRange.from !== procedureLogStartDate) {
        procedureLogStartDateUrlStr = '&procedureLogStartDate=' + procedureLogStartDate;
        hasSurgeryOrderDataPastOfflineDataDuration = true;
      }
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'surgery',dataRange,null,procedureLogStartDateUrlStr);

        if (response) {
          if (response['mySurgeryOrders'] && Array.isArray(response['mySurgeryOrders'])) {
            response['mySurgeryOrders'].forEach(rawSurgeryOrder=> {
              let orderObj = new SurgeryOrderActivity(rawSurgeryOrder);
                orderObj.type = ActivityType.SurgeryOrder;
                if (orderObj.offlineDBId && orderObj.ownerId) {
                  if (!hasSurgeryOrderDataPastOfflineDataDuration) {
                    this.activityService.agendaTeamViewActivities.push(orderObj);
                  } else {
                    if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
                      this.activityService.agendaTeamViewActivities.push(orderObj);
                    }
                  }
                }
            })
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Surgery Order Activities: ', error);
      }
    }
  }

  async fetchAgendaTeamViewCases(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) return;
    if (userId) {
      try {
        const response = await this.fetchTeamViewActivitiesForAgendaOnline(userId,'case',dataRange);

        if (response) {
          if (response['myCases'] && Array.isArray(response['myCases'])) {
            let mappedCases:Array<CaseActivity> = [];
            response['myCases'].forEach(rawCase=> {
              /* Pouch db doesnt allow to store keyword starting with _ */
              for (let key in rawCase) {
                if (key.charAt(0) === "_") {
                    var a = key.substring(1, key.length);
                    rawCase[a] = rawCase[a] = rawCase[key];
                    delete rawCase[key];
                    delete rawCase[key];
                }
              }
              const newCase = new CaseActivity(rawCase);
              mappedCases.push(newCase);
            })
            this.caseManagementService.reMapTeamDataWithValues(this.accountService.accounts, this.contactService.contacts,mappedCases);
            this.activityService.agendaTeamViewActivities.push(...mappedCases);
          }
        }
      } catch (error) {
        console.error('sync Agenda Team View Customer Enquiries Activities: ', error);
      }
    }
  }

  async fetchAgendaTeamViewKitBookings(userId:string,dataRange: { from: string, to: string }):Promise<any> {
    if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) return;
    if(userId) {
      try{
        const response = await this.syncSetBookingActivitiesForTeamView(userId, dataRange,true,false)
        if(response){
          this.activityService.agendaTeamViewActivities.push(...response);
        }
      } catch (error) {
        console.log('sync Agenda Team View Kit Bookings: ', error);
      }
    }
  }

  public async syncSetBookingActivitiesForTeamView(userId: string, dataRange: { from: string, to: string }, forceFullSync, loadFromDBOnly): Promise<any> {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) return;

    if (!(this.deviceService.isOffline || loadFromDBOnly)) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SET_BOOKING_ACTIVITIES);
      const lastUpdatedTime = syncState['lastUpdatedTime'] && !forceFullSync ? syncState['lastUpdatedTime'] : null;
      const setBookingActivitySyncInfo: EntitySyncInfo = {
        entityName: EntityNames.setbooking,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      try {
        const response = await this._fetchSetBookingActivitiesForTeamViewUserOnline(dataRange.from, dataRange.to, lastUpdatedTime, forceFullSync, userId).catch(err=> {
          console.log(err);
        });
        //await this.syncSetBookingActivitiesLinkedEntitiesForTeamView(dataRange, lastUpdatedTime, forceFullSync,userId);
        if (response && Array.isArray(response)) {
          const newLastUpdatedTime = new Date().getTime();
          if (lastUpdatedTime) {
            let setBookings: Array<SetBookingActivity> = [];
            //await this._loadSetBookingActivitiesFromDb();
            response.forEach(record => {
              let setBookingObj = new SetBookingActivity(record);
              setBookingObj.type = ActivityType.SetBooking;
              setBookingObj.isTeamKitBooking = true;
              if (setBookingObj.offlineDBId) {
                setBookings.push(setBookingObj);
              }
            });
            // let action: OperationDetail = {
            //   onDynamics: false,
            //   onLocalDatabase: true,
            //   onLocalCopy: true,
            //   operationDetail: {
            //     code: 'SBIADEL101',
            //     message: 'DELTA_SYNC_SETBOOKINGS_UPDATE',
            //   }
            // };
            if (setBookings.length > 0) {
              //await this.updateSetBookingActivity(action, setBookings, newLastUpdatedTime);
              }
              return setBookings;
          } else {
            let setBookings: Array<SetBookingActivity> = [];

            response.forEach(record => {
              let setBookingObj = new SetBookingActivity(record);
              setBookingObj.type = ActivityType.SetBooking;
              setBookingObj.isTeamKitBooking = true;
              if (setBookingObj.offlineDBId && (
                this.authenticationService.user.buSettings['indskr_displaycancelledkitbookingoncalendar'] ||
                (setBookingObj.indskr_status != SetBookingStatus.CANCELLED && setBookingObj.indskr_status != SetBookingStatus.CANCELERROR && setBookingObj.indskr_status != SetBookingStatus.PENDINGCANCEL)
                )) {
                setBookings.push(setBookingObj);
              }
            });
            if (setBookings.length > 0) {
              // let action: OperationDetail = {
              //   onDynamics: false,
              //   onLocalDatabase: true,
              //   onLocalCopy: true,
              //   operationDetail: {
              //     code: 'SBAINI101',
              //     message: 'Set Booking Activities Initial Sync Creation',
              //   }
              // };
              //await this.createSetBookingActivity(action, setBookings, newLastUpdatedTime);

              // setBookings.forEach(setBooking => {
              //   this.activityOfflineService.addActivity(setBooking, false, false, null, callForFilterActivities).then(success => {
              //     resolve('Successfully created order activity');
              //   }).catch(err => {
              //     reject('Error Occured while saving order activity into local array' + err);
              //   })
              // })
            }
            return setBookings;
          }
          //this.deltaService.addEntitySyncInfo(setBookingActivitySyncInfo);
          // Done sync. Update sync state.
          // if (setBookingActivitySyncInfo.syncStatus) {
          //   syncState.lastUpdatedTime = newLastUpdatedTime;
          //   await this.disk.updateSyncState(syncState);
          // }
        }
      } catch (error) {
      }
    }
    //this.eventsService.publish('refreshAgenda')
  }

  private async _fetchSetBookingActivitiesForTeamViewUserOnline(fromDate, toDate, lastUpdatedTime, fullSync, userId?:string) {
    let setBookingDisplayForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!setBookingDisplayForm) {
      return [];
    }
    let fetchXML = this._getSetBookingFetchXMLForConfiguredForm(setBookingDisplayForm);
    let dateAndUserFilter;
    //for team view
    if(userId && userId.length>0){
      let userIdValues=''
      const userIdsArray = userId.split(",");
      userIdValues = userIdsArray.map(userid => `<value>${userid}</value>`).join('');
      dateAndUserFilter = fetchQueries.setBooking.userAndDateFilterForTeamView;
      dateAndUserFilter = dateAndUserFilter.replace(/{userIDs}/g, userIdValues);
      dateAndUserFilter = dateAndUserFilter.replace('{startDate}', format(new Date(parseInt(fromDate)), 'YYYY-MM-DD'));
      dateAndUserFilter = dateAndUserFilter.replace('{endDate}', format(new Date(parseInt(toDate)), 'YYYY-MM-DD'));
      if ((fullSync || !lastUpdatedTime) && !this.authenticationService.user.buSettings['indskr_displaycancelledkitbookingoncalendar']) {
        // Custom check to filter Cancelled Status Set Booking Records
        dateAndUserFilter = dateAndUserFilter.replace('{DeltaSyncFilter}', '<condition attribute="indskr_status" operator="neq" value="808210005" /><condition attribute="indskr_status" operator="neq" value="808210012" /><condition attribute="indskr_status" operator="neq" value="808210013" />');
      } else {
        dateAndUserFilter = dateAndUserFilter.replace('{DeltaSyncFilter}', '');
      }
    }
    fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', dateAndUserFilter);
    return await this.dynamics.executeFetchQuery('indskr_setbookings', fetchXML);
  }

  private _getSetBookingFetchXMLForConfiguredForm(setBookingDisplayForm) {
    let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;

    let parentEntityAttributesStr = [...(function* () {
      yield 'indskr_setbookingid';
      for (const attr of RequiredSetBookingAttributes) {
        yield attr;
      }
    }).call(this)].reduce((p, x) => `${p}<attribute name="${x}"/>`, '');

    setBookingDisplayForm.metadata.forEach(tab => {
      tab.controls.forEach(control => {
        if (control.dataType) {
          let linkedAttrFetchXML = '';
          if (control.attributeName) {
            parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
          }
        }
      });
    });

    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', setBookingDisplayForm.entityName);
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');

    return parentEntityFetchXml;
  }

  // public async syncSetBookingActivitiesLinkedEntitiesForTeamView(dataRange,lastUpdatedTime, forceFullSync,userId,setBookingId:string = ''): Promise<any> {
  //   let setBookingDisplayForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
  //   let formType = DynamicFormType.CONFIGUREDFORM;
  //   if (!setBookingDisplayForm) {
  //     return [];
  //   }
  //   if (isBefore(lastUpdatedTime, new Date(setBookingDisplayForm.modiefiedOn)) || !lastUpdatedTime) {
  //     forceFullSync = true;
  //   }
  //   const linkedEntityControls = [];
  //   setBookingDisplayForm.metadata.forEach(tab => {
  //     tab.controls.forEach(control => {
  //       if (!control.dataType && control.subgrid) {
  //         linkedEntityControls.push(control);
  //       }
  //     });
  //   });
  //   // Pushing Hard Coded Notes Fetch Query
  //   linkedEntityControls.push({
  //     referencingEntity: 'annotation',
  //     subgrid: {
  //       referencingEntity: 'annotation',
  //       referencingAttribute: 'objectid',
  //       parentAttribute: 'indskr_setbookingid',
  //       subgridQuery: `
  //         <fetch version="1.0" mapping="logical">
  //           <entity name="annotation">
  //             <attribute name="subject" />
  //             <attribute name="notetext" />
  //             <attribute name="filename" />
  //             <attribute name="annotationid" />
  //             <attribute name="ownerid" />
  //             <attribute name="createdon" />
  //             <attribute name="objectid" />
  //             <attribute name="filesize"/>
  //             <attribute name="mimetype"/>
  //             <attribute name="isdocument"/>
  //           </entity>
  //         </fetch>
  //       `,
  //     }
  //   })
  //   if (linkedEntityControls.length > 0) {
  //     return Promise.all(linkedEntityControls.map(async (linkEntity) => {
  //       let linkEntityFetchXML = this._getSetBookingLinkedEntityFetchXML(linkEntity,dataRange,lastUpdatedTime,setBookingId,userId);
  //       let pagingInfo: any = null;
  //       let rawAllData = [];
  //       do {
  //         try {
  //           let response = await this.dynamics.executeFetchXml('indskr_setbookings', linkEntityFetchXML, undefined, pagingInfo?.nextPage ?? 0, pagingInfo?.cookie ?? null,);
  //           if (response) {
  //             pagingInfo = response.PagingInfo ?? null;
  //             if (Array.isArray(response.value)) {
  //               rawAllData.push(...response.value);
  //             }
  //           }
  //         } catch (error) {
  //           console.log("Error retreiving linked entity data: ", error);
  //           pagingInfo = null;
  //           rawAllData = null;
  //         }
  //       } while (pagingInfo !== null);
  //       let dbKey = linkEntity.subgrid.referencingEntity;
  //       let dataToSave = rawAllData;
  //       if ((lastUpdatedTime || setBookingId || !forceFullSync) && Array.isArray(rawAllData)) {
  //         let temp = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + dbKey);
  //         let leIdAttrName = linkEntity.subgrid.referencingEntity == 'appointment' ? linkEntity.subgrid.referencingEntity + ".activityid" : linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
  //         let localRaw;
  //         if (temp) {
  //           localRaw = temp.raw ? temp.raw : []
  //           rawAllData.forEach(rawRes => {
  //             if (rawRes && rawRes.hasOwnProperty('indskr_setbookingid') && rawRes.hasOwnProperty('indskr_setbookingid') && rawRes[leIdAttrName]) {
  //               if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.SetBooking && this.activityService.selectedActivity.ID == rawRes['indskr_setbookingid']) {
  //                 //this.refreshSelectedSetBookingDetails = true;
  //               }
  //               let idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_setbookingid') && a[leIdAttrName] == rawRes[leIdAttrName]);

  //               if (idx >= 0) {
  //                 if ((rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) || (rawRes[linkEntity.subgrid.referencingEntity + '.statecode'] == 1 && rawRes[linkEntity.subgrid.referencingEntity + '.statuscode'] == 2)) {
  //                   localRaw.splice(idx, 1);
  //                 } else {
  //                   localRaw[idx] = rawRes;
  //                 }
  //               } else {
  //                 if (rawRes['statuscode'] != 2 && rawRes[linkEntity.subgrid.referencingEntity + '.statuscode'] != 2) {
  //                   localRaw.push(rawRes);
  //                 }
  //               }
  //             }
  //           });
  //           dataToSave = localRaw;
  //         }
  //       }
  //       // await this.disk.updateOrInsert(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + dbKey, doc => {
  //       //   doc = {
  //       //     raw: dataToSave,
  //       //   };
  //       //   return doc;
  //       // }).catch(error => console.error('Save Set Booking LE to DB error: ', error));
  //     }));
  //   }
  // }

  // private _getSetBookingLinkedEntityFetchXML(linkEntity,dataRange,lastUpdatedTime,setBookingId, userId?:string){
  //   let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntityPaging;
  //   fetchXML = fetchXML.replace('{parentEntityName}', 'indskr_setbooking');
  //   fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="indskr_setbookingid"/>');
  //   fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
  //   fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
  //   fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
  //   fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
  //   fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
  //   fetchXML = fetchXML.replace('{customFilterLevel2}', '');
  //   fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}','');
  //   if(userId && userId.length>0){
  //     let userIdValues=''
  //     const userIdsArray = userId.split(",");
  //     userIdValues = userIdsArray.map(userid => `<value>${userid}</value>`).join('');
  //     let dateAndUserFilter = fetchQueries.setBooking.userAndDateFilterForTeamView;
  //     dateAndUserFilter = dateAndUserFilter.replace(/{userIDs}/g, userIdValues);
  //     dateAndUserFilter = dateAndUserFilter.replace('{startDate}', format(new Date(parseInt(dataRange.from)), 'YYYY-MM-DD'));
  //     dateAndUserFilter = dateAndUserFilter.replace('{endDate}', format(new Date(parseInt(dataRange.to)), 'YYYY-MM-DD'));
  //     fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
  //     fetchXML = fetchXML.replace('{customFilterLevel1}', dateAndUserFilter);
  //   }
  //   let hourDifference;
  //   // No modified on filter for intial sync
  //   if (!hourDifference && !setBookingId) {
  //     fetchXML = fetchXML.replace('{DeltaSyncFilter}', '<condition attribute="indskr_status" operator="neq" value="808210005" />');
  //     fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
  //     fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
  //   }
  //   let queryString = linkEntity.subgrid.subgridQuery;
  //   let JSONQuery;
  //   let linkEntityAttributesStr = '';
  //   XML2JS.parseString(queryString, (err, data) => {
  //     JSONQuery = data;
  //   });
  //   JSONQuery.fetch.entity[0].attribute.forEach(attr => {
  //     linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>';
  //   });
  //   let numOfLinkEntity = 0;
  //   if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
  //     JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
  //       numOfLinkEntity++;
  //       try {
  //         linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
  //           + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
  //         if (linEnt.attribute) {
  //           linEnt.attribute.forEach(linEntAttr => {
  //             linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
  //           });
  //         }
  //         linkEntityAttributesStr += "</link-entity>"
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  //   }
  //   linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
  //   linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
  //   if(linkEntity.subgrid && linkEntity.subgrid.referencingEntity != 'annotation'){
  //     linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
  //   }
  //   linkEntityAttributesStr = linkEntityAttributesStr.replace('<condition attribute=\"statecode\" operator=\"eq\" value=\"0\"/>', '');
  //   fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);
  //   if(linkEntity.subgrid && linkEntity.subgrid.referencingEntity == 'indskr_setbookingteammember'){
  //     fetchXML = fetchXML.replace(`<link-entity name="indskr_setbookingteammember" from="indskr_setbooking" to="indskr_setbookingid" visible="false" intersect="true" link-type="outer"></link-entity>`, '');
  //   }
  //   return fetchXML;
  // }

  // async tempsyncActivities(dataRange: { from: string, to: string }, loadFromDbOnly = false, forceFullSync = false) {
  //   if (!(this.deviceService.isOffline || loadFromDbOnly)) {
  //     await Promise.all([
  //       this.tempsyncMeetings(dataRange, forceFullSync),
  //       this.tempsyncTimeoffs(dataRange, forceFullSync),
  //       this.tempsyncAllocationOrders(dataRange, forceFullSync),
  //       this.tempsyncFollowupTasks(dataRange, forceFullSync),
  //       this.tempsyncPhoneCalls(dataRange, forceFullSync)
  //     ]).then(async (response) => {
  //       let meetingsResponse: any = response[0];
  //       let timeOffsResponse: any = response[1];
  //       let allocationOrdersResponse: any = response[2];
  //       let tasksResponse: any = response[3];
  //       let phoneCallsResponse: any = response[4];
  //       if (meetingsResponse) {
  //         if (meetingsResponse['isFullSync']) {
  //           await this.activityService.mapFullSyncedActivities(meetingsResponse['meetings'], meetingsResponse['lastUpdatedTime'], forceFullSync);
  //           this._isInitialMeetingMappingDone = true
  //         } else {
  //           if(!this._isInitialMeetingMappingDone) {
  //             await this.activityService.loadActivitiesFromDBAndMap(dataRange);
  //             if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
  //               await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
  //             }
  //             this._isInitialMeetingMappingDone = true
  //           }
  //           await this.activityService.mapDeltaSyncedActivities(meetingsResponse['meetings'], meetingsResponse['lastUpdatedTime']);
  //         }
  //       }
  //       if (timeOffsResponse) {
  //         if (timeOffsResponse['isFullSync']) {
  //           if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
  //             await this.timeOffService.mapFullSyncedTots(timeOffsResponse['myTimeOffs'], timeOffsResponse['lastUpdatedTime']);
  //           }
  //           if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS)) {
  //             await this.timeOffService.mapFullSyncedTeamTots(timeOffsResponse['teamTimeOffs'], timeOffsResponse['lastUpdatedTime']);
  //           }
  //           this._isInitialTimeOffMappingDone = true
  //         } else {
  //           if(!this._isInitialTimeOffMappingDone) {
  //             await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
  //             await this.timeOffService.loadTeamTotsFromDBAndMap(dataRange);
  //             this._isInitialTimeOffMappingDone = true
  //           }
  //           await this.timeOffService.mapDeltaSyncedTots(timeOffsResponse['myTimeOffs'], timeOffsResponse['deletedTimeOffs'], timeOffsResponse['lastUpdatedTime']);
  //           await this.timeOffService.mapDeltaSyncedTeamTots(timeOffsResponse['teamTimeOffs'], timeOffsResponse['lastUpdatedTime']);
  //         }
  //       }
  //       if (allocationOrdersResponse) {
  //         if (allocationOrdersResponse['isFullSync']) {
  //           if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
  //             await this.activityService.mapFullSyncedSampleOrderActivities(allocationOrdersResponse['sampleDrops'], allocationOrdersResponse['lastUpdatedTime']);
  //             this._isInitialSampleDropsMappingDone = true;
  //           }
  //         } else {
  //           if(!this._isInitialSampleDropsMappingDone) {
  //             await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
  //             this._isInitialSampleDropsMappingDone = true;
  //           }
  //           await this.activityService.mapDeltaSyncedSampleOrderActivities(allocationOrdersResponse['sampleDrops'], allocationOrdersResponse['lastUpdatedTime']);
  //         }
  //       }
  //       if (tasksResponse) {
  //         if (tasksResponse['isFullSync']) {
  //           if (tasksResponse['followupTasks'] && Array.isArray(tasksResponse['followupTasks']) && tasksResponse['followupTasks'].length > 0) {
  //             let followUps: Array<FollowUpActivity> = [];
  //             tasksResponse['followupTasks'].map(rawfollowup => {
  //               let followUpObj = new FollowUpActivity(rawfollowup);
  //               followUpObj.type = ActivityType.FollowUp;
  //               if (followUpObj.offlineDBId && followUpObj.ownerId && !(followUpObj.state == 2 && followUpObj.status == 6)) {
  //                 followUps.push(followUpObj);
  //               }
  //             });
  //             let action: OperationDetail = {
  //               onDynamics: false,
  //               onLocalDatabase: true,
  //               onLocalCopy: true,
  //             };
  //             await this.followUpActivityDataService.createFollowUpActivity(action, followUps, tasksResponse['lastUpdatedTime'], true);
  //             this._isInitialTasksMappingDone = true;
  //           }
  //         } else {
  //           if(!this._isInitialTasksMappingDone) {
  //             await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
  //             this._isInitialTasksMappingDone = true;
  //           }
  //           if (tasksResponse['followupTasks'] && Array.isArray(tasksResponse['followupTasks']) && tasksResponse['followupTasks'].length > 0) {
  //             let followUps: Array<FollowUpActivity> = [];
  //             tasksResponse['followupTasks'].map(rawfollowup => {
  //               let followUpObj = new FollowUpActivity(rawfollowup);
  //               if (rawfollowup['track_action'] && rawfollowup['track_action'] == TrackAction.Deleted) {
  //                 this.followUpActivityDataService.handleDeletedTrackActionForFollowUpTask(rawfollowup);
  //               } else {
  //                 followUpObj.type = ActivityType.FollowUp;
  //                 if (followUpObj.offlineDBId && followUpObj.ownerId) {
  //                   followUps.push(followUpObj);
  //                 }
  //               }
  //             });
  //             let action: OperationDetail = {
  //               onDynamics: false,
  //               onLocalDatabase: true,
  //               onLocalCopy: true,
  //               operationDetail: {
  //                 code: 'FUPDEL101',
  //                 message: this.translate.instant('DELTA_SYNC_UPDATE')
  //               }
  //             };
  //             if (followUps.length > 0) {
  //               await this.followUpActivityDataService.updateFollowUpActivity(action, followUps, tasksResponse['lastUpdatedTime']);
  //             }
  //           }
  //         }
  //       }
  //       if (phoneCallsResponse) {
  //         if (phoneCallsResponse['isFullSync']) {
  //           await this.activityService.mapFullSyncedPhoneCallActivities(phoneCallsResponse['phoneCalls'], phoneCallsResponse['lastUpdatedTime']);
  //           this._isInitialPhoneCallsMappingDone = true;
  //         } else {
  //           if (!this._isInitialPhoneCallsMappingDone) {
  //             await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
  //           }
  //           await this.activityService.mapDeltaSyncedPhoneActivities(phoneCallsResponse['phoneCalls'], phoneCallsResponse['lastUpdatedTime']);
  //         }
  //       }

  //       // Update UI after sync is mapped
  //       if (!this.uiService.toolsActivityActive) {
  //         this.events.publish('refreshAgenda');
  //       }
  //       else {
  //         this.uiService.agendaRefreshRequired = true;
  //       }
  //     })
  //   } else {
  //     // Falls through here if online fetch didn't work or if it's an offline flow
  //     // Load data from local db.
  //     await this.activityService.loadActivitiesFromDBAndMap(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY))
  //       await this.activityService.loadPhoneCallActivitiesFromDBAndMap(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL))
  //       await this.activityService.loadSampleOrderActivitiesFromDBAndMap(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL))
  //       await this.timeOffService.loadMyTotsFromDBAndMap(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS))
  //       await this.timeOffService.loadTeamTotsFromDBAndMap(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_FOLLOW_UP_TASK)
  //       || this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
  //       || this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK))
  //       await this.followUpActivityDataService.loadFollowUpActivitiesFromDb(dataRange);
  //     if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EMAIL_ACTIVITY))
  //       await this.activityService.loadEmailActivitiesFromDBAndMap(dataRange);
  //     // Update UI after sync is mapped
  //     if (!this.uiService.toolsActivityActive) {
  //       this.events.publish('refreshAgenda');
  //     }
  //     else {
  //       this.uiService.agendaRefreshRequired = true;
  //     }
  //   }
  // }

  // async tempsyncMeetings(dataRange: { from: string, to: string }, forceFullSync: boolean) {
  //   let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEETINGS);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const doFullSync = forceFullSync || isInitialSync;
  //   const positions = this.authenticationService.user.positions.map((o) => {
  //     return o.ID
  //   })
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITIES_BY_ACTIVITY_TYPES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to)
  //     .replace('{activityTypes}', "appointment")
  //     .replace('{positionIDs}', positions.toString());
  //   let appointmentConfigFields: string[] = []
  //   if (!_.isEmpty(this.authenticationService.user.appointmentConfiguredFields)) {
  //     appointmentConfigFields = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
  //       return obj.fieldName
  //     });
  //     url = url.concat("&appointmentConfigFields=").concat(appointmentConfigFields.toString());
  //   }
  //   url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //   const meetingActivitySyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.appointment,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let response: any;
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Sync-Service', 'true');
  //   headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //   try {
  //     response = await this.http.get(url, { headers }).toPromise();
  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (response['meetings'] && Array.isArray(response['meetings'])) {
  //         meetingActivitySyncInfo.totalSynced += response['meetings'].length;
  //       }
  //       this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
  //       // Done sync. Update sync state.
  //       if (meetingActivitySyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }
  //       return { meetings: response['meetings'], isFullSync: doFullSync, lastUpdatedTime: newLastUpdatedTime };
  //     }
  //   } catch (error) {
  //     console.error('syncMeetings: ', error);
  //     this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, error);
  //     this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo);
  //   }
  //   return { meetings: [], isFullSync: doFullSync, lastUpdatedTime: syncState ? syncState.lastUpdatedTime : null };
  // }

  // async tempsyncTimeoffs(dataRange: { from: string, to: string }, forceFullSync: boolean) {
  //   if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL) && !this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TEAM_REQUESTS)) return;
  //   console.log("Syncing TimeOffs...");
  //   let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_TIMEOFFS);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const doFullSync = forceFullSync || isInitialSync;
  //   const positions = this.authenticationService.user.positions.map((o) => {
  //     return o.ID
  //   })
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITIES_BY_ACTIVITY_TYPES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to)
  //     .replace('{activityTypes}', "timeoff")
  //     .replace('{positionIDs}', positions.toString());
  //   url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //   const timeOffSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.timeOff,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let response: any;
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Sync-Service', 'true');
  //   headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //   try {
  //     response = await this.http.get(url, { headers }).toPromise();
  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (!doFullSync) {
  //         if (Array.isArray(response['deletedTimeOffs'])) {
  //           timeOffSyncInfo.totalSynced += response['deletedTimeOffs'].length;
  //         }
  //       }
  //       if (response['myTimeOffs'] && Array.isArray(response['myTimeOffs'])) {
  //         timeOffSyncInfo.totalSynced += response['myTimeOffs'].length;
  //       }
  //       if (response['teamTimeOffs'] && Array.isArray(response['teamTimeOffs'])) {
  //         timeOffSyncInfo.totalSynced += response['teamTimeOffs'].length;
  //       }
  //       this.deltaService.addEntitySyncInfo(timeOffSyncInfo);
  //       // Done sync. Update sync state.
  //       if (timeOffSyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }
  //       return { teamTimeOffs: response['teamTimeOffs'], myTimeOffs: response['myTimeOffs'], deletedTimeOffs: response['deletedTimeOffs'], isFullSync: doFullSync, lastUpdatedTime: newLastUpdatedTime };
  //     }
  //   } catch (error) {
  //     console.error('syncTimeoffs: ', error);
  //     this.deltaService.addSyncErrorToEntitySyncInfo(timeOffSyncInfo, url, error);
  //     this.deltaService.addEntitySyncInfo(timeOffSyncInfo);
  //     return;
  //   }
  //   return { teamTimeOffs: [], myTimeOffs: [], deletedTimeOffs: [], isFullSync: doFullSync, lastUpdatedTime: syncState ? syncState.lastUpdatedTime : null };
  // }

  // async tempsyncAllocationOrders(dataRange: { from: string, to: string }, forceFullSync: boolean) {
  //   if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) return;
  //   console.log("Syncing allocation orders...");
  //   let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOCATION_ORDERS);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const doFullSync = forceFullSync || isInitialSync;
  //   const positions = this.authenticationService.user.positions.map((o) => {
  //     return o.ID
  //   })
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITIES_BY_ACTIVITY_TYPES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to)
  //     .replace('{activityTypes}', "sampledrop")
  //     .replace('{positionIDs}', positions.toString());
  //   url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //   const allocationOrderSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.allocationOrder,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let response: any;
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Sync-Service', 'true');
  //   headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //   try {
  //     response = await this.http.get(url, { headers }).toPromise();

  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (Array.isArray(response['sampleDrops'])) {
  //         allocationOrderSyncInfo.totalSynced = response['sampleDrops'].length;
  //       }
  //       this.deltaService.addEntitySyncInfo(allocationOrderSyncInfo);
  //       // Done sync. Update sync state.
  //       if (allocationOrderSyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }
  //       return { sampleDrops: response['sampleDrops'], isFullSync: doFullSync, lastUpdatedTime: newLastUpdatedTime };
  //     }
  //   } catch (error) {
  //     console.error('syncAllocationOrders: ', error);
  //     this.deltaService.addSyncErrorToEntitySyncInfo(allocationOrderSyncInfo, url, error);
  //     this.deltaService.addEntitySyncInfo(allocationOrderSyncInfo);
  //   }
  //   return { sampleDrops: [], isFullSync: doFullSync, lastUpdatedTime: syncState ? syncState.lastUpdatedTime : null };
  // }

  // async tempsyncFollowupTasks(dataRange: { from: string, to: string }, forceFullSync: boolean) {
  //   if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_FOLLOW_UP_TASK)
  //   && !this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
  //   && !this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK)) return;
  //   console.log("Syncing followupTasks...");
  //   let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_FOLLOWUP_TASKS);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const doFullSync = forceFullSync || isInitialSync;
  //   const positions = this.authenticationService.user.positions.map((o) => {
  //     return o.ID
  //   })
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITIES_BY_ACTIVITY_TYPES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to)
  //     .replace('{activityTypes}', "followup")
  //     .replace('{positionIDs}', positions.toString());
  //   url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //   const followupSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.followup,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let response: any;
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Sync-Service', 'true');
  //   headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //   try {
  //     response = await this.http.get(url, { headers }).toPromise();

  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (Array.isArray(response['followupTasks'])) {
  //         followupSyncInfo.totalSynced = response['followupTasks'].length;
  //       }
  //       this.deltaService.addEntitySyncInfo(followupSyncInfo);
  //       // Done sync. Update sync state.
  //       if (followupSyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }
  //       return { followupTasks: response['followupTasks'], isFullSync: doFullSync, lastUpdatedTime: newLastUpdatedTime };
  //     }
  //   } catch (error) {
  //     console.error('syncFollowupTasks: ', error);
  //     this.deltaService.addSyncErrorToEntitySyncInfo(followupSyncInfo, url, error);
  //     this.deltaService.addEntitySyncInfo(followupSyncInfo);
  //     return;
  //   }
  //   return { followupTasks: response['followupTasks'], isFullSync: doFullSync, lastUpdatedTime: syncState ? syncState.lastUpdatedTime : null };
  // }

  // async tempsyncPhoneCalls(dataRange: { from: string, to: string }, forceFullSync: boolean) {
  //   if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) return;
  //   console.log("Syncing phonecalls...");
  //   let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PHONECALLS);
  //   const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //   const doFullSync = forceFullSync || isInitialSync;
  //   const positions = this.authenticationService.user.positions.map((o) => {
  //     return o.ID
  //   })
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITIES_BY_ACTIVITY_TYPES
  //     .replace('{startDate}', dataRange.from)
  //     .replace('{endDate}', dataRange.to)
  //     .replace('{activityTypes}', "phonecall")
  //     .replace('{positionIDs}', positions.toString());
  //   let phonecallConfigFields: string[] = this.getPhoneCallConfigFields();
  //   if (!_.isEmpty(phonecallConfigFields)) {
  //     url = url.concat("&phonecallConfigFields=").concat(phonecallConfigFields.toString());
  //   }
  //   url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
  //   const phoneCallActivitySyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.phonecall,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let response: any;
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Sync-Service', 'true');
  //   headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //   try {
  //     response = await this.http.get(url, { headers }).toPromise();

  //     if (response) {
  //       const newLastUpdatedTime = new Date().getTime();
  //       if (response['phoneCalls'] && Array.isArray(response['phoneCalls'])) {
  //         phoneCallActivitySyncInfo.totalSynced += response['phoneCalls'].length;
  //       }
  //       this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo);
  //       // Done sync. Update sync state.
  //       if (phoneCallActivitySyncInfo.syncStatus) {
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //       }
  //       return { phoneCalls: response['phoneCalls'], isFullSync: doFullSync, lastUpdatedTime: newLastUpdatedTime };
  //     }
  //   } catch (error) {
  //     console.error('syncMeetings: ', error);
  //     this.deltaService.addSyncErrorToEntitySyncInfo(phoneCallActivitySyncInfo, url, error);
  //     this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo);
  //   }
  //   return { phoneCalls: [], isFullSync: doFullSync, lastUpdatedTime: syncState ? syncState.lastUpdatedTime : null };
  // }

  async getMeetingTypes(loadFromDbOnly = false) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_TYPE)) {
      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
      if (offlineFallback) {
        await this.activityService.loadMeetingTypesFromDB();
      } else {
        const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.MEETING_TYPE
        const meetingTypeSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.meetingType,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        try {
          const response: MeetingTypeDTO[] = await this.http.get<MeetingTypeDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
          if (Array.isArray(response)) {
            meetingTypeSyncInfo.totalSynced = response.length;
          }
          await this.activityService.mapMeetingTypes(response);
        } catch (error) {
          console.log('ActivityDataService: getMeetingTypes: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(meetingTypeSyncInfo, url, error);
          await this.activityService.loadMeetingTypesFromDB();
        }
        this.deltaService.addEntitySyncInfo(meetingTypeSyncInfo);
      }
    }
  }

  public async getUsersPositionsMasterData(loadFromDBOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDBOnly;;
    //ONLINE
    if (!offlineFallback) {

      const userPositionSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.userPositions,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.GET_USERS_POSITIONS;
      try {
        /*await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).subscribe(
          async (bulkResult: any) => {
            await this.timeOffDataService.getUsersPositions(bulkResult);
          }
        );*/
        const bulkResult = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
        await this.timeOffDataService.getUsersPositions(bulkResult, true);

        this.deltaService.addEntitySyncInfo(userPositionSyncInfo);

        return;
      } catch (httpError) {
        //Alert the user that we could not get bulk data
        this.logService.logDebug(
          "Error pulling bulk data!!! Trying from DB",
          httpError
        );
        offlineFallback = true;

        this.deltaService.addSyncErrorToEntitySyncInfo(userPositionSyncInfo, url, httpError);
        this.deltaService.addEntitySyncInfo(userPositionSyncInfo);
      }
    }
    //OFFLINE
    if (offlineFallback || loadFromDBOnly) {
      await this.disk.retrieve(DB_KEY_PREFIXES.USER_POSITIONS).then(response => {
        if (response && response['raw']) {
          this.timeOffDataService.getUsersPositions(response.raw, false);
          return;
        }
      }).catch(dbError => {
        this.logService.logDebug("Error pulling user positions data from DB!", dbError);
      });
    }
  }

  private async uploadDataForNextCallObjectives(meetingsToUpload) {
    let offlineNextCallObjectives = await this.nextCallObjectiveDataService.loadOfflineNextCallObjective();
    meetingsToUpload.forEach(element => {
      let objectiveList = offlineNextCallObjectives.filter(nco => {
        return !nco.deletestate && (nco.capturedMeetingId === element.offlineMeetingId || nco.completedMeetingId === element.offlineMeetingId);
      });
      let ncoData = [];
      objectiveList.forEach(obj => {
        let nco = {
          "indskr_callobjectives": obj.objective,
          "statuscode": obj.statusCode,
          "statecode": obj.stateCode,
          "deletestate": obj.deletestate ? 1 : 0,
          "indskr_externalid": obj.externalid,
          "indskr_callobjectiveid": obj.objectiveID,
          "createdon": obj.createdOn,
          "indskr_User": obj.userId,
          "indskr_Customer_contact": obj.contactId,
          "indskr_meetingcaptured_offlineid": obj.capturedMeetingId == element.offlineMeetingId ? obj.capturedMeetingId : undefined,
          "indskr_meetingcompleted_offlineid": obj.completedMeetingId == element.offlineMeetingId ? obj.completedMeetingId : undefined
        };
        if (nco.indskr_meetingcaptured_offlineid == undefined) {
          delete nco.indskr_meetingcaptured_offlineid;
        }
        if (nco.indskr_meetingcompleted_offlineid == undefined) {
          delete nco.indskr_meetingcompleted_offlineid;
        }
        ncoData.push(nco)
      });
      element['offlineNextCallObjectives'] = ncoData;
    });
    return meetingsToUpload;
  }

  private _getSplicedOfflineRecords(recordsToUpload: any[], maxRecordCountForPartialUpload = 10) {
    if (recordsToUpload.length > maxRecordCountForPartialUpload) {
      recordsToUpload = recordsToUpload.splice(0, maxRecordCountForPartialUpload);
    }

    return recordsToUpload;
  }

  async getOfflineMeetingsToForceUpload(offlineMeetingsDoc, entitySyncInfo: EntitySyncInfo, url): Promise<any[]> {
    let meetingsToUpload = JSON.parse(JSON.stringify(offlineMeetingsDoc['meetings']));
    let idxToBeRemoved = [];
    try {
      meetingsToUpload = await this.uploadDataForNextCallObjectives(meetingsToUpload);
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflineMeetingsToForceUpload: uploadDataForNextCallObjectives',
          }
        }
      );
    }

    try {
      for (let i = 0; i < meetingsToUpload.length; i++) {
        const meeting = meetingsToUpload[i];

        try {
          if (meeting.activityId && meeting.activityId.includes('offline')) {
            meeting.activityId = null;
          }
          if (meeting.hasOwnProperty('activityid')) {
            delete meeting.activityid;
          }
          if (meeting.hasOwnProperty('hasOfflineChange')) {
            delete meeting.hasOfflineChange;
          }
        } catch (error) {
          this.deltaService.addSyncErrorToEntitySyncInfo(
            entitySyncInfo,
            url,
            {
              debug: {
                error,
                record: meeting,
                description: 'DEBUG: getOfflineMeetingsToForceUpload: inside loop'
              }
            }
          );

          idxToBeRemoved.push(i);
        }
      }

      if (idxToBeRemoved.length > 0) {
        // Omit corrupted data
        for (let i = idxToBeRemoved.length - 1; i >= 0; i--) {
          const idx = idxToBeRemoved[i];
          meetingsToUpload.splice(idx, 1);
        }
      }
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflineMeetingsToForceUpload',
          }
        }
      );
    }
    return meetingsToUpload;
  }
  async getOfflinePhoneCallsToForceUpload(offlinePhoneCallMeetingDocs, entitySyncInfo: EntitySyncInfo, url): Promise<any[]> {
    let phoneCallActivitiesToUpload = JSON.parse(JSON.stringify(offlinePhoneCallMeetingDocs['meetings']));
    let phoneMeetingArray = [];

    try {
      for (let i = 0; i < phoneCallActivitiesToUpload.length; i++) {
        const meeting = phoneCallActivitiesToUpload[i];

        try {
          let phonenumber = ""
          if (meeting.mobileNumber) {
            phonenumber = meeting.mobileNumber
          }

          let appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad[] = [];
          appconfiglookupfields = meeting.appconfiglookupfields.length ? meeting.appconfiglookupfields : [];

          let appConfigFields: ConfiguredFields[] = [];
          appConfigFields = meeting.appconfigfields.length ? meeting.appconfigfields : [];

          let selectedProducts = [];
          const activityProd = meeting.activityProducts.length ? meeting.activityProducts : [];

          if (activityProd) {
            activityProd.map((product) => {
              if (product.indskr_productid !== undefined) {
                let keymessages = [];
                if (product.activityProductKeyMessages) {
                  product.activityProductKeyMessages.map((keymsg) => {
                    keymessages.push({
                      indskr_keymessageid: keymsg.indskr_keymessageid,
                    })
                  })
                }
                selectedProducts.push({
                  indskr_productid: product.indskr_productid,
                  indskr_sequence: product.indskr_sequence,
                  keymessages: keymessages
                })
              }
            })
          }
          const therapeuticareas = meeting.activityTherapeuticAreas.length ? meeting.activityTherapeuticAreas : [];
          let selectedTherapeuticareas = [];
          if (therapeuticareas) {
            therapeuticareas.map(therapeuticarea => {
              if (therapeuticarea.indskr_therapeuticarea !== undefined) {
                selectedTherapeuticareas.push({
                  indskr_TherapeuticArea: therapeuticarea.indskr_therapeuticarea,
                })
              }
            })
          }
          const accounts = meeting.activityAccounts.length ? meeting.activityAccounts : [];
          let selectedAccount = [];
          if (accounts) {
            selectedAccount = accounts;
          }
          const contactId = _.isEmpty(meeting.contactAttendees) ? '' : meeting.contactAttendees[0].ID;

          const activityAccompaniedUsers = [];
          if (meeting.activityAccompaniedUsers) {
            meeting.activityAccompaniedUsers.forEach(au => {
              activityAccompaniedUsers.push({
                'userId': au.indskr_user
              })
            })
          }

          let newPhoneCall = {
            "subject": meeting.subject,
            "scheduledend": meeting.scheduledend,
            "scheduledstart": meeting.scheduledstart,
            "offlineId": meeting.offlineId ? meeting.offlineId : "",
            "activityid": meeting.activityid.includes('offline') ? "" : meeting.activityid,
            "statecode": meeting.stateCode,
            "indskr_contactid": contactId,
            "phonenumber": phonenumber,
            "products": selectedProducts,
            "indskr_notes": meeting.indskr_notes ? meeting.indskr_notes : "",
            "therapeuticareas": selectedTherapeuticareas,
            "accounts": selectedAccount,
            "appconfiglookupfields": appconfiglookupfields,
            "appconfigfields": appConfigFields,
            "activityAccompaniedUsers": activityAccompaniedUsers,
            "indskr_activitytype": meeting.indskr_activitytype,
            "indskr_activitysubtype": meeting.indskr_activitysubtype
          }
          let activityParties = [];
          if (contactId) {
            activityParties.push({ contactId: contactId, participationtypemask: 2 });
          }
          if (accounts && accounts.length > 0) {
            for (let account of accounts) {
              activityParties.push({ accountId: account['indskr_accountid'], participationtypemask: 2 })
            }
          }
          if (activityParties.length > 0) {
            newPhoneCall['activityParty'] = activityParties;
          }
          phoneMeetingArray.push(newPhoneCall);
        } catch (error) {
          this.deltaService.addSyncErrorToEntitySyncInfo(
            entitySyncInfo,
            url,
            {
              debug: {
                error,
                record: meeting,
                description: 'DEBUG: getOfflinePhoneCallsToForceUpload: Inside loop'
              }
            }
          );
        }
      }

      phoneMeetingArray = JSON.parse(JSON.stringify(phoneMeetingArray));
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflinePhoneCallsToForceUpload',
          }
        }
      );
    }

    return phoneMeetingArray;
  }
  async getOfflineAllocationOrdersToForceUpload(offlineSamplesDoc, entitySyncInfo: EntitySyncInfo, url): Promise<any[]> {
    let allocationsToUpload = [];

    try {
      if (offlineSamplesDoc && Array.isArray(offlineSamplesDoc['orders'])) {
        for (let i = 0; i < offlineSamplesDoc['orders'].length; i++) {
          const order = offlineSamplesDoc['orders'][i];

          try {
            if (order.activityid && order.activityid.includes('offline')) {
              order.activityid = null;
            }
            if (order.indskr_appointmentid && order.indskr_appointmentid.includes('offline')) {
              order.offlineMeetingId = order.indskr_appointmentid;
              order.indskr_appointmentid = null;
            }
            const filter = !(!order.activityid && order.statecode === 2 && order.statuscode === 3);
            if (filter) {
              allocationsToUpload.push(JSON.parse(JSON.stringify(order)));
            }
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              entitySyncInfo,
              url,
              {
                debug: {
                  error,
                  record: order,
                  description: 'DEBUG: getOfflineAllocationOrdersToForceUpload inside loop',
                }
              }
            );
          }
        }
      }
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflineAllocationOrdersToForceUpload',
          }
        }
      );
    }

    return allocationsToUpload;
  }
  async getOfflineEmailsToForceUpload(offlineEmailsDoc, entitySyncInfo: EntitySyncInfo, url): Promise<{ emailsToUpload: any[], emailPartiesMap: any[] }> {
    let emailPartiesMap = [];
    let emailsToUpload = [];
    try {
      if (offlineEmailsDoc && Array.isArray(offlineEmailsDoc['emails'])) {

        for (let i = 0; i < offlineEmailsDoc['emails'].length; i++) {
          const email = offlineEmailsDoc['emails'][i];

          try {
            if (email.statuscode === 548910000) email.statecode = 1;
            if (email.activityid && email.activityid.includes('offline')) {
              email.activityid = null;
            }

            if (email.appointmentid && email.appointmentid.includes('offline')) {
              email.offlineMeetingId = email.appointmentid;
              email.appointmentid = null;
            }

            const filter = !(!email.activityid && email.statecode == 2 && email.statuscode == 3);
            if (filter) {
              emailsToUpload.push(JSON.parse(JSON.stringify(email)));
            }
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              entitySyncInfo,
              url,
              {
                debug: {
                  error,
                  record: email,
                  description: 'DEBUG: getOfflineEmailsToForceUpload: inside loop 1',
                }
              }
            );
          }
        }

        let idxToBeRemoved = [];
        for (let i = 0; i < emailsToUpload.length; i++) {
          let email = emailsToUpload[i];

          try {
            if (email.hasOwnProperty('ownerId')) {
              delete email.ownerId;
            }
            if (email.hasOwnProperty('meetingOwnerId')) {
              delete email.meetingOwnerId;
            }
            if (email.hasOwnProperty('channelType')) {
              delete email.channelType;
            }
            if (email['channelActivityType'] && (email['channelActivityType'] == ChannelActivityType.SMS || email['channelActivityType'] == ChannelActivityType.WHATSAPP) && email['emailActivityParties'] && email['emailActivityParties'].length != 0) {
              email['smsActivityParties'] = email['emailActivityParties'].map(con => {
                return {
                  "indskr_contactid": con.indskr_contactid,
                  "phoneNumbers": [
                    con.contact_mobilephone,
                  ]
                };
              })
            }
            if(email['activityAccounts'] && email['activityAccounts'].length != 0) {
              email['activityAccounts'] = email['activityAccounts'].map(acc => {
                return {
                  "indskr_accountid": acc.indskr_accountid
                };
              });
            }
            if (email['offlineActivityId']) {
              emailPartiesMap[email['offlineActivityId']] = email.emailActivityParties;
            }
            if (email.hasOwnProperty('emailActivityParties')) {
              delete email.emailActivityParties;
            }
            if (email.hasOwnProperty('modified')) {
              delete email.modified;
            }
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              entitySyncInfo,
              url,
              {
                debug: {
                  error,
                  record: email,
                  description: 'DEBUG: getOfflineEmailsToForceUpload: inside loop 2',
                }
              }
            );
            idxToBeRemoved.push(i);
          }
        }

        if (idxToBeRemoved.length > 0) {
          // Omit corrupted data
          for (let i = idxToBeRemoved.length - 1; i >= 0; i--) {
            const idx = idxToBeRemoved[i];
            emailsToUpload.splice(idx, 1);
          }
        }
      }
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflineEmailsToForceUpload',
          }
        }
      );
    }

    return { emailsToUpload, emailPartiesMap };
  }
  async getOfflineTimeOffsToForceUpload(offlineTimeOffs, entitySyncInfo: EntitySyncInfo, url): Promise<any[]> {
    let timeOffsToUpload: any[] = [];
    let idxToBeRemoved = [];
    try {
      timeOffsToUpload = JSON.parse(JSON.stringify(offlineTimeOffs['myTos']));

      if (Array.isArray(timeOffsToUpload)) {

        for (let i = 0; i < timeOffsToUpload.length; i++) {
          const timeOff = timeOffsToUpload[i];
          try {
            if (timeOff.hasOwnProperty('indskr_reason@OData.Community.Display.V1.FormattedValue')) {
              delete timeOff['indskr_reason@OData.Community.Display.V1.FormattedValue'];
            }
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              entitySyncInfo,
              url,
              {
                debug: {
                  error,
                  record: timeOff,
                  description: 'DEBUG: getOfflineTimeOffsToForceUpload: inside loop'
                }
              }
            );

            idxToBeRemoved.push(i);
          }
        }

        if (idxToBeRemoved.length > 0) {
          // Omit corrupted data
          for (let i = idxToBeRemoved.length - 1; i >= 0; i--) {
            const idx = idxToBeRemoved[i];
            timeOffsToUpload.splice(idx, 1);
          }
        }
      }
    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        entitySyncInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: getOfflineTimeOffsToForceUpload',
          }
        }
      );
    }
    return timeOffsToUpload;
  }

  async forceOfflineDataUpload() {
    const meetingActivitySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appointment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const phoneCallActivitySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.phonecall,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const sampleOrderSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.sampleProduct,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const emailSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.email,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const timeOffSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.timeOff,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    // Load offline meetings from DB
    const [
      offlineMeetingsDoc,
      offlinePhoneCallMeetingDocs,
      offlineSamplesDoc,
      offlineEmailsDoc,
      offlineTimeOffs,
    ] = await Promise.all([
      this.disk.loadOfflineMeetings()
        .catch(error => {
          console.error('forceOfflineDataUpload: loadOfflineMeetings: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            meetingActivitySyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: loadOfflineMeetings',
              }
            }
          );
          return [];
        }),

      this.disk.loadOfflinePhoneCallMeetings()
        .catch(error => {
          console.error('forceOfflineDataUpload: loadOfflinePhoneCallMeetings: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            phoneCallActivitySyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: loadOfflinePhoneCallMeetings',
              }
            }
          );
          return [];
        }),

      this.disk.loadOfflineSampleOrders()
        .catch(error => {
          console.error('forceOfflineDataUpload: loadOfflineSampleOrders: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            sampleOrderSyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: loadOfflineSampleOrders',
              }
            }
          );
          return [];
        }),

      this.disk.loadOfflineEmails()
        .catch(error => {
          console.error('forceOfflineDataUpload: loadOfflineEmails: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            emailSyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: loadOfflineEmails',
              }
            }
          );
          return [];
        }),
      this.disk.loadOfflineTimeOffs()
        .catch(error => {
          console.error('forceOfflineDataUpload: loadOfflineTimeOffs: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            timeOffSyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: loadOfflineTimeOffs',
              }
            }
          );
          return [];
        }),
    ]);

    // Prep data
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_MEETING;
    const [
      meetingsToUpload,
      phoneMeetingArray,
      allocationsToUpload,
      { emailsToUpload, emailPartiesMap },
      timeOffsToUpload,
    ] = await Promise.all([
      offlineMeetingsDoc && Array.isArray(offlineMeetingsDoc['meetings'])
      ? this.getOfflineMeetingsToForceUpload(offlineMeetingsDoc, meetingActivitySyncInfo, url)
          .catch(error => {
            console.error('forceOfflineDataUpload: getOfflineMeetingsToForceUpload: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              meetingActivitySyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: getOfflineMeetingsToForceUpload',
                }
              }
            );
            return [];
          })
      : [],

      offlinePhoneCallMeetingDocs && Array.isArray(offlinePhoneCallMeetingDocs['meetings'])
      ? this.getOfflinePhoneCallsToForceUpload(offlinePhoneCallMeetingDocs, phoneCallActivitySyncInfo, url)
        .catch(error => {
          console.error('forceOfflineDataUpload: getOfflinePhoneCallsToForceUpload: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(
            phoneCallActivitySyncInfo,
            url,
            {
              debug: {
                error,
                description: 'DEBUG: forceOfflineDataUpload: getOfflinePhoneCallsToForceUpload',
              }
            }
          );
          return [];
        })
      : [],

      offlineSamplesDoc && Array.isArray(offlineSamplesDoc['orders'])
      ? this.getOfflineAllocationOrdersToForceUpload(offlineSamplesDoc, sampleOrderSyncInfo, url)
          .catch(error => {
            console.error('forceOfflineDataUpload: getOfflineAllocationOrdersToForceUpload: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              sampleOrderSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: getOfflineAllocationOrdersToForceUpload',
                }
              }
            );
            return [];
          })
      : [],

      offlineEmailsDoc && Array.isArray(offlineEmailsDoc['emails'])
      ? this.getOfflineEmailsToForceUpload(offlineEmailsDoc, emailSyncInfo, url)
          .catch(error => {
            console.error('forceOfflineDataUpload: getOfflineEmailsToForceUpload: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              emailSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: getOfflineEmailsToForceUpload',
                }
              }
            );
            return { emailsToUpload: [], emailPartiesMap: [] };
          })
      : { emailsToUpload: [], emailPartiesMap: [] },

      offlineTimeOffs && Array.isArray(offlineTimeOffs['myTos'])
      ? this.getOfflineTimeOffsToForceUpload(offlineTimeOffs, timeOffSyncInfo, url)
        .catch(error => {
          console.error('forceOfflineDataUpload: getOfflineTimeOffsToForceUpload: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              timeOffSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: getOfflineTimeOffsToForceUpload',
                }
              }
            );
            return [];
        })
      : [],
    ]);

    let response: any;
    const headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
    headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
    const body = {
      offlineMeetings: meetingsToUpload,
      offlinePhonecalls: phoneMeetingArray,
      offlineTimeOffs: timeOffsToUpload,
      offlineSampleDrops: allocationsToUpload,
      offlineEmails: emailsToUpload,
      offlineFollowUpTasks: [],
      offlineProgressReports: [],
      offlineConsents: [],
      offlineMedicalEnquiry: [],
      offlineSurgeryOrder: [],
    };

    // console.log('### result: ', body, meetingActivitySyncInfo, phoneCallActivitySyncInfo, sampleOrderSyncInfo, emailSyncInfo, emailPartiesMap);

    try {
      response = await this.http.post(url, body, headers).toPromise();
    } catch (httpError) {
      console.error('forceOfflineDataUpload: httpRequest', httpError);
      this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, httpError);
      this.deltaService.addSyncErrorToEntitySyncInfo(phoneCallActivitySyncInfo, url, httpError);
      this.deltaService.addSyncErrorToEntitySyncInfo(sampleOrderSyncInfo, url, httpError);
      this.deltaService.addSyncErrorToEntitySyncInfo(emailSyncInfo, url, httpError);
    }

    await Promise.all([
      response && response['offlineMeetings']
      ? this.handleOfflineMeetingUploadResponse(response, offlineMeetingsDoc, meetingActivitySyncInfo, url, true)
          .catch(error => {
            console.error('forceOfflineDataUpload: handleOfflineMeetingUploadResponse: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              meetingActivitySyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: handleOfflineMeetingUploadResponse',
                }
              }
            );
            return false;
          })
      : undefined,

      response && response['offlinePhonecalls']
      ? this.handleOfflinePhonecallData(response, phoneCallActivitySyncInfo, offlinePhoneCallMeetingDocs, url, true)
          .catch(error => {
            console.error('forceOfflineDataUpload: handleOfflinePhonecallData: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              phoneCallActivitySyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: handleOfflinePhonecallData',
                }
              }
            );
            return false;
          })
      : undefined,

      response && response['offlineSampleDrops']
      ? this.handleOfflineAllocationOrderUploadResponse(response, offlineSamplesDoc, sampleOrderSyncInfo, url, true)
          .catch(error => {
            console.error('forceOfflineDataUpload: handleOfflineAllocationOrderUploadResponse: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              sampleOrderSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: handleOfflineAllocationOrderUploadResponse',
                }
              }
            );
            return false;
          })
      : undefined,

      response && response['offlineEmails']
      ? this.handleOfflineEmailData(response, emailSyncInfo, offlineEmailsDoc, url, emailPartiesMap, true)
          .catch(error => {
            console.error('forceOfflineDataUpload: handleOfflineEmailData: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              emailSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: handleOfflineEmailData',
                }
              }
            );
            return false;
          })
      : undefined,

      response && response['offlineTimeOffs']
      ? this.timeOffService.mapUploadedOfflineTots(response, offlineTimeOffs, timeOffSyncInfo, timeOffsToUpload.length, url, true)
        .catch(error => {
          console.error('forceOfflineDataUpload: mapUploadedOfflineTots: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(
              timeOffSyncInfo,
              url,
              {
                debug: {
                  error,
                  description: 'DEBUG: forceOfflineDataUpload: mapUploadedOfflineTots',
                }
              }
            );
            return false;
        })
      : undefined,
    ]);

    // Add sync info
    this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo, true);
    this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo, true);
    this.deltaService.addEntitySyncInfo(sampleOrderSyncInfo, true);
    this.deltaService.addEntitySyncInfo(emailSyncInfo, true);
    this.deltaService.addEntitySyncInfo(timeOffSyncInfo, true);
  }

  public offlineMeetingPayloadSanityCheck = meeting => {
    if (meeting.activityId && meeting.activityId.includes('offline')) {
      meeting.activityId = null;
    }
    if (meeting.hasOwnProperty('activityid')) {
      delete meeting.activityid;
    }
    if (meeting.hasOwnProperty('hasOfflineChange')) {
      delete meeting.hasOfflineChange;
    }
    //OMNI-48797: Last page end time missing, possible fix to prevent
    // this.activityService.updateSlideEndTime(meeting, true);

    if (meeting.hasOwnProperty('activityOpportunities')){
      meeting['opportunityIds'] = meeting.activityOpportunities.map(a=> a.opportunityId);
      delete meeting.activityOpportunities;
    }

    if (meeting.hasOwnProperty('activityAccountPlans')){
      meeting['accountPlanIds'] = meeting.activityAccountPlans.map(a=> a.accountPlanId);
      delete meeting.activityAccountPlans;
    }

    if (meeting.hasOwnProperty('activityEvents')){
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_MEETINGS)) {
        meeting['eventIds'] = meeting.activityEvents.map(a=> a.eventId);
      } else {
        meeting['eventIds'] = null;
      }
      delete meeting.activityEvents;
    }
    if (meeting.hasOwnProperty('activityProcedures')) {
      if (_.isEmpty(meeting.activityProducts)) meeting.activityProducts = [];
      meeting.activityProducts.push(...meeting.activityProcedures);
      meeting.activityProducts = _.uniqBy(meeting.activityProducts, 'indskr_productid');
      delete meeting.activityProcedures;
    }
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES)) {
      delete meeting.indskr_notes;
    }
  }

  public async uploadOfflineMeetingsTimeOffsAllocationsEmailsFollowupsConsents(syncDebugInfo: EntitySyncInfo, isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.deviceService.isOffline) return;

    // Load offline meetings from DB
    const offlineMeetingsDoc = await this.disk.loadOfflineMeetings();

    const offlinePhoneCallMeetingDocs = await this.disk.loadOfflinePhoneCallMeetings();

    const offlineTimeOffs = await this.disk.loadOfflineTimeOffs();
    // Load offline allocation orders from DB
    const offlineSamplesDoc = await this.disk.loadOfflineSampleOrders();
    // Load offline emails from DB
    const offlineEmailsDoc = await this.disk.loadOfflineEmails();

    const offlineFollowUps = await this.followUpActivityDataService.loadOfflineFollowUpActivities();

    const offlineProgressReports = await this.accountManagementOfflineService.loadOfflineReports()

    let offlineConsents = await this.consetnService.loadOfflineConsents();

    let offlineCaseDocs = await this.disk.loadOfflineCases();

    let offlineSurgeryOrdersDoc = await this.surgeryOrderActivityDataService.loadOfflineSurgeryOrderActivities();

    let uploadMeetings: boolean = true;
    let meetingsToUpload = [];

    let uploadPhoneCallMeetings: boolean = true;
    let phoneCallActivitiesToUpload = [];
    let phoneMeetingArray = [];

    let uploadTimeOffs: boolean = true;
    let timeOffsToUpload = [];

    let uploadAllocations: boolean = true;
    let allocationsToUpload = [];

    let uploadEmails: boolean = true;
    let emailsToUpload = [];

    let uploadFollowUps: boolean = true;
    let followupsToUpload = [];

    let uploadProgressReports: boolean = true;
    let progressReportsToUpload = [];

    let uploadConsents: boolean = true;
    let consentsToUpload = [];

    let emailPartiesMap: any = [];

    let uploadCases: boolean = true;
    let casesToUpload = [];

    let uploadSurgeryOrders: boolean = true;
    let surgeryOrdersToUpload = [];

    if (!offlineMeetingsDoc || !Array.isArray(offlineMeetingsDoc['meetings']) || offlineMeetingsDoc['meetings'].length === 0) {
      uploadMeetings = false;
    }

    if (!offlinePhoneCallMeetingDocs || !Array.isArray(offlinePhoneCallMeetingDocs['meetings']) || offlinePhoneCallMeetingDocs['meetings'].length === 0) {
      uploadPhoneCallMeetings = false;
    }

    if (!offlineTimeOffs || !Array.isArray(offlineTimeOffs['myTos']) || offlineTimeOffs['myTos'].length === 0) {
      uploadTimeOffs = false;
    }
    if (!offlineSamplesDoc || !Array.isArray(offlineSamplesDoc['orders']) || offlineSamplesDoc['orders'].length === 0) {
      uploadAllocations = false;
    }
    if (!offlineEmailsDoc || !Array.isArray(offlineEmailsDoc['emails']) || offlineEmailsDoc['emails'].length === 0) {
      uploadEmails = false;
    }
    if (!offlineFollowUps || !Array.isArray(offlineFollowUps) || offlineFollowUps.length === 0) {
      uploadFollowUps = false;
    }
    if (!offlineProgressReports || !Array.isArray(offlineProgressReports) || offlineProgressReports.length === 0) {
      uploadProgressReports = false;
    }
    if (!Array.isArray(offlineConsents) || offlineConsents.length === 0) {
      uploadConsents = false;
    }
    if (!offlineCaseDocs || !Array.isArray(offlineCaseDocs['myCases']) || offlineCaseDocs['myCases'].length === 0) {
      uploadCases = false;
    }
    if (!offlineSurgeryOrdersDoc || !Array.isArray(offlineSurgeryOrdersDoc) || offlineSurgeryOrdersDoc.length === 0) {
      uploadSurgeryOrders = false;
    }

    if (!uploadMeetings && !uploadPhoneCallMeetings && !uploadTimeOffs && !uploadAllocations && !uploadEmails && !uploadFollowUps && !uploadProgressReports && !uploadConsents && !uploadCases && !uploadSurgeryOrders) return;

    // Flag offline data upload
    this.disk.wasThereOfflineDataUpload = true;
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_MEETING;

    try {

    // Prepare payload
    if (uploadMeetings) {
      meetingsToUpload = JSON.parse(JSON.stringify(offlineMeetingsDoc['meetings']));
      if (isPartialUpload) {
        meetingsToUpload = this._getSplicedOfflineRecords(meetingsToUpload, maxRecordCountForPartialUpload);
      }
      meetingsToUpload = await this.uploadDataForNextCallObjectives(meetingsToUpload);

      meetingsToUpload.forEach(this.offlineMeetingPayloadSanityCheck);
    }

    if (uploadPhoneCallMeetings) {
      phoneCallActivitiesToUpload = JSON.parse(JSON.stringify(offlinePhoneCallMeetingDocs['meetings']));
      if (isPartialUpload) {
        phoneCallActivitiesToUpload = this._getSplicedOfflineRecords(phoneCallActivitiesToUpload, maxRecordCountForPartialUpload);
      }
      phoneCallActivitiesToUpload.map(meeting => {

        let phonenumber = ""
        if (meeting.mobileNumber) {
          phonenumber = meeting.mobileNumber
        }

        let appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad[] = [];
        appconfiglookupfields = meeting.appconfiglookupfields.length ? meeting.appconfiglookupfields : [];

        let appConfigFields: ConfiguredFields[] = [];
        appConfigFields = meeting.appconfigfields.length ? meeting.appconfigfields : [];

        let selectedProducts = [];
        const activityProd = meeting.activityProducts.length ? meeting.activityProducts : [];

        if (activityProd) {
          activityProd.map((product) => {
            if (product.indskr_productid !== undefined) {
              let keymessages = [];
              if (product.activityProductKeyMessages) {
                product.activityProductKeyMessages.map((keymsg) => {
                  keymessages.push({
                    indskr_keymessageid: keymsg.indskr_keymessageid,
                  })
                })
              }
              selectedProducts.push({
                indskr_productid: product.indskr_productid,
                indskr_sequence: product.indskr_sequence,
                keymessages: keymessages
              })
            }
          })
        }
        const therapeuticareas = meeting.activityTherapeuticAreas.length ? meeting.activityTherapeuticAreas : [];
        let selectedTherapeuticareas = [];
        if (therapeuticareas) {
          therapeuticareas.map(therapeuticarea => {
            if (therapeuticarea.indskr_therapeuticarea !== undefined) {
              selectedTherapeuticareas.push({
                indskr_TherapeuticArea: therapeuticarea.indskr_therapeuticarea,
              })
            }
          })
        }
        const accounts = meeting.activityAccounts.length ? meeting.activityAccounts : [];
        let selectedAccount = [];
        if (accounts) {
          selectedAccount = accounts;
        }
        const contactId = _.isEmpty(meeting.contactAttendees) ? '' : _.isArray(meeting.contactAttendees) && meeting.contactAttendees.length == 1 ?  meeting.contactAttendees[0].ID :  meeting.contactAttendees.ID;

        const activityAccompaniedUsers = [];
        if (meeting.activityAccompaniedUsers) {
          meeting.activityAccompaniedUsers.forEach(au => {
            activityAccompaniedUsers.push({
              'userId': au.indskr_user
            })
          })
        }

        let newPhoneCall = {
          "subject": meeting.subject,
          "scheduledend": meeting.scheduledend,
          "scheduledstart": meeting.scheduledstart,
          "offlineId": meeting.offlineId ? meeting.offlineId : "",
          "activityid": meeting.activityid.includes('offline') ? "" : meeting.activityid,
          "statecode": meeting.stateCode,
          "indskr_contactid": contactId,
          "phonenumber": phonenumber,
          "products": selectedProducts,
          "indskr_notes": meeting.indskr_notes ? meeting.indskr_notes : "",
          "therapeuticareas": selectedTherapeuticareas,
          "accounts": selectedAccount,
          "appconfiglookupfields": appconfiglookupfields,
          "appconfigfields": appConfigFields,
          "activityAccompaniedUsers": activityAccompaniedUsers,
          "indskr_activitytype": meeting.indskr_activitytype,
          "indskr_activitysubtype": meeting.indskr_activitysubtype,
          "accountPlanId" : meeting.accountPlanId
        }
        let activityParties = [];
        if (contactId) {
          activityParties.push({ contactId: contactId, participationtypemask: 2 });
        }
        if (accounts && accounts.length > 0) {
          for (let account of accounts) {
            activityParties.push({ accountId: account['indskr_accountid'], participationtypemask: 2 })
          }
        }
        if (activityParties.length > 0) {
          newPhoneCall['activityParty'] = activityParties;
        }
        phoneMeetingArray.push(newPhoneCall);
      });
      phoneMeetingArray = JSON.parse(JSON.stringify(phoneMeetingArray));
      if (isPartialUpload) {
        phoneMeetingArray = this._getSplicedOfflineRecords(phoneMeetingArray, maxRecordCountForPartialUpload);
      }
    }

    if (uploadTimeOffs) {
      timeOffsToUpload = JSON.parse(JSON.stringify(offlineTimeOffs['myTos']));
      if (isPartialUpload) {
        timeOffsToUpload = this._getSplicedOfflineRecords(timeOffsToUpload, maxRecordCountForPartialUpload);
      }
      timeOffsToUpload = timeOffsToUpload.map(timeoff => timeoff = _.omit(timeoff, ['indskr_reason@OData.Community.Display.V1.FormattedValue']));
    }

    if (uploadAllocations) {
      offlineSamplesDoc['orders'] = offlineSamplesDoc['orders'].map(order => {
        if (order.activityid && order.activityid.includes('offline')) {
          order.activityid = null;
        }
        if (order.indskr_appointmentid && order.indskr_appointmentid.includes('offline')) {
          order.offlineMeetingId = order.indskr_appointmentid;
          order.indskr_appointmentid = null;
        }
        return order;
      }).filter(a => !(!a.activityid && a.statecode == 2 && a.statuscode == 3));
      offlineSamplesDoc['count'] = offlineSamplesDoc['orders'].length;

      allocationsToUpload = JSON.parse(JSON.stringify(offlineSamplesDoc['orders']));
      if (isPartialUpload) {
        allocationsToUpload = this._getSplicedOfflineRecords(allocationsToUpload, maxRecordCountForPartialUpload);
      }
    }

    if (uploadEmails) {

      offlineEmailsDoc['emails'] = offlineEmailsDoc['emails'].map(email => {

        if (email.statuscode === 548910000) email.statecode = 1;
        if (email.activityid && email.activityid.includes('offline')) {
          email.activityid = null;
        }

        if (email.appointmentid && email.appointmentid.includes('offline')) {
          email.offlineMeetingId = email.appointmentid;
          email.appointmentid = null;
        }
        //email = _.omit(email, ['ownerId']);
        //email = _.omit(email, ['meetingOwnerId']);

        // if (email['channelActivityType'] && (email['channelActivityType'] == ChannelActivityType.SMS || email['channelActivityType'] == ChannelActivityType.WHATSAPP) && email['emailActivityParties'] && email['emailActivityParties'].length != 0) {
        //   email['smsActivityParties'] = email['emailActivityParties'].map(con => {
        //     return {
        //       "indskr_contactid": con.indskr_contactid,
        //       "phoneNumbers": [
        //         con.contact_mobilephone,
        //       ]
        //     };
        //   })
        // }
        // emailPartiesMap[email['offlineActivityId']] = email.emailActivityParties;
        // email = _.omit(email, ['emailActivityParties'])
        return email;
      }).filter(a => !(!a.activityid && a.statecode == 2 && a.statuscode == 3));
      offlineEmailsDoc['count'] = offlineEmailsDoc['emails'].length;

      emailsToUpload = JSON.parse(JSON.stringify(offlineEmailsDoc['emails'].map(email => {
        email = _.omit(email, ['ownerId']);
        email = _.omit(email, ['meetingOwnerId']);
        email = _.omit(email, ['channelType']);
        if (email['channelActivityType'] && (email['channelActivityType'] == ChannelActivityType.SMS || email['channelActivityType'] == ChannelActivityType.WHATSAPP) && email['emailActivityParties'] && email['emailActivityParties'].length != 0) {
          email['smsActivityParties'] = email['emailActivityParties'].map(con => {
            return {
              "indskr_contactid": con.indskr_contactid,
              "phoneNumbers": [
                con.contact_mobilephone,
              ]
            };
          })
        }
        if(email['activityAccounts'] && email['activityAccounts'].length != 0) {
          email['activityAccounts'] = email['activityAccounts'].map(acc => {
            return {
              "indskr_accountid": acc.indskr_accountid
            };
          });
        }
        emailPartiesMap[email['offlineActivityId']] = email.emailActivityParties;
        email = _.omit(email, ['emailActivityParties'])
        if (email.hasOwnProperty('modified')) {
          delete email.modified;
        }
        return email;
      })));
      if (isPartialUpload) {
        emailsToUpload = this._getSplicedOfflineRecords(emailsToUpload, maxRecordCountForPartialUpload);
      }
    }

    if (uploadFollowUps) {
      followupsToUpload = JSON.parse(JSON.stringify(offlineFollowUps));
      if (isPartialUpload) {
        followupsToUpload = this._getSplicedOfflineRecords(followupsToUpload, maxRecordCountForPartialUpload);
      }
    }

    if (uploadProgressReports) {
      progressReportsToUpload = JSON.parse(JSON.stringify(offlineProgressReports));
      if (isPartialUpload) {
        progressReportsToUpload = this._getSplicedOfflineRecords(progressReportsToUpload, maxRecordCountForPartialUpload);
      }
    }

    if (uploadConsents) {
      // Upload the added offline contact metadata(email address and phone number). Need to update the email address ID before uploading the offline consent.
      const updatedOfflineConsents = await this.consentService.uploadAddContactMetadataAndMapping(offlineConsents).catch(e => console.error('uploadOfflineData: contact meta: ', e));
      if (updatedOfflineConsents) {
        consentsToUpload = JSON.parse(JSON.stringify(updatedOfflineConsents));
        if (isPartialUpload) {
          consentsToUpload = this._getSplicedOfflineRecords(consentsToUpload, maxRecordCountForPartialUpload);
        }
      }
    }

    if (uploadCases) {
      casesToUpload = JSON.parse(JSON.stringify(offlineCaseDocs['myCases']));
      if (isPartialUpload) {
        casesToUpload = this._getSplicedOfflineRecords(casesToUpload, maxRecordCountForPartialUpload)
      }
    }

    if (uploadSurgeryOrders) {
      surgeryOrdersToUpload = JSON.parse(JSON.stringify(offlineSurgeryOrdersDoc));
      if (isPartialUpload) {
        surgeryOrdersToUpload = this._getSplicedOfflineRecords(surgeryOrdersToUpload, maxRecordCountForPartialUpload);
      }
    }

    } catch (error) {
      this.deltaService.addSyncErrorToEntitySyncInfo(
        syncDebugInfo,
        url,
        {
          debug: {
            error,
            description: 'DEBUG: uploadOfflineMeetingsTimeOffsAllocationsEmailsFollowupsConsents: during data prep'
          }
        }
      );
    }

    const meetingActivitySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.appointment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const phoneCallActivitySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.phonecall,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const timeOffSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.timeOff,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const sampleOrderSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.sampleProduct,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const emailSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.email,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const followUpSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.followup,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const progressReportsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.progressreports,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    }

    const consentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.consent,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const caseInqiurySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.case_inquiry,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    const surgeryOrderSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.order,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let response: any;
    const headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
    headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
    headers.headers = headers.headers.set('X-Zone-Id', timeZone);
    const body = {
      offlineMeetings: meetingsToUpload,
      offlinePhonecalls: phoneMeetingArray,
      offlineTimeOffs: timeOffsToUpload,
      offlineSampleDrops: allocationsToUpload,
      offlineEmails: emailsToUpload,
      offlineFollowUpTasks: followupsToUpload,
      offlineProgressReports: progressReportsToUpload,
      offlineConsents: consentsToUpload,
      offlineMedicalEnquiry: casesToUpload,
      offlineSurgeryOrder: surgeryOrdersToUpload,
    };

    try {
      if (isPartialUpload) {
        response = await this.http.post(url, body, headers).pipe(timeout(150000)).toPromise();
      } else {
        response = await this.http.post(url, body, headers).toPromise();
      }
    } catch (httpError) {
      console.error('uploadOfflineActivities:', httpError);
      if (uploadMeetings) {
        //meetingActivitySyncInfo.errorMessage = '[meeting][error]' + httpError ? httpError.errorMessage : '';
        this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, httpError);
        meetingActivitySyncInfo.totalFailed += meetingsToUpload.length;
      }
      if (uploadPhoneCallMeetings) {
        console.error('uploadOfflinePhoneCallActivities:', httpError);
        this.deltaService.addSyncErrorToEntitySyncInfo(phoneCallActivitySyncInfo, url, httpError);
        phoneCallActivitySyncInfo.totalFailed += phoneCallActivitiesToUpload.length;
      }
      if (uploadTimeOffs) {
        this.deltaService.addSyncErrorToEntitySyncInfo(timeOffSyncInfo, url, httpError);
        timeOffSyncInfo.totalFailed += timeOffsToUpload.length;
      }
      if (uploadAllocations) {
        //sampleOrderSyncInfo.errorMessage = '[sampleOrders][error]' + httpError ? httpError.errorMessage : '';
        this.deltaService.addSyncErrorToEntitySyncInfo(sampleOrderSyncInfo, url, httpError);
        sampleOrderSyncInfo.totalFailed += allocationsToUpload.length;
      }
      if (uploadEmails) {
        this.deltaService.addSyncErrorToEntitySyncInfo(emailSyncInfo, url, httpError);
        emailSyncInfo.totalFailed += emailsToUpload.length;
      }
      if (uploadFollowUps) {
        this.deltaService.addSyncErrorToEntitySyncInfo(followUpSyncInfo, url, httpError);
        followUpSyncInfo.totalFailed += followupsToUpload.length;
      }
      if (uploadProgressReports) {
        this.deltaService.addSyncErrorToEntitySyncInfo(progressReportsSyncInfo, url, httpError);
        progressReportsSyncInfo.totalFailed += progressReportsToUpload.length;
      }
      if (uploadConsents) {
        this.deltaService.addSyncErrorToEntitySyncInfo(consentSyncInfo, url, httpError);
        consentSyncInfo.totalFailed += consentsToUpload.length;
      }
      if (uploadCases) {
        this.deltaService.addSyncErrorToEntitySyncInfo(caseInqiurySyncInfo, url, httpError);
        caseInqiurySyncInfo.totalFailed += casesToUpload.length;
      }
      if (uploadSurgeryOrders) {
        this.deltaService.addSyncErrorToEntitySyncInfo(surgeryOrderSyncInfo, url, httpError);
        followUpSyncInfo.totalFailed += surgeryOrdersToUpload.length;
      }
    }

    if (uploadMeetings && response && response['offlineMeetings']) {
      // Loop through offlineMeetings response
      for (const key in response['offlineMeetings']) {
        if (response['offlineMeetings'].hasOwnProperty(key)) {
          const meetingResponse = response['offlineMeetings'][key];

          if (!meetingResponse.hasOwnProperty('errorId')) {
            if (meetingResponse.hasOwnProperty('activityId')) {
              if (meetingResponse.hasOwnProperty('offlineMeetingId')) {
                const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.offlineMeetingId === meetingResponse.offlineMeetingId);
                if (idx >= 0) {
                  if (offlineMeetingsDoc['meetings'][idx].activityid.includes('offline')) {
                    // It's an offline created meeting
                    const newMeetingDoc = offlineMeetingsDoc['meetings'][idx];
                    newMeetingDoc['activityid'] = meetingResponse.activityId;
                    if (newMeetingDoc.hasOwnProperty('activityId')) {
                      delete newMeetingDoc.activityId;
                    }
                    newMeetingDoc['lastUpdatedTime'] = 0;

                    // Just to be safe, re-try 5 times
                    for (let retries = 0; retries < 6; retries++) {
                      try {
                        // Create a new db record with guid
                        await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_ACTIVITY + meetingResponse.activityId, doc => newMeetingDoc);

                        // Remove from offline db record
                        offlineMeetingsDoc['meetings'].splice(idx, 1);
                        // Since upload was successful, delete from offline meeting data id hashmap if exists
                        if (this.activityService.hasOfflineMeetingData(meetingResponse.offlineMeetingId)) {
                          this.activityService.deleteFromOfflineMeetingIds(meetingResponse.offlineMeetingId);
                          //console.log('----- t1) deleted ' + meetingResponse.offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                        }

                        // Update appointment activity id of the object
                        const activity = this.activityService.getActivityByID(meetingResponse.offlineMeetingId);
                        if (activity) {
                          activity.ID = meetingResponse.activityId;
                          // this.disk.updateOrInsertActivityToActivityDetailRawDocument((activity as AppointmentActivity), true)
                        } else {
                          console.warn(`uploadOfflineMeetings: Couldn't find appointment object with ID ${meetingResponse.activityId}`);
                        }
                        break;
                      } catch (error) {
                        if (retries < 5) {
                          continue;
                        } else {
                          console.error('uploadOfflineMeetings: error saving a new record..', error);
                        }
                      }
                    }
                  } else {
                    // Existing meeting
                    // Remove from offline db record
                    offlineMeetingsDoc['meetings'].splice(idx, 1);
                    // Since upload was successful, delete from offline meeting data id hashmap if exists
                    if (this.activityService.hasOfflineMeetingData(meetingResponse.activityId)) {
                      this.activityService.deleteFromOfflineMeetingIds(meetingResponse.activityId);
                      //console.log('----- t2) deleted ' + meetingResponse.offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                    }
                  }
                } else {
                  console.warn(`uploadOfflineMeetings: ID ${meetingResponse.offlineMeetingId} is not found from local db.`);
                }
              } else {
                const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.activityId === meetingResponse.activityId);
                if (idx >= 0) {
                  // Should be legacy data without 'offlineMeetingId'
                  // Remove from offline db record
                  offlineMeetingsDoc['meetings'].splice(idx, 1);
                  // Since upload was successful, delete from offline meeting data id hashmap if exists
                  if (this.activityService.hasOfflineMeetingData(meetingResponse.activityId)) {
                    this.activityService.deleteFromOfflineMeetingIds(meetingResponse.activityId);
                    //console.log('----- t3) deleted ' + meetingResponse.activityId + ' from hashmap', this.activityService.offlineMeetingIds);
                  }
                } else {
                  console.warn(`uploadOfflineMeetings: ID ${meetingResponse.activityId} is not found from local db.`);
                }
              }

              meetingActivitySyncInfo.totalSynced++;
            } else {
              console.error(`uploadOfflineMeetings: 'activityId' is missing from the response: `, meetingResponse);
              this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, `'activityId' is missing from the response`);
              meetingActivitySyncInfo.totalFailed++;
            }
          } else {
            // Handle Contact & Account hard deletion from dynamics
            if ((meetingResponse['errorCode'] && meetingResponse['errorCode'] == 'ERR_IO_OME1')) {
              if (meetingResponse.hasOwnProperty('offlineMeetingId')) {
                const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.offlineMeetingId === meetingResponse.offlineMeetingId);
                if (idx >= 0) {
                  offlineMeetingsDoc['meetings'][idx].activityId = meetingResponse.offlineMeetingId;
                  offlineMeetingsDoc['meetings'][idx].activityid = meetingResponse.offlineMeetingId;
                  if (offlineMeetingsDoc['meetings'][idx].stateCode == 1) {
                    offlineMeetingsDoc['meetings'][idx].stateCode = 0;
                  }
                  if (meetingResponse.hasOwnProperty('errorMessage')) {
                    let meetingName: string = 'meeting';
                    if (offlineMeetingsDoc['meetings'][idx].contactAttendees && meetingResponse['errorMessage'].includes('Contact With Id')) {
                      // Mark the contact as Inactive in the offline meeting
                      offlineMeetingsDoc['meetings'][idx].contactAttendees.map(rawContact => {
                        if (meetingResponse['errorMessage'].includes(rawContact.indskr_contactid)) {
                          meetingName = 'meeting with ' + rawContact.indskr_name + '. ';
                          rawContact.statuscode = 1;
                        }
                      })
                    }
                    if (offlineMeetingsDoc['meetings'][idx].activityAccounts && meetingResponse['errorMessage'].includes('Account With Id')) {
                      // Remove the account from offline meeting
                      let index = offlineMeetingsDoc['meetings'][idx].activityAccounts.findIndex(account => (meetingResponse['errorMessage'].includes(account.indskr_accountid)));
                      if (index >= 0) {
                        offlineMeetingsDoc['meetings'][idx].activityAccounts.splice(index, 1);
                        meetingName = 'account in ' + offlineMeetingsDoc['meetings'][idx].subject + '. ';
                      }
                    }
                    const activity = (this.activityService.getActivityByID(meetingResponse.offlineMeetingId) as AppointmentActivity);
                    if (activity) {
                      if (activity.state == 1) { // Mark the completed meeting as open again
                        activity.state = 0;
                        activity.statusString = 'Open';
                        activity.status = 0;
                      }
                      // Mark the contact as Inactive in the contact service
                      if (activity.contacts && activity.contacts.length > 0) {
                        activity.contacts.forEach(contact => {
                          if (meetingResponse.hasOwnProperty('errorMessage')) {
                            if (meetingResponse['errorMessage'].includes(contact.ID)) {
                              contact.isActive = false;
                              if (this.contactService.getContactByID(contact.ID)) {
                                this.contactService.getContactByID(contact.ID).isActive = false;
                              }
                            }
                          }
                        });
                      }
                      // Remove the account from meeting
                      if (activity.accounts && activity.accounts.length > 0) {
                        let index = activity.accounts.findIndex(account => (meetingResponse['errorMessage'].includes(account.id)));
                        if (index >= 0) {
                          activity.accounts.splice(index, 1);
                        }
                      }
                    }
                    if (!this.uiService.toolsActivityActive){
                      let agendaFooterService = this.injector.get(AgendaFooterService)
                      if (agendaFooterService.actSegment === 'week') {
                        this.events.publish('weekview:RefreshUI');
                      } else {
                        this.events.publish('refreshAgenda');
                      }
                    } else {
                      this.uiService.agendaRefreshRequired = true;
                    }

                    this.activityNotificationDataModel = {
                      type: NOTIFICATION.DATA_UPLOAD_ERR_MEETING,
                      name: this.translate.instant("DATA_UPLOAD_ERR_MEETING", {errorCode: meetingResponse['errorCode']}),
                      DateTime: Date.now(),
                      id: NOTIFICATION.DATA_UPLOAD_ERR_MEETING + meetingResponse.offlineMeetingId,
                      data: meetingResponse,
                      icon: 'assets/imgs/appointment.svg',
                      isRed: false,
                      params: {errorCode: meetingResponse['errorCode']}
                    };
                    this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
                    // this.myAssistantService.addNewNotification(this.translate.instant('THERE_WAS_A_PROBLEM_WITH_REVIEW_MEETING', { text: meetingName }));
                  }
                }
              }
            }
          }
        }
      }
      this.nextCallObjectiveDataService.updateNCOForOfflineMeetings(response['offlineMeetings']);
      this.activityService.updateMeetingNoteInOfflineDBForOfflineMeeting(response['offlineMeetings']);
      this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo, true);
      await this.disk.updateOrInsert('offlineMeetings', doc => ({ meetings: offlineMeetingsDoc['meetings'] }));
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, offlineMeetingsDoc.meetings.length);
    }

    let pollService = this.injector.get(PollService);
    await pollService.submitOfflineData(isPartialUpload);

    if (uploadTimeOffs && response && response['offlineTimeOffs']) {
      await this.timeOffService.mapUploadedOfflineTots(response, offlineTimeOffs, timeOffSyncInfo, timeOffsToUpload.length, url, true);
    }

    if (uploadAllocations && response && response['offlineSampleDrops']) {
      // Loop through offlineMeetings response
      for (var i = 0; i < response['offlineSampleDrops'].length; i++) {
        let sampleActivity = response['offlineSampleDrops'][i];
        if (sampleActivity.hasOwnProperty('activityid')) {
          let responseForSampleOrder: any = sampleActivity;
          //const isOfflineCreatedMeeting = responseForSampleOrder.hasOwnProperty('offlineActivityId');
          let idx;
          offlineSamplesDoc['orders'].findIndex((a, index) => {
            if (a.activityid && a.activityid == responseForSampleOrder['activityid']) {
              idx = index;
            } else if (a.offlineActivityId && a.offlineActivityId == responseForSampleOrder['offlineActivityId']) {
              idx = index;
            }
          });
          if (!responseForSampleOrder['errorMessage']) {
            //if (isOfflineCreatedMeeting) {
            // Just to be safe, re-try 5 times
            for (let retries = 0; retries < 6; retries++) {
              if (idx >= 0) {
                const newSampleOrderDoc = offlineSamplesDoc['orders'][idx];
                let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
                newSampleOrderDoc['lastUpdatedTime'] = 0;
                try {
                  let isSelectedOnAgenda = (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                  // code for updating id in sampling to inmeeting activity mapping
                  if (responseForSampleOrder.offlineMeetingId && responseForSampleOrder.indskr_appointmentid && responseForSampleOrder.activityid) {
                    newSampleOrderDoc['indskr_appointmentid'] = responseForSampleOrder.indskr_appointmentid;
                    if (this.activityService.samplingToInMeetingActivityMapping.has(responseForSampleOrder.offlineMeetingId)) {
                      this.activityService.samplingToInMeetingActivityMapping.delete(responseForSampleOrder.offlineMeetingId);
                      this.activityService.samplingToInMeetingActivityMapping.set(responseForSampleOrder.indskr_appointmentid, responseForSampleOrder.activityid)
                    }
                  }
                  else if (responseForSampleOrder.indskr_appointmentid && this.activityService.samplingToInMeetingActivityMapping.has(responseForSampleOrder.indskr_appointmentid)) {
                    //this.activityService.samplingToInMeetingActivityMapping.delete(responseForSampleOrder.offlineMeetingId);
                    this.activityService.samplingToInMeetingActivityMapping.set(responseForSampleOrder.indskr_appointmentid, responseForSampleOrder.activityid)
                  }
                  // Update appointment activity id only if it was created offline
                  if (isSampleOrderDocCreatedOffline) {
                    let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                    if (activity) {
                      if ((activity as SampleActivity).getStatusString == 'Completed') {
                        // To update ID and subject in the completed allocation orders under customer allocation tool
                        for (let i = 0; i < this.activityService.sampleActivityMetasIndexedBySKU.length; i++) {
                          for (let j = 0; j < this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas.length; j++) {
                            if (this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].id == (activity as SampleActivity).ID) {
                              this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].id = responseForSampleOrder.activityid;
                              this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].subject = responseForSampleOrder.subject;
                              break;
                            }
                          }
                        }
                      }
                      activity.ID = responseForSampleOrder.activityid;
                      activity.subject = responseForSampleOrder.subject;
                      (activity as SampleActivity).appointmentID = responseForSampleOrder.indskr_appointmentid || '';
                      (activity as SampleActivity).orderId = responseForSampleOrder.indskr_orderid;
                      newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                      newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                    }
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                  }
                  offlineSamplesDoc['orders'].splice(idx, 1);
                  offlineSamplesDoc['count']--
                  this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                  if (isSelectedOnAgenda) {
                    if (!this.uiService.toolsActivityActive) {
                      this.events.publish("refreshAgenda");
                    } else {
                      this.uiService.agendaRefreshRequired = true;
                    }                    this.events.publish("selectedActivityChangedInBackround");
                  } else if (this.deviceService.isNativeApp) {
                    if (!this.uiService.toolsActivityActive) {
                      this.events.publish("refreshAgenda");
                    } else {
                      this.uiService.agendaRefreshRequired = true;
                    }
                  }
                  break;
                } catch (error) {
                  if (retries < 5) {
                    continue;
                  } else {
                    console.error('uploadOfflineSamples: error saving a new record..', error);
                  }
                }

              } else {
                console.warn(`uploadOfflineSamples: ID ${responseForSampleOrder.offlineActivityId} is not found from local db.`);
              }
            }
            sampleOrderSyncInfo.totalSynced++;
          } else {
            if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR02') {
              //For some reason linked Meeting failed, but Sample drop got created successfully.
              for (let retries = 0; retries < 6; retries++) {
                if (idx >= 0) {
                  const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                  let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                  newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
                  newSampleOrderDoc['lastUpdatedTime'] = 0;
                  try {
                    let isSelectedOnAgenda = (this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                    // code for updating id in sampling to inmeeting activity mapping
                    if (responseForSampleOrder.offlineMeetingId && responseForSampleOrder.activityid) {
                      newSampleOrderDoc['indskr_appointmentid'] = responseForSampleOrder.offlineMeetingId;
                      if (this.activityService.samplingToInMeetingActivityMapping.has(newSampleOrderDoc.offlineMeetingId)) {
                        this.activityService.samplingToInMeetingActivityMapping.delete(newSampleOrderDoc.offlineMeetingId);
                        this.activityService.samplingToInMeetingActivityMapping.set(newSampleOrderDoc.offlineMeetingId, responseForSampleOrder.activityid)
                      }
                    }
                    // Update appointment activity id only if it was created offline
                    if (isSampleOrderDocCreatedOffline) {
                      let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                      if (activity) {
                        activity.ID = responseForSampleOrder.activityid;
                        activity.subject = responseForSampleOrder.subject;
                        newSampleOrderDoc.activityid = responseForSampleOrder.activityid;
                        (activity as SampleActivity).orderId = responseForSampleOrder.indskr_orderid;
                      }
                      newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                      newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                    }
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                    offlineSamplesDoc['orders'].splice(idx, 1);
                    offlineSamplesDoc['orders'].push(newSampleOrderDoc)
                    this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                    this.activityService.addToOfflineSampleOrderIds(newSampleOrderDoc.activityid);
                    if (isSelectedOnAgenda) {

                      if (!this.uiService.toolsActivityActive) {
                        this.events.publish("refreshAgenda");
                      } else {
                        this.uiService.agendaRefreshRequired = true;
                      }
                      this.events.publish("selectedActivityChangedInBackround");
                    }
                    break;
                  } catch (error) {
                    if (retries < 5) {
                      continue;
                    } else {
                      console.error('uploadOfflineSamples: error saving a new record..', error);
                    }
                  }
                }
              }
            }
            else if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR04') {
              //For some reason linked allocation failed,
              for (let retries = 0; retries < 6; retries++) {
                if (idx >= 0) {
                  const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                  let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                  newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
                  newSampleOrderDoc['lastUpdatedTime'] = 0;
                  newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                  newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                  newSampleOrderDoc['statecode'] = 0;
                  newSampleOrderDoc['statuscode'] = 1;
                  try {
                    let isSelectedOnAgenda = (this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                    offlineSamplesDoc['orders'].splice(idx, 1);
                    offlineSamplesDoc['orders'].push(newSampleOrderDoc)
                    this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                    this.activityService.addToOfflineSampleOrderIds(newSampleOrderDoc.activityid);
                    this.notificationService.notify(this.translate.instant('ALLOWCATION_COULD_NOT_BE_COMPLETED'), 'Activity Data Service', 'top', '');
                    //if(isSelectedOnAgenda){
                    if (!this.uiService.toolsActivityActive)
                      this.events.publish("refreshAgenda");
                    else
                      this.uiService.agendaRefreshRequired = true;
                    this.events.publish("selectedActivityChangedInBackround");
                    //}
                    break;
                  } catch (error) {
                    if (retries < 5) {
                      continue;
                    } else {
                      console.error('uploadOfflineSamples: error saving a new record..', error);
                    }
                  }
                }
              }
            } else if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR05') {
              // handling hard deletion for sample allocation/ customer sample allocation
              for (let retries = 0; retries < 6; retries++) {
                if (idx >= 0) {
                  try {
                    const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                    let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                    // Reopen sample order if it is completed
                    newSampleOrderDoc['statecode'] = 0;
                    newSampleOrderDoc['statuscode'] = 1;
                    newSampleOrderDoc['indskr_signature'] = '';
                    if (responseForSampleOrder['entity'] == 'indskr_customersampleproduct' && responseForSampleOrder['entityId']) {
                      if (newSampleOrderDoc['activitySampleDrops']) {
                        newSampleOrderDoc['activitySampleDrops'].map(sample => {
                          if (sample.indskr_customersampleproductid == responseForSampleOrder['entityId']) {
                            sample['customersample_statecode'] = 1;
                          }
                        })
                      }
                    }
                    let meetingName: string = newSampleOrderDoc['subject'];
                    let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                    if (activity) {
                      activity.state = 0;
                      activity.status = 1;
                      (activity as SampleActivity).signature = '';
                      (activity as SampleActivity).statusString = 'Open';
                      if (responseForSampleOrder['entity'] == 'indskr_customersampleproduct' && responseForSampleOrder['entityId']) {
                        if ((activity as SampleActivity).samples) {
                          (activity as SampleActivity).samples.map(sample => {
                            if (sample.indskr_customersampleproductid == responseForSampleOrder['entityId']) {
                              sample.customersample_statecode = 1;
                              sample.isInvalid = true;
                            }
                          })
                        }
                      }
                    }
                    offlineSamplesDoc['orders'].splice(idx, 1);
                    offlineSamplesDoc['orders'].push(newSampleOrderDoc);
                    this.activityNotificationDataModel = {
                      type: NOTIFICATION.DATA_UPLOAD_ERR_ALLOCATION,
                      name: this.translate.instant("DATA_UPLOAD_ERR_ALLOCATION", {errorCode: responseForSampleOrder['errorCode']}),
                      DateTime: Date.now(),
                      id: NOTIFICATION.DATA_UPLOAD_ERR_ALLOCATION + responseForSampleOrder.offlineActivityId,
                      data: responseForSampleOrder,
                      icon: 'assets/imgs/sample_activity_agendaIcon.svg',
                      isRed: false,
                      params: {errorCode: responseForSampleOrder['errorCode']}
                    };
                    this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
                    // this.myAssistantService.addNewNotification('There was a problem with the ' + meetingName + ' Please review the allocation order');
                    //if(isSelectedOnAgenda){
                    if (!this.uiService.toolsActivityActive)
                      this.events.publish("refreshAgenda");
                    else
                      this.uiService.agendaRefreshRequired = true;
                    this.events.publish("selectedActivityChangedInBackround");
                    //}
                    break;
                  } catch (error) {
                    if (retries < 5) {
                      continue;
                    } else {
                      console.error('uploadOfflineSamples: error saving a new record..', error);
                    }
                  }
                }
              }
            } else {
              console.warn('uploadOfflineSamples: upload failed for the following meeting', responseForSampleOrder);
              //sampleOrderSyncInfo.errorMessage = '[sampleOrders][error]' + responseForSampleOrder.errorMessage ? responseForSampleOrder.errorMessage : '';
              this.deltaService.addSyncErrorToEntitySyncInfo(sampleOrderSyncInfo, url, responseForSampleOrder);
              sampleOrderSyncInfo.totalFailed++;
            }
          }
        }
      }

      this.deltaService.addEntitySyncInfo(sampleOrderSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineSamplesDoc);
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, offlineSamplesDoc.orders.length);
    }

    if (uploadPhoneCallMeetings && response && response['offlinePhonecalls']) {
      await this.handleOfflinePhonecallData(response, phoneCallActivitySyncInfo, offlinePhoneCallMeetingDocs, url);
    }
    if (uploadEmails && response && response['offlineEmails']) {
      await this.handleOfflineEmailData(response, emailSyncInfo, offlineEmailsDoc, url, emailPartiesMap);
    }
    if (uploadFollowUps && response && response['offlineFollowupTasks']) {
      await this.followUpActivityDataService.handleOfflinePushResponse(response['offlineFollowupTasks']);
    }
    if (uploadProgressReports && response && response['offlineProgressReports']) {
      await this.accountManagementOfflineService.handleOfflinePushResponse(response['offlineProgressReports']);
    }
    if (uploadConsents && response && response['offlineConsents']) {
      await this.consetnService.updateOfflineConsents(response, offlineConsents, consentSyncInfo);
    }
    if (uploadCases && response && response['offlineMedicalEnquiry']) {
      await this.caseManagementService.mapOfflineCases(response['offlineMedicalEnquiry'], caseInqiurySyncInfo, offlineCaseDocs, url, casesToUpload);
    }
    if (uploadSurgeryOrders && response && response['offlineSurgeryOrder']) {
      await this.surgeryOrderActivityDataService.handleSurgeryOrdersOfflinePushResponse(response['offlineSurgeryOrder']);
    }
  }

  public async uploadOfflineDataForOrders(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {

    const offlineOrderActivities = await this.orderActivityDataService.loadOfflineOrderActivities();

    let orderActivitiesToUpload = [];

    if (!offlineOrderActivities || !Array.isArray(offlineOrderActivities) || offlineOrderActivities.length === 0) {
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 0);
      return;
    } else {
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, offlineOrderActivities.length);
    }

    // Flag offline data upload
    this.disk.wasThereOfflineDataUpload = true;

    orderActivitiesToUpload = JSON.parse(JSON.stringify(offlineOrderActivities));
    if (isPartialUpload) {
      orderActivitiesToUpload = this._getSplicedOfflineRecords(orderActivitiesToUpload, maxRecordCountForPartialUpload);
    }

    const OrderActivitySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.order,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let response: any;
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_OFFLINE_DATA;
    const headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
    headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
    const body = {
      offlineOrders: orderActivitiesToUpload,
    };

    try {
      if (isPartialUpload) {
        response = await this.http.post(url, body, headers).pipe(timeout(150000)).toPromise();
      } else {
        response = await this.http.post(url, body, headers).toPromise();
      }
    } catch (httpError) {
      console.error('uploadOfflineActivities:', httpError);
      this.deltaService.addSyncErrorToEntitySyncInfo(OrderActivitySyncInfo, url, httpError);
      OrderActivitySyncInfo.totalFailed += orderActivitiesToUpload.length;
    }

    if (response && response['offlineOrders']) {
      await this.orderActivityDataService.handleOfflinePushResponse(response['offlineOrders']);
    }
  }

  private async handleOfflineMeetingUploadResponse(response, offlineMeetingsDoc, meetingActivitySyncInfo, url, doNotAddSyncInfoHere = false) {
    // Loop through offlineMeetings response
    for (const key in response['offlineMeetings']) {
      if (response['offlineMeetings'].hasOwnProperty(key)) {
        const meetingResponse = response['offlineMeetings'][key];

        if (!meetingResponse.hasOwnProperty('errorId')) {
          if (meetingResponse.hasOwnProperty('activityId')) {
            if (meetingResponse.hasOwnProperty('offlineMeetingId')) {
              const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.offlineMeetingId === meetingResponse.offlineMeetingId);
              if (idx >= 0) {
                if (offlineMeetingsDoc['meetings'][idx].activityid.includes('offline')) {
                  // It's an offline created meeting
                  const newMeetingDoc = offlineMeetingsDoc['meetings'][idx];
                  newMeetingDoc['activityid'] = meetingResponse.activityId;
                  if (newMeetingDoc.hasOwnProperty('activityId')) {
                    delete newMeetingDoc.activityId;
                  }
                  newMeetingDoc['lastUpdatedTime'] = 0;

                  // Just to be safe, re-try 5 times
                  for (let retries = 0; retries < 6; retries++) {
                    try {
                      // Create a new db record with guid
                      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_ACTIVITY + meetingResponse.activityId, doc => newMeetingDoc);

                      // Remove from offline db record
                      offlineMeetingsDoc['meetings'].splice(idx, 1);
                      // Since upload was successful, delete from offline meeting data id hashmap if exists
                      if (this.activityService.hasOfflineMeetingData(meetingResponse.offlineMeetingId)) {
                        this.activityService.deleteFromOfflineMeetingIds(meetingResponse.offlineMeetingId);
                        //console.log('----- t1) deleted ' + meetingResponse.offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                      }

                      // Update appointment activity id of the object
                      const activity = this.activityService.getActivityByID(meetingResponse.offlineMeetingId);
                      if (activity) {
                        activity.ID = meetingResponse.activityId;
                        if (this.activityService.selectedActivity && this.activityService.selectedActivity.ID === meetingResponse.offlineMeetingId) {
                          this.activityService.selectedActivity.ID = meetingResponse.activityId;
                        }
                        // this.disk.updateOrInsertActivityToActivityDetailRawDocument((activity as AppointmentActivity), true)
                      } else {
                        console.warn(`uploadOfflineMeetings: Couldn't find appointment object with ID ${meetingResponse.activityId}`);
                      }
                      break;
                    } catch (error) {
                      if (retries < 5) {
                        continue;
                      } else {
                        console.error('uploadOfflineMeetings: error saving a new record..', error);
                      }
                    }
                  }
                } else {
                  // Existing meeting
                  // Remove from offline db record
                  offlineMeetingsDoc['meetings'].splice(idx, 1);
                  // Since upload was successful, delete from offline meeting data id hashmap if exists
                  if (this.activityService.hasOfflineMeetingData(meetingResponse.activityId)) {
                    this.activityService.deleteFromOfflineMeetingIds(meetingResponse.activityId);
                    //console.log('----- t2) deleted ' + meetingResponse.offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                  }
                }
              } else {
                console.warn(`uploadOfflineMeetings: ID ${meetingResponse.offlineMeetingId} is not found from local db.`);
              }
            } else {
              const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.activityId === meetingResponse.activityId);
              if (idx >= 0) {
                // Should be legacy data without 'offlineMeetingId'
                // Remove from offline db record
                offlineMeetingsDoc['meetings'].splice(idx, 1);
                // Since upload was successful, delete from offline meeting data id hashmap if exists
                if (this.activityService.hasOfflineMeetingData(meetingResponse.activityId)) {
                  this.activityService.deleteFromOfflineMeetingIds(meetingResponse.activityId);
                  //console.log('----- t3) deleted ' + meetingResponse.activityId + ' from hashmap', this.activityService.offlineMeetingIds);
                }
              } else {
                console.warn(`uploadOfflineMeetings: ID ${meetingResponse.activityId} is not found from local db.`);
              }
            }

            meetingActivitySyncInfo.totalSynced++;
          } else {
            console.error(`uploadOfflineMeetings: 'activityId' is missing from the response: `, meetingResponse);
            this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivitySyncInfo, url, `'activityId' is missing from the response`);
            meetingActivitySyncInfo.totalFailed++;
          }
        } else {
          // Handle Contact & Account hard deletion from dynamics
          if ((meetingResponse['errorCode'] && meetingResponse['errorCode'] == 'ERR_IO_OME1')) {
            if (meetingResponse.hasOwnProperty('offlineMeetingId')) {
              const idx = offlineMeetingsDoc['meetings'].findIndex(a => a.offlineMeetingId === meetingResponse.offlineMeetingId);
              if (idx >= 0) {
                offlineMeetingsDoc['meetings'][idx].activityId = meetingResponse.offlineMeetingId;
                offlineMeetingsDoc['meetings'][idx].activityid = meetingResponse.offlineMeetingId;
                if (offlineMeetingsDoc['meetings'][idx].stateCode == 1) {
                  offlineMeetingsDoc['meetings'][idx].stateCode = 0;
                }
                if (meetingResponse.hasOwnProperty('errorMessage')) {
                  let meetingName: string = 'meeting';
                  if (offlineMeetingsDoc['meetings'][idx].contactAttendees && meetingResponse['errorMessage'].includes('Contact With Id')) {
                    // Mark the contact as Inactive in the offline meeting
                    offlineMeetingsDoc['meetings'][idx].contactAttendees.map(rawContact => {
                      if (meetingResponse['errorMessage'].includes(rawContact.indskr_contactid)) {
                        meetingName = 'meeting with ' + rawContact.indskr_name + '. ';
                        rawContact.statuscode = 1;
                      }
                    })
                  }
                  if (offlineMeetingsDoc['meetings'][idx].activityAccounts && meetingResponse['errorMessage'].includes('Account With Id')) {
                    // Remove the account from offline meeting
                    let index = offlineMeetingsDoc['meetings'][idx].activityAccounts.findIndex(account => (meetingResponse['errorMessage'].includes(account.indskr_accountid)));
                    if (index >= 0) {
                      offlineMeetingsDoc['meetings'][idx].activityAccounts.splice(index, 1);
                      meetingName = 'account in ' + offlineMeetingsDoc['meetings'][idx].subject + '. ';
                    }
                  }
                  const activity = (this.activityService.getActivityByID(meetingResponse.offlineMeetingId) as AppointmentActivity);
                  if (activity) {
                    if (activity.state == 1) { // Mark the completed meeting as open again
                      activity.state = 0;
                      activity.statusString = 'Open';
                      activity.status = 0;
                    }
                    // Mark the contact as Inactive in the contact service
                    if (activity.contacts && activity.contacts.length > 0) {
                      activity.contacts.forEach(contact => {
                        if (meetingResponse.hasOwnProperty('errorMessage')) {
                          if (meetingResponse['errorMessage'].includes(contact.ID)) {
                            contact.isActive = false;
                            if (this.contactService.getContactByID(contact.ID)) {
                              this.contactService.getContactByID(contact.ID).isActive = false;
                            }
                          }
                        }
                      });
                    }
                    // Remove the account from meeting
                    if (activity.accounts && activity.accounts.length > 0) {
                      let index = activity.accounts.findIndex(account => (meetingResponse['errorMessage'].includes(account.id)));
                      if (index >= 0) {
                        activity.accounts.splice(index, 1);
                      }
                    }
                  }
                  if (!this.uiService.toolsActivityActive){
                    let agendaFooterService = this.injector.get(AgendaFooterService)
                    if (agendaFooterService.actSegment === 'week') {
                      this.events.publish('weekview:RefreshUI');
                    } else {
                      this.events.publish('refreshAgenda');
                    }
                  } else {
                    this.uiService.agendaRefreshRequired = true;
                  }

                  this.activityNotificationDataModel = {
                    type: NOTIFICATION.DATA_UPLOAD_ERR_MEETING,
                    name: this.translate.instant("DATA_UPLOAD_ERR_MEETING", {errorCode: meetingResponse['errorCode']}),
                    DateTime: Date.now(),
                    id: NOTIFICATION.DATA_UPLOAD_ERR_MEETING + meetingResponse.offlineMeetingId,
                    data: meetingResponse,
                    icon: 'assets/imgs/appointment.svg',
                    isRed: false
                  };
                  this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
                  // this.myAssistantService.addNewNotification(this.translate.instant('THERE_WAS_A_PROBLEM_WITH_REVIEW_MEETING', { text: meetingName }));
                }
              }
            }
          }
        }
      }
    }
    this.nextCallObjectiveDataService.updateNCOForOfflineMeetings(response['offlineMeetings']);
    this.activityService.updateMeetingNoteInOfflineDBForOfflineMeeting(response['offlineMeetings']);
    if (!doNotAddSyncInfoHere) {
      this.deltaService.addEntitySyncInfo(meetingActivitySyncInfo, true);
    }
    await this.disk.updateOrInsert('offlineMeetings', doc => ({ meetings: offlineMeetingsDoc['meetings'] }));
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, offlineMeetingsDoc?.meetings?.length ?? 0);
  }

  private async handleOfflineAllocationOrderUploadResponse(response, offlineSamplesDoc, sampleOrderSyncInfo, url, doNotAddSyncInfoHere = false) {
    // Loop through offlineMeetings response
    for (var i = 0; i < response['offlineSampleDrops'].length; i++) {
      let sampleActivity = response['offlineSampleDrops'][i];
      if (sampleActivity.hasOwnProperty('activityid')) {
        let responseForSampleOrder: any = sampleActivity;
        //const isOfflineCreatedMeeting = responseForSampleOrder.hasOwnProperty('offlineActivityId');
        let idx;
        offlineSamplesDoc['orders'].findIndex((a, index) => {
          if (a.activityid && a.activityid == responseForSampleOrder['activityid']) {
            idx = index;
          } else if (a.offlineActivityId && a.offlineActivityId == responseForSampleOrder['offlineActivityId']) {
            idx = index;
          }
        });
        if (!responseForSampleOrder['errorMessage']) {
          //if (isOfflineCreatedMeeting) {
          // Just to be safe, re-try 5 times
          for (let retries = 0; retries < 6; retries++) {
            if (idx >= 0) {
              const newSampleOrderDoc = offlineSamplesDoc['orders'][idx];
              let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
              newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
              newSampleOrderDoc['lastUpdatedTime'] = 0;
              try {
                let isSelectedOnAgenda = (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                // code for updating id in sampling to inmeeting activity mapping
                if (responseForSampleOrder.offlineMeetingId && responseForSampleOrder.indskr_appointmentid && responseForSampleOrder.activityid) {
                  newSampleOrderDoc['indskr_appointmentid'] = responseForSampleOrder.indskr_appointmentid;
                  if (this.activityService.samplingToInMeetingActivityMapping.has(responseForSampleOrder.offlineMeetingId)) {
                    this.activityService.samplingToInMeetingActivityMapping.delete(responseForSampleOrder.offlineMeetingId);
                    this.activityService.samplingToInMeetingActivityMapping.set(responseForSampleOrder.indskr_appointmentid, responseForSampleOrder.activityid)
                  }
                }
                else if (responseForSampleOrder.indskr_appointmentid && this.activityService.samplingToInMeetingActivityMapping.has(responseForSampleOrder.indskr_appointmentid)) {
                  //this.activityService.samplingToInMeetingActivityMapping.delete(responseForSampleOrder.offlineMeetingId);
                  this.activityService.samplingToInMeetingActivityMapping.set(responseForSampleOrder.indskr_appointmentid, responseForSampleOrder.activityid)
                }
                // Update appointment activity id only if it was created offline
                if (isSampleOrderDocCreatedOffline) {
                  let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                  if (activity) {
                    if ((activity as SampleActivity).getStatusString == 'Completed') {
                      // To update ID and subject in the completed allocation orders under customer allocation tool
                      for (let i = 0; i < this.activityService.sampleActivityMetasIndexedBySKU.length; i++) {
                        for (let j = 0; j < this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas.length; j++) {
                          if (this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].id == (activity as SampleActivity).ID) {
                            this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].id = responseForSampleOrder.activityid;
                            this.activityService.sampleActivityMetasIndexedBySKU[i].sampleOrderActivityMetas[j].subject = responseForSampleOrder.subject;
                            break;
                          }
                        }
                      }
                    }
                    activity.ID = responseForSampleOrder.activityid;
                    activity.subject = responseForSampleOrder.subject;
                    (activity as SampleActivity).appointmentID = responseForSampleOrder.indskr_appointmentid || '';
                    (activity as SampleActivity).orderId = responseForSampleOrder.indskr_orderid;
                    newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                    newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                  }
                  await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                }
                offlineSamplesDoc['orders'].splice(idx, 1);
                offlineSamplesDoc['count']--
                this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                if (isSelectedOnAgenda) {
                  if (!this.uiService.toolsActivityActive) {
                    this.events.publish("refreshAgenda");
                  } else {
                    this.uiService.agendaRefreshRequired = true;
                  }                    this.events.publish("selectedActivityChangedInBackround");
                } else if (this.deviceService.isNativeApp) {
                  if (!this.uiService.toolsActivityActive) {
                    this.events.publish("refreshAgenda");
                  } else {
                    this.uiService.agendaRefreshRequired = true;
                  }
                }
                break;
              } catch (error) {
                if (retries < 5) {
                  continue;
                } else {
                  console.error('uploadOfflineSamples: error saving a new record..', error);
                }
              }

            } else {
              console.warn(`uploadOfflineSamples: ID ${responseForSampleOrder.offlineActivityId} is not found from local db.`);
            }
          }
          sampleOrderSyncInfo.totalSynced++;
        } else {
          if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR02') {
            //For some reason linked Meeting failed, but Sample drop got created successfully.
            for (let retries = 0; retries < 6; retries++) {
              if (idx >= 0) {
                const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
                newSampleOrderDoc['lastUpdatedTime'] = 0;
                try {
                  let isSelectedOnAgenda = (this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                  // code for updating id in sampling to inmeeting activity mapping
                  if (responseForSampleOrder.offlineMeetingId && responseForSampleOrder.activityid) {
                    newSampleOrderDoc['indskr_appointmentid'] = responseForSampleOrder.offlineMeetingId;
                    if (this.activityService.samplingToInMeetingActivityMapping.has(newSampleOrderDoc.offlineMeetingId)) {
                      this.activityService.samplingToInMeetingActivityMapping.delete(newSampleOrderDoc.offlineMeetingId);
                      this.activityService.samplingToInMeetingActivityMapping.set(newSampleOrderDoc.offlineMeetingId, responseForSampleOrder.activityid)
                    }
                  }
                  // Update appointment activity id only if it was created offline
                  if (isSampleOrderDocCreatedOffline) {
                    let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                    if (activity) {
                      activity.ID = responseForSampleOrder.activityid;
                      activity.subject = responseForSampleOrder.subject;
                      newSampleOrderDoc.activityid = responseForSampleOrder.activityid;
                      (activity as SampleActivity).orderId = responseForSampleOrder.indskr_orderid;
                    }
                    newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                    newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                  }
                  await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                  offlineSamplesDoc['orders'].splice(idx, 1);
                  offlineSamplesDoc['orders'].push(newSampleOrderDoc)
                  this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                  this.activityService.addToOfflineSampleOrderIds(newSampleOrderDoc.activityid);
                  if (isSelectedOnAgenda) {

                    if (!this.uiService.toolsActivityActive) {
                      this.events.publish("refreshAgenda");
                    } else {
                      this.uiService.agendaRefreshRequired = true;
                    }
                    this.events.publish("selectedActivityChangedInBackround");
                  }
                  break;
                } catch (error) {
                  if (retries < 5) {
                    continue;
                  } else {
                    console.error('uploadOfflineSamples: error saving a new record..', error);
                  }
                }
              }
            }
          }
          else if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR04') {
            //For some reason linked allocation failed,
            for (let retries = 0; retries < 6; retries++) {
              if (idx >= 0) {
                const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                newSampleOrderDoc['activityId'] = newSampleOrderDoc['activityid'] = responseForSampleOrder['activityid'];
                newSampleOrderDoc['lastUpdatedTime'] = 0;
                newSampleOrderDoc['subject'] = responseForSampleOrder.subject;
                newSampleOrderDoc['indskr_orderid'] = responseForSampleOrder.indskr_orderid;
                newSampleOrderDoc['statecode'] = 0;
                newSampleOrderDoc['statuscode'] = 1;
                try {
                  let isSelectedOnAgenda = (this.activityService.selectedActivity.ID == responseForSampleOrder.offlineActivityId) ? true : false;
                  await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseForSampleOrder['activityid'], doc => newSampleOrderDoc);
                  offlineSamplesDoc['orders'].splice(idx, 1);
                  offlineSamplesDoc['orders'].push(newSampleOrderDoc)
                  this.activityService.deleteFromOfflineSampleOrderIds(responseForSampleOrder.offlineActivityId);
                  this.activityService.addToOfflineSampleOrderIds(newSampleOrderDoc.activityid);
                  this.notificationService.notify(this.translate.instant('ALLOWCATION_COULD_NOT_BE_COMPLETED'), 'Activity Data Service', 'top', '');
                  //if(isSelectedOnAgenda){
                  if (!this.uiService.toolsActivityActive)
                    this.events.publish("refreshAgenda");
                  else
                    this.uiService.agendaRefreshRequired = true;
                  this.events.publish("selectedActivityChangedInBackround");
                  //}
                  break;
                } catch (error) {
                  if (retries < 5) {
                    continue;
                  } else {
                    console.error('uploadOfflineSamples: error saving a new record..', error);
                  }
                }
              }
            }
          } else if (responseForSampleOrder['errorCode'] == 'ERR_IO_SR05') {
            // handling hard deletion for sample allocation/ customer sample allocation
            for (let retries = 0; retries < 6; retries++) {
              if (idx >= 0) {
                try {
                  const newSampleOrderDoc = JSON.parse(JSON.stringify(offlineSamplesDoc['orders'][idx]));
                  let isSampleOrderDocCreatedOffline: boolean = (newSampleOrderDoc && newSampleOrderDoc['activityid']) ? false : true;
                  // Reopen sample order if it is completed
                  newSampleOrderDoc['statecode'] = 0;
                  newSampleOrderDoc['statuscode'] = 1;
                  newSampleOrderDoc['indskr_signature'] = '';
                  if (responseForSampleOrder['entity'] == 'indskr_customersampleproduct' && responseForSampleOrder['entityId']) {
                    if (newSampleOrderDoc['activitySampleDrops']) {
                      newSampleOrderDoc['activitySampleDrops'].map(sample => {
                        if (sample.indskr_customersampleproductid == responseForSampleOrder['entityId']) {
                          sample['customersample_statecode'] = 1;
                        }
                      })
                    }
                  }
                  let meetingName: string = newSampleOrderDoc['subject'];
                  let activity = this.activityService.getActivityByID(responseForSampleOrder.offlineActivityId);
                  if (activity) {
                    activity.state = 0;
                    activity.status = 1;
                    (activity as SampleActivity).signature = '';
                    (activity as SampleActivity).statusString = 'Open';
                    if (responseForSampleOrder['entity'] == 'indskr_customersampleproduct' && responseForSampleOrder['entityId']) {
                      if ((activity as SampleActivity).samples) {
                        (activity as SampleActivity).samples.map(sample => {
                          if (sample.indskr_customersampleproductid == responseForSampleOrder['entityId']) {
                            sample.customersample_statecode = 1;
                            sample.isInvalid = true;
                          }
                        })
                      }
                    }
                  }
                  offlineSamplesDoc['orders'].splice(idx, 1);
                  offlineSamplesDoc['orders'].push(newSampleOrderDoc);
                  this.activityNotificationDataModel = {
                    type: NOTIFICATION.DATA_UPLOAD_ERR_ALLOCATION,
                    name: this.translate.instant("DATA_UPLOAD_ERR_ALLOCATION", {errorCode: responseForSampleOrder['errorCode']}),
                    DateTime: Date.now(),
                    id: NOTIFICATION.DATA_UPLOAD_ERR_ALLOCATION + responseForSampleOrder.offlineActivityId,
                    data: responseForSampleOrder,
                    icon: 'assets/imgs/sample_activity_agendaIcon.svg',
                    isRed: false
                  };
                  this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
                  // this.myAssistantService.addNewNotification('There was a problem with the ' + meetingName + ' Please review the allocation order');
                  //if(isSelectedOnAgenda){
                  if (!this.uiService.toolsActivityActive)
                    this.events.publish("refreshAgenda");
                  else
                    this.uiService.agendaRefreshRequired = true;
                  this.events.publish("selectedActivityChangedInBackround");
                  //}
                  break;
                } catch (error) {
                  if (retries < 5) {
                    continue;
                  } else {
                    console.error('uploadOfflineSamples: error saving a new record..', error);
                  }
                }
              }
            }
          } else {
            console.warn('uploadOfflineSamples: upload failed for the following meeting', responseForSampleOrder);
            //sampleOrderSyncInfo.errorMessage = '[sampleOrders][error]' + responseForSampleOrder.errorMessage ? responseForSampleOrder.errorMessage : '';
            this.deltaService.addSyncErrorToEntitySyncInfo(sampleOrderSyncInfo, url, responseForSampleOrder);
            sampleOrderSyncInfo.totalFailed++;
          }
        }
      }
    }

    if (!doNotAddSyncInfoHere) {
      this.deltaService.addEntitySyncInfo(sampleOrderSyncInfo, true);
    }
    await this.disk.updateDocWithIdAndRev(offlineSamplesDoc);
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, offlineSamplesDoc?.orders?.length ?? 0);
  }

  private async handleOfflinePhonecallData(response: any, phoneCallActivitySyncInfo: EntitySyncInfo, offlinePhoneCallMeetingDocs, url: string, doNotAddSyncInfoHere = false) {

    if (response && response['offlinePhonecalls']) {
      // Loop through offlinePhonecalls response
      for (const key in response['offlinePhonecalls']) {
        if (response['offlinePhonecalls'].hasOwnProperty(key)) {
          const phoneCallObject = response['offlinePhonecalls'][key];

          if (!phoneCallObject.hasOwnProperty('errorId')) {
            if (phoneCallObject.hasOwnProperty('activityid')) {
              if (phoneCallObject.hasOwnProperty('offlineId')) {
                const idx = offlinePhoneCallMeetingDocs['meetings'].findIndex(a => a.offlineId === phoneCallObject["offlineId"]);
                if (idx >= 0) {
                  if (offlinePhoneCallMeetingDocs['meetings'][idx].activityid.includes('offline')) {
                    // It's an offline created meeting
                    const newMeetingDoc = offlinePhoneCallMeetingDocs['meetings'][idx];
                    newMeetingDoc['activityid'] = phoneCallObject["activityid"];
                    if (newMeetingDoc.hasOwnProperty('activityid')) {
                      delete newMeetingDoc.activityId;
                    }
                    newMeetingDoc['lastUpdatedTime'] = 0;
                    // Just to be safe, re-try 5 times
                    for (let retries = 0; retries < 6; retries++) {
                      try {
                        // Create a new db record with guid
                        this.disk.updateOrInsert(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + phoneCallObject["activityid"], doc => newMeetingDoc);

                        // Remove from offline db record
                        offlinePhoneCallMeetingDocs['meetings'].splice(idx, 1);
                        // Since upload was successful, delete from offline meeting data id hashmap if exists
                        if (this.activityService.hasOfflinePhoneCallData(phoneCallObject["offlineId"])) {
                          this.activityService.deleteFromOfflinePhoneCallIds(phoneCallObject["offlineId"]);
                          //console.log('----- t1) deleted ' + meetingResponse.offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                        }

                        // Update appointment activity id of the object
                        const activity = this.activityService.getActivityByID(phoneCallObject["offlineId"]);
                        if (activity) {
                          activity.ID = phoneCallObject["activityid"];
                          this.disk.updateOrInsertActivityToActivityDetailRawDocument((activity as PhoneActivity), true)
                        } else {
                          console.warn(`uploadOfflineMeetings: Couldn't find appointment object with ID ${phoneCallObject["activityid"]}`);
                        }
                        break;
                      } catch (error) {
                        if (retries < 5) {
                          continue;
                        } else {
                          console.error('uploadOfflineMeetings: error saving a new record..', error);
                        }
                      }
                    }
                  } else {
                    // Existing meeting
                    // Remove from offline db record
                    offlinePhoneCallMeetingDocs['meetings'].splice(idx, 1);
                    // Since upload was successful, delete from offline meeting data id hashmap if exists
                    if (this.activityService.hasOfflinePhoneCallData(phoneCallObject["activityid"])) {
                      this.activityService.deleteFromOfflinePhoneCallIds(phoneCallObject["activityid"]);
                      //console.log('----- t2) deleted ' + phoneCallObject["offlineMeetingId + ' from hashmap', this.activityService.offlineMeetingIds);
                    }
                  }
                } else {
                  console.warn(`uploadOfflineMeetings: ID ${phoneCallObject["offlineId"]} is not found from local db.`);
                }
              } else {
                const idx = offlinePhoneCallMeetingDocs['meetings'].findIndex(a => a.activityId === phoneCallObject["activityid"]);
                if (idx >= 0) {
                  // Should be legacy data without 'offlineMeetingId'
                  // Remove from offline db record
                  offlinePhoneCallMeetingDocs['meetings'].splice(idx, 1);
                  // Since upload was successful, delete from offline meeting data id hashmap if exists
                  if (this.activityService.hasOfflinePhoneCallData(phoneCallObject["activityid"])) {
                    this.activityService.deleteFromOfflinePhoneCallIds(phoneCallObject["activityid"]);
                    //console.log('----- t3) deleted ' + phoneCallObject["activityid + ' from hashmap', this.activityService.offlineMeetingIds);
                  }
                } else {
                  console.warn(`uploadOfflineMeetings: ID ${phoneCallObject["activityid"]} is not found from local db.`);
                }
              }
              phoneCallActivitySyncInfo.totalSynced++;
            } else {
              console.error(`uploadOfflineMeetings: 'activityId' is missing from the response: `, phoneCallObject);
              this.deltaService.addSyncErrorToEntitySyncInfo(phoneCallActivitySyncInfo, url, `'activityId' is missing from the response`);
              phoneCallActivitySyncInfo.totalFailed++;
            }
          } else {
            // Handle Contact & Account hard deletion from dynamics
            if ((phoneCallObject['errorCode'] && phoneCallObject['errorCode'] == 'ERR_IO_OME1')) {
              if (phoneCallObject.hasOwnProperty('offlineId')) {
                const idx = offlinePhoneCallMeetingDocs['meetings'].findIndex(a => a.offlineMeetingId === phoneCallObject["offlineId"]);
                if (idx >= 0) {
                  offlinePhoneCallMeetingDocs['meetings'][idx].activityId = phoneCallObject["offlineId"];
                  offlinePhoneCallMeetingDocs['meetings'][idx].activityid = phoneCallObject["offlineId"];
                  if (offlinePhoneCallMeetingDocs['meetings'][idx].stateCode == 1) {
                    offlinePhoneCallMeetingDocs['meetings'][idx].stateCode = 0;
                  }
                  if (phoneCallObject.hasOwnProperty('errorMessage')) {
                    let meetingName: string = 'meeting';
                    if (offlinePhoneCallMeetingDocs['meetings'][idx].contactAttendees && phoneCallObject['errorMessage'].includes('Contact With Id')) {
                      // Mark the contact as Inactive in the offline meeting
                      offlinePhoneCallMeetingDocs['meetings'][idx].contactAttendees.map(rawContact => {
                        if (phoneCallObject['errorMessage'].includes(rawContact.indskr_contactid)) {
                          meetingName = 'meeting with ' + rawContact.indskr_name + '. ';
                          rawContact.statuscode = 1;
                        }
                      })
                    }
                    if (offlinePhoneCallMeetingDocs['meetings'][idx].activityAccounts && phoneCallObject['errorMessage'].includes('Account With Id')) {
                      // Remove the account from offline meeting
                      let index = offlinePhoneCallMeetingDocs['meetings'][idx].activityAccounts.findIndex(account => (phoneCallObject['errorMessage'].includes(account.indskr_accountid)));
                      if (index >= 0) {
                        offlinePhoneCallMeetingDocs['meetings'][idx].activityAccounts.splice(index, 1);
                        meetingName = 'account in ' + offlinePhoneCallMeetingDocs['meetings'][idx].subject + '. ';
                      }
                    }
                    const activity = (this.activityService.getActivityByID(phoneCallObject["offlineId"]) as PhoneActivity);
                    if (activity) {
                      if (activity.state == 1) { // Mark the completed meeting as open again
                        activity.state = 0;
                        activity.statusString = 'Open';
                        activity.status = 0;
                      }
                      // Mark the contact as Inactive in the contact service
                      if (activity.contacts && activity.contacts.length > 0) {
                        activity.contacts.forEach(contact => {
                          if (phoneCallObject.hasOwnProperty('errorMessage')) {
                            if (phoneCallObject['errorMessage'].includes(contact.ID)) {
                              contact.isActive = false;
                              if (this.contactService.getContactByID(contact.ID)) {
                                this.contactService.getContactByID(contact.ID).isActive = false;
                              }
                            }
                          }
                        });
                      }
                      // Remove the account from meeting
                      if (activity.accounts && activity.accounts.length > 0) {
                        let index = activity.accounts.findIndex(account => (phoneCallObject['errorMessage'].includes(account.id)));
                        if (index >= 0) {
                          activity.accounts.splice(index, 1);
                        }
                      }
                    }
                    if (!this.uiService.toolsActivityActive)
                    this.events.publish('refreshAgenda');
                    else this.uiService.agendaRefreshRequired  = true;
                    this.activityNotificationDataModel = {
                      type: NOTIFICATION.DATA_UPLOAD_ERR_PHONECALL,
                      name: this.translate.instant("DATA_UPLOAD_ERR_PHONECALL", {errorCode: phoneCallObject['errorCode']}),
                      DateTime: Date.now(),
                      id: NOTIFICATION.DATA_UPLOAD_ERR_PHONECALL + Date.now(),
                      data: phoneCallObject,
                      icon: 'assets/imgs/phone-call-activity.svg',
                      isRed: false,
                      params: {errorCode: phoneCallObject['errorCode']}
                    };
                    this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
                    // this.myAssistantService.addNewNotification(this.translate.instant('THERE_WAS_A_PROBLEM_WITH_REVIEW_MEETING', { text: meetingName }));
                  }
                }
              }
            }
          }
        }
      }
    }
    if (!doNotAddSyncInfoHere) {
      this.deltaService.addEntitySyncInfo(phoneCallActivitySyncInfo, true);
    }
    await this.disk.updateDocWithIdAndRev(offlinePhoneCallMeetingDocs);
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PHONECALL_MEETING, offlinePhoneCallMeetingDocs?.meetings?.length ?? 0);
  }

  private async handleOfflineEmailData(response: any, emailSyncInfo: EntitySyncInfo, offlineEmailsDoc, url: string, emailPartiesMap, doNotAddSyncInfoHere = false) {
    // Loop through offlineMeetings response
    for (var i = 0; i < response['offlineEmails'].length; i++) {
      let emailActivity = response['offlineEmails'][i];
      if (emailActivity.hasOwnProperty('activityid')) {
        let responseForEmail: any = emailActivity;
        let idx;
        offlineEmailsDoc['emails'].findIndex((a, index) => {
          if (a.activityid && a.activityid == responseForEmail['activityid']) {
            idx = index;
          } else if (a.offlineActivityId && a.offlineActivityId == responseForEmail['offlineActivityId']) {
            idx = index;
          }
        });
        if (!responseForEmail['errorMessage']) {
          // Just to be safe, re-try 5 times
          for (let retries = 0; retries < 6; retries++) {
            if (idx >= 0) {
              const newEmailDoc = offlineEmailsDoc['emails'][idx];
              let isEmailCreatedOffline: boolean = (newEmailDoc && newEmailDoc['activityid']) ? false : true;
              newEmailDoc['activityid'] = responseForEmail['activityid'];
              newEmailDoc['lastUpdatedTime'] = 0;
              responseForEmail['statuscode'] = newEmailDoc['statuscode'];
              responseForEmail['status'] = newEmailDoc['statuscode'];
              responseForEmail['activitytypecode'] = 'email';
              responseForEmail['emailActivityParties'] = emailPartiesMap[responseForEmail['offlineActivityId']];
              responseForEmail['emailAttachments'] = newEmailDoc['emailAttachments'];
              responseForEmail['subject'] = newEmailDoc['subject'];
              responseForEmail['scheduledstart'] = newEmailDoc['scheduledstart'];
              responseForEmail['scheduledend'] = newEmailDoc['scheduledend'];
              responseForEmail['template_id'] = newEmailDoc['template_id'];
              responseForEmail['indskr_channel'] = newEmailDoc['indskr_channelid'];
              responseForEmail['channelActivityType'] = newEmailDoc['channelActivityType'];
              responseForEmail['channelType'] = newEmailDoc['channelType'];
              responseForEmail['description'] = newEmailDoc['description'];
              responseForEmail['indskr_emailnotes'] = newEmailDoc['indskr_emailnotes'];
              responseForEmail['product_id'] = newEmailDoc['product_id'];
              responseForEmail['statecode'] = newEmailDoc['statecode'];

              try {
                let isSelectedOnAgenda = (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == responseForEmail.offlineActivityId) ? true : false;
                // code for updating id in email to in meeting activity mapping

                // Update appointment activity id only if it was created offline
                if (isEmailCreatedOffline) {
                  let activity = this.activityService.getActivityByID(responseForEmail.offlineActivityId);
                  if (activity) {
                    activity.ID = responseForEmail.activityid;
                    (activity as EmailActivity).activityid = activity.ID;
                    responseForEmail['indskr_ownerid'] = (activity as EmailActivity).ownerId;
                  } else {
                    // Assuming that the new offline email activity creation will be done locally only
                    responseForEmail['indskr_ownerid'] = this.authenticationService.user.systemUserID;
                  }
                  await this.disk.updateOrInsert(DB_KEY_PREFIXES.EMAIL_ACTIVITY + responseForEmail['activityid'], doc => responseForEmail);
                }
                offlineEmailsDoc['emails'].splice(idx, 1);
                offlineEmailsDoc['count']--
                this.activityService.deleteFromOfflineEmailIds(responseForEmail.offlineActivityId);
                if (isSelectedOnAgenda) {
                  this.emailService.setCurrentEmail(this.activityService.selectedActivity);
                }
                break;
              } catch (error) {
                if (retries < 5) {
                  continue;
                } else {
                  console.error('uploadOfflineEmails: error saving a new record..', error);
                }
              }

            } else {
              console.warn(`uploadOfflineEmails: ID ${responseForEmail.offlineActivityId} is not found from local db.`);
            }
          }
          emailSyncInfo.totalSynced++;
        } else {
          if (responseForEmail['errorCode'] && responseForEmail['errorCode'] == 'ERR_IO_OEA1') {
            if (responseForEmail.hasOwnProperty('offlineActivityId')) {
              const idx = offlineEmailsDoc['emails'].findIndex(a => a.offlineActivityId === responseForEmail.offlineActivityId);
              if (idx >= 0) {
                offlineEmailsDoc['emails'][idx].activityid = responseForEmail.offlineActivityId;
                if (offlineEmailsDoc['emails'][idx].statuscode != 1) {
                  offlineEmailsDoc['emails'][idx].statuscode = 1;
                }
                if (responseForEmail.hasOwnProperty('errorMessage')) {
                  let errorMessage = '';
                  //remove deleted entity from request
                  this.updateOfflineEmailDocToRemoveDeletedEntity(offlineEmailsDoc, idx, responseForEmail, errorMessage);

                  const activity = (this.activityService.getActivityByOfflineID(responseForEmail.offlineActivityId) as EmailActivity);
                  if (activity) {
                    if (activity.status == 1) { // Mark the completed email as open again
                      activity.statusString = 'Draft';
                      activity.status = 1;
                    }
                    // Remove the resource from email
                    if (activity.emailAttachments && activity.emailAttachments.length > 0) {
                      let index = activity.emailAttachments.findIndex(emailAttachment =>
                        (responseForEmail['errorMessage'].includes(emailAttachment.indskr_resourceid)));
                      if (index >= 0) {
                        activity.emailAttachments.splice(index, 1);
                      }
                    }

                    else if (offlineEmailsDoc['emails'][idx].template_id && responseForEmail['errorMessage'].includes('indskr_emailtemplate With Id')) {
                      //Remove template from email : 1. update template id to null, 2. update description to null, 3.Update info to New Email
                      offlineEmailsDoc['emails'][idx].template_id = null;
                      offlineEmailsDoc['emails'][idx].product_id = null;
                      offlineEmailsDoc['emails'][idx].description = "";
                      offlineEmailsDoc['emails'][idx].subject = "New Message";
                      activity.template_id = null;
                      activity.description = "";
                      activity.info = "";
                      activity.subject = "New Message";
                      errorMessage = "template With Id = " + responseForEmail['errorMessage'].slice(responseForEmail['errorMessage'].indexOf("Id = "), responseForEmail['errorMessage'].indexOf(" Does Not Exist"))
                    }
                  }
                  if (!this.uiService.toolsActivityActive)
                  this.events.publish('refreshAgenda');
                  else this.uiService.agendaRefreshRequired = true;
                }
              }
            }
          }
        }
      }
      if (emailActivity['errorCode'] && emailActivity['errorCode'] == 'ERR_IO_OEA1') {
        this.activityNotificationDataModel = {
          type: NOTIFICATION.DATA_UPLOAD_ERR_MESSAGE,
          name: this.translate.instant("DATA_UPLOAD_ERR_MESSAGE", { errorCode: emailActivity['errorCode'] }),
          DateTime: Date.now(),
          id: NOTIFICATION.DATA_UPLOAD_ERR_MESSAGE + emailActivity.offlineActivityId,
          data: emailActivity,
          icon: 'assets/imgs/email.svg',
          isRed: false,
          params: { errorCode: emailActivity['errorCode'] }
        };
        this.myAssistantService.saveNotificationToDisk(this.activityNotificationDataModel);
      }
    }
    if (!doNotAddSyncInfoHere) {
      this.deltaService.addEntitySyncInfo(emailSyncInfo, true);
    }
    await this.disk.updateDocWithIdAndRev(offlineEmailsDoc);
    // Track offline data count
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, offlineEmailsDoc?.emails?.length ?? 0);
  }

  private updateOfflineEmailDocToRemoveDeletedEntity(offlineEmailsDoc: any, idx: any, responseForEmail: any, errorMessage: string) {
    if (offlineEmailsDoc['emails'][idx].emailAttachments && responseForEmail['errorMessage'].includes('indskr_ioresource With Id')
      || offlineEmailsDoc['emails'][idx].emailAttachments && responseForEmail['errorMessage'].includes('indskr_iodocument With Id')) {
      // Mark the resource/document as Inactive in the offline email
      let emailAttachmentIndex = offlineEmailsDoc['emails'][idx].emailAttachments.findIndex(rawAttachment => {
        (responseForEmail['errorMessage'].includes(rawAttachment.indskr_resourceid) ||
          responseForEmail['errorMessage'].includes(rawAttachment.indskr_iodocumentid));
      });
      if (emailAttachmentIndex >= 0) {
        errorMessage = "Resources With Id = " + responseForEmail['errorMessage'].slice(responseForEmail['errorMessage'].indexOf("Id = "), responseForEmail['errorMessage'].indexOf(" Does Not Exist"))
        offlineEmailsDoc['emails'][idx].emailAttachments.splice(emailAttachmentIndex, 1);
      }
    }
    else if (offlineEmailsDoc['emails'][idx].emailAddresses && responseForEmail['errorMessage'].includes('indskr_email_address With Id')) {
      let emailAddressIndex = offlineEmailsDoc['emails'][idx].emailAddresses.findIndex(rawEmailAddress => {
        (responseForEmail['errorMessage'].includes(rawEmailAddress));
      });

      if (emailAddressIndex >= 0) {
        errorMessage = "Email address With Id = " + responseForEmail['errorMessage'].slice(responseForEmail['errorMessage'].indexOf("Id = "), responseForEmail['errorMessage'].indexOf(" Does Not Exist"))
        offlineEmailsDoc['emails'][idx].emailAddresses.splice(emailAddressIndex, 1);
      }
    }
  }


  async getUserData(didOfflineDataUploadFail: boolean) {
    const userSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.user,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    try {
      await this.authenticationDataService.getUser(false, true, didOfflineDataUploadFail);
    } catch (error) {
      console.error('getUserData: ', error);
      this.deltaService.addSyncErrorToEntitySyncInfo(userSyncInfo, this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.authentication.GET_USER, error);
    }

    this.deltaService.addEntitySyncInfo(userSyncInfo);
  }

  /**
   *
   * @param activity
   */
  public async getRealTimeActivityDetails(activity: Activity) {
    if (activity) {
      let type: string;
      switch (activity.type) {
        case ActivityType.StoreCheck:
        case ActivityType.Appointment:
          type = "appointment";
          break;
        case ActivityType.Email:
          type = "email";
          break;
        case ActivityType.PhoneCall:
          type = "phonecall";
          break;
        case ActivityType.TimeOff:
        case ActivityType.TimeOffRequest:
          type = "tot";
          break;
        case ActivityType.Sample:
          type = "sampledrop";
          break;
        case ActivityType.FollowUp:
          type = "followuptask";
          break;
        case ActivityType.CaseIntake:
          type = 'CaseIntake';
          break;
        case ActivityType.Order:
          type = "order";
          break;
        case ActivityType.SurgeryOrder:
          type = "surgeryorder";
          break;
        case ActivityType.ProcedureTracker:
          type = "proceduretracker";
          break;
      }

      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;
      url = url.replace("{activity_id}", activity.ID);
      url = url.replace("{last_updated_time}", '');
      url = url.replace("{activity_type}", type);

      if ((type === 'appointment' || type === 'surgeryorder') && this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) {
        url = url + '&featureFlags=marketingbusinessplan';
      } else if (type === 'followuptask') {
        url = this.addFollowUpFeatureFlags(url);
      }

      let configFeilds: string[] = []
      if (type === 'appointment' && activity.type != ActivityType.StoreCheck) {
        if (this.authenticationService.user.appointmentConfiguredFields.length > 0 && this.authenticationService.user.appointmentConfiguredFields != undefined) {
          configFeilds = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
            return obj.fieldName
          })
        }
      }

      if (configFeilds.length > 0) {
        url = url.replace('{{configFields}}', configFeilds.toString());
      } else {
        url = url.replace('&configFields={{configFields}}', configFeilds.toString());
      }

      if (activity.isFromXperiences) {
        url += '&aggregateEmails=false'
      }
      let headers = Endpoints.headers.content_type.json;
      headers.headers = headers.headers.set(
        'No-Retry', 'true'
      );
      if (type == 'order' || type == 'surgeryorder' || type == 'proceduretracker') {
        headers.headers = headers.headers.set(
          'X-Feature-Id', 'Fetch_Activity_Appointment'
        );
      }

      const isMarketingPlanEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);

      if ((type === 'appointment' || type === 'surgeryorder') && isMarketingPlanEnabled) {
        url = url + '&featureFlags=marketingbusinessplan';
      } else if (type === 'followuptask') {
        url = this.addFollowUpFeatureFlags(url);
      }

      const fetchCancelledSurgeryOrders = this.authenticationService.user.buSettings &&
        this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar'];

      const fetchCancelledMeetings = this.authenticationService.user.buSettings &&
        this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'] ? true : false;
      const fetchShoreiMasterSurgeryOrders = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_displayshorei'];
      const isAssetBookingEnabled = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_assetavailabilitylogic'] == 548910001;
      let flags = [];

      if (fetchCancelledSurgeryOrders) flags.push('fetchCancelOrders');
      if (fetchCancelledMeetings && type === 'appointment') flags.push('fetchCancelMeetings');
      // if(marketingBusinessPlanEnabled) flags.push('marketingbusinessplan');
      if (fetchShoreiMasterSurgeryOrders) flags.push('displayShorei');
      if (isAssetBookingEnabled) flags.push('procedureLogAssetBooking');
      if (flags && flags.length > 0) {
        url = url.concat(`&featureFlags=${flags.join(',')}`);
      }


      let rawActivity: any;
      try {
        rawActivity = await this.http.get(url, headers).toPromise();
      } catch (error) {
        console.log(error);
      }
      if (rawActivity) {
        switch (activity.type) {
          case ActivityType.StoreCheck:
            const respActivity = new StoreCheckActivity(rawActivity);
            await this._appendStoreCheckDetailsToActivity(respActivity, rawActivity);
            return respActivity;
          case ActivityType.Appointment: {
            type = "appointment";

            // Need to copy runtime variables
            const replacingActivity = new AppointmentActivity(rawActivity);
            this.activityService.copyRuntimeActivityVariables(activity, replacingActivity);

            await this._appendMeetingDetailsToActivity(replacingActivity, rawActivity);
            //TODO: await this.updateActivityForConfigField(activity, rawActivity, activity.type); - Hemang
            return replacingActivity;
          }
          case ActivityType.PhoneCall: {
            const conflictingActivityIds = activity.conflictingActivityIds;
            let newActivity = new PhoneActivity(rawActivity);
            newActivity.conflictingActivityIds = conflictingActivityIds;
            await this._appendPhoneCallDetailsToActivity(newActivity, rawActivity);
            //TODO: await this.updateActivityForConfigField(activity, rawActivity, activity.type); - Hemang
            return newActivity;
          }
          case ActivityType.Email:
            let email: EmailActivity = new EmailActivity(rawActivity);
            if (this.consentService.allConsentChannelSubject.value && rawActivity['indskr_channel']) {
              const channel = this.consentService.allConsentChannelSubject.value.find(ch => ch.indskr_consenttypeid === rawActivity['indskr_channel']).indskr_consentType.toString();
              email.channelType = ChannelType[channel.toUpperCase()];
            }
            const socialActivity = ([ChannelType.SMS, ChannelType.FACEBOOK, ChannelType.WHATSAPP].indexOf(email.channelType)) >= 0;
            if (socialActivity) {
              let emailActivityParties = await this.dynamics.executeFetchQuery('activityparties', fetchXmlEntity({
                "@": { "name": "activityparty" },
                "attribute": [
                  { "@": { "name": "partyid", "alias": "email_addressid" } },
                  { "@": { "name": "activityid", "alias": "emailActivityId" } },
                  { "@": { "name": "addressused", "alias": "emailAddress" } }
                ],
                "filter": [
                  {
                    "@": { "type": "and" },
                    "condition": [
                      { "@": { "attribute": "participationtypemask", "operator": "eq", "value": "2" } },
                      { "@": { "attribute": "activityid", "operator": "eq", "value": activity['parentemailid'] || activity.ID } }
                    ]
                  }
                ],
                "link-entity": [
                  {
                    "@": { "name": "contact", "from": "contactid", "to": "partyid" },
                    "attribute": [
                      { "@": { "name": "contactid", "alias": "indskr_contactid" } },
                      { "@": { "name": "firstname", "alias": "contact_firstname" } },
                      { "@": { "name": "lastname", "alias": "contact_lastname" } },
                      { "@": { "name": "mobilephone", alias: 'contact_mobilephone' } },
                      { "@": { "name": "indskr_facebookpsid", alias: 'contact_indskr_facebookpsid' } },
                      { "@": { "name": "statuscode", "alias": "contact_statuscode" } }
                    ]
                  },
                ]
              }), null);
              email.emailActivityParties = emailActivityParties.map(p => ({ emailAddresses: [], ...p }));
            }
            return email;
          case ActivityType.Order:
            if (!rawActivity['activitytypecode']) {
              rawActivity['activitytypecode'] = 'indskr_order';
            }
            //let order = new OrderActivity(rawActivity);
            this._appendOrderDetailsToActivity(activity as OrderActivity,rawActivity);
            if ((activity as OrderActivity).ownerId != this.authenticationService.user.systemUserID) {
              (activity as OrderActivity).isTeamOrder = true;
            }
            return activity as OrderActivity;
          case ActivityType.SurgeryOrder:
            if (!rawActivity['activitytypecode']) {
              rawActivity['activitytypecode'] = 'SurgeryOrder';
            }
            let surgeryOrder = new SurgeryOrderActivity(rawActivity);
            if (surgeryOrder.ownerId != this.authenticationService.user.systemUserID) {
              surgeryOrder.isTeamOrder = true;
            }
            return surgeryOrder;
          case ActivityType.ProcedureTracker:
            if (!rawActivity['activitytypecode']) {
              rawActivity['activitytypecode'] = 'ProcedureTracker';
            }
            let procedureTracker = new ProcedureTrackerActivity(rawActivity);
            if (procedureTracker.ownerId != this.authenticationService.user.systemUserID) {
              procedureTracker.isTeamOrder = true;
            }
            return procedureTracker;
        }
      }
    }
  }

  public fetchCaseDetails(activity) {
    /* Online device with GUID */
    if (!this.deviceService.isOffline && !activity.ID.includes('offline')) {
      // let loader = this.loadingController.create();
      // loader.present();
      this.caseManagementDataService.fetchCaseById(activity.ID).then(
        async res => {

          activity._case_status_value = res['statuscode@OData.Community.Display.V1.FormattedValue'] ? res['statuscode@OData.Community.Display.V1.FormattedValue'] : activity._case_status_value;

          activity._case_stage_value = res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : activity._case_stage_value;

          activity._case_assignee = res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] ? res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] : activity._case_assignee;

          activity._case_timeline = res['inquiryTimeline'] ? activity.getTimeline(res['inquiryTimeline']) : activity._case_timeline ? activity._case_timeline : [];

          activity.state = res['statecode'] ? res['statecode'] : activity.state;

          activity._case_status_id = res['statuscode'] ? res['statuscode'] : activity['statuscode'];

          activity._case_overriddencreatedon = res['overriddencreatedon'] ? new Date(Number(res['overriddencreatedon'])) : undefined;
          activity._indskr_intakecreated = res['indskr_intakecreated'] ? new Date(Number(res['indskr_intakecreated'])) : undefined;

          activity._case_inquiry_response = res['expertInquiryResponse'] || activity._case_inquiry_response;

          this.caseManagementService.assignSelectedCase(activity);

          let idx: number = this.caseManagementService.myCases.findIndex((e: CaseActivity) => activity.ID === e.ID);
          if (idx > -1) {
            await this.caseManagementService.upsertMyCases(activity);
            await this.caseManagementService.updateCaseInPouch(activity);
          } else {
            await this.caseManagementService.upsertTeamCases(activity);
            await this.caseManagementService.updateTeamCaseInPouch(activity);
          }

          //loader.dismiss();
        }).catch(err => {
          this.caseManagementService.assignSelectedCase(activity);
          //loader.dismiss();
        });
    }
    /* Online device with offline ID, rare chance but need to handle, create case and assign */
    else if (!this.deviceService.isOffline && activity.ID.includes('offline') && String(activity._case_status_value).toLowerCase().includes('pending sync')) {
      this.caseManagementDataService.createCaseOnline(activity)
        .then(async response => {
          if (!response.hasOwnProperty('error')) {
            await this.caseManagementService.removeOfflinePayload(activity);
            await this.caseManagementService.addNewCase(response, activity);
          }
          else {
            //error occured while creating inquiry

          }
        });
    }
    /* probably the device is offline just set whatever data we have and be done with this */
    else {
      this.caseManagementService.assignSelectedCase(activity);
    }
  }

  private async clubActivityWithConfigFields(response: object, activity: Activity, activityType: ActivityType) {
    let key = "configuredFields";

    // Recent changes to fix OMNI-18459
    // as 'configuredFields' were missing it was not going through this method.
    // here we are creating 'configuredFields' and adding into the activityDoc which is offline
    // and it will continue in the flow and will show the data
    if (response) {

      if (!response['configuredFields']) {
        let activityName = '';
        let fields: ConfiguredFields[] = [];

        switch (activityType) {
          case ActivityType.Appointment:
            activityName = 'appointment';
            fields = this.authenticationService.user.getSupportedAppointmentConfiguredFields();
            break;
          case ActivityType.PhoneCall:
            activityName = 'phonecall';
            fields = this.authenticationService.user.getSupportedPhoneCallConfiguredFields();
            break;
          default:
            console.error('Invalid case in clubActivityWithConfigFields.');
            return;
        }

        if (fields.length) {
          let allFields = fields.map(field =>  field.fieldName );
          let appconfigfields = response['appconfigfields'];
          if (appconfigfields && appconfigfields.length) {
            const configuredFields = this.getConfigFieldsFromAppConfigFieldResponse(appconfigfields, allFields, activityName);
            response["configuredFields"] = configuredFields;
          }
        }
      }
    }
    //

    if (response && response[key] && Array.isArray(response[key])) {

      response[key].map(async (field) => {
        let key = field.key as string;
        let value = field.value;
        let stringValue = field.stringValue;
        let activityName = field.activityName as string;

        if (key === undefined) {
          // We're getting full ConfigField response and not from updateActivities call.
          // so have to update the key, value and activityName values from the field.
          key = field.fieldName;
        }

        if (activityName === undefined) {
          // We're getting full ConfigField response and not from updateActivities call.
          // so have to update the key, value and activityName values from the field.
          activityName = field.entityName;
        }

        if (key === undefined || value === undefined || activityName === undefined) {
          console.warn('Key, Value and Activity should not be undefined.');
          return;
        }

        let tempValues: string[] = [];
        let tableName = (key + '_customFieldType' + '_' + activityName);
        const savedCustomFields = await this.disk.retrieve(tableName, true);
        if (savedCustomFields && savedCustomFields.options && Array.isArray(savedCustomFields.options)) {
          //
          if (!value) {
            console.warn('clubActivityWithConfigFields: Value should not be undefined.');
            return;
          } else if (value.indexOf(',') > -1) {
            // comma separated value exist, possible field example:  Multi OptionSet (Virtual Type).
            tempValues = value.split(',');
          } else {
            // Only single value. Single selection example: Picklist.
            tempValues.push(value);
          }

          // Here assuming we will always have numbers values require for the OptionSet type.
          const compareValue = tempValues.map(each => { return Number(each) });

          if (!compareValue.length) {
            console.warn('CompareValue should not be undefined.');
            return;
          }
          //
          let result = savedCustomFields.options.filter(scf => { return (compareValue.includes(scf.value) && scf.activity === activityName) });
          if (result && result.length) {

            let multipleLabels: string[] = [];
            let multipleValues: number[] = [];
            let hasMultipleValues: boolean = false;

            if (result.length > 1) {
              hasMultipleValues = true;
              result.forEach(cfov => {
                multipleLabels.push(cfov.label);
                multipleValues.push(cfov.value);
              });
            }

            let scf = result[0] as ConfigFieldOptionValue;
            if (scf) {
              let cfResult = [...this.authenticationService.user.configuredFields.filter(field => { return (field.entityName === activityName && field.fieldName === scf.fieldName) })];
              if (cfResult && cfResult.length) {
                let cf = cloneDeep(cfResult[0]) as ConfiguredFields;
                if (cf) {

                  if (hasMultipleValues) {
                    cf.selectedValues = multipleLabels.join(', ');
                    cf.hasMultipleValues = true;
                    cf.multipleLabels = multipleLabels;
                    cf.multipleValues = multipleValues;
                    cf.value = '';
                  } else {
                    cf.selectedValues = scf.label;
                    cf.hasMultipleValues = false;
                    cf.multipleLabels = [];
                    cf.multipleValues = [];
                    cf.value = value;
                  }

                  // Check for existing same config field, if found remove the old one and add the new one.
                  let existingConfigFields = activity.appConfigFields;
                  if (existingConfigFields && existingConfigFields.length) {
                    let index = existingConfigFields.findIndex(field => { return field.fieldName === key || field['key'] === key});
                    if (index !== -1) {
                      existingConfigFields.splice(index, 1);
                    }
                    // if (result && result.length) {
                    //   let object = result[0];
                    //   let indexOfObject = existingConfigFields.indexOf(object);
                    //   if (indexOfObject !== -1) {
                    //     existingConfigFields.splice(indexOfObject, 1);
                    //   }
                    // }
                  }
                  existingConfigFields.push(cf);
                  activity.appConfigFields = existingConfigFields;
                }
              }
            }
          }
        } else {
          // Fields are of different type other then OptionSet / MultiOptionSet.
          let cfResult = [...this.authenticationService.user.configuredFields.filter(field => { return (field.entityName === activityName && field.fieldName === key) })];
          if (cfResult && cfResult.length) {
            let cf = cloneDeep(cfResult[0]) as ConfiguredFields;
            if (cf) {
              cf.value = value;
              cf.stringValue = stringValue;
              cf.selectedValues = value;
              cf.hasMultipleValues = false;
              cf.multipleLabels = [];
              cf.multipleValues = [];
              //
              // Check for existing same config field, if found remove the old one and add the new one.
              let existingConfigFields = activity.appConfigFields;
              if (existingConfigFields && existingConfigFields.length) {

                let index = existingConfigFields.findIndex(field => { return field.fieldName === key || field['key'] === key });
                if (index !== -1) {
                  existingConfigFields.splice(index, 1);
                }
                // let result = existingConfigFields.filter(field => { return field.fieldName === key });
                // if (result && result.length) {
                //   let object = result[0];
                //   let indexOfObject = existingConfigFields.indexOf(object);
                //   if (indexOfObject !== -1) {
                //     existingConfigFields.splice(indexOfObject, 1);
                //   }
                // }
              }
              existingConfigFields.push(cf);
              activity.appConfigFields = existingConfigFields;
              //
            }
          }

        }
      });
    }
  }

  private getConfigFieldsFromResponse(response: any, allFields: string[], activityName: string): any[] {
    let responseKeys = Object.keys(response);
    let configuredFields: any[] = [];
    responseKeys.forEach((key) => {
      if (allFields.includes(key)) {
        let responseValue = response[key];
        let isString = (typeof (responseValue) === 'string');
        let value = { 'key': key, 'value': isString ? responseValue : String(responseValue), 'activityName': activityName };
        if(response[key+'@OData.Community.Display.V1.FormattedValue']){
          value['stringValue'] = response[key+'@OData.Community.Display.V1.FormattedValue'];
          if(value['key'].includes('_value')){
            value['key'] = value['key'].substring(1,value['key'].length - 6);
          }
        }
        configuredFields.push(value);
      }
    });
    return configuredFields;
  }

  private getConfigFieldsFromAppConfigFieldResponse(response: any, allFields: string[], activityName: string): any[] {
    let configuredFields: any[] = [];
    response.forEach((field) => {
      let key = field['fieldname'];
      if (allFields.includes(key)) {
        let responseValue = field['value'];
        let isString = (typeof (responseValue) === 'string');
        let value = { 'key': key, 'value': isString ? responseValue : String(responseValue), 'activityName': activityName };
        if(field['stringValue']){
          value['stringValue'] = field['stringValue'];
        }
        configuredFields.push(value);
      }
    });
    return configuredFields;
  }

  private async updateAllActivitiesSync(activityType: ActivityType) {
    switch (activityType) {
      case ActivityType.Appointment:
        let syncStateAppointment = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_APPOINTMENT_ACTIVITIES_FOR_CUSTOM_FIELDS);
        if (syncStateAppointment) {
          syncStateAppointment.lastUpdatedTime = new Date().getTime();
        } else {
          syncStateAppointment = { 'lastUpdatedTime': new Date().getTime() };
        }
        await this.disk.updateSyncState(syncStateAppointment);
        break;
      case ActivityType.PhoneCall:
        let syncStatePhoneCall = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_PHONECALL_ACTIVITIES_FOR_CUSTOM_FIELDS);
        if (syncStatePhoneCall) {
          syncStatePhoneCall.lastUpdatedTime = new Date().getTime();
        } else {
          syncStatePhoneCall = { 'lastUpdatedTime': new Date().getTime() };
        }
        await this.disk.updateSyncState(syncStatePhoneCall);
        break;
    }
  }

  public async updateTeamActivitiesForConfigField(activityType: ActivityType,userIds,meetingResponse,dataRange: { from: string, to: string }){
    if (this.deviceService.isOffline || !userIds || !meetingResponse) {
      return;
    }
    let fields: ConfiguredFields[] = [];
    let entityName = '';
    let activityName = '';
    switch (activityType) {
      case ActivityType.Appointment:
        activityName = 'appointment';
        entityName = 'appointments';
        fields = this.authenticationService.user.getSupportedAppointmentConfiguredFields();
        break;
      case ActivityType.PhoneCall:
        activityName = 'phonecall';
        entityName = 'phonecalls';
        fields = this.authenticationService.user.getSupportedPhoneCallConfiguredFields();
        break;
      default:
        console.error('Invalid case in updateTeamActivitiesForConfigField.');
        return;
    }
    let supportedTypes = ['Picklist', 'Virtual', 'Memo', 'String', 'Integer', 'Boolean', 'Memo', 'Decimal','Lookup'];
    fields = fields.filter(field => { return supportedTypes.includes(field.fieldType) });

    var fetchXMLQuery = '<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">';
    fetchXMLQuery = fetchXMLQuery + '<entity name="' + activityName + '">';

    fields.forEach(field => {
      fetchXMLQuery = fetchXMLQuery + '<attribute name="' + field.fieldName + '"/>';
    });

    fetchXMLQuery = fetchXMLQuery + '<filter type="and">';

    fetchXMLQuery = fetchXMLQuery + '<condition attribute="activityid" operator="not-null"/>';
    let usersString = '';
    userIds = userIds.split(',');
    userIds.forEach(p=>{
      usersString += '<value>'+p+'</value>'
    })
    fetchXMLQuery = fetchXMLQuery + '<condition attribute="ownerid" operator="in">'+usersString+'</condition>'

    if(dataRange && dataRange.from && dataRange.to){
      fetchXMLQuery = fetchXMLQuery + `<condition attribute="scheduledstart" operator="on-or-after" value="{startDate}" />
      <condition attribute="scheduledend" operator="on-or-before" value="{endDate}" />`;
      fetchXMLQuery = fetchXMLQuery.replace('{startDate}', format(new Date(parseInt(dataRange.from)), 'YYYY-MM-DD'));
      fetchXMLQuery = fetchXMLQuery.replace('{endDate}', format(new Date(parseInt(dataRange.to)), 'YYYY-MM-DD'));
    }
    // Inner Condition Starts
    fetchXMLQuery = fetchXMLQuery + '<filter type="or">';
    fields.forEach(field => {
      fetchXMLQuery = fetchXMLQuery + '<condition attribute="' + field.fieldName + '" operator="not-null"/>';
    });
    fetchXMLQuery = fetchXMLQuery + '</filter>';
    // Inner Condition Ends

    fetchXMLQuery = fetchXMLQuery + '</filter>';

    fetchXMLQuery = fetchXMLQuery + '</entity>';
    fetchXMLQuery = fetchXMLQuery + '</fetch>';

    try {
      const response = await this.dynamics.executeFetchQuery(entityName, fetchXMLQuery.toString())
      if (!_.isEmpty(response)) {
        let allFields = fields.map(field => {
          if (field.fieldType == 'Lookup') {
            return '_' + field.fieldName + '_value';
          } else {
            return field.fieldName;
          }
        });
        for (let field of response) {
          switch (activityType) {
            case ActivityType.Appointment:
              try {
                let meeting = meetingResponse.find(a=> a.activityid == field.activityid);
                if (meeting) {
                  const configuredFields = this.getConfigFieldsFromResponse(field, allFields, activityName);
                  meeting["configuredFields"] = configuredFields;
                }
              } catch (error) {
                console.error('updateMeeting: ', error);
              }
              break;
            case ActivityType.PhoneCall:
              try {
                let phoneActivity = meetingResponse.find(a=> a.activityid == field.activityid);
                if (phoneActivity) {
                  const configuredFields = this.getConfigFieldsFromResponse(field, allFields, activityName);
                  phoneActivity["configuredFields"] = configuredFields;
                }
              } catch (error) {
                console.error('updateMeeting: ', error);
              }
              break;
            default:
              return;
          }
        }
      }
    } catch (error) {
      console.error('updateTeamActivitiesForConfigField: error:', error);
    }
    return;
  }

  public async updateActivitiesForConfigField(activityType: ActivityType, fullsync: boolean, loadFromDBOnly = false) {
    if (this.deviceService.isOffline || loadFromDBOnly) {
      return;
    }

    let syncStateKey = '';
    let entityName = '';
    let activityName = '';
    let fields: ConfiguredFields[] = [];

    switch (activityType) {
      case ActivityType.Appointment:
        syncStateKey = DB_SYNC_STATE_KEYS.SYNC_ALL_APPOINTMENT_ACTIVITIES_FOR_CUSTOM_FIELDS;
        activityName = 'appointment';
        entityName = 'appointments';
        fields = this.authenticationService.user.getSupportedAppointmentConfiguredFields();
        break;
      case ActivityType.PhoneCall:
        syncStateKey = DB_SYNC_STATE_KEYS.SYNC_ALL_PHONECALL_ACTIVITIES_FOR_CUSTOM_FIELDS;
        activityName = 'phonecall';
        entityName = 'phonecalls';
        fields = this.authenticationService.user.getSupportedPhoneCallConfiguredFields();
        break;
      default:
        console.error('Invalid case in updateActivitiesForConfigField.');
        return;
    }

    // TODO: Hemang - Have to make this dynamic.
    // Since this enhancement supports these types only.
    let supportedTypes = ['Picklist', 'Virtual', 'Memo', 'String', 'Integer', 'Boolean', 'Memo', 'Decimal','Lookup'];
    fields = fields.filter(field => { return supportedTypes.includes(field.fieldType) });

    if (!fields.length) {
      console.warn('No custom fields found for the activity.');
      return;
    }

    var fetchXMLQuery = '<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">';
    fetchXMLQuery = fetchXMLQuery + '<entity name="' + activityName + '">';

    fields.forEach(field => {
      fetchXMLQuery = fetchXMLQuery + '<attribute name="' + field.fieldName + '"/>';
    });

    let syncState = await this.disk.getSyncState(syncStateKey);
    let now = new Date();
    let hourDifference = 0;
    let isInitialSync = false;
    if (syncState && syncState.lastUpdatedTime) {
      hourDifference = differenceInHours(now, new Date(syncState.lastUpdatedTime));
      hourDifference += 1;
      isInitialSync = fullsync;
    } else {
      isInitialSync = true;
    }

    fetchXMLQuery = fetchXMLQuery + '<filter type="and">';

    if (isInitialSync) {
      fetchXMLQuery = fetchXMLQuery + '<condition attribute="createdon" operator="last-x-weeks" value="4" />';
    } else {
      fetchXMLQuery = fetchXMLQuery + '<condition attribute="modifiedon" operator="last-x-hours" value="' + hourDifference + '" />';
    }

    fetchXMLQuery = fetchXMLQuery + '<condition attribute="activityid" operator="not-null"/>';

    // Inner Condition Starts
    fetchXMLQuery = fetchXMLQuery + '<filter type="or">';
    fields.forEach(field => {
      fetchXMLQuery = fetchXMLQuery + '<condition attribute="' + field.fieldName + '" operator="not-null"/>';
    });
    fetchXMLQuery = fetchXMLQuery + '</filter>';
    // Inner Condition Ends

    fetchXMLQuery = fetchXMLQuery + '</filter>';

    fetchXMLQuery = fetchXMLQuery + '</entity>';
    fetchXMLQuery = fetchXMLQuery + '</fetch>';

    try {
      const response = await this.dynamics.executeFetchQuery(entityName, fetchXMLQuery.toString())
      if (!_.isEmpty(response)) {
        await this.parseAndSaveConfigFieldActivities(response, fields, activityName, activityType);
        // this.updateAllActivitiesSync(activityType);
      }
    } catch (error) {
      console.error('updateActivitiesForConfigField: error:', error);
    }
  }

  private async parseAndSaveConfigFieldActivities(response: any, configuredFields: ConfiguredFields[], activityName: string, activityType: ActivityType, rawActivity?: any) {
    let allFields = configuredFields.map(field => {
      if(field.fieldType == 'Lookup'){
        return '_'+field.fieldName+'_value';
      }else{
        return field.fieldName;
      }
    });
    let appConfigFields = []
    for (let field of response) {
      switch (activityType) {
        case ActivityType.Appointment:
          try {
            let meeting = await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_ACTIVITY + field.activityid, true);
            if (meeting) {

              const configuredFields = this.getConfigFieldsFromResponse(field, allFields, activityName);
              meeting["configuredFields"] = configuredFields;
              let activity = this.activityService.getActivityByID(field.activityid);
              if (activity) {
                // Need to copy runtime variables
                const replacingActivity = new AppointmentActivity(meeting);
                this.activityService.copyRuntimeActivityVariables(activity, replacingActivity);
                activity = replacingActivity;

                await this._appendMeetingDetailsToActivity(activity, meeting, true);
                if (rawActivity) {
                  rawActivity['configuredFields'] = configuredFields;
                }
              }
            }
          } catch (error) {
            console.error('updateMeeting: ', error);
          }
          break;
        case ActivityType.PhoneCall:
          try {

            let phoneActivity = await this.disk.retrieve(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + field.activityid, true);
            if (phoneActivity) {
              const configuredFields = this.getConfigFieldsFromResponse(field, allFields, activityName);
              phoneActivity["configuredFields"] = configuredFields;
              let activity = this.activityService.getActivityByID(field.activityid);
              if (activity) {
                const conflictingActivityIds = activity.conflictingActivityIds;
                activity = new PhoneActivity(phoneActivity);
                activity.conflictingActivityIds = conflictingActivityIds;
                await this._appendPhoneCallDetailsToActivity(activity, phoneActivity, true);
                if (rawActivity) {
                  rawActivity['configuredFields'] = configuredFields;
                }
              }
            }
          } catch (error) {
            console.error('updateMeeting: ', error);
          }
          break;
        default:
          return;
      }
    }
  }

  private async old_parseAndSaveConfigFieldActivities(response: any, configuredFields: ConfiguredFields[], activityName: string, activityType: ActivityType) {
    if (Array.isArray(response)) {
      let allFields = configuredFields.map(field => { return field.fieldName });
      response.map(async (activityResponse) => {

        let activityId = activityResponse.activityid;
        if (!activityId) return;

        let activity = this.activityService.getActivityByID(activityId);
        if (!activity) return;

        let configuredFields = this.getConfigFieldsFromResponse(activityResponse, allFields, activityName);

        let dbPrefix: string = '';
        switch (activityType) {
          case ActivityType.Appointment:
            dbPrefix = DB_KEY_PREFIXES.MEETING_ACTIVITY + activityId;
            break;
          case ActivityType.PhoneCall:
            dbPrefix = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + activityId;
            break;
          default:
            return;
        }

        const rawActivity = this.disk.retrieve(dbPrefix, true);
        rawActivity['configuredFields'] = configuredFields;

        //1. Get Raw meeting from Disk
        //2. Append the configured Fields in the raw response
        // get the activity from the this.activityService.getActivityByID(activityId);
        // call this._appendMeetingDetailsToActivity();

        //rawmeeting['configuredFields'] = configuredFields;


        if (configuredFields.length) {
          switch (activityType) {
            case ActivityType.Appointment:
              if (rawActivity) this._appendMeetingDetailsToActivity(activity, rawActivity, true);
              break;
            case ActivityType.PhoneCall:
              if (rawActivity) this._appendPhoneCallDetailsToActivity(activity, rawActivity, true);
              break;
            default:
              return;
          }
        }
      });
    }
  }

  public async updateActivityForConfigField(activity: Activity, rawActivity: any, activityType: ActivityType) {
    if (!activity || !activity.ID) return [];
    if (!rawActivity) return[];

    let entityName = '';
    let activityName = '';
    let fields: ConfiguredFields[] = [];

    switch (activityType) {
      case ActivityType.Appointment:
        activityName = 'appointment';
        entityName = 'appointments';
        fields = this.authenticationService.user.getSupportedAppointmentConfiguredFields();
        break;
      case ActivityType.PhoneCall:
        activityName = 'phonecall';
        entityName = 'phonecalls';
        fields = this.authenticationService.user.getSupportedPhoneCallConfiguredFields();
        break;
    }

    // TODO: Hemang - Have to make this dynamic.
    // Since this enhancement supports these types only.
    let supportedTypes = ['Picklist', 'Virtual', 'Memo', 'String', 'Integer', 'Boolean', 'Memo', 'Decimal','Lookup'];
    fields = fields.filter(field => { return supportedTypes.includes(field.fieldType) });

    if (!fields.length) {
      console.warn('No custom fields found for the activity.');
      return;
    }

    var fetchXMLQuery = '<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">';
    fetchXMLQuery = fetchXMLQuery + '<entity name="' + activityName + '">';

    fields.forEach(field => {
      fetchXMLQuery = fetchXMLQuery + '<attribute name="' + field.fieldName + '"/>';
    });

    fetchXMLQuery = fetchXMLQuery + '<filter type="and">';
    fetchXMLQuery = fetchXMLQuery + '<condition attribute="activityid" operator="eq" value="' + activity.ID + '"/>';
    fetchXMLQuery = fetchXMLQuery + '</filter>';

    fetchXMLQuery = fetchXMLQuery + '</entity>';
    fetchXMLQuery = fetchXMLQuery + '</fetch>';

    // try {
    //   await this.dynamics.executeFetchQuery(entityName, fetchXMLQuery.toString()).then(async (response) => {
    //     this.parseAndSaveConfigField(response, activity, rawActivity, fields, activityName, activityType);
    //   });
    // } catch (error) {
    //   console.error('updateActivityForConfigField: error:', error);
    // }

    try {
      await this.dynamics.executeFetchQuery(entityName, fetchXMLQuery.toString()).then(async (response) => {
        if (response) {
          if (Array.isArray(response)) {
            return await this.parseAndSaveConfigFieldActivities(response, fields, activityName, activityType, rawActivity);
          }
        }
      });
    } catch (error) {
      console.error('updateActivityForConfigField: error:', error);
    }
  }

  private old_parseAndSaveConfigField(response: any, activity: Activity, rawActivity: any, configuredFieldsForActivity: ConfiguredFields[], activityName: string, activityType: ActivityType) {
    if (configuredFieldsForActivity.length) {
      if (Array.isArray(response)) {
        let allFields = configuredFieldsForActivity.map(field => { return field.fieldName });
        let activityResponse = response[0];
        if (activityResponse) {
          let configuredFields = this.getConfigFieldsFromResponse(activityResponse, allFields, activityName);
          if (configuredFields.length) {
            rawActivity.configuredFields = configuredFields;
          }
        }
      } else {
        console.warn('Config Field: parse failed. Invalid response object type.');
      }
    }
  }

  /**
   * Update our activity from CRM
   *
   * @param {Activity} activity
   * @memberof ActivityDataService
   */
  public async updateActivityDetails(activity: Activity, callDeltaService: boolean = true, useSelectedActivity: boolean = true) {

    if (!activity || !activity.ID) return;

    let url: string = "";
    let type: string = "";
    let dbPrefix: string = '';
    let dbKey: string = '';
    let loader: HTMLIonLoadingElement = null;
    const loaderOptions = {
      duration: 15000
    };
    switch (activity.type) {
      case ActivityType.Appointment:
        type = "appointment";
        dbPrefix = DB_KEY_PREFIXES.MEETING_ACTIVITY;
        dbKey = this.activityService.hasOfflineMeetingData(activity.ID) || activity.ID.includes('offline_') ? dbKey = 'offline' : dbKey = dbPrefix + activity.ID;
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        break;
      case ActivityType.StoreCheck:
        type = "appointment";
        dbPrefix = DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY;
        dbKey = this.activityService.hasOfflineMeetingData(activity.ID) || activity.ID.includes('offline_') ? dbKey = 'offline' : dbKey = dbPrefix + activity.ID;
        loader = await this.loadingCtrl.create(loaderOptions);
        break;
      case ActivityType.PhoneCall:
        type = "phonecall";
        dbPrefix = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY;
        dbKey = this.activityService.hasOfflinePhoneCallData(activity.ID) || activity.ID.includes('offline_') ? dbKey = 'offline' : dbKey = dbPrefix + activity.ID;
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        break;

      case ActivityType.Email:
        type = "email";
        dbPrefix = DB_KEY_PREFIXES.EMAIL_ACTIVITY;
        dbKey = dbKey = dbPrefix + activity.ID;

        if (this.activityService.selectedActivity) {
          if (useSelectedActivity) {
            this.activityService.selected = this.activityService.getActivityByID(activity.ID);
            this.emailService.selectedActivity = <EmailActivity>this.activityService.selectedActivity;
          } else {
            this.emailService.selectedActivity = <EmailActivity>activity;
            this.events.publish('updateEmbeddedActivity', this.emailService.selectedActivity);
          }
          this.emailService.setCurrentEmail(this.emailService.selectedActivity);
          if (activity.ID.includes('offline_') || this.deviceService.isOffline
            || (this.emailService.selectedActivity && this.activityService.hasOfflineEmailData(this.emailService.selectedActivity.offlineActivityId))) {
            //If email activity is updated offline, but on delta sync any of its entity is deleted then remove it from from DB
            await this.emailService.removeDeactivatedEntities().then(() => {
              if (!useSelectedActivity) {
                this.events.publish('updateEmbeddedActivity', this.emailService.selectedActivity);
              }
            });
            return;
          }
        }
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        //Before online call, remove deactivated entities
        if(!((activity as EmailActivity).emailStatus !== 1)){
          await this.emailService.removeDeactivatedEntities().then(() => {
            if (!useSelectedActivity) {
              this.events.publish('updateEmbeddedActivity', this.emailService.selectedActivity);
            }
          });
        }
        break;


      case ActivityType.TimeOff:
        type = "totappointment";
        if (activity.ID.includes('offline_') || this.deviceService.isOffline) {
          return;
        } else {
          loader = await this.loadingCtrl.create(loaderOptions);
          loader.present();
        }
        break;

      case ActivityType.TimeOffRequest:
        type = "timeoff";
        if (activity.ID.includes('offline_') || this.deviceService.isOffline) {
          return;
        } else {
          loader = await this.loadingCtrl.create(loaderOptions);
          loader.present();
        }
        break;

      case ActivityType.Sample:
        type = "sampledrop";

        dbPrefix = DB_KEY_PREFIXES.SAMPLE_ACTIVITY;
        dbKey = (activity.ID.includes('offline_') || this.activityService.hasOfflineSampleOrderData((activity as SampleActivity).offlineActivityId)) ? 'Offline_SampleDrop' : dbPrefix + activity.ID;
        break;

      case ActivityType.FollowUp:
        type = "followuptask";
        dbPrefix = DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY;
        dbKey = (activity as FollowUpActivity).offlineDBId;
        loader = await this.loadingCtrl.create(loaderOptions);
        await loader.present();
        break;

      case ActivityType.CaseIntake:
        type = 'CaseIntake';
        break;

      case ActivityType.Order:
        type = "order";
        dbPrefix = DB_KEY_PREFIXES.ORDER_ACTIVITY;
        dbKey = (activity as OrderActivity).offlineDBId;
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        break;
      case ActivityType.SurgeryOrder:
        type = "surgeryorder";
        dbPrefix = DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY;
        dbKey = (activity as SurgeryOrderActivity).offlineDBId;
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        break;
      case ActivityType.ProcedureTracker:
        type = "proceduretracker";
        dbPrefix = DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY;
        dbKey = (activity as SurgeryOrderActivity).offlineDBId;
        loader = await this.loadingCtrl.create(loaderOptions);
        loader.present();
        break;

      default:
        this.logService.logError("No activity type for ", activity);
    }

    if (type != "CaseIntake") {
      //Doesn't matter if online or offline, load our latest bulk detail for performance
      let activityDoc;
      if (dbKey) {
        if (dbKey === 'offline') {
          // fetch offline created meeting detail

          if (type === 'phonecall') {
            activityDoc = await this.activityService.getOfflineCreatedPhonecallMeetingDetailsByID(activity.ID);
          }
          else {
            activityDoc = await this.activityService.getOfflineCreatedMeetingDetailsByID((activity as AppointmentActivity).offlineMeetingId ?? activity.ID);
          }
        } else if (dbKey === 'Offline_SampleDrop' || dbKey === 'offline_email_') {
          // fetch offline sample drop here (to handle case when some offline sample drop failed to get uploaded)
          if (useSelectedActivity) {
            this.activityService.selected = this.activityService.getActivityByID(activity.ID);
          } else {
            if (activity instanceof EmailActivity) {
              this.emailService.selectedActivity = <EmailActivity>this.activityService.getActivityByID(activity.ID);
              this.emailService.setCurrentEmail(this.emailService.selectedActivity);
              this.events.publish('updateEmbeddedActivity', this.emailService.selectedActivity);
            } else if (activity instanceof SampleActivity) {
              this.samplingService.inMeetingAllocationActivity = <SampleActivity>this.activityService.getActivityByID(activity.ID);
              this.activityDetailsLoaded = true;
              this.events.publish('updateEmbeddedActivity', this.samplingService.inMeetingAllocationActivity);
              this.events.publish('selectedActivityChangedInBackround');
            }
          }
          return;
        } else {
          activityDoc = await this.disk.retrieve(dbKey, true);
        }
      }
      if (type === 'appointment') {
        if (activityDoc) {
          if (activity.type === ActivityType.StoreCheck && activityDoc) {
            activity = new StoreCheckActivity(activityDoc);
            await this._appendStoreCheckDetailsToActivity(activity, activityDoc);
          } else {
            // Need to copy runtime variables
            const replacingActivity = new AppointmentActivity(activityDoc);
            this.activityService.copyRuntimeActivityVariables(activity, replacingActivity);
            activity = replacingActivity;
            await this._appendMeetingDetailsToActivity(activity, activityDoc);
          }
        }
      }
      if (type === 'phonecall') {
        if (activityDoc) {
          const conflictingActivityIds = activity.conflictingActivityIds;
          activity = new PhoneActivity(activityDoc);
          activity.conflictingActivityIds = conflictingActivityIds;
        }
        await this._appendPhoneCallDetailsToActivity(activity, activityDoc);
      }
      else if (type === 'followuptask' && activityDoc) {
        await this._appendFollowUpDetailsToActivity((activity as FollowUpActivity), activityDoc);
        await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: false, onLocalDatabase: false, onLocalCopy: true, appendActivityDetails: true }, [activity as FollowUpActivity], activityDoc.lastUpdatedTime, false).then(succ => {
        }).catch(err => {
          //To Do error Handling
        });
        if (activityDoc.pendingPushToDynamics) {
          await loader?.dismiss();
          return;
        }
      } else if (type === 'order' && activityDoc) {
        this._appendOrderDetailsToActivity((activity as OrderActivity), activityDoc);
        await this.orderActivityDataService.updateOrderActivity({ onDynamics: false, onLocalDatabase: false, onLocalCopy: true, appendActivityDetails: true, operationDetail: { code: 'AGENDACLICKUPDATE', message: 'Real time update when clicked from agenda' } }, [activity as OrderActivity], activityDoc.lastUpdatedTime).then(succ => {
        }).catch(err => {
          //To Do error Handling
        });
        if (activityDoc.pendingPushToDynamics) {
          await loader?.dismiss();
          return;
        }
      } else if (type === 'surgeryorder' && activityDoc) {
        this._appendSurgeryOrderDetailsToActivity((activity as SurgeryOrderActivity), activityDoc);
        await this.surgeryOrderActivityDataService.updateOrderActivity({ onDynamics: false, onLocalDatabase: false, onLocalCopy: true, appendActivityDetails: true, operationDetail: { code: 'AGENDACLICKUPDATE', message: 'Real time update when clicked from agenda' } }, [activity as SurgeryOrderActivity], activityDoc.lastUpdatedTime).then(succ => {
        }).catch(err => {
          //To Do error Handling
        });
        if (activityDoc.pendingPushToDynamics) {
          await loader?.dismiss();
          return;
        }
      }
      // Don't fetch if offline
      if (this.deviceService.isOffline || dbKey === 'offline' || !callDeltaService) {
        try {
          await loader?.dismiss();
        } catch (error) {

        }
        return;
      }

      if (activityDoc && activityDoc.lastUpdatedTime && (type === 'appointment' || type === 'storecheck')) {
        // Call delta service instead
        url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_DELTA_ACTIVITY_DETAILS;
        url = url.replace("{activity_id}", activity.ID);
        url = url.replace("{last_updated_time}", activityDoc.lastUpdatedTime);
        const positions = this.authenticationService.user.positions.map((o) => {
          return o.ID
        })
        url = url.replace('{{positionIDs}}', positions.toString());
        let appointmentConfigFields: string[] = []
        if (activity.type !== ActivityType.StoreCheck) {
          if (activity.isCompleted || this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_MEETINGS)) {
            url = url.concat('&featureFlags=activityEvents');
          }
          if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) {
            url = url.concat(url.includes('featureFlags') ? ',marketingbusinessplan' : '&featureFlags=marketingbusinessplan')
          }

          if (this.authenticationService.user.appointmentConfiguredFields.length > 0 && this.authenticationService.user.appointmentConfiguredFields != undefined) {
            appointmentConfigFields = this.authenticationService.user.appointmentConfiguredFields.map((obj) => {
              return obj.fieldName
            })
          }
        }
        url = url.replace('{{appointmentConfigFields}}', appointmentConfigFields.toString());
      }
      else if (activityDoc && type === 'phonecall') {
        // Call delta service instead
        let lastUpdatedTime = activityDoc.lastUpdatedTime ? activityDoc.lastUpdatedTime : new Date().getTime();
        url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.GET_DELTA_PHONE_CALL_ACTIVITY_DETAILS;
        url = url.replace("{activity_id}", activity.ID);
        url = url.replace("{activity_id1}", activity.ID);
        url = url.replace("{lastUpdatedTime}", lastUpdatedTime);
        url = url.replace("{startDate}", activityDoc.scheduledstart);
        url = url.replace("{endDate}", activityDoc.scheduledend);
        const positions = this.authenticationService.user.positions.map((o) => {
          return o.ID
        })
        url = url.replace('{positionIds}', positions.toString());

        let phonecallConfiguredFields: string[] = []
        if (this.authenticationService.user.phonecallConfiguredFields.length > 0 && this.authenticationService.user.phonecallConfiguredFields != undefined) {
          phonecallConfiguredFields = this.authenticationService.user.phonecallConfiguredFields.map((obj) => {
            return obj.fieldName
          })
        }
        url = url.replace('{phonecallConfigFields}', phonecallConfiguredFields.toString());
      } else {
        url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;
        const isMarketingPlanEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);
        if ((type === 'appoitment' || type === 'surgeryorder' ) && isMarketingPlanEnabled) {
          url = url + '&featureFlags=marketingbusinessplan';
        } else if (type === 'followuptask') {
          url = this.addFollowUpFeatureFlags(url);
        }
        if (activity.type === ActivityType.Email && activity['parentemailid'] != null) {
          url = url.replace("{activity_id}", activity['parentemailid']);
        } else {
          url = url.replace("{activity_id}", activity.ID);
        }
        if (type == "totappointment" || type == "timeoff") {
          url = url.replace("{activity_type}", "tot");
        } else
          url = url.replace("{activity_type}", type);
      }

      url = url.replace('&configFields={{configFields}}', '');


      const fetchCancelledMeetings = this.authenticationService.user.buSettings &&
        this.authenticationService.user.buSettings['indskr_displaycancelledmeetingoncalendar'];

      if (fetchCancelledMeetings && type === 'appointment') {
        url = url.concat(url.includes('featureFlags') ? ',fetchCancelMeetings' : '&featureFlags=fetchCancelMeetings')
      }

      // if (flags && flags.length > 0) {
      //   // url = url.concat(url.includes('featureFlags') ? `,${flags.join(',')}` : `&featureFlags=${flags.join(',')}`);
      //   // url = url.concat(`&featureFlags=${flags.join(',')}`);
      // }

      if (type === 'surgeryorder') {
        url = this.addProcedureLogFeatureFlags(url);
      }
      let headers = Endpoints.headers.content_type.json;
      headers.headers = headers.headers.set(
        'No-Retry', 'true'
      );
      if (type == 'order' || type == 'surgeryorder') {
        headers.headers = headers.headers.set(
          'X-Feature-Id', 'Fetch_Activity_Appointment'
        );
      }
      try {
        let rawActivity: any;
        let parallelCalls: any[] = [this.http.get(url, headers).toPromise()];
        let showDuration: boolean = false;
        if (type == 'appointment' && activity.indskr_type !== ActivityTypeCode.STORE_CHECK) {
          let selectedMeeting = activity as AppointmentActivity;
          if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CAPTURE_REMOTE_DETAILING_DURATION) && selectedMeeting.isRemoteDetailing
            && !_.isEmpty(selectedMeeting.contactAttendees)) {
            showDuration = true;
            let fetchXml = fetchQueries.fetchContactJoinDetails;
            fetchXml = fetchXml.replace('{activityId}', activity.ID);
            fetchXml = fetchXml.replace('{contactId}', selectedMeeting.contactAttendees[0].indskr_contactid);
            parallelCalls.push(this.dynamics.executeFetchQuery("indskr_activityattendeejoiningdetails", fetchXml));
          }
          if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TAG_ASSETS_TO_MEETINGS) && !this.activityService.isNewMeetingFlow) {
            parallelCalls.push(this.fieldMaterialManagementService.getMeetingAssetMappings(false, activity.ID));
          }
        }
        let parallelRes = await Promise.all(parallelCalls);
        rawActivity = parallelRes[0];
        if (showDuration) {
          (activity as AppointmentActivity).activityattendeejoiningdetails = parallelRes[1]
          if (rawActivity) rawActivity.activityattendeejoiningdetails = parallelRes[1];
        }
        if (type == 'appointment' && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_PLANS_FOR_MEETING) && !this.activityService.isNewMeetingFlow && activity.indskr_type !== ActivityTypeCode.STORE_CHECK) {
          let acPlansResponse = await this.getAccountPlansMeeting(activity.ID);
          if (!_.isEmpty(acPlansResponse)) {
            let acPlans = [];
            acPlansResponse.forEach(ap => {
              acPlans.push({accountPlanId: ap['accountPlan'], accountPlanName: ap['accountPlan_Formatted']});
            });
            if(rawActivity) {
              rawActivity['activityAccountPlans'] = acPlans;
            } else {
              (activity as AppointmentActivity).associatedAccountPlans = acPlans;
            }
          }
        }
        this.activityService.isNewMeetingFlow = false;
        if (type == 'appointment' && this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_CAPTURE) && activity.indskr_type !== ActivityTypeCode.STORE_CHECK) {
          if (activityDoc && !_.isEmpty(activityDoc['notes']) && !_.isEmpty(rawActivity)) rawActivity['notes'] = activityDoc['notes'];
        }
        if (loader) {
          loader.dismiss();
        }
        this.uiService.dismissLoader();
        let configFields = []
        if (activity.type === ActivityType.Email && activity instanceof EmailActivity) {
          let socialActivity = ([ChannelType.FACEBOOK, ChannelType.WHATSAPP].indexOf(activity.channelType)) >= 0;
          if(!rawActivity.channelType){
            rawActivity.channelType = activity.channelType;
          }if(!rawActivity['channelActivityType']){
            rawActivity['channelActivityType'] = (activity as EmailActivity).channelActivityType;
          }
          if (socialActivity) {
            let emailActivityParties = await this.dynamics.executeFetchQuery('activityparties', fetchXmlEntity({
              "@": { "name": "activityparty" },
              "attribute": [
                { "@": { "name": "partyid", "alias": "email_addressid" } },
                { "@": { "name": "activityid", "alias": "emailActivityId" } },
                { "@": { "name": "addressused", "alias": "emailAddress" } }
              ],
              "filter": [
                {
                  "@": { "type": "and" },
                  "condition": [
                    { "@": { "attribute": "participationtypemask", "operator": "eq", "value": "2" } },
                    { "@": { "attribute": "activityid", "operator": "eq", "value": activity['parentemailid'] || activity.ID } }
                  ]
                }
              ],
              "link-entity": [
                {
                  "@": { "name": "contact", "from": "contactid", "to": "partyid" },
                  "attribute": [
                    { "@": { "name": "contactid", "alias": "indskr_contactid" } },
                    { "@": { "name": "firstname", "alias": "contact_firstname" } },
                    { "@": { "name": "lastname", "alias": "contact_lastname" } },
                    { "@": { "name": "mobilephone", alias: 'contact_mobilephone' } },
                    { "@": { "name": "indskr_facebookpsid", alias: 'contact_indskr_facebookpsid' } },
                    { "@": { "name": "statuscode", "alias": "contact_statuscode" } }
                  ]
                },
              ]
            }), null);
            rawActivity.emailActivityParties = emailActivityParties.map(p => ({ emailAddresses: [], ...p }));
          }
        } else if ((activity.type === ActivityType.Appointment && activity.indskr_type !== ActivityTypeCode.STORE_CHECK) || activity.type === ActivityType.PhoneCall) {
          this.events.publish("savedProductsForCovisitor", rawActivity?.activityProducts)
          if (activity.type === ActivityType.PhoneCall && (activity as PhoneActivity).state !== 1) {
            if (rawActivity && rawActivity.statecode === 1) {
              // Completed Phone Call Activity.
              // We have to update the same in Plan tab when applicable.
              // Scenario: In plan tab, when we select a phone call activity which is locally opened but completed already.
              // once we get the response we can verify and update the same.
              this.events.publish('phoneCallActivityCompleted', activity);
            }
          }
          configFields = await this.updateActivityForConfigField(activity, rawActivity, activity.type);
        }
        const hasTrackAction = rawActivity ? rawActivity.hasOwnProperty('track_action') : false;
        if (rawActivity && (!hasTrackAction || (hasTrackAction && rawActivity.track_action !== TrackAction.Deleted))) {
          if (activity.type != ActivityType.Email && activity.indskr_type === ActivityTypeCode.STORE_CHECK) {
            const activityId = rawActivity.activityId || rawActivity.activityid || null;

            if (activityDoc && activityDoc._id && activityDoc._rev) {
              activity._id = rawActivity._id = activityDoc._id;
              activity._rev = rawActivity._rev = activityDoc._rev;
            } else {
              rawActivity._id = DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY;
              activity._id = rawActivity._id = activityId ? rawActivity._id + activityId : null;
            }

            if (!rawActivity._id) {
              console.error('updateActivityDetails: activity ID not provided!');
              return;
            }
            if (!rawActivity['activitytypecode']) rawActivity['activitytypecode'] = 'appointment';
            // if (!activityDoc) activityDoc = rawActivity;
            activity = new StoreCheckActivity(rawActivity);

            activity.lastUpdatedTime = rawActivity.lastUpdatedTime = new Date().getTime();
            await this._appendStoreCheckDetailsToActivity(activity, rawActivity, true);

          } else if (type === 'appointment') {
            const activityId = rawActivity.activityId || rawActivity.activityid || null;

            if (activityId !== activity.ID) {
              console.warn('updateActivityDetails: activity ID does not match!');
              return;
            }

            if (activityDoc && activityDoc._id && activityDoc._rev) {
              activity._id = rawActivity._id = activityDoc._id;
              activity._rev = rawActivity._rev = activityDoc._rev;
            } else {
              rawActivity._id = DB_KEY_PREFIXES.MEETING_ACTIVITY;
              activity._id = rawActivity._id = activityId ? rawActivity._id + activityId : null;
            }

            if (!rawActivity._id) {
              console.error('updateActivityDetails: activity ID not provided!');
              return;
            }
            // if(!activityDoc)
              activityDoc = rawActivity;
            // Need to copy runtime variables
            const replacingActivity = new AppointmentActivity(activityDoc);
            this.activityService.copyRuntimeActivityVariables(activity, replacingActivity);
            activity = replacingActivity;

            activity.lastUpdatedTime = rawActivity.lastUpdatedTime = new Date().getTime();
            await this._appendMeetingDetailsToActivity(activity as AppointmentActivity, rawActivity, true);

          } else if (type === 'email') {
            let indexOfEmailActivity;
            this.activityService.activities.map((o, index) => {
              if (o.ID == rawActivity['activityid']) {
                indexOfEmailActivity = index
              }
            });
            // setting channel type for activity which is not in local, like child record of email activity
            this.getChannelType(rawActivity);

            if (useSelectedActivity) {
              this.activityService.selected = new EmailActivity(rawActivity);
              this.emailService.selectedActivity = <EmailActivity>this.activityService.selectedActivity;
              this.activityService.activities[indexOfEmailActivity] = this.activityService.selectedActivity;
            } else {
              this.emailService.selectedActivity = new EmailActivity(rawActivity);
              this.events.publish('updateEmbeddedActivity', this.emailService.selectedActivity);
              this.activityService.activities[indexOfEmailActivity] = this.emailService.selectedActivity;
            }
            this.activityDetailsLoaded = true;
            this.emailService.setCurrentEmail(this.emailService.selectedActivity);
            if (!this.uiService.toolsActivityActive) {
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            if (activityDoc && activityDoc._id && activityDoc._rev) {
              rawActivity['lastUpdatedTime'] = new Date().getTime();
              await this.disk.updateOrInsert(activityDoc._id, doc => rawActivity);
            }

            loader.dismiss();
          } else if (type === 'phonecall') {
            const activityId = rawActivity.activityId || rawActivity.activityid || null;

            if (activityId !== activity.ID) {
              console.warn('updateActivityDetails: activity ID does not match!');
              return;
            }

            if (activityDoc && activityDoc._id && activityDoc._rev) {
              activity._id = rawActivity._id = activityDoc._id;
              activity._rev = rawActivity._rev = activityDoc._rev;
            } else {
              rawActivity._id = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY;
              activity._id = rawActivity._id = activityId ? rawActivity._id + activityId : null;
            }

            if (!rawActivity._id) {
              console.error('updateActivityDetails: activity ID not provided!');
              return;
            }
            const conflictingActivityIds = activity.conflictingActivityIds;
            activity = new PhoneActivity(rawActivity);
            activity.conflictingActivityIds = conflictingActivityIds;
            activity.lastUpdatedTime = rawActivity.lastUpdatedTime = new Date().getTime();

            await this._appendPhoneCallDetailsToActivity(activity as PhoneActivity, rawActivity, true);
          }
          else if (type === 'followuptask') {
            await this._appendFollowUpDetailsToActivity((activity as FollowUpActivity), rawActivity);
            await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, appendActivityDetails: true }, [activity as FollowUpActivity], new Date().getTime()).then(succ => {
              // let agendaFooterService = this.injector.get(AgendaFooterService)
              // && agendaFooterService.actSegment == 'agenda'

              if (!this.uiService.toolsActivityActive ) {
                this.events.publish("refreshAgenda");
              }
              else {
                this.uiService.agendaRefreshRequired = true;
              }
            }).catch(err => {
              //To Do error Handling
            });
            loader.dismiss();
          } else if (type === 'order') {
            this._appendOrderDetailsToActivity((activity as OrderActivity), rawActivity);
            await this.orderActivityDataService.updateOrderActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, appendActivityDetails: true }, [activity as OrderActivity], new Date().getTime()).then(succ => {
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');
              } else this.uiService.agendaRefreshRequired = true;
            }).catch(err => {
              //To Do error Handling
            });;
            loader.dismiss();
          } else if (type === 'surgeryorder') {
            this._appendSurgeryOrderDetailsToActivity((activity as SurgeryOrderActivity), rawActivity);
            let action: OperationDetail = {
              onDynamics: false,
              onLocalDatabase: true,
              onLocalCopy: true,
              appendActivityDetails: true,
            };
            if(rawActivity['assetbooking_statuscode'] && rawActivity['assetbooking_statuscode']!= 548910000)
            {
              action.operationDetail = {
                code: 'updateOnReopenedAssetBooking',
                message: "REAL TIME SURGERY ORDERS UPDATE"
              }
            }
            await this.surgeryOrderActivityDataService.updateOrderActivity(action, [activity as SurgeryOrderActivity], new Date().getTime()).then(succ => {
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');
              } else this.uiService.agendaRefreshRequired = true;
            }).catch(err => {
              //To Do error Handling
            });
          } else if (type === 'proceduretracker') {
            this._appendProcedureTrackerDetailsToActivity((activity as ProcedureTrackerActivity), rawActivity);
            await this.procedureTrackerDataService.updateOrderActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, appendActivityDetails: true }, [activity as ProcedureTrackerActivity], new Date().getTime()).then(succ => {
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');
              } else this.uiService.agendaRefreshRequired = true;
            }).catch(err => {
              //To Do error Handling
            });;
          } else if (type == 'timeoff' || type == 'totappointment') {
            const conflictingActivityIds = activity.conflictingActivityIds;
            if (rawActivity['statuscode'] == 100000004) {
              this.activityService.selected = new TimeOffActivity(rawActivity, ActivityType.TimeOff);
              this.activityService.selectedActivity.conflictingActivityIds = conflictingActivityIds;
            } else {
              this.activityService.selected = new TimeOffActivity(rawActivity, ActivityType.TimeOffRequest);
              this.activityService.selectedActivity.conflictingActivityIds = conflictingActivityIds;
            }
            this.timeOffService.setSelectedTot(<TimeOffActivity>this.activityService.selectedActivity);
            let indexOfActivity;
            this.activityService.activities.map((o, index) => {
              if (o.ID == rawActivity['indskr_timeoffrequestid']) {
                indexOfActivity = index
              }
            });
            this.activityService.activities[indexOfActivity] = this.activityService.selectedActivity;
            this.activityDetailsLoaded = true;
            //Update DB with latest response from service
            let dbKey = activity._id = DB_KEY_PREFIXES.MY_TIMEOFF + activity.ID;
            let activityDoc = await this.disk.retrieve(dbKey, true);
            if (activityDoc) {
              this.sanitizeTimeOffRawObject(rawActivity);
              rawActivity['lastUpdatedTime'] = new Date().getTime();
              await this.disk.updateOrInsert(activityDoc._id, doc => rawActivity);
            } else {
              dbKey = activity._id = DB_KEY_PREFIXES.TEAM_TIMEOFF + activity.ID;
              activityDoc = await this.disk.retrieve(dbKey, true);
              if (activityDoc) {
                this.sanitizeTimeOffRawObject(rawActivity);
                rawActivity['lastUpdatedTime'] = new Date().getTime();
                await this.disk.updateOrInsert(activityDoc._id, doc => rawActivity);
              }
            }
            loader.dismiss();
          }
          else {
            let indexOfSampleActivity;
            this.activityService.activities.map((o, index) => {
              if (o.ID == rawActivity['activityid']) {
                indexOfSampleActivity = index
              }
            })
            if (useSelectedActivity) {
              this.activityService.selected = new SampleActivity(rawActivity);
              this.activityService.activities[indexOfSampleActivity] = this.activityService.selectedActivity;
            }
            else {
              this.samplingService.inMeetingAllocationActivity = new SampleActivity(rawActivity);
              this.events.publish('updateEmbeddedActivity', this.samplingService.inMeetingAllocationActivity);
              this.activityService.activities[indexOfSampleActivity] = this.samplingService.inMeetingAllocationActivity;
            }
            this.activityDetailsLoaded = true;
            this.events.publish('selectedActivityChangedInBackround');
            if (activityDoc && activityDoc._id && activityDoc._rev) {
              rawActivity['lastUpdatedTime'] = new Date().getTime();
              await this.disk.updateOrInsert(activityDoc._id, doc => rawActivity);
            }
          }
        }
        return activity;
      }
      catch (error) {
        if (loader) {
          loader.dismiss();
        }
        console.error("updateActivityDetails: ", error);
      }
    }
  }

  private async getAccountPlansMeeting(activityId: string) {
    let fetchXml = fetchQueries.fetchAccountPlansByMeetingId;
    fetchXml = fetchXml.replace('{0}', activityId);
    return await this.dynamics.executeFetchQuery('indskr_activitymeetings', fetchXml);
  }

  public getChannelType(raw: any) {
    if (raw['channelType']) {
      return;
    } else {
      const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === raw['indskr_channel']);
      switch (channel.activityType) {
        case ChannelActivityType.SMS:
          raw['channelType'] = ChannelType.SMS;
          break;
        case ChannelActivityType.EMAIL:
          raw['channelType'] = ChannelType.EMAIL;
          break;
        case ChannelActivityType.FACEBOOK:
          raw['channelType'] = ChannelType.FACEBOOK;
          break;
        case ChannelActivityType.WHATSAPP:
          raw['channelType'] = ChannelType.WHATSAPP;
          break;
        default:
          console.log('channel type not found');
          break;
      }
    }
  }

  public async _appendFollowUpDetailsToActivity(activity: FollowUpActivity, rawDoc: object = null) {
    if (rawDoc) {
      if (rawDoc['subject']) {
        activity.subject = rawDoc['subject'];
      }

      if (rawDoc['scheduledstart']) {
        activity.scheduledStart = new Date(parseInt(rawDoc['scheduledstart']));
        activity.scheduledStartLocale = activity.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      }

      if (rawDoc['scheduledend']) {
        activity.scheduledEnd = new Date(parseInt(rawDoc['scheduledend']));
      }
      if (rawDoc['indskr_account']) {
        activity.accountId = rawDoc['indskr_account'];
      }
      if (rawDoc['accountName']) {
        activity.accountNameString = rawDoc['accountName'];
      }
      if (rawDoc['indskr_accountplan']) {
        activity.accountPlanId = rawDoc['indskr_accountplan'];
      }
      if (rawDoc['indskr_plantype']) {
        activity.planType = rawDoc['indskr_plantype'];
      }
      if (rawDoc['description']) {
        activity.description = rawDoc['description'];
      }

      activity.followupActionTypeID = rawDoc['indskr_followupactiontype'];
      activity.followupActionTypeName = rawDoc['indskr_followupactiontypename'];
      activity.priorityID = rawDoc['indskr_priority'];


      if (rawDoc['accountplanName']) {
        activity.accountPlanNameString = rawDoc['accountplanName'];
      }
      if (rawDoc['ownerName']) {
        activity.ownerNameString = rawDoc['ownerName'];
      }
      if (rawDoc['indskr_contact']) {
        activity.contactId = rawDoc['indskr_contact'];
      }
      if (rawDoc['contactName']) {
        activity.contactNameString = rawDoc['contactName'];
      }
      if (!isNaN(rawDoc['statecode'])) {
        activity.state = rawDoc['statecode'];
      }
      if (rawDoc['statuscode']) {
        activity.status = rawDoc['statuscode'];
        if (rawDoc['statuscode'] == 5) {
          activity.statusString = 'Completed';
        }
      }

      if(rawDoc['appointmentSubject']){
        activity.appointmentSubject = rawDoc['appointmentSubject'];
      }
      if(rawDoc['indskr_appoinmentid']){
        activity.appointmentId = rawDoc['indskr_appoinmentid'];
      }
      if(rawDoc['offlineMeetingId']){
        activity.offlineMeetingId = rawDoc['offlineMeetingId'];
      }

      if (Array.isArray(rawDoc['taskUsersList']) && rawDoc['taskUsersList'].length > 0) {
        activity.assignedTo = [];
        rawDoc['taskUsersList'].forEach(user => {
          if (!(user['statecode'] && user['statuscode'] && parseInt(user['statecode']) == 1 && parseInt(user['statuscode']) == 2))
            activity.assignedTo.push(new TaskUser(user));
        });
      }
      if (Array.isArray(rawDoc['notes']) && rawDoc['notes'].length > 0) {
        let followUpNotes = [];
        rawDoc['notes'].forEach(note => {
          if (note.hasOwnProperty('filesize') && note['filesize'] > 0 && note.hasOwnProperty('filename') && !_.isEmpty(note['filename'])) {
            note['isdocument'] = true
            let index = activity.followUpNotes.findIndex(taskNote=> taskNote.noteId == note['annotationid']);
            if (index > -1) {
              let onDevicenote = activity.followUpNotes[index];;
              if (onDevicenote.documentDataURL && !_.isEmpty(onDevicenote.documentDataURL)) {
                note['documentbody'] = onDevicenote.documentDataURL
              }
            }
          }
          followUpNotes.push(new IONote(note));
        });
        activity.followUpNotes = followUpNotes;
        activity.followUpNotes.sort((a, b) => {
          return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
        });
      }
      activity.assignmentHistory = [];
      if (!_.isEmpty(rawDoc['assignmentHistory'])) {
        rawDoc['assignmentHistory'].forEach(r => {
          activity.assignmentHistory.push(new AssignmentHistory(r));
        })
      }
      activity.products = [];
      if (Array.isArray(rawDoc['products']) && rawDoc['products'].length > 0) {
        rawDoc['products'].forEach(product => {
          activity.products.push({ productID: product['productId'], productName: product['productName'], taskProductID: product['indskr_taskproductid'] });
        });

        activity.products = activity.products.sort((a, b) => {
          if ((a.productName.toLowerCase() > b.productName.toLowerCase())) {
            return 1;
          }
          if (b.productName.toLowerCase() > a.productName.toLowerCase()) {
            return -1;
          }
        });
      }

      if (rawDoc['pendingPushToDynamics']) {
        activity.pendingPushToDynamics = rawDoc['pendingPushToDynamics'];
      }

      setTimeout(() => {
        this.events.publish('followupTask:updateFollowupActivity');
      }, 200);
    }

  }

  public async _appendOrderDetailsToActivity(activity: OrderActivity, rawDoc: object = null) {
    if (rawDoc) {
      if (rawDoc['name']) {
        activity.name = rawDoc['name'];
      }
      if (rawDoc['ownerid']) {
        activity.ownerId = rawDoc['ownerid'];
      }
      if (rawDoc['ownerName']) {
        activity.ownerNameString = rawDoc['ownerName'];
      }
      if (rawDoc['ordernumber']) {
        activity.orderNumber = rawDoc['ordernumber'];
        activity.subject = rawDoc['ordernumber'];
      }
      if (rawDoc['indskr_country']) {
        activity.countryId = rawDoc['indskr_country'];
      }
      if (rawDoc['countryName']) {
        activity.countryNameString = rawDoc['countryName'];
      }
      if (rawDoc['customerid']) {
        activity.accountId = rawDoc['customerid'];
      }
      if (rawDoc['pendingPushToDynamics']) {
        activity.pendingPushToDynamics = rawDoc['pendingPushToDynamics'];
      } else {
        activity.pendingPushToDynamics = false;
      }
      if (rawDoc['customerName']) {
        activity.accountNameString = rawDoc['customerName']
      }
      if (rawDoc['quoteid']) {
        activity.quoteNumberId = rawDoc['quoteid'];
      }
      if (rawDoc['quoteName']) {
        activity.quoteNumberString = rawDoc['quoteName'];
      }
      if (rawDoc['discountpercentage']) {
        activity.elligibleDiscount = parseInt(rawDoc['discountpercentage']);
        activity.formattedDiscount = activity.elligibleDiscount + "%";
      }
      if (rawDoc['totalamount']) {
        activity.total = parseFloat(rawDoc['totalamount']);
      }
      if (rawDoc['formattedTotalAmount']) {
        activity.formattedTotalAmount = rawDoc['formattedTotalAmount'];
      }
      if (!isNaN(rawDoc['statecode'])) {
        activity.state = parseInt(rawDoc['statecode']);
      }
      if (rawDoc['statuscode']) {
        activity.status = parseInt(rawDoc['statuscode']);
      }
      if (rawDoc['pricelevelid']) {
        activity.priceListId = rawDoc['pricelevelid'];
      }
      if(rawDoc['createdon']){
        activity.createdDate = new Date(parseInt(rawDoc['createdon']));
      }
      if(rawDoc['approvalActivityId']){
        activity.approvalActivityId = rawDoc['approvalActivityId'];
      }
      if (rawDoc['products'] && rawDoc['products'].length > 0) {
        activity.products = [];
        rawDoc['products'].forEach(product => {
          activity.products.push(new OrderProductClass({
            productId: product['productid'],
            productName: (product['productName']) ? product['productName'] : '',
            total: (product.extendedamount) ? product.extendedamount : 0,
            unitPrice: (product.priceperunit) ? product.priceperunit : 0,
            quantity: (product.quantity) ? product.quantity : 0,
            uomid: (product['uomid']) ? product['uomid'] : '',
            formattedTotalAmount: (product['formattedExtendedamount']) ? product['formattedExtendedamount'] : '',
            isDeleted: (product['isDeleted']) ? product['isDeleted'] : false,
          }));
        });
      }
    }
  }

  public async _appendProcedureTrackerDetailsToActivity(activity: ProcedureTrackerActivity, rawDoc: object = null) {
    if (rawDoc) {

      if (rawDoc['ownerid']) {
        activity.ownerId = rawDoc['ownerid'];
      }
      if (rawDoc['ownerName']) {
        activity.ownerNameString = rawDoc['ownerName'];
      }
      if (rawDoc['customerid']) {
        activity.accountId = rawDoc['customerid'];
      }
      if (rawDoc['pendingPushToDynamics']) {
        activity.pendingPushToDynamics = rawDoc['pendingPushToDynamics'];
      } else {
        activity.pendingPushToDynamics = false;
      }
      if (rawDoc['customerName']) {
        activity.accountNameString = rawDoc['customerName']
      }
      if (!isNaN(rawDoc['statecode'])) {
        activity.state = parseInt(rawDoc['statecode']);
      }
      if (rawDoc['statuscode']) {
        activity.status = parseInt(rawDoc['statuscode']);
      }
      if (rawDoc['name']) {
        activity.subject = rawDoc['name'];
      }
      if (rawDoc['modifiedby']) {
        activity.modifiedby = rawDoc['modifiedby'];
      }
      if (rawDoc['modifiedon']) {
        activity.modifiedon = rawDoc['modifiedon'] + '';
      }
      if (rawDoc['modifiedByName']) {
        activity.modifiedByName = rawDoc['modifiedByName'] + '';
      }
      if (rawDoc['divisionId']) {
        activity.divisionId = rawDoc['divisionId'] + '';
      }
      if (rawDoc['divisionName']) {
        activity.divisionName = rawDoc['divisionName'] + '';
      }
      if (rawDoc['indskr_scheduleddate']) {
        activity.scheduledStart = new Date(parseInt(rawDoc['indskr_scheduleddate']));
      }
      if (rawDoc['indskr_scheduledenddate']) {
        activity.scheduledEnd = new Date(parseInt(rawDoc['indskr_scheduledenddate']));
      }
      if (rawDoc['accountStatusCode']) {
        if (rawDoc['accountStatusCode'] === 2 || rawDoc['accountStatusCode'] == 548910003 || rawDoc['accountStatusCode'] == 548910010 || rawDoc['accountStatusCode'] == 548910011 || rawDoc['accountStatusCode'] == 548910012 || rawDoc['accountStatusCode'] == 548910013) {
          activity.isAccountActive = false;
        } else {
          activity.isAccountActive = true;
        }
      }
      if (rawDoc['procedureTrackers'] && !_.isEmpty(rawDoc['procedureTrackers'])) {
        const procedureTrackers = rawDoc['procedureTrackers'].map(pt => new ProcedureTracker(pt));
        activity.procedureTrackers = _.orderBy(procedureTrackers, ['createdon'], ['desc']);
      }
      if (rawDoc['coOwners']) {
        activity.coOwners = rawDoc['coOwners'];
      }
      if (rawDoc['opportunities']) {
        activity.opportunities = rawDoc['opportunities'];
      }
    }
  }

  public async _appendSurgeryOrderDetailsToActivity(activity: SurgeryOrderActivity, rawDoc: object = null) {
    if (rawDoc) {
      const isAssetBookingEnabled = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_assetavailabilitylogic'] == 548910001;
      if (rawDoc['ownerid']) {
        activity.ownerId = rawDoc['ownerid'];
      }
      if (rawDoc['ownerName']) {
        activity.ownerNameString = rawDoc['ownerName'];
      }
      if (rawDoc['customerid']) {
        activity.accountId = rawDoc['customerid'];
      } else {
        activity.accountId = null
      }
      if (rawDoc['pendingPushToDynamics']) {
        activity.pendingPushToDynamics = rawDoc['pendingPushToDynamics'];
      } else {
        activity.pendingPushToDynamics = false;
      }
      if (rawDoc['customerName']) {
        activity.accountNameString = rawDoc['customerName']
      } else {
        activity.accountNameString = null
      }
      if (!isNaN(rawDoc['statecode'])) {
        activity.state = parseInt(rawDoc['statecode']);
      }
      if (rawDoc['statuscode']) {
        activity.status = parseInt(rawDoc['statuscode']);
      }
      if (rawDoc['indskr_surgeryproduct']) {
        activity.surgeryId = rawDoc['indskr_surgeryproduct'];
      } else {
        activity.surgeryId = '';
      }
      if (rawDoc['indskr_surgeryproductName']) {
        activity.surgeryNameString = rawDoc['indskr_surgeryproductName'];
      } else {
        activity.surgeryNameString = '';
      }
      if (rawDoc['name']) {
        activity.subject = rawDoc['name'];
      }
      // if (rawDoc['indskr_location']) {
      //   activity.location = rawDoc['indskr_location'];
      // }
      if (rawDoc['indskr_scheduleddate']) {
        activity.scheduledStart = new Date(parseInt(rawDoc['indskr_scheduleddate']));
      }
      if (rawDoc['indskr_scheduledenddate']) {
        activity.scheduledEnd = new Date(parseInt(rawDoc['indskr_scheduledenddate']));
      }
      if (rawDoc['accountStatusCode']) {
        if (rawDoc['accountStatusCode'] === 2 || rawDoc['accountStatusCode'] == 548910003 || rawDoc['accountStatusCode'] == 548910010 || rawDoc['accountStatusCode'] == 548910011 || rawDoc['accountStatusCode'] == 548910012 || rawDoc['accountStatusCode'] == 548910013) {
          activity.isAccountActive = false;
        } else {
          activity.isAccountActive = true;
        }
      }
      if (rawDoc['modifiedByName']) {
        activity.modifiedBy = rawDoc['modifiedByName'];
      }
      if (rawDoc['modifiedon']) {
        activity.modifiedOn = new Date(parseInt(rawDoc['modifiedon']));
      }
      if (rawDoc['products'] && rawDoc['products'].length > 0) {
        activity.products = [];
        rawDoc['products'].forEach(product => {
          let prodObj = new OrderProductClass({
            productId: product['productid'],
            productName: (product['productName']) ? product['productName'] : '',
            total: (product.extendedamount) ? product.extendedamount : 0,
            unitPrice: (product.priceperunit) ? product.priceperunit : 0,
            quantity: (product.quantity) ? product.quantity : 0,
            uomid: (product['uomid']) ? product['uomid'] : '',
            formattedTotalAmount: (product['formattedExtendedamount']) ? product['formattedExtendedamount'] : '',
            isDeleted: (product['isDeleted']) ? product['isDeleted'] : false,
            productCategoryId: (product['productCategoryId']) ? product['productCategoryId'] : '',
            productCategoryName: (product['productCategoryName']) ? product['productCategoryName'] : ''
          });
          activity.products.push(prodObj);
        });
      } else {
        activity.products = [];
      }
      if (rawDoc['productCategory'] && rawDoc['productCategory'].length > 0) {
        activity.productCategory = [];
        activity.competitorProducts = [];
        rawDoc['productCategory'].forEach(product => {
          if (product['isCompetitor']) {
            activity.competitorProducts.push(new OrderProductClass({
              productId: product['productId'],
              productName: (product['productName']) ? product['productName'] : '',
            }));
          } else {
            activity.productCategory.push(new OrderProductCategory({
              productCategoryId: product.productId,
              productCategoryName: product.productName,
            }));
          }
        });
      } else {
        activity.productCategory = [];
        activity.competitorProducts = [];
      }

      if (rawDoc['contactorders'] && rawDoc['contactorders'].length > 0) {
        activity.customers = [];
        rawDoc['contactorders'].forEach(contact => {
          activity.customers.push(new OrderContact({
            id: contact['id'] || '',
            firstName: contact['firstName'] || '',
            title: contact['title'] || '',
            middleName: contact['middleName'] || '',
            lastName: contact['lastName'] || '',
            stateCode: contact['stateCode'],
            statusCode: contact['statusCode'],
          }));
        });
      } else {
        activity.customers = [];
      }
      if (rawDoc['notes'] && rawDoc['notes'].length > 0) {
        activity.procedureNotes = [];
        rawDoc['notes'].forEach(note => {
          activity.procedureNotes.push(new IONote({
            annotationid: note['annotationid'] || '',
            createdon: note['createdon'] || null,
            notetext: note['notetext'] || '',
            ownerid: note['ownerid'] || '',
            ownerName: note['ownerName'] || '',
            filename: note['filename'] || '',
            documentbody: note['documentbody'] || '',
            mimetype:  note['mimetype'] || '',
            filesize:  note['filesize'] || '',
            isdocument : note['isdocument'] || '',
            hasDocument: note['hasDocument'] || false
          }));
        });
      }

      if (rawDoc['coOwners'] && rawDoc['coOwners'].length > 0) {
        activity.coOwners = rawDoc['coOwners'];
      }

      if (rawDoc['marketingBusinessPlanId']) {
        activity.marketingBusinessPlanId = rawDoc['marketingBusinessPlanId'];
      }else{
        activity.marketingBusinessPlanId = '';
      }

      if (rawDoc['marketingBusinessPlanName']) {
        activity.marketingBusinessPlanName = rawDoc['marketingBusinessPlanName'];
      }else{
        activity.marketingBusinessPlanName  = ''
      }

      if (rawDoc['indskr_procedurecontract']) {
        activity.indskr_procedurecontract = rawDoc['indskr_procedurecontract'];
      } else {
        activity.indskr_procedurecontract = '';
      }

      if (rawDoc['indskr_proceduretypeid']) {
        activity.indskr_proceduretype = rawDoc['indskr_proceduretypeid'];
        activity.indskr_proceduretypename = rawDoc['indskr_proceduretypename'];
      }

      if (rawDoc['indskr_proceduresubtypeid']) {
        activity.indskr_proceduresubtype = rawDoc['indskr_proceduresubtypeid'];
        activity.indskr_proceduresubtypename = rawDoc['indskr_proceduresubtypename'];
      }

      if (rawDoc['maximumnoofassistance']) {
        activity.maximumnoofassistance = rawDoc['maximumnoofassistance'];
        activity.noofassistanceavailed = rawDoc['noofassistanceavailed'];

        let payload: any = {
          indskr_maximumnoofassistance: rawDoc['maximumnoofassistance']
        }

        if (rawDoc['noofassistanceavailed']) {
          payload = { ...payload, indskr_noofassistanceavailed: rawDoc['noofassistanceavailed'] }
        }

        this.contractService.updateProcedureContract(activity.ID, payload);
      }

      if (rawDoc['indskr_procedurecontractname']) {
        activity.indskr_procedurecontractname = rawDoc['indskr_procedurecontractname'];
      }

      if (rawDoc['contractStartDate']) {
        activity.contractStartDate = new Date(rawDoc['contractStartDate']);
      }

      if (rawDoc['contractEndDate']) {
        activity.contractEndDate = new Date(rawDoc['contractEndDate']);
      }

      if (rawDoc['indskr_reasonforcancellationname']) {
        activity.indskr_reasonforcancellationname = rawDoc['indskr_reasonforcancellationname']
      }

      if (rawDoc['indskr_reasonforcancellation']) {
        activity.indskr_reasonforcancellation = rawDoc['indskr_reasonforcancellation']
      }

      if (rawDoc['indskr_cancellationreasonother']) {
        activity.indskr_cancellationreasonother = rawDoc['indskr_cancellationreasonother'];
      }

      if (rawDoc['usageType']) {
        activity.contractUsageType = rawDoc['usageType'];
      }

      if (rawDoc['contractStatus']) {
        activity.contractStatus = rawDoc['contractStatus'];
      }

      if (rawDoc['cancellationreason']) {
        activity.cancellationreason = rawDoc['cancellationreason']
      }
      if (rawDoc['opportunities']) {
        activity.opportunities = rawDoc['opportunities']
      }
      if(rawDoc["assetbooking_statuscode"]){
        activity.assetbooking_statuscode = rawDoc["assetbooking_statuscode"]
      }
      if(rawDoc["indskr_assetbookingid"]){
        activity.indskr_assetbookingid = rawDoc["indskr_assetbookingid"]
      }
      if(rawDoc['assets'] && (!isAssetBookingEnabled || (rawDoc["assetbooking_statuscode"] && rawDoc["assetbooking_statuscode"] == 548910000))) {
        activity.assets = rawDoc['assets']
      }

      activity.customersNameString = this.surgeryOrderActivityDataService.getCustomerString;
    }
  }

  async getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(id: string,): Promise<AppointmentActivity> {
    let activity: AppointmentActivity;
    if (!id) {
      return;
    }

    const _id = DB_KEY_PREFIXES.MEETING_ACTIVITY + id;
    const raw = await this.disk.retrieve(_id, true);
    if (!raw) {
      return;
    }

    activity = this.activityService.getActivityByID(id) as AppointmentActivity
        ?? new AppointmentActivity(raw);
    if (!activity) {
      return;
    }

    await this._appendMeetingDetailsToActivity(activity, raw);

    return activity;
  }

  async getNewDetailAppendedPhoneCallActivityInstanceFromLocalDB(id: string,): Promise<PhoneActivity> {
    let activity: PhoneActivity;
    if (!id) {
      return;
    }
    const _id = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + id;
    const raw = await this.disk.retrieve(_id, true);
    if (!raw) {
      return;
    }
    activity = this.activityService.getActivityByID(id) as PhoneActivity?? new PhoneActivity(raw);
    if (!activity) {
      return;
    }
    await this._appendPhoneCallDetailsToActivity(activity, raw);
    return activity;
  }

  /**
   * Updates our activity with new information from details endpoint
   *
   * @private
   * @param {Activity} activity
   * @param {object} response
   * @memberof ActivityDataService
   */
  public async _appendStoreCheckDetailsToActivity(
    activity: Activity,
    response: object = null,
    shouldUpdateDB = false,
  ) {
    //Find accounts
    let accounts: any[] = [];

    if (response && response['activityAccounts'] && Array.isArray(response['activityAccounts'])) {
      response['activityAccounts'].map(rawAccount => {
        /*let account = this.accountService.getAccountById(rawAccount.indskr_accountid);
        if (account) {
          accounts.push(account);
        } else {
          // Maybe removed. But if this meeting is completed, add in.
          if (rawAccount.indskr_name && rawAccount.indskr_accountid) {
            rawAccount.accountid = rawAccount.indskr_accountid;
            rawAccount.name = rawAccount.indskr_name;
            rawAccount.statuscode = 2;
            accounts.push(new Account(rawAccount));
          }
        };*/
        if (rawAccount['indskr_accountid']) {
          accounts.push({
            indskr_accountid: rawAccount['indskr_accountid'],
            indskr_name: rawAccount['indskr_name']
          })
        }
      });
    }

    if (response && activity instanceof StoreCheckActivity) {
      activity.accounts = accounts;
      activity.photoAttachments = response['photoAttachments'] ?? [];
      if (shouldUpdateDB) {
        // Update local meeting detail document
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity);
        const index = this.activityService.activities?.findIndex(act => act.ID === activity.ID);
        if (index >= 0) this.activityService.activities[index] = activity;

        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
    }
    if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
      this.activityService.selectedActivity = activity;
      this.storeCheckService.setCurrentStoreCheckActivity(this.activityService.selectedActivity);
      this.activityDetailsLoaded = true;
    }
  }

  /**
   * Updates our activity with new information from details endpoint
   *
   * @private
   * @param {Activity} activity
   * @param {object} response
   * @memberof ActivityDataService
   */
  public async _appendMeetingDetailsToActivity(
    activity: Activity,
    response: object = null,
    shouldUpdateDB = false,
    isInboundFlow = false
  ) {
    //Find contacts
    let contacts: Array<Contact> = [];

    if (response && response["contactAttendees"] && Array.isArray(response["contactAttendees"])) {
      let rawContacts = response["contactAttendees"] ? response["contactAttendees"] : undefined; // throwing that stupid error
      if (rawContacts) {
        rawContacts.map(rawContact => {
          let contact = this.contactService.getContactByID(rawContact.indskr_contactid);

          if (contact) {
            contact.join_date = rawContact['indskr_joineddate'] || "";
            contact.left_date = rawContact['indskr_leftdate'] || "";
            contact.isguest = rawContact['indskr_isguest'] || false;
            contact.isremote = rawContact['indskr_isremote'] || false;
            contact.isleftmanually = rawContact['indskr_leftmanually'] || false;
            contact.indskr_sequence = rawContact['indskr_sequence'] || 0;
            if (isInboundFlow) {
              // Only set joined state when initiating inbound flow
              contact.contactJoinedState = rawContact['indskr_joinstatus'];
            }
            contacts.push(contact);
          } else {
            // This is an inbound meeting contact / a not synced contact that's not on my contact list. Could be an exising contact or a guest.
            // Create a contac object and add only to the activity so that it only appears on activity detail page.
            let rawObj = {
              'contactid': rawContact['indskr_contactid'],
              'firstname': rawContact['indskr_name'],
              'indskr_isguest': rawContact['indskr_isguest'],
              'indskr_joineddate': rawContact['indskr_joineddate'],
              'indskr_leftdate': rawContact['indskr_leftdate'],
              'indskr_isremote': rawContact['indskr_isremote'] || true,
              'indskr_leftmanually': rawContact['indskr_leftmanually'] || false,
              'indskr_sequence': rawContact['indskr_sequence'] || 0
            };
            contact = new Contact(rawObj);
            //Since its transactional data, this contact is not mapped to my position, setting `isActive=true`
            contact.isActive = true;
            if (rawContact['statuscode'] && rawContact['statuscode'] == 1) {
              contact.isActive = false;
            }
            if (isInboundFlow) {
              // Only set joined state when initiating inbound flow
              contact.contactJoinedState = rawContact['indskr_joinstatus'];
            }
            contacts.push(contact);
          }
        });
      }
    }

    //
    await this.clubActivityWithConfigFields(response, activity, ActivityType.Appointment);
    //

    //Find presentations
    //@todo presentations
    let presentations: Array<Presentation | Resource> = [];

    if (response && response['activityPlaylists'] && Array.isArray(response['activityPlaylists'])) {
      response['activityPlaylists'].map(content => {

        let pres: Presentation | Resource;
        if (content && content.indskr_iopresentationid) {
          pres = this.presentationService.getPresentationById(content.indskr_iopresentationid);
        } else if (content && (content['indskr_ioresourceid'] || content['indskr_iodocumentid'])) {
          pres = this.resourceService.getResourceById(content['indskr_ioresourceid'], content['indskr_iodocumentid']);
        }
        if (pres) presentations.push(pres);
      });

      if (isInboundFlow) {
        // sort presentations alphanumerically
        presentations.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (b.name.toLowerCase() < a.name.toLowerCase()) {
            return 1;
          }
        });
      }
    }
    let offMeeting = null;
    if (this.activityService.hasOfflineMeetingPresentationData(activity.ID)) {
      offMeeting = await this.activityService.getOfflineMeetingPres(activity.ID);
    }
    if (offMeeting && offMeeting['activityPresentations']) {
      activity['activityPresentations'] = [];
      offMeeting['activityPresentations'].map(activityPres => {
        activity['activityPresentations'].push(new ActivityPresentation(activityPres));
      });
    }
    else if (response && response['activityPresentations'] && Array.isArray(response['activityPresentations'])) {
      activity['activityPresentations'] = []; //response['activityPresentations'].slice();
      response['activityPresentations'].map(activityPres => {
        activity['activityPresentations'].push(new ActivityPresentation(activityPres));
      });
    }
    if (offMeeting && offMeeting['activityResources']) {
      activity['activityResources'] = [];
      offMeeting['activityResources'].map(ar => {
        activity['activityResources'].push(new ActivityResource(ar));
      });
    }
    else if (response && response['activityResources'] && Array.isArray(response['activityResources'])) {
      activity['activityResources'] = [];
      response['activityResources'].map(ar => {
        activity['activityResources'].push(new ActivityResource(ar));
      });
    }

    //Find accounts
    let accounts: Array<Account> = [];

    if (response && response['activityAccounts'] && Array.isArray(response['activityAccounts'])) {
      response['activityAccounts'].map(rawAccount => {
        let account = this.accountService.getAccountById(rawAccount.indskr_accountid);
        if (account) {
          accounts.push(account);
        } else {
          // Maybe removed. But if this meeting is completed, add in.
          if (rawAccount.indskr_name && rawAccount.indskr_accountid) {
            rawAccount.accountid = rawAccount.indskr_accountid;
            rawAccount.name = rawAccount.indskr_name;
            rawAccount.statuscode = 2;
            accounts.push(new Account(rawAccount));
          }
        };
      });
    }
    //CWD-1268 , CWD-1267 , hard reset because of the pass by reference nature of javascript , the updated UI model gets reflected
    //in the brands data model which ismapped to savedProducts(inconsistent data) and since we were mapping it without a reset hence the this bug

    if (!this.brandService.brands || this.brandService.brands.length === 0) {
      await this.brandDataService.getBrands(true);
    }

    this.brandService.deSelectAll();
    let brands: Array<Brand> = JSON.parse(JSON.stringify(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000'))));
    let savedProduct: Brand;
    let activityProducts = response ? response['activityProducts'] ? response['activityProducts'].filter(product => product['productStructure'] != 3 && !product['isCompetitor']) : [] : [];
    (activity as AppointmentActivity).competitorProducts = [];
    if(response && response['activityProducts']){
      response['activityProducts'].forEach(prod=> {
        if(prod['productStructure'] != 3 && prod['isCompetitor']){
          (activity as AppointmentActivity).competitorProducts.push(new ActivityCompetitorProduct({
            indskr_activityproductid: prod['indskr_activityproductid'] ? prod['indskr_activityproductid'] : '',
            indskr_geneeselected: prod['indskr_geneeselected'] ? prod['indskr_geneeselected'] : false,
            indskr_isautomaticallyselected: prod['indskr_isautomaticallyselected'] ? prod['indskr_isautomaticallyselected'] : false,
            indskr_productid: prod['indskr_productid'] ? prod['indskr_productid'] : '',
            indskr_sequence: prod['indskr_sequence'] ? prod['indskr_sequence'] : null,
            productName: prod['indskr_name'] ? prod['indskr_name'] : prod['productName'] ? prod['productName'] : '',
            productStructure: prod['productStructure'] ? prod['productStructure'] : '',
            isCompetitor: prod['isCompetitor'] ? prod['isCompetitor'] : false,
          }));
        }
      })
    }
    let activityProcedures = response ? response['activityProcedures'] ? response['activityProcedures'].map(product => new Brand(product)) : response['activityProducts'] ?
      response['activityProducts'].filter(product => product['productStructure'] == 3).map(product => new Brand(product)) : [] : [];


    /**
     * Find each brands from activityProducts in App's Master Brand
     * if found, isSelected to true and isAutoSelected = activityProduct[x].indskr_isautomaticallyseleted
     */
    if (activityProducts && Array.isArray(activityProducts)) {
      activityProducts.map((product, index) => {

        savedProduct = brands.find(brand => brand.ID === product.indskr_productid); // just for the brands Interface
        if (savedProduct) {
          savedProduct.priority = product.indskr_sequence === undefined ? index : product.indskr_sequence;
          savedProduct.isAutoSelected = product.indskr_isautomaticallyselected;
          savedProduct.isGenieSelected = product.indskr_geneeselected
          savedProduct.isSelected = true;
          //note the saved product returns the current state of the Model data UI , we just use it for mapping here , map the sequence as
          if (product.activityProductKeyMessages.length !== 0) {
            product.activityProductKeyMessages.forEach(keyMsg => {
              let mappedKeyMessages = savedProduct.keyMessages.find(key => (key.ID === keyMsg.indskr_keymessageid));
              if (mappedKeyMessages) { // since the meeting already exist in the history profile we need this check
                mappedKeyMessages.isAutoSelected = keyMsg.indskr_isautomaticallyselected;
                mappedKeyMessages.isSelected = true;
                mappedKeyMessages.isGenieSelected = keyMsg.indskr_geneeselected
              }
            });
          }
        } else {
          if (activity.isCompleted) {
            // If meeting is completed & the product doesn't exists (probably un-mapped), add the product to brand array
            // so that it can be at least displayed
            const unMappedProduct = new Brand(product, product.indskr_sequence);
            unMappedProduct.isAutoSelected = product.indskr_isautomaticallyselected;
            unMappedProduct.isSelected = true;
            if (product.activityProductKeyMessages.length > 0) {
              product.activityProductKeyMessages.forEach(keyMsg => {
                let mappedKeyMessages = unMappedProduct.keyMessages.find(key => (key.ID === keyMsg.indskr_keymessageid));
                if (mappedKeyMessages) {
                  mappedKeyMessages.isAutoSelected = keyMsg.indskr_isautomaticallyselected;
                  mappedKeyMessages.isSelected = true;
                }
              });
            }
            brands.push(unMappedProduct);
          }
        }
      });
      if (savedProduct !== undefined || brands.length !== 0) {
        let brand: Array<Brand> = brands.filter(value => {
          return value.isSelected === undefined || !value.isSelected;
        });
        brand.forEach((el: Brand, index: any) => el.priority = brands.length + index + 1);
        //sort the products and return it to UI
        //now sort the products based on the sequence(product priority) , if the sequence is not available default
        brands.sort((item1: any, item2: any) => {
          //sort criteria
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
      } else {
        brands.sort((item1: any, item2: any) => {
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
        this.events.publish('user:activity_products_not_found', brands);

      }
    } else {
      //no details, make basic array
      let brand = brands.filter(value => {
        return value.isSelected === undefined || !value.isSelected;
      });
      brand.forEach((el, index) => el.priority = brands.length + index + 1);
      //sort the products and return it to UI
      //now sort the products based on the sequence(product priority) , if the sequence is not available default
      brands.sort((item1, item2) => {
        //sort criteria
        if (item1.priority && item2.priority) {
          return item1.priority - item2.priority;
        }
      });
      activity.products = brands;
    }

    //Therapeutic area mapping
    let therapeuticAreas: any = [];
    if (response && response['activityTherapeuticAreas'] && Array.isArray(response['activityTherapeuticAreas'])) {
      response['activityTherapeuticAreas'].forEach(ta => {
        let newTa = new TherapeuticArea(ta);
        newTa.isSelected = true;
        therapeuticAreas.push(newTa);
        if (activity instanceof AppointmentActivity) {
          activity.activityTherapeuticAreas.push(newTa);
        }
      });
      console.log(therapeuticAreas);
    }

    if (response && response['activityDiseaseAreas'] && Array.isArray(response['activityDiseaseAreas'])) {
      (activity as AppointmentActivity).activityDiseaseAreas = [];
      response['activityDiseaseAreas'].forEach(da => {
        if (activity instanceof AppointmentActivity) {
          activity.activityDiseaseAreas.push(da);
        }
      });
    }

    if (response && response['activityProductIndications'] && Array.isArray(response['activityProductIndications']) && activity instanceof AppointmentActivity) {
      (activity as AppointmentActivity).activityProductIndications = [];
      response['activityProductIndications'].forEach(ta => {
          activity.activityProductIndications.push(ta);
      });
      activity.activityProductIndications.forEach(a=>{
        a.isSelected = true;
        if(a.activityProductIndicationKeyMessages){
          a.activityProductIndicationKeyMessages.forEach(b=>{
            b.isSelected = true;
          })
        }
      })
    }else if(response && !response['activityProductIndications'] && activity instanceof AppointmentActivity){
      activity.activityProductIndications = [];
    }

    if (response && response['activitySupportingMaterials'] && Array.isArray(response['activitySupportingMaterials'])) {
      (activity as AppointmentActivity).activitySupportingMaterials = [];
      response['activitySupportingMaterials'].forEach(sm => {
        if (activity instanceof AppointmentActivity) {
          activity.activitySupportingMaterials.push({
            id: sm['supportingMaterialId'],
            name: sm['supportingMaterialName'],
          });
        }
      });
    }

    let sharedResources = new Array<SharedResourceStatus>();

    let activitySharedResources = response && response['activitySharedResources'] ? response['activitySharedResources'] : [];

    if (activitySharedResources && Array.isArray(activitySharedResources)) {

      if (activitySharedResources.length > 0) {
        sharedResources.push(...activitySharedResources.reduce(
          (list: Map<string, SharedResourceStatus>, { indskr_contactid, indskr_resultstatus, indskr_resulttime, indskr_ioresourceid, indskr_iodocumentid, indskr_sharedtime }, currentIdx) => {

            let key = `${indskr_contactid}_${indskr_ioresourceid}_${indskr_iodocumentid}`;
            let existing = list.get(key);
            if (!existing) {
              let resource = this.resourceService.getResourceById(indskr_ioresourceid, indskr_iodocumentid);
              if (!resource) {
                resource = new Resource(activitySharedResources[currentIdx]);
              }
              let contact = this.contactService.getContactByID(indskr_contactid);

              if (!contact) { // if Contact Is not Found then try to find it within
                // let contacts  = response['contacts'];
                if (contacts && contacts.length > 0) {
                  contact = contacts.find(addedContact => addedContact.ID === indskr_contactid);
                }
              }

              if (!resource || !contact) return list;//
              existing = {
                resource,
                contact,
                statuses: []
              };
            }
            //If status is other than SENT, default add SENT status if not added yet
            if (indskr_resultstatus > 0 && indskr_sharedtime) {
              existing.statuses.push({ status: 1, date: new Date(+indskr_sharedtime) });
            }
            existing.statuses.push({ status: indskr_resultstatus + 1, date: new Date(+indskr_resulttime) });
            list.set(key, existing)
            return list;
          }, new Map<string, SharedResourceStatus>()).values());
      }
    }

    if(response && response.hasOwnProperty('activityOpportunities')){
      (activity as AppointmentActivity).associatedOpportunities = [];
      response['activityOpportunities'].forEach(item => {
        (activity as AppointmentActivity).associatedOpportunities.push({
          opportunityId: item['opportunityId'],
          opportunityName: item['opportunityName'],
        })
      });
    }

    if(response && response.hasOwnProperty('activityAccountPlans')){
      (activity as AppointmentActivity).associatedAccountPlans = [];
      response['activityAccountPlans'].forEach(item => {
        (activity as AppointmentActivity).associatedAccountPlans.push({
          accountPlanId: item['accountPlanId'],
          accountPlanName: item['accountPlanName'],
        })
      });
    }

    if(response && response.hasOwnProperty('activityEvents')){
      (activity as AppointmentActivity).associatedEvents = [];
      response['activityEvents'].forEach(item => {
        (activity as AppointmentActivity).associatedEvents.push({
          eventId: item['eventId'],
          name: item['eventName'] || item['name']
        })
      });
    }

    if(response && response.hasOwnProperty('gpsCheckInDetails')){
      (activity as AppointmentActivity).gpsCheckInDetails = {
        indskr_checkinstatus : response['gpsCheckInDetails'].indskr_checkinstatus,
        indskr_checkindatetime : response['gpsCheckInDetails'].indskr_checkindatetime,
        indskr_checkoutdatetime : response['gpsCheckInDetails'].indskr_checkoutdatetime,
        indskr_checkoutstatus : response['gpsCheckInDetails'].indskr_checkoutstatus,
        indskr_gpscheckindetailsid :response['gpsCheckInDetails'].indskr_gpscheckindetailsid,
        statuscode : response['gpsCheckInDetails'].statuscode
      }
    }
    if(response && response.hasOwnProperty('gpsActivityPhotos')){
      (activity as AppointmentActivity).gpsActivityPhotos = response['gpsActivityPhotos'];
    }

    if (response?.hasOwnProperty('activityAccompaniedUsers')) {
      (activity as AppointmentActivity).accompaniedUserList = (activity as AppointmentActivity).fetchUserList(response);
      (activity as AppointmentActivity).accompaniedUserString = (activity as AppointmentActivity).fetchUserString(response);
    }

    if (response?.hasOwnProperty('activityObjectives')) {
      (activity as AppointmentActivity).activityObjectives = _.orderBy(response['activityObjectives'], 'indskr_name') || [];
    }

    if (response?.hasOwnProperty('notes')) {
      const meetingNotesDoc = [];
      const hasMeetingNotesAccessFA = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_ACCESSIBILITY);
      const userId = this.authenticationService.user.systemUserID;
      const userTeams = this.authenticationService.user.teams;
      response['notes'].forEach((note:any) => {
        let showNote = true;
        const ownerId = note.ownerid || note.ownerId || '';
        if (userId !== ownerId && hasMeetingNotesAccessFA) {
          const foundTeams = userTeams.filter(team => team.members?.some(member => member.systemuserid.includes(ownerId)));
          showNote = !_.isEmpty(foundTeams) && !foundTeams.some(team => team.excludeFromNote);
        }
        if ((activity as AppointmentActivity).accompaniedUserList.some(user => user.id === userId)) {
          showNote = true;
        }
        if (showNote) {
          let existingNote = meetingNotesDoc.find(doc => doc.noteId === note.annotationid);
          if (!existingNote) {
            if (note.hasOwnProperty('filesize') && note.filesize > 0 && note.hasOwnProperty('filename') && !_.isEmpty(note.filename)) {
              note.isdocument = true;
              let index = activity.meetingNotes.findIndex(meetingNote => meetingNote.noteId === note.annotationid);
              if (index > -1) {
                let onDevicenote = activity.meetingNotes[index];
                if (onDevicenote.documentDataURL && !_.isEmpty(onDevicenote.documentDataURL)) {
                  note.documentbody = onDevicenote.documentDataURL;
                }
              }
            }
            meetingNotesDoc.push(new IONote(note));
          }
        }
      });
      activity.meetingNotes = [...meetingNotesDoc].sort((a, b) => isBefore(a.createdTime, b.createdTime) ? 1 : -1);
    }

    /*if(response && response.hasOwnProperty('activityAttachments')){
      (activity as AppointmentActivity).associatedAttachments = [];
      response['activityAttachments'].forEach(item => {
        (activity as AppointmentActivity).associatedAttachments.push({
          annotationId: item['annotationId'],
          attachmentName: item['attachmentName']
        })
      });
    }*/

    if (response && activity instanceof AppointmentActivity) {
      activity.update(response, contacts, accounts, presentations, brands, sharedResources, therapeuticAreas, activityProcedures);

      if(response['caseOutcome']){
        activity.meetingOutcomeId = response['caseOutcome'];
      }

      if(response['caseOutcomeName']){
        activity.meetingOutcomeString = response['caseOutcomeName'];
      }

      if(response['marketingBusinessPlanId']){
        activity.marketingBusinessPlanId = response['marketingBusinessPlanId'];
      }

      if(response['marketingBusinessPlanName']){
        activity.marketingBusinessPlanName = response['marketingBusinessPlanName'];
      }

      if(response['hasLinkedToMarketingBusinessPlan']){
        activity.hasLinkedToMarketingBusinessPlan = response['hasLinkedToMarketingBusinessPlan'];
      }

      if(response['indskr_reasonforcancellationname']){
        activity.indskr_reasonforcancellationname   = response['indskr_reasonforcancellationname']
      }

      if(response['indskr_reasonforcancellation']){
        activity.indskr_reasonforcancellation   = response['indskr_reasonforcancellation']
      }

      if(response['indskr_cancellationreasonother']){
        activity.indskr_cancellationreasonother   = response['indskr_cancellationreasonother'];
      }

      if(response['cancellationreason']){
        activity.cancellationreason   = response['cancellationreason']
      }

      if (shouldUpdateDB) {
        const accountVisitRecordCheckResponse = this.activityService.accountVisitRecordCheck(activity);

        if (
          !(
            accountVisitRecordCheckResponse.isAccountVisitNestedMeeting
            && accountVisitRecordCheckResponse.isAccountVisitRecord
          )
          && (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(activity.ID))
        ) {
          await this.activityService.upsertMeetingsOfflineData(activity); //Update Offline activity db
        } else {
          // Update local meeting detail document
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity);
        }

        const index = this.activityService.activities?.findIndex(act => act.ID === activity.ID);
        if (index >= 0) this.activityService.activities[index] = activity;

        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
    }
    if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
      this.activityService.selectedActivity = activity;
      this.events.publish("updateTherapeuticarea");
      this.events.publish("start-websocket-subscription");
      // This flag is for a loading on meeting detail page
      // Putting it at the end to also cover offline meeting scenario
      this.activityDetailsLoaded = true;
      this.events.publish('detectChangesOnActivityDetails', true); // To triger angular change detection manually
      this.events.publish('user:activity_products_created', brands);
      this.events.publish('activityproductkeymessagesupdated', brands);
    }
  }

  /**
     * Updates our activity with new information from details endpoint
     *
     * @private
     * @param {Activity} activity
     * @param {object} response
     * @memberof ActivityDataService
     */
  public async _appendPhoneCallDetailsToActivity(
    activity: Activity,
    response: object = null,
    shouldUpdateDB = false,
    isInboundFlow = false
  ) {
    //Find contacts
    let contacts: Array<Contact> = [];
    let rawContacts = [];
    if (response && response["contactAttendees"] ) {
      rawContacts = Array.isArray(response["contactAttendees"]) ? response["contactAttendees"] : [response["contactAttendees"]];
      if (rawContacts) {
        rawContacts.map(rawContact => {
          let contactID = rawContact.ID ? rawContact.ID : rawContact.indskr_contactid ? rawContact.indskr_contactid : undefined;
          let contact = this.contactService.getContactByID(contactID);// Changing from indskr_contactid to ID as teh rawContact contains ID and not indskr_ID

          if (contact) {
            contact.join_date = rawContact['indskr_joineddate'] || "";
            contact.left_date = rawContact['indskr_leftdate'] || "";
            contact.isguest = rawContact['indskr_isguest'] || false;
            contact.isremote = rawContact['indskr_isremote'] || false;
            contact.isleftmanually = rawContact['indskr_leftmanually'] || false;
            contact.indskr_sequence = rawContact['indskr_sequence'] || 0;
            if (isInboundFlow) {
              // Only set joined state when initiating inbound flow
              contact.contactJoinedState = rawContact['indskr_joinstatus'];
            }
            contacts.push(contact);
          } else {
            // This is an inbound meeting contact / a not synced contact that's not on my contact list. Could be an exising contact or a guest.
            // Create a contac object and add only to the activity so that it only appears on activity detail page.
            let rawObj = {
              'contactid': rawContact['ID'] || rawContact['indskr_contactid'],
              'firstname': rawContact['indskr_name'],
              'indskr_isguest': rawContact['indskr_isguest'],
              'indskr_joineddate': rawContact['indskr_joineddate'],
              'indskr_leftdate': rawContact['indskr_leftdate'],
              'indskr_isremote': rawContact['indskr_isremote'] || true,
              'indskr_leftmanually': rawContact['indskr_leftmanually'] || false,
              'indskr_sequence': rawContact['indskr_sequence'] || 0
            };
            contact = new Contact(rawObj);
            //Since its transactional data, this contact is not mapped to my position, setting `isActive=true`
            contact.isActive = true;
            if (rawContact['statuscode'] && rawContact['statuscode'] == 1) {
              contact.isActive = false;
            }
            if (isInboundFlow) {
              // Only set joined state when initiating inbound flow
              contact.contactJoinedState = rawContact['indskr_joinstatus'];
            }
            contacts.push(contact);
          }
        });
      }
    }
    await this.clubActivityWithConfigFields(response, activity, ActivityType.PhoneCall);
    //

    //Find accounts
    let accounts: Array<Account> = [];

    if (response && response['activityAccounts'] && Array.isArray(response['activityAccounts'])) {
      response['activityAccounts'].map(rawAccount => {
        let account = this.accountService.getAccountById(rawAccount.indskr_accountid);
        if (account) {
          accounts.push(account);
        } else {
          // Maybe removed. But if this meeting is completed, add in.
          if (activity.isCompleted && rawAccount.indskr_name && rawAccount.indskr_accountid) {
            rawAccount.accountid = rawAccount.indskr_accountid;
            rawAccount.name = rawAccount.indskr_name;
            rawAccount.statuscode = 2;
            accounts.push(new Account(rawAccount));
          }
        };
      });
    }
    //CWD-1268 , CWD-1267 , hard reset because of the pass by reference nature of javascript , the updated UI model gets reflected
    //in the brands data model which ismapped to savedProducts(inconsistent data) and since we were mapping it without a reset hence the this bug

    if (!this.brandService.brands || this.brandService.brands.length === 0) {
      await this.brandDataService.getBrands(true);
    }

    this.brandService.deSelectAll();
    let brands: Array<Brand> = JSON.parse(JSON.stringify(this.brandService.brands));
    let savedProduct: Brand;
    let activityProducts = response ? response['activityProducts'] : undefined;



    /**
     * Find each brands from activityProducts in App's Master Brand
     * if found, isSelected to true and isAutoSelected = activityProduct[x].indskr_isautomaticallyseleted
     */
    if (activityProducts && Array.isArray(activityProducts)) {
      activityProducts.map((product, index) => {

        savedProduct = brands.find(brand => brand.ID === product.indskr_productid); // just for the brands Interface
        if (savedProduct) {
          savedProduct.priority = product.indskr_sequence === undefined ? index : product.indskr_sequence;
          savedProduct.isAutoSelected = product.indskr_isautomaticallyselected;
          savedProduct.isGenieSelected = product.indskr_geneeselected
          savedProduct.isSelected = true;
          //note the saved product returns the current state of the Model data UI , we just use it for mapping here , map the sequence as
          if (product.activityProductKeyMessages.length !== 0) {
            product.activityProductKeyMessages.forEach(keyMsg => {
              let mappedKeyMessages = savedProduct.keyMessages.find(key => (key.ID === keyMsg.indskr_keymessageid));
              if (mappedKeyMessages) { // since the meeting already exist in the history profile we need this check
                mappedKeyMessages.isAutoSelected = keyMsg.indskr_isautomaticallyselected;
                mappedKeyMessages.isSelected = true;
                mappedKeyMessages.isGenieSelected = keyMsg.indskr_geneeselected
              }
            });
          }
        } else {
          const isReadOnlyJointPhoneCall = response['jointphonecall'] && response['indskr_ownerid'] != this.authenticationService.user.systemUserID;
          if (activity.isCompleted || isReadOnlyJointPhoneCall) {
            // If meeting is completed & the product doesn't exists (probably un-mapped), add the product to brand array
            // so that it can be at least displayed
            const unMappedProduct = new Brand(product, product.indskr_sequence);
            unMappedProduct.isAutoSelected = product.indskr_isautomaticallyselected;
            unMappedProduct.isSelected = true;
            unMappedProduct.name = product['indskr_name'];
            if (product.activityProductKeyMessages.length > 0) {
              product.activityProductKeyMessages.forEach(keyMsg => {
                let mappedKeyMessages = unMappedProduct.keyMessages.find(key => (key.ID === keyMsg.indskr_keymessageid));
                if (mappedKeyMessages) {
                  mappedKeyMessages.isAutoSelected = keyMsg.indskr_isautomaticallyselected;
                  mappedKeyMessages.isSelected = true;
                }
              });
            }
            brands.push(unMappedProduct);
          }
        }
      });
      if (savedProduct !== undefined || brands.length !== 0) {
        let brand: Array<Brand> = brands.filter(value => {
          return value.isSelected === undefined || !value.isSelected;
        });
        brand.forEach((el: Brand, index: any) => el.priority = brands.length + index + 1);
        //sort the products and return it to UI
        //now sort the products based on the sequence(product priority) , if the sequence is not available default
        brands.sort((item1: any, item2: any) => {
          //sort criteria
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
        this.events.publish('user:activity_products_created', brands);
      } else {
        brands.sort((item1: any, item2: any) => {
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
        this.events.publish('user:activity_products_not_found', brands);

      }
    } else {
      //no details, make basic array
      let brand = brands.filter(value => {
        return value.isSelected === undefined || !value.isSelected;
      });
      brand.forEach((el, index) => el.priority = brands.length + index + 1);
      //sort the products and return it to UI
      //now sort the products based on the sequence(product priority) , if the sequence is not available default
      brands.sort((item1, item2) => {
        //sort criteria
        if (item1.priority && item2.priority) {
          return item1.priority - item2.priority;
        }
      });
      activity.products = brands;
      this.events.publish('user:activity_products_created', brands);
    }


    //Therapeutic area mapping
    let therapeuticAreas: any = [];
    if (response && response['activityTherapeuticAreas'] && Array.isArray(response['activityTherapeuticAreas'])) {
      response['activityTherapeuticAreas'].forEach(ta => {
        let newTa = new TherapeuticArea(ta);
        newTa.isSelected = true;
        therapeuticAreas.push(newTa);
      });
      // console.log(therapeuticAreas);
    }



    if (response && activity instanceof PhoneActivity) {
      activity.update(response, contacts, accounts, brands, therapeuticAreas)//(response, contacts, accounts, brands, therapeuticAreas);
      if (shouldUpdateDB) {
        if (this.deviceService.isOffline || this.activityService.hasOfflinePhoneCallData(activity.ID)) {
          await this.activityService.upsertPhoneCallOfflineData(activity); //Update Offline activity db
        } else {
          // Update local phonecall detail document
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity);
        }
        const index = this.activityService.activities?.findIndex(act => act.ID === activity.ID);
        if (index >= 0) this.activityService.activities[index] = activity;
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
      if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
        this.activityService.selectedActivity = activity;
        this.activityDetailsLoaded = true;
        this.events.publish("updateTherapeuticarea");
        this.events.publish('detectChangesOnPhoneCallActivityDetails'); // To triger angular change detection manually
      }
    }
  }

  public getActivityByIdOnline(activityId: string, activityType: string): Promise<any> {

    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;
    url = url.replace("{activity_id}", activityId);
    url = url.replace("{activity_type}", activityType);

    url = url.replace('&configFields={{configFields}}', '');

    return this.http.get(url).toPromise();
  }


  public getPhonecallActivityByIdOnline(activityId: string, scheduledStart: string, scheduledEnd: string, lastUpdatedTime: string): Promise<any> {
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.phonecall.GET_DELTA_PHONE_CALL_ACTIVITY_DETAILS;
    url = url.replace("{activity_id}", activityId);
    url = url.replace("{activity_id1}", activityId);
    url = url.replace("{lastUpdatedTime}", lastUpdatedTime);
    url = url.replace("{startDate}", scheduledStart);
    url = url.replace("{endDate}", scheduledEnd);
    const positions = this.authenticationService.user.positions.map((o) => {
      return o.ID
    })
    url = url.replace('{positionIds}', positions.toString());
    let phonecallConfiguredFields: string[] = this.getPhoneCallConfigFields();
    url = url.replace('{phonecallConfigFields}', phonecallConfiguredFields.toString());

    return this.http.get(url).toPromise();
  }


  private getPhoneCallConfigFields() {
    let phonecallConfiguredFields: string[] = [];
    if (this.authenticationService.user.phonecallConfiguredFields != undefined && this.authenticationService.user.phonecallConfiguredFields.length > 0) {
      phonecallConfiguredFields = this.authenticationService.user.phonecallConfiguredFields.map((obj) => {
        return obj.fieldName;
      });
    }
    return phonecallConfiguredFields;
  }

  public async getAccompaniedUsers(loadFromDBOnly = false) {
    if (loadFromDBOnly) {
      await this.loadOfflineAccompaniedUsers();
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACCOMPANIED_USER;
      url = url.replace('{{positionIds}}', this.authenticationService.getLoggedInUserPositions().toString())

      const accompUserSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.accompaniedList,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      try {
        let headers: HttpHeaders = Endpoints.GLOBAL_SYNC_HEADER.headers;
        headers = headers.set('X-BusinessUnitId', this.authenticationService.user.xBusinessUnitId);
        let res: any = await this.http.get(url, { headers: headers }).toPromise();
        if (Array.isArray(res)) {
          accompUserSyncInfo.totalSynced = res.length;
        }
        this.activityService.mapAccompaniedUserList(res, true);
      }
      catch (error) {
        this.deltaService.addSyncErrorToEntitySyncInfo(accompUserSyncInfo, url, error);
        await this.loadOfflineAccompaniedUsers();
      }
      this.deltaService.addEntitySyncInfo(accompUserSyncInfo);

    }
  }

  private async loadOfflineAccompaniedUsers() {
    await this.disk.retrieve(DB_KEY_PREFIXES.ACCOMPANIED_USERS).then(async (users: any) => {
      if (users && users.raw) {
        await this.activityService.mapAccompaniedUserList(JSON.parse(users.raw));
      }
    }).catch((err: any) => {
      this.logService.logDebug('getAccompaniedUsers: Error retrieving accomapanied user details ', err);
    });
  }

  public addAccompaniedUser(raw: AccompainedUser[], id: string): Observable<any> {
    let payload: any[] = [];
    raw.map((e: AccompainedUser) => {
      let obj: object = { 'userId': e.id };
      payload.push(obj);
    });
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.POST_ACCOMPANIED_USER;
    url = url.replace("{activity_id}", id);
    return this.http.put(url, payload);
  }

  public get startDate(): Date {
    return this.currentStartDate;
  }

  public get endDate(): Date {
    return this.currentEndDate;
  }

  getTimelineRawActivityDetails(activity): Observable<any> {


    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;

    url = url.replace("{activity_id}", activity.activityId || activity.ID);
    url = url.replace("{last_updated_time}", '');
    url = url.replace("{activity_type}", activity.activity_type || activity.type.toLowerCase());

    url = url.replace('&configFields={{configFields}}', '');

    let headers = Endpoints.headers.content_type.json;
    headers.headers = headers.headers.set(
      'No-Retry', 'true'
    );

    return this.http.get(url, headers);
  }
  /**
   * This will capture the scrren sharing time
   */
  trackScreensharetime(screenShareRecords: { indskr_contactid: string, startTime: number, endTime?: number }[], activityID: string) {
    console.log(screenShareRecords);
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.SCREEN_SHARING_TIME;
    url = url.replace('{activityId}', activityID);
    let response = this.http.post(url, screenShareRecords).toPromise();
    console.log(response);
  }

  /**
   * Endpoint access to Activity Type in Meetings and Phone call.
   * @param loadFromDbOnly
   *
   */
   public async getActivityTypes(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      this.activityService.loadMeetingActivityTypesFromDB()
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_TYPE;
      let headers = new HttpHeaders();
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
      const meetingActivityTypeSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.meetingType,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      try {
        const response: MeetingActivityType[] = await this.http.get<MeetingActivityType[]>(url, { headers }).toPromise();
        if (Array.isArray(response)) {
          meetingActivityTypeSyncInfo.totalSynced = response.length;
        }
        await this.activityService.mapMeetingActivityTypes(response);
      } catch (error) {
        console.log('ActivityDataService: getMeetingActivityTypes: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(meetingActivityTypeSyncInfo, url, error);
        await this.activityService.loadMeetingActivityTypesFromDB();
      }
      this.deltaService.addEntitySyncInfo(meetingActivityTypeSyncInfo);
    }
  }

  /**
   * Endpoint access to Activity Type in Meetings and Phone call.
   * @param loadFromDbOnly
   *
   */
   public async getActivitySubjects(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      this.activityService.loadMeetingSubjectsFromDB()
    } else {
      let dwa = new DynamicsWebApi({
        webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
        onTokenRefresh: tokenRefresh => this.authenticationDataService.getTokenForSelectedInstance().then(at => at.token).then(tokenRefresh),
      });
      try {
        const subjects: string[] = (await dwa.retrieveAll('indsyn_meetingsubjects', ['indsyn_name']))?.value?.map(x => x.indsyn_name) || [];
        await this.activityService.mapMeetingSubjects(subjects);
      } catch (error) {
        console.log('ActivityDataService: getActivitySubjects: ', error);
        await this.activityService.loadMeetingSubjectsFromDB();
      }
    }
  }

  /**
   * Endpoint access to Sub Activity Type in Meetings and Phone call.
   * @param loadFromDbOnly
   *
   */

  public async getSubActivityTypes(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      this.activityService.loadMeetingSubActivityTypesFromDB()
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_SUBACTIVITY_TYPE;
      let headers = new HttpHeaders();
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
      const meetingSubActivityTypeSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.meetingType,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      try {
        const response: MeetingSubActivityType[] = await this.http.get<MeetingSubActivityType[]>(url, { headers }).toPromise();
        if (Array.isArray(response)) {
          meetingSubActivityTypeSyncInfo.totalSynced = response.length;
        }
        await this.activityService.mapMeetingSubActivityTypes(response);
      } catch (error) {
        console.log('ActivityDataService: getSubMeetingActivityTypes: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(meetingSubActivityTypeSyncInfo, url, error);
        await this.activityService.loadMeetingSubActivityTypesFromDB();
      }
      this.deltaService.addEntitySyncInfo(meetingSubActivityTypeSyncInfo);
    }
  }

  /**
  * Endpoint access to Config Fields values for Picklist and Virtual type (OptionSet and MultiSelect OptionSet) for Meeting.
  * @param loadFromDbOnly
  *
  */

  public async getAppointmentFieldOptionSetValues(loadFromDbOnly = false) {
    this.activityService.configFieldOptionsValues = [];
    let appointmentConfigFields = [...this.authenticationService.user.configuredFields.filter(field => { return field.entityName === 'appointment' })];
    const optionSetFields = appointmentConfigFields.filter(field => { return (field.fieldType === 'Picklist' || field.fieldType === 'Virtual') });
    optionSetFields.forEach(field => {
      this.getConfigFieldPicklistValues(loadFromDbOnly, field.entityName, field.fieldType, field.fieldName, field.fieldLabel);
    });
  }

  /**
  * Endpoint access to Config Fields values for Picklist and Virtual type (OptionSet and MultiSelect OptionSet) for PhoneCall.
  * @param loadFromDbOnly
  *
  */

  public async getPhoneCallFieldOptionSetValues(loadFromDbOnly = false) {
    let phoneCallConfigFields = [...this.authenticationService.user.configuredFields.filter(field => { return field.entityName === 'phonecall' })];
    let optionSetFields = phoneCallConfigFields.filter(field => { return (field.fieldType === 'Picklist' || field.fieldType === 'Virtual') });
    optionSetFields.forEach(field => {
      this.getConfigFieldPicklistValues(loadFromDbOnly, field.entityName, field.fieldType, field.fieldName, field.fieldLabel);
    });
  }

  /**
  * Endpoint access to Config Fields values for Picklist and Virtual type (OptionSet) for Recommended Engagement Period.
  * @param loadFromDbOnly
  *
  */

  public async getRecommendedEngagementPeriodFieldOptionSetValues(loadFromDbOnly = false) {
    let recommendedEngagementPeriodConfigFields = [...this.authenticationService.user.configuredFields.filter(field => { return field.entityName === 'indskr_rec_engagement_period' })];
    const optionSetFields = recommendedEngagementPeriodConfigFields.filter(field => { return (field.fieldType === 'Picklist' || field.fieldType === 'Virtual') });
    optionSetFields.forEach(field => {
      this.getConfigFieldPicklistValues(loadFromDbOnly, field.entityName, field.fieldType, field.fieldName, field.fieldLabel);
    });
  }

  public populateHCPInteractionField() {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_SOLICITATION_COMPLIANCE)) {
      let customerRequestedFormField = hcpInteractionDefaultField;
      customerRequestedFormField.fieldLabel = this.translate.instant('CONTACT_REQUESTED', { globalCustomerText: this.utilityService.globalCustomerText });
      let foundIdx = this.authenticationService.user.configuredFields.findIndex(a => a.entityName == 'appointment' && a.fieldAlias == 'indskr_hcpinteraction');
      if (foundIdx < 0) {
        this.authenticationService.user.configuredFields.push(customerRequestedFormField);
        this.authenticationService.user.appointmentConfiguredFields.push(customerRequestedFormField);
      } else {
        this.authenticationService.user.configuredFields[foundIdx] = customerRequestedFormField;
        let appFieldsIdx = this.authenticationService.user.appointmentConfiguredFields.findIndex(a => a.fieldAlias == 'indskr_hcpinteraction');
        if (appFieldsIdx >= 0) {
          this.authenticationService.user.appointmentConfiguredFields[appFieldsIdx] = customerRequestedFormField;
        } else {
          this.authenticationService.user.appointmentConfiguredFields.push(customerRequestedFormField);
        }
      }
      this.authenticationService.user.configuredFields.sort((a, b) => {
        return (a.fieldSequence === b.fieldSequence) ? ((a.fieldLabel > b.fieldLabel) ? 1 : -1) : 0;
      });
      this.authenticationService.user.appointmentConfiguredFields.sort((a, b) => {
        return (a.fieldSequence === b.fieldSequence) ? ((a.fieldLabel > b.fieldLabel) ? 1 : -1) : 0;
      });
      this.authenticationService.saveUser(false);
    }
    return;
  }

  private async getConfigFieldPicklistValues(loadFromDbOnly = false, activityName: string, configType: string, fieldName: string, fieldLabel: string) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      this.activityService.loadConfigFieldOptionsFromDB(fieldName, activityName);
    } else {
      const configFieldSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.configField,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      let url: string;

      try {
        let requestURL = getConfigFieldPickListRequestURL(configType, activityName, fieldName);
        url = this.authenticationService.userConfig.activeInstance.url + requestURL;
        let headers = new HttpHeaders();
        headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)

        const response: ConfigFieldOptionResponse = await this.http.get<ConfigFieldOptionResponse>(url, { headers }).toPromise();
        if (response) {
          let result: ConfigFieldOptionValue[] = parseConfigFieldPicklistResponse(response, activityName, fieldName, configType, fieldLabel);
          configFieldSyncInfo.totalSynced = result.length;
          if (result.length) {
            await this.activityService.mapConfigFieldOptions(result, fieldName, activityName);
          }
        }
      } catch (error) {
        console.log('ActivityDataService: getConfigFieldPicklistValues: ', error);
        this.deltaService.addSyncErrorToEntitySyncInfo(configFieldSyncInfo, url, error);
        await this.activityService.loadConfigFieldOptionsFromDB(fieldName, activityName);
      }
      this.deltaService.addEntitySyncInfo(configFieldSyncInfo);
    }
  }

  public async getUnsolicitedMeetingsData(fullSync, loadFromDBOnly) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_SOLICITATION_COMPLIANCE)) {
      if (loadFromDBOnly) {
        let data = await this.disk.retrieve(DB_KEY_PREFIXES.UNSOLICITED_MEETING_DATA);
        if (data && data.raw && Array.isArray(data.raw)) {
          this.activityService.unsolicitedMeetingsData = data.raw;
        }
        return;
      }
      let fetchXML = fetchQueries.solicitationTracking.unsolicitedMeetingsData;
      let positionString = '';
      let positionIds = this.authenticationService.user.positions.map(o => {
        return o.ID
      });
      positionIds.forEach(p => {
        positionString += '<value>' + p + '</value>'
      })
      fetchXML = fetchXML.split('{positionIDs}').join(positionString);
      fetchXML = fetchXML.replace('{unsolicitedAllowedPeriod}', this.authenticationService.user.unsolicitedMeetingLimitPeriod.toString());
      this.dynamics.executeFetchQuery('contacts', fetchXML).then((data) => {
        this.agreegateUnsolicitedMeetingsData(data);
        this.updateUnsolicitedMeetingDataOfflineDB();
      }, (error) => {
        console.log(error);
      })
    }
  }

  public async updateUnsolicitedMeetingDataOfflineDB() {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.UNSOLICITED_MEETING_DATA, doc => {
      doc = {
        raw: this.activityService.unsolicitedMeetingsData,
      };
      return doc;
    }).catch(error => console.error('Save unsolicited meeting data error: ', error));
  }

  private agreegateUnsolicitedMeetingsData(data) {
    this.activityService.unsolicitedMeetingsData = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach(item => {
        if (item['contactid']) {
          let idx = this.activityService.unsolicitedMeetingsData.findIndex(a => a.contactid == item['contactid']);
          if (idx >= 0) {
            this.activityService.unsolicitedMeetingsData[idx].appointments.push({
              activityid: item['meeting.activityid'],
              scheduledend: new Date(item['meeting.scheduledend']),
            })
          } else {
            this.activityService.unsolicitedMeetingsData.push({
              contactid: item['contactid'],
              appointments: [{
                activityid: item['meeting.activityid'],
                scheduledend: new Date(item['meeting.scheduledend']),
              }]
            })
          }
        }
      });
    }
  }

  private sanitizeTimeOffRawObject(rawTot){
    rawTot['indskr_positionid_value'] = rawTot['_indskr_positionid_value'];
    delete rawTot['_indskr_positionid_value'];
    rawTot['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    delete rawTot['_indskr_positionid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    rawTot['ownerid_value'] = rawTot['_ownerid_value'];
    delete rawTot['_ownerid_value'];
    rawTot['ownerid_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_ownerid_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    delete rawTot['_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    rawTot['indskr_approver_value@OData.Community.Display.V1.FormattedValue'] = rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_indskr_approver_value'];
    delete rawTot['_indskr_approver_value@Microsoft.Dynamics.CRM.lookuplogicalname'];

    delete rawTot['_indskr_appointmentid_value'];
    delete rawTot['_indskr_appointmentid_value@OData.Community.Display.V1.FormattedValue'];
    delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'];
    delete rawTot['_indskr_appointmentid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];
  }

  public async getOfflineActivityDocByKey(dbKey){
    try {
      return this.disk.retrieve(dbKey, true);
    } catch (error) {
      console.log(error);
    }
  }

  public async updateTeamsMeetingSubject(subject: string, teamsMeetingId: string) {
    const url = Endpoints.activites.UPDATE_TEAMS_MEETING.replace("{teamsMeetingId}", teamsMeetingId);
    const payload = { subject: subject};
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('X-MS-Graph-Token', 'true');
    await this.http.patch(url, payload, { headers: headers }).toPromise().then((data)=> {
      console.log("Teams Meeting updated successfully");
    });
  }

  public async updateUserTimeRecommendation() {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.UPDATE_USER_TIME_RECOMMENDATION;
    url = url.replace('{user}', this.authenticationService.user.systemUserID);

    let payload = {
      "indskr_showbesttimerecommendation" : this.authenticationService.user.showBestTimeRecommendation,
      "indskr_hidebesttimerecommendationcount" : this.authenticationService.user.hideBestTimeRecommendationCount
    }

    return await this.http.patch(url, payload).toPromise().then((data)=> {
      console.log("User Time Recommendation Count updated successfully");
    });
  }

  public async getUserDateTimeSettingsByBusinessUnitId(loadFromDBOnly = false) {
    console.log(`getUserDateTimeSettingsByBusinessUnitId`);

    this.isUserDatetimeSettingsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.USER_DATETIME_SETTINGS);

    /* To check foll.
      1.Feature Action is enabled
    */
    if (this.isUserDatetimeSettingsEnabled) {

      //To retrieve value of flag from Local Storage and set it across the app
      let isDateFormatSetViaApp = localStorage.getItem("isDateFormatSetViaApp");

      if (isDateFormatSetViaApp === "true") {
        this.dateFormatsService.isDateFormatSetViaApp = true;
      } else {
        this.dateFormatsService.isDateFormatSetViaApp = false;
      }

      const storedDateFormat = localStorage.getItem('selectedDateFormat');
      //To check if DateFormat is already set by User via Settings then skip
      if (storedDateFormat !== null && this.dateFormatsService.isDateFormatSetViaApp) {
        console.log(`Skipping as User has set Date Format via App`);
        return;
      }

      if (loadFromDBOnly) {
        // This fetch request was breaking offline launch scenario
        // Applying temporary fix to unblock
        // This will need to be handled properly by the feature owner
        if (storedDateFormat !== null) {
          try {
            let parsedDateFormat = JSON.parse(storedDateFormat);
            this.dateFormatsService.setDateFormat(parsedDateFormat);
            this.dateFormatsService.dateFormat.next(parsedDateFormat);
          } catch (error) {
            console.error('getUserDateTimeSettingsByBusinessUnitId: parse storedDateFormat: ', error);
          }
        }
        const storedTimeFormat = localStorage.getItem('selectedTimeFormat');
        if (storedTimeFormat !== null) {
          try {
            let parsedTimeFormat = JSON.parse(storedTimeFormat);
            this.dateFormatsService.setTimeFormat(parsedTimeFormat);
            this.dateFormatsService.timeFormat.next(parsedTimeFormat);
          } catch (error) {
            console.error('getUserDateTimeSettingsByBusinessUnitId: parse storedTimeFormat: ', error);
          }
        }
        return;
      }

      let fetchXML = fetchQueries.userDateTimeSettingsByBusinessUnitId;
      fetchXML = fetchXML.replace('{businessUnitId}', `${this.authenticationService.user.xBusinessUnitId}`);

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_userdatetimesettingses', fetchXML);
        console.warn(`getUserDateTimeSettings: executeFetchQuery`);
        console.log(response);

        if (!_.isEmpty(response)) {
          // --------------------------------User Datetime Settings-------------------------------- //
          let arr = response;

          //Filter by using reduce function into Organization and BU level settings
          let [buLevelSettings, orgLevelSettings] = response.reduce((result, element) => {
            result[element['indskr_level'] !== 548910000 ? 0 : 1].push(element); // Determine and push to small/large arr
            return result;
          },

          [[], []]);

          let selectedDateFormat = null;
          let selectedTimeFormat = null;

          //Check for Datetime settings at BU level
          if (buLevelSettings.length > 0) {
            // Sort based on modified On descending
            buLevelSettings = _.orderBy(buLevelSettings, ['modifiedon'], ['desc']);

            //Consider first value
            selectedDateFormat = buLevelSettings[0]['indskr_dateformat_Formatted'];
            selectedTimeFormat = buLevelSettings[0]['indskr_timeformat_Formatted'];
          }
          else if (orgLevelSettings.length > 0) { //Else check for Datetime settings at Organization level
            // Sort based on modified On descending
            orgLevelSettings = _.orderBy(orgLevelSettings, ['modifiedon'], ['desc']);

            //Consider first value
            selectedDateFormat = orgLevelSettings[0]['indskr_dateformat_Formatted'];
            selectedTimeFormat = orgLevelSettings[0]['indskr_timeformat_Formatted'];
          }

          if (selectedDateFormat) {
            //Find the selectedDateFormat in the allFormat array from the App and then set the localStorage
            const selected = this.dateFormatsService.allFormats.find((item) => {
              return item.display === selectedDateFormat;
            });

            if (selected) {
              console.warn(`UserDateTimeSettings selectedDateFormat: ${selectedDateFormat} is applied`);

              localStorage.setItem("selectedDateFormat", JSON.stringify(selected));

              this.dateFormatsService.setDateFormat(selected);
              this.dateFormatsService.dateFormat.next(selectedDateFormat);
            }
            else {
              console.error(`selectedDateFormat: ${selectedDateFormat} is missing in the App side`);
            }
          }

          if (selectedTimeFormat) {
            //Find the selectedTimeFormat in the timeFormatSegments array from the App and then set the localStorage
            const selected = this.dateFormatsService.timeFormatSegments.find((item) => {
              return item.display === selectedTimeFormat;
            });

            if (selected) {
              console.warn(`UserDateTimeSettings selectedTimeFormat: ${selectedTimeFormat} is applied`);

              localStorage.setItem("selectedTimeFormat", JSON.stringify(selected));

              this.dateFormatsService.setTimeFormat(selected);
              this.dateFormatsService.timeFormat.next(selected.format);
            }
            else {
              console.error(`selectedTimeFormat: ${selectedTimeFormat} is missing in the App side`);
            }
          }
          // --------------------------------User Datetime Settings-------------------------------- //
        }
      } catch (error) {
        console.error('getUserDateTimeSettings: error:', error);
      }
      return;
    } //end of if (this.isUserDatetimeSettingsEnabled)
  }

  private setContactsToolSettings(settings: { indskr_labelforthebutton: string, indskr_baseurl: string }) {
    this.contactService.labelforContactsTool = settings?.indskr_labelforthebutton || '';
    this.contactService.baseURLforContactsTool = settings?.indskr_baseurl || '';
  }

    public async getContactsToolSettingsByBusinessUnitId(loadFromDBOnly = false) {
      if (loadFromDBOnly) {
        // This fetch request was breaking offline launch scenario
        // Applying temporary fix to unblock
        // This will need to be handled properly by the feature owner
        return;
      }

      this.isCustomerAdditionalInformationEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ADDITIONAL_INFORMATION);

      /* To check foll.
        1.Feature Action is enabled
      */
      if (this.isCustomerAdditionalInformationEnabled) {
        console.warn(`Check for Contacts Tool Settings`);

        if (loadFromDBOnly) {
          const storedContactsToolSettings = localStorage.getItem('contactsToolSettings');
          const parsed = storedContactsToolSettings ? JSON.parse(storedContactsToolSettings) : null;
          parsed && this.setContactsToolSettings(parsed);
          return;
        }

        let fetchXML = fetchQueries.contactsToolSettingsByBusinessUnitId;
        fetchXML = fetchXML.replace('{businessUnitId}', `${this.authenticationService.user.xBusinessUnitId}`);

        try {
          let response = await this.dynamics.executeFetchQuery('businessunits', fetchXML);
          console.warn(`getContactsToolSettings: executeFetchQuery`);
          console.log(response);

          if (!_.isEmpty(response) && response[0]) {
            // --------------------------------Contacts Tool Settings-------------------------------- //
            // this.contactService.logoforContactsTool = response[0]['indskr_labelforthebutton'];
            this.setContactsToolSettings(response[0]);
            localStorage.setItem('contactsToolSettings', JSON.stringify(response[0]));
            // --------------------------------Contacts Tool Settings-------------------------------- //
          }
        } catch (error) {
          console.error('getContactsToolSettings: error:', error);
        }
        return;
      } //end of if (this.isCustomerAdditionalInformationEnabled)
    }

     // --------------------------------No Detailing in a Meeting-------------------------------- //
    public async getMeetingContentReasonsByBusinessUnitId(loadFromDBOnly: boolean = false) {
      if (loadFromDBOnly) {
        await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_CONTENT_REASONS, true).then((doc) => {
          if (doc && doc.raw) {
            this.activityService.meetingContentReasons = doc.raw
          }
          else {
            this.activityService.meetingContentReasons = [];
          }
        })
        return;
      }
      try {
        console.log(`getMeetingContentReasonsByBusinessUnitId`);
        let isRemoteDetailingEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_REASON);
        /* To check foll.
          1.Feature Action is enabled
        */
        if (isRemoteDetailingEnabled) {
          console.warn(`Check for Meeting Content Reasons`);
          let positionIds = this.authenticationService.user.positions.map(o => {
            return o.ID
          });
          let positionString = '';
          positionIds.forEach(p => {
            positionString += '<value>' + p + '</value>'
          })
          let fetchXML = fetchQueries.meetingContentReasons.split('{positionIDs}').join(positionString);
          try {
            let response = await this.dynamics.executeFetchQuery('indskr_meetingcontentreasonses', fetchXML);
            console.warn(`getMeetingContentReasons: executeFetchQuery`);
            console.log(response);
            this.activityService.meetingContentReasons = [];
            if (!_.isEmpty(response)) {
              this.activityService.meetingContentReasons = response;
            }
            this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_CONTENT_REASONS, (doc) => {
              doc = {
                raw: []
              };
              doc.raw = this.activityService.meetingContentReasons;
              return doc;
            })
          } catch (error) {
            console.error('getMeetingContentReasons: error:', error);
          }
          return;
        } //end of if (this.isRemoteDetailingEnabled)
      } catch (error) {
        console.log('meeting content reasons fetch failed', error)
      }
    }

  //   async updateMeetingDiseaseArea(selectedActivity:AppointmentActivity): Promise<any>{

  //     // if (this.activityService.selectedActivity instanceof AppointmentActivity) {
  //     //     if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
  //     //         try {
  //     //             return this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
  //     //             // return Promise.resolve();
  //     //         } catch(e) {
  //     //             console.log("Failed updating therapeutic area in offline");
  //     //             return Promise.reject(e);
  //     //         }
  //     //     }
  //     // }

  //     let daobj = [];
  //     selectedActivity['activityDiseaseAreas'].forEach((obj,idx) => {
  //         if(obj.isSelected) {
  //           daobj.push({
  //             "indskr_diseaseareaid": obj.indskr_diseaseareaid,
  //             "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx+1,
  //             "indskr_automaticallyselected": obj.indskr_automaticallyselected,
  //             "indskr_geneeselected": obj.indskr_geneeselected,
  //             "indskr_name": obj.indskr_name,
  //           });
  //         }
  //     });
  //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_DISEASEAREA;
  //     url = url.replace('{{ActivityId}}',selectedActivity.ID);
  //     try{
  //         let res = await this.http.put(url,daobj).toPromise();
  //         try {
  //           await this.disk.updateOrInsertActivityToActivityDetailRawDocument(selectedActivity, true);
  //         } catch (error) {
  //           console.error('updatemeetingdiseasearea: updateOrInsertActivityToActivityDetailRawDocument: ', error);
  //         }
  //         return res;
  //     }
  //     catch(err){
  //         console.error("Update Meeting Disease area : " + err);
  //         return Promise.reject(err);
  //     }


  // }

  // async updateMeetingProductIndications(selectedActivity:AppointmentActivity): Promise<any>{

  //   // if (this.activityService.selectedActivity instanceof AppointmentActivity) {
  //   //     if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
  //   //         try {
  //   //             return this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
  //   //             // return Promise.resolve();
  //   //         } catch(e) {
  //   //             console.log("Failed updating therapeutic area in offline");
  //   //             return Promise.reject(e);
  //   //         }
  //   //     }
  //   // }

  //   let piobj = [];
  //   selectedActivity['activityProductIndications'].forEach((obj,idx) => {
  //       if(obj.isSelected || obj.indskr_automaticallyselected) {
  //         let pi = {
  //           "productIndicationId": obj.productIndicationId,
  //           "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx+1,
  //           "indskr_automaticallyselected": obj.indskr_automaticallyselected,
  //           //"indskr_geneeselected": obj.indskr_geneeselected,
  //           "indskr_name": obj.indskr_name,
  //           "activityProductIndicationKeyMessages": [],
  //         };
  //         if(obj.activityProductIndicationKeyMessages){
  //           obj.activityProductIndicationKeyMessages.forEach((obj2,idx2) => {
  //             if(obj2.isSelected || obj2.indskr_automaticallyselected){
  //               pi.activityProductIndicationKeyMessages.push({
  //                 "indskr_keymessageid": obj2.indskr_keymessageid,
  //                 "indskr_automaticallyselected": obj2.indskr_automaticallyselected,
  //                 "indskr_name": obj2.indskr_name,
  //               });
  //             }
  //           });
  //         }
  //         piobj.push(pi);
  //       }
  //   });
  //   let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_PRODUCT_INDICATIONS;
  //   url = url.replace('{{ActivityId}}',selectedActivity.ID);
  //   try{
  //       let res = await this.http.put(url,piobj).toPromise();
  //       try {
  //         await this.disk.updateOrInsertActivityToActivityDetailRawDocument(selectedActivity, true);
  //       } catch (error) {
  //         console.error('updatemeetingproductindications: updateOrInsertActivityToActivityDetailRawDocument: ', error);
  //       }
  //       return res;
  //   }
  //   catch(err){
  //       console.error("Update Meeting Product Indications : " + err);
  //       return Promise.reject(err);
  //   }
  // }
    // --------------------------------No Detailing in a Meeting-------------------------------- //

    // public async getBuLevelConfigurations(fullSync?: boolean, loadFromDbOnly = false) {
    //   try {
    //     const offlineDataStored = await this.loadOfflineDataForBulevelConfigurations();
    //     if(loadFromDbOnly){
    //      this.authenticationService.buLevelConfigurations =  offlineDataStored?.raw ?? null;
    //       return;
    //     }

    //     const lastModifiedTime = offlineDataStored?.lastModified ?? null;
    //     if (!offlineDataStored) fullSync = true;
    //     let fetchXML = this.formatXml(fullSync,fetchQueries.fetchBuLevelConfigurations, lastModifiedTime);
    //     const buConfigs = await this.dynamics.executeFetchQuery('businessunits', fetchXML);
    //     if(!_.isEmpty(buConfigs)){
    //       let mappedBuConfigs = new BuLevelConfig(buConfigs[0]);
    //       this.authenticationService.buLevelConfigurations = mappedBuConfigs;
    //       this.saveBuLevelConfigurations(mappedBuConfigs);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     this.authenticationService.buLevelConfigurations = null;
    //   }
    // }

    public async fetchActivityCancellationReasons(fullSync, loadFromDBOnly) {
      if(this.authenticationService.user.buSettings && !this.authenticationService.user.buSettings['indskr_cancelprocedurelogwithreason'] && !this.authenticationService.user.buSettings['indskr_cancelmeetingwithreason']) return;
      const offlineData = await this.loadActivityCancellationReasonsFromLocalDB();
      try {
        let deltaFilter = '';

        if (loadFromDBOnly) {
          this.activityService.activityCancellationResons = offlineData?.raw ? offlineData?.raw : [];
          return;
        }

        if (offlineData && offlineData.lastModified) {
          const modifiedon = this.dateFormatsService.formatDateForFetchXML(offlineData.lastModified);
          deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
        }

        let fetchXML = fetchQueries.salesOrders.fetchCancelReasons;;
        fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

        if (fullSync) {
          fetchXML = fetchXML.replace('{fullSyncFilter}', '<condition attribute="statecode" operator="eq" value="0" />');
        }

        let activityIds = '';
        if(this.authenticationService.user.buSettings['indskr_cancelprocedurelogwithreason']){
          activityIds = activityIds +  '<value>548910002</value>'
        }

        if(this.authenticationService.user.buSettings['indskr_cancelmeetingwithreason']){
          activityIds = activityIds +  '<value>548910001</value>'
        }

        // ! value should be added based on Flag
        // fetchXML = fetchXML.replace('{activityIds}', '<value>548910002</value>');
        fetchXML = fetchXML.replace('{activityIds}', activityIds);
        // <value>548910001</value>

        await this.dynamics.executeFetchQuery('indskr_opportunityreasons', fetchXML).then((response) => {
          if (fullSync && !response.length) this.activityService.activityCancellationResons = [];

          if (response && response.length) {
            let cancellationReasons = response.map((r) => new ActivityCancellationReson(r));
            cancellationReasons = _.unionBy(cancellationReasons, (cancellationReason: ActivityCancellationReson) => cancellationReason.reasonId);
            if (fullSync || !this.activityService.activityCancellationResons.length) this.activityService.activityCancellationResons = cancellationReasons;

            if (!fullSync && this.activityService.activityCancellationResons.length > 0) {
              cancellationReasons.forEach((cancellationReason: ActivityCancellationReson) => {
                const index = this.activityService.activityCancellationResons.findIndex(({ reasonId }) => reasonId === cancellationReason.reasonId);
                if (index < 0 && cancellationReason.statecode === 0) {
                  this.activityService.activityCancellationResons.push(cancellationReason);
                } else if (cancellationReason.statecode === 1) {
                  this.activityService.activityCancellationResons.splice(index, 1);
                } else {
                  this.activityService.activityCancellationResons[index] = cancellationReason;
                }
              });
            }
          }
          this.saveActivityCancellationReasonsInLocalDB(this.activityService.activityCancellationResons);
        });
        return true;
      } catch (error) {
        console.log(error);
        this.activityService.activityCancellationResons = offlineData.raw ? offlineData.raw : [];
        return false;
      }
    }

    private async saveActivityCancellationReasonsInLocalDB(ProcedureSubTypes: ActivityCancellationReson[]) {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACTIVITY_CANCELLATION_REASONS, (doc) => {
        doc = {
          raw: [],
          lastModified: doc.lastModified
        };
        doc.raw = ProcedureSubTypes;
        return doc;
      });
    }

    public async loadActivityCancellationReasonsFromLocalDB() {
      let offlineDataStored;
      try {
        await this.disk.retrieve(DB_KEY_PREFIXES.ACTIVITY_CANCELLATION_REASONS, true).then((doc) => {
          offlineDataStored = doc?.raw ? doc : [];
        });
      }
      catch (er) {
        console.error("Failed to load activity cancellation reasons from local db!: ", er)
        offlineDataStored = [];
      }
      return offlineDataStored;
    }

  public async deleteMeeting(activityID) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.DELETE_MEETING;
    url = url.replace('{activityId}', activityID)
    return this.http.delete(url).toPromise();
  }

  public async syncConfigFieldLookupValues(loadFromDBOnly,forceFullSync){
    const supportedEntityNames = ['appointment','phonecall','coaching'];
    let lookupConfigFields = [...this.authenticationService.user.configuredFields.filter(field => { return field.fieldType == 'Lookup' ||  supportedEntityNames.includes(field.entityName)})];
    let previousAllLookUpFieldsDataForIoConfigFields;
    let lastUpdatedTime;
    if(!loadFromDBOnly && lookupConfigFields && lookupConfigFields.length > 0){
      if(!forceFullSync){
        await this.disk.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc)=>{
          if(doc && doc.raw){
            previousAllLookUpFieldsDataForIoConfigFields = doc.raw
            lastUpdatedTime = doc.lastUpdatedTime;
          }
        })
      }
      let allLookUpFieldsForIoConfigFields = {};
      let changeInData = false;
      try {
        for (let i = 0; i < lookupConfigFields.length; i++) {
          const lookupField = lookupConfigFields[i];
          if(lookupField.indskr_lookupquery && lookupField.fieldName && lookupField.indskr_lookupentitysetname){
            const results = await this.getConfigFieldLookupResults(lookupField,lastUpdatedTime);
            //Storing the results in the form of Key-Value pair
            if(results && results.length && results.length > 0){
              changeInData = true;
              if(lastUpdatedTime && previousAllLookUpFieldsDataForIoConfigFields[lookupField.entityName] && previousAllLookUpFieldsDataForIoConfigFields[lookupField.entityName][lookupField.fieldName]){
                if(!allLookUpFieldsForIoConfigFields[lookupField.entityName]){
                  allLookUpFieldsForIoConfigFields[lookupField.entityName] = {};
                }
                allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName] = previousAllLookUpFieldsDataForIoConfigFields[lookupField.entityName][lookupField.fieldName];
                results.forEach(record => {
                  try {
                    if(record && record[lookupField.indskr_lookupentityprimaryidattribute]){
                      let idx = allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName].findIndex(a=> a[lookupField.indskr_lookupentityprimaryidattribute] == record[lookupField.indskr_lookupentityprimaryidattribute]);
                      if(idx >= 0){
                        if(record['statecode'] == 0){
                          allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName][idx] = record;
                        }else{
                          allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName].splice(idx,1);
                        }
                      }else{
                        if(record['statecode'] == 0){
                          allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName].push(record);
                        }
                      }
                    }
                  }catch (error) {
                    console.log('lookup fields record for io config field - mapping failed', error)
                  }
                })
              }else{
                if(!allLookUpFieldsForIoConfigFields[lookupField.entityName]){
                  allLookUpFieldsForIoConfigFields[lookupField.entityName] = {};
                }
                allLookUpFieldsForIoConfigFields[lookupField.entityName][lookupField.fieldName] = results;
              }
            }
          }
        }
        if(changeInData){
          this.disk.updateOrInsert(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, (doc)=>{
            doc = {
                raw: allLookUpFieldsForIoConfigFields,
                lastUpdatedTime: new Date().getTime(),
            };
            doc.raw = allLookUpFieldsForIoConfigFields;
            return doc;
          })
        }
      } catch (error) {
        console.log('lookup fields data for io config fields - service failed', error)
      }
    }
  }

  public async getConfigFieldLookupResults(lookupField:ConfiguredFields,lastUpdatedTime): Promise<any> {
    const entitiesToExcludeStatecodeAttribute = ['systemusers'];
    let maximumCount = 1000;
    // if(searchParams && searchParams.length > 3) {
    //   maximumCount = "5000";
    // }

    let fetchXML = lookupField.indskr_lookupquery;
    if(fetchXML.includes('count="{0}"')){
      fetchXML = fetchXML.replace(`count="{0}"`, `count="`+maximumCount+`"`);
    }else {
      //count="4"
      fetchXML = fetchXML.replace(`<fetch `, `<fetch count="`+maximumCount+`" `);
    }
    if (lastUpdatedTime) {
      let hourDifference = differenceInHours(new Date(), new Date(lastUpdatedTime));
      hourDifference += 1
      fetchXML = fetchXML.replace(`<condition attribute="statecode" operator="eq" value="0" />`,'');
      fetchXML = fetchXML.replace('</entity>', `
            <attribute name="statecode" />
            <filter type="and">
              <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}"/>
            </filter>
          </entity>
        `);
    }else {
      if(!fetchXML.includes('<condition attribute="statecode" operator="eq" value="0" />')){
        fetchXML = fetchXML.replace('</entity>', `
            <attribute name="statecode" />
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
          </entity>
        `);
      }
    }
    if(entitiesToExcludeStatecodeAttribute.includes(lookupField.indskr_lookupentitysetname)){
      fetchXML = fetchXML.replace('<attribute name="statecode" />','');
      fetchXML = fetchXML.replace('<condition attribute="statecode" operator="eq" value="0" />','');
    }
    //execute fetch query
    let retryWithoutStatecode: boolean = false;
    let response = await this.dynamics.executeFetchXml(lookupField.indskr_lookupentitysetname, fetchXML)
      .then((res) => {
        return res;
      },
        (err) => {
          if (err && err.message) {
            if (err.message.includes(`entity doesn't contain attribute with Name = 'statecode'`)) {
              retryWithoutStatecode = true;
            }
          } else {
            console.log('Lookup search dynamics error', err);
          }
        })
    if (retryWithoutStatecode) {
      fetchXML = fetchXML.replace(`
            <attribute name="statecode" />
            <filter type="and">
              <condition attribute="statecode" operator="eq" value="0" />
            </filter>
        `, ``);
      response = await this.dynamics.executeFetchXml(lookupField.indskr_lookupentitysetname, fetchXML)
        .then((res) => {
          return res;
        },
          (err) => {
            console.log('Lookup search dynamics error', err);
          })
    }
    let result:any = response && response.value ? response.value : response;
    if(entitiesToExcludeStatecodeAttribute.includes(lookupField.indskr_lookupentitysetname) && result && result.length > 0){
      result = result.map(a=> {
        a['statecode'] = 0;
        return a;
      })
    }
    return result;
  }


  }
