import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DeviceService } from '@omni/services/device/device.service';


@Component({
  selector: 'go-app-title',
  templateUrl: './go-app-title.component.html',
  styleUrl: './go-app-title.component.scss'
})
export class GoAppTitleComponent {
  public userNameInitial: string = '';

  public backgroundUploadInProgress: boolean = false;
  public syncInProgress: boolean = false;
  public localDbDataLoadInProgress = false;
  public syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
  public hasBaselineEnabled: boolean = false;

  isAndroid: boolean = false;
  isHCPFacing = false;

  constructor(private authService: AuthenticationService,
    private device: DeviceService,
    private translate: TranslateService,
  ) {
    this.userNameInitial = (this.authService.user.displayName.charAt(0) + this.authService.user.displayName.charAt(1)).toUpperCase();
  }

  ngOnInit() {
    this.hasBaselineEnabled = this.authService.hasFeatureAction(FeatureActionsMap.BASELINE);
    this.isAndroid = this.device.isAndroid();
    this.device.isBackgroundUploadInProgressObservable.subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this.syncMessage = this.translate.instant('OFFLINE_UPLOAD_IN_PROGRESS');
    });

    this.device.syncInProgress$.subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this.syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
    });

    this.device.isDataLoadFromLocalDB.subscribe((inProgress) => {
      this.localDbDataLoadInProgress = inProgress;
      this.syncMessage = this.translate.instant('INIT_DATA_IN_PROGRESS');
    });

  }

  public onButtonClick(button) {
    console.log("GoAppTitle button clicked: ", button);
  }
}
