import { Activity } from './activity.class';
import { ChannelActivityType, ChannelType } from '../consent/channel.class';
import { EmailTemplateType } from '../email-templates/email-template.class';
import { ConsentService } from './../../services/consent/consent.service';
import _ from 'lodash';
import { Brand } from '../brand/brand.class';
import { AccompainedUser } from './accompained-user.class';
import { Account } from '../account/account.class';

export enum EmailStatusCodes {
    Draft = 1,
    Completed = 2,
    Sent = 3,
    Received = 4,
    Canceled = 5,
    PendingSend = 6,
    Sending = 7,
    Failed = 8,
    Scheduled = 9,
    Read = 0,
    Shared = 548910000
}

export enum ParticipationTypeMask {
    Sender = 1,
    To = 2,
    CC = 3,
    BCC = 4
}
/**
 * Class for representing an Email activity, extends our base Activity class
 *
 * @export
 * @class EmailActivity
 * @extends {Activity}
 */
export class EmailActivity extends Activity {

    public emailAttachments: EmailAttachment[];
    public template_id: string;
    public templateName:string;
    public emailActivityParties: Array<EmailActivityParty>;
    public emailStatus: EmailStatusCodes;
    public statuscode: number;
    public statecode: number;
    public description: string;
    public track_action: number;
    public offlineActivityId: string;
    public createdOffline: boolean;
    public emailSubject: string;
    public activityid: string;
    public deleted: boolean;
    public templatePreview: string;
    public editablePreview: string;
    public subjectDisabled: boolean = true;
    public templateRefProductId;
    public offlineAddedResource: any[];
    public offlineRemovedResource: any[];
    public appointmentid: string;
    public template_statuscode: number;
    public parentemailid: string;
    public indskr_datetimecreatedonapp: Date
    public channelType: ChannelType;
    public emailType: EmailTemplateType;
    public indskr_therapeuticareaid: String;
    public indskr_channelid: string;
    public channelName : string;
    public phoneCallActivityId : string;
    public phoneCallOfflineId:string;
    public products:Array<any>;
    public indskr_emailnotes: string;
    public product_id: string;
    public level1Product: string;
    public level2Product: string;
    public emailProduct: Brand;
    public emailProductName:string;
    public isMessageExternal: boolean = false;
    public channelActivityType: ChannelActivityType;
    public accompaniedUserList: AccompainedUser[] = [];
    public isPreviewEnabled: boolean = false;
    public indskr_recommendedmessage: boolean = false;
    public indskr_suggestionrescheduled: boolean = false;
    public indskr_reason: string;
    public lastopenedtime : string;
    public accounts: Array<Account>;
    public email_linksclickedcount: number;
    public email_attachmentopencount: number;

    /**
   * Returns a string depending on the current accounts associated with the appointment
   *
   * @readonly
   * @type {string}
   * @memberof EmailActivity
   */
  get accountString(): string {
    //Only one contact, return his name
    if (this.accounts.length === 1) {
      let accountString: string = `${this.accounts[0].accountName}`;
      if(this.accounts[0].status === 2 || this.accounts[0].status == 548910003 || this.accounts[0].status == 548910010 || this.accounts[0].status == 548910011 || this.accounts[0].status == 548910012 || this.accounts[0].status == 548910013) {
        return accountString += ' (Inactive)';
      }
      return accountString;
    } else if (this.accounts.length >= 2) {
      //find first alphabetical account
      this.accounts.sort((accountA, accountB): number => {
        if (accountA.accountName > accountB.accountName) return 1;
        if (accountA.accountName < accountB.accountName) return -1;
        return 0;
      });

      let accountString = `${this.accounts[0].accountName}`;
      if (this.accounts[0].status === 2 || this.accounts[0].status == 548910003 || this.accounts[0].status == 548910010 || this.accounts[0].status == 548910011 || this.accounts[0].status == 548910012 || this.accounts[0].status == 548910013) {
        accountString += ` (Inactive) +${this.accounts.length - 1}`;
      } else if (this.accounts.some((acc, index) => {
        if (index == 0) return false;
        return (acc.status === 2 || acc.status == 548910003 || acc.status == 548910010 || acc.status == 548910011 || acc.status == 548910012 || acc.status == 548910013);
      })) {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1} (Inactive)`;
      } else {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1}`;
      }
      return accountString;
    }
    return 'Select Accounts';
  }
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

    public get isSocialChannel() {
        return [ChannelType.FACEBOOK, ChannelType.SMS, ChannelType.WHATSAPP].some(c => c === this.channelType);
    }
    constructor(raw: object) {
        super(raw);
        this.offlineActivityId = raw['offlineActivityId'];
        this.statuscode = this.status;
        if (raw['emailType']) {
          switch (raw['emailType']) {
            case 100000000 : 
              this.emailType = EmailTemplateType.RemoteURL;
              break;
            case 100000001 : 
              this.emailType = EmailTemplateType.Resource;
              break;
            case 548910000 : 
              this.emailType = EmailTemplateType.FirstTimeEmail;
              break;
            case 548910001 : 
              this.emailType = EmailTemplateType.CalendarInvite;
              break;
            case 100000002 : 
              this.emailType = EmailTemplateType.OptIn;
              break;
            case 451680000 : 
              this.emailType = EmailTemplateType.OptOut;
              break;
            default :
              this.emailType = EmailTemplateType.Resource;
              break;
          }
        }
        if (this.offlineActivityId && this.offlineActivityId.indexOf('remoteurl') >= 0) {
            this.emailType = EmailTemplateType.RemoteURL;
        }
        if (raw['indskr_type'] && raw['indskr_type'] === 548910000) {
          this.emailType = EmailTemplateType.FirstTimeEmail;
        }
        this.channelType = raw['channelType'] || ChannelType.EMAIL;
        this.indskr_channelid = raw['indskr_channel'];

        if (raw['channelName']) {
          this.channelName = raw['channelName'];
        }
        if (raw['indskr_channel']) {
          this.indskr_channelid = raw['indskr_channel'];
        } else {
          this.indskr_channelid = raw['indskr_channelid'];
        }

        if (this.status == 6 && this.scheduledEnd > new Date()) {
            this.status = 9;
        }

        switch (this.status) {
            case 1:
                this.emailStatus = EmailStatusCodes.Draft;
                break;
            case 2:
                this.emailStatus = EmailStatusCodes.Completed;
                break;
            case 3:
                this.emailStatus = EmailStatusCodes.Sent;
                break;
            case 4:
                this.emailStatus = EmailStatusCodes.Received;
                break;
            case 5:
                this.emailStatus = EmailStatusCodes.Canceled;
                break;
            case 6:
                this.emailStatus = EmailStatusCodes.PendingSend;
                break;
            case 7:
                this.emailStatus = EmailStatusCodes.Sending;
                break;
            case 8:
                this.emailStatus = EmailStatusCodes.Failed;
                break;
            case 9:
                this.emailStatus = EmailStatusCodes.Scheduled;
                break;
            case 548910000:
                this.emailStatus = EmailStatusCodes.Shared;
                break;
        }

        if (raw['ownerId']) {
          this.meetingOwnerId = raw['ownerId'];
          this.ownerId = raw['ownerId'];
        }
        this.emailActivityParties = raw['emailActivityParties'] ? raw['emailActivityParties'] : [];
        if(raw['smsActivityParties'] && raw['smsActivityParties'].length != 0){
          raw['smsActivityParties'].forEach(party => {
            let idx = this.emailActivityParties.findIndex(a=> a.indskr_contactid == party.indskr_contactid);
            if(idx >= 0){
              this.emailActivityParties[idx] = {
                emailActivityId: party['emailActivityId'] || '',
                indskr_contactid: party['indskr_contactid'] || '',
                contact_lastname: party['contact_lastname'] || '',
                contact_firstname: party['contact_firstname'] || '',
                contact_mobilephone: party['phonenumber'] || '',
                contact_indskr_facebookpsid:'',
                emailAddresses: [],
                isSelected: false,
                allowToRemove: false,
                email_statuscode: party['email_statuscode'] || null,
                email_channelprovidererror: party['email_channelprovidererror'] || null,
                email_channelproviderresponse: party['email_channelproviderresponse'] || null,
                contact_statuscode: party['contact_statuscode'] || null,
                readOn: party['readOn'] || null,
                email_senton: party['email_senton'] || null,
                email_failedOn: party['email_failedOn'] || null,
              };
            }else{
              this.emailActivityParties.push({
                emailActivityId: party['emailActivityId'] || '',
                indskr_contactid: party['indskr_contactid'] || '',
                contact_lastname: party['contact_lastname'] || '',
                contact_firstname: party['contact_firstname'] || '',
                contact_mobilephone: party['phonenumber'] || '',
                contact_indskr_facebookpsid:'',
                emailAddresses: [],
                isSelected: false,
                allowToRemove: false,
                email_statuscode: party['email_statuscode'] || null,
                email_channelprovidererror: party['email_channelprovidererror'] || null,
                email_channelproviderresponse: party['email_channelproviderresponse'] || null,
                contact_statuscode: party['contact_statuscode'] || null,
                readOn: party['readOn'] || null,
                email_senton: party['email_senton'] || null,
                email_failedOn: party['email_failedOn'] || null,
              })
            }
          });
        }
        this.description = raw['description'];
        this.emailSubject = raw['subject'];
        this.template_id = raw['template_id'];
        this.activityid = raw['activityid'] || raw['activityId'] || null;
        this.channelActivityType = raw['channelActivityType'];
        this.indskr_recommendedmessage = raw['indskr_recommendedmessage'] || false;
        this.indskr_suggestionrescheduled = raw['indskr_suggestionrescheduled'] ?? false;
        this.indskr_reason = raw['indskr_reason'];
        this.subject = this.getTitleOfEmailActivity();
        if (typeof this.template_id != undefined && this.template_id) {
            this.info = this.emailSubject;
        }
        this.appointmentid = raw['appointmentid'];

        if (raw['phoneCallOfflineId']) {
          this.phoneCallOfflineId = raw['phoneCallOfflineId'];
        }
        if(raw['phoneCallActivityId']){
            if (raw['phoneCallActivityId'].includes('offline')) {
                this.phoneCallOfflineId = raw['phoneCallActivityId'];
            }
            else {
              this.phoneCallActivityId = raw['phoneCallActivityId']
            }
        }
        else if(raw['phonecallid']){
            this.phoneCallActivityId = raw['phonecallid']
        }
        else{
            this.phoneCallActivityId = undefined
        }
        this.deleted = raw['deleted'] ? raw['deleted'] : false
        this.template_statuscode = raw['template_statuscode'];
        this.lastopenedtime = raw['lastopenedtime'];
        this.emailAttachments = raw['emailAttachments'] ? this.getAttachments(raw['emailAttachments']) : [];
        if(!raw['smsActivityParties']?.length){
          this.updateEmailActivityPartyStatus(this.emailActivityParties);
        }
        this.parentemailid = raw['parentemailid'];
        this.indskr_datetimecreatedonapp = raw['indskr_datetimecreatedonapp'];
        this.product_id = raw['product_id'];

        //Comes in contact timeline response only
        if(raw['emailProductName']){
          this.emailProductName = raw['emailProductName'];
        }
        if(raw['templateName']){
          this.templateName = raw['templateName'];
        }

        this.indskr_emailnotes = raw['indskr_emailnotes'];
        this.level1Product = raw['level1Product'];
        this.level2Product = raw['level2Product'];

        /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
        this.accounts = raw['activityAccounts'] || undefined;
        /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
        if (!_.isEmpty(this.emailActivityParties)) {
          let numOfClickedActualLink: number = 0;
          let numOfClickedAttachmentOpen: number = 0;
          this.emailActivityParties.forEach(p => {
            if (p.activityid == this.ID || p.emailActivityId == this.ID) {
              if (!_.isEmpty(p.emailAddresses)) {
                p.emailAddresses.forEach(e => {
                  if (e.email_linksclickedcount) {
                    numOfClickedActualLink += e.email_linksclickedcount;
                  }
                  if (e.email_attachmentopencount) {
                    numOfClickedAttachmentOpen += e.email_attachmentopencount;
                  }
                });
              }
            }
            this.email_linksclickedcount = numOfClickedActualLink;
            this.email_attachmentopencount = numOfClickedAttachmentOpen;
          });
        }
    }

  public get accompaniedUserString(): string {
    let userString: string = "";
    if (this.accompaniedUserList != undefined) {
      if (this.accompaniedUserList.length <= 1) {
        let names = this.accompaniedUserList.map(e => e.name);
        userString = names.join(",");
      }
      else {
        userString = this.accompaniedUserList[0].name + " +" + (this.accompaniedUserList.length - 1);
      }
    }
    return userString;
  }

  private updateEmailActivityPartyStatus(emailActivityParties: EmailActivityParty[]) {
    if (emailActivityParties.length > 0) {
      for (let emailActivityParty of emailActivityParties) {
        if (this.emailStatus === EmailStatusCodes.Draft) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Draft;
        } else if (!emailActivityParty.emailAddresses || emailActivityParty.emailAddresses.length === 0) {
          emailActivityParty.email_statuscode = this.emailStatus;
        } else if ((this.channelActivityType === ChannelActivityType.WECOM && this.lastopenedtime == null && this.emailStatus === EmailStatusCodes.Sent)
          || (emailActivityParty.emailAddresses.some(emailAddress => (emailAddress.email_statuscode == EmailStatusCodes.Sent) && emailAddress.readOn == null))) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Sent;
        } else if (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.Shared)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Shared;
        } else if (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.PendingSend)) {
          emailActivityParty.email_statuscode = EmailStatusCodes.PendingSend;
        } else if (emailActivityParty.emailAddresses.filter(emailAddress => emailAddress.email_statuscode == EmailStatusCodes.Failed).length === emailActivityParty.emailAddresses.length) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Failed;
        }
        if (emailActivityParty.email_statuscode != EmailStatusCodes.Failed &&
          (emailActivityParty.emailAddresses.some(emailAddress => emailAddress.readOn != null) ||
            (this.channelActivityType === ChannelActivityType.WECOM && this.lastopenedtime != null && this.emailStatus === EmailStatusCodes.Sent))) {
          emailActivityParty.email_statuscode = EmailStatusCodes.Read;
        }
      }
      if (this.status != EmailStatusCodes.Failed &&
        emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == EmailStatusCodes.Read).length === emailActivityParties.length) {
        this.status = EmailStatusCodes.Read;
      }
    }
  }

    public getTitleOfEmailActivity(): string {
        if (this.emailActivityParties) {
            if (this.emailActivityParties.length == 1) {
                return ((this.emailActivityParties[0].contact_firstname || '') + " " + (this.emailActivityParties[0].contact_lastname || ''));
            }
            else if (this.emailActivityParties.length > 1) {
                this.sortedEmailActivtyParties(this.emailActivityParties);
                return ((this.emailActivityParties[0].contact_firstname || '') + " " + (this.emailActivityParties[0].contact_lastname || '') + " +" +
                    (this.emailActivityParties.length - 1));
            }
        }
        return 'Message';
    }

    private sortedEmailActivtyParties(emailActivtyParties: EmailActivityParty[]) {
        emailActivtyParties.sort((contactA, contactB): number => {
            let contactAFullName = (contactA.contact_firstname && contactA.contact_firstname.length > 0) ? contactA.contact_firstname + " " + contactA.contact_lastname : contactA.contact_lastname;
            let contactBFullName = (contactB.contact_firstname && contactB.contact_firstname.length > 0) ? contactB.contact_firstname + " " + contactB.contact_lastname : contactB.contact_lastname;
            if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase())
                return 1;
            if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase())
                return -1;
            return 0;
        });
    }

    public get emailStatusString(): string {
        switch (this.emailStatus) {
            case 1: return 'Open';
            case 2: return 'Completed';
            case 3: return 'Sent';
            case 4: return 'Received';
            case 5: return 'Canceled';
            case 6: return 'Pending Sync';
            case 7: return 'Sending';
            case 8: return 'Failed';
            case 9: return 'Scheduled';
            case 548910000: return 'Shared';
        }
    }

    /**
     * Returns a DTO version of our associated accounts
     *
     * @readonly
     * @type {Array<Object>}
     * @memberof AppointmentActivity
     */
    get accountsDTO(): Array<Object> {
      if (_.isEmpty(this.accounts)) return [];
        let accountsArray = [];

        this.accounts.map(account => {
            accountsArray.push({
                indskr_accountid: account.id
            });
        });

        return accountsArray;
    }

    get DTO(): Object {

        let DTOResponse = {
            activityAccounts: this.accountsDTO ? this.accountsDTO : [],
            offlineActivityId: this.offlineActivityId || '',
            subject: this.emailSubject || '',
            scheduledstart: '' + this.scheduledStart.getTime(),
            scheduledend: '' + this.scheduledEnd.getTime(),
            statuscode: this.status || 1,
            template_id: this.template_id,
            product_id: this.templateRefProductId ? this.templateRefProductId : this.product_id,
            level1Product: this.level1Product,
            level2Product: this.level2Product,
            activityid: this.ID || '',
            activitytypecode: "email",
            phoneCallOfflineId: this.phoneCallOfflineId,
            phoneCallActivityId: this.phoneCallActivityId,
            emailAddresses: this.getEmailAddressIds(),
            deleted: this.deleted,
            emailAttachments: this.addResource,
            ownerId: this.ownerId,
            meetingOwnerId:this.ownerId,
            deleteAttachments: this.deletedResource,
            emailActivityParties: this.emailActivityParties,
            description: this.description,
            indskr_datetimecreatedonapp: this.indskr_datetimecreatedonapp,
            indskr_therapeuticareaid: this.indskr_therapeuticareaid,
            indskr_emailnotes: this.indskr_emailnotes || "",
            statecode: this.state || this.statecode || 0,
            isMessageExternal: this.isMessageExternal ? this.isMessageExternal : false,
            isPreviewEnabled: this.isPreviewEnabled ? this.isPreviewEnabled : false,
            indskr_recommendedmessage: this.indskr_recommendedmessage,
            indskr_reason: this.indskr_reason,
            indskr_suggestionrescheduled: this.indskr_suggestionrescheduled,
            modified: this.modified
        }
        if (this.indskr_channelid){
          DTOResponse['indskr_channelid'] = this.indskr_channelid;
        }
        if(this.channelActivityType){
          DTOResponse['channelActivityType'] = this.channelActivityType;
        }
        if(this.channelType){
          DTOResponse['channelType'] = this.channelType;
        }
        if (this.appointmentid) { DTOResponse['appointmentid'] = this.appointmentid; }
        if (this.phoneCallActivityId) { DTOResponse['phoneCallActivityId'] = this.phoneCallActivityId; }
        //f75445e
        if (!DTOResponse.emailAttachments) {
            delete DTOResponse.emailAttachments;
        }

        if (!DTOResponse.deleteAttachments) {
            delete DTOResponse.deleteAttachments;
        }


        return DTOResponse;
    }

    private get addResource(): any[] {
        if (this.offlineAddedResource) {
            let res = [];
            this.offlineAddedResource.forEach(e => {
                let reqObject = e;
                for (let k in e) {
                    if (reqObject[k] === null || reqObject[k] === undefined || reqObject[k] === "") {
                        delete reqObject[k];
                    }
                }
                res.push(reqObject);
            });
            return res;
        }
        else
            return [];
    }

    private get deletedResource(): any[] {
        if (this.offlineRemovedResource) {
            let res = [];
            this.offlineRemovedResource.forEach(e => {
                if (e.indskr_emailattachmentid) {
                    let o;
                    o = { indskr_emailattachmentid: e.indskr_emailattachmentid } ;
                    res.push(o);
                }
            });
            return res;
        }
        else
            return [];
    }

    private getEmailAddressIds() {
         let emailAddresIds: string[] = [];
         if(this.channelActivityType === ChannelActivityType.SMS || this.channelActivityType === ChannelActivityType.WHATSAPP) {
              emailAddresIds = [];
         } else {
          this.emailActivityParties.forEach(emailActivityParty => {
            emailActivityParty.emailAddresses.forEach(emailAddress => emailAddresIds.push(emailAddress.email_addressid));
        });
         }
        return emailAddresIds;
    }

    private getAttachments(obj): EmailAttachment[] {
        let resp: EmailAttachment[] = [];
        if (obj && Array.isArray(obj)) {
            obj.forEach(e => {
                resp.push(new EmailAttachment(e));
            });
            return resp;
        }
        else {
            return resp;
        }
    }

}

export class ActivityAccount {
  constructor(public accountId: string,
    public accountName: string
  ) {

  }
}

export class EmailActivityParty {
    public emailActivityId: string;
    public indskr_contactid: string;
    public contact_lastname: string;
    public contact_firstname: string;
    public contact_mobilephone: string;
    public contact_indskr_facebookpsid: string;
    public emailAddresses: EmailAddress[];
    public isSelected: boolean;
    public allowToRemove: boolean;
    public email_statuscode: number;
    public email_channelprovidererror: string;
    public email_channelproviderresponse: string;

    public contact_statuscode: number;
    public isNotConsented?: boolean;
    public readOn?:number;
    public email_senton?:number;
    public email_failedOn?:number;
    public activityid?: string;
}

export class EmailAddress {
    public email_addressid: string;
    public emailAddress: string;
    public readOn: Date;
    public email_statuscode: number;
    public email_senton: Date;
    public emailAddress_statuscode: number;
    public attachmentDetails: AttachmentInteractionDetails[];
    public email_opencount: number;
    public email_linksclickedcount: number;
    public emailActivityId: string;
    public email_failedOn: Date;
    public activityid?: string;
    public email_attachmentopencount?: number;

    constructor(email_addressid?: string, emailAddress?: string, readOn?: Date,
        email_statuscode?: number, email_senton?: Date, attachmentDetails?: AttachmentInteractionDetails[],
        emailAddress_statuscode?: number, email_opencount?: number, email_linksclickedcount?: number,
        emailActivityId?: string,
        email_failedOn?: Date,
        email_attachmentopencount?: number
        ) {
        this.email_addressid = email_addressid;
        this.emailAddress = emailAddress;
        this.readOn = readOn;
        this.email_statuscode = email_statuscode;
        this.email_senton = email_senton;
        this.attachmentDetails = attachmentDetails;
        this.emailAddress_statuscode = emailAddress_statuscode;
        this.email_opencount = email_opencount;
        this.email_linksclickedcount = email_linksclickedcount || 0;
        this.emailActivityId = emailActivityId;
        this.email_failedOn = email_failedOn;
        this.email_attachmentopencount = email_attachmentopencount || 0;
    }
}

export class AttachmentInteractionDetails {
    public indskr_resourceid: string;
    public indskr_documentid: string;
    public interactionTime: Date;
}
export class EmailAttachment {
  emailActivityId: String;
  indskr_resourceid: string;
  statuscode: number;
  resource_statuscode: number;
  indskr_emailattachmentid: String;
  indskr_ckmtitle: string;
  indskr_ckmthumbnailurl: string;
  indskr_ckmasseturl: string;
  messageInteractions: MessageAttachmentInteractions[];

  constructor(raw: any) {
    this.emailActivityId = raw.emailActivityId;
    this.indskr_emailattachmentid = raw.indskr_emailattachmentid;
    this.indskr_resourceid = raw.indskr_resourceid ? raw.indskr_resourceid : (raw['indskr_ioresourceid'] ? raw['indskr_ioresourceid'] : raw.indskr_documentid);
    this.resource_statuscode = raw.resource_statuscode ? raw.resource_statuscode : raw.document_statuscode;
    this.statuscode = raw.statuscode;
    this.indskr_ckmtitle = raw.indskr_ckmtitle;
    this.indskr_ckmthumbnailurl = raw.indskr_ckmthumbnailurl;
    this.indskr_ckmasseturl = raw.indskr_ckmasseturl;
    this.messageInteractions = raw.messageInteractions;
  }
}
export class MessageAttachmentInteractions {
  constructor(public indskr_readtime: string,
    public indskr_readduration: number) {

  }
}

export enum ContactProperties {
    firstName = '%contact.firstname%',
    lastName = '%contact.lastname%',
    fullname = '%contact.fullname%',
    fax = '%contact.fax%',
    primaryEmail = '%contact.email%',
    title = '%contact.title%',
    onekeyTitle = '%contact.onekeytitle%',
    onekeyPrefix = '%contact.onekeytitlename%',
    onekeyMailingName = '%contact.individualsname%',
    mobilePhone = '%contact.mainphone%',
    addressesList = '%contact.businessaddress%'
}

export enum UserProperties {
    givenName = '%user.firstname%',
    lastName = '%user.lastname%',
    displayName = '%user.fullname%',
    mail = '%user.email%',
    mobilePhone = '%user.primaryphone%',
    photoUrl = '%user.signature%',
    title = '%user.title%',
    academicTitle = '%user.academictitle%'
}
export enum ProductProperties {
    name = '%product.name%'
}

export enum MeetingProperties {
    meetingURL = '%remote.url%',
    indskr_teamslink = '%remote.teamsurl%'
}

export enum EmailActionType {
    SCHEDULE,
    TEMPLATE_ADD,
    TEMPLATE_UPDATE,
    SUBJECT,
    CONTACT_REMOVE,
    RESOURCE_REMOVE,
    CONTACT_AND_RESOURCE_REMOVE,
    STATUS,
    NOTES,
    PRODUCT,
}

export enum EmailViewType {
    CREATE_FROM_MEETING, FROM_AGENDA, FROM_CONTACT_TIMELINE,FROM_SCIENTIFIC_PLAN, FROM_XPERIENCES, EMAIL_FROM_MEETING_PRESENTATION
    ,CREATE_FROM_PHONE_CALL, EMAIL_SCRAPPED,
}

export class ConsentedContact {
  public hasConsented: boolean;
  public contact: any;
}
