<ion-header>
  <ind-page-title [viewData]='pageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content>
  <ind-section-header [viewData]='opportunityDetailsHeader'></ind-section-header>
  <ind-form-field *ngFor="let field of newOpportunityFormFieldValues" [viewData]='field'
    (onInputClick)='onFieldClick($event)'></ind-form-field>
  <ind-datetime-form *ngIf="estimatedCloseDateFormField" [viewData]='estimatedCloseDateFormField' (onInputClick)='onFieldClick($event)'></ind-datetime-form>
  <ind-form-field *ngIf="estimatedValueFormField" [viewData]='estimatedValueFormField'></ind-form-field>
  <!-- <ind-form-field *ngIf="productCategoryFormField" [viewData]='productCategoryFormField'></ind-form-field> -->
  <ind-form-field *ngIf="productsFormField" [viewData]='productsFormField'></ind-form-field>
  <ind-form-field *ngIf="marketingPlanFormField" [viewData]='marketingPlanFormField'></ind-form-field>
  <!-- Stakeholder grid -->
  <ind-section-header [viewData]='stakeholderLineItemDetailsHeader'
    (onControlClick)='handleStakeholderField()'></ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="!allModelStakeholder || allModelStakeholder.length==0"></ion-item>
  <main-card *ngFor="let stakeholder of allModelStakeholder" [viewData]="stakeholder"></main-card>

</ion-content>