import {Component, Input} from '@angular/core';
import {PopoverController} from '@ionic/angular';


@Component({
  selector: 'ind-alert-msg',
  templateUrl: 'ind-alert-msg.html',
  styleUrls: ['ind-alert-msg.scss'],
})
export class IndAlertMsgComponent{
  @Input() inputs: any;
  @Input() header: string;
  @Input() message: string
  @Input() Placeholder: string
  @Input() cancelText;
  @Input() confirmText;
  @Input() isMandatory: boolean;
  selectedItem: any;
  selectedOtherItem: string;

  constructor(
    private popoverCtrl: PopoverController,
  ) {


  }

  // onSelectOption(ev) {
  //   this.selectedItem = ev;
  //   if (this.selectedItem != 'Others') {
  //     this.selectedOtherItem = null;
  //   }
  // }

  onDismiss(flag) {
    if (flag && this.isMandatory && _.isEmpty(this.selectedOtherItem)) return;
    if (!flag) {
      this.popoverCtrl.dismiss();
      return;
    }

    let payload = {
      inputs: this.selectedItem,
      selectedOtherItem: this.selectedOtherItem ? this.selectedOtherItem : null,
      role: 'ok'
    }

    this.popoverCtrl.dismiss(payload);

  }

}
