<ion-grid class="content-page" no-padding
  [ngClass]="(navService.isActiveChildNavRightPaneViewDisplayed)?'child-nav-active':'child-nav-inactive'">
  <ion-row no-padding>
    <ion-col class="left-pane" no-padding>
      <ion-header>
        <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="searchInput"
          [searchDisabled]="activities?.length == 0" (onControlClick)="onPageTitleControlClick($event)"
          (onSearchInput)="searchText($event)">
        </ind-header-left>
      </ion-header>
      <ion-content no-padding [ngClass]="{'vectorimageleft': !searchActive && activities?.length == 0}">
        <ng-container *ngIf="deviceIsOffline; else displayData">
          <p justify-content-center class="no-data-message">
            {{'AVAILABLE_IN_ONLINE_ONLY' | translate}}
          </p>
        </ng-container>
        <ng-template #displayData>
          <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <ind-section-header *ngIf="selectedActivitiesMainCardModel.length > 0"
              [viewData]='{id:"selecteded_items",title: translate.instant("SELECTED")+" ("+selectedActivities.length+")"}'>
            </ind-section-header>
            <main-card *ngFor="let item of selectedActivitiesMainCardModel" [id]="item.id" [viewData]="item">
            </main-card>
            <ind-section-header
              [viewData]='{id:"items_list_header",title: (searchActive ? translate.instant("AL_RESULTS") : (translate.instant("ALL") + " " + indHeaderLeftModel.title)) + " (" + activitiesMainCardModel.length + ")"}'>
            </ind-section-header>
            <main-card *ngFor="let item of activitiesMainCardModel" [id]="item.ID" [viewData]="item"></main-card>
            <p *ngIf="!searchActive && activitiesMainCardModel.length == 0" class="no-data-message">
              {{noActivitiesText}}
            </p>
            <ind-no-search-result *ngIf="searchActive && activitiesMainCardModel.length === 0"
              [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
          </ion-list>
        </ng-template>
      </ion-content>
      <footer-toolbar [footerView]="'masterView'" [selectedView]="'CoachingActivities'"></footer-toolbar>
    </ion-col>
    <ion-col class="right-pane" no-padding>
      <ion-nav #accountplanactivitiesrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>