<ion-item>
    <ion-textarea [attr.autocapitalize]="autoCapitalize" debounce="debounce" [maxlength]="maxLength" [inputmode]="inputMode"
        [minlength]="minLength" placeholder="{{placeHolder}}" [readonly]="readonly" [required]="required" [spellcheck]="spellCheck"
        [attr.cols]="cols" [attr.rows]="rows" [attr.wrap]="wrap" [attr.auto-grow]="autoGrow" [(ngModel)]="value"
        (ngModelChange)='ontextChange($event)' [disabled]="disabled" (ionFocus)="onFocus()" (ionBlur)="onBlur()"
        (input)="onInput($event)" [attr.ws]="ws">
          <ion-chip *ngIf="showAttachment" [style.display]="chipHidden ? 'none' : 'flex'">
            <ion-label>{{attachmentTitle}}</ion-label>
            <ion-icon *ngIf="hasRemove" src="assets/imgs/remove-icon.svg" (click)="deleteAttachment($event)"></ion-icon>
            <ion-icon *ngIf="hasDownload" src="assets/imgs/download-icon.svg" (click)="downloadAttachment($event)"></ion-icon>
          </ion-chip>
      </ion-textarea>
</ion-item>
