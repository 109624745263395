<ion-header>
  <ion-toolbar class="page-title" [ngClass]="{ 'offline-indicator': repService.isOfflineState }">
      <ind-header-left  [viewData]="indHeaderLeftModel"
        [searchDisabled]="true"
        [hideFilterButton]="true"
        [(searchText)]="contactSearchText"
        (onControlClick)="onPageTitleControlClick()"
      ></ind-header-left>
  </ion-toolbar>

</ion-header>

<ion-content>


  <ion-item-divider  sticky='true' *ngIf="$any(activityService.selectedActivity)?.contacts.length > 0">
    <div > {{ 'SELECTED_CAP' | translate}} ({{$any(activityService.selectedActivity)?.contacts.length}})</div>
  </ion-item-divider>

  <ion-list>
    <ion-item *ngFor="let selContact of $any(activityService.selectedActivity)?.contacts" [ngClass]="{'itemSelected':(selContact?.ID===contactService.contactInformation?.ID)}" (click)="openDetails(selContact)">
      {{selContact.firstName}} {{selContact.lastName}}
      <span *ngIf="selContact.isguest" class="contact-state-guest">{{ 'CONTACT_GUEST' | translate}}</span>
    </ion-item>
  </ion-list>
</ion-content>
