import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {AuthenticationService} from "../../services/authentication.service";
import {Endpoints} from "../../../config/endpoints.config";
import {ContactConsent} from "../../classes/consent/contact-consent.class";
import {Channel} from "../../classes/consent/channel.class";
import {ConsentTerm} from "../../classes/consent/consent-term.class";
import { ConsentActivity } from "@omni/classes/consent/consent-activity.class";
import { IONote } from "@omni/classes/io/io-note.class";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { differenceInHours } from "date-fns";


@Injectable({
  providedIn: 'root'
})
export class ConsentDataService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    public dynamics: DynamicsClientService,
  ) {

  }

  public async getActiveConsents(initialSync:boolean, lastUpdatedTime?): Promise<ContactConsent[]> {
    if (initialSync) {
      return await this.http.get<ContactConsent[]>(this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.consent.GET_ACTIVE_CONSENTS
          .replace('{{positionIDs}}',this.authenticationService.getLoggedInUserPositions().toString()),
        Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } else {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.consent.GET_DELTA_ACTIVE_CONSENTS
          .replace('{{positionIDs}}',this.authenticationService.getLoggedInUserPositions().toString())
          .replace('{lastUpdatedTime}', lastUpdatedTime);
      return await this.http.get<ContactConsent[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    }
  }

  async getDeletedActiveConsents(lastModifiedDate){
    let hourDifference;
    let now = new Date();
    let deletedRecords;
    let fetchXML = fetchQueries.fetchDeletedActiveConsents;
    hourDifference = differenceInHours(
      now,
      new Date(lastModifiedDate)
    )
    //add one to make sure we take care of fractional difference in hours
    hourDifference += 1
    fetchXML = fetchXML.replace('{hourDifference}',hourDifference);
    await this.dynamics.executeFetchQuery('indskr_trackchanges', fetchXML).then((data)=>{
      console.log('deleted active Consents',data);
      deletedRecords = data;
    })
    return deletedRecords;
  }


  public async getActiveConsentsByContactId(contactId:string): Promise<ContactConsent[]> {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.consent.GET_ACTIVE_CONSENTS_BY_CONTACTID
          .replace('{{contactId}}', contactId);
      return await this.http.get<ContactConsent[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async getConsentChannels(initialSync:boolean, lastUpdatedTime?): Promise<Channel[]> {
    if (initialSync) {
      return await this.http.get<Channel[]>(this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.consent.GET_CONSENT_CHANNELS, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } else {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.consent.GET_DELTA_CONSENT_CHANNELS.replace('{lastUpdatedTime}', lastUpdatedTime);
      return await this.http.get<Channel[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    }
  }

  public async getConsentTerms(initialSync:boolean, lastUpdatedTime?): Promise<ConsentTerm[]> {
    let headers: HttpHeaders = Endpoints.GLOBAL_SYNC_HEADER.headers;
    headers = headers.set('X-BusinessUnitId', this.authenticationService.user.xBusinessUnitId);

   let url =  this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_CONSENT_TERMS
   if (!this.authenticationService.user.isProductConsent) {
     url = url.concat('?isProductConsent=false')
   }
    if (initialSync) {
      return await this.http.get<ConsentTerm[]>(url, { headers: headers }).toPromise();
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_DELTA_CONSENT_TERMS.replace('{lastUpdatedTime}', lastUpdatedTime);
      if (!this.authenticationService.user.isProductConsent) {
        url = url.concat('&isProductConsent=false')
      }
      return await this.http.get<ConsentTerm[]>(url, { headers: headers }).toPromise();
    }
  }

  public async getConsentTermsForAllocationOrders(initialSync:boolean, lastUpdatedTime?): Promise<ConsentTerm[]> {
    let headers: HttpHeaders = Endpoints.GLOBAL_SYNC_HEADER.headers;
    headers = headers.set('X-BusinessUnitId', this.authenticationService.user.xBusinessUnitId);

   let url =  this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_CONSENT_TERMS
   url = url.concat('?isProductConsent=false')

   if (initialSync) {
      return await this.http.get<ConsentTerm[]>(url, { headers: headers }).toPromise();
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_DELTA_CONSENT_TERMS.replace('{lastUpdatedTime}', lastUpdatedTime);
      url = url.concat('&isProductConsent=false')
      return await this.http.get<ConsentTerm[]>(url, { headers: headers }).toPromise();
    }
  }

  public async saveConsent(term: ConsentTerm) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.consent.SAVE_CONSENT;
    return this.http.post(url, term).toPromise();
  }

  public async saveOptOutConsentActivity(consentActivities) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.consent.SAVE_CONSENT_ACTIVITIES;
    return this.http.post(url, consentActivities).toPromise();
  }

  public async savePaperConsent(notes: IONote[]) {
    const url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.SAVE_PAPER_CONSENT;
    return this.http.post(url, notes, Endpoints.headers.content_type.json).toPromise();
  }

}
