import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { UIService } from '@omni/services/ui/ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { EventRegistrationStatus, EventStatus } from '@omni/enums/event/event.enum';
import { isFuture, isPast } from 'date-fns';
import { ModalController } from '@ionic/angular';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';

/**
 * Generated class for the ContactOmnichannelEventsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-omnichannel-events',
  templateUrl: 'contact-omnichannel-events.html',
  styleUrls: ['contact-omnichannel-events.scss']
})
export class ContactOmnichannelEventsComponent {

  public pageTitleViewData: IndPageTitleViewDataModel;
  public contactGlanceViewData: GlanceCardViewDataModel;
  public eventsSectionHeader: IndSectionHeaderViewDataModel;
  public viewEventItems: Array<TimeLineItem> = [];
  private currentStartIndex: number = 30;
  private _allEvents: Array<EventActivity> = [];
  private _filteredEvents: Array<EventActivity> = [];
  private holdActivityDetailsPage:PageName;
  private ngDestroy$ = new Subject<boolean>();
  public searchPlaceholder: string;
  public filterButtonBadgeCount: number = 0;
  public eventsFilterFormView: IndFilterMenuModalDataModel[] = [];
  public selectedFilters: any = [];
  private selectedDate: { startDate: string, endDate: string } = { startDate: '', endDate: '' };

  constructor(
    private readonly contactService: ContactOfflineService,
    private readonly navService: NavigationService,
    private readonly translate: TranslateService,
    private readonly eventsToolService: EventsToolService,
    private readonly customer360UIservice: Customer360UIService,
    private readonly device: DeviceService,
    private readonly uiService: UIService,
    private readonly eventsToolDataService: EventsToolDataService,
    public footerService: FooterService,
    public activityService: ActivityService,
    private readonly modalCtrl: ModalController,
    private secondaryInfoService: SecInfoConfigDataService,
  ) {

  }

  ngOnInit() {
    this._initPageTitle();
    this._initGlanceCardViewData();
    this._initEventItemsViewData();
    this._initFilterFormViews();
    this._initSectionHeadersView();
    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && page == this.holdActivityDetailsPage && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent){
        this._initEventItemsViewData();
        this._initSectionHeadersView();
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private get events(): Array<EventActivity> {
    return this._filteredEvents.sort((a,b)=> {
      if (a.scheduledStart > b.scheduledStart) return -1;
      if (a.scheduledStart < b.scheduledStart) return 1;
      return 0;
    });
  }

  private _initPageTitle() {
    this.pageTitleViewData = {
      id: "contact-timeline-details",
      title: this.contactService?.contactInformation?.fullName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }]
    };
    this.searchPlaceholder = this.translate.instant('SEARCH');
  }

  private async _initGlanceCardViewData() {
    let glanceCardButtons = [];
    if (this.contactService.isAffiliationEnabled) {
      glanceCardButtons.push({
        id: "affiliation-explorer",
        imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
        name: this.translate.instant('AFFILIATION_EXPLORER'),
        isDisabled: !((this.contactService.linkEntityContactTo && this.contactService.linkEntityContactTo.length) || (this.contactService.linkEntityContactFrom && this.contactService.linkEntityContactFrom.length)
          || (this.contactService.affiliationAccount && this.contactService.affiliationAccount.length)),
        align: "right"
      });
    }
    const values = await this.contactService._getQuickGlanceValues();

    if(!this.device.isOffline){
      this.contactService.getEntityImageStringForContact(this.contactService.contactInformation).then((entityImageString => {
        if(entityImageString){ 
          this.contactService.contactInformation.entityImage = entityImageString;
          if(this.contactGlanceViewData){
            this.contactGlanceViewData.avatarURL = this.contactService.contactInformation.entityImage
          }
        }
      }));
    }

    const sequenceName = this.contactService.getFullNameForContactDisplaySequence(this.contactService.contactInformation);
    
    this.contactGlanceViewData = {
      name: sequenceName,
      locationText: this.contactService.contactInformation.getPrimaryAddress,
      avatarURL: this.contactService.contactInformation.entityImage,
      thirdText: (values && values.length && values[0]) ? values[0].value : '',
        fourthText: (values && values.length && values[1]) ? values[1].value : '',
        fifthText: (values && values.length && values[2]) ? values[2].value : '',
        sixthText: (values && values.length && values[3]) ? values[3].value : '',
        thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
        fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
        fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
        sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
      buttons: glanceCardButtons,
      enableExpand: true,
      isExpanded: false,
    };
  }

  public async onQuickGlanceControlClick(data) {
    if (data.id) {
      switch (data.id) {
        case 'affiliation-explorer':
          this._handleAffiliationExplorer();
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private async _handleAffiliationExplorer() {
    let params = {
      from: 'ContactPageComponent',
      parent: this.contactService.contactInformation,
      relatedTo: await this.contactService.getContactRelatedToByContactId(this.contactService.contactInformation.ID),
      contactAccountAffiliation: await this.contactService.getContactToAccountByContactId(this.contactService.contactInformation.ID),
      relatedFrom: await this.contactService.getContactRelatedFromByContactId(this.contactService.contactInformation.ID),
    };
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.ContactPageComponent, params);
  }

  private _initSectionHeadersView() {
    this.eventsSectionHeader = {
      id: 'contact-events-list-section',
      title: this.translate.instant('EVENTS')+' ('+this._filteredEvents.length+')',
      controls: []
    }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this._closePage();
    }
  }

  private _closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private _initEventItemsViewData() {
    if (this.contactService.contactInformation.ID) {
      if (this.eventsToolService.eventsToolData && this.eventsToolService.eventsToolData.length > 0) {
        this._allEvents = this._filteredEvents = this.eventsToolService.eventsToolData.filter(a=> {
          return ( ( a.participants && a.participants.some(b=> b.id == this.contactService.contactInformation.ID  && !(
            b.isDeleted
            || b.indskr_reasons === EventRegistrationStatus.Cancelled
            || b.indskr_reasons === EventRegistrationStatus.Declined
            || b.indskr_reasons === EventRegistrationStatus['Cancelled late']
            || b.indskr_reasons === EventRegistrationStatus['No show'])) )|| (a.statuscode == EventStatus.DRAFT && isFuture(a.endDate)))
        })
      }
      this.viewEventItems = this._getViewForEvents(this.events.slice(0, this.currentStartIndex))
    }
  }

  public doInfinite(event) {
    this.viewEventItems.push(...this._getViewForEvents(this.sliceActivities(this.currentStartIndex + 1, this.currentStartIndex + 30)));
    this.currentStartIndex += 30;
    event.target.complete();
  }

  private _getViewForEvents(events:Array<EventActivity>):Array<TimeLineItem> {
    let viewItems:Array<TimeLineItem> = [];
    if(events && events.length > 0){
      events.forEach(eventItem => {
        const view = this.customer360UIservice.getOmnichannelViewForEventActivity(eventItem);
        if(view){
          viewItems.push(view);
        }
      });
    }
    return viewItems;
  }

  private sliceActivities(startIndex: number, count: number) {
    return this.events.length < count ? this.events.slice(startIndex) : this.events.slice(startIndex, count);
  }

  public async openEventDetails(ev) {
    let item: any = this.viewEventItems.find(a => ev && a.id == ev.value);
    if (item) {
      let activity = item.refObject;
      if (activity.type == ActivityType.Event || activity instanceof EventActivity) {
        const eventsToolData = this.eventsToolService.eventsToolData;
        const foundEvent = eventsToolData.find(e => {
          if (e.ID) {
            return e.ID === activity.ID;
          } else if (e.offlineId) {
            return e.offlineId === activity.ID;
          } else {
            return false;
          }
        });
        if (!_.isEmpty(foundEvent)) {
          activity = foundEvent;
          this.eventsToolService.selectedEventOnEventsTool = foundEvent;
        } else {
          return;
        }
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          await this.eventsToolDataService.fetchEventRealTimeDetails(activity as EventActivity).then((updatedEvent: EventActivity) => {
            activity = updatedEvent;
            this.uiService.dismissLoader();
          }).catch(err => {
            console.log(err);
            this.uiService.dismissLoader();
          });
        }
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = activity;
        this.holdActivityDetailsPage = PageName.EventDetailsPageComponent;
        this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.ContactPageComponent, {
          from: 'ContactTimeline', event: this.activityService.selectedActivity,
        });
        this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
        this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
      }
    }
  }

  private _initFilterFormViews() {
    this.eventsFilterFormView = [];
    if (!_.isEmpty(this.events)) {
      let filterFormViewMain: IndFilterMenuModalDataModel = {
        id: 'filter-main',
        displayName: this.translate.instant('STATUS'),
        isMultiSelect: true,
        isExpanded: true,
        options: []
      }
      this.eventsFilterFormView.push(filterFormViewMain);
      filterFormViewMain.options.push({ id: 'event-status-eligible', group: 'eligible', isSelected: false, displayName: this.translate.instant('ELIGIBLE') });
      filterFormViewMain.options.push({ id: 'event-status-proposed', group: 'proposed', isSelected: false, displayName: this.translate.instant('PROPOSED') });
      filterFormViewMain.options.push({ id: 'event-status-checkin', group: 'checkin', isSelected: false, displayName: this.translate.instant('CHECKED_IN') });
      filterFormViewMain.options.push({ id: 'event-status-missed', group: 'missed', isSelected: false, displayName: this.translate.instant('EVENTS_MISSED') });
    }
  }

  public onFilterClick(ev) {
    this._openFilter(ev);
  }

  private async _openFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.ContactOmnichannelEventsComponent,
          data: this.eventsFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
          selectedDate: this.selectedDate
        },
      }
    });
    await modal.present().then(() => { });
    modal.onDidDismiss().then(async (res) => {
      //Show Result
      if (res && res.data && res.data.isDone) {
        if (!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
          //this.filterButtonBadgeCount = 0;
          //this._filteredEvents = [];
          this.eventsFilterFormView = res.data.selectedItems;
          this.selectedFilters = res.data.selectedItemsAccordion;
          // this.filterButtonBadgeCount = res.data.selectedItemsAccordion.length;
          // if (!_.isEmpty(res.data.selectedDate) && (!_.isEmpty(res.data.selectedDate.startDate) || !_.isEmpty(res.data.selectedDate.endDate))) {
          //   this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
          // }
          // let selectedActivitiesFromMainFilter = [];
          // res.data.selectedItemsAccordion.forEach(item => {
          //   if (item.type == 'filter-main') {
          //     selectedActivitiesFromMainFilter.push(item.category);
          //   }
          // });

          /******* filtered activities by duration *******/
          let isFilteredByDate: boolean = !_.isEmpty(res.data.selectedDate) && !_.isEmpty(res.data.selectedDate.startDate) && !_.isEmpty(res.data.selectedDate.endDate) || false;
          //let targetEventActivities = _.cloneDeep(this.events);
          if (isFilteredByDate) {
            this.selectedDate = {
              startDate: res.data.selectedDate.startDate,
              endDate: res.data.selectedDate.endDate,
            };
            //targetEventActivities = this._filterEventActivitiesByDate(targetEventActivities, this.selectedDate);
          } else {
            this.selectedDate = {
              startDate: '',
              endDate: '',
            };
          }
          this._applyAdvancedFilterOnEventActivities();
        }
        console.log(res);
      }
    })
  }

  private _applyAdvancedFilterOnEventActivities(){
    if (!_.isEmpty(this.eventsFilterFormView) || !_.isEmpty(this.selectedDate)) {
      this.filterButtonBadgeCount = 0;
      let _filteredTimelineActivities = [];
      this.filterButtonBadgeCount = this.selectedFilters.length;
      if (!_.isEmpty(this.selectedDate) && (!_.isEmpty(this.selectedDate.startDate) || !_.isEmpty(this.selectedDate.endDate))) {
        this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
      }
      let selectedActivitiesFromMainFilter = [];
      this.selectedFilters.forEach(item => {
        if (item.type == 'filter-main') {
          selectedActivitiesFromMainFilter.push(item.category);
        }
      });

      /******* filtered activities by duration *******/
      let isFilteredByDate: boolean = !_.isEmpty(this.selectedDate) && !_.isEmpty(this.selectedDate.startDate) && !_.isEmpty(this.selectedDate.endDate) || false;
      let targetActivities = _.cloneDeep(this._allEvents);
      if (isFilteredByDate) {
        // this.selectedDate = {
        //   startDate: this.selectedDate.startDate,
        //   endDate: this.selectedDate.endDate,
        // };
        targetActivities = this._filterEventActivitiesByDate(targetActivities, this.selectedDate);
      } else {
        // this.selectedDate = {
        //   startDate: '',
        //   endDate: '',
        // };
      }

      /******* filtered activities by selected activity and options *******/
      if (!_.isEmpty(selectedActivitiesFromMainFilter)) {
        let activities = [];
        selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
          if (mainFilterValue == "eligible") {
            targetActivities.forEach(eventActivity=> {
              if(eventActivity.statuscode == EventStatus.DRAFT && !(eventActivity.participants && eventActivity.participants.some(b=> b.id == this.contactService.contactInformation.ID  && !(
                b.isDeleted
                || b.indskr_reasons === EventRegistrationStatus.Cancelled
                || b.indskr_reasons === EventRegistrationStatus.Declined
                || b.indskr_reasons === EventRegistrationStatus['Cancelled late']
                || b.indskr_reasons === EventRegistrationStatus['No show'])) ))
              {
                if(!activities.some(a=> a.ID == eventActivity.ID || a.offlineId == eventActivity.offlineId)){
                  activities.push(eventActivity);
                }
              }
            });
          }else if (mainFilterValue == "proposed"){
            targetActivities.forEach(eventActivity=> {
              if(eventActivity.participants && eventActivity.participants.some(b=> b.id == this.contactService.contactInformation.ID  && !b.isDeleted && b.indskr_reasons == EventRegistrationStatus.Proposed)){
                if(!activities.some(a=> a.ID == eventActivity.ID || a.offlineId == eventActivity.offlineId)){
                  activities.push(eventActivity);
                }
              }
            });
          }else if (mainFilterValue == "checkin"){
            targetActivities.forEach(eventActivity=> {
              if(eventActivity.participants && eventActivity.participants.some(b=> b.id == this.contactService.contactInformation.ID  && !b.isDeleted && b.indskr_reasons == EventRegistrationStatus['Checked-in'])){
                if(!activities.some(a=> a.ID == eventActivity.ID || a.offlineId == eventActivity.offlineId)){
                  activities.push(eventActivity);
                }
              }
            });
          }else if (mainFilterValue == "missed"){
            targetActivities.forEach(eventActivity=> {
              if(eventActivity.participants && eventActivity.participants.some(b=> b.id == this.contactService.contactInformation.ID && isPast(eventActivity.endDate) && !b.isDeleted && (b.indskr_reasons == EventRegistrationStatus.Proposed || b.indskr_reasons == EventRegistrationStatus.Accepted))){
                if(!activities.some(a=> a.ID == eventActivity.ID || a.offlineId == eventActivity.offlineId)){
                  activities.push(eventActivity);
                }
              }
            });
          }
        });
        targetActivities = activities;
      }

      /******* grouped filtered activities *******/
      this._filteredEvents = targetActivities;
      this.viewEventItems = this._getViewForEvents(this.events.slice(0, this.currentStartIndex));
      this._initSectionHeadersView();
    }else{
      this._filteredEvents = this._allEvents;
      this.viewEventItems = this._getViewForEvents(this.events.slice(0, this.currentStartIndex));
      this._initSectionHeadersView();
    }
  }

  private _filterEventActivitiesByDate(targetActivities: any, selectedDate: any) {
    const startDate: Date = new Date(selectedDate.startDate);
    const endDate: Date = new Date(selectedDate.endDate);
    targetActivities.map(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['scheduledStart'] != '' && activity['scheduledStart'] != "Invalid Date" && activity['scheduledStart'] != undefined) {
        activity['isValidStartDate'] = true;
        activity['scheduledStart'] = new Date(activity['scheduledStart']);
      }
      if (activity.hasOwnProperty('scheduledEnd') && activity['scheduledEnd'] != '' && activity['scheduledEnd'] != "Invalid Date" && activity['scheduledEnd'] != undefined) {
        activity['isValidEndDate'] = true;
        activity['scheduledEnd'] = new Date(activity['scheduledEnd']);
      }
    });
    let filteredTimelineActivities = [];
    targetActivities.forEach(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate'] && activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledStart'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }
    });
    // targetTimelineActivities = filteredTimelineActivities;
    return filteredTimelineActivities;
  }

}
