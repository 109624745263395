<div *ngIf="!isInternalSurvey" class="searchbar-wrapper">
  <ion-searchbar placeholder="{{searchPlaceholder}}"[(ngModel)]="searchInput" (ionInput)="handleSearch()"></ion-searchbar>
  <div class="searchbar-overlay">
    <ion-buttons tooltip="{{'FILTERS' | translate}}" class="searchbar-overlay-button contactTimelineButtons" slot="end">
      <ion-icon class="searchbar-overlay-button-filter-icon contactTimelineFilterIcon" src="assets/imgs/filter-{{(totalSubmittedListLength == 0 && totalSavedListLength == 0) ? 'grey' : 'blue'}}.svg" (click)="onFilterClick($event)"> </ion-icon>
      <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge" (click)="onFilterClick($event)">{{ filterButtonBadgeCount }}</span>
    </ion-buttons>
  </div>
</div>
<div class="timelineWrapper">
  <div>
    <ion-list class="containerForData">
      <!-- Saved Survey list -->
      <ind-section-header [viewData]="savedSurveyListSectionHeader"></ind-section-header>
      <div class="no-data-message" *ngIf="timelineSavedSurveys?.length == 0">{{ 'NO_DATA' | translate}}</div>
        <ion-item-group class="timeline-group" *ngFor="let group of groupedSavedSurveysToDisplay">
            <ion-item-divider sticky='true' [id]="group.key">
                <div class="dividerBorder"></div>
                <div class="monthGroupName">{{group.key}}</div>
            </ion-item-divider>
            <ion-item *ngFor="let survey of group.list" (click)="openSavedSurveyDetails(survey)">
                <ion-grid>
                    <ion-row class="grid-row">
                        <ion-col size="small" class="col-first">
                            <ion-icon class='icon-left' src="assets/imgs/survey_black.svg"></ion-icon>
                        </ion-col>
                        <ion-col size="9" clase="col-second">
                            <div class="col-9 ion-float-left ion-margin-right" size="9">
                                <div class="info-label">{{survey.modifiedon}}</div>
                                <div *ngIf="!isInternalSurvey" class="info-label">{{ 'SURVEYED_BY' | translate}} {{survey.surveyedBy}}</div>
                                <div class="info-primary-label">{{survey.name}}</div>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
        <!-- Submitted Survey list -->
        <ind-section-header [viewData]="submittedSurveyListSectionHeader"></ind-section-header>
        <div class="no-data-message" *ngIf="timelineSubmittedSurveys?.length == 0">{{ 'NO_DATA' | translate}}</div>
        <ion-item-group class="timeline-group" *ngFor="let group of groupedSubmittedSurveysToDisplay">
            <ion-item-divider sticky='true' [id]="group.key">
                <div class="dividerBorder"></div>
                <div class="monthGroupName">{{group.key}}</div>
            </ion-item-divider>
            <ion-item *ngFor="let survey of group.list" (click)="openSubmittedSurveyDetails(survey)">
                <ion-grid>
                    <ion-row class="grid-row">
                        <ion-col size="small" class="col-first">
                            <ion-icon class='icon-left' src="assets/imgs/survey_black.svg"></ion-icon>
                        </ion-col>
                        <ion-col size="9" clase="col-second">
                            <div class="col-9 ion-float-left ion-margin-right" size="9">
                                <div class="info-label">{{survey.modifiedon}}</div>
                                <div *ngIf="!isInternalSurvey" class="info-label">{{ 'SURVEYED_BY' | translate}} {{survey.surveyedBy}}</div>
                                <div class="info-primary-label">{{survey.name}}</div>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-item>
        </ion-item-group>
        <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-list>
  </div>
</div>