<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [(searchText)]="searchText"
    (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="onInput()"
    (onFilterClick)="openFilterPopup($event)" [filterButtonBadgeCount]="filterCount">
  </ind-header-left>
  <ion-toolbar no-padding>
    <ind-tabs [data]="tabsData" [(selectedTab)]="selectedTab" (selectedTabChange)="checkModel($event)">
    </ind-tabs>
  </ion-toolbar>

  <ind-section-header [viewData]='manageListSectionHeader' (onControlClick)='onSectionControlClick($event)'>
  </ind-section-header>
</ion-header>

<ion-content [ngClass]="{'vectorimageleft': filteredListToDisplay?.length == 0 && !currentListPeriod}">
  <ion-row *ngIf="currentListPeriod && selectedTab=='Manage List'" class="list-period-section">
    <ion-col size="6">
      <omni-list-item label="{{'CURRENT_PERIOD' | translate}}" value="{{formattedStartDate}} - {{formattedEndDate}}"
        cssClass="list-item">
      </omni-list-item>
    </ion-col>
    <ion-col size="6">
      <omni-list-item label="{{ 'NEW_WINDOW_OPEN' | translate }}"
        value="{{formattedNextWindowStartDate}} - {{formattedNextWindowEndDate}}" cssClass="list-item">
      </omni-list-item>
    </ion-col>
  </ion-row>
  <ion-list lines="none" class="territory-list">
    <ion-item *ngIf="filteredListToDisplay.length === 0 && !isSearching">
      <div class="no_customer_position_list">{{'NO_MANAGE_LIST' | translate }}</div>
    </ion-item>
    <div *ngFor="let customerPositionList of filteredListToDisplay" [ngClass]="{'itemSelected':
    selectedCustomerPositionList && selectedCustomerPositionList.indskr_customerpositionlistid == customerPositionList.indskr_customerpositionlistid 
      && selectedCustomerPositionList.approvalActivityId === customerPositionList.approvalActivityId}"
      (click)="openCustomerPositionListDetails(customerPositionList)">
      <ion-item lines="none">
        <!-- <ion-icon name="indegene-manage-list" class="list-icon" slot="start"></ion-icon> -->
        <img src="assets/imgs/manage-list.svg" alt="list-icon" class="list-icon" slot="start">
        <ion-label class="manage-list-header"> {{customerPositionList.listPeriod.indskr_name}}</ion-label>
        <ion-button slot="end" fill="clear">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-row>
        <ion-col size="6">
          <omni-list-item label="{{ 'PERIOD_DATE' | translate }}"
            value="{{customerPositionList.listPeriod.startDateFormatted}} - {{customerPositionList.listPeriod.endDateFormatted}}"
            cssClass="list-item">
          </omni-list-item>
        </ion-col>
        <ion-col size="6">
          <omni-list-item label="{{ 'STATUS' | translate }}"
            value="{{territoryService.getStatusCodeFormatted(customerPositionList.statuscode)}}" cssClass="list-item">
          </omni-list-item>
        </ion-col>
        <ion-col size="6">
          <omni-list-item label="{{ 'POSITION' | translate }}" value="{{customerPositionList.positionName}}"
            cssClass="list-item">
          </omni-list-item>
        </ion-col>
      </ion-row>
    </div>
  </ion-list>
  <div *ngIf="filteredListToDisplay?.length == 0 && isSearching">
    <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  </div>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="'contacts'"></footer-toolbar>