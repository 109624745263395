import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { FooterService } from '@omni/services/footer/footer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { AuthenticationService } from './../../../services/authentication.service';
import { TrackingEventNames, TrackService } from './../../../services/logging/tracking.service';
// import { AccountPlanOpportunityListComponent } from './../account-plan-opportunity-list/account-plan-opportunity-list';
import * as _ from 'lodash';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { EventsService } from '@omni/services/events/events.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { MarketingPlanManagementDataService } from '@omni/data-services/marketing-plan/marketing-plan-management.data.service';
import {FeatureActionsMap} from "@omni/classes/authentication/user.class";
import {OpportunityManagementService} from "@omni/services/opportunity-management/opportunity-management.service";
import {NewOpportunityComponent} from "@omni/components/opportunity-management/new-opportunity/new-opportunity";
import { isPast } from 'date-fns';



@Component({
  selector: 'marketing-plan-details',
  templateUrl: 'marketing-plan-details.html',
  styleUrls: ['marketing-plan-details.scss']
})

export class MarketingPlanDetails implements OnInit, OnDestroy {

  @Input() data: any;
  @Input() selectedMode: any;
  public isOpportunitiesEnabled = true;
  //calling it a reference as its a copy by reference and not by value
  public planDataSetReference: MarketingPlan;
  private ngUnSubscibe$ = new Subject<boolean>();
  public pageTitle: IndPageTitleViewDataModel;
  public deviceOffline: boolean = false;
  tabsData: IndTabsDataModel[] = [
    {
      displayText: this.translate.instant('INFO'),
      value: 'info'
    },
    {
      displayText: this.translate.instant('TIMELINE'),
      value: 'timeline',
      disable: false,
    }
  ];
  public marketingPlanDetailSegment: string = this.tabsData[0].value;
  validForm = false;


  constructor(
    private events : EventsService,
    private readonly navService: NavigationService,
    private readonly uiService: UIService,
    public readonly translate: TranslateService,
    public device: DeviceService,
    public trackingService: TrackService,
    public footerService: FooterService,
    public authService: AuthenticationService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public alertService:AlertService,
    private marketingPlanManagementDataService: MarketingPlanManagementDataService,
    public opportunityService: OpportunityManagementService,

  ) { }
  ngOnInit() {

    const MarketingPlan = this.data;

    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      // if the device offlice diisable account plan cancel button
      this.deviceOffline = isOffline;
      this.initPageTitle();
    });

    this.marketingPlansManagementOfflineService.marketingPlans$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((data) => {
      if (data && data.length) {
        this.planDataSetReference = data.find(o => o.ID === MarketingPlan.ID);
        this.initPageTitle();
      }
    });


    this.planDataSetReference = this.marketingPlansManagementOfflineService.marketingPlans.find(o => o.ID === MarketingPlan.ID);
    this.initPageTitle();
  }

  ngOnDestroy() {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

  public backToMarketingPlanList(): void {
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.MarketingPlanManagementPageComponent);
    this.uiService.showRightPane = false;
    this.navService.setChildNavRightPaneView(false);
  }

  private initPageTitle(): void {
    this.isOpportunitiesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    console.log('this.isOpportunitiesEnabled', this.isOpportunitiesEnabled);
    let controls = [
      {
        id: 'mbp-scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.device.isOffline,
        align: 'right'
      },
      {
        id: "pluse-icon",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE_OPPORTUNITIES'),
        isDisabled: !this.isOpportunitiesEnabled || this.deviceOffline || this.navService.getPreviousPageName() === PageName.OpportunityManagementPage || isPast(parseInt(this.planDataSetReference.endDate)),
        align: 'right'
      }
    ];
    this.pageTitle = {
      id: 'brand-plan-details',
      title: this.planDataSetReference?.brandPlanName,
      controls: controls,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.backToMarketingPlanList();
        break;
      case 'ap-opportunities':
        // this.handleOpportunities();
        break;
      case 'mbp-scrap':
        this._handleMBPScrap();
        break;
      case 'pluse-icon':
        // Create opportunity
        this.createOpportunities();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private createOpportunities() {
    if(this.navService.getActiveChildNavViewPageName() != PageName.NewOpportunityComponent){
      this.trackingService.tracking('CreateClicked', TrackingEventNames.OPPORTUNITYMANAGEMENT);
      this.navService.pushChildNavPageWithPageTracking(NewOpportunityComponent,PageName.NewOpportunityComponent,PageName.OpportunityManagementPage, {from: 'MarketingPlanInfo', selectableOpportunityAccounts: this.planDataSetReference.accounts, mbp: this.planDataSetReference}).then(() => {
        this.navService.setChildNavRightPaneView(true);
        if(!_.isEmpty(this.planDataSetReference.accounts) && this.planDataSetReference.accounts.length == 1){
          let opportunity = this.opportunityService.newOpportunity$.value;
          opportunity.accountID = this.planDataSetReference.accounts[0].id
          opportunity.accountName = this.planDataSetReference.accounts[0].name;
          this.opportunityService.newOpportunity$.next(opportunity);
        }
      });
      console.log("create opportunity from marketing business plan");
    }
  }


  private _handleMBPScrap(): void {
    if(this.device.isOffline)return

    this.alertService.showAlert(
      {
          title: this.translate.instant('SCRAP_MBP'),
          message: this.translate.instant('R_U_SURE_SCRAP_MBP'),
      },
      this.translate.instant('SCRAP')
  ).then(res=>{
    if (res.role === 'ok') {

      if(!this.device.isOffline){
        this.uiService.displayLoader();
        this.marketingPlanManagementDataService.deleteMarketingBusinessPlan(this.planDataSetReference.ID).then(res=>{
          this.marketingPlansManagementOfflineService.deleteMarketingBusinessPlanFromDB(this.planDataSetReference.ID)
          this.backToMarketingPlanList();
          this.uiService.dismissLoader();
        }).catch(ex=>{
          this.uiService.dismissLoader();


        })
      }
    }
  })
  }

  async segmentChanged(selectedTab: string) {
    this.marketingPlanDetailSegment = selectedTab;
  }
}
