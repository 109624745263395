export const POPUP_MODAL_MAX_HEIGHT_PERCENT = 0.85;
export const POPUP_MODAL_HEADER_HEIGHT = 48;
export const POPUP_MODAL_CLASS_NAME = 'popup-modal';

export const ACCOUNT_VISIT_ALLOWED_FORMAT_IDS_IO_CONFIGURATION_NAME = 'AccountVisit_Allowed_Format_IDs';

export const POSITION_GROUP_BASED_CALCULATED_RATINGS_IO_CONFIGURATION_NAME = 'PG_Based_Calculated_Ratings';
export const REMOTE_MEETING_FORMAT_ID_IO_CONFIGURATION_NAME = 'RemoteMeeting_Format_ID';

export const DEFAULT_SYNC_REMINDER_TIME_IN_HOURS = 24;
export const DEFAULT_AUTO_DELTA_SYNC_TIME_IN_HOURS = 72;
