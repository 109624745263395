<ion-header>
  <ind-page-title [viewData]="pageTitleViewData" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  <div class="searchbar-wrapper">
    <ion-searchbar placeholder="{{searchPlaceholder}}" [(ngModel)]="searchInput" (ionInput)="handleSearch()"></ion-searchbar>
    <div class="searchbar-overlay">
      <ion-buttons tooltip="{{'FILTERS' | translate}}" class="searchbar-overlay-button contactTimelineButtons" slot="end">
        <!-- <div class="section-header-button-text" (click)="clearFilterResults()"> {{ 'CLEAR_FILTER' | translate }} </div> -->
        <ion-icon class="contactTimelineFilterIcon" src="assets/imgs/filter-{{_allTimelineActivities.length == 0 ? 'grey' : 'blue'}}.svg" (click)="onFilterClick($event)"> </ion-icon>
        <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge" (click)="onFilterClick($event)">{{ filterButtonBadgeCount }}</span>
      </ion-buttons>
    </div>
  </div>
  <!--Customer Glance Card-->
  <glance-card *ngIf="contactGlanceViewData" [viewData]="contactGlanceViewData"
    (onControlClick)="onQuickGlanceControlClick($event)"> </glance-card>
</ion-header>
<ion-content>
  <ind-section-header [viewData]='timelineSectionHeader'
    (onControlClickWithEventBubbleUp)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-list class="time-line-list" *ngIf="viewTimelineItems.length > 0">
      <timeline-item *ngFor="let item of viewTimelineItems" [inputData]="item"
        (clickedOnHeader)="openDetails($event,item)"></timeline-item>
      <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="30%">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
  </ion-list>
  <ion-list>
    <div class="no-data-message" *ngIf="viewTimelineItems.length == 0 && contactService.isContactDetailsLoaded">
      {{(isJourney? 'CONTACT_NO_JOURNEY' : 'NO_TIMELINE') | translate}}</div>
  </ion-list>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="onFooterButtonClicked($event)"
  [footerView]="'detailView'" [selectedView]="'contacts'"></footer-toolbar>