import {Observable} from 'rxjs';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IndSectionHeaderViewDataModel} from '@omni/models/indSectionHeaderDataModel';

const DefaultWidth = '30%';
const DefaultLineHeight = '24px';

/**
 * The component utilizes the IndSectionHeaderViewDataModel interface to define the structure and metadata associated with each section header.
 * Title: Displays the title of the section.
 * Customization Options: Provides various customization options such as modifying title case, displaying a right label, and configuring filter-related settings.
 * Arrow Navigation: Supports the display of arrows for navigation within the section.
 * Multi-Filter Support: Allows the section to function as a multi-filter, enabling users to apply multiple filters simultaneously.
 * Search Popover Header: Can be configured as a search popover header, facilitating search functionality within the section.
 * Control Array: Contains an array of controls associated with the section header, allowing users to interact with the data or perform actions specific to the section.
 * Optimized Button Width: Offers an option to optimize button width for improved visual presentation.
 * Vertical Separation Line: Provides an option to display a vertical separation line to visually distinguish the section header from next content.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-section-header',
  templateUrl: 'ind-section-header.html',
  styleUrls:['ind-section-header.scss']
})
export class IndSectionHeaderComponent {

  public defaultWidth = DefaultWidth;
  public defaultLineHeight = DefaultLineHeight;

  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: { headerSkeletonTextWidth: string,
                              headerSkeletonTextLineHeight: string }
            = { headerSkeletonTextWidth: DefaultWidth, headerSkeletonTextLineHeight: DefaultLineHeight };
  @Input() viewData: IndSectionHeaderViewDataModel;
  @Input() loader: Observable<number>;

  @Output() onControlClick = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<string>();
  @Output() onControlClickWithEventBubbleUp = new EventEmitter<{ button: any, event: any, buttonElRef: any }>();

  constructor(){
  }

  public onButtonClick(button, event, buttonElRef){
    if (button.bubbleUpEvent) {
      this.onControlClickWithEventBubbleUp.emit({ button, event, buttonElRef });
    } else if(button.id && !button.isDisabled){
        this.onControlClick.emit(button.id);
    }
  }

  public filterClicked(ev){
    if(this.viewData.isFilter){
      this.onFilterClick.emit(ev);
    }
  }

}
