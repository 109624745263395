import { Component, OnInit } from '@angular/core';
import {NavigationService} from "../../../../services/navigation/navigation.service";
import {DateFormat, DateTimeFormatsService} from "../../../../services/date-time-formats/date-time-formats.service";
import { IndHeaderLeftDataModel } from '../../../../models/indHeaderLeftDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { FooterService } from '@omni/services/footer/footer.service';

/**
 * Generated class for the AppDateFormats component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-date-formats',
  templateUrl: 'app-date-formats.html',
  styleUrls:['app-date-formats.scss']
})
export class AppDateFormats  implements OnInit {
  public dateFormats: DateFormat[];
  noOfFormats: number;
  selectedFormat: string;
  selected: DateFormat;
  public selectedFormatCode: string;
  filteredList: DateFormat[] = [];
  public filteredRecordCount: number;
  private readonly MIN_SEARCH_LENGTH = 1;
  public searching: boolean = false;
  disableDoneBtn: boolean;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  doneButtonRef: any;
  dateFomatHeader: IndSectionHeaderViewDataModel;
  dateFomatResultHeader: IndSectionHeaderViewDataModel

  constructor(
    public navService: NavigationService,
    public dateFormatsService: DateTimeFormatsService,
    private translate: TranslateService,
    public footerService: FooterService
  ) {

  }

  ngOnInit() {
    this.dateFormats = this.dateFormatsService.dateFormats();
    this.filteredList = this.dateFormats;
    this.noOfFormats = this.dateFormats.length;
    this.filteredRecordCount = this.noOfFormats;
    this.selected = this.dateFormatsService.selectedFormat;
    this.selectedFormat = this.selected.display;
    this.selectedFormatCode = this.dateFormatsService.selectedFormat.format;
    this.disableDoneBtn = true;
    this.initHeaderLeft();
    this.getSectionHeader();
    this.getResutHeader();    
  }

   getSectionHeader(){
     this.dateFomatHeader = {
      id:'date-formate-header',
      title: `${this.translate.instant('SETTINGS_ALL_DATE_FORMATS')} (${this.filteredRecordCount})`,
      controls: [],
    };
   }

   getResutHeader(){
     this.dateFomatResultHeader ={
      id:'date-reuslt-header',
      title: `${this.translate.instant('RESULTS_CAP')} (${this.filteredRecordCount})`,
      controls: [],
     }
   }


  private initHeaderLeft() {
    this.indHeaderLeftModel = {
      id: 'app-date-formats-header-left',
      title: this.translate.instant('DATE_FORMATS'),
      mode: true,
      controls: [
        {
          id: 'cancel',
          cssClass: 'seventyPercentWidth',
          imgSrc: 'assets/imgs/header_cancel.svg',
          isDisabled: false,
          align: 'left'
        },
        {
          id: 'done',
          cssClass: 'seventyPercentWidth',
          imgSrc: 'assets/imgs/header_complete.svg',
          isDisabled: this.disableDoneBtn,
          align: 'right'
        }
      ]
    };
    this.doneButtonRef = this.indHeaderLeftModel.controls[1];
  }
  onHeaderControlClick(id: string) {
    if (id === 'cancel') {
      this.onClosePage();
    } else if (id === 'done') {
      this.selectFormat();
    }
  }

  changeFormat(format) {
    this.selected = format;
    this.selectedFormat = format.display;
    this.selectedFormatCode = format.format;
    this.disableDoneBtn = false;
    if (this.doneButtonRef) {
      this.doneButtonRef.isDisabled = this.disableDoneBtn;
    }
  }

  onClosePage() {
    this.navService.popWithPageTracking();
  }

  selectFormat() {
    this.navService.popWithPageTracking();
    localStorage.setItem("selectedDateFormat", JSON.stringify(this.selected));
    this.dateFormatsService.setDateFormat(this.selected);
    this.dateFormatsService.dateFormat.next(this.selectedFormat);
   
    //To set flag across the app and store value in Local Storage  
    this.dateFormatsService.isDateFormatSetViaApp = true;
    localStorage.setItem("isDateFormatSetViaApp", this.dateFormatsService.isDateFormatSetViaApp.toString());
  }

  getItems({ target: { value: searchText = '' } = {} } = {}) {
    if (searchText.length <= this.MIN_SEARCH_LENGTH) { // the start typing after two words bug
      this.searching = false;
      this.filteredList = this.dateFormats;
      this.filteredRecordCount = this.noOfFormats;
      return;
    }
    this.filteredList = [];
    this.searching = true;
    this.filteredList = this.dateFormats.filter(df => {
      return df.display.includes(searchText);
    });
    this.filteredRecordCount = this.filteredList.length;
    this.getResutHeader();
  }

}
