import { AssetTrasferStatus } from './../../classes/field-materials/asset-transfer.class';
import { CustomerAsset } from "@omni/classes/field-materials/customer-asset.class";
import { FieldMaterialManagementService } from './../field-materials/field-material-management.service';
import { TencentService } from './../video-streaming/tencent.service';
import { ProcedureTrackerActivityDataService } from './../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { IAllocationAdjustment } from './../../interfaces/allocation/allocation-adjustment.interface';
import { AllocationFeatureService } from './../sample/allocation.feature.service';
import { ChannelActivityType, ChannelType } from './../../classes/consent/channel.class';
import { AppointmentActivity, CovisitorAccess } from './../../classes/activity/appointment.activity.class';
import { AuthenticationService } from './../authentication.service';
import { Injectable, Injector } from "@angular/core";
import { ActivityService } from "../activity/activity.service";
import { ActivityType, MeetingActivityTypeCode } from '../../classes/activity/activity.class';
import { UIService, PresentationView } from "../ui/ui.service";
import { WebsocketDataService } from "../../data-services/websocket/websocket.data.service";
import { DeviceService } from '../device/device.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { TimeOffActivity } from "../../classes/activity/timeoff.class";
import { TimeOffService } from '../time-off/time-off.service';
import { CoachingReportService } from '../coaching/coaching.report.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { SchedulerService } from "../scheduler/scheduler.service";
import { ConsentService } from "../consent/consent.service";
import { EmailActivity, EmailViewType } from '../../classes/activity/email.activity.class';
import { EmailService } from '../email-templates/email.service';
import { AccesingMode, CaseManagementService } from '../case-management/case-management.service';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { CustomerEventsService } from '../customer-event/customer-events.service';
import { PresentationService } from '../presentation/presentation.service';
import { PageName, NavigationService } from '../navigation/navigation.service';
import { ContentMode, SwipeStatus } from '../../classes/presentation/presentation.class';
import _ from 'lodash';
import { MarketScanService } from '../market-scan/market-scan.service';
import { MarketScanStatusCode } from '../../classes/market-scan/market-scan.class';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OpentokService } from '../video-streaming/opentok.service';
import { GlobalUtilityService } from '../global-utility.service';
import { EventsService } from '../events/events.service';
import { SampleService, SamplingDetailsViewMode } from '../sample/sample.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { debounceTime } from 'rxjs/operators';
import { AdjustmentStatus, TransferType } from '../../enums/allocation/allocation.enum';
import { FooterButtonOptions, FooterButtons } from './footer.buttons';
import { FollowUpActivityDataService } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { CustomerSurveyService } from '../customer-survey/customer-survey.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { FeatureActionsService } from '../feature-actions/feature-actions.service';
import { EmailTemplateType } from '@omni/classes/email-templates/email-template.class';

export enum FooterViews {
  Activities = 'activities',
  EmailDetails = 'emailDetails',
  MePage = 'mepage',
  Contacts = 'contacts',
  Accounts = 'accounts',
  Presentations = 'presentations',
  Resources = 'resources',
  MeetingURLEmail = 'meetingUrlEmail',
  PreviewMeeting = 'Meeting',
  PresentationFullscreen = 'fullscreenre',
  Calendar = 'calendar',
  CustomerCallPlan = 'customercallplan',
  ResourcesInMeeting = "ResourcesInMeeting",
  ResourceParticipants = "ResourceParticipants",
  CallPlanDetails = "callplandetails",
  TimeOffDetails = "TimeOffDetails",
  CoachingDetails = "CoachingDetails",
  SampleDropDetails = "SampleDropDetails",
  SampleDropInMeetingDetails = "SampleDropInMeeting",
  CaptureSampleOrder = "CaptureSampleOrder",
  ShipmentDetails = "ShipmentDetails",
  AdjustDtails = "AdjustDtails",
  SchedulingDetails = "SchedulingDetails",
  CallNotesAssistant = "CallNotesAssistant",
  ConsentDetails = "ConsentDetails",
  ConsentCapture = "ConsentCapture",
  EmailTemplates = "EmailTemplates",
  EmailTemplatesPreview = "EmailTemplatesPreview",
  AccountPlanReport = "AccountPlanReports",
  AccountPlanObjectives = 'AccountPlanObjectives',
  AccountPlanReportApproval = "AccountPlanReportApproval",
  FollowUpDetails = 'follow-up',
  AccountPlanReportCancelReview = 'AccountPlanReportCancelReview',
  CUSTOMER_INQUIRY = 'CustomerInquiry',
  ORDER_ACTIVITY_OPEN = 'OpenOrderActivity',
  ORDER_ACTIVITY_AUTO_APPROVE = 'AutoApproveOrderActivity',
  ORDER_ACTIVITY_FOR_REVIEW = 'ForReviewOrderActivity',
  ORDER_ACTIVITY_FOR_REVIEW_TEAM_ORDERS = 'ForReviewOrderActivityInTeamOrders',
  ORDER_ACTIVITY_APPROVED = 'ApprovedOrderActivity',
  SURGERY_ORDER_ACTIVITY = 'SurgeryOrderActivity',
  APP_SETTING_UPLOAD = "Appsettingupload",
  ScientificPlanDetails = "ScientificPlanDetails",
  OPPORTUNITY_INFO_OPEN = "OpportunityInfo",
  OPPORTUNITY_INFO_CONFIRMED = "OpportunityInfoConfirmed",
  MARKETING_EMAIL = "marketingEmailDetails",
  PREVIEW_MEETING_ACTIVITY = "PreviewMeetingActivity",
  INQUIRY_CONSENT = "inquiryConsent",
  APP_SETTINGS_ABOUT = "aboutsettings",
  APP_SETTINGS_GEN_SETTINGS = "generalsettings",
  PHONE_CALL = 'phoneCall',
  MARKET_SCAN_DETAILS = 'marketScanDetails',
  EVENTS_DETAILS = 'eventsDetails',
  CAPTURE_EVENTS = 'captureEvents',
  PDF_PREVIEW = 'pdfPreview',
  MESSAGE_NON_NATIVE = 'messageNonnative',
  None = 'none',
  ALLOCATION_TRANSFER = 'Allocation Transfer',
  CalendarInvite = 'calendarinvite',
  GenericRightPane = 'genericRightPane',
  PROCEDURE_TRACKER_ACTIVITY = "ProcedureTrackerActivity",
  ASSET_DETAILS='AssetDetails',
  ASSET_TRANSFER_DETAILS='AssetTransferDetails',
  MULTIPLE_ASSESS='multipleAssess',
  CONTACT_KOL_STATUS_OWNER = 'contactKOLStatusOwner',
  CONTACT_KOL_STATUS_APPROVER = 'contactKOLStatusApprover',
  CONTACT_MEDICAL_INSIGHT = "ContactMedicalInsight",
  WECOM = 'weCom',
  MANAGE_LIST_CUSTOMER_POSITION_LIST = "ManageListCustomerPositionList",
  APPROVAL_CUSTOMER_POSITION_LIST = "ApprovalCustomerPositionList",
  SANOFI_MDM_DETAILS = "SanofiMdmDetails",
  SURVEY_DETAILS = "surveyDetails",
  SAVE_SURVEY_DETAILS = "saveSurveyDetails",
  DCR_APPROVALS = "DCRApprovals",
  FILTER_MENU = "FilterMenu",
  STORE_CHECK = "StoreCheck",
  PROCEDURE_CONTRACT = 'procedureContract',
  APPEAL_DETAILS = 'appealDetails',
  SetBooking = "SetBooking",
}

export enum FabViewType {
  detailFabView = 1,
  fullscreenFabView = 2,
  invalidFabView = 3
}
@Injectable({
  providedIn: 'root'
})
export class FooterService {

  public isActivitiesButton: boolean = true;
  public footerSelectedView: string;
  public footerView: string;

  public footerForView: FooterViews = FooterViews.None;

  public showCenterGeenie: boolean;

  private buttonIdPublisher = new BehaviorSubject<string>('');
  public buttonIdObserver = this.buttonIdPublisher.asObservable();

  public actionButtons = [];

  samplingInModalViewActive: boolean = false;
  golbalContactName: any;

  public previousFooterView;

  private initActionButtons() {
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        this.golbalContactName = this.translate?.instant('STAKEHOLDER');
        break;
      case 'Contact':
        this.golbalContactName = this.translate?.instant('CONTACT');
        break;
      case 'Customer':
        this.golbalContactName = this.translate?.instant('CUSTOMER');
        break;
      default:
        this.golbalContactName = this.utilityService.globalCustomerText;
        break;
    }
    this.actionButtons = this.footerButtons.getFooterOptions();
  }

  private _globalCustomerTxtUpdatedSub: Subscription;
  private _deviceWidthSubscription: Subscription;
  private _deviceWidth = 0;
  private translate: TranslateService
  // Just to keep reference of what we've in footer-toolbar.ts
  public hasVisibleFooterIconsInMaster: boolean = false;

  // case-popover, select-list which are presented as a modal, don't have the proper navigation stack
  // to fix this, have to set to true when we're presenting one of these pop over
  // and set to false when we're dismissing one of these pop over
  // should be applicable to Master FAB only
  public forceHideMasterFooter: boolean = false;

  constructor(
    private activityService: ActivityService,
    private emailService: EmailService,
    private uiService: UIService,
    private wsService: WebsocketDataService,
    private deviceService: DeviceService,
    private opentok: OpentokService,
    private tencentService: TencentService,
    private callPlanOfflineService: CallPlanOfflineService,
    private authService: AuthenticationService,
    private timeOffService: TimeOffService,
    private reportService: CoachingReportService,
    private schedulerService: SchedulerService,
    private consentService: ConsentService,
    private caseManagementService: CaseManagementService,
    private customerEventsService: CustomerEventsService,
    private presentationService: PresentationService,
    private navService: NavigationService,
    private marketScanService: MarketScanService,
    private utilityService: GlobalUtilityService,
    private events: EventsService,
    public caseService: CaseManagementService,
    private sampleService: SampleService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
    private injector: Injector,
    private allocFeatureService: AllocationFeatureService,
    private fieldMaterialManagementService: FieldMaterialManagementService,
    private footerButtons: FooterButtons,
    private followupDataService: FollowUpActivityDataService,
    private customerSurveyService: CustomerSurveyService,
    private faService: FeatureActionsService,
  ) {

    this.initActionButtons();

    this.initOpentokButtons();

    this.translate = this.injector.get(TranslateService);

    if (!this._deviceWidthSubscription) {
      this._deviceWidthSubscription = this.deviceService.screenWidth.pipe(debounceTime(400)).subscribe((width: number) => {
        this._deviceWidth = window.innerWidth;
      });
    }

    this.events.observe("sync:completed").subscribe(() => {
      // On sync completed, re-initialising the action buttons as to get global customer text.
      this.initActionButtons();

      if (this.footerForView) {
        // have to reload the action buttons.
        this.footerSelectedView = this.footerForView;
        this.initButtons(this.footerSelectedView);
        //
        // signature button is enabled post sync even if values are not selected.
        if (this.footerSelectedView === FooterViews.SampleDropDetails) {
          this.events.publish('updateRequestFromFooterService');
        }
      }

    });

    // if (!this._globalCustomerTxtUpdatedSub) {
    //   this._globalCustomerTxtUpdatedSub = this.authService.globalCustomerTxtUpdatedObservable.subscribe(updated => {
    //     if (updated) this.initActionButtons();
    //   });
    // }
  }

  private lastFABInitTime: Date = null;
  private allowedToCallFooterMethod(): boolean {
    if (this.lastFABInitTime) {
      const millisecondBetweenTwoDate = Math.abs((new Date().getTime() - this.lastFABInitTime.getTime()));
      if (millisecondBetweenTwoDate > 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private shoudInitFooter(fabType: string): boolean {
    if (fabType === FooterViews.Activities) {
      if (this.allowedToCallFooterMethod()) {
        this.lastFABInitTime = new Date();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private initTextForCustomer() {
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        this.golbalContactName = this.translate.instant('STAKEHOLDER');
        break;
      case 'Contact':
        this.golbalContactName = this.translate.instant('CONTACT');
        break;
      case 'Customer':
        this.golbalContactName = this.translate.instant('CUSTOMER');
        break;
      default:
        this.golbalContactName = this.utilityService.globalCustomerText;
        break;
    }
  }

  private initFooterViewsPHONE_CALL() {
    const phonecallBackgroundUploadInProgress = this.deviceService.isBackgroundUploadInProgress && this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID);
    let phoneCallBtns = ['contactsphonecall', 'markcompletephonecall', 'scrapphonecall'];
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_REOPEN)) phoneCallBtns.push("phoneCallReopen");
    this.updateButtons(phoneCallBtns, true, null);

    let disableList: string[] = [];
    if (phonecallBackgroundUploadInProgress || !this.isSelectedActivityReopenable(this.authService.user.pastPhoneCallReopenPeriod)) disableList.push('phoneCallReopen');
    if (this.activityService.selectedActivity["meetingOwnerId"] != this.authService.user.systemUserID || phonecallBackgroundUploadInProgress) {
      disableList = disableList.concat(['markcompletephonecall', 'scrapphonecall']);
    } else {
      if (this.activityService.selectedActivity.isCompleted) {
        disableList = disableList.concat(['markcompletephonecall', 'scrapphonecall']);
      }
    }
    if (phonecallBackgroundUploadInProgress || this.activityService.teamViewActive || (this.activityService.selectedActivityJointMeetingStatus && _.isEmpty(this.activityService.selectedActivity['contacts']))) {
      disableList.push('contactsphonecall');
    }
    this.disableButton(disableList);
  }

  private initFooterViewsActivities() {
    let moreOptionsView = (this.deviceService.isMobileDevice && window.innerWidth < 500) ? true : false;
    let isLiveMeet = false;
    const isFromContactTimeline: boolean = this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent && this.uiService.contactDetailsSegment == 'timeline';
    let hasEventRegFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION);
    const hasRemoteFeature = this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE);
    const hasCalendarInviteFeature = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CALENDAR_INVITE);
    const showMessageButton = hasRemoteFeature || (hasCalendarInviteFeature && this.activityService.isMeetingOwner);
    const hasOpportunityFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) && (this.navService.getCurrentMasterPageName()==PageName.ActivitiesPageComponent || isFromContactTimeline);
    const hasMeetingReopenFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_REOPEN);
    const hasMeetingAssetsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.TAG_ASSETS_TO_MEETINGS);
    const hasAssignOwnerEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_MEETING);
    let enableList: string[] = ['contacts'];
    if (hasRemoteFeature || this.authService.hasFeatureAction(FeatureActionsMap.FACE_TO_FACE)) enableList.push('preview');
    isLiveMeet = this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.isLiveMeet;
    const teamsEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING);
    const isAccountVisitRecord = !!((this.activityService.selectedActivity as AppointmentActivity)?.indskr_isparentcall);
    const isAccountVisitNestedMeeting = !!((this.activityService.selectedActivity as AppointmentActivity)?.indskr_parentcallid);
    const isInStoreChildMeeting = isAccountVisitNestedMeeting && this.activityService.selectedActivity.activityTypeName === this.translate.instant("INSTORE");
    const disableGPSCheckInFlowForHCPMeeting = !(isAccountVisitRecord || isAccountVisitNestedMeeting) && this.authService.hasFeatureAction(FeatureActionsMap.DISABLE_GPS_CHECKIN_FEATURE_IN_HCP_MEETINGS);
    const isCancelEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelmeetingwithreason'];
    
    if (!isLiveMeet) {
      if (isCancelEnabled) {
        enableList.push('cancelorscrapmeeting')
      } else {
        enableList.push('cancelmeeting')
      }
      enableList.push('markcomplete');
      if (showMessageButton) enableList.push('message');
      if (hasEventRegFAEnabled) enableList.push('meetingEvent');
      if (hasOpportunityFAEnabled) enableList.push('meetingOpportunity');
      if (hasMeetingReopenFAEnabled) enableList.push('meetingReopen');
      if (hasAssignOwnerEnabled) enableList.push('meetingassignOwner');
      if (hasMeetingAssetsEnabled) enableList.push('meetingAssets');
      if (teamsEnabled) enableList.push('teams_meeting');
      if (
        !isAccountVisitNestedMeeting
        && this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)
        && this.activityService.selectedActivity
      ) {
        const meeting = (this.activityService.selectedActivity) as AppointmentActivity;
        if (meeting.state !== 1) {
          if (!meeting.gpsCheckInDetails || !meeting.gpsCheckInDetails.indskr_checkinstatus && !disableGPSCheckInFlowForHCPMeeting) {
            enableList.push('checkin')
          } else if (!meeting.gpsCheckInDetails.indskr_checkoutdatetime && !disableGPSCheckInFlowForHCPMeeting) {
            enableList.push('checkout');
          }
        }
      }

      

      if (moreOptionsView && hasEventRegFAEnabled) {
        enableList.push('presentationsleftalligned');
        // enableList.push('openmoreoptions');
      }
      else {
        enableList.push('presentationsrightalligned');
      }
    }

    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === 'Sample') {
      this.updateButtons([], true);
    } else {
      // this.updateButtons(enableList, true);
      // setTimeout(() => {
      //   this.displayFullScreenButtons();
      // }, 10);
    }

    // We have to have visible buttons to get the correct value for getNumOfMoreButtonsOnly()
    if (this.getNumOfMoreButtonsOnly() > 0) {
      enableList.push('openmoreoptions');
    }
    //

    if ( this.activityService.selectedActivity &&
      this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)
      && this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment && !!(this.activityService.selectedActivity as AppointmentActivity).indskr_isparentcall
    ) {
      enableList = enableList.filter(listItem => !(['preview','message', 'meetingAssets','meetingOpportunity'].includes(listItem)));
    }

    this.updateButtons(enableList, true);

    // setTimeout(() => {
    //   this.displayFullScreenButtons();
    // }, 10);

    if (this.activityService.selectedActivity) {
      const backgroundUploadInProgress = this.deviceService.isBackgroundUploadInProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID);
      const isOfflineActivity = this.activityService.selectedActivity.ID.includes('offline') || this.deviceService.isOffline || backgroundUploadInProgress;
      const enableEventRegistration = this.enableCustomerEventsInMeeting(this.activityService.selectedActivity['contacts']);

      
      if (this.activityService.selectedActivity["isJointmeeting"]) {
        let disableList: string[] = [];
        let isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false;
        let coVisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonmeeting'];

        if ((!this.activityService.isMeetingOwner && (isCovisitor && coVisitorAccess !== CovisitorAccess.FULL_ACCESS)) || backgroundUploadInProgress || this.activityService.selectedActivity.status === 4) {
          if (isCancelEnabled) {
            disableList.concat('cancelorscrapmeeting')
          } else {
            disableList.concat('cancelmeeting')
          }
          disableList = disableList.concat(['markcomplete', 'teams_meeting', 'preview', 'presentations', 'presentationsrightalligned', 'presentationsleftalligned', 'resources', 'video', 'meetingEvent', 'meetingOpportunity', 'meetingAssets', 'audioactive', 'message', 'checkin', 'checkout']);
          if (_.isEmpty(this.activityService.selectedActivity['contacts'])) {
            disableList.push('contacts');
          }
        }
        else {
          if (this.activityService.selectedActivity.isCompleted ||this.activityService.selectedActivity.status === 4 ) {
            disableList = disableList.concat(['markcomplete', , 'teams_meeting', 'preview', 'presentations', 'presentationsrightalligned', 'presentationsleftalligned', 'resources', 'video', 'meetingEvent','meetingOpportunity', 'audioactive', 'message'])
            if (isCancelEnabled) {
              disableList.concat('cancelorscrapmeeting')
            } else {
              disableList.concat('cancelmeeting')
            }
          }
          if (this.activityService.selectedActivity.isReopened) {
            disableList = disableList.concat(['teams_meeting', 'preview', 'presentations', 'presentationsrightalligned', 'presentationsleftalligned', 'resources', 'video', 'meetingEvent','meetingOpportunity', 'audioactive', 'meetingReopen', 'message'])
          }
        }
        if (!enableEventRegistration) {
          disableList.push('meetingEvent');
        }
        if(isOfflineActivity || this.activityService.selectedActivity.isCompleted){
          disableList.push('meetingOpportunity');
          disableList.push('meetingAssets');
        }
        if (!this.isSelectedActivityReopenable(this.authService.user.pastMeetingReopenPeriod)) disableList.push('meetingReopen');


        if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)) {
          if ((this.activityService.selectedActivity) instanceof AppointmentActivity) {
            const meeting = (this.activityService.selectedActivity) as AppointmentActivity;
            if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN)) {
              if ((!meeting.location || meeting.location === 'No Location') && enableList.includes('checkin') && _.isEmpty(meeting.accounts)) {
                disableList.push('checkin');
              }
            }
            else {
              if ((!meeting.location || meeting.location === 'No Location') && enableList.includes('checkin')) {
                disableList.push('checkin');
              }
            }//end of if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN))

            const meetingActivityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING).find((types) => types['indskr_activitytypeid'] == meeting['indskr_activitytype']);
            if (!meetingActivityTypes || meetingActivityTypes.indskr_remotechannel ) {
              disableList.push('checkin');
              disableList.push('checkout');
            }
          }
        }
        
        if (((this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.state !== 0) ||
          this.deviceService.isOffline || this.activityService.selectedActivity.ownerId !== this.authService.user.xSystemUserID) &&
          (this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_MEETING))) {
          disableList.push('meetingassignOwner');
        }
      
        this.disableButton(disableList);
      }
      else {
        let disableList: string[] = [];
        if ((!this.activityService.isMeetingOwner || this.activityService.selectedActivity.isCompleted || this.activityService.teamViewActive || backgroundUploadInProgress || this.activityService.selectedActivity.status === 4)) {
          disableList = ['markcomplete', 'message', 'teams_meeting', 'preview', 'presentations', 'presentationsrightalligned', 'presentationsleftalligned', 'resources', 'video', 'meetingEvent', 'audioactive', 'checkin', 'checkout'];
          if (isCancelEnabled) {
            disableList.push('cancelorscrapmeeting')
          } else {
            disableList.push('cancelmeeting')
          }
        }
        
        if (this.activityService.selectedActivity.isReopened) {
          disableList = ['message', 'teams_meeting', 'preview', 'presentations', 'presentationsrightalligned', 'presentationsleftalligned', 'resources', 'video', 'meetingEvent','meetingOpportunity', 'audioactive', 'meetingReopen'];
        }
        if ((this.activityService.teamViewActive || backgroundUploadInProgress || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.status === 4 ) && _.isEmpty(this.activityService.selectedActivity['contacts'])) {
          disableList.push('contacts');
        }
        if (!enableEventRegistration) {
          disableList.push('meetingEvent');
        }
        if (isOfflineActivity || this.activityService.selectedActivity.isCompleted || this.activityService.teamViewActive || this.activityService.selectedActivity.status === 4 ){
          disableList.push('meetingOpportunity');
          disableList.push('meetingAssets');
        }
        if (this.deviceService.isOffline) disableList.push('teams_meeting');
        if (!this.isSelectedActivityReopenable(this.authService.user.pastMeetingReopenPeriod)) disableList.push('meetingReopen');

        if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)) {
          if ((this.activityService.selectedActivity) instanceof AppointmentActivity) {
            const meeting = (this.activityService.selectedActivity) as AppointmentActivity;
            if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN)) {
              if ((!meeting.location || meeting.location === 'No Location') && enableList.includes('checkin') && _.isEmpty(meeting.accounts)) {
                disableList.push('checkin');
              }
            }
            else {
              if ((!meeting.location || meeting.location === 'No Location') && enableList.includes('checkin')) {
                disableList.push('checkin');
              }
            }//end of if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN))


            let meetingActivityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING).find((types) => types['indskr_activitytypeid'] == meeting['indskr_activitytype']);
            if (!meetingActivityTypes || meetingActivityTypes.indskr_remotechannel) {
              disableList.push('checkin');
              disableList.push('checkout');
            }
          }
        }

        if (((this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.state !== 0) ||
        this.deviceService.isOffline || this.activityService.selectedActivity.ownerId !== this.authService.user.xSystemUserID) &&
        (this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_MEETING))) {
        disableList.push('meetingassignOwner');
      }

        this.disableButton(disableList);
      }


    }
  }

  private initFooterViewsCUSTOMER_INQUIRY() {
    let selection: CaseActivity = this.caseManagementService.currentCase;
    const backgroundUploadInProgress = this.deviceService.isBackgroundUploadInProgress && String(selection._case_status_value).toLowerCase().includes('pending sync');

    if (this.authService.hasFeatureAction(FeatureActionsMap.CASE_SIGNATURE)) {
      this.updateButtons(['flipToInquiryAcknowledgement', 'scrapcase'], true);
    } else {
      this.updateButtons(['submitcase', 'scrapcase'], true);
    }

    this.disableButton(['scrapcase', 'submitcase', 'flipToInquiryAcknowledgement']);
    if (selection) {
      const btnToEnable = [];
      if ((String(selection._case_status_value).toLowerCase().includes('open') || String(selection._case_status_value).toLowerCase().includes('pending sync')) && !backgroundUploadInProgress) {
        btnToEnable.push('scrapcase');
      }

      if (String(selection._case_status_value).toLowerCase().includes('open') && this.caseManagementService.validateCaseForReview) {
        btnToEnable.push(...['submitcase', 'flipToInquiryAcknowledgement']);
      }

      if (selection) {
        if(selection.signature || (selection["raw"] && (selection.raw["indskr_signature"] || selection.raw["signature"]))) {
          btnToEnable.push('flipToInquiryAcknowledgement');
        }
      }

      if (!_.isEmpty(btnToEnable)) {
        this.enableButtons(btnToEnable);
      }
    }
  }

  private initFooterViewsCalendarInvite(options: any = null) {
    if (options) {
      // Init
      // Show buttons
      const buttonsToDisplay = ['generalTemplate'];
      if (options.saveButton && options.saveButton.buttonId) {
        buttonsToDisplay.push(options.saveButton.buttonId);
      }
      if (options.sendButton) {
        if (options.sendButton.show) {
          buttonsToDisplay.push('generalSend');
        }
      }
      this.updateButtons(buttonsToDisplay, true);
      this.enableButtons(buttonsToDisplay);

      // Enable / disable buttons
      const buttonsToDisable = [];
      if (!options.saveButton?.isActive) {
        buttonsToDisable.push(options.saveButton.buttonId);
      }
      if (!options.sendButton?.isActive) {
        buttonsToDisable.push('generalSend');
      }
      if (buttonsToDisable.length > 0) {
        this.disableButton(buttonsToDisable);
      }
    }
  }

  private initFooterViewsEmailDetails(options?: any) {
    const email: EmailActivity = this.emailService.selectedActivity;
    // check exteranl/preview channel
    if (email && email.indskr_channelid) {
      let selectedChannelId = email.indskr_channelid;
      this.emailService.getChannelInfo(selectedChannelId);
    }
    if (email && email.isMessageExternal) {
      this.initButtons(FooterViews.MESSAGE_NON_NATIVE);
      return;
    }
    if(email && email.channelType === ChannelType.WECOM) {
      this.initButtons(FooterViews.WECOM);
      return;
    }
    // fix for moving the icon right side for messages other than email to fill the right side of the footer completely
    if (this.emailService.selectedActivity && this.emailService.selectedActivity.channelType !== ChannelType.EMAIL) {
      this.actionButtons.find((button) => button.id === 'personalize_template').align = 'right';

    } else {
      this.actionButtons.find((button) => button.id === 'personalize_template').align = 'left';
    }

    let isNotEmailActivity: boolean = (this.emailService.selectedActivity && this.emailService.selectedActivity.channelType !== ChannelType.EMAIL);

    let messageOptions = [
      'sendEmail',
      'contactsMessage',
      ...isNotEmailActivity ? [] : ['attachment'],
      'personalize_template',
      'template',
      'scrapemail'];

    if (this.getNumOfMoreButtonsOnly() > 0) {
      messageOptions.push('openmoreoptions');
    }

    this.updateButtons(messageOptions, true);
    setTimeout(() => {
      this.displayFullScreenButtons();
    }, 10);
    this.enableButtons(['scrapemail', 'template', 'personalize_template', 'attachment', 'contactsMessage', 'sendEmail']);
    if (this.emailService.selectedActivity) {
      const email: EmailActivity = this.emailService.selectedActivity;
      const backgroundUploadInProgress = this.deviceService.isBackgroundUploadInProgress && this.activityService.hasOfflineEmailData(email.offlineActivityId);
      const isSkipConsentForFirstTimeEmail: boolean = email?.emailType === EmailTemplateType.FirstTimeEmail || false;

      if (this.authService.user.systemUserID != email.ownerId || email.status != 1 || this.activityService.teamViewActive || backgroundUploadInProgress) {
        const disable = [
          'scrapemail',
          'template',
          'personalize_template',
          'sendEmail',
          'attachment'
        ];
        if (_.isEmpty(email.emailActivityParties) || isSkipConsentForFirstTimeEmail) disable.push('contactsMessage');
        this.disableButton(disable);
      } else {
        const contactsAdedd: boolean = email.emailActivityParties && email.emailActivityParties.length != 0;
        let buttons: string[] = []
        if (contactsAdedd && email.template_id) buttons.push('sendEmail');
        if (contactsAdedd) buttons.push('template');
        //f75445e
        if (this.authService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL) && (email.status == 1 || email.status == 9) && (email.template_id && email.description && email.description.includes('%content.url%'))) {
          if (!this.deviceService.isNativeApp && this.deviceService.isOffline) {
            //Desktop app and device is offline
            let i = buttons.findIndex(b => { return b === 'attachment' });
            if (i > -1) {
              buttons.splice(i, 1);
            }
          }
          else {
            buttons.push('attachment');
          }
        };
        if ((email.status == 1 || email.status == 9) && (email.template_id && email.description && email.description.includes('%custom.') && !this.emailService.isHideContentTokenOnly(email.description))) {
          buttons.push('personalize_template');
        }
        //if (!this.emailService.disableSendButton) buttons.push('sendEmail');

        if (buttons.length == 0) this.disableButton(['sendEmail', 'attachment', 'personalize_template', 'template']);
        else {
          this.enableButtons(buttons);
          let buttonsToDisable: string[] = []
          if (buttons.indexOf('attachment') == -1) buttonsToDisable.push('attachment');
          if (buttons.indexOf('sendEmail') == -1) buttonsToDisable.push('sendEmail');
          if (buttons.indexOf('personalize_template') == -1) buttonsToDisable.push('personalize_template');
          if (this.emailService.disableSendButton ||(!_.isEmpty(options) && options['sendEmail'] && !options['sendEmail'].isActive)) buttonsToDisable.push('sendEmail');
          if (buttons.indexOf('template') == -1) buttonsToDisable.push('template');
          if (isSkipConsentForFirstTimeEmail) buttonsToDisable.push('contactsMessage');
          if (buttonsToDisable.length > 0) {
            this.disableButton(buttonsToDisable);
          }
        }
      }
    }
  }

  private initFooterViewsMARKET_SCAN_DETAILS() {
    let buttonsToDisplay = []
    const selectedMarketScan = this.marketScanService.getSelectedMarketScan();
    if (selectedMarketScan.statuscode === MarketScanStatusCode.Active) {
      if ((selectedMarketScan.indskr_marketscanid || selectedMarketScan.pendingPushToDynamics)) {
        buttonsToDisplay.push('updateMarketScan');
      } else {
        buttonsToDisplay.push('saveMarketScan');
      }
    } else {
      buttonsToDisplay.push('editMarketScan');
    }
    buttonsToDisplay.push('scrapMarketScan');

    this.updateButtons(buttonsToDisplay, true);
  }

  private initFooterViewsPresentations() {
    const pBtns: string[] = ['presentaion_detail','fullscreen', 'dummy'];
    if (this.presentationService.viewMode === PresentationView.MENU) {
      pBtns.push('play');
      //pBtns.splice(0, 0, 'presentaion_detail');
    } else {
      if (this.presentationService.contentMode !== ContentMode.RESOURCE) {
        const swipeStatus: SwipeStatus = this.presentationService.swipeStatus;
        pBtns.push((swipeStatus !== SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION && (swipeStatus === SwipeStatus.ENABLED_BY_USER || swipeStatus === SwipeStatus.ENABLED_DEFAULT)) ? 'presentationswipeenabled' : 'presentationswipedisabled');
      }
    }
    if(this.presentationService?.contentMode !== ContentMode.PRESENTATION){
      pBtns.shift();
    }
    this.updateButtons(pBtns, true);
  }

  private initFooterViewsPreviewMeeting() {
    let moreOptionsView = (this.deviceService.isMobileDevice && window.innerWidth < 500) ? true : false;
    let isLiveMeet = false;
    let hasEventRegFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION);
    const activityContacts = this.activityService.selectedActivity && this.activityService.selectedActivity['contacts'];
    const enableEventRegistration = this.enableCustomerEventsInMeeting(activityContacts);
    isLiveMeet = this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.isLiveMeet;
    const swipeStatus: SwipeStatus = this.presentationService.swipeStatus
    this.updateButtons([
      this.wsService.meetingInProgress ? 'homepage_disabled' : 'homepage', 'resources', 'presentations',
      !this.opentok.videoActive ? 'video' : 'stop_video_active', 'contactspresentationpreview',
      this.opentok.audioActive ? 'audioactive' : 'stop_audio',
      this.wsService.meetingInProgress ? 'pause' : 'play', this.presentationService.hideCarousel ? 'carousel' : 'carouselactive',
      (swipeStatus !== SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION && (swipeStatus === SwipeStatus.ENABLED_DEFAULT || swipeStatus === SwipeStatus.ENABLED_BY_USER)) ? 'meetingswipeenabledactive' : 'meetingswipedisabled',
      !isLiveMeet && hasEventRegFAEnabled ? 'meetingEvent' : '',
      moreOptionsView || this.getNumOfMoreButtonsOnly() > 0 ? 'openmoreoptions' : '',
      this.presentationService.isReferenceVisible ? 'referencesactive' : 'references',
    ], true);
    if (enableEventRegistration) {
      this.enableButtons(['meetingEvent']);
    } else {
      this.disableButton(['meetingEvent']);
    }
    if (this.deviceService.isOffline) {
      this.enableButtons(['resources', 'video', 'stop_video_active', 'audioactive', 'stop_audio', 'references'], false);
    } else if (this.wsService.meetingInProgress) {
      this.enableButtons(['homepage'], false);
    }
    if (this.deviceService.deviceFlags.desktop && this.deviceService.deviceFlags.web
      && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_SCREENSHARE))
      this.enableButtons(['play'], this.activityService.isThereConnectedContactsForCurrentMeeting(<AppointmentActivity>this.activityService.selectedActivity, true));
    setTimeout(() => {
      this.displayFullScreenButtons();
    }, 10);
  }

  private initFooterViewsPresentationFullscreen() {
    const fBtns: string[] = ['exitfullscreen', this.presentationService.hideCarousel ? 'presentationcarousel' : 'presentationcarouselactive'];
    const swipe: SwipeStatus = this.presentationService.swipeStatus;
    if (this.presentationService.contentMode !== ContentMode.RESOURCE) {
      fBtns.push((swipe !== SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION && (swipe === SwipeStatus.ENABLED_DEFAULT || swipe === SwipeStatus.ENABLED_BY_USER)) ? 'presentationswipeenabled' : 'presentationswipedisabled');
    }
    this.updateButtons(fBtns, true);
    setTimeout(() => {
      this.displayFullScreenButtons();
    }, 10);
  }

  private initFooterViewsCustomerCallPlan() {
    if (this.callPlanOfflineService.callPlanMode === 'mine') {
      this.updateButtons(['clear', 'send'], true);
      if (this.callPlanOfflineService.selectedRepCallPlan && this.callPlanOfflineService.selectedRepCallPlan.statuscode == 1) {
        let activeButtons = ['clear'];
        if (this.callPlanOfflineService.isSendDisabled) this.disableButton(['send']);
        else activeButtons.push('send');
        this.enableButtons(activeButtons);
      } else {
        this.disableButton(['clear', 'send']);
      }
    } else if (this.callPlanOfflineService.callPlanMode === 'team') {
      this.updateButtons(['reject', 'approve'], true);
      if (this.callPlanOfflineService.selectedRepCallPlan && this.callPlanOfflineService.selectedRepCallPlan.statuscode == 100000003) {
        this.enableButtons(['reject', 'approve']);

      } else {
        this.disableButton(['reject', 'approve']);
      }
    }
  }

  private initFooterViewsPREVIEW_MEETING_ACTIVITY() {
    this.initButtons(FooterViews.PreviewMeeting);
    const buttons = ["contactspresentationpreview", "meetingswipeenabledactive", "meetingswipedisabled", "play", "presentations"];
    this.disableButton(buttons);
  }

  private initFooterViewsCoachingDetails() {
    let shareFA: boolean = this.authService.hasFeatureAction(FeatureActionsMap.COACHING_SHARE);
    const report = this.reportService.selectedReport.getValue();
    const statusCode = report?.statuscode;
    const backgroundUpload = !report ? false : this.deviceService.isBackgroundUploadInProgress && this.reportService.isOffline(report);
    if (this.reportService.activeTab === 'myCoaching') {
      this.hideAllButtons();
      if (shareFA && (statusCode === 548910002
        || statusCode === 548910003
        || statusCode === 3)) {
        this.updateButtons(['acceptcoaching'], true);
        // if (!shareFA) {
        //   this.hideButton('acceptcoaching');
        // }
        if (backgroundUpload || statusCode === 548910003
          || statusCode === 3) {
          this.disableButton(['acceptcoaching']);
        }
      }
      if (statusCode === 548910001
        || statusCode === 548910000
        || statusCode === 5) {
        this.updateButtons(['ackcoaching'], true);
        const periodEnd = moment(new Date(report.indskr_periodenddate), "YYYY-MM-DD", true);
        const present = moment(Date.now()).format("YYYY-MM-DD");
        if (backgroundUpload || statusCode === 548910000 || statusCode === 5 || periodEnd.startOf('day').isAfter(present)) {
          this.disableButton(['ackcoaching']);
        }
      }
    } else {
      this.hideAllButtons();
      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN)) {
        if (statusCode === 1 || statusCode === 2) {
          this.updateButtons(['deletecoaching', 'recallcoaching', 'closecoaching'], true);
          this.disableButton(['recallcoaching']);
          if (!report.indskr_coachingfor_value || !report.indskr_coachingtemplateid || (!report.meetings.length && !report.procedureLogs.length) || !report.indskr_overallrating || !report.indskr_coachingplan) {
            this.disableButton(['recallcoaching', 'closecoaching']);
          }
        }
        if (backgroundUpload || statusCode === 548910004 || statusCode === 548910005
          || statusCode === 2) {
          this.disableButton(['deletecoaching', 'closecoaching']);
        }
        if (statusCode === 548910004) {
          this.enableButtons(['recallcoaching']);
        }
        if (statusCode === 548910005) {
          this.disableButton(['recallcoaching']);
        }
      } else {
        if (statusCode === 1
          || statusCode === 548910002
          || statusCode === 2) {
          this.updateButtons(['deletecoaching', 'sharecoaching', 'sendcoaching'], true);
          if (!shareFA) {
            this.hideButton('sharecoaching');
            this.showButton('sendcoaching');
          } else {
            this.showButton('sharecoaching');
            this.hideButton('sendcoaching');
          }
          if(!report.indskr_coachingfor_value || !report.indskr_coachingtemplateid) {
            if (shareFA) this.disableButton(['sharecoaching']);
            else this.disableButton(['sendcoaching']);
          }
          if (backgroundUpload || statusCode === 548910002
            || statusCode === 2) {
            this.disableButton(['deletecoaching', 'sharecoaching']);
          }
          if (backgroundUpload) {
            this.disableButton(['deletecoaching', 'sendcoaching']);
          }
        }
        else if (shareFA && (statusCode === 548910003
          || statusCode === 548910001
          || statusCode === 4)) {
          this.updateButtons(['sendcoaching'], true);
          if (backgroundUpload || statusCode === 548910001
            || statusCode === 4) {
            this.disableButton(['sendcoaching']);
          }
        }
        else if (!shareFA && (statusCode === 548910001
        || statusCode === 4)) {
          this.updateButtons(['deletecoaching', 'sendcoaching', 'recallcoaching'], true);
          if (backgroundUpload || statusCode === 548910001
          || statusCode === 4) {
            this.disableButton(['deletecoaching', 'sendcoaching']);
          }
        }
        else if (!shareFA && statusCode === 548910000) {
          this.updateButtons(['deletecoaching', 'sendcoaching'], true);
          if (backgroundUpload || statusCode === 548910000) {
            this.disableButton(['deletecoaching', 'sendcoaching']);
          }
        }
        else if (shareFA && statusCode === 548910000) {
          this.updateButtons(['sendcoaching'], true);
          if (backgroundUpload || statusCode === 548910000) {
            this.disableButton(['sendcoaching']);
          }
        }
      }
    }
  }

  private initFooterViewsTimeOffDetails() {
    let hasTimeOffAutoApp = this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTO_APPROVAL);
    let timeoff: TimeOffActivity;
    this.timeOffService.selectedTot.subscribe((res: TimeOffActivity) => { timeoff = res });
    let isReasonAutoApproved:boolean = false;
    let reasonsConfig =  this.authService.user.ioConfigurations?.find(o=>o.configName=="Timeoff.Reasons.Configuration")
    if(reasonsConfig && timeoff.reason){
      let selectedReasonConfig = JSON.parse(reasonsConfig.configValue).find(o=> o["Reason"]== timeoff.reason);
      if(selectedReasonConfig && selectedReasonConfig["AutoApproved"] == "Yes") isReasonAutoApproved = true
    }
    let saveOrSendTimeOff = hasTimeOffAutoApp || isReasonAutoApproved ? 'savetimeoff' : 'sendtimeoff';
    if (timeoff != undefined && timeoff != null) {
      if (timeoff.type === ActivityType.TimeOff) {
        this.updateButtons([saveOrSendTimeOff, 'deletetimeoff'], true);
        this.disableButton([saveOrSendTimeOff, 'deletetimeoff']);
      }

      if (this.timeOffService.totMode === 'myRequests') {
        this.updateButtons([saveOrSendTimeOff, 'deletetimeoff'], true);
        if (timeoff.statusValue === 'Open' && this.authService.user.systemUserID === timeoff.totOwnerId && !(timeoff.pendingPushToDynamics && this.deviceService.isBackgroundUploadInProgress)) {
          this.enableButtons([saveOrSendTimeOff, 'deletetimeoff']);
          if (this.deviceService.isOffline || !timeoff.reason || (this.authService.hasFeatureAction(FeatureActionsMap.TIMEOFF_ADMIN_COMMENTS) && timeoff.timeOffReason==548910003 && !timeoff.comments)) {
            this.disableButton([saveOrSendTimeOff]);
          }
        } else {
          this.disableButton([saveOrSendTimeOff, 'deletetimeoff']);
        }
      } else {
        this.updateButtons(['notapprovedtimeoff', 'approvetimeoff'], true);
        if (timeoff.statusValue === 'For Review' && (!this.deviceService.isNativeApp || !this.deviceService.isOffline) && !(timeoff.pendingPushToDynamics && this.deviceService.isBackgroundUploadInProgress)) {
          this.enableButtons(['notapprovedtimeoff', 'approvetimeoff']);
        } else if (timeoff.statusValue === 'Approved') {
          this.disableButton(['notapprovedtimeoff', 'approvetimeoff']);
        } else {
          this.disableButton(['notapprovedtimeoff', 'approvetimeoff']);
        }
      }
    }
    else {
      this.hideAllButtons();
    }
  }

  private initFooterViewsShipmentDetails() {
    if ((this.allocFeatureService.selectedShipment as IAllocationAdjustment)?.indskr_transfertype === TransferType.AllocationAdjustment) {
      if (this.allocFeatureService.selectedShipment.indskr_adjustedstatus === AdjustmentStatus.InReview) {
        if (this.allocFeatureService.selectedShipment.ownerId !== this.authService.user.systemUserID) {
          if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST)) {
            this.updateButtons(['notApproveAdjustment', 'approveAdjustment'], true);
          }
        } else {
          this.updateButtons(['scrapAdjustment', 'adjustShipment'], true);
          this.disableButton(['scrapAdjustment', 'adjustShipment']);
        }
      } else if (this.allocFeatureService.selectedShipment?.indskr_adjustedstatus === AdjustmentStatus.Approved) {
        if (this.allocFeatureService.selectedShipment.ownerId === this.authService.user.systemUserID) {
          if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
            this.updateButtons(['scrapAdjustment', 'saveAllocationAdjustment'], true);
            // this.disableButton(['scrapAdjustment', 'saveAllocationAdjustment']);
          } else {
            this.updateButtons(['scrapAdjustment', 'adjustShipment'], true);
            // this.disableButton(['scrapAdjustment', 'adjustShipment']);
          }
        } else {
          this.updateButtons(['notApproveAdjustment', 'approveAdjustment'], true);
          this.disableButton(['notApproveAdjustment', 'approveAdjustment']);
        }
      }
    } else {
      this.updateButtons(['acknowledgeShipment'], true);
    }
  }

  private initFooterViewsAdjustDtails() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
      this.updateButtons(['scrapAdjustment', 'saveAllocationAdjustment'], true);
    } else {
      this.updateButtons(['scrapAdjustment', 'adjustShipment'], true);
    }
  }

  private initFooterViewsSchedulingDetails() {
    const selectedScheduler = this.schedulerService.schedulerDataSource.getValue();
    let footerIcons = ['suggestscheduler', 'scrapScheduler', 'savescheduler']
    if (!selectedScheduler.isNewScheduler) {
      footerIcons.splice(1, 0, 'copyscheduler');
    }
    this.updateButtons(footerIcons, true);
    let buttonsToDisable = [];
    if (this.deviceService.isOffline) {
      buttonsToDisable = buttonsToDisable.concat(['savescheduler', 'scrapScheduler', 'suggestscheduler']);
    } else if (this.schedulerService.isNewPattern) {
      buttonsToDisable = buttonsToDisable.concat(['scrapScheduler', 'suggestscheduler'])
    } else if (!this.schedulerService.isNewPattern && this.schedulerService.areChangeSaved) {
      buttonsToDisable = buttonsToDisable.concat(['savescheduler'])
    } else if (this.schedulerService.isProcessing || !this.schedulerService.areChangeSaved) {
      buttonsToDisable = buttonsToDisable.concat(['suggestscheduler']);
    }
    if (buttonsToDisable.length > 0)
      this.disableButton(buttonsToDisable);
  }

  private initFooterViewsMESSAGE_NON_NATIVE() {
    let moreOptionsView = (this.deviceService.isMobileDevice && window.innerWidth < 500) ? true : false;
    const email: EmailActivity = this.emailService.selectedActivity;
    // check exteranl/preview channel
    if (email && email.indskr_channelid) {
      let selectedChannelId = email.indskr_channelid;
      this.emailService.getChannelInfo(selectedChannelId);
    }
    if(email && email.channelType === ChannelType.WECOM) {
      this.initButtons(FooterViews.WECOM);
      return;
    }

    if (email.isPreviewEnabled) {
      this.updateButtons(['scrapemail', 'contactsMessage', 'shareMessage', 'personalize_template', 'template',
        moreOptionsView || this.getNumOfMoreButtonsOnly() > 0 ? 'openmoreoptions' : ''], true);
    } else {
      this.updateButtons(['scrapemail', 'contactsMessage', 'markcomplete', 'personalize_template', 'template',
        moreOptionsView || this.getNumOfMoreButtonsOnly() > 0 ? 'openmoreoptions' : ''], true);
    }
    setTimeout(() => {
      this.displayFullScreenButtons();
    }, 10);

    if (email) {
      if (email.status === 3 && !email?.isPreviewEnabled) {
        this.disableButton(['scrapemail', 'markcomplete', 'personalize_template', 'template']);
      }
      if (email.status === 548910000 && email?.isPreviewEnabled) {
        this.disableButton(['scrapemail', 'shareMessage', 'personalize_template', 'template']);
      }
      if (this.authService.user.systemUserID != email.ownerId || email.status != 1 || this.activityService.teamViewActive) {
        if (email?.isPreviewEnabled) {
          this.disableButton(['scrapemail', 'shareMessage', 'personalize_template', 'template']);
        } else {
          this.disableButton(['scrapemail', 'markcomplete', 'personalize_template', 'template']);
        }
      } else {
        const contactsAdded: boolean = email.emailActivityParties && email.emailActivityParties.length != 0;
        let buttons: string[] = []
        if (email?.isPreviewEnabled) {
          if (contactsAdded) {
            buttons.push('template');
          }
          if (contactsAdded && email.template_id) {
            buttons.push('shareMessage');
          }
        } else {
          if (contactsAdded) {
            buttons.push('markcomplete');
          }
        }
        if (buttons.length == 0) {
          if (email?.isPreviewEnabled) {
            this.disableButton(['shareMessage', 'personalize_template', 'template']);
          } else {
            this.disableButton(['markcomplete', 'personalize_template', 'template']);
          }
        } else {
          this.enableButtons(buttons);
          let buttonsToDisable: string[] = []
          if (!email?.isPreviewEnabled) buttonsToDisable.push('template');
          if (buttons.indexOf('shareMessage') == -1) buttonsToDisable.push('shareMessage');
          if (buttons.indexOf('personalize_template') == -1) buttonsToDisable.push('personalize_template');
          if (buttons.indexOf('template') == -1) buttonsToDisable.push('template')
          if (buttonsToDisable.length > 0) {
            this.disableButton(buttonsToDisable);
          }
        }
      }
    }
  }

  public initButtons = (footerSelectedView: string, options: any = null) => {

    // if (this.shoudInitFooter(footerSelectedView) === false) {
    //   return;
    // }

    this.initTextForCustomer();

    this.hideAllButtons();

    this.footerForView = (footerSelectedView as FooterViews) ?? FooterViews.None;

    switch (footerSelectedView) {

      case FooterViews.None:
        this.updateButtons([], true);
        break;
      case FooterViews.PHONE_CALL:
        this.initFooterViewsPHONE_CALL();
        break;
      case FooterViews.Activities:
        this.initFooterViewsActivities();
        break;
      case FooterViews.CUSTOMER_INQUIRY:
        this.initFooterViewsCUSTOMER_INQUIRY();
        break;
      case FooterViews.CalendarInvite:
        this.initFooterViewsCalendarInvite(options);
        break;
      case FooterViews.EmailDetails:
        this.initFooterViewsEmailDetails(options);
        break;
      case FooterViews.WECOM:
        const email: EmailActivity = this.emailService.selectedActivity;
        let btns = [
          'sendEmail',
          'contactsMessage',
          'scrapemail'
        ];
        if (this.getNumOfMoreButtonsOnly() > 0) {
          btns.push('openmoreoptions');
        }
        this.updateButtons(btns, true);
        setTimeout(() => {
          this.displayFullScreenButtons();
        }, 10);
        this.enableButtons(['scrapemail', 'contactsMessage', 'sendEmail']);
        if (this.authService.user.systemUserID != this.emailService.selectedActivity.ownerId || this.emailService.selectedActivity.status != 1 || this.activityService.teamViewActive || (this.deviceService.isBackgroundUploadInProgress && this.activityService.hasOfflineEmailData(this.emailService.selectedActivity.offlineActivityId))) {
          const disable = [
            'scrapemail',
            'sendEmail',
          ];
          if (_.isEmpty(email.emailActivityParties)) disable.push('contactsMessage');
          this.disableButton(disable);
        } else {
          this.disableButton(['sendEmail']);
        }
        break;
      case FooterViews.MARKET_SCAN_DETAILS:
        this.initFooterViewsMARKET_SCAN_DETAILS();
        break;
      case FooterViews.Presentations:
        this.initFooterViewsPresentations();
        break;
      case FooterViews.Resources:
        this.updateButtons([], true);
        break;
      case FooterViews.EmailTemplates:
        this.updateButtons([], true);
        break;
      case FooterViews.EmailTemplatesPreview:
        this.updateButtons(['emailTemplateModalPreview', 'emailTemplateFlipPreview'], true);
        break;
      case FooterViews.ResourcesInMeeting:
        this.updateButtons(['share-resource'], true);
        break;
      case FooterViews.ResourceParticipants:
        this.updateButtons([], true);
        break;
      case FooterViews.PreviewMeeting:
        const currentMeeting = this.activityService.selectedActivity as AppointmentActivity;
        if (currentMeeting.indskr_shortcall) {
          let btns;
          if (this.wsService.shortCallRemoteMeeting) {
            btns = ['shareContent', 'remoteMeeting', 'video', 'stop_audio', 'completeShortCall'];
          } else {
            btns = ['shareContent', 'remoteMeeting', 'completeShortCall'];
          }
          if (currentMeeting.indskr_parentcallid != null) {
            btns.splice(btns.indexOf('remoteMeeting'), 1);
          }
          this.updateButtons(btns, true);
          this.enableButtons(btns, !this.deviceService.isOffline);
          if (
            (
              this.authService.user.buSettings
              && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
            ) || this.faService.getFeatureAction(FeatureActionsMap.ALL_PRESENTATION_PLAY_DURATION)?.getIsOn()
          ) {
            // Disable complete button at the beginning
            // This prevents users from completing the meeting even without running
            // the base initialization of play time count or/and gps location mark.
            this.disableButton(['completeShortCall']);
          }
        } else {
          this.initFooterViewsPreviewMeeting();
        }
        break;
      case FooterViews.PresentationFullscreen:
        this.initFooterViewsPresentationFullscreen();
        break;
      case FooterViews.CustomerCallPlan:
        this.initFooterViewsCustomerCallPlan();
        break;
      case FooterViews.PREVIEW_MEETING_ACTIVITY:
        this.initFooterViewsPREVIEW_MEETING_ACTIVITY();
        break;
      case FooterViews.MePage:
      case FooterViews.Contacts:
        break;
      case FooterViews.Accounts:
        break;
      case FooterViews.MeetingURLEmail:
      case FooterViews.Calendar:
        break;
      case FooterViews.CallPlanDetails:
        break;
      case FooterViews.CoachingDetails:
        this.initFooterViewsCoachingDetails();
        break;
      case FooterViews.TimeOffDetails:
        this.initFooterViewsTimeOffDetails();
        break;
      case FooterViews.SampleDropDetails:
        this.updateButtons(['contactsAllocation', 'samplingeligibilities', 'signature', 'cancelmeeting'], true);
        break;
      case FooterViews.SampleDropInMeetingDetails:
        this.samplingInModalViewActive = true;
        this.updateButtons(['contactsAllocation', 'samplingeligibilities', 'signature', 'cancelSamplingInMeeting'], true);
        break;
      case FooterViews.CaptureSampleOrder:
        this.updateButtons(['flipToSampleDetails'], true);
        break;
      case FooterViews.ShipmentDetails:
        this.initFooterViewsShipmentDetails();
        break;
      case FooterViews.AdjustDtails:
        this.initFooterViewsAdjustDtails();
        break;
      case FooterViews.SchedulingDetails:
        this.initFooterViewsSchedulingDetails();
        break;
      case FooterViews.CallNotesAssistant:
        this.updateButtons(['editText', 'confirmChanges'], true);
        break;
      case FooterViews.ConsentCapture:
        this.updateButtons(['generateconsents', 'consentFlip'], true);
        if (!this.consentService.allGeneratedTerms.value.length)
          this.disableButton(['generateconsents', 'consentFlip']);
        break;
      case FooterViews.ConsentDetails:
        this.updateButtons(['consentFlipDetails'], true);
        break;
      case FooterViews.AccountPlanReport:
        this.updateButtons(['cancelprogressreport', 'sendprogressreport'], true);
        if (options) {
          this.enableButtons(['cancelprogressreport'], !options.backgroundUploadInProgress && options.status === 'Open');
          this.enableButtons(['sendprogressreport'], (options.status === 'Open' && options.isApprovalEnabled && !this.deviceService.isOffline && !options.backgroundUploadInProgress));
        }
        break;
      case FooterViews.AccountPlanReportApproval:
        this.updateButtons(['notapproveprogressreport', 'approveprogressreport'], true);
        if (options) {
          this.enableButtons(['notapproveprogressreport', 'approveprogressreport'], options.status === 'For Review' && !this.deviceService.isOffline && !options.backgroundUploadInProgress);
        }
        break;
      case FooterViews.AccountPlanReportCancelReview:
        this.updateButtons(['cancelProgressReportReview'], true);
        this.enableButtons(['cancelProgressReportReview'], !this.deviceService.isOffline);
        break;
      case FooterViews.FollowUpDetails:
        this.updateButtons(['scrapfollowup','markfollowupcancel','markfollowupcomplete'], true);
        break;
      case FooterViews.ORDER_ACTIVITY_OPEN:
        this.updateButtons(['scraporderactivity', 'sendorderactivityforreview'], true);
        break;
      case FooterViews.ORDER_ACTIVITY_AUTO_APPROVE:
        this.updateButtons(['scraporderactivity', 'autoapproveorderactivity'], true);
        break;
      case FooterViews.ORDER_ACTIVITY_FOR_REVIEW:
        this.updateButtons(['cancelorderactivityreview'], true);
        break;
      case FooterViews.ORDER_ACTIVITY_FOR_REVIEW_TEAM_ORDERS:
        this.updateButtons(['notapproveorderactivity', 'approveorderactivity'], true);
        break;
      case FooterViews.ORDER_ACTIVITY_APPROVED:
        this.updateButtons(['fulfillorderactivity', 'reopenorderactivity'], true);
        break;
      case FooterViews.APP_SETTING_UPLOAD:
        this.updateButtons(['appsettingupload'], true);
        break;
      case FooterViews.STORE_CHECK:
        this.updateButtons(['scrapStoreCheck', 'completeStoreCheck'], true);
        break;
      case FooterViews.APPEAL_DETAILS:
        this.updateButtons(['viewMeeting','reject', 'approve'], true);
        break;
      case FooterViews.APP_SETTINGS_ABOUT:
      case FooterViews.APP_SETTINGS_GEN_SETTINGS:
        this.updateButtons([], true);
        break;
      case FooterViews.OPPORTUNITY_INFO_OPEN:
        let opportunityFooterBtns = ['opportunityscrap','confirmopportunity'];
        if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_OPPORTUNITY)){
          opportunityFooterBtns.push('assignOwneropportunity');
        }
        this.updateButtons(opportunityFooterBtns, true);
        break;
      case FooterViews.OPPORTUNITY_INFO_CONFIRMED:
        let opportunityFooterBtnsconfirmed = ['opportunityscrap','closeaslost','closeasusercanceled','closeaswon'];
        if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_OPPORTUNITY)){
          opportunityFooterBtnsconfirmed.push('assignOwneropportunity');
        }
        this.updateButtons(opportunityFooterBtnsconfirmed, true);
        break;
      case FooterViews.INQUIRY_CONSENT:
        this.updateButtons(['flipToInquiryDetails'], true);
        this.displayFullScreenButtons();
        break;
      case FooterViews.EVENTS_DETAILS: {
         const attendeeEvents = 'attendeeEvents';
        const submitBtn = this.authService.hasFeatureAction(FeatureActionsMap.EVENT_APPROVAL)
          && options?.isNotApprovedOrCompleted
          ? 'event_tool_send_for_approval'
          : !options?.isEventCompleteButtonHidden
            ? 'event_tool_activity_mark_complete'
            : '';
        const buttonsToDisplay = [attendeeEvents, 'event_tool_activity_scrap'];
        submitBtn && buttonsToDisplay.push(submitBtn);
        if (options && options.showPdfPreview) {
          buttonsToDisplay.unshift('pdfPreview');
        }
        this.updateButtons(buttonsToDisplay, true);
        break;
      }
      case FooterViews.CAPTURE_EVENTS:
        this.updateButtons(['pdfPreview','QRcodeScan'], true);
        // pdfPreview button could possibly be left aligned
        // from the event details page when in phone portrait mode.
        this.alignButtons([{ id: 'pdfPreview', align: 'right' }]);
        break;
      case FooterViews.PDF_PREVIEW: {
        const buttonsToDisplay = [];
        if (options && options.allowExport) {
          buttonsToDisplay.push('exportPDF');
        }
        this.updateButtons(buttonsToDisplay, true);
        break;
      }
      case FooterViews.PROCEDURE_CONTRACT: {
        this.updateButtons(['downloadPDF','sendEmailContract'], true);
        if (this.deviceService.isOffline) this.disableButton(['downloadPDF', 'sendEmailContract']);
        else this.enableButtons(['downloadPDF', 'sendEmailContract']);
        break;
      }
      case FooterViews.MESSAGE_NON_NATIVE:
        this.initFooterViewsMESSAGE_NON_NATIVE();
        break;
      case FooterViews.SURGERY_ORDER_ACTIVITY:
        let footerButtons = ['reopensurgeryactivity', 'markCompleteSurgeryOrder'];
        if(this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelprocedurelogwithreason']){
          footerButtons.push('scraporcancelSurgeryOrderActivity')
        }else{
          footerButtons.push('scrapSurgeryOrderActivity')
        }
        if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG) && !(this.activityService.selectedActivity as SurgeryOrderActivity).indskr_noprocedureday){
          footerButtons = [...footerButtons, 'assignOwnersurgeryactivity'];
        }
        this.updateButtons(footerButtons , true);
        this.enableButtons(footerButtons);
        break;
      case FooterViews.PROCEDURE_TRACKER_ACTIVITY:
        this.updateButtons(['reopenproceduretrackeractivity', 'scrapProcedureTrackerActivity', 'markCompleteProcedureTrackerActivity'], true);
        if (this.deviceService.isOffline) this.disableButton(['reopenproceduretrackeractivity', 'scrapProcedureTrackerActivity', 'markCompleteProcedureTrackerActivity']);
        else this.enableButtons(['reopenproceduretrackeractivity', 'scrapProcedureTrackerActivity', 'markCompleteProcedureTrackerActivity']);
        break;
      case FooterViews.ALLOCATION_TRANSFER:
        this.updateButtons(['transferallocation'], true);
        this.enableButtons(['transferallocation'], false);
        break;
      case FooterViews.CONTACT_MEDICAL_INSIGHT:
        this.updateButtons(['savecontactmedicalinsight'], true);
        break;
      case FooterViews.ASSET_DETAILS:
        this.updateButtons(['transferasset', 'confirmtransfer', 'cancelassetdeployment'], true);
        this.disableButton(['confirmtransfer', 'cancelassetdeployment']);

        const asset: CustomerAsset = this.fieldMaterialManagementService?.selectedAsset;
        if (asset) {
          const transferable: boolean =
            !asset.indskr_approvalrequired &&
            !asset.indskr_acknowledgerequired &&
            !asset.indskr_customerconfirmation &&
            !this.deviceService.isOffline;
          this.enableButtons(['transferasset'], transferable);
          if (!this.deviceService.isOffline && !_.isEmpty(asset.msdyn_account) && asset.latestTransfer && asset.latestTransfer.isLocationChange && asset.latestTransfer.statuscode == AssetTrasferStatus.Completed) {
            this.enableButtons(['cancelassetdeployment'], true);
          }
        }
        break;
      case FooterViews.ASSET_TRANSFER_DETAILS:
        const transfer = this.fieldMaterialManagementService.selectedAssetTransfer;
        if (transfer) {
          const locationBtns = ['transfer-complete', 'transfer-cancel'];
          const AMChangeBtnsForOwner = ['transfer-cancel'];
          const AMChangeBtnsForReceiver = ['transfer-acknowledge', 'transfer-reject'];
          let btns = transfer.isLocationChange ? locationBtns : (this.authService.user.systemUserID == transfer.current_asset_owner ? AMChangeBtnsForOwner : AMChangeBtnsForReceiver);
          this.updateButtons([...btns], true);
          if (this.deviceService.isOffline) {
            this.disableButton(btns);
          } else {
            let disabledBtns = [];
            let enabledBtns = [];
            if (transfer.isLocationChange) {
              if (transfer.statuscode == AssetTrasferStatus.Approved && transfer.indskr_customerconfirmation === 1 && !_.isEmpty(transfer.notes) && transfer.notes.some(n => n.isdocument)) {
                enabledBtns.push('transfer-complete');
              } else {
                disabledBtns.push('transfer-complete');
              }
              if (transfer.statuscode == AssetTrasferStatus.Initiated || transfer.statuscode == AssetTrasferStatus.Approved) {
                enabledBtns.push('transfer-cancel');
              } else {
                disabledBtns.push('transfer-cancel');
              }
            } else {
              if (transfer.statuscode == AssetTrasferStatus.Approved && this.authService.user.systemUserID == transfer.new_asset_owner) {
                enabledBtns.push(...['transfer-acknowledge', 'transfer-reject']);
              } else {
                disabledBtns.push(...['transfer-acknowledge', 'transfer-reject']);
              }
              if (transfer.statuscode == AssetTrasferStatus.Initiated && this.authService.user.systemUserID == transfer.current_asset_owner) {
                enabledBtns.push('transfer-cancel');
              } else {
                disabledBtns.push('transfer-cancel');
              }
            }
            this.enableButtons(enabledBtns, true);
            this.disableButton(disabledBtns);
          }
        }

        break;
      case FooterViews.MULTIPLE_ASSESS:
        this.updateButtons(['saveMultipleAssess'], true);
        this.enableButtons(['saveMultipleAssess'], false);
        break;
      case FooterViews.CONTACT_KOL_STATUS_OWNER:
        this.updateButtons(['savecontactkolstatus'], true);
        break;
      case FooterViews.CONTACT_KOL_STATUS_APPROVER:
        this.updateButtons(['approvecontactkolstatus', 'rejectcontactkolstatus'], true);
        break;
      case FooterViews.MANAGE_LIST_CUSTOMER_POSITION_LIST:
        this.updateButtons(['scrapterritory', 'saveterritory', 'sendTerritoryForApproval'], true);
        break;
      case FooterViews.APPROVAL_CUSTOMER_POSITION_LIST: 
        this.updateButtons(['approveManageList', 'rejectManageList'], true);
        break;
      case FooterViews.SANOFI_MDM_DETAILS: 
        this.updateButtons(['hopQrCode'], true);
        break;
      case FooterViews.SURVEY_DETAILS:
        this.updateButtons(['startSurvey'], true);
        if(options) {
          if(options.disableButtons) {
            this.disableButton(options.disableButtons);
          }
          if(options.enableButtons) {
            this.enableButtons(options.enableButtons);
          }
        }
        break;
      case FooterViews.SAVE_SURVEY_DETAILS:
        this.updateButtons(['scrapSurvey','saveSurvey','submitSurvey'], true);
        if(options) {
          if(options.disableButtons) {
            this.disableButton(options.disableButtons);
          }
          if(options.enableButtons) {
            this.enableButtons(options.enableButtons);
          }
        }
        break;
      case FooterViews.DCR_APPROVALS:
        this.updateButtons(['approveDCRRequest', 'rejectDCRRequest'], true);
        break;
      case FooterViews.FILTER_MENU:
        this.updateButtons(['saveFilter'], true);
        if(options) {
          if(options.disableButtons) {
            this.disableButton(options.disableButtons);
          }
          if(options.enableButtons) {
            this.enableButtons(options.enableButtons);
          }
        }
        break;
      case FooterViews.SetBooking:
        this.updateButtons(['scrapsetbooking','editsetbooking'], true);
        this.enableButtons(['scrapsetbooking','editsetbooking']);
        if(options) {
          if(options.disableButtons) {
            this.disableButton(['scrapsetbooking','editsetbooking']);
          }
          else if(options.disableScrapButton) {
            this.disableButton(['scrapsetbooking']);
          }
        }
        break;
      default:
        break;
    }
  }

  public publishButtonId(id: string) {
    this.buttonIdPublisher.next(id);
    this.buttonIdPublisher.next(null);
  }

  public showActivitybutton(selectedView): boolean {
    return (selectedView === 'activities' || selectedView === 'xperiences');
  }

  public getActiveButtonsList(): Array<string> {
    let res = [];
    this.actionButtons.forEach(b => {
      if (b.isvisible) {
        res.push(b.id);
      }
    });
    return res;
  }

  public shouldApplyBottomMargin(isMaster: boolean = false): boolean {
    // When master, should always add the bottom margin for portrait and landscape.
    // for else case, check for the mobile and portrait orientation.
    if (isMaster) {
      // confirming, we don't have a visible footer bar.
      // We only have a genee button and though we require bottom margin.
      return (this.hasVisibleFooterIconsInMaster === false);
    } else {
      return (this.isMobileDevicePortrait() === true);
    }
  }

  public hasVisibleFooterIcons(): boolean {
    return (this.actionButtons && this.actionButtons.filter(button => button.isvisible).length > 0);
  }

  public fabNotRequestedFromPresentation(value: string) {
    const type = value as ActivityType;
    switch (type) {
      case ActivityType.Appointment:
        return !this.presentationService.isFullScreenMode;
      case ActivityType.Email:
        return this.emailService.viewType !== EmailViewType.EMAIL_FROM_MEETING_PRESENTATION;
      case ActivityType.CaseIntake:
        return this.caseService.accessedFrom !== AccesingMode.PRESENTATION;
      case ActivityType.Sample:
        return this.sampleService.samplingDetailsViewMode !== SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION;
      case ActivityType.SurgeryOrder:
        return !this.surgeryOrderDataService.inMeetingSurgeryOrderActivity;
      case ActivityType.ProcedureTracker:
        return !this.procedureTrackerActivityDataService.inMeetingProcedureTrackerActivity;
      case ActivityType.FollowUp:
        return !this.followupDataService.inMeetingFollowupActionActivity;
      case ActivityType.CustomerSurvey:
        return !this.customerSurveyService.inMeetingCustomerSurvey;
      default:
        return true;
    }
  }

   shouldShowFooter(): boolean {
    if (this.isMobileDevicePortrait()) {
      return true;
    } else if (this.hasVisibleFooterIcons() === false) {
      // On mobile device, when landscape, if we select anythig from left
      // at right side, if no footer icons then footer it self was not rendered into the DOM
      // thus when we change to Portrait, we can't see the FAB it self.
      // Here, when we're on Mobile Device and having No footer icons then, returing true
      // so footer will be rendered into the DOM and next time when it change to Portrait
      // We can see the FAB in place.
      if (this.deviceService.isMobileDevice) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  hideButton(btn: string, hide = true) {
    this.actionButtons.forEach(value => {
      if (value.id === btn) {
        value.isvisible = !hide;
      }
    });
  }

  showButton(btn: string, show = true) {
    this.actionButtons.forEach(value => {
      if (value.id === btn) {
        value.isvisible = this.buttonAllowedByFeatureAction(value) && show;
      }
    });
  }

  hideAllButtons() {
    this.actionButtons.forEach(value => {
      value.isvisible = false;
    });
  };

  disableButton(keys?: Array<string>) {
    this.actionButtons.forEach(value => {
      if (keys && keys.indexOf(value.id) > -1) {
        value.isactive = false;
      } else {
        value.isactive = true;
      }
    });
  }

  // Enables the provied array of buttons (Doens't change state of other buttons)
  enableButtons(keys?: Array<string>, enable = true) {
    this.actionButtons.forEach(value => {
      if (keys && keys.indexOf(value.id) > -1) {
        value.isactive = enable;
      }
    })
  }

  buttonAllowedByFeatureAction(button) {
    if (button.featureActions && button.featureActions.length > 0) {
      return button.featureActions.some(fa => this.authService.hasFeatureAction(fa));
    }
    return true;
  }

  alignButtons(buttons: { id: string, align: 'left' | 'right' }[]) {
    if (Array.isArray(buttons)) {
      for (let i = 0; i < this.actionButtons.length; i++) {
        const actionButton = this.actionButtons[i];
        const idx = buttons.findIndex(b => b.id === actionButton.id);
        if (idx >= 0) {
          actionButton.align = buttons[idx].align;
        }
      }
    }
  }

  private enableCustomerEventsInMeeting(activityContacts): boolean {
    if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) && activityContacts && activityContacts.length > 0) {
      for (let contact of activityContacts) {
        let contactEvent = this.customerEventsService.getEventsForContact(contact.ID);
        if (contactEvent && contactEvent['availableEvents'].length > 0 && contactEvent['availableEvents'].some(event => event['indskr_reasons'] != 548910002) && contactEvent['availableEvents'].some(event => event['indskr_reasons'] != 451680002)) {
          return true;
        }
      };
    }
    return false;
  }


  private initOpentokButtons() {
    for (let btn of this.actionButtons) {
      if (btn.id === "video" || btn.id === "stop_video" || btn.id === "audio" || btn.id === "stop_audio") {
        (btn as any).opentok = this.opentok;
      }
    }
  }

  addOrRemoveOnFAB(remove: boolean, bid: string) {
    this.actionButtons.filter(btn => btn.id === bid).forEach(btn => {
      btn.availableOnFullScreen = !remove;
    });
  }

  private showMoreOption() {
    this.actionButtons.filter(btn => btn.id === 'openmoreoptions').forEach(btn => {
      btn.isvisible = true;
      btn.isactive = true;
      btn.availableOnFullScreen = true;
    });
  }

  private hideMoreOption() {
    this.actionButtons.filter(btn => btn.id === 'openmoreoptions').forEach(btn => {
      btn.isvisible = false;
      btn.isactive = false;
      btn.availableOnFullScreen = false;
    });
  }

  private isMobileDevicePortrait(): boolean {
    return (this.deviceService.isMobileDevice && this.deviceService.isMobileDevicePortrait);
  }

  private isOnlyMoreButton(): boolean {
    const visibleButtonsLength = this.actionButtons.filter(btn => btn.isvisible).length;
    const buttonsWithMoreOptionLength = this.actionButtons.filter(btn => btn.isvisible && btn.id === 'openmoreoptions').length;
    return (visibleButtonsLength > 0 && visibleButtonsLength === buttonsWithMoreOptionLength);
  }

  displayFullScreenButtons() {

    this.changeDisplayOrderOfPreview(true);

    // if (this.isOnlyMoreButton()) {
    //   return;
    // }

    const numberOfMoreButtons = this.getNumOfMoreButtonsOnly();
    const visibleButtons = _.orderBy(this.actionButtons.filter(btn => btn.isvisible), ['displayOrder']);
    if (numberOfMoreButtons > 0) {

      let intoolbarbutton = [];

      if (this.footerForView === FooterViews.PreviewMeeting && this.isMobileDevicePortrait()) {
        let items = ['play', 'pause', 'carousel', 'audio', 'video', 'home'];
        intoolbarbutton = visibleButtons.filter((elem) => items.find((t) => elem.id.includes(t)));
      } else if (this.footerForView === FooterViews.Activities && this.isMobileDevicePortrait()) {
        let items = ['contacts', 'presentations', 'preview'];
        intoolbarbutton = visibleButtons.filter((elem) => items.find((t) => elem.id.includes(t)));
      } else {
        const uptoIndex = (visibleButtons.length - numberOfMoreButtons) - 1;
        intoolbarbutton = visibleButtons.splice(0, uptoIndex);
      }

      this.actionButtons.filter(btn => btn.isvisible).forEach(button => {
        button.availableOnFullScreen = intoolbarbutton.indexOf(button) != -1 ? true : false;
      });
      this.showMoreOption();
    } else {
      this.hideMoreOption();
      this.actionButtons.filter(btn => btn.isvisible).forEach(button => {
        button.availableOnFullScreen = true;
      });
    }

    this.changeDisplayOrderOfPreview(false);
  }

  private swipeWhenRequire(intoolbarbutton: any[], haveMoreButton: boolean = false) {
    if (this.footerForView === FooterViews.Activities && this.isMobileDevicePortrait() === false) {
      const previewButtonIndex = intoolbarbutton.findIndex(b => b.id === "preview");
      if (previewButtonIndex > -1) {
        let lastButtonObjectId = haveMoreButton ? intoolbarbutton[(intoolbarbutton.length - 1)].id : intoolbarbutton[0].id;
        let previewButtonObject = this.actionButtons.find(b => b.id === "preview");
        let lastButtonObject = this.actionButtons.find(b => b.id === lastButtonObjectId);
        let lbodo = lastButtonObject.displayOrder;
        let pbodo = previewButtonObject.displayOrder;
        if (pbodo < lbodo) {
          previewButtonObject.displayOrder = lbodo;
          lastButtonObject.displayOrder = pbodo;
        }
      }
    }
  }

  updateButtons(keys: Array<string>, hidden, isDetailScreen = true) {

    this.changeDisplayOrderOfPreview(true);

    // if (this.isOnlyMoreButton()) {
    //   return;
    // }

    this.actionButtons.forEach(value => {
      if(value.hasOwnProperty('external')) {
        if (this.golbalContactName) {
          value.external = this.golbalContactName;
        }
      }
      if (keys.indexOf(value.id) > -1) {
        value.isvisible = this.buttonAllowedByFeatureAction(value) && hidden;
      } else {
        value.isvisible = this.buttonAllowedByFeatureAction(value) && !hidden;
      }
    });

    const visibleButtons = _.orderBy(this.actionButtons.filter(btn => btn.isvisible), ['displayOrder']);

    const numberOfMoreButtons = this.getNumOfMoreButtonsOnly();

    if (numberOfMoreButtons > 0) {
      // In case of Meeting Presentation
      // We have to have defined order.

      let intoolbarbutton = [];

      if (this.footerForView === FooterViews.PreviewMeeting && this.isMobileDevicePortrait()) {
        let items = ['play', 'pause', 'carousel', 'audio', 'video', 'home'];
        intoolbarbutton = visibleButtons.filter((elem) => items.find((t) => elem.id.includes(t)));
      } else if (this.footerForView === FooterViews.Activities && this.isMobileDevicePortrait()) {
        let items = ['contacts', 'presentations', 'preview','checkin','checkout'];
        intoolbarbutton = visibleButtons.filter((elem) => items.find((t) => elem.id.includes(t)));
      } else {
        const uptoIndex = (visibleButtons.length - numberOfMoreButtons) - 1;
        intoolbarbutton = visibleButtons.splice(0, uptoIndex);
      }

      this.actionButtons.filter(btn => btn.isvisible).forEach(button => {
        button.availableOnFullScreen = intoolbarbutton.indexOf(button) != -1 ? true : false;
      });

      this.showMoreOption();
    } else {
      this.hideMoreOption();
      // _.orderBy(this.actionButtons.filter(btn => btn.isvisible), ['displayOrder'], ['desc'])
      this.actionButtons.filter(btn => btn.isvisible).forEach(button => {
        button.availableOnFullScreen = true;
      });
    }
    this.changeDisplayOrderOfPreview(false);
  }

  updateButtonsWithOptions(
    buttonOptions: Map<string, FooterButtonOptions>,
    hideVisibilityOfRestButtons = false,
  ) {
    for (let i = 0; i < this.actionButtons.length; i++) {
      const button = this.actionButtons[i];
      const option = buttonOptions.get(button.id);
      if (option) {
        this.actionButtons[i] = {...button, ...option};
        this.actionButtons[i].isvisible = this.buttonAllowedByFeatureAction(button) && option.isvisible
      } else if (hideVisibilityOfRestButtons) {
        button.isvisible = false;
        button.availableOnFullScreen = true;
      }
    }
  }

  private changeDisplayOrderOfPreview(isUpdateStart: boolean) {
    if (this.footerForView === FooterViews.Activities) {
      let previewButtonIndex = this.actionButtons.findIndex((b) => { return b.id === 'preview' });
      if (previewButtonIndex > 0) {
        this.actionButtons[previewButtonIndex]['displayOrder'] = isUpdateStart ? 5 : 150;
      }
    }
  }

  public getNumOfMoreButtonsOnly() {
    let res = this.getNumOfMoreButtons();
    if (res.result === 'invalid') {
      return 0;
    } else {
      return res.isConsideringMore ? res.noOfButtonsInMore : 0;
    }
  }

  private getVisibleButtons(): number {
    const visibleButtons = !_.isEmpty(this.actionButtons) ? this.actionButtons.filter(btn => btn.isvisible) : [];
    const totalButtons: number = visibleButtons.length;
    return totalButtons;
  }

  private storedFabWidth = new Map<FabViewType, number>();

  public getFullScreenActionItemSize(): string {
    let size = '1';
    if (this.deviceService.isMobileDevice) {
      if (this.deviceService.isMobileDevicePortrait) {
        size = '2';
      }
    }
    return size;
  }

  public getDetailsScreenActionItemSize(hideGenie: boolean = false): string {
      const totalButtons: number = this.getVisibleButtons();
      let size = '2'; // Default Value
      if (this.deviceService.isMobileDevice) {
        if (this.deviceService.isMobileDevicePortrait) {
          if (totalButtons <= 4 ) {
            if (hideGenie || totalButtons == 2 || totalButtons == 1) {
              size = '3';  
            } else {
              size = '2.4';
            }
          }
        } else {
          size = '2.4';
        }
      }
      return totalButtons <= 4 ? size : '';
  }

  public updateFABWhenOrientationChanges() {
    const { fabViewType, clientWidth } = this.whichFABView();
    if (fabViewType !== FabViewType.invalidFabView) {
      const key = (fabViewType === FabViewType.detailFabView) ? FabViewType.detailFabView : FabViewType.fullscreenFabView;
      const storedFabWidth = this.storedFabWidth.get(key);
      const width = clientWidth;
      if (width > 0 && storedFabWidth !== width) {
        this.displayFullScreenButtons();
      }
    }
  }

  private whichFABView(): { fabViewType: FabViewType, clientWidth: number } {
    let detectedFAB = document.getElementById('detailsFAB');

    let isFullscreenFAB = false;
    let isDetailsScreenFAB = false;

    if (detectedFAB) {
      isDetailsScreenFAB = true;
      if(this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
        if(this.presentationService.isFullScreenMode && !this.presentationService.isPrevFullScreenMode) {
          isDetailsScreenFAB = false;
          isFullscreenFAB = true;
        }else if(!this.presentationService.isFullScreenMode && this.presentationService.isPrevFullScreenMode) {
          isDetailsScreenFAB = false;
          isFullscreenFAB = true;
        }
      }
    } else {
      detectedFAB = document.getElementById('fullscreenFAB');
      if (detectedFAB) {
        isFullscreenFAB = true;
      }
    }

    // Some time it may possible that there are details and full screen both are loaded.
    // so the case is when you go to the meeting presentation (any full screen) if for some reason
    // it is still getting access to the details view FAB.
    if (isFullscreenFAB === false) {
      if (this.footerView && this.footerView === 'fullScreenView') {
        if (this.footerForView !== FooterViews.Activities) {
          //
          const fsFAB = document.getElementById('fullscreenFAB');
          if (fsFAB) {
            detectedFAB = fsFAB;
            isFullscreenFAB = true;
            isDetailsScreenFAB = false;
          }
          //
        }
      } else {
        // Continue check for the fullscreen.
        // Case: from meeting details > presentation > resources > add a resource > see the footer.
        // Why it was happening? as the condition becomes invalid.
        // footerForView was changed to detailsView even if we are in fullScreenView.
        // Should be fixed with below conditions.
        if (this.navService.getCurrentPageName() === PageName.PresentationPageComponent
          || this.navService.getCurrentPageName() === PageName.PresentationPreviewComponent
          || this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent
          || this.navService.getCurrentPageName() === PageName.PresentationFullscreenComponent) {
            isDetailsScreenFAB = false;
            isFullscreenFAB = true;
        }
      }
    }

    let fabViewType: FabViewType = FabViewType.invalidFabView;
    let clientWidth: number = 0;

    if (detectedFAB) {
      if (isDetailsScreenFAB) {
        fabViewType = FabViewType.detailFabView;
        clientWidth = detectedFAB.clientWidth;
      } else if (isFullscreenFAB) {
        fabViewType = FabViewType.fullscreenFabView;
        clientWidth = detectedFAB.clientWidth;
      }
    } else {
      isDetailsScreenFAB = false;
      isFullscreenFAB = false;
    }

    // edge cases
    // Wfe > Contact Tools > Create Meeting > Open Contact , add/update customers > See the footer
    // Here, footerView = 'masterView' which is wrong.
    if (fabViewType === FabViewType.invalidFabView && clientWidth <= 0) {
      if (isDetailsScreenFAB === false && isFullscreenFAB === false) {
        if (this.footerView === 'masterView') {
          // reset the fabViewType to detailsView and clientWidth to detailsView width
          const oldDetailsWidth = this.storedFabWidth.get(FabViewType.detailFabView);
          if (oldDetailsWidth > 0) {
            clientWidth = oldDetailsWidth;
            fabViewType = FabViewType.detailFabView;
          }
        }
      }
    }

    return { fabViewType, clientWidth };
  }

  private updateFabWidth(reference: { fabWidth: number }, fabViewType: FabViewType) {
    if (fabViewType !== FabViewType.invalidFabView) {
      const key = (fabViewType === FabViewType.detailFabView) ? FabViewType.detailFabView : FabViewType.fullscreenFabView;
      // in case of surgery order screen fabWidth is coming 0 in some cases
      // so setting it back to original found.
      if (reference.fabWidth > 0 && this.storedFabWidth.get(key) !== reference.fabWidth) {
        // temporary store the latest fabWidth
        this.storedFabWidth.set(key, reference.fabWidth);
      } else {
        if (reference.fabWidth === 0 && this.storedFabWidth.get(key) > 0) {
          // take temporary stored fab width
          reference.fabWidth = this.storedFabWidth.get(key);
        }
      }

      if (reference.fabWidth <= 0 && this._deviceWidth > 0) {
        // one last try to get the fabWidth
        // When create a message from the contact tool once we start the app
        // fabWidth is always 0 so returning back from the next return statement.
        reference.fabWidth = (this._deviceWidth - 20);
        this.storedFabWidth.set(key, reference.fabWidth);
      }
    }
    return reference;
  }

  private getNumOfMoreButtons() {
    const visibleButtons = this.actionButtons.filter(btn => btn.isvisible);
    let totalButtons: number = visibleButtons.length;

    if (totalButtons === 0) {
      return { 'result': 'invalid' };
    }

    const { fabViewType, clientWidth } = this.whichFABView();

    if (fabViewType === FabViewType.invalidFabView) {
      return { 'result': 'invalid' };
    }

    // fabWidth is important,
    // if we don't have a FAB loaded into the DOM we have problems in render the buttons
    // to over come here we're storing the old fab width value and keep update it every time we get a reference to the FAB.
    var fabWidth = 0;

    if (clientWidth > 0) {
      fabWidth = clientWidth;
    }

    const result = this.updateFabWidth({ fabWidth }, fabViewType);
    fabWidth = result.fabWidth;

    if (fabWidth <= 0) {
      // If still fabWidth is 0, we have to return from here.
      return { 'result': 'invalid' };
    }

    // If mobile device then have to consider the Genee Button
    const isMobileDevice = this.deviceService.isMobileDevice;
    const fixedButtonWidth = isMobileDevice ? 60 : (fabViewType === FabViewType.fullscreenFabView) ? 100 : 150;

    if (isMobileDevice) {
      totalButtons += 1; // Considering Genee
    }

    if (isMobileDevice === false) {
      if (fabViewType === FabViewType.detailFabView || fabViewType === FabViewType.fullscreenFabView) {
        if (visibleButtons.filter(b => b.id.includes('openmoreoptions')).length === 1) {
          totalButtons -= 1;
        }
      }
    }

    // On phone form, when in portrait mode and total buttons are exactly 7 including a genee button,
    // increasing it to +1 will not show clumsy UI (all 7 buttons together) and will look good.
    // clientWidth <= 400 meaning includes specific smaller sized devices only.
    // Doing this additional button will be ignored but we will have a More button for a better UI.
    if (this.isMobileDevicePortrait() && totalButtons === 7 && clientWidth <= 400) {
      totalButtons += 1;
    }

    const totalSpaceRequire = totalButtons * fixedButtonWidth;
    const isMoreSpaceAvailableInsideFab = (fabWidth >= totalSpaceRequire);
    if (isMoreSpaceAvailableInsideFab) {
      return { 'result': 'invalid' };
    } else {

      const diffInSpace = (Math.abs(fabWidth - totalSpaceRequire));
      // when there is only one option inside the More button
      // const isMoreButtonRequire = (totalSpaceRequire  > fabWidth)
      const isValidDiffWhenCompareToFixedButtonWidth = (diffInSpace >= (fixedButtonWidth/2));
      const isMoreButtonRequire = ((totalSpaceRequire  > fabWidth) && isValidDiffWhenCompareToFixedButtonWidth === true);
      if (isMoreButtonRequire === false) {
        return { 'result': 'invalid' };
      }
      const inpoints = diffInSpace / fixedButtonWidth;
      const floorInpoints = Math.floor(inpoints);
      const sub = Math.abs(floorInpoints - inpoints);
      const considerOneMore = sub >= 0.5 ? 1 : 0
      const diff = (floorInpoints + considerOneMore);
      let noOfButtonsInMore = diff;

      if (noOfButtonsInMore <= 1) {
        return { 'result': 'invalid' };
      }

      return { 'result': 'valid', 'noOfButtonsInMore': noOfButtonsInMore, 'isConsideringMore': isMoreButtonRequire };
    }
  }

  updateSwipeButtonState(pageName: PageName) {
    if (this.presentationService.swipeStatus === SwipeStatus.DISABLED_BY_USER || this.presentationService.swipeStatus === SwipeStatus.DISABLED_FOR_CONTENT_INTERACTION) {
      if (pageName === PageName.PresentationMeetingComponent) {
        this.hideButton('meetingswipeenabledactive');
        this.showButton('meetingswipedisabled');
      } else if (pageName === PageName.PresentationFullscreenComponent || pageName === PageName.PresentationPreviewComponent) {
        this.hideButton('presentationswipeenabled');
        this.showButton('presentationswipedisabled');
      }
    } else {
      if (pageName === PageName.PresentationMeetingComponent) {
        this.hideButton('meetingswipedisabled');
        this.showButton('meetingswipeenabledactive');
      } else if (pageName === PageName.PresentationFullscreenComponent || pageName === PageName.PresentationPreviewComponent) {
        this.hideButton('presentationswipedisabled');
        this.showButton('presentationswipeenabled');
      }
    }
  }

  get shouldApplyExtraCssForIframe() {
    return (
      (
        this.presentationService.hideCarousel === true
        && this.presentationService.isReferenceVisible === false
      )
      && (this.deviceService.deviceFlags.ios || this.deviceService.deviceFlags.android)
    );
  }

  private isSelectedActivityReopenable(reopenPeriod: number):boolean {
    let isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false;
    let coVisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonmeeting'];

    return this.activityService.selectedActivity?.omnip_datecompleted ? !((!this.activityService.isMeetingOwner && !isCovisitor && coVisitorAccess !== CovisitorAccess.FULL_ACCESS)
      || this.activityService.teamViewActive || !this.activityService.selectedActivity?.isCompleted || this.deviceService.isOffline
      || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity?.ID) || this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity?.ID)
      || moment().diff(moment(new Date(parseFloat(this.activityService.selectedActivity?.omnip_datecompleted))), 'days') >= reopenPeriod) : false;
  }

  getFakeMouseEventForPopoverDerivedFromMoreOptionsButton(footerEl: HTMLElement): MouseEvent {
    let footerButtonTarget;// = this.footerEl.nativeElement.querySelector('.openmoreoptionsactive');
    // Get the right most icon
    const iconEls = footerEl.querySelectorAll('ion-col');
    const iconElsLength = iconEls.length;
    if (iconEls && iconElsLength > 0) {
      // when on mobile we have to access the last element
      // if on the web build/tablet have to access the first element
      // as we are reversing the elements when on the web build.
      if (this.deviceService.isMobileDevice === true && this.deviceService.isMobileDevicePortrait === true) {
        footerButtonTarget = iconEls[iconElsLength - 1];
      } else {
        footerButtonTarget = iconEls[0];
      }
    }
    if (footerButtonTarget === null || footerButtonTarget === undefined) {
      // If still null, just use footer as target
      footerButtonTarget = footerEl;
    }

    const targetRect = footerButtonTarget?.getBoundingClientRect();
    const event = new MouseEvent('click', {
      view: window,
      button: 0,
      clientX: targetRect.left + targetRect.width / 2 ?? 0,
      clientY: targetRect.top ?? 0,
      screenX: targetRect.left + targetRect.width / 2 ?? 0,
      screenY: targetRect.top ?? 0,
    });
    Object.defineProperty(event, 'target', { value: footerButtonTarget, enumerable: true });

    return event;
  }

}
