<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="searchTextModel" (onSearchInput)="onInput($event)" (onControlClick)="onHeaderControlClick($event)"></ind-header-left>
    <ind-section-header *ngIf="!searchTextModel else searchHeader" [viewData]='allSettingHeader'></ind-section-header>
    <ng-template #searchHeader>
        <ind-section-header [viewData]="searchResultHeader"></ind-section-header>
    </ng-template>
</ion-header>
<ion-content no-padding toolbar-md-height>
    <ng-container *ngIf="!searchTextModel else searchResult">
        <ion-list [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <div *ngFor="let list of settingList" tappable (click)="openDetails(list)">
                <main-card [viewData]="list"></main-card>
            </div>
        </ion-list>
    </ng-container>

    <ng-template #searchResult>
        <ion-list *ngIf="searchedSettingList?.length > 0 else noSearchResult;" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <div *ngFor="let list of searchedSettingList" tappable (click)="openDetails(list)">
                <main-card [viewData]="list"></main-card>
            </div>
        </ion-list>

        <ng-template #noSearchResult>
            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </ng-template>
    </ng-template>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="''"></footer-toolbar>
<!-- </ion-footer> -->
