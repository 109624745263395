import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { TokenCredential, TokenCredentialOptions } from "./token.credential";
import { CordovaPlugin } from "../cordova";

var GENERIC_ERR_MESSAGE = 'Error occurred while executing native method.';
var GENERIC_ERR_CODE = 'NATIVE_METHOD_GENERAL_FAILURE';

export class CordovaCredential implements TokenCredential {
  private _plugin = new CordovaPlugin('ADALProxy');
  private readonly _ready: Promise<any>;
  readonly ready = () => this._ready;
  constructor(private options: TokenCredentialOptions) {
    this._ready = this.init({
      clientId: options.clientId,
      authority: options.authority,
      redirectUri: 'omnipresence://com.omnipresence.io/login'
    })
  }


  async init({ clientId, authority, redirectUri }) {
    await this._plugin.exec('create', {
      auth: {
        clientId: clientId,
        authority,
        redirectUri: redirectUri,
      }
    });
  }


  async getToken(scopes: string | string[]) {
    await this.ready();
    if (!Array.isArray(scopes)) {
      scopes = [scopes];
    }
    const accessTokenRequest = {
      clientId: this.options.clientId,
      authority: this.options.authority,
      scopes,
      loginHint: this.options.username,
    };


    let interactionRequired = false;
    let authResult: AuthenticationResult;
    try {

      authResult = await this._plugin.exec('acquireTokenSilent', accessTokenRequest);
      if (!authResult) {
        interactionRequired = true;
        return;
      }
    }
    catch (error) {
      const interactionErrorCodes = [
        'no_current_account',
        'no_account_error',
        "invalid_grant",
        "interaction_required",
        "no_tokens_found",
        "no_account_found",
      ]
      if (interactionErrorCodes.includes(error.errorCode)) {
        interactionRequired = true;
      } else {
        throw error;
      }
    } finally {
      if (interactionRequired) {
        authResult = await this._plugin.exec('acquireTokenPopup', accessTokenRequest);
        if (authResult.account?.username?.toLowerCase() !== this.options.username?.toLowerCase()) {
          throw new Error("LOGIN_WE_SIGN_YOU_IN");
        }
      }
      if (authResult) {
        return {
          token: authResult.accessToken,
          expiresOnTimestamp: authResult.expiresOn as unknown as number
        };
      }
    }
  }

  async logout() {}
}

export class IntuneManager {
  private static _plugin = new CordovaPlugin('ADALProxy');
  static initIntune(clientId: string) {
    return this._plugin.exec('initIntune', { clientId });
  }

  static registerAccount(clientId: string, username: string) {
    return this._plugin.exec('registerAccount', { clientId, username });
  }

  static showDiagnostics() {
    return this._plugin.exec('showIntuneDiagnostics', {});
  }
}
