<ion-item-sliding class="list-item" *ngIf="listType == 'list'">
    <ion-item class='selector-item' [ngClass]="{'active': isActivePres, 'inactive': isOffline && !pres.downloaded}" (click)="showDetails()">
        <ion-checkbox slot="start" class="list-check-box" *ngIf="presService.isSelectMode" (click)="$event.stopPropagation()" (ngModelChange)="toggleIsPresSelected($event)" [(ngModel)]="pres.isSelected"></ion-checkbox>
        <div class="image-wrapper">
            <progress *ngIf="(isDownloading$ | async) && (isActiveDownload$ | async)" class="progress-bar" [value]="progressPer" max="100"></progress>
            <progress *ngIf="(isUnzipping$ | async) && (isActiveDownload$ | async)" class="progress-bar" [value]="progressPer" max="100"></progress>
            <ion-thumbnail class="thumbnail-ion" slot="start">
               <img class="thumbnail" [src]="thumbUrl" />
            </ion-thumbnail>
        </div>
        <ion-label class="presentation-name-text">
            <div class="name-label">{{pres.name || $any(pres).title}}</div>
            <p text-wrap class="sub-label secondaryTextColorAndfont" [ngClass]="(sortedBy == translate.instant('LAST_MODIFIED_DATE') ? 'two-lines' : 'three-lines' )">
                {{pres.description}}
            </p>
            <div class="sub-label secondaryTextColorAndfont" *ngIf="!pres.showCustomSecInfo && sortedBy == translate.instant('LAST_MODIFIED_DATE')"> {{getFormattedDate()}}</div>
            <div class="sub-label secondaryTextColorAndfont" *ngIf="pres.showCustomSecInfo" [innerHTML]="pres.secondaryInfo"></div>
        </ion-label>
        <img slot="end" *ngIf="pres.downloaded" [ngClass]="{'unsupported': isResource && !isResourceAttachable}" src="assets/imgs/contact_conn.png" width="11" class="green-dot" />
        <!-- START ADD TO MEETING -->
        <ion-icon slot="end" *ngIf="(viewMode == presView.ADDTOMEETING || viewMode == presView.ADDTOEVENT || viewMode == presView.ADDTOACCOUNTPLAN) && !isMeetingPres && !isSelected && (!isResource? true: isResourceAttachable)" class='add-icon' name="indegene-selectors-add-icon" (click)="addPresToMeeting()"></ion-icon>
        <ion-icon slot="end" *ngIf="(viewMode == presView.ADDTOMEETING || viewMode == presView.ADDTOEVENT || viewMode == presView.ADDTOACCOUNTPLAN) && isMeetingPres && showcancelIcon" class='remove-icon' name="indegene-selectors-remove-icon" (click)="removePresFromMeeting()"></ion-icon>
        <ion-icon slot="end" *ngIf="(viewMode == presView.ADDTOMEETING || viewMode == presView.ADDTOEVENT || viewMode == presView.ADDTOACCOUNTPLAN) && !isMeetingPres && isSelected" class='checkmark-icon' name="indegene-selectors-checkmark-icon"></ion-icon>

        <!-- END ADD TO MEETING -->

        <img slot="end" *ngIf="viewMode == presView.MENU && isMobile && !pres.downloaded" src="assets/imgs/pres_download_outline.svg" class="img-download button-foreground" (click)="download()" />
        <ion-icon slot="end" *ngIf="viewMode == presView.MENU && !isResource" class="yellow-color button-foreground" [ngClass]="{'offline': isOffline && !$any(pres).favourite}" [name]="$any(pres).favourite ? 'star':'star-outline'" (click)="favourite()">
        </ion-icon>
    </ion-item>
    <ion-item-options *ngIf="isMobile && listType == 'list'" side="start">
        <!--CWD-1426-->
        <ion-button class="action-button" (click)="download()">
            <img src="assets/imgs/pres_download_outline.svg" width="20" />
        </ion-button>
        <ion-button *ngIf="!isResource" class="action-button" (click)="favourite()">
            <ion-icon *ngIf="$any(pres).favourite" class="yellow-color" name="star"></ion-icon>
            <ion-icon *ngIf="!$any(pres).favourite" class="yellow-color" name="star-outline"></ion-icon>
        </ion-button>
    </ion-item-options>
    <div class="separationLine"></div>
</ion-item-sliding>

<!-- Grid ITEM -->
<div class="grid-item" id="grid-item-wrapper" *ngIf="listType == 'grid'" [ngClass]="{'active': isActivePres, 'inactive': isOffline && !pres.downloaded}" (click)="showDetails()">
    <div slot="start" class="image-wrapper-grid" (click)="toggleIsSelected()">
        <div class="img-wrapper" [ngStyle]="{
                    'margin-left': presItemTransform.mainWrapperMarginLeft + 'px',
                    'width': presItemTransform.iframeScaleWidth + 'px',
                    'height': presItemTransform.iframeScaleHeight + 'px',
                    'margin-top': presItemTransform.mainWrapperMarginTop + 'px'
                }">

            <ion-checkbox *ngIf="!presService.isSelectMode" (click)="$event.stopPropagation()" (ngModelChange)="toggleIsPresSelected($event)" [(ngModel)]="pres.isSelected"></ion-checkbox>
            <ion-icon *ngIf="!isResource && $any(pres).favourite" class="grid-favourite" [ngClass]="{'offline': isOffline && !$any(pres).favourite}" name="star" (click)="favourite()">
            </ion-icon>

            <progress *ngIf="(isDownloading$ | async) && (isActiveDownload$ | async)" class="progress-bar" [value]="progressPer" max="100"></progress>
            <progress *ngIf="(isUnzipping$ | async) && (isActiveDownload$ | async)" class="progress-bar" [value]="progressPer" max="100"></progress>

            <img class="thumbnail-grid" io-thumbnail [src]="thumbUrl" />
            <img *ngIf="pres.downloaded" src="assets/imgs/contact_conn.png" width="14" class="green-dot" />
        </div>
    </div>
    <div class="label-grid" [ngStyle]="{
        'margin-left': presItemTransform.mainWrapperMarginLeft + 'px',
        'width': presItemTransform.iframeScaleWidth + 'px',
        'height': presItemTransform.iframeScaleHeight + 'px',
        'margin-top': presItemTransform.mainWrapperMarginTop + 'px'
        }">
        <div class="title-label"> {{pres.name}} </div>
        <div class="sub-label secondary-label" *ngIf="sortedBy == 'Last Modified Date'"> {{ getFormattedDate() }}</div>
    </div>
</div>
