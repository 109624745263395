<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="!orderSearchInput && orderListCount == 0 && filterBadgeCount == 0" [(searchText)]="orderSearchInput" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="handleSearch()"
        (onSearchCancel)="onSearchCancel()" (onFilterClick)="openOrdersFilter($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()"></ind-header-left>

    <!-- <ion-item-divider class="keyControlsArea" color="light">

    </ion-item-divider> -->
    <ion-toolbar *ngIf="!from">
        <ind-tabs [data]="tabsData" [(selectedTab)]="orderManagementService.ordersListMode" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>
<ion-content #Content [ngClass]="{'vectorimageleft': !orderSearchInput && orderListCount == 0}">
    <div>
      <ng-container *ngIf="selectedItems.length > 0">
        <ind-section-header [viewData]="selectedHeader"></ind-section-header>
        <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
      </ng-container>


        <ion-item-divider color="light normal-divider" class="list-title" sticky="true" *ngIf="!isReadOnlyMode">
            <ion-label class="title-text ion-text-capitalize">
                {{filterText}} ({{orderListCount}})
            </ion-label>
            <ion-button class="noPaddingButton" slot="end" icon-only fill="clear" (click)='sortOrdersList()'>
                {{isTeamTabActive?teamSortBy.text: sortBy.text}}
                <img class="sortIcon" src="assets/imgs/sort_with_double_arrows.svg" width="16px">
                <!--      <ion-icon class="filterIcon filterStyleHeading" name="caret-down-outline"></ion-icon>-->
            </ion-button>
        </ion-item-divider>
        <ion-list *ngIf="orderListCount > 0 && !isReadOnlyMode" class="orders-list" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}" >
            <div *ngFor="let order of _slicedFilteredOrdersList">
                <main-card [viewData]='order'></main-card>
            </div>
        </ion-list>
    </div>
    <ion-infinite-scroll *ngIf="orderListCount > 0" (ionInfinite)="doInfinite($event.detail,$event)" threshold="20%">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ng-container *ngIf="orderSearchInput && (orderListCount === 0)">
        <div *ngIf="orderSearchInput" [hidden]="filterValueSelected">
            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
        </div>
    </ng-container>
    <div class="no-data-message" *ngIf="!orderSearchInput && isTeamTabActive && !orderListCount" [hidden]="filterValueSelected">
        {{(device.isOffline?'TEAM_ORDERS_ONLINE_ONLY':'OM_NO_TEAM_ORDERS') | translate}}
    </div>

    <div class="no-data-message" *ngIf="!orderSearchInput && !isTeamTabActive && !orderListCount" [hidden]="filterValueSelected">
        {{NoOrderMessage | translate}}
    </div>
</ion-content>
<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'orders'"></footer-toolbar>
<!-- </ion-footer> -->
