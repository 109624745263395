
import { takeUntil } from 'rxjs/operators';
import { TrackService, TrackingEventNames } from './../../../services/logging/tracking.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { ActivityService } from './../../../services/activity/activity.service';
import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { IndFormFieldViewDataModel, FormFieldType } from "../../../models/indFormFieldDataModel";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { CollaboratorType, EstimatedClosedDateMandatory, EstimatedRevenueCalculationBasedOn, Opportunity, OpportunityPrimaryTraget, ProductsMandatory, ProgressStatus } from "../../../classes/opportunity-management/opportunity.class";
import { OpportunityManagementDataService } from "../../../data-services/opportunity-management/opportunity-management.data.service";
import { OpportunityManagementService } from "../../../services/opportunity-management/opportunity-management.service";
import { PopoverComponent } from '../../popover/popover';
import { RepServices } from '../../../data-services/rep/rep.services';
import { OpportunitiesListComponent } from '../opportunities-list/opportunities-list';
import { AccountOfflineService, AccountSelectedFor, AccountSelectionObservableModel } from '../../../services/account/account.offline.service';
import { DeviceService } from '../../../services/device/device.service';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Subject } from 'rxjs';
import { EventsService, EventName } from '../../../services/events/events.service';
import { OpportunityDetailsComponent } from '../opportunity-details/opportunity-details';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import _ from 'lodash';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { ContactOfflineService, ContactSelectionObservableModel } from '@omni/services/contact/contact.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { NotificationService } from '@omni/services/notification/notification.service';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { Account } from '@omni/classes/account/account.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { format, isFuture, isPast, isSameDay } from 'date-fns';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { Utility } from '@omni/utility/util';
import * as moment from'moment';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { Brand } from '@omni/classes/brand/brand.class';
import { PRODUCT_DISPLAY } from '@omni/classes/activity/surgery-order.activity.class';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { MarketingPlanManagementDataService } from '@omni/data-services/marketing-plan/marketing-plan-management.data.service';
import { MarketingPlanSelectionViewDataModel } from '@omni/models/marketing-plan-selection-page-model';
import { MarketingPlanManagementPageComponent } from '@omni/pages/marketing-plan-page/marketing-plan-page';

@Component({
  selector: 'new-opportunity',
  templateUrl: 'new-opportunity.html',
  styleUrls: ['new-opportunity.scss']
})
export class NewOpportunityComponent {

  public isLayoverPushView: boolean = false;
  public layoverViewPushedFrom: string = '';
  public newOpportunityFormFieldValues: Array<IndFormFieldViewDataModel> = [];
  public opportunityDetailsHeader: IndSectionHeaderViewDataModel;
  public stakeholderLineItemDetailsHeader: IndSectionHeaderViewDataModel;
  public opportunity: Opportunity;

  public allModelStakeholder: MainCardViewDataModel[];

  private ngDestroy$ = new Subject<boolean>();
  @Input() from: any;
  @Input() mbp: MarketingPlan;
  public pageTitle: IndPageTitleViewDataModel;
  private isrevenuesystemcalculated: boolean = true;
  private isClickedAccount: boolean = false;
  private _opportunityTypePopover: any;
  private _opportunitySubTypePopover: any;
  opportunitySubTypeOptions: any[];
  private isAccountHaveAffiliatedContacts = false;
  private autoSubjectEnabled = false;
  private isPrimaryTargetFieldEnabled: boolean = false;
  private isClickedContact: boolean = false;
  private _opportunityPrimaryTargetPopover: any;
  private isStakeholderValueEnabled:boolean = false;
  private isEstimatedClosedDateMandatory:boolean = false;
  private isEstimatedValueMandatory:boolean = false;
  private isProductMandatory:boolean = false;
  private isMarketingBusinessPlanMandatory:boolean = false;
  private isOpportunityByProductsEnabled: boolean = false;
  private isMarketingPlanEnabled = false;
  private _isClicked: { [x: string]: boolean } = {};
  public estimatedCloseDateFormField: IndDateTimeFormViewDataModel;
  public estimatedValueFormField: IndFormFieldViewDataModel;
  public productCategoryFormField: IndFormFieldViewDataModel;
  public productsFormField: IndFormFieldViewDataModel;
  public marketingPlanFormField: IndFormFieldViewDataModel;
  private products: Brand[] = [];
  private productDisplay: PRODUCT_DISPLAY = PRODUCT_DISPLAY.ALL_PRODUCTS;

  constructor(
    public activityService: ActivityService,
    public navService: NavigationService,
    private opportunityDataService: OpportunityManagementDataService,
    private opportunityService: OpportunityManagementService,
    private popoverCtrl: PopoverController,
    public repService: RepServices,
    private accountService: AccountOfflineService,
    private device: DeviceService,
    private uiService: UIService,
    private events: EventsService,
    private translate: TranslateService,
    private trackingService: TrackService,
    private authService: AuthenticationService,
    private readonly contactService: ContactOfflineService,
    private readonly notificationService: NotificationService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly surgeryOrderDataService: SurgeryOrderActivityDataService,
    private readonly brandService: BrandOfflineService,
    private readonly marketingPlansManagementOfflineService : MarketingPlansManagementOfflineService,
    private readonly marketingPlanManagementDataService: MarketingPlanManagementDataService,
  ) {

    this.opportunityService.newOpportunityObserver.pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((data: Opportunity) => {
        if (data) {
          this.opportunity = data;
          this.createStakeHolderModel();
          if(this.from && this.from == 'MarketingPlanInfo'){
            let idx = this.opportunity.marketingBusinessPlans.findIndex((plan)=> plan.ID == this.mbp.ID);
            if(idx<0) this.opportunity.marketingBusinessPlans.push(this.mbp);
          }
          this.initNewOpportunityInfoFormFields();
        }
      });

    this.events.observe('updateMandatoryField').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((field) => {
        if (field == PageName.AccountPageComponent) this.isClickedAccount = true;
        this.initNewOpportunityInfoFormFields();
      });

    this.contactService.selectedContactObserver.pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((data: ContactSelectionObservableModel) => {
        if (data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID) {
          this.opportunity.stakeholders = [];
          this.opportunity.stakeholderValue = 0;
          this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
          data.contacts.forEach(con => {
            if(con.stakeholderValue >= 0){
              this.opportunity.stakeholderValue += con.stakeholderValue;
            }
            con.stakeholderRolesList.forEach(role => {
              this.opportunity.stakeholders.push({
                ID: con.ID,
                contactId: con.ID,
                firstName: con.firstName,
                fullName: con.fullName,
                isActive: con.isActive,
                stakeholderValue: con.stakeholderValue,
                lastName: con.lastName,
                role: { roleid: role.roleid, name: role.name }
              });
            });
          })
          this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
          // if (this.opportunity.stakeholders && this.opportunity.stakeholders.length > 0) {
            this.uiService.displayLoader();
            this.opportunity.pendingPushToDynamics = true;
            this.createStakeHolderModel();
            this.initNewOpportunityInfoFormFields();
          // } 
        } 
      });

      this.accountService.selectedAccountObserver.pipe(
        takeUntil(this.ngDestroy$))
        .subscribe((data: AccountSelectionObservableModel) => {
          if (data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID) {
            this.opportunity.stakeholders = [];
            this.opportunity.stakeholderValue = 0;
            this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
            data.accounts.forEach(acc => {
              if(acc.stakeholderValue >= 0){
                this.opportunity.stakeholderValue += acc.stakeholderValue;
              }
              acc.stakeholderRolesList.forEach(role => {
                this.opportunity.stakeholders.push({
                  ID: acc.id,
                  accountId: acc.id,
                  firstName: '',
                  fullName: acc.accountName,
                  isActive: (acc.status && (acc.status===1 || acc.status===548910000))? true : false,
                  stakeholderValue: acc.stakeholderValue,
                  lastName: '',
                  role: { roleid: role.roleid, name: role.name }
                });
              });
            })
            this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
            // if (this.opportunity.stakeholders && this.opportunity.stakeholders.length > 0) {
              this.uiService.displayLoader();
              this.opportunity.pendingPushToDynamics = true;
              this.createStakeHolderModel();
              this.initNewOpportunityInfoFormFields();
            // } 
          } 
        });
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    this.isrevenuesystemcalculated = !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    this.autoSubjectEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_AUTO_SUBJECT);
    this.isPrimaryTargetFieldEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_PRIMARY_TARGET);
    this.isStakeholderValueEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE);
    this.isOpportunityByProductsEnabled  = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS);
    this.isMarketingPlanEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);

    this.products = !_.isEmpty(this.brandService?.brands) ? this.brandService?.brands : [];
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.opportunityService.newOpportunity$.next(null);
  }

  public initNewOpportunityInfoFormFields() {
    if (!this.opportunity) return;

    if(this.autoSubjectEnabled){
      this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
    }

    let enableConfirm =  (this.opportunity.accountID && this.opportunity.opportunityTypeId && (_.isEmpty(this.opportunitySubTypeOptions) || this.opportunity.opportunitySubTypeId))
    if(!this.isrevenuesystemcalculated){
      enableConfirm = (this.opportunity.accountID && this.opportunity.opportunityTypeId && (_.isEmpty(this.opportunitySubTypeOptions) || this.opportunity.opportunitySubTypeId) && (!this.isrevenuesystemcalculated && this.opportunity.stakeholders.length > 0))
    }
    if(this.isPrimaryTargetFieldEnabled){
      enableConfirm = enableConfirm && !!this.opportunity.primaryTargetValue;
    }
    if(this._isFocusAreaEnabled){
      enableConfirm = enableConfirm && !!this.opportunity.focusArea;
    }
    if(this.isEstimatedClosedDateMandatory) enableConfirm = enableConfirm && !!this.opportunity.estimatedDate;
    if(!this.isrevenuesystemcalculated && this.isEstimatedValueMandatory) enableConfirm = enableConfirm && (this.opportunity.estimatedValue && this.opportunity.estimatedValue > 0);
    if(!this.isrevenuesystemcalculated && this.isOpportunityByProductsEnabled &&this.isProductMandatory) enableConfirm = enableConfirm && !!this._currentProductsString;
    if(this.isMarketingBusinessPlanMandatory && this.isMarketingPlanEnabled) enableConfirm = enableConfirm && !_.isEmpty(this.opportunity.marketingBusinessPlans);

    let isSurgeryDataAvailable = this.isOpportunityByProductsEnabled ? !_.isEmpty(this.surgeryOrderDataService.procedureTrackerProductHierarchies) : this.surgeryOrderDataService.productHierarchies.length > 0

    const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    this.newOpportunityFormFieldValues = [
      {
        label: this.translate.instant('ACCOUNT'),
        inputText: this.opportunity.accountName,
        id: 'account-field',
        isReadOnly: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNT'),
        showArrow: true,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedAccount && _.isEmpty(this.opportunity.accountName)
      },
      {
        label: this.translate.instant('DESCRIPTION'),
        inputText: this.opportunity.opportunityName,
        id: 'desc-field',
        isDisabled: this.autoSubjectEnabled,
        placeholderLabel: this.translate.instant('DESCRIPTION'),
        showArrow: !this.autoSubjectEnabled,
        formFieldType: FormFieldType.INLINE_INPUT,
        inputValue: this.opportunity.opportunityName,
        isReadOnly: false,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      },
      {
        label: this.translate.instant('OM_OWNER'),
        inputText: this.opportunity.ownerName,
        id: 'created-by-field',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      },
      {
        label: this.translate.instant('TYPE'),
        inputText: (this.opportunity.opportunityTypename) ? this.opportunity.opportunityTypename.toString() : '',
        id: 'oportunity-type-field',
        isReadOnly: true,
        isRequired: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_TYPE'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      },
      {
        label: this.translate.instant('SUB_TYPE'),
        inputText: (this.opportunity.opportunitySubTypename) ? this.opportunity.opportunitySubTypename.toString() : '',
        id: 'oportunity-Sub-Type-field',
        isReadOnly: true,
        isRequired: true,
        isHidden: _.isEmpty(this.opportunitySubTypeOptions) ? true : false,
        customPlaceholderLabel: this.translate.instant('SELECT_SUB_TYPE'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      },
      {
        isHidden: !this._isFocusAreaEnabled,
        label: this.translate.instant('FOCUS_AREA'),
        inputText: this.opportunity.focusArea,
        inputValue: this.opportunity.focusArea,
        id: 'focus-area-field',
        isReadOnly: false,
        isDisabled: false,
        placeholderLabel:this.translate.instant('ENTER_FOCUS_AREA'),
        showArrow: true,
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      }
    ];
    if(this.isEstimatedClosedDateMandatory) {
      this.estimatedCloseDateFormField = {
        isDateField: true,
        label: this.translate.instant('ESTIMATED_CLOSE_DATE'),
        customPlaceholderLabel: this.translate.instant('SELECTE_ESTIMATED_CLOSE_DATE'),
        inputText: (this.opportunity.estimatedDate) ? format(new Date(parseInt(this.opportunity.estimatedDate)), this.dateTimeFormatsService.dateToUpper) : '',
        fromViewPage: CurViewPageType.NewOpportunity,
        id: DateTimeFieldType.EndDateField,
        isReadOnly: true,
        isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
        showArrow: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this._isClicked[DateTimeFieldType.EndDateField] && this.opportunity.estimatedDate==''
      }
    } else this.estimatedCloseDateFormField = undefined;
    if(!this.isrevenuesystemcalculated && this.isEstimatedValueMandatory) {
      this.estimatedValueFormField = {
        label: this.translate.instant('ESTIMATED_VALUE'),
        inputText: this.opportunity.estimatedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.estimatedValueFormatted) : '',
        inputType: 'number',
        inputValue: (this.opportunity.estimatedValue) ? this.opportunity.estimatedValue : 0,
        id: 'estimated-value-field',
        isReadOnly: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
        isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
        customPlaceholderLabel: this.translate.instant('ENTER_ESTIMATED_VALUE'),
        showArrow: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this._isClicked['estimated-value-field'] && this.opportunity.estimatedValue <= 0,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      }
    } else this.estimatedValueFormField = undefined;
    // if(!this.isOpportunityByProductsEnabled && !this.isrevenuesystemcalculated && this.isProductMandatory) {
    //   this.productCategoryFormField = {
    //     isHidden: this.isOpportunityByProductsEnabled || this.opportunity.isrevenuesystemcalculated,
    //     label: this.translate.instant('PRODUCT_CATEGORY'),
    //     inputText: this._currentProductCategoriesString,
    //     customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCT_CATEGORY') : this.translate.instant('SELECT_PRODUCT_CATEGORY'),
    //     id: 'product-category-field',
    //     isReadOnly: true,
    //     // isDisabled: !isSurgeryDataAvailable || (!this.opportunity.surgeryId && !this.isOpportunityByProductsEnabled) || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
    //     isDisabled: !isSurgeryDataAvailable || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
    //     // showArrow: isSurgeryDataAvailable && (!!this.opportunity.surgeryId || this.isOpportunityByProductsEnabled) || this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
    //     showArrow: isSurgeryDataAvailable && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
    //     formFieldType: FormFieldType.NEW_PAGE_SELECT,
    //     isRequired: true,
    //     errorMessage: this.activityService.getErrorMessageRequiredField(),
    //     isEmptyRequiredField: this._isClicked['product-category-field'] && _.isEmpty(this._currentProductCategoriesString),
    //     eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    //   };
    // } else this.productCategoryFormField = undefined;
    if(!this.isrevenuesystemcalculated && this.isOpportunityByProductsEnabled &&this.isProductMandatory) {
      this.productsFormField = {
        isHidden: this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts,
        label: this.translate.instant('PRODUCTS'),
        inputText: this._currentProductsString,
        customPlaceholderLabel: (!isSurgeryDataAvailable && !this.isOpportunityByProductsEnabled) ? this.translate.instant('NO_PRODUCTS') : this.translate.instant('SELECT_PRODUCTS'),
        id: 'product-field',
        isReadOnly: true,
        isDisabled: (!isSurgeryDataAvailable && !this.isOpportunityByProductsEnabled) || (this.opportunity.productCategory.length == 0 && !this.isOpportunityByProductsEnabled) && !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: ((isSurgeryDataAvailable || this.isOpportunityByProductsEnabled) && (this.opportunity.productCategory.length > 0 || this.isOpportunityByProductsEnabled) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this._isClicked['product-field'] && _.isEmpty(this._currentProductsString),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    } else this.productsFormField = undefined;
    if(this.isMarketingBusinessPlanMandatory && this.isMarketingPlanEnabled) {
      let isMarketingPlanData = true;
      let marketingPlansData = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
        plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
      });
      if (_.isEmpty(marketingPlansData)) {
        isMarketingPlanData = false;
      }
      const marketingPlans =  _.isEmpty(this.opportunity.marketingBusinessPlans) ? [] : _.orderBy(this.opportunity.marketingBusinessPlans, 'brandPlanName');
      this.marketingPlanFormField = {
        label: this.translate.instant('MARKETING_BUSINESS_PLANS'),
        inputText: _.isEmpty(marketingPlans) ? "" : marketingPlans[0].brandPlanName + (this.opportunity.marketingBusinessPlans.length > 1 ? (" +" + (this.opportunity.marketingBusinessPlans.length - 1)) : ""),
        id: 'marketing-plan-field',
        isReadOnly: true,
        placeholderLabel: isMarketingPlanData ? this.translate.instant('MARKETING_BUSINESS_PLANS') : this.translate.instant('NO_MARKETING_PLANS_EXIST'),
        showArrow: !_.isEmpty(marketingPlans) || isMarketingPlanData,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this._isClicked['marketing-plan-field'] && _.isEmpty(this.opportunity.marketingBusinessPlans),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      }
    } else this.marketingPlanFormField = undefined;
    if(this.isPrimaryTargetFieldEnabled){
      const primaryTargetFormField = {
        label: this.translate.instant('PRIMARY_TARGET'),
        inputText: (this.opportunity.primaryTargetName) ? this.opportunity.primaryTargetName.toString() : '',
        id: 'primary-target-field',
        isReadOnly: true,
        isRequired: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_PRIMARY_TARGET'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      }
      if(this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT){
        const contactFormField = {
          label: this.translate.instant('CONTACT_FORM'),
          inputText: this.opportunity.contactName,
          id: 'contact-field',
          isReadOnly: true,
          isDisabled: false,
          customPlaceholderLabel: this.translate.instant('SELECT_CONTACT'),
          showArrow: true,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isRequired: true,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField: this.isClickedContact && _.isEmpty(this.opportunity.contactName)
        }
        this.newOpportunityFormFieldValues.unshift(contactFormField);
      }
      this.newOpportunityFormFieldValues.unshift(primaryTargetFormField);
      if(!this.opportunity.primaryTargetValue){
        let contactField = this.newOpportunityFormFieldValues.find(a=> a.id == 'contact-field');
        if(contactField){
          contactField.showArrow = false;
          contactField.isDisabled = true;
        }
        let accountField = this.newOpportunityFormFieldValues.find(a=> a.id == 'account-field');
        if(accountField){
          accountField.showArrow = false;
          accountField.isDisabled = true;
        }
      }
    }

    this.opportunityDetailsHeader = {
      id: 'opportunity-details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [

      ],
    };

    this.stakeholderLineItemDetailsHeader = {
      id: 'stakeholder-line-items-header',
      title: `${this.translate.instant('STAKEHOLDERS')}/${this.translate.instant('ACCOUNTS')}`,
      doNotModifyTitleCase: true,
      isRequired: !this.isrevenuesystemcalculated,
      controls: [
        {
          id: 'stakeholder_line_item_add',
          text: this.translate.instant('ADD'),
          isDisabled: _.isEmpty(opportunityType),
        }
      ],
    }

    this.pageTitle = {
      id: 'new-opportunity',
      title: this.translate.instant('OPPORTUNITY'),
      controls: [{
        id: 'opportunity_confirm',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: !enableConfirm,
        align: "right"
      },
      {
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }],
    };

  }

  ngAfterViewInit() {
    this.opportunity = this.opportunityService.getNewOpportunityObject();
    this.opportunityService.newOpportunity$.next(this.opportunity);
    if(this.from == 'MarketingPlanInfo' && this.opportunity && this.mbp){
      this.opportunity.focusArea = this.mbp.focusArea;
    }
  }

  public onClosePage() {
    this.navService.popChildNavPageWithPageTracking();
    if (this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView) {
      this.navService.setChildNavRightPaneView(false);
    }
  }

  public onFieldClick(event) {
    if (event && event.id) {
      switch (event.id) {
        case 'account-field':
          this.handleAccountSelection(event);
          this.isClickedAccount = true;
          break;
        case 'desc-field':
          this.handleEditDescription(event);
          break;
        case 'oportunity-type-field':
          this.handleOpportunityTypeSelection(event);
          break;
        case 'oportunity-Sub-Type-field':
          this.handleOpportunitySubTypeSelection(event);
          break;
        case 'primary-target-field':
          this.handlePrimaryTargetField(event);
          break;
        case 'contact-field':
          this.handleContactSelection(event);
          this.isClickedContact = true;
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'opportunity_confirm':
        this.handleConfirmOpportunity();
        break;
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  // private get _currentProductCategoriesString(): string {
  //   let str = '';
  //   let currentProducts = this.opportunity.productCategory;
  //   if (currentProducts) {
  //       if (currentProducts.length === 1) {
  //           str = `${currentProducts[0].productCategoryName}`;
  //       } else if (currentProducts.length >= 2) {
  //           currentProducts.sort((a, b): number => {
  //               if (a.productCategoryName > b.productCategoryName) return 1;
  //               if (a.productCategoryName < b.productCategoryName) return -1;
  //               return 0;
  //           });
  //           str = `${currentProducts[0].productCategoryName} +${currentProducts.length - 1}`;
  //       }
  //   }
  //   return str;
  // }

  private get _currentProductsString(): string {
    let str = '';
    let currentProducts = this.opportunity.products.filter(a=> !a.isDeleted);
    if (currentProducts) {
        if (currentProducts.length === 1) {
            str = `${currentProducts[0].productName}`;
        } else if (currentProducts.length >= 2) {
            currentProducts.sort((a, b): number => {
                if (a.productName > b.productName) return 1;
                if (a.productName < b.productName) return -1;
                return 0;
            });
            str = `${currentProducts[0].productName} +${currentProducts.length - 1}`;
        }
    }
    return str;
  }

  private async handleAccountSelection(event) {
    if(this.opportunity.primaryTargetValue && this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT && this.opportunity.contactID){
      let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.contactService.getContactByID(this.opportunity.contactID)]);
      if(!(affiliatedAccounts && affiliatedAccounts.length > 0)){
        this.notificationService.notify(this.translate.instant('NO_AFFILIATED_ACCOUNTS_FOR_CONTACT'), 'Opportunity-Details', 'top', 'danger');
        return;
      }
    }
    this.accountService.selectedFor = AccountSelectedFor.OPPORTUNITY_SELECTION;
    this.accountService.accessedAccountListFrom = PageName.NewOpportunityComponent;
    this.accountService.isMarketingBusinessPlanInvoked = this.from == 'MarketingPlanInfo' || false;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW, type: 'PushedAccountSelectionView' }, PageName.NewOpportunityComponent, { animate: false }, null);
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (eventName == 'input_value_confirm' && id) {
      switch (id) {
        case 'desc-field':
          this.handleEditDescription(event);
          break;
        case 'focus-area-field':
          this.handleFocusAreaField(event);
          break;
        case 'estimated-value-field':
          this.handleEditEstimatedValue(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    } else if(id){
      switch (id) {
        case 'product-category-field':
          this.openProductCategorySelect();
          break;
        case 'product-field':
          this.openProductSelect();
          break;
        case 'marketing-plan-field':
          this.openMarketingPlans(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
    this._isClicked[id] = true;
  }

  private handleEditDescription(data): void {
    this.trackingService.tracking('EnterDescription', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    if (data && data.target.value != this.opportunity.opportunityName) {
      if (data.target.value == '') this.opportunity.opportunityName = this.opportunity.opportunityName;
      else this.opportunity.opportunityName = data.target.value;
      this.initNewOpportunityInfoFormFields();
    }
  }

  private handleConfirmOpportunity(): void {
    this.trackingService.tracking('SelectConfirm', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    if (!this.opportunity.accountID) return;
    if (!this.opportunity.opportunityName || this.opportunity.opportunityName == '') {
      this.opportunity.opportunityName = 'Opportunity';
    }
    const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
    if (opportunityType && opportunityType.estimatedRevenueAutoCalculated && opportunityType.estimatedRevenueCalculationBasedOn == EstimatedRevenueCalculationBasedOn.PROCEDUREANDPRODUCTS) {
      this.opportunity.isestimatedrevenuebasedonprocedureandproducts = true;
    }
    this.opportunity.businessUnitId = this.authService.user.xBusinessUnitId;
    this.opportunity.businessUnitFormatted = this.authService.user.businessUnitName;
    this.opportunity.pendingPushToDynamics = true;
    this.opportunity.isrevenuesystemcalculated = this.isrevenuesystemcalculated;
    if (opportunityType['indskr_nostageprogressafterdays'] != null) {
      this.opportunity.indskr_progressstatus = ProgressStatus.IN_PROGRESS;
    }
    if(this.from == 'MarketingPlanInfo'){
      //this.opportunity.focusArea = this.mbp.focusArea;
      this.opportunity.primarySpecialtyId = this.mbp.primarySpecialtyId;
      this.opportunity.primarySpecialtyName = this.mbp.primarySpecialtyName;
      this.opportunity.subSpecialtyId = this.mbp.subSpecialtyId;
      this.opportunity.subSpecialtyName = this.mbp.subSpecialtyName;
      // this.opportunity.marketingBusinessPlans.push(this.mbp);
    }

    this.uiService.displayLoader();
    this.opportunityDataService.createOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.opportunity]).then(async data => {
      this.events.publish(EventName.NEWOPPORTUNITYCONFIRMED, this.opportunity);
      let account = this.accountService.getAccountById(this.opportunity.accountID);
      
      if (!this.opportunity.ID.includes('offline') && this.opportunity.appointmentId && this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
        (this.activityService.selectedActivity as AppointmentActivity).regardingObjectId = this.opportunity.ID;
        (this.activityService.selectedActivity as AppointmentActivity).regardingObjectIdValue = this.opportunity.opportunityName;
        await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
      }
      if (!this.device.isOffline) await this.opportunityDataService.getOpportunitiesData(false, account.id, false, true);
      
      if(this.from == 'MarketingPlanInfo') {
        this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent,
          PageName.OpportunityManagementPage, { from: 'MarketingPlanInfo', opportunity: this.opportunity });
          this.events.publish(EventName.OPPORTUNITY_CREATED_FROM_MARKETING_PLAN, this.opportunity);
      }else {
        this.navService.setChildNavRoot(OpportunitiesListComponent, PageName.OpportunitiesListComponent, PageName.OpportunityManagementPage, { from: 'OpportunityTool', account: account });
        this.navService.setChildNavRightPaneView(true);
        this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent,
          PageName.OpportunityManagementPage, { from: 'OpportunityTool', opportunity: this.opportunity });
      }
      if(this.isMarketingBusinessPlanMandatory && this.isMarketingPlanEnabled) {
        this.opportunity.marketingBusinessPlans = this.opportunity.marketingBusinessPlans.filter(mbp => !mbp['isDeleted']);
        this.opportunity.marketingBusinessPlans = _.orderBy(this.opportunity.marketingBusinessPlans, 'brandPlanName');
        this.opportunity.marketingBusinessPlans.forEach(async oppMbp => {
          const index = this.marketingPlansManagementOfflineService.marketingPlans.findIndex(mbp => mbp.ID == oppMbp.ID);
          if (index > -1 && !this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.some(acc => acc.id == this.opportunity.accountID)) {
            const payload = { accounts: [{ "id": this.opportunity.accountID }] };
            await this.marketingPlanManagementDataService.saveMarketPlan(payload, this.marketingPlansManagementOfflineService.marketingPlans[index].ID);
            this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.push({ id: this.opportunity.accountID, name: this.opportunity.accountName, statecode: 1 });
            oppMbp.accounts = this.marketingPlansManagementOfflineService.marketingPlans[index].accounts;
          }
        })
      }
      
      this.uiService.dismissLoader();
    }).catch(err => {
      // To Do error handling
      console.log(err);
      this.uiService.dismissLoader();
    })

  }

  private async handleOpportunityTypeSelection(data) {
    let opportunityTypeOptions = this.opportunityService.opportunityTypes;
    if (Array.isArray(opportunityTypeOptions) && opportunityTypeOptions.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Opportunity-Type-Select',
        data: opportunityTypeOptions.map(option => {
          let obj = {
            title: option.indskr_name,
            id: option.indskr_opportunitytypeid,
            isSelected: false,
          };
          if (this.opportunity.opportunityTypeId && obj.id == this.opportunity.opportunityTypeId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this._opportunityTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunityTypePopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunityTypeId) {
          this.opportunity.opportunityTypeId = data.selectedItems[0].id;
          this.opportunity.opportunityTypename = data.selectedItems[0].title;
          this.opportunity.opportunitySubTypeId = '';
          this.opportunity.opportunitySubTypename = '';
          const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
          if (opportunityType.singlestakeholder) {
            if (this.opportunity.stakeholders.length > 1) {
              this.opportunity.stakeholders = [];
              this.allModelStakeholder = [];
            }
          }
          // Set mandatory flags 
          this.isEstimatedClosedDateMandatory = opportunityType.indskr_estimatedcloseddatemandatory && opportunityType.indskr_estimatedcloseddatemandatory == EstimatedClosedDateMandatory.MANDATORY ? true: false;
          this.isEstimatedValueMandatory = (opportunityType.indskr_estimatedvaluemandatory && opportunityType.indskr_estimatedvaluemandatory === true && (opportunityType.estimatedRevenueAutoCalculated === false || !opportunityType.estimatedRevenueAutoCalculated)) ? true : false;
          this.isProductMandatory =  (opportunityType.indskr_productsmandatory && opportunityType.indskr_productsmandatory == ProductsMandatory.MANDATORY && (opportunityType.estimatedRevenueAutoCalculated === false || !opportunityType.estimatedRevenueAutoCalculated)) ? true : false;
          this.isMarketingBusinessPlanMandatory = opportunityType.indskr_marketingbusinessplanmandatory && opportunityType.indskr_marketingbusinessplanmandatory === true ? true : false; 
          if(this.isProductMandatory) {
            if (opportunityType.indskr_productdisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
              this.productDisplay = PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY;
              this.products = this.products.filter(p => p.indskr_newproductintroduction);
            } else if (opportunityType?.indskr_productdisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
              this.productDisplay = PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS;
              this.products = this.products.filter(p => !p.indskr_newproductintroduction);
            }
          }
          this.fetchOpportunitiesSubTypes();
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunityTypePopover = undefined;
      });
      this._opportunityTypePopover.present();
    }
  }

  private async fetchOpportunitiesSubTypes() {
    const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
    if (opportunityType) {
      this.opportunitySubTypeOptions = opportunityType.opportunitySubTypes;
    }
  }

  private async handleOpportunitySubTypeSelection(data) {
    if (Array.isArray(this.opportunitySubTypeOptions) && this.opportunitySubTypeOptions.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'oportunity-Sub-Type-field',
        data: this.opportunitySubTypeOptions.map(option => {
          let obj = {
            title: option.indskr_name,
            id: option.indskr_opportunitysubtypeid,
            isSelected: false,
          };
          if (this.opportunity.opportunitySubTypeId && obj.id == this.opportunity.opportunitySubTypeId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this._opportunitySubTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunitySubTypePopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunitySubTypeId) {
          this.opportunity.opportunitySubTypeId = data.selectedItems[0].id;
          this.opportunity.opportunitySubTypename = data.selectedItems[0].title;
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunitySubTypePopover = undefined;
      });
      this._opportunitySubTypePopover.present();
    }
  }

  async checkAffliatedContacts() {
    if (!this.opportunity) return;

    if (this.opportunity.accountID) {
      let affiliatedContacts: Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(this.opportunity.accountID)]);
      if (affiliatedContacts.length !== 0){
        this.isAccountHaveAffiliatedContacts = true;
      } else{
        this.isAccountHaveAffiliatedContacts = false;
      }
    }
  }

  async handleStakeholderField() {
    // await this.checkAffliatedContacts();
    // if (!this.isAccountHaveAffiliatedContacts) {
    //   this.notificationService.notify(this.translate.instant('NO_AFFILIATED_STAKEHOLDERS_FOR_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
    //   return;
    // }
    this.trackingService.tracking('SelectStakeholder', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let passedData = {
      type: 'PushedContactSelectionView',
      contactListData:
      {
        for: 'OpportunityStakeholderSelection',
        opportunity: this.opportunity
      }
    };
    // this.contactSelectionSubscriptionFlag = true;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.isSchedulerInvoked = false;
    const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (opportunityType.singlestakeholder && opportunityType.singlestakeholder === true) {
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    }
    this.contactService.accessedContactListFrom = PageName.NewOpportunityComponent;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.OpportunityManagementPage, { animate: false }, null);
  }

  createStakeHolderModel() {
    this.allModelStakeholder = [];

    let aggrStakeholder = [];
    this.opportunity.stakeholders.forEach(sh => {
      const stakeholder = aggrStakeholder.find(con => con.ID === sh.ID);
      if (!stakeholder) {
        aggrStakeholder.push(_.cloneDeep(sh));
      } else {
        stakeholder.role.name += ", " + sh.role.name;
      }
    });

    this.allModelStakeholder = aggrStakeholder.map((f) => {
      return {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.fullName + (this.isStakeholderValueEnabled && f.stakeholderValue ? ' ('+(this.opportunity.transactionCurrencySymbol?this.opportunity.transactionCurrencySymbol:'')+f.stakeholderValue+')' : ''),
        secondaryTextLeft: f.role.name,
        showEndIcon: false,
        endIconType: 'indegene-selectors-remove-icon',
        endIconCssClass: 'remove-icon',
        mainItemCssClass: 'selector-item',
        clickHandler: (id: string, event, specificTarget) => {}
      };
    });
    setTimeout(() => {
      this.uiService.dismissLoader();
    }, 200);
  }

  private async handlePrimaryTargetField(data){
    if (this.isPrimaryTargetFieldEnabled) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'oportunity-primary-target-field',
        data: [
          {
            title: this.translate.instant('ACCOUNT'),
            id: OpportunityPrimaryTraget.ACCOUNT.toString(),
            isSelected: this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.ACCOUNT,
          },
          {
            title: this.translate.instant('CONTACT_FORM'),
            id: OpportunityPrimaryTraget.CONTACT.toString(),
            isSelected: this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT,
          },
        ],
      };
      this._opportunityPrimaryTargetPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunityPrimaryTargetPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.primaryTargetValue) {
          this.opportunity.primaryTargetValue = data.selectedItems[0].id;
          this.opportunity.primaryTargetName = data.selectedItems[0].title;
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunityPrimaryTargetPopover = undefined;
      });
      this._opportunityPrimaryTargetPopover.present();
    }
  }

  private async handleContactSelection(event) {
    await this.checkAffliatedContacts();
    if (!this.isAccountHaveAffiliatedContacts && this.opportunity.accountID) {
      this.notificationService.notify(this.translate.instant('NO_AFFILIATED_CONTACTS_FOR_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
      return;
    }
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.NewOpportunityComponent;
    let passedData = {
      type: 'PushedContactSelectionView', 
      callbackEvent: (data: any) => this._handleContactComponentCallback(data),
      contactListData:
      {
          for:'NewOpportunityContactSelection',
          opportunity: this.opportunity
      }
    };
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.KOLDetailsComponent);
  }

  private async _handleContactComponentCallback(data) {
    if (data?.isDone) {
      if (data && data.selectedItem && data.selectedItem.ID != this.opportunity.contactID) {
        this.opportunity.contactID = data.selectedItem.ID;
        this.opportunity.contactName = data.selectedItem.fullName;    
        let affiliatedAccounts:Account[] = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.contactService.getContactByID(this.opportunity.contactID)]);   
        if(!this.opportunity.accountID){
          if(affiliatedAccounts && affiliatedAccounts.length == 1){
            this.opportunity.accountID = affiliatedAccounts[0].id;
            this.opportunity.accountName = affiliatedAccounts[0].accountName;  
          }
        }else {
          if(!(affiliatedAccounts && affiliatedAccounts.some(a=> a.id == this.opportunity.accountID))){
            this.opportunity.accountID = '';
            this.opportunity.accountName = '';  
          }
        }
        this.initNewOpportunityInfoFormFields();
      }else if(data && data.clearValue){
        this.opportunity.contactID = '';
        this.opportunity.contactName = '';
        this.initNewOpportunityInfoFormFields();
      }
      
    }
  }

  private get _isFocusAreaEnabled():boolean {
    let flag:boolean = false;
    if(this.opportunity.opportunityTypeId){
      const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
      if (opportunityType.indskr_focusarea) {
        flag = true;
      }
    }
    return flag;
  }

  private async handleFocusAreaField(data) {
    if (data && data.target.value != this.opportunity.focusArea) {
      this.opportunity.focusArea = data.target.value;
      this.opportunity.pendingPushToDynamics = true;
      const OppName = this.opportunity.opportunityName;
      if (this.autoSubjectEnabled) {
        this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
      }
      this.initNewOpportunityInfoFormFields();
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;  
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.OpportunityInfo,
          startDateTimeValue: !_.isEmpty(this.opportunity.estimatedDate) ? (new Date(parseInt(this.opportunity.estimatedDate))).toISOString() : moment(new Date()).format(),
          endDateTimeValue: !_.isEmpty(this.opportunity.estimatedDate) ? (new Date(parseInt(this.opportunity.estimatedDate))).toISOString() : moment(new Date()).format(),
        },
        cssClass: "datetime-popover"
      }
    );
    this._isClicked[DateTimeFieldType.EndDateField] = true;
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        const selectedEndDate = new Date(data.data.endTime);
        selectedEndDate.setHours(0,0,0,0);
        const endDate: string = !_.isEmpty(this.opportunity.estimatedDate) ? (new Date(parseInt(this.opportunity.estimatedDate))).toISOString() : '';
        if (!isSameDay(selectedEndDate, endDate)) {
          this.opportunity.estimatedDate = selectedEndDate.getTime().toString();
          if (this.autoSubjectEnabled) {
            this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
          }
          this.opportunity.pendingPushToDynamics = true;
        //   this.uiService.displayLoader();
        //   await this.opportunityDataService.updateOpportunity({
        //     onDynamics:!this.device.isOffline,
        //     onLocalDatabase:true,
        //     onLocalCopy:true, 
        //     operationDetail: {code: 'updatecloseddate', message: 'Update Opportunity Close Date'}},
        //     [this.opportunity]).then(success=>{
        //       this.uiService.dismissLoader();
        //     }).catch(async error => {
        //       this.uiService.dismissLoader();
        //     });
        // }
        }
      }
      if (!_.isEmpty(this.opportunity.estimatedDate)) this._isClicked[DateTimeFieldType.EndDateField] = false;
      this.initNewOpportunityInfoFormFields();
    });
    popover.present();
  }

  private async handleEditEstimatedValue (data){
    this._isClicked['estimated-value-field'] = true;
    if (data && parseInt(data.target.value) != this.opportunity.estimatedValue && parseInt(data.target.value) >= 0 && !data.target.value.includes('.')) {
      let previousEstimatedValue = this.opportunity.estimatedValue;
      let newEstimatedValue = parseInt(data.target.value);
      this.opportunity.estimatedValue = parseInt(data.target.value);
      this.opportunity.estimatedValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.estimatedValue);
      this.opportunity.pendingPushToDynamics = true;
      // this.handleUpdates.emit();
      // this.uiService.displayLoader();
      // await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true , operationDetail: { code: 'updateestimatedvalue', message : 'Update Opportunity Estimated Value'} }, [this.opportunity]).then(success => {
      //   this.uiService.dismissLoader();
      // }).catch(async error => {
      //   this.uiService.dismissLoader();
      // });
    }
    this.initNewOpportunityInfoFormFields();
  }

  private openProductCategorySelect(): void {
    let dataSet = [];
    if(this.isOpportunityByProductsEnabled){
      dataSet = this.opportunityService.productsHierarchyByPosition;
    }else{
      dataSet = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.opportunity.surgeryId).productCategories;
      if (!_.isEmpty(dataSet) && this.productDisplay != PRODUCT_DISPLAY.ALL_PRODUCTS) {
        if (this.productDisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
          dataSet = dataSet.filter(pc => pc.indskr_newproductintroduction && pc.products.some(pr => pr.indskr_newproductintroduction));
        } else {
          dataSet = dataSet.filter(pc => !pc.indskr_newproductintroduction && pc.products.some(pr => !pr.indskr_newproductintroduction));
        }
      }
    }
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCT_CATEGORY'),
      dividerTitle:this.translate.instant('ALL_PRODUCT_CATEGORY'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('PRODUCT_CATEGORY'),
      hideAllItemsList: false, //this.isReadOnlyMode,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'productcategory'),
      searchHandler:(text: string) => this._handleProductsComponentSearch(text,'productcategory'),
      data: dataSet.map(pro => {
        let isSelected = false;
        if(this.opportunity && this.opportunity.productCategory && this.opportunity.productCategory.some(sopro => (sopro.productCategoryId === pro.productCategoryId))){
          isSelected = true;
        }
        return {
          id: pro.productCategoryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
          endIconCssClass: isSelected?'checkmark-icon':'add-icon',
          primaryTextRight: pro.productCategoryName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.opportunity.ownerID
        };
      }),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
  }

  private openProductSelect(): void {
    let selectedProdCategories;
    if (this.isOpportunityByProductsEnabled) {
      selectedProdCategories = [];
      /*
      this.opportunity.productCategory.forEach(category => {
        let foundCategory = this.opportunityService.productsHierarchyByPosition.find(a => a.productCategoryId == category.productCategoryId);
        if (foundCategory) {
          selectedProdCategories.push(foundCategory);
        }
      })
      */
    } else {
      selectedProdCategories = this.surgeryOrderDataService.productHierarchies
        .find(p => p.surgeryId == this.opportunity.surgeryId)
        ?.productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
    }
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'product'),
      data: this.getProductsSelectionData(selectedProdCategories)
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: !this.isOpportunityByProductsEnabled }, PageName.MainToolTemplateComponent);
  }

  private _handleProductsComponentSearch(text: string, formField:string): string[] {
    let ids: Array<string> = [];
    switch (formField){
      case 'product':
        if (this.isOpportunityByProductsEnabled) {
          ids = this.products.filter(product => {
            return product.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(prod => prod.ID);
        } else {
          let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
            .find(p => p.surgeryId == this.opportunity.surgeryId)
            .productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
          let selectedProdCategoriesProducts = [];
          selectedProdCategories.forEach(pc => {
            selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
          })
          if (text.length >= 1) {
            ids = selectedProdCategoriesProducts.filter(product => {
              return product.productName.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(prod => prod.productId);
          }
        }
        break;
    }
    return ids;
  }

  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName:string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (this.opportunity.status == 'Open' && data && data.isDone) {
            let operationDetailCode: string;
            if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
                switch (fieldName){
                  case 'surgery':
                    break;
                  case 'productcategory':
                    this.opportunity.productCategory = [];
                      let applicableProducts = [];
                      data.selectedItems.forEach(item => {
                        let category;
                        if(this.isOpportunityByProductsEnabled){
                          category = this.opportunityService.productsHierarchyByPosition.find(p=>p.productCategoryId == item.id);
                        }else{
                          category = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId == this.opportunity.surgeryId).productCategories
                          .find(c=>c.productCategoryId == item.id)
                        }
                        if(category){
                          this.opportunity.productCategory.push({
                            productCategoryId:category.productCategoryId,
                            productCategoryName:category.productCategoryName
                          })
                          applicableProducts = applicableProducts.concat(category.products);
                        }
                      });
                      _.remove(this.opportunity.products,(prod)=>{
                        return !applicableProducts.some(o=>o.productId == prod.productID && o.productCategoryId == prod.productCategoryID);
                      });
                      // operationDetailCode = 'updateproductcategory';
                      // this._updateOpportunity(operationDetailCode);
                    break;
                  case 'product':
                    let selectedProdCategories = [];
                    let updatedCategoriesFlag: boolean = false;
                    if (!this.isOpportunityByProductsEnabled) {
                      selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                        .find(p => p.surgeryId == this.opportunity.surgeryId)
                        .productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
                    }

                    let selectedProdCategoriesProducts = [];
                    selectedProdCategories.forEach(pc => {
                      selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
                    })
                    let alreadySelectedProducts = this.opportunity.products;
                    this.opportunity.products = [];
                    data.selectedItems.forEach(item => {
                      let foundProduct = selectedProdCategoriesProducts.find(prod => prod.productId == item.id && prod.productCategoryId == item.parentID);
                      if (foundProduct) {
                        let alreadySelectedProd = alreadySelectedProducts.find(p => p.productID == item.id && p.productCategoryID == item.parentID);
                        if (alreadySelectedProd) {
                          this.opportunity.products.push(alreadySelectedProd);
                        }
                        else {
                          this.opportunity.products.push({
                            productID: foundProduct.productId,
                            productName: foundProduct.productName,
                            quantity: 1,
                            uomid: foundProduct.uomid,
                            isDeleted: false,
                            unitPrice: 0,
                            unitPriceFormatted: "0$",
                            total: 1,
                            totalFormatted: "1",
                            productCategoryID: foundProduct.productCategoryId,
                            productCategoryName: foundProduct.productCategoryName,
                          });
                        }
                      } else if (this.isOpportunityByProductsEnabled) {
                        let alreadySelectedProd = alreadySelectedProducts.find(p => p.productID == item.id);
                        if (alreadySelectedProd) {
                          this.opportunity.products.push(alreadySelectedProd);
                        }
                        else {
                          foundProduct = this.products.find(p => p.ID == item['id']);
                          if (foundProduct) {
                            this.opportunity.products.push({
                              productID: foundProduct.ID,
                              productName: foundProduct.name,
                              quantity: 1,
                              uomid: foundProduct.defaultuomid,
                              isDeleted: false,
                              unitPrice: 0,
                              unitPriceFormatted: "0$",
                              total: 1,
                              totalFormatted: "1",
                            });
                          }
                        }
                      }
                    });
                    if (this.autoSubjectEnabled) {
                      this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                    }
                    this.initNewOpportunityInfoFormFields();
                    // operationDetailCode = 'updateproducts';
                    // if (updatedCategoriesFlag) {
                    //   operationDetailCode = 'updateproductcategory';
                    // }
                    // this._updateOpportunity(operationDetailCode);
                    break;
                }

            }
            else if(data.selectedItems.length ==0){
              switch (fieldName){
                case 'surgery':
                  break;
                case 'productcategory':
                  this.opportunity.productCategory = [];
                  this.opportunity.products = [];
                  break;
                case 'product':
                  this.opportunity.products = [];
                  operationDetailCode = 'updateproducts';
                  if (this.autoSubjectEnabled) {
                    this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                  }
                  // this._updateOpportunity(operationDetailCode);
                  break;
              }
            }
            this.initNewOpportunityInfoFormFields();
        }
    }
  }

  getProductsSelectionData(selectedProdCategories) {
    let data = [];
    if (this.isOpportunityByProductsEnabled) {
      const brands = _.cloneDeep(_.sortBy(this.products, [function (o) { return o.name; }]));
      brands.forEach(prod => {
        let isSelected = false;
        if (this.opportunity && this.opportunity.products
          && this.opportunity.products.some(sopro => (sopro.productID === prod.ID))) {
          isSelected = true;
        }
        data.push({
          id: prod.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: prod.name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.opportunity.ownerID
        })
      })
    } else {
      selectedProdCategories.forEach(pro => {
        data.push({
          id: pro.productCategoryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: false,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: false,
          primaryTextRight: pro.productCategoryName,
          showArrow: false,
          eventOwnerId: this.opportunity.ownerID
        });
        let categoryProducts = !_.isEmpty(pro.products) ? pro.products : [];
        if (this.productDisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
          categoryProducts = categoryProducts.filter(p => p.indskr_newproductintroduction);
        } else if (this.productDisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
          categoryProducts = categoryProducts.filter(p => !p.indskr_newproductintroduction);
        }
        categoryProducts.forEach(prod => {
          let isSelected = false;
          if (this.opportunity && this.opportunity.products
            && this.opportunity.products.some(sopro => (sopro.productID === prod.productId && sopro.productCategoryID === prod.productCategoryId))) {
            isSelected = true;
          }
          data.push({
            id: prod.productId,
            parentID: prod.productCategoryId,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: prod.productName,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.opportunity.ownerID
          });
        })
      })
    }
    return data;
  }

  private async openMarketingPlans(data) {
    this.trackingService.tracking('SelectMarketingPlan', TrackingEventNames.OPPORTUNITYMANAGEMENT);
    if (!this.opportunity.accountID) {
      this.notificationService.notify(this.translate.instant('FOLLOW_UP_NOTIFY_SELECT_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
    } else {
      let marketingPlans = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
        plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
      });
      if (!_.isEmpty(marketingPlans)) {
        const marketingPlansSelectionViewData: MarketingPlanSelectionViewDataModel = {
          isReadOnlyMode: false,
          selectedMarketingPlans: this.opportunity.marketingBusinessPlans,
          disableDoneButtonInOffline: false,
          viewMode: ComponentViewMode.ADDNEW,
          callbackFromSelectionComponent: (event: string, newSelectedMarketingPlans: MarketingPlan[], data: any) => this._handledMarketingPlansSelection(event, newSelectedMarketingPlans, data),
        };
        this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { viewData: marketingPlansSelectionViewData, from: PageName.OpportunityInfoComponent }, PageName.MarketingPlanManagementPageComponent);
      }
    }
  }

  private async _handledMarketingPlansSelection(ev, selectedMarketingPlans, data) {
    if (ev && ev == 'DONEBUTTONCLICK') {
      let marketingPlans = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
        plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
      });
      const data = selectedMarketingPlans;
      // if (data && data.selectedItems) {
      if (_.isEmpty(selectedMarketingPlans)) this.opportunity.marketingBusinessPlans.forEach(mbp => mbp['isDeleted'] = true);
      else {
        selectedMarketingPlans.forEach(item => {
          const mbp = marketingPlans.find(mp => mp.ID == item.ID);
          if (mbp) this.opportunity.marketingBusinessPlans.push(mbp);
        });
        this.opportunity.marketingBusinessPlans.forEach(mbp => {
          if (!selectedMarketingPlans.map(item => item.ID).includes(mbp.ID)) mbp['isDeleted'] = true;
        })
      }
      this.opportunity.marketingBusinessPlans = this.opportunity.marketingBusinessPlans.filter(mbp => !mbp['isDeleted']);
      this.opportunity.marketingBusinessPlans = _.uniqBy(this.opportunity.marketingBusinessPlans, 'ID');
      this.opportunity.pendingPushToDynamics = true;
      this.initNewOpportunityInfoFormFields();
      // await this.uiService.displayLoader();
      // await this.opportunityDataService.updateOpportunity({
      //   onDynamics: !this.device.isOffline,
      //   onLocalCopy: true,
      //   onLocalDatabase: true,
      //   operationDetail: {
      //     code: 'updateopportunitymarketingplan',
      //     message: 'Update Opportunity Marketing Plan',
      //   }
      // }, [this.opportunity]).then(succ => {
      //   this.opportunity.marketingBusinessPlans = this.opportunity.marketingBusinessPlans.filter(mbp => !mbp['isDeleted']);
      //   this.opportunity.marketingBusinessPlans = _.orderBy(this.opportunity.marketingBusinessPlans, 'brandPlanName');
      //   this.opportunity.marketingBusinessPlans.forEach(async oppMbp => {
      //     const index = this.marketingPlansManagementOfflineService.marketingPlans.findIndex(mbp => mbp.ID == oppMbp.ID);
      //     if (index > -1 && !this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.some(acc => acc.id == this.opportunity.accountID)) {
      //       const payload = { accounts: [{ "id": this.opportunity.accountID }] };
      //       await this.marketingPlanManagementDataService.saveMarketPlan(payload, this.marketingPlansManagementOfflineService.marketingPlans[index].ID);
      //       this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.push({ id: this.opportunity.accountID, name: this.opportunity.accountName, statecode: 1 });
      //       oppMbp.accounts = this.marketingPlansManagementOfflineService.marketingPlans[index].accounts;
      //     }
      //     await this.uiService.dismissLoader();
      //   })
      // }).catch(async error => {
      //   console.error("Failed to save marketing plan for opportunities: ", error);
      //   await this.uiService.dismissLoader();
      // });
      // }
    }
  }

}
