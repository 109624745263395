<div class="carousel-label">
  <ion-grid>
    <ion-row no-padding>
      <ion-col size="6" class="ion-text-left">
        <div no-padding class="participant-count">
          {{("REFERENCES" | translate)}}
          <span>
            <ion-spinner name="dots" *ngIf="isDataLoading"></ion-spinner>
          </span>
        </div>
      </ion-col>
      <ion-col  size="6" class="ion-text-right">
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div #carousel class="slides-container">
  <!-- <ion-slides
    #Slides
    [options]="{
      slidesPerView: 'auto'
    }"
    class="swiper-wrapper"
    (ionSlideDrag)=removeSwiperLock()
  >
    <ion-slide
      class="carousel-slide"
      (click)="referenceClicked(reference)"
      *ngFor="let reference of references; index as i"
    >
      <div class="reference-carousel-card">
        <div class="reference-card-title">
          {{('REFERENCE' | translate) + ' ' + (i + 1)}}
          <span class="open-icon">
            <img [src]="reference.isShared
                        ? 'assets/imgs/action_button_open_in_new_window_active.svg'
                        : 'assets/imgs/action_button_open_in_new_window.svg'" />
          </span>
        </div>
        <div class="reference-card-reference-name">
          {{reference.indskr_displayname || reference['_indskr_document_value_Formatted']}}
        </div>
        <div
          class="reference-card-reference-secondary-info"
          *ngIf="reference.indskr_documentpagenumber"
        >
          {{ 'PAGE_X' | translate : { page_number: reference.indskr_documentpagenumber } }}
        </div>
      </div>
    </ion-slide>
  </ion-slides> -->

  <swiper-container
  #Slides
  class="swiper-wrapper"
>
  <swiper-slide
    class="carousel-slide"
    (click)="referenceClicked(reference)"
    *ngFor="let reference of references; index as i"
  >
    <div class="reference-carousel-card">
      <div class="reference-card-title">
        {{('REFERENCE' | translate) + ' ' + (i + 1)}}
        <span class="open-icon">
          <img [src]="reference.isShared
                      ? 'assets/imgs/action_button_open_in_new_window_active.svg'
                      : 'assets/imgs/action_button_open_in_new_window.svg'" />
        </span>
      </div>
      <div class="reference-card-reference-name">
        {{reference.indskr_displayname || reference['_indskr_document_value_Formatted']}}
      </div>
      <div
        class="reference-card-reference-secondary-info"
        *ngIf="reference.indskr_documentpagenumber"
      >
        {{ 'PAGE_X' | translate : { page_number: reference.indskr_documentpagenumber } }}
      </div>
    </div>
  </swiper-slide>
</swiper-container>
</div>
