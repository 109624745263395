<ion-toolbar class="page-title" *ngIf="viewData.showCancelAndDoneBtn || viewData.label">
  <ion-buttons slot="start" *ngIf="viewData.showCancelAndDoneBtn">
      <ion-button large fill="clear" (click)='onCancelClick()'>
          {{ (viewData.cancelButtonText ? viewData.cancelButtonText : 'CANCEL') | translate}}
      </ion-button>
  </ion-buttons>
  <!-- <ion-title class="ion-text-center overflowHiddenAndEllipsis dropdown-title"> {{(viewData) ? viewData.pageTitle : ''}} </ion-title> -->
  <ion-buttons slot="end" *ngIf="viewData.showCancelAndDoneBtn">
      <ion-button [disabled]="noChanges" icon-end large (click)="onDoneClick()">
          {{ (viewData.doneButtonText ? viewData.doneButtonText : 'DONE') | translate}}
      </ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-item>
  <ion-label *ngIf="viewData.label" position="stacked">{{viewData.label}}</ion-label>
  <!-- [accept]="inputFieldAttributes.accept || undefined" -->
  <!-- [size]="inputFieldAttributes.size || undefined" -->
  <ion-input
    [autocapitalize]="inputFieldAttributes.autocapitalize || 'off'"
    [autocomplete]="inputFieldAttributes.autocomplete || 'off'"
    [autocorrect]="inputFieldAttributes.autocorrect || 'off'"
    [autofocus]="inputFieldAttributes.autofocus || false"
    [clearInput]="inputFieldAttributes.clearInput || false"
    [clearOnEdit]="inputFieldAttributes.clearOnEdit || undefined"
    [color]="inputFieldAttributes.color || undefined"
    [debounce]="inputFieldAttributes.debounce || 300"
    [disabled]="inputFieldAttributes.disabled || false"
    [enterkeyhint]="inputFieldAttributes.enterkeyhint || undefined"
    [inputmode]="inputFieldAttributes.inputmode || undefined"
    [max]="inputFieldAttributes.max || undefined"
    [maxlength]="inputFieldAttributes.maxlength || undefined"
    [min]="inputFieldAttributes.min || undefined"
    [minlength]="inputFieldAttributes.minlength || undefined"
    [multiple]="inputFieldAttributes.multiple || undefined"
    [name]="inputFieldAttributes.name || undefined"
    [pattern]="inputFieldAttributes.pattern || undefined"
    [placeholder]="inputFieldAttributes.placeholder || undefined"
    [readonly]="inputFieldAttributes.readonly || false"
    [required]="inputFieldAttributes.required || false"
    [step]="inputFieldAttributes.step || undefined"
    [type]="inputFieldAttributes.type || 'text'"
    value="{{initialValue}}"
    [(ngModel)]="inputValue"
    (ionInput)="onChange($event)"
  >
  </ion-input>
  <ion-icon slot="end" class="ion-float-right arrow-icon chevron-back-outline" name="chevron-back-outline"></ion-icon>
</ion-item>
