<div class="carousel-label">
  <ion-grid>
    <ion-row no-padding>
      <ion-col size="6" class="ion-text-left">
        <div no-padding class="participant-count">
          {{("ALL_PARTICIPANTS_VIDEO" | translate) + " (" + (participantCount) + ")"}}
        </div>
      </ion-col>
      <ion-col size="6" class="ion-text-right">
        <div *ngIf="!hideContentToggle">
          <ion-button class="content-btn" fill="clear" [disabled]="false" (click)="onButtonClick.emit()">
            <span class="section-header-button-text">
              {{'CONTENT' | translate}}
            </span>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div class="slides-container">
  <div *ngIf="tencentEnabled" class="OT_Subscribers">
    <ng-container *ngIf="tencentService.isInitilized">
      <tencent-participant *ngIf="tencentService.localStreamConfig" [tencentConfig]="tencentService.localStreamConfig"></tencent-participant>
      <tencent-participant [tencentConfig]="config" *ngFor="let config of tencentService.remoteStreamConfigList"></tencent-participant>
    </ng-container>
    <ng-container *ngIf="!tencentService.isInitilized">
      <div class="wrapper">
        <ion-spinner name="dots"></ion-spinner>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!tencentEnabled"  class="OT_Subscribers">
    <ng-container *ngIf="opentok.session">
      <ot-publisher type="camera" [audio]="opentok.audioActive" [video]="opentok.videoActive"></ot-publisher>
      <ot-subscriber *ngFor="let stream of opentok.streams" [stream]="stream"></ot-subscriber>
    </ng-container>
    <ng-container *ngIf="!opentok.session">
      <div class="wrapper">
        <ion-spinner name="dots"></ion-spinner>
      </div>
    </ng-container>
  </div>
</div>
