<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" 
                   [hideFilterButton]="false" 
                   [(searchText)]="surveySearchText" 
                   (onControlClick)="onPageTitleControlClick($event)" 
                   (onSearchInput)="handleSearch()" 
                   (onFilterClick)="openFilterMenu($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()">
   </ind-header-left>

</ion-header>
<ion-content [ngClass]="{'vectorimageleft': surveySearchText.length === 0 && filteredSurveyListToDisplay?.length === 0}">
  <div class="survey-statistics-grid">
    <div *ngFor="let s of statistics" class="survey-statistics-item {{s.id}}">
      <div class="survey-statistics-title">
        <label>{{s.label}}</label>
      </div>
      <div class="survey-statistics-value">{{s.value}}</div>
    </div>
  </div>
  <ind-section-header [viewData]="surveyListSectionHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
  <div *ngIf="surveySearchText.length === 0 && !isFilteredSurvey && filteredSurveyListToDisplay.length === 0" class="no-data-message">
      {{ ('NO' | translate) + ' ' + ('SURVEY' | translate | lowercase) }}
  </div>
  <ind-no-search-result *ngIf="!searching && isFilteredSurvey && filteredSurveyListToDisplay.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  <ind-no-search-result *ngIf="searching && searchedSurveyListToDisplay.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
  
  <ion-list *ngIf="!searching && filteredSurveyListToDisplay" class='survey-list' [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
      <main-card *ngFor="let survey of filteredSurveyListToDisplay" [viewData]="survey" [id]="survey.id"></main-card>
  </ion-list>

  <ion-list *ngIf="searching && searchedSurveyListToDisplay" class='survey-list' [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
    <main-card *ngFor="let survey of searchedSurveyListToDisplay" [viewData]="survey" [id]="survey.id"></main-card>
</ion-list>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="'surveyList'"></footer-toolbar>
