import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {DeviceService} from '@omni/services/device/device.service';
import {AuthenticationService} from '@omni/services/authentication.service';


interface ButtonConfig {
  id: string;
  icon: string;
  isDisabled: boolean;
  align: 'left' | 'right';
}

const DefaultTitleWidth = '100%';

/**
 * The PageTitleComponent is a reusable component designed to display a page title along with optional controls. It is typically used to represent the title of a page within an application interface, providing users with essential information about the current page they are viewing.
 * Title Display: The component prominently displays the title of the page, allowing users to quickly identify the purpose or content of the page.
 * Default Back Button: Users have the option to display a default back button, enabling easy navigation within the application.
 * Tooltip Enablement: It supports the enablement of tooltips for the title, enhancing user experience by providing additional contextual information on hover.
 * Control Integration: The component accommodates the integration of additional controls, such as buttons or icons, allowing for extended functionality or actions related to the page.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-page-title',
  templateUrl: 'ind-page-title.html',
  styleUrls:['ind-page-title.scss']
})
export class IndPageTitleComponent implements OnInit, OnChanges, AfterViewInit {
  readonly buttonsFilter = {
    left: { align: 'left' as const },
    right: { align: 'right' as const }
  };
  readonly defaultTitleWidth = DefaultTitleWidth;

  @Input() viewData: any;
  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: { titleWidth: string } = { titleWidth: DefaultTitleWidth };

  @Output() onControlClick = new EventEmitter<string>();
  @Output() onFilterClick = new EventEmitter<string>();
  enableBackButton = false;
  readonly backButton: ButtonConfig = {
    id: 'close',
    icon: 'chevron-back-outline',
    isDisabled: false,
    align: 'left'
  };

  rightControls: ButtonConfig[] = [];
  leftControls: ButtonConfig[] = [];
  isAndroid: boolean = false;
  isHCPFacing = false;

  constructor(public device: DeviceService, public authService: AuthenticationService, private _cd: ChangeDetectorRef) { }

  backgroundUploadInProgress$ = this.device.isBackgroundUploadInProgressObservable;
  syncInProgress$ = this.device.syncInProgress$;

  ngOnInit() {
    this.isAndroid = this.device.isAndroid();
    if (this.viewData && !this.viewData.controls) this.viewData.controls = [];
    if (this.viewData && this.viewData.isHCPFacing) this.isHCPFacing = true;
    this.enableBackButton = this.isDefaultBackButtonReq();
    this.leftControls = this.viewData?.controls?.filter((btn) => btn.align === 'left') || [];
    this.rightControls = this.viewData?.controls?.filter((btn) => btn.align === 'right') || [];
  }

  // Fix to show tooltip on Mobile devices and hide when clicked on Back button //
  ngAfterViewInit() {
    var element = document.getElementsByTagName("tooltip"), index;

    for (index = element.length - 1; index >= 0; index--) {
        element[index].parentNode.removeChild(element[index]);
    }
  }
  // Fix to show tooltip on Mobile devices and hide when clicked on Back button //

  ngOnChanges(){
    if(this.viewData && !this.viewData.controls)
      this.viewData.controls = [];
    this.enableBackButton = this.isDefaultBackButtonReq();
    this.leftControls = this.viewData?.controls?.filter((btn)=> btn.align === 'left') || [];
    this.rightControls = this.viewData?.controls?.filter((btn)=> btn.align === 'right') || [];
  }

  private isDefaultBackButtonReq(): boolean {
    if(this.viewData && !this.viewData.controls) {
      this.viewData.controls = [];
    }
    if (this.viewData && this.viewData.id == 'veeva-widget') return true;
    if (this.viewData?.displayDefaultBackButton) return true;
    return (this.viewData && this.viewData.id !== "new-activity-page-title"
    && this.viewData.id !== "capture-allocation-order"
    && this.viewData.id !== "presentation-fullscreen"
    && this.viewData.id !== 'new-insights-plus-header'
    && this.viewData.id !== 'new-change-req-page-title'
    && this.viewData.id !== 'general-insights-page-title'
    && this.viewData.id !== 'customer-insight'
    && this.viewData.id !== 'customer-insight-journey'
    && this.viewData.id !== 'customer-relationship'
    && this.viewData.id !== 'dynamic-form-page-title'
    && this.viewData.id !== 'section-specific'
    && this.viewData.id !== 'new-manage-list-title'
    && this.viewData.id !== 'photo-capture-viewer-page-title'
    && this.viewData.controls.filter(control => control.align === this.backButton.align).length === 0)
      && (window.innerWidth < 620);
  }

  public onButtonClick(button) {
    if (button.id && !button.isDisabled) {
      this.onControlClick.emit(button.id);
    }
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.enableBackButton = this.isDefaultBackButtonReq();
  }

  public filterClicked(ev) {
    if (this.viewData.isFilter) {
      this.onFilterClick.emit();
    }
  }

}
