<ion-header>
  <ind-page-title [viewData]='{id:"consent-details", title: pageTitle, controls: backButton, isHCPFacing: true}' (onControlClick)='closepage()'></ind-page-title>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row justify-content-center>
      <ion-col *ngIf="!device.isNativeApp" size="2" class="colSpace"></ion-col>
      <ion-col class="orderCaptureScreenWrapper"
              [ngClass]="{'inMeetingConsent':consentService.inMeetingConsentFlow,
                          'inMeetingRemoteConsent':consentService.consentRemoteFlow,
                          'padding-ios': device.deviceFlags.ios}">
        <ion-list>
          <ng-container *ngIf="authenticationService.user.isProductConsent">
            <ind-section-header [viewData]="productsHeaderModel"></ind-section-header>
            <ion-item
              *ngFor="
                let product of (consentTerm.products
                  | orderBy: 'indskr_productname':true)| slice:0:limitProds
              "
            >
              <ion-checkbox
                color="primary"
                mode="ios"
                (ionChange)="checkIfAtleastOneProductSelected($event)"
                [(ngModel)]="product.isChecked"
                [checked]="product.isChecked"
                [disabled]="consentTerm.isSavedConsent"
                labelPlacement="end"
                justify="start"
              >
              {{ product.indskr_productname }}
              </ion-checkbox>
              <!-- <ion-label item-content>{{ product.indskr_productname }}</ion-label> -->
            </ion-item>
            <ion-item *ngIf="consentTerm.products && consentTerm.products.length > 3">
              <div class="expProd"
                   (click)="expandCollapse()">{{ (!isExpanded ? 'LENGTHPRODS' : 'SHOW_LESS') | translate : {lengthlimitprods: (consentTerm.products.length - limitProds)} }}</div>
            </ion-item>
          </ng-container>

          <div *ngFor="let channel of consentTerm.channels | orderBy: 'indskr_consentType':true">
            <ion-item-divider [ngClass]="{ 'hr-separator': !isExpanded }"
                              color="light">{{getChannelName(channel)}}
              <ion-button class="section-header-button-text" [hidden]="hideButton(channel.activityType)"
                          fill="clear" slot="end" (click)="addContactMetaData(channel)"
                          [disabled]="disableAddButtonOffline(channel.activityType) || disableAddButtonForOneKeyContact(channel.activityType)">{{ displayAddButtonText(channel.activityType) }}</ion-button>
            </ion-item-divider>
            <ion-item class="no-data-text" *ngIf="channel.values && channel.values.length == 0">
              {{displayNoValues(channel)}}
            </ion-item>
            <ng-container *ngIf="channel.values">
              <ion-item *ngFor="let chValue of channel.values">
                <ion-checkbox
                  color="primary"
                  mode="ios"
                  [(ngModel)]="chValue.isChecked"
                  [checked]="chValue.isChecked"
                  [disabled]="consentTerm.isSavedConsent || (chValue['disable'] && chValue['disable'] === true) || chValue.block"
                  (ionChange)="checkIfAtleastOneChannelValueSelected($event)"
                  labelPlacement="end"
                  justify="start"
                >
                {{ chValue.value }}
                </ion-checkbox>
                <!-- <ion-label item-content>{{ chValue.value }}</ion-label> -->
              </ion-item>
            </ng-container>
          </div>
          <ind-section-header [viewData]="termsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
          <ion-item class='consent-terms'>
            <div id="termBodyContainer" [ngClass]="isViewLess?'view-less':'view-all'" *ngIf="termBodyView" [innerHTML]="termBodyView" (click)="openUrl($event)"></div>
            <div id="termBodyContainer" *ngIf="!termBodyView"><br></div>
            <!-- <div
              [innerHTML]="termBodyView"
              class="consent-terms"
            ></div> -->
          </ion-item>
          <ind-section-header [viewData]="signatureHeaderModel"></ind-section-header>
          <ion-item *ngIf="!this.consentService.consentRemoteFlow">
            <ion-label class="agreeterms">{{ consentTerm.indskr_agreementmessage ||('I_AGREE_ON_TERMS' | translate)}}</ion-label>
            <ion-toggle
            slot="end"
              [disabled]="consentTerm.isSavedConsent || checkAllDisabled()"
              [(ngModel)]="consentTerm.isAcceptedTerms"
            ></ion-toggle>
          </ion-item>
          <ion-item *ngIf="isPaperConsentEnabled && consentTerm.indskr_signaturerequired != signaturerequired.NO">
            <ion-label class="paperterms">{{ 'UPLOAD_PAPER_CONSENT' | translate }}</ion-label>
            <ion-toggle
            slot="end"
              [disabled]="consentTerm.isSavedConsent || !consentTerm.isAcceptedTerms || checkAllDisabled()"
              [(ngModel)]="consentTerm.isPaperConsent" (ionChange)="togglePaperConsent()"
            ></ion-toggle>
          </ion-item>
          <ion-item *ngIf="consentTerm.isSavedConsent || !consentTerm.isPaperConsent">
            <div *ngIf="!consentTerm.isSavedConsent && consentTerm.indskr_signaturerequired == signaturerequired.NO" class="submitBtn" text-center>
              <a [ngClass]="{'disabled':(!consentTerm.isAcceptedTerms),'forceDisabled':consentService.consentRemoteFlow && (remoteConnectionLost || device.isOffline)}"
                (click)="openConfirmationPopup()" class="ion-text-center consentconfirmation"
              >{{ 'CONSENT_CONFIRMATION' | translate}}</a>
            </div>
            <ion-col
              *ngIf="(consentTerm.indskr_signaturerequired == signaturerequired.YES || consentTerm.indskr_signaturerequired == signaturerequired.OPTIONAL) && !consentTerm.isSavedConsent"
              class="signatureImageContainer"
              [ngClass]="{'disabled':(!consentTerm.isAcceptedTerms)}">
              <div>
                <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag"/>
              </div>
              <ion-col>
                <ion-row>
                  <p style="display: inline" [ngClass]="{'forceDisabled':consentService.consentRemoteFlow && (remoteConnectionLost || device.isOffline)}"
                      class="ion-text-center signatureWrapper" (click)="launchSignaturePad()">
                    {{(!this.consentService.consentRemoteFlow ? 'CONSENT_TAP_TO_SIGN' : 'CAPTURE_REMOTE_SIGN') | translate}}
                  </p>
                  <div *ngIf="consentTerm.indskr_signaturerequired == signaturerequired.OPTIONAL">
                    <br>
                    <br>
                  </div>
                  <p style="display: inline" *ngIf="consentTerm.indskr_signaturerequired == signaturerequired.OPTIONAL"
                      [ngClass]="{'forceDisabled':consentService.consentRemoteFlow && (remoteConnectionLost || device.isOffline)}"
                      class="ion-text-center consentconfirmation" (click)="openConfirmationPopup()">
                    {{'CONSENT_CONFIRMATION' | translate}}
                  </p>
                </ion-row>
              </ion-col>
            </ion-col>
            <div *ngIf="consentTerm.isSavedConsent && consentTerm.indskr_signature" class="signatureImageContainer">
              <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag"/>
              <div class="capturedImage signatureWrapper ion-text-center">
                <img [src]="consentTerm.indskr_signature" width="100" height="100"/>
              </div>
            </div>
          </ion-item>
          <ng-container *ngIf="consentTerm.isPaperConsent">
            <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
            </ind-section-header>
            <ind-text-area *ngIf="!consentTerm.isSavedConsent" id="notesInput" [placeHolder]="" [readonly]="true"
              [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000"
              (indChange)="notesChanged($event)" [value]="tempNoteText" (removeAttachment)="removeAttachment($event)" [rows]="2"
              [cols]="10"> </ind-text-area>
            <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)" id="file-input">
            <io-note *ngFor="let note of consentTerm.annotations" [isNoteAttachmentEnabled]="isPaperConsentEnabled"
              [inputNote]="note" [editable]="isNoteControlsEnabled(note)" [deletable]="isNoteControlsEnabled(note)"
              (saveNoteOut)="updateNote($event)">
            </io-note>
            <div *ngIf="!consentTerm.isSavedConsent" class="submitBtn" text-center>
              <a [ngClass]="{'disabled':(!consentTerm.isAcceptedTerms || !isSaveNotesEnabled),'forceDisabled':consentService.consentRemoteFlow && (remoteConnectionLost || device.isOffline)}"
                (click)="savePaperConsent()" class="ion-text-center consentconfirmation"
              >{{ 'CONSENT_CONFIRMATION' | translate}}</a>
            </div>
          </ng-container>
        </ion-list>
      </ion-col>
      <ion-col *ngIf="!device.isNativeApp" size="2" class="colSpace"></ion-col>
    </ion-row>
  </ion-grid>
  <div slot="fixed" *ngIf="consentService.consentTab === 'ConsentCapture' && !remoteSignatureFlowComplete"
       class="fixedContent">
    <div class="gen-terms">
      <ion-row *ngIf="generatedTerms.length > 0">
        <ion-col *ngIf="!device.isNativeApp" size="2" class="colSpace"></ion-col>
        <ion-col style="display: inline-flex;">
          <div class="content-list" *ngFor="let term of generatedTerms | orderBy: 'indskr_consentTermName':true" (click)="openConsentDetails(term)">
            <div style="width: 100px;height: 70px;" [ngClass]="{ selectedTerm: term.isSelectedTerm, generatedTerm: !term.isSelectedTerm }">
              <img class="signatureImg" src="assets/imgs/consent-signature.svg" />
              <div class="text-bottom">{{ term.indskr_consentTermName }}&nbsp;<img *ngIf="term.isSavedConsent" src="assets/imgs/approved_consent.png" width="8"/></div>
            </div>
          </div>
        </ion-col>
        <ion-col *ngIf="!device.isNativeApp" size="2" class="colSpace"></ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
<!-- <ion-footer *ngIf="!this.consentService.consentRemoteFlow"> -->
  <footer-toolbar *ngIf="!this.consentService.inMeetingConsentFlow" [footerView]="'detailView'" [selectedView]="'ConsentDetails'"></footer-toolbar>
<!-- </ion-footer> -->
