<ion-grid no-padding [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'child-nav-active':'child-nav-inactive'">
  <ion-row class="split-pane"
    [ngClass]="{ 'details-active': uiService.showRightPane && activityService.selectedActivity}">
    <ion-col class="left-pane">
      <ion-header>
        <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="report.statuscode !== 1"
          [(searchText)]="searchTerm" (onControlClick)="onHeaderControlClick($event)"
          (onSearchInput)="getItems($event)" (onFilterClick)="openFilter($event)"></ind-header-left>
      </ion-header>

      <ion-content no-padding [ngClass]="{
        'openCoaching': report.statuscode === 1}">
        <ng-container *ngIf="selectedActivities.length > 0">
          <ind-section-header
            [viewData]="{id: 'report-header', title: translate.instant('SELECTED_CAP') + ' (' + selectedActivities.length + ')' }">
          </ind-section-header>
          <ion-list scrollY="true" *ngIf="coachingReportService.coachingViewtype === coachingViewType.FROM_TOOL">
            <ion-item class="activity-item" class="selector-item" tappable *ngFor="let activity of selectedActivities"
              (click)="openActivityDetails(activity);"
              [ngClass]="{'itemSelected':activity.indskr_activityid === activityService?.selectedActivity?.ID}">
              <ion-label>
                <coaching-activity-item [activity]="activity"></coaching-activity-item>
              </ion-label>
              <ion-icon class='remove-icon' name="indegene-selectors-remove-icon"
                *ngIf="report.statuscode === 1 && !device.isOffline" slot="end"
                (click)="removeSelectedActivity(activity)">
              </ion-icon>
            </ion-item>
          </ion-list>
          <ion-list scrollY="true" *ngIf="coachingReportService.coachingViewtype !== coachingViewType.FROM_TOOL">
            <ion-item class="activity-item" *ngFor="let activity of selectedActivities">
              <ion-label>
                <coaching-activity-item [activity]="activity"></coaching-activity-item>
              </ion-label>
            </ion-item>
          </ion-list>
        </ng-container>
        <ng-container *ngIf="report.statuscode === 1 && !device.isOffline">
          <ind-section-header
            [viewData]="{id: 'report-header', title: searchTerm.length === 0 ? report.created_for : translate.instant('AL_RESULTS') + ' (' + allActivities.length + ')' }">
          </ind-section-header>
          <div class="no-data-message" *ngIf="allActivities.length == 0 && !searchTerm">{{'NO_ACTIVITIES' | translate}}
          </div>
          <ind-no-search-result *ngIf="allActivities.length === 0 && searchTerm"
            [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
          <ion-list *ngIf="allActivities.length > 0">
            <ion-item class="selector-item" tappable
              *ngFor="let activity of allActivities | orderBy:'indskr_scheduledstart':false; let last=last;"
              [ngClass]="{'itemSelected':activity.indskr_activityid === activityService?.selectedActivity?.ID, 'search-last-result': last}">
              <ion-label>
                <coaching-activity-item [activity]="activity" (click)="openActivityDetails(activity);">
                </coaching-activity-item>
              </ion-label>
              <ion-icon *ngIf="!activity.selected" slot="end" class='add-icon' name="indegene-selectors-add-icon"
                (click)="addSelectedActivity(activity)"></ion-icon>
              <ion-icon *ngIf="activity.selected" slot="end" class='checkmark-icon'
                name="indegene-selectors-checkmark-icon"></ion-icon>
            </ion-item>
          </ion-list>
        </ng-container>
        <ng-container *ngIf="report.statuscode === 1 && device.isOffline">
          <div class="no-data-message">{{'NO_ACTIVITIES' | translate}}</div>
        </ng-container>
      </ion-content>
      <!-- <ion-footer> -->
      <footer-toolbar [footerView]="'masterView'" [selectedView]="'CoachingActivities'"></footer-toolbar>
      <!-- </ion-footer> -->
    </ion-col>
    <ion-col class="right-pane">
      <ion-nav #coachingactivitiesrightpane></ion-nav>
      <!-- <nothing-selected-view
        *ngIf="!coachingReportService.activityClick || (uiService.showRightPane && uiService.activeView !== 'coachingDetails' && !activityService.selectedActivity)">
      </nothing-selected-view>
      <activities-details-pane
        *ngIf="uiService.showRightPane && activityService.selectedActivity && uiService.activeView === 'Appointment'">
      </activities-details-pane>
      <phone-call-details *ngIf="uiService.showRightPane && activityService?.selectedActivity && uiService.activeView === 'PhoneCall'"></phone-call-details> -->
    </ion-col>
  </ion-row>
</ion-grid>