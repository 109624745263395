<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="!searchInput && listCount == 0" [(searchText)]="searchInput" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="handleSearch()" (onSearchCancel)="onSearchCancel()" [hideFilterButton]="true"></ind-header-left>
  <ion-toolbar>
      <ind-tabs [data]="tabsData" [(selectedTab)]="kolStatusListMode" (selectedTabChange)="segmentChanged($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content #Content [ngClass]="{'vectorimageleft': !searchInput && listCount == 0}">
  <div>
    <ind-section-header [viewData]="listHeader"></ind-section-header>
    <ion-list *ngIf="listCount > 0" class="kol-status-list" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}" >
      <div *ngFor="let item of filteredViewItemsList">
          <main-card [viewData]='item'></main-card>
      </div>
  </ion-list>
  </div>
  <ng-container *ngIf="searchInput && (listCount === 0)">
    <div *ngIf="searchInput">
        <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </div>
  </ng-container>
  <div class="no-data-message" *ngIf="!searchInput && isTeamTabActive && !listCount">
      {{(device.isOffline?'APPROVALS_ONLINE_ONLY':'NO_APPROVALS') | translate}}
  </div>

  <div class="no-data-message" *ngIf="!searchInput && !isTeamTabActive && !listCount">
      {{noItemsMessage | translate}}
  </div>
</ion-content>
<footer-toolbar [footerView]="'masterView'" [selectedView]="'contacts'"></footer-toolbar>
