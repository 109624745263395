import { Component, HostListener } from '@angular/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '@omni/services/authentication.service';

@Component({
  selector: 'go-app-home[base-page]',
  templateUrl: './go-app-home.component.html',
  styleUrl: './go-app-home.component.scss'
})
export class GoAppHomeComponent {
  isExpanded = false;
  public isMobileView: boolean = false;
  public hasBaselineEnabled: boolean = false;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.isMobileView = window.innerWidth <= 768;
    this.hasBaselineEnabled = this.authService.hasFeatureAction(FeatureActionsMap.BASELINE);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobileView = window.innerWidth <= 768;
  }

  onSidebarToggle() {
    this.isExpanded = !this.isExpanded;
  }
}
