<ion-item-divider sticky='true' class="keyControlsArea" *ngIf="viewData && !viewData.isHidden" color="light">
  <div class="key-container"  #searchResults>
    <ion-button  [disabled]='button.isDisabled' *ngFor="let button of viewData.controls" class="ion-text-nowrap"
      [ngStyle]="{'display': (button.isHidden ? 'none' : 'block')}" slot="start" fill="clear"
      (click)="onButtonClick(button,$event)" id="{{button.id}}">
      {{button.text}}
      <img *ngIf="button.showImage" class="sortIcon" src="{{button.imageSRC}}" width="16px" alt="button">
    </ion-button>
  </div>
  <ion-button *ngIf="scrollEnabled" slot="start" fill="clear" [disabled]="disbaledLeftArrow" (click)="scrollLeft()">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-button>
  <ion-button *ngIf="scrollEnabled" slot="end" fill="clear" [disabled]="disbledRightArrow" (click)="scrollRight()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>

</ion-item-divider>
