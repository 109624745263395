import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CovisitorAccess } from '@omni/classes/activity/appointment.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { Channel } from '@omni/classes/consent/channel.class';
import { ConsentTerm } from '@omni/classes/consent/consent-term.class';
import { ContactConsent } from '@omni/classes/consent/contact-consent.class';
import { Product } from '@omni/classes/consent/product.class';
import { ConsentDetailsComponent } from '@omni/components/contact/consent-details/consent-details';
import { CurViewPageType, DateTimeFieldType } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { ConsentTermGenerateService } from '@omni/services/consent/consent-term-generate.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';
import _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'surgery-order-oce-tracking',
  templateUrl: './surgery-order-oce-tracking.component.html',
  styleUrls: ['./surgery-order-oce-tracking.component.scss'],
})
export class SurgeryOrderOceTrackingComponent implements OnInit {
  @Input() currentSurgeryOrder: SurgeryOrderActivity;

  procedureStatsSectionTitle: IndSectionHeaderViewDataModel;
  cartoStatsSectionTitle: IndSectionHeaderViewDataModel;

  // procedural stats fields
  bwRepArrivalsField: IndDateTimeFormViewDataModel;
  ptInRoomField: IndDateTimeFormViewDataModel;
  ptReadyField: IndDateTimeFormViewDataModel;
  procStartField: IndDateTimeFormViewDataModel;
  transseptalAccessField: IndDateTimeFormViewDataModel;
  mappingCompleteField: IndDateTimeFormViewDataModel;
  procedureCompleteField: IndDateTimeFormViewDataModel;
  ptOutOfRoomField: IndDateTimeFormViewDataModel;
  bwRepLeavesField: IndDateTimeFormViewDataModel;

  mappingCompleteFluorotimeMintutesField: IndDateTimeFormViewDataModel;
  mappingCompleteFluorotimeSecondsField: IndDateTimeFormViewDataModel;
  transseptalAcessMintutesFluorotimeField: IndDateTimeFormViewDataModel;
  transseptalAcessSecondsFluorotimeField: IndDateTimeFormViewDataModel;

  procedureCompleteMintutesFluorotimeField: IndDateTimeFormViewDataModel;
  procedureCompleteSecondsFluorotimeField: IndDateTimeFormViewDataModel;

  // carto stats fields
  ablPointsField: IndFormFieldViewDataModel;
  totalmappingPointsField: IndFormFieldViewDataModel;
  averageRfPowerWattsField: IndFormFieldViewDataModel;
  posteriorRightField: IndFormFieldViewDataModel;
  posteriorLeftField: IndFormFieldViewDataModel;
  interiorLeftFields: IndFormFieldViewDataModel;
  interiorRightField: IndFormFieldViewDataModel;
  anteriorLeftField: IndFormFieldViewDataModel;
  anteriorRightField: IndFormFieldViewDataModel;
  roofLeftField: IndFormFieldViewDataModel;
  roofRightField: IndFormFieldViewDataModel;
  superiorLeftField: IndFormFieldViewDataModel;
  superiorRightField: IndFormFieldViewDataModel;
  ridgeField: IndFormFieldViewDataModel;
  fluidDeliveredField: IndFormFieldViewDataModel;
  cTMergedField: IndFormFieldViewDataModel;

  isCartoStatsEnabled = false;
  generatedTerms: ConsentTerm[];
  private selectedContactConsents: ContactConsent;
  public activeConsents = []
  ngDestroy$: any = new Subject<boolean>();
  consent: ConsentTerm;
  isReadonly = false;
  isCovisitor = false;
  covisitorAccess;

  constructor(
    private uiService: UIService,
    public translate: TranslateService,
    private deviceSerivce: DeviceService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private authService: AuthenticationService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private consentTermGenerateService: ConsentTermGenerateService,
    private navService: NavigationService,
    private consentService: ConsentService,
    private contactService: ContactOfflineService,
    private brandOfflineService: BrandOfflineService,
    private eventService: EventsService,
    private popoverCtrl: PopoverController,
  ) { }

  async ngOnInit() {
    this.isCartoStatsEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_consentcheck'];
    this.isCovisitor = this.currentSurgeryOrder.coVisitors?.some((coVisitors) => coVisitors.userId === this.authService.user.systemUserID);;
    this.covisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonprocedurelog'];

    this.isReadonly = this.authService.user.xSystemUserID !== this.currentSurgeryOrder.ownerId && (this.isCovisitor && this.covisitorAccess !== CovisitorAccess.FULL_ACCESS);
    this.initializeProcedureStatsSectionitle();
    this.initializeCartoStatsSectionTitle();
    this.initializeFields();
    if (this.currentSurgeryOrder.customers) {
      this.consentService.allActiveConsentSubject.subscribe((consents: ContactConsent[]) => {
        if (consents.length) {
          if (this.currentSurgeryOrder.customers) {
            this.currentSurgeryOrder.customers.forEach(customerID => {
              this.selectedContactConsents = consents.filter((consent: ContactConsent) =>
                consent.indskr_contactid.toString() === customerID.ID.toString()
              )[0];
            })
          }
        }
      });
    }

    if (this.selectedContactConsents) {
      await this.getAllActiveConsentsForProcedureLog();
      this.initializeCartoStatsSectionTitle();
    }
    this.eventService.observe('update-active-consents-procedureLog').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(async () => {
        this.activeConsents = [];
        await this.getAllActiveConsentsForProcedureLog();
        this.initializeCartoStatsSectionTitle();
      });
  }

  async getAllActiveConsentsForProcedureLog() {
    let allActiveconsnets = !this.deviceSerivce.isOffline ? this.selectedContactConsents.activeConsents.filter(consent => !consent.isOfflineSaved) : this.selectedContactConsents.activeConsents.filter(consent => consent.isOfflineSaved);
    allActiveconsnets.forEach(consentAC => {
      if (consentAC.consentActivity) {
        let consent = consentAC.consentActivity
        let activeConsents = consent.filter(el => el.indskr_consentTypeName == "procedure log")
        if (activeConsents.length > 0) {
          this.activeConsents.push(activeConsents)
        }
      } else if (consentAC.channels) {
        let consent = consentAC.channels
        let activeConsents = consent.filter(el => el.indskr_consentType == "procedure log")
        if (activeConsents.length > 0) {
          this.activeConsents.push(activeConsents)
        }
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  initializeFields() {
    // procedure stats
    this.initializeptReadyField();
    this.initializeptInRoomField();
    this.initializeprocStartField();
    this.initializeBwRepArrivalsField();
    this.initializetransseptalAccessField();
    this.initializetransseptalAcessFluorotimeMintutesField();
    this.initializetransseptalAccessField();
    this.initializemappingCompleteField();
    this.initializemappingCompleteFluorotimeMinutesField();
    this.initializemappingCompleteFluorotimeSecondsField();
    this.initializeprocedureCompleteField();
    this.initializeprocedureCompleteFluorotimeMintutesField();
    this.initializeptOutOfRoomField();
    this.initializebwRepLeavesField();
    this.initializetransseptalAcessFluorotimeSecondsField();
    this.initializeprocedureCompleteFluorotimeSecondsField();

    // carto stats
    this.initializeablPointsField();
    this.initializetotalmappingPointsField();
    this.initializeaverageRfPowerWattsField();
    this.initializeposteriorRightField();
    this.initializeposteriorLeftField();
    this.initializeinteriorLeftFields();
    this.initializeinteriorRightField();
    this.initializeanteriorLeftField();
    this.initializeanteriorRightField();
    this.initializeroofLeftField();
    this.initializeroofRightField();
    this.initializesuperiorLeftField();
    this.initializesuperiorRightField();
    this.initializeridgeField();
    this.initializefluidDeliveredField();
    this.initializecTMergedField();
  }

  initializeProcedureStatsSectionitle() {
    this.procedureStatsSectionTitle = {
      id: 'procedure-stats-header',
      title: this.translate.instant('PROCEDURAL_STATS'),
      subtitle: `Clock Time (HH:MM)`,
      controls: [],
    };
  }

  initializeCartoStatsSectionTitle() {
    let buttons = [];
    if (this.activeConsents.length == 0 && this.currentSurgeryOrder.customers.length > 0) {
      buttons.push(
        {
          id: "pluse-icon",
          iconClass: 'pluse-icon',
          isDisabled: this.isReadonly
        })
    } else if (this.activeConsents.length > 0 && this.currentSurgeryOrder.customers.length > 0) {
      buttons.push(
        {
          id: "consent-lebal",
          text: this.translate.instant('CONSENT'),
          iconClass: 'carto-checkbox',
          isDisabled: this.isReadonly
        })
    }
    this.cartoStatsSectionTitle = {
      id: 'carto-stats-header',
      title: this.translate.instant('CARTO_STATS'),
      controls: buttons
    };
  }

  initializeBwRepArrivalsField() {
    this.bwRepArrivalsField = this.initField('BW_REP_ARRIVIES', 'SELECT_TIME', this.currentSurgeryOrder.indskr_bwreparrives);
  }

  initializeptInRoomField() {
    this.ptInRoomField = this.initField('PT_IN_ROOM', 'SELECT_TIME', this.currentSurgeryOrder.indskr_ptinroom);
  }

  initializeptReadyField() {
    this.ptReadyField = this.initField('PT_READY', 'SELECT_TIME', this.currentSurgeryOrder.indskr_ptready);
  }

  initializeprocStartField() {
    this.procStartField = this.initField('PROC_START', 'SELECT_TIME', this.currentSurgeryOrder.indskr_procstartneedle);
  }

  initializetransseptalAccessField() {
    this.transseptalAccessField = this.initField('TRANSSEPTAL_ACCESS', 'SELECT_TIME', this.currentSurgeryOrder.indskr_transseptalaccess);
  }

  initializemappingCompleteField() {
    this.mappingCompleteField = this.initField('MAPPING_COMPLETE', 'SELECT_TIME', this.currentSurgeryOrder.indskr_mappingcomplete);
  }

  initializeprocedureCompleteField() {
    this.procedureCompleteField = this.initField('PROCEDURE_COMPLETE', 'SELECT_TIME', this.currentSurgeryOrder.indskr_procedurecomplete);
  }

  initializemappingCompleteFluorotimeMinutesField() {
    this.mappingCompleteFluorotimeMintutesField = this.initDurationField(
      'MAPPING_COMPLETE_FLUOROTIME_MINUTES', 'ADD_DURATION', this.currentSurgeryOrder.indskr_mappingcompletefluorotime, 'minutes', 'indskr_mappingcompleteMinutesfluorotime');
  }

  initializemappingCompleteFluorotimeSecondsField() {
    this.mappingCompleteFluorotimeSecondsField = this.initDurationField(
      'MAPPING_COMPLETE_FLUOROTIME_SECONDS', 'ADD_DURATION', this.currentSurgeryOrder.indskr_mappingcompletefluorotime, 'seconds', 'indskr_mappingcompleteSecondsfluorotime');
  }

  initializetransseptalAcessFluorotimeMintutesField() {
    this.transseptalAcessMintutesFluorotimeField = this.initDurationField(
      'TRANSSEPTAL_ACCESS_FLUOROTIME_MINUTES', 'ADD_DURATION', this.currentSurgeryOrder.indskr_transseptalaccessfluorotime, 'minutes', 'indskr_transseptalaccessMinutesfluorotime');
  }

  initializetransseptalAcessFluorotimeSecondsField() {
    this.transseptalAcessSecondsFluorotimeField = this.initDurationField(
      'TRANSSEPTAL_ACCESS_FLUOROTIME_SECONDS', 'ADD_DURATION', this.currentSurgeryOrder.indskr_transseptalaccessfluorotime, 'seconds', 'indskr_transseptalaccessSecondsfluorotime');
  }

  initializeprocedureCompleteFluorotimeMintutesField() {
    this.procedureCompleteMintutesFluorotimeField = this.initDurationField(
      'PROCEDURE_COMPLETE_FLUOROTIME_MINUTES', 'ADD_DURATION', this.currentSurgeryOrder.indskr_procedurecompletefluorotime, 'minutes', 'indskr_procedurecompleteMinutesfluorotime');
  }

  initializeprocedureCompleteFluorotimeSecondsField() {
    this.procedureCompleteSecondsFluorotimeField = this.initDurationField(
      'PROCEDURE_COMPLETE_FLUOROTIME_SECONDS', 'ADD_DURATION', this.currentSurgeryOrder.indskr_procedurecompletefluorotime, 'seconds', 'indskr_procedurecompleteSecondsfluorotime');
  }

  initializeptOutOfRoomField() {
    this.ptOutOfRoomField = this.initField('PT_OUT_OF_ROOM', 'SELECT_TIME', this.currentSurgeryOrder.indskr_ptoutofroom);
  }

  initializebwRepLeavesField() {
    this.bwRepLeavesField = this.initField('BW_REP_LEAVES', 'SELECT_TIME', this.currentSurgeryOrder.indskr_bwrepleaves);
  }

  // carto stats fields start//
  initializeablPointsField() {
    this.ablPointsField = this.initCartoFields('ABL_POINTS', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_ablpoints, 'indskr_ablpoints');
  }

  initializetotalmappingPointsField() {
    this.totalmappingPointsField = this.initCartoFields('TOTAL_MAPPING_POINTS', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_totalmappingpoints, 'indskr_totalmappingpoints');
  }

  initializeaverageRfPowerWattsField() {
    this.averageRfPowerWattsField = this.initCartoFields('AVERAGE_RF_POWER', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_averagerfpowerwatts, 'indskr_averagerfpowerwatts');
  }

  initializeposteriorRightField() {
    this.posteriorRightField = this.initCartoFields('POSTERIOR_RIGHT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_posteriorright, 'indskr_posteriorright');
  }

  initializeposteriorLeftField() {
    this.posteriorLeftField = this.initCartoFields('POSTERIOR_LEFT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_posteriorleft, 'indskr_posteriorleft');
  }

  initializeinteriorLeftFields() {
    this.interiorLeftFields = this.initCartoFields('INTERIOR_LEFT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_interiorleft, 'indskr_interiorleft');
  }

  initializeinteriorRightField() {
    this.interiorRightField = this.initCartoFields('INTERIOR_RIGHT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_interiorright, 'indskr_interiorright');
  }

  initializeanteriorLeftField() {
    this.anteriorLeftField = this.initCartoFields('ANTERIOR_LEFT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_anteriorleft, 'indskr_anteriorleft');
  }

  initializeanteriorRightField() {
    this.anteriorRightField = this.initCartoFields('ANTERIOR_RIGHT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_anteriorright, 'indskr_anteriorright');
  }

  initializeroofLeftField() {
    this.roofLeftField = this.initCartoFields('ROOF_LEFT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_roofleft, 'indskr_roofleft');
  }

  initializeroofRightField() {
    this.roofRightField = this.initCartoFields('ROOF_RIGHT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_roofright, 'indskr_roofright');
  }

  initializesuperiorLeftField() {
    this.superiorLeftField = this.initCartoFields('SUPERIOR_LEFT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_superiorleft, 'indskr_superiorleft');
  }

  initializesuperiorRightField() {
    this.superiorRightField = this.initCartoFields('SUPERIOR_RIGHT', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_superiorright, 'indskr_superiorright');
  }

  initializeridgeField() {
    this.ridgeField = this.initCartoFields('RIDGE', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_ridge, 'indskr_ridge');
  }

  initializefluidDeliveredField() {
    this.fluidDeliveredField = this.initCartoFields('FLUID_DELIVERED', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_fluiddelivered, 'indskr_fluiddelivered');
  }

  initializecTMergedField() {
    // this.cTMergedField = this.initCartoFields('CT_MERGED', 'ADD_NUMBER', this.currentSurgeryOrder.indskr_ctmerged, 'indskr_ctmerged');
    this.cTMergedField = {
      label: this.translate.instant('CT_MERGED'),
      inputText: this.currentSurgeryOrder.indskr_ctmergednew != null && this.currentSurgeryOrder.indskr_ctmergednew != undefined ? this.currentSurgeryOrder.indskr_ctmergednew ? this.translate.instant('YES') : this.translate.instant('NO') : this.translate.instant('NO'),
      inputValue: this.currentSurgeryOrder.indskr_ctmergednew,
      id: 'indskr_ctmergednew',
      isReadOnly: true,
      isDisabled: this.currentSurgeryOrder.status !== 1 || this.isReadonly,
      showArrow: !this.isReadonly,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }
  // carto stats fields end //

  initField(label, placeholderLabel, value) {
    return {
      isTimeField: true,
      label: this.translate.instant(label),
      inputText: value ? this.formatTime(value) : '',
      customPlaceholderLabel: this.translate.instant(placeholderLabel),
      startDateTimeValue: value,
      fromViewPage: CurViewPageType.oceTracking,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: this.isReadonly,
      isDisabled: this.currentSurgeryOrder.status !== 1 || this.isReadonly,
      showArrow: false,
    };
  }

  initDurationField(label, placeholderLabel, value, mode, id) {
    return {
      label: this.translate.instant(label),
      inputText: value ? this.formatDurationToDisplay(value, mode) : '',
      inputValue: value ? this.formatDurationToDisplay(value, mode) : '',
      customPlaceholderLabel: this.translate.instant(placeholderLabel),
      id,
      inputType: 'number',
      isReadOnly: this.isReadonly,
      isDisabled: this.currentSurgeryOrder.status !== 1 || this.isReadonly,
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  initCartoFields(label, placeholderLabel, value, id): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant(label),
      inputText: value ? value : '',
      inputValue: value ? value : '',
      customPlaceholderLabel: this.translate.instant(placeholderLabel),
      id,
      inputType: 'number',
      isReadOnly: this.isReadonly,
      isDisabled: this.currentSurgeryOrder.status !== 1 || this.isReadonly,
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  formatTime(inputDate: any): string {
    inputDate = new Date(inputDate);
    let startTimeValue = inputDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return startTimeValue;
  }

  setProcedureTime(field: string, selectedDate?: Date) {
    if(this.isReadonly) return false;
    this.currentSurgeryOrder[field] = selectedDate ? new Date(selectedDate).getTime() : new Date().getTime();
    this.updateSurgeryOrder();
    this.initializeFields();
  }

  setDuration(field: string, value) {
    this.currentSurgeryOrder[field] = value;
    this.updateSurgeryOrder();
    this.initializeFields();
  }

  formatDurationToDisplay(inputValue: number, mode: string): string {
    if (mode == 'minutes') {
      let minutesInSting: any = moment.duration({ seconds: inputValue }).minutes();
      minutesInSting = minutesInSting < 10 ? '0' + minutesInSting : minutesInSting
      return minutesInSting.toString();
    } else {
      let secondsInString: any = moment.duration({ seconds: inputValue }).seconds();
      secondsInString = secondsInString < 10 ? '0' + secondsInString : secondsInString
      return secondsInString.toString();
    }
  }

  onProcedureCartoSectionClick(id: string) { 
    switch (id) {
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  async handlePlusButton() {
    this.setUpProductsChannelsContactInfoForConsent(this.currentSurgeryOrder);
    let generatedTerms: ConsentTerm[] = await this.consentTermGenerateService.generateConsentTerms();
    if (generatedTerms.length) {
      generatedTerms[0].isSelectedTerm = true;
      this.consentService.selectedConsentTerm.next(generatedTerms[0]);
      this.consentService.allGeneratedTerms.next(generatedTerms);
      this.consentService.consentTab = FooterViews.ConsentCapture;
      this.uiService.consentFlipButton = true;

      this.navService.pushChildNavPageWithPageTracking(
        ConsentDetailsComponent,
        PageName.ConsentDetailsComponent
      );
    }

  }

  setUpProductsChannelsContactInfoForConsent(currentSurgeryOrder: SurgeryOrderActivity) {
    this.consentTermGenerateService.selectedProducts = [];
    this.consentTermGenerateService.selectedChannels = [];
    if (this.brandOfflineService.brands && this.brandOfflineService.brands.length) {
      if (this.authService.user.isProductConsent) {
        this.brandOfflineService.brands.map(brand => {
          let product: Product;
          product = new Product(brand.ID, brand.name, true);
          this.consentTermGenerateService.selectedProducts.push(product)
        })
      } else {
        this.consentTermGenerateService.selectedProducts = [];
      }
    }
    const channels: Channel[] = this.consentService.allConsentChannelSubject.getValue();
    if (channels.length) {
      const channel = channels.find(ch => ch.indskr_consentType === this.currentSurgeryOrder?.channelType);
      if (channel) {
        channel.isChecked = true;
        this.consentTermGenerateService.selectedChannels.push(channel);
      }
    }
    this.consentTermGenerateService.contact = this.contactService.contacts.find(c => c.ID === this.currentSurgeryOrder.customers[0].ID);
    this.contactService.contactInformation = this.consentTermGenerateService.contact;
  }

  handleFormFieldEvent(id, event, eventName) {
    if (id != 'indskr_ctmergednew' && eventName !== 'input_value_confirm') return;
    switch (id) {
      case 'indskr_mappingcompleteMinutesfluorotime':
        this.currentSurgeryOrder.indskr_mappingcompletefluorotime = this.minuteDurationCalculations('indskr_mappingcompletefluorotime', event.target.value);
        break;
      case 'indskr_mappingcompleteSecondsfluorotime':
        this.currentSurgeryOrder.indskr_mappingcompletefluorotime = this.secondsDurationCalculations('indskr_mappingcompletefluorotime', event.target.value);
        break;
      case 'indskr_transseptalaccessMinutesfluorotime':
        this.currentSurgeryOrder.indskr_transseptalaccessfluorotime = this.minuteDurationCalculations('indskr_transseptalaccessfluorotime', event.target.value);
        break;
      case 'indskr_transseptalaccessSecondsfluorotime':
        this.currentSurgeryOrder.indskr_transseptalaccessfluorotime = this.secondsDurationCalculations('indskr_transseptalaccessfluorotime', event.target.value);
        break;
      case 'indskr_procedurecompleteMinutesfluorotime':
        this.currentSurgeryOrder.indskr_procedurecompletefluorotime = this.minuteDurationCalculations('indskr_procedurecompletefluorotime', event.target.value);
        break;
      case 'indskr_procedurecompleteSecondsfluorotime':
        this.currentSurgeryOrder.indskr_procedurecompletefluorotime = this.secondsDurationCalculations('indskr_procedurecompletefluorotime', event.target.value);
        break;
      case 'indskr_ctmergednew':
        this.handleCtmergednew(id, event, eventName);
        break;
      default:
        this.currentSurgeryOrder[id] = event.target.value;
        break;
    }
    if(id != 'indskr_ctmergednew') {
      this.updateSurgeryOrder();
      this.initializeFields();
    }
  }

  private async handleCtmergednew(id, event, eventName) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'ctMerged-select',
      isMultipleSelectionEnabled: false,
      data: [
        {
          title: this.translate.instant('YES'),
          id: 'Yes',
          isSelected: ((this.currentSurgeryOrder.indskr_ctmergednew != null && this.currentSurgeryOrder.indskr_ctmergednew != undefined)  && this.currentSurgeryOrder.indskr_ctmergednew == true) ? true : false,
        },
        {
          title: this.translate.instant('NO'),
          id: 'No',
          isSelected: ((this.currentSurgeryOrder.indskr_ctmergednew != null && this.currentSurgeryOrder.indskr_ctmergednew != undefined)  && this.currentSurgeryOrder.indskr_ctmergednew == true) ? false : true,
        }
      ],
    };
    const ctMergedPopovver = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    ctMergedPopovver.onDidDismiss().then(async (data: any) => {
      if (data.data) data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (data.selectedItems[0].id == 'Yes') {
          this.currentSurgeryOrder.indskr_ctmergednew = true;
        } else if (data.selectedItems[0].id == 'No') {
          this.currentSurgeryOrder.indskr_ctmergednew = false;
        }
      } else if (data && data.selectedItems && data.selectedItems.length == 0) {
        this.currentSurgeryOrder.indskr_ctmergednew = false;
      } else {
        this.currentSurgeryOrder.indskr_ctmergednew = false;
      }
      this.updateSurgeryOrder();
      this.initializeFields();
     })
    ctMergedPopovver.present();
  }

  minuteDurationCalculations(field, changedMinute) {
    let finalTIme;
    let currentDuration = this.currentSurgeryOrder[field];
    if (!currentDuration && changedMinute) return changedMinute * 60;

    currentDuration = parseInt(currentDuration);
    changedMinute = parseInt(changedMinute);

    let durationMinutes = moment.duration({ seconds: currentDuration }).minutes();
    if (!changedMinute) {
      finalTIme = currentDuration - durationMinutes * 60;
    } else {
      finalTIme = currentDuration - durationMinutes * 60;
      finalTIme = finalTIme + changedMinute * 60;
    }
    return finalTIme;
  }

  secondsDurationCalculations(field, changedSeconds) {
    let finalTIme;
    let currentDuration = this.currentSurgeryOrder[field];
    if (!currentDuration && changedSeconds) return changedSeconds;

    currentDuration = parseInt(currentDuration);
    changedSeconds = parseInt(changedSeconds);

    let durationSeconds = moment.duration({ seconds: currentDuration }).seconds();
    if (!changedSeconds) {
      finalTIme = currentDuration - durationSeconds;
    } else {
      finalTIme = currentDuration - durationSeconds;
      finalTIme = finalTIme + changedSeconds;
    }
    return finalTIme;
  }

  async updateSurgeryOrder() {
    let action: OperationDetail = {
      onDynamics: !this.deviceSerivce.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'procedureStatsUpdate',
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };

    const newLastUpdatedTime = new Date().getTime();
    let hasOfflineChanges = this.currentSurgeryOrder.pendingPushToDynamics || false;
    this.currentSurgeryOrder.pendingPushToDynamics = true;
    this.currentSurgeryOrder.modifiedOn = new Date();

    if (!this.deviceSerivce.isOffline) this.uiService.displayLoader();
    await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrder], newLastUpdatedTime, hasOfflineChanges).catch((error) => {
      console.log('Failed to update surgery order');
    });
    if (!this.deviceSerivce.isOffline) this.uiService.dismissLoader();
  }

}
