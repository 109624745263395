<ion-header>
  <ind-page-title [viewData]="pageTitleViewData" (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  <div class="searchbar-wrapper">
    <ion-searchbar placeholder="{{searchPlaceholder}}" [(ngModel)]="searchInput" (ionChange)="handleSearch()"></ion-searchbar>
    <div class="searchbar-overlay">
      <ion-buttons tooltip="{{'FILTERS' | translate}}" class="searchbar-overlay-button contactTimelineButtons"
        slot="end">
        <ion-icon class="searchbar-overlay-button-filter-icon contactTimelineFilterIcon"
          src="assets/imgs/filter-blue.svg" (click)="onFilterClick($event)"> </ion-icon>
        <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge" (click)="onFilterClick($event)">{{
          filterButtonBadgeCount }}</span>
      </ion-buttons>
    </div>
  </div>
  <!--Customer Glance Card-->
  <glance-card *ngIf="contactGlanceViewData" [viewData]="contactGlanceViewData"
    (onControlClick)="onQuickGlanceControlClick($event)"> </glance-card>
</ion-header>
<ion-content>
  <ind-section-header [viewData]='opportuitiesSectionHeader'></ind-section-header>
  <ion-list class="event-items-list" *ngIf="viewOpportunitiesItems.length > 0">
    <timeline-item *ngFor="let item of viewOpportunitiesItems" [inputData]="item"
      (clickedOnHeader)="openOpportunityDetail($event)"  (clickedOnPopupImage)="openDyanmicURl(item.refObject)"></timeline-item>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="30%">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
  <ion-list>
    <div class="no-data-message" *ngIf="viewOpportunitiesItems.length == 0">
      {{ 'NO_OPPORTUNITIES' | translate}}
    </div>
  </ion-list>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'contacts'"></footer-toolbar>