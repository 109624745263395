<!-- Opens as Modal -->
<ion-grid class="fullstrech">
    <ion-row class="fullstrech">
        <ion-col class="left-pane fullstrech">
            <ion-header>
                <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="searchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchInput)="onSearch()">
                </ind-header-left>
            </ion-header>
            <ion-content>
                <!-- Product selection on details -->
                <div>
                    <ion-list *ngIf="optionList.length != 0">
                        <ind-section-header *ngIf="listSelection" [viewData]="emailSelectHeaderModel"></ind-section-header>
                        <ion-item class='selector-item' *ngIf="listSelection">
                            {{listSelection.name}}
                            <ion-icon (click)="unselect()" class='remove-icon' slot="end"></ion-icon>
                        </ion-item>
                        <ind-section-header *ngIf="searchText.length === 0" [viewData]="emailAllProductHeaderModel"></ind-section-header>

                        <ind-section-header *ngIf="searchText.length > 0" [viewData]="emailResultHeaderModel"></ind-section-header>

                        <ion-item-group *ngFor="let item of optionList | orderBy: 'name' :true">
                            <ion-item class='selector-item' (click)="onOptionSelection(item)">
                                <ion-level>
                                    {{item.name}}
                                </ion-level>
                                <ion-icon *ngIf="listSelection && item.ID == listSelection?.ID" class='checkmark-icon' slot="end"></ion-icon>
                                <ion-icon *ngIf="!listSelection || item.ID != listSelection?.ID" class='add-icon' slot="end"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                            <ion-item *ngIf="optionList.length === 0">
                                {{'NO_PRODUCTS_AVAILABLE' | translate}}
                            </ion-item>
                            <div *ngIf="item.skus.length != 0">
                                <ion-item-group class="pad-20 selector-item" *ngFor="let sku of item.skus | orderBy: 'name' :true" (click)="onOptionSelection(sku)">
                                    <ion-item>
                                        <ion-level>
                                            {{sku.name}}
                                        </ion-level>
                                        <ion-icon *ngIf="listSelection && sku.ID == listSelection?.ID" class='checkmark-icon' slot="end"></ion-icon>
                                        <ion-icon *ngIf="!listSelection || sku.ID != listSelection?.ID" class='add-icon' slot="end"></ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </div>
                        </ion-item-group>
                    </ion-list>
                    <ion-item-divider *ngIf="searchText.length === 0 && optionList.length === 0">
                        <span class="ion-float-left">{{'PRODUCTS_CAP' | translate}} ({{productSize}})</span>
                    </ion-item-divider>
                    <ion-item-divider class="results-text" *ngIf="searchText.length > 0 && optionList.length === 0">
                        <span class="ion-float-left">{{'RESULTS_CAP' | translate}} ({{productSize}})</span>
                    </ion-item-divider>
                    <div *ngIf="optionList.length === 0 && !searchText" class="no-data-message">{{'SCHEDULER_NO_PRODUCTS'| translate}}</div>
                    <ind-no-search-result *ngIf="optionList.length === 0 && searchText" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                </div>
            </ion-content>
            <!-- <ion-footer> -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'callplans'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>

        <ion-col class="right-pane fullstrech">
            <nothing-selected-view [textToDisplay]="''" [deviceString]="device.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
        </ion-col>
    </ion-row>
</ion-grid>
