import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@omni/services/device/device.service';
import { GoAppNavigationService } from '@omni/services/go-app-navigation.service';
import _ from 'lodash';

@Component({
  selector: 'go-app-navigation-menu',
  templateUrl: './go-app-navigation-menu.component.html',
  styleUrl: './go-app-navigation-menu.component.scss'
})
export class GoAppNavigationMenuComponent {
  @Input() isExpanded = false;
  @Output() sidebarToggle = new EventEmitter<void>();

  public sideBarOptions = [];

  constructor(private translate: TranslateService,
    public device: DeviceService,
    private goAppNavigationService: GoAppNavigationService
  ) {
  }

  ngOnInit() {
    this.initData();
    this.device.syncInProgress$.subscribe(inProgress => {
      this.initData();
    });

    this.device.isDataLoadFromLocalDB.subscribe((inProgress) => {
      this.initData();
    });
  }

  private initData() {
    this.initSideBarOptions();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.initSideBarOptions();
  }

  private initSideBarOptions(): void {
    this.sideBarOptions = [{
      id: 'hamburger',
      visible: true,
      iconName: 'hamburger-icon',
      iconPath: 'assets/imgs/menu-toggle-grey.svg',
      selectedIconPath: 'assets/imgs/menu-toggle-grey.svg',
      displayName: "",
      selected: false,
      component: '',
      enable: true
    },
    {
      id: 'dashboard',
      visible: true,
      iconName: 'home-icon',
      iconPath: 'assets/imgs/home-grey.svg',
      selectedIconPath: 'assets/imgs/home-blue.svg',
      displayName: this.translate.instant('DASHBOARD'),
      selected: true,
      component: 'HomePage',
      enable: true
    }];
    this.sideBarOptions.push(...this.goAppNavigationService.initMenuOptions());
  }

  onClickMenuOption(option) {
    // if (option.id != 'hamburger')
    //   this.sideBarOptions.forEach(op => op.selected = option.id == op.id);
    switch (option.id) {
      case "hamburger":
        this.sidebarToggle.emit();
        break;
      case "dashboard":
        break;
      default:
        this.goAppNavigationService.navigateToMenuOption(option);
    }
  }

}
