<ion-header>
    <ion-toolbar *ngIf="viewData && viewData.title" class="title-toolbar">
        <ion-title class="ion-text-center lookup-popover-title"> {{viewData.title}} </ion-title>
    </ion-toolbar>
    <ion-toolbar *ngIf="viewData && viewData.isSearchEnabled" class="search-toolbar">
        <ion-searchbar #searchbar (ionInput)="searchText($event)"
            placeholder="{{'XPLORE_SEARCH_PLACEHOLDER' | translate}}">
        </ion-searchbar>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-scroll *ngIf="isAccessedFromOppCollabo" class="lookup-options-list-scroll">
        <ion-list class="lookup-options-list">
            <ion-item *ngIf="opportunityDataService.lookupSearchInProgress" class="lookup-search-progress-spinner">
                <ind-block-level-loader *ngIf="opportunityDataService.lookupSearchInProgress"></ind-block-level-loader>
            </ion-item>
            <ion-item
                *ngIf="viewData && viewData.showUnselectOption && opportunityDataService.lookupSearchData && opportunityDataService.lookupSearchData.length != 0"
                (click)="clearValue()" class="unselect-option">
                <ion-label>{{viewData.unselectOptionText}}</ion-label>
                <ion-icon slot="end" color="primary" name="checkmark" *ngIf="!viewData.selectedValue"></ion-icon>
            </ion-item>
            <ion-item *ngFor="let val of opportunityDataService.lookupSearchData" (click)="setSelectedLookupValue(val)" class="selectable-option">
                <ion-label>{{val.name}}</ion-label>
                <ion-icon slot="end" color="primary" name="checkmark" *ngIf="viewData.selectedValue == val.id"></ion-icon>
            </ion-item>
            <ion-item *ngIf="opportunityDataService.lookupSearchData.length == 0 && !opportunityDataService.lookupSearchInProgress"
                class="no-results-option">
                <ion-label>{{'NO_RESULTS' | translate}}</ion-label>
            </ion-item>
        </ion-list>
    </ion-scroll>
    <ion-scroll *ngIf="!isAccessedFromOppCollabo" class="lookup-options-list-scroll">
      <ion-list class="lookup-options-list">
          <ion-item *ngIf="searchLookupPopoverService.lookupSearchInProgress" class="lookup-search-progress-spinner">
              <ind-block-level-loader *ngIf="searchLookupPopoverService.lookupSearchInProgress"></ind-block-level-loader>
          </ion-item>
          <ion-item
              *ngIf="viewData && viewData.showUnselectOption && searchLookupPopoverService.lookupSearchData && searchLookupPopoverService.lookupSearchData.length != 0"
              (click)="clearValue()" class="unselect-option">
              <ion-label>{{viewData.unselectOptionText}}</ion-label>
              <ion-icon slot="end" color="primary" name="checkmark" *ngIf="!viewData.selectedValue"></ion-icon>
          </ion-item>
          <ion-item *ngFor="let val of searchLookupPopoverService.lookupSearchData" (click)="setSelectedLookupValue(val)" class="selectable-option">
              <ion-label>{{val.name}}</ion-label>
              <ion-icon slot="end" color="primary" name="checkmark" *ngIf="viewData.selectedValue == val.id"></ion-icon>
          </ion-item>
          <ion-item *ngIf="searchLookupPopoverService.lookupSearchData.length == 0 && !searchLookupPopoverService.lookupSearchInProgress"
              class="no-results-option">
              <ion-label>{{'NO_RESULTS' | translate}}</ion-label>
          </ion-item>
      </ion-list>
  </ion-scroll>
</ion-content>

