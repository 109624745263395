import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

interface GridItem {
  id?: string | number;
  [key: string]: any;
}

export interface ListGridDataModel {
  clickHandler?: (event: Event | undefined, item: GridItem, dataRef: ListGridDataModel) => void;
}

@Component({
  selector: 'ind-list-grid',
  templateUrl: 'ind-list-grid.html',
  styleUrls: ['ind-list-grid.scss']
})
export class IndListGridComponent {
  @Input({ required: true }) viewMetaData!: ListGridDataModel;
  @Input() listType?: string;
  @Input() listRef?: any;
  @Input() gridRef?: any;
  @Input() data: GridItem[] = [];

  constructor(public translate: TranslateService) {}

  trackByFn(index: number, item: GridItem): string | number {
    return item.id || index;
  }

  public handleMainItemClick(event: Event, item: GridItem): void {
    if (this.viewMetaData.clickHandler) {
      this.viewMetaData.clickHandler(event, item, this.viewMetaData);
      try {
        event?.stopPropagation();
      } catch (error: unknown) {
        console.error('Error handling click event:', error);
      }
    }
  }
}
