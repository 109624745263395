<!-- <ion-scroll scrollX="true">
  <ion-chip [attr.size]="size" class="chip" *ngFor="let data of viewData" (click)="chipSelected(data)"
    [ngClass]="{ 'active-chip': data.value === selectedChip.value, 'disabled': data.disabled }">
    <ion-thumbnail *ngIf="data.thumbnailUrl">
      <img [src]="data.thumbnailUrl">
    </ion-thumbnail>
    <ion-label class="chip-label">{{data.label}}</ion-label>
  </ion-chip>
</ion-scroll> -->

<!-- <ion-slides [options]="{ slidesPerView: 'auto', zoom: false, grabCursor: true, spaceBetween:15 }" [scrollbar]=false>
  <ion-slide *ngFor="let data of viewData; let i=index" class="slide-item">
    <ion-chip [attr.size]="size" class="chip" (click)="chipSelected(data, i)"
      [ngClass]="{ 'active-chip': data.value === selectedChip.value, 'disabled': data.disabled }">
      <ion-thumbnail *ngIf="data.thumbnailUrl">
        <img [src]="data.thumbnailUrl">
      </ion-thumbnail>
      <ion-label class="chip-label">{{data.label}}</ion-label>
    </ion-chip>
  </ion-slide>
</ion-slides> -->

<swiper-container #swiper
                  grab-cursor="true"
                  modules="swiperModules"
                  scroll-bar="false"
                  slides-per-view="auto"
                  space-between="15"
                  zoom="false"
>
  <swiper-slide *ngFor="let data of viewData; let i=index" class="slide-item">
    <ion-chip [attr.size]="size" class="chip" (click)="chipSelected(data, i)"
              [ngClass]="{ 'active-chip': data.value === selectedChip.value, 'disabled': data.disabled }">
      <ion-thumbnail *ngIf="data.thumbnailUrl">
        <img [src]="data.thumbnailUrl" alt="thumbnail">
      </ion-thumbnail>
      <ion-label class="chip-label">{{ data.label }}</ion-label>
    </ion-chip>
  </swiper-slide>
</swiper-container>
