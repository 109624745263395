import { OrderByPipe } from "./../../../pipes/orderby.pipe";
import { FooterService, FooterViews } from "./../../../services/footer/footer.service";
import { UIService } from "./../../../services/ui/ui.service";
import {
  ChildNavNames,
  NavigationService,
  PageName
} from "./../../../services/navigation/navigation.service";
import { Component, ViewChildren, Input, ChangeDetectorRef, ElementRef, HostListener } from "@angular/core";
import {
  ResourceService,
  ResourceView
} from "../../../services/resource/resource.service";
import { Resource } from "../../../classes/resource/resource.class";
import { DeviceService } from "../../../services/device/device.service";
import { ModalController, PopoverController, IonNav } from "@ionic/angular";
import { ActivityService } from "../../../services/activity/activity.service";
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { EventsService } from "../../../services/events/events.service";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { AuthenticationService } from "../../../services/authentication.service";
import { ContentMatchingService, CONTENTMATCHTYPE } from "../../../services/content-matching/content-matching.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { EmailViewType } from "../../../classes/activity/email.activity.class";
import { DeltaService } from "../../../data-services/delta/delta.service";
import { EmailService } from "../../../services/email-templates/email.service";
import { TranslateService } from "@ngx-translate/core";
import { RepServices } from '../../../data-services/rep/rep.services';
import { SearchConfigService } from "../../../services/search/search-config.service";
import { IndKeyControlsAreaModel } from './../../../models/indKeyControlsAreaModel';
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from "../../../models/search-config-data-model";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import * as _ from 'lodash';
import { AlertService } from "../../../services/alert/alert.service";
import { SearchConfigDataService } from "../../../data-services/search-config/search-config-data-service";
import { DiskService } from "../../../services/disk/disk.service";
import { DB_KEY_PREFIXES } from "../../../config/pouch-db.config";
import { ResourceState } from '../../../store/application.state';
import { Store } from "@ngrx/store";
import * as ResourceAction from '../../../store/io-file-service/actions/resource.actions';
import { NotificationService } from "../../../services/notification/notification.service";
import { ToastStyle } from "../../../services/notification/notification.service";
import { ResourceDataService } from "../../../data-services/resource/resource.data.service";
import { ContentCustomSortPipe } from "../../../pipes/content-custom-sort.pipe";
import { Utility } from "../../../utility/util";
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { CallbackPipe } from '../../../pipes/callback.pipe';
import { EntityTag, TagEntityType, TagStateCode, TagVisibility, UserTag, UserTagService } from "@omni/services/user-tag/user-tag.service";
import { PopoverComponent } from "@omni/components/popover/popover";
import { IndFilterMenuComponent } from "@omni/components/shared/ind-filter-menu/ind-filter-menu";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
@Component({
  selector: "resource-list",
  templateUrl: "resource-list.html",
  styleUrls: ['resource-list.scss']
})
export class ResourceListComponent {
  @Input() viewMode: ResourceView;
  @Input() resourcesFromNav: Resource[];
  @Input() collectionName: string;

  public listTitle: string = this.translate.instant("RESOURCES")

  public isAddToEmail = false;
  public resourceMode = ResourceView;
  public emailResource: Resource[] = [];
  public newResources: Resource[] = [];
  public contentMatchedRes: Resource[] = [];

  public isSingleFileType: boolean = false;
  public resourceCollectionName: string;

  resources: Array<Resource> = [];
  filteredList: Resource[] = [];
  allowPushResource: boolean;
  // filterPopoverData: any[];
  sortBy: { text: string; value: string; asc: boolean; };
  resourceFilter = { title: "", assetType: "", brands: "" };

  @ViewChildren('resourceItem') displayedResourceItem;
  isContentMatching: boolean;
  // Boolean to track if changes are made to email resource
  private isModified = false;
  public isSelectAll: boolean = false;
  public selectedResourceList: Resource[] = [];
  sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  get hasResources(): boolean {
    return this.resources && this.resources.length > 0;
  }

  //@ViewChild(Content, {static: true}) content: Content;

  public resourceListKeyControlAreaModel: IndKeyControlsAreaModel;
  public searching: boolean = false;
  suggestionsActive: boolean = false;
  shouldFireSearchChangeEvent: boolean = true;
  resourceSearchText: string;
  public singleSelect: boolean = false;
  @Input() resourceListMode: string;
  suggestionsData: {
    header: IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey: string = '';
  selectedSuggestionsData: SelectedSuggestionPillDataModel[] = [];
  disableSaveSearch: boolean = false;

  public filterMetadata = { count: 0 };
  contentCustomSortPipe: ContentCustomSortPipe<Resource> = new ContentCustomSortPipe<Resource>();
  public selectedTagData: UserTag;
  public filterByDate = Utility.filterByDate;
  public emailResourceCopy: Resource[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public allResourcesHeaderModel: IndSectionHeaderViewDataModel;
  slicedResources: Resource[];
  sliceCount = 15;
  private callbackPipe: CallbackPipe = new CallbackPipe();
  private orderBy = new OrderByPipe();
  public tagListPopover: HTMLIonPopoverElement;
  debounceTime: number = 300;
  public showRemoveTag :boolean =false;
  public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
  public filtering: boolean = false;
  public hideFilterButton: boolean = false;
  public visibleFilteredList:  Resource[] = [];
  constructor(
    public resourceService: ResourceService,
    public resourceDataService: ResourceDataService,
    public navService: NavigationService,
    protected popoverCtrl: ModalController,
    private events: EventsService,
    protected popover: PopoverController,
    private footerService: FooterService,
    private activityService: ActivityService,
    public uiService: UIService,
    private authService: AuthenticationService,
    public contentMatchService: ContentMatchingService,
    public trackingService: TrackService,
    private deltaService: DeltaService,
    private emailService: EmailService,
    private navCtrl: IonNav,
    public translate: TranslateService,
    public repService: RepServices,
    private resourceListElement: ElementRef,
    public searchConfigService: SearchConfigService,
    public utilityService: GlobalUtilityService,
    public _cd: ChangeDetectorRef,
    public disk: DiskService,
    public alertService: AlertService,
    public searchConfigDataService: SearchConfigDataService,
    public store: Store<ResourceState>,
    private notificationService: NotificationService,
    public deviceService: DeviceService,
    protected cdRef: ChangeDetectorRef,
    private readonly userTagService: UserTagService,
    private modalCtrl:ModalController,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) { }
  ngOnInit() {
    this.onDefaultFilterLoad();
    if (this.viewMode === this.resourceMode.ADDTOEMAIL) {
      this.isAddToEmail = true;
      this.addSelectedEmailResource();
    }

    if (this.viewMode === this.resourceMode.DOCUMENTS) {
      this.isSingleFileType = true;
      this.listTitle = this.translate.instant("R_STRATEGY_DOCUMENTS")
    }

    this.resources = this.resourceService.allResources.map(resource => {
      resource.assetType = resource.assetType || "other";
      resource.isSelected = false;
      return resource;
    });
    if (this.resourcesFromNav) {
      this.resources = this.resourcesFromNav;
      this.resourceCollectionName = this.collectionName || '';
    }
    if (this.deltaService.deltaRecordsDTO.resources.length) {
      this.resourceService.allResources.forEach(resource => {
        if (this.deltaService.deltaRecordsDTO.resources.indexOf(resource.ioResourceId) != -1 ||
          this.deltaService.deltaRecordsDTO.resources.indexOf(resource.ioDocumentId) != -1) {
          this.newResources.push(resource);
        }
      });
    }

    this.resourceService.updateNewResourceStatus(this.newResources);

    this.contentMatchService.isContentMatchingApplied = false;
    if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
      this.resources = this.contentMatchService.SelectedContactsPresentation(this.resources, CONTENTMATCHTYPE.RESOURCES, this.activityService.selectedActivity);
      this.contentMatchedRes = this.resources;
      this.sliceRecordsForInfiniteScroll();
    }

    if (this.searchConfigService.configUpdateRequired) {
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }

    let orderBy = new OrderByPipe();
    // this.filterPopoverData = [
    //   {
    //     text: "",
    //     value: "All",
    //     items: [{ text:this.translate.instant('SMALL_ALL_RESOURCES'), value: 'All' }],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items.map((o)=>{
    //         o.value = '';
    //       })
    //       item.value = selectedItem.value;
    //       this.resourceFilter = Object.assign({}, this.resourceFilter, { brands: '', assetType: ''  });
    //         // if (item.value !== '') {
    //         //     this.trackingService.tracking('PresentationProductFilter',TrackingEventNames.PRESENTATIONS);
    //         // }
    //         //this.resources = this.presentationFilterByPipe.transform(this.sortedPresentations, this.presentationFilter);
    //     }
    //   },
    //   {
    //     text: this.translate.instant('PRODUCT'),
    //     value: "",
    //     items: orderBy.transform(this.brandService.brands.map(b => ({ text: b.name, value: b.name })), "text", true),
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value=''
    //       item.value = item.value === selectedItem.value ? "" : selectedItem.value;
    //       this.resourceFilter.brands = item.value;
    //       if(this.resourceFilter.brands == '' && this.resourceFilter.assetType == ''){
    //         itemRef.parent.items[0].value='All'
    //       }
    //     }
    //   },
    //   {
    //     text: this.translate.instant('TYPE'),
    //     value: "",
    //     items: [
    //       { text: this.translate.instant('R_DOCUMENT'), value: "document" },
    //       { text: this.translate.instant('R_IMAGE'), value: "image" },
    //       { text: this.translate.instant('VIDEO'), value: "video" },
    //       { text: this.translate.instant('R_WEB'), value: "web" },
    //       { text: this.translate.instant('OTHER'), value: "other" }
    //     ],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value=''
    //       item.value = item.value === selectedItem.value ? "" : selectedItem.value;
    //       this.resourceFilter.assetType = item.value;
    //       if(this.resourceFilter.brands == '' && this.resourceFilter.assetType == ''){
    //         itemRef.parent.items[0].value='All'
    //       }
    //     }
    //   }
    // ];
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "title",
        items: [
          { text: this.translate.instant('NAME'), value: "title", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false },
          ... this.isSingleFileType ? [] : [{ text: this.translate.instant('TYPE'), value: "assetType", asc: true }]
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          if (selectedItem.value == '$downloaded') {
            this.filteredList = this.contentCustomSortPipe.transform(this.filteredList, { downloaded: true });
            this.resources = this.contentCustomSortPipe.transform(this.resources, { downloaded: true });
            this.newResources = this.contentCustomSortPipe.transform(this.newResources, { downloaded: true });
            this.emailResource = this.contentCustomSortPipe.transform(this.emailResource, { downloaded: true });
          }
          this.sliceRecordsForInfiniteScroll();
          this.initAllResourcesSectionHeader();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    if (this.deviceService.isNativeApp) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('DOWNLOADS'), value: "$downloaded", asc: true });
    }
    this.sliceRecordsForInfiniteScroll();
    this.allowPushResource = this.resourceService.viewMode === ResourceView.PUSH;
    this.initResourceHeaderLeft();
    this.initAllResourcesSectionHeader();
  }
  // filter() {
  //   this.trackingService.tracking('ResourceFilter', TrackingEventNames.RESOURCES);
  //   if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
  //     this.resources = this.contentMatchedRes;
  //   }
  //   this.popover
  //     .create(MultiSelectPopover, { root: this.filterPopoverData })
  //     .present({ ev: event });

  //   this.resourceFilter.title = '';
  // }
  sort() {
    if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.user.featureActions.indexOf(FeatureActionsMap.CONTENT_MATCHING) > - 1) {
      this.resources = this.contentMatchedRes;
      this.sliceRecordsForInfiniteScroll();
    }
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
      .then((data) => data.present())
  }

  sortResourcesBySortSelection(selectedSortOption) {
    if (!this.searching) {
      if (selectedSortOption.value.includes('$')) {
        return;
      }
      if (selectedSortOption.asc) {
        this.resources.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
          else return -1;
        })
      }
      else {
        this.resources.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
          else return 1;
        })
      }
      // this.resources = this.contentMatchedRes;
      this.sliceRecordsForInfiniteScroll();
    }
  }

  // handleSearch(){
  //   if(this.resourceSearchText.length>0){
  //     this.filterPopoverData.map(o=>{
  //       o.value = '';
  //     })
  //     this.resourceFilter = Object.assign({}, this.resourceFilter, { title:this.resourceSearchText, brands: '', assetType: ''  });
  //   }
  //   else{
  //     this.onSearchCancel();
  //   }
  // }

  // onSearchCancel(){
  //   this.resourceFilter = Object.assign({},this.resourceFilter, {title:''});
  // }

  highlightResource(resource) {
    this.trackingService.tracking('ResourcePreview', TrackingEventNames.RESOURCES)
    this.resourceService.highlightResource(resource);
  }
  pushResource(event: Event, resource: Resource) {
    this.resourceService.highlightResource(resource);
    this.events.publish("share-resource");
  }

  async cancel() {
    return this.goBack();
  }
  async goBack() {
    if (this.isAddToEmail) {
      this.trackingService.tracking('EmailAttachmentsCanceled', TrackingEventNames.EMAIL);
    }
    else if (!this.isAddToEmail && this.resourceService.viewMode !== ResourceView.PUSH) {
      this.deltaService.deltaRecordsDTO.resources.length = 0;
      this.trackingService.tracking('ResourcesBack', TrackingEventNames.RESOURCES);
    }
    else {
      this.trackingService.tracking('ResourcesBack', TrackingEventNames.RESOURCES);
    }
    if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (this.emailService.selectedActivity && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
        this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        });
        return;
      }
    }
    //this.navService.popChildNavCtrlFromStack(ChildNavNames.ResourceToolNavigation);
    this.navService.popWithPageTracking().then(() => {
      
      if (this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) return;
      if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
        if(this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
          this.footerService.initButtons(FooterViews.AccountPlanObjectives);
        }
        if(this.uiService.activitiesPagePlanTabSelectedPlan === 'callPlans') {
          this.footerService.initButtons(FooterViews.EmailDetails);
        }
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        if(this.isAddToEmail){
          this.uiService.showRightPane = true;
        }
      } else {
        this.uiService.activeView = this.uiService.prevView;
        if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
          this.footerService.initButtons(FooterViews.EmailDetails);
        } else if (this.uiService.activeView === 'customer_inquiry' || this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        } else {
          // Short call feature requires to bring the tab back to short call home
          if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
            this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
            this.uiService.setAgendaTab('shortCallHome');
          }
        }
      }
    });
  }
  public addToEmail() {
    this.trackingService.tracking('Done from Resource', TrackingEventNames.PRESENTATIONS)
    if (!this.isModified) {
      this.navService.popWithPageTracking().then(() => {
      });
      return;
    }
  }
  public addResourceToArray(resource: Resource) {
    this.isModified = true;
    let element = this.emailResource.find(p => p.assetID === resource.assetID);
    if (!element) {
      // pres.isSelected = true;
      this.emailResource.push(resource);
    } else {
      // pres.isSelected = false;
      let index = this.emailResource.indexOf(resource);
      this.emailResource.splice(index, 1);
    }
    if (this.indHeaderLeftModel.controls.length > 1) {
      this.indHeaderLeftModel.controls[1].isDisabled = this.disableDoneBtn;
    }
  }
  // Select Mode
  public updateSelResourceList(resource: Resource) {
    this.resourceService.updateSelResourceList(resource);
  }
  public addEmailResource() {
    _.uniqBy(this.emailResource, r => r.ioResourceId != null ? r.ioResourceId : r.ioDocumentId);
    this.trackingService.tracking('EmailAttachmentsAdded', TrackingEventNames.EMAIL);
    if (this.emailService.selectedActivity && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {

      if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(async () => {
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        await this.uiService.displayLoader();
        this.emailService.detectResourceAddedOrRemoved(this.emailResource).then(async () => {
          await this.uiService.dismissLoader();
        }).catch(async () => {
          await this.uiService.dismissLoader();
        });
      });
      return;
      
    }
    //this.navService.popChildNavCtrlFromStack(ChildNavNames.ResourceToolNavigation);
    this.navService.popWithPageTracking().then(async() => {
      
      if(this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showRightPane = true;
      } else {
        this.uiService.activeView = this.uiService.prevView;
      }
      this.emailService.setCurrentEmail(this.emailService.selectedActivity);
      await this.uiService.displayLoader();
      this.emailService.detectResourceAddedOrRemoved(this.emailResource).then(async () => {
        await this.uiService.dismissLoader();
      }).catch(async() => {
        await this.uiService.dismissLoader();
      });
    });
  }

  public addSelectedEmailResource() {
    if (this.emailService.selectedActivity) {
      this.emailResource = [];
      this.emailService.selectedActivity.emailAttachments.map(el => {
        let i = this.resourceService.allResources.find(v => {
          return (v.ioResourceId === el.indskr_resourceid) || (v.ioDocumentId === el.indskr_resourceid)
        });
        if (i && (this.emailResource.findIndex(c => {
          return (c.ioResourceId === el.indskr_resourceid) || (c.ioDocumentId === el.indskr_resourceid)
        }) === -1)) {
          this.emailResource.push(i);
        }
      });
      this.emailResourceCopy = _.cloneDeep(this.emailResource);
    }
    else
      return;
  }

  get disableDoneBtn() {
    return _.isEmpty(this.emailResource) || _.isEqual(this.emailResourceCopy, this.emailResource);
  }

  checkResourceSelected(resource: Resource): boolean {
    if (this.emailResource && this.emailResource.length) {
      let x = this.emailResource.find(e => { return e.assetID === resource.assetID });
      if (x) {
        return true;
      }
      else
        return false;
    }
    else
      return false;
  }
  getSectionHeadingText() {
    let str = ''
    if (this.resourceFilter.brands) {
      str = this.resourceFilter.brands;
      if (this.resourceFilter.assetType) str += ', ' + this.resourceFilter.assetType;
    }
    else if (this.resourceFilter.assetType) str = this.resourceFilter.assetType;
    else if (this.resourceFilter.title) str = this.translate.instant('RES_RESULTS')
    else str = this.translate.instant('ALL_RESOURCES');
    return str.toUpperCase();
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if (this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  searchResourcesList() {
    //second level search for resources
    this.searching = true;
    let filteredResources: any = this.resources;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o => o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredResources = filteredResources.filter(resource => {
          let resourceEntityLevel = this.getOjectValues(resource);
           return resourceEntityLevel.includes(searchText);
         });
      });
    }
    filteredResources = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredResources);
    this.filteredList = filteredResources;
    this.filterMetadata.count = this.filteredList.length;
    this.onSearchBlur(null);
  }

  searchResourceSuggestions(resource: Resource[], searchText: string, event?): Resource[] {

    if (searchText && resource) {
      searchText = searchText.toUpperCase();
      let obj = {
        categoryDisplayName : this.translate.instant("Tag"),//"Tag",
        categoryName  : "Tag",
        categoryRelativePath  : "userTag.indskr_name",
        // featureActionDependant  : "Tag",
        translationKey  : "Tag",
        values  : []
      }
      obj.values=this.userTagService.resourceTag.map(x=>x.indskr_name);
      let copyAccountSearchConfig = JSON.parse(JSON.stringify(this.searchConfigService.resourcesSearchIndexesConfig))  
      copyAccountSearchConfig.push(obj);
      //first search categories and category values
      let searchIndexConfig = copyAccountSearchConfig//this.searchConfigService.resourcesSearchIndexesConfig;

      if (searchIndexConfig.find(config => config.categoryName == 'Types').values.length > 0) {
        let typesSearched = searchIndexConfig.find(config => config.categoryName == 'Types');
        typesSearched.values.forEach((value, index) => {
          if (value == 'document') {
            typesSearched.values[index] = this.translate.instant('R_DOCUMENT');
          } else if (value == 'image') {
            typesSearched.values[index] = this.translate.instant('R_IMAGE');
          } else if (value == 'video') {
            typesSearched.values[index] = this.translate.instant('VIDEO');
          } else if (value == 'web') {
            typesSearched.values[index] = this.translate.instant('R_WEB');
          } else if (value == 'pdf' || value == 'Pdf') {
            typesSearched.values[index] = this.translate.instant(value.toUpperCase());
          } else if (value == 'other') {
            typesSearched.values[index] = this.translate.instant('OTHER');
          }
        })
      }
      // if(this.uiService.isConsentFromToolDrawer) searchIndexConfig = searchIndexConfig.filter(config=>!config.customizedBypassGateEnabled);
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig, this.selectedSuggestionsData, this.translate.instant("RESOURCES"), this.resourceSearchText);
      
      
      //testing for delete
      this.suggestionsData.filter(y=>y.header).filter(y=>y.header.title=="Tag" || y.header.title == this.translate.instant("Tag")).forEach(t=>t.listData.forEach(y=>{
        let tag=this.userTagService.resourceTag.find(x=>x.indskr_name==y.primaryTextLeft)
        if(tag){
          y.showArrow = tag.visibility == TagVisibility.PERSONAL;
          y.arrowType="indegene-ios-close-icon";
          y.tagDetails= tag;
          y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
            if (specificTarget == 'endArrow') {
              this.deleteTag(tag.tagDetails);
            } 
            else if (specificTarget == 'mainItem'){
              this.handleTagSelection(tag.tagDetails);
            }
          })
        }  
      }));
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = resource;
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
      this.filteredList = this.filteredList.filter(resource => {
        let resourceString = this.getOjectValues(resource);
        return resourceString.includes(formattedSearchText);
      });
    } else {
      return [];
    }
    this.filterMetadata.count = this.filteredList.length;
  }
  private deleteTag(userTagDetails: any) {
    this.alertService.showAlert({
      title: this.translate.instant('DELETE_TAG'),
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('TAG_DELETE_CONFIRMATION'),
    }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
           if(userTagDetails.entity && userTagDetails.entity == TagEntityType.RESOURCE){
            let deleteTagPayload = new UserTag(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,TagEntityType.RESOURCE,'');
            this.userTagService.deleteTag(deleteTagPayload);
          }
        }
      });
  }
  getOjectValues(resObject): string {
    let objString: string[] = [];
    let rscInclude = ['name', 'raw'];
    for (let resource in resObject) {
      if (rscInclude.indexOf(resource) > -1 && resObject[resource]) {
        if (Array.isArray(resObject[resource])) {
          for (let i = 0; i < resObject[resource].length; i++) {
            objString.push(this.getOjectValues(resObject[resource][i]));
          }
        } else {
          if (resource == "raw") {
            for (let subKey in resObject[resource]) {
              if (subKey == "indskr_ckmtitle") {
                objString.push(resObject[resource]["indskr_ckmtitle"])
              }
            }
          } else {
            objString.push(resObject[resource]);
          }
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  ionSearchFocus(ev) {
    try {
      let el = this.resourceListElement.nativeElement.ownerDocument.getElementById('allResources');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickSearchArea(ev) {
    ev.stopPropagation();
    if (!this.suggestionsActive) {
      this.suggestionsActive = true;
      if (!this.resourceService.recentSearches || !this.resourceService.recentSearches.length && _.isEmpty(this.userTagService.resourceTag)) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Resources')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
  }

  onInput(event) {
    this.searchKey = '';
    if (!this.shouldFireSearchChangeEvent) {
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.target && event.target.value) ? event.target.value : '';

    //for length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true;
      this.searchKey = params;
      this.searchResourceSuggestions(this.resources, params, event);
    }
    // for length 0 to 2
    else {
      this.suggestionsData = [];
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = []
        this.searching = false;
        // this.handleSearch();
        this.sortResourcesBySortSelection(this.sortBy);
      }
      else {
        this.searchResourcesList();
      }
      if (!this.resourceService.recentSearches || !this.resourceService.recentSearches.length) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Resources')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
    this._cd.detectChanges();
    this.onSearchBlur(null);
  }

  removeAllSelectedSuggestions() {
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.resourceSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.visibleFilteredList = []
    // this.presentationSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.filtering =false;
    // this.contactService.isInGlobalSearch = false;
    this.showRemoveTag = false;
    this.resourceService.isSelectMode = false;
    // this.updateContactToDisplay();
    this.onSearchBlur(null);
  }

  removeSelectedSuggestion(suggestion) {
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData, (o) => _.isEqual(o, suggestion));
    if(suggestion.categoryName === 'tag' || suggestion.categoryName === 'Tags') {
      this.showRemoveTag = false;
      this.resourceService.isSelectMode = false;
      this.cancelTagSelection();
    }
    if (this.selectedSuggestionsData.length == 0) {
      //this.content.resize();
      this.filteredList = [];
      this.resourceSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
    
    }
    else {
      this.searchResourcesList();
    }
    this.onSearchBlur(null);
  }

  async saveAdvancedSearch(failedText?: string) {
    let saveSearchName: string
    let toolName;
    toolName = this.searchConfigService.toolNames.find(o => o.toolName == 'Resources')
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o => o.searchToolName == toolName.searchToolNameID);
    this.alertService.showAlert({
      title: this.translate.instant('SAVE_SEARCH'),
      subTitle: failedText ? failedText : '',
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
      inputs: [{ type: 'text', name: "name", placeholder: this.translate.instant('ENTER_NAME') }]
    }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if (res.data && res.data.values.name) {
            saveSearchName = res.data.values.name.trim();
            let sameNameSearches = currentSavedSearches.filter(o => o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0);
            let incrementNumber: number = 1;
            if (sameNameSearches.length) {
              saveSearchName += ' (';
              _.each(sameNameSearches, (searchData => {
                if (searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0) {
                  let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                  if (!isNaN(currentIncrement) && currentIncrement >= incrementNumber) incrementNumber = currentIncrement + 1;
                }
              }))
              saveSearchName += incrementNumber + ')';
            }
            this.disableSaveSearch = true;
            await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
              toolName ? toolName.searchToolNameID : '',
              UserSavedSearchTypes.OWNED,
              this.selectedSuggestionsData.slice())
          }
          else if (res.data && res.data.values.name == "") {
            this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
          }
        }
      })
  }

  clickedInSuggestionsArea(ev) {
    ev.stopPropagation();
  }

  handleFacetSelection(data: SelectedSuggestionPillDataModel) {
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a, b) => {
      if (a.createdOn < b.createdOn) return 1
      else return -1
    })
    ///if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.resourceSearchText = '';
    this.suggestionsData = [];
    this.shouldFireSearchChangeEvent = false;
    this.searchResourcesList();
    if (!data.isComingFromRecentSearch) {
      let recentSearches;
      recentSearches = this.resourceService.recentSearches
      if (!recentSearches.some(recSearch => recSearch.categoryName == data.categoryName && recSearch.selectedFacet == data.selectedFacet)) {
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index) => index > 2);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.RESOURCE_RECENT_SEARCHES, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            }
          }
          doc.raw = recentSearches;
          return doc;
        })
      }
    }
    if(data.categoryName=="Tags"){
      // this.handleTagSelection(this.selectedTagData);
      this.suggestionsActive = false;
      this.showRemoveTag = true;
      this.searching = true;
      this.filteredList =[];
    }
  }

  handleSavedSearchSelection(data) {
    this.selectedSuggestionsData = [];
    if (data && data.categoryValuePairs) {
      data.categoryValuePairs.forEach(catValPair => {
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.resourceSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchResourcesList();
      // this.searchResoureListfilter();
      this._cd.detectChanges();
      this._cd.markForCheck();
    }
  }

  public selectMode() {
    this.resourceService.isSelectMode = !this.resourceService.isSelectMode;
    this.initAllResourcesSectionHeader();
    this.resources.map(res => res.isSelected = false);
    this.filteredList.map(pres => pres.isSelected = false);
    this.isSelectAll = false;
    this.selectedResourceList = [];
    this.sliceRecordsForInfiniteScroll();
  }

  public selectAll() {
    if (this.isSelectAll == true) {
      this.selectedResourceList = [];
      if (this.filteredList.length) {
        this.filteredList.map(res => {
          res.isSelected = true
          this.selectedResourceList.push(res);
        });
      } else {
        this.resources.map(res => {
          res.isSelected = true
          this.selectedResourceList.push(res);
        });
      }
    } else {
      this.resources.map(res => {
        res.isSelected = false;
      });
      this.selectedResourceList = [];
    }
    this.sliceRecordsForInfiniteScroll();
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  public get hasSelectedResources(): boolean {
    return this.selectedResourceList.length > 0;
  }

  public get downloadButtonState() {
    return this.selectedResourceList.some(resource => resource.downloaded != true);
  }

  public updateSelectedResource(res: Resource) {

    const element = this.selectedResourceList.find(r => r == res);
    if (!element) {
      this.selectedResourceList.push(res);
    } else {
      const index = this.selectedResourceList.indexOf(res);
      this.selectedResourceList.splice(index, 1);
    }
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  public bulkDownloadResources() {
    if (this.selectedResourceList.length < 1 || this.deviceService.isOffline || !this.deviceService.isNativeApp) {
      return;
    }
    const orderBy = new OrderByPipe();
    const seletedResources = orderBy.transform(this.selectedResourceList, this.sortBy['value'], true);
    seletedResources.forEach(res => {
      const id = res.ioResourceId ? res.ioResourceId : res.ioDocumentId;
      if (!res.downloaded) {
        if (res.assetURL) {
          this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: id }));
        } else {
          this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + res.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
        }
      } else {
        this.resourceDataService.deleteDownloadedResource(id);
      }
    });
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
  public downloadResource(resource: Resource) {
    this.trackingService.tracking('ResourceDownlaod', TrackingEventNames.RESOURCES);
    const resourceId: string = resource.ioResourceId ? resource.ioResourceId : resource.ioDocumentId;
    if ((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !resource.downloaded) {
      this.notificationService.notify(this.translate.instant('CANNOT_DOWNLOAD_RESOURCE_WHILE_OFFLINE'), "Resource List", "top", ToastStyle.DANGER, 2000, true);
      return;
    }

    if (!resource.downloaded) {
      this.notificationService.notify((this.translate.instant('DOWNLOADING') + ' ' + resource.title), 'Resource Download');

      if (resource.assetURL) {
        this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: resourceId }));
      } else {
        this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + resource.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
      }
    } else {
      this.notificationService.notify(this.translate.instant('SUCCESSFULLY_REMOVED') + ' ' + resource.title, "Resource List");
      this.resourceDataService.deleteDownloadedResource(resourceId);
    }
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.resourceService.isSelectMode = false;
  }

  private initResourceHeaderLeft(): void {
    let buttons;
    if(this.resourceService.viewMode=== this.resourceMode.DOCUMENTS
        || this.resourceService.viewMode=== this.resourceMode.ADDTOEMAIL
        || this.resourceService.viewMode=== this.resourceMode.PUSH) {
      buttons = [{
        id: "cancel",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_cancel.svg',
        isDisabled: false,
        align: "left",
      }];
    } else {
      buttons = [{
        id: "cancel",
        // cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      }];
    }
    if (this.isAddToEmail) {
      buttons.push({
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: this.disableDoneBtn,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'resource-list-header-left',
      cssClass: (this.resourceService.viewMode == this.resourceMode.MENU)?'main-tool-header-title':'leftScreenHeaderTitle',
      title: this.listTitle,
      mode: false,
      customHeaderProps: {
        hasCancel: (this.allowPushResource || this.isAddToEmail || this.resourceService.viewMode != this.resourceMode.MENU)
      },
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack();
        break;
      case 'done':
        if (!this.isAddToEmail) return;
        this.addEmailResource();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  initAllResourcesSectionHeader() {
    this.allResourcesHeaderModel = {
      id: 'all-resources-header',
      title: '',
      isMultiFilter: true,
      controls: [],
    };
    if (!this.resourceService.isSelectMode) {
      this.allResourcesHeaderModel.controls.push({
        id: 'resource-sort',
        text: this.sortBy?.text,
        isDisabled: false,
        icon:'assets/imgs/sort_with_double_arrows.svg'
      });
    }
  }

  onSectionHeaderControlClick(event) {
    if (event == 'resource-sort') {
      this.sort();
    }
  }

  doInfinite(eventDetail, event) {
    let count = this.slicedResources.length;
    this.slicedResources.push(...this.resources.slice(count,  count + 15));
    event.target.complete();
  }

  sliceRecordsForInfiniteScroll() {
    this.resources = this.callbackPipe.transform(this.resources, this.filterByDate);
    if (this.sortBy && this.sortBy.value !== "$downloaded") {
      this.resources = this.orderBy.transform(this.resources, this.sortBy.value, this.sortBy.asc)
    }
    this.slicedResources = this.resources.slice(0, this.sliceCount);
  }
  cancelTagSelection(){
    this.selectedResourceList =[];
    this.selectMode();
  };
  createNewTag(failedText?: string) {
    this.alertService.showAlert({
      title: this.translate.instant("CREATE_TAG"),
      subTitle: failedText ? failedText : "",
      cssClass:'saveSearchAlert',
      inputs: [{ type: "text", name: "name", placeholder: this.translate.instant("ENTER_TAG_NAME") }]
    }, this.translate.instant("CREATE"), this.translate.instant("CANCEL"))
      .then(res => {
        if (res.role == "ok") {
          if (res.data && res.data.values.name) {
            let selectedPresentationtag = this.selectedResourceList;
            // console.log("createNewTagAlert res", res, selectedContactsForTag);
            const selectedAccounts = selectedPresentationtag.map(res => {
              return res.ioResourceId ? new EntityTag(res.ioResourceId, false, "RESOURCE") : new EntityTag(res.ioDocumentId, false, "DOCUMENT");
            });
            let payload = new
              UserTag(null, null, false,
                res.data.values.name, selectedAccounts,
                false, TagStateCode.Active, TagEntityType.RESOURCE,TagVisibility.PERSONAL);
            this.userTagService.createOrUpdateTag(payload, true).then((reponse) => {
              if(reponse.length) {
                payload.indskr_usertagid = reponse[0].indskr_usertagid;
                payload.indskr_externalid = reponse[0].indskr_externalid;
              }
              this.userTagService.resourceTag.push(payload);
              this.userTagService.resourceTag =  _.sortBy(this.userTagService.resourceTag , "indskr_name")
              this.cancelTagSelection();
              console.log("createNewTagAlert response", reponse);
            }).catch(error => {
              console.log("createNewTagAlert error ", error);
            });
            console.log(payload);
          } else {
            this.createNewTag(this.translate.instant("TAG_NAME_IS_REQUIRED"));
          }
        }
      });
  }
  updateTagWithSelectedRes(tagData: UserTag) {
    // let selectedContactsForTag = this.contactsToDisplay.filter((contact) => contact.isSelectedForTag);
    // if(this.slicedSearchedContactsToDisplay?.length) {
    //   selectedContactsForTag = this.slicedSearchedContactsToDisplay.filter((contact) => contact.isSelectedForTag);
    // }
    let selectedResourcentag = this.selectedResourceList;
    const selectedRes = selectedResourcentag.map(resource => {
      // return new EntityTag(contact.ioResourceId);
      return resource.ioResourceId ? new EntityTag(resource.ioResourceId, false, "RESOURCE") : new EntityTag(resource.ioDocumentId, false, "DOCUMENT");
    });
    let differenceBySelectedRes = _.differenceBy(selectedRes, tagData.entityRecords, "id");
    tagData.entityRecords.push(...differenceBySelectedRes);
    this.userTagService.createOrUpdateTag(tagData).then((response) => {
     // this.handleTagSelection(tagData);
     this.userTagService.resourceTag.find(tag => tag.indskr_usertagid === tagData.indskr_usertagid).entityRecords = tagData.entityRecords;
      this.cancelTagSelection();
    }).catch((error) => {
      console.log("updateTagWithSelectedContacts error", error);
    })

  }
  
  async addToTag() {
    let filteredTagData: UserTag[] = !_.isEmpty(this.userTagService.resourceTag) ? _.cloneDeep(this.userTagService.resourceTag).filter(tag => tag.visibility == TagVisibility.PERSONAL) : [];
    let tagDataForModifiedTagFlag: UserTag[] = !_.isEmpty(this.userTagService.resourceTag) ? _.cloneDeep(this.userTagService.resourceTag).filter(tag => tag.allowUserToModifytag == true) : [];
    filteredTagData = _.concat(filteredTagData,tagDataForModifiedTagFlag);
    if (!_.isEmpty(filteredTagData)) {
      filteredTagData.sort((a, b): number => {
        if (a.indskr_name > b.indskr_name) return 1;
        if (a.indskr_name < b.indskr_name) return -1;
        return 0;
      });
    }
    
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('RESOURCE_TAGS'),
      dividerTitle: this.translate.instant('ALL_RESOURCE_TAGS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleResourceTagsComponentEvent(data, eventTarget),
      searchHandler: (text: string) => this._handleResourceTagsSearch(text, filteredTagData),
      data: filteredTagData.map(tag => {
        let isSelected = false;
        return {
          id: tag.indskr_usertagid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: tag.indskr_name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };

    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private _handleResourceTagsSearch(text: string, resourceTags): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = resourceTags && resourceTags.filter(tag => {
        return tag.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(tag => tag.indskr_usertagid);
    } else {
      ids = resourceTags && resourceTags.map(tag => tag.indskr_usertagid);
    }
    return ids;
  }

  private async _handleResourceTagsComponentEvent(data: any, eventTarget: string) {
    if (!eventTarget || eventTarget !== 'RightHeaderButtonClick' || !data.selectedItems || data.selectedItems.length === 0) return;
    const selectedTag = this.userTagService.resourceTag.find((tag) => tag.indskr_usertagid === data.selectedItems[0].id);
    if (!selectedTag) return;
    this.updateTagWithSelectedRes(selectedTag);
  }


  public handleTagSelection(tagData: UserTag) {
    if (!tagData) return;
    this.suggestionsActive = false;
    // this._selectedPresentationForTag = [];
    this.showRemoveTag = tagData.visibility == TagVisibility.PERSONAL;
    this.searching = true;
    this.filteredList =[];
    tagData.entityRecords.forEach(record => {
      let resource = this.resourceService.getResourceById(record.id,record.id);
      if (resource) {
        resource.isSelected = false;
        this.filteredList.push(resource);
      }
    });
    this.filteredList = _.uniqBy(this.filteredList,"assetID");
    this.filterMetadata.count = this.filteredList.length;
    // this.visibleFilteredList = this.slicePresentations(0, this.filteredRecordCount);
    // this.updateContactToDisplay();
    const selectedPill: SelectedSuggestionPillDataModel = this.getTagSuggestionPill(tagData);
    //Only one tag can be filtered at a time
    this.selectedSuggestionsData = this.selectedSuggestionsData.filter(pill => pill.categoryName !== selectedPill.categoryName);
    this.selectedSuggestionsData.push(selectedPill);
    this.selectedTagData = _.cloneDeep(tagData);
  }
  // private slicePresentations(startIndex: number, count: number) {
  //   return this.contents.length < startIndex + count ? this.contents.slice(startIndex) : this.contents.slice(startIndex, startIndex + count);
  // }
  private getTagSuggestionPill(tagData: UserTag): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: tagData.indskr_name,
      categoryPath: 'tag',
      categoryName: 'tag',
      categoryDisplayName: 'tag',
    };
  }

  removeContactsFromTag(selectedTagData: UserTag) {
    if (selectedTagData) {
      if (selectedTagData.visibility != TagVisibility.PERSONAL) return;
      this.selectedResourceList.forEach(resource => {
        let record
        if (resource.ioResourceId) {
          record = selectedTagData.entityRecords.find(selectedTagDataContact => selectedTagDataContact.id === resource.ioResourceId);
        } else if (resource.ioDocumentId) {
          record = selectedTagData.entityRecords.find(selectedTagDataContact => selectedTagDataContact.id === resource.ioDocumentId);
        }
        if (record) {
          record.deleted = resource.isSelected;
        }
      })
    }
    this.userTagService.createOrUpdateTag(selectedTagData).then((reponse) => {
      if (this.selectedResourceList.length) {
        selectedTagData.entityRecords = selectedTagData.entityRecords.filter(record => !record.deleted);
      }

      let tag = this.userTagService.resourceTag.find(tag => tag.indskr_usertagid === selectedTagData.indskr_usertagid);
      if (tag) {
        tag.entityRecords = selectedTagData.entityRecords;
      }
      this.cancelTagSelection();
      this.removeAllSelectedSuggestions();
      this.handleTagSelection(selectedTagData);
    }).catch((error) => {
      console.log(" removeFromTag error", error);
    })
  }

  async openFilterMenu(event) {
    this._initiateMyPresentationFilter();
  }


  private async _initiateMyPresentationFilter() {
    let options;
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.ResourcePageComponent,
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;
          data.selectedItems.forEach((selected) => {
            if (!this.selectedFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
              this.selectedFilterData.unshift(selected);
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
          this.suggestionsActive = false;
          (this.selectedFilterData.find(x=>x.categoryName=="Tags"))?this.disableSaveSearch=false:this.disableSaveSearch=true;
          this.searchResoureListfilter(true);
          this.selectedFilterData.sort((a, b) => {
            if (a.createdOn < b.createdOn) return 1
            else return -1
          });
        }
      }
    });
  }
  private _updateFilterNameBubble(item) {

    let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
    if (idx > -1) this.filterNameBubble[idx].suggestion.push(item);
    else {
      let selected = { categoryName: item.categoryName, suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        if(item.isSavedFilter) {
          selected.isSavedFilter = true;
          selected.savedFilterName = item.savedFilterName;
        }
      this.filterNameBubble.push(selected);
    }

  }
  private clearFilters() {
    this.filtering = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    if (this.selectedSuggestionsData.length == 0) {
      this.removeAllSelectedSuggestions();
    } else {
      // this.searchResoureListfilter();

    }
    // this.updateContactToDisplay();
  }
  searchResoureListfilter(isFilter?: boolean){
    //second level search for presentations
    this.searching = true;
    let filterResource =[];
    if (isFilter) this.filtering = true;
    else this.searching = true;
    let selectedData: SelectedSuggestionPillDataModel[] = [];
    if (this.filtering && this.searching) {
      selectedData = _.uniq(_.concat(this.selectedFilterData, this.selectedSuggestionsData));
    } else {
      selectedData = isFilter ? this.selectedFilterData : this.selectedSuggestionsData;
    }
    selectedData = this.mapForUserTag(selectedData)
    let tagSuggestion = selectedData.filter(o=>(o.categoryName=='Tag'||o.categoryName=='tag'|| o.categoryName == this.translate.instant("Tag")));
    if(tagSuggestion.length){
      let filteredAccountsWithinTagCategory: any = [];
     
      let results = selectedData.map(x=>x.selectedFacet);
      results.forEach(id=>{
        let thisContact = this.resourceService.getResourceById(id,id);
        if(!_.isEmpty(thisContact)) {
          // if(!_.isEmpty(filteredAccountsWithinTagCategory) && !filteredAccountsWithinTagCategory.some(acct=>acct.id == thisContact.id)) {
            filteredAccountsWithinTagCategory.push(thisContact);
          // }else {
            // filteredAccountsWithinTagCategory.push(thisContact);
          // }
        }
      });
      filterResource =  filteredAccountsWithinTagCategory;
    }
    // filteredPresentations = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredPresentations);
    this.filteredList = filterResource;
    this.filterMetadata.count = this.filteredList.length;
    // this.visibleFilteredList 
    const tagSuggestionIndex = selectedData.findIndex(data => data.categoryName === 'tag' || data.categoryName == this.translate.instant("Tag"));
    if (tagSuggestionIndex >= 0) {
      this.selectedTagData =this.userTagService.PresentationTags.find(tag=>tag.indskr_name === selectedData[tagSuggestionIndex].selectedFacet)
      if (this.selectedTagData) {
        this.handleTagSelection(this.selectedTagData);
      }
    }
    // this.updateContactToDisplay();
    this.onSearchBlur(null);
}
removefilterSelectedSuggestion(suggestion, filter) {
  this.disableSaveSearch = false;
  let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
  _.remove(this.selectedFilterData, (o) => _.isEqual(o, suggestion));
  _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion, (o) => _.isEqual(o, suggestion));
  if (_.isEmpty(selectedSuggestions)) {
    let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
    if (index > -1) this.filterNameBubble.splice(index, 1);
  }
  _.remove(this.selectedSuggestionsData, (o) => _.isEqual(o, suggestion));
  if (this.selectedFilterData.length == 0 && this.selectedSuggestionsData?.length == 0) {
    this.filteredList = [];
    this.visibleFilteredList = []
    // this.updateContactToDisplay();
    // this.presentationSearchText = '';
    this.searching = false;
    this.filtering = false;
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.filtering = false;
    this.resourceService.isSelectMode = false;
    // this.updateContactToDisplay();
  }
  else if (this.selectedFilterData.length == 0) {
    this.filtering = false;
    this.searchResoureListfilter();
  } else {
    this.searchResoureListfilter(true);
  }
  // this.updateContactToDisplay();
  this.onSearchBlur(null);
}
// public updateContactToDisplay() {
//   this.recordCount = 0;
//   this.filteredRecordCount = 0;
//   if(this.searching || this.filtering) {
//       this.visibleFilteredList = this.sliceSearchedPresentations(this.filteredRecordCount,20);
//       this.viewPres = this.visibleFilteredList;
//   } else {
//       this.presToDisplay = this.slicePresentations(this.recordCount, 20);
//       this.viewPres = this.presToDisplay;
//   }
//   try {
//       if (this.listTop && !this.scrollingTop) {
//         this.scrollingTop = true;
//         this.listTop.scrollToTop()
//           .then(res => this.scrollingTop = false)
//           .catch(err => { this.scrollingTop = false; console.log("scroll error", err) });
//       }
//     } catch (error) {
//       this.scrollingTop = false;
//       console.log("scroll error", error);
//     }
// if (this.resultPresentationHeader) {
//   this.resultPresentationHeader.title = this.translate.instant("RESULTS") + " (" + this.filterMetadata.count + ")";
//   this.resultPresentationHeader.controls[0].text = this.sortBy.text;
// }
// if (this.allPresentationHeader) {
//   this.allPresentationHeader.title = this.allPresentationSectionTitle;
//   this.allPresentationHeader.controls[0].text = this.sortBy.text;
// }

// }
mapForUserTag(selectedData){
  let tagIds=[];
  let copySelectedData : SelectedSuggestionPillDataModel[] = [];
  copySelectedData= selectedData.filter(x=>x.categoryName!="Tag");
  let tagData=selectedData.filter(x=>x.categoryName=="Tag" || x.categoryName == this.translate.instant("Tag"))
  tagData.forEach(c=> {
    const foundTag = this.userTagService.resourceTag.find(v=>v.indskr_name==c.selectedFacet)?.entityRecords;
    if(foundTag) tagIds.push(foundTag);
  })
  let contactIds=tagIds.flat().map(c=>c.id);
  contactIds = [...new Set(contactIds)];
  if(contactIds.length<=0){
    return selectedData;
  }
  contactIds.forEach(x=>{
   let  data : SelectedSuggestionPillDataModel = {
     selectedFacet: x,
     categoryPath: "accountid",
     categoryName: "Tag",
     categoryDisplayName: "Tag"
   } ;
   data.isSubCategory = false
   copySelectedData.push(data);
  })
  return copySelectedData;
}
onSearchBlur(event){
  if(this.resourceSearchText && this.resourceSearchText.length>0){
    this.hideFilterButton = true;
  }
  else{
    this.hideFilterButton = false;
  }
}
onDefaultFilterLoad(){ 
  let toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Resources');
  let search = this.searchConfigService.savedSearches.filter(x=>x.searchToolName == toolName.searchToolNameID ).find(x=>x.indskr_setfilter == true);         
  if(search){
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    this.filtering = true;
    this.selectedFilterData = search.categoryValuePairs;
    this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
    this.searchResoureListfilter();
  }
}

  getFilterButtonBadgeCount() {
    return this.selectedFilterData?.length;
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }

}
//f75445e
