import { GPSActivityApprovalStatus, GPSActivityPhoto } from './../../../classes/store-check/photo';
import { AccountPlansSelectionPageModel } from "@omni/models/account-plans-selection-page-model";
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { AccountManagementPageComponent } from "@omni/pages/account-management-page/account-management-page";
import { FieldMaterialManagementPageComponent } from './../../field-material-management/field-material-management-page/field-material-management-page';
import { AssetSelectionViewDataModel } from './../../../models/meeting-assets-selection-model';
import { CustomerAsset } from './../../../classes/field-materials/customer-asset.class';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import { AccountPlan } from "@omni/classes/account-management/account-plan.class";
import {
  MAXIMUM_NOTE_ATTACHMENT_SIZE,
  NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX,
  Utility,
  shortMonthArray, toBase64
} from '@omni/utility/util';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { debounceTime, distinctUntilChanged, filter, first, map, skip, takeUntil } from 'rxjs/operators';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  Output,
  ViewChild
} from '@angular/core';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AlertController, IonContent, LoadingController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ConfiguredFields,hcpInteractionDefaultField } from '../../../classes/authentication/configured.field.class';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { addDays, addMinutes, addMonths, differenceInCalendarDays, differenceInHours, differenceInMinutes, format, isAfter, isBefore, isEqual, lastDayOfMonth } from 'date-fns';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import {
  Activity,
  ActivityOutcome,
  ActivityOutcomeType,
  ActivityType,
  ActivityTypeCodeRaw,
  ConfigFieldOptionValue,
  FormatType,
  MeetingActivityState,
  MeetingActivityType,
  MeetingActivityTypeCode
} from '../../../classes/activity/activity.class';
import { ContactOfflineService } from '../../../services/contact/contact.service';
// import { NavController } from "@ionic/angular/navigation/nav-controller";
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { ComponentViewMode, PresentationView, UIService } from '../../../services/ui/ui.service';
import { DEVICE_ORIENTATION, DeviceService } from '../../../services/device/device.service';
import { LogService } from '../../../services/logging/log-service';
import { PopoverComponent, SelectListData } from '../../popover/popover';
import { LocationComponent } from '../../location/location';
import { MeetingDataService, UpdateMeetingPayload, UpdateTypeAndSubTypeActivityPayLoad } from '../../../data-services/meeting/meeting.data.service';
import { MeetingStatus } from '../../../classes/meeting/meeting.class';
import { AppointmentActivity, CHECKOUT_OPTIONS, CovisitorAccess, InStorePhoto, OFFLINE_ID_PREFIX } from '../../../classes/activity/appointment.activity.class';
import { RemoteURLEmailTemplate } from '../email-template/email-template';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { PresentationPageComponent } from '../../../pages/presentation/presentation';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { WebsocketDataService } from '../../../data-services/websocket/websocket.data.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { InboundMeetingControllerService } from '../../../services/meeting/inbound-meeting.controller.service';
import { EmailTemplateServices } from '../../../data-services/email-templates/email-template.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { AccompainedUser } from '../../../classes/activity/accompained-user.class';
import { Subject, Subscription } from 'rxjs';
import { IOMultiSelect } from '../../io-component/io-multiselect/io-multiselect';
import { IoUserSelection } from '../../../services/io-userselection/io-userselection.service';
import { COACHING_VIEW_TYPE, CoachingReportService } from '../../../services/coaching/coaching.report.service';
import { Report } from '../../../classes/coaching/report.class';
import { CoachingDetailsComponent } from '../../coaching/coaching-details/coaching-details';
import { FeatureActionsMap, MeetingProximityActionOption } from '../../../classes/authentication/user.class';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { EventName, EventsService } from '../../../services/events/events.service';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { SampleActivity } from '../../../classes/activity/sample.activity.class';
import { InMeetingAllocationComponent } from '../../in-meeting-allocation/in-meeting-allocation';
import { ContentMatchingService } from '../../../services/content-matching/content-matching.service';
import * as _ from 'lodash';
import { EmailTemplateType } from '../../../classes/email-templates/email-template.class';
import { EmailActivityChannelDetails } from '../../../classes/consent/channel.class';
import { DirectMessagingService } from '../../../services/direct-messaging/direct-messaging.service';
import { TherapeuticArea } from '../../../classes/therapeutic-area/therapeutic-area.class';
import { TherapeuticAreaDataService } from '../../../data-services/therapeutic-area/therapeutic-area.data.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { MeetingStructureService } from '../../../services/meeting-structure/meeting-structure.service';
import { REP_STATUS } from '../../../models/rep-status-model';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { EmailService } from '../../../services/email-templates/email.service';
import { Contact } from '../../../classes/contact/contact.class';
import { FooterToolbarComponent } from '../../footer-toolbar/footer-toolbar';
import { AccesingMode, CaseManagementService } from '../../../services/case-management/case-management.service';
import moment from 'moment';
import { AlertService } from '@omni/services/alert/alert.service';
import { NewActivityComponent } from '../new-activity/new-activity';
import { BusinessProcessViewDataModel } from '@omni/models/business-process-view-data.model';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { NextCallObjectiveComponent } from '@omni/components/next-call-objective/next-call-objective';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { DatePipe } from '@angular/common';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import {
  CurViewPageType,
  DateTimeFieldType,
  IndDatetimeFormComponent
} from './../../shared/ind-datetime-form/ind-datetime-form';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { PollService } from '@omni/services/poll/poll.service';
import {
  checkTimeConflictWithOtherActivities,
  removeConflictIdFromOtherActivities,
  startRecursiveActivityCloseByActivityFlagging
} from '../../../utility/activity.utility';
import { CheckboxCustomEvent } from '../../../interfaces/shared/shared.interface';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { configFieldSortFn, getConfigFormFieldIsReadOnly, getConfigFormFieldType, getConfigFormFieldViewDataModelId } from '../../../utility/common.utility';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { EventsSelectionViewDataModel } from '@omni/models/events-selection-page-model';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventsToolPageComponent } from '@omni/components/events-tool/event-tool-page/events-tools-page';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { IONote } from '@omni/classes/io/io-note.class';
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { electronApi } from "@omni/services/electron-api";
import { Brand } from "@omni/classes/brand/brand.class";
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { GPS_STATUS, LocationOfflineService } from '@omni/services/location/location.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { ReorderItemsViewDataModel } from "@omni/models/reorderItemsViewDataModel";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { MarketingPlansManagementOfflineService } from "@omni/services/marketing-management/marketing-management.service";
import { MarketingPlanSelectionViewDataModel } from "@omni/models/marketing-plan-selection-page-model";
import { MarketingPlan } from "@omni/classes/marketing-management/marketing-plan.class";
import { MarketingPlanManagementPageComponent } from "@omni/pages/marketing-plan-page/marketing-plan-page";
import { UserTypes } from "@omni/classes/activity/procedure-tracker.activity.class";
import { ProcedureTrackerActivityDataService } from "@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service";
import { CoachingPlan } from "@omni/classes/coaching/coaching-plan.class";
import { IndPhotoCaptureViewerDataModel } from "@omni/models/indPhotoCaptureViewerDataModel";
import { GPSActivity, Photo, PhotoOrigin, PhotoResponse } from '@omni/classes/store-check/photo';
import { IndPhotoCaptureViewerComponent } from "@omni/components/shared/ind-photo-capture-viewer/ind-photo-capture-viewer";
import { IndPhotoViewerDataModel } from "@omni/models/indPhotoViewerDataModel";
import { IndPhotoViewerComponent } from "@omni/components/shared/ind-photo-viewer/ind-photo-viewer";
import { PhotoAttachmentDto } from '@omni/classes/store-check/photo-attachment';
import { AppealService } from '@omni/services/appeal/appeal.service';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SetBookingActivityDetailsComponent } from '../set-booking-activity-detail/set-booking-activity-detail';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { CallPlanDataService } from '@omni/data-services/call-plan/call-plan.data.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { Task } from '@omni/classes/shared/task.class';
declare var event;


/**
 * Generated class for the ActivitiesDetailsPaneComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "activities-details-pane",
  templateUrl: "activities-details-pane.html",
  styleUrls: ['activities-details-pane.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class ActivitiesDetailsPaneComponent {

  textInput: any;

  @Input() showBackButton: boolean = false;
  private _isNewlyCreatedActivity: boolean = false;
  get isNewlyCreatedActivity(): boolean {
    return this._isNewlyCreatedActivity;
  }
  @Input() set isNewlyCreatedActivity(newValue: boolean) {
    this._isNewlyCreatedActivity = newValue;
  }
  @ViewChild('notesInput', { static: true }) notesInput: ElementRef;
  @ViewChild('footer', { read: ElementRef }) footerEl: ElementRef;

  @ViewChild('attachInput') attachInput: ElementRef;
  public annotationId: string = '';
  public attachmentTitle: string = '';
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;

  /**
   * We require products, participants & accounts
   * @memberof ActivitiesDetailsPaneComponent
   */
  public activityDetails: Activity;
  public activityType = ActivityType;
  public meetingState = MeetingActivityState;
  public meetingActivityTypeCode = MeetingActivityTypeCode;
  public previouslySelectedLocation: string;
  public notesModal: string;
  public preSavedNotes: string;
  public firstLevelFormFields:Array<IndFormFieldViewDataModel> = [];
  public secondLevelFormFields:Array<IndFormFieldViewDataModel> = [];
  public meetingTypeFormField:IndFormFieldViewDataModel;
  public accountFormField:IndFormFieldViewDataModel;
  public contactFormField:IndFormFieldViewDataModel;
  public ownerFormField:IndFormFieldViewDataModel;
  public activityTypeFormField:IndFormFieldViewDataModel;
  public activitySubTypeFormField:IndFormFieldViewDataModel;
  public meetingLocationFormField:IndFormFieldViewDataModel;
  public meetingSubjectFormField: IndFormFieldViewDataModel;
  public presentationDurationFormField: IndFormFieldViewDataModel;
  public isNotesEnabled: boolean = false;
  public isCoachingEnabled: boolean = false;
  public isAllocationEnabled: boolean = false;
  public isAutoSubjectEnabled: boolean = false;
  public meetingstate: MeetingStatus;
  public isNotesDirty: boolean = false;
  public blockUI: boolean = false;
  public isRemoteEnabled: boolean = false;
  private selectedActivityDiffer: KeyValueDiffer<string, any>;
  private _screenOrientation$;
  private hasJointMeeting: boolean = false;
  public hasMeetingObjectives: boolean = false;
  public meetingActivityTypes: MeetingActivityType[] = [];
  isGpsEnabled = false;
  isContentRequiredForRemoteChannelEnabled = false;
  isLiveTimeFieldEnabledForF2FMeetings =false;

  //Config Fields
  private appConfigInputText: string;
  private appConfigInputBoolValue: string;
  private appConfigProcessValue: string;
  private appConfigIntegervalue: string;
  private appConfigDecimalvalue: string;
  private appConfigMemovalue: string;
  private appConfigLookupValue: string;
  private appConfigPickValue: ConfigFieldOptionValue;
  private appConfigCurrencyValue: string;
  private formattedDate: string;
  private appConfigDateValue: number;
  private dateEvent: any;
  private dateFieldName: string;
  private isValueUpdated: boolean = false;
  public isCovisitor: boolean = false;

  // configMandatoryFields
  private configMandatoryField: string;
  tooltipEvent: string;
  showArrow: boolean = true;
  duration: number = 1000;
  private configFieldsSubscription: Subscription;
  private coachingActivitySubscription: Subscription;
  public isReadOnlyJointMeeting: boolean = false;
  private isReadOnlyRecommendedMeeting: boolean = false;
  private eventSubscriptions: { unsubscribe: Function }[] = [];
  private ngdestroy$$: any = new Subject<boolean>();
  public isInStoreMeeting: boolean = false;
  public isInStoreChildMeeting: boolean = false;


  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild(FooterToolbarComponent) footerCmp: FooterToolbarComponent;
  @ViewChild(NextCallObjectiveComponent) nextCallObj: NextCallObjectiveComponent;
  @Output() fullPageSelection = new EventEmitter<string>(null);

  public navPageName = PageName;
  private isLayoverPushView: boolean = false;
  private layoverViewPushedFrom: any;
  public currentAllocationsInMeeting: SampleActivity[];
  isKeyMessageSelectionEnabled: boolean = true;
  isStatusEnabled = true;
  isAccountSelectionEnabled: boolean = true
  isLocationEnabled: boolean = true;
  isProcedureSectionEnabled: boolean = false;
  public isSupportingMaterialsEnabled: boolean = false;

  @Input()
  callbackEvent;

  public isTherapeuticAreaEnabled: boolean = false;
  public isDiseaseAreaEnabled:boolean = false;
  public isProductIndicationEnabled:boolean = false;
  public diseaseAreaSectionViewData:ReorderItemsViewDataModel;
  public productIndicationSectionViewData:ReorderItemsViewDataModel;
  public therapeuticAreas: TherapeuticArea[] = [];
  activitiesDetailsPanePageTitle: IndPageTitleViewDataModel;
  private activitiesDetailsPanePageTitleText: string;
  private newActivitySubscription: Subscription;
  isMeetingTypeFAEnabled: boolean = false;
  isMeetingDurationEnabled = false;
  public hasMeetingDurationRequired = false;
  isMeetingEndDateEnabled = true;
  @Input() from: any
  selectedContacts: Contact[] = [];
  @Input() childFrom: PageName;
  public appointmentConfigFields: ConfiguredFields[] = [];
  @ViewChild('appConfigFieldDatePicker', { static: true }) appConfigFieldDatePicker;
  appConfigFieldDate: Date;
  public maxDate: string = moment(new Date()).endOf('month').format();
  public get isLiveMeet() {
    return this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.isLiveMeet;
  }
  ngUnsubscribe$ = new Subject<boolean>();
  shortMonth: string[] = shortMonthArray;
  public meetingProcessView:Array<BusinessProcessViewDataModel> = [];
  public detailsSectionId:string = 'details';
  public objectiveSectionId:string = 'meetingnotes';
  public gpsActivityPhotosSectionId:string = 'gpsActivityPhotos';
  public meetingStSectionId:string = 'meetingstructure';
  public sharedResourcesSectionId:string = 'sharedresources';
  public keyMessagesSectionId:string = 'keymessages';
  public procedureSectionId: string = 'procedures';
  public supportingMaterialsSectionId: string = 'supportingmaterials';
  public therapeuticSectionId:string = 'therapeuticarea';
  public meetingNotesSectionId:string = 'meetingnotes';
  public meetingEventsSectionId:string = 'meetingevents';
  public coachingDetailsSectionId:string = 'coachingdetails';
  public callObjectiveSectionId:string = 'callobjective';
  public meetingOpportunitiesSectionId:string='meetingOpportunitiesSectionId'
  public meetingAssetsSectionId:string='meetingAssetsSectionId'
  public diseaseAreaSectionId:string='diseaseAreaSectionId';
  public productIndicationSectionId:string='productIndicationSectionId';
  public photosSectionId: string = 'photos';
  public isSolicitatedMeetingComplianceCheckEnabled:boolean = false;
  private detectChangesOnActivityDetails$: Subject<boolean> = new Subject<boolean>();
  private calendarInviteModalHandle: any;
  private activityReopenedFromTimeline: boolean = false;
  public meetingStartDateField: IndDateTimeFormViewDataModel;
  public meetingStartTimeField: IndDateTimeFormViewDataModel;
  public meetingEndDateField: IndDateTimeFormViewDataModel;
  public meetingEndTimeField: IndDateTimeFormViewDataModel;
  public meetingDurationField: IndDateTimeFormViewDataModel;
  public opportunitiesSectionTitle: IndSectionHeaderViewDataModel;
  public assetsSectionTitle: IndSectionHeaderViewDataModel;
  public objectiveSectionHeader: IndSectionHeaderViewDataModel;
  public gpsActivityPhotosSectionHeader: IndSectionHeaderViewDataModel;
  public meetingStartDateTime: string;
  public meetingStartDate: string;
  public meetingStartTime: string;
  public meetingEndDateTime: string;
  public meetingEndDate: string;
  public meetingEndTime: string;
  public meetingDuration: string;
  dateFormatSubscription: Subscription;
  public backgroundUploadInProgress: boolean = false;
  public isTARequired: boolean = false;
  public requiredFields: any;
  public displayConflictMsg: boolean = false;
  private isClickedContacts: boolean = false;
  private isClickedAccounts:boolean = false;
  private isClickedFormat: boolean = false;
  private isClickedFormatDetails: boolean = false;
  private isClickedMeetingTypes: boolean = false;
  private isAccountClicked : boolean = false;
  private teamsMeetingStatus: { status: string, appointmentId: string } = null;
  private shouldCheckConflict: boolean = false;
  private covisitorComplianceAck$: Subject<boolean> = new Subject();

  public checkInField:IndFormFieldViewDataModel;
  public checkOutField:IndFormFieldViewDataModel;

  public isTimeRecommendationEnabled: boolean = false;
  public dictionaryDayOfWeek: { [key: number]: string };
  public dictionaryTimeSlot: { [key: number]: string };
  private timeSlotsFilteredByCurrentTime = [];
  private meetingsFilteredByCurrentTime = [];
  public recommendedByCurrentTime: any = [];
  isSelectedContactsCheck: boolean = false;
  public showTimeRecommendations: boolean = false;
  public hasMeetingAttachmentFA: boolean = false;
  public hasMeetingEventsFA: boolean = false;
  public hasCoachingPlan: boolean = false;
  public notesSectionHeader: IndSectionHeaderViewDataModel;
  public eventsSectionHeader: IndSectionHeaderViewDataModel;
  public proceduresSectionHeader: IndSectionHeaderViewDataModel;
  public supportingMaterialsSectionHeader: IndSectionHeaderViewDataModel;
  public photosSectionHeader: IndSectionHeaderViewDataModel;
  public applicableOpportunities: Opportunity[] = [];
  public applicableAssets: any[] = [];
  public applicableAssetsModel: MainCardViewDataModel[] = [];
  public applicableEvents: EventActivity[] = [];
  public applicableAccountPlans: AccountPlan[] = [];
  public isOppSectionEnabled:boolean=false;
  public conflictMsg: string;
  public isMeetingObjectiveSelectionFAEnabled: boolean = false;

  // New Meeting Notes & Attachments Section
  public meetingNotesSectionHeader: IndSectionHeaderViewDataModel;
  public meetingNotesAndAttachmentsSectionId:string = 'meetingnotes';
  public isMeetingNotesEnabled: boolean = false;
  public isMeetingAttachmentEnabled: boolean = false;
  public isSaveNotesEnabled = false;
  public tempNoteText: string;
  public isMeetingAttachmentAdded: boolean = false;
  public meetingNoteAttachmentTitle: string = '';
  public isMeetingAssetsEnabled:boolean = false;

  private _lookupPopover: HTMLIonPopoverElement;

  public isAccountVisitEnabled = false;
  public isAccountVisitRecord = false;
  public isAccountVisitNestedMeeting = false;
  public isMeetingNotesRelabelFAEnabled: boolean = false;
  public selectedDiseaseAreas=[];
  public selectedProductIndications=[];

  private activityOutcomeOptions:Array<ActivityOutcome> = [];
  private activityOutcomeisMandatory:boolean = false;
  private activityOutcomePopover:any;
  public meetinglinkedtoamarketingbusinessplanField : IndFormFieldViewDataModel;
  public marketingBusinessPlanField : IndFormFieldViewDataModel;
  public locationField: IndFormFieldViewDataModel;
  public coachingPlanField : IndFormFieldViewDataModel;
  private allCompetitorProducts:Array<Brand> = [];
  private allCoachingPlans:Array<CoachingPlan> = [];

  public isCheckoutAndComplete = false;
  checkinFieldsEmpty = false; // this will be true when user tries to complete meeting without checkin  && mandatory flag true
  checkOutFieldsEmpty = false;
  checkinProcess = false;
  private isCompetitorProductsEnabled:boolean = false;

  isManualCheckinProcess = false;

  public isManualGPSCheckinEnabled: boolean = false;
  public checkInPhotoAttachments: PhotoAttachmentDto[] = [];
  public checkOutPhotoAttachments: PhotoAttachmentDto[] = [];
  public GPSActivity = GPSActivity;
  appealStatusFormField: IndFormFieldViewDataModel;
  public disableGPSCheckInFlowForHCPMeeting: boolean = false;
  public lockStartEndTimeIfContentPlayed: boolean = false;
  public selfClaimNeededIfStartEndTimeModified: boolean = false;

  public coVisitorAccess;
  public hasSecondaryInfo: boolean = false;
  private lookupConfigFieldsData;
  public isFromChat: boolean = false;
  public opportunityDynamicUrl: string;
  public openOpportunityDynamic: boolean = false;
  public notesPlaceholder:string = '';

  private autoSavedNote:IONote;

  public testFormField:IndFormFieldViewDataModel;
  selectedData = [];

  accountSourceData:any = [];
  contactSourceData:any = [];

  constructor(
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public emailtemplateService: EmailTemplateServices,
    private emailService: EmailService,
    public contactService: ContactOfflineService,
    public accountService: AccountOfflineService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public device: DeviceService,
    public uiService: UIService,
    public footerService: FooterService,
    private logService: LogService,
    private meetingDataService: MeetingDataService,
    private events: EventsService,
    private clipboard: Clipboard,
    private presentationService: PresentationService,
    public authService: AuthenticationService,
    private websocket: WebsocketDataService,
    private notificationService: NotificationService,
    private authenticationOfflineService: AuthenticationService,
    public trackingService: TrackService,
    private navService: NavigationService,
    private inboundMeetingCtrl: InboundMeetingControllerService,
    public repService: RepServices,
    public appRef: ApplicationRef,
    public cdRef: ChangeDetectorRef,
    private ioUserSelection: IoUserSelection,
    public coachingReportService: CoachingReportService,
    private sampleService: SampleService,
    private contentMatchService: ContentMatchingService,
    private callplanService: CallPlanOfflineService,
    private callplanDataService: CallPlanDataService,
    private dms: DirectMessagingService,
    private theraputicService: TherapeuticAreaDataService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private meetingStructureService: MeetingStructureService,
    public loadingController: LoadingController,
    private differs: KeyValueDiffers,
    private readonly alertService: AlertService,
    private readonly agendaFooterService: AgendaFooterService,
    private caseManagementService: CaseManagementService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private datePipe: DatePipe,
    private pollService: PollService,
    private eventsService: EventsService,
    private inAppBrowser: InAppBrowser,
    private eventsToolService: EventsToolService,
    private dynamics: DynamicsClientService,
    public opportunityService: OpportunityManagementService,
    private opportunityDataService: OpportunityManagementDataService,
    private fileService: IoFileService,
    private disk: DiskService,
    public accountManagementService: AccountManagementOfflineService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    private locationService : LocationOfflineService,
    private alertCtrl : AlertController,
    private readonly brandService: BrandOfflineService,
    public marketingPlansManagementOfflineService : MarketingPlansManagementOfflineService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    private appealService: AppealService,
    private secondaryInfoService: SecInfoConfigDataService,
    private navParams: NavParams,
    private faService: FeatureActionsService,
  ) {
    this.coVisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonmeeting'];
    this.openOpportunityDynamic = this.authService.user.buSettings['indskr_openopportunityindynamics'] ;
    this.opportunityDynamicUrl = this.authService.user.buSettings['indskr_opportunityurl'] ;

    this.initializeheaderTitle();
    this.events.unsubscribe('ContactsSelected');
    this.events.observe('ContactsSelected').pipe(takeUntil(this.ngdestroy$$)).subscribe(async (data) => {
      if (data && this.navService.getCurrentPageName() !== PageName.PresentationMeetingComponent) {
        if (this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
          // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
          if (!_.isEmpty(this.activityService.selectedActivity.contacts)) {
            for (let i = 0; i < this.activityService.selectedActivity.contacts.length; i++) {
              this.activityService.selectedActivity.contacts[i].indskr_sequence = i + 1;
            }
          }
          // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
          const success = await this.meetingDataService.addContactsToMeeting(this.activityService.selectedActivity, true);

          await this.meetingDataService.addActivityAccountsAndContactAttendiesToMeeting(this.activityService.selectedActivity);

          this.meetingStructureService.addFilteredContentToMeeting();
          this.events.publish('contentIsAdded', this.activityService.selectedActivity.presentations);
          if (success) {
            this.activityService.notifyCalendarInviteUpdate(this.activityService.selectedActivity);
          }

          //Calc Best time on Contacts change
          this.calculateBestRecommendedTime();
        }
      }
    });

    this.eventSubscriptions.push(...this.events.subscribe('updateTherapeuticarea', () => {
      this.initTherapeuticAreas();
    }));
    this.events.observe('device:deviceIsOffline').pipe(takeUntil(this.ngdestroy$$)).subscribe((status) => {
      if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
        return;
      }
      this.initFooterAndHeaderForCompletedMeeting();
      this.initDieseaseAreaSectionData();
      this.initProductIndicationsSectionData();
      this.initActivitiesDetailsPageTitle();
      this.footerService.initButtons(FooterViews.Activities);
    });
    this.events.observe('updateMeetingDateFields').pipe(takeUntil(this.ngdestroy$$)).subscribe(() => {
      this.meetingEndTimeField.isHidden = true;
      setTimeout(() => {
        this.initMeetingDateFormFields();
      }, 50);
      this.updateUIWithLatestTime();
      this.cdRef.detectChanges();
    });
    this.events.observe("sync:completed").pipe(takeUntil(this.ngdestroy$$)).subscribe(async (status) => {
      this.initFooterAndHeaderForCompletedMeeting();

      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        const activityAccounts: any = await this.meetingDataService.fetchActivityAccountsByMeetingId(this.activityService.selectedActivity.ID);
        if (activityAccounts) {
          (this.activityService.selectedActivity as AppointmentActivity)._raw_activityAccounts = activityAccounts;
        }

        const activityContacts: any = await this.meetingDataService.fetchActivityContactAttendiesByMeetingId(this.activityService.selectedActivity.ID);
        if (activityContacts) {
          (this.activityService.selectedActivity as AppointmentActivity)._raw_contactAttendees = activityContacts;
        }
      }
  });
    this.events.observe('device:deviceIsOnline').pipe(takeUntil(this.ngdestroy$$)).subscribe((status) =>{
      if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
        return;
      }
      this.initFooterAndHeaderForCompletedMeeting();
      this.initDieseaseAreaSectionData();
      this.initProductIndicationsSectionData();
      this.initActivitiesDetailsPageTitle();
      this.footerService.initButtons(FooterViews.Activities);
    });
    this.events.observe('userStateChanged').pipe(takeUntil(this.ngdestroy$$)).subscribe((status) => this.initFooterAndHeaderForCompletedMeeting());
    this.events.observe('activityproductkeymessagesupdated').pipe(takeUntil(this.ngdestroy$$)).subscribe((event)=> {
      if(this.isProductIndicationEnabled){
        this.initProductIndicationsSectionData();
      }
    });
    this.events.observe('activity-detail:fieldRefreshRequired').pipe(takeUntil(this.ngdestroy$$)).subscribe(() => this.refreshFormFields());
    this.events.observe('AccountSelected').pipe(takeUntil(this.ngdestroy$$)).subscribe((data) => {
      if (data) {
        if (this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
          this.meetingDataService.addAccountsToMeeting(this.activityService.selectedActivity).then(async() => {
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {
              await this.meetingDataService.addActivityAccountsAndContactAttendiesToMeeting(this.activityService.selectedActivity);
            }
          });
        }
      }
    });
    this.activityService.teamsMeetingStatus$.subscribe(data => {
      if (data) this.teamsMeetingStatus = data;
    });
    this.events.unsubscribe('completeMeeting');
    this.events.subscribe('completeMeeting', () => {
      this.markComplete();
      this.initDieseaseAreaSectionData();
      this.initProductIndicationsSectionData();
    });

    this.events.subscribe('scrollToTop', () => {
      this.scrollToTop();
    });

    this.events.subscribe('remote_meeting_participant_Joined_left',(data)=> {
      this.detectChangesOnActivityDetails(true);
    });

    this.eventsService.observe('detectChangesOnActivityDetails').pipe(takeUntil(this.ngdestroy$$)).subscribe((initButtons: boolean = false) => {
      this.detectChangesOnActivityDetails$.next(initButtons);
    });

    this.detectChangesOnActivityDetails$.pipe(debounceTime(100)).subscribe((initButtons: boolean) => this.detectChangesOnActivityDetails(initButtons, false));

    this.events.observe('updateSelectedActivityDetails').pipe(takeUntil(this.ngdestroy$$)).subscribe(() => {
      if (!this.activityService.selectedActivity) {
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          this.uiService.showRightPane = false;
        }
      } else {
        this.activityDataService.updateActivityDetails(this.activityService.selectedActivity, false);
        this.initFormFields(false);
      }
    })

    this.events.subscribe('updateMandatoryField', (field) => {
      if(field == PageName.ContactPageComponent) {
        if(this.activityService.selectedActivity instanceof AppointmentActivity){
          this.isClickedContacts = true;
          this.initFormFields(false);
          this.appointmentConfigFields.forEach((conField) => {
            if(conField.fieldName == 'indskr_hcpinteraction' && (this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0 && this.isSolicitatedMeetingComplianceCheckEnabled) conField.isEmptyRequiredField = false;
          })
        }
      }
      else if(field == PageName.PresentationMeetingComponent) {
        this.initFormFields(false);
      }
    })
    this.eventsService.observe('updateMandatoryField_footer').pipe(takeUntil(this.ngdestroy$$)).subscribe((button) => {
      if(button == 'contacts')  this.isClickedContacts = true;
    });

    this.newActivitySubscription = this.uiService.activityRequestObserver.subscribe(value => {
      this.uiService.showNewActivity = value;
    });
    // open issue on github where the tooltip is not destroyed when you navigate to another page
    // Solution dont put the directive in the elements top parent wrapper  Reference: https://github.com/zyra/ionic-tooltips/issues/34
    this.tooltipEvent = this.device.isNativeApp || this.device.isMobileDevice ? 'click' : 'hover';
    this.meetingStructureService.accessedFrom = AccesingMode.MEETING_DETAIL;

    this.events.subscribe('activities:appConfigFields', (message) => {
      if(message == 'indskr_hcpinteraction') {
        let meetingConfig = this.appointmentConfigFields.find((field) => field.fieldName == 'indskr_hcpinteraction')
        if(meetingConfig){
          meetingConfig.isEmptyRequiredField = false;
        }
      }
      else {
        this.updateAppConfigFieldsOnActivityChanges();
        if (this.device.isOffline) {
          this.setOfflineDataForConfigFields();
        }
      }
    });
    this.events.observe('contentmatching:refresh').pipe(takeUntil(this.ngdestroy$$)).subscribe(() => {
      this.meetingStructureService.addFilteredContentToMeeting();
      this.contentMatchService.isNewContactAddedToMeeting = false;
    });

    this.isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false;
   
  }

  private footerSetDate = null;
  private allowedToCallFooterMethod(): boolean {
    if (this.footerSetDate) {
      const millisecondBetweenTwoDate = Math.abs((new Date().getTime() - this.footerSetDate.getTime()));
      // To avoid repeated calls for the footer init.
      if (millisecondBetweenTwoDate > 500) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private initMeetingAssets() {
    this.applicableAssets = [];
    if (!_.isEmpty(this.fieldMaterialManagementService.meetingAssets)) {
      this.fieldMaterialManagementService.meetingAssets.filter(a => a.appointmentid == this.activityService.selectedActivity.ID && a.statecode == 0).forEach(ma => {
        const asset = this.fieldMaterialManagementService.customerAssets.find(cs => cs.msdyn_customerassetid == ma.msdyn_customerassetid);
        if (asset) {
          this.applicableAssets.push(asset);
        } else {
          this.applicableAssets.push({ ...ma, productName: ma.indskr_product, categoryName: ma.indskr_category });
        }
      });
      if (this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings) {
        this.isMeetingAssetsEnabled = !_.isEmpty(this.applicableAssets);
      } else if (!this.activityService.selectedActivity.isCompleted && !this.enableReadonlyViewForMeetings && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TAG_ASSETS_TO_MEETINGS)) {
        this.isMeetingAssetsEnabled = true;
      }
      this.getApplicableAssetsHeirarchyModel();
    }
  }

  private detectChangesOnActivityDetails(initButtons: boolean, updateBusinessProcess: boolean = true) {
    if (this.allowedToCallFooterMethod()) {
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.initGpsFields();
        this.initGpsActivityPhotos();
        this.initGpsActivityPhotosSectionHeader();

        this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity);
        if (initButtons) {
          if (this.uiService.activeView == 'Meeting') {
            this.footerService.initButtons(FooterViews.PreviewMeeting)
          }
          if ((this.uiService.activeView == 'activityTimelineDetails' || this.uiService.activeView == 'Appointment') &&
            (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent)) {
            this.footerService.initButtons(FooterViews.Activities);
          }
        }
        this.initActivitiesDetailsPageTitle();
        // this.initFormFields(updateBusinessProcess);
        this.opportunitiesSectionHeader();
        this.isMeetingObjectiveSelectionFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION);
        this.initObjectivesSectionHeader();
        this.initEventsSectionHeader();
        this.initProceduresSectionHeader();
        this.initSupportingMaterialsSectionHeader();
        this.initPhotosSectionHeader();
        if (this.activityService.selectedActivity) {
          if (this.activityService.selectedActivity.associatedAttachments.length) {
            this.annotationId = this.activityService.selectedActivity.associatedAttachments[0].annotationId;
            this.attachmentTitle = this.activityService.selectedActivity.associatedAttachments[0].attachmentName;
            this.isAttachmentAdded = true;
          }
          this.applicableOpportunities = [];
          this.activityService.selectedActivity.associatedOpportunities.forEach(opp => {
            let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(opp.opportunityId);
            if (foundOpportunity) {
              this.applicableOpportunities.push(foundOpportunity);
            }
            else {
              const oppObj = {
                opportunityid: opp.opportunityId,
                name: opp.opportunityName
              }
              this.applicableOpportunities.push(new Opportunity(oppObj as any));
            }
          });
          this.applicableAccountPlans = [];
          this.activityService.selectedActivity.associatedAccountPlans.forEach(ap => {
            let foundAccountPlan = this.accountManagementService.getAccountPlanByPlanId(ap.accountPlanId);
            if(foundAccountPlan){
              this.applicableAccountPlans.push(foundAccountPlan);
            } else {
              const evObj = {
                indskr_accountplanid: ap.accountPlanId,
                indskr_name: ap.accountPlanName
              }
              this.applicableAccountPlans.push(new AccountPlan(evObj as any));
            }
          })
          this.applicableEvents = [];
          this.activityService.selectedActivity.associatedEvents.forEach(ev => {
            let foundEv = this.eventsToolService.getEventDetailsbyId(ev.eventId);
            if (foundEv) {
              this.applicableEvents.push(foundEv)
            } else {
              const evObj = {
                msevtmgt_eventid: ev.eventId,
                msevtmgt_name: ev.name
              }
              this.applicableEvents.push(new EventActivity(evObj));
            }
          });
          this.initMeetingAssets();
          this.isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false;

        }
        this.cdRef.detectChanges();
        this.footerSetDate = new Date();
      }
    }
  }

  private initializeheaderTitle() {
    switch (this.activityService.selectedActivity?.subject) {
      case 'Meeting':
        this.activitiesDetailsPanePageTitleText = this.translate.instant('MEETING');
        break;
      case 'Visit':
        this.activitiesDetailsPanePageTitleText = this.translate.instant('VISIT');
        break;
      default:
        this.activitiesDetailsPanePageTitleText = this.activityService.selectedActivity.subject;
        break;
    }
    return this.activitiesDetailsPanePageTitleText;
  }

  public get meetingStatusString(): string {
    let status: string = this.activityService.selectedActivity.getStatusString;
    switch (this.activityService.selectedActivity.getStatusString) {
      case 'Open':
        status = this.translate.instant('OPEN');
        break;
      case 'Completed':
        status = this.translate.instant('COMPLETED');
        break;
      case 'Canceled':
        status = this.translate.instant('CANCELLED');
        break;
      case 'Scheduled':
        status = this.translate.instant('SCHEDULED');
        break;
      default:
        status = this.translate.instant('N_A');
        break;
    }
    return status;
  }

  public get sharedResources() {
    return (this.activityService.selectedActivity as AppointmentActivity)?.sharedResources || []
  }


  ngAfterContentChecked() {
    // this.meetingTime = this.activityService.getFormattedMeetingTimeText();
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_TYPE)) {
      this.isMeetingTypeFAEnabled = true;
    } else {
      this.isMeetingTypeFAEnabled = false;
    }
    this.isMeetingDurationEnabled = this.authenticationOfflineService.hasFeatureAction({ featureAction: FeatureActionsMap.MEETING_DURATION, skipLDCheck: true });
    this.hasMeetingDurationRequired = this.authenticationOfflineService.hasFeatureAction({ featureAction: FeatureActionsMap.MEETING_DURATION_REQUIRED, skipLDCheck: true });
    this.isMeetingEndDateEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_REMOVE_END_DATE) ? false : !this.isMeetingDurationEnabled;
    if (
      !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING)
      && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE)
      && !(this.isAccountVisitRecord || this.isAccountVisitNestedMeeting)
    ) {
      this.isRemoteEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      this.isAutoSubjectEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES)) {
      this.isNotesEnabled = true;
    }
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_CAPTURE)) {
      this.isMeetingNotesEnabled = true;
      this.isNotesEnabled = false;
    }
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_ATTACHMENT_UPLOAD)) {
      this.isMeetingAttachmentEnabled = true;
      this.isNotesEnabled = false;
    }
    this.isTherapeuticAreaEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA);
    this.isMeetingNotesRelabelFAEnabled =this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_RELABEL);
    this.isDiseaseAreaEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_DISEASE_AREA);
    this.isProductIndicationEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_INDICATIONS);
    this.isProcedureSectionEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_PROCEDURE) || (this.activityService.selectedActivity && !_.isEmpty((this.activityService.selectedActivity as AppointmentActivity).activityProcedures) && this.activityService.selectedActivity.isCompleted)
    this.isSupportingMaterialsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_SUPPORTING_MATERIALS) || (this.activityService.selectedActivity && !_.isEmpty((this.activityService.selectedActivity as AppointmentActivity).activitySupportingMaterials) && this.activityService.selectedActivity.isCompleted)
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.JOINT_MEETING)) {
      this.hasJointMeeting = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVES)) {
      this.hasMeetingObjectives = true;
    } else {
      this.hasMeetingObjectives = false;
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_REASON) && this.activityService.isContentRequiredForRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity)?.indskr_activitytype)) {
      this.isContentRequiredForRemoteChannelEnabled = true;
    } else {
      this.isContentRequiredForRemoteChannelEnabled = false;
    }
    this.isReadOnlyJointMeeting = this.jointMeetingStatus;
    this.isReadOnlyRecommendedMeeting = this.activityService.selectedActivity && this.activityService.selectedActivity['indskr_suggest'];
    this.isKeyMessageSelectionEnabled = true;
    this.isStatusEnabled = true;
    if (this.isLiveMeet) {
      this.hasMeetingObjectives = false;
      this.isNotesEnabled = false;
      this.isCoachingEnabled = false;
      this.isAutoSubjectEnabled = true;
      this.isKeyMessageSelectionEnabled = false;
      this.isStatusEnabled = false;
      this.isLocationEnabled = false;
      this.isAccountSelectionEnabled = false;
      this.isAllocationEnabled = false;
    }
    this.assetsSectionTitle = { id: 'assets', title: this.translate.instant('ASSETS'), controls: [] };

    this.isManualGPSCheckinEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_MANUAL_GPS_CHECK_IN);
    this.disableGPSCheckInFlowForHCPMeeting = !(this.isAccountVisitRecord || this.isAccountVisitNestedMeeting) && this.authService.hasFeatureAction(FeatureActionsMap.DISABLE_GPS_CHECKIN_FEATURE_IN_HCP_MEETINGS);
    this.lockStartEndTimeIfContentPlayed = this.authService.user.buSettings['indskr_lockappointmentstartendtimeifcontentplaye'] != null ? this.authService.user.buSettings['indskr_lockappointmentstartendtimeifcontentplaye'] : false;
    this.selfClaimNeededIfStartEndTimeModified = this.authService.user.buSettings['indskr_selfclaimneededifstartendtimemodified'] != null ? this.authService.user.buSettings['indskr_selfclaimneededifstartendtimemodified'] : false;
  }

  ngOnInit() {
    //If we come back from the meeting lets mark this for changes for updating products and key messages
    // this.activityDataService.activityDetailsLoaded = false;
    // if(this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING){
    //   this.footerService.initButtons('');
    // }else{
    //   this.footerService.initButtons(FooterViews.Activities);
    // }

    // Let's check note & attachment FA here and initialize the header
    // The old version of Note FA : this.hasMeetingAttachmentFA(FeatureActionsMap.MEETING_ATTACHMENT_NOTES),isNotesEnabled
    // The new version of Note FA : isMeetingNotesEnabled, isMeetingAttachmentEnabled
    // It should be either of one. Can not use both.
    this.setAccountVisitFlags();

    if (this.activityService.selectedActivity instanceof AppointmentActivity &&
      this.activityService.selectedActivity.indskr_suggest &&
      !this.activityService.selectedActivity.meetingURL && !this.device.isOffline) {
      this.generateMeetingUrl(this.activityService.selectedActivity.ID)
    }

    if (this.activityService.selectedActivity instanceof AppointmentActivity &&
      this.activityService.selectedActivity.indskr_suggest &&
      !this.activityService.selectedActivity.meetingURL && !this.device.isOffline) {
      this.generateMeetingUrl(this.activityService.selectedActivity.ID)
    }

    this.hasMeetingAttachmentFA = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_ATTACHMENT_NOTES);
    this.isMeetingNotesRelabelFAEnabled =this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_RELABEL);
    this.initNotesSectionHeader();
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_CAPTURE)) {
      this.isMeetingNotesEnabled = true;
      this.isNotesEnabled = false;
    }
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_ATTACHMENT_UPLOAD)) {
      this.isMeetingAttachmentEnabled = true;
      this.hasMeetingAttachmentFA = false;
      this.isNotesEnabled = false;
    }
    this.isFromChat = (this.navParams && this.navParams.data && this.navParams.data.viewData && this.navParams.data.viewData.from == 'openChat') || false;
    if(this.isMeetingNotesEnabled || this.isMeetingAttachmentEnabled) {
      this.initMeetingNotesSectionHeader();
      this._sortMeetingNotes();
    }

    this.activityService.isNewActivityFlow = false;
    this.displayConflictMsg = this.checkConflictMsgDisplay();
    this.activityService.signalRefreshConflictAlertMsg$.asObservable()
    .pipe(takeUntil(this.ngdestroy$$)).subscribe(() => {
      this.displayConflictMsg = this.checkConflictMsgDisplay();
    });

    if (this.from) {
      this.isLayoverPushView = true;
      if (this.from == 'AccountManagementTool') {
        this.layoverViewPushedFrom = 'AccountManagementTool';
      } else if (this.from == 'AccountTool') {
        this.layoverViewPushedFrom = 'AccountTool';
      } else if (this.from == 'AccountTimeline') {
        this.layoverViewPushedFrom = 'AccountTimeline';
      } else if (this.from == 'ScientificActivityPage') {
        this.layoverViewPushedFrom = 'ScientificActivityPage';
      } else if (this.from == 'PresentationToolsPage') {
        this.layoverViewPushedFrom = 'PresentationToolsPage';
      } else if (this.from == 'OpportunityManagementTool') {
        this.layoverViewPushedFrom = 'OpportunityManagementTool';
      } else if (this.from == 'ProcedureLogFrom') {
        this.layoverViewPushedFrom == 'ProcedureLogFrom';
      } else if(this.from === PageName.AccountPlanActivities){
        this.activityService.teamViewActive = true;
      } else if(this.from === PageName.SetBookingActivityDetailsComponent) {
        this.layoverViewPushedFrom = PageName.SetBookingActivityDetailsComponent;
      }
    }

    this.uiService.isFlipButtonClicked = false;

    this.configFieldsSubscription = this.ioUserSelection.additionalConfigObserver.subscribe(data => {
      if (data != undefined) {
        if (data[0].type != undefined) {
          this.updateAdditionalFields(data);
        }
        this.initConfigFieldsSetup();
      }
    });
    this.isMeetingObjectiveSelectionFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION);

    this.opportunitiesSectionHeader();
    this.initEventsSectionHeader();
    this.initGpsActivityPhotosSectionHeader();
    this.initObjectivesSectionHeader();
    this.initProceduresSectionHeader();
    this.initSupportingMaterialsSectionHeader();
    this.initPhotosSectionHeader();

    // Opportunities section visibility on meeting details
    this.applicableOpportunities = [];
    (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities.forEach(opp=> {
      let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(opp.opportunityId);
      if(foundOpportunity){
        this.applicableOpportunities.push(foundOpportunity);
      }else {
        const oppObj = {
          opportunityid: opp.opportunityId,
          name: opp.opportunityName
        }
        this.applicableOpportunities.push(new Opportunity(oppObj as any));
      }
    });
    this.applicableAccountPlans = [];
    (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans.forEach(ap=> {
      let foundAccountPlan = this.accountManagementService.getAccountPlanByPlanId(ap.accountPlanId);
      if(foundAccountPlan){
        this.applicableAccountPlans.push(foundAccountPlan);
      } else {
        const evObj = {
          indskr_accountplanid: ap.accountPlanId,
          indskr_name: ap.accountPlanName
        }
        this.applicableAccountPlans.push(new AccountPlan(evObj as any));
      }
    });
    if (this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings) {
      this.isOppSectionEnabled = !!(this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities.length;
    }else if (!this.activityService.selectedActivity.isCompleted && !this.enableReadonlyViewForMeetings && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ASSOCIATE_OPPORTUNITIES_TO_MEETINGS)) {
      this.isOppSectionEnabled = true;
    }
    this.initMeetingAssets();
    if (this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings) {
      this.hasMeetingEventsFA = !!(this.activityService.selectedActivity as AppointmentActivity).associatedEvents.length;
    }else if (!this.activityService.selectedActivity.isCompleted && !this.enableReadonlyViewForMeetings && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_MEETINGS)) {
      this.hasMeetingEventsFA = true;
    }

    // Event section visibility on meeting details
    this.applicableEvents = [];
    (this.activityService.selectedActivity as AppointmentActivity).associatedEvents.forEach(ev=> {
      let foundEvent = this.eventsToolService.getEventDetailsbyId(ev.eventId);
      if(foundEvent){
        this.applicableEvents.push(foundEvent);
      } else {
        const evObj = {
          msevtmgt_eventid: ev.eventId,
          msevtmgt_name: ev.name
        }
        this.applicableEvents.push(new EventActivity(evObj));
      }
    });

    // Attachment in Meeting Notes
    let attachments = (this.activityService.selectedActivity as AppointmentActivity).associatedAttachments;
    if (attachments.length) {
      this.annotationId = attachments[0].annotationId;
      this.attachmentTitle = attachments[0].attachmentName;
      this.isAttachmentAdded = true;
    }

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA)) {
      this.isTherapeuticAreaEnabled = true;
      this.initTherapeuticAreas();
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES_RELABEL)) {
      this.isMeetingNotesRelabelFAEnabled = true;
      this.initNotesSectionHeader();
    }

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_DISEASE_AREA)) {
      this.isDiseaseAreaEnabled = true;
      this.initDieseaseAreaSectionData();
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_INDICATIONS)){
      this.isProductIndicationEnabled = true;
      this.initProductIndicationsSectionData();
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_SOLICITATION_COMPLIANCE)) {
      this.isSolicitatedMeetingComplianceCheckEnabled = true;
    }
    this.setCoachings();
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) && this.activityService.sampleActivities && this.activityService.sampleActivities.length > 0) {
      this.isAllocationEnabled = true;
      let allocationInSelectedMeeting = this.activityService.activities.filter((o) => {
        return o instanceof SampleActivity && o.appointmentID == this.activityService.selectedActivity.ID
      })
      this.allocationInSelectedMeeting = allocationInSelectedMeeting.map((p) => {
        return p as SampleActivity
      })
      // this.allocationInSelectedMeeting = allocationInSelectedMeeting.map((p)=>{
      //   return p as SampleActivity
      // })
    }
    else this.allocationInSelectedMeeting = [];
    this.isLocationEnabled = true;
    this.isStatusEnabled = true;
    this.isKeyMessageSelectionEnabled = true;
    if (this.isLiveMeet) {
      this.isNotesEnabled = false;
      this.isCoachingEnabled = false;
      this.isAutoSubjectEnabled = true;
      this.isKeyMessageSelectionEnabled = false;
      this.isStatusEnabled = false;
      this.isLocationEnabled = false;
      this.isAccountSelectionEnabled = false;
      this.isAllocationEnabled = false;
      this.isTherapeuticAreaEnabled = true;
    }

    if (this.contentMatchService.isNewContactAddedToMeeting) {
      this.meetingStructureService.addFilteredContentToMeeting();
      this.contentMatchService.isNewContactAddedToMeeting = false;
    }

    this.device.screenOrientation.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((newOrientation) => {
      if (newOrientation && this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent
        && this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent
        && this.uiService.activitiesPageTab !== 'plans')
        this.footerService.initButtons(FooterViews.Activities);
      // if (this.device.isMobileDevice && newOrientation && newOrientation == DEVICE_ORIENTATION['portrait-primary']) {
      //   this.footerService.showButton('openmoreoptions');
      // }
      // else if ((this.device.isMobileDevice && newOrientation && newOrientation == DEVICE_ORIENTATION['landscape-primary'])) {
      //   this.footerService.hideButton('openmoreoptions');
      // }
    });

    //taking appointment appConfigFields from current user service
    const record = this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity as AppointmentActivity);
    const formatType = record.isAccountVisitRecord || record.isAccountVisitNestedMeeting ? FormatType.ACCOUNT_VISIT : FormatType.HCP_MEETING;
    this.meetingActivityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, formatType);
    this.initConfigFieldsSetup();
    this.initConfigFieldsLookupData();
    if (this.device.isOffline) {
      this.setOfflineDataForConfigFields();
    }
    this.uiService.setnewActivityRequest(false);
    this.initActivitiesDetailsPageTitle();
    // if (this.activityService.selectedActivity instanceof AppointmentActivity) {
    //   this.getActivityTypes();
    // }

    this.selectedActivityDiffer = this.differs.find(this.activityService.selectedActivity).create();
    this.initFormFields();

    this.translate.onLangChange.subscribe(data => {
      this.initializeheaderTitle();
      this.initFormFields();
    });
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.initFormFields();
      }
    });

    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(inProgress => {
      if (this.activityService.selectedActivity && this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.backgroundUploadInProgress = inProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID);
        this.initActivitiesDetailsPageTitle();
        this.footerService.initButtons(FooterViews.Activities);
        if (!_.isEmpty(this.firstLevelFormFields)) {
          const subjField = this.firstLevelFormFields.find(field => field.id === 'subject-field');
          if (subjField) {
            subjField.isDisabled = this.backgroundUploadInProgress ? true : subjField.isDisabled;
          }
        }
      }
    });

    this.device.isOfflineObservable.pipe(
      skip(1),
      takeUntil(this.ngdestroy$$)
    )
    .subscribe(isOffline => {
      this.updateCovisitorInputEnableDisable();
      this.initActivitiesDetailsPageTitle();
    });

    if(this.authenticationOfflineService.impersonatedUser){
      this.detectChangesOnActivityDetails(false);
    }

    this.eventSubscriptions.push(
      this.covisitorComplianceAck$.asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((checked: boolean) =>
          checked !== null
          && this.activityService.selectedActivity.state !== MeetingActivityState.Completed
          // Adding this filter to
          && (this.activityService.selectedActivity as AppointmentActivity).covisitorComplianceAcked !== checked
        )
      )
      .subscribe(async (checked: boolean) => {
        try {
          await this.meetingDataService.updateCovisitorComplianceAck(
            this.activityService.selectedActivity as AppointmentActivity,
            checked,
            this.authService.user.covisitorComplianceDescription,
          );

          this.requiredFields['covisitor-compliance']['isAdded'] = checked;
        } catch (error) {
          console.error('activities-details-pane: covisitorComplianceAck$: ', error);
          // Set to null so that distinctUntilChanged doesn't filter out next attempt
          this.covisitorComplianceAck$.next(null);

          // Revert checkbox if needed
          const formField = this.secondLevelFormFields.find(field => field.id === 'covisitor-compliance');
          if (formField) {
            formField.inputValue = !!((this.activityService.selectedActivity as AppointmentActivity).covisitorComplianceAcked);
          }
          this.cdRef.detectChanges();

          // Display error message
          this.notificationService.notify(
            error?.message ?? 'Error',
            'Meeting Details',
            'top',
            ToastStyle.DANGER,
            3500,
            true,
          );
          return;
        }
      })
    );

    this.isTimeRecommendationEnabled = this.authService.hasFeatureAction(FeatureActionsMap.TIME_RECOMMENDATION);
    //Calc Best time on init
    this.calculateBestRecommendedTime();

    this.isGpsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)
    this.isLiveTimeFieldEnabledForF2FMeetings = this.authService.hasFeatureAction(FeatureActionsMap.HIDE_LIVETIME_FOR_F2F_MEETINGS) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)
  }

  private setAccountVisitFlags() {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)
      && this.activityService.selectedActivity.type === ActivityType.Appointment) {
      this.isAccountVisitEnabled = true;
      this.isAccountVisitRecord = !!((this.activityService.selectedActivity as AppointmentActivity).indskr_isparentcall);
      this.isAccountVisitNestedMeeting = !!((this.activityService.selectedActivity as AppointmentActivity).indskr_parentcallid);
    }
  }

  private setCoachings() {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
      this.isCoachingEnabled = true;
      this.coachingReportService.setActivityCoaching(this.activityService.selectedActivity);
      this.coachingActivitySubscription = this.coachingReportService.activityCoachingObserver.subscribe(data => {
        this.coachingReportService.coachingReports = data;
      });
    }
  }

  ngDoCheck(): void {
    if(this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
      if (this.selectedActivityDiffer) {
        const changes = this.selectedActivityDiffer.diff(this.activityService.selectedActivity);
        if (changes) {
          this.initFormFields(false);
        }
      }
    }
  }


  private shouldDisableCovisitorComplianceInput(): boolean {
    return !(
      !this.enableReadonlyViewForMeetings
      && this.activityService.selectedActivity?.state !== MeetingActivityState.Completed
      && this.activityService.selectedActivity?.state !== MeetingActivityState.Canceled
      && !(this.jointMeetingStatus && this.device.isOffline)
      && !this.activityService.selectedActivity?.isDiffPosition
    );
  }

  private initFormFields(updateBusinessProcess:boolean = true) {
    this.setAccountVisitFlags();
    this.firstLevelFormFields = [];
    this.secondLevelFormFields = [];
    this.ngAfterContentChecked();
 
    this.requiredFields = this.activityService.getRequiredData(this.activityService.selectedActivity as AppointmentActivity);
    this.isMeetingObjectiveSelectionFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION);
    let meeting = this.activityService.selectedActivity as AppointmentActivity;
    // let checkinInput= meeting.gpsCheckInDetails?.indskr_checkindatetime ? this.datePipe.transform(meeting.gpsCheckInDetails.indskr_checkindatetime, 'shortTime') +  ' | ' +  this.translate.instant(meeting.gpsCheckInDetails.indskr_checkinstatus === GPS_STATUS.VALID ? 'VALID' : 'INVALID') : '';

    this.activityOutcomeOptions = this.surgeryOrderDataService.activityOutcomes.filter(a=> a.indskr_omnipresenceactivity == ActivityOutcomeType.Meeting);
    this.activityOutcomeisMandatory = this.activityOutcomeOptions.some(a=> a.indskr_mandatory);
    this.isCompetitorProductsEnabled = this.authService.user.buConfigs['indskr_competitorproduct'];
    this.isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false;
    this.hasCoachingPlan = this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN);
    if(this.isCompetitorProductsEnabled){
      this.allCompetitorProducts = this.caseManagementService.caseProductSKU.filter(p => p.isCompetitorProduct && p.isCompetitorProduct == 'true');
    }

    const activityTypeFieldText = this.activityService.selectedActivity.activityTypeName
      || this.meetingActivityTypes?.find(t => t.indskr_activitytypeid === this.activityService.selectedActivity.indskr_activitytype)?.indskr_name;
    this.isInStoreMeeting = this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting && activityTypeFieldText === this.translate.instant("INSTORE");
    this.isInStoreChildMeeting = this.isAccountVisitNestedMeeting && activityTypeFieldText === this.translate.instant("INSTORE");

    if (this.hasCoachingPlan) {
      this.allCoachingPlans = this.coachingReportService.coachingPlans;
    }
    this.initGpsFields();
    this.initGpsActivityPhotos();
    this.setAccountFormField();
    this.setContactFormField();

    if(!this.therapeuticAreas || (this.therapeuticAreas && this.therapeuticAreas.length<1) ) {
      this.requiredFields['therapeutic-area']['isRequired'] = false;
    }

    this.firstLevelFormFields.push({
      isHidden: !this.isReadOnlyJointMeeting,
      label: this.translate.instant('ACTIVITY_DETAILS_SCHEDULED_BY'),
      inputText: ((this.activityService.selectedActivity && this.activityService.selectedActivity.meetingOwnerName) ? this.activityService.selectedActivity.meetingOwnerName:''),
      id: 'scheduled-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    });

    this.firstLevelFormFields.push({
      isHidden: !this.isStatusEnabled,
      label: this.translate.instant('STATUS'),
      inputText: this.meetingStatusString,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    });

    this.firstLevelFormFields.push({
      isHidden: !this.activityService.selectedActivity.cancellationreason && !this.activityService.selectedActivity.indskr_reasonforcancellationname,
      label: this.translate.instant('CANCELLATION_REASON'),
      inputText: this.activityService.selectedActivity.cancellationreason || this.activityService.selectedActivity.indskr_reasonforcancellationname,
      id: 'cancellation-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    });

    this.firstLevelFormFields.push({
      isHidden: !this.activityService.selectedActivity.indskr_cancellationreasonother,
      label: this.translate.instant('CANCELLATION_REASON_OTHER'),
      inputText: this.activityService.selectedActivity.indskr_cancellationreasonother,
      id: 'cancellation-other-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    });

    const isSubjectNotEditable = this.secondaryInfoService.isAppointmentSecInfoConfigured ? false : this.isAutoSubjectEnabled;

    let viewDataField = {
      label: this.translate.instant('SUBJECT'),
      inputText: ((this.activityService.selectedActivity && this.activityService.selectedActivity.subject) ? (this.activityService.selectedActivity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && this.activityService.selectedActivity.subject == 'Visit') ? this.translate.instant('VISIT') : this.activityService.selectedActivity.subject) + '' : ''),
      inputValue: ((this.activityService.selectedActivity && this.activityService.selectedActivity.subject) ? (this.activityService.selectedActivity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && this.activityService.selectedActivity.subject == 'Visit') ? this.translate.instant('VISIT') : this.activityService.selectedActivity.subject) + '' : ''),
      id: 'subject-field',
      isReadOnly: this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || isSubjectNotEditable || this.activityService.selectedActivity.state == MeetingActivityState.Completed || this.activityService.selectedActivity?.isDiffPosition,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyRecommendedMeeting || this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || isSubjectNotEditable || this.activityService.selectedActivity.state == MeetingActivityState.Completed || this.activityService.selectedActivity?.isDiffPosition ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !(this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || this.isAutoSubjectEnabled || this.activityService.selectedActivity.state == MeetingActivityState.Completed ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.activityService.selectedActivity?.isDiffPosition),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || this.isAutoSubjectEnabled || this.activityService.selectedActivity.state == MeetingActivityState.Completed ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.activityService.selectedActivity?.isDiffPosition),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      dropdown: undefined,
      displayInfoText: this.activityService.selectedActivity['indskr_suggest'],
      infoText: this.activityService.selectedActivity && this.activityService.selectedActivity['indskr_suggest'] ? (this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype && this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype) ? this.translate.instant('SUGGESTED_REMOTE_MEETING') : this.translate.instant('SUGGESTED_F2F_MEETING') : '',
      infoTextCSSClass: 'orange-info-message-text',
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if (this.activityService.meetingSubjects?.length && !viewDataField.isReadOnly) {
      viewDataField.showArrow = false;
      viewDataField.dropdown = {
        values: this.activityService.meetingSubjects.map((option, i) => ({ title: option, id: option }))
      };
    }
    this.firstLevelFormFields.push(viewDataField);

    let suggestReasonField: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGEST_REASON'),
      inputText: this.activityService.selectedActivity['indskr_reason'],
      inputValue: this.activityService.selectedActivity['indskr_reason'],
      id: 'suggest-reason-field',
      isReadOnly: true,
      isDisabled: true,
      placeholderLabel: this.translate.instant('SUGGEST_REASON'),
      showArrow: false,
      isHidden: !this.activityService.selectedActivity['indskr_suggest'],
      isFieldNextIcon: true,
      imgSrc: 'assets/imgs/field_star.svg',
      formFieldType: FormFieldType.DATA_SUBMITTED
    };
    this.firstLevelFormFields.push(suggestReasonField);

    this.activitiesDetailsPanePageTitle =  {...this.activitiesDetailsPanePageTitle, title: viewDataField.inputValue};

    this.initMeetingDateFormFields();

    this.meetingDurationField = {
      isDurationField: true,
      isNewActivityView: true,
      label: this.translate.instant('DURATION'),
      inputText: this.meetingDuration,
      id: DateTimeFieldType.TimeDurationField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      isHidden: !this.isMeetingDurationEnabled,
      isRequired: this.hasMeetingDurationRequired || ((location) => location?.toLowerCase()?.includes('Chicago') || location?.includes('Oregon'))(this.activityService?.selectedActivity?.location),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    if((!this.isLiveMeet && this.isMeetingTypeFAEnabled) && !this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting && !_.isEmpty(this.meetingActivityTypes)){
      this.meetingTypeFormField = {
        label: this.translate.instant('MEETING_TYPE'),
        inputText: this.activityService.getMeetingType((this.activityService.selectedActivity as AppointmentActivity).indskr_meetingtype),
        placeholderLabel: (this.activityService.meetingTypes.length > 0 && !(!this.isNotReadOnly && !((this.activityService.selectedActivity as AppointmentActivity).indskr_meetingtype)) ? "" : this.translate.instant("NO") + " ") + this.translate.instant("MEETING_TYPE"),
        id: 'meeting-type-field',
        isReadOnly: true,
        isDisabled: !(this.isNotReadOnly),
        showArrow: (this.isNotReadOnly && this.activityService.meetingTypes.length > 0),
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: this.isNotReadOnly && this.activityService.meetingTypes.length > 0 && this.requiredFields['meeting-type-field']['isRequired'],
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isNotReadOnly && this.activityService.meetingTypes.length > 0 && this.requiredFields['meeting-type-field']['isRequired'] && this.isClickedMeetingTypes && _.isEmpty(this.activityService.getMeetingType((this.activityService.selectedActivity as AppointmentActivity).indskr_meetingtype)),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }

    this.ownerFormField = {
      label: this.translate.instant('OM_OWNER'),
      inputText: this.activityService.selectedActivity.meetingOwnerName,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

    if(!_.isEmpty(this.meetingActivityTypes) || !_.isEmpty(this.activityService.selectedActivity.indskr_activitytype)){
      const activityTypeFieldText = this.activityService.selectedActivity.activityTypeName
        || this.meetingActivityTypes?.find(t => t.indskr_activitytypeid === this.activityService.selectedActivity.indskr_activitytype)?.indskr_name;
      if(_.isEmpty(this.activityService.selectedActivity.activitySubTypeName)){
        this.activityService.selectedActivity.activitySubTypeName =  this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, this.activityService.selectedActivity.indskr_activitytype)?.find(ast => ast.indskr_default)?.indskr_name ;
      }
      this.activityTypeFormField = {
        label: this.translate.instant('FORMAT'),
        inputText: activityTypeFieldText,
        inputValue: activityTypeFieldText,
        customPlaceholderLabel: this.isNotReadOnly ? this.translate.instant('SELECT_FORMAT') : this.translate.instant('NO_FORMAT'),
        id: 'meeting-activityType-field',
        isReadOnly: true,
        isDisabled: this.isAccountVisitNestedMeeting || this.isReadOnlyRecommendedMeeting || !this.isNotReadOnly || (this.isGpsEnabled && (this.activityService.selectedActivity as AppointmentActivity).gpsCheckInDetails?.indskr_checkinstatus != null),
        showArrow: (this.isNotReadOnly && !_.isEmpty(this.meetingActivityTypes)),
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: this.isNotReadOnly && !_.isEmpty(this.meetingActivityTypes) && this.requiredFields['meeting-activityType-field']['isRequired'],
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: (this.isNotReadOnly && !_.isEmpty(this.meetingActivityTypes) && this.requiredFields['meeting-activityType-field']['isRequired']) && this.isClickedFormat && _.isEmpty(this.activityService.selectedActivity.activityTypeName),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }

    this.initAllPresentationPlayDurationField();

    if(!this.isAccountVisitRecord && (!_.isEmpty(this.meetingActivityTypes) || !_.isEmpty(this.activityService.selectedActivity.indskr_activitysubtype))){
      const formatDetailShowArrow = (
        this.isNotReadOnly
        && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING))
      );
      this.activitySubTypeFormField = {
        label: this.translate.instant('FORMAT_DETAILS'),
        isHidden: this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE) ||  _.isEmpty(this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, this.activityService.selectedActivity.indskr_activitytype)),
        inputText: this.activityService.selectedActivity.activitySubTypeName,
        inputValue: this.activityService.selectedActivity.activitySubTypeName,
        customPlaceholderLabel: this.isNotReadOnly ? this.translate.instant('SELECT_FORMAT_DETAILS'):this.translate.instant('NO_FORMAT_DETAILS'),
        id: 'meeting-activitySubType-field',
        isReadOnly: true,
        isDisabled: !(this.isNotReadOnly),
        showArrow: formatDetailShowArrow,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: formatDetailShowArrow && this.requiredFields['meeting-activitySubType-field']['isRequired'],
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: (this.isNotReadOnly && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING)) && this.requiredFields['meeting-activitySubType-field']['isRequired']) && this.isClickedFormatDetails && _.isEmpty(this.activityService.selectedActivity.activitySubTypeName),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }

    // this.meetingLocationFormField = {
    //   isHidden: !this.isLocationEnabled,
    //   label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
    //   inputText: (this.activityService.selectedActivity && this.activityService.selectedActivity.location && this.activityService.selectedActivity.location != 'No Location' && this.activityService.selectedActivity.location != this.translate.instant('NO_LOCATION')) ? this.activityService.selectedActivity.location : '',
    //   id: 'location-field',
    //   isReadOnly: true,
    //   customPlaceholderLabel: (this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != MeetingActivityState.Completed)?this.translate.instant('SELECT_ACTIVITY_DETAILS_LOCATION'):this.translate.instant('NO_LOCATION'),
    //   isDisabled: !(this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != MeetingActivityState.Completed),
    //   showArrow: (this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != MeetingActivityState.Completed),
    //   formFieldType: FormFieldType.NEW_PAGE_SELECT,
    //   eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    // };

    // const isAccountRequired = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY)
    //   || this.isAccountVisitRecord;
    // const isAccountFormDisabled = !(
    //     this.activityService.selectedActivity
    //     && !this.isReadOnlyJointMeeting
    //     && !this.enableReadonlyViewForMeetings
    //     && (
    //       this.activityService.selectedActivity.state != MeetingActivityState.Completed
    //       || (
    //         this.activityService.selectedActivity.state == MeetingActivityState.Completed
    //         && (this.activityService.selectedActivity as AppointmentActivity).accounts.length !== 0
    //       )
    //     )
    //   ) || this.isAccountVisitNestedMeeting || this.from === PageName.AccountPlanActivities;

    // const isAccountFormShowArrow = (
    //   this.activityService.selectedActivity
    //   && !this.isReadOnlyJointMeeting
    //   && !this.enableReadonlyViewForMeetings
    //   && !this.isAccountVisitNestedMeeting
    //   && (
    //     this.activityService.selectedActivity.state != MeetingActivityState.Completed
    //     && !this.isGpsEnabled
    //     || (
    //       this.activityService.selectedActivity.state == MeetingActivityState.Completed
    //       && (this.activityService.selectedActivity as AppointmentActivity).accounts.length !== 0
    //     )
    //   )
    //   ||
    //     this.isGpsEnabled
    // );
    // this.accountFormField = {
    //   label: this.translate.instant('ACCOUNTS'),
    //   inputText: (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).accounts.length != 0) ? (this.activityService.selectedActivity as AppointmentActivity).accountString : '',
    //   customPlaceholderLabel: ((this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed))? this.translate.instant('SELECT_ACCOUNTS'):this.translate.instant('NO_ACCOUNT'),
    //   id: 'account-field',
    //   isReadOnly: true,
    //   isRequired: (this.isGpsEnabled || isAccountRequired && meeting.state != MeetingActivityState.Completed ) ||  this.activityService.selectedActivity.state != MeetingActivityState.Canceled,
    //   isEmptyRequiredField: this.isAccountClicked && !this.requiredFields['account-field']['isAdded'],
    //   isDisabled: isAccountFormDisabled ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
    //   showArrow: isAccountFormShowArrow ||  this.activityService.selectedActivity.state != MeetingActivityState.Canceled,
    //   formFieldType: FormFieldType.NEW_PAGE_SELECT,
    //   errorMessage: this.activityService.getErrorMessageRequiredField(),
    //   eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    // };

    if(this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_meetinglinkedtomarketingbusinessplan']){
      if((this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan == null) {
        (this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan = false;
      }
      this.meetinglinkedtoamarketingbusinessplanField = {
        label: this.translate.instant('IS_MEETING_LINKED_MBP'),
        inputText:  this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan ?  this.translate.instant("YES") : this.translate.instant("NO"),
        placeholderLabel: this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0 ? this.translate.instant('IS_MEETING_LINKED_MBP') : this.translate.instant('IS_MEETING_LINKED_MBP'),
        id:'is-meeting-linked-mbp-field',
        isReadOnly:true,
        isDisabled: (this.activityService.selectedActivity as AppointmentActivity).state !== 0 || !(this.isNotReadOnly),
        showArrow: (this.activityService.selectedActivity as AppointmentActivity).state === 0 && this.isNotReadOnly ,
        isRequired: false,
        formFieldType: FormFieldType.POPOVER_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      }
    }

    if(this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)){
      this.marketingBusinessPlanField = {
        label: this.translate.instant('MARKETING_BUSINESS_PLAN'),
        inputText:  this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).marketingBusinessPlanName ?  (this.activityService.selectedActivity as AppointmentActivity).marketingBusinessPlanName : '',
        placeholderLabel: this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0 ? this.translate.instant('MARKETING_BUSINESS_PLAN') : this.translate.instant('MARKETING_BUSINESS_PLAN'),
        id:'marketing-business-plan-field',
        isReadOnly:true,
        isDisabled: (this.activityService.selectedActivity as AppointmentActivity).state !== 0 || !(this.isNotReadOnly),
        showArrow: (this.activityService.selectedActivity as AppointmentActivity).state === 0 && this.isNotReadOnly,
        isRequired: this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan,
        isHidden: !((this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan && this.authService.user.buConfigs['indskr_meetinglinkedtomarketingbusinessplan']) ,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      }
    }

    if(this.hasCoachingPlan){
      this.coachingPlanField = {
        label: this.translate.instant('COACHING_PLAN'),
        inputText:  this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).indskr_coachingplanname ?  (this.activityService.selectedActivity as AppointmentActivity).indskr_coachingplanname : '',
        placeholderLabel: this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0 ? this.translate.instant('COACHING_PLAN') : this.translate.instant('COACHING_PLAN'),
        id:'coaching-plan-field',
        isReadOnly:true,
        isDisabled: (this.activityService.selectedActivity as AppointmentActivity).state !== 0 || this.isReadOnlyJointMeeting ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
        showArrow: (this.activityService.selectedActivity as AppointmentActivity).state === 0  || !this.isReadOnlyJointMeeting ||  this.activityService.selectedActivity.state != MeetingActivityState.Canceled,
        // isRequired: this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan,
        // isHidden: !(this.activityService.selectedActivity as AppointmentActivity).hasLinkedToMarketingBusinessPlan,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
      }
    }


    // Form Field for Account Plan for Meetings
    this.secondLevelFormFields.push({
      isHidden: !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_PLANS_FOR_MEETING),
      label: this.translate.instant('ACCOUNT_PLANS'),
      inputText: this.displayText(
        (this.activityService.selectedActivity as AppointmentActivity)
          .associatedAccountPlans,
        'accountPlanName'
      ),
      customPlaceholderLabel:
        this.activityService.selectedActivity &&
        !this.isReadOnlyJointMeeting &&
        !this.enableReadonlyViewForMeetings &&
        this.activityService.selectedActivity.state !=
        MeetingActivityState.Completed && this.checkIfAccountPlansAssociated()
          ? this.translate.instant('SELECT_ACCOUNT_PLANS')
          : this.translate.instant('ACCOUNTS_CALL_NO_ACCOUNT_PLANS'),
      id: 'account-plans-field',
      isReadOnly: true,
      isDisabled: !(this.isNotReadOnly && this.checkIfAccountPlansAssociated() || (this.activityService.selectedActivity && (this.activityService.selectedActivity.state == MeetingActivityState.Completed && (this.activityService.selectedActivity as AppointmentActivity).accounts.length !== 0 || (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans.length !== 0))),
      showArrow: this.isNotReadOnly && this.checkIfAccountPlansAssociated() || (this.activityService.selectedActivity && (this.activityService.selectedActivity.state == MeetingActivityState.Completed && (this.activityService.selectedActivity as AppointmentActivity).accounts.length !== 0 || (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans.length !== 0)),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) =>
        this.handleFormFieldEvent(id, event, eventName),
    });

    const isContactFormDisabled = (this.enableReadonlyViewForMeetings && (this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0)
      || (this.isAccountVisitRecord && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0) || this.from === PageName.AccountPlanActivities || this.isFromChat;

    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
    let contactString = '';
    if(this.contactService.isMeetingContactSequenceEnabled) {
      contactString =  (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).contacts.length != 0) ? this.getMeetingContactsSequenceString() : '';
    }
    else{
      contactString =  (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).contacts.length != 0) ? (this.activityService.selectedActivity as AppointmentActivity).contactString : '';
    }
    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //

    // this.contactFormField = {
    //   label: (this.isLiveMeet) ? this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS'):this.utilityService.globalCustomersText,
    //   inputText: contactString,
    //   customPlaceholderLabel: (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0) ? (this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.isFromXperiences)?this.translate.instant('No '+this.utilityService.globalCustomerText): this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', {globalCustomerText : this.utilityService.globalCustomersText}) : '',
    //   id: 'contact-field',
    //   isReadOnly: true,
    //   isDisabled: isContactFormDisabled ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
    //   showArrow: !isContactFormDisabled ||  this.activityService.selectedActivity.state != MeetingActivityState.Canceled,
    //   formFieldType: FormFieldType.NEW_PAGE_SELECT,
    //   isRequired: !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed && this.activityService.selectedActivity.state != MeetingActivityState.Canceled && !this.activityService.selectedActivity?.isDiffPosition && this.requiredFields['contact-field']['isRequired'],
    //   isEmptyRequiredField: this.isClickedContacts && this.requiredFields['contact-field']['isRequired'] && !this.requiredFields['contact-field']['isAdded'],
    //   errorMessage: this.activityService.getErrorMessageRequiredField(),
    //   eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    // };
    this.locationField = {
      isHidden: !this.isLocationEnabled,
      label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
      inputText: (this.activityService.selectedActivity && this.activityService.selectedActivity.location && this.activityService.selectedActivity.location != 'No Location' && this.activityService.selectedActivity.location != this.translate.instant('NO_LOCATION')) ? this.activityService.selectedActivity.location : '',
      id: 'location-field',
      isReadOnly: true,
      customPlaceholderLabel: this.isNotReadOnly ? this.translate.instant('SELECT_ACTIVITY_DETAILS_LOCATION'):this.translate.instant('NO_LOCATION'),
      isDisabled:  (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && (this.activityService.selectedActivity as AppointmentActivity).gpsCheckInDetails.indskr_checkinstatus != null) || !(this.activityService.selectedActivity && !this.isReadOnlyRecommendedMeeting && !this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed || this.activityService.selectedActivity.state == MeetingActivityState.Completed) ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
      showArrow: (this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed  &&  this.activityService.selectedActivity.state != MeetingActivityState.Canceled && !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && (this.activityService.selectedActivity as AppointmentActivity).gpsCheckInDetails.indskr_checkinstatus == null)),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    const isCoVisitoFieldDisabled = (this.activityService.teamViewActive || !this.activityService.isMeetingOwner || (this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity['accompaniedUserList'].length === 0) || this.activityService.selectedActivity?.isDiffPosition)
    this.secondLevelFormFields.push({
      isHidden: (this.activityService.selectedActivity.isCompleted && (!this.activityService.selectedActivity['accompaniedUserList'])) || !(this.hasJointMeeting || (this.activityService.selectedActivity['accompaniedUserList'] && this.activityService.selectedActivity['accompaniedUserList'].length >0)),
      label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
      inputText: (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).accompaniedUserList.length != 0) ? (this.activityService.selectedActivity as AppointmentActivity).accompaniedUserString : '',
      customPlaceholderLabel: ((this.activityService.selectedActivity && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed) && !this.activityService.selectedActivity?.isDiffPosition)? this.translate.instant('ACTIVITY_DETAILS_SELECT_COVISITORS'):this.translate.instant('ACTIVITY_DETAILS_NO_COVISITORS'),
      id: 'covisitor-field',
      isReadOnly: true,
      isDisabled: (this.enableReadonlyViewForMeetings || (this.activityService.selectedActivity.isCompleted &&  this.activityService.selectedActivity.state == MeetingActivityState.Canceled && this.activityService.selectedActivity['accompaniedUserList'].length === 0) || this.activityService.selectedActivity?.isDiffPosition && this.isNotReadOnly),
      showArrow: !(this.enableReadonlyViewForMeetings || (this.activityService.selectedActivity.isCompleted &&  this.activityService.selectedActivity.state == MeetingActivityState.Canceled && this.activityService.selectedActivity['accompaniedUserList'].length === 0) || this.activityService.selectedActivity?.isDiffPosition && this.isNotReadOnly) ,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    });
    this.secondLevelFormFields.push({
      isHidden: !this.activityService.shouldShowCovisitorComplianceForm(this.activityService.selectedActivity),
      label: this.translate.instant('COVISITOR_COMPLIANCE'),
      inputText: (this.activityService.selectedActivity as AppointmentActivity).covisitorComplianceDescription
                  ? (this.activityService.selectedActivity as AppointmentActivity).covisitorComplianceDescription
                  : this.authService.user.covisitorComplianceDescription,
      inputValue: !!((this.activityService.selectedActivity as AppointmentActivity).covisitorComplianceAcked),
      isRequired: this.activityService.selectedActivity.state !== MeetingActivityState.Completed
                  ? this.authService.user.isCovisitorComplianceMandatory
                  : false,
      isEmptyRequiredField: this.activityService.selectedActivity.state !== MeetingActivityState.Completed
                            ? this.authService.user.isCovisitorComplianceMandatory
                              && this.requiredFields['covisitor-compliance']['isEvaluated'] === true
                              && !this.requiredFields['covisitor-compliance']['isAdded']
                            : false,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      id: 'covisitor-compliance',
      isReadOnly: true,
      isDisabled: this.shouldDisableCovisitorComplianceInput(),
      formFieldType: FormFieldType.DESCRIPTION_AND_CONFIRM,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName)
    });
    const enableEdaField = this.authService.hasFeatureAction(FeatureActionsMap.CAPTURE_REMOTE_DETAILING_DURATION) && this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype);
    if (enableEdaField) {
      console.table(meeting.activityattendeejoiningdetails, ["indskr_joineddate", "indskr_leftdate"]);
      console.log("activityattendeejoiningdetails duartion", this.activityService.formattedDuration);
      this.secondLevelFormFields.push({
        label: this.translate.instant('REMOTE_DETAILING_DURATION'),
        inputText: this.activityService.formattedDuration,
        id: 'edd-field',
        isReadOnly: true
      });
    }

    let isViewReadOnly = (this.enableReadonlyViewForMeetings || (this.activityService.selectedActivity.isCompleted) || this.activityService.selectedActivity?.isDiffPosition ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled);
    if( (isViewReadOnly && (this.activityService.selectedActivity as AppointmentActivity).meetingOutcomeId) || (!isViewReadOnly && this.activityOutcomeOptions.length > 0) ){
      let meetingOutcomeFieldViewData: IndFormFieldViewDataModel;
      meetingOutcomeFieldViewData = {
        label: this.translate.instant('ACTIVITY_OUTCOME'),
        inputText: (this.activityService.selectedActivity as AppointmentActivity).meetingOutcomeString,
        customPlaceholderLabel: this.activityOutcomeOptions.length == 0 ? this.translate.instant('NO_ACTIVITY_OUTCOME'):this.translate.instant('SELECT_ACTIVITY_OUTCOME'),
        id: 'meeting-outcome-field',
        isReadOnly: true,
        isDisabled: isViewReadOnly || (this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS)),
        showArrow: !isViewReadOnly && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || (this.isCovisitor && this.coVisitorAccess === CovisitorAccess.FULL_ACCESS)),
        isRequired: this.activityOutcomeisMandatory,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isHidden: ((!isViewReadOnly && this.activityOutcomeOptions.length == 0) || (isViewReadOnly && !(this.activityService.selectedActivity as AppointmentActivity).meetingOutcomeId)),
        formFieldType: FormFieldType.POPOVER_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      this.secondLevelFormFields.push(meetingOutcomeFieldViewData);
    }
    if(this.isCompetitorProductsEnabled){
      this.secondLevelFormFields.push({
        label: this.translate.instant('COMPETITOR_PRODUCTS'),
        inputText: this._currentCompetitorProductsString,
        customPlaceholderLabel: !this.allCompetitorProducts ? this.translate.instant('NO_COMPETITOR_PRODUCTS') : this.translate.instant('SELECT_COMPETITOR_PRODUCTS'),
        id: 'competitor-products-field',
        isReadOnly: true,
        isDisabled: isViewReadOnly || !this.allCompetitorProducts.length ||  (this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS)),
        showArrow: (!isViewReadOnly && !!this.allCompetitorProducts.length) && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || (this.isCovisitor && this.coVisitorAccess === CovisitorAccess.FULL_ACCESS)),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      });
    }
    if(this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) {
      let linkedKitBooking = this.activityService.getActivityByID(this.activityService.selectedActivity['indskr_kitbooking']) as SetBookingActivity;
      this.secondLevelFormFields.push({
        label: this.translate.instant('KIT_BOOKING'),
        inputText: linkedKitBooking ? linkedKitBooking['subject'] : '',
        id: 'kit-booking-field',
        customPlaceholderLabel: this.from !== PageName.SetBookingActivityDetailsComponent ? this.translate.instant('SELECT_KIT_BOOKING') :this.translate.instant('NO_KIT_BOOKING'),
        isReadOnly: true,
        // isDisabled: isViewReadOnly || (this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS)),
        isDisabled: this.from === PageName.SetBookingActivityDetailsComponent || this.isFromChat ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
        showArrow: (this.from !== PageName.SetBookingActivityDetailsComponent || !this.isFromChat ) &&  this.activityService.selectedActivity.state != MeetingActivityState.Canceled,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName)
      });
    }
    this.selectedContacts = (this.activityService.selectedActivity as AppointmentActivity).contacts;

    /* To check foll.
      1.Feature Action is enabled
      2.showBestTimeRecommendation is enabled for this user
      3.Device should be online
    */
    if (this.isTimeRecommendationEnabled && this.authService.user.showBestTimeRecommendation && !this.device.isOffline)
    {
      if (this.isSelectedContactsCheck === false && this.selectedContacts.length > 0) {
          this.isSelectedContactsCheck = true;
          //Calc Best time if selected Contact length is only one on Form init
          this.calculateBestRecommendedTime();
        } else {
          //To check for scheduledStart.isAfter(currentTimeStart) then hide else show
          const scheduledStart = moment(this.activityService.selectedActivity.scheduledStart);
          const currentTimeStart: moment.Moment = moment();

          console.warn(`scheduledStart.isAfter(currentTimeStart)`);
          console.log(scheduledStart.isAfter(currentTimeStart));

          if (scheduledStart.isAfter(currentTimeStart)) {
            this.showTimeRecommendations = true;
          }
          else {
            this.showTimeRecommendations = false;
          }
        }
    }


    if(updateBusinessProcess){
      this.meetingProcessView = [];
      if(this.activityService.selectedActivity && (this.activityService.selectedActivity.type == 'Appointment' || this.activityService.selectedActivity.type == 'LiveMeet' || this.activityService.selectedActivity.type == 'Joint Meeting' )){
        this.detailsSectionId = 'details'+Math.random().toString(36).substring(7);
        this.meetingProcessView.push({
          id: 'detailsprocess'+Math.random().toString(36).substring(7),
          title: this.translate.instant('DETAILS'),
          bindingElementId: this.detailsSectionId,
          isActive:true,
        });
        // InStore Meeting Check
        if (this.isInStoreMeeting) {
          this.photosSectionId = 'photos' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'photosprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('IN_STORE_PHOTOS'),
            bindingElementId: this.photosSectionId,
            isActive: false,
          });
        }
        // GPS Activity Photos Check
        if (this.isManualGPSCheckinEnabled && !this.isAccountVisitNestedMeeting) {
          this.gpsActivityPhotosSectionId = 'gpsActivityPhotos' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'gpsActivityPhotosprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('CHECK_IN_CHECK_OUT_PHOTOS'),
            bindingElementId: this.gpsActivityPhotosSectionId,
            isActive: false,
          });
        }

        if (this.isMeetingObjectiveSelectionFAEnabled) {
          this.objectiveSectionId = 'objectives' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'objectives' + Math.random().toString(36).substring(7),
            title: this.translate.instant('OBJECTIVES'),
            bindingElementId: this.objectiveSectionId,
            isActive: false,
          });
        }

        this.meetingStSectionId = 'meetingstructure'+Math.random().toString(36).substring(7);
        this.meetingProcessView.push({
          id: 'meetingstructureprocess'+Math.random().toString(36).substring(7),
          title: this.translate.instant('MEETING_STRUCTURE_CAMEL_CASE'),
          bindingElementId: this.meetingStSectionId,
          isActive:false,
        });

        if(this.sharedResources && this.sharedResources.length > 0){
          this.sharedResourcesSectionId = 'sharedresources'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'sharedresourcesprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('SHARED_RESOURCES'),
            bindingElementId: this.sharedResourcesSectionId,
            isActive:false,
          });
        }

        if (this.isProcedureSectionEnabled) {
          this.procedureSectionId = 'procedures' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'proceduresprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('PROCEDURES'),
            bindingElementId: this.procedureSectionId,
            isActive: false,
          });
        }

        if(this.isKeyMessageSelectionEnabled){
          this.keyMessagesSectionId = 'keymessages'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'keymessagesprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('PRODUCT_KEY_MESSGES_CAMEL_CASE'),
            bindingElementId: this.keyMessagesSectionId,
            isActive:false,
          });
        }

        if (this.isSupportingMaterialsEnabled) {
          this.supportingMaterialsSectionId = 'supportingmaterials' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'supportingmaterialsprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('SUPPORTING_MATERIALS'),
            bindingElementId: this.supportingMaterialsSectionId,
            isActive: false,
          });
        }

        if(this.isTherapeuticAreaEnabled){
          this.therapeuticSectionId = 'therapeuticarea'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'therapeuticareaprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('THERAPEUTIC_AREAS'),
            bindingElementId: this.therapeuticSectionId,
            isActive:false,
          });
        }

        if(this.isDiseaseAreaEnabled){
          this.diseaseAreaSectionId = 'diseasearea'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'diseasearea'+Math.random().toString(36).substring(7),
            title: this.translate.instant('DISEASE_AREA'),
            bindingElementId: this.diseaseAreaSectionId,
            isActive:false,
          });
        }

        if(this.isProductIndicationEnabled){
          this.productIndicationSectionId = 'productindication'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'productindication'+Math.random().toString(36).substring(7),
            title: this.translate.instant('PRODUCT_INDICATION'),
            bindingElementId: this.productIndicationSectionId,
            isActive:false,
          });
        }

        if(this.hasMeetingEventsFA){
          this.meetingEventsSectionId = 'meetingevents'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'meetingeventsprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('EVENTS'),
            bindingElementId: this.meetingEventsSectionId,
            isActive:false,
          });
        }
        if (this.isOppSectionEnabled) {
          this.meetingOpportunitiesSectionId = 'meetingopportunities' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'meetingopportunitiesprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('OPPORTUNITIES'),
            bindingElementId: this.meetingOpportunitiesSectionId,
            isActive: false,
          });
        }
        if (this.isMeetingAssetsEnabled) {
          this.meetingAssetsSectionId = 'meetingassets' + Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'meetingassetsprocess' + Math.random().toString(36).substring(7),
            title: this.translate.instant('ASSETS'),
            bindingElementId: this.meetingAssetsSectionId,
            isActive: false,
          });
        }

        if(this.isNotesEnabled){
          this.meetingNotesSectionId = 'meetingnotes'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'meetingnotesprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('NOTES'),
            bindingElementId: this.meetingNotesSectionId,
            isActive:false,
          });
        }

        if(this.isMeetingNotesEnabled || this.isMeetingAttachmentEnabled){
          this.meetingNotesAndAttachmentsSectionId = 'meetingnotesandattachments'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'meetingnotesandattachmentsprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('NOTES'),
            bindingElementId: this.meetingNotesAndAttachmentsSectionId,
            isActive:false,
          });
        }

        if(this.isCoachingEnabled && !this.enableReadonlyViewForMeetings){
          this.coachingDetailsSectionId = 'coachingdetails'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'coachingdetailsprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('COACHING'),
            bindingElementId: this.coachingDetailsSectionId,
            isActive:false,
          });
        }

        if(this.hasMeetingObjectives){
          this.callObjectiveSectionId = 'callobjective'+Math.random().toString(36).substring(7);
          this.meetingProcessView.push({
            id: 'callobjectiveprocess'+Math.random().toString(36).substring(7),
            title: this.translate.instant('NEXT_MEETING_OBJECTIVES_CAP'),
            bindingElementId: this.callObjectiveSectionId,
            isActive:false,
          });
        }
      }
    }
  }

  private initMeetingDateFormFields() {
    let dateTimeValue = this.activityService.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.meetingStartDateTime = dateTimeValue.startDateTime;
      this.meetingStartDate = dateTimeValue.startDate;
      this.meetingStartTime = dateTimeValue.startTime;
      this.meetingEndDateTime = dateTimeValue.endDateTime;
      this.meetingEndDate = dateTimeValue.endDate;
      this.meetingEndTime = dateTimeValue.endTime;
      this.meetingDuration = dateTimeValue.duration;
    }
    this.meetingStartDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.meetingStartDate,
      startDateTimeValue: this.meetingStartDateTime,
      endDateTimeValue: this.meetingEndDateTime,
      fromViewPage: CurViewPageType.Appointment,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: !(this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly),
      showArrow: this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      isRequired: this.activityService.selectedActivity && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.meetingStartTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.meetingStartTime,
      startDateTimeValue: this.meetingStartDateTime,
      endDateTimeValue: this.meetingEndDateTime,
      fromViewPage: CurViewPageType.Appointment,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: !(this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly),
      showArrow: this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      isRequired: this.activityService.selectedActivity && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
    };
    this.meetingEndDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.meetingEndDate,
      startDateTimeValue: this.meetingStartDateTime,
      endDateTimeValue: this.meetingEndDateTime,
      fromViewPage: CurViewPageType.Appointment,
      id: DateTimeFieldType.EndDateField,
      isHidden: !this.isMeetingEndDateEnabled,
      isReadOnly: true,
      isDisabled: !(this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly),
      showArrow: this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      isRequired: this.activityService.selectedActivity && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.meetingEndTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.meetingEndTime,
      startDateTimeValue: this.meetingStartDateTime,
      endDateTimeValue: this.meetingEndDateTime,
      fromViewPage: CurViewPageType.Appointment,
      isHidden: !this.isMeetingEndDateEnabled,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: !(this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly),
      showArrow: this.activityService.selectedActivity && !this.isDateEditable && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly,
      isRequired: this.activityService.selectedActivity && !this.activityService.selectedActivity.isFromXperiences && this.isNotReadOnly
    };
  }

  private initAllPresentationPlayDurationField() {
    this.presentationDurationFormField = undefined;
    if (this.faService.getFeatureAction(FeatureActionsMap.ALL_PRESENTATION_PLAY_DURATION)?.getIsOn()) {
      const currentActivity = this.activityService.selectedActivity as AppointmentActivity;
      if (currentActivity.isRemoteDetailing) return;

      const meetingFormats = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.HCP_MEETING);
      if (currentActivity.indskr_activitytype) {
        const existingFormat = meetingFormats.find(mf => mf.indskr_activitytypeid == currentActivity.indskr_activitytype);
        if (existingFormat?.indskr_remotechannel) return;
      }

      const formattedString = this.activityService.getAllPresentationPlayDuration(this.activityService.selectedActivity as AppointmentActivity);

      if (formattedString) {
        this.presentationDurationFormField = {
          label: this.translate.instant('ALL_PRESENTATION_PLAY_DURATION'),
          inputText: formattedString,
          id: 'all-presentation-play-duration-field',
          isReadOnly: true,
          isDisabled: false,
          showArrow: false,
        };
      }
    }
  }

  public openPhotoViewer(photoAttachment) {
    const activity = <AppointmentActivity> this.activityService.selectedActivity;
    const photoAttachments = activity.inStorePhotos;
    photoAttachments.forEach(photo => { photo.isSelected = photo.indskr_photoattachmentid == photoAttachment.indskr_photoattachmentid });
    const photoModel: IndPhotoViewerDataModel = {
      pageTitle: this.translate.instant("INSTORE") + ' - ' + activity.accountString,
      masterPhotos: photoAttachments,
      readOnly: true,
      callbackEvent: null
    };
    this.navService.pushWithPageTracking(IndPhotoViewerComponent, PageName.IndPhotoViewerComponent, { photoModel: photoModel }, PageName.IndPhotoViewerComponent);
  }

  initGpsFields(){
    if(_.isEmpty(this.activityService.selectedActivity)) return;
    let meeting = this.activityService.selectedActivity as AppointmentActivity;

    let checkinInput = '';
    if(this.isManualGPSCheckinEnabled) {
      checkinInput= meeting.gpsCheckInDetails?.indskr_checkindatetime ? this.datePipe.transform(meeting.gpsCheckInDetails.indskr_checkindatetime, 'shortTime') +  ' | ' +  this.translate.instant(meeting.gpsCheckInDetails.indskr_checkinstatus === GPS_STATUS.VALID ? 'VALID' : 'MANUAL') : '';
    } else {
      checkinInput= meeting.gpsCheckInDetails?.indskr_checkindatetime ? this.datePipe.transform(meeting.gpsCheckInDetails.indskr_checkindatetime, 'shortTime') +  ' | ' +  this.translate.instant(meeting.gpsCheckInDetails.indskr_checkinstatus === GPS_STATUS.VALID ? 'VALID' : 'INVALID') : '';
    }

    this.checkInField = {
      isDateField: true,
      label: this.translate.instant('CHECK_IN_STATUS'),
      inputText:  checkinInput,
      customPlaceholderLabel: meeting.gpsCheckInDetails && !meeting.gpsCheckInDetails.indskr_checkindatetime ? this.translate.instant('START_CHECK_IN') : '',
      id: 'check-in-field',
      isReadOnly: true,
      isRequired : this.authService.user.buSettings['indskr_gpsfunctionality'] && meeting.state != MeetingActivityState.Completed,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField : this.checkinFieldsEmpty && this.authService.user.buSettings['indskr_gpsfunctionality'] && meeting.gpsCheckInDetails.indskr_checkinstatus == null,
      isHidden :  !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }

    let checkoutInput = '';
    if(this.isManualGPSCheckinEnabled) {
      checkoutInput= checkinInput= meeting.gpsCheckInDetails?.indskr_checkoutdatetime ? this.datePipe.transform(meeting.gpsCheckInDetails.indskr_checkoutdatetime, 'shortTime') +  ' | ' +  this.translate.instant(meeting.gpsCheckInDetails.indskr_checkoutstatus === GPS_STATUS.VALID ? 'VALID' : 'MANUAL') : '';
    } else {
      checkoutInput= meeting.gpsCheckInDetails?.indskr_checkoutdatetime ? this.datePipe.transform(meeting.gpsCheckInDetails.indskr_checkoutdatetime, 'shortTime') +  ' | ' +  this.translate.instant(meeting.gpsCheckInDetails.indskr_checkoutstatus === GPS_STATUS.VALID ? 'VALID' : 'INVALID') : '';
    }

    this.checkOutField = {
      isDateField: true,
      label: this.translate.instant('CHECK_OUT_STATUS'),
      inputText: checkoutInput,
      customPlaceholderLabel: meeting.gpsCheckInDetails && !meeting.gpsCheckInDetails.indskr_checkoutdatetime ?  this.translate.instant('START_CHECK_OUT') : '',
      id: 'check-out-field',
      isReadOnly: true,
      isRequired : this.authService.user.buSettings['indskr_gpscheckoutmandatory']  && meeting.state != MeetingActivityState.Completed,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField : this.checkOutFieldsEmpty && this.authService.user.buSettings['indskr_gpscheckoutmandatory'] && meeting.gpsCheckInDetails.indskr_checkoutstatus == null,
      isHidden : !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  get isDateEditable() {
    if (this.activityService.selectedActivity && this.lockStartEndTimeIfContentPlayed) {
     return this.isContentPlayed;
    }
  }

  get isContentPlayed() {
    const appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    if (!_.isEmpty(appointmentActivity.presentations)) {
      const playedContentIds = appointmentActivity.presentations.map(cont => cont.contentId);
      return ((!_.isEmpty(appointmentActivity.activityPresentations) && appointmentActivity.activityPresentations.some(pre => playedContentIds.includes(pre.presentationId)))
      || (!_.isEmpty(appointmentActivity.activityResources) && appointmentActivity.activityResources.some(res => playedContentIds.includes(res.indskr_ioresourceid) || playedContentIds.includes(res.indskr_iodocumentid))))
    }
  }

  isGPSStatusValid() {
    let isValid = false;

    const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;

    if(currentMeeting.gpsCheckInDetails
    && currentMeeting.gpsCheckInDetails.indskr_checkinstatus === GPS_STATUS.VALID
    && currentMeeting.gpsCheckInDetails.indskr_checkoutstatus === GPS_STATUS.VALID) {
        isValid = true;
      }

    return isValid;
  }

  checkIfAccountPlansAssociated(): boolean {
    let hasAccountPlans = false;
    if (this.isNotReadOnly) {
      const accountIds = (this.activityService
        .selectedActivity as AppointmentActivity).accounts.map(
        (el) => el.id
      );
      const accountPlanDataSet = [];
      if (accountIds.length) {
        this.accountManagementService.accountPlans.forEach((e) => {
          if (accountIds.includes(e.accountId)) {
            accountPlanDataSet.push(e)
          }
        });
      }
      if (!(this.activityService.selectedActivity as AppointmentActivity).accounts.length) {
        hasAccountPlans = true;
      }
      if (accountPlanDataSet.length) {
        hasAccountPlans = true;
      }
    }
    return hasAccountPlans;
  }

  public displayText(any, fieldName: string): string {
    if (_.isEmpty(any)) {
      return '';
    } else {
      any = this.sortListByFielName(any, fieldName);
      return any.length > 1
        ? any[0][fieldName].concat(' +').concat(any.length - 1)
        : any[0][fieldName];
    }
  }

  sortListByFielName(options, fieldName: string) {
    if (_.isEmpty(options)) return [];
    return options.sort((a, b) => {
      let nameA: string = a[fieldName];
      let nameB: string = b[fieldName];
      if (!nameA || !nameB) return 1;
      nameA = nameA.toLowerCase();
      nameB = nameB.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA >= nameB) return 1;
    });
  }

  private initNotesSectionHeader() {
    this.notesSectionHeader = {
      id: 'notes',
      title: this.isMeetingNotesRelabelFAEnabled ? this.translate.instant('MEDICAL_DISCUSSION'): this.translate.instant('ACTIVITY_DETAILS_NOTES'),
      controls: [
        {
          id: 'notes-attachment',
          text: this.translate.instant('ATTACH'),
          isVisible: this.hasMeetingAttachmentFA,
          isDisabled: this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || this.activityService.selectedActivity.state == MeetingActivityState.Canceled || !this.activityService.isMeetingOwner
        }
      ]
    }
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  public isNoteDisabled(): boolean {
    let isDisabled: boolean = false;
    isDisabled = this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.backgroundUploadInProgress || this.enableReadonlyViewForMeetings;
    if ((this.coVisitorAccess === CovisitorAccess.MINIMUM_ACCESS || this.coVisitorAccess === CovisitorAccess.FULL_ACCESS) && this.isCovisitor) {
      isDisabled = this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || !this.isSaveNotesEnabled || (_.isEmpty(this.tempNoteText) && !this.isMeetingAttachmentAdded);
    } else {
      isDisabled = isDisabled ? isDisabled : this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID;
    }
    return isDisabled;
   }

  private initMeetingNotesSectionHeader() {
    let isNotesAttachmentDisabled = this.device.isOffline || this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled;
    let isNotesSaveDisabled = this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.backgroundUploadInProgress || this.enableReadonlyViewForMeetings || !this.isSaveNotesEnabled || (_.isEmpty(this.tempNoteText) && !this.isMeetingAttachmentAdded);
    if ((this.coVisitorAccess === CovisitorAccess.MINIMUM_ACCESS || this.coVisitorAccess === CovisitorAccess.FULL_ACCESS) && this.isCovisitor) {
      isNotesAttachmentDisabled = isNotesAttachmentDisabled ? isNotesAttachmentDisabled : false;
      isNotesSaveDisabled = this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || !this.isSaveNotesEnabled || (_.isEmpty(this.tempNoteText) && !this.isMeetingAttachmentAdded);
    } else {
      isNotesAttachmentDisabled = isNotesAttachmentDisabled ? isNotesAttachmentDisabled : this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID;
      isNotesSaveDisabled = isNotesSaveDisabled ? isNotesSaveDisabled : this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID;
    }
    this.meetingNotesSectionHeader = {
      id: 'notes-and-attachments',
      title: this.isMeetingNotesRelabelFAEnabled ? this.translate.instant('MEDICAL_DISCUSSION'):this.translate.instant('ACTIVITY_DETAILS_NOTES'),
      controls: [
        {
          id: 'notes-attachment',
          text: this.translate.instant('ATTACH'),
          isVisible: this.isMeetingAttachmentEnabled,
          isDisabled: isNotesAttachmentDisabled
        },
        {
          id: 'meeting-notes-save',
          text: this.translate.instant('ADD'),
          isDisabled: isNotesSaveDisabled,
          isVisible: true,
        }
      ]
    }
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  public onSectionHeaderControlClick(id: string) {
    // Prevent account visit offline request
    if (this.activityService.accountVisitOfflineCheck(
      {
        isAccountVisitEnabled: this.isAccountVisitEnabled,
        isAccountVisitRecord: this.isAccountVisitRecord,
        isAccountVisitNestedMeeting: this.isAccountVisitNestedMeeting,
      },
      true,
    )) {
      return;
    }

    switch (id) {
      case 'add-objectives':
        this.handleObjectivesAdd();
        break;
      case 'notes-attachment':
        this.handleNoteAttachment();
        break;
      case 'meeting-notes-save':
        // this.saveMeetingNote();
        this.addMeetingNote();
        break;
      case 'add-opportunities':
        this._handleAddOpportunities();
        break;
      case 'add-meetingevents':
        this._handleAddEvents();
        break;
      case 'add-assets':
        this._handleAddAssets();
        break;
      case 'appeal':
        this.sendForAppeal();
        break;
      case 'add-procedures':
        this.handleAddProcedures();
        break;
      case 'add-photos':
        this.openPhotoCaptureViewer();
        break;
      case 'add-supportingmaterials':
        this.handleAddSupportingMaterials();
      default:
        break;
    }
  }

  public async sendForAppeal() {
      if(this.authService.user.buConfigs['indskr_approvalsubmissionperiod'] !== null) {
        await this.alertService.showAlert(
          {
            title: this.translate.instant('SUBMIT_APPEAL_TITLE'),
            message: this.translate.instant('SUBMIT_APPEAL_MESSAGE'),
          }, this.translate.instant('SUBMIT')
        ).then(async res => {
          if (res.role === 'ok') {
            const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;

            let appealStatus = GPSActivityApprovalStatus.PENDING;
            let toastMsg = ``;

            //Calculate Approval submission period
            let approvalSubmissionPeriod  = +this.authService.user.buConfigs['indskr_approvalsubmissionperiod'];
            approvalSubmissionPeriod = approvalSubmissionPeriod - 1;

            if(approvalSubmissionPeriod === -1) {
              toastMsg = this.translate.instant('APPEAL_STATUS_SENT_FOR_REVIEW');
            }
            else {
              const dateCompleted = new Date(+this.activityService.selectedActivity.omnip_datecompleted);
              console.log(`dateCompleted: ${dateCompleted.toString()}`);

              const currentdate = new Date();
              console.log(`currentdate: ${currentdate.toString()}`);

              let calcApprovalSubmissionPeriod = addMonths(dateCompleted, approvalSubmissionPeriod);
              calcApprovalSubmissionPeriod = lastDayOfMonth(calcApprovalSubmissionPeriod);
              calcApprovalSubmissionPeriod.setHours(23, 59, 59, 999);

              console.warn(`calcApprovalSubmissionPeriod: ${calcApprovalSubmissionPeriod.toString()}`);

              if(isBefore(currentdate, calcApprovalSubmissionPeriod)) {
                toastMsg = this.translate.instant('APPEAL_STATUS_SENT_FOR_REVIEW');
              } else {
                appealStatus = GPSActivityApprovalStatus.OUT_OF_SUBMISSION_WINDOW_PERIOD;
                toastMsg = this.translate.instant('APPEAL_STATUS_OUT_OF_SUBMISSION_WINDOW_PERIOD');
              }
            }

            let payload: any = {
              indskr_gpscheckindetailsid: currentMeeting.gpsCheckInDetails['indskr_gpscheckindetailsid'],
              statuscode: appealStatus
            }

            await this.uiService.displayLoader({duration: 30000});
            try {
              const existingStatus = await this.dynamics.retrieveAll('indskr_gpscheckindetailses', ['statuscode'],
                `indskr_gpscheckindetailsid eq ${payload.indskr_gpscheckindetailsid}`);
              if (!existingStatus || _.isEmpty(existingStatus['value']) || existingStatus['value'][0]['statuscode'] == 1) {
                console.log(existingStatus);
                await this.appealService.updateAppealStatus(currentMeeting.ID, payload);
              }
            } catch (error) {
              console.error('updateAppealStatus: ', error);
              await this.uiService.dismissLoader();
              return;
            }

            console.log('updateAppealStatus: update service success: ');
            currentMeeting.gpsCheckInDetails['statuscode'] = appealStatus;
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(currentMeeting, true);
            await this.uiService.dismissLoader();
            this.notificationService.notify(toastMsg, "Meeting Details", "top", ToastStyle.INFO, 3000);
            this.detectChangesOnActivityDetails(true);
          }
        });
      } else {
        this.notificationService.notify(this.translate.instant('ERR_APPEAL_NO_SUBMISSION_WINDOW_PERIOD'), "Meeting Details", "top", ToastStyle.DANGER, 3000);
      }
  }

  private openPhotoCaptureViewer() {
    if (!this.device.isNativeApp) {
      this.notificationService.notify(this.translate.instant('INSTORE_PHOTOS_WEB_WARNING'), 'Meeting Details', 'top', ToastStyle.DANGER);
      return;
    }
    const activity: AppointmentActivity = <AppointmentActivity>this.activityService.selectedActivity;
    let accountName = '';
    let etmsCode = '';
    if (!_.isEmpty(activity.accounts)) {
      accountName = activity.accountString;
      const account = this.accountService.getAccountById(activity.accounts[0].id);
      if (account?.extendedDetails['externalId']) {
        etmsCode = account.extendedDetails['externalId']
      }
    }
    const photoModel: IndPhotoCaptureViewerDataModel = {
      pageTitle: this.translate.instant("ADD") + ' - ' + activity.subject,
      photosLimit: _.isEmpty(activity.inStorePhotos) ? 10 : (10 - (activity.inStorePhotos.length)),
      photoNameFormat: `${this.authService.user.displayName} ${accountName} ${etmsCode} {currentTimestamp} ${this.translate.instant('INSTORE')}.PNG`,
      photoOrigin: PhotoOrigin.INSTORE_MEETING,
      accountId: _.isEmpty(activity.accounts) ? '' : activity.accounts[0].id,
      errorMessage: this.translate.instant('MAX_PHOTO_NOTIFICATION', { limit: 10 }),
      callbackEvent: (data: []) => this._handlePhotoCaptureComponentCallback(data)
    };
    this.navService.pushWithPageTracking(IndPhotoCaptureViewerComponent, PageName.IndPhotoCaptureViewerComponent, { photoModel: photoModel }, PageName.IndPhotoCaptureViewerComponent);
  }

  private async _handlePhotoCaptureComponentCallback(data: Photo[]) {
    if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID) || this.backgroundUploadInProgress || _.isEmpty(data)) return;
    await this.uiService.displayLoader({ duration: 30000 });
    await this.meetingDataService.uploadPhotosToBlobStorgae(data);
    this.initPhotosSectionHeader();
    await this.uiService.dismissLoader();
  }

  async deletePhotoAttachments(event , photo: InStorePhoto) {
    event.stopPropagation();
    if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID) || this.backgroundUploadInProgress) return;
    const res = await this.alertService.showAlert(
      {
        title: this.translate.instant('DELETE_PHOTO_HEADER'),
        message: this.translate.instant('DELETE_PHOTO_NAME').replace('{{name}}', photo.name),
      },
      this.translate.instant('CONFIRM'),
    );
    if (res.role == 'ok') {
      if (this.backgroundUploadInProgress || this.device.isOffline
        || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)
        || this.activityService.selectedActivity.isCompleted
        || !this.activityService.isMeetingOwner) {
        return;
      }
      await this.uiService.displayLoader();
      await this.meetingDataService.updateInStorePhotos([{"photoAttachmentId": photo.indskr_photoattachmentid, deleted: true}]);
      this.initPhotosSectionHeader();
      await this.uiService.dismissLoader();
    }
  }

  private handleAddProcedures() {
    const isReadOnlyMode = this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings ;
    const selectedProcedures = (this.activityService.selectedActivity as AppointmentActivity).activityProcedures;
    if (isReadOnlyMode && _.isEmpty(selectedProcedures)) return;
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PROCEDURES'),
      dividerTitle: this.translate.instant('ALL_PROCEDURES'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('PROCEDURES'),
      hideAllItemsList: isReadOnlyMode,
      isListSelectionEnabled: !isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProcedureComponentEvent(data, eventTarget, refData),
      data: this.getProceduresSelectionData(isReadOnlyMode, selectedProcedures),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getProceduresSelectionData(isReadOnlyMode, selectedProcedures) {
    let data: Array<MainCardViewDataModel> = [];
    let masterList = _.cloneDeep(this.surgeryOrderDataService.procedureTrackerProductHierarchies);
    masterList = masterList.sort((a, b): number => {
      if (a.surgeryName > b.surgeryName) return 1;
      if (a.surgeryName < b.surgeryName) return -1;
      return 0;
    });

    const selectedProcedureIds = selectedProcedures.map(procedure => procedure.ID);

    for (let i = 0; i < masterList.length; i++) {
      const item = masterList[i];
      const isSelected = selectedProcedureIds.includes(item.surgeryId);
      let obj = {
        id: item.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.surgeryName,
        showArrow: false,
        eventOwnerId: this.activityService.selectedActivity.ownerId
      };
      data.push(obj);
    }

    const mappedProcedureIds = this.surgeryOrderDataService.procedureTrackerProductHierarchies.filter(pro => pro.surgeryId);
    selectedProcedures.filter(procedure => mappedProcedureIds.includes(procedure['indskr_productid'])).forEach(procedure => {
      data.push({
        id: procedure.indskr_productid,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: true,
        endIconType: 'indegene-selectors-checkmark-icon',
        endIconCssClass: 'checkmark-icon',
        primaryTextRight: procedure.productName,
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.activityService.selectedActivity.ownerId
      })
    })
    return data;
  }

  private async _handleProcedureComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      const appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
      if (_.isEmpty(data.selectedItems)) {
        appointmentActivity.activityProcedures = [];
      } else {
        const selectedIds = appointmentActivity.activityProcedures ? appointmentActivity.activityProcedures.map(procedure => procedure.ID) : [];
        data.selectedItems.forEach(item => {
          if (!selectedIds.includes(item.id)) {
            const activityProcedure = new Brand({
              productid: item.id,
              name: item.primaryTextRight,
              productStructure: 3
            });
            appointmentActivity.activityProcedures.push(activityProcedure);
          }
        });
        if (!_.isEmpty(data.removedItems)) {
          data.removedItems.forEach(item => {
            const index = appointmentActivity.activityProcedures.findIndex(procedure => procedure.ID == item.id);
            if (index > -1) appointmentActivity.activityProcedures.splice(index, 1);
          });
        }
      }
      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        try { // I/O operations needs to be wrap in a try and catch block
          this.activityService.upsertMeetingsOfflineData(appointmentActivity); // offline saving
        } catch (e) {
          console.error("Failed to save procedures for apppointment in offlineDB ", e);
        }
      } else {
        const isSuccess = await this.meetingDataService.updateMeetingProcedures(appointmentActivity);
        if (!isSuccess) {
          this.activityService.upsertMeetingsOfflineData(appointmentActivity, false, true);
        }
      }
    }
  }

  private handleAddSupportingMaterials(){
    const isReadOnlyMode = this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings ;
    const selectedSupportedMaterials = (this.activityService.selectedActivity as AppointmentActivity).activitySupportingMaterials.map(a=> a.id);
    let supportingMaterialsList = this.brandService.supportingMaterials.length > 0 ? this.brandService.supportingMaterials.map(sm => {
      const isSelected = selectedSupportedMaterials.includes(sm.id);
      return {
        id: sm.id,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: sm.name,
        showArrow: false,
        eventOwnerId: this.activityService.selectedActivity.ownerId
      };
    }) : [];
    //
    if (isReadOnlyMode && _.isEmpty(selectedSupportedMaterials)) return;
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('SUPPORTING_MATERIALS'),
      dividerTitle: this.translate.instant('ALL_SUPPORTING_MATERIALS'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('SUPPORTING_MATERIALS'),
      hideAllItemsList: isReadOnlyMode,
      isListSelectionEnabled: !isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSupportingMaterialsComponentEvent(data, eventTarget, refData),
      data: supportingMaterialsList,
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private async _handleSupportingMaterialsComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      const appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
      if (_.isEmpty(data.selectedItems)) {
        appointmentActivity.activitySupportingMaterials = [];
      } else {
        const selectedIds = appointmentActivity.activitySupportingMaterials ? appointmentActivity.activitySupportingMaterials.map(material => material.id) : [];
        data.selectedItems.forEach(item => {
          if (!selectedIds.includes(item.id)) {
            const activitySupportingMaterial = {
              id: item.id,
              name: item.primaryTextRight,
            };
            appointmentActivity.activitySupportingMaterials.push(activitySupportingMaterial);
          }
        });
        if (!_.isEmpty(data.removedItems)) {
          data.removedItems.forEach(item => {
            const index = appointmentActivity.activitySupportingMaterials.findIndex(material => material.id == item.id);
            if (index > -1) appointmentActivity.activityProcedures.splice(index, 1);
          });
        }
      }
      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        try { // I/O operations needs to be wrap in a try and catch block
          this.activityService.upsertMeetingsOfflineData(appointmentActivity); // offline saving
        } catch (e) {
          console.error("Failed to save supporting materials for apppointment in offlineDB ", e);
        }
      } else {
        const isSuccess = await this.meetingDataService.updateMeetingSupportingMaterials(appointmentActivity);
        if (!isSuccess) {
          this.activityService.upsertMeetingsOfflineData(appointmentActivity, false, true);
        }
      }
    }
  }

  private async handleObjectivesAdd() {
    if(this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) return;
    const selectedObjIds = (this.activityService.selectedActivity as AppointmentActivity).activityObjectives?.map(obj => obj.indskr_meetingobjectivesid);
    const options = this.meetingDataService.meetingObjectives.map(obj => {
      return {
        id: obj.indskr_meetingobjectivesid,
        title: obj.indskr_name,
        isSelected: selectedObjIds.includes(obj.indskr_meetingobjectivesid)
      }
    });
    const objectiveModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('OBJECTIVES'),
          dividerTitle: this.translate.instant('ALL_OBJECTIVES'),
          isSearchEnabled: true,
        }
      },
      backdropDismiss: false
    });
    objectiveModal.present();
    objectiveModal.onDidDismiss().then(async (obj: any) => {
      const data = obj.data;
      if (data && data.isDone) {
        let selectedObjectives = [];
        const selectedIds = []
        data.selectedItems.forEach(selectedItem => {
          selectedIds.push(selectedItem.id);
          selectedObjectives.push({indskr_meetingobjectivesid: selectedItem.id, indskr_name: selectedItem.title});
        });
        (this.activityService.selectedActivity as AppointmentActivity).activityObjectives = _.orderBy(selectedObjectives, 'indskr_name');
        await this.uiService.displayLoader();
        await this.meetingDataService.saveMeetingObjectives(selectedIds);
        await this.uiService.dismissLoader();
      }
    });
  }

  private handleNoteAttachment(){
    if(this.hasMeetingAttachmentFA || this.isMeetingAttachmentEnabled){
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async loadImageFromDevice(event) {
    if (this.device.isOffline) return;
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if ((file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.attachmentFile = file;
            if(this.hasMeetingAttachmentFA) {
              this.attachmentTitle = file.name;
              this.isAttachmentAdded = true;
              await this.saveAttachmentInDynamics();
              this.initNotesSectionHeader();
            } else if(this.isMeetingAttachmentEnabled) {
              this.meetingNoteAttachmentTitle = file.name;
              this.isMeetingAttachmentAdded = true;
              this.isSaveNotesEnabled = true;
              this.onMeetingNotesFocusOut();
              this.initMeetingNotesSectionHeader();
            }
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Account Info', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Account Info', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async saveAttachmentInDynamics() {
    let payload = {
      "documentbody": this.base64str,
      "filename": this.attachmentFile.name,
      "mimetype": this.attachmentFile.type,
      "objectid_appointment@odata.bind": "/appointments(" +this.activityService.selectedActivity.ID+ ")",
      "notetext": this.activityService.selectedActivity.notes
    };
    await this.meetingDataService.updateMeetingAttachments(payload);
  }

  public removeAttachment(event){
    if (this.device.isOffline || this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) return;
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    if(this.hasMeetingAttachmentFA) {
      this.isAttachmentAdded = false;
      this.removeAttachmentFromDynamics();
      this.initNotesSectionHeader();
    }
    this.attachmentFile = null;
    this.base64str = null;
  }

  public removeMeetingNoteAttachment(event){
    if (this.device.isOffline || !this.activityService.isMeetingOwner || this.activityService.selectedActivity.isCompleted
      || this.backgroundUploadInProgress || !this.isMeetingAttachmentEnabled || this.enableReadonlyViewForMeetings) return;
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.meetingNoteAttachmentTitle = '';
    this.isMeetingAttachmentAdded = false;
    this.initMeetingNotesSectionHeader();
    this.attachmentFile = null;
    this.base64str = null;
    if(this.autoSavedNote && this.autoSavedNote.hasDocument){
      let ev = {
        action: 'SAVE',
        noteId: this.autoSavedNote.noteId,
        updatedText: this.tempNoteText,
        isAttachmentRemoved: true,
      };
      this.updateNote(ev);
    }
  }

  public async downloadAttachment(event) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('ATTACHMENT_CAN_BE_DOWNLOADED_ONLY_WHEN_ONLINE_MSG'), 'Notes Attachment Down', 'top', 'info');
      return;
    }
    let response = await this.dynamics.retrieveAll('annotations', [],
      `annotationid eq  ${(this.activityService.selectedActivity as AppointmentActivity).associatedAttachments[0].annotationId}`)
      .then(res => res.value).catch(() => []);
    if (response) {
      if (response[0].filename) {
        if (response[0].documentbody) {
          try {
            if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
              this.fileService.downloadeBase64DataFileAndOpenInNativeApp(response[0].filename, response[0].documentbody, response[0].mimetype);
            } else {
              const linkSource = response[0].documentbody;
              const downloadLink = document.createElement("a");
              const fileName = response[0].filename;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
              downloadLink.remove();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          await this.uiService.displayLoader();
          try {
            let data = await this.fileService.getDocumentBody(response[0].annotationid);
            if (data['documentbody']) {
              if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
                this.fileService.downloadeBase64DataFileAndOpenInNativeApp(response[0].filename, response[0].documentbody, response[0].mimetype);
              } else {
                const linkSource = data['documentbody'];
                const downloadLink = document.createElement("a");
                const fileName = response[0].filename;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                downloadLink.remove();
              }
            }
            await this.uiService.dismissLoader();
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }


  private async removeAttachmentFromDynamics() {
    await this.meetingDataService.removeMeetingAttachment();
  }

  private initConfigFieldsSetup(fromOnActivityChanges: boolean = false) {
    this.appointmentConfigFields = [];
    this.appointmentConfigFields = this.authService.user.getSupportedAppointmentConfiguredFields();
    // to prevent infinite call to the same function.
    // causing when creating a new phone call activity.
    this.appointmentConfigFields = this.appointmentConfigFields.filter(field => this.activityService.selectedActivity.location !== "LiveMeet");
    this.updateAppConfigFieldsOnActivityChanges(fromOnActivityChanges);
    this.setOrderToConfigFields();
  }

  private updateAppConfigFieldsOnActivityChanges(fromInitConfigFieldsSetup: boolean = false) {

    if (fromInitConfigFieldsSetup) {

      // Reset all the config fields value everything since this is a fresh activity.
      this.appointmentConfigFields.forEach(field => {
          field.value = '';
          field.stringValue = '';
          field.selectedValues = '';
          field.hasMultipleValues = false;
          field.multipleLabels = [];
          field.multipleValues = [];
          field.isEmptyRequiredField = false;
      });

      return;
    }

    let serviceAppConfigFields = this.appointmentConfigFields;
    let currentActivity = this.activityService.selectedActivity;
    let appConfigFieldsFromCurrentActivity = currentActivity.appConfigFields;
    let notReplacedFields: string[] = [];

    if (serviceAppConfigFields.length) {
      if (appConfigFieldsFromCurrentActivity.length) {

        let indexToFind = 0;
        serviceAppConfigFields.forEach(sacf => {
          let fieldName = sacf.fieldName;
          if (fieldName) {
            let findExistingConfigFieldFromCurrentActivity = appConfigFieldsFromCurrentActivity.filter( field => { return field.fieldName === fieldName });
            if (findExistingConfigFieldFromCurrentActivity.length) {
              let firstObject = findExistingConfigFieldFromCurrentActivity[0];
              this.appointmentConfigFields[indexToFind] = firstObject;
            } else {
              notReplacedFields.push(fieldName);
            }
          }
          indexToFind++;
        });

        if (notReplacedFields.length) {
          // Reset values for all the other fields.
          // So we will not have the duplicate values.
          this.appointmentConfigFields.forEach(field => {
            let fieldName = field.fieldName;
            if (notReplacedFields.includes(fieldName)) {
              field.value = '';
              field.selectedValues = '';
              field.hasMultipleValues = false;
              field.multipleLabels = [];
              field.multipleValues = [];
              field.isEmptyRequiredField = false;
            }
          });
        }

      } else {
        // Get the latest config fields from the service.
        this.initConfigFieldsSetup(true);
      }
    }
  }

  private setOrderToConfigFields() {
    // sort the appConfigFields according to the order
    this.appointmentConfigFields.sort(configFieldSortFn)
  }

  initTherapeuticAreas() {
    this.therapeuticAreas = [];
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      if (!this.activityService.selectedActivity.isCompleted) {
        this.theraputicService.therapeuticAreas.forEach(ta => {
          let selectedTA = this.activityService.selectedActivity['activityTherapeuticAreas'].find(item => item.therapeuticareaId === ta.therapeuticareaId);
          if (selectedTA) {
            this.therapeuticAreas.push(selectedTA);
          } else {
            this.therapeuticAreas.push(new TherapeuticArea(ta.TherapeuticAreaDTO));
          }
        });
      } else {
        // this.therapeuticAreas = _.unionBy(this.activityService.selectedActivity.activityTherapeuticAreas, this.theraputicService.therapeuticAreas, 'therapeuticareaId');
        this.therapeuticAreas = _.cloneDeep(this.activityService.selectedActivity.activityTherapeuticAreas);
      }
    } else {
      this.therapeuticAreas = JSON.parse(JSON.stringify(this.theraputicService.therapeuticAreas));
    }
  }
  ngAfterViewChecked() {
    if (this.activityService.inboundMeetingFlagForActivitiesDetailsPane) {
      this.activityService.inboundMeetingFlagForActivitiesDetailsPane = false;
      if (this.footerCmp) {
        this.footerCmp.triggerPreviewButton();
      }
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('remote_meeting_participant_Joined_left');
    // this.events.unsubscribe('detectChangesOnActivityDetails');
    this.events.unsubscribe('completeMeeting');
    this.events.unsubscribe('scrollToTop');
    // this.events.unsubscribe('updateSelectedActivityDetails');
    this.events.unsubscribe('updateMandatoryField');
    // this.translate.onLangChange.unsubscribe();
    this.ngdestroy$$.next(true);
    this.ngdestroy$$.complete();
    // this.isTypeSubTypeAPIFailed = false;
    if (this.newActivitySubscription) {
      this.newActivitySubscription.unsubscribe();
    }
    this.eventSubscriptions.forEach(sub => sub.unsubscribe());
    if (this.configFieldsSubscription) this.configFieldsSubscription.unsubscribe();
    if (this.coachingActivitySubscription) this.coachingActivitySubscription.unsubscribe();
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
    this.dateFormatSubscription.unsubscribe();
    this.presentationService.clearReferencesCache();
    this.activityService.isLayOverPushedMeetingFromOtherActivities = false;
  }

  ionViewDidEnter() {
    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool' ||
        this.layoverViewPushedFrom == 'AccountTool' ||
        this.layoverViewPushedFrom == 'ScientificActivityPage' ||
        this.layoverViewPushedFrom == 'PresentationToolsPage' ||
        this.layoverViewPushedFrom == 'OpportunityManagementTool'
        ||this.layoverViewPushedFrom == 'ProcedureLogFrom') {
        this.uiService.isFlipButtonClicked = false;
      }
    }
  }

  // Refresh form fields when required
  private refreshFormFields() {
    this.initAllPresentationPlayDurationField();
  }
  private async initFooterAndHeaderForCompletedMeeting() {
    if (!this.activityService.inboundMeetingFlagForActivitiesDetailsPane && _.isEmpty(document.getElementsByTagName('ion-modal'))
      && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_REOPEN) && this.activityService.selectedActivity.isCompleted) {
      this.footerService.initButtons(FooterViews.Activities);
      this.initActivitiesDetailsPageTitle();
    }
  }
  private updateCovisitorInputEnableDisable() {
    const formField = this.secondLevelFormFields.find(field => field.id === 'covisitor-compliance');
    if (formField) {
      formField.isDisabled = this.shouldDisableCovisitorComplianceInput();
    }
    this.cdRef.detectChanges();
  }

  ionViewWillEnter() {
    if (!this.activityService.inboundMeetingFlagForActivitiesDetailsPane) {
      if (this.sampleService.samplingDetailsViewMode != SamplingDetailsViewMode.CREATE_FROM_MEETING
        && this.caseManagementService.accessedFrom != AccesingMode.INMEETING
        && this.caseManagementService.accessedFrom != AccesingMode.IN_PHONE_CALL
        && this.emailService.viewType != EmailViewType.CREATE_FROM_MEETING
        && !this.surgeryOrderDataService.isInMeetingProcedureLogFlow) {
        if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent){
          this.footerService.initButtons(FooterViews.Activities);
        }
      }
      this.uiService.isFlipButtonClicked = false;
      if (this.isLayoverPushView) {
        // Do Nothing
      } else if (!this.device.shouldBeMobileView) {
        // Pop out since it's split view now
        // this.navService.popWithPageTracking();
      } else {
        if (!this._screenOrientation$) {
          this._screenOrientation$ = this.device.screenOrientation.subscribe((deviceOrientation: number) => {
            if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent
              && this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent) {
              if (deviceOrientation === DEVICE_ORIENTATION["landscape-primary"]
                || deviceOrientation === DEVICE_ORIENTATION["landscape-secondary"]) {
                this.navService.popWithPageTracking();
              }
            }
          });
        }
      }
    }
  }

  handleMeetingScrap() {
    if (this.isScrapButtonEnabled) {
      this.events.publish('deleteMeeting');
    }
  }

  ionViewWillLeave() {
    if (this._screenOrientation$) {
      this._screenOrientation$.unsubscribe();
      this._screenOrientation$ = null;
    }
  }

  /**
   *  Called when a new activity is created from call plan details page
   * This should scrap the meeting.
   *
   * @private
   * @memberof ActivitiesDetailsPaneComponent
   */
  private scrapActivity() {
    this.events.publish('deleteMeetingNoConfirm');
    if (this.uiService.prevView == 'callPlanDetails') {
      //this.events.publish('call-plan-details:confirmedNewActivity');
    }
    if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton) {
      this.events.publish("go-back-to-xperiences", PageName.ActivitiesDetailsPaneComponent);
    }
    if (this.uiService.activeView == "activityTimelineDetails" && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      //this.events.publish('contact-details:confirmedNewActivity');
      this.activityService.selectedActivity = undefined;
      this.uiService.showCancelDoneOnActivityDetails = false;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = "contactDetails";
      this.footerService.initButtons(FooterViews.Contacts);
    }
  }

  public get isScrapButtonEnabled(): boolean {
    let flag: boolean = false;
    if (this.activityService.selectedActivity.state == this.meetingState.Open && !this.enableReadonlyViewForMeetings && (this.activityService.selectedActivity && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || (this.isCovisitor && this.coVisitorAccess === CovisitorAccess.FULL_ACCESS))) && !this.backgroundUploadInProgress) {
      flag = true;
    }
    return flag;
  }

  /**
   *  Called when a new activity is created from the call plan details page and the user hits done
   *
   * @private
   * @memberof ActivitiesDetailsPaneComponent
   */
  private markNewActivityOkay() {
    //hide buttons? do nothing?
    this.uiService.showCancelDoneOnActivityDetails = false;
    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool' || this.layoverViewPushedFrom == 'PresentationToolsPage') {
        if(this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab'){
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        } else {
          this.uiService.activeView = "AccountManagement";
        }
        this.footerService.initButtons('');
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom == 'AccountTool') {
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
        this.footerService.initButtons('');
        this.navService.popToRootChildNavPageWithPageTracking();
      }else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'){
        this.navService.popChildNavPageWithPageTracking();
        //this.footerService.initButtons(FooterViews.OPPORTUNITY_INFO);
      }
      else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
        this.navService.popChildNavPageWithPageTracking();
      }
    }
    else if ((this.uiService.prevView == 'callPlanDetails' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) && this.uiService.activitiesPagePlanTabSelectedPlan !== 'goalsPlansTab') {
      this.uiService.activeView = 'callPlanDetails';
      this.uiService.showNewActivity = false;
      // this.callplanService.callPlanPageView === 'CallPlanDetail';
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
      this.events.publish('call-plan-details:confirmedNewActivity');
    } else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showRightPane = true;
      this.navService.popChildNavPageWithPageTracking();
    }
    else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent &&
      !this.footerService.isActivitiesButton) {
      this.events.publish("go-back-to-xperiences", PageName.ActivitiesDetailsPaneComponent, this.activityService.selectedActivity);
    }
    if (this.uiService.activeView == "activityTimelineDetails" && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      //this.events.publish('contact-details:confirmedNewActivity');
      this.activityService.selectedActivity = undefined;
      this.uiService.showCancelDoneOnActivityDetails = false;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = "contactDetails";
      this.uiService.showRightPane = false;
      this.footerService.initButtons(FooterViews.Contacts);
    }
  }

  async openStartDatePicker(myEvent) {
    if (this.activityService.selectedActivity.isFromXperiences) return;
    if (!this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings) {
      if (this.activityService.selectedActivity.state != MeetingActivityState.Completed) {
        this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.Appointment,
              startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
              endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          this.logService.logDebug(data);
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            if(this.backgroundUploadInProgress) return;
            this.updateMeetingDate(data.data, true);
            this.activityService.selectedActivity.resetScheduledStartLocale();
            let dateTimeValue = this.activityService.getFormattedDateTimeText();
            if (dateTimeValue) {
              this.meetingStartDate = dateTimeValue.startDate;
              this.meetingEndDate = dateTimeValue.endDate;
            }
            // this.refreshPlanToDO();
          }
        });
        popover.present();
      }
    }
  }
  async openEndDatePicker(myEvent) {
    if (this.activityService.selectedActivity.isFromXperiences) return;
    if (!this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings) {
      if (this.activityService.selectedActivity.state != MeetingActivityState.Completed) {
        this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.Appointment,
              startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
              endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          this.logService.logDebug(data);
          if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
            if (this.backgroundUploadInProgress) return;
            this.updateMeetingDate(data.data, false);
            this.activityService.selectedActivity.resetScheduledStartLocale();
            let dateTimeValue = this.activityService.getFormattedDateTimeText();
            if (dateTimeValue) {
              this.meetingEndTime = dateTimeValue.endTime;
            }
            // this.refreshPlanToDO();
          }
        });
        popover.present();
      }
    }
  }

  async openSubjectPopover(myEvent) {
    if (!this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && !this.backgroundUploadInProgress && !this.isReadOnlyRecommendedMeeting) {
      const isSubjectNotEditable = this.secondaryInfoService.isAppointmentSecInfoConfigured ? false : this.isAutoSubjectEnabled;
      if (isSubjectNotEditable) return;
      if (this.activityService.selectedActivity.state != MeetingActivityState.Completed) {
        let subject = (myEvent && myEvent.target && myEvent.target.value && myEvent.target.value.length != 0) ? myEvent.target.value : this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) ? 'Visit' : 'Meeting';
        if (subject != this.activityService.selectedActivity.subject) {
          await this.meetingSubjectUpdate(subject);
        }
      }
    }
  }

  async meetingSubjectUpdate(subject) {
    let payload = new UpdateMeetingPayload(
      this.activityService.selectedActivity.subject,
      this.activityService.selectedActivity.location,
      this.activityService.selectedActivity.scheduledStart,
      this.activityService.selectedActivity.scheduledEnd
    );
    payload.subject = subject;
    this.activityService.selectedActivity.subject = subject;
    this.initActivitiesDetailsPageTitle();
    this.activityService.getActivityByID(this.activityService.selectedActivity.ID).subject = subject;
    // this.activityService.isActivityUpdate = true;
    this.refreshPlanToDO();

    if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
      await this.uiService.displayLoader();
      try {
        this.activityService.selectedActivity.modified = new Date();
        const success = await this.meetingDataService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), payload);
        if (success) {
          this.activityService.notifyCalendarInviteUpdate(this.activityService.selectedActivity as AppointmentActivity);
        }
      } catch (error) {
        console.error('openSubjectPopover: ', error);
        // Create an offline record
        await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity), false, true);
      } finally {
        await this.uiService.dismissLoader();
      }
    } else {
      await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity));
    }
  }
  /**
   * Opens contacts component screen
   *
   * @returns
   * @memberof ActivitiesDetailsPaneComponent
   */
  openContacts(ev?:any) {
    this.trackingService.tracking('MeetingAddContact', TrackingEventNames.ACTIVITY);
    if (!this.contactService.loadedContacts) return;
    if((this.isReadOnlyRecommendedMeeting || this.backgroundUploadInProgress || this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings) && _.isEmpty(this.activityService.selectedActivity['contacts'])) return;
    if (
      this.enableReadonlyViewForMeetings
      || this.activityService.selectedActivity.isFromXperiences
      || (this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointMeeting || this.isReadOnlyRecommendedMeeting)
      || this.backgroundUploadInProgress
    ) {
      
      if (this.navService.getActiveChildNavViewPageName() ===  PageName.ActivitiesDetailsPaneComponent) {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
      }
      this.uiService.showNewActivity = false;
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
      this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
    } else {
      if (this.isLayoverPushView && (this.layoverViewPushedFrom == "AccountTool" || this.layoverViewPushedFrom == "AccountManagementTool")) {
        this.uiService.prevView = '';
        this.uiService.activeView = '';
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
      }
      this.uiService.showNewActivity = false;
      if (this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
        this.contactService.contactInformation = undefined;
        this.uiService.contactDetailsSegment = undefined;
      }
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      // if((this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && (this.activityService.selectedActivity as AppointmentActivity).gpsCheckInDetails.indskr_checkinstatus != null)){
      //   this.contactService.contactPageMode = ComponentViewMode.READONLY
      // }

      let searchText = '';
      let activateSearchOtherContacts = false;
      let overrideAutoFilteres = false;
      if (ev) {
        searchText = ev.text || '';
        if (ev.id === 'view-all' || ev.id === 'show-other') {
          activateSearchOtherContacts = ev.id === 'show-other'
          overrideAutoFilteres = true;
        } 
      }

      this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { 
        'contactSearchText': searchText,
        activateSearchOtherContacts,
        overrideAutoFilteres,
        hidesearchSuggestionsDropDown : true,
        callbackEvent: (data: any) => this.onContactsUpdated(data) 
      }, PageName.ContactPageComponent);
    }
    this.isClickedContacts = true;
  }

  private async markCompleteAccountVisit(selfClaim: boolean = false) {
    let shouldThrowError = false;
    let errorToThrow;

    const payload: AppointmentActivity[] = [];
    if (!this.activityService.selectedActivity) {
      throw(new Error('markCompleteAccountVisit has no selected activity'));
    }
    const activity = this.activityService.selectedActivity as AppointmentActivity;
    const origState = this.activityService.selectedActivity.state;
    const dateCompleted = new Date().getTime().toString();
    const nestedMeetings = this.isAccountVisitRecord ? this.activityService.getAccountVisitNestedMeetings(activity.ID) : [];

    activity.state = 1;
    activity.omnip_datecompleted = dateCompleted;
    if (this.isAccountVisitNestedMeeting) {
      // This activity is a nested meeting
      if (selfClaim) {
        activity.indskr_selfclaimbyuseronappttimemodification = selfClaim;
      }

      const activityPresentationGpsData = await this.presentationService.getActivityPresentationGpsData(activity.offlineMeetingId);
      // Update GPS data for each nested meetings
      if (
        this.authService.user.buSettings
        && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
        && activityPresentationGpsData
      ) {
        const meetingDistancePayload = this.presentationService.getActivityPresentationGpsDataPayload(activity, activityPresentationGpsData);
        if (meetingDistancePayload) {
          if (meetingDistancePayload.indskr_meetingdistance) {
            activity.indskr_meetingdistance = meetingDistancePayload.indskr_meetingdistance;
          } else if (meetingDistancePayload.indskr_reasonforlocationcapturefailure) {
            activity.indskr_reasonforlocationcapturefailure = meetingDistancePayload.indskr_reasonforlocationcapturefailure;
          }
        }
      }
    }
    payload.push(activity);

    if (nestedMeetings.length > 0) {
      // Need to check if nested meetings are all complete-able
      const notCompletedNestedMeetings: AppointmentActivity[] = nestedMeetings.filter(m => m.state !== 1);
      if (!this.activityService.appointmentsCanComplete(notCompletedNestedMeetings)) {
        shouldThrowError = true;
        errorToThrow = new Error('markCompleteAccountVisit failed nested meeting complete check');
      } else {
        const activityPresentationGpsDataMap = await this.presentationService.getMultiActivityPresentationGpsData(notCompletedNestedMeetings.map(m => m.offlineMeetingId));
        for (let i = 0; i < notCompletedNestedMeetings.length; i++) {
          const nActivity = notCompletedNestedMeetings[i];
          nActivity.state = 1;
          nActivity.omnip_datecompleted = dateCompleted;

          // Update GPS data for each nested meetings
          if (
            this.authService.user.buSettings
            && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
            && activityPresentationGpsDataMap
          ) {
            const activityPresentationGpsData = activityPresentationGpsDataMap[nActivity.offlineMeetingId] ?? null;
            const meetingDistancePayload = this.presentationService.getActivityPresentationGpsDataPayload(nActivity, activityPresentationGpsData);
            if (meetingDistancePayload) {
              if (meetingDistancePayload.indskr_meetingdistance) {
                nActivity.indskr_meetingdistance = meetingDistancePayload.indskr_meetingdistance;
              } else if (meetingDistancePayload.indskr_reasonforlocationcapturefailure) {
                nActivity.indskr_reasonforlocationcapturefailure = meetingDistancePayload.indskr_reasonforlocationcapturefailure;
              }
            }
          }

          payload.push(nActivity);
        }
      }
    }


    try {
      if (!shouldThrowError) {
        const success = await this.meetingDataService.crudMeetingsOnlineWithOfflineUploadEndpoint(payload);
        shouldThrowError = !success;
      }
    } catch (error) {
      console.error('markCompleteAccountVisit: ', error);
      this.notificationService.notify(
        this.translate.instant('XPERIENCES_ERROR'),
        'Activity Detail',
        'top',
        ToastStyle.DANGER,
      );
      shouldThrowError = true;
      errorToThrow = error;
    }

    if (shouldThrowError) {
      // Revert
      activity.state = origState;
      activity.omnip_datecompleted = undefined;
      throw(errorToThrow);
    }
  }

  private async processMarkComplete(isFromActivityDetails: boolean, selfClaim: boolean = false) {
    if (isFromActivityDetails) this.trackingService.tracking('MeetingMarkComplete', TrackingEventNames.ACTIVITY, null, true)
    this.blockUI = true;
    const loader = await this.loadingController.create({});
    loader.present();
    this.activityService.selectedActivity.completed = new Date();
    if (this.nextCallObj && this.nextCallObj.newObjectiveInput != '') await this.nextCallObj.saveObjective();
    const currentActivity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    if (currentActivity.isRemoteDetailing ||
      !_.isEmpty(currentActivity.activityattendeejoiningdetails)
      || (!_.isEmpty(currentActivity.contacts) && currentActivity.contacts.some(contact => contact.indskr_joineddate != null))) {
      await this.meetingDataService.convertToRemoteMeeting();
    }
    let meetingDistancePayload;
    try {
      await this.meetingDataService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity, true);
      if (this.isAccountVisitRecord || this.isAccountVisitNestedMeeting) {
        // Account visit & nested meetings will have a separate flow
        await this.markCompleteAccountVisit(selfClaim)
      } else {
        // Regular meetings
          if (
          this.authService.user.buSettings
          && this.authService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
        ) {
          const activityPresentationGpsData = await this.presentationService.getActivityPresentationGpsData(currentActivity.offlineMeetingId);
          meetingDistancePayload = this.presentationService.getActivityPresentationGpsDataPayload(currentActivity, activityPresentationGpsData);
          if (meetingDistancePayload) {
            if (meetingDistancePayload.indskr_meetingdistance) {
              currentActivity.indskr_meetingdistance = meetingDistancePayload.indskr_meetingdistance;
            } else if (meetingDistancePayload.indskr_reasonforlocationcapturefailure) {
              currentActivity.indskr_reasonforlocationcapturefailure = meetingDistancePayload.indskr_reasonforlocationcapturefailure;
            }
          }
        }

        await this.meetingDataService.updateMeetingStatus(<AppointmentActivity>this.activityService.selectedActivity, MeetingStatus.COMPLETED, new Date().getTime(), null, meetingDistancePayload ? meetingDistancePayload : null, selfClaim);
      }
    } catch (error) {
      console.error('markComplete: ', error);
        // this.notificationService.notify("Meeting complete action failed", undefined, 'red-toast-alert', 3000);

        const errorInfo = (error?.hasOwnProperty('error')) ? error.error : error;
        // Revert completed date if completion limit has been passed
        if (errorInfo?.errorCode === "ERR_IO_ME13") {
          this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT_DAYS',{N:this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']}), 'Meeting Details', 'top', ToastStyle.DANGER);
          this.activityService.selectedActivity.completed = undefined;
        } else if (errorInfo?.errorCode === "ERR_IO_ME14") {
          this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT_SAME_DATE'), 'Meeting Details', 'top', ToastStyle.DANGER);
          this.activityService.selectedActivity.completed = undefined;
        } else if (errorInfo?.errorCode === "ERR_IO_ME15") {
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Meeting Details', 'top', ToastStyle.DANGER);
          this.activityService.selectedActivity.completed = undefined;
        } else if (errorInfo?.errorCode === "ERR_IO_ME16") {
          this.notificationService.notify(this.translate.instant('ACTIVITY_MEETING_SCHEDULED_IN_FUTURE'), 'Meeting Details', 'top', ToastStyle.DANGER);
          this.activityService.selectedActivity.completed = undefined;
        } else if (errorInfo?.errorCode === "ERR_IO_ME18") {
          this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT',{N:this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimit']}), 'Meeting Details', 'top', ToastStyle.DANGER);
          this.activityService.selectedActivity.completed = undefined;
        } else if (errorInfo?.errorCode === "ERR_IO_ME19") {
          loader.dismiss();
          selfClaim = await this.selfClaimAlert();
          if (selfClaim) {
            loader.present();
            await this.meetingDataService.updateMeetingStatus(<AppointmentActivity>this.activityService.selectedActivity, MeetingStatus.COMPLETED, null, null, null, false);
          } else
            this.activityService.selectedActivity.completed = undefined;
        }

        this.blockUI = false;
        removeConflictIdFromOtherActivities(
          this.activityService.selectedActivity,
          this.activityService.activities,
          this.eventsToolService.eventsToolData,
        );
        this.displayConflictMsg = this.checkConflictMsgDisplay(true);
        loader.dismiss();
        this.detectChangesOnActivityDetails(true);
        return false;
    }
    // Remove conflict ID from other activities
    removeConflictIdFromOtherActivities(
      this.activityService.selectedActivity,
      this.activityService.activities,
      this.eventsToolService.eventsToolData,
    );
    if(!this.device.isOffline && this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) this.callplanDataService.syncCallPlanActivities();
    this.displayConflictMsg = this.checkConflictMsgDisplay(true);
    this.activityService.publishActivityEvent({ action: 'Update', activity: this.activityService.selectedActivity });
    this.meetingStructureService.setCurrentMeetingStructure(this.meetingStructureService.embeddedIntreactions);
    this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
    this.websocket.removeSubscription(this.activityService.selectedActivity);
    this.notificationService.notify(this.translate.instant("MEETNG_COMPLETED"), 'Meeting Details');
    //(this.activityService.selectedActivity.isCompleted) ? this.footerService.disableButton(['markcomplete','cancelmeeting','preview','presentations','createOrEditSampleOrder','createOrEditSampleOrderPresentationPreview']) : this.footerService.disableButton();
    this.footerService.initButtons(FooterViews.Activities);
    this.blockUI = false;
    loader.dismiss();
    // this.events.publish('detectChangesOnActivityDetails');
    this.detectChangesOnActivityDetails(true);
    this.events.publish("updateTherapeuticarea");
    this.events.publish("initTodo");
    this.events.publish('meetingCompleted',this.activityService.selectedActivity);
    this.updateUnsolicitedMeetingData();
    this.initDieseaseAreaSectionData();
    this.initProductIndicationsSectionData();
    return true;
  }



  /**
   * Set activity status to complete
   *
   * @memberof ActivitiesDetailsPaneComponent
   */
  async markComplete(isFromActivityDetails?: boolean) {
    //TODO: Add code to updtae activity status.
    if (this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.state == this.meetingState.Completed) return;
    const shouldReturn: boolean = this.handleMandatoryFieldNotification();
    if (shouldReturn) {
      return;
    }
    if (!this.device.isOffline && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONSENT_CHECK) && this.activityService.selectedActivity instanceof AppointmentActivity
      && !_.isEmpty(this.activityService.selectedActivity.contacts)) {
      await this.uiService.displayLoader();
      const hasActiveConsent = await this.meetingDataService.getActiveGeneralConsent(this.activityService.selectedActivity.contacts[0].ID);
      if (!hasActiveConsent) {
        await this.uiService.dismissLoader();
        this.notificationService.notify(this.translate.instant('ACTIVE_GENERAL_CONSENT_NOT_FOUND'), 'Meeting Details', 'top', ToastStyle.DANGER);
        return;
      }
      await this.uiService.dismissLoader();
    }
    if ((this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimit']) > 0) {
      let minutesDifference = differenceInMinutes(new Date(), new Date(this.activityService.selectedActivity.scheduledStart))
      let hourDifference = minutesDifference / 60;
      if (hourDifference > (this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimit'])) {
        this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT', {N: this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimit']}), 'Meeting Details', 'top', ToastStyle.DANGER);
        return;
      }
    }
    let currentActivity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity
    // Retroactive Meeting completion limit setting - By End of Meeting Day
    if (this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays'] != null) {
      let limitedDate = new Date(this.activityService.selectedActivity.scheduledStart);
      limitedDate.setHours(23);
      limitedDate.setMinutes(59);
      limitedDate.setSeconds(59);
      if ((this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']) > 0) {
        limitedDate.setDate(limitedDate.getDate() + this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']);
        if (new Date() > limitedDate) {
          this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT_DAYS', {N: this.authService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']}), 'Meeting Details', 'top', ToastStyle.DANGER);
          return;
        }
      } else {
        if (new Date() > limitedDate) {
          this.notificationService.notify(this.translate.instant('RETROACTIVE_MEETING_COMPLETION_LIMIT_SAME_DATE'), 'Meeting Details', 'top', ToastStyle.DANGER);
          return;
        }
      }
    }

    /* Meeting Proximity Action Check with completed meetings
      Warn: in-line text only
      BlockAndCancel: inline text, pop-up message, meeting is scrapped in the App and meeting staus is Cancelled in Dynamics
      BlockAndReopen: inline text, pop-up message, stay in the current meeting open status, the meeting cannot be completed */
    if (this.authService.user.meetingProximityWarningPeriod > 0) {
      const userId: string = this.authService.user.xSystemUserID;
      const hasAccountVisitEnabled = !!(this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT));
      const myMeetingsWithContacts: Activity[] = this.activityService.activities.filter(activity => activity instanceof AppointmentActivity && (activity.meetingOwnerId === userId ||
        (Array.isArray(activity.accompaniedUserList) && activity.accompaniedUserList.findIndex(a => a.id === userId) >= 0)) && !_.isEmpty(activity.contacts));
      //In case of the complete meeting from Account/Contact timeline, closeByActivityIds and closeByCompletedActivityIds should be updated
      if (!_.isEmpty(myMeetingsWithContacts)) {
        for (let i = 0; i < myMeetingsWithContacts.length; i++) {
          startRecursiveActivityCloseByActivityFlagging(myMeetingsWithContacts, i, userId, this.authService.user.meetingProximityWarningPeriod, hasAccountVisitEnabled);
        }
      }
      this.displayConflictMsg = this.checkConflictMsgDisplay(true);

      if (this.authService.user.buSettings && this.authService.user.buSettings['indskr_meetingproximityaction']
        && ((this.authService.user.buSettings['indskr_meetingproximityaction']) == MeetingProximityActionOption.BlockAndCancel
          || (this.authService.user.buSettings['indskr_meetingproximityaction']) == MeetingProximityActionOption.BlockAndReopen)) {

        const activity = this.activityService.activities.find(a => a.ID === this.activityService.selectedActivity.ID);
        const hasCompletedMeetingProximityWarning: boolean = activity?.closeByCompletedActivityIds?.size > 0 ? true : false;

        if (hasCompletedMeetingProximityWarning) {
          let messageString = this.translate.instant('MEETING_PROXIMITY_RESTRICTION_BLOCK_AND_REOPEN');

          if ((this.authService.user.buSettings['indskr_meetingproximityaction']) == MeetingProximityActionOption.BlockAndCancel) {
            this.events.publish('deleteMeetingNoConfirm');
            messageString = this.translate.instant('MEETING_PROXIMITY_RESTRICTION_BLOCK_AND_CANCEL');
          }

          this.notificationService.notify(messageString, 'Meeting Details', 'top', ToastStyle.DANGER, 3000);

          return;
        }
      }
    }
    // check eDA /uploaded picture
    if (this.isInStoreMeeting) {
      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_MR) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_OSS)) {
        if (_.isEmpty(this.activityService.selectedActivity['presentations'])) {
          this.notificationService.notify(this.translate.instant('FA_INSTORE_CHECK_GPS_EDA', {N: this.activityService.selectedActivity.subject}), 'Meeting Details', 'top', ToastStyle.INFO);
          return;
        }
        const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;
        if (_.isEmpty(currentMeeting.inStorePhotos)) {
          this.notificationService.notify(this.translate.instant('FA_INSTORE_CHECK_GPS_PIC', {N: this.activityService.selectedActivity.subject}), 'Meeting Details', 'top', ToastStyle.INFO);
          return;
        }
      }
    }
    // Account visit mark complete confirm
    if (this.isAccountVisitRecord) {
      const res = await this.alertService.showAlert(
        {
          title: this.translate.instant('MARK_COMPLETE'),
          message: this.translate.instant('ACCOUNT_VISIT_NESTED_MARK_COMPLETE_CONFIRM'),
        },
        this.translate.instant('SUBMIT'),
      );
      if (res.role !== 'ok') {
        return;
      }
    }

    if (!this.isAccountVisitNestedMeeting &&
      this.isGpsEnabled && this.activityService.selectedActivity instanceof AppointmentActivity
      && (this.activityService.selectedActivity.gpsCheckInDetails?.indskr_checkinstatus == null &&
        this.activityService.selectedActivity.gpsCheckInDetails?.indskr_checkoutstatus == null)) {
      if (this.authService.user.buSettings['indskr_gpsfunctionality']) {
        this.checkinFieldsEmpty = true;
        this.notificationService.notify(this.translate.instant('GPS_CHECKIN_MANDATORY'), 'Meeting Details', 'top', ToastStyle.DANGER);
        this.initFormFields();
        return;
        // } else {
        //   // const completeConfirmed = await this.showMeetingCompleteAlert();
        //   // if (!completeConfirmed) return;
      }

    }

    if (!this.isAccountVisitNestedMeeting &&
      this.isAccountVisitRecord && this.authService.user.buSettings['indskr_gpscheckoutmandatory'] && currentActivity.gpsCheckInDetails?.indskr_checkoutstatus == null) {
      this.checkOutFieldsEmpty = true;
      this.notificationService.notify(this.translate.instant('GPS_CHECKOUT_MANDATORY'), 'Meeting Details', 'top', ToastStyle.DANGER);
      this.initFormFields();
      return;
    }
    // check-in/out date verify
    if (this.isGpsEnabled) {
      if (this.isAccountVisitNestedMeeting) {
        let parentActivity = this.activityService.getActivityByID(currentActivity.indskr_parentcallid) as AppointmentActivity;
        currentActivity.gpsCheckInDetails = parentActivity.gpsCheckInDetails
      }
      if (this.validateGpsDetailsDates(currentActivity) == false) return
    }
    if (this.isMeetingLimitReached) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant('MEETING_LIMIT_REACHED'),
        message: this.translate.instant('MEETING_LIMIT_REACHED_MSG').replace("{{DATE}}", this.meetingStartDate),
        buttons: [{
          text: this.translate.instant("CLOSE"),
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      alert.present();
      alert.onDidDismiss().then(() => {
      });
      return;
    }

    if (this.pollService.isPollsToSubmit()) {
      const res = await this.alertService.showAlert({
        header: this.translate.instant('POP_SUBMIT_POLL'),
        message: this.translate.instant('POP_R_U_SURE_NOT_SUBMIT_POLL'), backdropDismiss: false
      }, "Yes", "No");

      if (res.role === "ok") {
        return;
      } else if (res.role === "cancel") {
        localStorage.setItem("polls", JSON.stringify([]));
      }
    }

    if (this.activityOutcomeisMandatory) {
      if (!(this.activityService.selectedActivity as AppointmentActivity).meetingOutcomeId) {
        this.notificationService.notify(this.translate.instant('MEETING_COMPLETE_FAIL_ALERT_MEETING_OUTCOME'), 'Meeting Details', 'top', ToastStyle.DANGER);
        return;
      }
    }
    if (this.activityService.activityCanComplete && this.activityService.selectedActivity instanceof AppointmentActivity && !this.blockUI) {
      // if(!this.unsolicitedMeetingCompleteCheck){
      //   this.notificationService.notify('unsolicited calls limit reached', 'Meeting Details', 'top', ToastStyle.DANGER);
      //   return;
      // }
      // Blocking UI to prevent multi request
      // if (this.uiService.showCancelDoneOnActivityDetails) {
      //   this.uiService.showCancelDoneOnActivityDetails = false;
      // }
      // showing alert for confirm

      /* To check foll.
        1.Feature Action is enabled
        2.If selected appointment has presentations added and any one of the presenations were played
        3.If selected appointment has not presentations added
        4.Warning for Same is the case for Remote Meeting - Format entity Remote Channel & Content Required is true
      */

      let isRemoteDetailingEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_REASON);
      const isRemoteDetailingReasonRequired = this.isCurrentContentNotPlayedByUser() || _.isEmpty(this.activityService.selectedActivity.presentations);

      if (this.isContentRequiredForRemoteChannelEnabled && isRemoteDetailingReasonRequired) {
        //Show warning for isContentRequiredForRemoteChannelEnabled
        this.notificationService.notify(this.translate.instant("NO_DETAILING_WARNING_REMOTE_MESSAGE"), 'Meeting Details', 'top', ToastStyle.DANGER);
      } else if (isRemoteDetailingEnabled && isRemoteDetailingReasonRequired && !this.isAccountVisitRecord) {
        let selfClaim = false;
        if (this.selfClaimNeededIfStartEndTimeModified && !this.isContentPlayed && !this.isAccountVisitRecord) {
          const createdOnTime = this.activityService.selectedActivity.offlineId ? new Date(parseInt(this.activityService.selectedActivity.offlineId.split(OFFLINE_ID_PREFIX)[1])) : new Date();
          if (!moment(this.activityService.selectedActivity.scheduledStart).isSame(createdOnTime, 'days')
            || !moment(this.activityService.selectedActivity.scheduledEnd).isSame(createdOnTime, 'days')
            || !this.isTimeBetween(
              moment(this.activityService.selectedActivity.scheduledStart).format('HH:mm'),
              moment(this.activityService.selectedActivity.scheduledEnd).format('HH:mm'),
              moment(createdOnTime).format('HH:mm')
            )) {
            selfClaim = await this.selfClaimAlert();
            if (!selfClaim)
              return;
          }
        }
        //Show modal popup for selecting Meeting Content Reasons
        const fetchedMeetingContentReasons = this.fetchMeetingContentReasons();
        if (_.isEmpty(fetchedMeetingContentReasons)) {
          return this.notificationService.notify(this.translate.instant("NO_DETAILING_REASON_EMPTY_ERROR"), 'Meeting Details', 'top', ToastStyle.DANGER);
        }

        const selectedReason = await this.showReaonPopover(fetchedMeetingContentReasons);
        if (selectedReason) {
          //Assign selected reason value so that it could be used in payload
          this.activityService.selectedActivity.indskr_meetingcontentreasonsid = selectedReason.value;
          return await this.processMarkComplete(isFromActivityDetails, selfClaim);
        }
      } //end of if(isRemoteDetailingReasonRequired)
      // --------------------------------No Detailing in a Meeting-------------------------------- //

      else {
        if (this.isAccountVisitRecord) {
          return await this.processMarkComplete(isFromActivityDetails);
        }
        let selfClaim = false;
        if (this.selfClaimNeededIfStartEndTimeModified && !this.isContentPlayed && !this.isAccountVisitRecord) {
          const createdOnTime = this.activityService.selectedActivity.offlineId ? new Date(parseInt(this.activityService.selectedActivity.offlineId.split(OFFLINE_ID_PREFIX)[1])) : new Date();
          if (!moment(this.activityService.selectedActivity.scheduledStart).isSame(createdOnTime, 'days')
            || !moment(this.activityService.selectedActivity.scheduledEnd).isSame(createdOnTime, 'days')
            || !this.isTimeBetween(
              moment(this.activityService.selectedActivity.scheduledStart).format('HH:mm'),
              moment(this.activityService.selectedActivity.scheduledEnd).format('HH:mm'),
              moment(createdOnTime).format('HH:mm')
            )) {
            selfClaim = await this.selfClaimAlert();
            if (!selfClaim)
              return;
          }
        }
        const hasMeetingReopenFA = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_REOPEN) && this.authService.user.pastMeetingReopenPeriod !== 0;
        if (hasMeetingReopenFA) {
          return await this.processMarkComplete(isFromActivityDetails, selfClaim);
        }
        const message = this.translate.instant(hasMeetingReopenFA ? 'MEETING_COMPLETE' : 'MEETING_NO_MODIFIED');
        const alertResponse = await this.alertService.showAlert(
          {
            title: this.translate.instant('MARK_COMPLETE'),
            message,
          }, this.translate.instant('SUBMIT')
        );
        if (alertResponse.role === 'ok') {
          return this.processMarkComplete(isFromActivityDetails, selfClaim);
        }
      }
    } else {
      return false;
    }
  }

  private validateGpsDetailsDates(appointment):boolean{
    let checkinDate = this.datePipe.transform(appointment.gpsCheckInDetails?.indskr_checkindatetime,'yyyy-MM-dd')
    let checkoutDate = this.datePipe.transform(appointment.gpsCheckInDetails?.indskr_checkoutdatetime,'yyyy-MM-dd')
    let scheduledStartDate = this.datePipe.transform(new Date(appointment.scheduledStart),'yyyy-MM-dd') ;
    let scheduledEndDate = this.datePipe.transform(new Date(appointment.scheduledEnd),'yyyy-MM-dd') ;
    if ((checkinDate != null && checkinDate != scheduledStartDate) || (checkoutDate != null && checkoutDate != scheduledEndDate)) {
      this.notificationService.notify(this.translate.instant(this.isAccountVisitRecord ? 'AC_DATE_AND_GPS_DATE' : 'MEETING_DATE_AND_GPS_DATE'), 'Meeting Details', 'top', ToastStyle.DANGER);
      return false;
    }
    return true;
  }
  private async showReaonPopover(fetchedMeetingContentReasons) {
    let popoverOptions = {
      header: this.translate.instant('NO_DETAILING_POPUP_HEADER'),
      inputs: fetchedMeetingContentReasons,
      cancelText: this.translate.instant('CANCEL'),
      confirmText: this.translate.instant('SUBMIT_AND_COMPLETE')
    };

    this._lookupPopover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: popoverOptions,
      cssClass: 'alert-with-input-list-view-for-meeting-content-reason',
      backdropDismiss: true
    });
    this._lookupPopover.present();

    return await this._lookupPopover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok' && res.data.inputs) {
        return res.data.inputs;
      }
      return false;
    });
  }

  private async selfClaimAlert() {
    const modal = await this.modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('ARE_YOU_SURE_YOU_WANT_SELF_CLAIM'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant("SELF_CLAIM_NOTIFICATION"),
        buttons: [{
          label: this.translate.instant("ACCEPT"),
          value: true
        }]
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then(async ({ data }) => {
      const response = data?.value ? data.value : false;
      if(!response) {
        await this.uiService.displayLoader();
        const isShortCall = (this.activityService.selectedActivity as AppointmentActivity).indskr_shortcall;
        const createdOnTime = this.activityService.selectedActivity.offlineId ? new Date(parseInt(this.activityService.selectedActivity.offlineId.split(OFFLINE_ID_PREFIX)[1])) : new Date();
        const startTime = format(createdOnTime);
        const endTime = format(addMinutes(createdOnTime, isShortCall ? 5 : this.authService.user?.meeting_duration || 30));
        const prevStartTime = startTime;
        const prevEndTime = endTime;

        await this.updateMeetingDate({
          startTime,
          endTime,
          prevStartTime,
          prevEndTime,
        }, true);
        this.initMeetingDateFormFields();
        this.activityService.selectedActivity.resetScheduledStartLocale();
        let dateTimeValue = this.activityService.getFormattedDateTimeText();
        if (dateTimeValue) {
          this.meetingStartTime = dateTimeValue.startTime;
          this.meetingEndTime = dateTimeValue.endTime;
        }
        this.cdRef.detectChanges();
        await this.uiService.dismissLoader();
      }
      return response;
    })
  }

  private get isMeetingLimitReached(): boolean {
    if (this.authService.user.meetingLimit > 0) {
      return this.activityService.activities.filter(activity =>
        activity.type == ActivityType.Appointment
        && this.activityService.isMeetingOwner
        && activity.state == MeetingActivityState.Completed
        && moment(activity.scheduledStart).format('MM DD YYYY') === moment(this.activityService.selectedActivity.scheduledStart).format('MM DD YYYY')
      ).length >= this.authService.user.meetingLimit;
    }
    return false;
  }

  private updateUnsolicitedMeetingData(reopen?: boolean) {
    let hcpInteractionField;
    if ((this.activityService.selectedActivity as AppointmentActivity).appConfigFields) {
      hcpInteractionField = (this.activityService.selectedActivity as AppointmentActivity).appConfigFields.find(a => a.fieldName == 'indskr_hcpinteraction');
    }
    if (this.isSolicitatedMeetingComplianceCheckEnabled && hcpInteractionField && hcpInteractionField.value == 548910001) {
      (this.activityService.selectedActivity as AppointmentActivity).contacts.forEach(c => {
        const solicDataIdx = this.activityService.unsolicitedMeetingsData.findIndex(a => a.contactid == c.ID);
        if (solicDataIdx >= 0) {
          if (reopen) {
            const filteredMeetings = this.activityService.unsolicitedMeetingsData[solicDataIdx].appointments.filter(a => a.activityid !== this.activityService.selectedActivity.ID);
            if (filteredMeetings.length == 0) {
              this.activityService.unsolicitedMeetingsData.splice(solicDataIdx, 1);
            } else {
              this.activityService.unsolicitedMeetingsData[solicDataIdx].appointments = filteredMeetings;
            }
          } else {
            this.activityService.unsolicitedMeetingsData[solicDataIdx].appointments.push({
              activityid: this.activityService.selectedActivity.ID,
              scheduledend: this.activityService.selectedActivity.scheduledEnd,
            });
          }
        }
        else {
          this.activityService.unsolicitedMeetingsData.push({
            contactid: c.ID,
            appointments: [{
              activityid: this.activityService.selectedActivity.ID,
              scheduledend: this.activityService.selectedActivity.scheduledEnd,
            }],
          });
        }
      });
      this.activityDataService.updateUnsolicitedMeetingDataOfflineDB();
    }
  }

  async reopenMeeting() {
    if (!this.isSelectedActivityReopenable) return;

    const title = this.isAccountVisitRecord
      ? this.translate.instant('ACCOUNT_VISIT_REOPEN')
      : this.translate.instant('REOPEN_MEETING');
    const message = this.isAccountVisitRecord
      ? this.translate.instant('ACCOUNT_VISIT_REOPEN_COMFIRM')
      : this.translate.instant('MEETING_REOPEN_CONFIRMATION')

    await this.alertService.showAlert({
      title,
      message,
    }, this.translate.instant('REOPEN')
    ).then(async res => {
      if (res.role === 'ok') {
        this.uiService.displayLoader();

        try {
          await this.meetingDataService.updateMeetingStatus(<AppointmentActivity>this.activityService.selectedActivity, MeetingStatus.OPEN);
        } catch (error) {
          console.error('reopenMeeting: ', error);
          this.uiService.dismissLoader();
          // Check conflicts
          checkTimeConflictWithOtherActivities(
            this.activityService.selectedActivity,
            this.activityService.activities,
            this.activityService.conflictCheckStartIdx,
            this.eventsToolService.eventsToolData,
            this.eventsToolService.conflictCheckLastIdx,
            this.authService.user.xSystemUserID,
          );
          this.displayConflictMsg = this.checkConflictMsgDisplay(true);
          this.detectChangesOnActivityDetails(true);
          return;
        }

        // Check conflicts
        checkTimeConflictWithOtherActivities(
          this.activityService.selectedActivity,
          this.activityService.activities,
          this.activityService.conflictCheckStartIdx,
          this.eventsToolService.eventsToolData,
          this.eventsToolService.conflictCheckLastIdx,
          this.authService.user.xSystemUserID,
        );
        this.displayConflictMsg = this.checkConflictMsgDisplay(true);
        this.activityService.publishActivityEvent({ action: 'Update', activity: this.activityService.selectedActivity });
        this.meetingStructureService.setCurrentMeetingStructure(this.meetingStructureService.embeddedIntreactions);
        this.footerService.initButtons(FooterViews.Activities);
        this.uiService.dismissLoader();
        if (this.activityService.selectedActivity.accessedFrom == PageName.AccountTimelineComponent
          || this.activityService.selectedActivity.accessedFrom == PageName.ScientificActivityTimeline
          || this.activityService.selectedActivity.accessedFrom == PageName.ContactTimelineComponent) {
          this.activityReopenedFromTimeline = true;
        }
        this.events.publish('meetingReopened', this.activityService.selectedActivity);
        this.events.publish("updateTherapeuticarea");
        this.activityService.updateActivities(this.activityService.selectedActivity);
        this.updateUnsolicitedMeetingData(true);
        this.detectChangesOnActivityDetails(true);
        this.initDieseaseAreaSectionData();
        this.initProductIndicationsSectionData();
      }
    });
  }

  /**
   *
   *
   * @returns
   * @memberof ActivitiesDetailsPaneComponent
   * set mode of Account view 'addnew', readOnly, listviw
   */
  openAccounts(ev?:any) {
    this.trackingService.tracking('MeetingAccountsClick', TrackingEventNames.ACTIVITY);
    this.accountService.selectedFor = AccountSelectedFor.ADD_TO_MEETING;
    if (!this.accountService.loadedAccounts || this.enableReadonlyViewForMeetings ||
      ((this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || this.isReadOnlyRecommendedMeeting)
      && this.activityService.selectedActivity['accounts'].length === 0)) return;
    if (this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointMeeting || !this.activityService.isMeetingOwner || this.backgroundUploadInProgress || this.isReadOnlyRecommendedMeeting) {
      this.accountService.accessedAccountListFrom = PageName.ActivitiesDetailsPaneComponent;
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.READONLY }, PageName.AccountPageComponent).then(() => {
        if (this.accountService.selectedAccounts.length > 0 && !this.device.isMobileDevice) this.events.publish(EventName.OPEN_SELECTED_ACCOUNT_DETAILS, this.accountService.selectedAccounts[0]);
      });
    } else {
      if (this.isLayoverPushView && (this.layoverViewPushedFrom == "AccountTool" || this.layoverViewPushedFrom == "AccountManagementTool")) {
        this.uiService.prevView = '';
        this.uiService.activeView = '';
      } else if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
      } else {
        this.uiService.prevView = 'Appointment';
        this.uiService.activeView = 'accountDetails';
      }
      this.uiService.showNewActivity = false;
      this.accountService.accessedAccountListFrom = PageName.ActivitiesDetailsPaneComponent;
      if (this.isAccountVisitRecord) {
        this.accountService.selectedFor = AccountSelectedFor.ACCOUNT_VISIT;
      }
      let viewMode = this.isGpsEnabled ? ComponentViewMode.SELECTION : ComponentViewMode.ADDNEW;
      if(this.isGpsEnabled && (this.activityService.selectedActivity as AppointmentActivity).gpsCheckInDetails?.indskr_checkinstatus != null){
        viewMode = ComponentViewMode.READONLY
      }

      let searchText = '';
      let activateSearchOtherAccounts = false;
      if (ev) {
        if (ev.id === 'view-all' || ev.id === 'show-other') {
          searchText = ev.text;
          activateSearchOtherAccounts = ev.id === 'show-other'
        }
      }

        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
          'listMode': viewMode,
          'accountSearchText': searchText,
          activateSearchOtherAccounts,
          hidesearchSuggestionsDropDown : true
          // callbackEvent: (data: any) => this.onContactsUpdated(data)
        }, PageName.AccountPageComponent).then(() => {
          if(!activateSearchOtherAccounts){
            if (this.accountService.selectedAccounts.length > 0 && !this.device.isMobileDevice) this.events.publish(EventName.OPEN_SELECTED_ACCOUNT_DETAILS, this.accountService.selectedAccounts[0]);
          }
        });
     
    }
    this.isClickedAccounts = true;
  }

  /*
    Open List of user for selection
  */
  openAccompainedUsers() {
    //if (!this.isReadOnlyJointMeeting && !this.activityService.selectedActivity.isCompleted) {
    //if (this.enableReadonlyViewForMeetings || (this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity['accompaniedUserList'].length === 0)) return;
    // this.trackingService.tracking('Add a user (rep / manager)  to the accompanied user field');
    // this.trackingService.tracking('Access Joint Meeting Tool via Meeting');
    this.ioUserSelection.setCaseSelectorSource("Team Members");
    this.ioUserSelection.setSelectedInputSource(this.activityService.selectedActivity["accompaniedUserList"]);
    let userList = _.cloneDeep(this.activityService.repUserList);
    if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_MEETING)){
      userList.push({
        id : this.authService.user.xSystemUserID,
        name : this.authService.user.displayName,
        emailaddress : this.authService.user.mail,
        businessunitname : this.authService.user.businessUnitName
      })
    }
    this.ioUserSelection.setSelectionDataSource(userList);

    this.uiService.showNewActivity = false;

    setTimeout(() => {
      const onUserSelect = (data) => { if (data != undefined) {
        this.activityService.selectedActivity.modified = new Date();
        this.updateAccompaniedUsers(data);
      }};
      this.navService.pushWithPageTracking(IOMultiSelect, PageName.IOMultiSelect, { remoteMeetingConnectionMapping: true, onSelectDone: onUserSelect }, PageName.IOMultiSelect);
    }, 10);
  }

  public closepage() {
    this.events.publish('initTodo');
    if (this.uiService.showCancelDoneOnActivityDetails) {
      if (this.uiService.prevView == 'callPlanDetails' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage
          ||(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab')) {
        this.events.publish('refresh-callplan-activity');
        this.markNewActivityOkay();
        return;
      }
      // this.scrapActivity();
      this.uiService.showCancelDoneOnActivityDetails = false;
    }
    if (this.activityService.selectedActivity && (this.activityService.selectedActivity.accessedFrom == PageName.AccountTimelineComponent
      || this.activityService.selectedActivity.accessedFrom == PageName.ContactTimelineComponent
      || this.activityService.selectedActivity.accessedFrom == PageName.ScientificActivityTimeline)) {
        this.uiService.agendaRefreshRequired = this.uiService.timelineRefreshRequired = this.activityReopenedFromTimeline;
        if (this.activityService.selectedActivity.accessedFrom == PageName.AccountTimelineComponent) {
          if (_.isEmpty(this.accountService.selected)) {
            this.accountService.selected = this.accountService.tempSelected;
            this.accountService.tempSelected = null;
          }
        }else if(this.activityService.selectedActivity.accessedFrom == PageName.ContactTimelineComponent) {
          //this.uiService.contactDetailsSegment = "timeline";
          //compare field value to refresh the timeline - Status, Date, Subject, Contact, Location
          if(this.activityService.prevSelectedActivityOnTimeline && this.activityService.selectedActivity) {
            const prevData = this.activityService.prevSelectedActivityOnTimeline || '';
            const currData = this.activityService.selectedActivity || '';
            if(prevData && currData) {
              const isDiffStatus: boolean = prevData.state != currData.state || prevData.status != currData.status;
              const isDiffTime: boolean = Math.ceil(moment(new Date(prevData.scheduledStart)).diff(moment(new Date(currData.scheduledStart)), 'seconds')) != 0;
              const isDiffSubject: boolean = prevData.subject != currData.subject;
              const isDiffContact: boolean = this.isClickedContacts; //it simply determines that the field is dirty
              const isDiffAccount: boolean = this.isClickedAccounts; //it simply determines that the field is dirty
              let isDiffLocation: boolean = false;
              //check location difference
              if(_.isEmpty(prevData.location) || prevData.location == 'No Location' || prevData.location == this.translate.instant('NO_LOCATION')){
                isDiffLocation = !(currData.location == 'No Location' || _.isEmpty(currData.location) || currData.location == this.translate.instant('NO_LOCATION'));
              }else {
                isDiffLocation = prevData.location != currData.location;
              }
              //Agenda list and Timeline refresh final decision
              this.uiService.agendaRefreshRequired = this.uiService.timelineRefreshRequired = isDiffStatus || isDiffTime || isDiffSubject || isDiffContact || isDiffLocation || isDiffAccount;
              if(this.uiService.agendaRefreshRequired && !_.isEmpty(this.activityService.selectedActivityAtHome) && !_.isEmpty(this.activityService.selectedActivity)
                && this.activityService.selectedActivityAtHome.ID == this.activityService.selectedActivity.ID) {
                this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
              }
            }
          }
          this.footerService.initButtons('');
        }
    }
    if (this.repService.getCurrentUserState() !== REP_STATUS.IN_MEETING.userState) {
      if(this.from !== PageName.SetBookingActivityDetailsComponent) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
      }

      if (this.navService.getActiveChildNavViewMasterPageName() === PageName.AppealDetailsComponent) {
        this.navService.popChildNavPageWithPageTracking();
        if (this.from == PageName.AppealDetailsComponent) {
          this.footerService.initButtons(FooterViews.APPEAL_DETAILS);
          if (this.callbackEvent) {
            this.callbackEvent();
          }
        }
      }
      // return;
    } else {
      this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
    }
    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool') {
        this.navService.popChildNavPageWithPageTracking().then(() => {
          this.footerService.initButtons(FooterViews.None);
        });
      } else if (this.layoverViewPushedFrom == 'PresentationToolsPage') {
        this.navService.popChildNavPageWithPageTracking();
      }
      else if (this.layoverViewPushedFrom == 'AccountTool' || this.layoverViewPushedFrom == 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.None);
      }else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'){
        this.navService.popChildNavPageWithPageTracking();
        //this.footerService.initButtons(FooterViews.OPPORTUNITY_INFO);
      } else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
        this.navService.popChildNavPageWithPageTracking();
      }
      else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
        this.footerService.initButtons('');
        if (this.childFrom === PageName.ContactDetailsComponent) {
          this.navService.popChildNavPageWithPageTracking();
        } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.navService.popChildNavPageWithPageTracking();
        } else {
            this.navService.popToRootChildNavPageWithPageTracking();
        }
        // this.navService.popToRootChildNavPageWithPageTracking();
      } else if(this.from === PageName.SetBookingActivityDetailsComponent) {
        this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
        this.navService.popChildNavPageWithPageTracking();
        this.activityService.isLayOverPushedMeetingFromOtherActivities = false;
        this.events.publish('refresh-kit-booking-activities');
      }
      return;
    } else if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      //create meeting from timeline
      if(this.activityService.isNewMeetingFlow && this.activityService.prevSelectedActivity && this.activityService.prevSelectedActivity.type == ActivityType.Appointment) {
        this.activityService.isNewMeetingFlow = false;
        this.uiService.timelineRefreshRequired = true;
      }
      this.navService.popChildNavPageWithPageTracking();//
      this.events.publish('refreshContactDetailPageTitleOnly');
      this.uiService.showNewActivity = false;
      this.footerService.initButtons(FooterViews.Contacts);
      return;
    } else if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage
        || this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab' && this.callplanService.selectedCallPlan) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'callPlanDetails';
      this.callplanService.callPlanPageView = 'CallPlanDetail';
      this.events.publish('activity-scrapped-created-from-call-plan');
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'accountDetails';
      // this.footerService.initButtons(FooterViews.None);
    } else if (this.navService.getCurrentMasterPageName() == PageName.CoachingActivitiesComponent || this.navService.getCurrentPageName() == PageName.AccountPlanActivities) {
      this.activityService.selectedActivity = undefined;
      this.coachingReportService.activityClick = false;
      this.navService.setChildNavRightPaneView(false);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, this.navService.getCurrentMasterPageName());
      return;
    } else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
        // this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        // this.navService.popChildNavPageWithPageTracking();
    }  else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent &&
      !this.footerService.isActivitiesButton && this.uiService.activitiesPageTab !== 'plans') {
      this.events.publish("go-back-to-xperiences", PageName.ActivitiesDetailsPaneComponent);
      return;
    } else if(this.from === PageName.AccountPlanActivities){
      this.activityService.teamViewActive = false;
    } else if(this.navService.getCurrentMasterPageName() == PageName.MarketingPlanManagementPageComponent){
      this.activityService.selectedActivity = undefined;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    } else if(this.from === PageName.SetBookingActivityDetailsComponent) {
      this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
      this.navService.popChildNavPageWithPageTracking();
      this.events.publish('refresh-kit-booking-activities');
    }
    if (this.device.isMobileDevice && this.uiService.activitiesPageTab !== 'plans') {
      this.activityService.selectedActivity = undefined;
      this.uiService.showRightPane = false;
      this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      }
      if (this.agendaFooterService.actSegment === "week") {
        this.events.publish('weekview:RefreshUI');
      }
      this.events.publish('scrollToDate');
    }
    this.activityService.didOpenDetailPageFromAgendaPage = false;
  }

  async showSelectList<Tid, Ttitle>(data: { id: Tid, title: Ttitle }[], orderOption?: { orderBy: 'title' | 'id' | '$', ascending?: boolean }) {
    //type GetElementType<T extends Array<any>> = T extends (infer U)[] ? U : never;
    //return new Promise<GetElementType<typeof data>>(resolve => {
    const popoverOption = {
      selectListPopupData: data,
      field: 'select-list-popup',
      orderOption
    };
    let ev = event;

    // Handling popover launch from more options popover scenario
    if (!ev || ev instanceof CustomEvent) {
      ev = this.footerService.getFakeMouseEventForPopoverDerivedFromMoreOptionsButton(this.footerEl.nativeElement);
    }
    let popover = await this.popoverCtrl.create({ component: PopoverComponent, componentProps: popoverOption, event: ev });
    popover.present();
    let dismissFn = await popover.onDidDismiss()
    return dismissFn.data;
    //})
  }
  async sendMeetingURL(ev) {
    if (ev) ev.stopPropagation();
    if (this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings) return;
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('NO_INTERNET_CONNECTION'), 'Meeting Details', 'top', ToastStyle.DANGER);
      return;
    }

    const hasRemoteFeature = this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE) || this.authService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING);
    const hasCalendarInviteFeature = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CALENDAR_INVITE);

    if (hasCalendarInviteFeature && !hasRemoteFeature) {
      await this.createCalendarInviteModal();
    } else {
    const channelPopoverData = await this.dms.channelList.pipe(map(channels => channels.map(channel => (channel))), first(),).toPromise();
    let channelPopoverDataWithoutExternalChannel: any[] = [];

    if (hasCalendarInviteFeature) {
      this.insertCalendarInviteAsChannel(channelPopoverData);
    }

    channelPopoverData.forEach(value => {
      let translatedChannel = this.emailService.translateChannelType(value.title)
      value.title = translatedChannel;
      if (!value.indskr_externalchannel) {
        channelPopoverDataWithoutExternalChannel.push(value);
      }
    });
    const selectedChannel: any = !channelPopoverData || channelPopoverData.length == 0 ? undefined : channelPopoverData.length == 1 ? {selectedItem: channelPopoverData[0]} : await this.showSelectList(channelPopoverDataWithoutExternalChannel);
    if (selectedChannel && selectedChannel.selectedItem) {
      if (selectedChannel.selectedItem.channelType === 'Calendar Invite') {
        await this.createCalendarInviteModal();
      } else {
      this.emailService.isRemoteEmail = true;
      this.createEmailInMeeting(selectedChannel?.selectedItem, EmailTemplateType.RemoteURL);
      }
    }
    }
    return;
  }
  private insertCalendarInviteAsChannel(channelPopoverData: any[]) {
    if (Array.isArray(channelPopoverData)) {
      channelPopoverData.unshift({
        activityType: 0,
        channelType: 'Calendar Invite',
        id: 'calendar-invite',
        title: this.translate.instant('CALENDAR_INVITE')
      });
    }
  }
  private async createCalendarInviteModal() {
    if (!this.calendarInviteModalHandle) {
      // iOS safari is not displaying the modal randomly and couldn't find the root cause.
      // As a workaround, forced no animation & added CSS rules (by appending calendar-invite-modal class)
      // so that functionality isn't broken.
      this.calendarInviteModalHandle = await this.modalCtrl.create({ animated: false, component: InMeetingAllocationComponent, componentProps: { type: 'Calendar Invite' }, backdropDismiss: false, cssClass: ['inMeetingAllocation', 'calendar-invite-modal'] })
      await this.calendarInviteModalHandle.present();
      this.calendarInviteModalHandle.onDidDismiss().then((data) => {
        this.calendarInviteModalHandle = undefined;
      });
    }
  }

  sendMeetingURL_old(ev) {
    if (ev) ev.stopPropagation();
    if (this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings) return;
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant("NO_INTERNET_CONNECTION"), 'Meeting Details', 'top', ToastStyle.DANGER);
      return;
    }
    if (this.activityService.selectedActivity) {
      this.emailtemplateService.emailContacts = (this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].slice() : [];
    }
    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool') {
        this.navService.pushChildNavPageWithPageTracking(RemoteURLEmailTemplate, PageName.RemoteURLEmailTemplate, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool' });
        return;
      }
    }
    this.uiService.prevView = 'Appointment';
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'EmailTemplate';


    if (this.device.isMobileDevice) {
      this.navService.pushWithPageTracking(RemoteURLEmailTemplate, PageName.RemoteURLEmailTemplate);
    }
  }

  openLocationModal() {
    if (!this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && !this.isReadOnlyRecommendedMeeting) {
      if (this.activityService.selectedActivity.state != MeetingActivityState.Completed) {
        this.uiService.showNewActivity = false;
        // this.uiService.previousActiveView = this.uiService.activeView;
        //this.uiService.activeView = 'Location';


        if (this.isLayoverPushView && this.layoverViewPushedFrom) {
          if (this.layoverViewPushedFrom == 'AccountManagementTool' || this.layoverViewPushedFrom == 'AccountTool' || this.layoverViewPushedFrom == 'AccountTimeline') {
            this.setLocationParams(PageName.AccountManagementPageComponent, 'AccountManagementTool');
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool' });
          } else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
            this.setLocationParams(PageName.ScientificActivityPage, 'ScientificActivityPage');
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.ScientificActivityPage, { from: 'ScientificActivityPage' });
          } else if (this.layoverViewPushedFrom == 'PresentationToolsPage') {
            this.setLocationParams(PageName.PresentationPageComponent, 'PresentationToolsPage');
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.PresentationPageComponent, { from: 'PresentationToolsPage' });
          }else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'){
            this.setLocationParams(PageName.AccountManagementPageComponent, 'OpportunityManagementTool');
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.AccountManagementPageComponent, { from: 'OpportunityManagementTool' });
          }
          else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
            this.setLocationParams(PageName.OpportunityManagementPage, 'ProcedureLogFrom');
          }
        } else {
          if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) {
            this.setLocationParams(PageName.ActivitiesPageComponent, 'AgendaView');
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView' });
            this.uiService.activeView = 'ActivitiesPageRightPaneNav';
            this.uiService.showNewActivity = false;
            this.uiService.showRightPane = true;
          } else if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
            this.callplanService.callPlanPageView = 'MeetingLocation';
            this.setLocationParams(PageName.CallPlanComponent,  PageName.ActivitiesDetailsPaneComponent);
            // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.CallPlanComponent, { from: PageName.ActivitiesDetailsPaneComponent });
          } else {
            if(this.from){
              this.setLocationParams(this.from, this.from);
            }
              // this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, this.from, { from: this.from });
            else
              this.uiService.activeView = 'Location';
          }
        }
      }
    }
  }

  setLocationParams(parentComponent, from){
    if (this.isGpsEnabled || (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])) {
      let accountIds = (this.activityService.selectedActivity as AppointmentActivity).accounts.map((account) => account.id) ?? [];
      this.navService.pushChildNavPageWithPageTracking(
        LocationComponent,
        PageName.LocationComponent,
        parentComponent,
        {
          from,
          for: 'ACCOUNT_ADDRESSES',
          accountIds,
          isAccountVisit: this.isAccountVisitRecord || this.isAccountVisitNestedMeeting,
          callbackEvent: (data: any) => this._handleLocationComponentCallback(data)
        }
      );
    }else{
      this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, parentComponent , { from });
    }

  }

  openPresentations() {
    if (!this.isReadOnlyJointMeeting) {
      this.trackingService.tracking('MeetingAddPresentation', TrackingEventNames.ACTIVITY)
      // if (this.activityService.selectedActivity.isCompleted) return;
      this.uiService.prevView = 'Appointment';
      this.presentationService.viewMode = PresentationView.ADDTOMEETING;
      this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent, null, PageName.PresentationPageComponent);
    }
  }

  /**
   *
   *
   * @returns
   * @memberof ActivitiesDetailsPaneComponent
   * updates the meeting notes for a selected activity
   */
  public async saveNotes() {
    if (this.preSavedNotes == this.activityService.selectedActivity.notes) return;
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      this.trackingService.tracking('MeetingNotesUpdate', TrackingEventNames.ACTIVITY)
      let currentAppointmentActivity: AppointmentActivity = this.activityService.selectedActivity;

      let payload = new UpdateMeetingPayload(
        currentAppointmentActivity.subject,
        currentAppointmentActivity.location,
        currentAppointmentActivity.scheduledStart,
        currentAppointmentActivity.scheduledEnd,
        currentAppointmentActivity.notes);

      payload.notes = this.activityService.selectedActivity.notes;

      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).notes = this.activityService.selectedActivity.notes;

      this.activityService.getDisplayctivityByID(this.activityService.selectedActivity.ID).notes = this.activityService.selectedActivity.notes;

      this.meetingDataService.updateMeeting(this.activityService.selectedActivity, payload);

      this.preSavedNotes = this.activityService.selectedActivity.notes;
    }
    this.isNotesDirty = false;

    // //online case
    // if (!this.device.isOffline) {
    //   //online activity registered on Dynamics
    //   if (!this.activityService.selectedActivity.ID.includes('offline_')) {

    //   }
    //   //offline id detected, data to be stored for delta sync in disk service
    //   else {
    //     await this.meetingDataService.updateUserNotes();
    //   }
    // }
    // //device set to offline
    // else {
    //   await this.meetingDataService.updateUserNotes();
    // }
  }

  cancelNotes() {
    if (this.preSavedNotes) {
      this.activityService.selectedActivity.notes = this.preSavedNotes;
    } else {
      this.activityService.selectedActivity.notes = '';
    }
    this.isNotesDirty = false
  }

  onNotesFocus() {
    console.log("Notes Focus IN ");
    this.preSavedNotes = this.activityService.selectedActivity.notes;
  }

  iconClick(id, event) {

    if (id === '') {
      this.openURLPopover(event);
    } else if (id === 'x') {
      this.onLiveMeetingLinkCopyClick(event);
    }

  }

  async onTeamsLinkCopy(ev) {
    if (ev) ev.stopPropagation();
    this.copyTeamsLinkToClipboard();
  }

  async openURLPopover(ev) {
    let popover = await this.popoverCtrl.create({
      component: PopoverComponent, componentProps: { field: 'LiveTimeURL' },
      cssClass: "livetimeURL-popover", event: ev
    })
    popover.present();
  }
  async openTeamsURLPopover(ev) {
    let popover = await this.popoverCtrl.create({
      component: PopoverComponent, componentProps: { field: 'TeamsMeetingUrl' },
      cssClass: "livetimeURL-popover", event: ev
    })
    popover.present();
  }
  async onLiveMeetingLinkCopyClick(ev) {
    if (ev) ev.stopPropagation();
    if (this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.isReopened || this.activityService.selectedActivity?.isDiffPosition) return;

    const listData = [
      {
        id: 'participants',
        title: this.translate.instant(this.nativeShareAvailable ? 'SHARE_PARTICIPANTS_LINK' : 'COPY_PARTICIPANTS_LINK')
      },
      {
        id: 'coVisitors',
        title: this.translate.instant(this.nativeShareAvailable ? 'SHARE_COVISITORS_LINK' : 'COPY_COVISITORS_LINK')
      }
    ];
    const data: any = await this.showSelectList(listData, { orderBy: '$' }); // '$' makes pipe to not sort at all.
    if (data && data.selectedItem.id === 'participants') {
      this.copyToClipboard();
    } else if (data && data.selectedItem.id === 'coVisitors') {
      this.copyToClipboard(true);
    }
  }

  copyTeamsLinkToClipboard() {
    this.trackingService.tracking('MeetingCopyURL', TrackingEventNames.ACTIVITY)
    if (this.activityService.selectedActivity['indskr_teamslink']) {
      const url = this.activityService.selectedActivity['indskr_teamslink'];
      if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
        this.clipboard.copy(url);
        this.notificationService.notify(this.translate.instant('LINK_COPIED_TO_CLIPBOARD'), "activity-details", "top", "", 3000, true);
      } else {
        this.copyTextToClipboard(url);
      }
    }
  }

  copyToClipboard(isForCoVisitor = false) {
    this.trackingService.tracking('MeetingCopyURL', TrackingEventNames.ACTIVITY)
    if (this.activityService.selectedActivity['meetingURL']) {
      const url = !isForCoVisitor ? this.activityService.selectedActivity['meetingURL'] : this.activityService.selectedActivity['meetingURL'] + '/covisitor';
      // const url = `https://trtc.omnipresence.afb5217e209645ac9e51.chinanorth2.aksapp.azure.cn/basic-rtc.html?rId=70007&mId=${this.activityService.selectedActivity.ID}`;
      const shareData = { url, title: this.activityService.selectedActivity.subject, text: this.translate.instant("INVITE_YOU_TO_CONNECT_REMOTELY") };
      try {
        if (this.nativeShareAvailable && (!(navigator as any).canShare || (navigator as any).canShare(shareData))) {
          navigator.share(shareData)
        } else {
          if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
            this.clipboard.copy(url);
            this.notificationService.notify(this.translate.instant('LINK_COPIED_TO_CLIPBOARD'), "activity-details", "top", "", 3000, true);
          } else {
            this.copyTextToClipboard(url);
          }
        }
      } catch (err) {
        console.log('Oops, unable to copy', err);
      }
    }
  }
  // tslint:disable-next-line:prefer-function-over-method
  copyTextToClipboard(text) {
    let txtArea = document.createElement("textarea");

    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.focus();
    txtArea.select();
    try {
      let successful = document.execCommand('copy');
      let msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
      if (successful) {
        document.body.removeChild(txtArea);

        this.notificationService.notify(this.translate.instant('LINK_COPIED_TO_CLIPBOARD'), "activity-details", "top", "", 3000, true);
        return true;
      }
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(txtArea);

    return false;
  }

  scrollToTop() {
    let scrollIntoViewOptions: object = {
      behaviour: "smooth"
    };
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it
    //let ele = document.querySelector(currentDate);
    let ele = document.getElementById('listScroll');
    if (ele) {
      ele.scrollIntoView(scrollIntoViewOptions);
    }
  }

  get meetingURL(): string {
    //Local testing purposes
    if (this.authService.userConfig.activeInstance.friendlyName === "Dev-Cystine") {
      //let temp:string = this.activityService.selectedActivity.meetingURL;
      //if(temp !== undefined){
      //temp = temp.split("?")[1];
      //return "?" + temp;
      //}
      let vanityURL: string = this.activityService.selectedActivity.meetingURL;
      return vanityURL
    } else {
      return this.activityService.selectedActivity.meetingURL;
    }
  }

  get teamsLink(): string {
      return _.isEmpty(this.activityService.selectedActivity['indskr_teamslink']) ? null : this.activityService.selectedActivity['indskr_teamslink'];
  }
  private async updateAccompaniedUsers(raw: AccompainedUser[]) {
    if(!this.device.isOffline){
      await this.uiService.displayLoader();
    }
    await this.meetingDataService.addRemoveAccompaniedUsers(raw).then(e => {
      this.ioUserSelection.setUserSelectedData(undefined);
    });
    this.resetCovisitorComplianceAck();
    if(!this.device.isOffline){
      this.uiService.dismissLoader();
      // To dismiss the loader after certain delay
      setTimeout(() => {
        this.uiService.dismissLoader();
      }, 5000);
    }
  }

  private async updateAdditionalFields(raw: any[]) {
    this.appConfigLookupValue = raw[0].name;
    this.ioUserSelection.setAdditionalConfigData(undefined);
    var appConfigFields: ConfiguredFields = {
      fieldName: raw[0].fieldName,
      datatype: 'Lookup',
      value: raw[0].id,
      stringValue: raw[0].name,
      fieldType: 'Lookup'
    };
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public get jointMeetingStatus(): boolean {
    if (this.activityService.selectedActivity != undefined && this.activityService.selectedActivity != null) {
      //If the meeting has been marked as joint meeting
      //Handle case where joint meeting flag is set as undefined.
      if (this.activityService.selectedActivity['isJointmeeting'] || (this.activityService.selectedActivity['accompaniedUserList'] && this.activityService.selectedActivity['accompaniedUserList'].length > 0)) {
        let meetingOwnerId = this.activityService.selectedActivity['meetingOwnerId'];
        let userId = this.authService.user.systemUserID;
        //owner
        if (this.activityService.isMeetingOwner || (this.isCovisitor &&  this.coVisitorAccess === CovisitorAccess.FULL_ACCESS)) {
          return false;
        }
        else
          return true;
      }
      //owner is the meeting creator
      else {
        return false;
      }
    }
    else
      return false;
  }

  // public get unsolicitedMeetingCompleteCheck():boolean {
  //   let flag:boolean = true;
  //   if(this.isSolicitatedMeetingComplianceCheckEnabled && this.getOptionSetValue('indskr_hcpinteraction') == 'No' && (this.activityService.selectedActivity as AppointmentActivity).contacts){
  //     for(var i=0;i<(this.activityService.selectedActivity as AppointmentActivity).contacts.length; i++){
  //       const c = (this.activityService.selectedActivity as AppointmentActivity).contacts[i];
  //       let unsolicData = this.activityService.unsolicitedMeetingsData.find(a=> a.contactid == c.ID);
  //       if(unsolicData){
  //         if(unsolicData.appointments.length >= this.authService.user.unsolicitedMeetingLimitTotal){
  //           flag = false;
  //           break;
  //         }
  //       }else if(this.authService.user.unsolicitedMeetingLimitTotal <= 0){
  //         flag = false;
  //         break;
  //       }
  //     }
  //   }
  //   return flag;
  // }

  openCoachingReportDetails(report: Report) {
    if (this.enableCoachingView) return;
    this.trackingService.tracking('CoachingMeetingDetails', TrackingEventNames.COACHING);
    let selectedReport: Report = JSON.parse(JSON.stringify(report));
    this.coachingReportService.selectedReport.next(selectedReport);
    this.coachingReportService.coachingViewtype = COACHING_VIEW_TYPE.FROM_MEETING;
    this.coachingReportService.activeTab = "teamCoaching";
    this.uiService.prevView = this.uiService.activeView;
    if(this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    } else if(this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.uiService.activeView = 'ContactPageRightPaneNav';
    } else if(this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
      this.uiService.activeView = 'AccountPageRightPaneNav';
    }
    this.navService.pushChildNavPageWithPageTracking(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.ActivitiesDetailsPaneComponent);
    this.activityService.lastActivity = this.activityService.selectedActivity.ID;
    this.uiService.showFieldCoaching = true;
    this.footerService.initButtons(FooterViews.CoachingDetails);
    if ((report.statuscode === 548910001 || report.statuscode === 548910002 || report.statuscode === 548910003) && !this.coachingReportService.isOffline(report)) {
      this.coachingReportService.checkCoachingOnline(report).then(() => {
        this.setCoachings();// Reset status in coachings list
      });
    }
  }

  public get enableCoachingView() {
    return this.navService.getPreviousPageName() === PageName.CoachingComponent ||
      this.navService.getPreviousPageName() === PageName.CoachingActivitiesComponent;
  }

  public saveUserNotes() {
    alert(this.translate.instant('NOTES_SAVED'));
  }

  get allocationInSelectedMeeting() {
    return this.currentAllocationsInMeeting;
  }
  set allocationInSelectedMeeting(allocations: SampleActivity[]) {
    this.currentAllocationsInMeeting = allocations
  }

  getFormattedDate(value) {
    return format(value, 'MMM DD')
  }

  openNewActivityPage() {
    this.uiService.showNewActivity = true;
    if (this.navService.getActiveChildNavViewMasterPageName() === PageName.CallPlanComponent) {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.CallPlanComponent, { from: PageName.PhoneCallDetailsComponent });
    }
  }

  async openAllocationoder(allocationOrder: SampleActivity) {
    this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_MEETING;
    this.sampleService.inMeetingAllocationActivity = allocationOrder
    this.activityService.activityDetailsLoaded = true;
    this.uiService.showNewActivity = false;
    let modal = await this.modalCtrl.create({ component: InMeetingAllocationComponent, componentProps: { type: ActivityType.Sample }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
    modal.present();
    modal.onDidDismiss().then((data?: any) => {
      this.sampleService.samplingDetailsViewMode = undefined;
      //if (this.uiService.activeView == 'Appointment') {
      this.footerService.initButtons(FooterViews.Activities)
      if (data && data.isCompleted) {
        if (this.activityService.samplingToInMeetingActivityMapping.has(this.activityService.selectedActivity.ID)
          && this.activityService.samplingToInMeetingActivityMapping.get(this.activityService.selectedActivity.ID) == allocationOrder.ID) {
          this.activityService.samplingToInMeetingActivityMapping.delete(this.activityService.selectedActivity.ID)
        }
      }
      //}
      // if (this.uiService.activeView == 'Meeting') {
      //   this.footerService.initButtons(FooterViews.PreviewMeeting)
      // }
      // if (this.uiService.activeView == 'activityTimelineDetails') {
      //   this.footerService.initButtons(FooterViews.Activities);
      // }
    })
    return;
  }

  public get enableBackButton() {
    return this.from === PageName.AppealDetailsComponent ||
    this.from === PageName.NotificationDetailsComponent ||
    this.from === PageName.ContactTimelineComponent ||
    this.navService.getCurrentMasterPageName() !== PageName.ActivitiesPageComponent &&
    this.navService.getCurrentPageName() !== PageName.AccountPlanActivities &&
    this.navService.getCurrentMasterPageName() !== PageName.CoachingActivitiesComponent &&
    !this.uiService.showCancelDoneOnActivityDetails;
  }


  private async createEmailInMeeting(channelDetails: EmailActivityChannelDetails, type: EmailTemplateType) {
    this.events.publish('activities-details-pane:createEmail', channelDetails, type);
  }

  onSaveTA(ta) {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      this.activityService.selectedActivity.activityTherapeuticAreas = ta.filter(item => item.isSelected || item.isAutoSelected);
      this.theraputicService.updateMeetingTherapeuticArea(this.activityService.selectedActivity).then(response => {
        console.log(response);
      }).catch(err => {
        console.log(err);
        this.notificationService.notify(this.translate.instant('UPDATING_THERAPEUTIC_AREA_FAILED'), 'Meeting Details', 'top', ToastStyle.DANGER);
      })
    }
  }

  get isNotReadOnly(): boolean {
    return this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed && this.activityService.selectedActivity.state != MeetingActivityState.Canceled && !this.activityService.selectedActivity?.isDiffPosition && this.from !== PageName.AccountPlanActivities;
  }

  public getLivetimeFormField(): IndFormFieldViewDataModel {
    const activity = <AppointmentActivity>this.activityService.selectedActivity;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.isLiveMeet ? this.translate.instant('LIVE_MEETING') : this.translate.instant('ACTIVITY_DETAILS_LIVE_TIME'),
      inputText: this.meetingURL?.substring((this.meetingURL?.length - 4), this.meetingURL?.length),
      placeholderLabel: (this.activityService.meetingTypes.length > 0 && !(!this.isNotReadOnly && !(activity.indskr_meetingtype)) ? "" : this.translate.instant("NO") + " ") + this.translate.instant("MEETING_TYPE"),
      id: 'meeting-live-time-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      additionalcontrols: [{
        id: 'live-time-copy',
        src: 'assets/imgs/link.svg',
      },
      {
        id: 'live-time-mail',
        src: 'assets/imgs/Live_meet_icon.svg',
      }]
    };
    return viewData;
  }

  private async updateAppConfigInputValuesForMultiOptionSet(fieldName, dataType, multipleLabels, multipleValues) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.appointmentConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];

    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: multipleValues,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };

    appConfigFields.selectedValues = multipleLabels;
    appConfigFields.multipleLabels = multipleLabels.split(', ')
    appConfigFields.multipleValues = multipleValues.split(', ')
    appConfigFields.hasMultipleValues = true;

    currentConfigField.selectedValues = multipleLabels;
    currentConfigField.value = multipleValues;
    currentConfigField.multipleLabels = multipleLabels.split(', ')
    currentConfigField.multipleValues = multipleValues.split(', ')
    currentConfigField.hasMultipleValues = true;

    this.appointmentConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);

    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateAppConfigInputValues(myEvent, fieldName, dataType, inputValue?, isOptionSet: boolean = false,stringValue:string = '') {
    let appConfigFieldsfromService: ConfiguredFields[] = this.appointmentConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: inputValue != undefined && inputValue != null ? inputValue : (myEvent && myEvent.target && myEvent.target.value && myEvent.target.value.length != 0) ? myEvent.target.value : '',
      stringValue: stringValue,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly,
      isEmptyRequiredField: false,
      indskr_optionsetdefaultext: currentConfigField.indskr_optionsetdefaultext,
      indskr_optionsetdefaultvalue: currentConfigField.indskr_optionsetdefaultvalue,
    };

    if(currentConfigField.datatype == 'Lookup' || currentConfigField.fieldType == 'Lookup'){
      appConfigFields['indskr_lookupentitysetname'] = currentConfigField.indskr_lookupentitysetname;
      appConfigFields['indskr_referencingentitynavigationpropertyname'] = currentConfigField.indskr_referencingentitynavigationpropertyname;
      appConfigFields['indskr_lookupentityprimaryidattribute'] = currentConfigField.indskr_lookupentityprimaryidattribute;
      appConfigFields['indskr_lookupentityprimarynameattribute'] = currentConfigField.indskr_lookupentityprimarynameattribute;
      appConfigFields['indskr_lookupquery'] = currentConfigField.indskr_lookupquery;
      appConfigFields['indskr_lookupentityname'] = currentConfigField.indskr_lookupentityname;
    }

    if (isOptionSet) {
      appConfigFields.selectedValues = (this.appConfigPickValue && this.appConfigPickValue.label) ?? '';
      currentConfigField.selectedValues = (this.appConfigPickValue && this.appConfigPickValue.label) ?? '';
    } else {
      appConfigFields.selectedValues = appConfigFields.value;
      currentConfigField.selectedValues = appConfigFields.value;
    }

    if(_.isEmpty(appConfigFields.selectedValues)) this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
    else this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;

    appConfigFields.multipleLabels = [];
    appConfigFields.multipleValues = [];
    appConfigFields.hasMultipleValues = false;

    currentConfigField.multipleLabels = [];
    currentConfigField.multipleValues = [];
    currentConfigField.hasMultipleValues = false;

    currentConfigField.value = appConfigFields.value;
    this.appointmentConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateAppConfigBoolValues(fieldName, dataType, inputValue?) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.appointmentConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: inputValue ? inputValue : false,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };
    currentConfigField.value = appConfigFields.value;
    this.appointmentConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateConfigDate(myEvent, fieldName, dateValue) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.appointmentConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: 'Date',
      value: dateValue,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };
    currentConfigField.value = appConfigFields.value;
    this.appointmentConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async saveAdditionalConfigFields(appConfigFields) {
    if (!appConfigFields.entityName) {
      // For later use.
      appConfigFields['entityName'] = 'appointment';
    }
    if (appConfigFields.fieldName && appConfigFields.fieldName.length) {
      // API requires this key (fieldname).
      let fieldName = appConfigFields.fieldName;
      appConfigFields['fieldname'] = fieldName;
    }
    this.pushAdditionalConfigOffline(appConfigFields);

    /**Note conflict(indskr_notes): Notes in App configured fields and Notes in Section with the FA(isNotesEnabled)
     * Note in App configured is override the Notes in Section
    */
    if(appConfigFields['fieldName'] =='indskr_notes') this.activityService.selectedActivity.notes = appConfigFields['value'] || '';
    if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
      await this.uiService.displayLoader();
      try {
        await this.meetingDataService.updateappConfigFields((this.activityService.selectedActivity as AppointmentActivity), [appConfigFields], true);
      } catch (error) {
        console.error('saveAdditionalConfigFields: ', error);
        this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity), false, true);
      } finally {
        // this.activityService.isActivityUpdate = true;
        await this.uiService.dismissLoader();
      }
    }
    else {
      // this.activityService.isActivityUpdate = true;
      this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity));
    }
  }

  public async openBooleanPopover(event, fieldName) {
    let appConfigBooleanValues = ['Yes', 'No'];
    const prevSelectedValue = this.appConfigInputBoolValue;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'appConfigBooleanValues-select',
      data: appConfigBooleanValues.map(value => {
        let obj = {
          title: value,
          id: value,
          isSelected: false,
        };
        if (prevSelectedValue === value) {
          obj['isSelected'] = true;
        }
        return obj;
      }),
    };
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data && data.data && data.data.selectedItems) {
        if (this.backgroundUploadInProgress) return;
        let value = data.data.selectedItems[0].title;
        let requestValue = value === 'Yes' ? true : false;
        this.appConfigInputBoolValue = value;
        this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
        this.updateAppConfigBoolValues(fieldName, 'boolean', requestValue);
      } else {
        let fieldValue = this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).value;
        if(!fieldValue) this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
      }
    });
  }

  public async openLookup(event,fieldName) {
    const configField = this.appointmentConfigFields.find(a=> a.fieldName == fieldName);
    if(configField){
      if (fieldName === 'indskr_activitytype' || fieldName === 'indskr_activitysubtype') {
        this.ioUserSelection.setCaseSelectorSource(configField.fieldLabel);
        let dataSource: any = this.lookupData(configField.fieldLabel, fieldName);
        this.ioUserSelection.setSelectionDataSource(dataSource);
        this.uiService.showNewActivity = false;
        setTimeout(() => {
          this.navService.pushWithPageTracking(IOMultiSelect, PageName.IOMultiSelect, { remoteMeetingConnectionMapping: true }, PageName.IOMultiSelect);
        }, 10);
      }else {

        let results = [];
        if(this.device.isOffline){
          if(this.lookupConfigFieldsData && this.lookupConfigFieldsData[configField.fieldName]){
            results = this.lookupConfigFieldsData[configField.fieldName]
          }
        }else{
          results = await this.activityDataService.getConfigFieldLookupResults(configField,null);
        }
        let currentSelectedValue:string;
        if(this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.appConfigFields && this.activityService.selectedActivity.appConfigFields.length > 0){
          const currentConfigField = this.activityService.selectedActivity.appConfigFields.find(a=> a.fieldName == fieldName);
          if(currentConfigField && currentConfigField.value){
            currentSelectedValue = currentConfigField.value;
          }
        }
        let lookupPopoverModel:IndDropdownListDetailModel = {
          id: 'config-fields-lookup-select',
          isSearchEnabled:true,
          searchInputPlaceholder: this.translate.instant('SEARCH'),
          data: results.map(item=> {
            let data:SelectListData = {
              title: item[configField.indskr_lookupentityprimarynameattribute],
              id: item[configField.indskr_lookupentityprimaryidattribute],
              isSelected: currentSelectedValue == item[configField.indskr_lookupentityprimaryidattribute] ? true : false,
            }
            return data;
          })
        };
        this._lookupPopover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData: lookupPopoverModel },
          cssClass: 'dropdown-list-view',
          event: event
        });
        this._lookupPopover.onDidDismiss().then(async (data: any) => {
          data = data.data;
          if (data && data.selectedItems && data.selectedItems.length == 1) {
            let selectedPopOverValue = data.selectedItems[0].id;
            let selectedPopOverStringValue = data.selectedItems[0].title;
            this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
            // this.saveAdditionalConfigFields(appConfigFields);
            // let lookupConfigFieldsPayload:UpdateTypeAndSubTypeActivityPayLoad = new UpdateTypeAndSubTypeActivityPayLoad(
              // configField.fieldName,
              // configField.indskr_lookupentitysetname,
              // configField.entityName,
              // data.selectedItems[0].id,
            // );
            // await this.meetingDataService.updateActivityLookupFields(this.activityService.selectedActivity as AppointmentActivity,lookupConfigFieldsPayload);
            this.updateAppConfigInputValues(event, fieldName, 'Lookup', selectedPopOverValue, false,selectedPopOverStringValue);
          } else if (data && data.selectedItems && data.selectedItems.length == 0) {
            //this._clearAttributeValue(targetControl);
            let fieldValue = this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).value;
            if(!fieldValue) this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
          }
          //if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
          this._lookupPopover = undefined;
        });
        this._lookupPopover.present();
      }
    }
  }

  private lookupData(fieldLabel, fieldName): any[] {
    switch (fieldLabel) {
      case 'Activity Type':
        let typesArray = this.activityService.meetingActivityTypes.filter(at => at.indskr_omnipresenceactivity === this.meetingActivityTypeCode.MEETING);
        let activityTypeSource: any = typesArray.map((obj) => {
          let values = {
            "name": obj.indskr_name,
            "id": obj.indskr_activitytypeid,
            "type": "activity",
            'fieldName': fieldName
          }
          return values
        })
        return activityTypeSource;
      case 'Activity Sub Type':
        let SubTypesArray = this.activityService.meetingSubActivityTypes.filter(at => at.indskr_omnipresenceactivity === this.meetingActivityTypeCode.MEETING);
        let subActivityTypeSource: any = SubTypesArray.map((obj) => {
          let values = {
            "name": obj.indskr_name,
            "id": obj.indskr_activitysubtypeid,
            "type": "sub activity",
            'fieldName': fieldName
          }
          return values
        })
        return subActivityTypeSource;
    }
  }

  public async updateConfigFieldDate(isDone: boolean) {
    if (isDone) {
      const m = moment(this.appConfigFieldDate);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'appconfigfields', 'top', ToastStyle.DANGER);
        return;
      }
      this.appointmentConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).isEmptyRequiredField = false;
      this.appConfigDateValue = new Date(this.appConfigFieldDate).getTime();
      this.formattedDate = format(new Date(parseInt(this.appConfigDateValue.toString())), this.dateTimeFormatsService.dateTimeToUpper);
      if (this.appConfigDateValue && this.formattedDate) {
        this.updateDateField();
      }
    } else {
      let fieldValue = this.appointmentConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).value;
      if(!fieldValue) this.appointmentConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).isEmptyRequiredField = true;

    }
  }

  private selectedCFOValues: ConfigFieldOptionValue[] = [];

  private handleMultiOptionSelectPopoverDismiss(data: any) {
    if (data && data.selectedItems && data.selectedItems.length && data.isDone) {
      let firstSelectedPopOverOptionValue = data.selectedItems[0].value;
      let optionsLabels = data.selectedItems.map(each => { return each.title });
      let optionsValues = data.selectedItems.map(each => { return each.value });
      let multipleLabels = optionsLabels.join(', ');
      let multipleValues = optionsValues.join(', ');
      let selectedValue = this.selectedCFOValues.filter(option => { return option.value === firstSelectedPopOverOptionValue });
      let fieldName = selectedValue.length ? selectedValue[0].fieldName : '';
      this.selectedCFOValues = [];
      this.updateAppConfigInputValuesForMultiOptionSet(fieldName, 'String', multipleLabels, multipleValues);
    }
  }

  public async getPicklistForRecommendedEngagementPeriod() {
    let entityName = 'indskr_rec_engagement_period';
    let fieldName = 'indskr_day_of_week';

    let optionValuesForDayOfWeek = this.activityService.getConfigFieldsValuesByActivityName(entityName, fieldName);
    if (!optionValuesForDayOfWeek.length) {
      console.error(`No options available for ${fieldName} in ${entityName}.`);
      return;
    }
    else {
      //To create dictionary
      let tempArr = [];
      optionValuesForDayOfWeek.forEach((element) => {
        tempArr.push(_.pick(element, ['label', 'value']));
      });
      this.dictionaryDayOfWeek = Object.assign(
        {},
        ...tempArr.map((x) => ({ [x.value]: x.label }))
      );
    }

    fieldName = 'indskr_time_slot';
    let optionValuesForTimeSlot = this.activityService.getConfigFieldsValuesByActivityName(entityName, fieldName);
    if (!optionValuesForTimeSlot.length) {
      console.error(`No options available for ${fieldName} in ${entityName}.`);
      return;
    } else {
      //To create dictionary
      let tempArr = [];
      optionValuesForTimeSlot.forEach((element) => {
        tempArr.push(_.pick(element, ['label', 'value']));
      });
      this.dictionaryTimeSlot = Object.assign(
        {},
        ...tempArr.map((x) => ({ [x.value]: x.label }))
      );
    }
  }

  public async getRecommendedEngagementPeriodByContactId(contactId:string) {
    // if (this.device.isOffline || !contactId ) {
    //   return;
    // }

    let fetchXML = fetchQueries.recommendedEngagementPeriodByContactId;
    fetchXML = fetchXML.replace('{contactId}', `${contactId}`);

    try {
      await this.uiService.displayLoader();

      let response = await this.dynamics.executeFetchQuery('indskr_rec_engagement_periods', fetchXML);
      console.warn(`getRecommendedEngagementPeriodByContactId: executeFetchQuery`);
      console.log(response);

      if (!_.isEmpty(response) && this.dictionaryTimeSlot && this.dictionaryDayOfWeek) {
        // --------------------------------Time Slot-------------------------------- //
        let arr = response;

        // Add three properties labelTimeSlot, labelDayOfWeek and responseRate for storing value
        arr = arr.map((obj) => ({
        ...obj,
        labelTimeSlot: this.dictionaryTimeSlot[obj.indskr_time_slot],
        labelDayOfWeek: this.dictionaryDayOfWeek[obj.indskr_day_of_week],
        responseRate: obj.indskr_score ? obj.indskr_score : 0,
        }));

        // Sort based on response rate descending
        arr = _.orderBy(arr, ['responseRate'], ['desc']);

        console.warn(`Sorted by Response rate descending`);
        console.log(arr);

        let currentDayNum = moment().day();
        currentDayNum = 100000000 + currentDayNum;
        const currentTimeStart: moment.Moment = moment();

        // Calc. dates for scheduledStart/scheduledEnd based on daysDifference from current day
        arr.forEach((slot, index) => {
          let daysDifference = 0;

          if (slot['indskr_day_of_week'] >= currentDayNum) {
            daysDifference = slot['indskr_day_of_week'] - currentDayNum;
          } else {
            daysDifference = 7 - (currentDayNum - slot['indskr_day_of_week']);
          }

          arr[index]['scheduledStart'] = moment(currentTimeStart).add(
            daysDifference,
            'days'
          );
          arr[index]['scheduledEnd'] = moment(currentTimeStart).add(
            daysDifference,
            'days'
          );
       });

        console.warn(`DaysDifference from current day`);
        console.log(arr);

        // To check for current Date and then check for time
        arr = arr.filter((item) => {
          if (item['indskr_day_of_week'] === currentDayNum) {
            let startTime = item['labelTimeSlot'].toUpperCase().substring(0, 5);
            let momentStartTime: moment.Moment = moment(startTime, 'hh A');
            momentStartTime.set('minutes', 0);

            let endTime = item['labelTimeSlot'].toUpperCase().substring(8, 13);
            let momentEndTime: moment.Moment = moment(endTime, 'hh A');

            if (momentEndTime.format('hh:mm A') == '12:00 AM') {
              momentEndTime.set('minutes', -5);
              momentEndTime.set('days', 1);
            } else {
              momentEndTime.set('minutes', 0);
            }

            return (
              momentEndTime.isAfter(currentTimeStart) &&
              !currentTimeStart.isBetween(
                momentStartTime,
                momentEndTime,
                'minutes',
                '[]'
              )
            );
          } else return true;
        });

        this.timeSlotsFilteredByCurrentTime = Object.assign([], arr);

        console.warn(`timeSlotsFilteredByCurrentTime`);
        console.log(this.timeSlotsFilteredByCurrentTime);
        // --------------------------------Time Slot-------------------------------- //

        // --------------------------------Meetings-------------------------------- //
        const appointments = this.activityService.displayActivities.filter((activity) => {
              return activity.type === ActivityType.Appointment;
        });

        //To create dictionary
        let tempArr = [];
        appointments.forEach((element) => {
          tempArr.push(_.pick(element, ['ID', 'scheduledEnd', 'scheduledStart']));
        });

        // Add extra property dayNum for storing value
        tempArr = tempArr.map((obj) => ({
          ...obj,
          momentScheduledStart: moment(obj.scheduledStart),
          momentScheduledEnd: moment(obj.scheduledEnd),
          dayOfWeek: 100000000 + moment(new Date(obj.scheduledStart)).day(),
        }));

        // Sort based on momentScheduledStart asc
        tempArr = _.orderBy(tempArr, ['momentScheduledStart'], ['asc']);
        // Sort based on days of week and time ascending
        // tempArr = _.orderBy(tempArr, ['dayOfWeek'], ['asc']);

        console.warn(`sorted`);
        console.log(tempArr);

        // Filter out the days of the week that have passed
        // currentDayNum = new Date().getDay();
        tempArr = tempArr.filter((obj) => obj['momentScheduledStart'] >= moment());

        console.warn(`filtered`);
        console.log(tempArr);

        // Filter out the timeslots of today that have passed current Time
        tempArr = tempArr.filter((item) => {
          // To check for current Date and then check for time
          if (item['dayOfWeek'] === currentDayNum) {
            let momentStartTime: moment.Moment = moment(item['scheduledStart']);
            let momentEndTime: moment.Moment = moment(item['scheduledEnd']);

            return (
              momentEndTime.isAfter(currentTimeStart) &&
              !currentTimeStart.isBetween(
                momentStartTime,
                momentEndTime,
                'minutes',
                '[]'
              )
            );
          } else return true;
        });


        this.meetingsFilteredByCurrentTime = Object.assign([], tempArr);
        console.warn(`meetingsFilteredByCurrentTime`);
        console.log(this.meetingsFilteredByCurrentTime);
        // --------------------------------Meetings-------------------------------- //

        // // --------------------------------Recommended-------------------------------- //

        // //To check for slotStartTime between Booking Start and End Time
        this.recommendedByCurrentTime = Object.assign([], this.timeSlotsFilteredByCurrentTime);

        this.timeSlotsFilteredByCurrentTime.forEach((slot, index) => {
          this.meetingsFilteredByCurrentTime.forEach((booking, index1) => {
            if (slot['indskr_day_of_week'] === booking['dayOfWeek']) {

              let slotStartTime = slot['labelTimeSlot'].toUpperCase().substring(0, 5);
              let momentslotStartTime: moment.Moment = moment(
                slotStartTime,
                'hh A'
              );
              momentslotStartTime.set('minutes', 0);

              let slotEndTime = slot['labelTimeSlot'].toUpperCase().substring(8, 13);
              let momentslotEndTime: moment.Moment = moment(slotEndTime, 'hh A');
              momentslotEndTime.set('minutes', 0);


              let momentbookingStartTime: moment.Moment = moment(
                booking['scheduledStart'],
                'hh:mm A'
              );

              let momentbookingEndtTime: moment.Moment = moment(
                booking['scheduledEnd'],
                'hh:mm A'
              );


              if (
                this.isTimeBetween(
                  momentslotStartTime.format('HH:mm'),
                  momentslotEndTime.format('HH:mm'),
                  momentbookingStartTime.format('HH:mm')
                ) ||
                this.isTimeBetween(
                  momentslotStartTime.format('HH:mm'),
                  momentslotEndTime.format('HH:mm'),
                  momentbookingEndtTime.format('HH:mm')
                )
              ) {
                delete this.recommendedByCurrentTime[index];
              }

            } // end of if (slot['dayNum'] === booking['dayNum'])
          });
        });

        //To remove undefined
        this.recommendedByCurrentTime = this.recommendedByCurrentTime.filter(function (element) {
          return element !== undefined;
        });

        // Sort based on response rate descending
        this.recommendedByCurrentTime = _.orderBy(this.recommendedByCurrentTime, ['responseRate'], ['desc']);

        console.warn(`Order by ResponseRate`);
        console.log(this.recommendedByCurrentTime);

        this.recommendedByCurrentTime = this.recommendedByCurrentTime.slice(0, 3);

        console.warn(`Best 3 by ResponseRate`);
        console.log(this.recommendedByCurrentTime);

       // Sort based on day of week ascending
       this.recommendedByCurrentTime = _.orderBy(this.recommendedByCurrentTime, ['indskr_day_of_week'], ['asc']);

       console.warn(`Order by Day of Week`);
       console.log(this.recommendedByCurrentTime);

        // Add extra property dayNum for storing value
        this.recommendedByCurrentTime = this.recommendedByCurrentTime.map((obj) => ({
          ...obj,
          timeSlot: obj.labelTimeSlot.split(':')[0],
        }));

        //To remove group by Days of Week
        this.recommendedByCurrentTime = _.groupBy(
          this.recommendedByCurrentTime, "labelDayOfWeek"
        );

        //To convert Object to Array
        this.recommendedByCurrentTime = Object.keys(this.recommendedByCurrentTime).map((key) => [
          String(key),
          _.orderBy(
            this.recommendedByCurrentTime[key],
            ['indskr_time_slot'],
            ['asc']
          ),
        ]);

        // this.recommendedByCurrentTime = Object.assign([], tempArr);
        console.warn(`recommendedByCurrentTime`);
        console.log(this.recommendedByCurrentTime);
        // --------------------------------Recommended-------------------------------- //
      }
      else { // To clear when response is empty from FetchXML
        this.recommendedByCurrentTime = [];
      }
      this.uiService.dismissLoader();
    } catch (error) {
      console.error('getRecommendedEngagementPeriodByContactId: error:', error);
    }
    finally {
      await this.uiService.dismissLoader();
    }
    return;
  }

  public isTimeBetween(startTime, endTime, serverTime) {
    let start = moment(startTime, 'H:mm');
    let end = moment(endTime, 'H:mm');
    let server = moment(serverTime, 'H:mm');
    if (end < start) {
      return (
        (server >= start && server <= moment('23:59:59', 'h:mm:ss')) ||
        (server >= moment('0:00:00', 'h:mm:ss') && server < end)
      );
    }
    return server >= start && server < end;
  }


  public async setRecommendedDateTime(slot) {
    let scheduledStart = moment(slot['scheduledStart']);
    let scheduledEnd = moment(slot['scheduledEnd']);

    // console.warn(`Before`);
    // console.log(scheduledStart.format('DD/MM/YYYY  hh:mm A'));
    // console.log(scheduledEnd.format('DD/MM/YYYY  hh:mm A'));

    const slotStartTime = slot['timeSlot'].split('-')[0].trim();
    const slotEndTime = slot['timeSlot'].split('-')[1].trim();

    let hours = moment(slotStartTime, 'hh A').format('HH:mm');
    hours = '' + hours.split(':')[0];
    scheduledStart.set({ hour: +hours, minutes: 0 });

    hours =  moment(slotEndTime, 'hh A').format('HH:mm');
    hours = '' + hours.split(':')[0];
    scheduledEnd.set({ hour: +hours, minutes: 0 });

    // console.warn(`After`);
    // console.log(scheduledStart.format('DD/MM/YYYY  hh:mm A'));
    // console.log(scheduledEnd.format('DD/MM/YYYY  hh:mm A'));

    this.activityService.selectedActivity.scheduledStart = scheduledStart.toDate();
    this.activityService.selectedActivity.scheduledEnd = scheduledEnd.toDate();

    this.events.publish("initTimePickerLimitedMinTime");

    let dateTimeValue = this.activityService.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.meetingStartDateTime = dateTimeValue.startDateTime;
      this.meetingStartDate = dateTimeValue.startDate;
      this.meetingStartTime = dateTimeValue.startTime;
      this.meetingEndDateTime = dateTimeValue.endDateTime;
      this.meetingEndDate = dateTimeValue.endDate;
      this.meetingEndTime = dateTimeValue.endTime;
      this.meetingDuration = dateTimeValue.duration;
    }

    this.meetingStartDateField.inputText = this.meetingStartDate;
    this.meetingStartDateField.startDateTimeValue = this.meetingStartDateTime;
    this.meetingStartDateField.endDateTimeValue = this.meetingEndDateTime;

    this.meetingStartTimeField.inputText = this.meetingStartTime;
    this.meetingStartTimeField.startDateTimeValue = this.meetingStartDateTime;
    this.meetingStartTimeField.endDateTimeValue = this.meetingEndDateTime;

    this.meetingEndDateField.inputText = this.meetingEndDate;
    this.meetingEndDateField.startDateTimeValue = this.meetingStartDateTime;
    this.meetingEndDateField.endDateTimeValue = this.meetingEndDateTime;

    this.meetingEndTimeField.inputText = this.meetingEndTime;
    this.meetingEndTimeField.startDateTimeValue = this.meetingStartDateTime;
    this.meetingEndTimeField.endDateTimeValue = this.meetingEndDateTime;

    //-----------------API call - Update Meeting with Recommended DateTime-----------------//
    this.trackingService.tracking('MeetingTimeUpdate', TrackingEventNames.ACTIVITY)
    this.activityService.selectedActivity.scheduledStart = new Date(this.meetingStartTime);
    this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(scheduledStart.toDate());
    this.activityService.selectedActivity.scheduledEnd = new Date(this.meetingEndTime);

    this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(scheduledEnd.toDate());
    let currentAppointmentActivity = this.activityService.selectedActivity;
    let payload = new UpdateMeetingPayload(
      currentAppointmentActivity.subject,
      currentAppointmentActivity.location,
      currentAppointmentActivity.scheduledStart,
      currentAppointmentActivity.scheduledEnd,
      currentAppointmentActivity.notes,
      true
    );
    // this.activityService.isActivityUpdate = true;

    await this.uiService.displayLoader();

    await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
    if (this.activityService.selectedActivity instanceof AppointmentActivity && (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {

      if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;

        this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
    this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, false, true)
      .then(() => {
        this.uiService.dismissLoader();

        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;
        this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
      })
      .catch(e => {
        this.uiService.dismissLoader();

        console.error('ActivitiesDetailsPaneComponent: onDismiss: ', e);
        this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
      });
    //-----------------API call - Update Meeting with Recommended DateTime-----------------//

    // Calc Best time again after Updated meeting
    // this.calculateBestRecommendedTime();
  }

  public async openPicklist(event, fieldName, id) {
    const selectedConfigField = this.getOptionSetField(fieldName);
    const prevSelectedValue = this.getOptionSetValue(fieldName);
    const isMultipleSelectionCase = (id === 'appConfig-MultiSelectPicklist-field');
    let optionValues = this.activityService.getConfigFieldsValues(ActivityType.Appointment, fieldName);
    if (!optionValues.length) {
      console.error('No options available for this custom field in appointment.');
      return;
    }

    optionValues.sort((a,b) => (a.label && a.label.toLowerCase()) > (b.label && b.label.toLowerCase()) ? 1 : -1);

    this.selectedCFOValues = optionValues;
    let objects = optionValues.map(option => {
      let obj = {
        title: option.label,
        id: option.label,
        isSelected: false,
        value: option.value
      };
      if (isMultipleSelectionCase) {
        if (selectedConfigField && selectedConfigField.multipleLabels && selectedConfigField.multipleLabels.includes(option.label)) {
          obj['isSelected'] = true;
        } else if (prevSelectedValue && prevSelectedValue === option.label) {
          obj['isSelected'] = true;
        }
      } else {
        if (prevSelectedValue && prevSelectedValue === option.label) {
          obj['isSelected'] = true;
        }
      }
      return obj;
    });
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'appConfigPickValues-select',
      data: objects,
      isMultipleSelectionEnabled: isMultipleSelectionCase,
      showCancelandDoneBtn: isMultipleSelectionCase
    };
    // if (isMultipleSelectionCase) {
    //   if (selectedConfigField && selectedConfigField.fieldLabel) {
    //     dropdownListDetail.pageTitle = selectedConfigField.fieldLabel;
    //   }
    // }
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    })
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (this.backgroundUploadInProgress) return;
      if (isMultipleSelectionCase) {
        if (data && data.data) {
          this.handleMultiOptionSelectPopoverDismiss(data.data);
        }
      } else if(this.authService.user.unsolicitedMeetingLimitPeriod && this.authService.user.unsolicitedMeetingLimitTotal && this.isSolicitatedMeetingComplianceCheckEnabled && fieldName == 'indskr_hcpinteraction' && data && data.data && data.data.selectedItems && data.data.selectedItems[0].value == 548910001){
        this.displayUnsolicitedMeetingConfirmation(data);
        this.appointmentConfigFields.find(conField => conField.fieldName == 'indskr_hcpinteraction').isEmptyRequiredField = false;
      } else if (data && data.data && data.data.selectedItems) {
          // This is for single selection.
          let selectedPopOverValue = data.data.selectedItems[0].value;
          let selectedValue = this.selectedCFOValues.filter(option => { return option.value === selectedPopOverValue });
          if (selectedValue && selectedValue.length) {
            this.appConfigPickValue = selectedValue[0];
          }
          this.selectedCFOValues = [];
          this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
          this.updateAppConfigInputValues(event, fieldName, 'String', selectedPopOverValue, true);
        } else {
          let fieldValue = this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).value;
          if(!fieldValue) this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
        }
      // this.initConfigFieldsSetup();
    });
  }

   private _getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  private async displayUnsolicitedMeetingConfirmation(data){
    let count = 0;
    (this.activityService.selectedActivity as AppointmentActivity).contacts.filter(a=> !a.isguest).forEach( async contact=>{
      let contactData = this.activityService.unsolicitedMeetingsData.find(a=> a.contactid == contact.ID);
      let remainingCalls:any = this.authService.user.unsolicitedMeetingLimitTotal - 1;
      let dateAfterAdjustment = addDays(new Date(),this.authService.user.unsolicitedMeetingLimitPeriod)
      let formattedDate:string = this._getFormattedAndLocalisedDate(dateAfterAdjustment);
      if(contactData){
        let count = 0;
        for(var i=0;i< contactData.appointments.length;i++){
          const appoint = contactData.appointments[i];
          if(isBefore(addDays(appoint.scheduledend,this.authenticationOfflineService.user.unsolicitedMeetingLimitPeriod),new Date().setHours(23,59,59))){
            count++;
          }else{
            break;
          }
        }
        if(count > 0){
          contactData.appointments.splice(0,count);
          await this.activityDataService.updateUnsolicitedMeetingDataOfflineDB();
        }
        remainingCalls = this.authService.user.unsolicitedMeetingLimitTotal - contactData.appointments.length - 1;

        let dateAfterAdjustment = addDays(new Date(contactData.appointments[0].scheduledend),this.authService.user.unsolicitedMeetingLimitPeriod);

        formattedDate = this._getFormattedAndLocalisedDate(dateAfterAdjustment);
      }else {
        let dateAfterAdjustment = addDays(new Date((this.activityService.selectedActivity as AppointmentActivity).scheduledEnd),this.authService.user.unsolicitedMeetingLimitPeriod);
        formattedDate = this._getFormattedAndLocalisedDate(dateAfterAdjustment);
      }
      if(remainingCalls == 0){
        remainingCalls = 'no';
      }
      let msg = this.translate.instant('REMAINING_UNSOLICITATED_CALLS_MESSAGE',{number:remainingCalls,customerName:contact.fullName,date:formattedDate});
      if(remainingCalls < 0){
        msg = this.translate.instant('OVER_LIMIT_UNSOLICITATED_CALLS_MESSAGE',{customerName:contact.fullName,date:formattedDate});
      }
      await this.alertService.showAlert({
        title: this.translate.instant('SOLICITATION_COMPLIANCE'),
        message: this.translate.instant(msg),
        buttons: [{ text:this.translate.instant('OK'), role: 'ok' }],
        backdropDismiss : false,
      }, this.translate.instant('OK')
      ).then(async res => {
        if (res.role === 'ok') {
          count++;
        }
        if (count == (this.activityService.selectedActivity as AppointmentActivity).contacts.filter(a=> !a.isguest).length) {
          let selectedPopOverValue = data.data.selectedItems[0].value;
          let selectedValue = this.selectedCFOValues.filter(option => { return option.value === selectedPopOverValue });
          if (selectedValue && selectedValue.length) {
            this.appConfigPickValue = selectedValue[0];
          }
          this.selectedCFOValues = [];
          this.updateAppConfigInputValues(event, 'indskr_hcpinteraction', 'String', selectedPopOverValue, true);
        }
      });
    });
    if ((this.activityService.selectedActivity as AppointmentActivity).contacts.filter(a => !a.isguest).length == 0) {
      let selectedPopOverValue = data.data.selectedItems[0].value;
      let selectedValue = this.selectedCFOValues.filter(option => { return option.value === selectedPopOverValue });
      if (selectedValue && selectedValue.length) {
        this.appConfigPickValue = selectedValue[0];
      }
      this.selectedCFOValues = [];
      this.updateAppConfigInputValues(event, 'indskr_hcpinteraction', 'String', selectedPopOverValue, true);
    }
  }

  public openOwner(event, fieldName) {
    if (event && event == 'input_value_confirm') {
      this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
      if (this.appConfigInputText != '') {
        this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
        this.updateAppConfigInputValues(event, fieldName, 'String');
      } else {
        this.appointmentConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
      }
    }
  }

  onPeriodEndDateFieldClick(event, fieldName) {
    this.appConfigFieldDatePicker.open();
    this.dateEvent = event;
    this.dateFieldName = fieldName;
  }

  private updateDateField() {
    if (this.appConfigDateValue && this.formattedDate) {
      this.updateConfigDate(this.dateEvent, this.dateFieldName, this.appConfigDateValue);
    }
  }

  public getAppontmentsFormField(appConfigField: any): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {

      viewData = {
        label: appConfigField.fieldLabel,
        inputText: this.getInputText(appConfigField.fieldType, appConfigField.value, appConfigField.fieldName),
        inputValue: this.getInputText(appConfigField.fieldType, appConfigField.value, appConfigField.fieldName),
        placeholderLabel: this.activityService.selectedActivity.state != MeetingActivityState.Completed ? this.getplaceholderLabel(appConfigField.fieldType, appConfigField.fieldLabel) : this.getPlaceHolderForCompletedAct(appConfigField.fieldType, appConfigField.fieldLabel),
        id: getConfigFormFieldViewDataModelId(appConfigField.fieldType),
        isReadOnly: getConfigFormFieldIsReadOnly(appConfigField.fieldType, appConfigField.readOnly),
        isDisabled: this.backgroundUploadInProgress || !(this.isNotReadOnly) || (this.isSolicitatedMeetingComplianceCheckEnabled && appConfigField.fieldName == 'indskr_hcpinteraction' && ( this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0),
        showArrow: this.isNotReadOnly && !(this.isSolicitatedMeetingComplianceCheckEnabled && appConfigField.fieldName == 'indskr_hcpinteraction' && (this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0),
        formFieldType: ((appConfigField.fieldName === 'indskr_activitytype' || appConfigField.fieldName === 'indskr_activitysubtype')) ? FormFieldType.NEW_PAGE_SELECT : getConfigFormFieldType(appConfigField.fieldType),
        isRequired: (this.isNotReadOnly && appConfigField.mandatory == "true" && !this.backgroundUploadInProgress && !(this.isSolicitatedMeetingComplianceCheckEnabled && appConfigField.fieldName == 'indskr_hcpinteraction' && ( this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0)) ? true : false,
        errorMessage: appConfigField.mandatory == "true" && !(this.isSolicitatedMeetingComplianceCheckEnabled && appConfigField.fieldName == 'indskr_hcpinteraction' && ( this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0)? this.activityService.getErrorMessageRequiredField() : undefined,
        isEmptyRequiredField: appConfigField.isEmptyRequiredField,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName, appConfigField.fieldName, appConfigField.fieldLabel),
      };
    }
    return viewData;
  }

  private setOfflineDataForConfigFields() {
    if (this.appointmentConfigFields.length > 0 && this.activityService.selectedActivity.appConfigFields.length > 0) {
      this.activityService.selectedActivity.appConfigFields.forEach(o => {
        let fieldName = o.fieldName
        this.appointmentConfigFields.map(obj => {
          if (obj.fieldName === fieldName) {
            if (o.value !== undefined || o.value !== "") {
              obj.value = o.value;
              this.isValueUpdated = true;
            }
            if (o.selectedValues !== undefined || o.selectedValues !== "") {
              obj.selectedValues = o.selectedValues;
              obj.hasMultipleValues = o.hasMultipleValues;
              obj.multipleLabels = o.multipleLabels;
              obj.multipleValues = o.multipleValues;
              this.isValueUpdated = true;
            }
          }
        })
      })
    } else {
      this.isValueUpdated = false;
      this.initConfigFieldsSetup();
    }
  }


  private getOptionSetValue(fieldName: string): string {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          if (field.hasMultipleValues) {
            let count = (field.multipleLabels && field.multipleLabels.length) ?? 0;
            if (count > 1) {
              return (field.multipleLabels[0] + ' +' + (count-1));
            } else {
              return field.selectedValues;
            }
          } else {
            return field.selectedValues;
          }
        }
      }
    }
    return '';
  }

  private getLookupValue(fieldName: string): string {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          if (field.stringValue) {
            return field.stringValue;
          }
        }
      }
    }
    return '';
  }

  private getOptionSetField(fieldName: string): ConfiguredFields {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          return field;
        }
      }
    }
    return null;
  }

  private getInputText(fieldType, fieldValue, fieldName?) {
    switch (fieldType) {
      case 'String':
        this.updateInputStringValue(fieldName, fieldValue);
        return this.appConfigInputText;
      case 'Uniqueidentifier':
        this.appConfigProcessValue = fieldValue;
        return this.appConfigProcessValue;
      case 'Boolean':
        if (fieldValue === undefined) {
          this.appConfigInputBoolValue = "";
        } else {
          this.appConfigInputBoolValue = (fieldValue === true) ? 'Yes' : 'No';
        }
        return this.appConfigInputBoolValue;
      case 'DateTime':
        if (fieldValue != undefined) {
          this.formattedDate = fieldValue ? format(new Date(parseInt(fieldValue.toString())), this.dateTimeFormatsService.dateTimeToUpper) : this.formattedDate;
        }
        return this.formattedDate;
      case 'Lookup':
        return this.getLookupValue(fieldName);
      case 'Picklist':
        return this.getOptionSetValue(fieldName);
      case 'Virtual':
        return this.getOptionSetValue(fieldName);
      case 'Owner':
        return this.activityService.selectedActivity.meetingOwnerName;
      case 'Currency':
        this.appConfigCurrencyValue = fieldValue;
        return this.appConfigCurrencyValue;
      case 'Integer':
        this.appConfigIntegervalue = fieldValue;
        return this.appConfigIntegervalue;
      case 'Decimal':
        this.appConfigDecimalvalue = fieldValue;
        return this.appConfigDecimalvalue;
      case 'Memo':
        if (fieldValue !== undefined) {
          this.appConfigMemovalue = fieldValue;
        } else {
          this.appConfigMemovalue = '';
        }
        return this.appConfigMemovalue;
      default:
        break;
    }
  }

  private updateInputStringValue(fieldName, fieldValue) {
    this.appConfigInputText = fieldValue;
  }

  public isCheckMandatoryFieldValidationIsFailed(): boolean {
    let fieldNames: string[] = [];
    let count = 0;
    let inputValues: any[] = [];
    let appConfigFields: ConfiguredFields[] = this.appointmentConfigFields.filter(reqField => (reqField.mandatory === 'true' && reqField.readOnly === false && reqField.fieldName != hcpInteractionDefaultField.fieldName ));

    if (this.activityService.selectedActivity && this.activityService.selectedActivity.appConfigFields) {
      let inActivityAppConfigFields = this.activityService.selectedActivity.appConfigFields.filter(reqField => { return (reqField.mandatory === 'true' && reqField.readOnly === false) });
      if (inActivityAppConfigFields && inActivityAppConfigFields.length) {
        appConfigFields.forEach(cf => {
          let key = cf.fieldName;
          if (key) {
            let inActivityAppConfigFieldValue = inActivityAppConfigFields.filter(cf => { return cf.fieldName === key });
            if (inActivityAppConfigFieldValue && inActivityAppConfigFieldValue.length) {
              let first = inActivityAppConfigFieldValue[0];
              if (first.value && first.value !== '') {
                inputValues.push({
                  'key': key,
                  'value': first.value
                });
              } else if (first.selectedValues && first.selectedValues !== '') {
                inputValues.push({
                  'key': key,
                  'value': first.selectedValues
                });
              }
            }
          }
        });
      }
    }
    if (appConfigFields.length) {
      appConfigFields.forEach(configuredField => {
        let key = configuredField.fieldName;
        if (key) {
          let result = inputValues.filter(r => { return r.key === key });
          if (!result.length) {
            fieldNames.push(configuredField.fieldLabel.toLowerCase());
            count = count + 1;
          }
        }
      });
    }
    if (count > 0) {
      this.configMandatoryField = fieldNames.join(', ');
      return true;
    } else {
      return false;
    }
  }


  private getplaceholderLabel(fieldType, fieldLabel) {
    let appConfigInputText
    switch (fieldType) {
      case 'String':
        appConfigInputText = fieldLabel;
        break;
      case 'Uniqueidentifier':
        appConfigInputText = fieldLabel;
        break;
      case 'Boolean':
        appConfigInputText = fieldLabel;
        break;
      case 'DateTime':
        appConfigInputText = fieldLabel
        break;
      case 'Lookup':
        appConfigInputText = fieldLabel
        break;
      case 'Picklist':
        appConfigInputText = fieldLabel
        break;
      case 'Virtual':
        appConfigInputText = fieldLabel
        break;
      case 'Owner':
        appConfigInputText = fieldLabel
        break;
      case 'Currency':
        appConfigInputText = fieldLabel
        break;
      case 'Integer':
        appConfigInputText = fieldLabel
        break;
      case 'Memo':
        appConfigInputText = fieldLabel
        break;
      case 'EntityName':
        appConfigInputText = fieldLabel
        break;
      case 'Decimal':
        appConfigInputText = fieldLabel
      default:
        break;
    }
    return appConfigInputText;
  }

  getPlaceHolderForCompletedAct(fieldType, fieldLabel) {
    let appConfigInputText
    switch (fieldType) {
      case 'String':
        appConfigInputText = `${this.translate.instant('NO_EXIST')}${fieldLabel}`;
        break;
      case 'Uniqueidentifier':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`;
        break;
      case 'Boolean':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`;
        break;
      case 'DateTime':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Lookup':
        appConfigInputText = `${this.translate.instant('NO_EXIST')} ${fieldLabel}`
        break;
      case 'Picklist':
        appConfigInputText = `${this.translate.instant('NO_EXIST')} ${fieldLabel}`
        break;
      case 'Virtual':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Owner':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Currency':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Integer':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Memo':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'EntityName':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Decimal':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
      default:
        break;
    }
    return appConfigInputText;
  }

  private handleFormFieldEvent(id, event, eventName, fieldName?, fieldLabel?) {
    if (id) {
      //during background upload, allow account/contact in read only
      if (id !== 'account-field' && id !== 'contact-field' && (this.backgroundUploadInProgress)) return;
      // Prevent account visit offline request
      if (this.activityService.accountVisitOfflineCheck(
        {
          isAccountVisitEnabled: this.isAccountVisitEnabled,
          isAccountVisitRecord: this.isAccountVisitRecord,
          isAccountVisitNestedMeeting: this.isAccountVisitNestedMeeting,
        },
        true,
      )) {
        return;
      }

      this.events.publish(EventName.ACTIVITY_DETAILS_INTERACTION);
      switch (id) {
        case 'subject-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.openSubjectPopover(event);
          }
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'location-field':
          this.openLocationModal();
          break;
        case 'account-field':
          this.openAccounts();
          break;
        case 'account-plans-field':
          this._handleAddAccountPlans(event);
          break;
        case 'contact-field':
          this.openContacts();
          break;
        case 'covisitor-field':
          this.openAccompainedUsers();
          break;
        case 'appConfig-text-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Lookup-field':
          this.openLookup(event,fieldName);
          break;
        case 'appConfig-Picklist-field':
          this.openPicklist(event, fieldName, id);
          break;
        case 'appConfig-MultiSelectPicklist-field':
          this.openPicklist(event, fieldName, id);
          break;
        case 'appConfig-Owner-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.openOwner(event, fieldName);
          }
          break;
        case 'appConfig-Currency-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Integer-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigIntegervalue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'Int');
          }
          break;
        case 'appConfig-Memo-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigMemovalue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-EntityName-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Uniqueidentifier-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigProcessValue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'Number');
          }
          break;
        case 'appConfig-Boolean-field':
          this.openBooleanPopover(event, fieldName);
          break;
        case 'appConfig-DateTime-field':
          this.onPeriodEndDateFieldClick(event, fieldName);
          break;
        case 'meeting-type-field':
          this.selectMeetingType(event);
          break;
        case 'meeting-activityType-field':
            if(event && eventName && eventName != undefined) {
              this.selectMeetingActivityType(event);
            }
          break;
        case 'meeting-activitySubType-field':
          this.selectMeetingSubActivityType(event);
          break;
        case 'covisitor-compliance':
          this.onCovisitorComplianceChange(event);
          break;
        case 'marketing-business-plan-field':
          this.openMarketingPlans();
          break;
        case 'coaching-plan-field':
          this.openCoachingPlans();
          break;
        case DateTimeFieldType.TimeDurationField:
          this.selectDuration(event);
          break;
        case 'meeting-outcome-field':
          this.handleMeetingOutcomeField(event);
          break;
        case 'competitor-products-field':
          this.handleCompetitorProductSelect();
          break;
        case 'is-meeting-linked-mbp-field':
          this.handleIsMeetingMBPSelect(event);
          break;
        case 'kit-booking-field':
          this.openKitBooking();
          break;
        default:
          break;
      }
    }
  }

  private async handleIsMeetingMBPSelect(event) {
    const activity = this.activityService.selectedActivity as AppointmentActivity;
    const isValueTrue = activity && activity.hasLinkedToMarketingBusinessPlan == true ? true : false;
    const viewData: IndDropdownListDetailModel = {
      id: 'is_mbp-select',
      data: [
        {
          id: 'yes',
          title: this.translate.instant('YES'),
          isSelected: isValueTrue,
        },
        {
          id: 'no',
          title: this.translate.instant('NO'),
          isSelected: !isValueTrue,
        }
      ],
      isMultipleSelectionEnabled: false,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData },
      cssClass: 'dropdown-list-view',
      event,
    });
    await dropdownPopover.present();

    const selectionData = await dropdownPopover.onDidDismiss();
    if (
      Array.isArray(selectionData?.data?.selectedItems)
      && selectionData?.data?.selectedItems[0]
    ) {

      const selectedData = selectionData.data.selectedItems[0];
      const isSelectedValueTrue: boolean = selectedData.id === 'yes';
      let payload :any = { hasLinkedToMarketingBusinessPlan : isSelectedValueTrue };

      // if (activity?.hasLinkedToMarketingBusinessPlan !== isSelectedValueTrue) {
      activity.hasLinkedToMarketingBusinessPlan = isSelectedValueTrue;
      this.meetinglinkedtoamarketingbusinessplanField.inputText = this.getIsMeetingMBPFormattedText(activity);

      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(activity.ID)) {
        activity.hasLinkedToMarketingBusinessPlan = isSelectedValueTrue;
        if (!isSelectedValueTrue && activity.marketingBusinessPlanId) {
          // this.meetingDataService.updateMeetingMarketingBusinessPlanOffline(activity.ID, "");
          this.updateMarketBusinessPlanInMeeting("", activity);
        }
        this.activityService.upsertMeetingsOfflineData(activity);
        return;
      }

      if (!isSelectedValueTrue && activity.marketingBusinessPlanId) {
        payload = {
          ...payload,
          indskr_marketingbusinessplan: ""
        }
      }

      const response = await this.meetingDataService.updateMeetingMarketingBusinessPlanOnline(
        'update', payload, activity.ID);
      if (!response) return;
      if (!isSelectedValueTrue && activity.marketingBusinessPlanId) {
        this.updateMarketBusinessPlanInMeeting("", activity);
      }
      this.activityService.upsertMeetingsOfflineData(activity);
      // }

    }
  }

  private getIsMeetingMBPFormattedText = (dataObj: AppointmentActivity): string => {
    let formattedText = this.translate.instant('NO');
    try {
      if (
        dataObj.hasLinkedToMarketingBusinessPlan === true
      ) {
        formattedText = this.translate.instant('YES');
      }
    } catch (error) {
      console.error('getIsMeetingMBPFormattedText')
    }
    return formattedText;
  }

  private async selectDuration(event) {
    const popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: {
          id: 'duration-field',
          data: _.range(1, 61).map(option => {
            return { title: option == 60 ? '1 Hour' : `${option} Minute${option > 1 ? 's' : ''}`, id: option.toString(), isSelected: _.isEqual(option, this.activityService.selectedActivity.durationInMinutes) }
          })
        }
      },
      event: event
    });
    await popover.present();
    const data = (await popover.onDidDismiss())?.data?.selectedItems[0];
    if (this.backgroundUploadInProgress) return;
    if (data) {
      let selectedDuration = parseInt(data.id);
      if (!_.isEqual(this.activityService.selectedActivity.durationInMinutes, selectedDuration)) {
        const startTime = format(new Date(this.activityService.selectedActivity.scheduledStart));
        const endTime = format(addMinutes(this.activityService.selectedActivity.scheduledStart, selectedDuration));
        const prevStartTime = startTime;
        const prevEndTime = endTime;

        this.updateMeetingDate({
          startTime,
          endTime,
          prevStartTime,
          prevEndTime,
        }, false);
        this.activityService.selectedActivity.resetScheduledStartLocale();
        let dateTimeValue = this.activityService.getFormattedDateTimeText();
        if (dateTimeValue) {
          this.meetingEndTime = dateTimeValue.endTime;
        }
      }
    } else {
      if(!this.meetingDurationField.inputValue) this.meetingDurationField.isEmptyRequiredField = true;
    }
  }

  private async selectMeetingSubActivityType(event) {
    if (_.isEmpty(this.activityService.selectedActivity.indskr_activitytype)) return;
    const activitySubTypes = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, this.activityService.selectedActivity.indskr_activitytype);
    if (_.isEmpty(activitySubTypes)) {
      this.notificationService.notify(this.translate.instant("FORMAT_DETAILS_NOT_CONFIGURED"), 'Meeting Details');
      return;
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'meeting-activityType-field',
      data: activitySubTypes.map(option => {
        return { title: option.indskr_name, id: option.indskr_activitysubtypeid.toString(), isSelected: _.isEqual(option.indskr_activitysubtypeid, this.activityService.selectedActivity.indskr_activitysubtype) }
      })
    };
    const popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'subject-popover', event: event });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      this.isClickedFormatDetails = true;
      if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
        if (this.backgroundUploadInProgress) return;
        const selectedActivityType = data.data.selectedItems[0];
        if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
          const selectedActivitySubType = data.data.selectedItems[0];
          if (!_.isEqual(this.activityService.selectedActivity.indskr_activitysubtype, selectedActivitySubType.id)) {
            const payload = { indskr_activitysubtype: selectedActivitySubType.id, activitySubTypeName: selectedActivitySubType.title };
            await this.meetingDataService.updateActivityTypeAndActivitySubType((this.activityService.selectedActivity as AppointmentActivity), payload, ActivityTypeCodeRaw.Appointment, "indskr_activitysubtype");
            this.activityService.selectedActivity.modified = new Date();
          }
        }
      } else {
        if(!this.activitySubTypeFormField.inputValue) this.activitySubTypeFormField.isEmptyRequiredField = true;
      }
    });
  }

  private async selectMeetingActivityType(event) {
    const data = this.meetingActivityTypes.map(option => {
      return { title: option.indskr_name, id: option.indskr_activitytypeid.toString(), isSelected: _.isEqual(option.indskr_activitytypeid, this.activityService.selectedActivity.indskr_activitytype) }
    });
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'meeting-activityType-field',
      data,
    };
    const popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view2', event: event });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      this.isClickedFormat = true;
      if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
        if (this.backgroundUploadInProgress) return;
        const previousSelectedActivityType=this.activityService.selectedActivity.activityTypeName
        const selectedActivityType = data.data.selectedItems[0];
        if (!_.isEqual(this.activityService.selectedActivity.indskr_activitytype, selectedActivityType.id)) {
          await this.meetingSubjectAutomateForAcino(selectedActivityType.title);
          const defaultActivitySubType = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, selectedActivityType.id).find(ast => ast.indskr_default);
          let currentActivity:AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity
          const arrayActivityToUpdate : AppointmentActivity[] = []
          currentActivity.indskr_activitytype = selectedActivityType.id
          currentActivity.activityTypeName = selectedActivityType.title
          currentActivity.indskr_activitysubtype = defaultActivitySubType ? defaultActivitySubType.indskr_activitysubtypeid : null
          currentActivity.activitySubTypeName = defaultActivitySubType ? defaultActivitySubType.indskr_name : null
          if (this.isAccountVisitRecord) {
            if (selectedActivityType.title === this.translate.instant("INSTORE")) {
              // check selected A/B Level Accounts
              if(this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_applyabaccountlevelfilter']){
                this.activityService.selectedActivity['accounts'].some(itemAcct => {
                  if (itemAcct.accountLevel !='548910000' && itemAcct.accountLevel !='548910001') {
                    this.notificationService.notify(this.translate.instant('NON_AB_LEVEL_ACCOUNT') , 'Meeting Details', 'top', ToastStyle.INFO);
                    this.selectedContacts.length=0
                    this.activityService.selectedActivity['accounts'] =[]
                    currentActivity.location = ""
                    const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(currentActivity.ID);
                    arrayActivityToUpdate.push(
                      ...nestedMeetings.map(a => {
                        return new AppointmentActivity({
                          _id: a._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + a.ID,
                          _rev: a._rev ?? undefined,
                          activityid: a.ID,
                          offlineMeetingId: a.offlineMeetingId,
                          subject: a.subject,
                          scheduledstart: a.scheduledStart,
                          scheduledend: a.scheduledEnd,
                          indskr_parentcallid: a.indskr_parentcallid,
                          stateCode: 2,
                        });
                      })
                    );

                    return true
                  }
                })
              }
            }
            //Subject = Account Name - Activity - Format
            currentActivity['subject'] = (_.isEmpty(currentActivity.accountString)
              ? '' :
              (currentActivity.accountString + ' - ')) +
              this.translate.instant('ACCOUNT_VISIT') + ' - ' +
              selectedActivityType.title;
          } else {
            if (!this.isAccountVisitNestedMeeting && this.secondaryInfoService.isAppointmentSecInfoConfigured) {
              //Subject = HCP Name - Activity - Format - Rep Name
              if(this.secondaryInfoService.checkIfSchemaIsConfig('indskr_ActivityType', SecondaryInfoEntityName.Appointment))
                currentActivity['subject'] = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
            }
            else if (!this.isAccountVisitNestedMeeting && this.isAutoSubjectEnabled) {
              //Subject = HCP Name - Activity - Format - Rep Name
              currentActivity['subject'] =
                (_.isEmpty(currentActivity.contacts)
                  ? ''
                  : this.contactService.getFullNameForContactDisplaySequence(currentActivity.contacts[0]) + ' - ') +
                this.translate.instant('MEETING') +
                ' - ' +
                selectedActivityType.title +
                ' - ' +
                currentActivity.meetingOwnerName;
            }
          }
          if (this.isInStoreMeeting || !_.isEmpty(this.activityService.selectedActivity['inStorePhotos'])) {
            await this.meetingDataService.updateInStorePhotos(this.activityService.selectedActivity['inStorePhotos'].map(photo => { return { photoAttachmentId: photo.indskr_photoattachmentid, deleted: true } }));
          }
          arrayActivityToUpdate.push(currentActivity);
          await this.uiService.displayLoader({ duration: 30000 });
          if (!this.device.isOffline) {
            if(this.isAccountVisitRecord) await this.meetingDataService.crudMeetingsOnlineWithOfflineUploadEndpoint(arrayActivityToUpdate);
            else {
              const payload = {
                indskr_activitytype: selectedActivityType.id,
                activityTypeName: selectedActivityType.title,
                indskr_activitysubtype: defaultActivitySubType ? defaultActivitySubType.indskr_activitysubtypeid : null,
                activitySubTypeName: defaultActivitySubType ? defaultActivitySubType.indskr_name : null
              }
              await this.meetingDataService.updateActivityTypeAndActivitySubType((this.activityService.selectedActivity as AppointmentActivity), payload, ActivityTypeCodeRaw.Appointment, "indskr_activitytype");
              if (this.secondaryInfoService.isAppointmentSecInfoConfigured || this.isAutoSubjectEnabled) {
                let payload = new UpdateMeetingPayload(
                  this.activityService.selectedActivity.subject
                );
                await this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload);
              }
            }
          } else {
            await this.activityService.upsertMeetingsOfflineData(currentActivity, false, true);
          }
          currentActivity.modified = new Date();
          await this.uiService.dismissLoader();
          this.footerService.initButtons(FooterViews.Activities)
          this.isGpsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)
          this.isLiveTimeFieldEnabledForF2FMeetings =this.authService.hasFeatureAction(FeatureActionsMap.HIDE_LIVETIME_FOR_F2F_MEETINGS) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)
          this.isContentRequiredForRemoteChannelEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_REASON) && this.activityService.isContentRequiredForRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity)?.indskr_activitytype)
          this.initPhotosSectionHeader();
          this.initFormFields();
          this.initPhotosSectionHeader();
        }
      } else {
        if(!this.activityTypeFormField.inputValue) this.activityTypeFormField.isEmptyRequiredField = true;
      }
    });
  }

  async meetingSubjectAutomateForAcino(formatTitle) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
      let subject = this.activityService.selectedActivity.subject;
      let contactFormattedSubject = '';
      let formatContactFormattedSubject = '';
      if (this.activityService.selectedActivity['contacts'] && this.activityService.selectedActivity['contacts'].length) {
        contactFormattedSubject = `${this.translate.instant('VISIT')} - ${this.contactService.getFullNameForContactDisplaySequence(this.activityService.selectedActivity['contacts'][0])}`
      }
      if (this.activityService.selectedActivity.activityTypeName) {
        formatContactFormattedSubject = `${this.activityService.selectedActivity.activityTypeName} ${contactFormattedSubject ? contactFormattedSubject : this.translate.instant('VISIT')} `
      }
      if (_.isEqual(subject, this.translate.instant('VISIT')) || _.isEqual(subject, contactFormattedSubject.trim()) || _.isEqual(subject, formatContactFormattedSubject.trim())) {
        subject = `${formatTitle} ${contactFormattedSubject ? contactFormattedSubject : this.translate.instant('VISIT')}`;
        await this.meetingSubjectUpdate(subject);
      }
    }
  }

  private async selectMeetingType(event) {

    const activity: AppointmentActivity = <AppointmentActivity>this.activityService.selectedActivity;

    const prevSelectedValue = activity.indskr_meetingtype;

    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'meeting-type-select',
      data: this.activityService.meetingTypes.map(option => {
        let obj = {
          title: option.type,
          id: option.value.toString(),
          isSelected: false,
        };
        if (prevSelectedValue === option.value) {
          obj['isSelected'] = true;
        }
        return obj;
      }),
    };
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    })
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data && data.data && data.data.selectedItems && data.data.selectedItems.length == 1 && data.data.selectedItems[0].id != activity.indskr_meetingtype) {
        if (this.backgroundUploadInProgress) return;
        const seletectedMeetingType = parseInt(data.data.selectedItems[0].id);
        let payload = new UpdateMeetingPayload(
          this.activityService.selectedActivity.subject,
          this.activityService.selectedActivity.location,
          this.activityService.selectedActivity.scheduledStart,
          this.activityService.selectedActivity.scheduledEnd
        );
        payload.indskr_meetingtype = seletectedMeetingType;
        await this.uiService.displayLoader();
        if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
          try {
            await this.meetingDataService.updateMeeting(activity, payload, true);
          } catch (error) {
            console.error("Failed to update meeting type, resetting: error: ", error);
            const isSuccess = await this.activityService.upsertMeetingsOfflineData(activity, false, true);
            if (!isSuccess) {
              console.error("Failed to update meeting type, resetting")
              activity.indskr_meetingtype = prevSelectedValue;
              const errorMessage = error.errorId ? error.errorId : error.message;
              this.notificationService.notify(this.translate.instant('UPDATING_MEETING_TYPE_FAILED') + errorMessage, 'Meeting Details', 'top', ToastStyle.DANGER);
            }
          }
        }
        else {
          activity.indskr_meetingtype = seletectedMeetingType;
          const isSuccess = await this.activityService.upsertMeetingsOfflineData(activity);
          if (!isSuccess) {
            console.error("Failed to update meeting type in offline data, resetting")
            activity.indskr_meetingtype = prevSelectedValue;
          }
        }
        await this.uiService.dismissLoader();
      }
      this.isClickedMeetingTypes = true;
    });
  }

  private initActivitiesDetailsPageTitle(): void {
    let buttons = [];
    let reopenBtn = null;
    let isCancelEnabled = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_cancelmeetingwithreason'];

    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ASSIGN_MEETING) && !this.isFromChat){
      buttons.push({
          id: "assign-owner",
          imgSrc: 'assets/imgs/assign_owner_white.svg',
          name: this.translate.instant('ASSIGN'),
          isDisabled: this.device.isOffline || (this.activityService.selectedActivity as AppointmentActivity).state !== 0 || (this.activityService.selectedActivity.ownerId !== this.authService.user.xSystemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS) || this.activityService.selectedActivity.status === 4),
          align: "right",
      })
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_REOPEN)) {
      const disabled = !this.isSelectedActivityReopenable;
      reopenBtn = {
        id: "reopen",
        imgSrc: 'assets/imgs/header_activity_reopen.svg',
        name: this.translate.instant('REOPEN'),
        isDisabled: disabled,
        align: "right"
      }
    }
    if (this.enableBackButton && !this.isFromChat) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    } else if(this.isFromChat) {
      buttons.push({
        id: 'close',
        icon: 'close-outline',
        isDisabled: false,
        align: "left"
      })
    }
    if (this.uiService.showCancelDoneOnActivityDetails || this.activityService.selectedActivity.isFromXperiences) {
      buttons.push(
        {
          id: "close",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
        });
        if (reopenBtn) {
          buttons.push(reopenBtn);
        }
        buttons.push({
          id: "scrap",
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: !this.isScrapButtonEnabled || this.activityService.selectedActivity.status === 4,
          align: "right"
        },
        {
          id: "markComplete",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('COMPLETE'),
          isDisabled: this.canNotComplete || this.activityService.selectedActivity.status === 4,
          align: "right"
        }
      );
    } else if (this.isLiveMeet) {
      buttons.push({
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapButtonEnabled || this.activityService.selectedActivity.status === 4,
        align: "right"
        }
      );
    }
    else {
      if (reopenBtn) {
        buttons.push(reopenBtn);
      }
      buttons.push({
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: isCancelEnabled ? this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER') : this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapButtonEnabled || this.activityService.selectedActivity.status === 4,
        align: "right"
      });
      if (!(this.activityService.selectedActivity['isJointmeeting'] && (!this.activityService.isMeetingOwner && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS)))) {
        buttons.push({
          id: "markComplete",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('COMPLETE'),
          isDisabled: this.canNotComplete || this.activityService.selectedActivity.status === 4,
          align: "right"
        });
      }
    }
    this.activitiesDetailsPanePageTitle = {
      id: 'activities-details-page-title',
      title: this.initializeheaderTitle(),
      controls: buttons,
      enableTooltipForTitle: this.secondaryInfoService.isAppointmentSecInfoConfigured
    };
    console.log("this.activityService.selectedActivity.subject", this.activityService.selectedActivity.subject)

    // // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
    // if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
    //   this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
    // }
    // // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
  }

  private get canNotComplete() {
    return this.activityService.selectedActivity
      && (
      (!this.activityService.isMeetingOwner && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS))
        || this.activityService.selectedActivity.state == this.meetingState.Completed
        || this.enableReadonlyViewForMeetings
        || this.backgroundUploadInProgress
        || (
          // Account visit meeting does not support offline yet
          this.device.isOffline
          && (
            this.isAccountVisitRecord
            || this.isAccountVisitNestedMeeting
          )
        )
      );
  }

  private canNotAppeal() {
    let isDisabled = true;

    const isMeetingCompleted = this.activityService.selectedActivity
      && (!this.activityService.isMeetingOwner
        || this.activityService.selectedActivity.state == this.meetingState.Completed
        || this.activityService.selectedActivity.state == this.meetingState.Canceled
        || this.activityService.teamViewActive
        || this.backgroundUploadInProgress
        || (
          // Account visit meeting does not support offline yet
          this.device.isOffline
          && (
            this.isAccountVisitRecord
            || this.isAccountVisitNestedMeeting
          )
        )
      );

      if(isMeetingCompleted && !this.device.isOffline) {
        const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;

        if(currentMeeting.gpsCheckInDetails && !_.isEmpty(currentMeeting.gpsActivityPhotos)
        && (currentMeeting.gpsCheckInDetails.statuscode === GPSActivityApprovalStatus.DRAFT
          || !currentMeeting.gpsCheckInDetails.statuscode)
        && currentMeeting.gpsCheckInDetails.indskr_checkinstatus === GPS_STATUS.INVALID && currentMeeting.gpsCheckInDetails.indskr_checkoutstatus === GPS_STATUS.INVALID) {
          isDisabled = false;
        }

      }
      return isDisabled;
  }


  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
      case 'close':
        if(this.isFromChat) this.modalCtrl.dismiss();
        else this.closepage();
        break;
      case 'done':
        this.markNewActivityOkay();
        break;
      case 'scrap':
        this.handleMeetingScrap();
        break;
      case 'markComplete':
        this.markComplete(true);
        break;
      case 'reopen':
        this.reopenMeeting();
        break;
      case 'assign-owner':
        this.openUsersList();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private opportunitiesSectionHeader() {
    this.opportunitiesSectionTitle = {
      id: 'opportunities',
      title: this.translate.instant('OPPORTUNITIES'),
      controls: [
        {
          id: 'add-opportunities',
          text: this.translate.instant('ADD'),
          isDisabled: !(!this.activityService.selectedActivity.isCompleted && !this.isReadOnlyRecommendedMeeting && !this.enableReadonlyViewForMeetings  && this.authService.hasFeatureAction(FeatureActionsMap.ASSOCIATE_OPPORTUNITIES_TO_MEETINGS)) ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID
        }
      ]
    };
  }

  private initObjectivesSectionHeader() {
    this.objectiveSectionHeader = {
      id: 'objectives',
      title: this.translate.instant('OBJECTIVES'),
      // isRequired: true,
      controls: [
        {
          id: 'add-objectives',
          text: this.translate.instant('ADD'),
          isVisible: this.isMeetingObjectiveSelectionFAEnabled,
          isDisabled: this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.enableReadonlyViewForMeetings
        }
      ]
    };
  }

  private initGpsActivityPhotosSectionHeader() {
    this.gpsActivityPhotosSectionHeader = {
      id: 'gpsActivityPhotos',
      title: this.translate.instant('CHECK_IN_CHECK_OUT_PHOTOS_CAP'),
      controls: [
        {
          id: 'appeal',
          text: this.translate.instant('APPEAL'),
          isVisible: true,
          isDisabled: this.canNotAppeal()
        }
      ]
    };
  }

  private initEventsSectionHeader() {
    this.eventsSectionHeader = {
      id: 'meetingevents',
      title:  this.translate.instant('EVENTS'),
      controls: [
        {
          id: 'add-meetingevents',
          text: this.translate.instant('ADD'),
          isDisabled: !(!this.activityService.selectedActivity.isCompleted && !this.isReadOnlyRecommendedMeeting && !this.enableReadonlyViewForMeetings  && this.authService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_MEETINGS)) ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled
        }
      ]
    }
  }

  private initProceduresSectionHeader() {
    this.proceduresSectionHeader = {
      id: 'procedures',
      title: this.translate.instant('PROCEDURES'),
      controls: [
        {
          id: 'add-procedures',
          text: this.translate.instant('ADD'),
          isDisabled: this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.enableReadonlyViewForMeetings,
        }
      ]
    }
  }

  private initSupportingMaterialsSectionHeader() {
    this.supportingMaterialsSectionHeader = {
      id: 'supportingmaterials',
      title: this.translate.instant('SUPPORTING_MATERIALS'),
      controls: [
        {
          id: 'add-supportingmaterials',
          text: this.translate.instant('ADD'),
          isDisabled: this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.enableReadonlyViewForMeetings,
        }
      ]
    }
  }

  private initPhotosSectionHeader() {
    const activityTypeFieldText = this.activityService.selectedActivity.activityTypeName
      || this.meetingActivityTypes?.find(t => t.indskr_activitytypeid === this.activityService.selectedActivity.indskr_activitytype)?.indskr_name;
    this.isInStoreMeeting = this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting && activityTypeFieldText === this.translate.instant("INSTORE");
    if (this.isInStoreMeeting) {
      this.photosSectionHeader = {
        id: 'photos',
        title: this.translate.instant('IN_STORE_PHOTOS'),
        controls: [
          {
            id: 'add-photos',
            text: this.translate.instant('ADD'),
            isDisabled: this.backgroundUploadInProgress
              || this.device.isOffline
              || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)
              || _.isEmpty(this.activityService.selectedActivity['accounts'])
              || this.activityService.selectedActivity['inStorePhotos']?.length >= 10
              || !this.activityService.isMeetingOwner || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.enableReadonlyViewForMeetings,
          }
        ]
      }
    }
  }

  private _handleAddOpportunities() {
    const accountIds = this.activityService.selectedActivity['accounts'].map(el => el.id)
    const contactIds = this.activityService.selectedActivity['contacts'].map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => accountIds.includes(item.accountID) || contactIds.includes(item.contactID) || this._checkIfOppHasTheseContacts(contactIds,item));
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.activityService.selectedActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection: true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);
  }

  private _checkIfOppHasTheseContacts(contactIds:Array<string>,opp:Opportunity):boolean{
    let flag = false;
    for(let i=0;i<opp.stakeholders.length;i++){
      const stakeholder = opp.stakeholders[i];
      if(contactIds.includes(stakeholder.ID)){
        flag=true;
        break;
      }
    }
    return flag;
  }

  private _handleAddAssets() {
    const assets = this.applicableAssets.filter(asset => !asset.noaccess);
    const opportuntiesSelectionViewData: AssetSelectionViewDataModel = {
      viewMode: this.activityService.selectedActivity.isCompleted ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
      selectedAssets: assets,
      callbackFromSelectionComponent: (event: string, selectedAssets: CustomerAsset[], data: any) => this._handledAssetsSelection(event, selectedAssets, data),
    };
    this.uiService.prevView = this.uiService.activeView;
    this.navService.pushWithPageTracking(FieldMaterialManagementPageComponent, PageName.FieldMaterialManagementPageComponent, { viewData: opportuntiesSelectionViewData }, PageName.FieldMaterialManagementPageComponent);
  }

  private async _handleAddAccountPlans(ev) {
    const accountIds = this.activityService.selectedActivity['accounts'].map(el => el.id)
    let accountPlanDataSet = [];
    if (accountIds.length) {
      this.accountManagementService.accountPlans.filter(ap => Number(ap.endDate) > this.activityService.selectedActivity.scheduledEnd.getTime() || !ap.endDate).forEach((e) => {
        if (accountIds.includes(e.accountId)) {
          accountPlanDataSet.push(e)
        }
      });
    } else {
      accountPlanDataSet = this.accountManagementService.accountPlans.filter(ap => Number(ap.endDate) > this.activityService.selectedActivity.scheduledEnd.getTime() || !ap.endDate);
    }
    if (accountPlanDataSet.length)
      accountPlanDataSet = this.sortListByFielName(_.uniqBy(accountPlanDataSet,'ID'), 'accountPlanName');
    const accountPlansSelectionViewData: AccountPlansSelectionPageModel = {
      isReadOnlyMode: this.activityService.selectedActivity.isCompleted,
      selectedAccPlans: this.applicableAccountPlans,
      selectableAccPlans: this.activityService.selectedActivity.isCompleted ? [] : accountPlanDataSet,
      disableDoneButtonInOffline: false,
      viewMode: this.activityService.selectedActivity.isCompleted ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
      callbackFromSelectionComponent: (event: string, newSelectedAccountPlans: Array<AccountPlan>, data: any) => this.handleAccountPlanSelection(event, newSelectedAccountPlans, data),
    };
    this.navService.pushWithPageTracking(AccountManagementPageComponent, PageName.AccountManagementPageComponent, { viewData: accountPlansSelectionViewData }, PageName.AccountManagementPageComponent);
  }

  async handleAccountPlanSelection(event, newSelectedAccPlans: Array<AccountPlan>, data: any) {
    if (event && event == 'DONEBUTTONCLICK') {
      let accPlansPayload = [];
      newSelectedAccPlans.forEach(a => {
        accPlansPayload.push(a.ID);
      });
      const payload = accPlansPayload;
      this.applicableAccountPlans = [];
      accPlansPayload.forEach(item => {
        const foundAccountPlan = this.accountManagementService.getAccountPlanByPlanId(
          item
        );
        this.applicableAccountPlans.push(foundAccountPlan);
      });
      const origAccountPlans = (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans;
      if (this.applicableAccountPlans.length) {
        (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans = this.applicableAccountPlans.map(a => {
          return {
            accountPlanId: a.ID,
            accountPlanName: a.accountPlanName,
          }
        });
      } else {
        (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans = [];
      }
      await this.uiService.displayLoader();

      // Account visit
      if (
        this.isAccountVisitRecord
        || this.isAccountVisitNestedMeeting
      ) {
        let shouldRevert = false;
        try {
          const meeting: AppointmentActivity = this.activityService.getActivityByID(this.activityService.selectedActivity.ID) as AppointmentActivity;
          shouldRevert = !await this.meetingDataService.crudMeetingsOnlineWithOfflineUploadEndpoint(
            [meeting],
          );
          if (!shouldRevert) {
            this.activityService.selectedActivity = meeting;
          }
        } catch (error) {
          console.error('handleAccountPlanSelection: ', error);
          this.notificationService.notify(
            this.translate.instant('XPERIENCES_ERROR'),
            'Activity Detail',
            'top',
            ToastStyle.DANGER,
          );
          // Revert
          shouldRevert = true;
        }
        if (shouldRevert) {
          (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans = origAccountPlans;
        }
        await this.uiService.dismissLoader();
        return;
      }

      await this.meetingDataService
        .addAccountPlansToMeeting(
          this.activityService.selectedActivity as AppointmentActivity,
          payload
        )
        .then(async (opportunities) => {
          await this.uiService.dismissLoader();
        });
    }
  }

  private _handleAddEvents() {
    const eventsSelectionViewData: EventsSelectionViewDataModel = {
      isReadOnlyMode: this.activityService.selectedActivity.isCompleted,
      eventsDataSet: [],
      selectedEvents: this.applicableEvents,
      disableDoneButtonInOffline: false,
      // accessedFromMeeting: true,
      viewMode: ComponentViewMode.ADDNEW,
      callbackFromSelectionComponent: (event: string, newSelectedEvents: Array<EventActivity>, data: any) => this._handledEventsSelection(event, newSelectedEvents, data),
    };
    this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, { viewData: eventsSelectionViewData }, PageName.EventsToolPageComponent);
  }

  private _handledOpportunitiesSelection(event, newSelectedOpportunities: Array<Opportunity>, data: any) {
    if (event && event == 'DONEBUTTONCLICK') {
      let opportuntiesPayload = [];
      newSelectedOpportunities.forEach(a => {
        opportuntiesPayload.push(a.ID);
      });
      const payload = opportuntiesPayload;
      this.applicableOpportunities = [];
      opportuntiesPayload.forEach(item => {
        let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(item);
        this.applicableOpportunities.push(foundOpportunity);
      });
      if (this.applicableOpportunities.length) {
        (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities = this.applicableOpportunities.map(a => {
          return {
            opportunityId: a.ID,
            opportunityName: a.opportunityName,
          }
        });
      } else {
        (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities = [];
      }
      this.uiService.displayLoader();
      this.meetingDataService.addOpportunitiesToMeeting(this.activityService.selectedActivity as AppointmentActivity, payload).then(async (opportunities) => {
        this.uiService.dismissLoader();
      });
    }
  }

  private async _handledAssetsSelection(event, selectedAssets: CustomerAsset[], data: any) {
    if (event && event == 'DONEBUTTONCLICK') {
      const assets = this.applicableAssets.filter(asset => !asset.noaccess);
      let newAssets = [];
      let removedAssets = [];
      if (_.isEmpty(assets) && !_.isEmpty(selectedAssets)) {
        newAssets = selectedAssets;
      } else if (!_.isEmpty(assets) && _.isEmpty(selectedAssets)) {
        removedAssets = selectedAssets;
      } else {
        removedAssets = _.intersectionBy(assets, selectedAssets, 'msdyn_customerassetid');
        newAssets = _.differenceBy(selectedAssets, removedAssets, 'msdyn_customerassetid');
      }
      this.uiService.displayLoader();
      await this.fieldMaterialManagementService.mapAssetsToMeeting(newAssets, removedAssets, this.activityService.selectedActivity?.ID).then(() => {
        this.uiService.dismissLoader();
        this.initMeetingAssets();
      }).catch(err=> {
        console.log("Error while mapping assets to meeting", err);
        this.uiService.dismissLoader();
      });
    }
  }

  private _handledEventsSelection(event, newSelectedEvents: Array<EventActivity>, data: any) {
    if (event && event == 'DONEBUTTONCLICK') {
      let eventsPayload = [];
      newSelectedEvents.forEach(a => {
        eventsPayload.push(a.ID);
      });
      const payload = eventsPayload;
      this.applicableEvents = [];
      eventsPayload.forEach(item => {
        let foundEvent = this.eventsToolService.getEventDetailsbyId(item);
        this.applicableEvents.push(foundEvent);
      });
      if (this.applicableEvents.length) {
        (this.activityService.selectedActivity as AppointmentActivity).associatedEvents = this.applicableEvents.map(a => {
          return {
            eventId: a.ID,
            name: a.name,
          }
        });
      } else {
        (this.activityService.selectedActivity as AppointmentActivity).associatedEvents = [];
      }
      this.uiService.displayLoader();
      this.meetingDataService.addEventsToMeeting(this.activityService.selectedActivity as AppointmentActivity, payload).then(async (events) => {
        this.uiService.dismissLoader();
      });
    }
  }


  private openTeamsMeeting() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      const activity = this.activityService.selectedActivity;
      const url = activity.indskr_teamslink;
      if (!url) return;
      this.websocket.removeSubscription(activity);
      this.activityDataService.updateTeamsMeetingSubject(activity.subject, activity.indskr_teams_meeting_id);
      if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
        if(this.device.deviceFlags.ios) {
          this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
        }else {
          this.inAppBrowser.create(url, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
        }
      } else {
        if(this.device.deviceFlags.electron) {
          electronApi.openExternal(url);
        }else {
          window.open(url, "_system");
        }
      }
    }
  }

  /**
     * @description This method will save additional Config
     *
    */
  private pushAdditionalConfigOffline(payload) {
    if (this.activityService.selectedActivity.appConfigFields !== undefined) {
      let removeIndex = this.activityService.selectedActivity.appConfigFields.map(function (item) { return item.fieldName; }).indexOf(payload.fieldName);
      if (removeIndex > -1) {
        this.activityService.selectedActivity.appConfigFields.splice(removeIndex, 1);
      }
      this.activityService.selectedActivity.appConfigFields.push(payload);
    }
  }

  /**
    * @description This method will keep listen for touch end event.
   */
  //  @HostListener('touchend', ['$event.target'])
  //  onDifferentFocus(target) {
  //     this.events.publish(EventName.ACTIVITY_DETAILS_INTERACTION);
  //  }


  onFooterButtonClicked(buttonId: string) {
    // Prevent account visit offline request
    if (this.activityService.accountVisitOfflineCheck(
      {
        isAccountVisitEnabled: this.isAccountVisitEnabled,
        isAccountVisitRecord: this.isAccountVisitRecord,
        isAccountVisitNestedMeeting: this.isAccountVisitNestedMeeting,
      },
      true,
    )) {
      return;
    }

    switch (buttonId) {
      case 'message':
        this.sendMeetingURL(null);
        break;
      case 'meetingReopen':
        this.reopenMeeting();
        break;
      case 'teams_meeting':
        this.openTeamsMeeting();
        break;
      case 'meetingAssets':
        this._handleAddAssets();
        break;
      case 'checkin':
        this.checkInForMeeting();
        break;
      case 'checkout':
        this.checkOutMetting();
        break;
      case 'meetingassignOwner':
        this.openUsersList();
        break;
      default:
        break;
    }
  }

  get isSelectedActivityReopenable() {
    return (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS) ? 
    false : this.from == PageName.AccountPlanActivities ? false : this.activityService.selectedActivity.omnip_datecompleted ? 
        !((!this.activityService.isMeetingOwner && (!this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS))
    || this.enableReadonlyViewForMeetings || !this.activityService.selectedActivity.isCompleted || this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)
    || moment().diff(moment(new Date(parseFloat(this.activityService.selectedActivity.omnip_datecompleted))), 'days') >= this.authenticationOfflineService.user.pastMeetingReopenPeriod
    || this.backgroundUploadInProgress) : false;
  }

  refreshPlanToDO() {
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
  }

  checkConflictMsgDisplay(shouldForceCheck = false): boolean {
    const flag = this.isNewlyCreatedActivity || this.shouldCheckConflict || shouldForceCheck;
    if (this.shouldCheckConflict && this.agendaFooterService.actSegment === 'week') {
      this.activityService.publishActivityEvent({ action: 'Update', activity: this.activityService.selectedActivity });
    }
    this.shouldCheckConflict = false;
    const activity = this.activityService.activities.find(a => a.ID === this.activityService.selectedActivity.ID);
    const hasConflict = (flag && activity?.conflictingActivityIds?.size > 0) ? true : false;
    const hasWarning = (this.authService.user.meetingProximityWarningPeriod > 0 && flag && activity?.closeByActivityIds?.size > 0) ? true : false;
    this.conflictMsg = hasWarning ? this.translate.instant('PROXIMITY_MEETING_WARNING') : this.translate.instant('ACTIVITY_CONFLICT_MSG');
    return hasConflict || hasWarning;
  }

  onTimeUpdated() {
    this.shouldCheckConflict = true;
  }
  private checkMandatoryFields() {
    if(this.requiredFields) {
      if(!this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed && !this.requiredFields['contact-field']['isAdded']) {
        this.contactFormField.isEmptyRequiredField = true;
        this.isClickedContacts = true;
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY) || this.isAccountVisitRecord) {
        if(!this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed && !this.requiredFields['account-field']['isAdded']) {
          this.accountFormField.isEmptyRequiredField = true;
          this.isAccountClicked = true;
        }
      }
      if(this.isNotReadOnly && !this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting && this.activityService.meetingTypes.length > 0 && this.requiredFields['meeting-type-field']['isRequired'] && _.isEmpty(this.activityService.getMeetingType((this.activityService.selectedActivity as AppointmentActivity).indskr_meetingtype))) {
        this.meetingTypeFormField.isEmptyRequiredField = true;
        this.isClickedMeetingTypes = true;
      }
      if(this.isNotReadOnly && !_.isEmpty(this.meetingActivityTypes) && this.requiredFields['meeting-activityType-field']['isRequired'] && _.isEmpty(this.activityService.selectedActivity.activityTypeName)) {
        this.activityTypeFormField.isEmptyRequiredField = true;
        this.isClickedFormat = true;
      }
      if(this.isNotReadOnly && !this.isAccountVisitRecord && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING)) && this.requiredFields['meeting-activitySubType-field']['isRequired'] && _.isEmpty(this.activityService.selectedActivity.activitySubTypeName)) {
        this.activitySubTypeFormField.isEmptyRequiredField = true;
        this.isClickedFormatDetails = true;
      }
      if (
        this.isNotReadOnly
        && this.activityService.shouldShowCovisitorComplianceForm(this.activityService.selectedActivity)
        && this.requiredFields['covisitor-compliance']?.['isRequired'] === true
        && this.requiredFields['covisitor-compliance']?.['isAdded'] === false
      ) {
        const formField = this.secondLevelFormFields.find(field => field.id === 'covisitor-compliance');
        if (formField) {
          formField.isEmptyRequiredField = true;
          this.requiredFields['covisitor-compliance']['isEvaluated'] = true;
        }
      }
    }
    this.appointmentConfigFields.forEach(field => {
      if(field.mandatory == 'true') {
        if(!field.value) {
          field.isEmptyRequiredField = true;
        }
        if(field.fieldName == 'indskr_hcpinteraction' && !this.requiredFields['contact-field']['isAdded']) field.isEmptyRequiredField = false;
      }
    });
    this.cdRef.detectChanges();
  }

  private handleMandatoryFieldNotification(): boolean {
    const shouldDisplayMandatoryConfigFormFieldNotification: boolean = this.isCheckMandatoryFieldValidationIsFailed();
    const shouldDisplayCovisitorComplianceMandatoryFieldNotification: boolean = this.activityService.shouldShowCovisitorComplianceForm(this.activityService.selectedActivity)
      && this.requiredFields['covisitor-compliance']?.['isRequired'] === true
      && this.requiredFields['covisitor-compliance']?.['isAdded'] === false;

    let shouldReturn: boolean = shouldDisplayMandatoryConfigFormFieldNotification
      || shouldDisplayCovisitorComplianceMandatoryFieldNotification;

    let covisitorComplianceNotificationFn: () => void =
      shouldDisplayCovisitorComplianceMandatoryFieldNotification
      ? () => {
        this.notificationService.notify(
          this.translate.instant('MEETING_COMPLETE_FAIL_ALERT_COVISITOR_COMPLIANCE'),
          'Meeting Details',
          'top',
          ToastStyle.DANGER
        );
      }
      : null;

    if (shouldDisplayMandatoryConfigFormFieldNotification) {
      this.notificationService.notify(
        this.translate.instant('APP_CONFIG_FIELDS_MESSAGE_MEETING',
        { appConfigFieldName: this.configMandatoryField.toLowerCase() }),
        'Meeting Details',
        'top',
        ToastStyle.DANGER,
        3000,
        true,
        undefined,
        covisitorComplianceNotificationFn,
      );
    } else if (covisitorComplianceNotificationFn) {
      covisitorComplianceNotificationFn();
    }

    return shouldReturn;
  }

  private onCovisitorComplianceChange(event: CheckboxCustomEvent) {
    if (event && event.detail) {
      this.covisitorComplianceAck$.next(!!(event.detail.checked));
    }
  }
  private resetCovisitorComplianceAck() {
    if (
      this.activityService.selectedActivity instanceof AppointmentActivity
      && (this.activityService.selectedActivity as AppointmentActivity)?.covisitorComplianceAcked === true
      && (
        !this.activityService.selectedActivity.accompaniedUserList
        || (
          Array.isArray(this.activityService.selectedActivity.accompaniedUserList)
          && this.activityService.selectedActivity.accompaniedUserList.length === 0
        )
      )
    ) {
      this.covisitorComplianceAck$.next(false);
    }
  }

  /* To check foll.
    1.Feature Action is enabled
    2.Selected Contacts length is 1
    3.scheduledStart isAfter currentTimeStart
  */
    calculateBestRecommendedTime() {
      console.warn(`calculateBestRecommendedTime`);

      /* To check foll.
        1.Feature Action is enabled
        2.Selected Contacts length is 1
        3.showBestTimeRecommendation is enabled for this user
        4.scheduledStart isAfter currentTimeStart
        5.Device should be online
      */
      if (this.isTimeRecommendationEnabled && this.selectedContacts.length === 1
        && this.authService.user.showBestTimeRecommendation && !this.device.isOffline) {
        console.warn(`Best time for a single customer`);

        const scheduledStart = moment(this.activityService.selectedActivity.scheduledStart);
        const currentTimeStart: moment.Moment = moment();

        console.warn(`scheduledStart.isAfter(currentTimeStart)`);
        console.log(scheduledStart.isAfter(currentTimeStart));

        if (scheduledStart.isAfter(currentTimeStart)) {
          this.showTimeRecommendations = true;

          const contactId = this.selectedContacts[0]['ID'];

          this.getPicklistForRecommendedEngagementPeriod();
          this.getRecommendedEngagementPeriodByContactId(contactId);
        }
        else {
          this.showTimeRecommendations = false;

          this.recommendedByCurrentTime = [];
        }
      }
      else {
        console.warn(`isTimeRecommendationEnabled: ${this.isTimeRecommendationEnabled}`);
        console.warn(`selectedContacts.length: ${this.selectedContacts.length}`);
        console.warn(`authService.user.showBestTimeRecommendation: ${this.authService.user.showBestTimeRecommendation}`);

        this.recommendedByCurrentTime = [];
      }
    }

    async closeRecommendedations(){
      this.showTimeRecommendations = false;
      this.authService.user.hideBestTimeRecommendationCount++;

      if(this.authService.user.hideBestTimeRecommendationCount === this.authService.user.bestTimeRecommendationConfig){
        this.notificationService.notify(this.translate.instant('SETTINGS_RECOMMENDATIONS_BEST_TIME_MEETING_WARNING'), "Meeting Details", "top", ToastStyle.INFO, 3000);
        this.authService.user.showBestTimeRecommendation = false;
        this.authService.user.hideBestTimeRecommendationCount = 0;
      }

      await this.activityDataService.updateUserTimeRecommendation();
    }
    // update meeting date and upsert offline data
    private async updateMeetingDate(dateData, isUpdateStartDate: boolean = false) {
      const startTime = dateData.startTime;
      const endTime = dateData.endTime;
      const prevStartTime = dateData.prevStartTime;
      const prevEndTime = dateData.prevEndTime;

      let currentAppointmentActivity = this.activityService.selectedActivity;
      currentAppointmentActivity.scheduledStart = new Date(startTime);
      currentAppointmentActivity.scheduledEnd = new Date(endTime);
      let payload = new UpdateMeetingPayload(
        currentAppointmentActivity.subject,
        currentAppointmentActivity.location,
        currentAppointmentActivity.scheduledStart,
        currentAppointmentActivity.scheduledEnd,
        currentAppointmentActivity.notes,
        false
      );
      try{
      const isUpdated = await this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, false, true, true, isUpdateStartDate)
      // .then(async (isUpdated) => {
        if (isUpdated && isUpdated != undefined) {
          this.activityService.selectedActivity.modified = new Date();
          this.activityService.selectedActivity.scheduledStart = new Date(startTime);
          this.activityService.selectedActivity.scheduledEnd = new Date(endTime);
          this.activityService.selectedActivity.durationInMinutes = differenceInMinutes(this.activityService.selectedActivity.scheduledEnd, this.activityService.selectedActivity.scheduledStart);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(startTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(endTime);
          // upsert offline data
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          this.updateUIWithLatestTime();
        } else {
          // error handling
          if (this.meetingDataService.isErrorStartDateUpdateMeeting) {
            // Do not update the selected date in the case of error due to altering system date
            console.error('IndDatetimeFormComponent: onDismiss: error update meeting - start date');
            this.activityService.selectedActivity.scheduledStart = new Date(prevStartTime);
            this.activityService.selectedActivity.scheduledEnd = new Date(prevEndTime);
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(prevStartTime);
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(prevEndTime);
            const dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
            this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
          } else {
            this.activityService.selectedActivity.scheduledStart = new Date(startTime);
            this.activityService.selectedActivity.scheduledEnd = new Date(endTime);
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(startTime);
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(endTime);
            const dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
            this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
            // upsert offline data
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          }
          if (this.activityService.selectedActivity instanceof AppointmentActivity && (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {
            if (!this.uiService.toolsActivityActive) {
              this.events.publish("refreshAgenda");
            } else this.uiService.agendaRefreshRequired = true;
            this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
          }
          if (this.agendaFooterService.actSegment === 'week') {
            const displayActivity = this.activityService.displayActivities.find(a => a.ID === this.activityService.selectedActivity.ID);
            if (displayActivity) {
              displayActivity.scheduledStart = new Date(currentAppointmentActivity.scheduledStart);
              displayActivity.scheduledEnd = new Date(currentAppointmentActivity.scheduledEnd);
              this.activityService.publishActivityEvent({action: "Update", activity: this.activityService.selectedActivity});
            }
          } else if (!this.uiService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          } else this.uiService.agendaRefreshRequired = true;
          this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
          const dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
          this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
        }
      }catch(error ) {
        console.error('IndDatetimeFormComponent: onDismiss: ', error);
        this.activityService.selectedActivity.scheduledStart = new Date(startTime);
        this.activityService.selectedActivity.scheduledEnd = new Date(endTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(startTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(endTime);
        // upsert offline data
        this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
        const dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
        this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
      };
    }

  private updateUIWithLatestTime() {
    const currentAppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    if (currentAppointmentActivity && (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      } else this.uiService.agendaRefreshRequired = true;
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
    if (this.agendaFooterService.actSegment === 'week') {
      // Date Time is updated on calendar view
      // After the update, calendar view needs to refresh the cell data from displayActivities array
      // Hence, we want to update the record in displayActivities array before publishing an update event
      // We can publish refreshAgenda event but try to update only the record in the array instead
      // because running refreshAgenda is expensive.
      const displayActivity = this.activityService.displayActivities.find(a => a.ID === this.activityService.selectedActivity.ID);
      if (displayActivity) {
        displayActivity.scheduledStart = new Date(currentAppointmentActivity.scheduledStart);
        displayActivity.scheduledEnd = new Date(currentAppointmentActivity.scheduledEnd);
        this.activityService.publishActivityEvent({ action: "Update", activity: this.activityService.selectedActivity });
      }
    } else if (!this.uiService.toolsActivityActive) {
      this.events.publish("refreshAgenda");
    } else this.uiService.agendaRefreshRequired = true;
    this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    const dateTime = { startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd };
    this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
  }

    public notesChanged(ev): void {
      if (this.backgroundUploadInProgress) return;
      if (ev && ev.target) {
        this.tempNoteText = ev.target.value;
        this.isSaveNotesEnabled = true;
      } else {
        this.tempNoteText = '';
        this.isSaveNotesEnabled = true;
      }
      this.initMeetingNotesSectionHeader();
    }

    get nonDeletedNotes() {
      return this.activityService.selectedActivity.meetingNotes.filter(note => !note.isDeleted && (!this.autoSavedNote || note.noteId != this.autoSavedNote.noteId))
    }

    public isNoteControlsEnabled(note: IONote): boolean {
      return (((this.isCovisitor && (this.coVisitorAccess === CovisitorAccess.FULL_ACCESS || this.coVisitorAccess === CovisitorAccess.MINIMUM_ACCESS)) || this.activityService.isMeetingOwner) && note.ownerId == this.authService.user.systemUserID && !this.activityService.selectedActivity?.isCompleted);
    }

    public async saveMeetingNote() {
      if (this.isSaveNotesEnabled && ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str))) {
        this.trackingService.tracking('MeetingNotesUpdate', TrackingEventNames.ACTIVITY);
        const now = new Date().getTime().toString();
        let tempNote = {
          annotationid: 'offlinmeetingnote' + now,
          activityid: this.activityService.selectedActivity.ID,
          createdon: now,
          notetext: this.tempNoteText,
          ownerName: this.authService.user.displayName,
          ownerid: this.authService.user.systemUserID,
          deleted: false,
          pendingPushForDynamics: true,
          isdocument : false
        }

        if (this.base64str) {
          tempNote['isdocument'] = true;
          tempNote['documentbody'] = this.base64str;
          tempNote['filename'] = this.attachmentFile.name;
          tempNote['filesize'] = this.attachmentFile.size;
          tempNote['mimetype'] = this.attachmentFile.type;
        } else {
          tempNote['hasDocument'] = false;
        }
        let currentNote = new IONote(tempNote);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).meetingNotes = this.activityService.selectedActivity.meetingNotes;
        this.activityService.getDisplayctivityByID(this.activityService.selectedActivity.ID).meetingNotes = this.activityService.selectedActivity.meetingNotes;
        this.activityService.selectedActivity.meetingNotes.push(currentNote);
        await this.uiService.displayLoader();
        await this.activityService.updateMeetingNoteInOfflineDB(tempNote);
        if(!this.device.isOffline) {
          await this.meetingDataService.uploadMeetingNotesOnline().then(res => {
            if (res && Array.isArray(res) && res.length>0 && res[0].noteid) {
              currentNote.noteId = res[0].noteid;
            }
          })
        } else {
          if(this.activityService.selectedActivity.ID.includes('offline')) {
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          } else {
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as AppointmentActivity, true);
          }
        }
        this.isSaveNotesEnabled = false;
        this.removeMeetingNoteAttachment(null);
        this.tempNoteText = '';
        this._sortMeetingNotes();
        this.initMeetingNotesSectionHeader();

        await this.uiService.dismissLoader();
      }
    }

    public async addMeetingNote() {
      if ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str)) {
        setTimeout(() => {
          this.autoSavedNote = null;
          this.isSaveNotesEnabled = false;
          this.removeMeetingNoteAttachment(null);
          this.tempNoteText = '';
          this._sortMeetingNotes();
          this.initMeetingNotesSectionHeader();
        },60);
      }
    }

    public async updateNote(ev) {
      if (ev && ev.action) {
        if (this.backgroundUploadInProgress) return;
        const idx = this.activityService.selectedActivity.meetingNotes.findIndex(note => note.noteId === ev.noteId);
        if (idx < 0) {
          console.error("Error : coundn't find note id --> ", ev.noteId);
          return;
        }
        let existingNote = this.activityService.selectedActivity.meetingNotes[idx];
        let offlinePayload: any =  {
          annotationid: existingNote.noteId,
          activityid: this.activityService.selectedActivity.ID,
          createdon: existingNote.createdTime.getTime().toString(),
          notetext: existingNote.noteText,
          ownerName: existingNote.ownerName,
          ownerid: existingNote.ownerId,
          isdocument: existingNote.hasDocument,
          filename: existingNote.documentName,
          mimetype: existingNote.documentMimeType,
          filesize: existingNote.documentSize,
          pendingPushForDynamics: true
        };
        if (existingNote.noteId.includes("offlinmeetingnote") && existingNote.hasDocument && !_.isEmpty(existingNote.documentDataURL)) {
          offlinePayload.documentbody = existingNote.documentDataURL;
        }
        if (ev.action === 'DELETE' && idx >= 0) {
          offlinePayload.deleted = true;
          this.activityService.selectedActivity.meetingNotes.splice(idx, 1);
          if(!_.isEmpty(this.autoSavedNote) && this.autoSavedNote.noteId == ev.noteId) this.autoSavedNote = null;
        } else if (ev.action === 'SAVE' && idx >= 0) {
          existingNote.noteText = offlinePayload.notetext = ev.updatedText;
          if (ev.attachmentFileUpdated) {
            [existingNote, offlinePayload] = this._updateAttachmentinNotes(existingNote, offlinePayload, ev);
          } else if (ev.isAttachmentRemoved) {
            [existingNote, offlinePayload] = this._resetDocumentDetailsOfNote(existingNote, offlinePayload);
          }
        }
        // this.activityService.selectedActivity.meetingNotes[idx] = existingNote;
        // await this.uiService.displayLoader();
        try {
          await this.activityService.updateMeetingNoteInOfflineDB(offlinePayload);
          if(!this.device.isOffline) {
            await this.meetingDataService.uploadMeetingNotesOnline().then(res => {
              if (res && Array.isArray(res) && res.length>0 && res[0].noteid) {
                existingNote.noteId = res[0].noteid;
              }
            })
          } else {
            if(this.activityService.selectedActivity.ID.includes('offline')) {
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
            } else {
              await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as AppointmentActivity, true);
            }
          }
        } catch (error){
          console.error('updateMeetingNote: '+ error);
        }
        // await this.uiService.dismissLoader();
        this.cdRef.detectChanges();
        this.cdRef.markForCheck();
      }
    }

    private _resetDocumentDetailsOfNote(note: IONote, offlinePayload: any) {
      note.documentSize = offlinePayload['filesize'] = 0;
      note.documentName = offlinePayload['filename'] = '';
      note.documentMimeType = offlinePayload['mimetype'] = '';
      note.documentDataURL = offlinePayload['documentbody'] = '';
      note.hasDocument = offlinePayload['isdocument'] = false;
      offlinePayload['fileremoved'] = true;
      return [note, offlinePayload];
    }

    private _updateAttachmentinNotes(note: IONote, offlinePayload: any, ev: any) {
      note.hasDocument = offlinePayload['isdocument'] = true;
      note.documentSize = offlinePayload['filesize'] = ev.documentSize;
      note.documentName = offlinePayload['filename'] = ev.documentName;
      note.documentMimeType = offlinePayload['mimetype'] = ev.documentMimeType;
      note.documentDataURL = offlinePayload['documentbody'] = ev.attachmentFileDataUrl;
      offlinePayload['fileupdated'] = true;
      return [note, offlinePayload];
    }

    private _sortMeetingNotes(): void {
      if (this.activityService.selectedActivity.meetingNotes && this.activityService.selectedActivity.meetingNotes.length > 1) {
        this.activityService.selectedActivity.meetingNotes.sort((a, b) => {
          return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
        })
      }
    }

    private getApplicableAssetsHeirarchyModel() {
      const hashTable = Object.create(null)
      this.applicableAssets = _.orderBy(this.applicableAssets, obj => obj.msdyn_name);
      this.applicableAssets.forEach(aData => hashTable[aData.msdyn_customerassetid] = this.getMainCardDataModel(aData));
      const dataTree = []
      this.applicableAssets.forEach(aData => {
        if (aData.msdyn_parentasset && hashTable[aData.msdyn_parentasset]) {
          hashTable[aData.msdyn_parentasset].showIcon = true;
          hashTable[aData.msdyn_parentasset].noLeftMarginLabel = true;
          hashTable[aData.msdyn_parentasset].childItems.push((hashTable[aData.msdyn_customerassetid]));
          hashTable[aData.msdyn_parentasset].childItems = _.orderBy(hashTable[aData.msdyn_parentasset].childItems, obj => obj.primaryTextRight);
        } else {
          dataTree.push(hashTable[aData.msdyn_customerassetid]);
        }
      })
      this.applicableAssetsModel = dataTree;
    }

    private getMainCardDataModel(asset: CustomerAsset) {
      let viewData: MainCardViewDataModel;
      viewData = {
        id: asset.msdyn_customerassetid,
        fixedHeight: true,
        primaryTextLeft: '',
        primaryTextRight: asset.msdyn_name,
        secondaryTextRight: asset.indskr_serialnumber ? this.translate.instant("SERIAL_NUMBER") + " : " + asset.indskr_serialnumber : '',
        secondaryTextRightTwo: '',
        isMainToolTemplate: true,
        childItems: [],
        iconName: 'chevron-forward-outline',
        isExpanded: false,
        isNotAutoExpanded: true,
        parentAsset: asset.msdyn_parentasset,
        mainItemCssClass: 'asset selector-item',
        showMoreInfo: true,
        moreInfo: [{
          label: this.translate.instant("CATEGORY"),
          value: asset.categoryName
        }, {
          label: this.translate.instant("PRODUCT"),
          value: asset.productName
        }, {
          label: this.translate.instant("STATUS"),
          value: asset.indskr_assetstatus
        }],
        clickHandler: async (id: string, event, specificTarget, refData) => {
          event?.stopPropagation();
          if (specificTarget == 'startIcon') {
            let card = this.findInDepth(id, this.applicableAssetsModel);
            card.isExpanded = !card.isExpanded;
            card.iconName = card.isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline';
          } else if (specificTarget == 'endIcon') {
            await this.alertService.showAlert({
              title: this.translate.instant('ASSET_REMOVE_TITLE'),
              message: this.translate.instant('ASSET_REMOVE_MSG')
            }, this.translate.instant('REMOVE')
            ).then(async res => {
              if (res.role === 'ok') {
                const found = this.applicableAssets.find(a => a.msdyn_customerassetid == id);
                if (found) {
                  let removedAssets = [];
                  removedAssets.push(found);
                  let card = this.findInDepth(id, this.applicableAssetsModel);
                  if (card.childItems) {
                    const childCards = this.flattenTree(card.childItems);
                    childCards.forEach(c => {
                      let childAsset = this.applicableAssets.find(a => a.msdyn_customerassetid == c.id);
                      if (childAsset) {
                        removedAssets.push(childAsset);
                      }
                    });
                  }
                  this.uiService.displayLoader();
                  await this.fieldMaterialManagementService.mapAssetsToMeeting([], removedAssets, this.activityService.selectedActivity?.ID).then(() => {
                    this.uiService.dismissLoader();
                    this.initMeetingAssets();
                  }).catch(err => {
                    console.log("Error while mapping assets to meeting", err);
                    this.uiService.dismissLoader();
                  });
                }
              }
            });
          }
        },
      };
      if (!this.isReadOnlyMeeting && !this.device.isOffline) {
        viewData.showEndIcon = true;
        viewData.endIconType = 'indegene-selectors-remove-icon';
        viewData.endIconCssClass = 'remove-icon';
      }
      return viewData;
    }

  private findInDepth(id: string, array: any[]) {
    let result;
    array.some(o => o.id === id && (result = o) || (result = this.findInDepth(id, o.childItems || [])));
    return result;
  }

  async checkInForMeeting() {
    const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;
    const isMeetingValidDay = isAfter(new Date(), new Date(currentMeeting.scheduledStart)) && isBefore(new Date(), new Date(currentMeeting.scheduledEnd)) || differenceInCalendarDays(new Date(currentMeeting.scheduledStart), new Date()) == 0
    let distance;
    let gpsCheckInStatus;

    if(this.device.isOffline){
      this.notificationService.notify(this.translate.instant('NETOWRK_UNAVAILABLE_CHECKIN'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      return;
    }

    if(this.checkinProcess) return;
    this.checkinProcess = true;

    if (!isMeetingValidDay) {
      this.notificationService.notify(this.translate.instant('CHECKIN_DIFFERENT_DAY'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      this.checkinProcess = false;
      return;
    }

    await this.uiService.displayLoader({duration : 30000});
    const currentCoordinates: any = await this.locationService.getCurrentLocation();

    this.isManualCheckinProcess = false;

    if (currentCoordinates.latitude <= 0) {
      this.notificationService.notify(this.translate.instant('UNABLE_TO_GET_LOCATION'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      await this.uiService.dismissLoader();
      this.checkinProcess = false;

      // if isManualGPSCheckinEnabled is ON then force user to do manual checkin incase of UNABLE_TO_GET_LOCATION
      if (this.isManualGPSCheckinEnabled && this.locationService.isLocationEnabledOnDevice) {
        let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', this.translate.instant('MANUAL_CHECKIN_DISTANCE_WARNING'),'MANUAL_CHECKIN');
        if (!confirmedManualCheckIn) {
          this.checkinProcess = false;
          return;
        };
        this.isManualCheckinProcess = true;
        gpsCheckInStatus = GPS_STATUS.INVALID;
      }
      else
        return;
    };

    // if meeting doesn't have latitude and logitude
    if (!currentMeeting.indskr_meetinglocationlatitude && !currentMeeting.indskr_meetinglocationlongitude) {
      const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityService.selectedActivity['accounts'][0].id);
      if (selectedAddress && selectedAddress.longitude && selectedAddress.latitude) {
        currentMeeting.location = selectedAddress.compositeAdd;
        currentMeeting.indskr_meetinglocationlongitude = selectedAddress.longitude;
        currentMeeting.indskr_meetinglocationlatitude = selectedAddress.latitude;
      } else {
        const coordinatesFromAddress = await this.locationService.convertAddressToCoords(currentMeeting.location);
        if (coordinatesFromAddress) {
          currentMeeting.indskr_meetinglocationlongitude = coordinatesFromAddress.longitude;
          currentMeeting.indskr_meetinglocationlatitude = coordinatesFromAddress.latitude;
        } else if(this.isManualGPSCheckinEnabled) {
          await this.uiService.dismissLoader();
          // if isManualGPSCheckinEnabled is ON then force user to do manual checkin incase of INVALID_ADDRESS
          let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', this.translate.instant('MANUAL_CHECKIN_DISTANCE_WARNING'),'MANUAL_CHECKIN');
          if (!confirmedManualCheckIn) {
            this.checkinProcess = false;
            return;
          };
          this.isManualCheckinProcess = true;
          gpsCheckInStatus = GPS_STATUS.INVALID;
        }
        else {
          this.notificationService.notify(this.translate.instant('INVALID_ADDRESS'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
          await this.uiService.dismissLoader();
          this.checkinProcess = false;
          return;
        }
      }
      await this.meetingDataService.updateMeetingLocation(currentMeeting);
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, currentMeeting.indskr_meetinglocationlatitude,  currentMeeting.indskr_meetinglocationlongitude, 'M');
      // const coordinatesFromAddress = await this.locationService.convertAddressToCoords(currentMeeting.location);
      // if (coordinatesFromAddress) {
      //   currentMeeting.indskr_meetinglocationlongitude = coordinatesFromAddress.longitude;
      //   currentMeeting.indskr_meetinglocationlatitude = coordinatesFromAddress.latitude;
      //   this.meetingDataService.updateMeetingLocation(currentMeeting);
      //   distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, coordinatesFromAddress.latitude, coordinatesFromAddress.longitude, 'M');
      // } else {
      //   this.notificationService.notify(this.translate.instant('INVALID_ADDRESS'), 'Check In', 'top', ToastStyle.DANGER, 3000);
      //   await this.uiService.dismissLoader();
      //   this.checkinProcess = false;
      //   return;
      // }
    } else {
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, currentMeeting.indskr_meetinglocationlatitude, currentMeeting.indskr_meetinglocationlongitude, 'M');
    }
    await this.uiService.dismissLoader();

    if(!this.isManualCheckinProcess) {
      if (distance > this.authService.user.validCallDistance) {

        if (this.isManualGPSCheckinEnabled) {
          const fixedDistance = (distance / 1000).toFixed(2);
          const alertMsg = this.translate.instant(this.isAccountVisitRecord ? 'MANUAL_CHECKIN_WITH_DISTANCE_WARNING_AC' :'MANUAL_CHECKIN_WITH_DISTANCE_WARNING', { distance: fixedDistance });
          let confirmedManualCheckIn = await this.showManualGPSConfirmAlert('AUTO_CHECK_IN_FAILED', alertMsg,'MANUAL_CHECKIN');
          if (!confirmedManualCheckIn) {
            this.checkinProcess = false;
            return;
          };
          this.isManualCheckinProcess = true;
          gpsCheckInStatus = GPS_STATUS.INVALID;
        } else {
          let confirmedCheckIn = await this.showCheckInDistanceAlert((distance / 1000).toFixed(2));
          if (!confirmedCheckIn) {
            this.checkinProcess = false;
            return;
          };
          gpsCheckInStatus = GPS_STATUS.INVALID;
        }

      } else {
        let confirmedCheckIn = await this.showCheckInConfirmAlert();
        if (!confirmedCheckIn) {
          this.checkinProcess = false;
          return;
        };
        gpsCheckInStatus = GPS_STATUS.VALID;
      }
    }

    let payload: any = {
      indskr_checkinlongitude: currentCoordinates.longitude,
      indskr_checkinlatitude: currentCoordinates.latitude,
      indskr_checkindatetime: new Date(),
      indskr_checkindistance: distance,
      indskr_checkinstatus: gpsCheckInStatus
    }

    if(this.isManualGPSCheckinEnabled && this.isManualCheckinProcess) {
      this.openPhotoCaptureViewerForGPSActivity(GPSActivity.CHECKIN,payload)
    } else {
      await this.uiService.displayLoader();

      const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(currentMeeting, payload);
      if (!response) {
        await this.uiService.dismissLoader();
        this.checkinProcess = false;
        return;
      }

      currentMeeting.gpsCheckInDetails['indskr_checkinstatus'] = response.indskr_checkinstatus ?? gpsCheckInStatus;
      currentMeeting.gpsCheckInDetails['indskr_checkindatetime'] = response.indskr_checkindatetime ?? payload.indskr_checkindatetime;
      currentMeeting.gpsCheckInDetails['indskr_gpscheckindetailsid'] = response.indskr_gpscheckindetailsid;
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as AppointmentActivity, true);
      await this.uiService.dismissLoader();
      this.footerService.initButtons(FooterViews.Activities);
      this.initFormFields();
      this.checkinProcess = false;
      this.notificationService.notify(this.translate.instant('GPS_CHECKIN_SUCESS'), 'Check In', 'top', ToastStyle.INFO, 3000);
    }
  }

  async checkOutMetting() {
    const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;
    const isMeetingToday = differenceInCalendarDays(new Date(currentMeeting.scheduledStart), new Date()) == 0;
    let gpsCheckOutStatus;
    let distance;

    const checkoutDateTime = new Date();
    // GPS check out min duration reminder
    if (currentMeeting.gpsCheckInDetails?.indskr_checkindatetime) {
      const { durationLimitInMin, actualDurationInMin, shouldAlert } = this.activityService.validateGpsCheckOutMinDurationViolation(
        new Date(currentMeeting.gpsCheckInDetails?.indskr_checkindatetime),
        checkoutDateTime,
      );

      if (shouldAlert) {
        if (!await this.activityService.showGPSCheckOutMinDurationReminder(durationLimitInMin, actualDurationInMin)) {
          return;
        }
      }
    }

    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('NETOWRK_UNAVAILABLE_CHECKOUT'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
      return;
    }
    // check GPS check-in duration
    if (this.isInStoreMeeting && this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_MR)) {
      let minutesDifference = differenceInMinutes(new Date(),new Date(currentMeeting.gpsCheckInDetails.indskr_checkindatetime));
      if(minutesDifference < 15 ){
        let userclicked = await this.alertService.showAlert({
          header: '',
          message: this.translate.instant('FA_INSTORE_CHECK_GPS_DURATION',{N:15})
        }, this.translate.instant('CONTINUE'),  this.translate.instant('CANCEL')
        ).then(async res => {
          if (res.role === 'ok') {
          }else{
            return 'userClickedCancel';
          }
        }).catch((err) => console.log('catch event ! ' + err));
        if(userclicked == 'userClickedCancel') return;
      }
    }
    if (this.isInStoreMeeting && this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_OSS)) {
      let minutesDifference = differenceInMinutes(new Date(),new Date(currentMeeting.gpsCheckInDetails.indskr_checkindatetime));
      if(minutesDifference < 30 ){
        let userclicked = await this.alertService.showAlert({
          header: '',
          message: this.translate.instant('FA_INSTORE_CHECK_GPS_DURATION',{N:30})
        }, this.translate.instant('CONTINUE'),  this.translate.instant('CANCEL')
        ).then(async res => {
          if (res.role === 'ok') {
          }else{
            return 'userClickedCancel';
          }
        }).catch((err) => console.log('catch event ! ' + err));
        if(userclicked == 'userClickedCancel') return;
      }
    }
    if (this.checkinProcess) return;
    this.checkinProcess = true;

    if (!isMeetingToday) {
      this.notificationService.notify(this.translate.instant('CHECKIN_DIFFERENT_DAY'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
      this.checkinProcess = false;
      return;
    }
    await this.uiService.displayLoader({ duration: 30000 });
    const currentCoordinates: any = await this.locationService.getCurrentLocation();

    let confirmedCheckOut;
    this.isManualCheckinProcess = false;

    if (currentCoordinates.latitude <= 0) {
      this.notificationService.notify(this.translate.instant('UNABLE_TO_GET_LOCATION'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
      await this.uiService.dismissLoader();
      this.checkinProcess = false;

      // if isManualGPSCheckinEnabled is ON then force user to do manual checkout incase of UNABLE_TO_GET_LOCATION
      if (this.isManualGPSCheckinEnabled && this.locationService.isLocationEnabledOnDevice) {
        let confirmedManualCheckOut = await this.showManualGPSConfirmAlert('AUTO_CHECK_OUT_FAILED', this.translate.instant('MANUAL_CHECKOUT_DISTANCE_WARNING'),'MANUAL_CHECKOUT');
        if (!confirmedManualCheckOut) {
          this.checkinProcess = false;
          return;
        };
        confirmedCheckOut = CHECKOUT_OPTIONS.CHECKOUT;
        this.isManualCheckinProcess = true;
        gpsCheckOutStatus = GPS_STATUS.INVALID;
      }
      else
        return;
    };

    // if meeting doesn't have latitude and logitude
    if (!currentMeeting.indskr_meetinglocationlatitude && !currentMeeting.indskr_meetinglocationlongitude) {
      const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityService.selectedActivity['accounts'][0].id);
      if (selectedAddress) {
        currentMeeting.location = selectedAddress.compositeAdd;
        currentMeeting.indskr_meetinglocationlongitude = selectedAddress.longitude ? selectedAddress.longitude : null;
        currentMeeting.indskr_meetinglocationlatitude = selectedAddress.latitude ? selectedAddress.latitude : null;
      } else {
        const coordinatesFromAddress = await this.locationService.convertAddressToCoords(currentMeeting.location);
        if (coordinatesFromAddress) {
          currentMeeting.indskr_meetinglocationlongitude = coordinatesFromAddress.longitude;
          currentMeeting.indskr_meetinglocationlatitude = coordinatesFromAddress.latitude;
        } if(this.isManualGPSCheckinEnabled) {
          // if isManualGPSCheckinEnabled is ON then force user to do manual checkin incase of INVALID_ADDRESS
          await this.uiService.dismissLoader();
          let confirmedManualCheckOut = await this.showManualGPSConfirmAlert('AUTO_CHECK_OUT_FAILED', this.translate.instant('MANUAL_CHECKOUT_DISTANCE_WARNING'),'MANUAL_CHECKOUT');
          if (!confirmedManualCheckOut) {
            this.checkinProcess = false;
            return;
          };
          confirmedCheckOut = CHECKOUT_OPTIONS.CHECKOUT;
          this.isManualCheckinProcess = true;
          gpsCheckOutStatus = GPS_STATUS.INVALID;
        } else {
          this.notificationService.notify(this.translate.instant('INVALID_ADDRESS'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
          await this.uiService.dismissLoader();
          this.checkinProcess = false;
          return;
        }
      }
      await this.meetingDataService.updateMeetingLocation(currentMeeting);
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, currentMeeting.indskr_meetinglocationlatitude, currentMeeting.indskr_meetinglocationlongitude, 'M');
    } else {
      distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, currentMeeting.indskr_meetinglocationlatitude, currentMeeting.indskr_meetinglocationlongitude, 'M');
    }

    await this.uiService.dismissLoader();

    if(!this.isManualCheckinProcess) {
      if (distance > this.authService.user.validCallDistance) {

        if (this.isManualGPSCheckinEnabled) {
          const fixedDistance = (distance / 1000).toFixed(2);
          const alertMsg = this.translate.instant(this.isAccountVisitRecord ? 'MANUAL_CHECKOUT_WITH_DISTANCE_WARNING_AC' : 'MANUAL_CHECKOUT_WITH_DISTANCE_WARNING', { distance: fixedDistance });
          let confirmedManualCheckOut = await this.showManualGPSConfirmAlert('AUTO_CHECK_OUT_FAILED', alertMsg,'MANUAL_CHECKOUT');
          if (!confirmedManualCheckOut) {
            this.checkinProcess = false;
            return;
          };
          confirmedCheckOut = CHECKOUT_OPTIONS.CHECKOUT;
          this.isManualCheckinProcess = true;
          gpsCheckOutStatus = GPS_STATUS.INVALID;
        } else {
          confirmedCheckOut = await this.showCheckOutConfirmAlert('CHECKOUT_DISTANCE_WARNING', (distance / 1000).toFixed(2));
          if (!confirmedCheckOut) {
            this.checkinProcess = false;
            return;
          };
          gpsCheckOutStatus =  GPS_STATUS.INVALID;
        }

      } else {
        confirmedCheckOut = await this.showCheckOutConfirmAlert(this.isAccountVisitRecord ? 'CHECKOUT_POSITIVE_AC' : 'CHECKOUT_POSITIVE');
        if (!confirmedCheckOut) {
          this.checkinProcess = false;
          return;
        };
        gpsCheckOutStatus =  GPS_STATUS.VALID;
      }
    }

    let payload: any = {
      indskr_checkoutlongitude: currentCoordinates.longitude,
      indskr_checkoutlatitude: currentCoordinates.latitude,
      indskr_checkoutdatetime: new Date(),
      indskr_checkoutdistance: distance,
      indskr_checkoutstatus: gpsCheckOutStatus,
      indskr_gpscheckindetailsid: currentMeeting.gpsCheckInDetails["indskr_gpscheckindetailsid"]
    }

    if (confirmedCheckOut === CHECKOUT_OPTIONS.CHECKOUT_COMPLETE) {
      await this.uiService.displayLoader();
      const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(currentMeeting, payload);
      if (!response) {
        await this.uiService.dismissLoader();
        this.checkinProcess = false;
        return;
      };

      this.setCheckinCheckoutStatus(currentMeeting, payload);

      const markedComplete = await this.markComplete(true);
      if (!markedComplete) {
        await this.uiService.dismissLoader();
        return;
      };

      await this.activityService.upsertMeetingsOfflineData(currentMeeting);
      this.footerService.initButtons(FooterViews.Activities);
      await this.uiService.dismissLoader();
      this.initFormFields();
      this.notificationService.notify(this.translate.instant('GPS_CHECKOUT_COMPLETE_SUCESS'), 'Check Out', 'top', ToastStyle.INFO, 3000);
    } else if (confirmedCheckOut === CHECKOUT_OPTIONS.CHECKOUT) {
      if(this.isManualGPSCheckinEnabled && this.isManualCheckinProcess) {
        this.openPhotoCaptureViewerForGPSActivity(GPSActivity.CHECKOUT, payload)
      }
      else {
        await this.uiService.displayLoader();
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(currentMeeting.ID, payload);
        await this.uiService.dismissLoader();
        if (!response) {
          this.checkinProcess = false;
          return;
        };
        this.setCheckinCheckoutStatus(currentMeeting, payload);
        this.notificationService.notify(this.translate.instant('GPS_CHECKOUT_SUCESS'), 'Check Out', 'top', ToastStyle.INFO, 3000);
      }
    }
  }

  private async setCheckinCheckoutStatus(currentMeeting,payload ){
    currentMeeting.gpsCheckInDetails['indskr_checkoutstatus'] = payload.indskr_checkoutstatus;
    currentMeeting.gpsCheckInDetails['indskr_checkoutdatetime'] = payload.indskr_checkoutdatetime;
    await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as AppointmentActivity, true);
    this.footerService.hideButton('checkout');
    this.footerService.initButtons(FooterViews.Activities);
    this.initFormFields();
    this.checkinProcess = false;
  }

  async showCheckInConfirmAlert(): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKIN'),
      message :this.translate.instant('CHECKIN_CONFIRM_MESSAGE'),
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return  false;
        },
      },{
        text: this.translate.instant('CHECK_IN_FOOTBAR'),
        role: 'confirm',
        handler: () => {
          return  true;
        },
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm";
    });

  }

  async showCheckInDistanceAlert(distance): Promise<any> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKIN'),
      message : this.translate.instant(this.isAccountVisitRecord ? 'CHECKIN_DISTANCE_WARNING_AC' : 'CHECKIN_DISTANCE_WARNING', { distance }),
      buttons: [{
        text: this.translate.instant('CHECKIN_ANYWAY'),
        role: 'confirm',
        handler: () => {
          return true
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return  false;
        },
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm" ;
    });
  }

  public async _handleLocationComponentCallback(selectedAddress) {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      let meeting = this.activityService.selectedActivity;
      if (selectedAddress == null) {
        meeting.location = "";
        meeting.indskr_meetinglocationlongitude = null;
        meeting.indskr_meetinglocationlatitude = null;
      } else {
        meeting.location = selectedAddress.compositeAdd;
        if (selectedAddress.longitude && selectedAddress.latitude) {
          meeting.indskr_meetinglocationlongitude = selectedAddress.longitude;
          meeting.indskr_meetinglocationlatitude = selectedAddress.latitude;
        }
      }
      await this.meetingDataService.updateMeetingLocation(meeting);
      this.footerService.initButtons(FooterViews.Activities);
    }
  }

  async showCheckOutConfirmAlert(message, distance?) : Promise<any> {
    if (distance) {
      message = this.translate.instant(message, { distance });
    } else {
      message = this.translate.instant(message, {})
    }

    const alert = await this.alertCtrl.create({
      header: this.translate.instant('CONFIRM_CHECKOUT'),
      message,
      buttons: [{
        text: this.translate.instant(distance ? 'CHECKOUT_ANYWAY_AND_COMPLETE'  : 'CHECKOUT_COMPLETE'),
        role: 'confirm',
        handler: () => {
          return ({ values : CHECKOUT_OPTIONS.CHECKOUT_COMPLETE })
        },
      },{
        text: this.translate.instant('CHECK_OUT_ONLY'),
        role: 'confirm',
        handler: () => {
          return ({ values : CHECKOUT_OPTIONS.CHECKOUT })
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return ({ values : CHECKOUT_OPTIONS.CANCEL })
        },
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then(({data}) => {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED'), 'Check Out', 'top', ToastStyle.DANGER, 3000);
        return false;
      }
      return data ? data.values : false;
    });

  }

  async showManualGPSConfirmAlert(header,message, button) : Promise<any> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(header),
      message: message,
      buttons: [{
        text: this.translate.instant(button),
        role: 'confirm',
        handler: () => {
          return  true;
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          return  false;
        }
      }],
    });
    await alert.present();

    return await alert.onDidDismiss().then((data) => {
      return data.role == "confirm";
    });
  }

  private openPhotoCaptureViewerForGPSActivity(activityType :number, gpsPayload) {
    if (!this.device.isNativeApp) {
      this.notificationService.notify(this.translate.instant('GPS_PHOTOS_WEB_WARNING'), 'Meeting Details', 'top', ToastStyle.DANGER);
      return;
    }
    let header;// = activityType === GPSActivity.CHECKIN ? 'MANUAL_CHECKIN': 'MANUAL_CHECKOUT';
    let activityPhotos: GPSActivityPhoto;
    const activity = (this.activityService.selectedActivity) as AppointmentActivity;
    if (activityType === GPSActivity.CHECKIN) {
      header = 'MANUAL_CHECKIN';
      if (!_.isEmpty(activity.gpsActivityPhotos)) {
        activityPhotos = activity.gpsActivityPhotos.find(gpsActivityPhoto => gpsActivityPhoto.indskr_type === 548910000);
      }
    } else {
      header = 'MANUAL_CHECKOUT';
      if (!_.isEmpty(activity.gpsActivityPhotos)) {
        activityPhotos = activity.gpsActivityPhotos.find(gpsActivityPhoto => gpsActivityPhoto.indskr_type === 548910001);
      }
    }
    let accountName = '';
    let etmsCode = '';
    if (!_.isEmpty(activity.accounts)) {
      accountName = activity.accountString;
      const account = this.accountService.getAccountById(activity.accounts[0].id);
      if (account?.extendedDetails['externalId']) {
        etmsCode = account.extendedDetails['externalId']
      }
    }
    const photoModel: IndPhotoCaptureViewerDataModel = {
      pageTitle: this.translate.instant(header),
      photosLimit: activityPhotos ? (10 - activityPhotos.photoAttachments.length) : 10,
      photoNameFormat: `${this.authService.user.displayName} ${accountName} ${etmsCode} {currentTimestamp} ${this.translate.instant(header)}.PNG`,
      photoOrigin: PhotoOrigin.GPS_CHECKIN,
      accountId: _.isEmpty(activity.accounts) ? '' : activity.accounts[0].id,
      errorMessage: this.translate.instant('MAX_PHOTO_NOTIFICATION', { limit: 10 }),
      callbackEvent: (data: []) => this._handlePhotoCaptureComponentCallbackForGPSActivity(data, activityType, gpsPayload)
    };
    this.navService.pushWithPageTracking(IndPhotoCaptureViewerComponent, PageName.IndPhotoCaptureViewerComponent, { photoModel: photoModel }, PageName.IndPhotoCaptureViewerComponent);
  }

  private async _handlePhotoCaptureComponentCallbackForGPSActivity(data: Photo[], activityType: number, gpsPayload) {
    if(!_.isEmpty(data))
    {
      const currentMeeting = (this.activityService.selectedActivity) as AppointmentActivity;
      let gpsActivitiyPayload = [{
        'indskr_type': activityType,
        'indskr_gpscheckindetailsid': currentMeeting.gpsCheckInDetails['indskr_gpscheckindetailsid'],
        'photoAttachmentIds': []
      }];

      await this.uiService.displayLoader({ duration: 30000 });
      if(activityType === GPSActivity.CHECKIN) {
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(currentMeeting, gpsPayload, data, gpsActivitiyPayload);
        if (!response) {
          await this.uiService.dismissLoader();
          this.checkinProcess = false;
          return;
        }

        currentMeeting.gpsCheckInDetails['indskr_checkinstatus'] = response.indskr_checkinstatus ?? GPS_STATUS.INVALID;
        currentMeeting.gpsCheckInDetails['indskr_checkindatetime'] = response.indskr_checkindatetime ?? gpsPayload.indskr_checkindatetime;
        currentMeeting.gpsCheckInDetails['indskr_gpscheckindetailsid'] = response.indskr_gpscheckindetailsid;
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(this.activityService.selectedActivity as AppointmentActivity, true);
        this.footerService.initButtons(FooterViews.Activities);
        this.initFormFields();
        this.checkinProcess = false;
      } else {
        const response: any = await this.meetingDataService.updateMeetingCheckInCheckout(currentMeeting, gpsPayload, data, gpsActivitiyPayload);
        if (!response) {
          this.checkinProcess = false;
          return;
        };
        await this.setCheckinCheckoutStatus(currentMeeting, gpsPayload);
      }

      await this.initGpsActivityPhotos();
      await this.uiService.dismissLoader();
      this.notificationService.notify(activityType === GPSActivity.CHECKIN?this.translate.instant('GPS_CHECKIN_SUCESS'):this.translate.instant('GPS_CHECKOUT_SUCESS'), 'Check In', 'top', ToastStyle.INFO, 3000);
    }
    else {
      let meeting = this.activityService.selectedActivity as AppointmentActivity;
      this.checkinProcess = false;

      if(activityType === GPSActivity.CHECKIN) {
        this.checkInField = {
          isDateField: true,
          label: this.translate.instant('CHECK_IN_STATUS'),
          inputText:  '',
          customPlaceholderLabel: meeting.gpsCheckInDetails && !meeting.gpsCheckInDetails.indskr_checkindatetime ? this.translate.instant('START_CHECK_IN') : '',
          id: 'check-in-field',
          isReadOnly: true,
          isRequired : this.authService.user.buSettings['indskr_gpsfunctionality'] && meeting.state != MeetingActivityState.Completed,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField : this.checkinFieldsEmpty && this.authService.user.buSettings['indskr_gpsfunctionality'] && meeting.gpsCheckInDetails.indskr_checkinstatus == null,
          isHidden :  !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype),
          eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
        }
      } else {
        this.checkOutField = {
          isDateField: true,
          label: this.translate.instant('CHECK_OUT_STATUS'),
          inputText: '',
          customPlaceholderLabel: meeting.gpsCheckInDetails && !meeting.gpsCheckInDetails.indskr_checkoutdatetime ?  this.translate.instant('START_CHECK_OUT') : '',
          id: 'check-out-field',
          isReadOnly: true,
          // isRequired : this.authService.user.buSettings['indskr_gpsfunctionality']  && meeting.state != MeetingActivityState.Completed,
          // errorMessage: this.activityService.getErrorMessageRequiredField(),
          // isEmptyRequiredField : this.checkinFieldsEmpty && this.authService.user.buSettings['indskr_gpsfunctionality'] && meeting.gpsCheckInDetails.indskr_checkoutstatus == null,
          isHidden : !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype),
          eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
        }
      }

      this.detectChangesOnActivityDetails(true);
    }
  }

  public openPhotoViewerForGPSActivity(photoAttachment: PhotoAttachmentDto, activityType :number) {
    const header = activityType === GPSActivity.CHECKIN? 'MANUAL_CHECKIN': 'MANUAL_CHECKOUT';

    const photoModel: IndPhotoViewerDataModel = {
      pageTitle: this.translate.instant(header),
      masterPhotos: photoAttachment.photos,
      readOnly: true,
      callbackEvent: (data: []) => this._handlePhotoViewerCallback(data, photoAttachment)
    };
    this.navService.pushWithPageTracking(IndPhotoViewerComponent, PageName.IndPhotoViewerComponent, { photoModel: photoModel }, PageName.IndPhotoViewerComponent);
  }

  private async _handlePhotoViewerCallback(removedItems: PhotoResponse[], photoAttachment: PhotoAttachmentDto) {
    console.log("PhotoResponse[] ", removedItems);
    if (removedItems.length) {
      const removedAttachmentIds = removedItems.map((item) => item.indskr_photoattachmentid);
      const photos:PhotoResponse[] = _.filter(photoAttachment.photos, (photo) =>
        _.includes(removedAttachmentIds, photo.indskr_photoattachmentid)
      );
      const payload = photos.map(photo => ({ photoAttachmentId: photo.indskr_photoattachmentid, deleted: true }));
      console.log(payload);
      await this.uiService.displayLoader();

      this.initGpsActivityPhotos();
      await this.uiService.dismissLoader();
    }
  }

  public async initGpsActivityPhotos() {
    if(this.isManualGPSCheckinEnabled) {
      const activity = this.activityService.selectedActivity as AppointmentActivity;
      this.initAppealStatusFormField(activity);

      this.checkInPhotoAttachments = [];
      this.checkOutPhotoAttachments = [];
      const gpsActivityPhotos = activity.gpsActivityPhotos ?? [];
      gpsActivityPhotos.map(at => {
        if(at['indskr_type'] == GPSActivity.CHECKIN) {
          this.checkInPhotoAttachments.push({photos: at['photoAttachments'] });
        } else {
          this.checkOutPhotoAttachments.push({photos: at['photoAttachments'] });
        }
      });
    }
  }

  private initAppealStatusFormField(activity) {
    if(activity.gpsCheckInDetails && !_.isEmpty(activity.gpsActivityPhotos)
      && activity.gpsCheckInDetails.statuscode !== null && activity.gpsCheckInDetails.statuscode !== undefined
      && activity.gpsCheckInDetails.statuscode !== 1) {
      this.appealStatusFormField = {
        label: this.translate.instant('APPEAL_STATUS'),
        inputText: this.appealService.getAppealStatusFormattedValue(activity.gpsCheckInDetails['statuscode']),
        id: 'appeal-status',
        isReadOnly: true,
        isDisabled: true,
        showArrow: false,
      }
    }
  }

  async showMeetingCompleteAlert(){
    return this.uiService.displayAlertConfirmation({
      title: this.translate.instant('MARK_COMPLETE'),
      message: this.translate.instant('COMPLETE_WITHOUT_CHECKIN')+'?', // need to change the text
      cancelButtonText: this.translate.instant('NO'),
      confirmButtonText: this.translate.instant('YES')
    }).then((confirm) => {
      return confirm
    }).catch(err => {
      return false;
    });
  }

  // async findAddressToUpdateCoordinates(currentMeeting) {
  //   const accountIds = currentMeeting.accounts.map((account) => account.id);
  //   let rawData = await this.disk.retrieve('account_linked_entityindskr_indskr_customeraddress_v2');
  //   if (rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0) {
  //     const addresses = rawData.raw.filter(item => item.hasOwnProperty('accountid') && accountIds.some(account => account == item['accountid']));
  //     let findtheaddresses: any = addresses.find((address) => address['indskr_address.indskr_composite'] === currentMeeting.location);
  //     return findtheaddresses["indskr_indskr_customeraddress_v2.indskr_address"];
  //   }
  // }

  onContactsUpdated(data) {
    if (this.authService.user.meetingProximityWarningPeriod > 0) {
      this.shouldCheckConflict = true;
      if (this.uiService.toolsActivityActive) {
        this.activityService.signalActivityConflictCheck$.next(this.activityService.selectedActivity);
      } else {
        this.events.publish("refreshAgenda");
      }
    }
  }

  private flattenTree(childItems) {
    return childItems.flatMap(child => _.isEmpty(child.childItems) ? child : [child, ...this.flattenTree(child.childItems)]);
  }

  private get isReadOnlyMeeting() {
    return this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.isCompleted || this.isReadOnlyJointMeeting || this.backgroundUploadInProgress;
  }

  public get nativeShareAvailable() {
    if (this.device.isNativeApp && this.device.deviceFlags.electron) {
      return false;
    }
    if (!this.device.isNativeApp && (window as any).plugins?.socialsharing?.shareW3C === navigator.share) {
      return false;
    }
    if (this.device.isNativeApp && this.device.deviceFlags.ios) return !!(navigator.share);
    return !!(navigator.share && (!(navigator as any).canShare || (navigator as any).canShare({ url: location.href })));
  }

   // --------------------------------No Detailing in a Meeting-------------------------------- //
   private fetchMeetingContentReasons() {
    const meetingContentReasons = this.activityService.meetingContentReasons;
    if (_.isEmpty(meetingContentReasons)) {
      return []
    }
    return meetingContentReasons.map(res => {
      return {
        type: 'radio',
        name: res.indskr_name,
        label: res.indskr_name,
        value: res.indskr_meetingcontentreasonsid
      }
    })
  }

  public isCurrentContentNotPlayedByUser() {
    let isNotPlayed = true;

    let tempActivityResources = this.activityService.selectedActivity['activityResources'].map(a => ({...a}));
    tempActivityResources.forEach(function (element) {
      element['name'] = element['indskr_ckmtitle'];
    });

    const intersection_Presentations = _.intersectionBy(
      this.activityService.selectedActivity['presentations'],
      this.activityService.selectedActivity['activityPresentations'],
      'name'
    );

    console.warn('intersection_Presentations');
    console.log(intersection_Presentations);

    const intersection_Resources = _.intersectionBy(
      this.activityService.selectedActivity['presentations'],
      tempActivityResources,
      'name'
    );

    console.warn('intersection_Resources');
    console.log(intersection_Resources);

    if(!_.isEmpty(intersection_Presentations) || !_.isEmpty(intersection_Resources))
      isNotPlayed = false;

    return isNotPlayed;
  }

  public async onDiseaseAreasSave(event){
    if (!this.device.isOffline && !(this.activityService.selectedActivity as AppointmentActivity).ID.includes('offline')  && this.activityService.selectedActivity instanceof AppointmentActivity && event && Array.isArray(event)) {
      this.activityService.selectedActivity.activityDiseaseAreas = event.filter(item => item.isSelected || item.isAutoSelected).map((obj,idx)=> {
        return {
            "indskr_diseaseareaid": obj.id,
            "indskr_sequence": idx+1,
            "indskr_automaticallyselected": obj.isAutoSelected,
            "indskr_geneeselected": obj.isGenieSelected,
            "indskr_name": obj.label,
            "isSelected" : true,
        };
      });
      this.uiService.displayLoader();
      await this.meetingDataService.updateMeetingProductKeyMessages(this.activityService.selectedActivity,{products:false,indications:false,diseaseAreas:true}).then(response => {
        console.log(response);
        this.initDieseaseAreaSectionData();
      }).catch(err => {
        console.log(err);
        this.notificationService.notify(this.translate.instant('UPDATING_DISEASE_AREA_FAILED'), 'Meeting Details', 'top', ToastStyle.DANGER);
      })
      this.uiService.dismissLoader();
    }
  }

  public async onProductIndicationsSave(event){
    if (!this.device.isOffline && !(this.activityService.selectedActivity as AppointmentActivity).ID.includes('offline')  && this.activityService.selectedActivity instanceof AppointmentActivity && event && Array.isArray(event)) {
      this.activityService.selectedActivity.activityProductIndications = event.filter(item => item.isSelected || item.isAutoSelected).map((obj,idx)=> {
        return {
            "productIndicationId": obj.id,
            "indskr_sequence": idx+1,
            "indskr_automaticallyselected": obj.isAutoSelected,
            "indskr_geneeselected": obj.isGenieSelected,
            "indskr_name": obj.label,
            "isSelected" : true,
            activityProductIndicationKeyMessages: obj.childItems ? obj.childItems.filter(ci=> ci.isSelected || ci.isAutoSelected).map((obj2,idx2)=> {
              return {
                "indskr_keymessageid": obj2.id,
                "indskr_automaticallyselected": obj2.isAutoSelected,
                "indskr_geneeselected": obj2.isGenieSelected,
                "indskr_name": obj2.label,
                "isSelected": true,
              };
            }) : [],
        };
      });
      this.uiService.displayLoader();
      await this.meetingDataService.updateMeetingProductKeyMessages(this.activityService.selectedActivity,{products:false,indications:true,diseaseAreas:false}).then(response => {
        console.log(response);
        this.initProductIndicationsSectionData();
      }).catch(err => {
        console.log(err);
        this.notificationService.notify(this.translate.instant('UPDATING_PRODUCT_INDICATION_FAILED'), 'Meeting Details', 'top', ToastStyle.DANGER);
      })
      this.uiService.dismissLoader();
    }
  }

  private async initDieseaseAreaSectionData(){
    if(!this.isDiseaseAreaEnabled){
      return;
    }
    let diseaseAreasList = [];
    this.selectedDiseaseAreas = [];
    if(this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings){
      if((this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas){
        (this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas.forEach(item => {
          this.selectedDiseaseAreas.push(item.indskr_diseaseareaid);
          if(item['indskr_diseaseareaid'] && item['indskr_name']){
            diseaseAreasList.push({
              id: item['indskr_diseaseareaid'],
              title: item['indskr_name'],
            })
          }
        });
      }
    }else{
      if((this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas){
        (this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas.forEach(item => {
          this.selectedDiseaseAreas.push(item.indskr_diseaseareaid);
        });
      }
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_DISEASE_AREAS);
      let usersPositionGroups = this.authenticationOfflineService.user.positionGroups ? this.authenticationOfflineService.user.positionGroups.map(a=> a.positionGroupId) : [];
      if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
        offlineData.raw.forEach(item => {
          if(item['indskr_diseaseareaid'] && item['indskr_name'] && item['positionGroups'] && _.intersection(usersPositionGroups,item['positionGroups']).length > 0){
            diseaseAreasList.push({
              id: item['indskr_diseaseareaid'],
              title: item['indskr_name'],
            })
          }
        });
        if((this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas && (this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas.length > 0){
          (this.activityService.selectedActivity as AppointmentActivity).activityDiseaseAreas.forEach((acpi,idx) => {
            let selacpiidx = diseaseAreasList.findIndex(a=> a.id == acpi.indskr_diseaseareaid);
            if(selacpiidx >= 0){
              let element = diseaseAreasList[selacpiidx];
              diseaseAreasList.splice(selacpiidx, 1);
              diseaseAreasList.splice(idx, 0, element);
            }
          })
        }
      }
    }

    this.diseaseAreaSectionViewData= {
      id: 'meeting-disease-area',
      sectionHeaderViewData: {
        id: 'meetingdiseasearea',
        title: this.translate.instant('DISEASE_AREA'),
        controls: [
          {
            id: 'ri-header-save',
            text: this.translate.instant('SAVE'),
            isDisabled: true,
          }
        ]
      },
      isDisabled: this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.backgroundUploadInProgress || this.enableReadonlyViewForMeetings || this.device.isOffline || (this.activityService.selectedActivity as AppointmentActivity).ID.includes('offline') ,
      items: diseaseAreasList.map(a=> {
        return {
          id: a.id,
          label: a.title,
          value: a.id,
          isSelected: this.selectedDiseaseAreas.some(b=> b == a.id),
          isAutoSelected: false,
          isGenieSelected: false,
        }
      })
    }
  }

  private async initProductIndicationsSectionData(){
    if(!this.isProductIndicationEnabled){
      return;
    }
    let productIndicationsList = [];
    this.selectedProductIndications= [];
    if(this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings){
      if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications){
        (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.forEach(item => {
          this.selectedProductIndications.push(item.productIndicationId);
          if(item['productIndicationId'] && item['indskr_name']){
            let obj = {
              id: item['productIndicationId'],
              title: item['indskr_name'],
              keyMessages: [],
            };
            if(item.activityProductIndicationKeyMessages){
              item.activityProductIndicationKeyMessages.forEach(keyMsg=> {
                this.selectedProductIndications.push(item.productIndicationId+keyMsg.indskr_keymessageid);
                obj.keyMessages.push({
                  id: keyMsg.indskr_keymessageid,
                  title: keyMsg.indskr_name,
                })
              })
            }
            productIndicationsList.push(obj);
          }
        });
      }
    }else{
      if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications){
        (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.forEach(item => {
          this.selectedProductIndications.push(item.productIndicationId);
        });
      }
      if(this.brandService.productIndications && Array.isArray(this.brandService.productIndications)){
        this.brandService.productIndications.forEach(item => {
          if(item['productid'] && (this.activityService.selectedActivity as AppointmentActivity).products && (this.activityService.selectedActivity as AppointmentActivity).products.some(p=> (p.isSelected || p.isAutoSelected) && p.ID == item['productid'])){
            if(item['productIndications']){
              item['productIndications'].forEach(indication=> {
                let selActProInd = (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.find(a=> a.productIndicationId == indication['indskr_productindicationid']);
                let obj = {
                    id: indication['indskr_productindicationid'],
                    title: indication['indskr_name'],
                    isSelected: selActProInd && selActProInd.isSelected,
                    isAutoSelected: selActProInd && selActProInd.indskr_automaticallyselected,
                    keyMessages: [],
                }
                if(indication['productIndicationsKeyMessages']){
                  indication['productIndicationsKeyMessages'].forEach(keyMsg=> {
                    if(this.authService.user.indskr_mapkeymessagestopositiongroup){
                      if(!this.brandService.keyMessagesByPositionGroups.some(a=> a.indskr_keymessageid == keyMsg['indskr_keymessageid'])){
                        return;
                      }
                    }
                    let selActProIndKeyMsg;
                    if(selActProInd){
                      selActProIndKeyMsg = selActProInd.activityProductIndicationKeyMessages.find(a=> a.indskr_keymessageid == keyMsg['indskr_keymessageid']);
                    }
                   obj.keyMessages.push({
                        id: keyMsg['indskr_keymessageid'],
                        title: keyMsg['indskr_name'],
                        isSelected: selActProIndKeyMsg && selActProIndKeyMsg.isSelected,
                        isAutoSelected: selActProIndKeyMsg && selActProIndKeyMsg.indskr_automaticallyselected,
                    });
                    this.selectedProductIndications.push(indication.indskr_productindicationid+keyMsg.indskr_keymessageid);
                 });
                }
                productIndicationsList.push(obj);
              });

            }
          }
        });
        // if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications && (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.length > 0){
        //   (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.forEach((acpi,idx) => {
        //     let selacpiidx = productIndicationsList.findIndex(a=> a.id == acpi.productIndicationId);
        //     if(selacpiidx >= 0){
        //       let element = productIndicationsList[selacpiidx];
        //       productIndicationsList.splice(selacpiidx, 1);
        //       productIndicationsList.splice(idx, 0, element);
        //     }
        //   })
        // }
        if(productIndicationsList.length > 0){
          productIndicationsList = _.sortBy(productIndicationsList, [function (o) { return o.title; }]);
          productIndicationsList = _.sortBy(productIndicationsList, [function(o) { return o.isSelected || o.isAutoSelected}]);
        }
      }
    }

    this.productIndicationSectionViewData= {
      id: 'meeting-product-indication',
      sectionHeaderViewData: {
        id: 'meetingproductindication',
        title: this.translate.instant('PRODUCT_INDICATION'),
        controls: [
          {
            id: 'ri-header-save',
            text: this.translate.instant('SAVE'),
            isDisabled: true,
          }
        ]
      },
      isDisabled: this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.backgroundUploadInProgress || this.enableReadonlyViewForMeetings || this.device.isOffline || (this.activityService.selectedActivity as AppointmentActivity).ID.includes('offline') ,
      items: productIndicationsList.map(a=> {
        return {
          id: a.id,
          label: a.title,
          value: a.id,
          isSelected: this.selectedProductIndications.some(b=> b == a.id),
          isAutoSelected: a.isAutoSelected,
          isGenieSelected: false,
          childItems: a.keyMessages.map(b=> {
            return {
              id: b.id,
              label: b.title,
              value: b.id,
              isSelected: b.isSelected,
              isAutoSelected: b.isAutoSelected,
              isGenieSelected: false,
            }
          }),

        }
      })
    }
  }

  public openKeyMessageSentimentPage(ev) {
    if (!this.contactService.loadedContacts) return;
    if (this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.isFromXperiences || ((this.activityService.selectedActivity.isCompleted
      || this.activityService.selectedActivity['indskr_suggest'] || this.activityService.selectedActivityJointMeetingStatus)
      && this.activityService.selectedActivity['contacts'].length > 0) || (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
      }
      this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
      this.uiService.showNewActivity = false;
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
    } else {
      if (this.navService.getCurrentPageName() == PageName.AccountPageComponent) {
        this.uiService.prevView = '';
        this.uiService.activeView = '';
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
      }
      this.uiService.showNewActivity = false;
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
        this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
      } else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
        this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
      }
      if (this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
        this.contactService.contactInformation = undefined;
        this.uiService.contactDetailsSegment = undefined;
      }
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent);
    }
  }
  // --------------------------------No Detailing in a Meeting-------------------------------- //

  async generateMeetingUrl(meetingId){
   let response:any = await this.meetingDataService.generateMeetingUrl(meetingId);
   (this.activityService.selectedActivity as AppointmentActivity).meetingURL = response.meetingUrl;
   this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
  }

  async handleMeetingOutcomeField(myEvent) {
    if (_.isArray(this.activityOutcomeOptions) && !_.isEmpty(this.activityOutcomeOptions)) {
      let activity: AppointmentActivity = <AppointmentActivity>this.activityService.selectedActivity;
      const prevSelectedValue = activity.meetingOutcomeId;
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'activity_outcome_type',
        isMultipleSelectionEnabled: false,
        data: this.activityOutcomeOptions.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_activityoutcomeid,
            isSelected: type.indskr_activityoutcomeid == (this.activityService.selectedActivity as AppointmentActivity).meetingOutcomeId,
          }
        }),
      };

      this.activityOutcomePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

      await this.activityOutcomePopover.present();

      await this.activityOutcomePopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        if (data && data.data && data.data.selectedItems && data.data.selectedItems.length == 1 && data.data.selectedItems[0].id != activity.meetingOutcomeId) {
          if (this.backgroundUploadInProgress) return;
          const seletectedMeetingOutcome = data.data.selectedItems[0].id;
          let payload = new UpdateMeetingPayload(
            this.activityService.selectedActivity.subject,
            this.activityService.selectedActivity.location,
            this.activityService.selectedActivity.scheduledStart,
            this.activityService.selectedActivity.scheduledEnd
          );
          payload.meetingOutcomeId = seletectedMeetingOutcome;
          payload.meetingOutcomeString = data.data.selectedItems[0].title;
          if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            await this.uiService.displayLoader();
            try {
              await this.meetingDataService.updateMeeting(activity, payload, true);
            } catch (error) {
              console.error("Failed to update meeting type, resetting: error: ", error);
              const isSuccess = await this.activityService.upsertMeetingsOfflineData(activity, false, true);
              if (!isSuccess) {
                console.error("Failed to update meeting type, resetting")
                activity.meetingOutcomeId = prevSelectedValue;
                const errorMessage = error.errorId ? error.errorId : error.message;
                this.notificationService.notify(this.translate.instant('UPDATING_MEETING_OUTCOME_FAILED') + errorMessage, 'Meeting Details', 'top', ToastStyle.DANGER);
              }else{
                activity.meetingOutcomeId = seletectedMeetingOutcome;
                activity.meetingOutcomeString = data.data.selectedItems[0].title;
              }
            }
            await this.uiService.dismissLoader();
          }
          else {
            activity.meetingOutcomeId = seletectedMeetingOutcome;
            activity.meetingOutcomeString = data.data.selectedItems[0].title;
            const isSuccess = await this.activityService.upsertMeetingsOfflineData(activity);
            if (!isSuccess) {
              console.error("Failed to update meeting type in offline data, resetting")
              activity.meetingOutcomeId = prevSelectedValue;
            }
          }
        }
        this.activityOutcomePopover = undefined;
      });
    }
  }

  private async openUsersList() {
    let data = [];
    if (this.procedureTrackerDataService.users.length) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userId != this.authService.user.xSystemUserID && user.userType && user.userType.includes(UserTypes.SALES_REP) && user.primaryPosition).map(user => {
        return {
          id: user.userId,
          title: user.userFullName,
        }
      })
    }

    data = _.orderBy(data, 'title');

    const productManagerModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          confirmationCheckRequired: true,
          confirmationAlertData: {
            title: this.translate.instant('ARE_YOU_SURE'),
            message:this.translate.instant('ASSIGN_OWNER_WARNING'),
            confirmButtonText: this.translate.instant('ASSIGN'),
            cancelButtonText: this.translate.instant('CANCEL')
          },
          needcustomMessage: true
        }
      },
      backdropDismiss: false
    });
    productManagerModal.present();
    productManagerModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) return;

        let newOwner =  this.procedureTrackerDataService.users.find((user) => user.userId === data.selectedItems[0].id);
        let payload = {
          ownerid: newOwner.userId,
          indskr_positionid: newOwner.primaryPosition
        }

        const isAssignedOwner = await this.meetingDataService.assignOwner(payload, this.activityService.selectedActivity.ID);
        if (!isAssignedOwner) return;

        let activity = (this.activityService.selectedActivity as AppointmentActivity);
        let subject = activity.subject;
        let updatedSubject = false;
        this.meetingDataService.setMeetingSubjectFromContact(activity, activity.meetingOwnerName)
        if(subject != (this.activityService.selectedActivity as AppointmentActivity).subject){
          updatedSubject = true;
        }
        // const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
        // if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) || isAcinoBaseUser) {
        //   subject = this.translate.instant('MEETING');
        //   let subPrefix = ` - ${subject}`;
        //   subPrefix = ` - ${subject} - ${data.selectedItems[0].title}`;

        //   if (isAcinoBaseUser) {
        //     subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}` + this.translate.instant('VISIT');
        //   }
        //   switch (this.selectedContacts.length) {
        //     case 0:
        //       break;
        //     case 1:
        //       subject = `${this.selectedContacts[0].fullName}${subPrefix}`;
        //       if (isAcinoBaseUser) {
        //         subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}` + this.translate.instant('VISIT') + ` - ${this.selectedContacts[0].fullName}`;
        //       }
        //       break;
        //     default:
        //       if (this.selectedContacts.length > 1) {
        //         this.selectedContacts.sort((contactA, contactB): number => {
        //           let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
        //           let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
        //           if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
        //           if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

        //           return 0;
        //         });
        //         subject = `${this.selectedContacts[0].fullName} + ${this.selectedContacts.length - 1}${subPrefix}`;
        //         if (isAcinoBaseUser) {
        //           subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}` + this.translate.instant('VISIT') + ` - ${this.selectedContacts[0].fullName}`;
        //         }
        //       }
        //   }
        //   if (activity.subject !== subject) {
        //     updatedSubject = true;
        //   }
        // }
        
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          this.activityService.selectedActivity.ownerId = newOwner.userId;
          this.activityService.selectedActivity.positionId = newOwner.primaryPosition;
          this.activityService.selectedActivity.meetingOwnerId = newOwner.userId;
          this.activityService.selectedActivity.meetingOwner = newOwner.userFullName;
          this.activityService.selectedActivity.meetingOwnerName = data.selectedItems[0].title
          // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
          
          if(this.secondaryInfoService.isAppointmentSecInfoConfigured && this.secondaryInfoService.checkIfSchemaIsConfig('OwnerId', SecondaryInfoEntityName.Appointment) ) {
            subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
            updatedSubject= true;
          }

          if (updatedSubject) {
            this.activityService.selectedActivity.subject = subject;
            let meetingPayload = new UpdateMeetingPayload(
              this.activityService.selectedActivity.subject,
              this.activityService.selectedActivity.location,
              this.activityService.selectedActivity.scheduledStart,
              this.activityService.selectedActivity.scheduledEnd
            );
            if (!this.device.isOffline) {
              await this.meetingDataService.updateMeeting(activity, meetingPayload);
              // this.initFormFields();
            }
          }

          this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          this.initFormFields();

          let currentUserIsCovisitor = this.isUserCovisitor(this.authService.user.xSystemUserID, this.activityService.selectedActivity);
          if (currentUserIsCovisitor) {
            this.footerService.initButtons(FooterViews.Activities);
            this. initActivitiesDetailsPageTitle();
            return
          };

          let currentUserPositions = this.authService.user.positions.map((position) => position.ID);
          if (currentUserPositions.includes(newOwner.primaryPosition)) {
            this.footerService.initButtons(FooterViews.Activities);
            this. initActivitiesDetailsPageTitle();
            return;
          };
        }

        if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
          this.navService.popWithPageTracking();
        } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
          if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
        } else {
          this.navService.popChildNavPageWithPageTracking();
        }

        this.activityService.removeActivity(this.activityService.selectedActivity, true);
        if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
        else this.uiService.agendaRefreshRequired = true;
        if (this.uiService.activitiesPageTab === 'day') {
          this.events.publish('activityDeleted');
        }
        if(this.activityService.multiOptionActivityFilter && this.activityService.multiOptionActivityFilter.some(a=> a.categoryPath == "Users" && (a.value == this.activityService.selectedActivity.ownerId || this.isUserCovisitor(a.value, this.activityService.selectedActivity as AppointmentActivity)))){
          // Dont remove the activity from calendar
        }else{
          this.activityService.publishActivityEvent({ action: "Delete", activity: this.activityService.selectedActivity });
        }
      }
    })
  }

  isUserCovisitor(userId : string, meeting : AppointmentActivity){
    return meeting.accompaniedUserList.length > 0 &&  meeting.accompaniedUserList.some((user) => user.id === userId);
  }

  private async openMarketingPlans() {
    const currentMeeting = this.activityService.selectedActivity as AppointmentActivity;
    if(currentMeeting.state ==MeetingActivityState.Completed && !currentMeeting.marketingBusinessPlanId) return;
    // let marketingPlans = this.marketingPlansManagementOfflineService.filterMarketingBusinessPlanByPosition(this.marketingPlansManagementOfflineService.marketingPlans);
    let marketingPlans = this.marketingPlansManagementOfflineService.filterMarketingBusinessPlanByTime(this.marketingPlansManagementOfflineService.marketingPlans);

    const selectedMarketingPlan: any = {
      indskr_brandplanid: currentMeeting.marketingBusinessPlanId,
      indskr_name: currentMeeting.marketingBusinessPlanName
    }

    if (!_.isEmpty(marketingPlans)) {
      const marketingPlansSelectionViewData: MarketingPlanSelectionViewDataModel = {
        isReadOnlyMode: false,
        selectedMarketingPlans: currentMeeting.marketingBusinessPlanId ? [new MarketingPlan(selectedMarketingPlan)] : [],
        disableDoneButtonInOffline: false,
        positionfilter: true,
        viewMode: false ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
        callbackFromSelectionComponent: (event: string, newSelectedMarketingPlans: MarketingPlan[], data: any) => this._handledMarketingPlansSelection(event, newSelectedMarketingPlans, data),
      };
      this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { viewData: marketingPlansSelectionViewData, from: PageName.ActivitiesPageComponent }, PageName.MarketingPlanManagementPageComponent);
    }
  }

  // private openKitBooking(){
  //   let linkedKitBooking = this.activityService.getActivityByID(this.activityService.selectedActivity['indskr_kitbooking']);
  //   this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
  //   this.activityService.selectedActivity = linkedKitBooking;
  //   this.navService.pushChildNavPageWithPageTracking(SetBookingActivityDetailsComponent, PageName.SetBookingActivityDetailsComponent, PageName.ActivitiesPageComponent, { from: 'Meeting', activity: linkedKitBooking });
  //   this.uiService.activeView = 'ActivitiesPageRightPaneNav';
  //   this.uiService.showNewActivity = false;
  //   this.uiService.showRightPane = true;
  // }

    private openKitBooking() {
    const isReadOnlyMode = (this.enableReadonlyViewForMeetings || (this.activityService.selectedActivity.isCompleted) || this.activityService.selectedActivity?.isDiffPosition) || (this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS));
    const selectedKitBooking = this.activityService.getActivityByID(this.activityService.selectedActivity['indskr_kitbooking']);
    if (isReadOnlyMode) return;
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('KIT_BOOKINGS'),
      dividerTitle: this.translate.instant('KIT_BOOKINGS'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'secondaryTextLeft',
      hideAllItemsList: false,
      isListSelectionEnabled: !isReadOnlyMode || !this.device.isOffline,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleKitBookingComponentEvent(data, eventTarget, refData),
      data: this.getKitBookingSelectionData(isReadOnlyMode, selectedKitBooking),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, disableSorting: true }, PageName.MainToolTemplateComponent);
  }

    private getKitBookingSelectionData(isReadOnlyMode, selectedKitBooking) {
      let setBookingActivities = _.cloneDeep(this.activityService.activities.filter(o => o.type == ActivityType.SetBooking) as SetBookingActivity[]);
      setBookingActivities = setBookingActivities.filter(setbooking => (setbooking.indskr_status!= SetBookingStatus.CANCELLED && setbooking.indskr_status!= SetBookingStatus.CANCELERROR && setbooking.indskr_status!= SetBookingStatus.PENDINGCANCEL) || setbooking.ID==selectedKitBooking?.ID )
      setBookingActivities = setBookingActivities.sort((a, b): number => {
        if (a.scheduledStart > b.scheduledStart) return 1;
        if (a.scheduledStart < b.scheduledStart) return -1;
        return 0;
      });
      const data: Array<MainCardViewDataModel> = setBookingActivities.map(item => {
      let isSelected = !_.isEmpty(selectedKitBooking) ? item.ID == selectedKitBooking?.ID : false;
      return {
        id: item.ID,
        primaryTextLeft: item.statusString ? `${this.translate.instant('STATUS')} - ${item.statusString}` : '',
        secondaryTextLeft: item.scheduledStart ? `${format(new Date(item.scheduledStart), this.dateTimeFormatsService.dateToUpper)}` : '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.subject ? item.subject : this.translate.instant('KIT_BOOKING'),
        showArrow: false,
        isSelected: false,
        eventOwnerId: (this.activityService.selectedActivity as AppointmentActivity).ownerId
      };
    });
    return data;
  }

  private async _handleKitBookingComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      let appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
      let payload = [];
      // let payload = new UpdateMeetingPayload(
      //   this.activityService.selectedActivity.subject
      // );
      if (_.isEmpty(data.selectedItems)) {
        appointmentActivity.indskr_kitbooking = '';
      } else {
        appointmentActivity.indskr_kitbooking = data.selectedItems[0].id;
        payload.push({
          'indskr_kitbooking': data.selectedItems[0].id
        })
      }
      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)){
        await this.activityService.upsertMeetingsOfflineData(appointmentActivity);
      } else{
        try {
          await this.meetingDataService.updateKitBookings(appointmentActivity, payload);
        } catch (error) {
          console.error('Adding Set Booking to Meeting: ', error);
          this.activityService.upsertMeetingsOfflineData(appointmentActivity, false, true);
        }
      }
      this.initFormFields();
    } else if (eventTarget && eventTarget === 'LeftHeaderButtonClick') {
      await this.navService.popWithPageTracking();
    } else if (eventTarget && eventTarget === 'MainItemEvents-mainItem') {
      if(data && data.id) {
        let linkedKitBooking = this.activityService.getActivityByID(data.id);
        if(linkedKitBooking) {
          this.navService.pushChildNavPageWithPageTracking(SetBookingActivityDetailsComponent, PageName.SetBookingActivityDetailsComponent, PageName.ActivitiesPageComponent, { from: 'Meeting', activity: linkedKitBooking });
          this.navService.setChildNavRightPaneView(true);
          this.uiService.showRightPane = true;
        } else {
          this.notificationService.notify(this.translate.instant("SOMETHING_REALLY_BAD_HAPPENED"), 'Meeting Details', 'top', ToastStyle.DANGER);
          return;
        }
      }
    }
  }

  async _handledMarketingPlansSelection(event, newSelectedMarketingPlans, data) {
    let currentMeeting = this.activityService.selectedActivity as AppointmentActivity;

    if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
      this.meetingDataService.updateMeetingMarketingBusinessPlanOffline(this.activityService.selectedActivity.ID, newSelectedMarketingPlans[0]);
      this.updateMarketBusinessPlanInMeeting(newSelectedMarketingPlans[0], currentMeeting);
      return;
    }

    this.uiService.displayLoader();
    const mode = newSelectedMarketingPlans.length > 0 ? 'Update' : 'Delete';
    const payloadMarketingPlanID = newSelectedMarketingPlans.length > 0 ? newSelectedMarketingPlans[0].ID : currentMeeting.marketingBusinessPlanId;

    const response = await this.meetingDataService.updateMeetingMarketingBusinessPlanOnline(mode, { indskr_marketingbusinessplan: payloadMarketingPlanID }, this.activityService.selectedActivity.ID);
    if (!response) return;
    this.updateMarketBusinessPlanInMeeting(newSelectedMarketingPlans[0], currentMeeting);

    this.activityService.publishActivityEvent({ action: 'Update', activity: this.activityService.selectedActivity });
    this.activityService.upsertMeetingsOfflineData(currentMeeting);
    this.uiService.dismissLoader();
    this.initFormFields();
  }

  private async _handledCoachingPlanSelection(event, selected, data) {
    if (this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) {
      return;
    }

    const currentMeeting = this.activityService.selectedActivity as AppointmentActivity;
    const selectedPlan = selected.selectedItems[0];
    const isOfflineMode = this.device.isOffline || this.activityService.hasOfflineMeetingData(currentMeeting.ID);

    try {
      if (isOfflineMode) {
        await this.handleCoachingPlanOfflineUpdate(currentMeeting, selectedPlan);
      } else {
        await this.handleCoachingPlanOnlineUpdate(currentMeeting, selectedPlan);
      }
      this.initFormFields();
    } catch (error) {
      console.error('Error updating coaching plan:', error);
    }
  }

  private async handleCoachingPlanOfflineUpdate(meeting: AppointmentActivity, selectedPlan: any) {
    await this.meetingDataService.updateMeetingCoachingPlanOffline(meeting.ID, selectedPlan);
    this.updateCoachingPlanInMeeting(selectedPlan, meeting);
  }

  private async handleCoachingPlanOnlineUpdate(meeting: AppointmentActivity, selectedPlan: any) {
    this.uiService.displayLoader();
    try {
      const selectedCoachingId = selectedPlan?.id || '';
      const payload = { indskr_coachingplan: selectedCoachingId };
      
      const response = await this.meetingDataService.updateMeetingCoachingPlanOnline(payload, meeting.ID);

      if (!response) {
        return;
      }

      this.updateCoachingPlanInMeeting(selectedPlan, meeting);
      this.activityService.publishActivityEvent({ action: 'Update', activity: meeting });
      await this.activityService.upsertMeetingsOfflineData(meeting);
    } finally {
      this.uiService.dismissLoader();
    }
  }

  updateMarketBusinessPlanInMeeting(marketingBusinessPlan, meeting) {
    meeting.marketingBusinessPlanId = marketingBusinessPlan && marketingBusinessPlan.ID ? marketingBusinessPlan.ID : "";
    meeting.marketingBusinessPlanName = marketingBusinessPlan && marketingBusinessPlan.brandPlanName ? marketingBusinessPlan.brandPlanName : "";
  }

  updateCoachingPlanInMeeting(coachingPlan, meeting) {
    meeting.indskr_coachingplan = coachingPlan && coachingPlan.id ? coachingPlan.id : "";
    meeting.indskr_coachingplanname = coachingPlan && coachingPlan.primaryTextRight ? coachingPlan.primaryTextRight : "";
  }

  private get _currentCompetitorProductsString(): string {
    let str = '';
    let currentCompetitorProducts = (this.activityService.selectedActivity as AppointmentActivity).competitorProducts;
    if (currentCompetitorProducts) {
        if (currentCompetitorProducts.length === 1) {
            str = `${currentCompetitorProducts[0].productName}`;
        } else if (currentCompetitorProducts.length >= 2) {
          currentCompetitorProducts.sort((a, b): number => {
                if (a.productName > b.productName) return 1;
                if (a.productName < b.productName) return -1;
                return 0;
            });
            str = `${currentCompetitorProducts[0].productName} +${currentCompetitorProducts.length - 1}`;
        }
    }
    return str;
  }

  private handleCompetitorProductSelect(): void {
    // if(!this.isReadOnlyMode){
      const isReadOnlyMode = this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings;
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('COMPETITOR_PRODUCTS'),
        dividerTitle:this.translate.instant('ALL_COMPETITOR_PRODUCTS'),
        isSearchEnabled: !isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: '',
        hideAllItemsList: false, //this.isReadOnlyMode,
        isListSelectionEnabled: !isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleCompetitorProductComponentEvent(data,eventTarget,refData),
        searchHandler:(text: string) => this._handleCompetitorProductsComponentSearch(text),
        data: this.allCompetitorProducts.map(c => {
          let isSelected = false;
          if((this.activityService.selectedActivity as AppointmentActivity) && (this.activityService.selectedActivity as AppointmentActivity).competitorProducts && (this.activityService.selectedActivity as AppointmentActivity).competitorProducts.some(oco => (oco.indskr_productid === c.ID))){
            isSelected = true;
          }
          return {
            id: c.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: c.name,
            showArrow: false,
            arrowType: '',
            eventOwnerId: (this.activityService.selectedActivity as AppointmentActivity).ownerId
          };
        }),
      };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
    // }
  }

  private async _handleCompetitorProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      let appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
      if (_.isEmpty(data.selectedItems)) {
        appointmentActivity.competitorProducts = [];
      } else {
        const alreadySelComProds = appointmentActivity.competitorProducts;
        appointmentActivity.competitorProducts = [];
        data.selectedItems.forEach((item,idx) => {
          const alreadySelComProd = alreadySelComProds.find(a=> a.indskr_productid == item.id);
          if(alreadySelComProd){
            appointmentActivity.competitorProducts.push(alreadySelComProd)
          }else{
            const foundCompProduct = this.allCompetitorProducts.find(a=> a.ID == item.id);
            if(foundCompProduct){
              appointmentActivity.competitorProducts.push({
                indskr_activityproductid:'',
                indskr_geneeselected:false,
                indskr_isautomaticallyselected:false,
                indskr_productid: foundCompProduct.ID,
                indskr_sequence: idx+1,
                productName: foundCompProduct.name,
                productStructure: foundCompProduct.productStructure,
                isCompetitor: true,
              })
            }
          }
        });
      }

      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(appointmentActivity.ID)) {
        try{ // I/O operations needs to be wrap in a try and catch block
          this.activityService.upsertMeetingsOfflineData(appointmentActivity); // offline saving
        }catch(e){
          console.log(e);
        }
      }else{
        this.uiService.displayLoader();
        await this.meetingDataService.updateMeetingProductKeyMessages((this.activityService.selectedActivity as AppointmentActivity),{products:true,indications:false,diseaseAreas:false}).then(response => {
          //console.log(response);
        }).catch(err => {
          console.log(err);
          this.notificationService.notify(this.translate.instant('UPDATING_COMPETITOR_PRODUCT_FAILED'), 'Meeting Details', 'top', ToastStyle.DANGER);
        })
        this.uiService.dismissLoader();
      }

    }
  }

  private async _handleCoachingPlanComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      this._handledCoachingPlanSelection(eventTarget, data, refData)
    }
  }

  private openCoachingPlans() {
    const isReadOnlyMode = this.activityService.selectedActivity.isCompleted || this.activityService.teamViewActive;
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('COACHING_PLANS'),
      dividerTitle:this.translate.instant('ALL_COACHING_PLANS'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: '',
      hideAllItemsList: false, //this.isReadOnlyMode,
      isListSelectionEnabled: !isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleCoachingPlanComponentEvent(data,eventTarget,refData),
      searchHandler:(text: string) => this._handleCoachingPlanComponentSearch(text),
      data: this.allCoachingPlans.map(c => {
        let isSelected = false;
        if((this.activityService.selectedActivity as AppointmentActivity) && (this.activityService.selectedActivity as AppointmentActivity).indskr_coachingplan && (this.activityService.selectedActivity as AppointmentActivity).indskr_coachingplan == c.indskr_coachingplanid){
          isSelected = true;
        }
        return {
          id: c.indskr_coachingplanid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
          endIconCssClass: isSelected?'checkmark-icon':'add-icon',
          primaryTextRight: c.indskr_name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: (this.activityService.selectedActivity as AppointmentActivity).ownerId
        };
      }),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
  }

  private _handleCompetitorProductsComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.allCompetitorProducts && this.allCompetitorProducts.filter(pro => {
          return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.ID);
    } else {
        ids = this.allCompetitorProducts && this.allCompetitorProducts.map(pro => pro.ID);
    }
    return ids;
  }

  // Only introduced to handle specific sccenario of making appointment editable in calendar view
  private get enableReadonlyViewForMeetings():boolean {
    let flag = this.activityService.teamViewActive;
    if(this.activityService.isMyUserSelectedInTeamView && this.agendaFooterService.actSegment == 'week' && this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.activities.some(a=> a.ID == this.activityService.selectedActivity.ID)){
      flag = false;
    }

    if ((!this.activityService.isMeetingOwner &&
      (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS))) {
      flag = true;
    }
    if (this.isFromChat) {
      flag = true;
    }

    return flag;
  }
  private _handleCoachingPlanComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.allCoachingPlans && this.allCoachingPlans.filter(pro => {
          return pro.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.indskr_coachingplanid);
    } else {
        ids = this.allCoachingPlans && this.allCoachingPlans.map(pro => pro.indskr_coachingplanid);
    }
    return ids;
  }

  // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
  public getMeetingContactsSequenceString(){
    let contactString:string = `${this.isLiveMeet ? 'Participants' : Utility.globalCustomersText}`;

    if(this.contactService.isMeetingContactSequenceEnabled) {
      this.selectedContacts.sort((contactA, contactB) => {
        return contactA.indskr_sequence - contactB.indskr_sequence;
      });
    }

    //Only one contact, return his name
    if (this.selectedContacts.length === 1) {
        contactString = `${this.selectedContacts[0].fullName}`;
        if(!this.selectedContacts[0].isActive && String(this.selectedContacts[0].fullName).trim() != ''){
            contactString += ' (Inactive)';
        }
    } else if (this.selectedContacts.length >= 2) {
        contactString =  `${this.selectedContacts[0].fullName}`;
        if(!this.selectedContacts[0].isActive){
            contactString += ` (Inactive) +${this.selectedContacts.length-1}`;
        }else if(this.selectedContacts.some((con,index) =>{
            if(index == 0) return false;
            return (!con.isActive);
        })){
            contactString =  `${this.selectedContacts[0].fullName} +${this.selectedContacts.length-1} (Inactive)`;
        }else{
            contactString =  `${this.selectedContacts[0].fullName} +${this.selectedContacts.length-1}`;
        }

        // if(this.contacts[this.contacts.length - 1] === undefined){
        //     return;
        // }else{
        //     return `${this.contacts[this.contacts.length - 1].fullName} +${this.contacts.length-1}`;
        // }
    }

    if(!contactString || contactString == undefined || contactString == 'undefined ') {
        console.log(this.selectedContacts);
        return "";
    }
    else
    return contactString;
  }

  private async initConfigFieldsLookupData(){
    let lookupFields = [];
    if(this.appointmentConfigFields && this.appointmentConfigFields.length > 0){
      lookupFields = this.appointmentConfigFields.filter(a => a.datatype == 'Lookup' || a.fieldType == 'Lookup');
    }
    if(lookupFields && lookupFields.length > 0){
      await this.disk.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc)=>{
        if(doc && doc.raw){
          this.lookupConfigFieldsData = doc.raw['appointment']
        }
      })
    }
  }
  public selctedOpport(selected){
    if(this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID) return;
    const accountIds = this.activityService.selectedActivity['accounts'].map(el => el.id)
    const contactIds = this.activityService.selectedActivity['contacts'].map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => accountIds.includes(item.accountID) || contactIds.includes(item.contactID) || this._checkIfOppHasTheseContacts(contactIds,item));
    let opportunityReadOnly = this.activityService.selectedActivity.isCompleted ||  this.activityService.selectedActivity.createdby != this.authService.user.systemUserID
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: opportunityReadOnly ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection: true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };
    let clickedOpp = selected;
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData , clickedOpportunity:clickedOpp }, PageName.OpportunitySelectionPage);

  }

  onMeetingNotesFocusIn() {
    console.log("Meeting Notes Focus IN 1");
  }

  async onMeetingNotesFocusOut() {
    console.log("Meeting Notes Focus OUT 1");
    const selectedActivity = _.cloneDeep(this.activityService.selectedActivity);
    if (this.isSaveNotesEnabled && ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str) || (_.isEmpty(this.tempNoteText) && this.autoSavedNote && this.autoSavedNote.noteText))) {
      await this.activityService.task.begin();
      try {
        if(this.autoSavedNote){
          let actionType = _.isEmpty(this.base64str) && _.isEmpty(this.tempNoteText) ? 'DELETE' : 'SAVE';
          let ev = {
            action: actionType,
            noteId: this.autoSavedNote.noteId,
            updatedText: this.tempNoteText,
          };
          if(this.base64str && this.attachmentFile && this.base64str != this.autoSavedNote.documentDataURL){
            ev['attachmentFileUpdated'] = true;
            ev['documentSize'] = this.attachmentFile.size;
            ev['documentName'] = this.attachmentFile.name;
            ev['documentMimeType'] = this.attachmentFile.type;
            ev['attachmentFileDataUrl'] = this.base64str;
          }
          if(this.autoSavedNote.noteText != this.tempNoteText || ev['attachmentFileUpdated']){
            await this.updateNote(ev);
            await this.activityService.task.complete();
          }
          this.initNotesSectionHeader();
          return;
        }
        this.trackingService.tracking('MeetingNotesUpdate', TrackingEventNames.ACTIVITY);
        const now = new Date().getTime().toString();
        let tempNote = {
          annotationid: 'offlinmeetingnote' + now,
          activityid: selectedActivity.ID,
          createdon: now,
          notetext: this.tempNoteText,
          ownerName: this.authService.user.displayName,
          ownerid: this.authService.user.systemUserID,
          deleted: false,
          pendingPushForDynamics: true,
          isdocument : false
        }
  
        if (this.base64str) {
          tempNote['isdocument'] = true;
          tempNote['documentbody'] = this.base64str;
          tempNote['filename'] = this.attachmentFile.name;
          tempNote['filesize'] = this.attachmentFile.size;
          tempNote['mimetype'] = this.attachmentFile.type;
        } else {
          tempNote['hasDocument'] = false;
        }
        this.autoSavedNote = new IONote(tempNote);
        this.activityService.getActivityByID(selectedActivity.ID).meetingNotes = selectedActivity.meetingNotes;
        if(this.activityService.getDisplayctivityByID(selectedActivity.ID)) this.activityService.getDisplayctivityByID(selectedActivity.ID).meetingNotes = selectedActivity.meetingNotes;
        selectedActivity.meetingNotes.push(this.autoSavedNote);
        // await this.uiService.displayLoader();
        try {
          await this.activityService.updateMeetingNoteInOfflineDB(tempNote);
          if(!this.device.isOffline) {
            let response = await this.meetingDataService.uploadMeetingNotesOnline();
            if (response && Array.isArray(response) && response.length>0) {
              response.forEach(res => {
                if(res['activityid'] == selectedActivity.ID) {
                  if(this.autoSavedNote) this.autoSavedNote.noteId = res.annotationid ? res.annotationid : res.noteid;
                }
              })
            } 
          } else {
            if(selectedActivity.ID.includes('offline')) {
              this.activityService.upsertMeetingsOfflineData(selectedActivity as AppointmentActivity);
            } else {
              await this.disk.updateOrInsertActivityToActivityDetailRawDocument(selectedActivity as AppointmentActivity, true);
            }
          }
          if(selectedActivity.ID == this.activityService.selectedActivity.ID) this.activityService.selectedActivity.meetingNotes = selectedActivity.meetingNotes;
          this.isSaveNotesEnabled = false;
          // await this.uiService.dismissLoader();
          this.initNotesSectionHeader();
          await this.activityService.task.complete();
        } catch(error) {
          console.error('createMeetingNote: ' + error);
          await this.activityService.task.complete();
        }
      } catch (error) {
        console.error('note outfocus event: ' + error);
      } finally {
        await this.activityService.task.complete();
      }
    }
  }
  // # # # View Contacts in sequence the way they were selected during meeting creation # # # //

  //======= new account contact ui ==============//

  async setAccountsSourceData(meeting: AppointmentActivity, selectedAccounts: any) {
    const affiliatedAccounts = meeting.contacts.length > 0
      ? await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(this.selectedContacts)
      : [];

    this.accountSourceData = this.getAvailableAccounts(selectedAccounts, affiliatedAccounts);
  }

  async setAccountFormField() {
    const meeting = this.activityService.selectedActivity as AppointmentActivity;
    const selectedAccounts = this.getSelectedAccounts();
    const endIcons = [{
      iconName: 'searchblue', overRideDisabledProperty: false
    },
    { iconName: 'expandblue', overRideDisabledProperty: true }];

    this.setAccountsSourceData(meeting, selectedAccounts)
    // const affiliatedAccounts = meeting.contacts.length > 0
    //   ? await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(this.selectedContacts)
    //   : [];

    // this.accountSourceData = this.getAvailableAccounts(selectedAccounts, affiliatedAccounts);
    const isAccountRequired = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY) || this.isAccountVisitRecord;

    const isAccountFormDisabled = !(
      this.activityService.selectedActivity
      && !this.isReadOnlyJointMeeting
      && !this.enableReadonlyViewForMeetings
      && (this.activityService.selectedActivity.state != MeetingActivityState.Completed)) || this.isAccountVisitNestedMeeting || this.from === PageName.AccountPlanActivities;

    this.accountFormField = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: '',
      id: 'account-field',
      formFieldType: FormFieldType.LIST,
      isReadOnly: true,
      showArrow: true,
      isHidden: false,
      isRequired: ![MeetingActivityState.Completed, MeetingActivityState.Canceled].includes(meeting.state) && (this.isGpsEnabled || isAccountRequired),
      isEmptyRequiredField: this.isAccountClicked && !this.requiredFields['account-field']['isAdded'],
      isDisabled: isAccountFormDisabled || this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      modalConfiguration: {
        searchTitle: this.translate.instant('SEARCH_ACCOUNTS'),
        selectedTitle: this.translate.instant('SELECTED_CONTACTS'),
        titleEndIcon: 'expandblue',
        searchInputConfig: {
          label: this.translate.instant('ACCOUNTS'),
          placeholder: this.translate.instant('TYPE') + '...'
        },
        showSearch: true,
      },
      filterMethod: this.filterAccountData.bind(this),
      endIcons,
      sections: await this.generateAccountSections(),
      selectedData: selectedAccounts,
      customPlaceholderLabel: ((this.activityService.selectedActivity && !this.isReadOnlyJointMeeting && !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed)) ? this.translate.instant('SELECT_ACCOUNTS') : this.translate.instant('NO_ACCOUNT'),
      eventHandler: (id: string, event, eventName, data) => {
        console.log(id, event, eventName, data)
        if (data && eventName == 'item-click') {
          const isAlreadyExist = meeting.accounts.some((account) => account.id === data.id);
          if (!isAlreadyExist) {
            this.addAccount(data);
          }
        } else if (eventName === 'closebutton') {
          this.removeAccount(data);
        }
      },
    };

    this.cdRef.detectChanges();
  }

  private getSelectedAccounts() {
    return this.activityService.selectedActivity.accounts.sort((accountA, accountB) => {
      if (accountA.accountName > accountB.accountName) return 1;
      if (accountA.accountName < accountB.accountName) return -1;
      return 0;
    }).map((account) => {
      let name = account.accountName;
      if (account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013) {
        name += ' (Inactive)';
      }

      let mappedAccount = {
        id: account.id,
        title: name,
        image: '',
        refObject: account,
        endIcon: 'closebutton',
        endIconClickEnabled: true
      }

      return mappedAccount;
    });
  }

  private getAvailableAccounts(selectedAccounts: any[], affiliatedAccounts: any[]) {
    return this.accountService.accounts
      .map(account => ({
        id: account.id,
        title: account.accountName,
        image: '',
        refObject: account,
        endIcon: affiliatedAccounts.some(ac => ac.id === account.id) ? 'affiliation' : undefined,
      }))
      .filter(account => !selectedAccounts.some(acc => acc.id === account.id))
      .sort(this.sortList);
  }

  private sortList(a: any, b: any) {
    if (a.endIcon && !b.endIcon) return -1;
    if (!a.endIcon && b.endIcon) return 1;
    return a.title.localeCompare(b.title);
  }

  async addAccount(data) {
    await this.meetingDataService.updateMeetingWithAccounts('add', data.refObject);
    this.initFormFields();
  }

  async removeAccount(data) {
    await this.meetingDataService.updateMeetingWithAccounts('remove', data.refObject);
    this.initFormFields();
  }

  async setContactSourceData(meeting: AppointmentActivity, selectedContacts: any) {
    const affiliatedContacts = meeting.accounts.length > 0
      ? await this.contactService.getAffiliatedContactsFromAccountsForMeeting(meeting.accounts)
      : [];

    this.contactSourceData = this.getAvailableContacts(selectedContacts, affiliatedContacts);
  }

  async setContactFormField() {
    const meeting = this.activityService.selectedActivity as AppointmentActivity;
    const selectedContacts = this.getSelectedContacts();
    const endIcons = [{
      iconName: 'searchblue', overRideDisabledProperty: false
    },
    { iconName: 'expandblue', overRideDisabledProperty: true }];

    this.setContactSourceData(meeting, selectedContacts)

    // const affiliatedContacts = meeting.accounts.length > 0
    //   ? await this.contactService.getAffiliatedContactsFromAccountsForMeeting(meeting.accounts)
    //   : [];

    // this.contactSourceData = this.getAvailableContacts(selectedContacts, affiliatedContacts);

    const isContactFormDisabled = (this.enableReadonlyViewForMeetings || this.isReadOnlyJointMeeting ||
      ((this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0 &&
        meeting.state === MeetingActivityState.Completed) || meeting.state === MeetingActivityState.Completed
      || (this.isAccountVisitRecord && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0) ||
      this.from === PageName.AccountPlanActivities || this.isFromChat);

    this.contactFormField = {
      label: (this.isLiveMeet) ? this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS') : this.utilityService.globalCustomersText,
      inputText: '',
      id: 'contact-field',
      formFieldType: FormFieldType.LIST,
      isReadOnly: true,
      showArrow: true,
      isHidden: false,
      isRequired: !this.enableReadonlyViewForMeetings && this.activityService.selectedActivity.state != MeetingActivityState.Completed && this.activityService.selectedActivity.state != MeetingActivityState.Canceled && !this.activityService.selectedActivity?.isDiffPosition && this.requiredFields?.['contact-field']['isRequired'],
      isEmptyRequiredField: this.isClickedContacts && this.requiredFields?.['contact-field']['isRequired'] && !this.requiredFields['contact-field']['isAdded'],
      isDisabled: isContactFormDisabled || this.activityService.selectedActivity.state == MeetingActivityState.Canceled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      customPlaceholderLabel: (this.activityService.selectedActivity && (this.activityService.selectedActivity as AppointmentActivity).contacts.length == 0) ? (this.activityService.selectedActivity.isCompleted || this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.isFromXperiences) ? this.translate.instant('No ' + this.utilityService.globalCustomerText) : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.utilityService.globalCustomersText }) : '',
      modalConfiguration: {
        searchTitle: this.translate.instant('SEARCH_CONTACTS'),
        selectedTitle: this.translate.instant('SELECTED_CONTACTS'),
        titleEndIcon: 'expandblue',
        searchInputConfig: {
          label: this.translate.instant('CONTACTS'),
          placeholder: this.translate.instant('TYPE') + '...'
        },
        showSearch: true,
      },
      filterMethod: this.filterContactData.bind(this),
      endIcons,
      sections: await this.generateContactSections(),
      selectedData: selectedContacts,
      eventHandler: (id: string, event, eventName, data) => {
        if (data && eventName == 'item-click') {
          const isAlreadyExist = meeting.contacts.some((contact) => contact.ID === data.id);
          if (!isAlreadyExist) {
            this.addContact(data);
          }
        } else if (eventName === 'closebutton') {
          this.removeContact(data);
        }
      },
    };

    this.cdRef.detectChanges();
  }

  private getSelectedContacts() {
    if ((this.activityService.selectedActivity as AppointmentActivity).contacts.length === 0) return [];

    if (this.contactService.isMeetingContactSequenceEnabled) {
      this.selectedContacts.sort((contactA, contactB) => {
        return contactA.indskr_sequence - contactB.indskr_sequence;
      });
    }

    return (this.activityService.selectedActivity as AppointmentActivity).contacts.sort((contactA, contactB): number => {
      let contactAFullName =  contactA.fullname ? contactA.fullname :(contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
      let contactBFullName = contactB.fullname ? contactB.fullname  : (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
      if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
      if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
      return 0;
    }).map(contact => ({
      id: contact.ID,
      title: contact.fullname.trim() + (!contact.isActive ? ' (Inactive)' : '') ? contact.fullname.trim() + (!contact.isActive ? ' (Inactive)' : '') : contact.fullName.trim() + (!contact.isActive ? ' (Inactive)' : ''),
      image: '',
      refObject: contact,
      endIcon: 'closebutton'
    }));
  }

  private getAvailableContacts(selectedContacts: any[], affiliatedContacts: any[]) {
    return this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true)
      .map(contact => ({
        id: contact.ID,
        title: contact.fullName,
        image: '',
        refObject: contact,
        endIcon: affiliatedContacts.some(con => con.ID === contact.ID) ? 'affiliation' : undefined,
      }))
      .filter(contact => !selectedContacts.some(con => con.ID === contact.id))
      .sort(this.sortList);
  }

  async addContact(data) {
    await this.meetingDataService.updateMeetingWithContacts('add', data.refObject);
    this.initFormFields();
  }

  async removeContact(data) {
    await this.meetingDataService.updateMeetingWithContacts('remove', data.refObject);
    this.initFormFields();
  }

  async generateAccountSections(): Promise<Section[]> {
    const selectedAccounts = this.getSelectedAccounts();
    return this.createSections(selectedAccounts, 'SELECTED', 'AL_RESULTS', this.translate.instant('SEARCH_OTHER_WITH_TEXT', { text: this.translate.instant("ACCOUNT") }));
  }

  async generateContactSections(): Promise<Section[]> {
    const selectedContacts = this.getSelectedContacts();
    let globalCustomerText;
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        if (this.utilityService.globalCustomersText) {
          globalCustomerText = this.utilityService.globalCustomerText;
        }
        break;
    }

    return this.createSections(selectedContacts, 'SELECTED', 'AL_RESULTS', this.translate.instant('SEARCH_OTHER_WITH_TEXT', { text: globalCustomerText }));
  }

  private createSections(
    selectedData: any[],
    selectedTitleKey: string,
    resultsTitleKey: string,
    lessThanResultButtonKey: string
  ): Section[] {
    return [
      {
        id: 'selected',
        title: this.translate.instant(selectedTitleKey),
        showCountOnTitle: true,
        maxResultsView: 5,
        bottomButtonShow: true,
        expand: false,
        showBottomButton: true,
        bottomButtonConfig: {
          exceedMaxResultButton: {
            id: 'view-all',
            label: this.translate.instant('VIEW_MORE'),
          },
        },
        data: selectedData,
      },
      {
        id: 'results',
        title: this.translate.instant(resultsTitleKey),
        showFilteredData: true,
        showCountOnTitle: true,
        maxResultsView: 5,
        bottomButtonShow: true,
        expand: true,
        showBottomButton: true,
        bottomButtonConfig: {
          exceedMaxResultButton: {
            id: 'view-all',
            label: this.translate.instant('VIEW_MORE'),
          },
          lessThanResultButton: {
            id: 'show-other',
            label: this.translate.instant(lessThanResultButtonKey),
          },
        },
        data: [],
      },
    ];
  }

  filterAccountData(query: string): any[] {
    const meeting = this.activityService.selectedActivity;

    return this.accountSourceData
      .filter((h: any) => h.title.toLowerCase().includes(query.toLowerCase()))
      .filter((h: any) => !meeting.accounts?.some((a: any) => a.id === h.id));
  }

  filterContactData(query: string): any[] {
    const meeting = this.activityService.selectedActivity as AppointmentActivity;

    return this.contactSourceData
      .filter((h: any) => h.title.toLowerCase().includes(query.toLowerCase()))
      .filter((h: any) => !meeting.contacts?.some((a: any) => a.ID === h.id));
  }
}

export interface BottomButtonConfig {
  exceedMaxResultButton?: {
    id: string;
    label: string;
  };
  lessThanResultButton?: {
    id: string;
    label: string;
  };
}

export interface Section {
  id: string;
  title: string;
  showCountOnTitle?: boolean;
  maxResultsView?: number;
  bottomButtonShow?: boolean;
  expand?: boolean;
  showBottomButton?: boolean;
  bottomButtonConfig?: BottomButtonConfig;
  showFilteredData?: boolean;
  data: any[];
  image?: string; // Added property for image
  refObject?: any; // Added property for reference object
  endIcon?: string; // Added property for end icon
  endIconClickEnabled?: boolean; // Added property for enabling/disabling end icon clicks
}




